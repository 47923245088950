import React from 'react'
import { Card, CardContent, Grid, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { SChip } from '../../shared/MUIAutoComplete/Autocomplete'

const DatasetDetailsComponent = props => {
  const { datasetPreview, addedViewers } = props

  const previewData = datasetPreview.data

  const isColumnSecurity = () => {
    const colTypeArray = ['metric', 'dimension']
    return datasetPreview.data?.fields_array?.some(
      col => colTypeArray.includes(col.type) && col.data_classification === 'Confidential'
    )
  }

  const externalViewers = addedViewers.filter(users => users.user_type === 'external')
  const internalViewers = addedViewers.filter(users => users.user_type === 'internal')

  return (
    <Box m={2} mt={0}>
      <Card>
        <CardContent>
          <Grid container justifyContent="space-between" direction="column">
            <Grid item>
              <Grid container justifyContent="space-between">
                <Typography tabIndex="0" variant="subtitle1" className="label-text">
                  Security
                </Typography>
              </Grid>
            </Grid>
            <Typography tabIndex="0" variant="button" className="label-text">
              Data Classification
            </Typography>
            <Typography tabIndex="0" className="value-text">
              {previewData.data_classification}
            </Typography>
            {internalViewers.length ? (
              <>
                <Typography tabIndex="0" variant="button" className="label-text">
                  Viewer(s)
                </Typography>
                <Typography tabIndex="0">
                  {internalViewers.map((item, index) => (
                    <SChip component="span" label={item.user_group_name || item.label} key={index} color="primary" />
                  ))}
                </Typography>
              </>
            ) : (
              ''
            )}
            {externalViewers.length ? (
              <>
                <Typography tabIndex="0" variant="button" className="label-text">
                  Vendor(s)
                </Typography>
                <Typography tabIndex="0">
                  {externalViewers.map((item, index) => (
                    <SChip component="span" label={item.user_group_name || item.label} key={index} color="primary" />
                  ))}
                </Typography>
              </>
            ) : (
              ''
            )}
            <Typography tabIndex="0" variant="button" className="label-text">
              Row Level Security
            </Typography>
            <Typography tabIndex="0">
              {previewData.security_control?.controls
                ? previewData.security_control.controls.map(eachControl => (
                    <SChip
                      component="span"
                      className="chip-business"
                      key={eachControl.security_control_id}
                      label={eachControl.security_control_id}
                      tabIndex={-1}
                    />
                  ))
                : 'None'}
            </Typography>
            <Typography tabIndex="0" variant="button" className="label-text">
              Column Level Security
            </Typography>
            <Typography tabIndex="0">{isColumnSecurity() ? 'Applied' : 'None'}</Typography>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  )
}

export default DatasetDetailsComponent
