import React from 'react'
import { Typography } from '@mui/material'

const Feedback = () => (
  <div>
    <Typography type="body1">Feedback page here</Typography>
  </div>
)

export default Feedback
