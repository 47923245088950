import React from 'react'
import { Redirect, Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import {
  AppBar,
  Card,
  CardContent,
  Toolbar,
  Button,
  Icon,
  Chip,
  IconButton,
  Grid,
  Typography,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Tooltip,
  Popover,
  Slide,
  MenuList,
  ListItemIcon,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Link as MaterialLink,
  Badge,
  Tabs,
  Tab,
  ListItemText,
  Collapse,
  ListItemButton,
  List,
  Box,
} from '@mui/material'
import {
  Link as IconLink,
  Edit,
  Delete,
  InfoRounded,
  FilterList,
  MoreVert,
  Close,
  DateRange,
  Lock,
  FileCopy,
  Refresh,
  InfoOutlined,
  Print,
  ExpandLess,
  ExpandMore,
  Engineering,
  FileDownload,
  DataUsage,
  ManageHistory,
  Code,
  CodeOff,
  Info,
  TodayRounded,
} from '@mui/icons-material'
import moment from 'moment'
import Viewer from 'react-froala-wysiwyg/FroalaEditorView'
import groupBy from 'lodash/groupBy'
import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'
import isEmpty from 'lodash/isEmpty'
import { CSVLink } from 'react-csv'
import classnames from 'classnames'
import LoadingOverlay from 'react-loading-overlay'
import urlon from 'urlon'
import { Spinner, isMetric, createErrMsg, getColIdx, CUSTOM_STR } from 'greenfield-utilities'
import Gfv from 'greenfield-visualizations'
import styled from 'styled-components'
import SwipeableViews from 'react-swipeable-views'

import {
  GreenfieldFavoriteButton,
  GreenfieldFilterViewer,
  GreenfieldFilterChipToolbar,
  SaveAsContainer,
} from '../../../shared/shared'
import Generateshortlink from '../../../shared/Generateshortlink'
import ViewerDescription from '../../../shared/ViewerDescription'
import ConfirmDialog from '../../../shared/Dialog/ConfirmDialog'
import { getFormatDate, getTimePeriodButtonLabel } from '../../../shared/util'
import { prepCsv, buildURI, getCsvFooter } from '../../../shared/CsvUtility/CsvUtlity'
import TreetableContainer from '../../../shared/CardContent/GfvTreetable.container'
import MapsContainer from '../../../shared/CardContent/GfvMaps.container'
import ViewerTimePeriod from '../../../shared/ViewerTimePeriod/ViewerTimePeriod.container'
import DrillthroughBreadcrumbs from '../../../shared/CardContent/DrillthroughBreadcrumbs'
import { DrillThroughClicked } from '../../../../ducks/Contexts'
import LockAndCompare from '../../../shared/LockAndCompare/LockAndCompare.js'
import Notifications from '../../../shared/Notifications/Notifications.js'
import LargeExportInit from '../../LargeExport/LargeExportInit/LargeExportInit.container'
import {
  SMART_EXPORT_TOKEN_EXPIRE_COPY,
  SMART_EXPORT_SERVICE_ERROR,
  SMART_EXPORT_COULD_NOT_GET_ALL_ROW_MESSAGE,
  MULTI_SELECT_CUSTOM_STR,
  VIEW_USAGE_CARD,
  LWR_CASE_TODAY,
} from '../../../constants/StringConstants'
import { METRIC_TYPES, STACK_BAR_VIZ_TYPES } from '../../../constants/ArrayConstants'
import { AnchorIcon } from '../../../shared/Svgicon/Svgicon'
import {
  getButtonText,
  getSecondaryTimePeriodColumn,
  checkMultipleTimeSeries,
} from '../../../shared/TimePeriod/TimePeriodUtils'
import HistoryPopover from '../../../shared/HistoryPopover/HistoryPopover'
import SupportInfo from '../../../shared/SupportDialog/SupportInfo'
import ConfigOverrides from '../../../shared/ConfigOverrides/ConfigOverrides'
import AccessDeniedViewOwner from '../../../shared/AccessDeniedViewOwner'
import { getOwnerInfoChip } from '../../../shared/AgGrid/AgGridHelper'

import './cardviewer.scss'
import DataAlertSubscriptionDialog, {
  CARD_SUBSCRIPTION,
  DATA_ALERT,
} from './DataAlertAndSubscription/DataAlertSubscriptionDialog'

const Transition = React.forwardRef((props, ref) => <Slide direction="left" {...props} ref={ref} />)

const SListItemButton = styled(ListItemButton)`
  margin-left: 65px;
`

class Cardviewer extends React.Component {
  state = {
    dataAlertCreateStatus: this.props.userAccessStatus?.data?.SUBSCRIPTION_CREATE,
    subscriptionCreateStatus: this.props.userAccessStatus?.data?.SUBSCRIPTION_CREATE_CARD_SUBSCRIPTION,
    enableAmChartv5: this.props.userAccessStatus?.data?.ENABLE_AMCHART_V5,
    dataAlertDialogOpen: false,
    subscriptionDialogOpen: false,
    expandLess: false,
    expandMore: false,
    expandHistory: false,
    expandConfigs: false,
    openFilters: false,
    selectedFilterId: '',
    data: null,
    openActionsMenu: null,
    openMenuItem: false,
    confirmDeleteCard: false,
    queryCardOpen: false,
    openSaveAs: false,
    redirectToNewCard: false,
    serviceError: false,
    isShowHelp: false,
    cardDeleted: false,
    mobileFilterDialogState: false,
    viewMobileFilterDialog: false,
    mobileMoreMenuActive: false,
    mobileViewDetailActive: null,
    mobileAnchorEl: null,
    openLockAndCompareWin: false,
    lockColList: [],
    compareColList: [],
    selectedColList: [],
    mobileLockAndCompareState: false,
    drillPathColList: {
      lockCols: [],
      compareCols: [],
    },
    timePeriodLabelClicked: null,
    csvDataForExport: [],
    activeFilterMobileTab: 0,
    triggerSmartExportDownload: false,
    smartExportData: [],
    smartExportLoading: false,
    notAllRowsLoadedDialogStatus: false,
    smartExportErrorStateObj: {},
    smartExportInErrorState: false,
    loadingNewToken: false,
    showSmartExportDebugSection: false,
    smartExportInitDialogBtnActive: false,
    largeExportDialogIsOpen: false,
    buttonText: '',
    beginDate: '',
    endDate: '',
    secondaryButtonText: '',
    secondaryBeginDate: '',
    secondaryEndDate: '',
    unDeleteConfirm: true,
    cancelRestore: false,
    showSupportDialog: false,
    agGridApi: null,
    shortLinkVisible: false,
    pageSize: 25,
    page: 0,
    historyData: null,
    subscriptionOpen: false,
    printOpen: false,
    exportOpen: false,
    troubleShootOpen: false,
    errorMessage: 'No results were found.',
  }

  // updates once regular table & pivot table loads - keep out of state to prevent components re-rendering unnecessarily
  rawDataForCsv = null
  drillCardDelete = false

  componentDidUpdate(prevProps) {
    const {
      cardInfo,
      displayServiceErrorMessage,
      renderCard,
      saveCardFromViewerStatus,
      cardId,
      isMobile,
      activeDrillthroughIndex,
      drillCardMetaStatus,
      setHeaderTitle,
      smartExportData,
      isBadShortUrl,
      refreshCard,
      userAccessStatus,
    } = this.props
    const prevRenderCard = prevProps.renderCard
    const prevSmartExportData = prevProps.smartExportData

    if (!prevProps.isBadShortUrl && isBadShortUrl) {
      displayServiceErrorMessage(
        createErrMsg(
          '',
          'The short link you have requested does not exist and you have been redirected to the default card.'
        )
      )
    }

    if (userAccessStatus && userAccessStatus !== prevProps.userAccessStatus && userAccessStatus.status === 200) {
      const isDataAlertEnabledForUser = this.props.userAccessStatus?.data.SUBSCRIPTION_CREATE
      const isSubscriptionEnabledForUser = this.props.userAccessStatus?.data.SUBSCRIPTION_CREATE_CARD_SUBSCRIPTION
      const enableAmChartv5 = this.props.userAccessStatus?.data.ENABLE_AMCHART_V5
      this.setState({
        dataAlertCreateStatus: isDataAlertEnabledForUser,
        subscriptionCreateStatus: isSubscriptionEnabledForUser,
        enableAmChartv5,
      })
    }

    if (saveCardFromViewerStatus && saveCardFromViewerStatus !== prevProps.saveCardFromViewerStatus) {
      if (saveCardFromViewerStatus.status >= 200 && saveCardFromViewerStatus.status < 300) {
        const { routeProps } = this.props

        routeProps.history.replace(`/card/${saveCardFromViewerStatus.data._id}`)
        // This is not ideal to say the least but its not a common issue and resolves the weird race condition that is currently there.  Will be updated when cardviewer is refactored
        window.location.reload()
      } else if (saveCardFromViewerStatus.status === 'failed') {
        displayServiceErrorMessage(createErrMsg(saveCardFromViewerStatus, 'Your attempt to save this card has failed.'))
      }
    }

    if (
      isMobile &&
      Number.isInteger(activeDrillthroughIndex) &&
      activeDrillthroughIndex !== -1 &&
      drillCardMetaStatus &&
      drillCardMetaStatus[cardId] &&
      drillCardMetaStatus[cardId].data &&
      (prevProps.activeDrillthroughIndex !== activeDrillthroughIndex ||
        prevProps.drillCardMetaStatus[cardId].status !== drillCardMetaStatus[cardId].status)
    ) {
      setHeaderTitle(drillCardMetaStatus[cardId].data.card_config.card_attribute.card_title)
    }

    if (
      isMobile &&
      Number.isInteger(activeDrillthroughIndex) &&
      activeDrillthroughIndex === -1 &&
      prevProps.activeDrillthroughIndex !== activeDrillthroughIndex
    ) {
      setHeaderTitle(cardInfo.data.card_attribute.card_title)
    }

    if (
      Number.isInteger(activeDrillthroughIndex) &&
      activeDrillthroughIndex !== -1 &&
      drillCardMetaStatus &&
      drillCardMetaStatus[cardId] &&
      prevProps.drillCardMetaStatus[cardId] &&
      prevProps.drillCardMetaStatus[cardId].status === 206 &&
      drillCardMetaStatus[cardId].status === 'requested'
    ) {
      this.drillCardDelete = true
    }

    if (
      Number.isInteger(activeDrillthroughIndex) &&
      activeDrillthroughIndex !== -1 &&
      drillCardMetaStatus &&
      drillCardMetaStatus[cardId] &&
      drillCardMetaStatus[cardId].data &&
      prevProps.drillCardMetaStatus[cardId] &&
      prevProps.drillCardMetaStatus[cardId].status === 'requested' &&
      drillCardMetaStatus[cardId].status !== 'requested' &&
      this.drillCardDelete
    ) {
      this.drillCardDelete = false
      refreshCard()
    }

    if (
      prevRenderCard[cardId] &&
      prevRenderCard[cardId].status === 'requested' &&
      renderCard[cardId].status === 'failed'
    ) {
      displayServiceErrorMessage(
        createErrMsg(renderCard[cardId], 'Something went wrong with the request to render this card.')
      )
    }

    if (prevProps.cardInfo.status === 'requested' && cardInfo.status === 'failed') {
      displayServiceErrorMessage(
        createErrMsg(cardInfo, 'Something went wrong with the request to retrieve card metadata.')
      )
    } else if (
      isMobile &&
      Number.isInteger(activeDrillthroughIndex) &&
      activeDrillthroughIndex === -1 &&
      prevProps.activeDrillthroughIndex !== activeDrillthroughIndex
    ) {
      setHeaderTitle(cardInfo.data.card_attribute.card_title)
    }

    if (
      prevProps.renderCard[cardId] &&
      prevProps.renderCard[cardId].status === 'requested' &&
      renderCard[cardId].status === 202
    ) {
      let errMsg = createErrMsg(renderCard[cardId], renderCard[cardId].data.message).replace(/\s*\[.*?\]\s*/g, '')
      if (errMsg === '') {
        errMsg = 'This card has an excessive amount of information being returned, you need to add additional filters'
      }
      this.setState({
        errorMessage: errMsg,
      })
    }

    if (
      prevRenderCard[cardId]?.status !== 202 &&
      prevRenderCard[cardId]?.status !== 'requested' &&
      renderCard[cardId]?.status === 202
    ) {
      this.handleTimeChange({ granularity: renderCard[cardId].data.rendered_interval.granularity })
    }

    // Reset state for TableViz print
    if (renderCard[cardId]?.status === 'requested' && prevRenderCard[cardId]?.status === 200) {
      this.setState({
        page: 0,
        pageSize:
          prevRenderCard[cardId]?.data?.card_config?.card_attribute?.table_properties?.default_rows_per_page || 25,
      })
    } else if (prevRenderCard[cardId]?.status === 'requested' && renderCard[cardId]?.status === 200) {
      // Initialize state for TableViz print
      this.setState({
        page: 0,
        pageSize: renderCard[cardId]?.data?.card_config?.card_attribute?.table_properties?.default_rows_per_page || 25,
      })
    }

    /*
    Below is setting data and states for smart export
  */

    if (
      smartExportData !== prevSmartExportData &&
      smartExportData &&
      smartExportData.status >= 200 &&
      smartExportData.status < 300
    ) {
      const { data } = smartExportData

      if (data.partial_rows) {
        this.setState({
          notAllRowsLoadedDialogStatus: true,
          smartExportLoading: false,
          smartExportInitDialogBtnActive: false,
          smartExportData: data,
          smartExportErrorStateObj: {},
        })
      } else {
        this.handleSmartExportDownloadAnyway(smartExportData.data)
      }
    } else if (
      smartExportData !== prevSmartExportData &&
      smartExportData &&
      smartExportData.status >= 400 &&
      smartExportData.status < 500
    ) {
      // Lee is adding an explicit error code so we know for sure that what is going is an expired token. For now, we are inferring from the resp message
      this.setState({
        notAllRowsLoadedDialogStatus: true,
        smartExportLoading: false,
        smartExportInErrorState: true,
        smartExportInitDialogBtnActive: false,
        smartExportErrorStateObj: {
          type: 'tokenExpire',
          message: SMART_EXPORT_TOKEN_EXPIRE_COPY,
        },
      })
    } else if (
      smartExportData !== prevSmartExportData &&
      smartExportData &&
      smartExportData.status >= 500 &&
      smartExportData.status < 600
    ) {
      this.setState({
        smartExportLoading: false,
        notAllRowsLoadedDialogStatus: true,
        smartExportInErrorState: true,
        smartExportInitDialogBtnActive: false,
        smartExportErrorStateObj: {
          type: 'serviceError',
          message: SMART_EXPORT_SERVICE_ERROR,
        },
      })
    }

    /*
      Below code: a smart export token has expired. At this point, we're getting a new token
      Once the conditional passes, It'll go on to get SmartExport data
    */

    if (
      this.state.loadingNewToken &&
      renderCard[cardId] &&
      renderCard[cardId].status >= 200 &&
      renderCard[cardId].status < 300 &&
      prevProps.renderCard[cardId] &&
      prevProps.renderCard[cardId].status &&
      prevProps.renderCard[cardId].status !== renderCard[cardId].status
    ) {
      this.setState(
        {
          loadingNewToken: false,
        },
        () => {
          this.handleSmartExportInit()
        }
      )
    }
    this.updateTimePeriodLabel(prevProps)
  }

  updateTimePeriodLabel(prevProps) {
    const {
      dateFilterStatus,
      timePeriod,
      secondaryTimePeriod,
      cardInfo,
      selectedDatasetObj,
      renderCard,
      anchorDateFilterStatus,
      cardId,
    } = this.props

    if (cardInfo && cardInfo.status >= 200 && cardInfo.status < 300) {
      const currentCardId = cardId || cardInfo.data._id

      if (renderCard[currentCardId] && renderCard[currentCardId].data) {
        const datasetId = cardInfo?.data?.card_attribute?.dataset_id
        const renderedCardData = renderCard[currentCardId].data
        const renderedPayload = renderedCardData?.context?.rendered_payload
        const secondaryTimePeriodColumn = getSecondaryTimePeriodColumn(
          selectedDatasetObj && selectedDatasetObj[datasetId]?.data
        )
        const rendererTimeInterval = renderedPayload ? renderedPayload.time_period : {}
        const rendererSecondaryTimeInterval =
          renderedPayload && secondaryTimePeriodColumn
            ? renderedPayload.secondary_time_periods[secondaryTimePeriodColumn?.field_name]
            : {}

        const mergedTimePeriod = { ...timePeriod, ...rendererTimeInterval }
        const isTimeIntervalArray =
          Array.isArray(mergedTimePeriod?.intervals) && mergedTimePeriod?.type === CUSTOM_STR.toLowerCase()
        const intervalTime = isTimeIntervalArray ? mergedTimePeriod?.intervals : mergedTimePeriod?.interval || ''
        const dateFilterObj = mergedTimePeriod?.anchor_date ? anchorDateFilterStatus : dateFilterStatus

        const mergedSecondaryTimePeriod = secondaryTimePeriodColumn &&
          secondaryTimePeriod && {
            ...secondaryTimePeriod[secondaryTimePeriodColumn?.field_name],
            ...rendererSecondaryTimeInterval,
          }
        const isSecondaryTimeIntervalArray =
          Array.isArray(mergedSecondaryTimePeriod?.intervals) &&
          mergedSecondaryTimePeriod?.type === CUSTOM_STR.toLowerCase()
        const secondaryIntervalTime = isSecondaryTimeIntervalArray
          ? mergedSecondaryTimePeriod?.intervals
          : mergedSecondaryTimePeriod?.interval || ''

        if (
          dateFilterObj?.data &&
          intervalTime &&
          (!isEqual(renderCard[currentCardId], prevProps.renderCard[currentCardId]) ||
            !isEqual(selectedDatasetObj[datasetId], prevProps.selectedDatasetObj[datasetId]))
        ) {
          const formattedText = getButtonText(
            mergedTimePeriod,
            intervalTime,
            renderedCardData,
            dateFilterStatus,
            selectedDatasetObj[datasetId]?.data,
            anchorDateFilterStatus
          )
          if (rendererSecondaryTimeInterval && checkMultipleTimeSeries(selectedDatasetObj[datasetId]?.data)) {
            const secondaryFormattedText = getButtonText(
              mergedSecondaryTimePeriod,
              secondaryIntervalTime,
              renderedCardData,
              dateFilterStatus,
              selectedDatasetObj[datasetId]?.data,
              anchorDateFilterStatus
            )
            this.setState({
              secondaryButtonText: secondaryFormattedText.buttonText,
              secondaryBeginDate: secondaryFormattedText.beginDate,
              secondaryEndDate: secondaryFormattedText.endDate,
            })
          }
          this.setState({
            buttonText: formattedText.buttonText,
            beginDate: formattedText.beginDate,
            endDate: formattedText.endDate,
          })
        }
      } else if (renderCard[currentCardId]?.status === 'failed') {
        const datasetId = cardInfo?.data?.card_attribute?.dataset_id
        const secondaryTimePeriodColumn = getSecondaryTimePeriodColumn(
          selectedDatasetObj && selectedDatasetObj[datasetId]?.data
        )
        const cardQueryAttribute = cardInfo?.data?.card_query_attribute
        const savedTimePeriod = cardQueryAttribute?.time_period
        const rendererSecondaryTimeInterval = cardQueryAttribute?.secondary_time_periods
          ? cardQueryAttribute?.secondary_time_periods[secondaryTimePeriodColumn?.field_name]
          : {}

        const mergedTimePeriod = { ...savedTimePeriod, ...timePeriod }
        const isTimeIntervalArray =
          Array.isArray(mergedTimePeriod?.intervals) && mergedTimePeriod?.type === CUSTOM_STR.toLowerCase()
        const intervalTime = isTimeIntervalArray ? mergedTimePeriod?.intervals : mergedTimePeriod?.interval || ''
        const dateFilterObj = mergedTimePeriod?.anchor_date ? anchorDateFilterStatus : dateFilterStatus

        const mergedSecondaryTimePeriod = secondaryTimePeriodColumn &&
          secondaryTimePeriod && {
            ...secondaryTimePeriod[secondaryTimePeriodColumn?.field_name],
            ...rendererSecondaryTimeInterval,
          }
        const isSecondaryTimeIntervalArray =
          Array.isArray(mergedSecondaryTimePeriod?.intervals) &&
          mergedSecondaryTimePeriod?.type === CUSTOM_STR.toLowerCase()
        const secondaryIntervalTime = isSecondaryTimeIntervalArray
          ? mergedSecondaryTimePeriod?.intervals
          : mergedSecondaryTimePeriod?.interval || ''

        if (
          dateFilterObj?.data &&
          intervalTime &&
          (!isEqual(renderCard[currentCardId], prevProps.renderCard[currentCardId]) ||
            !isEqual(selectedDatasetObj[datasetId], prevProps.selectedDatasetObj[datasetId]))
        ) {
          const formattedText = getButtonText(
            mergedTimePeriod,
            intervalTime,
            null,
            dateFilterStatus,
            selectedDatasetObj[datasetId]?.data,
            anchorDateFilterStatus
          )
          if (rendererSecondaryTimeInterval && checkMultipleTimeSeries(selectedDatasetObj[datasetId]?.data)) {
            const secondaryFormattedText = getButtonText(
              mergedSecondaryTimePeriod,
              secondaryIntervalTime,
              null,
              dateFilterStatus,
              selectedDatasetObj[datasetId]?.data,
              anchorDateFilterStatus
            )
            this.setState({
              secondaryButtonText: secondaryFormattedText.buttonText,
              secondaryBeginDate: secondaryFormattedText.beginDate,
              secondaryEndDate: secondaryFormattedText.endDate,
            })
          }
          this.setState({
            buttonText: formattedText.buttonText,
            beginDate: formattedText.beginDate,
            endDate: formattedText.endDate,
          })
        }
      }
    }
  }

  handleMobileFavoriteButtonClick = () => {
    this.sendFireflyEvent(false, { eventName: 'mobileMoreFavoriteBtnClick' })
  }

  handleMobileMoreBtnClick = eventName => {
    const eventObject = {
      eventName,
      key: this.props.cardId,
      eventData: {
        filters: this.props.activeFilters,
        timePeriod: this.props.timePeriod,
      },
    }
    this.sendFireflyEvent(true, eventObject)
  }

  handleToggleSmartExportDebugSection = () => {
    this.setState({
      showSmartExportDebugSection: !this.state.showSmartExportDebugSection,
    })
  }

  toggleMobileViewDetail = (panel, expanded) => {
    this.setState({
      mobileViewDetailActive: expanded ? panel : false,
    })
  }

  handleTriggerLargeExportInit = () => {
    this.props.trackEvent({
      event: {
        type: 'LargeExportRequestDialogClick',
      },
    })

    this.setState({
      largeExportDialogIsOpen: !this.state.largeExportDialogIsOpen,
      openActionsMenu: null,
      notAllRowsLoadedDialogStatus: false,
      exportOpen: false,
    })
  }

  handleToggleNotAllRowsDialogStatus = () => {
    this.setState({
      notAllRowsLoadedDialogStatus: !this.state.notAllRowsLoadedDialogStatus,
    })
  }

  handleSetAgGridApi = tableRef => {
    this.setState({ agGridApi: tableRef })
  }

  getTimePeriodViewer() {
    const { cardInfo, renderCard, cardId, timePeriod } = this.props

    if (cardInfo && cardInfo.status >= 200 && cardInfo.status < 300) {
      const currentCardId = cardId || cardInfo.data._id
      if (renderCard[currentCardId] && renderCard[currentCardId].data) {
        return cloneDeep(renderCard[currentCardId].data?.context?.rendered_payload?.time_period) || {}
      } else if (renderCard[currentCardId]?.status === 'failed') {
        return timePeriod
      }
    }
  }

  getLargeExportTimePeriodViewer() {
    const { renderCard, cardId } = this.props

    return (renderCard && renderCard[cardId] && renderCard[cardId]?.data?.context?.rendered_payload?.time_period) || {}
  }

  handleDismissPopover = () => {
    this.setState({
      openFilters: false,
    })
  }

  toggleLoadingState = () => {
    this.setState({
      smartExportLoading: !this.state.smartExportLoading,
    })
  }

  closeMobilePopOver = () => {
    this.setState({
      mobileAnchorEl: null,
    })
  }

  handleToggleMobileTimePeriod = e => {
    this.handleMobileMoreBtnClick('mobileMoreDatepickerClick')
    if (!this.state.mobileAnchorEl) {
      this.setState({
        mobileAnchorEl: e.currentTarget,
      })
    }
  }

  handleFilterClick = e => {
    const { target } = e

    this.setState({
      openFilters: target,
      selectedFilterId: '',
    })
  }

  handleClose = () => {
    this.setState({
      expandMore: false,
      expandLess: true,
      openActionsMenu: null,
    })
  }

  handleUsageClick = () => {
    this.setState({
      expandLess: false,
    })
  }

  handleMoreClick = () => {
    this.setState({
      expandLess: false,
      expandMore: true,
    })
  }

  handleViewHistory = () => {
    this.setState({
      expandLess: false,
      expandHistory: true,
      openActionsMenu: null,
    })
  }

  handleCloseHistory = () => {
    this.setState({
      expandHistory: false,
      openActionsMenu: null,
    })
  }

  setHistoryDataCallback = data => {
    this.setState({
      historyData: data,
    })
  }

  handleViewConfigs = () => {
    this.setState({
      expandConfigs: true,
      expandLess: false,
      openActionsMenu: null,
      troubleShootOpen: false,
    })
  }

  handleCloseConfigs = () => {
    this.setState({
      expandConfigs: false,
      openActionsMenu: null,
    })
  }

  filterChipClick = selectedFilterId => {
    this.setState({
      openFilters: true,
      selectedFilterId,
    })
  }

  handleMoreActionsClick = event => {
    this.setState({
      openActionsMenu: event.currentTarget,
    })
  }

  handleMoreActionsClose = () => {
    this.setState({
      openActionsMenu: null,
      subscriptionOpen: false,
      printOpen: false,
      exportOpen: false,
      troubleShootOpen: false,
    })
  }

  prepCsvData = data => {
    this.rawDataForCsv = cloneDeep(data)
  }

  handleSmartExportTrigger = () => {
    const { renderCard, cardId } = this.props

    this.setState({
      smartExportData: renderCard[cardId].data,
      notAllRowsLoadedDialogStatus: true,
    })
    this.setState({ exportOpen: false })
  }

  handleSmartExportInit = () => {
    const { renderCard, cardId } = this.props

    this.setState({
      smartExportLoading: true,
      openActionsMenu: null,
      smartExportInitDialogBtnActive: true,
      smartExportData: [], // reset data from previous requests
    })

    this.props.getSmartExportData({
      cardId,
      cardData: renderCard[cardId].data.context.rendered_payload,
    })
  }

  insertComma = str => str.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')

  handleSmartExportDownloadAnyway = data => {
    /*
        We attempted to get all the users data, but could not get all of it. If the user indicates they still want the data, this function gets fired
    */
    const { lockColList, compareColList, drillPathColList } = this.state
    const { appliedFilters, cardInfo, cardId, renderCard, drill } = this.props
    const smartExportData = data || this.state.smartExportData
    const cardName = cardInfo.data.card_attribute.card_title

    const vizType = cardInfo && cardInfo.data ? cardInfo.data.card_attribute.viz_type.toLowerCase() : ''
    let columnsHeader = []
    if (vizType === 'table' && (lockColList.length || compareColList.length)) {
      columnsHeader = lockColList.concat(compareColList)

      // Handle for drill columns // Change for columnsHeaderChange
      const columns = cardInfo?.data ? this.getColumns(cardInfo.data, 'column_display_name') : []
      const renderColumns = this.getRenderColumns(cardId, renderCard, columns, drill)
      if (!isEqual(columns, renderColumns) && drill && drill.length) {
        const updatedColumnsHeader = []
        let colHeader = ''
        columnsHeader.forEach(colName => {
          colHeader = colName
          if (
            !renderColumns.includes(colHeader.display_name) &&
            (getColIdx(drillPathColList.lockCols, 'field_name', colHeader.name) > -1 ||
              getColIdx(drillPathColList.lockCols, 'column_display_name', colHeader.display_name) > -1 ||
              getColIdx(drillPathColList.compareCols, 'field_name', colHeader.name) > -1 ||
              getColIdx(drillPathColList.compareCols, 'column_display_name', colHeader.display_name) > -1)
          ) {
            // eslint-disable-next-line camelcase
            const drillMaps = cardInfo?.data?.card_query_attribute?.drillmaps
              ? cardInfo.data.card_query_attribute.drillmaps
              : []
            let selDrillPathCols = []
            drillMaps.forEach(eachDrillCol => {
              if (eachDrillCol.drillmap_name && eachDrillCol.drillmap_name === colHeader.name) {
                selDrillPathCols = eachDrillCol.drillmap_path
              }
            })

            let isDrillColNameUpdated = false
            selDrillPathCols.forEach(drillPath => {
              if (
                !isDrillColNameUpdated &&
                drillPath.column_display_name !== colHeader.display_name &&
                renderColumns.includes(drillPath.column_display_name)
              ) {
                colHeader = { name: drillPath.field_name, display_name: drillPath.column_display_name }
                isDrillColNameUpdated = true
              }
            })
          }
          updatedColumnsHeader.push(colHeader)
        })
        columnsHeader = updatedColumnsHeader
      }
    }

    // ***TODO: Is this still needed?
    // const isCompareBy =
    //   renderCard &&
    //   renderCard[cardId] &&
    //   renderCard[cardId].data &&
    //   renderCard[cardId].data.context &&
    //   renderCard[cardId].data.context.rendered_payload &&
    //   Boolean(
    //     renderCard[cardId].data.context.rendered_payload.time_period &&
    //       renderCard[cardId].data.context.rendered_payload.time_period.compare_by
    //   )

    // const queryResults =
    //   vizType === 'pivot' ? formatDataForPivot(smartExportData, isCompareBy).dataObj : smartExportData.query_results
    const queryResults = vizType === 'pivot' ? [] : smartExportData.query_results

    if (vizType === 'tree_table') {
      // tree table csv raw data stored in nested hash map
      const { headerRow } = this.flattenTreeTableCsvData()
      cardInfo.data.card_query_attribute.columns.forEach(col => {
        if (isMetric(col.type)) {
          headerRow[col.ref_id] = col.column_display_name
        }
      })

      columnsHeader = headerRow
    }

    prepCsv(this.props, queryResults, columnsHeader, appliedFilters).then(data => {
      this.setState(
        {
          smartExportLoading: false,
          notAllRowsLoadedDialogStatus: false,
          smartExportInitDialogBtnActive: false,
        },
        () => {
          const dataUri = buildURI(data.csvData)

          /*
            I dont like this either...
            File extensives are omitted on windows machines by window.open, this gets around that problem
            -Art/49754518
          */

          const downloadLink = document.createElement('a')
          downloadLink.href = dataUri
          downloadLink.download = `${cardName}.csv`
          downloadLink.target = '_self'

          document.body.appendChild(downloadLink)
          downloadLink.click()
          document.body.removeChild(downloadLink)
        }
      )
    })
  }

  handleExportToCsvClick = done => {
    const {
      cardInfo,
      cardId,
      renderCard,
      drill,
      thumbnail,
      appliedFilters,
      activeDrillthroughIndex,
      drillCardMetaStatus,
    } = this.props
    const { lockColList, compareColList, drillPathColList } = this.state
    if (!thumbnail) {
      /* eslint-disable */
      const cardAttribute =
        !Number.isInteger(activeDrillthroughIndex) || activeDrillthroughIndex === -1
          ? cardInfo?.data?.card_attribute
          : drillCardMetaStatus[cardId]?.data?.card_config?.card_attribute
      const vizType = cardAttribute?.viz_type?.toLowerCase() || ''
      /* eslint-enable */
      let columnsHeader = []
      if (vizType === 'table' && (lockColList.length || compareColList.length)) {
        columnsHeader = lockColList.concat(compareColList)

        // Handle for drill columns // Change for columnsHeaderChange
        const columns = cardInfo && cardInfo.data ? this.getColumns(cardInfo.data, 'column_display_name') : []
        const renderColumns = this.getRenderColumns(cardId, renderCard, columns, drill)
        if (!isEqual(columns, renderColumns) && drill && drill.length) {
          const updatedColumnsHeader = []
          let colHeader = ''
          columnsHeader.forEach(colName => {
            colHeader = colName
            if (
              !renderColumns.includes(colHeader.display_name) &&
              (getColIdx(drillPathColList.lockCols, 'field_name', colHeader.name) > -1 ||
                getColIdx(drillPathColList.lockCols, 'column_display_name', colHeader.display_name) > -1 ||
                getColIdx(drillPathColList.compareCols, 'field_name', colHeader.name) > -1 ||
                getColIdx(drillPathColList.compareCols, 'column_display_name', colHeader.display_name) > -1)
            ) {
              const drillMaps =
                cardInfo &&
                cardInfo.data &&
                cardInfo.data.card_query_attribute &&
                cardInfo.data.card_query_attribute.drillmaps
                  ? cardInfo.data.card_query_attribute.drillmaps
                  : []

              let selDrillPathCols = []
              drillMaps.forEach(eachDrillCol => {
                if (eachDrillCol.drillmap_name && eachDrillCol.drillmap_name === colHeader.name) {
                  selDrillPathCols = eachDrillCol.drillmap_path
                }
              })

              let isDrillColNameUpdated = false
              selDrillPathCols.forEach(drillPath => {
                if (
                  !isDrillColNameUpdated &&
                  drillPath.column_display_name !== colHeader.display_name &&
                  renderColumns.includes(drillPath.column_display_name)
                ) {
                  colHeader = { name: drillPath.field_name, display_name: drillPath.column_display_name }
                  isDrillColNameUpdated = true
                }
              })
            }
            updatedColumnsHeader.push(colHeader)
          })
          columnsHeader = updatedColumnsHeader
        }
      }

      if (vizType === 'tree_table') {
        // tree table csv raw data stored in nested hash map
        const { headerRow, data } = this.flattenTreeTableCsvData()
        // get metric column details & append to column header object
        cardInfo.data.card_query_attribute.columns.forEach(col => {
          if (isMetric(col.type)) {
            headerRow[col.ref_id] = col.column_display_name
          }
        })
        prepCsv(this.props, data, headerRow, appliedFilters)
          .then(data => {
            this.setState({ csvDataForExport: data.csvData }, done())
          })
          .catch(() => {
            done(false)
          })
      } else if (this.rawDataForCsv && this.rawDataForCsv.length) {
        prepCsv(this.props, this.rawDataForCsv, columnsHeader, appliedFilters)
          .then(data => {
            this.setState({ csvDataForExport: data.csvData }, done())
          })
          .catch(() => {
            done(false)
          })
      } else {
        // cancel export
        done(false)
      }
    }

    const eventObj = {
      eventName: 'userExportToCsv',
      key: cardInfo?.data?.card_attribute?.dataset_id,
      eventData: {
        cardId,
      },
    }
    this.sendFireflyEvent(true, eventObj)
  }

  /**
   * converts nested hashmap into flat array of objects
   * @returns array of data to export & column headers info
   */
  flattenTreeTableCsvData = () => {
    const { treeTableCsvData } = this.props
    const data = []
    const headerRow = {}
    const recurse = (node, parentData) => {
      for (const key of Object.keys(node)) {
        const _rowObj = cloneDeep(node[key])
        // for child nodes keep track of parent dimension info & append to row obj
        const _parentData = { ...parentData }
        const rowObj = { ..._rowObj, ..._parentData }
        headerRow[rowObj.refId] = rowObj.columnDisplayName
        _parentData[rowObj.refId] = rowObj[rowObj.refId]
        delete rowObj.children
        delete rowObj.columnDisplayName
        delete rowObj.refId
        data.push(rowObj)
        if (
          node[key] &&
          node[key].children &&
          Object.keys(node[key].children) &&
          Object.keys(node[key].children).length
        ) {
          recurse(node[key].children, _parentData)
        }
      }
    }
    recurse(treeTableCsvData, {})
    return { headerRow, data }
  }

  handleToggleMobileFilterMenu = () => {
    this.setState({
      mobileFilterDialogState: !this.state.mobileFilterDialogState,
    })
  }

  handleToggleMobileMoreMenu = () => {
    this.setState({
      mobileMoreMenuActive: !this.state.mobileMoreMenuActive,
    })
  }

  handleMobileMoreButtonClick = () => {
    const eventObj = {
      eventName: 'mobileMoreButtonClick',
    }

    this.sendFireflyEvent(false, eventObj)
    this.handleToggleMobileMoreMenu()
  }

  triggerPrint = redirectToLandscape => {
    const { trackEvent } = this.props

    this.setState({ openActionsMenu: null }, () => {
      if (redirectToLandscape) {
        const { routeProps } = this.props
        const urlState = `${routeProps.location.search}${routeProps.location.hash}`

        routeProps.history.push(`${routeProps.location.pathname}/print/landscape${urlState}`)
        setTimeout(() => {
          window.print()
        }, 1000)
      } else {
        window.print()
      }
    })

    trackEvent({
      event: {
        type: 'cardviewer-print-clicked',
      },
    })
  }

  handleTimeChange = (selectedTime, isAnchorDate, isSecondaryTimeChanged) => {
    const { routeProps, updateSelectedTimePeriod } = this.props
    const isCalendarChange = Object.keys(selectedTime).length === 1 && selectedTime.calendar_type
    const isGranularityChange = Object.keys(selectedTime).length === 1 && selectedTime.granularity

    if (isSecondaryTimeChanged) {
      updateSelectedTimePeriod(selectedTime, true, this.handleOnApplyTimePeriod)
    } else {
      // Calendar change updates the route itself in ViewerTimePeriod
      if (isCalendarChange) {
        return
      }
      const urlState = `${routeProps.location.search}${routeProps.location.hash}`
      let decodedUrlState

      try {
        decodedUrlState = urlon.parse(urlState.slice(1, urlState.length))
        decodedUrlState.timePeriod = isGranularityChange
          ? { ...decodedUrlState.timePeriod, ...selectedTime }
          : { granularity: decodedUrlState.timePeriod?.granularity, ...selectedTime }

        if (!decodedUrlState.timePeriod.granularity) {
          delete decodedUrlState.timePeriod.granularity
        }

        updateSelectedTimePeriod(selectedTime, false, this.handleOnApplyTimePeriod)
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err, 'caught error while parsing timeperiod in handleTimeChange in Cardviewer.js')
      }
    }

    this.setState({
      mobileAnchorEl: null,
      mobileMoreMenuActive: false,
    })
  }

  handleOnApplyTimePeriod = () => {
    const {
      cardInfo,
      routeProps,
      selectedTimePeriod,
      cardRender,
      cardId,
      drill,
      appliedFilters,
      clearSelectedTimePeriod,
    } = this.props
    const selectedPrimaryTimePeriod =
      cloneDeep(selectedTimePeriod?.timePeriod) || cloneDeep(cardInfo?.data?.card_query_attribute?.time_period)
    const urlState = `${routeProps.location.search}${routeProps.location.hash}`
    let decodedUrlState

    if (selectedTimePeriod && !selectedTimePeriod.timePeriod && selectedTimePeriod.secondaryTimePeriods) {
      const payload = {
        drill,
        filters: appliedFilters,
        time_period: this.getTimePeriodViewer(),

        secondary_time_periods: cloneDeep(selectedTimePeriod.secondaryTimePeriods),
      }
      clearSelectedTimePeriod()
      cardRender({
        card: cardId,
        post: payload,
      })
    } else {
      try {
        decodedUrlState = urlon.parse(urlState.slice(1, urlState.length))
        decodedUrlState.timePeriod = { ...selectedPrimaryTimePeriod }

        if (!decodedUrlState.timePeriod.granularity) {
          delete decodedUrlState.timePeriod.granularity
        }

        if (decodedUrlState.timePeriod.intervals) {
          delete decodedUrlState.timePeriod.interval
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err, 'caught error while parsing timeperiod in handleTimeChange in Cardviewer.js')
      }
      routeProps.history.push(`${routeProps.match.url}${`?${decodedUrlState ? urlon.stringify(decodedUrlState) : ''}`}`)
    }
  }

  renderCardView(data) {
    const {
      print,
      isMobile,
      thumbnail,
      pagination,
      setDrill,
      drill,
      cardSize,
      isHomePage,
      setColumnSwap,
      drillthrough,
      activeDrillthroughIndex,
      topLevelId,
      cardRenderFilters,
      routeProps,
      updateCardWithoutRerender,
      displayServiceErrorMessage,
      chart,
      animationsDisabled,
      appliedFilters,
    } = this.props

    const isThumbnail = thumbnail || false
    const { lockColList, compareColList, selectedColList, drillPathColList, page, pageSize, enableAmChartv5 } =
      this.state
    const queryResults = data.query_results
    const vizType = data.card_config.card_attribute.viz_type.toLowerCase()
    const displayType =
      vizType?.toLowerCase() === 'text' ? vizType : queryResults && queryResults.length > 0 ? vizType : 'error'

    if (displayType?.toLowerCase() === 'text') {
      const backgroundColor = data.card_config.card_attribute.style?.textCardBackgroundColor || 'inherit'
      return (
        <div className="chart-height scroll-auto" style={{ backgroundColor }}>
          <Gfv type="text" className="cardviewer-barviz" renderCard={data} cardInfo={data} print={this.props.print} />
        </div>
      )
    } else if (displayType === 'pivot') {
      return (
        <>
          <Grid container className="cardGridHeight">
            <Grid item xs={12} className={classnames('cardGridHeight', 'cardviewer-table-container', { isMobile })}>
              <div
                className={
                  isThumbnail
                    ? 'thumbnail-table-container greenFieldNoPrint table-header-fixed'
                    : 'table-container greenFieldNoPrint'
                }
              >
                <Gfv
                  type="pivot"
                  className="cardviewer-tableviz"
                  renderCard={data}
                  pagination={pagination}
                  setDrill={setDrill}
                  prepCsvData={this.prepCsvData}
                  drill={drill}
                  print={print}
                  cardSize={cardSize}
                  isHomePage={isHomePage}
                  isMobile={isMobile}
                  cardRenderFilters={cardRenderFilters}
                  drillthrough={drillthrough}
                  activeDrillthroughIndex={activeDrillthroughIndex}
                  topLevelId={topLevelId}
                  onHandleSetAgGridApi={this.handleSetAgGridApi}
                />
              </div>
              <div
                className={
                  isThumbnail
                    ? 'thumbnail-table-container printable-table-container'
                    : 'table-container printable-table-container printable-aggrid-container'
                }
              >
                <Gfv
                  type="pivot"
                  className="cardviewer-tableviz"
                  renderCard={data}
                  pagination={false}
                  setDrill={setDrill}
                  prepCsvData={this.prepCsvData}
                  drill={drill}
                  print={print}
                  printView
                  cardSize={cardSize}
                  isHomePage={isHomePage}
                  isMobile={isMobile}
                />
              </div>
            </Grid>
          </Grid>
        </>
      )
    } else if (displayType === 'bar') {
      return (
        <>
          <Grid container className="cardGridHeight">
            <Grid item xs={12} className="cardGridHeight">
              <div className="chart-height">
                <Gfv
                  type="bar"
                  className="cardviewer-barviz"
                  renderCard={data}
                  cardInfo={data}
                  setDrill={setDrill}
                  drill={drill}
                  cardSize={cardSize}
                  isHomePage={isHomePage}
                  drillthrough={drillthrough}
                  activeDrillthroughIndex={activeDrillthroughIndex}
                  topLevelId={topLevelId}
                  cardRenderFilters={cardRenderFilters}
                  isMobile={isMobile}
                  isThumbnail={isThumbnail}
                  isChart={chart || animationsDisabled}
                  enableAmChartv5={enableAmChartv5}
                />
              </div>
            </Grid>
          </Grid>
        </>
      )
    } else if (displayType === 'table') {
      return (
        <>
          <Grid container className="cardGridHeight">
            <Grid item xs={12} className={classnames('cardGridHeight', 'cardviewer-table-container', { isMobile })}>
              <div
                className={
                  isThumbnail
                    ? 'thumbnail-table-container greenFieldNoPrint table-header-fixed'
                    : 'table-container greenFieldNoPrint'
                }
              >
                <Gfv
                  type="table"
                  className="cardviewer-tableviz"
                  renderCard={data}
                  pagination={this.props.chart ? false : pagination}
                  setDrill={setDrill}
                  setColumnSwap={setColumnSwap}
                  drill={drill}
                  print={print}
                  cardSize={cardSize}
                  isHomePage={isHomePage}
                  prepCsvData={this.prepCsvData}
                  isMobile={isMobile}
                  lockColumn={lockColList}
                  compareColumn={compareColList}
                  selectedColList={selectedColList}
                  drillthrough={drillthrough}
                  activeDrillthroughIndex={activeDrillthroughIndex}
                  topLevelId={topLevelId}
                  cardRenderFilters={cardRenderFilters}
                  drillPathColumns={drillPathColList}
                  isThumbnail={isThumbnail}
                  routeProps={routeProps}
                  pageChange={page => this.setState({ page })}
                  pageSizeChange={(pageSize, page) => {
                    return this.setState({
                      pageSize: this.props.chart ? 25 : pageSize,
                      page,
                    })
                  }}
                />
              </div>
              <div
                className={
                  isThumbnail
                    ? 'thumbnail-table-container printable-table-container'
                    : 'table-container printable-table-container'
                }
              >
                <Gfv
                  type="table"
                  className="cardviewer-tableviz"
                  renderCard={data}
                  pagination={false}
                  page={page}
                  pageSize={pageSize}
                  setDrill={setDrill}
                  setColumnSwap={setColumnSwap}
                  drill={drill}
                  print
                  cardSize={cardSize}
                  isHomePage={isHomePage}
                  prepCsvData={this.prepCsvData}
                  isMobile={isMobile}
                  lockColumn={lockColList}
                  compareColumn={compareColList}
                  selectedColList={selectedColList}
                  drillPathColumns={drillPathColList}
                  isThumbnail={isThumbnail}
                />
              </div>
            </Grid>
          </Grid>
        </>
      )
    } else if (displayType === 'table_beta') {
      return (
        <>
          <Grid container className="cardGridHeight">
            <Grid item xs={12} className={classnames('cardGridHeight', 'cardviewer-table-container', { isMobile })}>
              <div
                className={
                  isThumbnail
                    ? 'thumbnail-table-container greenFieldNoPrint table-header-fixed'
                    : 'table-container greenFieldNoPrint'
                }
              >
                <Gfv
                  type="table_beta"
                  className="cardviewer-tableviz"
                  renderCard={data}
                  setDrill={setDrill}
                  setColumnSwap={setColumnSwap}
                  drill={drill}
                  print={print}
                  cardSize={cardSize}
                  isHomePage={isHomePage}
                  isMobile={isMobile}
                  lockColumn={lockColList}
                  compareColumn={compareColList}
                  selectedColList={selectedColList}
                  drillthrough={drillthrough}
                  activeDrillthroughIndex={activeDrillthroughIndex}
                  topLevelId={topLevelId}
                  cardRenderFilters={cardRenderFilters}
                  drillPathColumns={drillPathColList}
                  isThumbnail={isThumbnail}
                  routeProps={routeProps}
                  onHandleSetAgGridApi={this.handleSetAgGridApi}
                />
              </div>
              <div
                className={
                  isThumbnail
                    ? 'thumbnail-table-container printable-table-container'
                    : 'table-container printable-table-container printable-aggrid-container'
                }
              >
                <Gfv
                  type="table_beta"
                  className="cardviewer-tableviz"
                  renderCard={data}
                  setDrill={setDrill}
                  setColumnSwap={setColumnSwap}
                  drill={drill}
                  print={print}
                  cardSize={cardSize}
                  isHomePage={isHomePage}
                  prepCsvData={this.prepCsvData}
                  isMobile={isMobile}
                  lockColumn={lockColList}
                  compareColumn={compareColList}
                  selectedColList={selectedColList}
                  drillthrough={drillthrough}
                  activeDrillthroughIndex={activeDrillthroughIndex}
                  topLevelId={topLevelId}
                  cardRenderFilters={cardRenderFilters}
                  drillPathColumns={drillPathColList}
                  isThumbnail={isThumbnail}
                  routeProps={routeProps}
                  printView
                />
              </div>
            </Grid>
          </Grid>
        </>
      )
    } else if (displayType === 'line' || displayType === 'area_chart' || displayType === 'sparkline') {
      return (
        <Grid container className="cardGridHeight">
          <Grid item xs={12} className="cardGridHeight">
            <div className="chart-height">
              <Gfv
                type={displayType}
                className="cardviewer-barviz"
                renderCard={data}
                cardInfo={data}
                setDrill={setDrill}
                drill={drill}
                print={print}
                cardSize={cardSize}
                isHomePage={isHomePage}
                drillthrough={drillthrough}
                activeDrillthroughIndex={activeDrillthroughIndex}
                topLevelId={topLevelId}
                cardRenderFilters={cardRenderFilters}
                isMobile={isMobile}
                isThumbnail={isThumbnail}
                isChart={chart || animationsDisabled}
                enableAmChartv5={enableAmChartv5}
              />
            </div>
          </Grid>
        </Grid>
      )
    } else if (displayType === 'bar and line') {
      return (
        <Grid container className="cardGridHeight">
          <Grid item xs={12} className="cardGridHeight">
            <div className="chart-height">
              <Gfv
                type="bar and line"
                className="cardviewer-barviz"
                renderCard={data}
                cardInfo={data}
                setDrill={setDrill}
                drill={drill}
                print={print}
                cardSize={cardSize}
                isHomePage={isHomePage}
                drillthrough={drillthrough}
                activeDrillthroughIndex={activeDrillthroughIndex}
                topLevelId={topLevelId}
                cardRenderFilters={cardRenderFilters}
                isMobile={isMobile}
                isThumbnail={isThumbnail}
                isChart={chart || animationsDisabled}
                enableAmChartv5={enableAmChartv5}
              />
            </div>
          </Grid>
        </Grid>
      )
    } else if (displayType === 'pareto') {
      return (
        <Grid container className="cardGridHeight">
          <Grid item xs={12} className="cardGridHeight">
            <div className="chart-height">
              <Gfv
                type="pareto"
                className="cardviewer-barviz"
                renderCard={data}
                cardInfo={data}
                setDrill={setDrill}
                drill={drill}
                print={print}
                cardSize={cardSize}
                isHomePage={isHomePage}
                drillthrough={drillthrough}
                activeDrillthroughIndex={activeDrillthroughIndex}
                topLevelId={topLevelId}
                cardRenderFilters={cardRenderFilters}
                isMobile={isMobile}
                isThumbnail={isThumbnail}
                isChart={chart || animationsDisabled}
                enableAmChartv5={enableAmChartv5}
              />
            </div>
          </Grid>
        </Grid>
      )
    } else if (STACK_BAR_VIZ_TYPES.includes(displayType)) {
      return (
        <Grid container className="cardGridHeight">
          <Grid item xs={12} className="cardGridHeight">
            <div className="chart-height">
              <Gfv
                type={displayType}
                className="cardviewer-barviz"
                renderCard={data}
                cardInfo={data}
                setDrill={setDrill}
                drill={drill}
                print={print}
                cardSize={cardSize}
                isHomePage={isHomePage}
                drillthrough={drillthrough}
                activeDrillthroughIndex={activeDrillthroughIndex}
                topLevelId={topLevelId}
                cardRenderFilters={cardRenderFilters}
                isMobile={isMobile}
                isThumbnail={isThumbnail}
                isChart={chart || animationsDisabled}
                enableAmChartv5={enableAmChartv5}
              />
            </div>
          </Grid>
        </Grid>
      )
    } else if (['pie_chart', 'word_cloud', 'donut_chart'].includes(displayType)) {
      return (
        <Grid container className="cardGridHeight">
          <Grid item xs={12} className="cardGridHeight">
            <div className="chart-height">
              <Gfv
                type={displayType}
                className="cardviewer-barviz"
                renderCard={data}
                cardInfo={data}
                print={print}
                cardSize={cardSize}
                isHomePage={isHomePage}
                cardRenderFilters={cardRenderFilters}
                isMobile={isMobile}
                isThumbnail={isThumbnail}
                setDrill={setDrill}
                drill={drill}
                drillthrough={drillthrough}
                activeDrillthroughIndex={activeDrillthroughIndex}
                topLevelId={topLevelId}
                isChart={chart || animationsDisabled}
                enableAmChartv5={enableAmChartv5}
              />
            </div>
          </Grid>
        </Grid>
      )
    } else if (displayType === 'heatmap' || displayType === 'scatter_plot' || displayType === 'bubble_chart') {
      return (
        <Grid container className="cardGridHeight">
          <Grid item xs={12} className="cardGridHeight">
            <div className="chart-height">
              <Gfv
                type={displayType}
                className="cardviewer-barviz"
                renderCard={data}
                cardInfo={data}
                setDrill={setDrill}
                drill={drill}
                print={print}
                cardSize={cardSize}
                isHomePage={isHomePage}
                drillthrough={drillthrough}
                activeDrillthroughIndex={activeDrillthroughIndex}
                topLevelId={topLevelId}
                cardRenderFilters={cardRenderFilters}
                isMobile={isMobile}
                isThumbnail={isThumbnail}
                isChart={chart || animationsDisabled}
                enableAmChartv5={enableAmChartv5}
              />
            </div>
          </Grid>
        </Grid>
      )
    } else if (['map_pinned', 'map_filled'].includes(displayType)) {
      return (
        <Grid container className="cardGridHeight">
          <Grid item xs={12} className="cardGridHeight">
            <div className="chart-height">
              <MapsContainer
                type={displayType}
                className="cardviewer-barviz"
                renderCard={data}
                cardInfo={data}
                setDrill={setDrill}
                drill={drill}
                print={print}
                cardSize={cardSize}
                isHomePage={isHomePage}
                drillthrough={drillthrough}
                activeDrillthroughIndex={activeDrillthroughIndex}
                topLevelId={topLevelId}
                cardRenderFilters={cardRenderFilters}
                isMobile={isMobile}
                isThumbnail={isThumbnail}
              />
            </div>
          </Grid>
        </Grid>
      )
    } else if (displayType === 'tree_table') {
      // Fixes GREEN-12464 issue where hiding a time period viewer is not honoring card filters in a dashboard, set time period to empty object in such cases
      const timePeriod =
        this.props.timePeriod.hide_time_period_viewer && Object.keys(this.props.timePeriod).length === 1
          ? {}
          : this.props.timePeriod
      return (
        <Grid container className="cardGridHeight">
          <Grid item xs={12} className={classnames('cardGridHeight', 'cardviewer-table-container', { isMobile })}>
            <div className={isThumbnail ? 'thumbnail-table-container  table-header-fixed' : 'table-container '}>
              <TreetableContainer
                type={displayType}
                className="cardviewer-barviz"
                renderCard={data}
                cardInfo={data}
                setDrill={setDrill}
                drill={drill}
                print={print}
                cardSize={cardSize}
                isHomePage={isHomePage}
                drillthrough={drillthrough}
                activeDrillthroughIndex={activeDrillthroughIndex}
                topLevelId={topLevelId}
                cardRenderFilters={cardRenderFilters}
                appliedFilters={appliedFilters}
                isMobile={isMobile}
                timePeriod={timePeriod}
                isThumbnail={isThumbnail}
                updateCardWithoutRerender={updateCardWithoutRerender}
                displayServiceErrorMessage={displayServiceErrorMessage}
              />
            </div>
          </Grid>
        </Grid>
      )
    } else if (displayType === 'big_number') {
      return (
        <Grid container className={!this.props.chart ? '' : 'bigNumberCardGridHeight'}>
          <Grid item xs={12} className={!this.props.chart ? '' : 'bigNumberCardGridHeight'}>
            <div className="chart-height">
              <Gfv
                type={displayType}
                className="cardviewer-barviz"
                renderCard={data}
                cardInfo={data}
                print={print}
                cardSize={cardSize}
                isHomePage={isHomePage}
                drillthrough={drillthrough}
                setDrill={setDrill}
                activeDrillthroughIndex={activeDrillthroughIndex}
                topLevelId={topLevelId}
                cardRenderFilters={cardRenderFilters}
                isMobile={isMobile}
                isThumbnail={isThumbnail}
              />
            </div>
          </Grid>
        </Grid>
      )
    } else if (displayType === 'bullet') {
      return (
        <Grid container className="cardGridHeight">
          <Grid item xs={12} className={classnames('cardGridHeight', 'cardviewer-bullet-container', { isMobile })}>
            <div
              className={isThumbnail ? 'thumbnail-table-container  table-header-fixed' : 'bullet-container '}
              style={{ height: '100%' }}
            >
              <Gfv
                type={displayType}
                className="cardviewer-bullet"
                renderCard={data}
                cardInfo={data}
                print={print}
                cardSize={cardSize}
                isHomePage={isHomePage}
                drillthrough={drillthrough}
                setDrill={setDrill}
                activeDrillthroughIndex={activeDrillthroughIndex}
                topLevelId={topLevelId}
                cardRenderFilters={cardRenderFilters}
                isMobile={isMobile}
                isThumbnail={isThumbnail}
                isChart={chart || animationsDisabled}
                enableAmChartv5={enableAmChartv5}
              />
            </div>
          </Grid>
        </Grid>
      )
    } else if (displayType === 'box_plot') {
      return (
        <Grid container className="cardGridHeight">
          <Grid item xs={12} className={classnames('cardGridHeight', { isMobile })}>
            <div
              className={isThumbnail ? 'thumbnail-table-container table-header-fixed' : ''}
              style={{ height: '100%' }}
            >
              <Gfv
                type={displayType}
                renderCard={data}
                cardInfo={data}
                print={print}
                cardSize={cardSize}
                isHomePage={isHomePage}
                drillthrough={drillthrough}
                setDrill={setDrill}
                activeDrillthroughIndex={activeDrillthroughIndex}
                topLevelId={topLevelId}
                cardRenderFilters={cardRenderFilters}
                isMobile={isMobile}
                isThumbnail={isThumbnail}
                isChart={chart || animationsDisabled}
                enableAmChartv5={enableAmChartv5}
              />
            </div>
          </Grid>
        </Grid>
      )
    } else if (displayType === 'treemap') {
      return (
        <Grid container className="cardGridHeight">
          <Grid item xs={12} className="cardGridHeight">
            <div className="chart-height">
              <Gfv
                type={displayType}
                className="cardviewer-barviz"
                renderCard={data}
                cardInfo={data}
                setDrill={setDrill}
                drill={drill}
                print={print}
                cardSize={cardSize}
                isHomePage={isHomePage}
                drillthrough={drillthrough}
                activeDrillthroughIndex={activeDrillthroughIndex}
                topLevelId={topLevelId}
                cardRenderFilters={cardRenderFilters}
                isMobile={isMobile}
                isThumbnail={isThumbnail}
                isChart={chart || animationsDisabled}
              />
            </div>
          </Grid>
        </Grid>
      )
    } else if (displayType === 'dynamic_text') {
      return (
        <Grid container className="cardGridHeight">
          <Grid item xs={12} className="cardGridHeight">
            <div className="chart-height">
              <Gfv
                type={displayType}
                className="cardviewer-barviz"
                renderCard={data}
                cardInfo={data}
                setDrill={setDrill}
                drill={drill}
                print={print}
                cardSize={cardSize}
                isHomePage={isHomePage}
                drillthrough={drillthrough}
                activeDrillthroughIndex={activeDrillthroughIndex}
                topLevelId={topLevelId}
                cardRenderFilters={cardRenderFilters}
                isMobile={isMobile}
                isThumbnail={isThumbnail}
              />
            </div>
          </Grid>
        </Grid>
      )
    } else if (displayType === 'gauge_chart') {
      return (
        <Grid container className="bigNumberCardGridHeight">
          <Grid item xs={12} className="bigNumberCardGridHeight">
            <div className="chart-height">
              <Gfv
                type={displayType}
                className="cardviewer-barviz"
                renderCard={data}
                cardInfo={data}
                print={print}
                cardSize={cardSize}
                isHomePage={isHomePage}
                drillthrough={drillthrough}
                setDrill={setDrill}
                activeDrillthroughIndex={activeDrillthroughIndex}
                topLevelId={topLevelId}
                cardRenderFilters={cardRenderFilters}
                isMobile={isMobile}
                isThumbnail={isThumbnail}
                isChart={chart || animationsDisabled}
                enableAmChartv5={enableAmChartv5}
              />
            </div>
          </Grid>
        </Grid>
      )
    } else if (displayType === 'error') {
      return (
        <div>
          <Typography className="no-data-message" variant="h3" data-cy="no-data-message">
            {this.state.errorMessage}
          </Typography>
        </div>
      )
    }
  }

  getUpdatedFilterViewer(viewerFilters, columnArray = []) {
    const viewerFilterArray = cloneDeep(viewerFilters) || []

    return viewerFilterArray.map(filter => {
      if (filter.ref_id) {
        const filterColIndex = columnArray.findIndex(col => col.ref_id === filter.ref_id)
        if (filterColIndex > -1) {
          filter.dimension = columnArray[filterColIndex].field_name
        }
      }
      return filter
    })
  }

  cardFilter(viewerFilters, cardId, columnArray = [], datasetId, contextFilters) {
    const { openFilters, selectedFilterId } = this.state
    const { routeProps, cardRenderFilters = [], timePeriod, renderCard, deviceType } = this.props
    return (
      <>
        {cardRenderFilters.length ? (
          <Badge className="hint-badge" badgeContent={cardRenderFilters.length}>
            <Button
              id="card-view-filterby"
              onClick={this.handleFilterClick}
              startIcon={<FilterList />}
              aria-label="Add Filter"
              color="secondary"
            >
              Filter By
            </Button>
          </Badge>
        ) : (
          <Button
            id="card-view-filterby"
            onClick={this.handleFilterClick}
            startIcon={<FilterList />}
            aria-label="Add Filter"
            color="secondary"
          >
            Filter By
          </Button>
        )}
        <span className="vertical-bar">|</span>
        <GreenfieldFilterViewer
          routeProps={routeProps}
          contextFilters={contextFilters}
          open={openFilters}
          viewerFilters={this.getUpdatedFilterViewer(viewerFilters, columnArray)}
          type="card"
          id={Number(cardId)}
          onDismissPopover={this.handleDismissPopover}
          selectedFilterId={selectedFilterId}
          isMobile={this.props.isMobile}
          existingTimePeriod={timePeriod}
          drill={this.props.drill}
          datasetId={datasetId}
          columnArray={columnArray}
          cardInfo={renderCard[cardId]}
          deviceType={deviceType}
          closeMobilePopover={this.closeMobilePopOver}
        />
      </>
    )
  }

  handleSetOpenSaveAs = () => {
    this.openSaveAsDialog(true, null)
  }

  saveAsSuccess = details => {
    const cardDetails = cloneDeep(this.props.cardInfo.data)

    const cardInfo = {
      card_attribute: cardDetails.card_attribute,
      card_query_attribute: cardDetails.card_query_attribute,
      ...(cardDetails.card_attribute?.viz_type === 'pivot' && { application_data: cardDetails.application_data }),
    }

    delete cardInfo.card_attribute.owners_access
    delete cardInfo.card_attribute.viewers_access
    delete cardInfo.card_attribute.edit_enabled

    // Set business tags to empty if it is Save as
    cardInfo.card_attribute.business_use = []
    cardInfo.card_attribute.card_title = details.title
    cardInfo.card_attribute.card_description = details.description
    cardInfo.card_attribute.support_details = details.support_details

    const ownersType = groupBy(details.selectedOwners, 'type')
    cardInfo.card_attribute.owners = ownersType.user ? ownersType.user.map(owner => owner._id) : []
    cardInfo.card_attribute.owner_groups = ownersType.group ? ownersType.group.map(owner => owner._id) : []

    this.props.saveCardFromViewer(cardInfo)
    this.handleToggleMobileMoreMenu()
  }

  cancelSaveAs = () => {
    this.openSaveAsDialog(false, null)
  }

  openSaveAsDialog(isOpen, reloadNewCard) {
    this.setState(
      {
        openSaveAs: isOpen,
      },
      () => {
        const { cardId } = this.props
        const eventObj = {
          eventName: 'greenfieldSaveAsClick',
          key: cardId,
          eventData: {
            saveAsType: 'card',
          },
        }

        this.sendFireflyEvent(true, eventObj)
        reloadNewCard && this.props.reLoadNewCard(this.props.saveCardFromViewerStatus.data._id)
      }
    )
  }

  renderSaveAsCard() {
    const { openSaveAs } = this.state
    const { saveCardFromViewerStatus, cardId } = this.props

    return (
      <>
        {saveCardFromViewerStatus && saveCardFromViewerStatus.status === 'requested' && (
          <Spinner size="large" layout="selfCentering" style={{ marginTop: '50px' }} />
        )}
        <SaveAsContainer
          openSaveAs={openSaveAs}
          saveAsSuccess={this.saveAsSuccess}
          cancelSaveAs={this.cancelSaveAs}
          titleLabel="Card Name"
          ownerLabel="Owner(s)"
          descriptionLabel="Card Description"
          saveAsType="card"
          itemId={cardId}
          apiCallRequestStatus={saveCardFromViewerStatus}
        />
      </>
    )
  }

  hideViewerTimePeriod() {
    const { cardInfo } = this.props

    return cardInfo && cardInfo.status >= 200 && cardInfo.status < 300 && cardInfo.data && cardInfo.data
      ? !cardInfo.data.card_query_attribute || cardInfo.data.card_query_attribute.time_period.hide_time_period_viewer
      : false
  }

  hideViewerSecondaryTimePeriod() {
    const { cardInfo, selectedDatasetObj } = this.props
    const datasetId = cardInfo.data && cardInfo.data?.card_attribute?.dataset_id
    const secondaryTPColumn = getSecondaryTimePeriodColumn(selectedDatasetObj && selectedDatasetObj[datasetId]?.data)
    const secondaryTPColumnName = secondaryTPColumn?.field_name

    return cardInfo?.data
      ? !cardInfo.data.card_query_attribute ||
          (secondaryTPColumnName &&
            Boolean(
              cardInfo.data.card_query_attribute.secondary_time_periods[secondaryTPColumnName]?.hide_time_period_viewer
            ))
      : false
  }

  renderTimePeriod() {
    const {
      routeProps,
      renderCard,
      thumbnail,
      cardRender,
      cardId,
      isMobile,
      deviceType,
      isTimePeriodDrillThrough,
      selectedTimePeriod,
      cardInfo,
      secondaryTimePeriod,
    } = this.props
    return (
      Boolean(!thumbnail) &&
      Boolean(!isMobile) && (
        <ViewerTimePeriod
          routeProps={routeProps}
          cardInfo={
            renderCard[cardId]?.status === 'failed'
              ? { data: { card_config: cardInfo?.data || {} } }
              : renderCard[cardId]
          }
          cardRender={cardRender}
          isDashboard={false}
          isMobile={isMobile}
          deviceType={deviceType}
          onTimePeriodChange={this.handleTimeChange}
          onApplyTimePeriod={this.handleOnApplyTimePeriod}
          timePeriod={this.getTimePeriodViewer()}
          isTimePeriodDrillThrough={isTimePeriodDrillThrough}
          hideViewerTimePeriod={this.hideViewerTimePeriod()}
          updateCardWithoutRerender={this.props.updateCardWithoutRerender}
          hideViewerSecondaryTimePeriod={this.hideViewerSecondaryTimePeriod()}
          updateCardInfoStatusWithoutRender={this.props.updateCardInfoStatusWithoutRender}
          selectedTimePeriod={selectedTimePeriod}
          secondaryTime={secondaryTimePeriod}
        />
      )
    )
  }

  clickLockAndCompare = () => {
    const { cardId, renderCard } = this.props
    const { selectedColList } = this.state
    let defaultSelectedColList = cloneDeep(selectedColList)

    if (isEmpty(selectedColList)) {
      const card = renderCard[cardId]
      const columns = card && card.data ? this.getColumns(card.data.card_config) : []
      defaultSelectedColList = columns.map(col => {
        return { name: col.name, display_name: col.display_name, value: 'compare', ref_id: col.ref_id }
      })
    }

    this.setState({
      openLockAndCompareWin: true,
      selectedColList: defaultSelectedColList,
    })
  }

  toggleLockAndComparePopover = (lockColumn, compareColumn, selected) => {
    const { mobileLockAndCompareState, mobileMoreMenuActive } = this.state
    const { isMobile, cardInfo } = this.props

    // Lock and compare on drill column
    const drillMaps =
      cardInfo && cardInfo.data && cardInfo.data.card_query_attribute && cardInfo.data.card_query_attribute.drillmaps
        ? cardInfo.data.card_query_attribute.drillmaps
        : []
    const drillPathColList = {
      lockCols: [],
      compareCols: [],
    }
    if (drillMaps && drillMaps.length) {
      drillMaps.forEach(eachDrillCol => {
        if (eachDrillCol.drillmap_name && eachDrillCol.drillmap_path) {
          eachDrillCol.drillmap_path.forEach(eachDrillPath => {
            if (eachDrillPath.column_display_name) {
              if (getColIdx(lockColumn, 'name', eachDrillCol.drillmap_name) > -1) {
                drillPathColList.lockCols.push(eachDrillPath)
              } else if (getColIdx(compareColumn, 'name', eachDrillCol.drillmap_name) > -1) {
                drillPathColList.compareCols.push(eachDrillPath)
              }
            }
          })
        }
      })
    }

    this.setState({
      lockColList: lockColumn,
      compareColList: compareColumn,
      selectedColList: selected,
      drillPathColList,
      openLockAndCompareWin: false,
      mobileLockAndCompareState: isMobile ? false : mobileLockAndCompareState,
      mobileMoreMenuActive: isMobile ? false : mobileMoreMenuActive,
    })
  }

  getColumns = (cardData, type) => {
    let columns = []
    if (cardData && cardData.card_query_attribute && cardData.card_query_attribute.columns) {
      const colsData = cardData.card_query_attribute.columns
      columns = colsData.map(col => {
        if (type) {
          return col[type]
        } else {
          const name = col.field_name
          const displayName = col.column_display_name

          return {
            name,
            display_name: displayName,
            ref_id: col.ref_id,
          }
        }
      })
    }

    return columns
  }

  getRenderColumns = (cardId, renderCard, allColumns, drill) => {
    // Send renderColumns to LockAndCompare when column has drill.
    const isColDrill = (drill && drill.length) || false
    let renderColumns = allColumns
    if (
      isColDrill &&
      cardId &&
      renderCard &&
      renderCard[cardId] &&
      renderCard[cardId].data &&
      renderCard[cardId].data.card_config &&
      renderCard[cardId].data.card_config.card_query_attribute &&
      renderCard[cardId].data.card_config.card_query_attribute.columns
    ) {
      renderColumns = this.getColumns(renderCard[cardId].data.card_config, 'column_display_name')
    }
    return renderColumns
  }

  handleKeyPressed = event => {
    if (event.which === 9) {
      this.handleShowSupportText(event)
    }
  }

  renderLockAndCompare() {
    const { thumbnail, cardInfo, drill, cardId, renderCard } = this.props
    const { openLockAndCompareWin, selectedColList } = this.state
    const card = renderCard[cardId]
    const columns = card && card.data ? this.getColumns(card.data.card_config) : []
    const defaultSelectedColList = isEmpty(selectedColList)
      ? columns.map(col => ({ name: col.name, display_name: col.display_name, value: 'compare', ref_id: col.ref_id }))
      : selectedColList

    let vizType
    if (card && card.status >= 200 && card.status < 300) {
      vizType = card.data.card_config.card_attribute.viz_type.toLowerCase()
    }
    return (
      Boolean(!thumbnail) &&
      vizType &&
      (vizType === 'table' || vizType === 'table_beta') && (
        <>
          <Tooltip title="Lock / Compare Columns" placement="top">
            <div>
              <IconButton
                className="lock-compare-icon"
                id="lock-compare-columns"
                aria-label="Lock / Compare Columns"
                onClick={event => {
                  this.clickLockAndCompare(event)
                }}
              />
            </div>
          </Tooltip>
          {openLockAndCompareWin && (
            <LockAndCompare
              open={openLockAndCompareWin}
              columns={columns}
              isAvatar
              cardInfo={cardInfo}
              selectedColList={defaultSelectedColList}
              drill={drill}
              renderColumns={this.getRenderColumns(cardId, renderCard, columns, drill)}
              submitLockAndCompare={this.toggleLockAndComparePopover}
            />
          )}
        </>
      )
    )
  }

  dateSubstr(dates) {
    return dates ? dates.substr(0, 10) : null
  }

  renderDateButtonLabel(timePeriod, buttonLabel, buttonText, showCustom, isButtonTextArray, beginDate, endDate) {
    const fromDate = beginDate?.toLowerCase() === LWR_CASE_TODAY ? beginDate : getFormatDate(beginDate)
    const toDate = endDate?.toLowerCase() === LWR_CASE_TODAY ? endDate : getFormatDate(endDate)

    return (
      <span>
        <DateRange />
        {timePeriod && timePeriod.anchor_date && <AnchorIcon />}
        <span className="time-period-label" data-cy="time-period-label">
          {beginDate
            ? `${buttonLabel} ${fromDate} to ${toDate}`
            : showCustom
            ? buttonText
            : isButtonTextArray
            ? MULTI_SELECT_CUSTOM_STR
            : buttonLabel}
        </span>
      </span>
    )
  }

  renderSelectedTimePeriod(isCardRender = false, renderLargeExport = false, handleTimeViewerClick, largeExportObj) {
    const { buttonText, beginDate, endDate, secondaryButtonText, secondaryBeginDate, secondaryEndDate } = this.state
    const { cardInfo, renderCard, cardId } = this.props
    const { timePeriod, secondaryTimePeriod } = this.props
    const tempButtonText = renderLargeExport ? largeExportObj.buttonText : buttonText
    const buttonLabel = getTimePeriodButtonLabel(tempButtonText, timePeriod)
    const isButtonTextArray = Array.isArray(buttonText) && timePeriod?.type === CUSTOM_STR.toLowerCase()
    const showCustom = Boolean(buttonLabel === CUSTOM_STR && buttonText?.length < 30)
    const renderedCardData = cardInfo && cardInfo.data && renderCard[cardId || cardInfo.data._id]?.data
    const secondaryTPColumnName =
      renderedCardData?.context &&
      renderedCardData.context.rendered_payload &&
      renderedCardData.context.rendered_payload.secondary_time_periods
        ? Object.keys(renderedCardData.context.rendered_payload.secondary_time_periods)[0]
        : ''

    const tempSecondaryButtonText = secondaryButtonText
    const secondaryButtonLabel =
      secondaryTPColumnName &&
      secondaryTimePeriod &&
      getTimePeriodButtonLabel(tempSecondaryButtonText, secondaryTimePeriod[secondaryTPColumnName])
    const isSecondaryButtonTextArray =
      Array.isArray(secondaryButtonText) &&
      secondaryTimePeriod &&
      secondaryTPColumnName &&
      secondaryTimePeriod[secondaryTPColumnName]?.type === CUSTOM_STR.toLowerCase()
    const showSecondaryCustom = Boolean(secondaryButtonLabel === CUSTOM_STR && secondaryButtonText?.length < 30)

    const tooltipContents = renderLargeExport ? (
      <div
        id="selected-time-period"
        tabIndex={0}
        role="button"
        className="time-period-section"
        onClick={() => handleTimeViewerClick(true)}
        onKeyPress={() => handleTimeViewerClick(true)}
      >
        {this.renderDateButtonLabel(
          timePeriod,
          buttonLabel,
          largeExportObj.buttonText,
          showCustom,
          isButtonTextArray,
          largeExportObj.beginDate,
          largeExportObj.endDate
        )}
      </div>
    ) : (
      <div
        id="selected-time-period"
        tabIndex={0}
        role="button"
        className={isCardRender ? 'time-period-section' : 'time-period-request'}
        onClick={event => {
          this.setState({ timePeriodLabelClicked: event.currentTarget })
        }}
        onKeyPress={event => {
          this.setState({ timePeriodLabelClicked: event.currentTarget })
        }}
      >
        {isCardRender &&
          this.hideViewerTimePeriod() &&
          !this.hideViewerSecondaryTimePeriod() &&
          secondaryTPColumnName &&
          secondaryTimePeriod &&
          this.renderDateButtonLabel(
            secondaryTimePeriod[secondaryTPColumnName],
            secondaryButtonLabel,
            secondaryButtonText,
            showSecondaryCustom,
            isSecondaryButtonTextArray,
            secondaryBeginDate,
            secondaryEndDate
          )}
        {isCardRender &&
          !this.hideViewerTimePeriod() &&
          this.renderDateButtonLabel(
            timePeriod,
            buttonLabel,
            buttonText,
            showCustom,
            isButtonTextArray,
            beginDate,
            endDate
          )}
      </div>
    )

    return (
      <Tooltip
        title={!showCustom && buttonLabel === CUSTOM_STR ? buttonText : isButtonTextArray ? buttonLabel : ''}
        placement="bottom"
      >
        {tooltipContents}
      </Tooltip>
    )
  }

  handleEditClick = cardInfo => {
    const { clearCardWithoutRerender, topLevelId, drillthrough } = this.props
    const id = cardInfo.data ? cardInfo.data._id : ''
    const drillIds =
      topLevelId && drillthrough && drillthrough.length
        ? drillthrough.map(drill => drill.card_id).concat(topLevelId)
        : []

    if (drillIds.length) {
      drillIds.forEach(drill => {
        clearCardWithoutRerender({ id: drill })
      })
    } else {
      clearCardWithoutRerender({ id })
    }
  }

  handleShowSupportText = e => {
    const { isShowHelp } = this.state
    const { target } = e

    this.setState({
      isShowHelp: isShowHelp ? false : target,
    })
  }

  redirectToFromTextCard = () => {
    // To redirect when user selected textcard using card link we have to redirect to textcard vice versa
    const { cardId, cardInfo, routeProps } = this.props
    const { _siteName } = routeProps.match.params
    if (
      cardInfo &&
      cardInfo.data &&
      cardInfo.data.card_attribute.viz_type?.toLowerCase() === 'text' &&
      routeProps.location &&
      routeProps.location.pathname.includes('/card')
    ) {
      const _cardId = cardId || routeProps.match.params._id
      return <Redirect to={_siteName ? `/site/${_siteName}/textcard/${_cardId}` : `/textcard/${_cardId}`} />
    } else if (
      cardInfo &&
      cardInfo.data &&
      cardInfo.data.card_attribute.viz_type?.toLowerCase() !== 'text' &&
      routeProps.location &&
      routeProps.location.pathname.includes('/textcard')
    ) {
      return <Redirect to={_siteName ? `/site/${_siteName}/card/${cardId}` : `/card/${cardId}`} />
    }
    return null
  }

  renderCardMetadata() {
    const {
      cardInfo,
      topLevelId,
      drillthrough,
      activeDrillthroughIndex,
      renderCard,
      setDrill,
      thumbnail,
      isMobile,
      cardId,
      drillCardMetaStatus,
      isEmbed,
      treeTableCsvData,
      selectedDatasetObj,
      routeProps,
      user,
    } = this.props

    const {
      expandLess,
      expandMore,
      expandHistory,
      expandConfigs,
      historyData,
      openActionsMenu,
      csvDataForExport,
      agGridApi,
      subscriptionOpen,
      printOpen,
      exportOpen,
      troubleShootOpen,
    } = this.state

    const card =
      !Number.isInteger(activeDrillthroughIndex) || activeDrillthroughIndex === -1
        ? cardInfo
        : drillCardMetaStatus[cardId]

    const breadCrumbClickAndDrill = options => {
      setDrill([], options)
    }
    const isRender =
      (renderCard[cardId] && renderCard[cardId].status && renderCard[cardId].status !== 'requested') || false
    const renderCardData = renderCard[cardId]
    const { _siteName } = routeProps.match.params

    if (card && card.status >= 200 && card.status < 300) {
      const cardConfig = card.data.card_config || card.data
      const cardAttribute = cardConfig.card_attribute
      const cardQueryAttribute = cardConfig.card_query_attribute
      const cardType = cardAttribute.viz_type
      const datasetId = cardAttribute.dataset_id
      const datasetObj = (selectedDatasetObj && selectedDatasetObj[datasetId]?.data) || {}
      const largeExportEnable = cardConfig?.card_query_attribute?.large_export_enabled // eslint-disable-line camelcase
      const renderData =
        renderCard[cardId] &&
        renderCard[cardId].status >= 200 &&
        renderCard[cardId].status < 300 &&
        renderCard[cardId].data
      const allFilters =
        renderData &&
        renderData.context &&
        renderData.context.render_details &&
        renderData.context.render_details.filters

      if (!thumbnail && !isMobile) {
        return (
          <>
            {!this.props.chart && (
              <AppBar
                position="static"
                style={{
                  zIndex: 100,
                }}
              >
                <Toolbar id="cardMetadataContainer">
                  <Grid container className="toolbar-align">
                    <Grid item xs={4}>
                      <Grid container alignItems="flex-start" direction="column">
                        <div className="card-title-fav-view-group">
                          <Grid container wrap="nowrap" alignItems="flex-start" direction="row">
                            <GreenfieldFavoriteButton
                              type="cards"
                              id={cardConfig._id}
                              isFavorite={cardAttribute.favorite}
                            />
                            <Typography
                              aria-label={cardAttribute.card_title}
                              variant="h2"
                              className="card-title"
                              style={{
                                color: cardAttribute.style?.headerFontColor || '#000',
                                fontFamily: cardAttribute.style?.headerFont || 'Roboto',
                                fontSize: `${cardAttribute.style?.headerFontSize}px` || '20px',
                              }}
                            >
                              {this.renderCardTitle(cardAttribute.card_title)}
                            </Typography>
                          </Grid>
                        </div>
                        <ViewerDescription
                          description={cardAttribute.card_description}
                          style={cardAttribute?.style || {}}
                        />
                        {expandMore && (
                          <Dialog open={!expandLess} className="moredetails-popover">
                            <DialogTitle>
                              {cardAttribute.card_title}
                              <GreenfieldFavoriteButton
                                className="fav-btn"
                                type="cards"
                                id={cardConfig._id}
                                isFavorite={cardAttribute.favorite}
                              />
                              <Typography variant="caption" component="span">
                                {cardAttribute.data_classification}
                              </Typography>
                            </DialogTitle>
                            <DialogContent>
                              <Typography variant="caption" component="p">
                                <b>Owner(s):</b>
                                {getOwnerInfoChip(cardAttribute.owners_access)}
                              </Typography>
                              <Typography variant="caption" component="p">
                                <b>Card Created: </b>
                                {moment(cardAttribute.create_date).format('MMMM Do YYYY, h:mm:ss a')}
                              </Typography>
                              <Typography variant="caption" component="p">
                                <b>Card Last Updated: </b>
                                {moment(cardAttribute.last_updated_date).format('MMMM Do YYYY, h:mm:ss a')}
                              </Typography>
                              {cardType && cardType.toLowerCase() !== 'text' && datasetObj && (
                                <>
                                  <Typography variant="caption" component="p">
                                    <b>Dataset: </b>
                                    <Link to={`/dataset/preview/${datasetObj._id}`}>
                                      {datasetObj.dataset_business_name || datasetObj.dataset_name}
                                    </Link>
                                  </Typography>
                                  {cardAttribute.dataset_last_load_date && (
                                    <Typography variant="caption" component="p">
                                      <b>Dataset Last Loaded: </b>
                                      {moment(cardAttribute.dataset_last_load_date).format('MMMM Do YYYY, h:mm:ss a')}
                                    </Typography>
                                  )}
                                </>
                              )}
                              {cardAttribute.business_use && Boolean(cardAttribute.business_use.length) && (
                                <Typography
                                  variant="caption"
                                  className="business-area min-margin-top-comp"
                                  component="p"
                                >
                                  <b>Business Area(s) </b>
                                  <span>
                                    {cardAttribute.business_use.map((item, key) => (
                                      <Chip
                                        data-cy={`business-area-tag-${key}`}
                                        key={key}
                                        label={item}
                                        variant="outlined"
                                        color="primary"
                                      />
                                    ))}
                                  </span>
                                </Typography>
                              )}
                              {cardAttribute.card_description && (
                                <div className="card-description min-margin-top-comp">
                                  <Typography variant="caption">
                                    <b>Description:</b> {cardAttribute.card_description}
                                  </Typography>
                                </div>
                              )}
                              <div className="details-dashboard-count">
                                <span>
                                  {cardConfig.dashboards_containing_card
                                    ? cardConfig.dashboards_containing_card.length
                                    : 0}
                                </span>
                                <div>Dashboards</div>
                              </div>
                            </DialogContent>
                            <footer>
                              <Button variant="text" color="secondary" id="view-more-ok-btn" onClick={this.handleClose}>
                                OK
                              </Button>
                            </footer>
                          </Dialog>
                        )}
                        {expandHistory && (
                          <HistoryPopover
                            isOpen={expandHistory}
                            onHandleClose={this.handleCloseHistory}
                            historyType="card"
                            historyId={cardId}
                            historyData={historyData}
                            setHistoryDataCallback={this.setHistoryDataCallback}
                            editEnabled={cardAttribute.edit_enabled === 'yes'}
                          />
                        )}
                        {expandConfigs && (
                          <ConfigOverrides
                            isOpen={expandConfigs}
                            onHandleClose={this.handleCloseConfigs}
                            cardId={parseInt(cardId, 10)}
                            datasetId={parseInt(datasetId, 10)}
                          />
                        )}
                      </Grid>
                    </Grid>
                    <Grid item xs={8}>
                      <Grid container alignItems="flex-end" direction="column">
                        <div className="icon-container">
                          {!thumbnail &&
                            !isMobile &&
                            cardAttribute.dataset_last_load_date &&
                            this.renderLastUpdatedDate()}
                          {!thumbnail && !isMobile && this.renderRefreshCard()}
                          {cardType && cardType.toLowerCase() !== 'text' && this.renderSelectedTimePeriod(isRender)}
                          {this.renderTimePeriodPopup()}
                          {cardQueryAttribute &&
                            this.cardFilter(
                              cardQueryAttribute.viewer_filters,
                              cardId,
                              cardQueryAttribute.columns,
                              datasetId,
                              allFilters
                            )}
                          {cardAttribute.support_details && cardAttribute.support_details.text && (
                            <Tooltip title="Help" placement="top">
                              <IconButton
                                id="card-view-help"
                                onClick={this.handleShowSupportText}
                                aria-label="help button"
                              >
                                <InfoRounded className="align-icon" />
                              </IconButton>
                            </Tooltip>
                          )}
                          {this.state.isShowHelp && (
                            <Popover
                              anchorReference="anchorEl"
                              anchorEl={this.state.isShowHelp}
                              className="support-text-popover"
                              open
                              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                              onClose={this.handleShowSupportText}
                              onKeyDown={this.handleKeyPressed}
                            >
                              <div className="support-text-popover">
                                <Viewer model={cardAttribute.support_details.text} />
                              </div>
                            </Popover>
                          )}
                          {cardAttribute.edit_enabled === 'yes' && renderCard[cardId]?.status !== 'requested' && (
                            <Tooltip title="Edit Card" placement="top" tabIndex="-1">
                              <Link
                                to={
                                  topLevelId
                                    ? _siteName
                                      ? `/site/${_siteName}/builder/card/${topLevelId}`
                                      : `/builder/card/${topLevelId}`
                                    : _siteName
                                    ? `/site/${_siteName}/builder/${
                                        cardInfo.data && cardType?.toLowerCase() === 'text' ? 'textcard' : 'card'
                                      }/${cardInfo.data ? cardInfo.data._id : ''}`
                                    : `/builder/${
                                        cardInfo.data && cardType?.toLowerCase() === 'text' ? 'textcard' : 'card'
                                      }/${cardInfo.data ? cardInfo.data._id : ''}`
                                }
                              >
                                <IconButton
                                  className="icon-button"
                                  id="card-view-edit"
                                  onClick={() => this.handleEditClick(cardInfo)}
                                >
                                  <Edit className="align-icon link-icon" />
                                </IconButton>
                              </Link>
                            </Tooltip>
                          )}
                          {(cardAttribute.enable_save_as || cardAttribute.edit_enabled === 'yes') &&
                            this.renderSaveAsCard()}
                          {this.state.showSupportDialog && this.renderSupportDialog()}
                          {!thumbnail && !isMobile && isRender && (
                            <div className="card-display-indicator greenFieldNoPrint">
                              {this.renderLockAndCompare()}
                            </div>
                          )}
                          <IconButton id="card-view-more" aria-label="More" onClick={this.handleMoreActionsClick}>
                            <Icon>more_vert</Icon>
                          </IconButton>
                          <Menu
                            open={Boolean(openActionsMenu) && !expandMore}
                            anchorEl={openActionsMenu}
                            onClose={this.handleMoreActionsClose}
                            PaperProps={{
                              sx: {
                                overflow: 'visible',
                                minWidth: 300,
                              },
                            }}
                          >
                            {(cardAttribute.enable_save_as || cardAttribute.edit_enabled === 'yes') && (
                              <MenuItem
                                onClick={() => {
                                  this.handleSetOpenSaveAs()
                                  this.handleMoreActionsClose()
                                }}
                                id="save-card-as-btn"
                              >
                                <ListItemIcon>
                                  <FileCopy />
                                </ListItemIcon>
                                <ListItemText primary="Save Card As" />
                              </MenuItem>
                            )}
                            {cardAttribute.edit_enabled === 'yes' && (
                              <MenuItem
                                onClick={() => {
                                  this.handleOnClickDeleteCard()
                                  this.handleMoreActionsClose()
                                }}
                                id="delete-card-btn"
                              >
                                <ListItemIcon>
                                  <Delete />
                                </ListItemIcon>
                                <ListItemText primary="Delete" />
                              </MenuItem>
                            )}
                            <MenuItem
                              id="cardviewer-generate-shortlink"
                              onClick={() => {
                                this.handleMoreActionsClose()
                                this.setState({
                                  shortLinkVisible: true,
                                })
                              }}
                            >
                              <ListItemIcon>
                                <IconLink />
                              </ListItemIcon>
                              <ListItemText primary="Card Short Link" />
                            </MenuItem>
                            {(cardAttribute.viz_type.toLowerCase() === 'table' ||
                              cardAttribute.viz_type.toLowerCase() === 'tree_table') &&
                              !renderCardData?.data?.partial_rows && (
                                <List component="nav" disablePadding>
                                  <ListItemButton
                                    data-cy="open-export"
                                    onClick={() => this.setState({ exportOpen: !exportOpen })}
                                  >
                                    <ListItemIcon>
                                      <FileDownload />
                                    </ListItemIcon>
                                    <ListItemText primary="Export" />
                                    {exportOpen ? <ExpandLess /> : <ExpandMore />}
                                  </ListItemButton>
                                  <Collapse in={exportOpen} timeout="auto" unmountOnExit>
                                    <List>
                                      <CSVLink
                                        asyncOnClick
                                        onClick={(event, done) => this.handleExportToCsvClick(done)}
                                        data={csvDataForExport}
                                        className="downloadLinkButton"
                                        target="_self"
                                        filename={`${cardAttribute.card_title}.csv`}
                                      >
                                        <SListItemButton
                                          id="export-to-csv-btn"
                                          onClick={this.handleMoreActionsClose}
                                          disabled={
                                            cardAttribute.viz_type.toLowerCase() === 'tree_table'
                                              ? !treeTableCsvData
                                              : cardAttribute.viz_type.toLowerCase() === 'pivot'
                                              ? !this.rawDataForCsv
                                              : !this.rawDataForCsv || !this.rawDataForCsv.length
                                          }
                                        >
                                          <ListItemText primary="Export to CSV" />
                                        </SListItemButton>
                                      </CSVLink>
                                      {((largeExportEnable &&
                                        renderCard[cardId]?.status >= 200 &&
                                        renderCard[cardId]?.status < 300) ||
                                        (largeExportEnable && renderCard[cardId]?.status === 400)) && (
                                        <SListItemButton
                                          id="export-request-btn"
                                          className="requestLargeExportTriggerBtn"
                                          onClick={this.handleTriggerLargeExportInit}
                                        >
                                          <ListItemText primary="Request Export" />
                                        </SListItemButton>
                                      )}
                                    </List>
                                  </Collapse>
                                </List>
                              )}
                            {(cardAttribute.viz_type.toLowerCase() === 'pivot' ||
                              cardAttribute.viz_type.toLowerCase() === 'table_beta') && (
                              <List component="nav" disablePadding>
                                <ListItemButton onClick={() => this.setState({ exportOpen: !exportOpen })}>
                                  <ListItemIcon>
                                    <FileDownload />
                                  </ListItemIcon>
                                  <ListItemText primary="Export" />
                                  {exportOpen ? <ExpandLess /> : <ExpandMore />}
                                </ListItemButton>
                                <Collapse in={exportOpen} timeout="auto" unmountOnExit>
                                  <List>
                                    {cardAttribute &&
                                      cardAttribute.viz_type &&
                                      cardAttribute.viz_type.toLowerCase() !== 'text' && (
                                        <SListItemButton
                                          id="export-to-csv-btn"
                                          onClick={() => {
                                            this.handleMoreActionsClose()
                                            const footer = `\n${getCsvFooter(this.props)}`
                                            agGridApi.exportDataAsCsv({
                                              fileName: `${cardAttribute.card_title}.csv`,
                                              columnGroups: true,
                                              processCellCallback: params => {
                                                params.data = { ...params.node.data }
                                                // use column definition's value formatter to format csv output
                                                return params.column.colDef.valueFormatter(params) || params.value
                                              },
                                              customFooter: footer,
                                            })
                                          }}
                                          disabled={!agGridApi}
                                        >
                                          <ListItemText primary="Export to CSV" />
                                        </SListItemButton>
                                      )}
                                    {((largeExportEnable &&
                                      renderCard[cardId]?.status >= 200 &&
                                      renderCard[cardId]?.status < 300) ||
                                      (largeExportEnable && renderCard[cardId]?.status === 400)) && (
                                      <SListItemButton
                                        id="export-request-btn"
                                        className="requestLargeExportTriggerBtn"
                                        onClick={this.handleTriggerLargeExportInit}
                                      >
                                        <ListItemText primary="Request Export" />
                                      </SListItemButton>
                                    )}
                                  </List>
                                </Collapse>
                              </List>
                            )}
                            {(cardAttribute.viz_type.toLowerCase() === 'table' ||
                              cardAttribute.viz_type.toLowerCase() === 'tree_table') &&
                              renderCardData &&
                              renderCardData.data &&
                              renderCardData.data.partial_rows && (
                                <List component="nav" disablePadding>
                                  <ListItemButton onClick={() => this.setState({ exportOpen: !exportOpen })}>
                                    <ListItemIcon>
                                      <FileDownload />
                                    </ListItemIcon>
                                    <ListItemText primary="Export" />
                                    {exportOpen ? <ExpandLess /> : <ExpandMore />}
                                  </ListItemButton>
                                  <Collapse in={exportOpen} timeout="auto" unmountOnExit>
                                    <List>
                                      {cardAttribute &&
                                        cardAttribute.viz_type &&
                                        cardAttribute.viz_type.toLowerCase() !== 'text' && (
                                          <SListItemButton
                                            id="export-to-csv-btn"
                                            onClick={this.handleSmartExportTrigger}
                                          >
                                            <ListItemText primary="Export to CSV" />
                                          </SListItemButton>
                                        )}
                                      {((largeExportEnable &&
                                        renderCard[cardId]?.status >= 200 &&
                                        renderCard[cardId]?.status < 300) ||
                                        (largeExportEnable && renderCard[cardId]?.status === 400)) && (
                                        <SListItemButton
                                          id="export-request-btn"
                                          className="requestLargeExportTriggerBtn"
                                          onClick={this.handleTriggerLargeExportInit}
                                        >
                                          <ListItemText primary="Request Export" />
                                        </SListItemButton>
                                      )}
                                    </List>
                                  </Collapse>
                                </List>
                              )}
                            <List component="nav" disablePadding>
                              <ListItemButton onClick={() => this.setState({ printOpen: !printOpen })}>
                                <ListItemIcon>
                                  <Print />
                                </ListItemIcon>
                                <ListItemText primary="Print" />
                                {printOpen ? <ExpandLess /> : <ExpandMore />}
                              </ListItemButton>
                              <Collapse in={printOpen} timeout="auto" unmountOnExit>
                                <List>
                                  <SListItemButton
                                    id="print-card-portrait-btn"
                                    onClick={() => {
                                      this.triggerPrint(true)
                                    }}
                                  >
                                    <ListItemText primary="Card In Portrait" />
                                  </SListItemButton>
                                  <SListItemButton
                                    id="print-card-landscape-btn"
                                    onClick={() => {
                                      this.triggerPrint(true)
                                    }}
                                  >
                                    <ListItemText primary="Card In Landscape" />
                                  </SListItemButton>
                                </List>
                              </Collapse>
                            </List>
                            {/* for internal users only */}
                            {this.props.user?.data?.user_type === 'internal' && (
                              <List component="nav" disablePadding>
                                <div>
                                  <ListItemButton
                                    data-cy="expand-notifications-and-subscriptions"
                                    onClick={() => this.setState({ subscriptionOpen: !subscriptionOpen })}
                                  >
                                    <ListItemIcon>
                                      <TodayRounded />
                                    </ListItemIcon>
                                    <ListItemText primary="Notifications & Subscriptions" />
                                    {subscriptionOpen ? <ExpandLess /> : <ExpandMore />}
                                  </ListItemButton>
                                </div>
                                <Collapse in={subscriptionOpen} timeout="auto" unmountOnExit>
                                  <List>
                                    <Tooltip
                                      placement="left-start"
                                      title="Create In App Card Notification, which displays a banner on card"
                                    >
                                      <SListItemButton
                                        disabled={cardAttribute.edit_enabled !== 'yes'}
                                        data-cy="create-card-notification-btn"
                                        component={Link}
                                        to={`/notification-manager/edit/card/${cardId}`}
                                        style={{
                                          display: cardAttribute.edit_enabled === 'yes' ? 'flex' : 'none',
                                        }}
                                      >
                                        <ListItemText primary="Create Card Notification" />
                                      </SListItemButton>
                                    </Tooltip>
                                    <Tooltip
                                      placement="left-start"
                                      title={
                                        renderCard[cardId]?.data?.card_config?.card_query_attribute?.columns?.filter(
                                          col => METRIC_TYPES.includes(col.type)
                                        )?.length === 0
                                          ? 'Please add metric columns to the card to setup data alert'
                                          : 'Create alerts on card, which triggers an email when configured criteria is met'
                                      }
                                    >
                                      <span>
                                        <SListItemButton
                                          style={{
                                            display: this.state.dataAlertCreateStatus ? 'flex' : 'none',
                                          }}
                                          disabled={
                                            !this.state.dataAlertCreateStatus ||
                                            renderCard[
                                              cardId
                                            ]?.data?.card_config?.card_query_attribute?.columns?.filter(col =>
                                              METRIC_TYPES.includes(col.type)
                                            )?.length === 0
                                          }
                                          data-cy="create-data-alert-button"
                                          onClick={() => {
                                            this.handleMoreActionsClose()
                                            this.setState({ dataAlertDialogOpen: true })
                                            this.props.checkUserAccess()
                                          }}
                                        >
                                          <ListItemText primary="Create Data Alert" />
                                        </SListItemButton>
                                      </span>
                                    </Tooltip>
                                    <Tooltip
                                      placement="left-start"
                                      title="Create subscriptions on card, receive an email with data based on data change and selected frequency"
                                    >
                                      <SListItemButton
                                        style={{
                                          display: this.state.subscriptionCreateStatus ? 'flex' : 'none',
                                        }}
                                        disabled={
                                          !this.state.subscriptionCreateStatus ||
                                          cardAttribute.viz_type.toLowerCase() === 'text'
                                        }
                                        data-cy="create-subscription-button"
                                        onClick={() => {
                                          this.handleMoreActionsClose()
                                          this.setState({ subscriptionDialogOpen: true })
                                          this.props.checkUserAccess()
                                        }}
                                      >
                                        <ListItemText primary="Create Card Subscription" />
                                      </SListItemButton>
                                    </Tooltip>
                                    <Tooltip
                                      placement="left-start"
                                      title="View selected card subscriptions and data alerts"
                                    >
                                      <SListItemButton
                                        data-cy="view-card-notification-btn"
                                        component={Link}
                                        to={`/notifications/card/${cardId}`}
                                        disabled={
                                          !this.state.subscriptionCreateStatus ||
                                          cardAttribute.viz_type.toLowerCase() === 'text'
                                        }
                                      >
                                        <ListItemText primary="View Card Subscriptions" />
                                      </SListItemButton>
                                    </Tooltip>
                                  </List>
                                </Collapse>
                              </List>
                            )}
                            <List component="nav" disablePadding>
                              <ListItemButton
                                data-cy="open-troubleshooting"
                                onClick={() => this.setState({ troubleShootOpen: !troubleShootOpen })}
                              >
                                <ListItemIcon>
                                  <Engineering />
                                </ListItemIcon>
                                <ListItemText primary="Troubleshooting" />
                                {troubleShootOpen ? <ExpandLess /> : <ExpandMore />}
                              </ListItemButton>
                              <Collapse in={troubleShootOpen} timeout="auto" unmountOnExit>
                                <List>
                                  {cardAttribute &&
                                    cardAttribute.viz_type &&
                                    cardAttribute.viz_type.toLowerCase() !== 'text' && (
                                      <SListItemButton
                                        id="view-card-query-btn"
                                        onClick={this.handleCardQueryToggleVisibility}
                                      >
                                        <ListItemText primary="View Card Query" />
                                      </SListItemButton>
                                    )}
                                  <SListItemButton id="information-support-btn" onClick={this.handleSupportClick}>
                                    <ListItemText primary="Information for Support Ticket" />
                                  </SListItemButton>
                                  {user &&
                                    user.status >= 200 &&
                                    user.status < 300 &&
                                    user.data &&
                                    user.data.is_admin === 'yes' && (
                                      <SListItemButton id="view-config-overrides-btn" onClick={this.handleViewConfigs}>
                                        <ListItemText primary="Configuration Overrides" />
                                      </SListItemButton>
                                    )}
                                </List>
                              </Collapse>
                            </List>
                            <ListItemButton id="view-more-details-btn" onClick={this.handleMoreClick}>
                              <ListItemIcon>
                                <Info />
                              </ListItemIcon>
                              <ListItemText primary="Card Details" />
                            </ListItemButton>
                            <ListItemButton id="view-history-btn" onClick={this.handleViewHistory}>
                              <ListItemIcon>
                                <ManageHistory />
                              </ListItemIcon>
                              <ListItemText primary="Card History" />
                            </ListItemButton>
                            <Link
                              id="view-usage-btn"
                              to={VIEW_USAGE_CARD(cardId)}
                              style={{ textDecoration: 'none', color: 'rgba(0, 0, 0, 0.87)' }}
                            >
                              <ListItemButton onClick={this.handleUsageClick}>
                                <ListItemIcon>
                                  <DataUsage />
                                </ListItemIcon>
                                <ListItemText primary="Card Usage" />
                              </ListItemButton>
                            </Link>
                            <Link
                              id="view-embedded-full-btn"
                              to={isEmbed ? `/card/${cardId}` : `/card/${cardId}/embed`}
                              style={{ textDecoration: 'none', color: 'rgba(0, 0, 0, 0.87)' }}
                            >
                              <MenuItem id="view-embeddable-card-btn">
                                <ListItemIcon>{isEmbed ? <CodeOff /> : <Code />}</ListItemIcon>
                                <ListItemText primary={isEmbed ? 'View Full Card' : 'View Embeddable Card'} />
                              </MenuItem>
                            </Link>
                          </Menu>
                        </div>
                      </Grid>
                    </Grid>
                    <ConfirmDialog
                      open={this.state.confirmDeleteCard}
                      contentText={this.getConfirmText(cardInfo)}
                      okText="DELETE"
                      onCloseDialog={this.handleCloseDialog}
                      onClickOk={this.handleDeleteCard}
                      onClickCancel={this.handleCloseDialog}
                    />
                  </Grid>
                </Toolbar>
              </AppBar>
            )}
            {Boolean(drillthrough.length && activeDrillthroughIndex >= 0) && (
              <DrillthroughBreadcrumbs
                topLevelId={topLevelId.toString()}
                drillthrough={drillthrough}
                activeDrillthroughIndex={activeDrillthroughIndex}
                renderedCardTitles={[
                  {
                    id: topLevelId.toString(),
                    title: renderCard[topLevelId].data
                      ? renderCard[topLevelId].data.card_config.card_attribute.card_title
                      : '',
                  },
                ].concat(
                  Object.keys(drillCardMetaStatus).map(cardId => ({
                    id: cardId,
                    title: drillCardMetaStatus[cardId].data
                      ? drillCardMetaStatus[cardId].data.card_config.card_attribute.card_title
                      : '',
                  }))
                )}
                setDrill={breadCrumbClickAndDrill}
              />
            )}
          </>
        )
      }
    }
    return null
  }

  renderCardTitle = title => (
    <div data-cy="card-viewer-title" className={this.props.isMobile ? 'cardMobileTitle' : 'cardDesktopTitle'}>
      {title}
    </div>
  )

  handleDeleteCard = () => {
    const { cardInfo } = this.props
    const delCard = cardInfo.data._id

    this.setState({ cardDeleted: true })
    this.props.delCard({ delCard })
  }

  handleOnClickDeleteCard = () => {
    this.setState({
      confirmDeleteCard: true,
    })
  }

  getConfirmText = cardInfo => {
    let text

    if (
      cardInfo &&
      cardInfo.data &&
      cardInfo.data.dashboards_containing_card &&
      cardInfo.data.dashboards_containing_card.length > 0
    ) {
      text = `Are you sure you want to delete ${cardInfo.data.card_attribute.card_title} card ? This card will be deleted from ${cardInfo.data.dashboards_containing_card.length} dashboard(s)`
    } else {
      text =
        cardInfo.data && cardInfo.data.card_attribute
          ? `Are you sure you want to delete ${cardInfo.data.card_attribute.card_title} card ? `
          : 'Are you sure you want to delete this card?'
    }
    return text
  }

  handleCloseDialog = () => {
    this.setState({ confirmDeleteCard: false })
  }

  handleCardQueryToggleVisibility = () => {
    this.setState(prevState => ({ queryCardOpen: !prevState.queryCardOpen }))
  }

  renderCountCard = (label, count) => (
    <Card className="dashboardExpandCard expandCardBackgroundColor">
      <CardContent className="dashboardExpandCardContents">
        <Typography className="expandCardData">{count}</Typography>
        <Typography className="expandCardLabel">{label}</Typography>
      </CardContent>
    </Card>
  )

  sendFireflyEvent = (isCustomEvent, eventObj) => {
    if (isCustomEvent) {
      this.props.trackCustomEvent(eventObj.eventName, eventObj.key, JSON.stringify(eventObj.eventData))
    } else {
      this.props.trackEvent({
        event: {
          type: eventObj.eventName,
        },
      })
    }
  }

  renderOnError = cardData => {
    const { cardId, renderCard, cardRenderFilters, timePeriod } = this.props
    const largeExportEnable = this.props.cardInfo?.data?.card_query_attribute?.large_export_enabled

    return (
      <CardContent className="cardFailMessage">
        <Icon className="notFoundIcon">not_interested</Icon>
        <Typography className="error-message">
          {createErrMsg(cardData, 'Something went wrong with the request to render this card.')}
        </Typography>
        <div className="error-message">
          <AccessDeniedViewOwner errorData={cardData?.message?.response?.data} />
        </div>
        {renderCard[cardId]?.type === 'DataClientErrorCacheException' && largeExportEnable && (
          <Typography id="cacheErrorExportPromptContainer">
            Do you want to create a Large Export Request?
            <Button
              color="primary"
              onClick={() => {
                const eventData = {
                  eventName: 'cacheErrorLargeExportBtnClick',
                  key: cardId,
                  eventData: {
                    message: renderCard[cardId]?.message,
                    filters: cardRenderFilters,
                    timePeriod,
                  },
                }

                this.handleTriggerLargeExportInit()
                this.sendFireflyEvent(true, eventData)
              }}
            >
              Yes
            </Button>
          </Typography>
        )}
      </CardContent>
    )
  }

  renderMessage = message => <p style={{ fontSize: '16px', margin: '10px' }}>{message}</p>

  processLoader() {
    const {
      preloadedData,
      renderCard,
      cardId,
      cardInfo,
      isMobile,
      restoreCard,
      activeDrillthroughIndex,
      drillCardMetaStatus,
      restoreDrillCard,
    } = this.props

    const cardDrill =
      !Number.isInteger(activeDrillthroughIndex) || activeDrillthroughIndex < 0
        ? cardInfo.data
        : drillCardMetaStatus[cardId]?.data?.card_config
    const restoreCardFunc =
      !Number.isInteger(activeDrillthroughIndex) || activeDrillthroughIndex < 0 ? restoreCard : restoreDrillCard

    if (this.state.cancelRestore) {
      return <Redirect to="/cards" />
    }

    if (preloadedData) {
      return preloadedData.message ? this.renderMessage(preloadedData.message) : this.renderCardView(preloadedData)
    } else if (
      (Number.isInteger(activeDrillthroughIndex) &&
        activeDrillthroughIndex < 0 &&
        cardInfo.status &&
        cardInfo.status !== 'requested' &&
        cardInfo.status !== 'failed' &&
        cardInfo.data.status?.state === 'disabled') ||
      (Number.isInteger(activeDrillthroughIndex) &&
        activeDrillthroughIndex >= 0 &&
        drillCardMetaStatus[cardId] &&
        drillCardMetaStatus[cardId].status !== 'requested' &&
        drillCardMetaStatus[cardId].status !== 'failed' &&
        drillCardMetaStatus[cardId].data.card_config.status?.state === 'disabled')
    ) {
      return cardDrill.card_attribute.edit_enabled === 'yes' ? (
        <ConfirmDialog
          open={this.state.unDeleteConfirm}
          contentText="Card you are trying to view is deleted. Do you want to restore ?"
          okText="RESTORE"
          onCloseDialog={() => {}}
          onClickOk={() => {
            restoreCardFunc({ cardId })
            this.setState({ unDeleteConfirm: false })
          }}
          onClickCancel={() => {
            this.setState({
              unDeleteConfirm: false,
              cancelRestore: true,
            })
          }}
        />
      ) : (
        <Dialog open>
          <DialogContent>
            <DialogContentText className="dialog-text">
              This card is deleted, please contact{' '}
              <b>
                {(cardInfo.data.card_attribute.owners.length && cardInfo.data.card_attribute.owners[0]) ||
                  (cardInfo.data.card_attribute.owners_access.length &&
                    cardInfo.data.card_attribute.owners_access[0].user_group_name) ||
                  cardInfo.data.card_attribute.created_by}
              </b>{' '}
              to restore this card
            </DialogContentText>
          </DialogContent>
          <DialogActions className="okButton">
            <Button
              onClick={() => {
                this.setState({
                  cancelRestore: true,
                })
              }}
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>
      )
    } else {
      if (renderCard[cardId]) {
        const vizType =
          renderCard[cardId].status &&
          renderCard[cardId].status !== 'requested' &&
          renderCard[cardId].status !== 'failed'
            ? renderCard[cardId].data.card_config.card_attribute.viz_type.toLowerCase()
            : ''
        switch (renderCard[cardId].status) {
          case 'requested':
            return <Spinner size="large" layout="selfCentering" style={{ marginTop: '50px' }} />
          case 'failed':
            return this.renderOnError(renderCard[cardId])
          default:
            return (
              <div
                className={`mobileCardHeightHook ${vizType}VizType ${
                  vizType === 'table' || vizType === 'pivot' ? '' : 'cardviewer-chart-viz-container'
                }`}
              >
                {isMobile && this.renderMobileFilters()}
                {this.renderCardView(renderCard[cardId].data)}
                {renderCard[cardId].data &&
                  renderCard[cardId].status !== 202 &&
                  renderCard[cardId].data.partial_rows && (
                    <div className="card-viewer-note">** Not all results displayed</div>
                  )}
              </div>
            )
        }
      } else if (cardInfo && cardInfo.status && cardInfo.status === 'requested') {
        return <Spinner size="large" layout="selfCentering" className="card-spinner" style={{ marginTop: '50px' }} />
      }
    }
    return null
  }

  renderQueryCard() {
    const { renderCard, cardId, preloadedData } = this.props
    let cardData = {}

    if (preloadedData) {
      cardData = preloadedData
    } else if (
      renderCard[cardId] &&
      renderCard[cardId].status >= 200 &&
      renderCard[cardId].status < 300 &&
      renderCard[cardId].status !== 'failed'
    ) {
      cardData = renderCard[cardId].data
    }

    const cardDataString = cardData.query ? JSON.stringify(cardData.query, null, 2) : ''

    return (
      <Dialog
        open={Boolean(this.state.queryCardOpen)}
        onClose={this.handleCardQueryToggleVisibility}
        maxWidth="md"
        fullWidth
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Card Query</DialogTitle>
        <DialogContent dividers>
          <pre className="dialog-text">{cardDataString}</pre>
        </DialogContent>
        <DialogActions>
          <Button
            style={{ marginRight: 'auto' }}
            onClick={this.handleCardQueryToggleVisibility}
            id="card-query-cancel-btn"
            color="secondary"
          >
            Close
          </Button>
          <Button
            onClick={() => {
              navigator.clipboard.writeText(cardDataString)
            }}
            id="card-query-copy-btn"
            color="primary"
          >
            Copy to Clipboard
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  getHeightMultiplier = height => {
    let multiplier = 60
    switch (height) {
      case 4:
        multiplier = 60
        break
      case 5:
      case 6:
        multiplier = 65
        break
      case 7:
      case 8:
        multiplier = 70
        break
      case 9:
      case 10:
        multiplier = 75
        break
    }
    return multiplier
  }

  renderMobileDrillBreadCrumpDialog = () => {
    const { activeDrillthroughIndex, drillthrough, topLevelId, renderCard, setDrill } = this.props
    const breadCrumbClickAndDrill = options => {
      setDrill([], options)
    }
    return (
      <DrillThroughClicked.Consumer>
        {({ drillClicked, setDrillRowClicked, isDrillThroughButton, backButtonClicked }) => {
          if (!isDrillThroughButton && Boolean(drillthrough.length) && activeDrillthroughIndex >= 0) {
            setDrillRowClicked('isDrillThroughButton')
          }

          if (backButtonClicked && activeDrillthroughIndex >= 0) {
            breadCrumbClickAndDrill({
              activeDrillthroughIndex: activeDrillthroughIndex - 1,
              id:
                activeDrillthroughIndex - 1 >= 0
                  ? drillthrough[activeDrillthroughIndex - 1].card_id
                  : topLevelId.toString(),
              drillthrough,
              isBreadCrumb: true,
            })
            setDrillRowClicked('backButtonClicked')
          }

          if (isDrillThroughButton && !(Boolean(drillthrough.length) && activeDrillthroughIndex >= 0)) {
            setDrillRowClicked('isDrillThroughButton')
          }
          return (
            <>
              {!backButtonClicked && (
                <Dialog
                  fullScreen
                  open={drillClicked}
                  onClose={() => setDrillRowClicked('drillClicked')}
                  TransitionComponent={Transition}
                  className="mobileFilterSlide"
                >
                  <header className="header-container">
                    <Typography className="title">Drill Through</Typography>
                  </header>
                  <DrillthroughBreadcrumbs
                    isMobile
                    topLevelId={topLevelId && topLevelId.toString()}
                    drillthrough={drillthrough}
                    activeDrillthroughIndex={activeDrillthroughIndex}
                    renderedCardTitles={Object.keys(renderCard).map(cardId => ({
                      id: cardId,
                      title: renderCard[cardId].data
                        ? renderCard[cardId].data.card_config.card_attribute.card_title
                        : '',
                    }))}
                    setDrill={breadCrumbClickAndDrill}
                    setDrillRowClicked={setDrillRowClicked}
                  />
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      className="mobileMoreCloseBtn"
                      onClick={() => setDrillRowClicked('drillClicked')}
                    >
                      Close
                    </Grid>
                  </Grid>
                </Dialog>
              )}
            </>
          )
        }}
      </DrillThroughClicked.Consumer>
    )
  }

  handleTabChange = (event, value) => {
    this.setState({ activeFilterMobileTab: value })
  }

  renderMobileFilterDialog = () => {
    const { selectedFilterId, activeFilterMobileTab } = this.state
    const { cardInfo, activeDrillthroughIndex, drillCardMetaStatus, cardId, renderCard, routeProps, deviceType } =
      this.props
    const card =
      !Number.isInteger(activeDrillthroughIndex) || activeDrillthroughIndex === -1
        ? cardInfo
        : drillCardMetaStatus[cardId]
    const cardConfig = card.data && (card.data.card_config || card.data)
    let filters = []
    const datasetId = cardConfig ? cardConfig.card_attribute.dataset_id : ''
    const cardQueryAttribute = cardConfig?.card_query_attribute // eslint-disable-line camelcase
    let renderedFilters = []
    if (cardConfig) {
      const cardQueryAttr = cardConfig.card_query_attribute
      filters =
        (cardQueryAttr && this.getUpdatedFilterViewer(cardQueryAttr.viewer_filters, cardQueryAttr.columns)) || []
    }
    if (renderCard[cardId]) {
      const renderCardConfig = renderCard[cardId].data && renderCard[cardId].data.card_config
      renderedFilters = (renderCardConfig && renderCardConfig.requested_filters_list) || []
    }

    return (
      <Dialog
        fullScreen
        open={this.state.mobileFilterDialogState}
        onClose={this.handleToggleMobileFilterMenu}
        TransitionComponent={Transition}
        className="mobileFilterSlide"
      >
        <>
          <Tabs
            value={activeFilterMobileTab}
            onChange={this.handleTabChange}
            className="accent-color"
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab
              key="Filter(s)"
              label={!filters.length ? 'Card Filter(s)' : 'Filter(s)'}
              className="mobile-text-transform"
              data-cy="mobile-filter-details"
            />
            <Tab
              key="Applied Filter(s)"
              label="Applied Filter(s)"
              className="mobile-text-transform"
              data-cy="mobile-applied-filter-details"
            />
          </Tabs>
          <SwipeableViews class="mobile-swipeable-views" index={activeFilterMobileTab}>
            <GreenfieldFilterViewer
              routeProps={routeProps}
              open={this.state.mobileFilterDialogState}
              viewerFilters={filters}
              type="card"
              id={Number(cardId)}
              datasetId={datasetId}
              onDismissPopover={this.handleToggleMobileFilterMenu}
              selectedFilterId={selectedFilterId}
              isMobile
              activeFilterMobileTab={activeFilterMobileTab}
              columnArray={cardQueryAttribute?.columns || []}
              cardInfo={renderCard[cardId]}
              deviceType={deviceType}
              closeMobilePopover={this.closeMobilePopOver}
            />
            <GreenfieldFilterViewer
              open={this.state.mobileFilterDialogState}
              viewerFilters={filters}
              type="card"
              id={Number(cardId)}
              onDismissPopover={this.handleToggleMobileFilterMenu}
              selectedFilterId={selectedFilterId}
              isMobile
              activeFilterMobileTab={activeFilterMobileTab}
              isAppliedFilters
              renderedFilters={renderedFilters}
              routeProps={routeProps}
              datasetId={datasetId}
              columnArray={cardQueryAttribute?.columns || []}
              cardInfo={cardInfo}
              deviceType={deviceType}
              closeMobilePopover={this.closeMobilePopOver}
            />
          </SwipeableViews>
        </>
      </Dialog>
    )
  }

  handleToggleMobileLockAndCompare = () => {
    this.sendFireflyEvent(false, { eventName: 'moileLockandCompareMoreClick' })
    this.setState({
      mobileLockAndCompareState: !this.state.mobileLockAndCompareState,
    })
  }

  renderMobileLockAndCompare = () => {
    const { mobileLockAndCompareState, selectedColList } = this.state
    const { cardInfo, drill, cardId, renderCard, isMobile } = this.props

    const columns = cardInfo && cardInfo.data ? this.getColumns(cardInfo.data) : []
    const defaultSelectedColList = isEmpty(selectedColList)
      ? columns.map(col => {
          return { name: col.name, display_name: col.display_name, value: 'compare' }
        })
      : selectedColList

    return (
      <Dialog
        fullScreen
        open={mobileLockAndCompareState}
        TransitionComponent={Transition}
        className="mobileFilterSlide"
      >
        <LockAndCompare
          open={mobileLockAndCompareState}
          columns={columns}
          isAvatar
          cardInfo={cardInfo}
          selectedColList={defaultSelectedColList}
          drill={drill}
          renderColumns={this.getRenderColumns(cardId, renderCard, columns, drill)}
          submitLockAndCompare={this.toggleLockAndComparePopover}
          isMobile={isMobile}
        />
      </Dialog>
    )
  }

  renderMobileMoreMenu = () => {
    const { routeProps, cardInfo, saveCardFromViewerStatus, renderCard, cardRender, cardId, isMobile, deviceType } =
      this.props
    const { data } = cardInfo

    if (data) {
      const { mobileMoreMenuActive, mobileViewDetailActive, openSaveAs, mobileAnchorEl, confirmDeleteCard } = this.state
      const cardAttribute = cardInfo.data.card_attribute
      const editEnabled = cardAttribute.edit_enabled
      const dataSetId = cardAttribute.dataset_id

      let vizType = null
      if (cardInfo && cardInfo.status >= 200 && cardInfo.status < 300) {
        vizType = data.card_attribute.viz_type.toLowerCase()
      }

      return (
        <Dialog
          fullScreen
          open={mobileMoreMenuActive}
          onClose={this.handleToggleMobileMoreMenu}
          TransitionComponent={Transition}
          className="mobileMoreSlide"
          id="mobile-more-dialog"
        >
          <MenuList>
            <Accordion
              expanded={mobileViewDetailActive === 'panel1'}
              onClick={() => this.handleMobileMoreBtnClick('mobileMoreViewDetailClick')}
              onChange={(event, expanded) => {
                this.toggleMobileViewDetail('panel1', expanded)
              }}
              className="mobileDashboardViewMoreExpand"
              id="mobile-more-view-detail"
            >
              <AccordionSummary
                classes={{
                  content: 'expansionContent',
                }}
              >
                <Typography>View Detail</Typography>
                <Icon className="align-icon">visibility</Icon>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container alignItems="flex-start" direction="row">
                  <Grid item xs={6}>
                    <div className="card-details">
                      <Typography variant="caption">
                        <b>Owner(s):</b>
                        {cardAttribute.owners_access.map((item, key) => (
                          <Typography key={key} variant="caption">
                            {item.user_group_name}
                          </Typography>
                        ))}
                      </Typography>
                    </div>
                    <div className="card-details" hidden={editEnabled === 'no'}>
                      <Typography variant="caption">
                        <b>Dataset:</b>
                        <MaterialLink
                          className="edit-card-link"
                          href={`/dataset/preview/${dataSetId}/columns`}
                          color="inherit"
                        >
                          {cardAttribute.dataset_name}
                        </MaterialLink>
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className="card-details">
                      <Typography variant="caption">
                        <b>Data Classification: </b>
                        {cardAttribute.data_classification}
                      </Typography>
                    </div>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={mobileViewDetailActive === 'panel2'}
              onClick={() => this.handleMobileMoreBtnClick('mobileMoreHelpBtnClick')}
              onChange={(event, expanded) => {
                this.toggleMobileViewDetail('panel2', expanded)
              }}
              className="mobileDashboardViewMoreExpand"
              id="mobile-more-help"
            >
              <AccordionSummary
                classes={{
                  content: 'expansionContent',
                }}
              >
                <Typography>Help</Typography>
                <Icon className="align-icon">help</Icon>
              </AccordionSummary>
              <AccordionDetails>
                <Viewer model={cardAttribute.support_details.text} />
              </AccordionDetails>
            </Accordion>
            <MenuItem
              className="mobileMoreListItem"
              onClick={() => {
                this.setState({
                  shortLinkVisible: true,
                })
              }}
              id="mobile-more-generate-shortlink"
            >
              <Typography>Card Short Link</Typography>
              <ListItemIcon>
                <Icon className="align-icon">link</Icon>
              </ListItemIcon>
            </MenuItem>
            {editEnabled === 'yes' && (
              <MenuItem
                onClick={() => {
                  this.handleOnClickDeleteCard()
                  this.handleMobileMoreBtnClick('mobileMoreDeleteClick')
                }}
                id="mobile-more-delete-card"
              >
                <ListItemIcon>
                  <Delete />
                </ListItemIcon>
                <Typography>Delete</Typography>
              </MenuItem>
            )}

            <MenuItem
              className="mobileMoreListItem"
              onClick={this.handleToggleMobileTimePeriod}
              id="mobile-more-timepicker-toggle-card"
            >
              <Typography>Time Period</Typography>
              <ListItemIcon>
                <Icon className="align-icon">date_range</Icon>
              </ListItemIcon>
            </MenuItem>

            <MenuItem
              className="mobileMoreListItem"
              onClick={() => {
                this.handleToggleMobileMoreMenu()
                this.handleMobileMoreBtnClick('mobileMoreRefreshClick')
                this.props.refreshCard()
              }}
              id="mobile-more-refresh-card"
            >
              <Typography>Refresh</Typography>
              <ListItemIcon>
                <Icon className="align-icon">refresh</Icon>
              </ListItemIcon>
            </MenuItem>

            <MenuItem className="mobileMoreListItem">
              <Typography>Favorite</Typography>

              <div className="align-icon mobile-favorite-icon-button">
                <GreenfieldFavoriteButton type="cards" id={Number(cardId)} isFavorite={cardAttribute.favorite} />
              </div>
            </MenuItem>

            {vizType && (vizType === 'table' || vizType === 'table_beta') && (
              <MenuItem
                className="mobileMoreListItem"
                onClick={this.handleToggleMobileLockAndCompare}
                id="mobile-more-lock-and-compare-card"
              >
                <Typography>Lock and Compare Columns</Typography>
                <ListItemIcon>
                  <IconButton className="lock-compare-icon-mobile" id="lock-compare-columns" />
                </ListItemIcon>
              </MenuItem>
            )}
          </MenuList>

          <Grid container>
            <Grid
              item
              xs={12}
              className="mobileMoreCloseBtn"
              onClick={this.handleToggleMobileMoreMenu}
              id="mobile-more-close-btn"
            >
              Close
            </Grid>
          </Grid>
          <SaveAsContainer
            openSaveAs={openSaveAs}
            saveAsSuccess={this.saveAsSuccess}
            cancelSaveAs={this.cancelSaveAs}
            titleLabel="Card Name"
            ownerLabel="Owner(s)"
            descriptionLabel="Card Description"
            apiCallRequestStatus={saveCardFromViewerStatus}
          />
          <ConfirmDialog
            open={confirmDeleteCard}
            contentText={this.getConfirmText(cardInfo)}
            okText="DELETE"
            onCloseDialog={this.handleCloseDialog}
            onClickOk={this.handleDeleteCard}
            onClickCancel={this.handleCloseDialog}
          />

          <ViewerTimePeriod
            routeProps={routeProps}
            cardInfo={renderCard[cardId]}
            cardRender={cardRender}
            isDashboard={false}
            isMobile={isMobile}
            deviceType={deviceType}
            anchorEl={mobileAnchorEl}
            onTimePeriodChange={this.handleTimeChange}
            closeMobilePopover={this.closeMobilePopOver}
            timePeriod={this.getTimePeriodViewer()}
          />
        </Dialog>
      )
    }
    return null
  }

  renderMobileFilters = () => {
    const { cardId, renderCard } = this.props
    const card = renderCard[cardId]
    const cardConfig = card.data && card.data.card_config
    const filters = (cardConfig && cardConfig.requested_filters_list) || []
    const filtersApplied = filters.length
    return (
      <div>
        <Grid container>
          <Grid
            item
            xs={6}
            className="mobileFilterBtnLeft"
            onClick={this.handleToggleMobileFilterMenu}
            id="mobile-filter-trigger-btn"
          >
            <Badge badgeContent={filtersApplied} color="error">
              <div className="mobileFilterBtnLeftBadge">
                <FilterList /> Filter
              </div>
            </Badge>
          </Grid>
          <Grid
            item
            xs={6}
            className="mobileFilterBtnRight"
            onClick={this.handleMobileMoreButtonClick}
            id="mobile-more-trigger-btn"
          >
            <MoreVert />
            <span>More</span>
          </Grid>
        </Grid>
      </div>
    )
  }

  checkGranularity = columns => columns.some(val => val.type === 'timeseries')

  renderRefreshCard() {
    const { cardInfo, refreshCard, renderCard, cardId } = this.props
    let vizType = null

    if (cardInfo && cardInfo.status >= 200 && cardInfo.status < 300) {
      vizType = cardInfo.data.card_attribute.viz_type.toLowerCase()
    }

    return vizType && vizType?.toLowerCase() !== 'text' ? (
      <Tooltip title="Refresh Card" placement="bottom">
        <span>
          <IconButton
            disabled={renderCard[cardId] && renderCard[cardId].status === 'requested'}
            onClick={() => {
              refreshCard()
            }}
            data-cy="cardviewer-refresh-btn"
          >
            <Refresh />
          </IconButton>
        </span>
      </Tooltip>
    ) : null
  }

  renderLastUpdatedDate() {
    const date = this.props.cardInfo.data.card_attribute.dataset_last_load_date
    return (
      <span className="lastupdated-container">
        <Typography className="lastupdated-title">Data Last Loaded: </Typography>
        <Typography>{moment(date).format('MMMM Do YYYY, h:mm:ss a')}</Typography>
      </span>
    )
  }

  renderCardContent() {
    const { thumbnail, cardDelStatus, isMobile, renderCard, cardId, filterMetadataStatus, routeProps, chart } =
      this.props
    const { cardDeleted } = this.state
    const card = renderCard[cardId]
    const renderData = card && card.status >= 200 && card.status < 300 && card.data
    const cardConfig = renderData ? renderData.card_config : ''
    const allFilters =
      renderData && renderData.context && renderData.context.render_details && renderData.context.render_details.filters
    const isRlsApplied = allFilters && allFilters.some(filt => filt.source && filt.source === 'rls')

    return (
      <>
        {chart && (
          <Box m={2} style={{ textAlign: 'center' }}>
            <Typography variant="h6">{cardConfig?.card_attribute?.card_title}</Typography>
          </Box>
        )}
        {!thumbnail && !isMobile && cardConfig && !this.props.chart && (
          <GreenfieldFilterChipToolbar
            id={cardConfig._id}
            chipClicked={this.filterChipClick}
            filterMetadataStatus={filterMetadataStatus}
            routeProps={routeProps}
            cardInfo={card}
          />
        )}
        {!thumbnail && !isMobile && isRlsApplied && (
          <Typography className="rls-applied" data-cy="rls-applied">
            <Lock variant="small" />
            Row Level Security is Applied
          </Typography>
        )}
        {isMobile && (
          <>
            {this.renderMobileFilterDialog()}
            {this.renderMobileDrillBreadCrumpDialog()}
            {this.renderMobileMoreMenu()}
            {this.renderMobileLockAndCompare()}
          </>
        )}
        {this.processLoader()}
        {!thumbnail && (
          <div>
            {this.renderQueryCard()}
            {cardDeleted && cardDelStatus && cardDelStatus.status >= 200 && cardDelStatus.status < 300 && (
              <Redirect to="/cards" />
            )}
          </div>
        )}
        {this.redirectToFromTextCard()}
      </>
    )
  }

  handleTimePeriodClose = () => {
    const { clearSelectedTimePeriod } = this.props
    this.setState({ timePeriodLabelClicked: null })

    try {
      clearSelectedTimePeriod()
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err, 'caught error while parsing timeperiod in handleTimeChange in Cardviewer.js')
    }
  }

  renderTimePeriodPopup() {
    const { thumbnail, isMobile, selectedTimePeriod } = this.props
    const { timePeriodLabelClicked } = this.state
    const isApplyDisabled =
      !selectedTimePeriod || (!selectedTimePeriod.timePeriod && !selectedTimePeriod.secondaryTimePeriods)

    return (
      timePeriodLabelClicked &&
      !isMobile &&
      !thumbnail && (
        <Popover
          id="time-period-card-viewer"
          anchorEl={timePeriodLabelClicked}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          open={Boolean(timePeriodLabelClicked)}
          className="filters-popover-form popover-time-period"
          onClose={() => {
            this.setState({ timePeriodLabelClicked: null })
          }}
        >
          <div className="time-period-content">
            <Typography variant="h2">Time Period</Typography>
            <IconButton id="close-btn" onClick={() => this.handleTimePeriodClose()} aria-label="Close Time Period">
              <Close />
            </IconButton>
          </div>
          <div className="viewer-time-period-container">{!thumbnail && this.renderTimePeriod()}</div>
          <div className="apply-timeperiod-button">
            {!isApplyDisabled && (
              <Typography className="time-period-warning">
                <InfoOutlined className="info-icon" /> Press Apply to update
              </Typography>
            )}

            <Button
              id="cancel-timeperiod"
              aria-label="Cancel relative dates"
              variant="text"
              color="secondary"
              onClick={() => this.handleTimePeriodClose()}
            >
              Cancel
            </Button>
            <Button
              id="apply-time-period-btn"
              data-cy="apply-time-period-btn"
              aria-label="Apply relative dates"
              variant="text"
              color="primary"
              onClick={() => {
                this.handleOnApplyTimePeriod()
                this.handleTimePeriodClose()
              }}
              disabled={isApplyDisabled}
            >
              Apply
            </Button>
          </div>
        </Popover>
      )
    )
  }

  smartExportMessageRouter = () => {
    const { smartExportErrorStateObj } = this.state

    if (Object.keys(smartExportErrorStateObj).length) {
      if (smartExportErrorStateObj.type === 'tokenExpire' && !this.state.smartExportLoading) {
        return this.renderSmartExportErrorState(true)
      } else if (smartExportErrorStateObj.type === 'serviceError') {
        // Note; this is not an else. We only want to call renderSmartExportErrorState with false when the error type is serviceError - Art/49754518
        return this.renderSmartExportErrorState(false)
      }
    }

    return null
  }

  renderNotAllRowsLoadedDialog = () => {
    const { smartExportData } = this.state
    const { renderCard, cardId, cardInfo } = this.props

    if (
      smartExportData &&
      smartExportData.query_results &&
      smartExportData.query_results.length &&
      renderCard[cardId] &&
      renderCard[cardId].data &&
      renderCard[cardId].status >= 200 &&
      renderCard[cardId].status < 300 &&
      renderCard[cardId].data.query_results &&
      renderCard[cardId].data.context
    ) {
      const largeExportEnable = cardInfo?.data?.card_query_attribute?.large_export_enabled // eslint-disable-line camelcase
      // Using a conditional so the following line(s) wont throw an error - Art/49754518

      const maxEstRows = this.insertComma(
        renderCard[cardId].data.context.rendered_payload.resource_adjusted_max_rows || 0
      )
      const totalReturned = this.insertComma(smartExportData.query_results.length)
      const upperLimitRowEstimate = this.insertComma(smartExportData.upper_limit_row_estimate || 0)

      return (
        <Dialog open={this.state.notAllRowsLoadedDialogStatus}>
          <DialogTitle className="smartExportDialogTitle" onClose={this.handleToggleNotAllRowsDialogStatus}>
            <Tooltip title="More detail">
              <IconButton onClick={this.handleToggleSmartExportDebugSection}>
                <MoreVert />
              </IconButton>
            </Tooltip>

            <Tooltip title="Close">
              <IconButton aria-label="close" onClick={this.handleToggleNotAllRowsDialogStatus}>
                <Close />
              </IconButton>
            </Tooltip>
          </DialogTitle>
          <DialogContent className="display-linebreak">
            {SMART_EXPORT_COULD_NOT_GET_ALL_ROW_MESSAGE(totalReturned)}
          </DialogContent>
          <DialogActions className="smartExportAlignItems">
            <div className="smartExportActionItems">
              <Button onClick={this.handleToggleNotAllRowsDialogStatus} color="primary">
                Cancel
              </Button>

              {largeExportEnable && (
                <Button onClick={this.handleTriggerLargeExportInit} color="primary">
                  Create Large Export Request
                </Button>
              )}

              <div>
                <Button onClick={() => this.handleSmartExportDownloadAnyway()} className="smartExportDownloadAnywayBtn">
                  Download anyway
                </Button>
              </div>
            </div>
          </DialogActions>

          {this.state.showSmartExportDebugSection && (
            <div className="smartExportDebugSection">
              <div className="smartExportDebugItem">Card row estimate : {maxEstRows}</div>
              <div className="smartExportDebugItem">Rows safely exportable estimate: {upperLimitRowEstimate}</div>
              <div className="smartExportDebugItem">Actual rows returned: {totalReturned}</div>
            </div>
          )}
        </Dialog>
      )
    }

    return null
  }

  renderSmartExportErrorState = tokenExpire => {
    const { smartExportErrorStateObj, smartExportInErrorState } = this.state

    if (tokenExpire && smartExportInErrorState) {
      this.setState(
        {
          smartExportInErrorState: false,
          loadingNewToken: true,
        },
        () => {
          this.props.refreshCard()
        }
      )
    }

    return (
      <Dialog
        open={this.state.notAllRowsLoadedDialogStatus}
        onClose={!tokenExpire ? this.handleToggleNotAllRowsDialogStatus : null}
      >
        <DialogContent>
          <DialogContentText className="display-linebreak">{smartExportErrorStateObj.message}</DialogContentText>
        </DialogContent>
      </Dialog>
    )
  }

  renderLargeExport = () => {
    const { largeExportDialogIsOpen, selectedFilterId } = this.state
    const {
      renderCard,
      cardId,
      cardRender,
      isMobile,
      deviceType,
      isTimePeriodDrillThrough,
      cardInfo,
      appliedFilters,
      dateFilterStatus,
      timePeriod,
      selectedDatasetObj,
      filterMetadataStatus,
      selectedTimePeriod,
      secondaryTimePeriod,
    } = this.props
    const availableFilters = this.getUpdatedFilterViewer(
      cardInfo?.data?.card_query_attribute?.viewer_filters, // eslint-disable-line camelcase
      cardInfo?.data?.card_query_attribute?.columns // eslint-disable-line camelcase
    )

    return (
      <LargeExportInit
        cardInfo={cardInfo}
        cardId={cardId}
        selectedDatasetObj={selectedDatasetObj}
        timePeriod={timePeriod}
        dateFilterStatus={dateFilterStatus}
        renderCard={renderCard}
        toggleDialog={this.handleTriggerLargeExportInit} // eslint-disable-line
        isOpen={largeExportDialogIsOpen}
        appliedFilters={appliedFilters}
        timeViewerJson={this.getLargeExportTimePeriodViewer()}
        filterMetadataStatus={filterMetadataStatus}
        cardRender={cardRender}
        isDashboard={false}
        routeProps={this.props.routeProps}
        onTimePeriodChange={this.handleTimeChange}
        isMobile={isMobile}
        deviceType={deviceType}
        isTimePeriodDrillThrough={isTimePeriodDrillThrough}
        availableFilters={availableFilters}
        selectedFilterId={selectedFilterId}
        selectedTimeDiv={this.renderSelectedTimePeriod.bind(this)}
        triggerLoading={this.toggleLoadingState}
        closeMobilePopover={this.closeMobilePopOver}
        hideViewerTimePeriod={
          renderCard[cardId] && renderCard[cardId]?.status >= 200 && renderCard[cardId]?.status < 300
            ? renderCard[cardId]?.data?.card_config?.card_query_attribute?.time_period?.hide_time_period_viewer // eslint-disable-line camelcase
            : false
        }
        selectedTimePeriod={selectedTimePeriod}
        secondaryTime={secondaryTimePeriod}
      />
    )
  }

  renderCardContents = vizType => {
    const {
      cardId,
      thumbnail,
      cardSize,
      routeProps,
      trackEvent,
      print,
      isHomePage,
      objectNotificationStatus,
      cardInfo,
      selectedDatasetObj,
      isMobile,
    } = this.props
    const { shortLinkVisible } = this.state
    const cardAttribute = cardInfo?.data?.card_attribute
    const datasetId = cardAttribute?.dataset_id
    const datasetObj = selectedDatasetObj && selectedDatasetObj[datasetId] ? selectedDatasetObj[datasetId].data : {}

    const style =
      vizType?.toLowerCase() === 'text' && typeof cardSize.height === 'number' && cardSize.height !== 0
        ? { height: 'inherit' }
        : cardSize && cardSize.height
        ? { height: `${cardSize.height * this.getHeightMultiplier(cardSize.height)}px` }
        : {}

    const cardHeightClass =
      thumbnail && cardSize && !Number.isInteger(cardSize.height)
        ? 'page-cards'
        : cardSize && Number.isInteger(cardSize.height)
        ? ''
        : 'gf-card-page page-cards'
    const onDismissShortlinkModal = () => {
      this.setState({
        shortLinkVisible: false,
      })
    }
    const cardLevelNotifications = (objectNotificationStatus?.data && objectNotificationStatus?.data[cardId]) || []
    const datasetLevelNotifications =
      (objectNotificationStatus?.data && objectNotificationStatus?.data[datasetId]) || []
    const combine = [...cardLevelNotifications, ...datasetLevelNotifications]
    return (
      <div
        style={style}
        className={`cardviewer-container ${cardHeightClass}`}
        id={`card-${cardId || Math.random().toString(36).substring(2)}`}
      >
        {shortLinkVisible && (
          <Generateshortlink
            trackEvent={trackEvent}
            routeProps={routeProps}
            onDismiss={onDismissShortlinkModal}
            type="card"
            id={cardId}
          />
        )}
        {this.renderNotAllRowsLoadedDialog()}
        {this.renderLargeExport()}
        {this.smartExportMessageRouter()}
        {this.renderCardMetadata()}

        {!print && !isHomePage && !thumbnail && objectNotificationStatus?.status && objectNotificationStatus?.data && (
          <Notifications
            notificationData={combine}
            level={thumbnail ? 'dashboard' : 'card'}
            isDatasetEdit={datasetObj?.edit_enabled === 'yes'}
            datasetId={datasetId}
            isViewerEdit={cardAttribute?.edit_enabled === 'yes'}
            viewerId={cardId}
            sendFireflyEvent={this.sendFireflyEvent}
            isMobile={isMobile}
          />
        )}

        {this.renderCardContent()}
        {this.state.dataAlertDialogOpen && (
          <DataAlertSubscriptionDialog
            open={this.state.dataAlertDialogOpen}
            card={this.props.cardInfo}
            handleClose={() => this.setState({ dataAlertDialogOpen: false })}
            timePeriod={this.props.timePeriod}
            secondaryTimePeriod={this.props.secondaryTimePeriod}
            appliedFilters={this.props.appliedFilters}
            selectedDataset={selectedDatasetObj[datasetId]?.data}
            defaultUser={this.props.defaultUser}
            label={DATA_ALERT}
          />
        )}
        {this.state.subscriptionDialogOpen && (
          <DataAlertSubscriptionDialog
            open={this.state.subscriptionDialogOpen}
            card={this.props.cardInfo}
            handleClose={() => this.setState({ subscriptionDialogOpen: false })}
            timePeriod={this.props.timePeriod}
            secondaryTimePeriod={this.props.secondaryTimePeriod}
            appliedFilters={this.props.appliedFilters}
            selectedDataset={selectedDatasetObj[datasetId]?.data}
            defaultUser={this.props.defaultUser}
            label={CARD_SUBSCRIPTION}
          />
        )}
      </div>
    )
  }

  renderSupportDialog = () => {
    const { renderCard, cardId, cardInfo } = this.props
    const card = renderCard[cardId]

    return (
      <SupportInfo
        cardInfo={card.data}
        cardMeta={cardInfo}
        status={card.status}
        expanded={this.state.showSupportDialog}
        setExpanded={this.setShowSupportDialog}
      />
    )
  }

  setShowSupportDialog = isShowSupport => {
    this.setState({
      showSupportDialog: isShowSupport,
    })
  }

  handleSupportClick = () => {
    this.setState({
      showSupportDialog: !this.state.showSupportDialog,
      openActionsMenu: null,
      expandLess: false,
    })
  }

  handleClickCreateNotificationMenu = event => {
    this.setState({ notificationAnchorEl: event.currentTarget })
  }

  render() {
    const { preloadedData } = this.props
    const vizType = preloadedData?.card_config?.card_attribute?.viz_type // eslint-disable-line
      ? preloadedData.card_config.card_attribute.viz_type.toLowerCase() // eslint-disable-line
      : null

    // console error here, see https://github.com/derrickpelletier/react-loading-overlay/issues/56.  Should probably replace this component.
    return vizType === 'big_number' || vizType?.toLowerCase() === 'text' ? (
      this.renderCardContents(vizType)
    ) : (
      <LoadingOverlay
        className="smartExportLoadingOverlay"
        active={this.state.smartExportLoading}
        spinner
        text="Getting your data..."
      >
        {this.renderCardContents(vizType)}
      </LoadingOverlay>
    )
  }
}

Cardviewer.defaultProps = {
  timePeriod: {},
  cardInfo: {},
  routeProps: {},
  renderCard: {},
  activeFilters: [],
  applyFilter: () => {},
  cardRender: () => {},
  displayServiceErrorMessage: () => {},
  setDrill: () => {},
  drill: [],
  pagination: true,
  print: false,
  isHomePage: false,
  routerTimePeriod: null,
  drillthrough: [],
  treeTableCsvData: {},
  anchorDateFilterStatus: {},
  drillCardMetaStatus: {},
  errorMessage: '',
}

Cardviewer.propTypes = {
  displayServiceErrorMessage: PropTypes.func,
  cardInfo: PropTypes.object,
  routeProps: PropTypes.object,
  renderCard: PropTypes.object,
  activeFilters: PropTypes.array,
  applyFilter: PropTypes.func,
  cardRender: PropTypes.func,
  setDrill: PropTypes.func,
  setColumnSwap: PropTypes.func,
  drill: PropTypes.array,
  clearCardWithoutRerender: PropTypes.func,
  updateCardWithoutRerender: PropTypes.func,
  isHomePage: PropTypes.bool,
  routerTimePeriod: PropTypes.object,
  refreshCard: PropTypes.func,
  treeTableCsvData: PropTypes.object,
  anchorDateFilterStatus: PropTypes.object,
  animationsDisabled: PropTypes.bool,
}

export default Cardviewer
