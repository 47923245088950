import { call, put, all } from 'redux-saga/effects'
import axios from 'axios'
import { API_GATEWAY_URL } from './utils'

// Action types
export const EXECUTE_CALCULATED_FIELD_REQUESTED = 'EXECUTE_CALCULATED_FIELD_REQUESTED'
export const EXECUTE_CALCULATED_FIELD_SUCCEEDED = 'EXECUTE_CALCULATED_FIELD_SUCCEEDED'
export const EXECUTE_CALCULATED_FIELD_FAILED = 'EXECUTE_CALCULATED_FIELD_FAILED'

export const CREATE_CALCULATED_FIELD_REQUESTED = 'CREATE_CALCULATED_FIELD_REQUESTED'
export const CREATE_CALCULATED_FIELD_SUCCEEDED = 'CREATE_CALCULATED_FIELD_SUCCEEDED'
export const CREATE_CALCULATED_FIELD_FAILED = 'CREATE_CALCULATED_FIELD_FAILED'

export const EDIT_CALCULATED_FIELD_REQUESTED = 'EDIT_CALCULATED_FIELD_REQUESTED'
export const EDIT_CALCULATED_FIELD_SUCCEEDED = 'EDIT_CALCULATED_FIELD_SUCCEEDED'
export const EDIT_CALCULATED_FIELD_FAILED = 'EDIT_CALCULATED_FIELD_FAILED'

export const EDIT_ALL_CALCULATED_FIELDS_REQUESTED = 'EDIT_ALL_CALCULATED_FIELDS_REQUESTED'
export const EDIT_ALL_CALCULATED_FIELDS_SUCCEEDED = 'EDIT_ALL_CALCULATED_FIELDS_SUCCEEDED'
export const EDIT_ALL_CALCULATED_FIELDS_FAILED = 'EDIT_ALL_CALCULATED_FIELDS_FAILED'

export const SEARCH_CALCULATED_FIELD_REQUESTED = 'SEARCH_CALCULATED_FIELD_REQUESTED'
export const SEARCH_CALCULATED_FIELD_SUCCEEDED = 'SEARCH_CALCULATED_FIELD_SUCCEEDED'
export const SEARCH_CALCULATED_FIELD_FAILED = 'SEARCH_CALCULATED_FIELD_FAILED'

export const GET_CALCULATED_FIELD_FN_HELP_REQUESTED = 'GET_CALCULATED_FIELD_FN_HELP_REQUESTED'
export const GET_CALCULATED_FIELD_FN_HELP_SUCCEEDED = 'GET_CALCULATED_FIELD_FN_HELP_SUCCEEDED'
export const GET_CALCULATED_FIELD_FN_HELP_FAILED = 'GET_CALCULATED_FIELD_FN_HELP_FAILED'

export const GET_CALCULATED_FIELD_REQUESTED = 'GET_CALCULATED_FIELD_REQUESTED'
export const GET_CALCULATED_FIELD_SUCCEEDED = 'GET_CALCULATED_FIELD_SUCCEEDED'
export const GET_CALCULATED_FIELD_FAILED = 'GET_CALCULATED_FIELD_FAILED'

// Reducer
export default function calculatedFieldReducer(state = {}, action = {}) {
  switch (action.type) {
    case EXECUTE_CALCULATED_FIELD_REQUESTED: {
      const newState = {
        ...state,
        executeCalculatedFieldStatus: {
          status: 'requested',
        },
      }
      return newState
    }
    case EXECUTE_CALCULATED_FIELD_SUCCEEDED: {
      const newState = {
        ...state,
        executeCalculatedFieldStatus: action.payload,
      }
      return newState
    }
    case EXECUTE_CALCULATED_FIELD_FAILED: {
      const newState = {
        ...state,
        executeCalculatedFieldStatus: action.payload.response,
      }
      return newState
    }

    case CREATE_CALCULATED_FIELD_REQUESTED: {
      const newState = {
        ...state,
        createCalculatedFieldStatus: {
          status: 'requested',
        },
      }
      return newState
    }
    case CREATE_CALCULATED_FIELD_SUCCEEDED: {
      const newState = {
        ...state,
        createCalculatedFieldStatus: action.payload,
      }
      return newState
    }
    case CREATE_CALCULATED_FIELD_FAILED: {
      const newState = {
        ...state,
        createCalculatedFieldStatus: action.payload.response,
      }
      return newState
    }

    case EDIT_CALCULATED_FIELD_REQUESTED: {
      const newState = {
        ...state,
        editCalculatedFieldStatus: {
          status: 'requested',
        },
      }
      return newState
    }
    case EDIT_CALCULATED_FIELD_SUCCEEDED: {
      const newState = {
        ...state,
        editCalculatedFieldStatus: action.payload,
      }
      return newState
    }
    case EDIT_CALCULATED_FIELD_FAILED: {
      const newState = {
        ...state,
        editCalculatedFieldStatus: action.payload.response,
      }
      return newState
    }

    case GET_CALCULATED_FIELD_REQUESTED: {
      const id = action.payload.id
      const newState = {
        ...state,
        getCalculatedFieldStatus: {
          ...state.getCalculatedFieldStatus,
          [id]: {
            status: 'requested',
          },
        },
      }
      return newState
    }

    case GET_CALCULATED_FIELD_SUCCEEDED: {
      const id = action.payload.data._id
      const newState = {
        ...state,
        getCalculatedFieldStatus: {
          ...state.getCalculatedFieldStatus,
          [id]: action.payload,
        },
      }
      return newState
    }

    case GET_CALCULATED_FIELD_FAILED: {
      const id = action.payload.id
      const newState = {
        ...state,
        getCalculatedFieldStatus: {
          ...state.getCalculatedFieldStatus,
          [id]: {
            status: 'failed',
          },
        },
      }
      return newState
    }

    case EDIT_ALL_CALCULATED_FIELDS_REQUESTED: {
      const newState = {
        ...state,
        editAllCalculatedFieldsStatus: {
          status: 'requested',
        },
      }
      return newState
    }
    case EDIT_ALL_CALCULATED_FIELDS_SUCCEEDED: {
      const newState = {
        ...state,
        editAllCalculatedFieldsStatus: action.payload,
      }
      return newState
    }
    case EDIT_ALL_CALCULATED_FIELDS_FAILED: {
      const newState = {
        ...state,
        editAllCalculatedFieldsStatus: action.payload.response,
      }
      return newState
    }

    case SEARCH_CALCULATED_FIELD_REQUESTED: {
      const newState = {
        ...state,
        searchCalculatedFieldStatus: {
          status: 'requested',
        },
      }
      return newState
    }
    case SEARCH_CALCULATED_FIELD_SUCCEEDED: {
      const newState = {
        ...state,
        searchCalculatedFieldStatus: action.payload,
      }
      return newState
    }
    case SEARCH_CALCULATED_FIELD_FAILED: {
      const newState = {
        ...state,
        searchCalculatedFieldStatus: action.payload.response,
      }
      return newState
    }

    case GET_CALCULATED_FIELD_FN_HELP_REQUESTED: {
      const newState = {
        ...state,
        getCalculatedFieldFnHelpStatus: {
          status: 'requested',
        },
      }
      return newState
    }
    case GET_CALCULATED_FIELD_FN_HELP_SUCCEEDED: {
      const newState = {
        ...state,
        getCalculatedFieldFnHelpStatus: action.payload,
      }
      return newState
    }
    case GET_CALCULATED_FIELD_FN_HELP_FAILED: {
      const newState = {
        ...state,
        getCalculatedFieldFnHelpStatus: action.payload.response,
      }
      return newState
    }

    default:
      return state
  }
}

// Actions
export function executeCalculatedField(data) {
  return {
    type: EXECUTE_CALCULATED_FIELD_REQUESTED,
    payload: data,
  }
}

export function createCalculatedField(data) {
  return {
    type: CREATE_CALCULATED_FIELD_REQUESTED,
    payload: data,
  }
}

export function editCalculatedField(data) {
  return {
    type: EDIT_CALCULATED_FIELD_REQUESTED,
    payload: data.payload,
    id: data.calFieldId,
  }
}

export function editAllCalculatedFields(data) {
  return {
    type: EDIT_ALL_CALCULATED_FIELDS_REQUESTED,
    payload: data.payload,
    datasetId: data.datasetId,
  }
}

export function searchCalculatedField(data) {
  return {
    type: SEARCH_CALCULATED_FIELD_REQUESTED,
    payload: data,
  }
}

export function getCalculatedFieldFnHelp(data) {
  return {
    type: GET_CALCULATED_FIELD_FN_HELP_REQUESTED,
    payload: data,
  }
}

export function getCalculatedField(data) {
  return {
    type: GET_CALCULATED_FIELD_REQUESTED,
    payload: data,
  }
}

// Sagas (service requests)
export function* postExecuteCalculatedField(action) {
  try {
    const executeCal = yield call(axios, {
      method: 'post',
      url: `${API_GATEWAY_URL}/bi_reporting_assets/v2/execute_calculated_metrics`,
      data: action.payload,
    })
    if (executeCal.error) {
      const error = executeCal.error
      throw new Error(`status: ${error.code}, ${error.type} - ${error.message}`)
    }
    yield put({ type: EXECUTE_CALCULATED_FIELD_SUCCEEDED, payload: executeCal })
  } catch (error) {
    yield put({ type: EXECUTE_CALCULATED_FIELD_FAILED, payload: error })
  }
}

export function* postCreateCalculatedField(action) {
  try {
    const createCal = yield call(axios, {
      method: 'post',
      url: `${API_GATEWAY_URL}/bi_reporting_assets/v2/calculated_metrics`,
      data: action.payload,
    })

    if (createCal.error) {
      const error = createCal.error
      throw new Error(`status: ${error.code}, ${error.type} - ${error.message}`)
    }

    yield put({ type: CREATE_CALCULATED_FIELD_SUCCEEDED, payload: createCal })
  } catch (error) {
    yield put({ type: CREATE_CALCULATED_FIELD_FAILED, payload: error })
  }
}

export function* getCalculatedFieldReq(action) {
  try {
    const getCal = yield call(axios, {
      method: 'get',
      url: `${API_GATEWAY_URL}/bi_reporting_assets/v2/calculated_metrics/${action.payload.id}`,
    })

    if (getCal.error) {
      const error = getCal.error
      throw new Error(`status: ${error.code}, ${error.type} - ${error.message}`)
    }

    yield put({ type: GET_CALCULATED_FIELD_SUCCEEDED, payload: getCal })
  } catch (error) {
    yield put({ type: GET_CALCULATED_FIELD_FAILED, payload: error })
  }
}

export function* updateCalculatedField(action) {
  try {
    const editCal = yield call(axios, {
      method: 'put',
      url: `${API_GATEWAY_URL}/bi_reporting_assets/v2/calculated_metrics/${action.id}`,
      data: action.payload,
    })

    if (editCal.error) {
      const error = editCal.error
      throw new Error(`status: ${error.code}, ${error.type} - ${error.message}`)
    }

    yield put({ type: EDIT_CALCULATED_FIELD_SUCCEEDED, payload: editCal })
  } catch (error) {
    yield put({ type: EDIT_CALCULATED_FIELD_FAILED, payload: error })
  }
}

export function* updateAllCalculatedFields(action) {
  try {
    const params = action.payload.map(payloadIdx => {
      if (payloadIdx.hasChanged) {
        delete payloadIdx.hasChanged
        delete payloadIdx.datasetId
        payloadIdx.dataset_id = action.datasetId
        return {
          method: 'put',
          url: `${API_GATEWAY_URL}/bi_reporting_assets/v2/calculated_metrics/${payloadIdx.calculated_field_metadata._id}?validate_expression=false`,
          data: payloadIdx,
        }
      }

      return undefined
    })
    const responses = yield all(params.map(p => p && call(axios, p)))

    const resp = mergeResp(responses)

    const returnData = {
      data: resp,
      status: 200,
    }

    yield put({ type: EDIT_ALL_CALCULATED_FIELDS_SUCCEEDED, payload: returnData })
  } catch (error) {
    yield put({ type: EDIT_ALL_CALCULATED_FIELDS_FAILED, payload: error })
  }
}

export function* getSearchCalculatedField(action) {
  try {
    const searchCal = yield call(axios, {
      method: 'get',
      url:
        `${API_GATEWAY_URL}/bi_reporting_assets/v2/calculated_metrics?page=1&per_page=${action.payload.perPage}&` +
        `search_string=${action.payload.searchText}&dataset_id=${action.payload.datasetId}`,
    })

    if (searchCal.error) {
      const error = searchCal.error
      throw new Error(`status: ${error.code}, ${error.type} - ${error.message}`)
    }

    yield put({ type: SEARCH_CALCULATED_FIELD_SUCCEEDED, payload: searchCal })
  } catch (error) {
    yield put({ type: SEARCH_CALCULATED_FIELD_FAILED, payload: error })
  }
}

export function* getCalculatedFieldFnHelpReq() {
  try {
    const getCalFnHelp = yield call(axios, {
      method: 'get',
      url: `${API_GATEWAY_URL}/bi_reporting_assets/v2/metadata?type=calculated_field_function_help`,
    })

    if (getCalFnHelp.error) {
      const error = getCalFnHelp.error
      throw new Error(`status: ${error.code}, ${error.type} - ${error.message}`)
    }

    yield put({ type: GET_CALCULATED_FIELD_FN_HELP_SUCCEEDED, payload: getCalFnHelp })
  } catch (error) {
    yield put({ type: GET_CALCULATED_FIELD_FN_HELP_FAILED, payload: error })
  }
}

function mergeResp(arr) {
  return arr.reduce((prev, item) => {
    prev.push(item?.data)

    return prev
  }, [])
}
