import React from 'react'
import CryptoJS from 'crypto-js'
import { StylesProvider } from '@mui/styles'
import { Router, Route, Switch } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import { Helmet } from 'react-helmet'
import { AuthProvider } from '@praxis/component-auth'
import { withAnalytics } from '@praxis/component-analytics'
import { LicenseManager } from '@ag-grid-enterprise/core'

/* CSS Commmunity */
import '@ag-grid-community/core/dist/styles/ag-grid.css'
import '@ag-grid-community/core/dist/styles/ag-theme-alpine.css'

import '../styles/thirdPartyStyles/index.scss'
import apiConfig from '../config/apiConfig'
import analyticsConfig from './analytics'
import ViewController from './ViewController'

const decryptedAgGridLicenseKey =
  process.env.AG_GRID_LICENSE_KEY &&
  CryptoJS.AES.decrypt(process.env.AG_GRID_LICENSE_KEY, apiConfig.agGridDecryptKey).toString(CryptoJS.enc.Utf8)

LicenseManager.setLicenseKey(decryptedAgGridLicenseKey)

const history = createBrowserHistory()
class App extends React.Component {
  render() {
    return (
      <StylesProvider>
        <Helmet defaultTitle="Greenfield" />
        <AuthProvider {...apiConfig.auth} hideLoadingIndicator>
          <Router history={history}>
            <Switch>
              <Route path="/" render={routeProps => <ViewController routeProps={routeProps} />} />
            </Switch>
          </Router>
        </AuthProvider>
      </StylesProvider>
    )
  }
}

export default withAnalytics(analyticsConfig)(App)
