import React, { useState } from 'react'
import { Button } from '@mui/material'
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import { SiteDetailComponentMobile } from './SiteDetailComponentMobile'

export const SiteDetailButtonRenderer = props => {
  const { isMobile, node, api, data, handleToggleSiteDetail } = props
  const rowNode = api.getDisplayedRowAtIndex(node.rowIndex)

  const [mobileDetails, setMobileDetails] = useState({
    mobileSubComponentActive: false,
    mobileTableIndex: null,
    rowData: '',
  })

  const { mobileSubComponentActive, rowData } = mobileDetails

  const handleToggleMobileComponent = (node, rowData) => {
    setMobileDetails({
      mobileSubComponentActive: !mobileDetails.mobileSubComponentActive,
      mobileTableIndex: node.rowIndex,
      rowData,
    })
  }

  return (
    <>
      <Button
        id={`view-details-btn-${node.rowIndex}`}
        data-cy={`view-details-btn-${node.rowIndex}`}
        className="view-details-button"
        size="small"
        endIcon={!rowNode.expanded ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
        variant="outlined"
        aria-label="View Details"
        onClick={() => (isMobile ? handleToggleMobileComponent(node, data) : handleToggleSiteDetail(api, node))}
      >
        {!rowNode.expanded ? 'View Details' : 'Hide Details'}
      </Button>
      {mobileSubComponentActive && (
        <SiteDetailComponentMobile rowData={rowData} state={mobileDetails} setState={setMobileDetails} {...props} />
      )}
    </>
  )
}
