import { call, put } from 'redux-saga/effects'
import axios from 'axios'
import { API_GATEWAY_URL } from './utils'

// Action types
export const CREATE_FEATURE_FLAG_REQUESTED = 'CREATE_FEATURE_FLAG_REQUESTED'
export const CREATE_FEATURE_FLAG_SUCCEEDED = 'CREATE_FEATURE_FLAG_SUCCEEDED'
export const CREATE_FEATURE_FLAG_FAILED = 'CREATE_FEATURE_FLAG_FAILED'

export const UPDATE_FEATURE_FLAG_REQUESTED = 'UPDATE_FEATURE_FLAG_REQUESTED'
export const UPDATE_FEATURE_FLAG_SUCCEEDED = 'UPDATE_FEATURE_FLAG_SUCCEEDED'
export const UPDATE_FEATURE_FLAG_FAILED = 'UPDATE_FEATURE_FLAG_FAILED'

export const UPDATE_FEATURE_FLAG_OPT_IN_REQUESTED = 'UPDATE_FEATURE_FLAG_OPT_IN_REQUESTED'
export const UPDATE_FEATURE_FLAG_OPT_IN_SUCCEEDED = 'UPDATE_FEATURE_FLAG_OPT_IN_SUCCEEDED'
export const UPDATE_FEATURE_FLAG_OPT_IN_FAILED = 'UPDATE_FEATURE_FLAG_OPT_IN_FAILED'

export const FETCH_FEATURE_FLAGS_REQUESTED = 'FETCH_FEATURE_FLAGS_REQUESTED'
export const FETCH_FEATURE_FLAGS_SUCCEEDED = 'FETCH_FEATURE_FLAGS_SUCCEEDED'
export const FETCH_FEATURE_FLAGS_FAILED = 'FETCH_FEATURE_FLAGS_FAILED'

export const DELETE_FEATURE_FLAG_REQUESTED = 'DELETE_FEATURE_FLAG_REQUESTED'
export const DELETE_FEATURE_FLAG_SUCCEEDED = 'DELETE_FEATURE_FLAG_SUCCEEDED'
export const DELETE_FEATURE_FLAG_FAILED = 'DELETE_FEATURE_FLAG_FAILED'

export const CLEAR_FEATURE_FLAG_CREATE = 'CLEAR_FEATURE_FLAG_CREATE'
export const CLEAR_FEATURE_FLAG_DELETE = 'CLEAR_FEATURE_FLAG_DELETE'
export const CLEAR_FEATURE_FLAG_UPDATE = 'CLEAR_FEATURE_FLAG_UPDATE'
export const CLEAR_FEATURE_FLAG_OPT_IN_UPDATE = 'CLEAR_FEATURE_FLAG_OPT_IN_UPDATE'

// Reducer
export default function featureFlagReducer(state = {}, action = {}) {
  switch (action.type) {
    case CREATE_FEATURE_FLAG_REQUESTED: {
      const newState = {
        ...state,
        featureFlagCreateStatus: {
          status: 'requested',
        },
      }
      return newState
    }
    case CREATE_FEATURE_FLAG_SUCCEEDED: {
      const newState = {
        ...state,
        featureFlagCreateStatus: action.payload,
      }
      return newState
    }
    case CREATE_FEATURE_FLAG_FAILED: {
      const newState = {
        ...state,
        featureFlagCreateStatus: {
          status: 'failed',
          error: action.payload,
        },
      }
      return newState
    }
    case CLEAR_FEATURE_FLAG_CREATE: {
      const newState = {
        ...state,
        featureFlagCreateStatus: {},
      }
      return newState
    }
    case UPDATE_FEATURE_FLAG_REQUESTED: {
      const newState = {
        ...state,
        featureFlagUpdateStatus: {
          status: 'requested',
        },
      }
      return newState
    }
    case UPDATE_FEATURE_FLAG_SUCCEEDED: {
      const newState = {
        ...state,
        featureFlagUpdateStatus: action.payload,
      }
      return newState
    }
    case UPDATE_FEATURE_FLAG_FAILED: {
      const newState = {
        ...state,
        featureFlagUpdateStatus: {
          status: 'failed',
          error: action.payload,
        },
      }
      return newState
    }
    case CLEAR_FEATURE_FLAG_UPDATE: {
      const newState = {
        ...state,
        featureFlagUpdateStatus: {},
      }
      return newState
    }
    case UPDATE_FEATURE_FLAG_OPT_IN_REQUESTED: {
      const newState = {
        ...state,
        featureFlagOptInStatus: {
          status: 'requested',
        },
      }
      return newState
    }
    case UPDATE_FEATURE_FLAG_OPT_IN_SUCCEEDED: {
      const newState = {
        ...state,
        featureFlagOptInStatus: action.payload,
      }
      return newState
    }
    case UPDATE_FEATURE_FLAG_OPT_IN_FAILED: {
      const newState = {
        ...state,
        featureFlagOptInStatus: {
          status: 'failed',
          error: action.payload,
        },
      }
      return newState
    }
    case CLEAR_FEATURE_FLAG_OPT_IN_UPDATE: {
      const newState = {
        ...state,
        featureFlagOptInStatus: {},
      }
      return newState
    }
    case FETCH_FEATURE_FLAGS_REQUESTED: {
      const newState = {
        ...state,
        fetchfeatureFlagsStatus: {
          status: 'requested',
        },
      }
      return newState
    }
    case FETCH_FEATURE_FLAGS_SUCCEEDED: {
      const newState = {
        ...state,
        fetchfeatureFlagsStatus: action.payload,
      }
      return newState
    }
    case FETCH_FEATURE_FLAGS_FAILED: {
      const newState = {
        ...state,
        fetchfeatureFlagsStatus: {
          status: 'failed',
          error: action.payload,
        },
      }
      return newState
    }
    case DELETE_FEATURE_FLAG_REQUESTED: {
      const newState = {
        ...state,
        deleteFeatureFlagStatus: {
          status: 'requested',
        },
      }
      return newState
    }
    case DELETE_FEATURE_FLAG_SUCCEEDED: {
      const newState = {
        ...state,
        deleteFeatureFlagStatus: action.payload,
      }
      return newState
    }
    case DELETE_FEATURE_FLAG_FAILED: {
      const newState = {
        ...state,
        deleteFeatureFlagStatus: {
          status: 'failed',
          error: action.payload,
        },
      }
      return newState
    }
    case CLEAR_FEATURE_FLAG_DELETE: {
      const newState = {
        ...state,
        deleteFeatureFlagStatus: {},
      }
      return newState
    }
    default:
      return state
  }
}

// Actions
// CREATE FF
export function createFeatureFlag(data) {
  return {
    type: CREATE_FEATURE_FLAG_REQUESTED,
    payload: data,
  }
}

// UPDATE FF
export function updateFeatureFlag(data) {
  return {
    type: UPDATE_FEATURE_FLAG_REQUESTED,
    payload: data,
  }
}

// UPDATE OPT IN
export function updateFeatureFlagOptIn(data) {
  return {
    type: UPDATE_FEATURE_FLAG_OPT_IN_REQUESTED,
    payload: data,
  }
}

// DELETE FF
export function deleteFeatureFlag(data) {
  return {
    type: DELETE_FEATURE_FLAG_REQUESTED,
    payload: data,
  }
}

// GET ALL FF
export function getFeatureFlags(data) {
  return {
    type: FETCH_FEATURE_FLAGS_REQUESTED,
    payload: data,
  }
}

// Cleanup
export function clearFFCreate(data) {
  return {
    type: CLEAR_FEATURE_FLAG_CREATE,
    payload: data,
  }
}

export function clearFFUpdate(data) {
  return {
    type: CLEAR_FEATURE_FLAG_UPDATE,
    payload: data,
  }
}

export function clearFFOptInUpdate(data) {
  return {
    type: CLEAR_FEATURE_FLAG_OPT_IN_UPDATE,
    payload: data,
  }
}

export function clearFFDelete(data) {
  return {
    type: CLEAR_FEATURE_FLAG_DELETE,
    payload: data,
  }
}

// Sagas (service requests)
export function* saveFeatureFlag(action) {
  try {
    const url = `${API_GATEWAY_URL}/bi_reporting_assets/v2/feature_flags`
    const createFeatureFlag = yield call(axios, {
      method: 'post',
      url,
      data: action.payload,
    })
    if (createFeatureFlag.error) {
      const e = createFeatureFlag.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }

    yield put({ type: CREATE_FEATURE_FLAG_SUCCEEDED, payload: createFeatureFlag })
  } catch (e) {
    yield put({ type: CREATE_FEATURE_FLAG_FAILED, payload: { data: action.payload, resp: e.response } })
  }
}

export function* updateFFOptIn(action) {
  try {
    const url = `${API_GATEWAY_URL}/bi_reporting_assets/v2/feature_flags/${action.payload.id}/opt_in`
    const updateOptIn = yield call(axios, {
      method: action.payload.method,
      url,
    })
    if (updateOptIn.error) {
      const e = updateOptIn.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }

    yield put({ type: UPDATE_FEATURE_FLAG_OPT_IN_SUCCEEDED, payload: updateOptIn })
  } catch (e) {
    yield put({ type: UPDATE_FEATURE_FLAG_OPT_IN_FAILED, payload: { data: action.payload, resp: e.response } })
  }
}

export function* updateFF(action) {
  try {
    const url = `${API_GATEWAY_URL}/bi_reporting_assets/v2/feature_flags/${action.payload.id}`
    const updateFeatureFlag = yield call(axios, {
      method: 'patch',
      url,
      data: action.payload.data,
    })
    if (updateFeatureFlag.error) {
      const e = updateFeatureFlag.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }

    yield put({ type: UPDATE_FEATURE_FLAG_SUCCEEDED, payload: updateFeatureFlag })
  } catch (e) {
    yield put({ type: UPDATE_FEATURE_FLAG_FAILED, payload: { data: action.payload, resp: e.response } })
  }
}

export function* fetchAllFeatureFlag(action) {
  try {
    const url = `${API_GATEWAY_URL}/bi_reporting_assets/v2/feature_flags`
    const featureFlags = yield call(axios, {
      method: 'get',
      url,
    })
    if (featureFlags.error) {
      const e = featureFlags.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }

    yield put({ type: FETCH_FEATURE_FLAGS_SUCCEEDED, payload: featureFlags })
  } catch (e) {
    yield put({ type: FETCH_FEATURE_FLAGS_FAILED, payload: { data: action.payload, resp: e.response } })
  }
}

export function* removeFeatureFlag(action) {
  try {
    const url = `${API_GATEWAY_URL}/bi_reporting_assets/v2/feature_flags/${action.payload}`
    const deleteFF = yield call(axios, {
      method: 'delete',
      url,
    })
    if (deleteFF.error) {
      const e = deleteFF.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }

    yield put({ type: DELETE_FEATURE_FLAG_SUCCEEDED, payload: deleteFF })
  } catch (e) {
    yield put({ type: DELETE_FEATURE_FLAG_FAILED, payload: { data: action.payload, resp: e.response } })
  }
}
