import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Grid, TextField, Typography, MenuItem } from '@mui/material'
import { Add, Check, Close, DeleteOutlined } from '@mui/icons-material'
import startCase from 'lodash/startCase'
import styled from 'styled-components'
import cloneDeep from 'lodash/cloneDeep'
import { getFilterMetadata } from '../../../../../ducks/metadata'
import { DividerWithText, getEmailCriteriaText } from './Helper'
import { Item } from './EmailStep'

const defaultFilterParams = {
  column_display_name: '',
  display_name: '',
  type: 'greaterThan',
  value: 0,
  lowervalue: 0,
  uppervalue: 0,
}

const SButton = styled(Button)`
  font-size: 16px;
  padding: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;
`

const DataAlertSubscriptionConfig = props => {
  const {
    allMetricFilters,
    appliedMetricFilters,
    setAppliedMetricFilters,
    setEmailMessage,
    displayRow,
    setDisplayRow,
  } = props

  const dispatch = useDispatch()
  const { filterMetadataStatus } = useSelector(state => state.metadata)

  const [filterOperators, setFilterOperators] = useState([])
  const [newFilter, setNewFilter] = useState(defaultFilterParams)

  const handleOnFilterChange = (e, data) => {
    if (data?.props.filter) {
      const filterData = cloneDeep(data.props.filter)
      delete filterData?.display_filter_type
      delete filterData?.type

      setNewFilter({
        ...newFilter,
        ...filterData,
        [e.target.name]: e.target.value,
      })
    } else {
      setNewFilter({
        ...newFilter,
        [e.target.name]: e.target.value,
      })
    }
  }

  const handleAddMoreFilters = () => {
    setDisplayRow(true)
  }

  const handleAddNewFilter = () => {
    const value =
      newFilter.type === 'between'
        ? { uppervalue: parseFloat(newFilter.uppervalue), lowervalue: parseFloat(newFilter.lowervalue) }
        : { value: parseFloat(newFilter.value) }

    const newMetricFilter = {
      dimension: newFilter.field_name,
      display_name: newFilter.column_display_name,
      type: newFilter.type,
      obj_type: newFilter.ref_id ? 'column' : 'field',
      ref_id: newFilter.ref_id,
      ...value,
      ...(newFilter.aggregation_type && { aggregation_type: newFilter.aggregation_type }),
    }
    const newAppliedMetricFilters = [...appliedMetricFilters, newMetricFilter]
    setAppliedMetricFilters(newAppliedMetricFilters)
    const emailMessage = getEmailCriteriaText({
      allMetricFilters,
      appliedMetricFilters: newAppliedMetricFilters,
    })
    setEmailMessage(emailMessage)
    setNewFilter(defaultFilterParams)
    setDisplayRow(false)
  }

  const handleDeleteAppliedFilters = metric => {
    const newAppliedMetricFilters = appliedMetricFilters.filter(
      filter => filter.column_display_name !== metric.column_display_name || filter.display_name !== metric.display_name
    )
    setAppliedMetricFilters(newAppliedMetricFilters)
    const emailMessage = getEmailCriteriaText({ allMetricFilters, appliedMetricFilters: newAppliedMetricFilters })
    setEmailMessage(emailMessage)
  }

  const getColumnType = displayName => {
    return allMetricFilters.find(metric => metric.column_display_name === displayName)?.type
  }

  useEffect(() => {
    if (filterMetadataStatus?.data) {
      const filterOperators = filterMetadataStatus?.data.filter_operations_aggregations
      setFilterOperators(filterOperators)
    } else {
      dispatch(getFilterMetadata())
    }
  }, [])

  // For Edit from Profile Page
  useEffect(() => {
    if (filterMetadataStatus?.data) {
      const filterOperators = filterMetadataStatus?.data.filter_operations_aggregations
      setFilterOperators(filterOperators)
    }
  }, [filterMetadataStatus])

  return (
    <>
      <Typography style={{ marginBottom: '1rem', fontWeight: 500 }} variant="subtitle1">
        SEND AN ALERT WHEN
      </Typography>
      {appliedMetricFilters.map((metric, index) => (
        <React.Fragment key={index}>
          <Grid justifyContent="flex-start" alignContent="center" container spacing={2}>
            <Grid item xs={12} key={metric.ref_id + index}>
              <Item>
                <TextField
                  value={metric.column_display_name || metric.display_name}
                  disabled
                  variant="outlined"
                  label="Metric Name"
                  style={{ width: 330, marginBottom: '1rem' }}
                />
              </Item>
            </Grid>
            <Grid item xs={3} key={metric.type + index} sx={{ marginLeft: '0.5rem', marginRight: '0.5rem' }}>
              <TextField fullWidth value={startCase(metric.type)} disabled variant="outlined" label="Condition" />
            </Grid>
            {metric.type !== 'between' ? (
              <Grid item xs={3} key={metric.value + index}>
                <TextField value={metric.value} type="number" variant="outlined" label="Value" disabled fullWidth />
              </Grid>
            ) : (
              <>
                <Grid item xs={3} key={metric.lowervalue + index} sx={{ marginRight: '0.5rem' }}>
                  <TextField
                    value={metric.lowervalue}
                    type="number"
                    variant="outlined"
                    label="Min Value"
                    disabled
                    fullWidth
                  />
                </Grid>
                <Grid item xs={3} key={metric.uppervalue + index}>
                  <TextField value={metric.uppervalue} type="number" variant="outlined" disabled fullWidth />
                </Grid>
              </>
            )}
            <Grid item style={{ marginTop: -5 }}>
              <SButton
                startIcon={<DeleteOutlined />}
                size="large"
                style={{
                  marginLeft: '0.5rem',
                  marginBottom: '0.5rem',
                }}
                color="secondary"
                onClick={() => handleDeleteAppliedFilters(metric)}
              >
                Remove
              </SButton>
            </Grid>
          </Grid>
          {index !== appliedMetricFilters.length - 1 && (
            <DividerWithText style={{ marginBottom: '1rem', marginTop: '1rem' }}>
              <Typography style={{ fontWeight: 500 }} variant="subtitle1">
                AND
              </Typography>
            </DividerWithText>
          )}
        </React.Fragment>
      ))}
      {displayRow ? (
        <Grid justifyContent="flex-start" alignItems="flex-start" style={{ marginTop: '1rem' }} container spacing={1}>
          <Grid item xs={12}>
            <TextField
              select
              value={newFilter.column_display_name || newFilter.display_name}
              onChange={handleOnFilterChange}
              variant="outlined"
              label="Metric"
              data-cy="select-menu-filter"
              error={newFilter.column_display_name.length === 0 && newFilter.display_name.length === 0}
              name="column_display_name"
              style={{ width: 330, marginBottom: '2rem' }}
            >
              {allMetricFilters.map((filter, idx) => {
                if (
                  !appliedMetricFilters.some(
                    item =>
                      item.column_display_name === filter.column_display_name ||
                      item.display_name === filter.column_display_name
                  )
                ) {
                  const value = filter.column_display_name.trim().length
                    ? filter.column_display_name
                    : filter.field_name
                  return (
                    <MenuItem filter={filter} value={value} key={filter.ref_id} data-cy={`menu-item-${idx}`}>
                      {value}
                    </MenuItem>
                  )
                }
                return ''
              })}
            </TextField>
          </Grid>
          <Grid item xs={3} sx={{ marginBottom: '0.5rem', marginRight: '0.5rem' }}>
            <TextField
              select
              value={newFilter.type}
              onChange={handleOnFilterChange}
              variant="outlined"
              label="Condition"
              name="type"
              fullWidth
            >
              {(getColumnType(newFilter.display_name || newFilter.column_display_name) === 'postagg'
                ? filterOperators && filterOperators[0]?.postagg
                : filterOperators && filterOperators[0]?.metric
              )?.map(filter => (
                <MenuItem value={filter.value} key={filter.value}>
                  {filter.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          {newFilter.type === 'between' ? (
            <>
              <Grid item xs={3}>
                <TextField
                  variant="outlined"
                  name="lowervalue"
                  type="number"
                  value={newFilter.lowervalue}
                  onChange={handleOnFilterChange}
                  label="Min Value"
                />
              </Grid>
              <Grid item xs={3} sx={{ marginRight: '0.5rem', marginLeft: '0.5rem' }}>
                <TextField
                  variant="outlined"
                  name="uppervalue"
                  type="number"
                  value={newFilter.uppervalue}
                  onChange={handleOnFilterChange}
                  label="Max Value"
                />
              </Grid>
            </>
          ) : (
            <Grid item xs={3} sx={{ marginRight: '0.5rem' }}>
              <TextField
                variant="outlined"
                name="value"
                type="number"
                value={newFilter.value}
                onChange={handleOnFilterChange}
                label="Value"
                helperText={
                  newFilter.format_type === 'percentage' ? 'Enter percentage in decimal format, eg: 5% = 0.05' : ''
                }
              />
            </Grid>
          )}
          <Grid item style={{ marginTop: -5 }}>
            <SButton
              startIcon={<Check />}
              size="large"
              color="primary"
              data-cy="criteria-save-btn"
              disabled={!newFilter.column_display_name}
              onClick={handleAddNewFilter}
            >
              Save
            </SButton>
            <SButton
              startIcon={<Close />}
              size="large"
              color="secondary"
              onClick={() => {
                setDisplayRow(false)
                setNewFilter(defaultFilterParams)
              }}
            >
              Cancel
            </SButton>
          </Grid>
        </Grid>
      ) : (
        appliedMetricFilters.length !== allMetricFilters?.length && (
          <SButton
            startIcon={<Add />}
            color="primary"
            size="large"
            data-cy="add-criteria-button"
            onClick={handleAddMoreFilters}
          >
            ADD CRITERIA
          </SButton>
        )
      )}
    </>
  )
}

export default DataAlertSubscriptionConfig
