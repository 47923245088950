import React from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@mui/material'
import { getDashboardAppliedFilterArrForSavedFilters } from '../../../../shared/FilterViewer/filter-utils'

export class DashboardviewerHeaderMobile extends React.Component {
  componentDidMount() {
    const { metadata } = this.props
    const savedFilters = getDashboardAppliedFilterArrForSavedFilters(metadata.saved_filters, metadata.filters)
    this.props.clearAllFilters() // Clear out redux state, saved/default filters get stuck in applied filters - Art/49754518

    if (savedFilters.length) {
      this.props.updateAppliedFilters(savedFilters)
    }
  }

  render() {
    return (
      <div className="mobileHeader">
        <Typography className="mobileDashTitle">{this.props.metadata.dashboard_title}</Typography>

        <Typography variant="subtitle2" className="mobileDashDescrip">
          {this.props.metadata.description}
        </Typography>
      </div>
    )
  }
}

DashboardviewerHeaderMobile.propTypes = {
  updateAppliedFilters: PropTypes.func,
  clearAllFilters: PropTypes.func,
}

export default DashboardviewerHeaderMobile
