import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { getFavoriteDatasets } from '../../../ducks/favorites'
import { displayServiceErrorMessage } from '../../../ducks/layout'
import { updateFavorite } from '../../../ducks/user'
import { delDataset } from '../../../ducks/dataset'
import FavoritesDatasets from './FavoritesDatasets'

export class FavoritesDatasetsContainer extends React.Component {
  componentDidMount() {
    const { setHeaderTitle, getFavoriteDatasets } = this.props

    setHeaderTitle('Favorite Datasets')
    getFavoriteDatasets()
  }

  render() {
    return <FavoritesDatasets {...this.props} />
  }
}

const mapStateToProps = state => ({
  favoriteDatasetsInfo: state.favorites.favoriteDatasetsInfo,
  datasetDelStatus: state.dataset.datasetDelStatus,
  postFavoriteStatus: state.user.postFavoriteStatus,
})

export const mapDispatchToProps = dispatch => ({
  getFavoriteDatasets(data) {
    dispatch(getFavoriteDatasets(data))
  },
  displayServiceErrorMessage(data) {
    dispatch(displayServiceErrorMessage(data))
  },
  delDataset(data) {
    dispatch(delDataset(data))
  },
  updateFavorite(data) {
    dispatch(updateFavorite(data))
  },
})

FavoritesDatasetsContainer.defaultProps = {
  favoriteDatasetsInfo: {},
  datasetDelStatus: {},
  postFavoriteStatus: {},
}

FavoritesDatasetsContainer.propTypes = {
  getFavoriteDatasets: PropTypes.func,
  favoriteDatasetsInfo: PropTypes.object,
  displayServiceErrorMessage: PropTypes.func,
  delDataset: PropTypes.func,
  datasetDelStatus: PropTypes.object,
  updateFavorite: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps)(FavoritesDatasetsContainer)
