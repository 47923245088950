import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@mui/material'
import debounce from 'lodash/debounce'

import AdminSideBarList from './Admin.sideBarList'
import AdminSideBarSearch from './Admin.sideBarSearch'
import './admin.scss'

class AdminSidebar extends React.Component {
  render() {
    const { topic, routeProps, className, isMobile, isShowExternalOnly } = this.props

    return (
      <Grid item xs={3} className={className || 'admin-left-pane'}>
        <AdminSideBarSearch
          userDetails={this.props.userDetails}
          updateSidebarInfo={debounce(this.props.updateSidebarInfo, 1000)}
          topic={topic}
          updateShowExternalOnly={this.props.updateShowExternalOnly}
          isShowExternalOnly={isShowExternalOnly}
        />
        <AdminSideBarList
          topic={topic}
          sideBarListInfo={this.props.sideBarListInfo}
          updateSidebarInfo={this.props.updateSidebarInfo}
          routeProps={routeProps}
          onAddGroupClick={this.props.onAddGroupClick}
          displayServiceErrorMessage={this.props.displayServiceErrorMessage}
          updateDetail={this.props.updateDetail}
          updateDeleteGroupSuccess={this.props.updateDeleteGroupSuccess}
          addGroupInFocus={this.props.addGroupInFocus}
          isShowExternalOnly={isShowExternalOnly}
          isMobile={isMobile}
        />
      </Grid>
    )
  }
}

AdminSidebar.defaultProps = {
  topic: '',
  sideBarListInfo: {},
  routeProps: {},
  handleAddGroupClick: () => {},
  updateSidebarInfo: () => {},
  displayServiceErrorMessage: () => {},
  updateDetail: () => {},
}

AdminSidebar.propTypes = {
  topic: PropTypes.string,
  sideBarListInfo: PropTypes.object,
  routeProps: PropTypes.object,
  handleAddGroupClick: PropTypes.func,
  updateSidebarInfo: PropTypes.func,
  displayServiceErrorMessage: PropTypes.func,
  updateDetail: PropTypes.func,
}

export default AdminSidebar
