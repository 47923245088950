import React, { useEffect, useState } from 'react'
import {
  Tooltip,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Box,
  Typography,
  Alert,
  AlertTitle,
} from '@mui/material'
import moment from 'moment'
import { Close, DeleteOutline, EditOutlined, ForwardToInbox, History, UnsubscribeOutlined } from '@mui/icons-material'
import { useDispatch, useSelector } from 'react-redux'
import { Spinner } from 'greenfield-utilities'
import { useAnalytics } from '@praxis/component-analytics'
import analyticsConfig from '../../analytics'
import ConfirmDialogComp from '../../shared/Dialog/ConfirmDialog'
import DataAlertSubscriptionDialog, {
  CARD_SUBSCRIPTION,
  DATA_ALERT,
} from '../Cards/Cardviewer/DataAlertAndSubscription/DataAlertSubscriptionDialog'
import { getCardDetail } from '../../../ducks/cards'
import {
  clearCardSubscriptionDelete,
  clearDataAlertDelete,
  clearOptOutSubscription,
  clearSubscriptionLogs,
  deleteDataAlert,
  deleteSubscription,
  editOptOutSubscription,
  getAllDataAlertLogs,
  getAllSubscriptions,
  getAllSubscriptionsByCardId,
} from '../../../ducks/dataAlertAndSubscription'
import { getSubscriptionsPayload } from '../Cards/Cardviewer/DataAlertAndSubscription/DataAlertSubscriptionListing'
import { displayServiceErrorMessage } from '../../../ducks/layout'
import { AgGridTable } from '../../shared/AgGrid/AgGridTableClientSide'
import { LogsDetailComponent } from './LogsDetailComponent'

export const SubscriptionActionRenderer = props => {
  const { rowIndex, data, setSubscriptions, trackEvent, isShowCardNotifications, isMobile, statusType } = props
  const dispatch = useDispatch()

  const defaultParams = {
    isOpen: false,
    selectedSubscriptionId: '',
    selectedSubscriptionName: '',
  }

  const [isEditOpen, setIsEditOpen] = useState(false)
  const [cardId, setCardId] = useState(null)
  const [cardInfo, setCardInfo] = useState(null)
  const [open, setOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [subscription, setSubscription] = useState(defaultParams)
  const [selectedId, setSelectedId] = useState(null)
  const [subscriptionLogId, setSubscriptionLogId] = useState(null)
  const [dataAlertLogs, setDataAlertLogs] = useState([]) // Evaluation window data
  const [error, setError] = useState('')

  const { isOpen, selectedSubscriptionId, selectedSubscriptionName } = subscription

  const { trackClick } = useAnalytics(analyticsConfig)

  const { cardDetailStatus } = useSelector(state => state.cards)
  const {
    cardSubscriptionCreateStatus,
    cardSubscriptionDeleteStatus,
    dataAlertCreateStatus,
    dataAlertDeleteStatus,
    subscriptionsListStatus,
    subscriptionsListByCardStatus,
    subscriptionLogsStatus,
    optOutSubscriptionStatus,
  } = useSelector(state => state.dataAlertAndSubscription)

  useEffect(() => {
    if (cardId) {
      dispatch(getCardDetail({ cardId }))
    }
  }, [cardId])

  useEffect(() => {
    if (cardDetailStatus?.data) {
      setCardInfo(cardDetailStatus.data)
    }
  }, [cardDetailStatus])

  useEffect(() => {
    if (subscriptionLogId) {
      setIsLoading(true)
      dispatch(getAllDataAlertLogs({ subscriptionId: subscriptionLogId }))
    }
  }, [subscriptionLogId])

  useEffect(() => {
    if (subscriptionLogsStatus?.data) {
      setDataAlertLogs(subscriptionLogsStatus.data)
      setIsLoading(false)
    } else if (subscriptionLogsStatus?.status === 'failed') {
      setIsLoading(false)
      dispatch(
        displayServiceErrorMessage(subscriptionLogsStatus?.message || 'Something went wrong, please try again later!')
      )
    }
  }, [subscriptionLogsStatus])

  useEffect(() => {
    if (subscriptionsListStatus?.data && !isShowCardNotifications) {
      setSubscriptions(subscriptionsListStatus?.data)
    }
  }, [subscriptionsListStatus])

  useEffect(() => {
    if (subscriptionsListByCardStatus?.data && isShowCardNotifications) {
      setSubscriptions(subscriptionsListByCardStatus?.data)
    }
  }, [subscriptionsListByCardStatus])

  // Opt in/Opt out subscription
  useEffect(() => {
    if (optOutSubscriptionStatus?.status && selectedId) {
      if (optOutSubscriptionStatus.status === 204) {
        if (isShowCardNotifications) {
          getCardSubscriptions()
        } else {
          getSubscriptions()
        }
      } else if (optOutSubscriptionStatus?.status === 'failed') {
        dispatch(
          displayServiceErrorMessage(
            optOutSubscriptionStatus.error?.resp?.data?.description ||
              'Opt in/Opt out subscription failed, please try again later.'
          )
        )
      }
      dispatch(clearOptOutSubscription())
    }
  }, [optOutSubscriptionStatus, selectedId])

  useEffect(() => {
    if ((selectedSubscriptionId || selectedId) && dataAlertDeleteStatus?.status) {
      if (dataAlertDeleteStatus?.status === 204) {
        if (isShowCardNotifications) {
          getCardSubscriptions()
        } else {
          getSubscriptions()
        }
        handleCloseDialog()
      } else if (dataAlertDeleteStatus?.status === 'failed') {
        setErrorMessage('Delete Subscription failed, please try again later.')
        dispatch(displayServiceErrorMessage('Delete Subscription failed, please try again later.'))
      }
      dispatch(clearDataAlertDelete())
    }
  }, [dataAlertDeleteStatus, selectedSubscriptionId])

  useEffect(() => {
    if ((selectedSubscriptionId || selectedId) && cardSubscriptionDeleteStatus?.status) {
      if (cardSubscriptionDeleteStatus?.status === 204) {
        if (isShowCardNotifications) {
          getCardSubscriptions()
        } else {
          getSubscriptions()
        }
        handleCloseDialog()
      } else if (cardSubscriptionDeleteStatus?.status === 'failed') {
        setErrorMessage('Delete Subscription failed, please try again later.')
        dispatch(displayServiceErrorMessage('Delete Subscription failed, please try again later.'))
      }
      dispatch(clearCardSubscriptionDelete())
    }
  }, [cardSubscriptionDeleteStatus, selectedSubscriptionId])

  useEffect(() => {
    if (selectedSubscriptionId && dataAlertCreateStatus?.status) {
      if (dataAlertCreateStatus?.status === 201) {
        getSubscriptions()
      } else {
        setErrorMessage('Create Subscription failed, please try again later.')
      }
    }
  }, [dataAlertCreateStatus, selectedSubscriptionId])

  const getCardSubscriptions = () => {
    dispatch(getAllSubscriptionsByCardId(getSubscriptionsPayload(statusType, data.card_id)))
  }

  const getSubscriptions = () => {
    dispatch(getAllSubscriptions(getSubscriptionsPayload(statusType)))
  }

  // Opt in/Opt out Subscription
  const handleOptInOptOutSubscription = (subscriptionId, isOptIn) => {
    setSelectedId(subscriptionId)
    dispatch(editOptOutSubscription({ subscriptionId, isOptIn }))
  }

  // Delete Subscription
  const handleDeleteSubscription = () => {
    data.subscription_type === 'DATA_ALERT'
      ? dispatch(deleteDataAlert({ subscriptionId: selectedSubscriptionId }))
      : dispatch(deleteSubscription({ subscriptionId: selectedSubscriptionId }))
  }

  const handleOnClickDeleteSubscription = (selectedSubscriptionId, selectedSubscriptionName) => {
    setSubscription({ isOpen: true, selectedSubscriptionId, selectedSubscriptionName })
  }

  const handleCloseDialog = () => {
    setSubscription(defaultParams)
  }

  const handleEditClose = () => {
    setIsEditOpen(false)
    if (dataAlertCreateStatus?.status === 201) {
      dispatch(deleteDataAlert({ subscriptionId: selectedId }))
    } else if (cardSubscriptionCreateStatus?.status === 201) {
      dispatch(deleteSubscription({ subscriptionId: selectedId }))
    }
  }

  const handleLogsDialogClose = () => {
    dispatch(clearSubscriptionLogs())
    setOpen(false)
    setDataAlertLogs([])
    setSubscriptionLogId(null)
    setError('')
  }

  /**
   *
   *  | Evaluation Status | Execution Status | Value     |
   *  | :---------------- | :--------------- | :-------- |
   *  | COMPLETE          | SUBMITTED        | QUEUED    |
   *  | COMPLETE          | COMPLETE         | SUCCESS   |
   *  | COMPLETE          | FAILED           | FAILED    |
   *  | COMPLETE          | SKIPPED          | SKIPPED   |
   *  | COMPLETE          | OPT_OUT          | OPTED OUT |
   *  | COMPLETE          | null             | N/A       | // if only recipient
   *  | COMPLETE          | null             | SUCCESS   | // if owner (not recipient)
   *  | ACTIVE            | null             | WAITING   |
   *  | FAILED            | SUBMITTED        | FAILED    |
   *  | FAILED            | COMPLETE         | FAILED    |
   *  | FAILED            | FAILED           | FAILED    |
   *  | FAILED            | SKIPPED          | FAILED    |
   *  | FAILED            | null             | FAILED    |
   *  | FAILED            | OPT_OUT          | FAILED    |
   * @param {string} evaluationStatus
   * @param {string} executionStatus
   * @param {boolean} isRecipient
   * @returns {string}
   */
  const getCellValue = (evaluationStatus, executionStatus, isRecipient) => {
    if (evaluationStatus === 'COMPLETE') {
      return executionStatus === 'SUBMITTED'
        ? 'QUEUED'
        : executionStatus === 'COMPLETE'
        ? 'SUCCESS'
        : executionStatus === 'OPT_OUT'
        ? 'OPTED OUT'
        : !executionStatus
        ? isRecipient
          ? 'N/A'
          : 'SUCCESS'
        : executionStatus
    } else if (evaluationStatus === 'ACTIVE' && !executionStatus) {
      return 'WAITING'
    } else if (evaluationStatus === 'FAILED') {
      return 'FAILED'
    }
    return ''
  }

  const columns = [
    {
      field: 'subscription_id',
      headerName: '',
      maxWidth: 50,
      filter: false,
      sortable: false,
      hide: isMobile,
      suppressColumnsToolPanel: true,
      cellRenderer: 'agGroupCellRenderer',
      valueFormatter: () => '',
      suppressMenu: true,
    },
    {
      field: 'begin',
      headerName: 'Begin Period Date',
      minWidth: 250,
      valueGetter: ({ data }) => moment(data.begin).format('MMMM Do YYYY'),
    },
    {
      field: 'end',
      headerName: 'End Period Date',
      minWidth: 250,
      valueGetter: ({ data }) => moment(data.end).format('MMMM Do YYYY'),
    },
    {
      field: 'depends_on',
      headerName: 'Dependency Met',
      minWidth: 200,
      cellRendererFramework: ({ value }) => {
        let dependencyStatusMet = 'No'
        value.forEach(item => {
          if (!item.dependency_resolved) {
            dependencyStatusMet = 'No'
            return
          }
          dependencyStatusMet = item.dependency_resolved ? 'Yes' : 'No'
        })
        return <Typography variant="subtitle1">{dependencyStatusMet}</Typography>
      },
    },
    {
      field: 'execution_status',
      headerName: 'Status',
      minWidth: 150,
      cellRendererFramework: ({ data }) => {
        const cellValue = getCellValue(data.evaluation_status, data.execution_status, data.is_recipient)
        return (
          <Typography
            color={
              cellValue === 'SUCCESS'
                ? 'success.main'
                : cellValue === 'FAILED'
                ? 'error.main'
                : cellValue === 'WAITING' || cellValue === 'QUEUED'
                ? 'warning.main'
                : ''
            }
          >
            {cellValue}
          </Typography>
        )
      },
    },
  ]

  return (
    <>
      <Tooltip title="Subscription History" placement="top">
        <IconButton
          disabled={(!data.edit_enabled && !data.is_recipient) || moment(data.end_time) < new Date()}
          component="div"
          id={`subscription-logs-${rowIndex}`}
          aria-label={`Subscription Log ${data.name}`}
          onClick={() => {
            trackClick('data-alert-subscription-logs-clicked')
            setError('')
            setDataAlertLogs([])
            setSubscriptionLogId(data._id)
            setOpen(true)
          }}
        >
          <History />
        </IconButton>
      </Tooltip>
      <Tooltip title={data.is_recipient && data.has_optedout ? 'Subscribe' : 'Unsubscribe'} placement="top">
        <IconButton
          component="div"
          id={`subscription-opt-in-out-${rowIndex}`}
          aria-label={`Subscription Opt In Opt Out ${data.name}`}
          onClick={() => {
            trackClick(
              data.has_optedout ? 'data-alert-subscription-opt-in-clicked' : 'data-alert-subscription-opt-out-clicked'
            )
            handleOptInOptOutSubscription(data._id, !data.has_optedout)
          }}
          sx={!data.has_optedout && data.is_recipient ? { marginTop: '5px' } : {}}
          disabled={!data.is_recipient || moment(data.end_time) < new Date()}
        >
          {!data.has_optedout && data.is_recipient ? (
            <UnsubscribeOutlined
              color={data.is_recipient && moment(data.end_time) > new Date() ? 'warning' : 'inherit'}
              sx={{ fontSize: 26 }}
            />
          ) : (
            <ForwardToInbox color={data.is_recipient ? 'success' : 'inherit'} />
          )}
        </IconButton>
      </Tooltip>
      <Tooltip title="Edit Subscription" placement="top">
        <IconButton
          component="div"
          disabled={!data.edit_enabled}
          data-cy={`subscriptionlist-edit-${rowIndex}`}
          id={`subscriptionlist-edit-${rowIndex}`}
          aria-label={`Edit Subscription ${data.name}`}
          onClick={() => {
            setIsEditOpen(true)
            setCardId(data.card_id)
            setSelectedId(data._id)
          }}
        >
          <EditOutlined color={data.edit_enabled ? 'success' : 'inherit'} />
        </IconButton>
      </Tooltip>
      <Tooltip title="Delete Subscription" placement="top">
        <IconButton
          disabled={!data.edit_enabled}
          component="div"
          id={`subscriptionlist-delete-${rowIndex}`}
          aria-label={`Delete Subscription ${data.name}`}
          onClick={() => handleOnClickDeleteSubscription(data._id, data.name)}
        >
          <DeleteOutline color={data.edit_enabled ? 'error' : 'inherit'} />
        </IconButton>
      </Tooltip>
      {isEditOpen && (
        <DataAlertSubscriptionDialog
          open={isEditOpen}
          card={cardInfo}
          handleClose={handleEditClose}
          timePeriod={cardInfo?.card_query_attribute?.time_period}
          secondaryTimePeriod={cardInfo?.card_query_attribute?.secondary_time_period || {}}
          appliedFilters={data.card_filter}
          subscriptionDetails={data}
          trackEvent={trackEvent}
          label={data.subscription_type === 'DATA_ALERT' ? DATA_ALERT : CARD_SUBSCRIPTION}
        />
      )}
      <ConfirmDialogComp
        open={isOpen}
        contentText={`Are you sure you want to delete subscription - "${selectedSubscriptionName}"?`}
        errorText={errorMessage}
        okText="DELETE"
        onCloseDialog={handleCloseDialog}
        onClickOk={handleDeleteSubscription}
        onClickCancel={handleCloseDialog}
      />
      <Dialog
        open={open}
        onClose={handleLogsDialogClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        maxWidth="xl"
        fullWidth
      >
        <DialogTitle>
          <Grid container justifyContent="space-between">
            <Grid item>Subscription History</Grid>
            {!data.is_recipient && (
              <Grid item>
                <Typography variant="subtitle2">You are not the recipient of this subscription</Typography>
              </Grid>
            )}
            <Grid item>
              <IconButton onClick={handleLogsDialogClose}>
                <Close />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent style={{ height: '80vh' }}>
          {isLoading ? (
            <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 300 }}>
              <Spinner />
            </Box>
          ) : (
            <AgGridTable
              columns={columns}
              pagination={false}
              isMobile={isMobile}
              data={dataAlertLogs}
              filter={false}
              masterDetail
              detailCellRendererFramework={props => {
                return <LogsDetailComponent rowData={props.data} isRecipient={data.is_recipient} />
              }}
            />
          )}
        </DialogContent>
        {error && (
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            {error}
          </Alert>
        )}
      </Dialog>
    </>
  )
}
