import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withAuth } from '@praxis/component-auth'
import {
  executeCalculatedField,
  createCalculatedField,
  editCalculatedField,
  searchCalculatedField,
  getCalculatedFieldFnHelp,
} from '../../../ducks/calculatedField'
import { getDetailsOwners } from '../../../ducks/builder'
import { getMetadata } from '../../../ducks/metadata'
import CalculatedField from './CalculatedField'

export class CalculatedFieldContainer extends React.Component {
  render() {
    const { auth = {} } = this.props
    const { session = {} } = auth
    const { userInfo = {} } = session

    return <CalculatedField {...this.props} defaultUser={userInfo} />
  }
}

export const mapStateToProps = (state, props) => {
  return {
    executeCalculatedFieldStatus: state.calculatedField.executeCalculatedFieldStatus,
    createCalculatedFieldStatus: state.calculatedField.createCalculatedFieldStatus,
    editCalculatedFieldStatus: state.calculatedField.editCalculatedFieldStatus,
    searchCalculatedFieldStatus: state.calculatedField.searchCalculatedFieldStatus,
    getCalculatedFieldFnHelpStatus: state.calculatedField.getCalculatedFieldFnHelpStatus,
    metadataStatus: state.metadata.metadataStatus,
    cardsDatasetStatus: props.isBuilder ? state.builder.cardsDatasetStatus : props.datasetObject,
    detailsOwnersStatus: state.builder.detailsOwnersStatus ? state.builder.detailsOwnersStatus : {},
  }
}

export const mapDispatchToProps = dispatch => ({
  executeCalculatedField(data) {
    dispatch(executeCalculatedField(data))
  },

  createCalculatedField(data) {
    dispatch(createCalculatedField(data))
  },

  editCalculatedField(data) {
    dispatch(editCalculatedField(data))
  },
  getMetadata(data) {
    dispatch(getMetadata(data))
  },
  searchCalculatedField(data) {
    dispatch(searchCalculatedField(data))
  },

  getCalculatedFieldFnHelp(data) {
    dispatch(getCalculatedFieldFnHelp(data))
  },
  getDetailsOwners(data) {
    dispatch(getDetailsOwners(data))
  },
})

CalculatedFieldContainer.defaultProps = {
  isBuilder: false,
  cardsDatasetStatus: {},
  executeCalculatedFieldStatus: {},
  createCalculatedFieldStatus: {},
  searchCalculatedFieldStatus: {},
  getCalculatedFieldFnHelpStatus: {},
}

CalculatedFieldContainer.propTypes = {
  isBuilder: PropTypes.bool,
  cardsDatasetStatus: PropTypes.object.isRequired,
  executeCalculatedFieldStatus: PropTypes.object,
  createCalculatedFieldStatus: PropTypes.object,
  editCalculatedFieldStatus: PropTypes.object,
  searchCalculatedFieldStatus: PropTypes.object,
  getCalculatedFieldFnHelpStatus: PropTypes.object,
  executeCalculatedMetric: PropTypes.func,
  createCalculatedMetric: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps)(withAuth()(CalculatedFieldContainer))
