import React, { createRef } from 'react'
import { Avatar, Typography, Button, Dialog, Radio, Grid, Chip, Divider, FormControlLabel, Switch } from '@mui/material'
import { Lock, Compare, Block } from '@mui/icons-material'
import { List as VirtualList } from 'react-virtualized'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import cloneDeep from 'lodash/cloneDeep'
import replace from 'lodash/replace'
import isEqual from 'lodash/isEqual'

import { processColumnsIcon } from '../../routes/Builder/Sidebar/utils'

import './lockandcompare.scss'

class LockAndCompare extends React.Component {
  state = {
    isSorted: false,
    selected: [],
  }

  listRef = createRef()

  sortedColumns = []

  componentDidMount() {
    const { selectedColList, isMobile } = this.props
    this.setState(
      {
        selected: selectedColList,
      },
      () => {
        if (isMobile) {
          this.listRef.current.forceUpdateGrid()
        }
      }
    )
  }

  componentDidUpdate(prevProps) {
    const { selectedColList } = this.props
    if (!isEqual(prevProps.selectedColList, selectedColList)) {
      this.setState({
        selected: selectedColList,
        isSorted: false,
      })
    }
  }

  renderRowOnMobile = ({ key, index, style }) => {
    const { selected, isSorted } = this.state
    const { columns, cardInfo, renderColumns, drill } = this.props
    const updatedCols = isSorted ? this.sortedColumns : cloneDeep(columns)
    if (selected?.length && selected.some(col => !col.ref_id)) {
      this.handleClearSelection()
    }
    const selectedObj = this.getSelectedCol(selected)
    const colType = this.getColumnType(cardInfo, updatedCols[index].ref_id)

    let colChipLabel = updatedCols[index].display_name
    // Update Drill-column name with current Drill-Path column name
    colChipLabel = this.updateColName(colChipLabel, cardInfo, columns, renderColumns, drill)

    return (
      <div key={key} style={style}>
        <Chip
          id={index + replace(updatedCols[index], /\\/g, '\\\\')}
          className={`${colType} format-chip`}
          avatar={
            this.props.isAvatar ? (
              <Avatar
                alt={colType}
                src={processColumnsIcon({ type: colType })}
                className={classNames('icon-avatar', colType)}
              />
            ) : null
          }
          label={colChipLabel}
        />
        <Grid spacing={3} container className="column-option">
          <div>
            <Lock className="mobile-icon lock" />
            <Typography variant="h3" className="mobile-lock-title">
              Lock
            </Typography>
            <Radio
              id={`lock_btn_${index}`}
              className="lock-radio"
              checked={selectedObj.lockColumn.findIndex(col => col.ref_id === updatedCols[index].ref_id) !== -1}
              onChange={event => {
                this.handleChange(event, index, updatedCols[index])
              }}
              value={`lock_${updatedCols[index]}`}
              name={`lock_${updatedCols[index]}`}
              aria-label={`Lock Column_${updatedCols[index]}`}
            />
          </div>
          <div className="padding-left">
            <Compare className="mobile-icon compare" />
            <Typography variant="h3" className="mobile-compare-title">
              Compare
            </Typography>
            <Radio
              id={`compare_btn_${index}`}
              className="compare-radio"
              checked={selectedObj.compareColumn.findIndex(col => col.ref_id === updatedCols[index].ref_id) !== -1}
              onChange={event => {
                this.handleChange(event, index, updatedCols[index])
              }}
              value={`compare_${updatedCols[index]}`}
              name={`compare_${updatedCols[index]}`}
              aria-label={`Compare Column_${updatedCols[index]}`}
            />
          </div>
          <div className="padding-left">
            <Block className="mobile-icon block" />
            <Typography variant="h3" className="margin-45">
              None
            </Typography>
            <Radio
              id={`none_btn_${index}`}
              className="margin-45"
              checked={selectedObj.noneColumn.findIndex(col => col.ref_id === updatedCols[index].ref_id) !== -1}
              onChange={event => {
                this.handleChange(event, index, updatedCols[index])
              }}
              value={`none_${updatedCols[index]}`}
              name={`none_${updatedCols[index]}`}
              aria-label={`No Selection_${updatedCols[index]}`}
            />
          </div>
        </Grid>
        <Divider />
      </div>
    )
  }

  renderMobileContainer = () => {
    const { columns, isMobile } = this.props
    const { selected } = this.state
    const selectedObj = this.getSelectedCol(selected)
    return (
      <>
        <div className="lockandcompare-popover-form mobileLockAndCompare">
          <header className="header-container">
            <div className="display-flex">
              <Typography variant="h2" className="mobileTitle">
                Lock and Compare Columns
              </Typography>
              {isMobile && this.getResetBtn(isMobile, selectedObj)}
            </div>
          </header>
          {selectedObj.lockColumn.length > 0 && this.renderClearAndSortBtn(selectedObj)}
          <div className="columns-section columns-list-container onMobile">
            <VirtualList
              {...selectedObj.lockColumn}
              {...selectedObj.compareColumn}
              ref={this.listRef}
              width={700}
              height={500}
              rowCount={columns.length}
              rowHeight={125}
              rowRenderer={this.renderRowOnMobile}
              className="column-filter-list column-list"
            />
          </div>

          <Grid spacing={3} container>
            <Grid
              item
              xs={6}
              className="mobileFilterBtnLeft"
              onClick={this.handleApplyLockAndCompare}
              id="lock-compare-apply-btn"
            >
              Apply
            </Grid>
            <Grid
              item
              xs={6}
              className="mobileFilterBtnRight"
              onClick={this.handleDismissPopover}
              id="lock-compare-close-btn"
            >
              Close
            </Grid>
          </Grid>
        </div>
      </>
    )
  }

  getColumnType = (cardInfo, colName) => {
    let colType = ''
    if (cardInfo && cardInfo.data && cardInfo.data.card_query_attribute && cardInfo.data.card_query_attribute.columns) {
      const colsData = cardInfo.data.card_query_attribute.columns
      colType = colsData.reduce((accumulator, eachCol) => {
        if (eachCol && eachCol.ref_id === colName) {
          accumulator.push(eachCol.type)
        }
        return accumulator
      }, [])[0]
    }
    return colType
  }

  updateColName = (colChipLabel, cardInfo, columns, renderColumns, drill) => {
    if (!renderColumns.includes(colChipLabel) && !isEqual(columns, renderColumns) && drill && drill.length) {
      const drillMaps =
        cardInfo && cardInfo.data && cardInfo.data.card_query_attribute && cardInfo.data.card_query_attribute.drillmaps
          ? cardInfo.data.card_query_attribute.drillmaps
          : []

      let selDrillPathCols = []
      drillMaps.forEach(eachDrillCol => {
        if (eachDrillCol.drillmap_name && eachDrillCol.drillmap_name === colChipLabel) {
          selDrillPathCols = eachDrillCol.drillmap_path
        }
      })

      let isDrillColNameUpdated = false
      selDrillPathCols.forEach(drillPath => {
        if (!isDrillColNameUpdated) {
          if (drillPath.column_display_name !== colChipLabel && renderColumns.includes(drillPath.column_display_name)) {
            colChipLabel = drillPath.column_display_name
            isDrillColNameUpdated = true
          }
        }
      })
    }
    return colChipLabel
  }

  handleChange = (e, index) => {
    const targetValue = e.target.value || ''
    const newValue = targetValue.includes('lock') ? 'lock' : targetValue.includes('compare') ? 'compare' : 'none'
    this.setState(prevState => ({
      selected: [
        ...prevState.selected.slice(0, index),
        { ...prevState.selected[index], value: newValue },
        ...prevState.selected.slice(index + 1),
      ],
      isSorted: prevState.isSorted ? !prevState.isSorted : false,
    }))
    this.listRef.current.forceUpdateGrid()
  }

  renderRow = ({ key, index, style }) => {
    const { selected, isSorted } = this.state
    const { columns, cardInfo, renderColumns, drill } = this.props
    const updatedCols = isSorted ? this.sortedColumns : cloneDeep(columns)

    const selectedObj = this.getSelectedCol(selected)
    const colType = this.getColumnType(cardInfo, updatedCols[index].ref_id)

    let colChipLabel = updatedCols[index].display_name

    // Update Drill-column name with current Drill-Path column name
    colChipLabel = this.updateColName(colChipLabel, cardInfo, columns, renderColumns, drill)

    return (
      <div key={key} style={style}>
        <Grid spacing={3} container>
          <Grid item xs={6} className="default-cursor">
            <Chip
              id={index + replace(updatedCols[index], /\\/g, '\\\\')}
              className={`${colType} column-chip format-chip`}
              avatar={
                this.props.isAvatar ? (
                  <Avatar
                    alt={colType}
                    src={processColumnsIcon({ type: colType })}
                    className={classNames('icon-avatar', colType)}
                  />
                ) : null
              }
              label={colChipLabel}
            />
          </Grid>
          <Grid item xs={6} className="calc-field-search">
            <Radio
              id={`lock_btn_${index}`}
              className="lock-radio"
              checked={selectedObj.lockColumn.findIndex(col => col.ref_id === updatedCols[index].ref_id) !== -1}
              onChange={event => {
                this.handleChange(event, index, updatedCols[index])
              }}
              value={`lock_${updatedCols[index]}`}
              name={`lock_${updatedCols[index]}`}
              aria-label={`Lock Column_${updatedCols[index]}`}
            />
            <Radio
              id={`compare_btn_${index}`}
              className="compare-radio"
              checked={selectedObj.compareColumn.findIndex(col => col.ref_id === updatedCols[index].ref_id) !== -1}
              onChange={event => {
                this.handleChange(event, index, updatedCols[index])
              }}
              value={`compare_${updatedCols[index]}`}
              name={`compare_${updatedCols[index]}`}
              aria-label={`Compare Column_${updatedCols[index]}`}
            />
            <Radio
              id={`none_btn_${index}`}
              className="margin-45"
              checked={selectedObj.noneColumn.findIndex(col => col.ref_id === updatedCols[index].ref_id) !== -1}
              onChange={event => {
                this.handleChange(event, index, updatedCols[index])
              }}
              value={`none_${updatedCols[index]}`}
              name={`none_${updatedCols[index]}`}
              aria-label={`No Selection_${updatedCols[index]}`}
            />
          </Grid>
        </Grid>
        <Divider />
      </div>
    )
  }

  handleClearSelection = () => {
    const { columns } = this.props
    const selected = columns.map(col => {
      return { name: col.name, display_name: col.display_name, value: 'compare', ref_id: col.ref_id }
    })
    this.setState({
      selected,
      isSorted: false,
    })
  }

  handleDismissPopover = () => {
    const { selectedColList } = this.props
    const selectedObj = this.getSelectedCol(selectedColList)
    this.props.submitLockAndCompare(selectedObj.lockColumn, selectedObj.compareColumn, selectedColList)
  }

  handleApplyLockAndCompare = () => {
    const { selected } = this.state
    const selectedObj = this.getSelectedCol(selected)
    this.props.submitLockAndCompare(selectedObj.lockColumn, selectedObj.compareColumn, selected)
  }

  getSelectedCol = selectedObj => {
    let lockColumn = []
    let compareColumn = []
    let noneColumn = []

    lockColumn = selectedObj.reduce((accumulator, eachCol) => {
      if (eachCol.value === 'lock') {
        accumulator.push({ name: eachCol.name, display_name: eachCol.display_name, ref_id: eachCol.ref_id })
      }
      return accumulator
    }, [])

    compareColumn = selectedObj.reduce((accumulator, eachCol) => {
      if (eachCol.value === 'compare') {
        accumulator.push({ name: eachCol.name, display_name: eachCol.display_name, ref_id: eachCol.ref_id })
      }
      return accumulator
    }, [])

    noneColumn = selectedObj.reduce((accumulator, eachCol) => {
      if (eachCol.value === 'none') {
        accumulator.push({ name: eachCol.name, display_name: eachCol.display_name, ref_id: eachCol.ref_id })
      }
      return accumulator
    }, [])

    return {
      lockColumn,
      compareColumn,
      noneColumn,
    }
  }

  handleSortSelection = () => {
    const { isSorted, selected } = this.state
    const tempSelected = cloneDeep(selected)
    const lockArr = []
    const compArr = []
    const noneArr = []
    tempSelected.forEach(eachCol => {
      const colObj = { name: eachCol.name, display_name: eachCol.display_name, ref_id: eachCol.ref_id }
      if (eachCol.value === 'lock') {
        lockArr.push(colObj)
      } else if (eachCol.value === 'compare') {
        compArr.push(colObj)
      } else {
        noneArr.push(colObj)
      }
    })
    this.sortedColumns = this.sortedColumns.length ? [] : this.sortedColumns
    this.sortedColumns.push(...lockArr, ...compArr, ...noneArr)
    this.setState(
      {
        isSorted: !isSorted,
      },
      () => {
        this.listRef.current.forceUpdateGrid()
      }
    )
  }

  getResetBtn = (isMobile, selectedObj) => {
    return (
      selectedObj.lockColumn.length > 0 && (
        <Button
          disabled={selectedObj.lockColumn.length === 0}
          aria-label="Reset"
          size="small"
          variant="text"
          color="primary"
          className={isMobile ? 'mobile-reset-btn' : ''}
          style={{ textTransform: 'none' }}
          onClick={this.handleClearSelection}
        >
          Reset
        </Button>
      )
    )
  }

  renderClearAndSortBtn = selectedObj => {
    const { isSorted } = this.state
    const { isMobile } = this.props
    return (
      <div className="display-flex-space">
        {!isMobile && this.getResetBtn(isMobile, selectedObj)}
        <FormControlLabel
          control={
            <Switch
              checked={isSorted}
              disabled={selectedObj.lockColumn.length === 0 && selectedObj.compareColumn.length === 0}
              className="test-switch"
              onClick={this.handleSortSelection}
            />
          }
          label="Sort by locked and compared columns"
        />
      </div>
    )
  }

  renderColumnsTable = () => {
    return (
      <div className="columns-header-container">
        <Grid spacing={3} container className="Column-header-grid">
          <Grid item xs={6} className="header-1">
            <Typography variant="h3" className="headerTitle">
              Columns
            </Typography>
          </Grid>
          <Grid item xs={6} className="display-flex">
            <div className="display-flex">
              <Lock className="icon-size" />
              <Typography variant="h3" className="headerTitle">
                Lock
              </Typography>
            </div>
            <div className="display-flex padding-left">
              <Compare className="icon-size" />
              <Typography variant="h3" className="headerTitle">
                Compare
              </Typography>
            </div>
            <div className="display-flex padding-left">
              <Block className="icon-size" />
              <Typography variant="h3" className="headerTitle">
                Hide
              </Typography>
            </div>
          </Grid>
        </Grid>
      </div>
    )
  }

  render() {
    const { open, columns, isMobile } = this.props
    const { selected } = this.state
    const selectedObj = this.getSelectedCol(selected)

    if (isMobile) {
      return this.renderMobileContainer()
    }

    return (
      <Dialog open={open} className="lockandcompare-popover-form">
        <div className="ctl-container">
          <header className="header-container">
            <Typography variant="h2" className="title">
              Lock and Compare Columns
            </Typography>
          </header>
          {selectedObj.lockColumn.length > 0 && this.renderClearAndSortBtn(selectedObj)}
          {this.renderColumnsTable()}
          <div className="columns-section columns-list-container">
            <VirtualList
              {...selectedObj.lockColumn}
              {...selectedObj.compareColumn}
              ref={this.listRef}
              width={700}
              height={250}
              rowCount={columns.length}
              rowHeight={40}
              rowRenderer={this.renderRow}
              className="column-filter-list column-list"
            />
          </div>
          <footer className="footer">
            <Button variant="text" color="primary" onClick={this.handleApplyLockAndCompare} id="lock-compare-apply-btn">
              Apply
            </Button>
            <Button variant="text" color="secondary" onClick={this.handleDismissPopover} id="lock-compare-close-btn">
              Close
            </Button>
          </footer>
        </div>
      </Dialog>
    )
  }
}

LockAndCompare.propTypes = {
  open: PropTypes.bool.isRequired,
  cardInfo: PropTypes.object.isRequired,
  columns: PropTypes.array,
  selectedColList: PropTypes.array,
  submitLockAndCompare: PropTypes.func.isRequired,
}

export default LockAndCompare
