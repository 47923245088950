import React from 'react'
import PropTypes from 'prop-types'
import AutoCompleteSelect from './AutoCompleteSelect.component'

class GreenfieldAutoCompleteSelect extends React.Component {
  render() {
    return (
      <AutoCompleteSelect
        id={this.props.id}
        inputId={this.props.inputId}
        ariaLabel={this.props.ariaLabel}
        customWidth={this.props.customWidth}
        isDefaultHeight={this.props.isDefaultHeight}
        isReset={this.props.isReset}
        label={this.props.label}
        suggestions={this.props.suggestions}
        searchApi={this.props.searchApi}
        selectedSuggestions={this.props.selectedSuggestions}
        isDisabled={this.props.isDisabled}
        shiftTab={this.props.shiftTab}
      />
    )
  }
}

GreenfieldAutoCompleteSelect.defaultProps = {
  isReset: false,
  label: '',
  suggestions: [],
  searchApi: () => {},
  selectedSuggestions: () => {},
  shiftTab: () => {},
}

GreenfieldAutoCompleteSelect.propTypes = {
  isReset: PropTypes.bool,
  label: PropTypes.string,
  suggestions: PropTypes.array,
  searchApi: PropTypes.func,
  selectedSuggestions: PropTypes.func,
  shiftTab: PropTypes.func,
}

export default GreenfieldAutoCompleteSelect
