import { call, put } from 'redux-saga/effects'
import axios from 'axios'
import { API_GATEWAY_URL } from './utils'

export const PREVIEW_FETCH_CARD_REQUESTED = 'PREVIEW_FETCH_CARD_REQUESTED'
export const PREVIEW_FETCH_CARD_SUCCEEDED = 'PREVIEW_FETCH_CARD_SUCCEEDED'
export const PREVIEW_FETCH_CARD_FAILED = 'PREVIEW_FETCH_CARD_FAILED'
export const TAB_CARDS_PAYLOAD_CLEAR_STATE = 'TAB_CARDS_PAYLOAD_CLEAR_STATE'

export const CLEAR_SAVEDCARDS = 'CLEAR_SAVEDCARDS'

// reducer
export default function cardPreviewReducer(state = {}, action = {}) {
  switch (action.type) {
    case PREVIEW_FETCH_CARD_REQUESTED: {
      const id = action.payload.id
      const newState = {
        ...state,
        fetchCardData: {
          status: 'requested',
        },
        cards: {
          ...state.cards,
          [id]: {
            status: 'requested',
          },
        },
        tabCardsPayload: {
          ...state.tabCardsPayload,
          [id]: action.payload.json,
        },
      }
      return newState
    }
    case PREVIEW_FETCH_CARD_SUCCEEDED: {
      const id = action.payload.data.card_config._id

      const newState = {
        ...state,
        fetchCardData: action.payload,
        cards: {
          ...state.cards,
          [id]: action.payload,
        },
      }

      return newState
    }

    case CLEAR_SAVEDCARDS: {
      const newState = {
        ...state,
        cards: {},
      }
      return newState
    }

    case PREVIEW_FETCH_CARD_FAILED: {
      const cardId = action.payload.cardId.id
      const newState = {
        ...state,
        cards: {
          ...state.cards,
          [cardId]: action.payload.resp,
        },
        fetchCardData: action.payload.resp,
      }
      return newState
    }

    case TAB_CARDS_PAYLOAD_CLEAR_STATE: {
      const newtabCardsPayload = {
        ...state,
        tabCardsPayload: {},
      }
      return newtabCardsPayload
    }

    default:
      return state
  }
}

// actions
export function getCardData(data) {
  return {
    type: PREVIEW_FETCH_CARD_REQUESTED,
    payload: data,
  }
}

export const clearSavedCards = () => ({
  type: CLEAR_SAVEDCARDS,
})

export function clearTabCardsPayload() {
  return {
    type: TAB_CARDS_PAYLOAD_CLEAR_STATE,
  }
}

// sagas
export function* fetchPreviewCardData(action) {
  try {
    const previewCardInfo = yield call(axios, {
      method: 'post',
      url: `${API_GATEWAY_URL}/bi_reporting_assets/v3/execute_cards?card_id=${action.payload.id}`,
      data: action.payload.json,
    })

    if (previewCardInfo.error) {
      const e = previewCardInfo.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }

    yield put({ type: 'PREVIEW_FETCH_CARD_SUCCEEDED', payload: previewCardInfo })
  } catch (e) {
    yield put({ type: 'PREVIEW_FETCH_CARD_FAILED', payload: { cardId: action.payload, resp: e.response } })
  }
}
