import React from 'react'
import { connect } from 'react-redux'
import Gfv from 'greenfield-visualizations'

import { getBasemap, updateBasemapCardIds } from '../../../ducks/maps'
import { updateCardWithoutRerender } from '../../../ducks/cards'

export class MapsContainer extends React.Component {
  render() {
    return <Gfv {...this.props} />
  }
}

const mapStateToProps = state => ({
  reduxBasemapStatus: state.maps.basemapStatus,
})
export const mapDispatchToProps = dispatch => ({
  getBasemap(data) {
    dispatch(getBasemap(data))
  },
  updateBasemapCardIds(data) {
    dispatch(updateBasemapCardIds(data))
  },
  updateCardWithoutRerender(data) {
    dispatch(updateCardWithoutRerender(data))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(MapsContainer)
