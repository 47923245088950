import React, { useEffect, useMemo, useState } from 'react'
import {
  Chip,
  Autocomplete,
  TextField,
  CircularProgress,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  Stack,
} from '@mui/material'
import { Cancel, Group, Person } from '@mui/icons-material'
import styled from 'styled-components'
import debounce from 'lodash/debounce'
import { useDispatch, useSelector } from 'react-redux'
import { clearDetailOwnersInfo, getDetailsOwners } from '../../../ducks/builder'

export const SChip = styled(Chip)`
  margin: 3px;
`

/***
 * Reusable Autocomplete component for Owners, Vendors, Viewers (User group lookup)
 */

const GFAutocomplete = ({
  value,
  setValue,
  label = '',
  vendors = false,
  variant = 'outlined',
  id = 'autocomplete-outlined',
  disabled = false,
  membersOnly = false,
  groupsOnly = false,
  required = true,
}) => {
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [options, setOptions] = useState([])
  const [searchText, setSearchText] = useState('')
  const { detailsOwnersStatus } = useSelector(state => state.builder)

  const searchAssets = useMemo(() => {
    return debounce(searchText => {
      const payload = {
        type: 'external',
        data: searchText,
      }
      if (vendors) {
        dispatch(getDetailsOwners(payload))
      } else {
        dispatch(getDetailsOwners(searchText))
      }
    }, 300)
  }, [])

  useEffect(() => {
    if (detailsOwnersStatus?.data) {
      setLoading(false)
      setOptions(() =>
        membersOnly
          ? detailsOwnersStatus.data.filter(item => item.type === 'user')
          : groupsOnly
          ? detailsOwnersStatus.data.filter(item => item.type === 'group')
          : detailsOwnersStatus.data
      )
    }
  }, [detailsOwnersStatus])

  useEffect(() => {
    if (searchText.length >= 3) {
      setLoading(true)
      searchAssets(searchText)
    }
  }, [searchText])

  return (
    <Autocomplete
      id={id}
      data-cy={id}
      multiple
      open={open}
      onOpen={() => {
        setOptions([])
        setOpen(true)
      }}
      onClose={() => {
        setOptions([])
        setOpen(false)
      }}
      autoComplete
      includeInputInList
      filterSelectedOptions
      disabled={disabled}
      filterOptions={x => x}
      noOptionsText="Please add text to search"
      getOptionLabel={option => {
        return option.user_group_name || (option.first_name && `${option.first_name} ${option.last_name}`)
      }}
      options={options}
      onChange={(event, newValue) => {
        // disable chips deletion on keydown
        if (event.type !== 'keydown') {
          setValue(newValue)
          dispatch(clearDetailOwnersInfo())
        }
      }}
      onInputChange={(event, newInputValue) => {
        setSearchText(newInputValue)
      }}
      value={value || ''}
      renderInput={params => (
        <TextField
          {...params}
          variant={variant}
          required={required}
          label={label}
          InputProps={{
            ...params.InputProps,
            style: value.length > 2 ? { flexWrap: 'wrap', paddingTop: '5px' } : { paddingTop: '5px' },
            endAdornment: (
              <>
                {loading && searchText.length > 2 ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      renderTags={(value, getTagProps) => {
        return value.map((option, index) => (
          <Chip
            key={index}
            color="primary"
            sx={{ m: '3px' }}
            label={
              <Stack direction="row" alignItems="center" gap={1} justifyContent="flex-start">
                {option.type === 'group' ? <Group /> : option.type === 'user' ? <Person /> : ''}
                <Typography>
                  {option.user_group_name || (option.first_name && `${option.first_name} ${option.last_name}`)}
                </Typography>
              </Stack>
            }
            {...getTagProps({ index })}
            deleteIcon={<Cancel tabIndex={0} style={{ color: 'white' }} />}
          />
        ))
      }}
      renderOption={(props, option) => (
        <ListItem {...props} key={option._id} component="div" button>
          <span className="owner-list-item" data-cy="owner-list-item">
            {option.type && <ListItemAvatar>{option.type === 'group' ? <Group /> : <Person />}</ListItemAvatar>}
            <ListItemText
              primary={option.user_group_name || (option.first_name && `${option.first_name} ${option.last_name}`)}
              secondary={option._id}
            />
          </span>
        </ListItem>
      )}
    />
  )
}

export default GFAutocomplete

export const filterUserType = (usersArr = [], type) => {
  return usersArr.reduce((accumulator, eachOwner) => {
    if (eachOwner.type === type) {
      accumulator.push(eachOwner._id)
    }
    return accumulator
  }, [])
}
