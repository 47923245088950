import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { ListItemIcon, Chip, Paper, Grid } from '@mui/material'
import { LibraryAdd } from '@mui/icons-material'

import moment from 'moment'
import './profile.scss'
import FavoriteButton from '../../shared/FavoriteButton/FavoriteButton'
import { isCertifiedDataset } from '../../shared/util'
import { AgGridTable } from '../../shared/AgGrid/AgGridTableClientSide'
import { CertifiedIcon } from '../../shared/Svgicon/Svgicon'
import { TableFilter } from '../../shared/AgGrid/TableFilter'
import { dateComparator } from '../../shared/AgGrid/AgGridHelper'
import AssetTable, { AssetType } from '../../shared/AssetTableSS/AssetTable'
import { getCardFiltersURL } from '../Cards/Cardviewer/DataAlertAndSubscription/Helper'
import StatusFilter from '../../shared/AssetTableSS/StatusFilters'
import { SubscriptionActionRenderer } from './SubscriptionActionRenderer'
import { SubscriptionDetailComponent } from './SubscriptionDetailComponent'

const SUBSCRIPTION_OPT_OUT_STATUS = {
  Subscribed: 'Subscribed',
  Unsubscribed: 'Unsubscribed',
  Disabled: 'Disabled',
}

const ContentTable = ({
  chipInfo = [],
  chipTitle,
  isFavorite,
  assetIds,
  isHomepage = false,
  onFavoriteClick = null,
  setSubscriptions,
  routeProps,
  trackEvent,
  isMobile,
  isShowCardNotifications,
  ownerOnly = false,
  statusType,
  setStatusType,
  sendFireflyEvent,
  currentUser,
}) => {
  const [gridApi, setGridApi] = useState(null)
  const gridRef = useRef()
  const [pageSize, setPageSize] = useState(10)

  const subscriptionId = new URLSearchParams(routeProps?.location?.search).get('id') || ''

  useEffect(() => {
    if (subscriptionId && gridRef) {
      gridRef.current.api.setQuickFilter(subscriptionId)
    }
  }, [subscriptionId])

  const onGridReady = params => {
    setGridApi(params.api)
  }

  const textFormatting = str => {
    return str.replace(/\w\S*/g, txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase())
  }

  const handleStatusChange = statusType => {
    setStatusType(statusType)
  }

  const getData = () => {
    switch (chipTitle) {
      case 'Card':
        return chipInfo?.map(chip => ({
          name: chip.card_attribute.card_title,
          owners: isFavorite ? chip.card_attribute.owners : chip.search.owner_names,
          last_modified_time: moment(chip.card_attribute.last_updated_date).format('DD MMM YYYY'),
          _id: chip._id,
        }))
      case 'Dashboard':
        return chipInfo?.map(chip => ({
          name: chip.dashboard_title,
          owners: isFavorite ? chip.owners : chip.search.owner_names,
          last_modified_time: moment(chip.last_modified_time).format('DD MMM YYYY'),
          _id: chip._id,
        }))
      case 'Dataset':
        return chipInfo?.map(chip => ({
          name: chip.dataset_business_name,
          dataset_description: chip.dataset_description,
          owners: isFavorite ? chip.owners : chip.owners_access,
          last_modified_time: moment(chip.last_updated).format('DD MMM YYYY'),
          last_load_date: chip?.last_load_date && moment(chip.last_load_date).fromNow(),
          favorite: chip.favorite,
          certified: chip.certified,
          _id: chip._id,
        }))
      case 'Calculated Field':
        return chipInfo?.map(chip => ({
          name: chip.column_name,
          query: chip.exprQL,
          dataset: chip.dataset_name,
          dataset_id: chip.dataset_id,
          created_date: moment(chip.created_date).format('DD MMM YYYY'),
          _id: chip._id,
        }))
      case 'Group':
        return chipInfo?.map(chip => ({
          name: chip.group_name,
          owner: chip.owner,
          _id: chip._id,
        }))
      case 'Subscription':
        return chipInfo
    }
  }

  const headers =
    chipTitle === 'Subscription'
      ? [
          {
            field: 'name',
            headerName: '',
            maxWidth: 50,
            filter: false,
            sortable: false,
            hide: isMobile,
            suppressColumnsToolPanel: true,
            cellRenderer: 'agGroupCellRenderer',
            valueFormatter: () => '',
            suppressMenu: true,
          },
          {
            field: 'name',
            headerName: 'Name',
          },
          {
            field: 'description',
            headerName: 'Description',
          },
          {
            field: '_id',
            headerName: 'Subscription ID',
            hide: true,
            filter: false,
          },
          {
            field: 'card_id',
            headerName: 'Subscribed Card',
            cellRendererFramework: ({ data, value }) => {
              const cardFiltersUrl = getCardFiltersURL({ appliedFilters: data.filters, cardId: data.card_id })
              return (
                <Link target="_blank" rel="noopener" to={{ pathname: cardFiltersUrl }}>
                  {value}
                </Link>
              )
            },
          },
          {
            field: 'subscription_type',
            headerName: 'Subscription Type',
            valueGetter: ({ data }) => (data.subscription_type === 'DATA_ALERT' ? 'Data Alert' : 'Subscription'),
          },
          {
            field: 'run_period',
            headerName: 'Frequency',
          },
          {
            field: 'end_time',
            headerName: 'Subscription Expiry',
            minWidth: 250,
            comparator: dateComparator,
            cellRendererFramework: ({ data, value }) =>
              moment(value) < new Date() ? (
                <Chip label="EXPIRED" size="small" color="error" />
              ) : (
                moment(data.end_time, "YYYY-MM-DD'T'HH:mm:ssZ").fromNow()
              ),
          },
          {
            field: 'has_optedout',
            headerName: 'Actions',
            pinned: 'right',
            width: 220,
            suppressMenu: true,
            filter: true,
            sortable: false,
            cellRendererFramework: SubscriptionActionRenderer,
            valueGetter: ({ data }) =>
              data.is_recipient && moment(data.end_time) > new Date()
                ? data.has_optedout
                  ? SUBSCRIPTION_OPT_OUT_STATUS.Unsubscribed
                  : SUBSCRIPTION_OPT_OUT_STATUS.Subscribed
                : SUBSCRIPTION_OPT_OUT_STATUS.Disabled,
            cellRendererParams: {
              setSubscriptions,
              trackEvent,
              isShowCardNotifications,
              isMobile,
              sendFireflyEvent,
              statusType,
            },
          },
        ]
      : chipTitle !== 'Calculated Field'
      ? chipTitle === 'Group'
        ? [
            {
              field: 'name',
              headerName: 'Name',
              cellRendererFramework: ({ value, data }) => (
                <Link to={`/admin/groups/${data._id}/members`}>{value.trim()}</Link>
              ),
            },
            {
              field: 'owner',
              headerName: 'Owner',
              cellRendererFramework: ({ value }) => (
                <Chip label={value} className="chip-owners" component="span" size="small" role="note" />
              ),
            },
          ]
        : [
            isHomepage && {
              headerName: '',
              field: 'favorite',
              filter: false,
              sortable: false,
              cellRendererFramework: ({ data }) => (
                <FavoriteButton
                  icon="star"
                  data-cy="shoo"
                  onClick={() => {
                    onFavoriteClick(data._id)
                  }}
                />
              ),
              maxWidth: 100,
            },
            {
              headerName: 'Name',
              field: 'name',
              cellRendererFramework: ({ value, data }) => {
                return chipTitle === 'Card' ? (
                  <Link to={`/card/${data._id}`}>{value}</Link>
                ) : chipTitle === 'Dashboard' ? (
                  <Link to={`/dashboard/${data._id}`}>{value}</Link>
                ) : (
                  <>
                    <Link to={`/dataset/preview/${data._id}`} data-cy={`datasetId-${data._id}`}>
                      {value}
                    </Link>
                    {isCertifiedDataset(data) && <CertifiedIcon className="dataset-certified-icon" />}
                  </>
                )
              },
              ...(isHomepage && { width: 450 }),
            },
            ...(isHomepage
              ? [
                  {
                    headerName: 'Description',
                    field: 'dataset_description',
                  },
                  {
                    headerName: 'Last Load Date',
                    field: 'last_load_date',
                    width: 150,
                  },
                  {
                    headerName: 'Create Card',
                    field: 'create_card',
                    sortable: false,
                    filter: false,
                    cellRendererFramework: ({ data }) => (
                      <Link
                        to={`/builder/card/dataset/${data._id}`}
                        style={{ display: 'inherit', textDecoration: 'none' }}
                      >
                        <ListItemIcon>
                          <LibraryAdd />
                        </ListItemIcon>
                      </Link>
                    ),
                    width: 100,
                  },
                ]
              : [
                  {
                    headerName: 'Owners',
                    field: 'owners',
                    cellRendererFramework: ({ value }) => {
                      return value.map((item, index) => (
                        <Chip
                          label={item?.user_group_name || textFormatting(value[index])}
                          key={index}
                          className="chip-owners"
                          component="span"
                          size="small"
                          role="note"
                        />
                      ))
                    },
                  },
                  {
                    headerName: 'Last Updated Date',
                    field: 'last_modified_time',
                    comparator: dateComparator,
                    valueGetter: ({ data }) => moment(data.last_modified_time).format('DD MMM YYYY'),
                  },
                ]),
          ].filter(Boolean)
      : [
          {
            headerName: 'Name',
            field: 'name',
          },
          {
            headerName: 'Query',
            field: 'query',
          },
          {
            headerName: 'Dataset',
            field: 'dataset',
            cellRendererFramework: ({ data, value }) => {
              return <Link to={`/dataset/preview/${data.dataset_id}/calculated-fields`}>{value}</Link>
            },
          },
          {
            headerName: 'Created Date',
            field: 'created_date',
          },
        ]

  return (
    <Paper
      sx={{ padding: 2 }}
      elevation={0}
      data-cy={`${isFavorite ? 'favorite-' : ''}${chipTitle.replace(/\s+/g, '-').toLowerCase()}-table`}
    >
      {chipTitle === 'Card' ? (
        <AssetTable
          assetType={AssetType.CARDS}
          isMobile={isMobile}
          ownerOnly={ownerOnly}
          favoriteOnly={isFavorite}
          assetIds={assetIds}
        />
      ) : chipTitle === 'Dashboard' ? (
        <AssetTable
          assetType={AssetType.DASHBOARDS}
          isMobile={isMobile}
          ownerOnly={ownerOnly}
          favoriteOnly={isFavorite}
          assetIds={assetIds}
        />
      ) : chipTitle === 'Dataset' ? (
        <AssetTable
          assetType={AssetType.DATASETS}
          isMobile={isMobile}
          ownerOnly={ownerOnly}
          favoriteOnly={isFavorite}
          assetIds={assetIds}
        />
      ) : chipTitle === 'Calculated Field' ? (
        <AssetTable assetType={AssetType.CALC_FIELDS} isMobile={isMobile} ownerOnly={ownerOnly} />
      ) : (
        <>
          {routeProps?.location?.pathname !== '/notification-manager' && (
            <TableFilter
              gridRef={gridRef}
              pageSize={pageSize}
              setPageSize={setPageSize}
              subscriptionId={subscriptionId}
            />
          )}
          {chipTitle === 'Subscription' && (
            <Grid container justifyContent="flex-end" alignItems="center">
              <StatusFilter statusType={statusType} handleStatusChange={handleStatusChange} />
            </Grid>
          )}
          <AgGridTable
            gridRef={gridRef}
            gridApi={gridApi}
            onGridReady={onGridReady}
            isMobile={isMobile}
            columns={headers}
            pageSize={pageSize}
            data={getData() || []}
            masterDetail
            detailCellRendererFramework={({ data }) => (
              <SubscriptionDetailComponent rowData={data} isInternalUser={currentUser?.user_type === 'internal'} />
            )}
          />
        </>
      )}
    </Paper>
  )
}

export default ContentTable
