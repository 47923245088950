import React from 'react'
import {
  Checkbox,
  Radio,
  MenuItem,
  TextField,
  Chip,
  Icon,
  Tooltip,
  FormControl,
  Select,
  InputLabel,
  Grid,
} from '@mui/material'
import { List as VirtualList, CellMeasurer, CellMeasurerCache } from 'react-virtualized'
import cloneDeep from 'lodash/cloneDeep'
import PropTypes from 'prop-types'
import { Spinner, STRING_COLUMN_TYPE_OPTIONS } from 'greenfield-utilities'

import UploadFilterFile from '../UploadFilterFile/UploadFilterFile'
import { escapeOutput } from '../util'
import { isVirtualColumnCheck } from '../../routes/Builder/Sidebar/utils'
import TimePeriod from '../TimePeriod/TimePeriod.container'
import TimePeriodCalendarType from '../TimePeriod/TimePeriodCalendarType'
import TimePeriodGranularity from '../TimePeriod/TimePeriodGranularity'
import { METRIC_TYPES } from '../../constants/ArrayConstants'
import {
  getCardFilterObject,
  getDashboardFilterObject,
  getFilterCtrlValues,
  getFilteredColumn,
} from './filter-utils.js'
import './filterviewer.scss'

class FilterDimension extends React.Component {
  constructor(props) {
    super(props)
    const propPattern = props?.activeFilters.find(item => item.type === '!=' || item.type === '==')?.pattern

    const filterCtrlValues = getFilterCtrlValues(props.activeFilters, props.selectedFilter, props.objectType)
    const type = filterCtrlValues && filterCtrlValues.type ? filterCtrlValues.type : props.selectedFilter.type || 'in'

    this.filterItemRef = React.createRef()
    this.state = {
      type,
      pattern: filterCtrlValues?.pattern
        ? Array.isArray(filterCtrlValues.pattern)
          ? type === '!=' || type === '=='
            ? filterCtrlValues.pattern[0]
            : filterCtrlValues.pattern
          : filterCtrlValues.pattern.split(',')
        : [],
      timePeriod: filterCtrlValues?.time_period || {},
      saveAsDefault: filterCtrlValues?.saveAsDefault ? filterCtrlValues.saveAsDefault : false,
      searchString: '',
      showSaveAsWarning: false,
      multiSelectString: '',
      filterData: [],
      filterItemCount: 0,
      radioFilterSelected:
        props.objectType !== 'dashboard'
          ? Array.isArray(propPattern)
            ? propPattern
            : [propPattern]
          : (filterCtrlValues && filterCtrlValues.pattern) || [],
      value: filterCtrlValues && filterCtrlValues.value ? filterCtrlValues.value : '',
      conditions: [],
      aggregationList: [],
      aggregationValue:
        filterCtrlValues && filterCtrlValues.aggregation_type ? filterCtrlValues.aggregation_type : 'Sum',
      upperValue: filterCtrlValues && filterCtrlValues.uppervalue ? filterCtrlValues.uppervalue : '',
      lowerValue: filterCtrlValues && filterCtrlValues.lowervalue ? filterCtrlValues.lowervalue : '',
      column:
        props.objectType !== 'dashboard'
          ? getFilteredColumn(props.selectedFilter, props.cardsDatasetStatus, props.datasetId, props.columnArray)
          : {},
    }
  }

  HIGH_CARDINALITY_THRESHOLD = 300
  searchTimeout = 0
  calulatedFieldType = ['postagg', 'aggregation']

  componentDidMount() {
    const { filterMetadataStatus, objectType } = this.props
    const { conditions, column } = this.state
    if (
      Object.keys(filterMetadataStatus).length &&
      filterMetadataStatus.status >= 200 &&
      filterMetadataStatus.status < 300 &&
      !conditions.length &&
      ((column && Object.keys(column).length) || objectType === 'dashboard')
    ) {
      const filterOper = filterMetadataStatus.data.filter_operations_aggregations
      const dimFilterOp = filterOper.find(obj => obj.name === 'filter_operations') || {}
      const newConditions =
        dimFilterOp && Object.keys(dimFilterOp).length > 0
          ? objectType === 'dashboard'
            ? dimFilterOp.dimension
            : dimFilterOp[column.type]
          : []

      const aggregationList = filterOper.find(obj => obj.name === 'aggregation_operations').metric

      this.setState({
        conditions: newConditions,
        aggregationList,
      })
    }
  }

  /* eslint-disable camelcase */
  UNSAFE_componentWillReceiveProps(nextProps) {
    /* eslint-enable camelcase */
    const {
      objectType,
      selectedFilter,
      activeFilters,
      renderFilterStatus,
      filterMetadataStatus,
      cardsDatasetStatus,
      datasetId,
      columnArray,
    } = nextProps
    const { filterData, searchString, filterItemCount, column } = this.state
    const key = objectType === 'dashboard' ? 'filter_id' : 'dimension'
    let { conditions, aggregationList } = this.state

    if (
      selectedFilter[key] !== this.props.selectedFilter[key] ||
      (!selectedFilter[key] && selectedFilter.ref_id && selectedFilter.ref_id !== this.props.selectedFilter.ref_id)
    ) {
      const columnValue =
        objectType !== 'dashboard'
          ? Object.keys(selectedFilter).length
            ? getFilteredColumn(selectedFilter, cardsDatasetStatus, datasetId, columnArray)
            : column
          : {}
      if (
        filterMetadataStatus &&
        filterMetadataStatus.data &&
        Object.keys(selectedFilter).length &&
        ((columnValue && Object.keys(columnValue).length) || objectType === 'dashboard')
      ) {
        const filterOper = filterMetadataStatus.data.filter_operations_aggregations
        const dimFilterOp = filterOper.find(obj => obj.name === 'filter_operations') || {}
        conditions =
          dimFilterOp && Object.keys(dimFilterOp).length > 0
            ? objectType === 'dashboard'
              ? dimFilterOp.dimension
              : dimFilterOp[columnValue.type]
            : []
        aggregationList = filterOper.find(obj => obj.name === 'aggregation_operations').metric
      }
      const filterCtrlValues = getFilterCtrlValues(activeFilters, selectedFilter, objectType)

      this.setState({
        filterData:
          renderFilterStatus.status >= 200 && renderFilterStatus.status < 300
            ? renderFilterStatus.data.query_results
            : [],
        filterItemCount:
          !searchString && renderFilterStatus.status >= 200 && renderFilterStatus.status < 300
            ? renderFilterStatus.data.query_results.length
            : filterItemCount,
        type: filterCtrlValues && filterCtrlValues.type ? filterCtrlValues.type : selectedFilter.type || 'in',
        value: filterCtrlValues && filterCtrlValues.value ? filterCtrlValues.value : '',
        pattern: filterCtrlValues?.pattern
          ? Array.isArray(filterCtrlValues.pattern)
            ? filterCtrlValues.pattern
            : filterCtrlValues.pattern.split(',')
          : [],
        timePeriod: filterCtrlValues?.time_period || {},
        radioFilterSelected: [filterCtrlValues?.pattern],
        saveAsDefault: filterCtrlValues && filterCtrlValues.saveAsDefault ? filterCtrlValues.saveAsDefault : false,
        multiSelectString: '',
        aggregationValue:
          filterCtrlValues && filterCtrlValues.aggregation_type ? filterCtrlValues.aggregation_type : 'Sum',
        upperValue: filterCtrlValues && filterCtrlValues.uppervalue ? filterCtrlValues.uppervalue : '',
        lowerValue: filterCtrlValues && filterCtrlValues.lowervalue ? filterCtrlValues.lowervalue : '',
        searchString: '',
        column: columnValue,
        conditions,
        aggregationList,
      })
    } else {
      this.setState({
        filterData:
          searchString && filterItemCount < this.HIGH_CARDINALITY_THRESHOLD && filterData && filterData.length
            ? cloneDeep(filterData)
            : renderFilterStatus.status >= 200 && renderFilterStatus.status < 300
            ? renderFilterStatus.data.query_results
            : [],
        filterItemCount:
          !searchString && renderFilterStatus.status >= 200 && renderFilterStatus.status < 300
            ? renderFilterStatus.data.query_results.length
            : filterItemCount,
      })
    }
  }

  componentDidUpdate() {
    const { filterMetadataStatus, objectType } = this.props
    const { conditions, column } = this.state

    if (
      filterMetadataStatus &&
      Object.keys(filterMetadataStatus).length &&
      filterMetadataStatus.status >= 200 &&
      filterMetadataStatus.status < 300 &&
      !conditions?.length &&
      !isVirtualColumnCheck(column) &&
      ((column && Object.keys(column).length) || objectType === 'dashboard')
    ) {
      const filterOper = filterMetadataStatus.data.filter_operations_aggregations
      const dimFilterOp = filterOper.find(obj => obj.name === 'filter_operations') || {}
      const newConditions =
        dimFilterOp && Object.keys(dimFilterOp).length > 0
          ? objectType === 'dashboard'
            ? dimFilterOp.dimension
            : dimFilterOp[column.type]
          : []
      const aggregationList = filterOper.find(obj => obj.name === 'aggregation_operations').metric
      this.setState({
        conditions: newConditions,
        aggregationList,
      })
    }
  }

  handleConditionChange = e => {
    const { pattern, filterData } = this.state
    const { value } = e.target
    const isRadioAndNoValue = (value === '==' || value === '!=') && !pattern[0]

    this.savedFilterIsChanged('type', value)

    if (isRadioAndNoValue) {
      this.setState(
        {
          type: value,
          radioFilterSelected: [filterData[0]],
          pattern: [filterData[0]],
        },
        () => {
          this.saveFilter()
        }
      )
    } else {
      this.setState(
        {
          type: e.target.value,
        },
        () => {
          this.saveFilter()
          this.handleSwitchToNotEqual(e.target.value)
        }
      )
    }
  }

  handleSwitchToNotEqual = type => {
    const { pattern } = this.state
    if ((type === '!=' || type === '==') && Array.isArray(pattern)) {
      const _pattern = cloneDeep(pattern)
      _pattern.splice(1)

      const item = _pattern[0] === null ? 'null' : _pattern[0]

      this.setState(
        {
          pattern: item || [],
          radioFilterSelected: [item],
        },
        this.saveFilter
      )
    } else if (!Array.isArray(pattern)) {
      this.setState(
        {
          pattern: [pattern],
        },
        this.saveFilter
      )
    }
  }

  handleFilterItemToggle = item => {
    const { pattern } = this.state
    const currentIndex = pattern.indexOf(item)
    const newChecked = [...pattern]

    if (currentIndex === -1) {
      newChecked.push(item)
    } else {
      newChecked.splice(currentIndex, 1)
    }
    this.savedFilterIsChanged('pattern', newChecked)
    this.setState(
      {
        radioFilterSelected: [item === null ? 'null' : item],
        pattern: newChecked,
      },
      this.saveFilter
    )
  }

  handleFilterRadioItemToggle = item => {
    const { radioFilterSelected } = this.state
    item = item === null ? 'null' : item
    const _item = Array.isArray(item) ? item[0] : item
    const newChecked =
      _item === (Array.isArray(radioFilterSelected) ? radioFilterSelected[0] : radioFilterSelected) ? [] : [item]
    this.savedFilterIsChanged('pattern', newChecked)

    this.setState(
      {
        radioFilterSelected: newChecked,
        pattern: newChecked[0] || [],
      },
      this.saveFilter
    )
  }

  handleMultiSelect = e => {
    this.setState({ multiSelectString: e.target.value })
  }

  checkType() {
    const { type, column } = this.state

    if (column && type === 'between' && !this.calulatedFieldType.includes(column.type)) {
      return this.renderBetween.bind(this)
    } else if (column && this.calulatedFieldType.includes(column.type)) {
      return this.renderCalculatedField.bind(this)
    } else if (column && column.type === 'metric') {
      return this.renderMetric.bind(this)
    } else if (type === 'contains') {
      return this.renderContains
    } else if (type === 'regex') {
      return this.renderRegex.bind(this)
    } else {
      return this.renderList.bind(this)
    }
  }

  handleApplyMultiSelect = e => {
    const { pattern, multiSelectString } = this.state
    const newMultiSelectString = multiSelectString
      .split(',')
      .map(item => item.trim())
      .filter(item => pattern.indexOf(item) < 0)
    if (e.key === 'Enter') {
      const newPattern = [...pattern, ...newMultiSelectString]
      this.savedFilterIsChanged('pattern', newPattern)
      this.setState({ multiSelectString: '', pattern: newPattern }, this.saveFilter)
    }
  }

  handleRegexOrContainsOnChange = e => {
    this.setState(
      {
        pattern: e.target.value.split(','),
      },
      this.saveFilter
    )
  }

  handleValueChange = (state, value) => {
    this.savedFilterIsChanged(state, value)
    this.setState(
      {
        [state]: value,
      },
      this.saveFilter
    )
  }

  handleSaveAsDefaultChange = e => {
    this.setState({ saveAsDefault: e.target.checked, showSaveAsWarning: false }, this.saveFilter)
  }

  handleSearchChange = e => {
    const { onHighCardinalitySearch, selectedFilter, renderFilterStatus, objectType } = this.props
    const { filterItemCount } = this.state
    const searchString = e.target.value
    const key = objectType === 'dashboard' ? 'filter_id' : 'dimension'
    if (filterItemCount >= this.HIGH_CARDINALITY_THRESHOLD || renderFilterStatus.status === 'failed') {
      this.setState({ searchString })
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout)
      }
      this.searchTimeout = setTimeout(() => {
        onHighCardinalitySearch(selectedFilter[key], searchString)
      }, 1000)
    } else {
      const newFilterdata = renderFilterStatus?.data?.query_results
        ? renderFilterStatus.data.query_results.filter(item =>
            new RegExp(e.target.value.replace(/\\/g, '\\\\'), 'i').test(`${item}`)
          )
        : []

      this.setState({
        filterData: newFilterdata,
        searchString,
      })
    }
  }

  savedFilterIsChanged(key, newValue) {
    const { appliedFilter, objectType } = this.props
    const savedFilter =
      objectType === 'dashboard'
        ? appliedFilter && appliedFilter.cardFilters && appliedFilter.cardFilters[0].filter
          ? Object.assign({}, appliedFilter.cardFilters[0].filter)
          : {}
        : Object.assign({}, appliedFilter)
    if (savedFilter.saveAsDefault) {
      if (savedFilter.pattern !== newValue || savedFilter[key] !== newValue) {
        this.setState({
          showSaveAsWarning: true,
          saveAsDefault: false,
        })
      } else {
        this.setState({
          showSaveAsWarning: false,
        })
      }
    }
  }

  saveFilter() {
    const { pattern, type, saveAsDefault, value, aggregationValue, upperValue, lowerValue, column, timePeriod } =
      this.state
    const { selectedFilter, objectType, onAddActiveFilter } = this.props
    let filter = {}

    if ((value || value === 0) && type !== 'between') {
      if (column.type && column.type === 'metric') {
        filter = getCardFilterObject(selectedFilter, '', type, saveAsDefault, value, aggregationValue)
      } else {
        filter = getCardFilterObject(selectedFilter, '', type, saveAsDefault, value)
      }
      onAddActiveFilter(filter)
    } else if ((upperValue || upperValue === 0) && (lowerValue || lowerValue === 0)) {
      if (column.type && column.type === 'metric') {
        filter = getCardFilterObject(
          selectedFilter,
          '',
          type,
          saveAsDefault,
          '',
          aggregationValue,
          lowerValue,
          upperValue
        )
      } else {
        filter = getCardFilterObject(selectedFilter, '', type, saveAsDefault, '', '', lowerValue, upperValue)
      }
      onAddActiveFilter(filter)
    } else if (pattern?.length >= 0 && type !== 'none' && type !== 'interval') {
      const _pattern = (type === '!=' || type === '==') && Array.isArray(pattern) ? pattern[0] : pattern
      filter =
        objectType === 'dashboard'
          ? getDashboardFilterObject(selectedFilter, _pattern, type, saveAsDefault)
          : getCardFilterObject(selectedFilter, _pattern, type, saveAsDefault)
      onAddActiveFilter(filter)
    } else if ((timePeriod?.interval || timePeriod?.granularity) && type === 'interval') {
      filter =
        objectType === 'dashboard'
          ? getDashboardFilterObject(selectedFilter, timePeriod, type, saveAsDefault)
          : getCardFilterObject(selectedFilter, timePeriod, type, saveAsDefault)
      onAddActiveFilter(filter)
    }
  }

  renderVirtualList() {
    const { filterData, pattern, type, radioFilterSelected } = this.state
    const { isMobile } = this.props
    /*
      This is doing some calculation to figure out how large to make the filter box
      It gets the height of the page and how far down the page the filter box is
      Then subtracts the page height from how far down the page the box is to get the height of the filter box
    */
    const fromTop = this.filterItemRef.current ? this.filterItemRef.current.offsetTop : 300
    const pageHeight = window.innerHeight
    const height = pageHeight - fromTop - 175
    const filterHeight = this.props.isMobile ? height : 165
    const cache = new CellMeasurerCache({
      fixedWidth: true,
      defaultHeight: 40,
    })

    const renderRow = ({ key, index, style, parent }) => (
      <CellMeasurer cache={cache} columnIndex={0} parent={parent} rowIndex={index} key={key}>
        <div style={style}>
          {type === '!=' || type === '==' ? (
            <>
              <Radio
                checked={
                  filterData[index] === radioFilterSelected[0] ||
                  (radioFilterSelected[0] === 'null' && filterData[index] === null)
                }
                id={`dimension-value${index}`}
                label={filterData[index] || String(filterData[index])}
                onChange={() => {
                  this.handleFilterRadioItemToggle(filterData[index])
                }}
              />
              <span
                className="filter-dimension-label"
                role="button"
                tabIndex={-1}
                onClick={() => {
                  this.handleFilterRadioItemToggle(filterData[index])
                }}
                onKeyPress={e => {
                  if (e.key === 'Enter') {
                    this.handleFilterRadioItemToggle(filterData[index])
                  }
                }}
              >
                {filterData[index] || String(filterData[index])}
              </span>
            </>
          ) : (
            <>
              <Checkbox
                id={`dimension-value${index}`}
                checked={pattern.indexOf(filterData[index]) !== -1}
                tabIndex={-1}
                label={filterData[index] || String(filterData[index])}
                onChange={() => {
                  this.handleFilterItemToggle(filterData[index])
                }}
              />
              <span
                className="filter-dimension-label"
                role="button"
                tabIndex={-1}
                onClick={() => {
                  this.handleFilterItemToggle(filterData[index])
                }}
                onKeyPress={e => {
                  if (e.key === 'Enter') {
                    this.handleFilterItemToggle(filterData[index])
                  }
                }}
              >
                {filterData[index] || String(filterData[index])}
              </span>
            </>
          )}
        </div>
      </CellMeasurer>
    )

    return (
      <VirtualList
        {...pattern} // pass pattern as props in order to force render of row when checkbox state changes
        width={330}
        height={filterHeight}
        rowCount={filterData.length}
        rowRenderer={renderRow}
        className={`filter-list-container ${isMobile ? 'mobile-filter-list-container' : ''}`}
        columnWidth={cache.columnWidth}
        rowHeight={cache.rowHeight}
        deferredMeasurementCache={cache}
      />
    )
  }

  onHandleUploadRows = rows => {
    const { pattern } = this.state
    const rowArray = [].concat.apply([], rows)
    const stringArray = rowArray.map(str => {
      str = String(str)
      if (str && str.includes('<') && (str.includes('>') || str.includes('</') || str.includes('/>'))) {
        str = escapeOutput(str)
      }
      return str
    })
    const comArr = [...new Set(pattern.concat(stringArray))]
    this.savedFilterIsChanged('pattern', comArr)
    this.setState({ pattern: comArr }, this.saveFilter)
  }

  renderSaveAsDefault = (saveAsDefault, isMobile) => (
    <div>
      <Checkbox
        id="save-as-default-filter"
        className={isMobile ? 'mobileSaveAsDefaultCheckbox' : ''}
        checked={saveAsDefault}
        onChange={this.handleSaveAsDefaultChange}
        aria-label="Save as Default Filter"
      />
      <span>Save as Default Filter</span>
    </div>
  )

  renderList() {
    const { searchString, saveAsDefault, showSaveAsWarning, multiSelectString } = this.state
    const { renderFilterStatus, isMobile, renderType } = this.props

    return (
      <div>
        {this.props.selectedFilter.display_filter_type !== 'text' && (
          <>
            <TextField
              id="filter-textarea-search"
              className="field-margin"
              aria-labelledby="Search categories"
              label="Search categories"
              onChange={this.handleSearchChange}
              margin="normal"
              autoComplete="off"
              value={searchString}
              fullWidth
              variant="standard"
            />
            <div className={isMobile ? 'filter-items mobile' : 'filter-items'} ref={this.filterItemRef}>
              {renderFilterStatus.status >= 200 && renderFilterStatus.status < 300 && this.renderVirtualList()}
              {renderFilterStatus.status === 'requested' && (
                <div id="filter-spinner-container">
                  <Spinner size="medium" layout="selfCentering" />
                </div>
              )}
            </div>
            <div className="section-helper-text">
              This list shows up to 300 items. If you don't see the item you want to filter on, try a more specific
              search.
            </div>
          </>
        )}
        <div>
          <TextField
            id="filter-multi-select"
            className="field-margin"
            aria-labelledby="Enter comma separated values and hit enter"
            label="Enter comma separated values and hit enter"
            onChange={this.handleMultiSelect}
            onKeyPress={this.handleApplyMultiSelect}
            margin="normal"
            autoComplete="off"
            value={multiSelectString}
            fullWidth
            variant="standard"
          />
        </div>
        {!isMobile && <UploadFilterFile handleUploadRows={this.onHandleUploadRows} />}
        <div id="filter-save-as-container" className={isMobile ? 'mobileSaveDefaultContainer' : ''}>
          {showSaveAsWarning && (
            <Tooltip id="tooltip-icon" title="You modified your saved filter - save to persist changes">
              <Icon aria-label="You modified your saved filter - save to persist changes" style={{ color: '#fd624c' }}>
                warning
              </Icon>
            </Tooltip>
          )}
          {renderType !== 'largeExport' && this.renderSaveAsDefault(saveAsDefault, isMobile)}
        </div>
      </div>
    )
  }

  renderRegex() {
    const { pattern } = this.state
    return (
      <div>
        <TextField
          label="Enter pattern"
          onChange={this.handleRegexOrContainsOnChange}
          aria-labelledby="pattern"
          fullWidth
          margin="normal"
          autoComplete="off"
          value={pattern.toString()}
          variant="standard"
        />
      </div>
    )
  }

  renderContains = () => {
    const { pattern } = this.state

    return (
      <div>
        <TextField
          label="Enter word"
          onChange={this.handleRegexOrContainsOnChange}
          aria-labelledby="pattern"
          fullWidth
          margin="normal"
          autoComplete="off"
          value={pattern.toString()}
          variant="standard"
        />
      </div>
    )
  }

  renderBetween() {
    const { upperValue, lowerValue, aggregationValue, showSaveAsWarning, saveAsDefault, aggregationList } = this.state
    return (
      <>
        <FormControl className="field-margin-right width-45 margin-right field-margin-left" variant="standard">
          <InputLabel htmlFor="filter-aggregartion-select">Aggregation</InputLabel>
          <Select
            variant="standard"
            id="filter-aggregation"
            name="aggregation_type"
            value={aggregationValue}
            onChange={event => this.handleValueChange('aggregationValue', event.target.value)}
            aria-label={`Aggregation ${aggregationValue}`}
          >
            {aggregationList.map((value, index) => (
              <MenuItem key={value} value={value} id={`filter-agg${index}`}>
                {value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          className="field-margin-right width-45 margin-right"
          name="lowervalue"
          type="number"
          id="from-field"
          label="From"
          value={lowerValue ? Number.parseFloat(lowerValue) : lowerValue}
          onChange={event => this.handleValueChange('lowerValue', event.target.value)}
          margin="normal"
          aria-labelledby="From"
          variant="standard"
        />
        <TextField
          className="field-margin-right width-45"
          name="uppervalue"
          type="number"
          id="to-field"
          label="To"
          value={upperValue ? Number.parseFloat(upperValue) : upperValue}
          onChange={event => this.handleValueChange('upperValue', event.target.value)}
          margin="normal"
          aria-labelledby="To"
          variant="standard"
        />
        <div id="filter-save-as-container">
          {showSaveAsWarning && (
            <Tooltip id="tooltip-icon" title="You modified your saved filter - save to persist changes">
              <Icon aria-label="You modified your saved filter - save to persist changes" style={{ color: '#fd624c' }}>
                warning
              </Icon>
            </Tooltip>
          )}
          <Checkbox
            id="save-as-default-filter"
            checked={saveAsDefault}
            onChange={this.handleSaveAsDefaultChange}
            aria-label="Save as Default Filter"
          />
          <span>Save as Default Filter</span>
        </div>
      </>
    )
  }

  renderCalculatedField() {
    const { value, showSaveAsWarning, saveAsDefault, type, upperValue, lowerValue } = this.state
    return (
      <div>
        {type !== 'between' ? (
          <TextField
            className="field-margin width-45 margin-right"
            name="value"
            type="number"
            id="value-field"
            label="Enter the value"
            aria-labelledby="Enter the value"
            value={value}
            onChange={event => this.handleValueChange('value', event.target.value)}
            margin="normal"
            variant="standard"
          />
        ) : (
          <>
            <TextField
              className="field-margin-right width-45 margin-right"
              name="lowervalue"
              type="number"
              id="from-field"
              label="From"
              value={lowerValue ? Number.parseFloat(lowerValue) : lowerValue}
              onChange={event => this.handleValueChange('lowerValue', event.target.value)}
              margin="normal"
              aria-labelledby="From"
              variant="standard"
            />
            <TextField
              className="field-margin-right width-45"
              name="uppervalue"
              type="number"
              id="to-field"
              label="To"
              value={upperValue ? Number.parseFloat(upperValue) : upperValue}
              onChange={event => this.handleValueChange('upperValue', event.target.value)}
              margin="normal"
              aria-labelledby="To"
              variant="standard"
            />
          </>
        )}
        <div id="filter-save-as-container">
          {showSaveAsWarning && (
            <Tooltip id="tooltip-icon" title="You modified your saved filter - save to persist changes">
              <Icon aria-label="You modified your saved filter - save to persist changes" style={{ color: '#fd624c' }}>
                warning
              </Icon>
            </Tooltip>
          )}
          <Checkbox
            id="save-as-default-filter"
            checked={saveAsDefault}
            onChange={this.handleSaveAsDefaultChange}
            aria-label="Save as Default Filter"
          />
          <span>Save as Default Filter</span>
        </div>
      </div>
    )
  }

  renderMetric() {
    const { value, aggregationValue, showSaveAsWarning, saveAsDefault, aggregationList } = this.state
    return (
      <>
        <FormControl className="field-margin-right width-45 margin-right field-margin-left" variant="standard">
          <InputLabel htmlFor="filter-aggregartion-select">Aggregation</InputLabel>
          <Select
            variant="standard"
            id="filter-aggregation"
            name="aggregation_type"
            value={aggregationValue}
            onChange={event => this.handleValueChange('aggregationValue', event.target.value)}
            aria-label={`Aggregation ${aggregationValue}`}
          >
            {aggregationList.map((value, index) => (
              <MenuItem key={value} value={value} id={`filter-agg${index}`}>
                {value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          className="field-margin-right width-45 margin-right"
          name="value"
          type="number"
          id="value-field"
          label="Enter the value"
          value={value || ''}
          onChange={event => this.handleValueChange('value', event.target.value)}
          margin="normal"
          aria-labelledby="Enter the value"
          variant="standard"
        />
        <div id="filter-save-as-container">
          {showSaveAsWarning && (
            <Tooltip id="tooltip-icon" title="You modified your saved filter - save to persist changes">
              <Icon aria-label="You modified your saved filter - save to persist changes" style={{ color: '#fd624c' }}>
                warning
              </Icon>
            </Tooltip>
          )}
          <Checkbox
            id="save-as-default-filter"
            checked={saveAsDefault}
            onChange={this.handleSaveAsDefaultChange}
            aria-label="Save as Default Filter"
          />
          <span>Save as Default Filter</span>
        </div>
      </>
    )
  }

  renderSelectedPatternConditions() {
    const { pattern, type, conditions, column } = this.state
    const { objectType, renderFilterStatus = {} } = this.props

    return (
      <>
        {pattern &&
          Boolean(pattern.length) &&
          ((column && STRING_COLUMN_TYPE_OPTIONS.includes(column.type)) || objectType === 'dashboard') &&
          type !== 'regex' &&
          type !== 'contains' && (
            <div id="filter-chip-dimensions-container">
              <div>
                {Array.isArray(pattern) && (type !== '!=' || type !== '==') ? (
                  pattern.map(item => {
                    const title = item && typeof item === 'string' ? item.trim() : ''

                    return (
                      <Chip
                        variant="outlined"
                        size="small"
                        key={item}
                        label={title.length > 24 ? `${item.substring(0, 24)}...` : item || String(item)}
                        title={title}
                        onDelete={
                          type !== '==' && type !== '!='
                            ? () => {
                                this.handleFilterItemToggle(item)
                              }
                            : null
                        }
                      />
                    )
                  })
                ) : (
                  <Chip
                    variant="outlined"
                    size="small"
                    label={pattern.length > 24 ? `${pattern.substring(0, 24)}...` : pattern || String(pattern)}
                    title={pattern && typeof pattern === 'string' ? pattern.trim() : ''}
                    onDelete={type !== '==' && type !== '!=' ? this.handleFilterRadioItemToggle(pattern) : null}
                  />
                )}
              </div>
            </div>
          )}
        {Boolean(conditions.length) && (
          <TextField
            select
            label="Select Condition"
            id="filter-condition-select"
            key="filter-condition-select"
            className="filter-condition-select"
            value={type}
            aria-labelledby="Select Condition"
            onChange={this.handleConditionChange}
            inputProps={{
              name: 'type',
              id: 'type',
            }}
            variant="standard"
            disabled={!renderFilterStatus.data && !METRIC_TYPES.includes(column.type)}
          >
            {conditions.map((condition, index) => (
              <MenuItem
                id={`condition-option${index}`}
                className="filter-condition-select-options"
                key={`${condition.value}-${index}`}
                value={condition.value}
              >
                {condition.name}
              </MenuItem>
            ))}
          </TextField>
        )}
        {this.checkType().call()}
      </>
    )
  }

  handleFilterTimePeriodChange = (selectedTime = {}) => {
    this.setState(
      {
        timePeriod: selectedTime,
        type: 'interval',
        pattern: [],
      },
      this.saveFilter
    )
  }

  renderDateDimension(timePeriod = {}, columnObj = {}) {
    const {
      cardsDatasetStatus,
      datasetId,
      cardInfo,
      deviceType,
      dateFilterStatus = {},
      isMobile,
      closeMobilePopover,
    } = this.props
    const { saveAsDefault } = this.state
    const _timePeriod = cloneDeep(timePeriod)
    return (
      <>
        {dateFilterStatus?.data ? (
          <Grid>
            <Grid display="flex" justifyContent="flex-end">
              <TimePeriodCalendarType
                timePeriod={_timePeriod}
                onCalendarTypeChange={this.handleFilterTimePeriodChange}
              />
            </Grid>
            <TimePeriod
              isBuilder={false}
              isVirtualColumnFilter
              isViewer
              isMobile
              closeMobilePopover={closeMobilePopover}
              cardInfo={cardInfo}
              selectedDataset={cardsDatasetStatus[datasetId]}
              deviceType={deviceType}
              onTimeJsonChange={this.handleFilterTimePeriodChange}
              viewerJson={_timePeriod}
              selectedTimePeriod={{ timePeriod: _timePeriod }}
            />
            <TimePeriodGranularity
              cardInfo={cardInfo}
              timePeriod={_timePeriod}
              columnObj={columnObj}
              selectedDataset={cardsDatasetStatus[datasetId]?.data}
              onGranularityChange={this.handleFilterTimePeriodChange}
            />
            <Grid display="flex" justifyContent="flex-end">
              {this.renderSaveAsDefault(saveAsDefault, isMobile)}
            </Grid>
          </Grid>
        ) : (
          dateFilterStatus.status === 'requested' && <Spinner size="medium" layout="selfCentering" />
        )}
      </>
    )
  }

  render() {
    const { column, pattern, type, conditions, timePeriod } = this.state
    const isVirtualColumnFilter = isVirtualColumnCheck(column)
    return (
      <div className="filter-items-container">
        {isVirtualColumnFilter
          ? this.renderDateDimension(timePeriod, column)
          : this.renderSelectedPatternConditions(column, pattern, type, conditions)}
      </div>
    )
  }
}

FilterDimension.propTypes = {
  selectedFilter: PropTypes.object,
  appliedFilter: PropTypes.object,
  addActiveFilter: PropTypes.func,
  onHighCardinalitySearch: PropTypes.func,
  objectType: PropTypes.string,
}

export default FilterDimension
