import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { updateAppliedFilters, clearAllFilters } from '../../../../../ducks/filter'
import { clearSavedCards } from '../../../../../ducks/cardPreview'
import { dateFilter, getAnchorDateFilter } from '../../../../../ducks/timeperiod'
import DashboardviewerHeader from './DashboardviewerHeader'

export class DashboardviewerHeaderContainer extends React.Component {
  componentDidMount() {
    const { clearAllFilters, routeProps, dashboardData, anchorDateFilterStatus, getAnchorDateFilter, dateFilter } =
      this.props

    if (!routeProps.location.search) {
      clearAllFilters() // Clear out redux state, saved/default filters get stuck in applied filters - Art/49754518
    }

    const timePeriodObj = dashboardData?.time_period
    if (timePeriodObj?.anchor_date && timePeriodObj?.anchor_date !== anchorDateFilterStatus?.anchor_date) {
      getAnchorDateFilter({ anchor_date: timePeriodObj.anchor_date })
    }
    dateFilter()
  }

  render() {
    return (
      <DashboardviewerHeader
        timePeriod={this.props.timePeriod || {}}
        onTimePeriodChange={this.props.onTimePeriodChange}
        data={this.props.data}
        delDashboard={this.props.delDashboard}
        dashboardDelStatus={this.props.dashboardDelStatus}
        dashboardData={this.props.dashboardData}
        saveDashboard={this.props.saveDashboard}
        saveDashboardStatus={this.props.saveDashboardStatus}
        routeProps={this.props.routeProps}
        activeTab={this.props.activeTab}
        isMobile={this.props.isMobile}
        cardsInfo={this.props.cardsInfo}
        metadata={this.props.metadata}
        appliedFilters={this.props.appliedFilters}
        clearSavedCards={this.props.clearSavedCards}
        clearTabCardsPayload={this.props.clearTabCardsPayload}
        refreshDashboardClicked={this.props.refreshDashboardClicked}
        deviceType={this.props.deviceType}
        dismissPopover={this.props.dismissPopover}
        filterClick={this.props.filterClick}
        openFilters={this.props.openFilters}
        selectedFilterId={this.props.selectedFilterId}
        dashboardId={this.props.dashboardId}
        dateFilterStatus={this.props.dateFilterStatus}
        anchorDateFilterStatus={this.props.anchorDateFilterStatus}
        selectedDashboardTimePeriod={this.props.selectedDashboardTimePeriod}
        onApplyDashboardTimePeriod={this.props.onApplyDashboardTimePeriod}
        updateSelectedDashTimePeriod={this.props.updateSelectedDashTimePeriod}
        onHandleDashTimePeriodClose={this.props.onHandleDashTimePeriodClose}
      />
    )
  }
}

const mapStateToProps = state => ({
  appliedFilters: state.filter.appliedFilters,
  dashboardDelStatus: state.dashboard.dashboardDelStatus,
  saveDashboardStatus: state.builder.saveDashboardStatus,
  cardsInfo: state.previewCard.cards,
  anchorDateFilterStatus: state.timeperiod.anchorDateFilterStatus,
  dateFilterStatus: state.timeperiod.dateFilterStatus,
})

export const mapDispatchToProps = dispatch => ({
  updateAppliedFilters(data) {
    dispatch(updateAppliedFilters(data))
  },
  clearAllFilters() {
    dispatch(clearAllFilters())
  },
  clearSavedCards() {
    dispatch(clearSavedCards())
  },
  getAnchorDateFilter(data) {
    dispatch(getAnchorDateFilter(data))
  },
  dateFilter(data) {
    dispatch(dateFilter(data))
  },
})

DashboardviewerHeaderContainer.defaultProps = {
  cardsInfo: {},
  anchorDateFilterStatus: {},
  dateFilter: () => {},
}

DashboardviewerHeaderContainer.propTypes = {
  updateAppliedFilters: PropTypes.func,
  clearAllFilters: PropTypes.func,
  anchorDateFilterStatus: PropTypes.object,
  dateFilter: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardviewerHeaderContainer)
