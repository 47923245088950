import React from 'react'
import { Group, Person } from '@mui/icons-material'
import { Chip } from '@mui/material'
import { AssetType } from '../AssetTableSS/AssetTable'

export const TagChipRenderer = props => {
  const { value, node, api, handleToggleDetail, className } = props
  const showMore = value?.length > 1
  return (
    <>
      {value && value[0] && (
        <Chip
          size="small"
          label={value[0]}
          key={node.rowIndex}
          id={`chip-business-${node.rowIndex}`}
          className={className}
        />
      )}
      {showMore && (
        <Chip
          size="small"
          label={`+ ${value?.length - 1} more`}
          className={className}
          onClick={() => handleToggleDetail(api, node)}
          data-index={node.rowIndex}
          id={`chip-business-${node.rowIndex}`}
        />
      )}
    </>
  )
}

export const OwnerChipRenderer = props => {
  const { value = [], data, node, api, handleToggleDetail, className, assetType } = props
  if (assetType === AssetType.CALC_FIELDS) {
    return getOwnerInfoChip(data.owners_access)
  }
  const ownerAccessGroup = data?.owners_access || data?.card_attribute?.owners_access || []
  const showMore = ownerAccessGroup?.length > 1
  const cellValue = ownerAccessGroup[0]
    ? ownerAccessGroup[0].user_group_name === 'NonUser Account'
      ? ownerAccessGroup[0]._id
      : ownerAccessGroup[0].user_group_name
    : value[0]

  return (
    <>
      {cellValue && <Chip size="small" label={cellValue} key={node.rowIndex} className={className} />}
      {showMore && (
        <Chip
          size="small"
          label={`+ ${ownerAccessGroup?.length - 1} more`}
          className={className}
          onClick={() => handleToggleDetail(api, node)}
          data-index={node.rowIndex}
        />
      )}
    </>
  )
}

export const getBusinessInfoChip = rowData =>
  rowData.map((item, index) => (
    <Chip label={item} key={index} className="chip-business" component="span" size="small" />
  ))

export const getOwnerInfoChip = (rowData = []) =>
  rowData.map(item => {
    const isGroup = item.user_type === 'internal' && item.type === 'group'
    const groupName = item.user_group_name === 'NonUser Account' ? item._id : item.user_group_name
    const chipName = isGroup ? 'group_chip' : 'user_chip'

    return (
      <Chip
        data-cy={chipName}
        label={groupName}
        key={item._id}
        className="chip-owners"
        icon={isGroup ? <Group fontSize="small" /> : <Person fontSize="small" />}
        component={isGroup ? 'a' : 'span'}
        color="primary"
        size="small"
        href={`/admin/groups/${item._id}/members`}
        target="_blank"
        clickable={isGroup}
      />
    )
  })

// Sort by date
export const dateComparator = (date1, date2) => (new Date(date1) < new Date(date2) ? -1 : 1)
