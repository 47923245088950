import React from 'react'
import PublicIcon from '@mui/icons-material/Public'
import SecurityIcon from '@mui/icons-material/Security'
import { Tooltip, Icon } from '@mui/material'
import { Lock } from '@mui/icons-material'

export const SecurityRenderer = ({ data }) => (
  <>
    {data?.rls_enabled && (
      <Tooltip title="RLS Enabled" placement="top">
        <Icon aria-label="RLS Enabled">
          <Lock className="dataset-security-icon" />
        </Icon>
      </Tooltip>
    )}
    {data?.data_classification === 'Confidential' && (
      <Tooltip title="Confidential" placement="top">
        <Icon aria-label="Confidential">
          <SecurityIcon className="dataset-security-icon" />
        </Icon>
      </Tooltip>
    )}
    {data?.enable_vendor_access && (
      <Tooltip title="Vendor Access Enabled" placement="top">
        <Icon aria-label="Vendor Access Enabled">
          <PublicIcon className="dataset-security-icon" />
        </Icon>
      </Tooltip>
    )}
  </>
)
