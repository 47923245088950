import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import debounce from 'lodash/debounce'
import { clearBusinessAreaTag, getDetailsBusinessAreas, validateBusinessArea } from '../../../ducks/builder'
import BuilderBusinessArea from '../../routes/Builder/Sidebar/BuilderBusinessArea'

const BusinessAreaComponent = props => {
  const { variant, businessAreaList, setBusinessAreaList } = props
  const dispatch = useDispatch()
  const { detailsBusinessAreasStatus, isBusinessAreaValid } = useSelector(state => state.builder)

  const [businessAreaSearchValue, setBusinessAreaSearchValue] = useState('')

  const searchBusinessAreas = debounce(searchText => {
    dispatch(getDetailsBusinessAreas(searchText))
  }, 1000)

  useEffect(() => {
    if (isBusinessAreaValid?.data) {
      handleBusinessAreaClick(isBusinessAreaValid.data[0])
    }
  }, [isBusinessAreaValid])

  const handleValidateBusinessAreaTag = data => {
    dispatch(validateBusinessArea(data))
    setBusinessAreaSearchValue('')
  }

  const handleOnBusinessAreaChange = event => {
    if (event.target.value) {
      setBusinessAreaSearchValue(event.target.value)
      searchBusinessAreas(event.target.value)
    }
  }

  const handleDeleteBusinessArea = area => {
    const newBusinessAreaList = businessAreaList.filter(item => item !== area)
    setBusinessAreaList(newBusinessAreaList)
  }

  const handleBusinessAreaClick = businessArea => {
    if (businessArea) {
      setBusinessAreaList(businessAreaList => [...businessAreaList, businessArea])
      setBusinessAreaSearchValue('')
    }
  }

  return (
    <BuilderBusinessArea
      variant={variant}
      businessAreaFieldId="dataset-edit-business-area"
      businessAreaSearchValue={businessAreaSearchValue}
      selectedBusinessAreas={businessAreaList}
      detailsBusinessAreasStatus={detailsBusinessAreasStatus}
      onBusinessAreaChange={handleOnBusinessAreaChange}
      onDeleteBusinessArea={handleDeleteBusinessArea}
      onValidateBusinessArea={handleValidateBusinessAreaTag}
      isBusinessAreaValid={isBusinessAreaValid}
      clearBusinessAreaTag={() => dispatch(clearBusinessAreaTag())}
    />
  )
}

export default BusinessAreaComponent
