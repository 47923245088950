import React, { useState, useEffect } from 'react'
import {
  Button,
  Typography,
  IconButton,
  FormControl,
  Input,
  InputLabel,
  InputAdornment,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Alert,
} from '@mui/material'
import { Visibility, VisibilityOff, Info } from '@mui/icons-material'
import './DataStore.scss'
import debounce from 'lodash/debounce'
import cloneDeep from 'lodash/cloneDeep'
import { useDispatch, useSelector } from 'react-redux'
import { GreenfieldAutoComplete } from '../../shared/shared'
import { createConnection, editConnection, testConnection } from '../../../ducks/datastore'
import { getDetailsOwners } from '../../../ducks/builder'
import { filterUserType } from '../../shared/MUIAutoComplete/Autocomplete'

const DatastoreDetails = props => {
  const dispatch = useDispatch()
  const { error, title, open, handleDialogClose, details = null } = props

  const [connectionDetails, setConnectionDetails] = useState(null)
  const [showPassword, setShowPassword] = useState(false)
  const [ownersSuggestions, setOwnersSuggestions] = useState([])
  const [updatedDefaultOwners, setUpdatedDefaultOwners] = useState({
    labels: [],
    users: [],
    groups: [],
  })

  const { detailsOwnersStatus } = useSelector(state => state.builder)
  const { datastoreTestStatus } = useSelector(state => state.datastore)

  useEffect(() => {
    details && setConnectionDetails(details)
  }, [details])

  useEffect(() => {
    if (detailsOwnersStatus?.data) {
      const ownersSuggestionsTemp = detailsOwnersStatus.data.map(item => ({
        ...item,
        label: item.user_group_name,
      }))
      setOwnersSuggestions(ownersSuggestionsTemp)
    }
  }, [detailsOwnersStatus])

  useEffect(() => {
    const updatedDefaultOwnersNew = {}
    if (connectionDetails?.owners_access?.length) {
      updatedDefaultOwnersNew.labels =
        connectionDetails.owners_access.map(owner => owner.user_group_name || owner.label) || []
      updatedDefaultOwnersNew.users = filterUserType(connectionDetails && connectionDetails.owners_access, 'user')
      updatedDefaultOwnersNew.groups = filterUserType(connectionDetails && connectionDetails.owners_access, 'group')
    }
    setUpdatedDefaultOwners(updatedDefaultOwnersNew)
  }, [connectionDetails])

  const searchOwnersNames = debounce(searchText => {
    dispatch(getDetailsOwners(searchText))
  }, 1000)

  const checkConnectionDetails = () => {
    if (
      connectionDetails &&
      connectionDetails.username &&
      connectionDetails.host &&
      connectionDetails.password &&
      connectionDetails.dbname
    ) {
      return true
    }
    return false
  }

  const checkSaveConnection = () => {
    return checkConnectionDetails() && connectionDetails.owners_access?.length && connectionDetails.datastore_name
  }

  const selectedOwnersAccess = selections => {
    const connectionDetailsClone = cloneDeep(connectionDetails || {})
    const owners = connectionDetailsClone.owners_access || []
    let index = -1

    if (selections._id) {
      isNaN(selections) ? owners.push(selections) : owners.splice(selections, 1)
      connectionDetailsClone.owners_access = owners
      setConnectionDetails(connectionDetailsClone)
    } else if (Number.isInteger(selections)) {
      index = parseInt(selections, 10)
      owners.splice(index, 1)
      connectionDetailsClone.owners_access = owners
      setConnectionDetails(connectionDetailsClone)
    }
  }

  const testDatastoreConnection = () => {
    if (checkConnectionDetails()) {
      const { host, dbname, username, password } = connectionDetails
      dispatch(testConnection({ host, dbname, username, password, datastore_type: 'PostgreSQL' }))
    }
  }

  const handleChange = event => {
    const connectionDetailsClone = cloneDeep(connectionDetails || {})
    connectionDetailsClone[event.target.name] = event.target.value || ''
    setConnectionDetails(connectionDetailsClone)
  }

  const saveConnection = () => {
    if (checkSaveConnection()) {
      const { host, dbname, username, password } = connectionDetails
      const datastoreValue = {
        host,
        dbname,
        username,
        password,
        owners: updatedDefaultOwners.users,
        owner_groups: updatedDefaultOwners.groups,
        datastore_name: connectionDetails.datastore_name,
        datastore_type: 'PostgreSQL',
        port: connectionDetails?.port,
      }
      if (connectionDetails._id) {
        datastoreValue._id = connectionDetails._id
        dispatch(editConnection(datastoreValue))
      } else {
        dispatch(createConnection(datastoreValue))
      }
    }
  }

  const isDatastoreTestSuccess = datastoreTestStatus?.status === 200 && datastoreTestStatus?.status !== 'requested'
  // only allow numeric values for port id
  const onKeyPressEvent = event => {
    const keyCode = event.keyCode || event.which
    const keyValue = String.fromCharCode(keyCode)
    if (!/0-9/.test(keyValue)) {
      event.preventDefault()
    }
  }

  return (
    <Dialog className="margin-container" open={open} maxWidth="md" fullWidth onClose={handleDialogClose}>
      <DialogTitle>
        <div className="flex-display">
          <Typography data-cy="datastore-title" variant="h2">
            {title}
          </Typography>
          <Button
            variant="text"
            onClick={() => {
              setConnectionDetails(null)
            }}
          >
            Reset All
          </Button>
        </div>
      </DialogTitle>
      <DialogContent dividers style={{ height: '45vh' }}>
        <div className="flex-display">
          <FormControl className="width-margin" variant="standard">
            <InputLabel htmlFor="datastoreConnectionName">Connection Name</InputLabel>
            <Input
              id="datastoreConnectionName"
              name="datastore_name"
              value={connectionDetails?.datastore_name || ''}
              onChange={handleChange}
              inputProps={{ 'aria-label': 'Datastore Connection Name' }}
              endAdornment={
                <InputAdornment position="end">
                  <Tooltip title="Name of connect for Greenfield for reference">
                    <Info />
                  </Tooltip>
                </InputAdornment>
              }
              disabled={Boolean(details?.datastore_name)}
            />
          </FormControl>
          <FormControl className="width-margin" variant="standard">
            <InputLabel htmlFor="datastoreUsername">Username</InputLabel>
            <Input
              id="datastoreUsername"
              name="username"
              value={connectionDetails?.username || ''}
              onChange={handleChange}
              inputProps={{ 'aria-label': 'Datastore Username' }}
              endAdornment={
                <InputAdornment position="end">
                  <Tooltip title="Login User Id to postgres database">
                    <Info />
                  </Tooltip>
                </InputAdornment>
              }
            />
          </FormControl>
        </div>
        <div className="flex-display">
          <FormControl className="width-margin" variant="standard">
            <InputLabel htmlFor="datastoreHostName">Host Name</InputLabel>
            <Input
              id="datastoreHostName"
              name="host"
              value={connectionDetails?.host || ''}
              onChange={handleChange}
              inputProps={{ 'aria-label': 'Datastore Host Name' }}
              endAdornment={
                <InputAdornment position="end">
                  <Tooltip title="Server name(s)">
                    <Info />
                  </Tooltip>
                </InputAdornment>
              }
            />
          </FormControl>
          <FormControl className="width-margin" variant="standard">
            <InputLabel htmlFor="datastorePassword">Password</InputLabel>
            <Input
              id="datastorePassword"
              name="password"
              type={!showPassword ? 'password' : 'text'}
              value={connectionDetails?.password || ''}
              onChange={handleChange}
              inputProps={{ 'aria-label': 'Datastore Password' }}
              endAdornment={
                <InputAdornment position="end">
                  <Tooltip title="Password to connect to postgres database">
                    <Info />
                  </Tooltip>
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => {
                      setShowPassword(!showPassword)
                    }}
                    edge="end"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </div>
        <div className="flex-display">
          <FormControl className="width-margin" variant="standard">
            <InputLabel htmlFor="datastoreHostName">Database Name</InputLabel>
            <Input
              id="datastoreDatabaseName"
              name="dbname"
              value={connectionDetails?.dbname || ''}
              onChange={handleChange}
              inputProps={{ 'aria-label': 'Datastore Database Name' }}
              endAdornment={
                <InputAdornment position="end">
                  <Tooltip title="Schema/Database name">
                    <Info />
                  </Tooltip>
                </InputAdornment>
              }
            />
          </FormControl>

          <FormControl className="width-margin" variant="standard">
            <InputLabel htmlFor="datastorePortID">Port</InputLabel>
            <Input
              id="datastorePortID"
              name="port"
              value={connectionDetails?.port || ''}
              onKeyPress={onKeyPressEvent}
              onChange={handleChange}
              inputProps={{ 'aria-label': 'Datastore Port ID' }}
              endAdornment={
                <InputAdornment position="end">
                  <Tooltip title="Database port id (5432)">
                    <Info />
                  </Tooltip>
                </InputAdornment>
              }
            />
          </FormControl>
        </div>
        <div className="flex-display">
          <GreenfieldAutoComplete
            isEditable
            label="Add Owners"
            searchApi={value => {
              searchOwnersNames(value)
            }}
            searchStatus={detailsOwnersStatus}
            suggestions={ownersSuggestions}
            selectedSuggestions={selectedOwnersAccess}
            default={updatedDefaultOwners?.labels}
            labelText="Owners"
          />
        </div>
      </DialogContent>
      <DialogActions>
        <div style={{ display: 'flex', marginRight: 'auto' }}>
          <Button
            variant="outlined"
            onClick={testDatastoreConnection}
            disabled={!checkConnectionDetails()}
            id="test-connection"
            color="secondary"
          >
            Test Connection
          </Button>
        </div>
        <Button onClick={handleDialogClose} variant="outlined" color="secondary" id="cancel-connection">
          Cancel
        </Button>
        <Button
          onClick={saveConnection}
          disabled={!checkSaveConnection()}
          variant="outlined"
          color="secondary"
          id="save-connection"
        >
          Save Connection
        </Button>
      </DialogActions>
      {datastoreTestStatus && (
        <Alert id="test-message" severity={isDatastoreTestSuccess ? 'success' : 'error'}>
          {isDatastoreTestSuccess
            ? 'Test Connection success'
            : `Test Connection failed: ${
                datastoreTestStatus?.message?.message || 'Something went wrong, please try again.'
              }`}
        </Alert>
      )}
      {error && (
        <Alert data-cy="datastore-alert-container" severity="error">
          {error}
        </Alert>
      )}
    </Dialog>
  )
}

export default DatastoreDetails
