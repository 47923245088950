import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { dateFilter, getAnchorDateFilter, getCustomDateFilter } from '../../../ducks/timeperiod'
import { displayServiceErrorMessage } from '../../../ducks/layout'
import TimePeriod from './TimePeriod'
import SecondaryTimePeriod from './SecondaryTimePeriod'

export class TimePeriodContainer extends React.Component {
  componentDidMount() {
    const { anchorDateFilterStatus, cardInfo, getAnchorDateFilter, dateFilter, isBuilder, isVirtualColumnFilter } =
      this.props
    const anchorDate = cardInfo?.data?.card_config?.card_query_attribute?.time_period?.anchor_date
    if (!isVirtualColumnFilter) {
      dateFilter()
      if (isBuilder && anchorDate && !(anchorDateFilterStatus.status >= 200 && anchorDateFilterStatus.status < 300)) {
        getAnchorDateFilter({ anchor_date: anchorDate })
      }
    }
  }

  render() {
    return this.props.isSecondaryTime
      ? !this.props.hideViewerSecondaryTimePeriod && <SecondaryTimePeriod {...this.props} />
      : !this.props.hideViewerTimePeriod && <TimePeriod {...this.props} />
  }
}

const mapStateToProps = state => ({
  dateFilterStatus: state.timeperiod.dateFilterStatus,
  anchorDateFilterStatus: state.timeperiod.anchorDateFilterStatus,
  customDateFilterStatus: state.timeperiod.customDateFilterStatus,
})

export const mapDispatchToProps = dispatch => ({
  dateFilter(data) {
    dispatch(dateFilter(data))
  },

  getAnchorDateFilter(data) {
    dispatch(getAnchorDateFilter(data))
  },

  getCustomDateFilter(data) {
    dispatch(getCustomDateFilter(data))
  },

  displayServiceErrorMessage(data) {
    dispatch(displayServiceErrorMessage(data))
  },
})

TimePeriodContainer.defaultProps = {
  cardsDatasetStatus: {},
  dateFilterStatus: {},
  selectedDataset: {},
  cardInfo: {},
  pendingCardUid: '',
  dateFilter: () => {},
  anchorDateFilterStatus: {},
  customDateFilterStatus: {},
  isVirtualColumnFilter: false,
}

TimePeriodContainer.propTypes = {
  dateFilterStatus: PropTypes.object,
  cardInfo: PropTypes.object,
  pendingCardUid: PropTypes.string,
  cardsDatasetStatus: PropTypes.object,
  isDashboard: PropTypes.bool,
  dashboardInfo: PropTypes.object,
  modifyDashboardInfo: PropTypes.func,
  displayServiceErrorMessage: PropTypes.func,
  anchorDateFilterStatus: PropTypes.object,
  getCustomDateFilter: PropTypes.func,
  customDateFilterStatus: PropTypes.object,
  isVirtualColumnFilter: PropTypes.bool,
}

export default connect(mapStateToProps, mapDispatchToProps)(TimePeriodContainer)
