import React, { useEffect, useState } from 'react'
import axios from 'axios'
import moment from 'moment'
import {
  Button,
  Typography,
  Grid,
  List,
  ListItem,
  ListItemText,
  IconButton,
  CircularProgress,
  Box,
} from '@mui/material'
import { Spinner } from 'greenfield-utilities'
import { OpenInNew } from '@mui/icons-material'
import styled from 'styled-components'
import { useAnalytics } from '@praxis/component-analytics'

import analyticsConfig from '../../analytics'
import { getBusinessInfoChip, getOwnerInfoChip } from '../../shared/AgGrid/AgGridHelper'
import { SCard, SCountTypography, STypography } from '../Cards/Cardlist/CardDetailComponent'
import { API_GATEWAY_URL } from '../../../ducks/utils'

const IdLinkContainer = styled.div`
  display: flex;
`
const LinkButton = styled.a`
  margin-top: 25px;
  text-decoration: none;

  > button {
    background: #fff;
  }
`
export const DatasetDetailComponent = ({ rowData }) => {
  const datasetId = rowData._id
  const [datasetDetail, setDatasetDetail] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  const [isLoadingIngest, setIsLoadingIngest] = useState(true)
  const [ingestStatus, setIngestStatus] = useState('')

  const { trackClick } = useAnalytics(analyticsConfig)

  const isUploadedDataset = rowData.sources?.includes('upload')

  useEffect(() => {
    const getDataSetDetail = async () => {
      await axios
        .get(`${API_GATEWAY_URL}/bi_reporting_assets/v2/datasets/${datasetId}`)
        .then(response => setDatasetDetail(response.data))

      setIsLoading(false)
    }
    getDataSetDetail()
  }, [datasetId])

  useEffect(() => {
    if (isUploadedDataset) {
      axios.get(`${API_GATEWAY_URL}/greenfield_ingests/v1/ingests?dataset=${rowData.dataset_name}`).then(response => {
        setIngestStatus(response.data[0].status)
        setIsLoadingIngest(false)
      })
    }
  }, [])

  const retention = rowData.data_retention

  return (
    <Grid className="datasetRowExpand" container direction="row" justifyContent="space-evenly" alignItems="flex-start">
      <Grid item xs={6}>
        <IdLinkContainer>
          <GridItem primary="Dataset ID" secondary={rowData._id} />
          {rowData.data_portal?.data_portal_url && (
            <LinkButton
              href={rowData.data_portal.data_portal_url}
              rel="noopener noreferrer"
              target="_blank"
              onClick={() => {
                trackClick('dataportal-link-click')
              }}
            >
              <Button color="secondary" startIcon={<OpenInNew className="edit-dataset-icon" />}>
                Open Dataset in dataPortal
              </Button>
            </LinkButton>
          )}
        </IdLinkContainer>

        {rowData.dataset_description && (
          <GridItem primary="Dataset Description" secondary={rowData.dataset_description} />
        )}

        <GridItem primary="Datastore" secondary={`${rowData.database_name} (${rowData.database_type})`} />
        {rowData.business_use.length > 0 && (
          <GridItem
            primary="Business Area(s)"
            secondary={<Box style={{ fontWeight: 'bold' }}>{getBusinessInfoChip(rowData.business_use)}</Box>}
          />
        )}
        <GridItem
          primary="Owner(s)"
          secondary={<Box style={{ fontWeight: 'bold' }}>{getOwnerInfoChip(rowData.owners_access)}</Box>}
        />
        <GridItem
          primary="Datastream Action"
          secondary={getDatastreamLink(rowData.datastream_action_url, rowData.datastream_action_name)}
        />
        {datasetDetail.data_portal_url && <GridItem primary={getDataPortalLink(datasetDetail)} />}
        {isUploadedDataset &&
          (isLoadingIngest ? (
            <Spinner size="small" layout="selfCentering" />
          ) : (
            <GridItem primary={`Uploaded Dataset status: ${ingestStatus}`} />
          ))}
      </Grid>

      <Grid item xs={4}>
        <GridItem primary="Data Classification" secondary={rowData.data_classification} />
        {retention ? (
          <GridItem
            primary="Dataset Retention"
            secondary={`${retention.n} ${
              (retention.units !== 'Week' && retention.units !== 'Day' && retention.calendar_type?.toLowerCase()) || ''
            }${retention.units.toLowerCase()}s completed, plus current ${retention.units.toLowerCase()}`}
          />
        ) : null}
        <GridItem
          primary="Last Load Date"
          secondary={rowData.last_load_date && moment(rowData.last_load_date).format('MMMM Do YYYY, h:mm:ss a')}
        />
        <GridItem primary="Last Updated" secondary={moment(rowData.last_updated).format('MMMM Do YYYY, h:mm:ss a')} />
        <GridItem primary="Dataset Size (GB)" secondary={rowData.data_size_gb} />
        <GridItem
          primary="Row Count"
          secondary={
            rowData.record_count &&
            rowData.record_count.toLocaleString(navigator.language, { minimumFractionDigits: 0 })
          }
        />
        <GridItem
          primary="Column Count"
          secondary={
            rowData.column_count &&
            rowData.column_count.toLocaleString(navigator.language, { minimumFractionDigits: 0 })
          }
        />
      </Grid>
      <Grid item xs={2}>
        <SCard>
          {isLoading ? (
            <CircularProgress size={30} color="inherit" />
          ) : (
            <SCountTypography className="expandCardData">{datasetDetail?.cards?.length || 0}</SCountTypography>
          )}
          <STypography className="expandCardLabel">Cards</STypography>
        </SCard>
        <SCard>
          {isLoading ? (
            <CircularProgress size={30} color="inherit" />
          ) : (
            <SCountTypography className="expandCardData">{datasetDetail?.dashboards?.length || 0}</SCountTypography>
          )}
          <STypography className="expandCardLabel">Dashboards</STypography>
        </SCard>
      </Grid>
    </Grid>
  )
}

const getDataPortalLink = datasetDetail => (
  <Typography variant="subtitle1">
    Data Portal Link
    <a href={datasetDetail.data_portal_url} rel="noopener noreferrer" target="_blank">
      <IconButton tabIndex={-1}>
        <OpenInNew className="edit-dataset-icon" />
      </IconButton>
    </a>
  </Typography>
)
const getDatastreamLink = (url, name) => (
  <a target="_blank" rel="noopener noreferrer" href={url}>
    {name}
  </a>
)

export const GridItem = ({ primary, secondary }) => {
  return (
    <List>
      <ListItem>
        <ListItemText
          className="card-list-item"
          primary={<Typography variant="subtitle1">{primary}</Typography>}
          secondary={
            <Typography
              variant="body1"
              component="span"
              data-cy={`card-list-item-${primary}`}
              style={{ wordBreak: 'break-word', whiteSpace: 'pre-wrap' }}
            >
              {secondary}
            </Typography>
          }
        />
      </ListItem>
    </List>
  )
}
