import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { createStore, applyMiddleware } from 'redux'
import { ThemeProvider } from '@mui/material/styles'
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'
import createSagaMiddleware from 'redux-saga'
import { unstable_ClassNameGenerator as ClassNameGenerator } from '@mui/material/utils'

import 'typeface-roboto'

import muiTheme from './config/themeConfig'
import rootReducer from './mainReducer'
import App from './components/App'
import mainSaga from './mainSaga'

/* eslint-disable no-console */
const rootEl = document.getElementById('root')
const sagaMiddleware = createSagaMiddleware(mainSaga)
const middleware = [sagaMiddleware]

ClassNameGenerator.configure(componentName => `gf-${componentName}`)

if (process.env.NODE_ENV === 'development') {
  window.localStorage.setItem('environment', process.env.NODE_ENV)
}

if (process.env.NODE_ENV !== 'production') {
  middleware.push(reduxImmutableStateInvariant())
}

const composeEnhancers = composeWithDevTools({ trace: true })

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(...middleware)))

sagaMiddleware.run(mainSaga)

ReactDOM.render(
  <ThemeProvider theme={muiTheme}>
    <Provider store={store}>
      <App />
    </Provider>
  </ThemeProvider>,
  rootEl
)

if (module.hot) {
  console.log(
    '%c%s',
    'color: #107b73; background: #eee; font-size: 12px; font-style: italic; font-family: verdana',
    'Greenfield-react app loaded in dev mode'
  )

  module.hot.accept('./components/App', () => {
    const NextApp = require('./components/App').default

    ReactDOM.render(
      <ThemeProvider theme={muiTheme}>
        <Provider store={store}>
          <NextApp />
        </Provider>
      </ThemeProvider>,
      rootEl
    )
  })
}

/* eslint-enable no-console */
