import React, { useState } from 'react'
import DesktopDatePicker from '@mui/lab/DesktopDatePicker'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import { TextField } from '@mui/material'
import { createTheme } from '@mui/material/styles'
import { ThemeProvider, createGenerateClassName, StylesProvider } from '@mui/styles'
import AdapterDateFns from '@mui/lab/AdapterMoment'
import PropTypes from 'prop-types'
import moment from 'moment'

const generateClassName = createGenerateClassName({
  seed: '',
})

const materialTheme = createTheme({
  components: {
    MuiPickersSlideTransition: {
      styleOverrides: {
        transitionContainer: {
          minHeight: '240px !important',
        },
      },
    },

    MuiButtonBase: {
      styleOverrides: {
        root: {
          '&$focusVisible': {
            textDecoration: 'underline',
            outlineOffset: '1px',
            outline: '#222222 dashed 1px',
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: '10px',
          '&:focus': {
            outlineOffset: 'none',
          },
          '&:hover': {
            backgroundColor: 'rgba(174, 219, 175, 0.32)',
            color: '#222222',
          },
          '&:active': {
            backgroundColor: '#81c784',
            color: '#222222',
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          width: '150px',
        },
      },
    },
    MuiPickersBasePicker: {
      styleOverrides: {
        pickerView: {
          width: '320px',
          maxHeight: '335px',
          minHeight: '335px',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        subtitle1: {
          fontSize: '16px',
          height: '18px',
        },
        h4: {
          fontSize: '16px',
        },
        caption: {
          margin: '0 !important',
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        dayWithMargin: {
          margin: 0,
        },
        day: {
          color: '#222222',
          '&:focus': {
            outlineOffset: 'none',
            backgroundColor: '#81c784 !important',
          },
          '&:hover': {
            backgroundColor: 'rgba(174, 219, 175, 0.32)',
            color: '#222222',
          },
          '&:active': {
            backgroundColor: '#81c784',
            color: '#222222',
          },
        },
        daySelected: {
          backgroundColor: '#81c784',
          '&:focus': {
            outlineOffset: 'none',
            backgroundColor: '#81c784 !important',
          },
          '&:hover': {
            backgroundColor: 'rgba(174, 219, 175, 0.32)',
            color: '#222222',
          },
          '&:active': {
            backgroundColor: '#81c784',
            color: '#222222',
          },
        },
        dayDisabled: {
          color: '#e2e2e2',
        },
        today: {
          border: 'none !important',
          color: '#51a255',
        },
      },
    },
    MuiPickersYear: {
      styleOverrides: {
        yearButton: {
          color: '#222222',
          '&:focus': {
            textDecoration: 'underline',
            outlineOffset: '1px',
            outline: '#222222 dashed 1px',
            color: '#616161',
            backgroundColor: 'rgba(174, 219, 175, 0.32)',
          },
          '&:hover': {
            backgroundColor: 'rgba(174, 219, 175, 0.32)',
            color: '#616161',
          },
          '&:active': {
            backgroundColor: '#81c784',
            color: '#222222',
          },
        },

        yearSelected: {
          backgroundColor: '#81c784',
          '&:focus': {
            textDecoration: 'none',
            outline: 'none',
            color: '#ffffff',
            backgroundColor: '#81c784',
          },
          '&:hover': {
            backgroundColor: 'rgba(174, 219, 175, 0.32)',
            color: '#616161',
          },
          '&:active': {
            backgroundColor: '#81c784',
            color: '#ffffff',
          },
        },
      },
    },
    MuiPickersMonthSelection: {
      styleOverrides: {
        container: {
          margin: '10px',
        },
      },
    },
    MuiPickersMonth: {
      styleOverrides: {
        root: {
          borderRadius: '16px',
          color: '#222222',
          height: '56px',

          '&:focus': {
            textDecoration: 'underline',
            outlineOffset: '1px',
            outline: '#222222 dashed 1px',
            color: '#616161',
            backgroundColor: 'rgba(174, 219, 175, 0.32)',
          },
          '&:hover': {
            backgroundColor: 'rgba(174, 219, 175, 0.32)',
            color: '#616161',
          },
          '&:active': {
            backgroundColor: '#81c784',
            color: '#222222',
          },
        },
        monthSelected: {
          backgroundColor: '#81c784',
          color: '#ffffff',

          '&:focus': {
            color: '#ffffff',
            backgroundColor: '#81c784',
          },
          '&:hover': {
            backgroundColor: 'rgba(174, 219, 175, 0.32)',
            color: '#616161',
          },
          '&:active': {
            backgroundColor: '#81c784',
            color: '#ffffff',
          },
        },
      },
      MuiFormHelperText: {
        root: {
          width: '140px',
        },
      },
    },
  },
  props: {
    MuiButtonBase: {
      disableRipple: true, // No more ripple,
    },
  },
})

const DatePickerComponent = props => {
  const renderInput = props => <TextField width={width} variant="standard" {...props} label={errorMessage || label} />
  const {
    id = '',
    label = '',
    value,
    handleOnChange,
    ariaLabel = '',
    minDate,
    maxDate,
    width = '100%',
    customRenderInput = renderInput,
  } = props
  const [errorMessage, setErrorMessage] = useState('')
  return (
    <StylesProvider generateClassName={generateClassName}>
      <ThemeProvider theme={materialTheme}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <div id={id}>
            <DesktopDatePicker
              minDate={moment(minDate)}
              maxDate={moment(maxDate)}
              autoOk
              allowKeyboardControl
              openTo="day"
              aria-label={ariaLabel}
              views={['year', 'month', 'day']}
              renderInput={customRenderInput}
              value={value}
              leftArrowButtonProps={{ 'aria-label': 'Prev month' }}
              rightArrowButtonProps={{ 'aria-label': 'Next month' }}
              onChange={handleOnChange}
              inputFormat="YYYY-MM-DD"
              variant="standard"
              onError={error => {
                setErrorMessage(error ? 'Date range error' : '')
              }}
            />
          </div>
        </LocalizationProvider>
      </ThemeProvider>
    </StylesProvider>
  )
}

DatePickerComponent.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  minDate: PropTypes.string,
  maxDate: PropTypes.string,
  handleOnChange: PropTypes.func,
  ariaLabel: PropTypes.string,
}

export default DatePickerComponent
