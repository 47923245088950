import React from 'react'
import PropTypes from 'prop-types'
import { Spinner } from 'greenfield-utilities'
import { Button, Dialog, Divider, DialogActions, DialogTitle, DialogContent, TextField } from '@mui/material'
import cloneDeep from 'lodash/cloneDeep'

import { GreenfieldAutoComplete } from '../../shared/shared'

import './save-as.scss'

class SaveAs extends React.Component {
  state = {
    title: '',
    description: '',
    props: this.props,
    addedOwners: [
      {
        label: `${this.props?.defaultUser?.firstName} ${this.props?.defaultUser?.lastName}`,
        _id: this.props?.defaultUser?.lanId,
        type: 'user',
      },
    ],
    isDefaultPresent: true,
    support_details: { text: '' },
  }

  selectedOwners = []

  static getDerivedStateFromProps(nextProps, prevState) {
    const { detailsOwnersStatus } = nextProps

    if (
      detailsOwnersStatus !== prevState.props.detailsOwnersStatus &&
      detailsOwnersStatus &&
      detailsOwnersStatus.data &&
      detailsOwnersStatus.data.length
    ) {
      const suggestions = detailsOwnersStatus.data.map(item => ({
        ...item,
        label: item.user_group_name,
      }))

      return {
        suggestions,
      }
    }

    return null
  }

  sendFireflyEvent = (isCustomEvent, eventObj) => {
    if (isCustomEvent) {
      this.props.trackCustomEvent(eventObj.eventName, eventObj.key, eventObj.eventData)
    } else {
      this.props.trackEvent({
        event: {
          type: eventObj.eventName,
        },
      })
    }
  }

  handleSaveClicked = () => {
    const { itemId, saveAsType } = this.props

    this.props.saveAsSuccess({
      title: this.state.title,
      description: this.state.description,
      selectedOwners: this.state.addedOwners,
      support_details: this.state.support_details,
    })

    const eventObj = {
      eventName: 'greenfieldSaveAsClickThrough',
      key: itemId,
      eventData: {
        saveAsType,
        itemId,
      },
    }
    this.sendFireflyEvent(true, eventObj)
  }

  handleCancelClicked = () => {
    this.props.cancelSaveAs()
  }

  selectedSuggestions = selections => {
    const addedOwners = cloneDeep(this.state.addedOwners)
    let index = -1
    if (selections._id) {
      isNaN(selections) ? addedOwners.push(selections) : addedOwners.splice(selections, 1)
      this.setState({ addedOwners })
    } else if (Number.isInteger(selections)) {
      index = parseInt(selections, 10)
      if (index === 0) {
        this.setState({
          isDefaultPresent: false,
        })
      }
      addedOwners.splice(index, 1)
      this.setState({ addedOwners })
    }
  }

  searchOwnersNames = searchText => {
    this.props.getDetailsOwners(searchText)
  }

  handleChangeValue = event => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  render() {
    const { addedOwners } = this.state
    const { detailsOwnersStatus } = cloneDeep(this.props)
    let updatedDefaults = []

    if (addedOwners.length) {
      updatedDefaults = addedOwners.map(owner => {
        return owner.label
      })
    }

    return (
      <Dialog maxWidth={false} className="save-as" open={this.props.openSaveAs} aria-labelledby="save-as-title">
        <div className={this.props.apiCallRequestStatus.status === 'requested' ? 'api-requested' : ''}>
          {this.props.apiCallRequestStatus.status === 'requested' && (
            <Spinner className="spinner-center" size="large" layout="selfCentering" />
          )}
          <DialogTitle id="save-as-dialog-title">Save As</DialogTitle>
          <Divider />
          <DialogContent>
            <div style={{ width: '40vw', height: '30vh' }}>
              <TextField
                fullWidth
                required
                margin="dense"
                id="title-field"
                value={this.state.title}
                label={this.props.titleLabel}
                name="title"
                onChange={this.handleChangeValue}
                variant="standard"
              />

              <div className="comp-margin">
                <GreenfieldAutoComplete
                  isEditable
                  label={this.props.ownerLabel}
                  searchApi={this.searchOwnersNames}
                  searchStatus={detailsOwnersStatus}
                  suggestions={this.state.suggestions}
                  selectedSuggestions={this.selectedSuggestions}
                  default={updatedDefaults}
                  labelText="Owners"
                />
              </div>

              <TextField
                fullWidth
                multiline
                rows={3}
                id="description-field"
                label={this.props.descriptionLabel}
                margin="dense"
                value={this.state.description}
                name="description"
                onChange={this.handleChangeValue}
                variant="standard"
              />
            </div>
          </DialogContent>
          <Divider />
          <DialogActions>
            <Button onClick={this.handleCancelClicked} variant="text" id="cancel-save-as-btn" color="secondary">
              Cancel
            </Button>
            <Button
              variant="text"
              color="primary"
              id="save-as-card-btn"
              data-cy="save-as-card-btn"
              disabled={!this.state.title && !this.state.selectedOwners}
              onClick={this.handleSaveClicked}
            >
              Save
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    )
  }
}

SaveAs.defaultProps = {
  apiCallRequestStatus: {},
  openSaveAs: false,
  titleLabel: '',
  ownerLabel: '',
  descriptionLabel: '',
}

SaveAs.propsTypes = {
  apiCallRequestStatus: PropTypes.object,
  openSaveAs: PropTypes.bool,
  saveAsSuccess: PropTypes.func,
  cancelSaveAs: PropTypes.func,
  itemId: PropTypes.string,
  saveAsType: PropTypes.string,
  titleLabel: PropTypes.string.isRequired,
  ownerLabel: PropTypes.string.isRequired,
  descriptionLabel: PropTypes.string.isRequired,
}

export default SaveAs
