import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import PropTypes from 'prop-types'

import { dateFilter } from '../../../ducks/timeperiod'

import {
  fetchLargeExportJobs,
  fetchLargeExportIndividualJob,
  deleteLargeExportJob,
  fetchDownloadData,
  cancelJob,
  clearLargeExportCacheData,
} from '../../../ducks/LargeExport'
import LargeExport from './LargeExport'

const LargeExportContainer = props => {
  useEffect(() => {
    props.fetchLargeExportJobs()
    props.dateFilter()
    props.setHeaderTitle('Export Requests')
  }, [])

  return (
    <LargeExport
      largeExportJobList={props.largeExportJobs}
      fetchLargeExportIndividualJob={props.fetchLargeExportIndividualJob}
      largeExportIndividualJob={props.largeExportIndividualJob}
      deleteLargeExportJob={props.deleteLargeExportJob}
      deleteJobInfo={props.deleteJobInfo}
      dateFilterStatus={props.dateFilterStatus}
      fetchDownloadData={props.fetchDownloadData}
      downloadedData={props.downloadedData}
      fetchLargeExportJobs={props.fetchLargeExportJobs}
      cancelJob={props.cancelJob}
      cancelJobInfo={props.cancelJobInfo}
      clearLargeExportCache={props.clearLargeExportCacheData}
      userInfo={props.userInfo}
    />
  )
}

export const mapDispatchToProps = dispatch => ({
  fetchLargeExportJobs(data) {
    dispatch(fetchLargeExportJobs(data))
  },
  fetchLargeExportIndividualJob(data) {
    dispatch(fetchLargeExportIndividualJob(data))
  },
  deleteLargeExportJob(data) {
    dispatch(deleteLargeExportJob(data))
  },
  dateFilter(data) {
    dispatch(dateFilter(data))
  },
  fetchDownloadData(data) {
    dispatch(fetchDownloadData(data))
  },
  cancelJob(data) {
    dispatch(cancelJob(data))
  },
  clearLargeExportCacheData(data) {
    dispatch(clearLargeExportCacheData(data))
  },
})

export const mapStateToProps = state => {
  return {
    largeExportJobs: state.largeExport.largeExportJobs,
    largeExportIndividualJob: state.largeExport.largeExportIndividualJob,
    deleteJobInfo: state.largeExport.deleteJobInfo,
    dateFilterStatus: state.timeperiod.dateFilterStatus,
    downloadedData: state.largeExport.downloadedData,
    cancelJobInfo: state.largeExport.cancelJobInfo,
    userInfo: state.user.userType?.data,
  }
}

LargeExportContainer.propTypes = {
  routeProps: PropTypes.object,
  setHeaderTitle: PropTypes.func,
  user: PropTypes.object,
  isMobile: PropTypes.bool,
  deviceType: PropTypes.array,
}

export default connect(mapStateToProps, mapDispatchToProps)(LargeExportContainer)
