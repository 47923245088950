import React, { useEffect, useState } from 'react'
import { makeStyles } from '@mui/styles'
import PropTypes from 'prop-types'
import {
  Button,
  Checkbox,
  Dialog,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material'
import styled from 'styled-components'
import axios from 'axios'
import { Spinner, createErrMsg } from 'greenfield-utilities'
import { API_GATEWAY_URL } from '../../../ducks/utils'

const useStyles = makeStyles(
  {
    configPopover: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      '& .gf-MuiPaper-elevation8': {
        minWidth: '80%',
        minHeight: '80%',
      },
      height: 'auto',
    },
    textArea: {
      '& .gf-MuiInputBase-fullWidth': {
        width: '400%',
      },
    },
    textAreaCol: {
      paddingTop: 15,
      paddingLeft: 15,
    },
    overridesCol: {
      paddingTop: 38,
      paddingLeft: 60,
    },
    loadingText: {
      paddingTop: 25,
      paddingLeft: 25,
    },
    formLabel: {
      fontSize: '24px !important',
      color: 'black',
    },
  },
  { index: 1 }
)

const SSpinner = styled(Spinner)`
  margin-top: 350px;
`

const ConfigOverrides = ({ isOpen, onHandleClose, cardId, datasetId }) => {
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(true)
  const [configValue, setConfigValue] = useState('card')
  const [overrides, setOverrides] = useState(false)
  const [readOnly, setReadOnly] = useState(true)
  const [updateType, setUpdateType] = useState('append')
  const [textAreaContent, setTextAreaContent] = useState('')
  const [error, setError] = useState('')

  useEffect(() => {
    if (configValue === 'card') {
      axios
        .get(`${API_GATEWAY_URL}/bi_reporting_assets/v2/admin/card/${cardId}/config${overrides ? '/overrides' : ''}`)
        .then(response => {
          if (response.status >= 200 && response.status < 300) {
            setTextAreaContent(JSON.stringify(response.data, undefined, 4))
          }
        })
        .catch(e => {
          setError(createErrMsg(e.status, e.message))
        })
    } else if (configValue === 'dataset') {
      axios
        .get(
          `${API_GATEWAY_URL}/bi_reporting_assets/v2/admin/dataset/${datasetId}/config${overrides ? '/overrides' : ''}`
        )
        .then(response => {
          if (response.status >= 200 && response.status < 300) {
            setTextAreaContent(JSON.stringify(response.data, undefined, 4))
          }
        })
        .catch(e => {
          setError(createErrMsg(e.status, e.message))
        })
    } else if (configValue === 'system') {
      axios
        .get(`${API_GATEWAY_URL}/bi_reporting_assets/v2/admin/config${overrides ? '/overrides' : ''}`)
        .then(response => {
          if (response.status >= 200 && response.status < 300) {
            setTextAreaContent(JSON.stringify(response.data, undefined, 4))
          }
        })
        .catch(e => {
          setError(createErrMsg(e.status, e.message))
        })
    }

    setIsLoading(false)
  }, [overrides, configValue])

  const handleOverridesChange = event => {
    setOverrides(event.target.checked)
  }

  const handleConfigChange = event => {
    if (event.target.value !== 'card') {
      setReadOnly(true)
    }
    setConfigValue(event.target.value)
  }

  const handleUpdateClicked = () => {
    setReadOnly(false)
  }

  const handleUpdateTypeChange = event => {
    setUpdateType(event.target.value)
  }

  const handleTextAreaChange = event => {
    setTextAreaContent(event.target.value)
  }

  const handleSubmit = () => {
    try {
      const payload = textAreaContent.length > 0 ? JSON.parse(textAreaContent) : {}
      if (updateType === 'append') {
        axios
          .patch(`${API_GATEWAY_URL}/bi_reporting_assets/v2/admin/card/${cardId}/config/overrides`, payload)
          .then(() => {
            onHandleClose()
          })
          .catch(e => {
            setError(createErrMsg(e.status, e.message))
          })
      } else if (updateType === 'replace') {
        axios
          .put(`${API_GATEWAY_URL}/bi_reporting_assets/v2/admin/card/${cardId}/config/overrides`, payload)
          .then(() => {
            onHandleClose()
          })
          .catch(e => {
            setError(createErrMsg(e.status, e.message))
          })
      }
    } catch (e) {
      setError(createErrMsg(e.status, e.message))
    }
  }

  const renderLoading = () => (
    <div>
      <Typography variant="h2" className={classes.loadingText}>
        Loading configs
      </Typography>
      <SSpinner layout="selfCentering" size="large" />
    </div>
  )

  return (
    <Dialog onClose={onHandleClose} open={isOpen} fullWidth maxWidth="md" className={classes.configPopover}>
      {isLoading ? (
        renderLoading()
      ) : (
        <Grid container direction="row" alignItems="flex-start">
          <Grid item xs={9}>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="flex-start"
              className={classes.textAreaCol}
            >
              <Grid item>
                <FormControl component="fieldset" variant="standard">
                  <FormLabel component="label" className={classes.formLabel} data-cy="config-title">
                    Configurations
                  </FormLabel>
                  <RadioGroup
                    aria-label="configurations"
                    name="configurations"
                    value={configValue}
                    onChange={handleConfigChange}
                    row
                  >
                    <FormControlLabel value="card" control={<Radio data-cy="card-radio" />} label="Card" />
                    <FormControlLabel value="dataset" control={<Radio data-cy="dataset-radio" />} label="Dataset" />
                    <FormControlLabel value="system" control={<Radio data-cy="system-radio" />} label="System" />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item>
                <form className={classes.textArea} noValidate autoComplete="off" spellCheck="false">
                  <TextField
                    multiline
                    rows={40}
                    value={textAreaContent}
                    variant="outlined"
                    fullWidth
                    disabled={readOnly || configValue !== 'card'}
                    onChange={handleTextAreaChange}
                    inputProps={{ 'data-cy': 'configs-textfield' }}
                  />
                </form>
              </Grid>
              <Grid item>
                <Typography variant="subtitle1" color="error">
                  {error}
                </Typography>
              </Grid>
              <Grid item>
                <Button variant="text" color="secondary" onClick={onHandleClose}>
                  Cancel
                </Button>
                <Button variant="text" color="secondary" onClick={handleSubmit} data-cy="submit-btn">
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item className={classes.overridesCol} xs={3}>
            <Grid container direction="column" alignItems="flex-start">
              <Grid item>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={overrides}
                        onChange={handleOverridesChange}
                        inputProps={{ 'aria-label': 'overrides checkbox' }}
                        data-cy="overrides-checkbox"
                      />
                    }
                    label="Display Overrides"
                  />
                </FormGroup>
              </Grid>
              {configValue === 'card' && overrides && (
                <>
                  <Grid item>
                    <Button variant="text" color="secondary" onClick={handleUpdateClicked} data-cy="update-btn">
                      Update
                    </Button>
                  </Grid>
                  {!readOnly && (
                    <Grid item>
                      <RadioGroup
                        aria-label="updateType"
                        name="updateType"
                        value={updateType}
                        onChange={handleUpdateTypeChange}
                      >
                        <FormControlLabel value="append" control={<Radio data-cy="append-update" />} label="Append" />
                        <FormControlLabel
                          value="replace"
                          control={<Radio data-cy="replace-update" />}
                          label="Replace"
                        />
                      </RadioGroup>
                    </Grid>
                  )}
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      )}
    </Dialog>
  )
}

ConfigOverrides.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onHandleClose: PropTypes.func.isRequired,
  cardId: PropTypes.number.isRequired,
  datasetId: PropTypes.number.isRequired,
}

export default ConfigOverrides
