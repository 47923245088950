import { createTheme } from '@mui/material/styles'

const colors = {
  gfGreen: '#006649', // from UX
}

const gfTheme = createTheme({
  palette: {
    primary: {
      main: '#006649',
    },
  },
  typography: {
    useNextVariants: true,
    h1: {
      fontSize: '24px',
      fontWeight: 400,
    },
    h2: {
      fontSize: '20px',
      fontWeight: 400,
    },
    h3: {
      fontSize: '16px',
    },
    h4: {
      fontSize: '14px',
    },
    body2: {
      fontSize: '14px',
    },
    body1: {
      fontSize: '14px',
    },
    caption: {
      fontSize: '12px',
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          color: '#000',
          backgroundColor: '#f5f5f5',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          lineHeight: 1,
          fontWeight: 400,
          margin: '5px 5px 5px 0',
          textTransform: 'capitalize',
          minHeight: '36px',
        },

        outlined: {
          border: '1px solid #999999',
          color: '#222222',

          '&:hover': {
            border: '1px solid #222222',
            backgroundColor: '#ffffff',
            textDecoration: 'underline',
          },
          '&:active': {
            border: '1px solid #222222',
            backgroundColor: 'rgba(177, 177, 177, 0.84)',
            textDecoration: 'underline',
          },
          '&:disabled': {
            border: '1px solid #797979',
            backgroundColor: '#e0e0e0',
            color: '#999999',
          },
        },

        outlinedPrimary: {
          backgroundColor: '#ffffff',
          border: '2px solid #006649',
          color: '#000000',

          '&:hover': {
            border: '2px solid #81c784',
            backgroundColor: 'rgba(174, 219, 175, 0.32)',
            textDecoration: 'none',
          },
          '&:active': {
            backgroundColor: 'rgba(174, 219, 175, 0.84)',
            textDecoration: 'none',
          },
          '&:disabled': {
            border: '1px solid #797979',
            backgroundColor: '#e0e0e0',
            color: '#999999',
          },
        },

        outlinedSecondary: {
          backgroundColor: '#f7f7f7',
          border: '2px solid #999999',
          color: '#000000',

          '&:hover': {
            backgroundColor: 'rgba(177, 177, 177, 0.32)',
            border: '2px solid #999999',
            textDecoration: 'none',
          },
          '&:active': {
            backgroundColor: 'rgba(177, 177, 177, 0.84)',
            textDecoration: 'none',
          },
          '&:disabled': {
            border: '1px solid #797979',
            backgroundColor: '#e0e0e0',
            color: '#999999',
          },
        },

        containedPrimary: {
          backgroundColor: '#aedbaf',
          color: '#000000',

          '&:hover': {
            backgroundColor: '#006649',
          },
          '&:active': {
            backgroundColor: '#51a255',
          },
          '&:disabled': {
            border: '1px solid #797979',
            backgroundColor: '#e0e0e0',
            color: '#999999',
          },
        },

        containedSecondary: {
          backgroundColor: '#f7f7f7',
          color: '#222222',

          '&:hover': {
            backgroundColor: '#e2e2e2',
          },
          '&:active': {
            backgroundColor: '#b1b1b1',
          },
          '&:disabled': {
            border: '1px solid #797979',
            backgroundColor: '#e0e0e0',
            color: '#999999',
          },
        },

        // Styles applied to the root element if `variant="text"` and `color="primary"`
        textPrimary: {
          backgroundColor: colors.gfGreen,
          color: '#ffffff',
          border: '1px solid #006649',
          padding: '8px 16px',

          '&:hover': {
            backgroundColor: '#004733',
          },
          '&:active': {
            backgroundColor: '#86afe5',
          },
          '&:disabled': {
            border: '1px solid #797979',
            backgroundColor: '#e0e0e0',
            color: '#999999',
          },
        },

        // Styles applied to the root element if `variant="text"` and `color="secondary"`
        textSecondary: {
          backgroundColor: '#ffffff',
          color: colors.gfGreen,
          border: '1px solid #006649',
          padding: '8px 16px',

          '&:hover': {
            backgroundColor: '#e2e2e2',
          },
          '&:active': {
            backgroundColor: '#b1b1b1',
          },
          '&:disabled': {
            border: '1px solid #797979',
            backgroundColor: '#e0e0e0',
            color: '#999999',
          },
        },

        sizeSmall: {
          padding: '4px 8px',
          minHeight: 20,
          fontSize: '12px',
          lineHeight: 1,
        },
      },
    },

    MuiIconButton: {
      styleOverrides: {
        root: {
          textDecoration: 'none !important',
          color: '#616161',
          padding: '10px',

          '&:focus': {
            outlineOffset: 'none',
          },
          '&:hover': {
            backgroundColor: 'rgba(174, 219, 175, 0.32)',
            color: '#222222',
          },
          '&:active': {
            backgroundColor: '#81c784',
            color: '#222222',
          },
          '&:disabled': {
            color: '#999999',
            cursor: 'default',
            pointerEvents: 'none',
          },
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          '&:focusVisible': {
            textDecoration: 'underline',
            outlineOffset: '1px',
            outline: '#222222 dashed 1px',
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          outline: 'none',

          '&:focus': {
            outline: 'none',
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          outline: 'none',
          textTransform: 'inherit',
          '&:focus': {
            outline: 'none',
          },
          '&:selected': {
            color: '#333',
            borderBottom: '2px solid #51a255',
          },
        },
        textColorPrimary: {
          color: '#616161',
          borderBottom: '1px solid #999999',
          '&:selected': {
            color: '#000000',
          },
          '&:hover': {
            backgroundColor: 'rgba(174, 219, 175, 0.32)',
            color: '#222222',
          },
        },
        textColorSecondary: {
          minWidth: '105px',
          color: '#616161',
          borderBottom: '1px solid #999999',
          '&:selected': {
            color: '#000000',
            borderBottom: '3px solid #006649',
          },
          '&:hover': {
            backgroundColor: 'rgba(174, 219, 175, 0.32)',
            color: '#222222',
          },
        },
      },
    },
    MuiPrivateTabIndicator: {
      styleOverrides: {
        colorPrimary: {
          height: 3,
          backgroundColor: '#81c784',
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          '&:focused': {
            color: '#2278cf',
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        underline: {
          '&:after': {
            borderBottomColor: '#2278cf',
          },
          '&:hover:not($disabled):not($focused):not($error):before': {
            borderBottom: '2px solid #222222',
          },
        },
        root: {
          '&:error': {
            color: '#eb0000 !important',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&:focused $notchedOutline': {
            borderColor: '#2278cf',
          },
          fontSize: '16px',
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: '#616161',

          '&:hover': {
            color: '#222222',
          },
          '&:active': {
            color: '#222222',
          },
        },
        colorSecondary: {
          '&:checked': {
            color: '#51A255',
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: '#616161',

          '&:hover': {
            color: '#222222',
          },
          '&:active': {
            color: '#222222',
          },
        },
        colorSecondary: {
          '&:checked': {
            color: '#51A255',
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          '& $checked': {
            color: '#51a255 !important',
            '& + $track': {
              backgroundColor: '#aedbaf !important',
            },
          },
          '& $switchBase': {
            '&:hover': {
              color: '#006649 !important',
              backgroundColor: 'inherit',
            },
          },
        },
        track: {
          backgroundColor: '#999 !important',
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          color: '#616161',
          width: 26,
          height: 26,
          marginLeft: 5,
          backgroundColor: '#d6e3f3',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          marginRight: '8px',

          '& $avatar': {
            color: '#616161',
            width: '32px',
            height: '32px',
            marginRight: '-4px',
            fontSize: '1rem',
          },
          '& .gf-MuiChip-deleteIcon': {
            color: '#555',
            cursor: 'pointer',
            height: 'auto',
            margin: '0 4px 0 -8px',
            '&:hover': {
              color: '#616161',
            },
            '&:focus': {
              color: '#616161',
              outline: '#222222 dashed 1px',
            },
          },
        },
        deleteIcon: {
          color: '#b1b1b1',
          cursor: 'pointer',
          height: 'auto',
          margin: '0 4px 0 -8px',
          '&:hover': {
            color: '#616161',
          },
          '&:focus': {
            color: '#616161',
            outline: '#222222 dashed 1px',
          },
        },
        deleteIconOutlinedColorPrimary: {
          color: '#b1b1b1',
          cursor: 'pointer',
          height: 'auto',
          margin: '0 4px 0 -8px',
          '&:hover': {
            color: '#616161',
          },
        },
        deleteIconOutlinedColorSecondary: {
          color: '#b1b1b1',
          cursor: 'pointer',
          height: 'auto',
          margin: '0 4px 0 -8px',
          '&:hover': {
            color: '#616161',
          },
        },
        outlined: {
          color: '#222222',
          backgroundColor: '#ffffff',
          borderColor: '#999999',
          marginTop: '4px',

          '& $avatar': {
            backgroundColor: '#d6e3f3',
            color: '#616161',
            width: '26px',
            height: '26px',
            marginLeft: '5px',
          },
        },
        outlinedPrimary: {
          color: '#222222',
          backgroundColor: '#f2ecfa',
          borderColor: '#9e8fb4',
        },
        outlinedSecondary: {
          color: '#222222',
          backgroundColor: '#e9f8d8',
          borderColor: '#b8d695',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: '14px',
          '&:error': {
            color: '#eb0000 !important',
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          '&:error': {
            color: '#eb0000 !important',
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          '&:error': {
            color: '#eb0000 !important',
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          '&:focus': {
            backgroundColor: '#ffffff',
            color: '#616161',
            outline: '#222222 dashed 1px',
          },
        },
      },
    },

    MuiFab: {
      styleOverrides: {
        root: {
          textDecoration: 'none !important',
          color: '#616161',
          padding: '10px',
        },
        primary: {
          '&:focus': {
            outlineOffset: 'none',
          },
          '&:hover': {
            backgroundColor: 'rgba(174, 219, 175, 0.32)',
            color: '#222222',
          },
          '&:active': {
            backgroundColor: '#81c784',
            color: '#222222',
          },
          '&:disabled': {
            color: '#999999',
            cursor: 'default',
            pointerEvents: 'none',
          },
        },
      },
    },
    MuiStepper: {
      styleOverrides: {
        horizontal: {
          padding: '10px',
        },
      },
    },
  },
})
export default gfTheme
