import React from 'react'
import { Typography, Box } from '@mui/material'

const ExternalUserHelpContent = () => {
  return (
    <Box m={1}>
      <Typography variant="body1">
        Contact Partners Online Help either using the available "Quick Help" or by clicking "Contact Us" at the bottom
        to be directed to Partners Online Help Page
      </Typography>
    </Box>
  )
}

export default ExternalUserHelpContent
