import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { fetchUserType, fetchActAsAdmin, updateCurrentUser } from '../../ducks/user'
import SideNav from './Sidenav'
import navJson from './sideNav.json'

const SideNavContainer = props => {
  const { sideNavIsOpen, onToggle, updateFavoriteData, isMobile, deviceType, auth, routeProps } = props
  const { userType, userAdminOptIn } = useSelector(state => state.user)
  const siteObj = useSelector(state => state.site.siteStatus)
  const dispatch = useDispatch()

  useEffect(() => {
    if (userAdminOptIn?.data) {
      window.location.reload()
    }
  }, [userAdminOptIn])

  const updateUser = data => {
    dispatch(updateCurrentUser(data))
  }

  const getUserType = () => {
    dispatch(fetchUserType())
  }

  const actAsAdmin = data => {
    dispatch(fetchActAsAdmin(data))
  }

  return (
    <SideNav
      updateUser={updateUser}
      actAsAdmin={actAsAdmin}
      sideNavIsOpen={sideNavIsOpen}
      onToggleNav={onToggle}
      navList={navJson.nav}
      fetchFavData={updateFavoriteData}
      auth={auth}
      isMobile={isMobile}
      deviceType={deviceType}
      userType={userType}
      fetchUserType={getUserType}
      routeProps={routeProps}
      siteObj={siteObj}
    />
  )
}

SideNavContainer.defaultProps = {
  sideNavIsOpen: false,
  navList: [],
  subMenuOpen: false,
}

SideNavContainer.propTypes = {
  actAsAdmin: PropTypes.func,
  sideNavIsOpen: PropTypes.bool,
  navList: PropTypes.array,
  subMenuOpen: PropTypes.bool,
  onToggle: PropTypes.func,
  fetchFavData: PropTypes.func,
  isMobile: PropTypes.bool,
  deviceType: PropTypes.array,
}

export default SideNavContainer
