import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import { Typography, Grid, Avatar, Card, CardContent, Tabs, Tab, AppBar, Paper, Toolbar } from '@mui/material'
import { Apps, Group, Notifications, Favorite, Person } from '@mui/icons-material'
import { Switch, Route, Link } from 'react-router-dom'

import {
  fetchUserDashboardsCount,
  fetchUserCardsCount,
  fetchUserDatasetsCount,
  fetchUserCalcFieldsCount,
  fetchUserFavCardsCount,
  fetchUserFavDashboardsCount,
  fetchUserFavDatasetsCount,
} from '../../../ducks/user'
import { displayServiceErrorMessage } from '../../../ducks/layout'
import { clearGetAllSubscriptionsByCard, getAllSubscriptions } from '../../../ducks/dataAlertAndSubscription'
import { getSubscriptionsPayload } from '../Cards/Cardviewer/DataAlertAndSubscription/DataAlertSubscriptionListing'
import { ACTIVE } from '../../shared/AssetTableSS/StatusFilters'
import './profile.scss'
import { GridFiltersWithData } from './GridFilters'
import ContentTable from './ContentTable'
import RecommendedCards from './UserInsightsUtils/RecommendedCards'

export const SUBSCRIPTIONS_PAGE_SIZE = 1000
const tabsList = [
  '/profile/subscriptions',
  '/profile/favorites',
  '/profile/owned-items',
  '/profile/groups',
  '/profile/user-recommendations',
]

const Profile = ({ setHeaderTitle, company, isMobile, routeProps, trackEvent, sendFireflyEvent }) => {
  const dispatch = useDispatch()
  const [user, setUser] = useState(null)
  const [userHasSubscriptionAccess, setUserHasSubscriptionAccess] = useState(false)
  const [subscriptions, setSubscriptions] = useState(null)
  const [statusType, setStatusType] = useState(ACTIVE)
  const [isLoading, setIsLoading] = useState(false)

  const { userAccessStatus, userType } = useSelector(state => state.user)
  const { subscriptionsListStatus } = useSelector(state => state.dataAlertAndSubscription)

  const {
    userCalcFieldsCount,
    userDatasetsCount,
    userCardsCount,
    userDashboardsCount,
    userFavCardsCount,
    userFavDashboardsCount,
    userFavDatasetsCount,
  } = useSelector(state => state.user)

  useEffect(() => {
    setHeaderTitle('Profile')
    if (routeProps.location.pathname === tabsList[1]) {
      setIsLoading(true)
      dispatch(fetchUserFavCardsCount({ favoriteOnly: true }))
      dispatch(fetchUserFavDashboardsCount({ favoriteOnly: true }))
      dispatch(fetchUserFavDatasetsCount({ favoriteOnly: true }))
    } else if (routeProps.location.pathname === tabsList[2]) {
      dispatch(fetchUserDashboardsCount())
      dispatch(fetchUserCardsCount())
      dispatch(fetchUserDatasetsCount())
      dispatch(fetchUserCalcFieldsCount())
    }
  }, [routeProps.location.pathname])

  useEffect(() => {
    if (subscriptionsListStatus?.data) {
      setSubscriptions(subscriptionsListStatus?.data)
    } else if (subscriptionsListStatus?.status === 'failed') {
      dispatch(
        displayServiceErrorMessage(
          subscriptionsListStatus?.resp?.statusText || 'Subscriptions fetch request failed, please try again later!'
        )
      )
    }
  }, [subscriptionsListStatus])

  useEffect(() => {
    if (userAccessStatus?.data) {
      setUserHasSubscriptionAccess(userAccessStatus.data.SUBSCRIPTION_CREATE)
    }
  }, [userAccessStatus])

  useEffect(() => {
    if (userHasSubscriptionAccess && routeProps.location.pathname === tabsList[0]) {
      dispatch(clearGetAllSubscriptionsByCard())
      dispatch(getAllSubscriptions(getSubscriptionsPayload(statusType)))
    }
  }, [routeProps, userHasSubscriptionAccess, statusType])

  useEffect(() => {
    if (userType?.data && !user) {
      setUser(userType?.data)
    }
  }, [userType])

  useEffect(() => {
    if (userFavCardsCount?.data) {
      setIsLoading(false)
    }
  }, [userFavCardsCount])

  const formatDate = (date, format) => moment(date).format(format)

  const getDates = () =>
    (user?.first_active_date || user?.created_date) && (
      <>
        <Typography variant="subtitle2">
          Active Since:
          {` ${formatDate(user?.first_active_date, 'MMM DD YYYY')} | ${formatDate(user?.first_active_date, 'hh:mm A')}`}
        </Typography>
        <Typography variant="subtitle2" sx={{ textAlign: 'end' }}>
          Last Active:
          {` ${formatDate(user?.last_active_date, 'MMM DD YYYY')} | ${formatDate(user?.last_active_date, 'hh:mm A')}`}
        </Typography>
      </>
    )

  return (
    <div className="page-profile">
      <AppBar position="sticky">
        <Toolbar classes={{ root: 'toolbar' }}>
          <Typography classes={{ root: 'toolbar-title' }} tabIndex="0" aria-label="my profile">
            MY PROFILE
          </Typography>
        </Toolbar>
      </AppBar>
      <Card className="owned-card">
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <CardContent style={{ margin: '1rem' }}>
              <Grid container alignItems="center">
                <Grid item>
                  <Avatar style={{ width: 56, height: 56, background: 'grey', color: 'white' }}>
                    {user?.first_name && user?.last_name
                      ? `${user?.first_name.charAt(0)}${user?.last_name.charAt(0)}`
                      : ''}
                  </Avatar>
                </Grid>
                <Grid item sx={{ marginLeft: '1rem' }}>
                  <Typography variant="subtitle2">
                    {user?.first_name && user?.last_name ? `${user?.first_name} ${user?.last_name}` : ''}
                  </Typography>
                  <Typography variant="subtitle2">{user?.email_address}</Typography>
                  <Typography variant="subtitle2">{user?._id}</Typography>
                  <Typography variant="subtitle2">{company}</Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Grid>
          <Grid item>
            <CardContent>{getDates()}</CardContent>
          </Grid>
        </Grid>
      </Card>
      <Paper sx={{ marginTop: 1, marginBottom: 5 }}>
        <AppBar position="static" elevation={1}>
          <Route
            path="/profile"
            render={() => (
              <>
                <Tabs
                  aria-label="profile tabs"
                  variant={isMobile ? 'scrollable' : 'fullWidth'}
                  value={location.pathname}
                >
                  <Tab
                    label="My Subscriptions & Alerts"
                    icon={<Notifications />}
                    data-cy="subscriptions"
                    value="/profile/subscriptions"
                    component={Link}
                    to={tabsList[0]}
                  />
                  <Tab
                    component={Link}
                    data-cy="favorites"
                    icon={<Favorite />}
                    label="My Favorites"
                    value="/profile/favorites"
                    to={tabsList[1]}
                  />
                  <Tab
                    component={Link}
                    to={tabsList[2]}
                    data-cy="owned"
                    icon={<Apps />}
                    label="Owned Items"
                    value="/profile/owned-items"
                  />
                  <Tab
                    component={Link}
                    to={tabsList[3]}
                    data-cy="groups"
                    icon={<Group />}
                    label="My Groups"
                    value="/profile/groups"
                  />
                  <Tab
                    component={Link}
                    to={tabsList[4]}
                    data-cy="user-recommendations"
                    icon={<Person />}
                    label="User Recommendations"
                    value="/profile/user-recommendations"
                  />
                </Tabs>
                <Switch>
                  {userHasSubscriptionAccess && (
                    <Route
                      path={[tabsList[0], '/profile/subscriptions?id']}
                      render={() => (
                        <ContentTable
                          chipInfo={subscriptions}
                          setSubscriptions={setSubscriptions}
                          chipTitle="Subscription"
                          routeProps={routeProps}
                          trackEvent={trackEvent}
                          isMobile={isMobile}
                          isProfilePage
                          statusType={statusType}
                          setStatusType={setStatusType}
                          sendFireflyEvent={sendFireflyEvent}
                          currentUser={user}
                        />
                      )}
                    />
                  )}
                  <Route
                    path={tabsList[1]}
                    render={() => (
                      <GridFiltersWithData
                        title="My Favorites"
                        cardsCount={userFavCardsCount?.data?.count || 0}
                        dashboardsCount={userFavDashboardsCount?.data?.count || 0}
                        datasetsCount={userFavDatasetsCount?.data?.count || 0}
                        isFavorite
                        isMobile={isMobile}
                        isLoading={isLoading}
                      />
                    )}
                  />
                  <Route
                    path={tabsList[2]}
                    render={() => (
                      <GridFiltersWithData
                        title="Owned Items"
                        isMobile={isMobile}
                        cardsCount={userCardsCount?.data?.count || 0}
                        dashboardsCount={userDashboardsCount?.data?.count || 0}
                        datasetsCount={userDatasetsCount?.data?.count || 0}
                        calcFieldsCount={userCalcFieldsCount?.data?.count || 0}
                      />
                    )}
                  />
                  <Route
                    path={tabsList[3]}
                    render={() => <ContentTable chipInfo={user?.groups} chipTitle="Group" isMobile={isMobile} />}
                  />
                  <Route path={tabsList[4]} render={() => <RecommendedCards />} />
                </Switch>
              </>
            )}
          />
        </AppBar>
      </Paper>
    </div>
  )
}

export default Profile
