import React from 'react'
import { Close } from '@mui/icons-material'
import { AppBar, IconButton, Toolbar, Typography } from '@mui/material'
import styled from 'styled-components'

const SAppBar = styled(AppBar)`
  background: black;
  color: white;
`

const Header = ({ onChangeHelpDrawerToggle }) => {
  return (
    <SAppBar position="static">
      <Toolbar>
        <Typography variant="subtitle1" component="div" sx={{ flexGrow: 1 }}>
          Help
        </Typography>
        <IconButton sx={{ color: 'white' }} onClick={onChangeHelpDrawerToggle}>
          <Close />
        </IconButton>
      </Toolbar>
    </SAppBar>
  )
}

export default Header
