import React from 'react'
import PropTypes from 'prop-types'
import { Spinner, createErrMsg } from 'greenfield-utilities'
import { List, ListItem, ListItemText, TextField, Typography } from '@mui/material'
import { Cancel } from '@mui/icons-material'
import { SChip } from '../../../shared/MUIAutoComplete/Autocomplete'

const BuilderBusinessArea = ({
  businessAreaSearchValue,
  cardInfo,
  selectedBusinessAreas,
  detailsBusinessAreasStatus,
  onBusinessAreaChange,
  updateChangedCards,
  onDeleteBusinessArea,
  businessAreaFieldId,
  onValidateBusinessArea,
  isBusinessAreaValid = {},
  clearBusinessAreaTag,
  variant = 'standard',
}) => (
  <div>
    <TextField
      fullWidth
      label="Business Area"
      aria-label="business area"
      autoComplete="off"
      value={businessAreaSearchValue}
      onChange={onBusinessAreaChange}
      disabled={cardInfo && cardInfo.status === 'requested'}
      id={businessAreaFieldId}
      variant={variant}
    />
    {isBusinessAreaValid.status === 'requested' && (
      <Spinner data-cy="validate-spinner" layout="selfCentering" size="small" />
    )}
    {detailsBusinessAreasStatus.status === 'requested' && (
      <Spinner data-cy="business-list-spinner" layout="selfCentering" size="small" />
    )}
    {detailsBusinessAreasStatus.status >= 200 &&
      detailsBusinessAreasStatus.status < 300 &&
      Boolean(businessAreaSearchValue.length) && (
        <div className="businessarea-list-container">
          <List className="businessarea-list">
            <>
              {detailsBusinessAreasStatus.data
                .filter(business => !selectedBusinessAreas.includes(business))
                .map((business, index) => (
                  <ListItem
                    component="li"
                    data-cy={`business-area-tag-item-${index}`}
                    id={`${businessAreaSearchValue}${index}`}
                    button
                    key={business}
                    onClick={() => {
                      if (cardInfo && cardInfo.data && cardInfo.data.card_config) {
                        updateChangedCards(cardInfo.data.card_config._id)
                      }
                      clearBusinessAreaTag()
                      onValidateBusinessArea(business)
                    }}
                  >
                    <ListItemText primary={business} data-cy={`business-area-item-text-${index}`} />
                  </ListItem>
                ))}
              {detailsBusinessAreasStatus.status >= 200 &&
                detailsBusinessAreasStatus.status < 300 &&
                detailsBusinessAreasStatus.data.findIndex(
                  business => businessAreaSearchValue?.toLowerCase() === business.toLowerCase()
                ) === -1 && (
                  <ListItem
                    component="li"
                    id="create-business-area"
                    data-cy="create-business-area-tag-item"
                    button
                    key="create-business-area-dropdown"
                    onClick={() => {
                      onValidateBusinessArea(businessAreaSearchValue)
                    }}
                  >
                    <ListItemText
                      primary={
                        <Typography className="create-tag" variant="body1">
                          {`Create - ${businessAreaSearchValue}`}
                        </Typography>
                      }
                    />
                  </ListItem>
                )}
            </>
          </List>
        </div>
      )}
    {isBusinessAreaValid.status === 'failed' && !businessAreaSearchValue && (
      <Typography
        className="business-area-valid-error"
        data-cy="error-msg-details"
        id="error-msg-details"
        variant="body2"
      >
        {createErrMsg(isBusinessAreaValid, 'Business Area is not valid')}
      </Typography>
    )}
    <div className="businessarea-chip-container" style={{ marginTop: '0.5rem' }}>
      {selectedBusinessAreas.map((area, index) => (
        <SChip
          data-cy={`business-area-chip-${index}`}
          className="chip-business"
          key={area}
          label={<Typography>{area}</Typography>}
          deleteIcon={<Cancel tabIndex={0} role="button" style={{ color: 'white' }} />}
          onDelete={() => {
            if (cardInfo && cardInfo.data && cardInfo.data.card_config) {
              updateChangedCards(cardInfo.data.card_config._id)
            }
            onDeleteBusinessArea(area)
          }}
        />
      ))}
    </div>
  </div>
)

BuilderBusinessArea.defaultProps = {
  businessAreaSearchValue: '',
  cardInfo: {},
  selectedBusinessAreas: [],
  detailsBusinessAreasStatus: {},
}

BuilderBusinessArea.propTypes = {
  businessAreaSearchValue: PropTypes.string,
  cardInfo: PropTypes.object,
  selectedBusinessAreas: PropTypes.array,
  detailsBusinessAreasStatus: PropTypes.object,
  handleOnChange: PropTypes.func,
  onBusinessAreaClick: PropTypes.func,
  updateChangedCards: PropTypes.func,
  onDeleteBusinessArea: PropTypes.func,
}

export default BuilderBusinessArea
