import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Icon, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Spinner, createErrMsg } from 'greenfield-utilities'

import Dashboardviewer from '../Dashboard/Dashboardviewer/Dashboardviewer.container'

import './favorites.scss'

const SSpinner = styled(Spinner)`
  margin-top: 50px;
`

const HomeDashboard = ({ routeProps = {}, isMobile = false, deviceType, setHeaderTitle = () => {}, user = {} }) => {
  useEffect(() => {
    setHeaderTitle('Home Dashboard')
  }, [])

  if (user?.userType?.data?.personalization?.home?.id) {
    return (
      <Dashboardviewer
        activeTab={routeProps.match.params._tab}
        isPrintLandscape={false}
        routeProps={routeProps}
        setHeaderTitle={() => {}}
        isMobile={isMobile}
        deviceType={deviceType}
        dashboardId={user.userType.data.personalization.home.id}
        isHomePage
      />
    )
  } else if (user?.userType?.status === 'requested') {
    return <SSpinner layout="selfCentering" size="large" />
  } else if (user?.userType?.status && user.userType.status === 'failed') {
    return (
      <div className="homeDashboardMsgContainer">
        <div className="homeDashboardMsg">
          <Icon>not_interested</Icon>
          <Typography>
            {createErrMsg(user.userType.status, 'Something went wrong with the request to get your home dashboard.')}
          </Typography>
        </div>
      </div>
    )
  } else {
    return (
      <div className="homeDashboardMsgContainer">
        <div className="homeDashboardMsg">
          <Typography>
            This is a placeholder for your favorite dashboard. To set this click &nbsp;
            <Icon className="noMessageIcon">radio_button_unchecked</Icon> by your prefered dashboard&nbsp;
            <Link className="link-text" to="/dashboards">
              here
            </Link>
          </Typography>
        </div>
      </div>
    )
  }
}

HomeDashboard.propTypes = {
  routeProps: PropTypes.object,
  setHeaderTitle: PropTypes.func,
  user: PropTypes.object,
  isMobile: PropTypes.bool,
  deviceType: PropTypes.array,
}
export default HomeDashboard
