import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { DeleteOutline, EditOutlined, KeyboardArrowDown } from '@mui/icons-material'
import { Grid, Stack, Tooltip, Typography, IconButton, CardContent, MenuItem, Button, Menu } from '@mui/material'
import { Hover } from '../../Search/AllAssetsListing'
import { ACCESS_CONTROL } from './BetaFeatures'

const SCard = styled.div({
  position: 'relative',
  height: '150px',
  cursor: 'pointer',
  background: 'white',
  overflow: 'auto',
  transition: '0.3s',
  [`:hover ${Hover}`]: {
    opacity: 1,
  },
})

const FeatureFlag = ({
  featureFlag,
  isMobile,
  isAdmin,
  setIsDialogOpen,
  setSelectedFeatureFlag,
  setDeleteDialogOpen,
  handleOptInOptOut,
}) => {
  const { description, label, _id } = featureFlag
  const { userAccessStatus } = useSelector(state => state.user)

  const canUserOptIn = featureFlag.participation.user_can_opt_in
  const isUserAccessEnabled = userAccessStatus?.data && userAccessStatus.data[featureFlag._id]

  const buttonText = isUserAccessEnabled
    ? canUserOptIn
      ? 'ENABLED'
      : 'FORCED ON'
    : canUserOptIn
    ? 'DISABLED'
    : 'FORCED OFF'

  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleButtonClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleOnSelectChange = () => {
    setSelectedFeatureFlag(featureFlag)
    setAnchorEl(null)
    handleOptInOptOut(featureFlag)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <Grid item key={_id} xs={6} sx={{ padding: 2 }}>
      <SCard variant="outlined" style={isMobile ? { height: 'auto' } : {}}>
        <CardContent>
          <Stack direction={isMobile ? 'column' : 'row'} justifyContent="space-between" alignItems="center">
            <Grid container alignItems="center">
              <Tooltip position="bottom" title={label}>
                <Typography
                  variant="h6"
                  component="div"
                  data-cy="feature-flag-title"
                  sx={
                    isMobile
                      ? { wordBreak: 'break-all' }
                      : {
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          maxWidth: '400px',
                        }
                  }
                >
                  {label}
                </Typography>
              </Tooltip>
              {isAdmin && (
                <Hover>
                  <IconButton
                    onClick={() => {
                      setIsDialogOpen(true)
                      setSelectedFeatureFlag(featureFlag)
                    }}
                    data-cy={`edit-${_id}`}
                  >
                    <EditOutlined />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      setDeleteDialogOpen(true)
                      setSelectedFeatureFlag(featureFlag)
                    }}
                    data-cy={`delete-${_id}`}
                  >
                    <DeleteOutline color="error" />
                  </IconButton>
                </Hover>
              )}
            </Grid>
            <Button
              color={buttonText === 'ENABLED' ? 'primary' : 'secondary'}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              disabled={featureFlag.access.access_control === ACCESS_CONTROL.NO_USERS || !canUserOptIn}
              data-cy={`feature-flag-button-status-${_id}`}
              sx={{ minWidth: 120 }}
              disableElevation
              onClick={handleButtonClick}
              endIcon={
                !(featureFlag.access.access_control === ACCESS_CONTROL.NO_USERS || !canUserOptIn) && (
                  <KeyboardArrowDown />
                )
              }
            >
              {buttonText}
            </Button>
            <Menu elevation={0} anchorEl={anchorEl} open={open} onClose={handleClose}>
              <MenuItem data-cy={`select-option-${_id}`} onClick={handleOnSelectChange} disableRipple>
                {buttonText === 'DISABLED' ? 'ENABLED' : 'DISABLED'}
              </MenuItem>
            </Menu>
          </Stack>
          <Typography data-cy="feature-flag-unique-id" variant="caption">
            ({_id})
          </Typography>
          <Typography color="text.secondary">{description}</Typography>
        </CardContent>
      </SCard>
    </Grid>
  )
}

export default FeatureFlag
