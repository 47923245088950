import React, { useEffect, useState } from 'react'
import { Grid, Toolbar, Typography, Box, Button } from '@mui/material'
import { Add } from '@mui/icons-material'
import { createErrMsg } from 'greenfield-utilities'
import { useDispatch, useSelector } from 'react-redux'
import orderBy from 'lodash/orderBy'
import DeleteDialog from '../../../shared/Dialog/DeleteDialog'
import { displayServiceErrorMessage } from '../../../../ducks/layout'
import { getUserAccess } from '../../../../ducks/user'
import {
  clearFFDelete,
  clearFFOptInUpdate,
  deleteFeatureFlag,
  getFeatureFlags,
  updateFeatureFlagOptIn,
} from '../../../../ducks/featureFlag'
import FeatureFlagAddEditDialog from './FeatureFlagAddEditDialog'
import FeatureFlag from './FeatureFlag'

export const ACCESS_CONTROL = { ALL_USERS: 'all_users', RESTRICTED: 'restricted', NO_USERS: 'no_users' }

const BetaFeatures = props => {
  const { setHeaderTitle } = props

  const [featureFlags, setFeatureFlags] = useState([])
  const [isDialogOpen, setIsDialogOpen] = useState(false) // add/edit
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false) // delete
  const [selectedFeatureFlag, setSelectedFeatureFlag] = useState({})

  const dispatch = useDispatch()
  const { userType, userAccessStatus } = useSelector(state => state.user)
  const { fetchfeatureFlagsStatus, featureFlagOptInStatus, deleteFeatureFlagStatus } = useSelector(
    state => state.featureFlag
  )

  const isAdmin = userType?.data?.is_admin === 'yes' || false

  const handleDialogOpen = () => {
    setIsDialogOpen(true)
  }

  const handleEditDialogClose = () => {
    setIsDialogOpen(false)
    setSelectedFeatureFlag('')
  }

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false)
    setSelectedFeatureFlag('')
    dispatch(clearFFDelete())
  }

  const handleDeleteFF = featureFlag => {
    setSelectedFeatureFlag(featureFlag)
    dispatch(deleteFeatureFlag(featureFlag._id))
  }

  const handleOptInOptOut = featureFlag => {
    const isUserAccessEnabled = (userAccessStatus?.data && userAccessStatus.data[featureFlag._id]) || false
    const method = isUserAccessEnabled ? 'DELETE' : 'POST'
    dispatch(updateFeatureFlagOptIn({ id: featureFlag._id, method }))
  }

  useEffect(() => {
    setHeaderTitle('βeta Features')
    dispatch(getFeatureFlags())
  }, [])

  useEffect(() => {
    if (fetchfeatureFlagsStatus?.data) {
      const featureFlagsData = orderBy(fetchfeatureFlagsStatus.data, [data => data.label.toLowerCase()], 'asc')
      setFeatureFlags(featureFlagsData)
    } else if (fetchfeatureFlagsStatus?.status === 'failed') {
      dispatch(displayServiceErrorMessage(createErrMsg(fetchfeatureFlagsStatus, 'Feature flag get request failed!')))
    }
  }, [fetchfeatureFlagsStatus])

  useEffect(() => {
    if (deleteFeatureFlagStatus?.status === 204) {
      dispatch(getFeatureFlags())
      handleDeleteDialogClose()
    } else if (deleteFeatureFlagStatus?.status === 'failed') {
      dispatch(
        displayServiceErrorMessage(createErrMsg(deleteFeatureFlagStatus || 'Delete Feature falg request failed'))
      )
      handleDeleteDialogClose()
    }
  }, [deleteFeatureFlagStatus])

  useEffect(() => {
    if (featureFlagOptInStatus?.data) {
      dispatch(getUserAccess())
      dispatch(clearFFOptInUpdate())
      dispatch(getFeatureFlags())
      setSelectedFeatureFlag('')
    } else if (featureFlagOptInStatus?.status === 'failed') {
      dispatch(
        displayServiceErrorMessage(createErrMsg(featureFlagOptInStatus, 'Feature flag opt-in/opt-out request failed!'))
      )
      dispatch(clearFFOptInUpdate())
      setSelectedFeatureFlag('')
    }
  }, [featureFlagOptInStatus])

  return (
    <>
      <Toolbar classes={{ root: 'toolbar' }}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Typography classes={{ root: 'toolbar-title' }} tabIndex="0" aria-label="beta features">
            βeta Features
          </Typography>
          {isAdmin && (
            <Button color="secondary" data-cy="feature-flag-add" startIcon={<Add />} onClick={handleDialogOpen}>
              Add Feature Flag
            </Button>
          )}
        </Grid>
      </Toolbar>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container justifyContent="space-between">
          {featureFlags.map(featureFlag => {
            return (
              <FeatureFlag
                featureFlag={featureFlag}
                key={featureFlag._id}
                isAdmin={isAdmin}
                setIsDialogOpen={setIsDialogOpen}
                setDeleteDialogOpen={setDeleteDialogOpen}
                setSelectedFeatureFlag={setSelectedFeatureFlag}
                handleOptInOptOut={handleOptInOptOut}
                {...props}
              />
            )
          })}
        </Grid>
      </Box>
      {isDialogOpen && (
        <FeatureFlagAddEditDialog
          featureFlag={selectedFeatureFlag}
          isDialogOpen={isDialogOpen}
          handleDialogClose={handleEditDialogClose}
          {...props}
        />
      )}
      {deleteDialogOpen && (
        <DeleteDialog
          open={deleteDialogOpen}
          handleClose={handleDeleteDialogClose}
          handleDelete={() => handleDeleteFF(selectedFeatureFlag)}
          asset="Feature flag"
          name={selectedFeatureFlag._id}
        />
      )}
    </>
  )
}

export default BetaFeatures
