import { takeEvery, takeLatest } from 'redux-saga/effects'
import {
  fetchAdminSidebarInfo,
  ADMINSIDEBAR_FETCH_REQUESTED,
  fetchAdminDetail,
  ADMINDETAIL_FETCH_REQUESTED,
  fetchGrouplist,
  ADMINGROUPLIST_FETCH_REQUESTED,
  sendAddGroups,
  ADMINADDGROUPS_FETCH_REQUESTED,
  sendRemoveGroups,
  ADMINREMOVEGROUPS_FETCH_REQUESTED,
  sendCreateNewGroup,
  ADMINCREATENEWGROUP_REQUESTED,
  sendEditGroup,
  ADMIN_EDIT_GROUP_REQUESTED,
  fetchDefaultOwner,
  ADMIN_DEFAULTOWNER_FETCH_REQUESTED,
  fetchAutoSuggest,
  ADMIN_AUTO_SUGGEST_FETCH_REQUESTED,
  sendAddMemberToGroup,
  ADMIN_ADD_MEMBERS_GROUP_REQUESTED,
  sendDeleteMemberFromGroup,
  ADMIN_DELETE_MEMBERS_GROUP_REQUESTED,
  sendAddGroupProps,
  ADMIN_ADD_GROUP_PROPERTIES_REQUESTED,
  sendRemoveGroupProps,
  ADMIN_REMOVE_GROUP_PROPERTIES_REQUESTED,
  sendDeleteGroup,
  ADMIN_DELETE_GROUP_REQUESTED,
  sendAddAdGroup,
  ADMIN_ADD_AD_GROUP_REQUESTED,
} from './ducks/admin'

import {
  fetchDatasetList,
  DATASETLIST_FETCH_REQUESTED,
  searchDatasetList,
  SEARCH_DATASETLIST_FETCH_REQUESTED,
  fetchDatasetDetail,
  DATASETDETAIL_FETCH_REQUESTED,
  deleteDataset,
  DATASET_DELETE_REQUESTED,
  fetchDatasetPreview,
  DATASET_PREVIEW_REQUESTED,
  fetchDatasetCalcFields,
  DATASET_CALCFIELD_FETCH_REQUESTED,
  deleteCalculatedField,
  CALCFIELD_DELETE_REQUESTED,
  updateDataset,
  DATASET_EDIT_REQUESTED,
  fetchDatastoresList,
  DATASET_PATCH_REQUESTED,
  editDataset,
  DATASTORES_FETCH_REQUESTED,
  fetchDatasetRelatedToDatastore,
  DATASTORE_DATASETS_FETCH_REQUESTED,
  sendRefreshDataset,
  REFRESH_DATASETS_FETCH_REQUESTED,
  fetchFavoriteDatasetList,
  FAVORITE_DATASETLIST_FETCH_REQUESTED,
  fetchDatasetCalcFieldsCount,
  DATASET_CALC_FIELD_COUNT_REQUESTED,
  updateDatasetMultipleDates,
  DATASET_REFRESH_MULTIPLE_DATES_REQUESTED,
  updateDisableMultipleDates,
  DATASET_DISABLE_MULTIPLE_DATES_REQUESTED,
} from './ducks/dataset'

import {
  FAVORITECARDS_FETCH_REQUESTED,
  fetchFavoriteCards,
  FAVORITEDASHBOARDS_FETCH_REQUESTED,
  fetchFavoriteDashboards,
  FAVORITEDATASETS_FETCH_REQUESTED,
  fetchFavoriteDatasets,
} from './ducks/favorites'

import {
  renderCard,
  RENDERCARD_FETCH_REQUESTED,
  fetchCards,
  CARDS_FETCH_REQUESTED,
  fetchCardMeta,
  CARDMETA_FETCH_REQUESTED,
  CARDMETA_ONLY_FETCH_REQUESTED,
  fetchDrillCardMeta,
  DRILLCARD_CARDMETA_FETCH_REQUESTED,
  fetchCardList,
  CARDLIST_FETCH_REQUESTED,
  fetchCardDetail,
  SEARCH_CARDLIST_FETCH_REQUESTED,
  fetchSearchCardList,
  CARDDETAIL_FETCH_REQUESTED,
  deleteCard,
  CARD_DELETE_REQUESTED,
  CARD_RESTORE_REQUESTED,
  sendSaveCardFromViewer,
  SAVE_CARD_REQUESTED,
  SMART_EXPORT_REQUESTED,
  smartExportExecuteCard,
  restoreCardMeta,
  CLEAR_RENDER_COUNT,
  resetCardRenderCount,
  FAVORITE_CARDLIST_FETCH_REQUESTED,
  fetchFavoriteCardList,
  DRILLCARD_RESTORE_REQUESTED,
  restoreDrillCardMeta,
} from './ducks/cards'

import {
  fetchCardsDatasets,
  CARDSDATASETS_FETCH_REQUESTED,
  fetchCardsDataset,
  CARDSDATASET_FETCH_REQUESTED,
  sendSaveCard,
  SAVECARD_REQUESTED,
  sendSaveAsCard,
  SAVE_AS_CARD_REQUESTED,
  sendSaveDashboard,
  SAVEDASHBOARD_REQUESTED,
  fetchDetailsBusinessAreas,
  DETAILSBUSINESSAREAS_REQUESTED,
  fetchDetailsOwners,
  DETAILSOWNERS_REQUESTED,
  fetchDimensionValueList,
  DATASET_CARD_FILTER_FETCH_REQUESTED,
  fetchBusinessAreaTags,
  BUSINESSAREA_TAGS_FETCH_REQUESTED,
  validateBusinessAreaTags,
  VALIDATE_BUSINESS_AREA_REQUESTED,
  fetchDimensionValueListForDatasetFilters,
  DATASET_FILTER_FETCH_REQUESTED,
} from './ducks/builder'

import {
  fetchViewDashboardInfo,
  VIEWDASHBOARDINFO_FETCH_REQUESTED,
  fetchDashboardsList,
  DASHBOARDINFO_FETCH_REQUESTED,
  deleteDashboard,
  DASHBOARD_DELETE_REQUESTED,
  fetchSearchDashboardList,
  SEARCH_DASHBOARDLIST_FETCH_REQUESTED,
  DASHBOARD_RESTORE_REQUESTED,
  restoreDashboardMeta,
  FAVORITE_DASHBOARDLIST_FETCH_REQUESTED,
  fetchFavoriteDashboardsList,
} from './ducks/dashboard'

import {
  USERUPDATEFAVORITE_FETCH_REQUESTED,
  sendUserFavorite,
  UPDATECURRENTUSER_FETCH_REQUESTED,
  sendCurrentUser,
  FETCHUSERTYPE_REQUESTED,
  getUserType,
  setActAsAdmin,
  UPDATE_ADMIN_OPT_IN_REQUESTED,
  GET_USER_ACCESS_REQUESTED,
  fetchUserAccess,
  FETCHUSERDASHBOARDSCOUNT_REQUESTED,
  getUserDashboardsCount,
  FETCHUSERCARDSCOUNT_REQUESTED,
  FETCH_USER_FAV_CARDS_COUNT_REQUESTED,
  FETCH_USER_FAV_DATASETS_COUNT_REQUESTED,
  FETCH_USER_FAV_DASHBOARDS_COUNT_REQUESTED,
  getUserCardsCount,
  FETCHUSERDATASETSCOUNT_REQUESTED,
  getUserDatasetsCount,
  FETCHUSERCALCFIELDSCOUNT_REQUESTED,
  getUserCalcFieldsCount,
} from './ducks/user'
import { PREVIEW_FETCH_CARD_REQUESTED, fetchPreviewCardData } from './ducks/cardPreview'

import {
  RENDERCARDFILTER_FETCH_REQUESTED,
  fetchCardRenderFilter,
  RENDERDASHBOARDFILTER_FETCH_REQUESTED,
  fetchDashboardRenderFilter,
} from './ducks/filter'

import {
  postExecuteCalculatedField,
  postCreateCalculatedField,
  updateCalculatedField,
  updateAllCalculatedFields,
  getSearchCalculatedField,
  getCalculatedFieldFnHelpReq,
  getCalculatedFieldReq,
  EXECUTE_CALCULATED_FIELD_REQUESTED,
  CREATE_CALCULATED_FIELD_REQUESTED,
  EDIT_CALCULATED_FIELD_REQUESTED,
  EDIT_ALL_CALCULATED_FIELDS_REQUESTED,
  SEARCH_CALCULATED_FIELD_REQUESTED,
  GET_CALCULATED_FIELD_FN_HELP_REQUESTED,
  GET_CALCULATED_FIELD_REQUESTED,
} from './ducks/calculatedField'

import {
  DASHBOARD_BUSINESSAREA_TAGS_REQUESTED,
  USER_DASHBOARD_BUSINESSAREA_TAGS_REQUESTED,
  SAVE_USER_QUICKLINKS_REQUESTED,
  SAVE_GROUP_QUICKLINKS_REQUESTED,
  fetchUserBusinessTags,
  fetchBusinessTags,
  updateUserBusinessTags,
  updateGroupBusinessTags,
} from './ducks/quicklinks'

import {
  SECURITYCONTROLLIST_FETCH_REQUESTED,
  SECURITYCONTROLDETAIL_FETCH_REQUESTED,
  GROUP_FETCH_REQUESTED,
  SINGLE_GROUP_REQUESTED,
  fetchSecurityControlList,
  fetchSecurityControlDetail,
  fetchGroupSearchDetail,
  fetchSingleGroupDetail,
} from './ducks/securityControl'

import {
  fetchDateFilters,
  DATEFILTERS_FETCH_REQUESTED,
  fetchDateFiltersWithAnchorDate,
  ANCHOR_DATE_FILTERS_FETCH_REQUESTED,
  fetchCustomDateFilters,
  CUSTOM_DATE_FILTERS_FETCH_REQUESTED,
} from './ducks/timeperiod'

import { fetchBasemap, FETCH_BASEMAP_REQUESTED, fetchAllBasemaps, FETCH_ALLBASEMAPS_REQUESTED } from './ducks/maps'
import {
  fetchMetadata,
  FETCH_METADATA_REQUESTED,
  fetchFilterMetadata,
  FETCH_FILTER_METADATA_REQUESTED,
} from './ducks/metadata'
import {
  CREATE_DATA_ALERT_REQUESTED,
  createDataSubscription,
  CREATE_DATA_ALERT_TEST_REQUESTED,
  createDataSubscriptionTest,
  CREATE_CARD_SUBSCRIPTION_REQUESTED,
  createSubscription,
  CREATE_CARD_SUBSCRIPTION_TEST_REQUESTED,
  createSubscriptionTest,
  FETCH_ALL_SUBSCRIPTIONS_REQUESTED,
  getAllSubscriptionsList,
  FETCH_ALL_SUBSCRIPTIONS_BY_CARD_REQUESTED,
  FETCH_ALL_SUBSCRIPTION_LOGS_REQUESTED,
  getSubscriptionsByCardId,
  DELETE_DATA_ALERT_REQUESTED,
  deleteDataAlertSubscription,
  DELETE_CARD_SUBSCRIPTION_REQUESTED,
  deleteCardSubscription,
  fetchAllSubscriptionLogs,
  UPDATE_OPT_OUT_SUBSCRIPTION_REQUESTED,
  updateOptOutSubscription,
} from './ducks/dataAlertAndSubscription'
import {
  CREATE_FEATURE_FLAG_REQUESTED,
  saveFeatureFlag,
  UPDATE_FEATURE_FLAG_REQUESTED,
  updateFF,
  UPDATE_FEATURE_FLAG_OPT_IN_REQUESTED,
  updateFFOptIn,
  FETCH_FEATURE_FLAGS_REQUESTED,
  fetchAllFeatureFlag,
  DELETE_FEATURE_FLAG_REQUESTED,
  removeFeatureFlag,
} from './ducks/featureFlag'
import {
  fetchNotificationInfo,
  NOTIFICATION_FETCH_REQUESTED,
  deleteNotificationInfo,
  NOTIFICATION_DELETE_REQUESTED,
  readUpdateNotificationInfo,
  NOTIFICATION_READ_UPDATE_REQUESTED,
  createNotificationInfo,
  NOTIFICATION_CREATE_REQUESTED,
  editNotificationInfo,
  NOTIFICATION_EDIT_REQUESTED,
  fetchAllNotificationInfo,
  NOTIFICATION_FETCH_ALL_REQUESTED,
  readDeleteNotificationInfo,
  NOTIFICATION_READ_DELETE_REQUESTED,
  publishNotificationInfo,
  NOTIFICATION_PUBLISH_REQUESTED,
  GET_OBJECT_NOTIFICATION_REQUESTED,
  fetchObjectNotification,
  DELETE_OBJECT_NOTIFICATION_REQUESTED,
  deleteNotificationObject,
  GET_NOTIFICATION_COUNT_REQUESTED,
  fetchNotificationCount,
  CREATE_OBJECT_NOTIFICATION_REQUESTED,
  createObjectNotification,
  GET_NOTIFICATION_LISTING_REQUESTED,
  fetchObjectListingNotifications,
  UPDATE_NOTIFICATION_REQUESTED,
  updateObjectNotification,
  OBJECT_ID_NOTIFICATION_REQUESTED,
  getObjectIdNotification,
} from './ducks/notification'

import { BUILD_GREENFIELD_SERVICES_REQUESTED, fetchBuildGreenfieldServices } from './ducks/layout'

import { fetchTrainingContent, TRAINING_CONTENT_REQUESTED } from './ducks/training'

import {
  postLargeExportJob,
  LARGE_EXPORT_CREATION_REQUESTED,
  getLargeExportJobs,
  GET_LARGE_EXPORT_JOBS_REQUESTED,
  removeLargeExportJob,
  DELETE_EXPORT_JOB_REQUESTED,
  getDownloadData,
  GET_DOWNLOAD_DATA_REQUESTED,
  getDownloadNotifyData,
  GET_DOWNLOAD_NOTIFY_DATA_REQUESTED,
  cancelLargeExportJob,
  CANCEL_JOB_REQUESTED,
  clearLargeExportCache,
  CLEAR_CACHE_REQUESTED,
} from './ducks/LargeExport'

import {
  DATASTORE_LIST_FETCH_REQUESTED,
  fetchDatastoreList,
  DATASTORE_TEST_REQUESTED,
  testDatastoreConnection,
  DATASTORE_PUT_REQUESTED,
  putDatastoreConnection,
  DATASTORE_POST_REQUESTED,
  postDatastoreConnection,
  getDatastoreDetail,
  DATASTORE_DETAIL_REQUESTED,
  DATASTORE_DELETE_REQUESTED,
  deleteDataStoreDetail,
} from './ducks/datastore'

import {
  getUserSite,
  SITE_REQUESTED,
  getAllSites,
  ALL_SITES_REQUESTED,
  updateSiteRequest,
  ADD_UPDATE_SITES_REQUESTED,
} from './ducks/site'

export default function* mainSaga() {
  // notifications
  yield takeEvery(GET_OBJECT_NOTIFICATION_REQUESTED, fetchObjectNotification)
  yield takeEvery(DELETE_OBJECT_NOTIFICATION_REQUESTED, deleteNotificationObject)
  yield takeEvery(GET_NOTIFICATION_COUNT_REQUESTED, fetchNotificationCount)
  yield takeEvery(CLEAR_RENDER_COUNT, resetCardRenderCount)
  yield takeEvery(GET_NOTIFICATION_LISTING_REQUESTED, fetchObjectListingNotifications)
  yield takeEvery(CREATE_OBJECT_NOTIFICATION_REQUESTED, createObjectNotification)
  yield takeEvery(UPDATE_NOTIFICATION_REQUESTED, updateObjectNotification)
  yield takeEvery(OBJECT_ID_NOTIFICATION_REQUESTED, getObjectIdNotification)

  yield takeEvery(SMART_EXPORT_REQUESTED, smartExportExecuteCard)

  // start Large Export

  yield takeEvery(LARGE_EXPORT_CREATION_REQUESTED, postLargeExportJob)
  yield takeEvery(GET_LARGE_EXPORT_JOBS_REQUESTED, getLargeExportJobs)
  yield takeEvery(DELETE_EXPORT_JOB_REQUESTED, removeLargeExportJob)
  yield takeEvery(GET_DOWNLOAD_DATA_REQUESTED, getDownloadData)
  yield takeEvery(GET_DOWNLOAD_NOTIFY_DATA_REQUESTED, getDownloadNotifyData)
  yield takeEvery(CANCEL_JOB_REQUESTED, cancelLargeExportJob)
  yield takeEvery(CLEAR_CACHE_REQUESTED, clearLargeExportCache)

  // end Large Export

  yield takeEvery(BUSINESSAREA_TAGS_FETCH_REQUESTED, fetchBusinessAreaTags)
  yield takeEvery(DETAILSOWNERS_REQUESTED, fetchDetailsOwners)
  yield takeEvery(DETAILSBUSINESSAREAS_REQUESTED, fetchDetailsBusinessAreas)
  yield takeEvery(VIEWDASHBOARDINFO_FETCH_REQUESTED, fetchViewDashboardInfo)
  yield takeLatest(DASHBOARDINFO_FETCH_REQUESTED, fetchDashboardsList)
  yield takeEvery(FAVORITECARDS_FETCH_REQUESTED, fetchFavoriteCards)
  yield takeEvery(FAVORITEDASHBOARDS_FETCH_REQUESTED, fetchFavoriteDashboards)
  yield takeEvery(FAVORITEDATASETS_FETCH_REQUESTED, fetchFavoriteDatasets)
  yield takeLatest(ADMINSIDEBAR_FETCH_REQUESTED, fetchAdminSidebarInfo)
  yield takeEvery(ADMINDETAIL_FETCH_REQUESTED, fetchAdminDetail)
  yield takeEvery(ADMINGROUPLIST_FETCH_REQUESTED, fetchGrouplist)
  yield takeEvery(ADMINADDGROUPS_FETCH_REQUESTED, sendAddGroups)
  yield takeEvery(ADMINREMOVEGROUPS_FETCH_REQUESTED, sendRemoveGroups)
  yield takeEvery(ADMINCREATENEWGROUP_REQUESTED, sendCreateNewGroup)
  yield takeEvery(ADMIN_EDIT_GROUP_REQUESTED, sendEditGroup)
  // yield takeEvery(PREVIEWDATASET_FETCH_REQUESTED, previewDataset)
  yield takeEvery(CARDSDATASETS_FETCH_REQUESTED, fetchCardsDatasets)
  yield takeEvery(CARDSDATASET_FETCH_REQUESTED, fetchCardsDataset)
  yield takeEvery(RENDERCARD_FETCH_REQUESTED, renderCard)
  yield takeLatest(CARDS_FETCH_REQUESTED, fetchCards)
  yield takeEvery(CARDMETA_FETCH_REQUESTED, fetchCardMeta)
  yield takeEvery(CARDMETA_ONLY_FETCH_REQUESTED, fetchCardMeta)
  yield takeEvery(DRILLCARD_CARDMETA_FETCH_REQUESTED, fetchDrillCardMeta)
  // yield takeEvery(DATASETS_FETCH_REQUESTED, datasetsInfo)
  yield takeEvery(USERUPDATEFAVORITE_FETCH_REQUESTED, sendUserFavorite)
  yield takeEvery(FETCHUSERTYPE_REQUESTED, getUserType)
  yield takeEvery(UPDATE_ADMIN_OPT_IN_REQUESTED, setActAsAdmin)
  yield takeEvery(GET_USER_ACCESS_REQUESTED, fetchUserAccess)
  yield takeEvery(FETCHUSERDASHBOARDSCOUNT_REQUESTED, getUserDashboardsCount)
  yield takeEvery(FETCHUSERCARDSCOUNT_REQUESTED, getUserCardsCount)
  yield takeEvery(FETCH_USER_FAV_CARDS_COUNT_REQUESTED, getUserCardsCount)
  yield takeEvery(FETCH_USER_FAV_DASHBOARDS_COUNT_REQUESTED, getUserDashboardsCount)
  yield takeEvery(FETCH_USER_FAV_DATASETS_COUNT_REQUESTED, getUserDatasetsCount)
  yield takeEvery(FETCHUSERDATASETSCOUNT_REQUESTED, getUserDatasetsCount)
  yield takeEvery(FETCHUSERCALCFIELDSCOUNT_REQUESTED, getUserCalcFieldsCount)
  yield takeEvery(PREVIEW_FETCH_CARD_REQUESTED, fetchPreviewCardData)
  yield takeLatest(RENDERCARDFILTER_FETCH_REQUESTED, fetchCardRenderFilter)
  yield takeLatest(RENDERDASHBOARDFILTER_FETCH_REQUESTED, fetchDashboardRenderFilter)
  yield takeEvery(ADMIN_DEFAULTOWNER_FETCH_REQUESTED, fetchDefaultOwner)
  yield takeEvery(ADMIN_AUTO_SUGGEST_FETCH_REQUESTED, fetchAutoSuggest)
  yield takeEvery(ADMIN_ADD_MEMBERS_GROUP_REQUESTED, sendAddMemberToGroup)
  yield takeEvery(ADMIN_DELETE_MEMBERS_GROUP_REQUESTED, sendDeleteMemberFromGroup)
  yield takeEvery(ADMIN_ADD_AD_GROUP_REQUESTED, sendAddAdGroup)
  yield takeEvery(ADMIN_ADD_GROUP_PROPERTIES_REQUESTED, sendAddGroupProps)
  yield takeEvery(ADMIN_REMOVE_GROUP_PROPERTIES_REQUESTED, sendRemoveGroupProps)
  yield takeEvery(ADMIN_DELETE_GROUP_REQUESTED, sendDeleteGroup)
  yield takeEvery(SAVECARD_REQUESTED, sendSaveCard)
  yield takeEvery(SAVE_AS_CARD_REQUESTED, sendSaveAsCard)
  yield takeEvery(SAVEDASHBOARD_REQUESTED, sendSaveDashboard)
  yield takeEvery(UPDATECURRENTUSER_FETCH_REQUESTED, sendCurrentUser)
  yield takeEvery(DASHBOARD_DELETE_REQUESTED, deleteDashboard)
  yield takeEvery(EXECUTE_CALCULATED_FIELD_REQUESTED, postExecuteCalculatedField)
  yield takeEvery(CREATE_CALCULATED_FIELD_REQUESTED, postCreateCalculatedField)
  yield takeEvery(EDIT_CALCULATED_FIELD_REQUESTED, updateCalculatedField)
  yield takeEvery(EDIT_ALL_CALCULATED_FIELDS_REQUESTED, updateAllCalculatedFields)
  yield takeEvery(SEARCH_CALCULATED_FIELD_REQUESTED, getSearchCalculatedField)
  yield takeEvery(GET_CALCULATED_FIELD_FN_HELP_REQUESTED, getCalculatedFieldFnHelpReq)
  yield takeLatest(CARDLIST_FETCH_REQUESTED, fetchCardList)
  yield takeLatest(SEARCH_CARDLIST_FETCH_REQUESTED, fetchSearchCardList)
  yield takeEvery(CARDDETAIL_FETCH_REQUESTED, fetchCardDetail)
  yield takeEvery(CARD_DELETE_REQUESTED, deleteCard)
  yield takeLatest(CARD_RESTORE_REQUESTED, restoreCardMeta)
  yield takeLatest(DASHBOARD_RESTORE_REQUESTED, restoreDashboardMeta)
  yield takeLatest(DASHBOARD_BUSINESSAREA_TAGS_REQUESTED, fetchBusinessTags)
  yield takeEvery(SEARCH_DASHBOARDLIST_FETCH_REQUESTED, fetchSearchDashboardList)
  yield takeEvery(USER_DASHBOARD_BUSINESSAREA_TAGS_REQUESTED, fetchUserBusinessTags)
  yield takeEvery(SAVE_USER_QUICKLINKS_REQUESTED, updateUserBusinessTags)
  yield takeLatest(DATASETLIST_FETCH_REQUESTED, fetchDatasetList)
  yield takeLatest(SEARCH_DATASETLIST_FETCH_REQUESTED, searchDatasetList)
  yield takeEvery(DATASETDETAIL_FETCH_REQUESTED, fetchDatasetDetail)
  yield takeEvery(DATASET_DELETE_REQUESTED, deleteDataset)
  yield takeEvery(DATASET_CARD_FILTER_FETCH_REQUESTED, fetchDimensionValueList)
  yield takeEvery(DATASET_FILTER_FETCH_REQUESTED, fetchDimensionValueListForDatasetFilters)
  yield takeEvery(DATASET_PREVIEW_REQUESTED, fetchDatasetPreview)
  yield takeEvery(SAVE_CARD_REQUESTED, sendSaveCardFromViewer)
  yield takeEvery(DATEFILTERS_FETCH_REQUESTED, fetchDateFilters)
  yield takeLatest(ANCHOR_DATE_FILTERS_FETCH_REQUESTED, fetchDateFiltersWithAnchorDate)
  yield takeLatest(CUSTOM_DATE_FILTERS_FETCH_REQUESTED, fetchCustomDateFilters)
  yield takeLatest(DATASET_CALCFIELD_FETCH_REQUESTED, fetchDatasetCalcFields)
  yield takeEvery(CALCFIELD_DELETE_REQUESTED, deleteCalculatedField)
  yield takeEvery(DATASET_EDIT_REQUESTED, updateDataset)
  yield takeEvery(DATASET_PATCH_REQUESTED, editDataset)
  yield takeEvery(DATASTORES_FETCH_REQUESTED, fetchDatastoresList)
  yield takeEvery(SECURITYCONTROLLIST_FETCH_REQUESTED, fetchSecurityControlList)
  yield takeEvery(SECURITYCONTROLDETAIL_FETCH_REQUESTED, fetchSecurityControlDetail)
  yield takeEvery(GROUP_FETCH_REQUESTED, fetchGroupSearchDetail)
  yield takeEvery(SINGLE_GROUP_REQUESTED, fetchSingleGroupDetail)
  yield takeEvery(BUILD_GREENFIELD_SERVICES_REQUESTED, fetchBuildGreenfieldServices)
  yield takeLatest(DATASTORE_DATASETS_FETCH_REQUESTED, fetchDatasetRelatedToDatastore)
  yield takeLatest(REFRESH_DATASETS_FETCH_REQUESTED, sendRefreshDataset)
  yield takeLatest(DATASET_CALC_FIELD_COUNT_REQUESTED, fetchDatasetCalcFieldsCount)
  yield takeLatest(DATASET_REFRESH_MULTIPLE_DATES_REQUESTED, updateDatasetMultipleDates)
  yield takeLatest(DATASET_DISABLE_MULTIPLE_DATES_REQUESTED, updateDisableMultipleDates)
  yield takeLatest(SAVE_GROUP_QUICKLINKS_REQUESTED, updateGroupBusinessTags)
  yield takeEvery(FETCH_BASEMAP_REQUESTED, fetchBasemap)
  yield takeEvery(FETCH_ALLBASEMAPS_REQUESTED, fetchAllBasemaps)
  yield takeLatest(FETCH_METADATA_REQUESTED, fetchMetadata)
  yield takeLatest(FETCH_FILTER_METADATA_REQUESTED, fetchFilterMetadata)
  yield takeLatest(NOTIFICATION_FETCH_REQUESTED, fetchNotificationInfo)
  yield takeEvery(NOTIFICATION_DELETE_REQUESTED, deleteNotificationInfo)
  yield takeEvery(NOTIFICATION_READ_UPDATE_REQUESTED, readUpdateNotificationInfo)
  yield takeEvery(GET_CALCULATED_FIELD_REQUESTED, getCalculatedFieldReq)
  yield takeEvery(TRAINING_CONTENT_REQUESTED, fetchTrainingContent)
  yield takeEvery(NOTIFICATION_CREATE_REQUESTED, createNotificationInfo)
  yield takeEvery(CREATE_DATA_ALERT_REQUESTED, createDataSubscription)
  yield takeEvery(CREATE_DATA_ALERT_TEST_REQUESTED, createDataSubscriptionTest)
  yield takeEvery(CREATE_CARD_SUBSCRIPTION_REQUESTED, createSubscription)
  yield takeEvery(CREATE_CARD_SUBSCRIPTION_TEST_REQUESTED, createSubscriptionTest)
  yield takeEvery(FETCH_ALL_SUBSCRIPTIONS_REQUESTED, getAllSubscriptionsList)
  yield takeEvery(FETCH_ALL_SUBSCRIPTIONS_BY_CARD_REQUESTED, getSubscriptionsByCardId)
  yield takeEvery(FETCH_ALL_SUBSCRIPTION_LOGS_REQUESTED, fetchAllSubscriptionLogs)
  yield takeEvery(DELETE_DATA_ALERT_REQUESTED, deleteDataAlertSubscription)
  yield takeEvery(DELETE_CARD_SUBSCRIPTION_REQUESTED, deleteCardSubscription)
  yield takeEvery(UPDATE_OPT_OUT_SUBSCRIPTION_REQUESTED, updateOptOutSubscription)
  yield takeEvery(NOTIFICATION_FETCH_ALL_REQUESTED, fetchAllNotificationInfo)
  yield takeEvery(NOTIFICATION_READ_DELETE_REQUESTED, readDeleteNotificationInfo)
  yield takeEvery(NOTIFICATION_PUBLISH_REQUESTED, publishNotificationInfo)
  yield takeEvery(NOTIFICATION_EDIT_REQUESTED, editNotificationInfo)
  yield takeLatest(FAVORITE_CARDLIST_FETCH_REQUESTED, fetchFavoriteCardList)
  yield takeLatest(FAVORITE_DASHBOARDLIST_FETCH_REQUESTED, fetchFavoriteDashboardsList)
  yield takeLatest(FAVORITE_DATASETLIST_FETCH_REQUESTED, fetchFavoriteDatasetList)
  yield takeEvery(DATASTORE_LIST_FETCH_REQUESTED, fetchDatastoreList)
  yield takeLatest(DATASTORE_TEST_REQUESTED, testDatastoreConnection)
  yield takeEvery(DATASTORE_POST_REQUESTED, postDatastoreConnection)
  yield takeEvery(DATASTORE_PUT_REQUESTED, putDatastoreConnection)
  yield takeEvery(DATASTORE_DETAIL_REQUESTED, getDatastoreDetail)
  yield takeEvery(DATASTORE_DELETE_REQUESTED, deleteDataStoreDetail)
  yield takeEvery(SITE_REQUESTED, getUserSite)
  yield takeLatest(ALL_SITES_REQUESTED, getAllSites)
  yield takeLatest(ADD_UPDATE_SITES_REQUESTED, updateSiteRequest)
  yield takeEvery(VALIDATE_BUSINESS_AREA_REQUESTED, validateBusinessAreaTags)
  yield takeEvery(DRILLCARD_RESTORE_REQUESTED, restoreDrillCardMeta)
  // Beta Features
  yield takeEvery(CREATE_FEATURE_FLAG_REQUESTED, saveFeatureFlag)
  yield takeEvery(UPDATE_FEATURE_FLAG_REQUESTED, updateFF)
  yield takeEvery(UPDATE_FEATURE_FLAG_OPT_IN_REQUESTED, updateFFOptIn)
  yield takeEvery(FETCH_FEATURE_FLAGS_REQUESTED, fetchAllFeatureFlag)
  yield takeEvery(DELETE_FEATURE_FLAG_REQUESTED, removeFeatureFlag)
}
