import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Route } from 'react-router-dom'
import { Tabs, Tab, Icon, Typography } from '@mui/material'
import SwipeableViews from 'react-swipeable-views'
import styled from 'styled-components'
import { Spinner, createErrMsg } from 'greenfield-utilities'
import SiteCards from './SiteCards'
import SiteDashboards from './SiteDashboards'
import SiteDatasets from './SiteDatasets'
import SiteHome from './SiteHome'

const SSpinner = styled(Spinner)`
  margin-top: 50px;
`
const Site = ({ routeProps = {}, isMobile = false, deviceType, setHeaderTitle = () => {}, siteObj = {} }) => {
  const routeIndex = id => {
    if (id === 'home') {
      return 0
    } else if (id === 'site-cards') {
      return 1
    } else if (id === 'site-dashboards') {
      return 2
    } else if (id === 'site-datasets') {
      return 3
    }
  }

  const [activeTab, setActiveTab] = useState(routeIndex(routeProps.match.params._tabName))
  const { params } = routeProps.match

  useEffect(() => {
    setActiveTab(routeIndex(params._tabName))
  }, [activeTab])

  const handleTabChange = (event, activeTab) => {
    setActiveTab(activeTab)
    const route = (() => {
      switch (activeTab) {
        case 0:
          return 'home'
        case 1:
          return 'site-cards'
        case 2:
          return 'site-dashboards'
        case 3:
          return 'site-datasets'
      }
    })()

    routeProps.history.push(`/site/${params._siteName}/${route}`)
  }

  if (siteObj.status === 'requested') {
    return <SSpinner layout="selfCentering" size="large" />
  } else if (siteObj.status === 'failed') {
    return (
      <div className="site-error">
        <Icon>not_interested</Icon>
        <Typography>
          {createErrMsg(siteObj, 'Something went wrong with the request to get your home dashboard.')}
        </Typography>
      </div>
    )
  } else {
    return (
      <div className="homePage">
        <Tabs
          indicatorColor="primary"
          textColor="primary"
          value={activeTab}
          onChange={handleTabChange}
          className="homePageTabBar"
        >
          <Tab label="Home" data-cy="site-home" />
          <Tab label="Cards" data-cy="site-cards" />
          <Tab label="Dashboards" data-cy="site-dashboards" />
          {!isMobile && <Tab label="Datasets" data-cy="site-datasets" />}
        </Tabs>
        <SwipeableViews index={activeTab} className="fullHeight">
          <Route
            path={`/site/${params._siteName}/home/`}
            render={() => (
              <SiteHome
                isPrintLandscape={false}
                routeProps={routeProps}
                setHeaderTitle={setHeaderTitle}
                isMobile={isMobile}
                deviceType={deviceType}
                siteObj={siteObj}
              />
            )}
          />
          <Route
            path={`/site/${params._siteName}/site-cards/`}
            render={() => (
              <SiteCards
                setHeaderTitle={setHeaderTitle}
                isMobile={isMobile}
                deviceType={deviceType}
                siteObj={siteObj}
                routeProps={routeProps}
              />
            )}
          />
          <Route
            path={`/site/${params._siteName}/site-dashboards/`}
            render={() => (
              <SiteDashboards
                setHeaderTitle={setHeaderTitle}
                isMobile={isMobile}
                siteObj={siteObj}
                routeProps={routeProps}
              />
            )}
          />
          <Route
            path={`/site/${params._siteName}/site-datasets/`}
            render={() => (
              <SiteDatasets
                setHeaderTitle={setHeaderTitle}
                isMobile={isMobile}
                siteObj={siteObj}
                routeProps={routeProps}
              />
            )}
          />
        </SwipeableViews>
      </div>
    )
  }
}
Site.propTypes = {
  routeProps: PropTypes.object,
  setHeaderTitle: PropTypes.func,
  siteObj: PropTypes.object,
  isMobile: PropTypes.bool,
  deviceType: PropTypes.array,
}
export default Site
