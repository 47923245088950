import React from 'react'
import { CardContent, Typography, Icon } from '@mui/material'
import { createErrMsg } from 'greenfield-utilities'
import { Link } from 'react-router-dom'

const AccessDeniedError = ({ errorMessage = '', fallBackErrorMessage = '', ownerAccess = [] }) => {
  return (
    <CardContent className="failMessage">
      <Icon className="notFoundIcon">not_interested</Icon>
      <Typography>{createErrMsg(errorMessage, fallBackErrorMessage)}</Typography>
      <Typography>
        {ownerAccess?.map((item, key) =>
          item.type === 'group' ? (
            <Link key={key} className="link-text" to={`/admin/groups/${item._id}/members`}>
              {key === ownerAccess.length - 1 ? `${item.user_group_name}` : `${item.user_group_name}, `}
            </Link>
          ) : (
            <Typography key={key} color="inherit" component="span" variant="subtitle2">
              {key === ownerAccess.length - 1 ? `${item.user_group_name}` : `${item.user_group_name}, `}
            </Typography>
          )
        )}
      </Typography>
    </CardContent>
  )
}

export default AccessDeniedError
