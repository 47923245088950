import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { getFavoriteCards } from '../../../ducks/favorites'
import { displayServiceErrorMessage } from '../../../ducks/layout'
import FavoritesCards from './FavoritesCards'

export class FavoritesCardsContainer extends React.Component {
  componentDidMount() {
    const { setHeaderTitle, getFavoriteCards } = this.props

    setHeaderTitle('Favorite Cards')
    getFavoriteCards()
  }

  render() {
    const { isMobile, deviceType, routeProps, favoriteCardsStatus, displayServiceErrorMessage } = this.props

    return (
      <FavoritesCards
        favoriteCardInfo={favoriteCardsStatus}
        displayServiceErrorMessage={displayServiceErrorMessage}
        isMobile={isMobile}
        deviceType={deviceType}
        routeProps={routeProps}
      />
    )
  }
}

const mapStateToProps = state => ({
  favoriteCardsStatus: state.favorites.favoriteCardsStatus,
})

export const mapDispatchToProps = dispatch => ({
  getFavoriteCards(data) {
    dispatch(getFavoriteCards(data))
  },
  displayServiceErrorMessage(data) {
    dispatch(displayServiceErrorMessage(data))
  },
})

FavoritesCardsContainer.defaultProps = {
  favoriteCardsStatus: {},
}

FavoritesCardsContainer.propTypes = {
  getFavoriteCards: PropTypes.func,
  favoriteCardsStatus: PropTypes.object,
  displayServiceErrorMessage: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps)(FavoritesCardsContainer)
