import { call, put } from 'redux-saga/effects'
import axios from 'axios'
import cloneDeep from 'lodash/cloneDeep'
import { API_GATEWAY_URL, formatDatasetFields } from './utils'

// Action types
export const CARDSDATASETS_FETCH_REQUESTED = 'CARDSDATASETS_FETCH_REQUESTED'
export const CARDSDATASETS_FETCH_SUCCEEDED = 'CARDSDATASETS_FETCH_SUCCEEDED'
export const CARDSDATASETS_FETCH_FAILED = 'CARDSDATASETS_FETCH_FAILED'

export const CARDSDATASET_FETCH_REQUESTED = 'CARDSDATASET_FETCH_REQUESTED'
export const CARDSDATASET_FETCH_SUCCEEDED = 'CARDSDATASET_FETCH_SUCCEEDED'
export const CARDSDATASET_FETCH_FAILED = 'CARDSDATASET_FETCH_FAILED'

export const SAVECARD_REQUESTED = 'SAVECARD_REQUESTED'
export const SAVECARD_SUCCEEDED = 'SAVECARD_SUCCEEDED'
export const SAVE_AS_CARD_REQUESTED = 'SAVE_AS_CARD_REQUESTED'
export const SAVE_AS_CARD_SUCCEEDED = 'SAVE_AS_CARD_SUCCEEDED'
export const SAVECARD_FAILED = 'SAVECARD_FAILED'

export const CLEAR_SAVECARDS_AND_SAVEDASHBOARD = 'CLEAR_SAVECARDS_AND_SAVEDASHBOARD'
export const CLEAR_DETAIL_BUSINESS_AREAS = 'CLEAR_DETAIL_BUSINESS_AREAS'

export const SAVEDASHBOARD_REQUESTED = 'SAVEDASHBOARD_REQUESTED'
export const SAVEDASHBOARD_SUCCEEDED = 'SAVEDASHBOARD_SUCCEEDED'
export const SAVEDASHBOARD_FAILED = 'SAVEDASHBOARD_FAILED'

export const DETAILSBUSINESSAREAS_REQUESTED = 'DETAILSBUSINESSAREAS_REQUESTED'
export const DETAILSBUSINESSAREAS_SUCCEEDED = 'DETAILSBUSINESSAREAS_SUCCEEDED'
export const DETAILSBUSINESSAREAS_FAILED = 'DETAILSBUSINESSAREAS_FAILED'

export const DETAILSOWNERS_REQUESTED = 'DETAILSOWNERS_REQUESTED'
export const DETAILSOWNERS_SUCCEEDED = 'DETAILSOWNERS_SUCCEEDED'
export const DETAILSOWNERS_FAILED = 'DETAILSOWNERS_FAILED'

export const CARD_DATASET_ID_REQUEST = 'UPDATE_DATASET_ID_REQUEST'
export const CARD_DATASET_ID_UPDATED = 'UPDATE_DATASET_ID_UPDATED'

export const DATASET_CARD_FILTER_FETCH_REQUESTED = 'DATASET_CARD_FILTER_FETCH_REQUESTED'
export const DATASET_CARD_FILTER_FETCH_SUCCEEDED = 'DATASET_CARD_FILTER_FETCH_SUCCEEDED'
export const DATASET_CARD_FILTER_FETCH_FAILED = 'DATASET_CARD_FILTER_FETCH_FAILED'

export const DATASET_FILTER_FETCH_REQUESTED = 'DATASET_FILTER_FETCH_REQUESTED'
export const DATASET_FILTER_FETCH_SUCCEEDED = 'DATASET_FILTER_FETCH_SUCCEEDED'
export const DATASET_FILTER_FETCH_FAILED = 'DATASET_FILTER_FETCH_FAILED'

export const BUSINESSAREA_TAGS_FETCH_REQUESTED = 'BUSINESSAREA_TAGS_FETCH_REQUESTED'
export const BUSINESSAREA_TAGS_FETCH_SUCCEEDED = 'BUSINESSAREA_TAGS_FETCH_SUCCEEDED'
export const BUSINESSAREA_TAGS_FETCH_FAILED = 'BUSINESSAREA_TAGS_FETCH_FAILED'

export const CARD_NEEDS_MORE_REQUIREMENTS_UPDATE = 'CARD_NEEDS_MORE_REQUIREMENTS_UPDATE'

export const BUILDER_AUTOUPDATE_UPDATE = 'BUILDER_AUTOUPDATE_UPDATE'
export const BUILDER_CARDISEDITED_UPDATE = 'BUILDER_CARDISEDITED_UPDATE'
export const BUILDER_OVERWRITE_AUTOUPDATE_UPDATE = 'BUILDER_OVERWRITE_AUTOUPDATE_UPDATE'

export const DETAILSOWNERS_CLEAR_STATE = 'DETAILSOWNERS_CLEAR_STATE'

export const VALIDATE_BUSINESS_AREA_REQUESTED = 'VALIDATE_BUSINESS_AREA_REQUESTED'
export const VALIDATE_BUSINESS_AREA_SUCCEEDED = 'VALIDATE_BUSINESS_AREA_SUCCEEDED'
export const VALIDATE_BUSINESS_AREA_FAILED = 'VALIDATE_BUSINESS_AREA_FAILED'
export const CLEAR_BUSINESS_AREA_TAG = 'CLEAR_BUSINESS_AREA_TAG'
export const UPDATE_BUILDER_CLICK_COUNT = 'UPDATE_BUILDER_CLICK_COUNT'

// Reducer
export default function cardsReducer(state = { builderCardIsEditedStatus: false }, action = {}) {
  switch (action.type) {
    case BUSINESSAREA_TAGS_FETCH_REQUESTED: {
      const newState = {
        ...state,
        businessAreaTagsStatus: {
          status: 'requested',
        },
      }
      return newState
    }
    case BUSINESSAREA_TAGS_FETCH_SUCCEEDED: {
      const newState = {
        ...state,
        businessAreaTagsStatus: action.payload,
      }
      return newState
    }
    case BUSINESSAREA_TAGS_FETCH_FAILED: {
      const newState = {
        ...state,
        businessAreaTagsStatus: {
          status: 'failed',
        },
      }
      return newState
    }
    case DETAILSOWNERS_REQUESTED: {
      const newState = {
        ...state,
        detailsOwnersStatus: {
          status: 'requested',
        },
      }
      return newState
    }
    case DETAILSOWNERS_SUCCEEDED: {
      const newState = {
        ...state,
        detailsOwnersStatus: action.payload,
      }
      return newState
    }
    case DETAILSOWNERS_FAILED: {
      const newState = {
        ...state,
        detailsOwnersStatus: {
          status: 'failed',
        },
      }
      return newState
    }
    case DETAILSBUSINESSAREAS_REQUESTED: {
      const newState = {
        ...state,
        detailsBusinessAreasStatus: {
          status: 'requested',
        },
      }
      return newState
    }
    case DETAILSBUSINESSAREAS_SUCCEEDED: {
      const newState = {
        ...state,
        detailsBusinessAreasStatus: action.payload,
      }
      return newState
    }
    case DETAILSBUSINESSAREAS_FAILED: {
      const newState = {
        ...state,
        detailsBusinessAreasStatus: {
          status: 'failed',
        },
      }
      return newState
    }
    case SAVECARD_REQUESTED: {
      const newState = {
        ...state,
        saveCardStatus: {
          ...state.saveCardStatus,
          [action.payload._id || action.payload.tempid]: {
            status: 'requested',
          },
        },
      }
      return newState
    }
    case SAVECARD_SUCCEEDED: {
      const newState = {
        ...state,
        saveCardStatus: {
          ...state.saveCardStatus,
          [action.payload.data.tempid || action.payload.data._id]: {
            status: action.payload.status,
            newId: action.payload.data.tempid ? action.payload.data._id : null,
          },
        },
      }
      return newState
    }
    case SAVE_AS_CARD_SUCCEEDED: {
      const newState = {
        ...state,
        saveAsCardStatus: action.payload,
      }
      return newState
    }
    case SAVECARD_FAILED: {
      const newState = {
        ...state,
        saveCardStatus: {
          status: 'failed',
          message: action.payload.response,
        },
      }
      return newState
    }
    case CLEAR_SAVECARDS_AND_SAVEDASHBOARD: {
      const newState = {
        ...state,
        saveCardStatus: {},
        saveDashboardStatus: {},
      }
      return newState
    }
    case SAVEDASHBOARD_REQUESTED: {
      const newState = {
        ...state,
        saveDashboardStatus: {
          status: 'requested',
        },
      }
      return newState
    }
    case SAVEDASHBOARD_SUCCEEDED: {
      const newState = {
        ...state,
        saveDashboardStatus: action.payload,
      }
      return newState
    }
    case SAVEDASHBOARD_FAILED: {
      const newState = {
        ...state,
        saveDashboardStatus: {
          status: 'failed',
          message: action.payload.response,
        },
      }
      return newState
    }
    case CARDSDATASETS_FETCH_REQUESTED: {
      const newState = {
        ...state,
        cardsDatasetsStatus: {
          status: 'requested',
        },
      }
      return newState
    }
    case CARDSDATASETS_FETCH_SUCCEEDED: {
      const newState = {
        ...state,
        cardsDatasetsStatus: action.payload,
      }
      return newState
    }
    case CARDSDATASETS_FETCH_FAILED: {
      const newState = {
        ...state,
        cardsDatasetsStatus: {
          status: 'failed',
          message: action.payload.response,
        },
      }
      return newState
    }
    case CARDSDATASET_FETCH_REQUESTED: {
      const datasetId = String(action.payload).includes('?') ? action.payload.split('?')[0] : action.payload // Handle quick metadata request

      const newState = {
        ...state,
        cardsDatasetStatus: {
          ...state.cardsDatasetStatus,
          [datasetId]: {
            status: 'requested',
          },
        },
      }

      return newState
    }
    case CARDSDATASET_FETCH_SUCCEEDED: {
      action.payload.data.fields_array = formatDatasetFields(action.payload.data.fields)
      delete action.payload.data.columns

      const newState = {
        ...state,
        cardsDatasetStatus: {
          ...state.cardsDatasetStatus,
          [action.payload.data._id]: action.payload,
        },
      }

      return newState
    }
    case CARDSDATASET_FETCH_FAILED: {
      const newState = {
        ...state,
        cardsDatasetStatus: {
          ...state.cardsDatasetStatus,
          [action.payload.id]: {
            status: 'failed',
            message: action.payload.response,
          },
        },
      }
      return newState
    }

    case CARD_DATASET_ID_UPDATED: {
      const id = Boolean(action.payload)
      const newState = {
        ...state,
        cardDatasetId: id ? id : null, // eslint-disable-line
      }

      return newState
    }

    case DATASET_CARD_FILTER_FETCH_REQUESTED: {
      const newState = {
        ...state,
        dimensionValueListStatus: {
          status: 'requested',
        },
      }
      return newState
    }
    case DATASET_CARD_FILTER_FETCH_SUCCEEDED: {
      const newState = {
        ...state,
        dimensionValueListStatus: action.payload.data,
      }
      return newState
    }
    case DATASET_CARD_FILTER_FETCH_FAILED: {
      const newState = {
        ...state,
        dimensionValueListStatus: {
          status: 'failed',
          message: action.payload.response,
        },
      }
      return newState
    }

    case DATASET_FILTER_FETCH_REQUESTED: {
      const newState = {
        ...state,
        dimensionValueListForDatasetFiltersStatus: {
          status: 'requested',
        },
      }
      return newState
    }
    case DATASET_FILTER_FETCH_SUCCEEDED: {
      const newState = {
        ...state,
        dimensionValueListForDatasetFiltersStatus: action.payload.data,
      }
      return newState
    }
    case DATASET_FILTER_FETCH_FAILED: {
      const newState = {
        ...state,
        dimensionValueListForDatasetFiltersStatus: {
          status: 'failed',
          message: action.payload.response,
        },
      }
      return newState
    }

    case CARD_NEEDS_MORE_REQUIREMENTS_UPDATE: {
      const newState = {
        ...state,
        cardsNeedingMoreRequirements: {
          ...state.cardsNeedingMoreRequirements,
          [action.payload.id]: action.payload.needsMoreRequirements,
        },
      }
      return newState
    }
    case DETAILSOWNERS_CLEAR_STATE: {
      const newDetailsOwnersStatus = {
        ...state,
        detailsOwnersStatus: {},
      }
      return newDetailsOwnersStatus
    }

    case UPDATE_BUILDER_CLICK_COUNT: {
      const newState = {
        ...state,
        builderClickCount: state.builderClickCount ? state.builderClickCount + 1 : 1,
      }
      return newState
    }

    case BUILDER_AUTOUPDATE_UPDATE: {
      const newState = {
        ...state,
        autoUpdateStatus: action.payload,
      }
      return newState
    }

    case BUILDER_CARDISEDITED_UPDATE: {
      const newState = {
        ...state,
        builderCardIsEditedStatus: action.payload,
      }
      return newState
    }

    case BUILDER_OVERWRITE_AUTOUPDATE_UPDATE: {
      const newState = {
        ...state,
        builderOverwriteAutoUpdateStatus: action.payload,
      }
      return newState
    }

    case VALIDATE_BUSINESS_AREA_REQUESTED: {
      const newState = {
        ...state,
        isBusinessAreaValid: {
          status: 'requested',
        },
      }
      return newState
    }
    case VALIDATE_BUSINESS_AREA_SUCCEEDED: {
      const newState = {
        ...state,
        isBusinessAreaValid: action.payload,
      }
      return newState
    }
    case VALIDATE_BUSINESS_AREA_FAILED: {
      const newState = {
        ...state,
        isBusinessAreaValid: {
          status: 'failed',
          message: action.payload.response,
        },
      }
      return newState
    }

    case CLEAR_BUSINESS_AREA_TAG: {
      const newState = {
        ...state,
        isBusinessAreaValid: {},
      }
      return newState
    }

    case CLEAR_DETAIL_BUSINESS_AREAS: {
      const newState = {
        ...state,
        detailsBusinessAreasStatus: {},
      }
      return newState
    }

    default:
      return state
  }
}

// Actions
export const clearSaveCardsAndDashboard = () => ({
  type: CLEAR_SAVECARDS_AND_SAVEDASHBOARD,
})

export const retrieveBusinessAreaTags = data => ({
  type: BUSINESSAREA_TAGS_FETCH_REQUESTED,
  payload: data,
})

export const updateCardNeedingMoreRequirements = data => ({
  type: CARD_NEEDS_MORE_REQUIREMENTS_UPDATE,
  payload: data,
})

export const updateCardsDatasets = data => ({
  type: CARDSDATASETS_FETCH_REQUESTED,
  payload: data,
})

export const updateCardsDataset = data => ({
  type: CARDSDATASET_FETCH_REQUESTED,
  payload: data,
})

export const saveCard = data => ({
  type: SAVECARD_REQUESTED,
  payload: data,
})

export const saveAsCard = data => ({
  type: SAVE_AS_CARD_REQUESTED,
  payload: data,
})

export const saveDashboard = data => ({
  type: SAVEDASHBOARD_REQUESTED,
  payload: data,
})

export const getDetailsBusinessAreas = data => ({
  type: DETAILSBUSINESSAREAS_REQUESTED,
  payload: data,
})

export const getDetailsOwners = data => ({
  type: DETAILSOWNERS_REQUESTED,
  payload: data,
})

export const validateBusinessArea = data => ({
  type: VALIDATE_BUSINESS_AREA_REQUESTED,
  payload: data,
})

export const updateDatasetId = data => ({
  type: CARD_DATASET_ID_REQUEST,
  payload: data,
})

export const getDimensionValuesList = data => ({
  type: DATASET_CARD_FILTER_FETCH_REQUESTED,
  payload: data,
})

export const getDimensionValuesListForDatasetFilters = data => ({
  type: DATASET_FILTER_FETCH_REQUESTED,
  payload: data,
})

export const clearDetailOwnersInfo = () => ({
  type: DETAILSOWNERS_CLEAR_STATE,
})

export const clearDetailBusinessAreas = () => ({
  type: CLEAR_DETAIL_BUSINESS_AREAS,
})

export const updateBuilderClickCount = () => ({
  type: UPDATE_BUILDER_CLICK_COUNT,
})

export const updateAutoUpdateStatus = status => ({
  type: BUILDER_AUTOUPDATE_UPDATE,
  payload: status,
})

export const updateCardIsEditedStatus = status => ({
  type: BUILDER_CARDISEDITED_UPDATE,
  payload: status,
})

export const updateOverwriteAutoUpdateStatus = status => ({
  type: BUILDER_OVERWRITE_AUTOUPDATE_UPDATE,
  payload: status,
})

export const clearBusinessAreaTag = () => {
  return {
    type: CLEAR_BUSINESS_AREA_TAG,
  }
}

// Sagas (service requests)

export function* fetchBusinessAreaTags() {
  try {
    const tags = yield call(axios, {
      method: 'get',
      url: `${API_GATEWAY_URL}/bi_reporting_assets/v2/business_area_tags`,
    })

    if (tags.error) {
      const e = tags.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }

    yield put({ type: BUSINESSAREA_TAGS_FETCH_SUCCEEDED, payload: tags })
  } catch (error) {
    yield put({ type: BUSINESSAREA_TAGS_FETCH_FAILED, payload: error })
  }
}

export function* fetchCardsDatasets(action) {
  try {
    const searchValue = action?.payload?.searchValue
    const tags = action?.payload?.tags

    const datasets = yield call(axios, {
      method: 'get',
      url: `${API_GATEWAY_URL}/bi_reporting_assets/v2/datasets?field_groups=name_only&page=1&per_page=100&search_string=${
        searchValue || ''
      }&tags=${tags || ''}`,
    })

    if (datasets.error) {
      const e = datasets.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }

    yield put({ type: CARDSDATASETS_FETCH_SUCCEEDED, payload: datasets })
  } catch (error) {
    yield put({ type: CARDSDATASETS_FETCH_FAILED, payload: error })
  }
}

export function* fetchCardsDataset(action) {
  try {
    const dataset = yield call(axios, {
      method: 'get',
      url: `${API_GATEWAY_URL}/bi_reporting_assets/v2/datasets/${action.payload}`,
    })

    if (dataset.error) {
      const e = dataset.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }

    yield put({ type: CARDSDATASET_FETCH_SUCCEEDED, payload: dataset })
  } catch (error) {
    yield put({ type: CARDSDATASET_FETCH_FAILED, payload: { id: action.payload, response: error } })
  }
}

export function* sendSaveCard(action) {
  const isExisting = action.payload._id && !window.isNaN(action.payload._id)
  try {
    const card = yield call(axios, {
      method: isExisting ? 'put' : 'post',
      url: isExisting
        ? `${API_GATEWAY_URL}/bi_reporting_assets/v3/cards/${action.payload._id}`
        : `${API_GATEWAY_URL}/bi_reporting_assets/v3/cards`,
      data: action.payload,
    })

    if (card.error) {
      const e = card.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }

    yield put({ type: SAVECARD_SUCCEEDED, payload: card })
  } catch (error) {
    yield put({ type: SAVECARD_FAILED, payload: error })
  }
}

export function* sendSaveAsCard(action) {
  const isExisting = action.payload._id && !window.isNaN(action.payload._id)
  try {
    const card = yield call(axios, {
      method: isExisting ? 'put' : 'post',
      url: isExisting
        ? `${API_GATEWAY_URL}/bi_reporting_assets/v3/cards/${action.payload._id}`
        : `${API_GATEWAY_URL}/bi_reporting_assets/v3/cards`,
      data: action.payload,
    })

    if (card.error) {
      const e = card.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }

    yield put({ type: SAVE_AS_CARD_SUCCEEDED, payload: card })
  } catch (error) {
    yield put({ type: SAVECARD_FAILED, payload: error })
  }
}

export function* sendSaveDashboard(action) {
  const isExistingDashboard = action.payload._id

  try {
    const dashboard = yield call(axios, {
      method: isExistingDashboard ? 'put' : 'post',
      url: isExistingDashboard
        ? `${API_GATEWAY_URL}/bi_reporting_assets/v2/dashboards/${isExistingDashboard}`
        : `${API_GATEWAY_URL}/bi_reporting_assets/v2/dashboards`,
      data: action.payload,
    })

    if (dashboard.error) {
      const e = dashboard.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }

    yield put({ type: SAVEDASHBOARD_SUCCEEDED, payload: dashboard })
  } catch (error) {
    yield put({ type: SAVEDASHBOARD_FAILED, payload: error })
  }
}

export function* fetchDetailsBusinessAreas(action) {
  try {
    const businessAreas = yield call(axios, {
      method: 'get',
      url: `${API_GATEWAY_URL}/bi_reporting_assets/v2/business_area_tags?search_string=${action.payload}`,
    })

    if (businessAreas.error) {
      const e = businessAreas.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }
    yield put({ type: DETAILSBUSINESSAREAS_SUCCEEDED, payload: businessAreas })
  } catch (e) {
    yield put({ type: DETAILSBUSINESSAREAS_FAILED })
  }
}

export function* fetchDetailsOwners(action) {
  try {
    const addUrl = !action.payload.type
      ? `search_string=${action.payload}&user_type=internal`
      : action.payload.type === 'none'
      ? `search_string=${action.payload.data}`
      : `search_string=${action.payload.data}&user_type=${action.payload.type}`
    const owners = yield call(axios, {
      method: 'get',
      url: `${API_GATEWAY_URL}/bi_reporting_assets/v2/user_group_lookup?page=1&per_page=50&fast_search=true&${addUrl}`,
    })

    if (owners.error) {
      const e = owners.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }
    yield put({ type: DETAILSOWNERS_SUCCEEDED, payload: owners })
  } catch (e) {
    yield put({ type: DETAILSOWNERS_FAILED })
  }
}

export function* fetchDimensionValueList(action) {
  try {
    const _cardConfig = cloneDeep(action.payload.card_config)
    delete _cardConfig.pendingCardUid
    const dimensionValueList = yield call(axios, {
      method: 'post',
      url: `${API_GATEWAY_URL}/bi_reporting_assets/v2/card_filters?max_rows=${
        action.payload.max_rows
      }&search_string=${encodeURIComponent(action.payload.search_string)}&${
        action.payload.dimension_name ? 'dimension_name' : 'ref_id'
      }=${encodeURIComponent(action.payload.dimension_name ? action.payload.dimension_name : action.payload.ref_id)}`,
      data: _cardConfig,
    })
    if (dimensionValueList.error) {
      const e = dimensionValueList.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }

    yield put({ type: 'DATASET_CARD_FILTER_FETCH_SUCCEEDED', payload: dimensionValueList })
  } catch (error) {
    yield put({ type: 'DATASET_CARD_FILTER_FETCH_FAILED', payload: error })
  }
}

export function* fetchDimensionValueListForDatasetFilters(action) {
  try {
    const dimensionValueList = yield call(axios, {
      method: 'get',
      url: `${API_GATEWAY_URL}/bi_reporting_assets/v2/dataset_filters?max_rows=${action.payload.max_rows}&dataset_id=${
        action.payload.dataset_id
      }&search_string=${encodeURIComponent(action.payload.search_string)}&dimension_name=${encodeURIComponent(
        action.payload.dimension_name
      )}`,
    })
    if (dimensionValueList.error) {
      const e = dimensionValueList.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }

    yield put({ type: 'DATASET_FILTER_FETCH_SUCCEEDED', payload: dimensionValueList })
  } catch (error) {
    yield put({ type: 'DATASET_FILTER_FETCH_FAILED', payload: error })
  }
}

export function* validateBusinessAreaTags(action) {
  try {
    const card = yield call(axios, {
      method: 'post',
      url: `${API_GATEWAY_URL}/bi_reporting_assets/v2/business_area_tags/validate`,
      data: [`${action.payload}`],
    })

    if (card.error) {
      const e = card.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }
    yield put({ type: VALIDATE_BUSINESS_AREA_SUCCEEDED, payload: card })
  } catch (error) {
    yield put({ type: VALIDATE_BUSINESS_AREA_FAILED, payload: error })
  }
}
