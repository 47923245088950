import React, { useState, useEffect, useRef } from 'react'
import readXlsxFile from 'read-excel-file'
import { parse } from 'papaparse'
import { DropzoneArea } from 'mui-file-dropzone'
import { post } from 'axios'
import { LinearProgress } from '@mui/material'
import styled from 'styled-components'

// https://jira.target.com/browse/GREEN-7988 this changes specificity to less than the original theme
const FilterContainer = styled.div`
  position: relative;

  .MuiTypography-h5 {
    font-size: 14px;
  }

  .MuiDropzoneArea-root {
    min-height: 35px;
  }

  .MuiDropzoneArea-textContainer {
    margin: 5px;
    display: flex;
    justify-content: center;

    .MuiTypography-h5 {
      margin: 0 5px 0 0;
    }
  }

  .gf-MuiLinearProgress-root {
    background: #fff;
  }

  svg {
    width: 20px;
    height: 20px;
  }

  > div:first-child {
    min-height: 115px;
  }

  span.gf-MuiTypography-root + div.gf-MuiBox-root {
    position: absolute;
    top: 80px;
  }
`

const UploadFilterFile = ({ handleUploadRows }) => {
  const [percent, setPercent] = useState(0)
  const isMounted = useRef(false)

  // to fix memory leak by unmounting the component
  useEffect(() => {
    isMounted.current = true
    return () => (isMounted.current = false)
  }, [])

  const onDrop = files => {
    setPercent(0)
    const data = new FormData()
    files.forEach(file => {
      data.append('files[]', file, file.name)
    })

    const config = {
      headers: { 'content-type': 'multipart/form-data' },
      onUploadProgress: progressEvent => {
        const percent = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        if (percent >= 100) {
          setPercent(100)
        } else {
          setPercent(percent)
        }
      },
    }

    post('/', data, config)
  }

  return isMounted?.current ? (
    <FilterContainer>
      <DropzoneArea
        acceptedFiles={[
          'text/csv, application/vnd.ms-excel, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        ]}
        dropzoneText="Drop file or click to upload"
        maxFileSize={1000000}
        previewText={null}
        showPreviews
        showFileNamesInPreview
        showPreviewsInDropzone={false}
        previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
        onDrop={onDrop}
        filesLimit={1}
        showFileNames
        alertSnackbarProps={{ style: { marginBottom: '30px' } }}
        onChange={files => {
          setPercent(0)
          if (files?.length) {
            switch (files[0].type) {
              case 'application/vnd.ms-excel':
              case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                readXlsxFile(files[0]).then(rows => {
                  handleUploadRows(rows)
                })
                break
              case 'text/csv':
              case 'application/csv':
              case 'application/x-csv':
              case 'text/comma-separated-values':
              case 'text/x-comma-separated-values':
                parse(files[0], {
                  complete: function (results) {
                    if (results && results.data && results.data.length === 2 && results.data[1] === '') {
                      handleUploadRows(results.data[0])
                    } else {
                      handleUploadRows(results.data)
                    }
                  },
                })
                break
              default:
                break
            }
          }
        }}
      />
      <LinearProgress variant="determinate" value={percent} />
    </FilterContainer>
  ) : null
}

export default UploadFilterFile
