import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import moment from 'moment'
import { useSelector } from 'react-redux'

import './supportinfo.scss'
import useBuildGreenfieldServices from '../useBuildGreenfieldServices'

const SupportInfo = ({ cardInfo = {}, cardStatus = 0, expanded = true, setExpanded, cardMeta }) => {
  const [user, setUser] = useState(null)
  const [isMounted, setIsMounted] = useState(false)
  const [copy, setCopy] = useState('Copy to Clipboard')

  const buildGreenfieldServicesData = useBuildGreenfieldServices()

  const cardConfig =
    cardMeta && cardMeta.status >= 200 && cardMeta.status < 300 && (cardMeta.data.card_config || cardMeta?.data)

  const userTypeResponse = useSelector(state => {
    if (isMounted && !user) {
      return state.user.userType
    }
  }, [])

  useEffect(() => {
    setIsMounted(true)
  }, [])

  useEffect(() => {
    if (userTypeResponse?.data && !user) {
      setUser(userTypeResponse?.data)
    }
  }, [userTypeResponse])

  function browserDetection() {
    const ua = navigator.userAgent.match(/(Opera|Chrome|Safari|Firefox|Microsoft Edge)\/?\s*(\.?\d+(\.\d+)*)/i)
    const browser = `${ua[1]}, version ${ua[2]}`
    return browser
  }

  function handleCopy() {
    const createdDate = cardConfig?.card_attribute.create_date
      ? moment(cardConfig?.card_attribute.create_date).format('MMMM Do YYYY, h:mm:ss a')
      : ''
    const lastUpdatedDate = cardConfig?.card_attribute.last_updated_date
      ? moment(cardConfig?.card_attribute.last_updated_date).format('MMMM Do YYYY, h:mm:ss a')
      : ''
    const datasetLastLoaded = cardConfig?.card_attribute?.dataset_last_load_date
      ? moment(cardConfig?.card_attribute.dataset_last_load_date).format('MMMM Do YYYY, h:mm:ss a')
      : ''
    const cardQuery = cardInfo?.query ? JSON.stringify(cardInfo.query, null, 2) : 'Not Available'
    const exportSupport = [
      `Title: ${cardConfig?.card_attribute?.card_title}\n` +
        `ID: ${cardConfig?._id}\n` +
        `Data Classification: ${cardConfig?.card_attribute.data_classification}\n` +
        'Owner(s): ' +
        cardConfig?.card_attribute.owners_access.map((item, index) => (index ? ', ' : '') + item.user_group_name) +
        '\n' +
        `Viever: ${user?._id}\n` +
        `UI Version: ${process.env.UI_BUILD_NUMBER || 'Not Available'}\n` +
        `API version: ${buildGreenfieldServicesData?.build_number || 'Not Available'}\n` +
        `Browser: ${browserDetection()}\n` +
        'Card Created: ' +
        createdDate +
        '\n' +
        'Card Last Updated: ' +
        lastUpdatedDate +
        '\n' +
        `Dataset: ${cardConfig?.card_attribute.dataset_name}\n` +
        'Dataset Last Loaded: ' +
        datasetLastLoaded +
        '\n' +
        'Card Query: \n' +
        cardQuery +
        `\nTransaction ID: ${cardInfo.transaction_id || 'Not Available'}` +
        '\nStatus: ' +
        `${cardStatus ? cardStatus + ', ' + cardInfo.message : '200'}\n`,
    ]

    navigator.clipboard.writeText(exportSupport)
    setCopy('Copied!')
  }

  return (
    <Dialog open={expanded} className="supportdetails-popover" fullWidth maxWidth="md">
      {cardConfig && (
        <>
          <DialogTitle>
            {cardConfig?.card_attribute.card_title} ({cardConfig._id})
            <Typography variant="caption" component="p">
              {cardConfig?.card_attribute?.data_classification}
            </Typography>
          </DialogTitle>
          <DialogContent dividers>
            <Typography variant="caption" component="p">
              <b>Owner(s): </b>
              {cardConfig?.card_attribute?.owners_access.map(
                (item, index) => (index ? ', ' : '') + item.user_group_name
              )}
            </Typography>
            <Typography variant="caption" component="p">
              <b>Viewer: </b>
              {user?._id}
            </Typography>
            <Typography variant="caption" component="p">
              <b>UI Version: </b>
              {process.env.UI_BUILD_NUMBER || 'Not Available'}
            </Typography>
            <Typography variant="caption" component="p">
              <b>API Version: </b>
              {buildGreenfieldServicesData?.build_number || 'Not Available'}
            </Typography>
            <Typography variant="caption" component="p">
              <b>Browser: </b>
              {browserDetection()}
            </Typography>
            <Typography variant="caption" component="p">
              <b>Card Created: </b>
              {moment(cardConfig?.card_attribute?.create_date).format('MMMM Do YYYY, h:mm:ss a')}
            </Typography>
            <Typography variant="caption" component="p">
              <b>Card Last Updated: </b>
              {moment(cardConfig?.card_attribute?.last_updated_date).format('MMMM Do YYYY, h:mm:ss a')}
            </Typography>
            <Typography variant="caption" component="p">
              <b>Dataset: </b>
              {cardConfig?.card_attribute.dataset_name}
            </Typography>
            {cardConfig?.card_attribute.dataset_last_load_date && (
              <Typography variant="caption" component="p">
                <b>Dataset Last Loaded: </b>
                {moment(cardConfig?.card_attribute.dataset_last_load_date).format('MMMM Do YYYY, h:mm:ss a')}
              </Typography>
            )}
            <Typography variant="caption" component="span">
              <b>Card Query:</b>
              <pre>{cardInfo.query ? JSON.stringify(cardInfo.query, null, 2) : 'Not Available'}</pre>
            </Typography>
            <Typography variant="caption" component="p">
              <b>Transaction ID: </b>
              {cardInfo.transaction_id || 'Not Available'}
            </Typography>
            <Typography variant="caption" component="p">
              <b>Status: </b>
              {cardStatus ? `${cardStatus}, ${cardInfo.message}` : '200'}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              style={{ marginRight: 'auto' }}
              color="secondary"
              id="close-support-btn"
              onClick={() => setExpanded(false)}
            >
              Close
            </Button>
            <Button color="primary" id="copy-clipboard-btn" onClick={handleCopy}>
              {copy}
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  )
}

SupportInfo.propTypes = {
  cardInfo: PropTypes.object,
  cardStatus: PropTypes.number,
  expanded: PropTypes.bool,
}

export default SupportInfo
