import axios from 'axios'
import React from 'react'
import { Dialog, DialogContent, DialogActions, Button } from '@mui/material'
import apiConfig from '../../config/apiConfig'
import authUtil, { clearLocalStorage, checkAuthTtl } from './authUtil'

/*
Interceptor adds useful handlers to axios requests and responses.
If the user has an accessToken, it is used as the value for the
Authorization header. This applies to all requests to .target.com and
.tgt domains. You can disable this per request by passing in this config:
{headers: {Authorization: false}}
If an axios response is an error, it is handled based on the error code:
- 401: prompt the user to sign in
- 403: notify the user that they don't have permission to do that
- Other: show the axios error message to the user
When the component unmounts, the interceptors are removed.
*/
export class Interceptor extends React.Component {
  state = {
    authInterceptor: undefined,
    errorInterceptor: undefined,
    showSessionWillExpireWarning: false,
    sessionWillExpireWarning: false,
    setSessionLocal: false,
  }

  componentDidMount() {
    this.addAuthInterceptor()
    this.addErrorInterceptor()
  }

  componentWillUnmount() {
    this.removeAuthInterceptor()
    this.removeErrorInterceptor()
  }

  addAuthInterceptor = () => {
    const authObj = this.props.auth
    const authInterceptor = axios.interceptors.request.use(
      config => {
        const sessionTtl = checkAuthTtl()
        const isSessionValid = authUtil(authObj)
        const isAuthenticateUser = authObj && authObj.isAuthorized() && authObj.session && isSessionValid
        const sessionEndLocalStorageFlag = localStorage.getItem('sessionEndWarn')

        if (sessionTtl && !this.state.sessionWillExpireWarning && !sessionEndLocalStorageFlag) {
          localStorage.setItem('sessionEndWarn', true)
          this.setState({
            sessionWillExpireWarning: true,
            showSessionWillExpireWarning: true,
          })
        } else if (!sessionTtl && Boolean(sessionEndLocalStorageFlag)) {
          localStorage.removeItem('sessionEndWarn')
        }

        if (!isAuthenticateUser) {
          clearLocalStorage(encodeURIComponent(this.props.routeProps.history.location.pathname))
        }
        if (!config.headers.Authorization) {
          const accessToken =
            authObj && authObj.session && authObj.session.userInfo ? authObj.session.userInfo.accessToken : null
          if (accessToken) {
            axios.defaults.headers.common.Authorization = accessToken
            config.headers.Authorization = accessToken
            axios.defaults.headers.common['x-api-key'] = apiConfig.gnfapi.apikey
            config.headers['x-api-key'] = apiConfig.gnfapi.apikey

            if (config.url && config.url.includes('.md')) {
              config.headers['Cache-Control'] = 'no-cache'
            }
          }
        } else if (!config.headers.Authorization) {
          delete config.headers.Authorization
          delete axios.defaults.headers.common.Authorization
          delete axios.defaults.headers.common['x-api-key']
        }
        return config
      },
      error => {
        return Promise.reject(error)
      }
    )

    this.setState({ authInterceptor })
  }

  removeAuthInterceptor = () => {
    axios.interceptors.request.eject(this.state.authInterceptor)
    this.setState({ authInterceptor: undefined })
  }

  addErrorInterceptor = () => {
    const errorInterceptor = axios.interceptors.response.use(
      response => {
        return response
      },
      error => {
        if (error.response) {
          const code = error.response.status
          if (code === 401) {
            clearLocalStorage()
          } else {
            // Looks like this never handled correctly TODO
            // let message = 'Something went wrong.'
            // if (code === 403) {
            //   message = 'You’re not authorized to do that.'
            // } else if (error.message) {
            //   message = error.message
            // }
          }
        }
        return Promise.reject(error)
      }
    )
    this.setState({ errorInterceptor })
  }

  handleCloseSessionExpireDialog = () => {
    this.setState({
      showSessionWillExpireWarning: false,
    })
  }

  removeErrorInterceptor = () => {
    axios.interceptors.request.eject(this.state.errorInterceptor)
    this.setState({ errorInterceptor: undefined })
  }

  // hostname ends with .target.com or .tgt
  isTargetDomain = url => {
    return /^([^/]+:)?\/{2,3}[^/]+?(\.target\.com|\.tgt)(:|\/|$)/i.test(url)
  }

  render() {
    return (
      <Dialog open={this.state.showSessionWillExpireWarning} onClose={this.handleCloseSessionExpireDialog}>
        <DialogContent>
          Your session will expire in under 5 minutes. <br />
          <br />
          Please save your changes
        </DialogContent>

        <DialogActions>
          <Button onClick={this.handleCloseSessionExpireDialog}>Sounds good!</Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default Interceptor
