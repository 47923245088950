import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { Route } from 'react-router-dom'
import { Tabs, Tab } from '@mui/material'
import SwipeableViews from 'react-swipeable-views'
import { GridFiltersWithData } from '../Profile/GridFilters'
import { fetchUserFavCardsCount, fetchUserFavDashboardsCount, fetchUserFavDatasetsCount } from '../../../ducks/user'
import FavoritesCards from './FavoritesCards.container'
import FavoritesDashboards from './FavoritesDashboards.container'
import FavoritesDatasets from './FavoritesDatasets.container'
import HomeDashboard from './HomeDashboard'
import './favorites.scss'
const Home = ({ routeProps = {}, isMobile = false, deviceType, setHeaderTitle = () => {}, user = {} }) => {
  const dispatch = useDispatch()
  const userAccess = useSelector(state => state.user.userAccessStatus)
  const isUserLabelEnabled = userAccess?.data?.USER_LABEL_ENABLED
  const routeIndex = id => {
    if (id === 'home') {
      return 0
    } else if (id === 'cards' || id === 'favorites') {
      return 1
    } else if (id === 'dashboards') {
      return 2
    } else if (id === 'datasets') {
      return 3
    }
  }
  const [activeTab, setActiveTab] = useState(routeIndex(routeProps.match.params._id))
  const [isLoading, setIsLoading] = useState(false)
  const { userFavCardsCount, userFavDashboardsCount, userFavDatasetsCount } = useSelector(state => state.user)

  useEffect(() => {
    if (routeProps.location.pathname === '/favorites/favorites') {
      setIsLoading(true)
      dispatch(fetchUserFavCardsCount({ favoriteOnly: true }))
      dispatch(fetchUserFavDashboardsCount({ favoriteOnly: true }))
      dispatch(fetchUserFavDatasetsCount({ favoriteOnly: true }))
    }
  }, [routeProps.location.pathname])

  useEffect(() => {
    const { params } = routeProps.match
    setActiveTab(routeIndex(params._id))
  }, [activeTab])

  useEffect(() => {
    if (userFavCardsCount?.data) {
      setIsLoading(false)
    }
  }, [userFavCardsCount])

  const handleTabChange = (event, activeTab) => {
    setActiveTab(activeTab)
    const route = (() => {
      switch (activeTab) {
        case 0:
          return 'home'
        case 1:
          return isUserLabelEnabled ? 'favorites' : 'cards'
        case 2:
          return 'dashboards'
        case 3:
          return 'datasets'
      }
    })()
    routeProps.history.push(`/favorites/${route}`)
  }

  return (
    <div className="homePage">
      <Tabs
        indicatorColor="primary"
        textColor="primary"
        value={activeTab}
        onChange={handleTabChange}
        className="homePageTabBar"
      >
        <Tab label="Home" data-cy="homePageTabHomeBtn" />
        {!isUserLabelEnabled && <Tab label="Favorite Cards" data-cy="homePageTabHomeBtn" />}
        {!isUserLabelEnabled && <Tab label="Favorite Dashboards" />}
        {!isUserLabelEnabled && <Tab label="Favorite Datasets" />}
        {isUserLabelEnabled && <Tab label="Favorites" data-cy="homePageTabHomeBtn" />}
      </Tabs>

      <SwipeableViews index={activeTab === 1 && isUserLabelEnabled ? activeTab + 3 : activeTab} className="fullHeight">
        <Route
          path="/favorites/home/"
          render={() => (
            <HomeDashboard
              isPrintLandscape={false}
              routeProps={routeProps}
              setHeaderTitle={setHeaderTitle}
              isMobile={isMobile}
              deviceType={deviceType}
              user={user}
            />
          )}
        />
        <Route
          path="/favorites/cards"
          render={() => (
            <FavoritesCards
              routeProps={routeProps}
              setHeaderTitle={setHeaderTitle}
              isMobile={isMobile}
              deviceType={deviceType}
            />
          )}
        />
        <Route
          path="/favorites/dashboards"
          render={() => <FavoritesDashboards setHeaderTitle={setHeaderTitle} isMobile={isMobile} />}
        />
        <Route
          path="/favorites/datasets"
          render={() => <FavoritesDatasets setHeaderTitle={setHeaderTitle} isMobile={isMobile} />}
        />
        <Route
          path="/favorites"
          render={() => {
            return (
              activeTab === 1 &&
              isUserLabelEnabled && (
                <GridFiltersWithData
                  title="My Favorites"
                  cardsCount={userFavCardsCount?.data?.count || 0}
                  dashboardsCount={userFavDashboardsCount?.data?.count || 0}
                  datasetsCount={userFavDatasetsCount?.data?.count || 0}
                  setHeaderTitle={setHeaderTitle}
                  isFavorite
                  isMobile={isMobile}
                  isLoading={isLoading}
                />
              )
            )
          }}
        />
      </SwipeableViews>
    </div>
  )
}
Home.propTypes = {
  routeProps: PropTypes.object,
  setHeaderTitle: PropTypes.func,
  user: PropTypes.object,
  isMobile: PropTypes.bool,
  deviceType: PropTypes.array,
}
export default Home
