import React from 'react'
import PropTypes from 'prop-types'
import 'froala-editor/js/froala_editor.pkgd.min.js'
import 'froala-editor/css/froala_style.min.css'
import 'froala-editor/css/froala_editor.pkgd.min.css'
import Editor from 'react-froala-wysiwyg'
import { Typography } from '@mui/material'
import CryptoJS from 'crypto-js'

import apiConfig from '../../../../config/apiConfig'
const BuilderSupportDetails = ({
  supportText = '',
  type = 'card',
  cardInfo = {},
  updateSupportText,
  updateChangedCards,
}) => {
  const handleDescriptionChange = model => {
    if (type === 'dataset') {
      updateSupportText(model)
    } else {
      updateSupportText('supportText', model)
    }

    if (cardInfo.data && cardInfo.data.card_config && cardInfo.data.card_config._id) {
      updateChangedCards(cardInfo.data.card_config._id)
    }
  }

  return (
    <div className="support-content-container">
      <Typography variant="body1" className="text-editor-help" tabIndex="0" aria-label="information text">
        Text entered below will be used as support help for the {type} where needed
      </Typography>
      <Editor
        model={supportText}
        onModelChange={handleDescriptionChange}
        aria-label="text editor"
        key="editor"
        config={{
          attribution: false,
          key: CryptoJS.AES.decrypt(process.env.FROALA_KEY.toString(), apiConfig.froalaDecryptKey).toString(
            CryptoJS.enc.Utf8
          ),
          fileUpload: false,
          imageUpload: false,
          videoUpload: false,
          height: 250,
          toolbarButtons: [
            'fullscreen',
            'bold',
            'italic',
            'underline',
            'strikeThrough',
            'subscript',
            'superscript',
            'fontFamily',
            'fontSize',
            'colors',
            'inlineStyle',
            'paragraphStyle',
            'lineHeight',
            'paragraphFormat',
            'align',
            'formatOL',
            'formatUL',
            'insertLink',
            'insertTable',
            'insertHorizontalLine',
            'clearFormatting',
            'html',
            'codeView',
            'undo',
            'redo',
          ],
          zIndex: 999,
        }}
      />
    </div>
  )
}

BuilderSupportDetails.propTypes = {
  supportText: PropTypes.string,
  type: PropTypes.string,
  cardInfo: PropTypes.object,
  updateSupportText: PropTypes.func,
  updateChangedCards: PropTypes.func,
}

export default BuilderSupportDetails
