import React from 'react'
import { Spinner } from 'greenfield-utilities'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import { Typography, Dialog, DialogTitle, DialogActions, Button, Grid, Icon, Paper } from '@mui/material'
import cloneDeep from 'lodash/cloneDeep'
import styled from 'styled-components'
import AssetTable, { AssetType } from '../../shared/AssetTableSS/AssetTable'

const SSpinner = styled(Spinner)`
  margin-top: 50px;
`

class FavoritesDatasets extends React.Component {
  state = {
    datasets: [],
    kebabMenuElement: null,
    kebabMenuOpenIndex: null,
    redirect: '',
    deleteDatasetConfirmationId: '',
    isUnFavoritingId: '',
  }

  componentDidUpdate(prevProps) {
    const { isUnFavoritingId, datasets } = this.state
    const { postFavoriteStatus, datasetDelStatus, favoriteDatasetsInfo } = this.props

    if (
      prevProps.favoriteDatasetsInfo.status === 'requested' &&
      favoriteDatasetsInfo.status >= 200 &&
      favoriteDatasetsInfo.status < 300
    ) {
      this.setState({
        datasets: this.props.favoriteDatasetsInfo.data,
      })
    }

    if (
      prevProps.postFavoriteStatus.status === 'requested' &&
      !Object.keys(postFavoriteStatus).length &&
      isUnFavoritingId
    ) {
      this.setState(prevState => {
        const datasets = cloneDeep(prevState.datasets)

        datasets.splice(
          datasets.findIndex(dataset => dataset._id === isUnFavoritingId),
          1
        )
        return {
          isUnFavoritingId: '',
          datasets,
        }
      })
    }

    if (
      prevProps.datasetDelStatus.status === 'requested' &&
      datasetDelStatus.status >= 200 &&
      datasetDelStatus.status < 300
    ) {
      const cardId = datasetDelStatus.config.url.split('/')[datasetDelStatus.config.url.split('/').length - 1]
      const _datasets = cloneDeep(datasets)

      _datasets.splice(
        _datasets.findIndex(dataset => dataset._id.toString() === cardId),
        1
      )

      this.setState({ datasets: _datasets })
    }
  }

  handleKebabClick = (e, index) => {
    this.setState({
      kebabMenuElement: e.currentTarget,
      kebabMenuOpenIndex: index,
    })
  }

  handleKebabMenuClose = () => {
    this.setState({ kebabMenuElement: null, kebabMenuOpenIndex: null })
  }

  handleCreateCardClick = _id => {
    this.setState({
      redirect: `/builder/card/dataset/${_id}`,
    })
  }

  handleDeleteDatasetClick = _id => {
    this.setState({
      deleteDatasetConfirmationId: _id,
      kebabMenuElement: null,
    })
  }

  handleDeleteConfirmationClose = () => {
    this.setState({ deleteDatasetConfirmationId: '' })
  }

  handleDeleteConfirmationSubmit = () => {
    const { deleteDatasetConfirmationId } = this.state

    this.props.delDataset({ delDataset: deleteDatasetConfirmationId })
    this.setState({ deleteDatasetConfirmationId: '', kebabMenuOpenIndex: null })
  }

  handleFavoriteClick = id => {
    const { updateFavorite } = this.props

    this.setState({ isUnFavoritingId: id })

    updateFavorite({
      is_favorite: 'no',
      object_id: id,
      object_type: 'datasets',
    })
  }

  renderDeleteModals() {
    const { deleteDatasetConfirmationId } = this.state

    return (
      <Dialog open onClose={this.handleDeleteConfirmationClose}>
        <DialogTitle>{`Are you sure you want to delete dataset ${deleteDatasetConfirmationId}?`}</DialogTitle>
        <DialogActions>
          <Button onClick={this.handleDeleteConfirmationSubmit} color="primary">
            Delete
          </Button>
          <Button onClick={this.handleDeleteConfirmationClose} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  renderDatasets() {
    const { redirect, datasets } = this.state

    return redirect ? (
      <Redirect to={redirect} />
    ) : datasets.length === 0 ? (
      <Grid container className="noFavoriteDatasetMessage">
        <Grid item className="noMessageMobileHook">
          <Typography className="noMessageFirst">
            No favorite datasets. Click the <Icon className="noMessageIcon">star_outline</Icon> on any card, dashboard
            or dataset to have them appear in your favorites.
          </Typography>
        </Grid>
      </Grid>
    ) : (
      <Paper sx={{ padding: '1rem' }} elevation={0}>
        <AssetTable assetType={AssetType.DATASETS} ownerOnly favoriteOnly />
      </Paper>
    )
  }

  render() {
    const { favoriteDatasetsInfo, displayServiceErrorMessage, postFavoriteStatus, datasetDelStatus } = this.props

    if (
      favoriteDatasetsInfo.status === 'requested' ||
      postFavoriteStatus.status === 'requested' ||
      datasetDelStatus.status === 'requested'
    ) {
      return <SSpinner layout="selfCentering" size="large" />
    } else if (favoriteDatasetsInfo.status >= 200 && favoriteDatasetsInfo.status < 300 && favoriteDatasetsInfo) {
      return this.renderDatasets()
    } else if (favoriteDatasetsInfo.status === 'failed') {
      displayServiceErrorMessage('Your request to retrieve your favorite datasets failed.')
    }

    return null
  }
}

FavoritesDatasets.propTypes = {
  favoriteDatasetsStatus: PropTypes.object,
  displayServiceErrorMessage: PropTypes.func,
}

export default FavoritesDatasets
