import React, { useState } from 'react'
import { Button } from '@mui/material'
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import { CardDetailComponentMobile } from '../../routes/Cards/Cardlist/CardDetailComponentMobile'
import { DashboardDetailComponentMobile } from '../../routes/Dashboard/DashboardListing/DashboardDetailComponentMobile'
import { AssetType } from './AssetTable'

export const DetailButtonRenderer = props => {
  const { isMobile, node, api, data, handleToggleCardDetail, assetType } = props
  const rowNode = api.getDisplayedRowAtIndex(node.rowIndex)

  const [mobileDetails, setMobileDetails] = useState({
    mobileSubComponentActive: false,
    mobileTableIndex: null,
    rowData: '',
  })

  const { mobileSubComponentActive, rowData } = mobileDetails

  const handleToggleMobileComponent = (node, rowData) => {
    setMobileDetails({
      mobileSubComponentActive: !mobileDetails.mobileSubComponentActive,
      mobileTableIndex: node.rowIndex,
      rowData,
    })
  }

  return (
    <>
      <Button
        id={`view-details-btn-${node.rowIndex}`}
        data-cy={`view-details-btn-${node.rowIndex}`}
        className="view-details-button"
        size="small"
        endIcon={!rowNode.expanded ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
        color="secondary"
        aria-label="View Details"
        onClick={() => (isMobile ? handleToggleMobileComponent(node, data) : handleToggleCardDetail(api, node))}
      >
        {!rowNode.expanded ? 'View Details' : 'Hide Details'}
      </Button>
      {mobileSubComponentActive &&
        (assetType === AssetType.CARDS ? (
          <CardDetailComponentMobile rowData={rowData} state={mobileDetails} setState={setMobileDetails} {...props} />
        ) : assetType === AssetType.DASHBOARDS ? (
          <DashboardDetailComponentMobile
            rowData={rowData}
            state={mobileDetails}
            setState={setMobileDetails}
            {...props}
          />
        ) : (
          ''
        ))}
    </>
  )
}
