import React from 'react'
import {
  Typography,
  Button,
  Popover,
  IconButton,
  Grid,
  Chip,
  Badge,
  Tabs,
  Tab,
  SnackbarContent,
  Stack,
  DialogTitle,
  DialogActions,
  DialogContent,
  Fab,
  Tooltip,
} from '@mui/material'
import { Close, Cancel, KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material'
import PropTypes from 'prop-types'
import SwipeableViews from 'react-swipeable-views'
import isEqual from 'lodash/isEqual'
import { cardColumnsFilterList } from '../../routes/Builder/Sidebar/utils'
import FilterDimension from './FilterDimension'
import './filterviewer.scss'

class FilterViewer extends React.Component {
  state = {
    appliedFilterSelected: null,
    activeTab: 0,
    showRls: false,
    systemFilterNoteOpen: true,
    systemFilterSelected: {
      name: 'Row Level Security',
      value: 'rls',
    },
    openCardFilter: this.props.isCard && !this.props.viewerFilters?.length,
  }

  systemFilterSidePanel = [
    {
      name: 'Row Level Security',
      value: 'rls',
    },
    {
      name: 'DataSet Filters',
      value: 'dataset_filters',
    },
  ]

  componentDidUpdate(prevProps) {
    const { renderedFilters, viewerFilters, isMobile } = this.props
    if (!isEqual(renderedFilters, prevProps.renderedFilters) && isMobile) {
      const filterArray = renderedFilters.filter(filter => {
        return !viewerFilters.some(viewerFilter => viewerFilter.display_name === filter.display_name)
      })
      this.setState({ appliedFilterSelected: filterArray[0] })
    }
  }

  componentDidMount() {
    const { renderedFilters, viewerFilters, isMobile } = this.props
    if (isMobile) {
      const filterArray = renderedFilters.filter(filter => {
        return !viewerFilters.some(viewerFilter => viewerFilter.display_name === filter.display_name)
      })
      this.setState({ appliedFilterSelected: filterArray[0] })
    }
  }

  handleTabChange = (event, value) => {
    const { openCardFilter } = this.state
    const { onFilterClick, selectedFilter, cardInfo, viewerFilters } = this.props
    if (!value) {
      const columnList = (cardInfo?.data && cardColumnsFilterList(cardInfo)) || []
      openCardFilter
        ? onFilterClick(columnList[0]?.field_name || columnList[0]?.ref_id, true)
        : onFilterClick(
            selectedFilter?.dimension ? selectedFilter.dimension : viewerFilters && viewerFilters[0]?.dimension
          )
    }
    this.setState({
      activeTab: value,
      systemFilterNoteOpen: true,
    })
  }

  getSystemFilterCount = value => {
    const { contextFilters } = this.props
    const filterValue = contextFilters && contextFilters.filter(filt => filt.source && filt.source === value)
    return (filterValue && filterValue.length) || 0
  }

  renderFilterItems() {
    const {
      selectedFilter,
      appliedFilter,
      activeFilters,
      onAddActiveFilter,
      onHighCardinalitySearch,
      renderFilterStatus,
      type,
      filterMetadataStatus,
      isMobile,
      cardsDatasetStatus,
      datasetId,
      columnArray,
      cardInfo,
      deviceType,
      dashboardInfo,
      isDashboard,
      dateFilterStatus,
      closeMobilePopover,
    } = this.props
    return (
      <FilterDimension
        className="filter-dimension"
        selectedFilter={selectedFilter}
        renderFilterStatus={renderFilterStatus}
        appliedFilter={appliedFilter}
        activeFilters={activeFilters}
        onAddActiveFilter={onAddActiveFilter}
        onHighCardinalitySearch={onHighCardinalitySearch}
        objectType={type}
        filterMetadataStatus={filterMetadataStatus}
        isMobile={isMobile}
        cardsDatasetStatus={cardsDatasetStatus}
        datasetId={datasetId}
        columnArray={columnArray}
        renderType={this.props.renderType}
        cardInfo={cardInfo}
        deviceType={deviceType}
        dashboardInfo={dashboardInfo}
        isDashboard={isDashboard}
        dateFilterStatus={dateFilterStatus}
        closeMobilePopover={closeMobilePopover}
      />
    )
  }

  renderAppliedFilterMobileItems() {
    const { appliedFilterSelected } = this.state
    const { deleteAppliedFilter } = this.props
    return appliedFilterSelected && appliedFilterSelected.display_name ? (
      <Chip
        variant="outlined"
        key={appliedFilterSelected.pattern}
        label={`${appliedFilterSelected.display_name} ${appliedFilterSelected.type} ${
          appliedFilterSelected.pattern
            ? appliedFilterSelected.pattern
            : appliedFilterSelected.value
            ? appliedFilterSelected.value
            : ''
        }`}
        title={appliedFilterSelected.pattern}
        deleteIcon={
          <div id="delete-applied-filter">
            <Cancel />
          </div>
        }
        onDelete={() => {
          deleteAppliedFilter(appliedFilterSelected)
          this.setState({ appliedFilterSelected: null })
        }}
      />
    ) : null
  }

  handleOnCardFilterClick = () => {
    this.setState(prevState => ({
      openCardFilter: !prevState.openCardFilter,
    }))
  }

  renderFilterSidePane(isCardFilters = false) {
    const { viewerFilters = [], onFilterClick, type, selectedFilter, isMobile, cardInfo } = this.props
    const filterNameKey = type === 'dashboard' ? 'filter_name' : 'dimension'
    const filterIdKey = type === 'dashboard' ? 'filter_id' : isCardFilters ? 'field_name' || 'ref_id' : 'dimension'
    const columnList = isCardFilters ? (cardInfo?.data && cardColumnsFilterList(cardInfo)) || [] : viewerFilters
    return (
      <section
        className={
          isMobile
            ? 'filter-details-list mobile'
            : this.state.openCardFilter && viewerFilters?.length
            ? 'filter-details-list card-filter'
            : 'filter-details-list'
        }
      >
        <ul id="filter-details-list-ul">
          {columnList.map((filter, index) => {
            const displayName =
              type !== 'dashboard' && (filter.display_name || filter.column_display_name)
                ? filter.display_name || filter.column_display_name
                : filter[filterNameKey]
            return (
              <li id={`filter-list-items-${index}`} key={index}>
                {this.getAppliedFilterCount(filter) ? (
                  <Badge
                    className="hint-badge"
                    data-cy={`filter-hint-badge-${index}`}
                    badgeContent={this.getAppliedFilterCount(filter)}
                  >
                    <Tooltip title={displayName}>
                      <Button
                        id={`filter-list-items-btn${index}`}
                        className={
                          (filter[filterNameKey] && selectedFilter[filterNameKey] === filter[filterNameKey]) ||
                          (filter.ref_id && filter.ref_id === selectedFilter.ref_id)
                            ? 'selected-tab'
                            : ''
                        }
                        onClick={() => onFilterClick(filter[filterIdKey] || filter.ref_id, isCardFilters)}
                      >
                        {displayName}
                      </Button>
                    </Tooltip>
                  </Badge>
                ) : (
                  <Tooltip title={displayName}>
                    <Button
                      id={`filter-list-items-btn${index}`}
                      className={
                        (filter[filterNameKey] && selectedFilter[filterNameKey] === filter[filterNameKey]) ||
                        (filter.ref_id && filter.ref_id === selectedFilter.ref_id)
                          ? 'selected-tab'
                          : ''
                      }
                      onClick={() => onFilterClick(filter[filterIdKey] || filter.ref_id, isCardFilters)}
                    >
                      {displayName}
                    </Button>
                  </Tooltip>
                )}
              </li>
            )
          })}
        </ul>
      </section>
    )
  }

  renderSystemFilterSidePanel() {
    const { systemFilterSelected } = this.state
    return (
      <section className="filter-details-list">
        <ul id="filter-details-list-ul">
          {this.systemFilterSidePanel.map((filter, index) => (
            <li id="filter-list-items" key={filter.name}>
              <Badge
                className="hint-badge"
                data-cy={`badge-count${index}`}
                badgeContent={this.getSystemFilterCount(filter.value)}
              >
                <Button
                  id={`filter-list-items-btn${index}`}
                  className={systemFilterSelected.name === filter.name ? 'selected-tab' : ''}
                  aria-label={`${filter.name} have ${this.getSystemFilterCount(filter.value)} filters`}
                  onClick={() => {
                    this.setState({
                      systemFilterSelected: filter,
                    })
                  }}
                >
                  {filter.name}
                </Button>
              </Badge>
            </li>
          ))}
        </ul>
      </section>
    )
  }

  renderSystemFilterItems() {
    const { systemFilterSelected, showRls } = this.state
    const { contextFilters } = this.props
    const systemFilter =
      contextFilters &&
      contextFilters.filter(systemFilter => systemFilter.source && systemFilter.source === systemFilterSelected.value)
    return (
      <div className="filter-items-container">
        {systemFilterSelected.value === 'rls' && (
          <>
            <div className="filter-rls">
              <Typography className="text-margin">
                {systemFilter && systemFilter.length ? 'Applied' : 'Not Applied'}
              </Typography>
              {Boolean(systemFilter && systemFilter.length) && (
                <Button
                  id="show-rls"
                  size="small"
                  variant="outlined"
                  className="show-button"
                  aria-label={showRls ? 'Hide RLS' : 'Show RLS'}
                  onClick={() => {
                    this.setState({ showRls: !showRls })
                  }}
                  endIcon={!showRls ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
                >
                  {showRls ? 'Hide' : 'Show'}
                </Button>
              )}
            </div>
            {systemFilter &&
              showRls &&
              systemFilter.map(filterVal => (
                <Chip
                  className="system-filter-chip"
                  variant="outlined"
                  key={filterVal.filterQl || `${filterVal.dimension} ${filterVal.type} ${filterVal.pattern}`}
                  label={
                    <div>{filterVal.filterQl || `${filterVal.dimension} ${filterVal.type} ${filterVal.pattern}`}</div>
                  }
                  title={filterVal.filterQl || `${filterVal.dimension} ${filterVal.type} ${filterVal.pattern}`}
                />
              ))}
          </>
        )}
        {systemFilterSelected.value === 'dataset_filters' && systemFilter && (
          <>
            {systemFilter.map((filterVal, index) => {
              const type = filterVal.type !== 'interval' ? filterVal.type : ''
              const chipValue =
                filterVal.type === 'filterQL'
                  ? filterVal.expression
                  : `${filterVal.column} ${type} ${
                      filterVal.pattern || filterVal.value || filterVal.time_period?.interval
                    }`

              return (
                <Chip
                  data-cy={`dataset-filter-label${index}`}
                  variant="outlined"
                  className="system-filter-chip"
                  key={chipValue}
                  label={chipValue}
                />
              )
            })}
          </>
        )}
      </div>
    )
  }

  getAppliedFilterCount = filter => {
    const { activeFilters } = this.props
    let count = 0
    activeFilters.forEach(eachFilter => {
      if (
        eachFilter &&
        ((eachFilter.ref_id && eachFilter.ref_id === filter.ref_id) ||
          (eachFilter.dimension && eachFilter.dimension === filter.dimension))
      ) {
        if (eachFilter.type !== 'regex' && eachFilter?.type !== 'contains' && eachFilter.type !== 'interval') {
          count = !eachFilter.pattern
            ? 0
            : Array.isArray(eachFilter.pattern)
            ? eachFilter.pattern.length
            : eachFilter.pattern.split(',').length || 0
        } else {
          count = 1
        }
      } else if (eachFilter.cardFilters && eachFilter.cardFilters.length && eachFilter.filter_id === filter.filter_id) {
        const _filter = eachFilter?.cardFilters[0]?.filter
        if (_filter?.type !== 'regex' && _filter?.type !== 'interval' && _filter?.type !== 'contains') {
          count =
            _filter?.pattern && Array.isArray(_filter.pattern)
              ? eachFilter.cardFilters[0].filter.pattern.length
              : _filter?.pattern
              ? eachFilter.cardFilters[0].filter.pattern.split(',').length
              : 0
        } else {
          count = 1
        }
      }
    })

    return count
  }

  renderFabButton(openCardFilter) {
    return (
      <Fab
        size="small"
        variant="extended"
        data-cy="toggle-card-filters-button"
        onClick={this.handleOnCardFilterClick}
        sx={{ m: 1, background: 'white', color: '#006649' }}
      >
        {!openCardFilter ? <KeyboardArrowUp sx={{ mr: 1 }} /> : <KeyboardArrowDown sx={{ mr: 1 }} />}
        {!openCardFilter ? 'Show Card Filters' : 'Hide Card Filters'}
      </Fab>
    )
  }

  renderAppliedFilterSidePanelMobile(filterArray) {
    const { appliedFilterSelected } = this.state

    return (
      <section className="filter-details-list">
        <ul id="filter-details-list-ul">
          {filterArray &&
            filterArray.map((filter, index) => (
              <Button
                key={filter.display_name}
                id={`filter-list-items-btn${index}`}
                className={
                  appliedFilterSelected && appliedFilterSelected.dimension === filter.dimension ? 'selected-tab' : ''
                }
                onClick={() => {
                  this.setState({
                    appliedFilterSelected: filter,
                  })
                }}
              >
                <li id="filter-list-items" key={filter.display_name}>
                  {filter.display_name}
                </li>
              </Button>
            ))}
        </ul>
      </section>
    )
  }

  renderMobileContainer = () => {
    const { openCardFilter } = this.state
    const {
      onDismissPopover,
      onApplyFilters,
      viewerFilters,
      renderedFilters,
      isCard,
      activeFilterMobileTab,
      isAppliedFilters,
    } = this.props
    const filterArray = renderedFilters.filter(filter => {
      return !viewerFilters.some(viewerFilter => viewerFilter.display_name === filter.display_name)
    })

    return (
      <div className="filters-popover-form mobileFilters" style={{ height: '100%' }}>
        <div className={activeFilterMobileTab && !filterArray.length ? 'mobileNoFilterMessage' : 'filter-details'}>
          {!activeFilterMobileTab && !isCard ? (
            this.renderFilterSidePane() // dashboard
          ) : isCard && viewerFilters?.length && !activeFilterMobileTab ? ( // card filters + viewer filters
            <Stack direction="column" className="filter-details-list">
              {this.renderFilterSidePane()}
              {this.renderFabButton(openCardFilter)}
              {openCardFilter && this.renderFilterSidePane(true)}
            </Stack>
          ) : (
            !activeFilterMobileTab && this.renderFilterSidePane(true) // card filters only
          )}
          {filterArray.length ? this.renderAppliedFilterSidePanelMobile(filterArray) : null}
          {!activeFilterMobileTab
            ? this.renderFilterItems()
            : filterArray.length
            ? this.renderAppliedFilterMobileItems()
            : null}
          {activeFilterMobileTab && !filterArray.length ? (
            <Typography className="mobileNoFilter">There are no filters available</Typography>
          ) : null}
        </div>
        <Grid container spacing={3} id="mobile-btn-container">
          <Grid
            item
            xs={!activeFilterMobileTab ? 6 : 12}
            className={!activeFilterMobileTab ? 'mobileFilterBtnLeft' : 'mobileMoreCloseBtn'}
            onClick={onDismissPopover}
            id="mobile-filter-close-btn"
          >
            Close
          </Grid>
          {!isAppliedFilters && (
            <Grid item xs={6} className="mobileFilterBtnRight" onClick={onApplyFilters} id="mobile-filter-apply-btn">
              Apply
            </Grid>
          )}
        </Grid>
      </div>
    )
  }

  renderBaseDesktop = children => {
    const { open, onDismissPopover } = this.props

    return (
      <Popover
        onClose={onDismissPopover}
        anchorEl={open}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={Boolean(open)}
        className="filters-popover-form"
      >
        {children}
      </Popover>
    )
  }

  renderLargeExportDesktop = children => {
    const { open } = this.props
    return open ? <div className="largeExportFilterWrapper">{children}</div> : null
  }

  renderDesktopRouter = () => {
    const { onDismissPopover, onApplyFilters, onClearFilters, viewerFilters, isCard, contextFilters, renderType } =
      this.props
    const { activeTab, systemFilterNoteOpen, openCardFilter } = this.state
    const isSystemFilter =
      contextFilters &&
      contextFilters.some(filt => filt.source && (filt.source === 'rls' || filt.source === 'dataset_filters'))

    const filterRoot = (
      <>
        <DialogTitle className="header-container">
          {isCard ? (
            <Tabs value={activeTab} onChange={this.handleTabChange} indicatorColor="primary" textColor="primary">
              <Tab key="Filters" data-cy="filter-tab-name" label={!viewerFilters.length ? 'CARD FILTERS' : 'FILTERS'} />
              {isSystemFilter && (
                <Tab key="SYSTEM APPLIED FILTERS" label="SYSTEM APPLIED FILTERS" data-cy="system-applied-filters" />
              )}
            </Tabs>
          ) : (
            <Typography variant="h2" className="title">
              FILTERS
            </Typography>
          )}
          <IconButton onClick={onDismissPopover} aria-label="Close Filter" id="close-filter-dialog">
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers sx={{ padding: 0, overflowY: 'hidden' }}>
          <SwipeableViews index={activeTab}>
            <div className="filter-details">
              {viewerFilters?.length && !isCard ? (
                this.renderFilterSidePane() // dashboard
              ) : isCard && viewerFilters?.length ? (
                <Stack direction="column" className="filter-details-list">
                  {this.renderFilterSidePane()}
                  {this.renderFabButton(openCardFilter)}
                  {openCardFilter && this.renderFilterSidePane(true)}
                </Stack>
              ) : (
                this.renderFilterSidePane(true)
              )}
              {this.renderFilterItems()}
            </div>
            <>
              <div className="filter-details">
                {this.renderSystemFilterSidePanel()}
                {this.renderSystemFilterItems()}
              </div>

              {systemFilterNoteOpen && (
                <SnackbarContent
                  className="snackbar-note"
                  role="alert"
                  action={
                    <Button
                      color="inherit"
                      size="small"
                      aria-label="Close alert"
                      onClick={() => {
                        this.setState({ systemFilterNoteOpen: !systemFilterNoteOpen })
                      }}
                    >
                      <Cancel />
                    </Button>
                  }
                  message="System applied filter are applied at the dataset level for the viewers"
                />
              )}
            </>
          </SwipeableViews>
        </DialogContent>
        <DialogActions>
          {!activeTab && (
            <>
              <Button
                id="clear-all-filters"
                variant="text"
                color="secondary"
                className="clear-filters-btn"
                onClick={onClearFilters}
              >
                Clear All Filters
              </Button>
              <Button
                id="apply-filters"
                variant="text"
                color="primary"
                className="apply-filters-btn"
                onClick={onApplyFilters}
              >
                Apply Filters
              </Button>
            </>
          )}
        </DialogActions>
      </>
    )

    return renderType !== 'largeExport' ? this.renderBaseDesktop(filterRoot) : this.renderLargeExportDesktop(filterRoot)
  }

  render() {
    const { isMobile } = this.props

    return isMobile ? this.renderMobileContainer() : this.renderDesktopRouter()
  }
}

FilterViewer.propTypes = {
  open: PropTypes.any,
  renderFilterStatus: PropTypes.object,
  type: PropTypes.string,
  selectedFilter: PropTypes.object,
  onApplyFilters: PropTypes.func,
  onClearFilters: PropTypes.func,
  onDismissPopover: PropTypes.func.isRequired,
  onFilterClick: PropTypes.func.isRequired,
  onSearchHighCardinalityList: PropTypes.func,
  appliedFilter: PropTypes.object,
  onAddActiveFilter: PropTypes.func,
}

export default FilterViewer
