import moment from 'moment'
import cloneDeep from 'lodash/cloneDeep'
import { isVirtualColumnCheck } from '../../routes/Builder/Sidebar/utils'

export const isDisableBetweenApply = (selectedStartDate, selectedEndDate, setBeginDateToday, setEndDateToday) => {
  let isDisable = false

  if (setBeginDateToday || setEndDateToday) {
    isDisable = false
  } else if (
    selectedStartDate === null ||
    selectedEndDate === null ||
    !moment(selectedStartDate).isValid() ||
    !moment(selectedEndDate).isValid()
  ) {
    isDisable = true
  }
  return isDisable
}

export const getSecondaryTimePeriodColumn = selectedDataset => {
  return selectedDataset?.fields_array?.find(eachColumn => {
    // Check if druid dataset selected
    if (
      selectedDataset?.database_type?.toLowerCase() === 'druid' &&
      eachColumn.type === 'timeseries' &&
      eachColumn.field_name !== '__time' &&
      !isVirtualColumnCheck(eachColumn)
    ) {
      return eachColumn
    }

    return undefined
  })
}

// Primary time period column name
export const getPrimaryTimePeriodColumnName = selectedDataset => {
  let columnName
  let timeseriesColumn
  if (selectedDataset?.database_type === 'Druid') {
    columnName = `${selectedDataset?.fields?.__time?.column_display_name}(${selectedDataset?.fields?.__time?.field_name})`
  } else {
    timeseriesColumn = selectedDataset?.fields_array?.find(eachColumn => eachColumn.type === 'timeseries')
    columnName = `${timeseriesColumn?.column_display_name}(${timeseriesColumn?.field_name})`
  }
  return columnName
}

export const checkMultipleTimeSeries = selectedDataset => {
  let timeSeriesCount = 0
  selectedDataset &&
    selectedDataset.fields_array &&
    selectedDataset.fields_array.forEach(column => {
      if (column?.type === 'timeseries' && !isVirtualColumnCheck(column)) {
        timeSeriesCount++
      }
    })
  return timeSeriesCount > 1
}

export const getButtonText = (
  mergedTimePeriod,
  intervalTime,
  renderedCardData,
  dateFilterStatus,
  selectedDatasetObjData,
  anchorDateFilterStatus
) => {
  let beginDate = null
  let endDate = null
  let buttonText = null
  /* eslint-disable camelcase */
  const { Fiscal, Gregorian } = mergedTimePeriod?.anchor_date
    ? anchorDateFilterStatus?.data
    : dateFilterChange(selectedDatasetObjData, dateFilterStatus)
  /* eslint-enable camelcase */
  const calendarType =
    mergedTimePeriod && mergedTimePeriod.calendar_type && mergedTimePeriod.calendar_type.toLowerCase() === 'fiscal'
      ? Fiscal
      : Gregorian

  if ((mergedTimePeriod?.set_begin_date_today || mergedTimePeriod?.set_end_date_today) && intervalTime?.includes(',')) {
    const splitInterval = intervalTime?.split(',')
    const start = splitInterval[0]?.trim()
    const end = splitInterval[1]?.trim()

    const startDateStr = mergedTimePeriod.set_begin_date_today ? start.slice(1) : start?.slice(2, start?.length - 1)
    const endDateStr = mergedTimePeriod.set_end_date_today
      ? end?.slice(0, end?.length - 1)
      : end?.slice(1, end?.length - 2)

    beginDate = mergedTimePeriod.set_begin_date_today ? 'Today' : startDateStr
    endDate = mergedTimePeriod.set_end_date_today ? 'Today' : endDateStr
    buttonText = 'Between'
  } else if (intervalTime && intervalTime?.includes('/')) {
    beginDate = intervalTime.split('/')[0]
    endDate = intervalTime.split('/')[1]
    buttonText = 'Between'
  } else {
    if (Object.prototype.hasOwnProperty.call(calendarType, intervalTime)) {
      buttonText = intervalTime
      beginDate = calendarType[intervalTime]['Begin Date']
      endDate = calendarType[intervalTime]['End Date']
    } else if (intervalTime !== 'last segment') {
      buttonText = intervalTime
    } else {
      if (renderedCardData && renderedCardData.rendered_interval) {
        beginDate = renderedCardData.rendered_interval.begin
        endDate = renderedCardData.rendered_interval.end
      }
      buttonText = intervalTime
    }
  }
  return { beginDate, endDate, buttonText }
}

export const dateFilterChange = (selectedDataset, dateFilterStatus) => {
  const dateFilter = cloneDeep(dateFilterStatus.data)
  if (selectedDataset && dateFilter) {
    dateFilter.Fiscal['All Time']['Begin Date'] = selectedDataset.min_date_range
    dateFilter.Fiscal['All Time']['End Date'] = selectedDataset.max_date_range
    dateFilter.Gregorian['All Time']['Begin Date'] = selectedDataset.min_date_range
    dateFilter.Gregorian['All Time']['End Date'] = selectedDataset.max_date_range
  }
  return dateFilter
}
