import React, { useEffect, useState } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
  Tooltip,
  Chip,
  IconButton,
  DialogContentText,
  TextField,
  Typography,
  Box,
  Grid,
} from '@mui/material'
import { Link } from 'react-router-dom'
import { CheckCircle, Cancel, Close, FilterList } from '@mui/icons-material'
import { withAnalytics } from '@praxis/component-analytics'
import cloneDeep from 'lodash/cloneDeep'
import styled from 'styled-components'

import { GreenfieldFilterViewer } from '../../../shared/shared'
import ViewerTimePeriod from '../../../shared/ViewerTimePeriod/ViewerTimePeriod.container'
import analyticsConfig from '../../../analytics'
import { getSecondaryTimePeriodColumn } from '../../../shared/TimePeriod/TimePeriodUtils'
import { MULTI_SELECT_CUSTOM_STR } from '../../../constants/StringConstants'

const SDialog = styled(Dialog)`
  .gf-MuiPaper-rounded.gf-MuiDialog-paperWidthSm {
    width: 800px;
  }
`
const LargeExportInit = props => {
  const {
    routeProps,
    availableFilters,
    cardId,
    selectedFilterId,
    appliedFilters,
    newLargeExportJobInfo,
    triggerLoading,
    filterMetadataStatus,
    cardInfo = {},
    selectedDatasetObj,
    secondaryTime = {},
    renderCard,
    deviceType,
    closeMobilePopover,
  } = props

  // Windows cant handle certain characters. The file wont open if it has any of them in the file name.
  const checkInitialTitle = title => title?.replace(/[<>:"/\|?*]/g, '_') // eslint-disable-line no-useless-escape

  const [filterDialogIsOpen, setFilterDialogIsOpen] = useState(false)
  const [timeViewerDialogIsOpen, setTimeViewerDialogIsOpen] = useState(false)
  const [jobCreatedDialogStatus, setJobCreatedDialogStatus] = useState(false)
  const [selectedFilters, setSelectedFilters] = useState(appliedFilters)
  const [largeExportTimePeriod, setLargeExportTimePeriod] = useState(null)
  const [newExportJob, setNewExportJob] = useState(newLargeExportJobInfo)
  const [createButtonStatus, setCreateButtonStatus] = useState(false)
  const [failedJobCreationDialogStatus, setFailedJobCreationDialogStatus] = useState(false)
  const [labelIllegalWindowsCharStatus, setLabelIllegalWindowsCharStatus] = useState(false)
  const [labelIllegalWindowsCharString, setLabelIllegalWindowsCharString] = useState('')
  const [cardTitle, setCardTitle] = useState(cardInfo?.data?.card_attribute?.card_title)
  const [largeExportSecTimePeriod, setLargeExportSecTimePeriod] = useState(null)

  const hasFilters = Boolean(props?.cardInfo?.data?.card_query_attribute?.viewer_filters)
  const filterOperName = filterMetadataStatus?.data?.filter_operations_aggregations.find(
    obj => obj.name === 'filter_operations'
  ).allOperations

  const isProduction = process.env.NODE_ENV === 'production'

  useEffect(() => {
    setCardTitle(checkInitialTitle(cardInfo?.data?.card_attribute?.card_title))
  }, [cardInfo?.data?.card_attribute?.card_title])

  useEffect(() => {
    // Updates this components selected filters when the card selected filters are also updated

    setSelectedFilters(appliedFilters)
  }, [appliedFilters])

  useEffect(() => {
    if (
      newLargeExportJobInfo?.status >= 200 &&
      newLargeExportJobInfo?.status < 300 &&
      (newExportJob === undefined || newExportJob.length > 0)
    ) {
      setNewExportJob(newLargeExportJobInfo.data)
      setJobCreatedDialogStatus(true)
      setCreateButtonStatus(false)
      triggerLoading()
      resetData()

      if (isProduction) {
        props.trackCustomEvent({
          type: 'LargeExportCreation',
          key: props.cardInfo?.data?.card_attribute?.dataset_id,
          value: newLargeExportJobInfo?.data,
        })
      }
    } else if (newLargeExportJobInfo && newLargeExportJobInfo.status >= 400 && newLargeExportJobInfo.status < 600) {
      triggerLoading()
      resetData()
      setCreateButtonStatus(false)
      setFailedJobCreationDialogStatus(true)

      if (isProduction) {
        props.trackCustomEvent({
          type: 'largeExportJobFail',
          key: props?.userInfo?.userType?.data?._id,
          value: newLargeExportJobInfo?.data,
        })
      }
    }
  }, [newLargeExportJobInfo])

  const handleTimeChange = (selectedTime, noAnchorDate, isSecondaryTime) => {
    const { timeViewerJson, isTimePeriodDrillThrough } = props
    const datasetId = cardInfo.data?.card_attribute?.dataset_id
    const secondaryTPColumn = getSecondaryTimePeriodColumn(selectedDatasetObj && selectedDatasetObj[datasetId]?.data)
    const secondaryTPColumnName = secondaryTPColumn?.field_name

    const timePeriodViewer = largeExportTimePeriod || timeViewerJson
    const timePeriodObj = cloneDeep(timePeriodViewer)
    const { granularity } = timePeriodObj
    const calendarType = timePeriodObj.calendar_type
    const newTimePeriod = { ...timePeriodObj, ...selectedTime }
    const isTimeIntervalArray = Array.isArray(newTimePeriod?.intervals) && newTimePeriod?.type === 'custom'
    if (selectedTime.granularity || (selectedTime.calendar_type && Object.keys(selectedTime).length === 1)) {
      if (isTimePeriodDrillThrough && selectedTime.granularity) {
        if (isTimeIntervalArray) {
          newTimePeriod.intervals = timePeriodObj.intervals
        } else {
          newTimePeriod.interval = timePeriodObj.interval
        }
      }
    } else {
      if (granularity) {
        newTimePeriod.granularity = granularity
      }
      if (calendarType) {
        newTimePeriod.calendar_type = calendarType
      }
    }

    if (selectedTime.compare_by === null || isTimeIntervalArray) {
      delete newTimePeriod.compare_by
    }

    if (noAnchorDate) {
      delete newTimePeriod.anchor_date
    }

    if (isTimeIntervalArray) {
      delete newTimePeriod.interval
    } else {
      delete newTimePeriod.intervals
    }

    if (isSecondaryTime) {
      setLargeExportSecTimePeriod({
        [secondaryTPColumnName]: newTimePeriod,
      })
    } else {
      setLargeExportTimePeriod(newTimePeriod)
    }
  }

  const handleCloseFailedJobCreationDialog = () => {
    setFailedJobCreationDialogStatus(false)
  }

  const updateSelectedFilters = newFilter => {
    setSelectedFilters(newFilter)
  }

  const handleFilterClick = status => {
    props.toggleDialog()
    setFilterDialogIsOpen(status)
  }

  const handleTimeViewerClick = status => {
    props.toggleDialog()
    setTimeViewerDialogIsOpen(status)
  }

  const toggleFilterDialog = () => {
    setFilterDialogIsOpen(!filterDialogIsOpen)
    props.toggleDialog()
  }

  const handleJobTitleChange = e => {
    const testReg = /[<>:"/\|?*]/g // eslint-disable-line no-useless-escape

    if (testReg.test(e.target.value)) {
      setLabelIllegalWindowsCharStatus(true)
      setCreateButtonStatus(true)
      setLabelIllegalWindowsCharString('Illegal characters: < > : " / \\ | ? *')
    } else {
      setLabelIllegalWindowsCharStatus(false)
      setLabelIllegalWindowsCharString('')
      setCreateButtonStatus(false)
      setCardTitle(e.target.value)
    }
  }

  const clearAllFilters = () => {
    setSelectedFilters([])
  }

  const resetData = () => {
    setSelectedFilters(appliedFilters)
    setLargeExportTimePeriod(null)
    setCardTitle(checkInitialTitle(props?.cardInfo?.data?.card_attribute?.card_title)) // eslint-disable-line camelcase
  }

  const renderFilters = () => {
    return (
      <Box>
        <Box>
          <Button
            id="card-view-filterby"
            color="secondary"
            startIcon={<FilterList />}
            onClick={() => handleFilterClick(true)}
            aria-label="Add Filter"
            className="icon-label-btn-props largeExportTriggetFilterBtn"
          >
            Filter By
          </Button>
        </Box>

        <Box className="filter-chip-container">
          {selectedFilters.map((filter, index) => {
            const valueLabel = filter.pattern
              ? `${filter.pattern.toString().split(',')[0]},...`
              : filter.value || filter.value === 0
              ? filter.value.toString()
              : filter.time_period?.interval
              ? filter.time_period.interval
              : `${filter.lowervalue}-${filter.uppervalue}`
            const titleLabel = filter.pattern
              ? filter.pattern.toString()
              : filter.value || filter.value === 0
              ? filter.value.toString()
              : filter.time_period?.interval
              ? filter.time_period.interval
              : `${filter.lowervalue} to ${filter.uppervalue}`
            const chipLabel =
              filter.type === 'interval'
                ? `${filter.display_name || filter.dimension}`
                : `${filter.dimension || filter.display_name} ${filterOperName?.[filter.type]}`
            return (
              <Tooltip
                key={`${filter.display_name}-${index}`}
                id={filter.dimension}
                title={`${chipLabel} ${titleLabel}`}
              >
                <Chip
                  variant="outlined"
                  className={filter.passedViaRouting ? 'uneditable-filter-chip' : 'editable-filter-chip'}
                  label={`${chipLabel} ${valueLabel}`}
                  key={filter.display_name}
                  deleteIcon={
                    <div id={`delete-card-viewer-filter-chip-${index}`}>
                      <Cancel />
                    </div>
                  }
                  onDelete={() => {
                    deleteFilter(filter)
                  }}
                />
              </Tooltip>
            )
          })}
        </Box>
      </Box>
    )
  }

  const deleteFilter = filter => {
    const removeFilterArr = selectedFilters.filter(item => item.ref_id !== filter.ref_id)
    setSelectedFilters(removeFilterArr)
  }

  const closeLargeExportDialog = () => {
    // On close, reset everything
    // We want to reset everything if users change anything on the card, they can get a clean request.

    resetData()
    props.toggleDialog()
  }

  const closeJobCreatedDialog = () => {
    setJobCreatedDialogStatus(false)

    // Clear status for any furture attempts without the user reloading the page
    setNewExportJob(undefined)
  }

  const createLargeExport = () => {
    const timePeriod = largeExportTimePeriod || props.timePeriod
    const secTimePeriod = largeExportSecTimePeriod || secondaryTime
    const _selectedFilters = cloneDeep(selectedFilters)

    const filters = _selectedFilters.map(item => {
      delete item.ref_id
      delete item.saveAsDefault
      delete item.obj_type

      return item
    })

    triggerLoading()
    props.toggleDialog()
    setCreateButtonStatus(true)

    props.createLargeExportJob({
      cardId: props.cardId,
      jobData: {
        secondary_time_periods: secTimePeriod,
        time_period: timePeriod,
        filters,
        request_label: cardTitle,
      },
    })

    setLargeExportSecTimePeriod(null)
    setLargeExportTimePeriod(null)
  }

  const renderFilterDialog = () => {
    return (
      <Dialog
        open={filterDialogIsOpen}
        className="largeExportInitDialogFilters"
        onClose={() => handleFilterClick(false)}
      >
        <GreenfieldFilterViewer
          routeProps={routeProps}
          open
          viewerFilters={availableFilters}
          type="card"
          id={Number(cardId)}
          onDismissPopover={toggleFilterDialog}
          selectedFilterId={selectedFilterId}
          isMobile={false}
          datasetId={props?.cardInfo?.data?.card_attribute?.dataset_id} // eslint-disable-line camelcase
          columnArray={props?.cardInfo?.data?.card_query_attribute?.columns} // eslint-disable-line camelcase
          renderType="largeExport" // todo: use as bool
          largeExportFilters={selectedFilters}
          updateSelectedFilters={updateSelectedFilters}
          clearAllFilters={clearAllFilters}
          handleApplyClick={handleFilterClick}
          cardInfo={renderCard[cardId]}
          deviceType={deviceType}
          closeMobilePopover={closeMobilePopover}
        />
      </Dialog>
    )
  }

  const hideViewerTimePeriod = () => {
    return cardInfo && cardInfo.status >= 200 && cardInfo.status < 300 && cardInfo.data
      ? !cardInfo.data.card_query_attribute || cardInfo.data.card_query_attribute.time_period.hide_time_period_viewer
      : false
  }

  const hideViewerSecondaryTimePeriod = () => {
    const datasetId = cardInfo.data?.card_attribute?.dataset_id
    const secondaryTPColumn = getSecondaryTimePeriodColumn(selectedDatasetObj && selectedDatasetObj[datasetId]?.data)
    const secondaryTPColumnName = secondaryTPColumn?.field_name

    return cardInfo?.data
      ? !cardInfo.data.card_query_attribute ||
          (secondaryTPColumnName &&
            Boolean(
              cardInfo.data.card_query_attribute.secondary_time_periods[secondaryTPColumnName]?.hide_time_period_viewer
            ))
      : false
  }

  const renderTimePeriod = () => {
    const largeExportBtnObj = constructBtnTxt()

    return (
      <Box>
        <Box className="largeExportTimeViewTriggerBtn">
          {props.selectedTimeDiv(false, true, handleTimeViewerClick, largeExportBtnObj)}
        </Box>
      </Box>
    )
  }

  const constructBtnTxt = () => {
    const { anchorDateFilterStatus, dateFilterStatus, selectedDatasetObj, renderCard } = props
    const renderedCardData = renderCard[props?.cardId]?.data
    const datasetId = cardInfo?.data?.card_attribute?.dataset_id // eslint-disable-line camelcase
    const secondaryTPColumn = getSecondaryTimePeriodColumn(selectedDatasetObj && selectedDatasetObj[datasetId]?.data)
    const secondaryTPColumnName = secondaryTPColumn?.field_name
    const timePeriod = !hideViewerTimePeriod()
      ? largeExportTimePeriod || props?.timePeriod
      : (secondaryTPColumnName && largeExportSecTimePeriod && largeExportSecTimePeriod[secondaryTPColumnName]) ||
        (secondaryTPColumnName && secondaryTime && secondaryTime[secondaryTPColumnName])
    let beginDate = null
    let endDate = null
    let buttonText = null
    const secTime = renderedCardData?.context?.rendered_payload?.secondary_time_periods || {}
    const rendererTimeInterval = !hideViewerTimePeriod()
      ? renderedCardData?.context?.rendered_payload?.time_period || {}
      : (secondaryTPColumnName && secTime[secondaryTPColumnName]) || {}
    const intervalTime =
      timePeriod && timePeriod?.interval
        ? timePeriod.interval
        : timePeriod?.intervals
        ? timePeriod.intervals
        : rendererTimeInterval && rendererTimeInterval?.interval
        ? rendererTimeInterval?.interval
        : ''

    const dateFilterObj = timePeriod?.anchor_date ? anchorDateFilterStatus : dateFilterStatus // eslint-disable-line camelcase

    if (dateFilterObj?.data && intervalTime) {
      const { Fiscal, Gregorian } = timePeriod?.anchor_date // eslint-disable-line camelcase
        ? anchorDateFilterStatus?.data
        : dateFilterChange(selectedDatasetObj[datasetId]?.data, dateFilterStatus)
      const calendarType =
        timePeriod && timePeriod?.calendar_type && timePeriod?.calendar_type.toLowerCase() === 'fiscal' // eslint-disable-line camelcase
          ? Fiscal
          : Gregorian

      if (intervalTime.includes('/')) {
        beginDate = intervalTime.split('/')[0]
        endDate = intervalTime.split('/')[1]
        buttonText = 'Between'
      } else {
        if (Object.prototype.hasOwnProperty.call(calendarType, intervalTime)) {
          buttonText = intervalTime
          beginDate = calendarType[intervalTime]['Begin Date']
          endDate = calendarType[intervalTime]['End Date']
        } else if (Array.isArray(intervalTime)) {
          buttonText = MULTI_SELECT_CUSTOM_STR
        } else if (intervalTime !== 'last segment') {
          buttonText = intervalTime
        } else {
          if (renderedCardData && renderedCardData.rendered_interval) {
            beginDate = renderedCardData.rendered_interval.begin
            endDate = renderedCardData.rendered_interval.end
          }
          buttonText = intervalTime
        }
      }
    }
    return {
      buttonText,
      beginDate,
      endDate,
    }
  }

  const dateFilterChange = (selectedDataset, dateFilterStatus) => {
    const dateFilter = cloneDeep(dateFilterStatus.data)
    if (selectedDataset && dateFilter) {
      dateFilter.Fiscal['All Time']['Begin Date'] = selectedDataset.min_date_range
      dateFilter.Fiscal['All Time']['End Date'] = selectedDataset.max_date_range
      dateFilter.Gregorian['All Time']['Begin Date'] = selectedDataset.min_date_range
      dateFilter.Gregorian['All Time']['End Date'] = selectedDataset.max_date_range
    }
    return dateFilter
  }

  const renderSuccessfulJobCreationDialog = () => {
    const cardName = newLargeExportJobInfo?.data?.data?.payload?.request_payload?.request_label // eslint-disable-line camelcase
    return (
      <Dialog
        open={jobCreatedDialogStatus}
        onClose={closeJobCreatedDialog}
        className="largeExportSuccessfulJobCreationDialogWrapper"
      >
        <DialogContent>
          <Box className="successCheckMark">
            <CheckCircle />
          </Box>

          <Box className="successMessage">
            <DialogContentText>Export request created for</DialogContentText>

            <DialogContentText>{cardName}</DialogContentText>
          </Box>

          <Box>
            <DialogContentText>
              Go to &nbsp; <Link to="/exportrequests">Export Requests</Link> &nbsp; page
            </DialogContentText>

            <Button onClick={closeJobCreatedDialog} className="largeExportSuccessfulJobCreationDialogCloseBtn">
              Close
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    )
  }

  const renderTimeViewerDialog = () => {
    const { cardRender, deviceType, renderCard, cardId } = props
    const timeViewerJson = cloneDeep(largeExportTimePeriod || props.timeViewerJson)
    const secTimePeriod = cloneDeep(largeExportSecTimePeriod || secondaryTime)

    return (
      <Dialog
        open={timeViewerDialogIsOpen}
        className="largeExportInitTimeViewer"
        onClose={() => handleTimeViewerClick(false)}
      >
        <DialogTitle style={{ display: 'flex', justifyContent: 'end' }}>
          <IconButton
            className="largeExportTimeViewerIconCloseBtn"
            aria-label="close"
            onClick={() => handleTimeViewerClick(false)}
          >
            <Close />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <ViewerTimePeriod
            cardInfo={renderCard[cardId]}
            cardRender={cardRender}
            isDashboard={false}
            isMobile={false}
            deviceType={deviceType}
            onTimePeriodChange={handleTimeChange}
            timePeriod={timeViewerJson}
            isTimePeriodDrillThrough={false}
            routeProps={routeProps}
            secondaryTime={secTimePeriod}
            hideViewerTimePeriod={hideViewerTimePeriod()}
            hideViewerSecondaryTimePeriod={hideViewerSecondaryTimePeriod()}
            selectedTimePeriod={{
              secondaryTimePeriods: secTimePeriod,
              timePeriod: timeViewerJson,
            }}
          />
        </DialogContent>
        <Button
          className="largeExportTimeViewerApplyBtn"
          data-cy="large-export-time-period-apply-btn"
          id="large-export-time-period-apply-btn"
          onClick={() => handleTimeViewerClick(false)}
        >
          Apply
        </Button>
      </Dialog>
    )
  }

  const renderFailedJobCreationDialog = () => {
    const message =
      newLargeExportJobInfo?.data?.message || newLargeExportJobInfo?.data?.message === ''
        ? newLargeExportJobInfo?.data?.message
        : newLargeExportJobInfo?.data

    return (
      <Dialog
        className="largeExportFailedJobDialogWrapper"
        open={failedJobCreationDialogStatus}
        onClose={handleCloseFailedJobCreationDialog}
      >
        <DialogContent>
          <Typography color="error">There was an error creating the large export job</Typography>
          <Typography color="error">{message}</Typography>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleCloseFailedJobCreationDialog} className="largeExportFailedJobCloseBtn">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  return (
    <div>
      {renderFilterDialog()}
      {renderTimeViewerDialog()}
      {renderSuccessfulJobCreationDialog()}
      {renderFailedJobCreationDialog()}

      <SDialog open={props.isOpen} onClose={closeLargeExportDialog}>
        <DialogTitle>
          <Grid container justifyContent="space-between" alignItems="center">
            Create Export Request
            <Box component="span">
              <IconButton aria-label="close" onClick={closeLargeExportDialog} id="largeExportInitDialogCloseBtn">
                <Close />
              </IconButton>
            </Box>
          </Grid>
        </DialogTitle>

        <DialogContent>
          <Box>
            <TextField
              className="largeExportRequestLabelInput"
              data-cy="large-export-label-name"
              id="large-export-label-name"
              label="Export label"
              defaultValue={cardTitle}
              error={labelIllegalWindowsCharStatus}
              helperText={labelIllegalWindowsCharString}
              onChange={handleJobTitleChange}
              variant="standard"
            />

            {(!hideViewerTimePeriod() || !hideViewerSecondaryTimePeriod()) && renderTimePeriod()}
          </Box>

          {hasFilters && <Box className="largeExportDialogSelectedFilters">{renderFilters()}</Box>}
        </DialogContent>

        <DialogActions>
          <Button className="largeExportInitCancelDialogBtn" onClick={closeLargeExportDialog} variant="outlined">
            Cancel
          </Button>
          <Button
            onClick={createLargeExport}
            className="largeExportInitCreateBtn"
            data-cy="create-large-export-request-btn"
            id="create-large-export-request-btn"
            disabled={createButtonStatus}
          >
            Create Export Request
          </Button>
        </DialogActions>
      </SDialog>
    </div>
  )
}

export default withAnalytics(analyticsConfig)(LargeExportInit)
