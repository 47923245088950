import React, { useEffect, useState } from 'react'
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Grid,
  IconButton,
  Typography,
  CircularProgress,
  Drawer,
  Tooltip,
  Alert,
  Snackbar,
} from '@mui/material'
import { createErrMsg, Spinner } from 'greenfield-utilities'
import { zonedTimeToUtc } from 'date-fns-tz'
import { add, format, isPast } from 'date-fns'
import { Cancel, CheckCircleOutline, Close, LiveHelp } from '@mui/icons-material'
import { useDispatch, useSelector } from 'react-redux'
import { useAnalytics } from '@praxis/component-analytics'
import isEmpty from 'lodash/isEmpty'
import analyticsConfig from '../../../../analytics'

import { METRIC_TYPES } from '../../../../constants/ArrayConstants'
import {
  clearCardSubscription,
  clearCardSubscriptionTest,
  clearDataAlert,
  clearDataAlertTest,
  createCardSubscription,
  createCardSubscriptionTest,
  createDataAlert,
  createDataAlertTest,
} from '../../../../../ducks/dataAlertAndSubscription'
import GreenfieldStepper from '../../../../shared/Stepper/GreenfieldStepper'
import { filterUserType } from '../../../../shared/MUIAutoComplete/Autocomplete'
import { clearServiceErrorMessage, displayServiceErrorMessage } from '../../../../../ducks/layout'
import {
  AMERICA_CHICAGO_TIME_ZONE,
  DATA_ALERT_TRIGGER_MESSAGE,
  SUBSCRIPTION_MESSAGE,
} from '../../../../constants/StringConstants'
import { clearCardDetailStatus } from '../../../../../ducks/cards'
import { getCardData } from '../../../../../ducks/cardPreview'
import {
  DATA_ALERT_ICONS,
  SUBSCRIPTION_ICONS,
  DATA_ALERT_STEPS,
  SUBSCRIPTION_STEPS,
  getExistingOwners,
  snakeCase,
  getEmailCriteriaText,
  getOwnerDetail,
} from '../DataAlertAndSubscription/Helper'
import apiConfig from '../../../../../config/apiConfig'
import DataAlertSubscriptionDetails from './InformationStep'
import DataAlertThresholdFilter from './ConfigStep'
import DataAlertEmailDetails from './EmailStep'

export const DATA_ALERT = 'Data Alert'
export const CARD_SUBSCRIPTION = 'Card Subscription'

const DataAlertSubscriptionDialog = props => {
  const {
    open,
    card,
    handleClose,
    timePeriod,
    secondaryTimePeriod,
    appliedFilters = [],
    defaultUser = {},
    subscriptionDetails = {},
    label = '',
  } = props
  const defaultMessage = label === DATA_ALERT ? DATA_ALERT_TRIGGER_MESSAGE : SUBSCRIPTION_MESSAGE
  const dataAlertSaveResponse = useSelector(state => state.dataAlertAndSubscription.dataAlertCreateStatus)
  const { cardDetailStatus } = useSelector(state => state.cards)
  const { fetchCardData } = useSelector(state => state.previewCard)
  const dataAlertTestSaveResponse = useSelector(state => state.dataAlertAndSubscription.dataAlertTestCreateStatus)
  const subscriptionSaveResponse = useSelector(state => state.dataAlertAndSubscription.cardSubscriptionCreateStatus)
  const subscriptionTestSaveResponse = useSelector(
    state => state.dataAlertAndSubscription.cardSubscriptionTestCreateStatus
  )
  const renderCard = useSelector(state => state.cards.renderCardStatus)
  const cardId = card?._id || card?.data?._id
  const dispatch = useDispatch()
  const { trackClick } = useAnalytics(analyticsConfig)

  const [openSnackBar, setOpenSnackBar] = useState(false)

  // Recipient Details
  const [userRecipientDetails, setUserRecipientDetails] = useState([])
  const [groupRecipientDetails, setGroupRecipientDetails] = useState([])

  // Owner Details
  const [userOwnerDetails, setUserOwnerDetails] = useState([])
  const [groupOwnerDetails, setGroupOwnerDetails] = useState([])

  // Subscription Details
  const [title, setTitle] = useState(subscriptionDetails?.name || '')
  const [description, setDescription] = useState(subscriptionDetails?.description || '')
  const [frequency, setFrequency] = useState(subscriptionDetails?.run_period || 'DAILY')
  const [checked, setChecked] = useState(true)
  const [startDate, setStartDate] = useState(
    subscriptionDetails?.start_time
      ? isPast(new Date(subscriptionDetails.start_time))
        ? format(new Date(), 'yyyy-MM-dd')
        : format(new Date(subscriptionDetails.start_time), 'yyyy-MM-dd')
      : format(new Date(), 'yyyy-MM-dd')
  )
  // subscriptions period max allowed is for 180 days
  const [endDate, setEndDate] = useState(
    subscriptionDetails?.end_time
      ? isPast(new Date(subscriptionDetails.end_time))
        ? format(add(new Date(), { days: 180 }), 'yyyy-MM-dd')
        : format(new Date(subscriptionDetails.end_time), 'yyyy-MM-dd')
      : format(add(new Date(), { days: 180 }), 'yyyy-MM-dd')
  )

  // Threshold filter
  const [appliedMetricFilters, setAppliedMetricFilters] = useState(subscriptionDetails?.threshold_filters || [])
  const [allMetricFilters, setAllMetricFilters] = useState([])
  const [displayRow, setDisplayRow] = useState(false)

  const defaultUserDetails = defaultUser?.lanId
    ? [
        {
          _id: defaultUser.lanId,
          type: 'user',
          user_group_name: `${defaultUser?.firstName} ${defaultUser?.lastName}`,
        },
      ]
    : []

  // Owners
  const [ownersList, setOwnersList] = useState(defaultUserDetails)
  const [isLoadingUserOwnerDetail, setIsLoadingUserOwnerDetail] = useState(false)

  // Message Details
  const [recipientsList, setRecipientsList] = useState(defaultUserDetails)
  const [emailSubject, setEmailSubject] = useState(subscriptionDetails?.notification?.subject || '')
  const [emailMessage, setEmailMessage] = useState(subscriptionDetails?.notification?.message || '')

  // Stepper
  const [activeStep, setActiveStep] = useState(0)
  const [completed, setCompleted] = useState(new Set())

  const [errorMessage, setErrorMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingSendNow, setIsLoadingSendNow] = useState(false)
  const [isSendNowButtonClicked, setIsSendNowButtonClicked] = useState(null)

  const STEPS = label === DATA_ALERT ? DATA_ALERT_STEPS : SUBSCRIPTION_STEPS
  const ICONS = label === DATA_ALERT ? DATA_ALERT_ICONS : SUBSCRIPTION_ICONS
  const isLastStep = activeStep === STEPS.length - 1

  const [disableSendNowButton, setDisableSendNowButton] = useState(false)

  useEffect(() => {
    if (subscriptionDetails?.permission) {
      const userIds = subscriptionDetails.permission.user_owners.join(',')
      const groupIds = subscriptionDetails.permission.group_owners.join(',')

      if (userIds) {
        setIsLoadingUserOwnerDetail(true)
        getOwnerDetail(true, userIds, setUserOwnerDetails, setIsLoadingUserOwnerDetail, dispatch)
      }
      if (groupIds) {
        setIsLoadingUserOwnerDetail(true)
        getOwnerDetail(false, groupIds, setGroupOwnerDetails, setIsLoadingUserOwnerDetail, dispatch)
      }
    }
    if (subscriptionDetails?.notification) {
      const userIds = subscriptionDetails.notification.user_recipients.join(',')
      const groupIds = subscriptionDetails.notification.group_recipients.join(',')

      if (userIds) {
        setIsLoadingUserOwnerDetail(true)
        getOwnerDetail(true, userIds, setUserRecipientDetails, setIsLoadingUserOwnerDetail, dispatch)
      }
      if (groupIds) {
        setIsLoadingUserOwnerDetail(true)
        getOwnerDetail(false, groupIds, setGroupRecipientDetails, setIsLoadingUserOwnerDetail, dispatch)
      }
    }
  }, [subscriptionDetails])

  useEffect(() => {
    if (userOwnerDetails.length || groupOwnerDetails.length) {
      setOwnersList(getExistingOwners(userOwnerDetails, groupOwnerDetails))
    }
  }, [userOwnerDetails, groupOwnerDetails])

  useEffect(() => {
    if (userRecipientDetails.length || groupRecipientDetails.length) {
      setRecipientsList(getExistingOwners(userRecipientDetails, groupRecipientDetails))
    }
  }, [userRecipientDetails, groupRecipientDetails])

  useEffect(() => {
    const cardId = card?.data?._id || card?._id
    if (
      cardId &&
      (card?.card_attribute?.status?.state !== 'disabled' || card?.data?.card_attribute?.status?.state !== 'disabled')
    ) {
      dispatch(clearCardDetailStatus())
      const cardSelectedColumns = card?.card_query_attribute?.columns || card?.data?.card_query_attribute?.columns
      if (cardSelectedColumns) {
        const allMetricFilters = cardSelectedColumns.filter(col => METRIC_TYPES.includes(col.type))
        setAllMetricFilters(allMetricFilters)
      }
      setEmailMessage(subscriptionDetails?.notification?.message || '')
      setEmailSubject(
        subscriptionDetails?.notification?.subject ||
          `${defaultMessage} - ${card._id ? card.card_attribute?.card_title : card.data.card_attribute?.card_title}`
      )
    }
  }, [card])

  useEffect(() => {
    if (cardDetailStatus?.status === 'failed') {
      dispatch(
        displayServiceErrorMessage(
          cardDetailStatus?.message?.data?.message || 'Card fetch request failed, please try again later!'
        )
      )
    }
  }, [cardDetailStatus])

  useEffect(() => {
    if (fetchCardData?.status === 'failed') {
      dispatch(
        displayServiceErrorMessage(createErrMsg(fetchCardData, 'Execute card request failed, please try again later!'))
      )
    } else if (fetchCardData?.data && typeof isSendNowButtonClicked === 'boolean') {
      isSendNowButtonClicked ? createTestSubscription() : createSubscription()
    }
  }, [fetchCardData, isSendNowButtonClicked])

  useEffect(() => {
    if (dataAlertSaveResponse?.error) {
      setErrorMessage(
        dataAlertSaveResponse.error.resp?.data?.description || 'Data Alert Save request failed, please try again later!'
      )
    }
    if (dataAlertSaveResponse?.error || dataAlertSaveResponse?.data) {
      setIsLoading(false)
    }
  }, [dataAlertSaveResponse])

  useEffect(() => {
    if (subscriptionSaveResponse?.error) {
      setErrorMessage(
        subscriptionSaveResponse.error.resp?.data?.description ||
          'Subscription Save request failed, please try again later!'
      )
    }
    if (subscriptionSaveResponse?.error || subscriptionSaveResponse?.data) {
      setIsLoading(false)
    }
  }, [subscriptionSaveResponse])

  useEffect(() => {
    if (dataAlertTestSaveResponse?.error) {
      dispatch(
        displayServiceErrorMessage(
          dataAlertTestSaveResponse.error.resp?.data?.description || 'Something went wrong, please try again later!'
        )
      )
      setIsLoadingSendNow(false)
      setTimeout(() => {
        setDisableSendNowButton(false)
        dispatch(clearServiceErrorMessage())
      }, 5000)
    } else if (dataAlertTestSaveResponse?.data) {
      setOpenSnackBar(true)
      setIsLoadingSendNow(false)
      dispatch(clearDataAlertTest())
      setTimeout(() => {
        setOpenSnackBar(false)
        setDisableSendNowButton(false)
      }, 5000)
    }
  }, [dataAlertTestSaveResponse])

  useEffect(() => {
    if (subscriptionTestSaveResponse?.error) {
      dispatch(
        displayServiceErrorMessage(
          subscriptionTestSaveResponse.error.resp?.data?.description || 'Something went wrong, please try again later!'
        )
      )
      setIsLoadingSendNow(false)
      setTimeout(() => {
        setDisableSendNowButton(false)
        dispatch(clearServiceErrorMessage())
      }, 5000)
    } else if (subscriptionTestSaveResponse?.data) {
      setOpenSnackBar(true)
      setIsLoadingSendNow(false)
      dispatch(clearCardSubscriptionTest())
      setTimeout(() => {
        setOpenSnackBar(false)
        setDisableSendNowButton(false)
      }, 5000)
    }
  }, [subscriptionTestSaveResponse])

  const createSubscription = () => {
    return label === DATA_ALERT
      ? dispatch(createDataAlert(getPayload()))
      : dispatch(createCardSubscription(getPayload()))
  }

  const createTestSubscription = () => {
    return label === DATA_ALERT
      ? dispatch(createDataAlertTest(getPayload()))
      : dispatch(createCardSubscriptionTest(getPayload()))
  }

  const isStepFailed = step => {
    if (!appliedMetricFilters.length && step === 1 && label === DATA_ALERT) {
      return true
    }
    if (!title && step === 0) {
      return true
    }
    if (!emailSubject && step === 2) {
      return true
    }
    return false
  }

  const handleStep = step => () => {
    setCompleted(new Set())
    setActiveStep(step)
  }

  const handleNext = () => {
    if (isLastStep) {
      setIsLoading(true)
      setActiveStep(prevActiveStep => prevActiveStep + 1)
      handleSave()
    } else if (activeStep === 1) {
      setActiveStep(prevActiveStep => prevActiveStep + 1)
      setCompleted(new Set([0, 1, 2]))
    } else {
      setActiveStep(prevActiveStep => prevActiveStep + 1)
    }
  }

  const handleBack = () => {
    setCompleted(new Set())
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  const handleSendNowButtonClick = () => {
    setIsLoadingSendNow(true)
    setDisableSendNowButton(true)
    if (!isEmpty(subscriptionDetails)) {
      setIsSendNowButtonClicked(true)
      executeCardAndCreateSubscription()
    } else {
      createTestSubscription()
    }
    trackClick(label === DATA_ALERT ? 'data-alert-test-email-clicked' : 'card-subscription-test-email-clicked')
  }

  const handleSave = () => {
    if (!isEmpty(subscriptionDetails)) {
      setIsSendNowButtonClicked(false)
      executeCardAndCreateSubscription()
    } else {
      label === DATA_ALERT ? dispatch(createDataAlert(getPayload())) : dispatch(createCardSubscription(getPayload()))
    }
  }

  const executeCardAndCreateSubscription = () => {
    if (cardId && !isEmpty(subscriptionDetails)) {
      dispatch(
        getCardData({
          id: cardId,
          json: {
            drill: [],
            filters: [],
            time_period: card.data ? card.data.card_query_attribute.time_period : card.card_query_attribute.time_period,
          },
        })
      )
    } else {
      isSendNowButtonClicked ? createTestSubscription() : createSubscription()
    }
  }
  const getPayload = () => {
    if (cardId) {
      const utcStartDate = zonedTimeToUtc(startDate, AMERICA_CHICAGO_TIME_ZONE)
      const utcEndDate = zonedTimeToUtc(endDate, AMERICA_CHICAGO_TIME_ZONE)
      const fieldSubstitutions = {}
      appliedMetricFilters.forEach(filter => {
        // gets value of the field_name from druid
        fieldSubstitutions[`(Current_${snakeCase(filter.display_name)})`] = filter.ref_id
      })
      const dependencyDatasets = fetchCardData?.data
        ? fetchCardData.data.execution_details.datastore_interaction.dependencies.dataset
        : renderCard
        ? renderCard[cardId]?.data.execution_details.datastore_interaction.dependencies.dataset
        : []
      const datasetIdsArray = dependencyDatasets.filter(item => item.type === 'fact').map(dataset => dataset._id)

      const payload = {
        name: title,
        description,
        card_id: cardId,
        ...(label === DATA_ALERT && {
          threshold_filters: appliedMetricFilters,
        }),
        filters: subscriptionDetails?.filters ? subscriptionDetails?.filters : appliedFilters,
        time_period: timePeriod,
        secondary_time_period: secondaryTimePeriod,
        permission: {
          user_owners: ownersList && filterUserType(ownersList, 'user'),
          group_owners: ownersList && filterUserType(ownersList, 'group'),
        },
        notification: {
          subject: emailSubject,
          message: emailMessage,
          field_substitution: fieldSubstitutions,
          user_recipients: recipientsList && filterUserType(recipientsList, 'user'),
          group_recipients: recipientsList && filterUserType(recipientsList, 'group'),
        },
        dependency: {
          object_type: 'DATASET',
          object_ids: datasetIdsArray,
        },
        run_period: frequency,
        start_time: utcStartDate,
        end_time: utcEndDate,
      }
      return payload
    }
  }

  const handleSnackBarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpenSnackBar(false)
  }

  const handleCloseAndResetState = () => {
    handleClose()
    setTitle('')
    setDescription('')
    setFrequency('DAILY')
    setOwnersList([
      {
        _id: defaultUser?.lanId,
        type: 'user',
        user_group_name: `${defaultUser?.firstName} ${defaultUser?.lastName}`,
      },
    ])
    setAppliedMetricFilters([])
    setRecipientsList([
      {
        _id: defaultUser?.lanId,
        type: 'user',
        user_group_name: `${defaultUser?.firstName} ${defaultUser?.lastName}`,
      },
    ])
    setEmailSubject(
      card?._id
        ? `${defaultMessage} - ${card.card_attribute?.card_title}`
        : card?.data
        ? `${defaultMessage} - ${card.data.card_attribute.card_title}`
        : ''
    )
    setEmailMessage(getEmailCriteriaText({ allMetricFilters, appliedMetricFilters }))
    setActiveStep(0)
    setCompleted(new Set())
    setErrorMessage('')
    setOpenSnackBar(false)
    setIsSendNowButtonClicked(false)
    dispatch(clearDataAlert())
    dispatch(clearCardSubscription())
    dispatch(clearDataAlertTest())
    dispatch(clearCardSubscriptionTest())
    dispatch(clearServiceErrorMessage())
  }

  // disable next button when alert title is empty (or) when no metric filters selected (or) when adding metric filter
  const isCreateDataAlertDisabled =
    (activeStep === STEPS.length - 1 &&
      (appliedMetricFilters.length === 0 || !title || !ownersList || displayRow || !recipientsList.length)) ||
    (STEPS.length - 1 && displayRow) ||
    isStepFailed(activeStep)

  // disable when not title and date range selected
  const isCreateSubscriptionDisabled =
    (activeStep === STEPS.length - 1 && (!title || !ownersList.length || !recipientsList.length)) ||
    isStepFailed(activeStep)

  const isDisabled = label === DATA_ALERT ? isCreateDataAlertDisabled : isCreateSubscriptionDisabled

  return (
    <Drawer anchor="right" open={open}>
      {activeStep !== STEPS.length && (
        <DialogTitle id="customized-dialog-title" onClose={handleCloseAndResetState}>
          <Grid container alignItems="center" justifyContent="space-between" alignContent="center">
            <Grid item xs={11}>
              {subscriptionDetails?.name ? `Edit ${label}` : `Create ${label}`}
              <Tooltip title={`Helpcenter information for ${label}`}>
                <a
                  href={`${apiConfig.help.url}/05_advanced/notifications_and_subscriptions/#${
                    label === DATA_ALERT ? 'greenfield-data-alerts' : 'greenfield-card-subscriptions'
                  }`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IconButton
                    onClick={() => {
                      return trackClick(
                        label === DATA_ALERT
                          ? 'data-alert-help-button-clicked'
                          : 'card-subscription-help-button-clicked'
                      )
                    }}
                  >
                    <LiveHelp />
                  </IconButton>
                </a>
              </Tooltip>
            </Grid>
            <Grid item>
              <Grid container alignItems="center" justifyContent="flex-end" alignContent="center">
                <IconButton id="alert-subscription-close-button" onClick={handleCloseAndResetState}>
                  <Close />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
          <GreenfieldStepper
            steps={STEPS}
            icons={ICONS}
            completed={completed}
            activeStep={activeStep}
            handleStep={handleStep}
            isStepFailed={isStepFailed}
          />
        </DialogTitle>
      )}
      <DialogContent dividers style={{ height: 550, width: 820 }}>
        {isLoadingUserOwnerDetail ? (
          <Spinner />
        ) : activeStep === 0 ? (
          <DataAlertSubscriptionDetails
            title={title}
            setTitle={setTitle}
            description={description}
            setDescription={setDescription}
            frequency={frequency}
            setFrequency={setFrequency}
            ownersList={ownersList}
            setOwnersList={setOwnersList}
            label={label}
            checked={checked}
            setChecked={setChecked}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            startDate={startDate}
          />
        ) : activeStep === 1 && label === DATA_ALERT ? (
          <DataAlertThresholdFilter
            appliedMetricFilters={appliedMetricFilters}
            allMetricFilters={allMetricFilters}
            setAppliedMetricFilters={setAppliedMetricFilters}
            appliedFilters={appliedFilters}
            setEmailMessage={setEmailMessage}
            displayRow={displayRow}
            setDisplayRow={setDisplayRow}
          />
        ) : (activeStep === 1 && label === CARD_SUBSCRIPTION) || (activeStep === 2 && label === DATA_ALERT) ? (
          <DataAlertEmailDetails
            recipientsList={recipientsList}
            setRecipientsList={setRecipientsList}
            emailSubject={emailSubject}
            setEmailSubject={setEmailSubject}
            emailMessage={emailMessage}
            setEmailMessage={setEmailMessage}
            appliedMetricFilters={appliedMetricFilters}
            allMetricFilters={allMetricFilters}
            label={label}
          />
        ) : (
          <Grid
            style={{ height: '100%' }}
            spacing={3}
            direction="column"
            container
            alignItems="center"
            justifyContent="center"
          >
            {isLoading && (
              <CircularProgress
                size={60}
                style={{
                  color: '#006649',
                }}
              />
            )}
            {dataAlertSaveResponse?.data && (
              // Subscription Success Message
              <>
                <Grid item>
                  <CheckCircleOutline style={{ fontSize: 100, color: '#006649' }} />
                </Grid>
                <Grid item>
                  <Typography variant="h6">Data Alert Subscription Created Successfully</Typography>
                </Grid>
              </>
            )}
            {dataAlertSaveResponse?.error && (
              // Subscription Error Message
              <>
                <Grid item>
                  <Typography data-cy="fail-message" variant="h6">
                    Data Alert Subscription Failed
                  </Typography>
                </Grid>
                <Grid item>
                  <Cancel style={{ fontSize: 100, color: '#eb0000' }} />
                </Grid>
                <Grid item>
                  <Typography variant="subtitle1" style={{ textAlign: 'center', color: '#eb0000' }}>
                    {errorMessage}
                  </Typography>
                </Grid>
              </>
            )}
            {subscriptionSaveResponse?.data && (
              // Subscription Success Message
              <>
                <Grid item>
                  <CheckCircleOutline style={{ fontSize: 100, color: '#006649' }} />
                </Grid>
                <Grid item>
                  <Typography variant="h6">Card Subscription Created Successfully</Typography>
                </Grid>
              </>
            )}
            {subscriptionSaveResponse?.error && (
              // Subscription Error Message
              <>
                <Grid item>
                  <Typography data-cy="subscription-fail-message" variant="h6">
                    Card Subscription Failed
                  </Typography>
                </Grid>
                <Grid item>
                  <Cancel style={{ fontSize: 100, color: '#eb0000' }} />
                </Grid>
                <Grid item>
                  <Typography variant="subtitle1" style={{ textAlign: 'center', color: '#eb0000' }}>
                    {errorMessage}
                  </Typography>
                </Grid>
              </>
            )}
          </Grid>
        )}
      </DialogContent>
      <DialogActions style={{ margin: '1rem' }}>
        {activeStep === STEPS.length ? (
          <>
            {dataAlertSaveResponse?.error && (
              <Button
                disabled={activeStep === 0}
                style={{ marginRight: 'auto', fontSize: 16, padding: '12px' }}
                color="secondary"
                onClick={handleBack}
                variant="outlined"
              >
                Previous
              </Button>
            )}
            <Button
              style={{ fontSize: 16, padding: '1rem', paddingLeft: '2rem', paddingRight: '2rem' }}
              color="secondary"
              onClick={() => {
                trackClick(label === DATA_ALERT ? 'data-alert-subscription-added' : 'card-subscription-added')
                handleCloseAndResetState()
              }}
            >
              Close
            </Button>
          </>
        ) : (
          <>
            {activeStep === STEPS.length - 1 && (
              <Button
                style={{
                  fontSize: 16,
                  padding: '1rem',
                  paddingRight: '2rem',
                  paddingLeft: '2rem',
                  marginRight: 'auto',
                }}
                color="secondary"
                disabled={cardDetailStatus?.status === 'failed' && (isDisabled || disableSendNowButton)}
                onClick={handleSendNowButtonClick}
              >
                {isLoadingSendNow && <CircularProgress size={18} sx={{ mr: 1 }} />}
                Send Now
              </Button>
            )}
            <Button
              disabled={activeStep === 0}
              style={{
                fontSize: 16,
                padding: '1rem',
                paddingRight: '2rem',
                paddingLeft: '2rem',
              }}
              color="secondary"
              onClick={handleBack}
            >
              Previous
            </Button>
            <Button
              color="primary"
              style={{ fontSize: 16, padding: '1rem', paddingRight: '2rem', paddingLeft: '2rem' }}
              onClick={handleNext}
              disabled={isDisabled}
              data-cy="next-button"
            >
              {activeStep === STEPS.length - 1
                ? subscriptionDetails?._id
                  ? label === DATA_ALERT
                    ? 'Update Alert'
                    : 'Update Subscription'
                  : label === DATA_ALERT
                  ? 'Create Alert'
                  : 'Create Subscription'
                : 'Next'}
            </Button>
            {openSnackBar && (
              <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                open={openSnackBar}
                autoHideDuration={5000}
                onClose={handleSnackBarClose}
              >
                <Alert onClose={handleSnackBarClose} severity="success">
                  Please check your email. You should be receiving an email in few minutes.
                </Alert>
              </Snackbar>
            )}
          </>
        )}
      </DialogActions>
    </Drawer>
  )
}

export default DataAlertSubscriptionDialog
