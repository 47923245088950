import React from 'react'
import PropTypes from 'prop-types'
import { AppBar, IconButton, Toolbar, Typography } from '@mui/material'
import { Close } from '@mui/icons-material'
import './ie-message.scss'

const IEMessage = ({ dismissIEMessage }) => (
  <AppBar position="static" className="ie-message">
    <Toolbar>
      <Typography variant="caption" className="flex-1 message-header">
        Greenfield works best with Google Chrome browser
      </Typography>
      <IconButton id="close-btn" className="close-icon" onClick={dismissIEMessage} aria-label="Close message">
        <Close />
      </IconButton>
    </Toolbar>
  </AppBar>
)

IEMessage.propTypes = {
  dismissIEMessage: PropTypes.func,
}

export default IEMessage
