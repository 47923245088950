import React from 'react'
import { Typography, Button, Chip, IconButton } from '@mui/material'
import PropTypes from 'prop-types'
import { Spinner } from 'greenfield-utilities'
import isEqual from 'lodash/isEqual'
import './admin.scss'
import { AddCircle, Clear } from '@mui/icons-material'

import { GreenfieldAutoComplete } from '../../shared/shared'

class AdminQuicklinks extends React.Component {
  state = {
    isAddQLContainerShown: false,
    addedQuicklinks: [],
    addedBusinessAreas: [],
    userHasGroupQuicklinks: [],
    newBusinessTags: [],
  }

  componentDidMount() {
    if (this.props.adminDetail && this.props.adminDetail.data && this.props.adminDetail.data.quick_links) {
      const { adminDetail } = this.props
      const addedBusinessAreas = adminDetail.data.quick_links.all_tags.map(owner => ({ label: owner }))
      this.props.getUserBusinessTags()
      this.setState({
        addedQuicklinks: adminDetail.data.quick_links.all_tags,
        addedBusinessAreas,
      })
    }
  }

  updateOnUserDashboardBusinessTagInfoStatus(prevProps) {
    const { userDashboardBusinessTagInfoStatus } = this.props
    if (
      userDashboardBusinessTagInfoStatus &&
      !isEqual(userDashboardBusinessTagInfoStatus, prevProps.userDashboardBusinessTagInfoStatus) &&
      userDashboardBusinessTagInfoStatus.status >= 200 &&
      userDashboardBusinessTagInfoStatus.status < 300 &&
      userDashboardBusinessTagInfoStatus.data &&
      userDashboardBusinessTagInfoStatus.data.all_tags.length
    ) {
      const userAllQuicklinks = userDashboardBusinessTagInfoStatus.data.all_tags
      const userHasGroupQuicklinks = userAllQuicklinks.filter(businessTag => businessTag?.type === 'group')
      this.setState({
        userHasGroupQuicklinks,
      })
    }
  }

  updateOnDetailsBusinessAreasStatus(prevProps, prevState) {
    const { detailsBusinessAreasStatus } = this.props

    if (
      detailsBusinessAreasStatus &&
      !isEqual(detailsBusinessAreasStatus, prevProps.detailsBusinessAreasStatus) &&
      detailsBusinessAreasStatus.status >= 200 &&
      detailsBusinessAreasStatus.status < 300 &&
      detailsBusinessAreasStatus.data &&
      detailsBusinessAreasStatus.data.length
    ) {
      const addedQLs = prevState.addedQuicklinks

      const businessTags = detailsBusinessAreasStatus.data.filter(businessTag => !addedQLs.includes(businessTag))

      const businessUseSuggestions = businessTags.map(item => ({
        label: item,
      }))

      this.setState({
        businessUseSuggestions,
      })
    }
  }

  updateOnSaveUserQuickLinksInfoStatus(prevProps) {
    const { saveUserQuickLinksInfoStatus } = this.props
    if (
      saveUserQuickLinksInfoStatus &&
      !isEqual(saveUserQuickLinksInfoStatus, prevProps.saveUserQuickLinksInfoStatus) &&
      saveUserQuickLinksInfoStatus.status >= 200 &&
      saveUserQuickLinksInfoStatus.status < 300 &&
      saveUserQuickLinksInfoStatus.data &&
      saveUserQuickLinksInfoStatus.data.all_tags.length
    ) {
      const addedQuicklinks = saveUserQuickLinksInfoStatus.data.all_tags
      this.setState({
        addedQuicklinks,
      })
    }
  }

  updateOnSaveGroupQuickLinksInfoStatus(prevProps) {
    const { saveGroupQuickLinksInfoStatus } = this.props
    if (
      saveGroupQuickLinksInfoStatus &&
      !isEqual(saveGroupQuickLinksInfoStatus, prevProps.saveGroupQuickLinksInfoStatus) &&
      saveGroupQuickLinksInfoStatus.status >= 200 &&
      saveGroupQuickLinksInfoStatus.status < 300 &&
      saveGroupQuickLinksInfoStatus.data &&
      saveGroupQuickLinksInfoStatus.data.quick_links &&
      saveGroupQuickLinksInfoStatus.data.quick_links.all_tags &&
      saveGroupQuickLinksInfoStatus.data.quick_links.all_tags.length
    ) {
      const addedQuicklinks = saveGroupQuickLinksInfoStatus.data.quick_links.all_tags
      this.setState({
        addedQuicklinks,
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    this.updateOnUserDashboardBusinessTagInfoStatus(prevProps, prevState)
    this.updateOnDetailsBusinessAreasStatus(prevProps, prevState)
    this.updateOnSaveUserQuickLinksInfoStatus(prevProps, prevState)
    this.updateOnSaveGroupQuickLinksInfoStatus(prevProps, prevState)
  }

  callSave = (topic, adminDetail, allSelectedTags) => {
    if (topic === 'groups' && adminDetail && adminDetail.data && adminDetail.data._id) {
      const groupData = {
        id: adminDetail.data._id,
        businessTags: allSelectedTags,
      }
      this.props.clearSaveUserBusinessTagsInfo()
      this.props.saveGroupBusinessTags(groupData)
    } else {
      this.props.clearSaveGroupBusinessTagsInfo()
      this.props.saveUserBusinessTags(allSelectedTags)
    }
  }

  handleOnClickAddQuicklinks = () => {
    // Show add new QL container
    this.setState({
      newBusinessTags: [],
      isAddQLContainerShown: true,
    })
  }

  handleSaveQuicklink = event => {
    // Save and updated newly added quicklinks
    event.preventDefault()
    const { addedBusinessAreas } = this.state
    const { adminDetail, topic } = this.props
    const allSelectedTags = addedBusinessAreas.map(ql => ql.label || ql)

    this.callSave(topic, adminDetail, allSelectedTags)

    this.setState({
      newBusinessTags: [],
      isAddQLContainerShown: false,
    })
  }

  handleCancelClick = event => {
    // Hide add new QL container
    event.preventDefault()
    const { addedQuicklinks } = this.state
    const addedBusinessAreas = addedQuicklinks.map(businessTag => {
      return { label: businessTag }
    })
    this.setState({
      addedBusinessAreas,
      newBusinessTags: [],
      isAddQLContainerShown: false,
    })
  }

  searchBusinessAreas = searchText => {
    // Clear detailsBusinessAreasStatus
    this.props.getDetailsBusinessAreas(searchText)
  }

  selectedBusinessTags = selections => {
    const { addedBusinessAreas, newBusinessTags } = this.state
    const selectedTags = addedBusinessAreas.map(ql => ql.label || ql)
    let index = -1

    if (isNaN(selections) && selections.label && selectedTags.filter(tag => tag === selections.label).length === 0) {
      newBusinessTags.push(selections)
      addedBusinessAreas.push(selections)
    } else if (Number.isInteger(selections)) {
      index = parseInt(selections, 10)

      newBusinessTags.splice(index, 1)

      const removeIdx = addedBusinessAreas.findIndex(tag => tag.label === selections.label)
      addedBusinessAreas.splice(removeIdx, 1)
    }

    this.setState({
      newBusinessTags,
      addedBusinessAreas,
    })
  }

  getAddQLOption = () => {
    const { detailsBusinessAreasStatus } = this.props
    const { businessUseSuggestions, newBusinessTags } = this.state

    return (
      <div className="field-margin">
        <div className="add-business-area">
          <GreenfieldAutoComplete
            isEditable
            label="Add Business Area"
            searchApi={this.searchBusinessAreas}
            searchStatus={detailsBusinessAreasStatus}
            suggestions={businessUseSuggestions}
            selectedSuggestions={this.selectedBusinessTags}
            labelText="Business Use"
          />
        </div>
        <div className="field-margin">
          <Button
            id="save-qucklink-btn"
            className="save-submit-btn"
            disabled={!newBusinessTags.length}
            onClick={this.handleSaveQuicklink}
            variant="text"
            color="primary"
            aria-label="Save Quicklink"
          >
            Save
          </Button>
          <Button
            id="cancel-qucklink-btn"
            className="cancel-btn"
            onClick={this.handleCancelClick}
            variant="text"
            color="secondary"
            aria-label="Cancel Add Quicklink"
          >
            Cancel
          </Button>
        </div>
      </div>
    )
  }

  handleBusinessTagItemToggle = item => {
    const { addedQuicklinks } = this.state
    const { topic, adminDetail } = this.props
    const newAddedQuicklinks = [...addedQuicklinks]

    if (addedQuicklinks.includes(item)) {
      const index = addedQuicklinks.findIndex(sel => sel === item)
      newAddedQuicklinks.splice(index, 1)
    } else {
      newAddedQuicklinks.push(item)
    }

    this.callSave(topic, adminDetail, newAddedQuicklinks)

    const addedBusinessAreas = newAddedQuicklinks.map(businessTag => {
      return { label: businessTag }
    })
    this.setState({
      addedQuicklinks: newAddedQuicklinks,
      addedBusinessAreas,
      isAddQLContainerShown: false,
    })
  }

  getQuicklinks = (quicklinksTags, isAdmin, isGroups, userHasGroupQuicklinks) => {
    const { userDashboardBusinessTagInfoStatus } = this.props

    return (
      <div className="quicklinks-list-container" data-cy="quicklinks-list-container">
        <div>
          {userDashboardBusinessTagInfoStatus && userDashboardBusinessTagInfoStatus.status === 'requested' && (
            <Spinner size="medium" layout="selfCentering" />
          )}
          {!isGroups &&
            userHasGroupQuicklinks &&
            userHasGroupQuicklinks.map((item, index) => {
              return (
                <Chip
                  label={item.tag}
                  key={index}
                  variant="outlined"
                  color="primary"
                  className="focusOutline"
                  tabIndex="0"
                />
              )
            })}
          {quicklinksTags.map((item, index) => {
            return isAdmin ? (
              <Chip
                variant="outlined"
                color="primary"
                label={item}
                key={index}
                onDelete={() => {
                  this.handleBusinessTagItemToggle(item)
                }}
                deleteIcon={
                  <IconButton tabIndex="0" className="quicklinkDeleteIcon">
                    <Clear />
                  </IconButton>
                }
                className="focusOutline"
                tabIndex="0"
              />
            ) : (
              <Chip label={item} key={index} variant="outlined" color="primary" className="focusOutline" tabIndex="0" />
            )
          })}
        </div>
      </div>
    )
  }

  render() {
    let isAdmin = false
    const { isAddQLContainerShown, addedQuicklinks, userHasGroupQuicklinks } = this.state
    const { topic, saveUserQuickLinksInfoStatus, adminDetail, defaultOwner, saveGroupQuickLinksInfoStatus, isMobile } =
      this.props

    const isGroups = topic === 'groups'
    const type = isGroups ? 'Group' : ''

    const key = isGroups
      ? this.props.adminDetail.data.group_name
      : `${this.props.adminDetail.data.first_name} ${this.props.adminDetail.data.last_name}`
    let message = `${type} ${key} has no Quicklinks`

    if (
      addedQuicklinks &&
      addedQuicklinks.length &&
      !isGroups &&
      userHasGroupQuicklinks &&
      userHasGroupQuicklinks.length
    ) {
      message = `Showing ${userHasGroupQuicklinks.length + addedQuicklinks.length} Quicklink(s)`
    } else if (addedQuicklinks && addedQuicklinks.length) {
      message = `Showing ${addedQuicklinks.length} Quicklink(s)`
    } else if (!isGroups && userHasGroupQuicklinks && userHasGroupQuicklinks.length) {
      message = `Showing ${userHasGroupQuicklinks.length} Quicklink(s)`
    }

    if (
      adminDetail.data &&
      ((type === 'Group' && adminDetail.data.edit_enabled === 'yes') ||
        (defaultOwner.data && adminDetail.data._id === defaultOwner.data._id))
    ) {
      isAdmin = true
    }

    const getAddQltn = () => {
      return !isMobile ? (
        <Button
          id="add-qucklink-btn"
          color="secondary"
          className="icon-label-btn-props"
          classes={{ root: 'add-btn' }}
          aria-label="Add Quicklinks"
          onClick={this.handleOnClickAddQuicklinks}
        >
          <AddCircle className="icon-left" />
          Add Quicklinks
        </Button>
      ) : (
        <IconButton id="add-qucklink-btn-mobile" onClick={this.handleOnClickAddQuicklinks}>
          <AddCircle className="align-icon" />
        </IconButton>
      )
    }

    return (
      <div className="dashboards-container">
        <div
          className={
            (saveUserQuickLinksInfoStatus && saveUserQuickLinksInfoStatus.status === 'requested') ||
            (saveGroupQuickLinksInfoStatus && saveGroupQuickLinksInfoStatus.status === 'requested')
              ? 'disable-on-request'
              : 'quicklinks-container'
          }
        >
          {((saveUserQuickLinksInfoStatus && saveUserQuickLinksInfoStatus.status === 'requested') ||
            (saveGroupQuickLinksInfoStatus && saveGroupQuickLinksInfoStatus.status === 'requested')) && (
            <Spinner size="medium" layout="selfCentering" />
          )}
          <div className="detail-btn-section">
            <Typography variant="subtitle1">{message}</Typography>
            {isAdmin && <div className="button-end">{getAddQltn()}</div>}
          </div>

          {isAddQLContainerShown && this.getAddQLOption()}
          {addedQuicklinks && this.getQuicklinks(addedQuicklinks, isAdmin, isGroups, userHasGroupQuicklinks)}
        </div>
      </div>
    )
  }
}

AdminQuicklinks.defaultProps = {
  adminDetail: {},
  topic: '',
  autoSuggest: {},
  fetchAutoSuggest: () => {},
  defaultOwner: {},
}

AdminQuicklinks.propTypes = {
  adminDetail: PropTypes.object,
  topic: PropTypes.string,
  autoSuggest: PropTypes.object,
  fetchAutoSuggest: PropTypes.func,
  defaultOwner: PropTypes.object,
}

export default AdminQuicklinks
