import React, { useState, useEffect } from 'react'

import {
  TextField,
  Typography,
  ListItem,
  ListItemText,
  Link,
  Checkbox,
  MenuItem,
  CircularProgress,
  Grid,
} from '@mui/material'
import Button from '@mui/material/Button'
import moment from 'moment'
import cloneDeep from 'lodash/cloneDeep'
import 'froala-editor/js/froala_editor.pkgd.min.js'
import 'froala-editor/css/froala_style.min.css'
import 'froala-editor/css/froala_editor.pkgd.min.css'
import Editor from 'react-froala-wysiwyg'
import 'froala-editor/js/plugins.pkgd.min.js'
import Viewer from 'react-froala-wysiwyg/FroalaEditorView'
import CryptoJS from 'crypto-js'
import { GreenfieldAutoComplete } from '../../shared/shared'
import { toolbarButtons } from '../../shared/util'

// Decrypt the FROALA_KEY
import apiConfig from '../../../config/apiConfig'
import Calendar from '../../shared/Calendar/Calendar'
import { BI_REPORTING_ASSETS_ROUTE } from '../../constants/StringConstants'

const VIDEO_UPLOAD_ENABLED_SETTINGS = {
  videoUpload: true,
  // NOTE: GREEN-12572 use relative path, don't bypass the proxy server
  videoUploadURL: BI_REPORTING_ASSETS_ROUTE,
  videoMaxSize: 50 * 1024 * 1024, // 50mb
  videoAllowedTypes: ['mp4', 'mov', 'mpg', 'avi', 'wmv', 'gifv', 'm4v'],
}

const VIDEO_UPLOAD_DISABLED_SETTINGS = {
  videoUpload: false,
  videoAllowedTypes: [],
  videoInsertButtons: [],
}

const NotificationAuthoring = ({
  createMode,
  cancelCreateNotification,
  editMode = {},
  notificationCreateAction,
  notificationEditAction,
  displayServiceErrorMessage,
  getDetailsAudienceAction,
  detailsAudienceStatus,
  currentUser,
  createNotification,
  createObjectNotificationStatus = {},
  updateNotification,
  updateNotificationStatus = {},
  notificationOptionType,
  routeObjectType = '',
  routeObjectId = -1,
  getCardMeta,
  cardInfoStatus,
  viewDashBoardInfoStatus,
  getViewDashboardInfo,
  getDatasetDetail,
  datasetDetailStatus,
  setRouteObjectId,
  setRouteObjectType,
  sendFireflyEvent,
  routeObjectNotificationId,
  getObjectNotificationBasedOnId,
  objectIdNotificationStatus = {},
  deleteObjectNotification,
  deleteObjectNotificationStatus,
}) => {
  const formDefaults = {
    notificationType: notificationOptionType,
    notificationTitle: '',
    notificationBody: '',
    expiry: '30',
    audience: '',
    objectType: '',
    objectId: '',
  }
  const objNotificationId = editMode?.message?.object_detail?.object_id
  const objNotificationType = editMode.message?.object_detail?.object_type.toLowerCase()
  const currentUserData = currentUser?.data

  const setFormObject = editObj => {
    return {
      notification_type: editObj?.type || formDefaults.notificationType,
      notification_title: editObj?.message
        ? editObj.message?.title || editObj.message?.subject
        : formDefaults.notificationTitle,
      notification_body: editObj?.message?.body || formDefaults.notificationBody,
      // eslint-disable-next-line camelcase
      expiry: editObj?.expiration?.expire_in_days.toString() || formDefaults.expiry,
      audience: editObj?.audience ? (editObj.audience?.target === 'all' ? 'all' : 'specify') : formDefaults.audience,
      objectType:
        editObj?.type === 'object-notification' || (routeObjectType && routeObjectId > 0)
          ? editObj?.object?.type || objNotificationType || routeObjectType?.toLowerCase()
          : formDefaults.objectType,
      objectId:
        editObj?.type === 'object-notification' || (routeObjectType && routeObjectId > 0)
          ? editObj?.object?._id || objNotificationId || routeObjectId
          : formDefaults.objectId,
    }
  }

  const [form, setFormValues] = useState(setFormObject(editMode))
  const [focusedAudience, setFocusedAudience] = useState('')
  const [suggestions, setSuggestions] = useState([])
  const [audienceList, setAudienceList] = useState(
    editMode?.audience ? (editMode.audience.target === 'all' ? [] : editMode.audience.target) : []
  )
  const [popup, setPopup] = useState(editMode?.delivery?.popup || false)

  const [isInLoadingState, setIsInLoadingState] = useState(false)
  const [calendarOpen, setCalendarOpen] = useState(false)
  const [selectedStartDate, setSelectedStartDate] = useState(
    editMode?.message?.time_to_live
      ? moment(editMode.message.time_to_live?.start_date).format('YYYY-MM-DDTHH:mm')
      : moment().format('YYYY-MM-DDTHH:mm')
  )
  const [selectedEndDate, setSelectedEndDate] = useState(
    editMode?.message?.time_to_live
      ? moment(editMode.message.time_to_live?.end_date).format('YYYY-MM-DDTHH:mm')
      : moment().format('YYYY-MM-DD') + 'T23:59'
  )
  const maxCalendarDate = moment().add('days', 30).format('YYYY-MM-DDTHH:mm')

  const expiryPeriodOptions = [
    { label: '7 Days', value: '7' },
    { label: '14 Days', value: '14' },
    { label: '28 Days', value: '28' },
    { label: '30 Days', value: '30' },
    { label: '90 Days', value: '90' },
    { label: '180 Days', value: '180' },
  ]
  const globalAudienceOptions = [
    { label: 'All', value: 'all' },
    { label: 'Specify', value: 'specify' },
  ]
  const objectTypeOptions = [
    { label: 'Card', value: 'card' },
    { label: 'Dashboard', value: 'dashboard' },
    { label: 'Dataset', value: 'dataset' },
  ]

  useEffect(() => {
    if (objNotificationId && currentUserData) {
      const objectList = objectOwnedOptions()
      const objIndex = objectList.findIndex(obj => obj.value === objNotificationId)

      if (objIndex === -1 && objNotificationType) {
        setRouteObjectId(objNotificationId)
        setRouteObjectType(objNotificationType)
      }
    }
  }, [objNotificationId, currentUserData])

  const isUpdateFormValue = objectIdNotificationStatus?.data && editMode && Object.keys(editMode).length
  useEffect(() => {
    if (routeObjectNotificationId) {
      setFormValues(setFormObject(editMode))
    }
  }, [isUpdateFormValue])

  useEffect(() => {
    if (routeObjectType && routeObjectId) {
      if (routeObjectType === 'card') {
        getCardMeta({ id: routeObjectId })
      } else if (routeObjectType === 'dashboard') {
        getViewDashboardInfo(routeObjectId)
      } else if (routeObjectType === 'dataset') {
        getDatasetDetail({ datasetId: `${routeObjectId}?field_groups=quick_metadata` })
      }

      if (routeObjectNotificationId) {
        getObjectNotificationBasedOnId({ id: routeObjectNotificationId })
      }
    }
  }, [routeObjectType, routeObjectId])

  useEffect(() => {
    if (detailsAudienceStatus) {
      if (detailsAudienceStatus.status === 'failed') {
        displayServiceErrorMessage('Fetch user group failed')
      } else if (detailsAudienceStatus.data && detailsAudienceStatus.data.length) {
        setSuggestions(
          detailsAudienceStatus.data.map(item => ({
            ...item,
            label: item.user_group_name,
          }))
        )
      }
    }
  }, [detailsAudienceStatus])

  useEffect(() => {
    setIsInLoadingState(createObjectNotificationStatus?.status === 'requested')
  }, [createObjectNotificationStatus])

  useEffect(() => {
    setIsInLoadingState(updateNotificationStatus?.status === 'requested')
  }, [updateNotificationStatus])

  const handleFormSave = event => {
    event.preventDefault()
    if (createMode) {
      const createNotificationData = {
        type: form.notification_type,
        message: {
          title: form.notification_title,
          body: form.notification_body,
          link: '',
        },
        delivery: {
          mode: form.audience === 'all' ? 'broadcast' : 'direct',
          channel: 'in-app',
          priority: 'normal',
          popup,
        },
        expire_in_days: parseInt(form.expiry),
      }
      if (form.audience === 'specify') {
        createNotificationData.audience_user_group = audienceList
      }
      if (form.notification_type === 'object-notification') {
        const currentDate = moment(moment.now()).utc()
        const startDate = moment(new Date(selectedStartDate)).utc()
        const endDate = moment(new Date(selectedEndDate)).utc()
        let formattedStartDate = startDate.format()
        let formattedEndDate = endDate.format()

        if (startDate < currentDate) {
          formattedStartDate = currentDate.format()
        }

        if (endDate < currentDate) {
          formattedEndDate = currentDate.format()
        }

        const payload = {
          subject: form.notification_title,
          body: form.notification_body,
          object_detail: {
            object_type: form.objectType,
            object_id: parseInt(form.objectId),
          },
          display_type: 'BANNER',
          time_to_live: {
            start_date: formattedStartDate,
            end_date: formattedEndDate,
          },
        }

        createNotification(payload)
        sendFireflyEvent(true, {
          eventName: 'createPublishObjectNotificationClicked',
          key: 'notification',
          data: {
            object_type: form.objectType,
          },
        })
      } else {
        notificationCreateAction(createNotificationData)
      }
    } else if (editMode) {
      const editNotificationData = {
        _id: editMode._id,
        type: form.notification_type,
        message: {
          title: form.notification_title,
          body: form.notification_body,
          link: '',
        },
        delivery: {
          mode: form.audience === 'all' ? 'broadcast' : 'direct',
          channel: 'in-app',
          priority: 'normal',
          popup,
        },
        expire_in_days: parseInt(form.expiry),
      }
      if (form.audience === 'specify') {
        editNotificationData.audience_user_group = audienceList
      }
      if (form.notification_type === 'object-notification') {
        editNotificationData.object = {
          _id: parseInt(form.objectId),
          type: form.objectType,
        }

        const currentDate = moment(moment.now()).utc()
        const endDate = moment(new Date(selectedEndDate)).utc()
        let formattedEndDate = endDate.format()

        if (endDate < currentDate) {
          formattedEndDate = currentDate.format()
        }
        const obj = {
          subject: editNotificationData.message.title,
          body: editNotificationData.message.body,
          object_detail: {
            object_type: editNotificationData.object.type,
            object_id: editNotificationData.object._id,
          },
          time_to_live: {
            end_date: formattedEndDate,
          },
        }

        setIsInLoadingState(true)
        updateNotification({
          id: editMode.id,
          data: obj,
        })

        sendFireflyEvent(true, {
          eventName: 'editPublishObjectNotificationClicked',
          key: 'notification',
          data: {
            object_type: form.objectType,
          },
        })
      } else {
        notificationEditAction(editNotificationData)
      }
    }
  }
  const updateNotificationBody = event => {
    setFormValues({
      ...form,
      notification_body: event,
    })
  }
  const updateField = event => {
    setFormValues({
      ...form,
      [event.target.name]: event.target.value,
    })
  }
  const handleOnOwnerFocus = value => {
    setFocusedAudience(value)
  }
  const objectOwnedOptions = () => {
    let ownedObjectMap = []
    if (currentUserData) {
      const ownedObjectList =
        (form.objectType === 'card' && currentUserData.owned_cards) ||
        (form.objectType === 'dashboard' && currentUserData.owned_dashboards) ||
        (form.objectType === 'dataset' && currentUserData.owned_datasets) ||
        []
      if (form.objectType === 'card') {
        ownedObjectMap = ownedObjectList.map(item => ({ label: item.card_attribute.card_title, value: item._id }))
      } else if (form.objectType === 'dashboard') {
        ownedObjectMap = ownedObjectList.map(item => ({ label: item.dashboard_title, value: item._id }))
      } else if (form.objectType === 'dataset') {
        ownedObjectMap = ownedObjectList.map(item => ({ label: item.dataset_name, value: item._id }))
      }
    }

    return ownedObjectMap.sort((a, b) => a.label.localeCompare(b.label))
  }

  const selectedViewerAccess = selections => {
    const _audienceList = cloneDeep(audienceList)
    if (isNaN(selections)) {
      selections.label = selections.user_group_name
      _audienceList.push(selections)
    } else {
      _audienceList.splice(selections, 1)
    }
    setAudienceList(_audienceList)
  }

  const checkDisableSaveBtnStatus = () => {
    const isObjectNotification = form.notification_type === 'object-notification'

    if (isObjectNotification) {
      // eslint-disable-next-line camelcase
      const { notification_body, notification_title, objectType } = form
      return !(Boolean(notification_body) && Boolean(notification_title) && Boolean(objectType))
    }

    return false
  }

  const toggleCalendarState = () => {
    setCalendarOpen(true)
  }

  const getObjectName = () => {
    const isRequested =
      cardInfoStatus?.status === 'requested' ||
      viewDashBoardInfoStatus?.status === 'requested' ||
      datasetDetailStatus?.status === 'requested'
    let objectName = ''
    if (routeObjectType === 'card') {
      objectName = cardInfoStatus?.data?.card_attribute?.card_title
    } else if (routeObjectType === 'dashboard') {
      objectName = viewDashBoardInfoStatus?.data?.dashboard_title
    } else if (routeObjectType === 'dataset') {
      objectName = datasetDetailStatus?.data?.dataset_business_name
    }
    return (
      <>
        {isRequested ? (
          <CircularProgress size={30} style={{ color: '#cccccc' }} />
        ) : (
          <Typography data-cy="object-name">{objectName}</Typography>
        )}
      </>
    )
  }

  return (
    <>
      <div className="createNotificationContainer">
        <div className="createDivContainer">
          <Typography variant="h3" data-cy="notification-label">
            {form.notification_type === 'object-notification'
              ? 'Create Object Notification'
              : 'Create Global Notification'}
          </Typography>
          {form.notification_type === 'global-notification' && (
            <>
              <div className="element-field">
                <Typography className="formField">Audience</Typography>
                <TextField
                  select
                  name="audience"
                  value={form.audience}
                  onChange={updateField}
                  SelectProps={{
                    displayEmpty: true,
                  }}
                  error={form.audience === formDefaults.audience}
                  helperText={form.audience === formDefaults.audience ? 'Select a value' : ''}
                  variant="standard"
                >
                  <MenuItem value="" disabled>
                    Audience
                  </MenuItem>
                  {globalAudienceOptions.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
              {form.audience === 'specify' && (
                <div className="element-field">
                  <Typography className="formField">Audience List</Typography>
                  <GreenfieldAutoComplete
                    isEditable
                    label="Add Audience"
                    searchApi={getDetailsAudienceAction}
                    suggestions={suggestions}
                    searchStatus={focusedAudience === 'audience' ? detailsAudienceStatus : {}}
                    selectedSuggestions={selectedViewerAccess}
                    default={audienceList?.map(item => item.user_group_name)}
                    labelText="Audience"
                    type="audience"
                    onOwnerFocus={handleOnOwnerFocus}
                  />
                </div>
              )}
            </>
          )}
          <form className="formBox">
            {form.notification_type === 'global-notification' && (
              <>
                <div className="element-field">
                  <label htmlFor="enable-popup-for-user">
                    Enable Popup for User
                    <Checkbox
                      aria-label="Enable Popup for User"
                      checked={popup}
                      onChange={() => {
                        setPopup(!popup)
                      }}
                      value="Enable Popup for User"
                      data-cy="enable-popup-for-user"
                    />
                  </label>
                </div>
                <div className="element-field">
                  <Typography className="formField">Expiry Period</Typography>
                  <TextField select name="expiry" value={form.expiry} onChange={updateField} variant="standard">
                    {expiryPeriodOptions.map(option => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
              </>
            )}
            {form.notification_type === 'object-notification' && (
              <>
                <div className="element-field">
                  <Typography className="formField">Object Type</Typography>
                  <TextField
                    select
                    disabled={Boolean(routeObjectType && routeObjectId > 0)}
                    name="objectType"
                    value={form.objectType}
                    onChange={updateField}
                    data-cy="selected-object-type"
                    SelectProps={{
                      displayEmpty: true,
                    }}
                    variant="standard"
                  >
                    <MenuItem value="" disabled>
                      Select Object Type
                    </MenuItem>
                    {objectTypeOptions.map((option, index) => (
                      <MenuItem key={option.value} value={option.value} data-cy={`object-type${index}`}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
                <div className="element-field">
                  {routeObjectType && routeObjectId > 0 ? (
                    <>
                      <Typography className="formField">Selected Object</Typography>
                      {getObjectName()}
                    </>
                  ) : (
                    <>
                      <Typography className="formField">Object</Typography>
                      <TextField
                        select
                        name="objectId"
                        value={form.objectId}
                        onChange={updateField}
                        data-cy="select-object"
                        SelectProps={{
                          displayEmpty: true,
                        }}
                        variant="standard"
                      >
                        <MenuItem value="" disabled>
                          Select Object
                        </MenuItem>
                        {objectOwnedOptions().map(option => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </>
                  )}
                </div>
                <div className="element-field calendar-section">
                  <Typography variant="body1">Notification Period</Typography>
                  <Calendar
                    id="start-date"
                    label="Start Date"
                    calendarOpen={calendarOpen}
                    deviceType="isDesktop"
                    toggleCalendarState={toggleCalendarState}
                    endDate={selectedEndDate}
                    defaultValue={selectedStartDate}
                    onChange={value => {
                      setSelectedStartDate(value)
                      if (selectedEndDate === null) {
                        setSelectedEndDate(selectedEndDate)
                      }
                    }}
                    minDate={selectedStartDate}
                    maxDate={maxCalendarDate}
                    isDashboard={false}
                  />
                  <Calendar
                    id="end-date"
                    label="End Date"
                    deviceType="isDesktop"
                    calendarOpen={calendarOpen}
                    toggleCalendarState={toggleCalendarState}
                    startDate={selectedStartDate}
                    defaultValue={selectedEndDate}
                    onChange={value => {
                      setSelectedEndDate(value)

                      if (selectedStartDate === null) {
                        setSelectedStartDate(selectedStartDate)
                      }
                    }}
                    minDate={selectedStartDate}
                    maxDate={maxCalendarDate}
                    isDashboard={false}
                  />
                </div>
              </>
            )}
            <div className="element-field">
              <Typography variant="body1">Notification Title</Typography>
              <TextField
                data-cy="notification-title"
                className="formTextField"
                multiline
                size="small"
                name="notification_title"
                onChange={updateField}
                value={form.notification_title}
                placeholder="Enter the title"
                variant="standard"
              />
            </div>
            <div className="element-field froala-container">
              <Typography variant="body1">Notification Body</Typography>
              <Editor
                label="Notification Body"
                aria-label="text editor"
                model={form.notification_body}
                onModelChange={updateNotificationBody}
                config={{
                  width: '80%',
                  attribution: false,
                  events: {
                    'froalaEditor.image.uploaded': (e, editor, response) => {
                      // reference this https://wysiwyg-editor.froala.help/hc/en-us/articles/115000593909-How-can-I-use-a-different-image-response-
                      const { link } = JSON.parse(response)

                      // NOTE: GREEN-12572 this relative route should be able to remain once we figure out go-proxy and fastly configs
                      editor.image.insert(
                        `/images/${link.split('/')[link.split('/').length - 1]}`,
                        false,
                        null,
                        editor.image.get(),
                        response
                      )

                      return false
                    },
                  },
                  key: CryptoJS.AES.decrypt(process.env.FROALA_KEY.toString(), apiConfig.froalaDecryptKey).toString(
                    CryptoJS.enc.Utf8
                  ),
                  fileUpload: true,
                  imageUpload: true,
                  // NOTE: GREEN-12572 use relative path, don't bypass the proxy server
                  imageUploadURL: BI_REPORTING_ASSETS_ROUTE,
                  // NOTE: GREEN-12572 use relative path, don't bypass the proxy server
                  fileUploadURL: BI_REPORTING_ASSETS_ROUTE,
                  fileMaxSize: 20 * 1024 * 1024, // 20mb
                  fileUploadMethod: 'post',
                  fileAllowedTypes: ['application/pdf'],
                  ...(form.notification_type === 'global-notification'
                    ? VIDEO_UPLOAD_ENABLED_SETTINGS
                    : VIDEO_UPLOAD_DISABLED_SETTINGS),
                  imageInsertButtons: ['imageBack', '|', 'imageUpload', 'imageByURL'],
                  fontFamily: {
                    'Arial,Helvetica,sans-serif': 'Arial',
                    'Georgia,serif': 'Georgia',
                    'Impact,Charcoal,sans-serif': 'Impact',
                    'Roboto,Arial,sans-serif': 'Roboto',
                    'Tahoma,Geneva,sans-serif': 'Tahoma',
                    "'Times New Roman',Times,serif": 'Times New Roman',
                    'Verdana,Geneva,sans-serif': 'Verdana',
                  },
                  fontFamilyDefaultSelection: 'Roboto',
                  fontSize: ['8', '9', '10', '11', '12', '14', '16', '18', '24', '30', '36', '48', '60', '72', '96'],
                  toolbarButtons: {
                    ...toolbarButtons,
                    moreRich: {
                      buttons:
                        form.notification_type === 'global-notification'
                          ? [...toolbarButtons.moreRich.buttons, 'insertVideo']
                          : toolbarButtons.moreRich.buttons,
                      buttonsVisible: 3,
                    },
                  },
                  zIndex: 999,
                }}
              />
            </div>
            <Grid container justifyContent="space-between">
              <Grid item>
                {!editMode?.object &&
                  editMode?.id &&
                  (editMode?.type === 'object-notification' || form.notification_type === 'object-notification') && (
                    <Button
                      color="error"
                      sx={{ border: '1px solid' }}
                      onClick={() => {
                        deleteObjectNotification({ id: editMode.id })
                        sendFireflyEvent(true, {
                          eventName: 'deleteObjectNotificationFromEditClicked',
                          key: 'notification',
                        })
                      }}
                      disabled={checkDisableSaveBtnStatus()}
                      data-cy="delete-notification-btn"
                    >
                      {deleteObjectNotificationStatus &&
                      deleteObjectNotificationStatus[editMode.id]?.status === 'requested' ? (
                        <CircularProgress />
                      ) : (
                        'Delete Notification'
                      )}
                    </Button>
                  )}
              </Grid>
              <Grid item>
                <Button onClick={cancelCreateNotification} color="secondary">
                  Cancel
                </Button>
                <Button
                  color="primary"
                  onClick={handleFormSave}
                  disabled={checkDisableSaveBtnStatus()}
                  data-cy="create-notification-btn"
                >
                  {isInLoadingState ? (
                    <CircularProgress size={20} />
                  ) : form.notification_type === 'object-notification' ? (
                    'Publish Notification'
                  ) : (
                    'Save Notification'
                  )}
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
        <div className="previewDivContainer">
          Preview
          <NotificationItem notificationData={form} />
        </div>
      </div>
    </>
  )
}

const NotificationItem = props => {
  const [expanded, setExpanded] = useState(false)
  const timeAgoProps = {
    variant: 'caption',
  }
  const notificationItem = {
    message: {
      title: props.notificationData.notification_title,
      body: props.notificationData.notification_body,
    },
  }
  const notificationTitleProps = {
    variant: 'subtitle2',
    noWrap: false,
  }
  const secondaryJSX = messageBody => {
    const noOfCharactersInBody = 40
    const displayMore = messageBody.length > noOfCharactersInBody
    const tmp = document.createElement('DIV')
    tmp.innerHTML = messageBody
    const formattedMessageBody = tmp.textContent || tmp.innerText
    return (
      <span>
        {expanded ? <Viewer model={messageBody} /> : formattedMessageBody.slice(0, noOfCharactersInBody)}
        {displayMore ? (expanded ? '   ' : '...  ') : null}
        <br />
        {displayMore ? (
          <Link
            className="linkStyles"
            align="left"
            component="button"
            variant="body2"
            onClick={() => {
              setExpanded(!expanded)
            }}
          >
            {expanded ? '<< Show Less' : 'Show More >>'}
          </Link>
        ) : null}
      </span>
    )
  }
  return (
    <div className="notificationItem">
      <ListItem className={`notificationExpanded${expanded}`} value={notificationItem.type}>
        <ListItemText
          primary={
            <ListItemText
              primary={notificationItem.message.title}
              primaryTypographyProps={notificationTitleProps}
              secondary={notificationItem.message.body ? secondaryJSX(notificationItem.message.body) : null}
            />
          }
          secondary={moment().fromNow()}
          secondaryTypographyProps={timeAgoProps}
        />
      </ListItem>
    </div>
  )
}

export default NotificationAuthoring
