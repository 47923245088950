import React, { forwardRef } from 'react'
import { Dialog, IconButton, Typography, Slide } from '@mui/material'
import { KeyboardBackspace } from '@mui/icons-material'
import { getBusinessInfoChip, getOwnerInfoChip } from '../../../shared/AgGrid/AgGridHelper'

export const DashboardDetailComponentMobile = ({ rowData, state, setState, isMobile }) => {
  const { mobileSubComponentActive } = state

  const Transition = forwardRef((props, ref) => <Slide direction="left" {...props} ref={ref} />)

  const handleCloseMobileSubComponent = () => {
    handleToggleMobileSubComponent(null)
  }

  const handleToggleMobileSubComponent = index => {
    if (isMobile) {
      setState({
        ...state,
        mobileSubComponentActive: !mobileSubComponentActive,
        mobileTableIndex: index,
      })
    }
  }

  return (
    <Dialog
      fullScreen
      open={mobileSubComponentActive}
      onClose={handleToggleMobileSubComponent}
      TransitionComponent={Transition}
      className="mobileListingViewDetailSlide isMobile"
    >
      <div className="mobileDashboardViewDetails">
        <IconButton onClick={handleCloseMobileSubComponent}>
          <KeyboardBackspace />
        </IconButton>

        <div className="viewDetailContent">
          <div className="mobileViewDetailDashName mobileViewDetailSection">
            <Typography className="expandLabel" variant="h3">
              Dashboard:
            </Typography>
            <Typography className="expandValue">{rowData.dashboard_title}</Typography>
          </div>

          <div className="mobileViewDetailDashBusinessArea mobileViewDetailSection">
            <Typography className="expandLabel" variant="h3">
              Business Area:
            </Typography>
            {getBusinessInfoChip(rowData.business_area)}
          </div>

          <div className="mobileViewDetailOwners mobileViewDetailSection">
            <Typography className="expandLabel" variant="h3">
              Owners:
            </Typography>
            {getOwnerInfoChip(rowData.owners_access)}
          </div>

          <div className="mobileViewDetailNumOfCards mobileViewDetailSection">
            <Typography className="expandLabel" variant="h3">
              Cards:
            </Typography>
            <Typography className="expandValue">{rowData.card_count}</Typography>
          </div>

          <div className="mobileViewDetailDataClass mobileViewDetailSection">
            <Typography className="expandLabel" variant="h3">
              Data Classification:
            </Typography>
            <Typography className="expandValue">{rowData.dashboard_data_classification}</Typography>
          </div>

          <div className="mobileViewDetailDashLastUpdate mobileViewDetailSection">
            <Typography className="expandLabel" variant="h3">
              Last Updated:
            </Typography>
            <Typography className="expandValue">{rowData.last_modified_time}</Typography>
          </div>
        </div>
      </div>
    </Dialog>
  )
}
