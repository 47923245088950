import { combineReducers } from 'redux'

import admin from './ducks/admin'
import layout from './ducks/layout'
import dataset from './ducks/dataset'
import favorites from './ducks/favorites'
import cards from './ducks/cards'
import dashboard from './ducks/dashboard'
import user from './ducks/user'
import previewCard from './ducks/cardPreview'
import filter from './ducks/filter'
import builder from './ducks/builder'
import calculatedField from './ducks/calculatedField'
import quicklinks from './ducks/quicklinks'
import timeperiod from './ducks/timeperiod'
import maps from './ducks/maps'
import metadata from './ducks/metadata'
import securityControl from './ducks/securityControl'
import notification from './ducks/notification'
import training from './ducks/training'
import largeExport from './ducks/LargeExport'
import datastore from './ducks/datastore'
import site from './ducks/site'
import dataAlertAndSubscription from './ducks/dataAlertAndSubscription'
import featureFlag from './ducks/featureFlag'

const rootReducer = combineReducers({
  cards,
  layout,
  admin,
  dataset,
  favorites,
  dashboard,
  user,
  previewCard,
  filter,
  builder,
  calculatedField,
  quicklinks,
  timeperiod,
  maps,
  metadata,
  securityControl,
  notification,
  training,
  largeExport,
  datastore,
  site,
  dataAlertAndSubscription,
  featureFlag,
})

export default rootReducer
