import React from 'react'
import PropTypes from 'prop-types'
import { Edit, Lens } from '@mui/icons-material'
import Select, { components } from 'react-select'

class AutoCompleteSelect extends React.Component {
  state = {
    selectedOption: null,
  }

  static getDerivedStateFromProps(nextProps) {
    if (nextProps.isReset) {
      return {
        selectedOption: null,
      }
    }
    return null
  }

  handleChange = selectedOption => {
    this.setState({ selectedOption })

    this.props.selectedSuggestions(selectedOption)
  }

  handleInputChange = input => {
    this.props.searchApi(input)
  }

  handleFocus = () => {
    this.props.searchApi('')
  }

  render() {
    const { selectedOption } = this.state
    const { Option } = components

    const IconOption = props => {
      return (
        <Option {...props}>
          {props.isFocused && <Lens className="dot-format" />}
          {props.data.label}
          {props.data.edit_enabled && <Edit />}
        </Option>
      )
    }

    return (
      <Select
        variant="standard"
        id={this.props.id}
        inputId={this.props.inputId}
        aria-label={this.props.ariaLabel || this.props.label}
        value={selectedOption}
        onChange={this.handleChange}
        onFocus={this.handleFocus}
        onInputChange={this.handleInputChange}
        options={this.props.suggestions}
        placeholder={this.props.label}
        components={{ Option: IconOption }}
        maxMenuHeight={this.props.isDefaultHeight ? 140 : 300}
        isDisabled={this.props.isDisabled}
        onKeyDown={event => this.props.shiftTab(event)}
      />
    )
  }
}

AutoCompleteSelect.defaultProps = {
  isReset: false,
  label: '',
  suggestions: [],
  searchApi: () => {},
  selectedSuggestions: () => {},
  shiftTab: () => {},
}

AutoCompleteSelect.propTypes = {
  isReset: PropTypes.bool,
  label: PropTypes.string,
  suggestions: PropTypes.array,
  searchApi: PropTypes.func,
  selectedSuggestions: PropTypes.func,
  shiftTab: PropTypes.func,
}

export default AutoCompleteSelect
