import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withAuth } from '@praxis/component-auth'
import { withAnalytics } from '@praxis/component-analytics'
import { getDetailsOwners } from '../../../ducks/builder'

import analyticsConfig from '../../analytics'

import SaveAs from './SaveAs'

export class SaveAsContainer extends React.Component {
  render() {
    const { auth = {} } = this.props
    const { session = {} } = auth
    const { userInfo = {} } = session

    return <SaveAs {...this.props} defaultUser={userInfo} />
  }
}

const mapStateToProps = state => ({
  detailsOwnersStatus: state.builder.detailsOwnersStatus || {},
})

const mapDispatchToProps = dispatch => ({
  getDetailsOwners(data) {
    dispatch(getDetailsOwners(data))
  },
})

SaveAsContainer.defaultProps = {
  openSaveAs: false,
  defaultUser: {},
}

SaveAsContainer.propTypes = {
  defaultUser: PropTypes.object,
  openSaveAs: PropTypes.bool,
  saveAsSuccess: PropTypes.func,
  cancelSaveAs: PropTypes.func,
}
export default withAnalytics(analyticsConfig)(connect(mapStateToProps, mapDispatchToProps)(withAuth()(SaveAsContainer)))
