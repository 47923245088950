import React from 'react'
import { Box, Drawer } from '@mui/material'
import styled from 'styled-components'
import Footer from './Footer'
import CardDashboardHelpContent from './CardDashboardHelpContent'
import InternalUserHelpContent from './InternalUserHelpContent'
import Header from './Header'
import DatasetHelpContent from './DatasetHelpContent'
import ExternalUserHelpContent from './ExternalUserHelpContent'

const SBox = styled(Box)`
  overflow: auto;
  margin: 1rem;
  flex-grow: 1;
`

const Help = props => {
  const { onChangeHelpDrawerToggle, currentUser, openHelpDrawer, isMobile } = props
  const isInternalUser = currentUser?.data?.user_type === 'internal'
  const isDataset = /^\/dataset\//i.test(window.location.pathname)
  const isSite = /^\/site\//i.test(window.location.pathname)

  return (
    <Drawer
      anchor="right"
      open={openHelpDrawer}
      onClose={onChangeHelpDrawerToggle}
      PaperProps={{
        sx: { width: isMobile ? '100%' : 550 },
      }}
    >
      <Header onChangeHelpDrawerToggle={onChangeHelpDrawerToggle} />
      <SBox>
        {isInternalUser && <CardDashboardHelpContent {...props} isSite={isSite} />}
        {isInternalUser && isDataset && <DatasetHelpContent isInternalUser={isInternalUser} />}
        {isInternalUser ? <InternalUserHelpContent /> : <ExternalUserHelpContent />}
      </SBox>
      <Footer isInternalUser={isInternalUser} />
    </Drawer>
  )
}

export default Help
