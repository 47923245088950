import React from 'react'
import PropTypes from 'prop-types'
import Downshift from 'downshift'
import { TextField, Chip, Paper, ListItem, ListItemText, ListItemAvatar } from '@mui/material'
import difference from 'lodash/difference'
import { Cancel, Group, Person } from '@mui/icons-material'
import classNames from 'classnames'
import { Spinner } from 'greenfield-utilities'

import '../Chip/chip.scss'

class DownshiftMultiple extends React.Component {
  state = {
    inputValue: '',
    suggestions: [],
    selectedItem: [],
    highlightedIndex: -1,
  }

  matchedSuggestions = []

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.default.length && difference(nextProps.default, prevState.selectedItem).length) {
      return {
        selectedItem: nextProps.default,
      }
    }
    return null
  }

  getSuggestions(inputValue) {
    let count = 0

    const response = this.props.suggestions.filter(suggestion => {
      const suggId = suggestion?._id?.toString() || ''
      const keep =
        (!inputValue ||
          suggestion?.label?.toLowerCase().includes(inputValue?.toLowerCase()) ||
          suggId?.toLowerCase().includes(inputValue?.toLowerCase())) &&
        count < 5

      if (keep) {
        count += 1
      }

      return keep
    })

    this.matchedSuggestions = response

    return response
  }

  renderSuggestion({ suggestion, index, itemProps, highlightedIndex, selectedItem }) {
    const isHighlighted = highlightedIndex === index
    const isSelected = (selectedItem || '').indexOf(suggestion.label) > -1

    return (
      <ListItem
        {...itemProps}
        key={suggestion && suggestion._id ? suggestion._id : index}
        selected={isHighlighted}
        component="div"
        style={{
          fontWeight: isSelected ? 500 : 400,
        }}
        button
      >
        <span className="owner-list-item" data-cy="owner-list-item">
          <ListItemAvatar>
            {suggestion.type === 'group' ? <Group fontSize="small" /> : <Person fontSize="small" />}
          </ListItemAvatar>
          <ListItemText primary={suggestion.label} secondary={suggestion._id} />
        </span>
      </ListItem>
    )
  }

  handleInputChange = event => {
    this.setState({ inputValue: event.target.value })
  }

  handleChange = item => {
    let { selectedItem } = this.state

    if (!selectedItem.includes(item) && !this.props.isSingleSelection) {
      selectedItem = [...selectedItem, item]
    } else {
      selectedItem = [...item]
    }
    this.setState({
      inputValue: '',
      selectedItem,
    })

    if (this.state.highlightedIndex > -1) {
      this.props.selectedSuggestions(this.matchedSuggestions[this.state.highlightedIndex])
    } else {
      const select = this.matchedSuggestions.filter(match => item === match.label)

      if (select.length) {
        this.props.selectedSuggestions(select[0])
      }
    }
  }

  handleValueChange = (item, stateValue) => {
    if (
      item.length > 2 &&
      !item.includes(',') &&
      ((stateValue && stateValue.selectedItem && stateValue.selectedItem.length === 0) ||
        !item.includes(stateValue.selectedItem))
    ) {
      this.props.searchApi(item)
    }
  }

  handleStateChange = changes => {
    if (changes.type === '__autocomplete_item_mouseenter__') {
      this.setState({ highlightedIndex: changes.highlightedIndex })
    }
  }

  handleDelete = (item, index) => {
    const selectedItem = [...this.state.selectedItem]
    selectedItem.splice(selectedItem.indexOf(item), 1)

    this.setState({ selectedItem })

    this.props.selectedSuggestions(index)
  }

  keyPressDelete = (event, item, index) => {
    if (event.which === 13 || event.which === 32) {
      this.handleDelete(item, index)
    }
  }

  render() {
    const { inputValue, selectedItem } = this.state
    const {
      searchStatus,
      labelText,
      label,
      isEditable,
      type,
      onOwnerFocus,
      showLabel,
      showEmptyFieldError,
      getObjectStatus,
      variant = 'standard',
    } = this.props
    const isSelectItemLen = selectedItem?.length > 0

    return (
      <div className="position-relative">
        <Downshift
          inputValue={inputValue}
          onChange={selection => this.handleChange(selection)}
          selectedItem={selectedItem}
          onInputValueChange={this.handleValueChange}
          onStateChange={this.handleStateChange}
        >
          {({
            getInputProps,
            getItemProps,
            isOpen,
            inputValue: inputValue2,
            selectedItem: selectedItem2,
            highlightedIndex,
          }) => (
            <div>
              <TextField
                disabled={!isEditable}
                variant={variant}
                required
                fullWidth
                className="chip-container-textfield"
                data-cy="chip-container-textfield"
                autoComplete="off"
                label={showLabel ? label : ''}
                onFocus={() => {
                  if (type) {
                    onOwnerFocus(type)
                  }
                }}
                aria-label={label}
                error={!isSelectItemLen && showEmptyFieldError}
                helperText={!isSelectItemLen && showEmptyFieldError ? 'This field is required.' : ''}
                InputProps={{
                  ...getInputProps({
                    startAdornment:
                      isSelectItemLen &&
                      selectedItem.map(
                        (item, index) =>
                          item && (
                            <Chip
                              disabled={!isEditable}
                              key={item + index}
                              label={item}
                              size="small"
                              tabIndex={-1}
                              style={variant === 'outlined' ? { margin: '0.1rem', marginTop: '0.5rem' } : {}}
                              onDelete={() => this.handleDelete(item, index)}
                              variant="outlined"
                              data-cy={`selected-owner-${index}`}
                              deleteIcon={
                                <Cancel
                                  tabIndex={0}
                                  role="button"
                                  style={{ color: 'white', fontSize: 20, marginLeft: '0.1rem' }}
                                />
                              }
                              className={classNames(
                                'chip-owners',
                                {
                                  labelText: labelText === 'Owners' || labelText === 'Viewers',
                                },
                                'chip-business',
                                {
                                  labelText: labelText === 'Business Use',
                                },
                                'chip-business',
                                {
                                  labelText:
                                    labelText === 'Card' || labelText === 'Dashboard' || labelText === 'Dataset',
                                },
                                { 'chip-site-home': labelText === 'SiteHome' }
                              )}
                            />
                          )
                      ),
                    onChange: this.handleInputChange,
                    placeholder: !showLabel ? label : '',
                    id: 'integration-downshift-multiple',
                  }),
                }}
              />
              {(searchStatus?.status === 'requested' || getObjectStatus?.status === 'requested') && (
                <Spinner style={{ margin: 'inherit' }} size="small" />
              )}
              {isOpen ? (
                <Paper square className="downshift-dropdown-suggestion" style={{ zIndex: 100 }}>
                  {this.getSuggestions(inputValue2).map((suggestion, index) =>
                    this.renderSuggestion({
                      suggestion,
                      index,
                      itemProps: getItemProps({ item: suggestion.label }),
                      highlightedIndex,
                      selectedItem: selectedItem2,
                    })
                  )}
                </Paper>
              ) : null}
            </div>
          )}
        </Downshift>
      </div>
    )
  }
}

DownshiftMultiple.defaultProps = {
  isEditable: true,
  label: '',
  default: [],
  suggestions: [],
  searchApi: () => {},
  selectedSuggestions: () => {},
  labelText: '',
  showLabel: false,
  showEmptyFieldError: false,
  isSingleSelection: false,
  getObjectStatus: {},
}

DownshiftMultiple.propTypes = {
  isEditable: PropTypes.bool,
  label: PropTypes.string,
  default: PropTypes.array,
  suggestions: PropTypes.array,
  searchApi: PropTypes.func,
  selectedSuggestions: PropTypes.func,
  labelText: PropTypes.string,
  showLabel: PropTypes.bool,
  showEmptyFieldError: PropTypes.bool,
  isSingleSelection: PropTypes.bool,
  getObjectStatus: PropTypes.object,
}

export default DownshiftMultiple
