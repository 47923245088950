import React, { useState } from 'react'
import { Typography, Accordion, AccordionSummary } from '@mui/material'
import { ExpandMore } from '@mui/icons-material'
import styled from 'styled-components'

const DescriptionContainer = styled.div`
  margin-left: 10px;
  white-space: break-spaces;
`

const DescriptionExpansion = styled(Accordion)`
  && {
    background: inherit;
    box-shadow: none;
  }
`
/**
 * @param {string} description card or dashboard description
 * @return {jsx}
 */
const ViewerDescription = ({ description = '', style = {} }) => {
  const [isDescriptionExpanded, toggleDescriptionExpanded] = useState(false)
  const hasLineBreaksOrVeryLongDescription = description.length > 50
  let crunchedDescription = ''
  const splitDescription = description.split('\n')
  if (splitDescription?.length > 1) {
    if (splitDescription[0] && splitDescription[0].length > 1) {
      crunchedDescription = splitDescription[0].length > 50 ? splitDescription[0].slice(0, 50) : splitDescription[0]
    } else {
      const replaceNextLineStr = description.replace(/(\r\n|\n|\r)/gm, ' ')
      crunchedDescription = description.length > 50 ? replaceNextLineStr?.slice(0, 50) : replaceNextLineStr
    }
  } else {
    crunchedDescription = description.slice(0, 50)
  }

  const descStyle = {
    color: style.descriptionFontColor || '#000',
    fontFamily: style.descriptionFont || 'Roboto',
    fontSize: `${style.descriptionFontSize}px` || '16px',
  }

  return description ? (
    <DescriptionContainer>
      {hasLineBreaksOrVeryLongDescription ? (
        <DescriptionExpansion
          open={isDescriptionExpanded}
          onClick={() => {
            toggleDescriptionExpanded(!isDescriptionExpanded)
          }}
        >
          <AccordionSummary expandIcon={<ExpandMore />} style={descStyle}>
            {!isDescriptionExpanded ? `${crunchedDescription}...` : description}
          </AccordionSummary>
        </DescriptionExpansion>
      ) : (
        <Typography data-cy="short-text-object-description" variant="caption">
          <b>Description:</b> <span style={descStyle}>{description}</span>
        </Typography>
      )}
    </DescriptionContainer>
  ) : null
}

export default ViewerDescription
