import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { fetchUserType } from '../../../ducks/user'
import Home from './Home'

const HomeContainer = ({ routeProps = {}, isMobile = false, deviceType, setHeaderTitle = () => {} }) => {
  const dispatch = useDispatch()

  const userResponse = useSelector(state => state.user)

  useEffect(() => {
    if (!userResponse?.userType?.data) {
      dispatch(fetchUserType())
    }
  }, [])

  return (
    <Home
      setHeaderTitle={setHeaderTitle}
      routeProps={routeProps}
      isMobile={isMobile}
      deviceType={deviceType}
      user={userResponse}
    />
  )
}

HomeContainer.propTypes = {
  routeProps: PropTypes.object,
  setHeaderTitle: PropTypes.func,
  user: PropTypes.object,
  isMobile: PropTypes.bool,
  deviceType: PropTypes.array,
}
export default HomeContainer
