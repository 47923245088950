import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Grid, Toolbar, Typography } from '@mui/material'
import { createErrMsg } from 'greenfield-utilities'
import { Add } from '@mui/icons-material'
import { TableFilter } from '../../shared/AgGrid/TableFilter'
import { AgGridTable } from '../../shared/AgGrid/AgGridTableClientSide'
import { getSiteList } from '../../../ducks/site'
import { displayServiceErrorMessage } from '../../../ducks/layout'
import { ColumnDefs } from './ColumnDefs'
import { SiteDetailComponent } from './SiteDetailComponent'
import { AddEditSiteDialog } from './AddEditSiteDialog'

const SiteListTable = ({ isMobile }) => {
  const dispatch = useDispatch()
  const gridRef = useRef()
  const [pageSize, setPageSize] = useState(10)
  const [columns, setColumns] = useState([])
  const [open, setOpen] = useState(false)

  const { allSitesStatus, updateSiteStatus } = useSelector(state => state.site)
  const { userType } = useSelector(state => state.user)

  const getSites = () => {
    dispatch(
      getSiteList({
        pageIndex: 1,
        pageSize: 1000,
        sortColumn: 'favorite:asc',
      })
    )
  }
  useEffect(() => {
    setColumns(ColumnDefs(isMobile))
  }, [isMobile, pageSize])

  useEffect(() => {
    if (allSitesStatus?.status === 'failed') {
      dispatch(displayServiceErrorMessage(createErrMsg(allSitesStatus, 'Site list has failed.')))
    }
  }, [allSitesStatus])

  useEffect(() => {
    if (updateSiteStatus?.data) {
      getSites()
    }
  }, [updateSiteStatus])

  const onGridReady = () => {
    getSites()
  }

  const handleDialogOpen = () => {
    setOpen(true)
  }

  return (
    <>
      <Toolbar classes={{ root: 'toolbar' }}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Typography classes={{ root: 'toolbar-title' }} tabIndex="0" aria-label="site listing">
            SITE LISTING
          </Typography>
          {userType?.data?.is_admin === 'yes' && (
            <Button color="secondary" startIcon={<Add />} onClick={handleDialogOpen}>
              Create Site
            </Button>
          )}
        </Grid>
      </Toolbar>
      <div id="sites">
        <TableFilter gridRef={gridRef} pageSize={pageSize} setPageSize={setPageSize} />
        <Typography
          variant="subtitle1"
          id="total-pages"
          aria-label="Displaying records"
          classes={{ root: 'total-records' }}
          data-cy="site-display-record-count"
        />
        <AgGridTable
          gridRef={gridRef}
          columns={columns}
          pageSize={pageSize}
          onGridReady={onGridReady}
          data={allSitesStatus?.data}
          isMobile={isMobile}
          masterDetail
          detailCellRendererFramework={({ data }) => <SiteDetailComponent rowData={data} />}
        />
      </div>
      {open && <AddEditSiteDialog isOpen={open} setIsOpen={setOpen} />}
    </>
  )
}

export default SiteListTable
