import { Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import React from 'react'
import { GridItem } from './CardDashboardHelpContent'

const DatasetHelpContent = ({ isInternalUser }) => {
  const { datasetPreviewStatus } = useSelector(state => state.dataset)
  return (
    <>
      <Typography variant="subtitle1" color="primary">
        Have questions about this dataset? Please reach out to the dataset owners
      </Typography>
      <GridItem
        isInternalUser={isInternalUser}
        owners={datasetPreviewStatus?.data?.owners_access}
        title="Dataset Owner(s)"
      />
    </>
  )
}

export default DatasetHelpContent
