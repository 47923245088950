import React from 'react'
import StaticDatePicker from '@mui/lab/StaticDatePicker'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import AdapterDateFns from '@mui/lab/AdapterMoment'
import { TextField } from '@mui/material'
import PropTypes from 'prop-types'
import moment from 'moment'

const StaticDatePickerComponent = ({ minDate, maxDate, id = '', ariaLabel = '', value, handleOnChange }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <StaticDatePicker
        minDate={moment(minDate)}
        maxDate={moment(maxDate)}
        displayStaticWrapperAs="desktop"
        aria-label={ariaLabel}
        id={id}
        inputFormat="YYYY-MM-DD"
        leftArrowButtonProps={{ 'aria-label': 'Prev month' }}
        rightArrowButtonProps={{ 'aria-label': 'Next month' }}
        onChange={handleOnChange}
        openTo="day"
        renderInput={props => <TextField variant="standard" {...props} />}
        views={['year', 'month', 'day']}
        value={value}
      />
    </LocalizationProvider>
  )
}

StaticDatePickerComponent.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  minDate: PropTypes.string,
  maxDate: PropTypes.string,
  handleOnChange: PropTypes.func,
  ariaLabel: PropTypes.string,
}

export default StaticDatePickerComponent
