import React from 'react'
import { Dialog, DialogContent, DialogTitle, Typography, Icon, Grid, IconButton } from '@mui/material'
import { Close } from '@mui/icons-material'
import { Spinner } from 'greenfield-utilities'
import styled from 'styled-components'
import greenfieldLogo from '../../images/greenfield-logo.svg'
import useBuildGreenfieldServices from '../shared/useBuildGreenfieldServices'
import useFetchFileContents from '../shared/useFetchFileContents'

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const BuildDetailsModal = ({ onHandleToggleAbout, isShowAbout, isMobile, Transition, DesktopTransition }) => {
  const uiReleaseDate = useFetchFileContents({ localFileName: 'UI_RELEASE_DATE.txt', isActive: isShowAbout })
  const uiVersion = useFetchFileContents({ localFileName: 'UI_VERSION.txt', isActive: isShowAbout })
  const buildGreenfieldServicesData = useBuildGreenfieldServices({ isActive: isShowAbout })

  return (
    <Dialog
      className="aboutGreenfieldDialog"
      onClose={onHandleToggleAbout}
      open={isShowAbout}
      fullScreen={isMobile}
      TransitionComponent={isMobile ? Transition : DesktopTransition}
      aria-labelledby="responsive-dialog-title"
    >
      <TitleContainer>
        <DialogTitle
          data-cy="aboutVersion"
          className="dialogTitle"
          id="about-dialog-title"
          onClose={onHandleToggleAbout}
        >
          GREENFIELD - Version {uiVersion || 'Not Available'}
        </DialogTitle>
        <IconButton aria-label="Close About" onClick={onHandleToggleAbout}>
          <Close />
        </IconButton>
      </TitleContainer>
      <DialogContent className="dialogContent">
        <Grid container className="gridContainer">
          <Grid item lg={4}>
            <Icon className="dialogIcon">
              <img src={greenfieldLogo} alt="Greenfield logo" />
            </Icon>
          </Grid>
          <Grid item lg={8} className="dialogContentText">
            <Typography data-cy="aboutBuildNumber">
              {`Build Number: ${process.env.UI_BUILD_NUMBER || 'Not Available'}`}
            </Typography>
            <Typography data-cy="aboutReleaseDate">{`Release Date: ${uiReleaseDate || 'Not available'}`}</Typography>
            <br />
            {buildGreenfieldServicesData ? (
              <>
                <Typography data-cy="aboutApiVersion">
                  {`API Version: ${buildGreenfieldServicesData?.build_version || 'Not Available'}`}
                </Typography>
                <Typography data-cy="aboutApiBuildNumber">
                  {`API Build Number: ${buildGreenfieldServicesData?.build_number || 'Not Available'}`}
                </Typography>
                <Typography data-cy="aboutApiReleaseDate">
                  {`API Release Date: ${buildGreenfieldServicesData?.deploy_date_time || 'Not Available'}`}
                </Typography>
              </>
            ) : (
              <Spinner layout="selfCentering" />
            )}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

export default BuildDetailsModal
