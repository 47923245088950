import React from 'react'
import { Dialog, DialogContent, Icon, Typography, DialogActions, Grid, Button } from '@mui/material'
import moment from 'moment'
import { GridItem } from '../DatasetDetailComponent'
import { AgGridTable } from '../../../shared/AgGrid/AgGridTableClientSide'

const columns = [
  {
    field: 'name',
    headerName: 'Card Name',
  },
  {
    field: 'owners',
    headerName: 'Owner(s)',
    valueGetter: ({ data }) => data.owners?.join(','),
  },
  {
    field: 'last_updated_date',
    headerName: 'Last Edited',
    valueGetter: ({ data }) =>
      data.last_updated_date && moment(data.last_updated_date, "YYYY-MM-DD'T'HH:mm:ssZ").fromNow(),
  },
  {
    field: 'view_count',
    headerName: 'Views',
  },
]

const DatasetDeleteDialog = ({ hasDependentCards, datasetDetail, handleCloseDeleteDatasetDialog }) => {
  return (
    <Dialog
      maxWidth={false}
      open={hasDependentCards}
      className="dataset-delete-popover-form"
      aria-labelledby="delete-dataset-confirmation"
    >
      <DialogContent>
        <div className="dataset-info">
          <Icon classes={{ root: 'warning-icon' }}>warning</Icon>
          <Typography variant="subtitle1" className="warning-content">
            Dataset {datasetDetail?.dataset_business_name} cannot be deleted because there are dependent cards on it.
            Delete cards listed below to delete the dataset.
          </Typography>
        </div>
        <div className="dataset-info-container">
          <Typography variant="h6" className="dataset-info">
            List of cards using the Dataset
          </Typography>
          <Grid container>
            <GridItem primary="Dataset Status" secondary="Active" />
            <GridItem primary="Number of Cards" secondary={datasetDetail?.cards?.length} />
            <GridItem primary="Number of Dashboards" secondary={datasetDetail?.dashboards?.length} />
          </Grid>
        </div>
        <AgGridTable data={datasetDetail?.cards} pageSize={5} columns={columns} />
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleCloseDeleteDatasetDialog}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DatasetDeleteDialog
