import React, { useEffect, useState } from 'react'
import { Spinner } from 'greenfield-utilities'
import { Typography, Toolbar, Tabs, Tab, Paper } from '@mui/material'
import { Box } from '@mui/system'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import AssetTable, { AssetType } from '../../shared/AssetTableSS/AssetTable'
import AllAssetsListing from './AllAssetsListing'

const SearchComp = ({ routeProps, setHeaderTitle, sendFireflyEvent }) => {
  const searchParams = new URLSearchParams(routeProps.location.search)
  const searchText = searchParams.get('keyword')?.trim()
  const [tabValue, setTabValue] = useState(0)
  const [allAssetsCount, setAllAssetsCount] = useState(0)
  const [cardCount, setCardCount] = useState(0)
  const [datasetCount, setDatasetCount] = useState(0)
  const [dashboardCount, setDashboardCount] = useState(0)

  const currentUser = useSelector(state => state.user.userType)
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue)
    sendFireflyEvent(true, {
      eventName: 'globalSearchTabChange',
      key: event.target.name,
      data: event.target.name,
    })
  }

  useEffect(() => {
    setHeaderTitle('Search')
  }, [])

  useEffect(() => {
    setTabValue(0)
  }, [searchText])

  if (!currentUser?.data) {
    return <Spinner />
  }
  return currentUser?.data?.application_data?.ui?.global_search_disabled ? (
    <Box m={2} sx={{ textAlign: 'center' }}>
      Global Search is disabled, please enable on Settings menu
    </Box>
  ) : (
    <>
      <Toolbar classes={{ root: 'toolbar' }}>
        <Typography classes={{ root: 'toolbar-title' }} tabIndex="0" aria-label="search">
          SEARCH
        </Typography>
      </Toolbar>
      {searchText && (
        <Paper elevation={0}>
          <Box m={4}>
            <Typography
              variant="h2"
              id="total-results"
              aria-label="Displaying records"
              classes={{ root: 'total-records' }}
              data-cy="card-display-record-count"
            >
              {`Results for "${searchText}"`}
            </Typography>
          </Box>
          <Tabs value={tabValue} onChange={handleTabChange} variant="fullWidth">
            <Tab
              name="All Assets Tab"
              label={<Typography variant="subtitle1">{`All Assets (${allAssetsCount})`}</Typography>}
            />
            <Tab name="Cards Tab" label={<Typography variant="subtitle1">{`Cards (${cardCount})`}</Typography>} />
            <Tab
              name="Dashboards Tab"
              label={<Typography variant="subtitle1">{`Dashboards (${dashboardCount})`}</Typography>}
            />
            <Tab
              name="Datasets Tab"
              label={<Typography variant="subtitle1">{`Datasets (${datasetCount})`}</Typography>}
            />
          </Tabs>
        </Paper>
      )}
      <>
        {!searchText ? (
          <Box m={2} sx={{ textAlign: 'center' }}>
            <Typography variant="subtitle1">Please enter text to search assets</Typography>
          </Box>
        ) : (
          <>
            <TabPanel value={tabValue} index={0}>
              <AllAssetsListing
                searchText={searchText}
                cardCount={cardCount}
                datasetCount={datasetCount}
                dashboardCount={dashboardCount}
                setTabValue={setTabValue}
                setDashboardCount={setDashboardCount}
                setDatasetCount={setDatasetCount}
                setCardCount={setCardCount}
                setAllAssetsCount={setAllAssetsCount}
              />
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <AssetTable searchKeyword={searchText} assetType={AssetType.CARDS} />
            </TabPanel>
            <TabPanel value={tabValue} index={2}>
              <AssetTable searchKeyword={searchText} assetType={AssetType.DASHBOARDS} />
            </TabPanel>
            <TabPanel value={tabValue} index={3}>
              <AssetTable searchKeyword={searchText} assetType={AssetType.DATASETS} />
            </TabPanel>
          </>
        )}
      </>
    </>
  )
}

const TabPanel = props => {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

export default SearchComp
