import { Grid, Typography, Toolbar, Paper } from '@mui/material'
import { ArrowBackIos } from '@mui/icons-material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Spinner } from 'greenfield-utilities'
import { Link } from 'react-router-dom'
import { getAllSubscriptionsByCardId } from '../../../../../ducks/dataAlertAndSubscription'
import ContentTable from '../../../Profile/ContentTable'
import { ACTIVE, INACTIVE } from '../../../../shared/AssetTableSS/StatusFilters'
import { SUBSCRIPTIONS_PAGE_SIZE } from '../../../Profile/Profile'

const SUBSCRIPTION_STATUS_TYPE = {
  ACTIVE: 'LIVE',
  INACTIVE: 'EXPIRED',
}

const DataAlertSubscriptionListing = ({ setHeaderTitle, routeProps, isMobile, trackEvent }) => {
  const dispatch = useDispatch()
  const [notifications, setNotifications] = useState(null)
  const [statusType, setStatusType] = useState(ACTIVE)
  const [isLoading, setIsLoading] = useState(false)
  const notificationsResponse = useSelector(state => state.dataAlertAndSubscription.subscriptionsListByCardStatus)
  const cardId = routeProps.match.params._id

  useEffect(() => {
    if (cardId) {
      setHeaderTitle('Notifications')
      setIsLoading(true)
      dispatch(getAllSubscriptionsByCardId(getSubscriptionsPayload(statusType, cardId)))
    }
  }, [statusType, cardId])

  useEffect(() => {
    if (notificationsResponse?.data) {
      setIsLoading(false)
      setNotifications(notificationsResponse?.data)
    }
  }, [notificationsResponse])

  return (
    <>
      <Toolbar classes={{ root: 'toolbar' }}>
        <Link to={`/card/${cardId}`}>
          <Grid container justifyContent="flex-start" alignItems="center">
            <ArrowBackIos sx={{ fontSize: 20 }} />
            <Typography variant="button">Back to Card</Typography>
          </Grid>
        </Link>
      </Toolbar>
      <Paper elevation={0} sx={{ p: 2 }}>
        <Typography classes={{ root: 'toolbar-title' }} tabIndex="0" aria-label="card subscription listing">
          CARD SUBSCRIPTIONS AND ALERTS
        </Typography>
        {/* Data Alert Notifications */}
        {isLoading ? (
          <Spinner />
        ) : (
          <ContentTable
            chipInfo={notifications}
            setSubscriptions={setNotifications}
            chipTitle="Subscription"
            routeProps={routeProps}
            trackEvent={trackEvent}
            isMobile={isMobile}
            isShowCardNotifications={Boolean(cardId)}
            statusType={statusType}
            setStatusType={setStatusType}
          />
        )}
      </Paper>
    </>
  )
}

export default DataAlertSubscriptionListing

export const getSubscriptionsPayload = (statusType, cardId) => {
  return {
    page: 1,
    per_page: SUBSCRIPTIONS_PAGE_SIZE,
    subscription_status:
      statusType === ACTIVE
        ? SUBSCRIPTION_STATUS_TYPE.ACTIVE
        : statusType === INACTIVE
        ? SUBSCRIPTION_STATUS_TYPE.INACTIVE
        : '',
    ...(cardId ? { cardId } : {}),
  }
}
