import { call, put } from 'redux-saga/effects'
import axios from 'axios'
import { API_GATEWAY_URL } from './utils'

// Action types
// Data Alerts
export const CREATE_DATA_ALERT_REQUESTED = 'CREATE_DATA_ALERT_REQUESTED'
export const CREATE_DATA_ALERT_SUCCEEDED = 'CREATE_DATA_ALERT_SUCCEEDED'
export const CREATE_DATA_ALERT_FAILED = 'CREATE_DATA_ALERT_FAILED'

export const CREATE_DATA_ALERT_TEST_REQUESTED = 'CREATE_DATA_ALERT_TEST_REQUESTED'
export const CREATE_DATA_ALERT_TEST_SUCCEEDED = 'CREATE_DATA_ALERT_TEST_SUCCEEDED'
export const CREATE_DATA_ALERT_TEST_FAILED = 'CREATE_DATA_ALERT_TEST_FAILED'

export const CLEAR_DATA_ALERT_DATA = 'CLEAR_DATA_ALERT_DATA'
export const CLEAR_DATA_ALERT_TEST = 'CLEAR_DATA_ALERT_TEST'
export const CLEAR_DATA_ALERT_LOGS_DATA = 'CLEAR_DATA_ALERT_LOGS_DATA'
export const CLEAR_DATA_ALERT_DELETE_DATA = 'CLEAR_DATA_ALERT_DELETE_DATA'
export const CLEAR_FETCH_SUBSCRIPTIONS_BY_CARD = 'CLEAR_FETCH_SUBSCRIPTIONS_BY_CARD'

export const DELETE_DATA_ALERT_REQUESTED = 'DELETE_DATA_ALERT_REQUESTED'
export const DELETE_DATA_ALERT_SUCCEEDED = 'DELETE_DATA_ALERT_SUCCEEDED'
export const DELETE_DATA_ALERT_FAILED = 'DELETE_DATA_ALERT_FAILED'

// Subscription Listing
export const FETCH_ALL_SUBSCRIPTIONS_REQUESTED = 'FETCH_ALL_SUBSCRIPTIONS_REQUESTED'
export const FETCH_ALL_SUBSCRIPTIONS_SUCCEEDED = 'FETCH_ALL_SUBSCRIPTIONS_SUCCEEDED'
export const FETCH_ALL_SUBSCRIPTIONS_FAILED = 'FETCH_ALL_SUBSCRIPTIONS_BY_CARD_FAILED'

export const FETCH_ALL_SUBSCRIPTIONS_BY_CARD_REQUESTED = 'FETCH_ALL_SUBSCRIPTIONS_BY_CARD_REQUESTED'
export const FETCH_ALL_SUBSCRIPTIONS_BY_CARD_SUCCEEDED = 'FETCH_ALL_SUBSCRIPTIONS_BY_CARD_SUCCEEDED'
export const FETCH_ALL_SUBSCRIPTIONS_BY_CARD_FAILED = 'FETCH_ALL_SUBSCRIPTIONS_BY_CARD_FAILED'

export const FETCH_ALL_SUBSCRIPTION_LOGS_REQUESTED = 'FETCH_ALL_SUBSCRIPTION_LOGS_REQUESTED'
export const FETCH_ALL_SUBSCRIPTION_LOGS_SUCCEEDED = 'FETCH_ALL_SUBSCRIPTION_LOGS_SUCCEEDED'
export const FETCH_ALL_SUBSCRIPTION_LOGS_FAILED = 'FETCH_ALL_SUBSCRIPTION_LOGS_FAILED'

// Josb logs Listing
export const FETCH_ALL_DATA_ALERT_EVALUATION_JOBS_REQUESTED = 'FETCH_ALL_DATA_ALERT_EVALUATION_JOBS_REQUESTED'
export const FETCH_ALL_DATA_ALERT_EVALUATION_JOBS_SUCCEEDED = 'FETCH_ALL_DATA_ALERT_EVALUATION_JOBS_SUCCEEDED'
export const FETCH_ALL_DATA_ALERT_EVALUATION_JOBS_FAILED = 'FETCH_ALL_DATA_ALERT_EVALUATION_JOBS_FAILED'

// Card Subscription
export const CREATE_CARD_SUBSCRIPTION_REQUESTED = 'CREATE_CARD_SUBSCRIPTION_REQUESTED'
export const CREATE_CARD_SUBSCRIPTION_SUCCEEDED = 'CREATE_CARD_SUBSCRIPTION_SUCCEEDED'
export const CREATE_CARD_SUBSCRIPTION_FAILED = 'CREATE_CARD_SUBSCRIPTION_FAILED'

export const CREATE_CARD_SUBSCRIPTION_TEST_REQUESTED = 'CREATE_CARD_SUBSCRIPTION_TEST_REQUESTED'
export const CREATE_CARD_SUBSCRIPTION_TEST_SUCCEEDED = 'CREATE_CARD_SUBSCRIPTION_TEST_SUCCEEDED'
export const CREATE_CARD_SUBSCRIPTION_TEST_FAILED = 'CREATE_CARD_SUBSCRIPTION_TEST_FAILED'

export const CLEAR_CARD_SUBSCRIPTION_DATA = 'CLEAR_CARD_SUBSCRIPTION_DATA'
export const CLEAR_CARD_SUBSCRIPTION_TEST = 'CLEAR_CARD_SUBSCRIPTION_TEST'
export const CLEAR_CARD_SUBSCRIPTION_DELETE_DATA = 'CLEAR_CARD_SUBSCRIPTION_DELETE_DATA'

export const DELETE_CARD_SUBSCRIPTION_REQUESTED = 'DELETE_CARD_SUBSCRIPTION_REQUESTED'
export const DELETE_CARD_SUBSCRIPTION_SUCCEEDED = 'DELETE_CARD_SUBSCRIPTION_SUCCEEDED'
export const DELETE_CARD_SUBSCRIPTION_FAILED = 'DELETE_CARD_SUBSCRIPTION_FAILED'

// Optout Subscriptions
export const UPDATE_OPT_OUT_SUBSCRIPTION_REQUESTED = 'UPDATE_OPT_OUT_SUBSCRIPTION_REQUESTED'
export const UPDATE_OPT_OUT_SUBSCRIPTION_SUCCEEDED = 'UPDATE_OPT_OUT_SUBSCRIPTION_SUCCEEDED'
export const UPDATE_OPT_OUT_SUBSCRIPTION_FAILED = 'UPDATE_OPT_OUT_SUBSCRIPTION_FAILED'
export const CLEAR_OPT_OUT_SUBSCRIPTION = 'CLEAR_OPT_OUT_SUBSCRIPTION'

// Reducer
export default function dataAlertAndSubscriptionReducer(state = {}, action = {}) {
  switch (action.type) {
    case CREATE_DATA_ALERT_REQUESTED: {
      const newState = {
        ...state,
        dataAlertCreateStatus: {
          status: 'requested',
        },
      }
      return newState
    }
    case CREATE_DATA_ALERT_SUCCEEDED: {
      const newState = {
        ...state,
        dataAlertCreateStatus: action.payload,
      }
      return newState
    }
    case CREATE_DATA_ALERT_FAILED: {
      const newState = {
        ...state,
        dataAlertCreateStatus: {
          status: 'failed',
          error: action.payload,
        },
      }
      return newState
    }
    case CREATE_DATA_ALERT_TEST_REQUESTED: {
      const newState = {
        ...state,
        dataAlertTestCreateStatus: {
          status: 'requested',
        },
      }
      return newState
    }
    case CREATE_DATA_ALERT_TEST_SUCCEEDED: {
      const newState = {
        ...state,
        dataAlertTestCreateStatus: action.payload,
      }
      return newState
    }
    case CREATE_DATA_ALERT_TEST_FAILED: {
      const newState = {
        ...state,
        dataAlertTestCreateStatus: {
          status: 'failed',
          error: action.payload,
        },
      }
      return newState
    }
    case FETCH_ALL_SUBSCRIPTIONS_REQUESTED: {
      const newState = {
        ...state,
        subscriptionsListStatus: {
          status: 'requested',
        },
      }
      return newState
    }
    case FETCH_ALL_SUBSCRIPTIONS_SUCCEEDED: {
      const newState = {
        ...state,
        subscriptionsListStatus: action.payload,
      }
      return newState
    }
    case FETCH_ALL_SUBSCRIPTIONS_FAILED: {
      const newState = {
        ...state,
        subscriptionsListStatus: {
          status: 'failed',
          error: action.payload,
        },
      }
      return newState
    }
    case FETCH_ALL_SUBSCRIPTIONS_BY_CARD_REQUESTED: {
      const newState = {
        ...state,
        subscriptionsListByCardStatus: {
          status: 'requested',
        },
      }
      return newState
    }
    case FETCH_ALL_SUBSCRIPTIONS_BY_CARD_SUCCEEDED: {
      const newState = {
        ...state,
        subscriptionsListByCardStatus: action.payload,
      }
      return newState
    }
    case FETCH_ALL_SUBSCRIPTIONS_BY_CARD_FAILED: {
      const newState = {
        ...state,
        subscriptionsListByCardStatus: {
          status: 'failed',
          error: action.payload,
        },
      }
      return newState
    }
    case DELETE_DATA_ALERT_REQUESTED: {
      const newState = {
        ...state,
        dataAlertDeleteStatus: {
          status: 'requested',
        },
      }
      return newState
    }
    case DELETE_DATA_ALERT_SUCCEEDED: {
      const newState = {
        ...state,
        dataAlertDeleteStatus: action.payload,
      }
      return newState
    }
    case DELETE_DATA_ALERT_FAILED: {
      const newState = {
        ...state,
        dataAlertDeleteStatus: {
          status: 'failed',
          error: action.payload,
        },
      }
      return newState
    }
    case FETCH_ALL_SUBSCRIPTION_LOGS_REQUESTED: {
      const newState = {
        ...state,
        subscriptionLogsStatus: {
          status: 'requested',
        },
      }
      return newState
    }
    case FETCH_ALL_SUBSCRIPTION_LOGS_SUCCEEDED: {
      const newState = {
        ...state,
        subscriptionLogsStatus: action.payload,
      }
      return newState
    }
    case FETCH_ALL_SUBSCRIPTION_LOGS_FAILED: {
      const newState = {
        ...state,
        subscriptionLogsStatus: {
          status: 'failed',
          error: action.payload,
        },
      }
      return newState
    }
    case FETCH_ALL_DATA_ALERT_EVALUATION_JOBS_REQUESTED: {
      const newState = {
        ...state,
        dataAlertEvaluationLogsStatus: {
          status: 'requested',
        },
      }
      return newState
    }
    case FETCH_ALL_DATA_ALERT_EVALUATION_JOBS_SUCCEEDED: {
      const newState = {
        ...state,
        dataAlertEvaluationLogsStatus: action.payload,
      }
      return newState
    }
    case FETCH_ALL_DATA_ALERT_EVALUATION_JOBS_FAILED: {
      const newState = {
        ...state,
        dataAlertEvaluationLogsStatus: {
          status: 'failed',
          error: action.payload,
        },
      }
      return newState
    }
    case CLEAR_DATA_ALERT_DATA: {
      const newState = {
        ...state,
        dataAlertCreateStatus: {},
      }
      return newState
    }
    case CLEAR_DATA_ALERT_TEST: {
      const newState = {
        ...state,
        dataAlertTestCreateStatus: {},
      }
      return newState
    }
    case CLEAR_DATA_ALERT_LOGS_DATA: {
      const newState = {
        ...state,
        subscriptionLogsStatus: {},
      }
      return newState
    }

    case CLEAR_FETCH_SUBSCRIPTIONS_BY_CARD: {
      const newState = {
        ...state,
        subscriptionsListByCardStatus: {},
      }
      return newState
    }
    case CLEAR_DATA_ALERT_DELETE_DATA: {
      const newState = {
        ...state,
        dataAlertDeleteStatus: {},
      }
      return newState
    }

    case CREATE_CARD_SUBSCRIPTION_REQUESTED: {
      const newState = {
        ...state,
        cardSubscriptionCreateStatus: {
          status: 'requested',
        },
      }
      return newState
    }
    case CREATE_CARD_SUBSCRIPTION_SUCCEEDED: {
      const newState = {
        ...state,
        cardSubscriptionCreateStatus: action.payload,
      }
      return newState
    }
    case CREATE_CARD_SUBSCRIPTION_FAILED: {
      const newState = {
        ...state,
        cardSubscriptionCreateStatus: {
          status: 'failed',
          error: action.payload,
        },
      }
      return newState
    }
    case CREATE_CARD_SUBSCRIPTION_TEST_REQUESTED: {
      const newState = {
        ...state,
        cardSubscriptionTestCreateStatus: {
          status: 'requested',
        },
      }
      return newState
    }
    case CREATE_CARD_SUBSCRIPTION_TEST_SUCCEEDED: {
      const newState = {
        ...state,
        cardSubscriptionTestCreateStatus: action.payload,
      }
      return newState
    }
    case CREATE_CARD_SUBSCRIPTION_TEST_FAILED: {
      const newState = {
        ...state,
        cardSubscriptionTestCreateStatus: {
          status: 'failed',
          error: action.payload,
        },
      }
      return newState
    }
    case DELETE_CARD_SUBSCRIPTION_REQUESTED: {
      const newState = {
        ...state,
        cardSubscriptionDeleteStatus: {
          status: 'requested',
        },
      }
      return newState
    }
    case DELETE_CARD_SUBSCRIPTION_SUCCEEDED: {
      const newState = {
        ...state,
        cardSubscriptionDeleteStatus: action.payload,
      }
      return newState
    }
    case DELETE_CARD_SUBSCRIPTION_FAILED: {
      const newState = {
        ...state,
        cardSubscriptionDeleteStatus: {
          status: 'failed',
          error: action.payload,
        },
      }
      return newState
    }
    case UPDATE_OPT_OUT_SUBSCRIPTION_REQUESTED: {
      const newState = {
        ...state,
        optOutSubscriptionStatus: {
          status: 'requested',
        },
      }
      return newState
    }
    case UPDATE_OPT_OUT_SUBSCRIPTION_SUCCEEDED: {
      const newState = {
        ...state,
        optOutSubscriptionStatus: action.payload,
      }
      return newState
    }
    case UPDATE_OPT_OUT_SUBSCRIPTION_FAILED: {
      const newState = {
        ...state,
        optOutSubscriptionStatus: {
          status: 'failed',
          error: action.payload,
        },
      }
      return newState
    }
    case CLEAR_OPT_OUT_SUBSCRIPTION: {
      const newState = {
        ...state,
        optOutSubscriptionStatus: {},
      }
      return newState
    }
    case CLEAR_CARD_SUBSCRIPTION_DATA: {
      const newState = {
        ...state,
        cardSubscriptionCreateStatus: {},
      }
      return newState
    }
    case CLEAR_CARD_SUBSCRIPTION_TEST: {
      const newState = {
        ...state,
        cardSubscriptionTestCreateStatus: {},
      }
      return newState
    }
    case CLEAR_CARD_SUBSCRIPTION_DELETE_DATA: {
      const newState = {
        ...state,
        cardSubscriptionDeleteStatus: {},
      }
      return newState
    }
    default:
      return state
  }
}

// Actions
export function createDataAlert(data) {
  return {
    type: CREATE_DATA_ALERT_REQUESTED,
    payload: data,
  }
}

export function createDataAlertTest(data) {
  return {
    type: CREATE_DATA_ALERT_TEST_REQUESTED,
    payload: data,
  }
}

export function clearDataAlert(data) {
  return {
    type: CLEAR_DATA_ALERT_DATA,
    payload: data,
  }
}

export function clearDataAlertTest(data) {
  return {
    type: CLEAR_DATA_ALERT_TEST,
    payload: data,
  }
}

export function clearOptOutSubscription(data) {
  return {
    type: CLEAR_OPT_OUT_SUBSCRIPTION,
    payload: data,
  }
}

export function clearSubscriptionLogs(data) {
  return {
    type: CLEAR_DATA_ALERT_LOGS_DATA,
    payload: data,
  }
}

export function clearDataAlertDelete(data) {
  return {
    type: CLEAR_DATA_ALERT_DELETE_DATA,
    payload: data,
  }
}

export function clearGetAllSubscriptionsByCard(data) {
  return {
    type: CLEAR_FETCH_SUBSCRIPTIONS_BY_CARD,
    payload: data,
  }
}

export function getAllSubscriptions(data) {
  return {
    type: FETCH_ALL_SUBSCRIPTIONS_REQUESTED,
    payload: data,
  }
}

export function getAllSubscriptionsByCardId(data) {
  return {
    type: FETCH_ALL_SUBSCRIPTIONS_BY_CARD_REQUESTED,
    payload: data,
  }
}

export function deleteDataAlert(data) {
  return {
    type: DELETE_DATA_ALERT_REQUESTED,
    payload: data,
  }
}

export function deleteSubscription(data) {
  return {
    type: DELETE_CARD_SUBSCRIPTION_REQUESTED,
    payload: data,
  }
}

export function getAllDataAlertLogs(data) {
  return {
    type: FETCH_ALL_SUBSCRIPTION_LOGS_REQUESTED,
    payload: data,
  }
}

export function createCardSubscription(data) {
  return {
    type: CREATE_CARD_SUBSCRIPTION_REQUESTED,
    payload: data,
  }
}

export function createCardSubscriptionTest(data) {
  return {
    type: CREATE_CARD_SUBSCRIPTION_TEST_REQUESTED,
    payload: data,
  }
}

export function editOptOutSubscription(data) {
  return {
    type: UPDATE_OPT_OUT_SUBSCRIPTION_REQUESTED,
    payload: data,
  }
}

export function clearCardSubscription(data) {
  return {
    type: CLEAR_CARD_SUBSCRIPTION_DATA,
    payload: data,
  }
}

export function clearCardSubscriptionTest(data) {
  return {
    type: CLEAR_CARD_SUBSCRIPTION_TEST,
    payload: data,
  }
}

export function clearCardSubscriptionDelete(data) {
  return {
    type: CLEAR_CARD_SUBSCRIPTION_DELETE_DATA,
    payload: data,
  }
}

// Sagas (service requests)
export function* createDataSubscription(action) {
  try {
    const url = `${API_GATEWAY_URL}/greenfield_subscriptions/v1/event_subscriptions/data_alerts`
    const createDataAlert = yield call(axios, {
      method: 'post',
      url,
      data: action.payload,
    })
    if (createDataAlert.error) {
      const e = createDataAlert.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }

    yield put({ type: CREATE_DATA_ALERT_SUCCEEDED, payload: createDataAlert })
  } catch (e) {
    yield put({ type: CREATE_DATA_ALERT_FAILED, payload: { data: action.payload, resp: e.response } })
  }
}

export function* createDataSubscriptionTest(action) {
  try {
    const url = `${API_GATEWAY_URL}/greenfield_subscriptions/v1/event_subscriptions/data_alerts?test_run=true`
    const createDataAlertTest = yield call(axios, {
      method: 'post',
      url,
      data: action.payload,
    })
    if (createDataAlert.error) {
      const e = createDataAlert.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }

    yield put({ type: CREATE_DATA_ALERT_TEST_SUCCEEDED, payload: createDataAlertTest })
  } catch (e) {
    yield put({ type: CREATE_DATA_ALERT_TEST_FAILED, payload: { data: action.payload, resp: e.response } })
  }
}

export function* getAllSubscriptionsList(action) {
  try {
    const subscriptions = yield call(axios, {
      method: 'get',
      url: `${API_GATEWAY_URL}/greenfield_subscriptions/v1/subscriptions?page=${action.payload.page}&per_page=${
        action.payload.per_page
      }${action.payload.subscription_status ? `&subscription_status=${action.payload.subscription_status}` : ''}`,
    })
    if (subscriptions.error) {
      const e = subscriptions.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }

    yield put({ type: FETCH_ALL_SUBSCRIPTIONS_SUCCEEDED, payload: subscriptions })
  } catch (e) {
    yield put({ type: FETCH_ALL_SUBSCRIPTIONS_FAILED, payload: { data: action.payload, resp: e.response } })
  }
}

export function* getSubscriptionsByCardId(action) {
  try {
    const cardSubscriptions = yield call(axios, {
      method: 'get',
      url: `${API_GATEWAY_URL}/greenfield_subscriptions/v1/subscriptions/cards/${action.payload.cardId}?page=${
        action.payload.page
      }&per_page=${action.payload.per_page}${
        action.payload.subscription_status ? `&subscription_status=${action.payload.subscription_status}` : ''
      }`,
    })
    if (cardSubscriptions.error) {
      const e = cardSubscriptions.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }

    yield put({ type: FETCH_ALL_SUBSCRIPTIONS_BY_CARD_SUCCEEDED, payload: cardSubscriptions })
  } catch (e) {
    yield put({
      type: FETCH_ALL_SUBSCRIPTIONS_BY_CARD_FAILED,
      payload: { data: action.payload, resp: e.response },
    })
  }
}

export function* deleteDataAlertSubscription(action) {
  try {
    const subscriptions = yield call(axios, {
      method: 'delete',
      url: `${API_GATEWAY_URL}/greenfield_subscriptions/v1/event_subscriptions/data_alerts/${action.payload.subscriptionId}`,
    })
    if (subscriptions.error) {
      const e = subscriptions.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }

    yield put({ type: DELETE_DATA_ALERT_SUCCEEDED, payload: subscriptions })
  } catch (e) {
    yield put({ type: DELETE_DATA_ALERT_FAILED, payload: { data: action.payload, resp: e.response } })
  }
}

export function* deleteCardSubscription(action) {
  try {
    const deleteSubscription = yield call(axios, {
      method: 'delete',
      url: `${API_GATEWAY_URL}/greenfield_subscriptions/v1/event_subscriptions/card_subscriptions/${action.payload.subscriptionId}`,
    })
    if (deleteSubscription.error) {
      const e = deleteSubscription.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }

    yield put({ type: DELETE_CARD_SUBSCRIPTION_SUCCEEDED, payload: deleteSubscription })
  } catch (e) {
    yield put({ type: DELETE_CARD_SUBSCRIPTION_FAILED, payload: { data: action.payload, resp: e.response } })
  }
}

export function* updateOptOutSubscription(action) {
  try {
    const optOutSubscription = yield call(axios, {
      method: action.payload.isOptIn ? 'put' : 'delete', // put to opt in and delete to opt out of subscription
      url: `${API_GATEWAY_URL}/greenfield_subscriptions/v1/subscriptions/${action.payload.subscriptionId}/opt_outs`,
    })
    if (optOutSubscription.error) {
      const e = optOutSubscription.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }

    yield put({ type: UPDATE_OPT_OUT_SUBSCRIPTION_SUCCEEDED, payload: optOutSubscription })
  } catch (e) {
    yield put({ type: UPDATE_OPT_OUT_SUBSCRIPTION_FAILED, payload: { data: action.payload, resp: e.response } })
  }
}

export function* fetchAllSubscriptionLogs(action) {
  try {
    const dataAlertLogs = yield call(axios, {
      method: 'get',
      url: `${API_GATEWAY_URL}/greenfield_subscriptions/v1/execution_details/subscriptions/${action.payload.subscriptionId}`,
    })
    if (dataAlertLogs.error) {
      const e = dataAlertLogs.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }

    yield put({ type: FETCH_ALL_SUBSCRIPTION_LOGS_SUCCEEDED, payload: dataAlertLogs })
  } catch (e) {
    yield put({ type: FETCH_ALL_SUBSCRIPTION_LOGS_FAILED, payload: { data: action.payload, resp: e.response } })
  }
}

export function* createSubscription(action) {
  try {
    const url = `${API_GATEWAY_URL}/greenfield_subscriptions/v1/event_subscriptions/card_subscriptions`
    const cardSubscription = yield call(axios, {
      method: 'post',
      url,
      data: action.payload,
    })
    if (createCardSubscription.error) {
      const e = createCardSubscription.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }

    yield put({ type: CREATE_CARD_SUBSCRIPTION_SUCCEEDED, payload: cardSubscription })
  } catch (e) {
    yield put({ type: CREATE_CARD_SUBSCRIPTION_FAILED, payload: { data: action.payload, resp: e.response } })
  }
}

export function* createSubscriptionTest(action) {
  try {
    const url = `${API_GATEWAY_URL}/greenfield_subscriptions/v1/event_subscriptions/card_subscriptions?test_run=true`
    const subscriptionTest = yield call(axios, {
      method: 'post',
      url,
      data: action.payload,
    })
    if (createCardSubscription.error) {
      const e = createCardSubscription.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }

    yield put({ type: CREATE_CARD_SUBSCRIPTION_TEST_SUCCEEDED, payload: subscriptionTest })
  } catch (e) {
    yield put({ type: CREATE_CARD_SUBSCRIPTION_TEST_FAILED, payload: { data: action.payload, resp: e.response } })
  }
}
