import React from 'react'
import PropTypes from 'prop-types'
import {
  TextField,
  MenuItem,
  FormControlLabel,
  Typography,
  Switch,
  Divider,
  FormControl,
  InputLabel,
  Select,
  IconButton,
  Input,
  FormHelperText,
} from '@mui/material'
import { AddCircle, Cancel } from '@mui/icons-material'
import moment from 'moment'
import cloneDeep from 'lodash/cloneDeep'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import upperFirst from 'lodash/upperFirst'
import { Spinner, TIMESERIES_STR, CUSTOM_STR, NUMBER_COLUMN_TYPE_OPTIONS } from 'greenfield-utilities'

import TimePeriod from '../TimePeriod/TimePeriod.container'
import {
  COMPARE_BY_NOT_SUPPORTED_OPTIONS,
  DEFAULT_PERIOD_PLURAL_OPTIONS,
  DEFAULT_PERIOD_SINGULAR_OPTIONS,
  GRANULARITY_OPTIONS,
  INTERVAL_OPTIONS,
  RELATIVE_OPTIONS,
  TIME_INTERVAL_TYPE_OPTIONS,
} from '../../constants/ArrayConstants'
import ConfirmDialog from '../Dialog/ConfirmDialog'
import { getFormatDate } from '../util'
import {
  getSecondaryTimePeriodColumn,
  checkMultipleTimeSeries,
  getPrimaryTimePeriodColumnName,
} from '../TimePeriod/TimePeriodUtils'

import './ViewerTimePeriod.scss'

class ViewerTimePeriod extends React.Component {
  state = {
    datasetId: this.props.cardInfo.data ? this.props.cardInfo.data.card_config.card_attribute.dataset_id : null,
    isTimeSeries: this.props.cardInfo?.data?.card_config
      ? this.checkGranularity(this.props.cardInfo.data.card_config.card_query_attribute.columns)
      : false,
    isSecondaryTimeSeries: this.props.cardInfo?.data?.card_config
      ? this.checkGranularity(this.props.cardInfo.data.card_config.card_query_attribute.columns, true)
      : false,
    compareValues: [],
    isCompareBySeries: false,
    compareByType: 'Relative',
    isIntervalTypeChange: false,
    tempCompareByType: 'Relative',
    cacheCardInfo: {},
    hasLabelChanged: false,
    duplicateLabelError: {},
    secondaryTimePeriodColumn: {},
  }

  granularity = GRANULARITY_OPTIONS

  componentDidMount() {
    const { isDashboard, timePeriod, cardInfo, cardsDatasetStatus } = this.props
    const datasetId = cardInfo?.data?.card_config?.card_attribute?.dataset_id
    if (!isDashboard) {
      const compareBy = timePeriod.compare_by

      this.setState({
        isCompareBySeries: Boolean(compareBy),
        compareValues: compareBy ? compareBy.values : [],
        compareByType: compareBy ? compareBy.type : 'Relative',
        cacheCardInfo: cardInfo,
        tempCompareByType: compareBy ? compareBy.type : 'Relative',
        secondaryTimePeriodColumn: cardsDatasetStatus[datasetId]?.data?.fields_array?.find(eachColumn => {
          if (eachColumn.type === 'timeseries' && eachColumn.name !== '__time') {
            return eachColumn
          }
          return undefined
        }),
      })
    }
  }

  componentDidUpdate(prevProps) {
    const { cardInfo, cardsDatasetStatus, updateCardsDataset } = this.props

    if (!isEqual(prevProps.cardInfo, cardInfo) && cardInfo.status >= 200 && cardInfo.status < 300) {
      const cardAttribute = cardInfo.data.card_config.card_attribute
      const datasetId = cardAttribute.dataset_id

      if (
        (!cardsDatasetStatus || !Object.keys(cardsDatasetStatus).length || !cardsDatasetStatus[datasetId]) &&
        cardAttribute.viz_type?.toLowerCase() !== 'text'
      ) {
        updateCardsDataset(datasetId)
      }

      this.setState({
        datasetId,
        isTimeSeries: this.checkGranularity(cardInfo.data.card_config.card_query_attribute.columns),
        isSecondaryTimeSeries: this.checkGranularity(cardInfo.data.card_config.card_query_attribute.columns, true),
        cacheCardInfo: cardInfo,
      })
    }
  }

  checkGranularity(columns, isCheckSecondaryTime) {
    if (isCheckSecondaryTime) {
      return columns.some(val => val.type === 'timeseries' && val.field_name !== '__time')
    } else {
      return columns.some(val => val.type === 'timeseries' && val.field_name === '__time')
    }
  }

  updateState = (stateName, value) => {
    this.setState({
      [stateName]: value,
    })
  }

  isDisabled() {
    const { isDashboard, timePeriod } = this.props
    const value = isDashboard && (!timePeriod || !Object.keys(timePeriod).length)

    return value
  }

  renderFiscalSwitch = (_timePeriod, isFiscal = false, isSecondaryTime) => {
    const { onTimePeriodChange } = this.props
    return (
      <>
        <FormControlLabel
          className="test-switch switch-base"
          control={
            <Switch
              disabled={this.isCardRenderRequested()}
              id={isSecondaryTime ? 'fiscal-secondary-calendar-btn' : 'fiscal-calendar-btn'}
              data-cy={isSecondaryTime ? 'fiscal-secondary-calendar-btn' : 'fiscal-calendar-btn'}
              aria-label="Fiscal Calendar"
              checked={isFiscal}
              onClick={() => {
                _timePeriod.calendar_type = isFiscal ? 'Gregorian' : 'Fiscal'

                onTimePeriodChange(_timePeriod, false, isSecondaryTime)
              }}
              inputProps={{ 'aria-label': 'Fiscal Calendar' }}
            />
          }
          label="Fiscal Calendar"
        />
      </>
    )
  }

  renderTimePeriod(dataSet, isSecondaryTime) {
    const { isTimeSeries, isSecondaryTimeSeries, datasetId } = this.state
    const {
      isDashboard,
      isMobile,
      hideViewerTimePeriod,
      hideViewerSecondaryTimePeriod,
      showFiscalSwitch,
      timePeriod,
      onTimePeriodChange,
      cardInfo,
      cardsDatasetStatus,
      selectedTimePeriod,
      selectedDashboardTimePeriod,
      secondaryTime,
    } = this.props
    const _timePeriod = isDashboard
      ? selectedDashboardTimePeriod || cloneDeep(timePeriod)
      : cloneDeep(selectedTimePeriod?.timePeriod) || cloneDeep(timePeriod)
    const selectedSecondaryTimePeriods = selectedTimePeriod?.secondaryTimePeriods || secondaryTime?.secondaryTimePeriods
    const secondaryTimePeriodColumn =
      cardsDatasetStatus &&
      cardsDatasetStatus[datasetId] &&
      getSecondaryTimePeriodColumn(cardsDatasetStatus[datasetId]?.data)
    const primaryTimePeriodColName =
      cardsDatasetStatus &&
      cardsDatasetStatus[datasetId] &&
      getPrimaryTimePeriodColumnName(cardsDatasetStatus[datasetId]?.data)
    const secondaryTimePeriod = selectedSecondaryTimePeriods
      ? cloneDeep(selectedSecondaryTimePeriods[secondaryTimePeriodColumn?.field_name])
      : secondaryTimePeriodColumn &&
        cloneDeep(
          cardInfo.data?.context?.rendered_payload?.secondary_time_periods[secondaryTimePeriodColumn?.field_name]
        )
    const isFiscal = _timePeriod.calendar_type === 'Fiscal' || !_timePeriod.calendar_type
    const isSecondaryFiscal =
      secondaryTimePeriod && (secondaryTimePeriod.calendar_type === 'Fiscal' || !secondaryTimePeriod.calendar_type)

    const hasATimePeriodColumnAndUnsetGranularity =
      cardInfo.data?.card_config?.card_query_attribute?.columns.some(column => column.type === 'timeseries') && 'All'
    const selectedGraphGranularity = _timePeriod.granularity || hasATimePeriodColumnAndUnsetGranularity
    const selectedSecondaryGraphGrain =
      !secondaryTimePeriod?.granularity || secondaryTimePeriod?.granularity === 'none'
        ? 'All'
        : secondaryTimePeriod?.granularity
    let minDateRange = null
    let maxDateRange = null

    if (dataSet) {
      if (dataSet.min_date_range && moment(dataSet.min_date_range).isValid()) {
        const minDate = dataSet.min_date_range.slice(0, 19)
        minDateRange = minDate ? getFormatDate(minDate) : ''
      }

      if (dataSet.max_date_range && moment(dataSet.max_date_range).isValid()) {
        const maxDate = dataSet.max_date_range.slice(0, 19)
        maxDateRange = maxDate ? getFormatDate(maxDate) : ''
      }
    }

    return (
      <>
        <div className="viewer-time-period">
          {!isSecondaryTime && !isMobile && !isDashboard && minDateRange && maxDateRange && (
            <>
              <Typography className="topo-padding">
                {`Data Availability:
              ${minDateRange} to
              ${maxDateRange}`}
              </Typography>
              <Divider className="light-divider field-margin" />
            </>
          )}
          {isSecondaryTime && !isMobile && !hideViewerTimePeriod && <Divider className="light-divider field-margin" />}
          <Typography className="topo-padding font-bold">
            {!isMobile &&
              `${
                isSecondaryTime
                  ? `${!hideViewerSecondaryTimePeriod ? 'Secondary Time Period' : ''}`
                  : `${!hideViewerTimePeriod ? 'Primary Time Period' : ''}`
              }`}
          </Typography>
          {!hideViewerTimePeriod && (
            <div className="align-selection">
              <Typography variant="body2" className="topo-padding">
                {isDashboard && !isMobile
                  ? 'Select Time Period'
                  : isSecondaryTime
                  ? !hideViewerSecondaryTimePeriod &&
                    `${secondaryTimePeriodColumn?.column_display_name}(${secondaryTimePeriodColumn?.field_name})`
                  : !hideViewerTimePeriod && primaryTimePeriodColName}
              </Typography>
              {!isSecondaryTime &&
                !isMobile &&
                !(isDashboard && !Object.keys(_timePeriod).length) &&
                this.renderFiscalSwitch(_timePeriod, isFiscal, false)}
              {isSecondaryTime &&
                !isMobile &&
                !(isDashboard && secondaryTimePeriod && !Object.keys(secondaryTimePeriod).length) &&
                this.renderFiscalSwitch(secondaryTimePeriod, isSecondaryFiscal, true)}
            </div>
          )}
          <div className="component-row greenFieldNoPrint">
            {((hideViewerTimePeriod && !hideViewerSecondaryTimePeriod) ||
              !hideViewerTimePeriod ||
              showFiscalSwitch) && (
              <>
                <TimePeriod
                  className="topo-padding"
                  onTimeJsonChange={onTimePeriodChange}
                  isViewer
                  selectedDataset={dataSet}
                  {...this.props}
                  viewerJson={timePeriod}
                  fiscalYear={
                    isSecondaryTime
                      ? isSecondaryFiscal
                      : _timePeriod.calendar_type === 'Fiscal' || !_timePeriod.calendar_type
                  }
                  isSecondaryTime={isSecondaryTime}
                  hideViewerSecondaryTimePeriod={hideViewerSecondaryTimePeriod}
                  secondaryTimePeriodColumn={secondaryTimePeriodColumn}
                />
              </>
            )}
            <div>
              {(!hideViewerTimePeriod || showFiscalSwitch) &&
                (isDashboard || isTimeSeries) &&
                !isSecondaryTime &&
                selectedGraphGranularity &&
                this.renderGranularityOption(dataSet, false, _timePeriod, selectedGraphGranularity)}
              {(!hideViewerSecondaryTimePeriod || showFiscalSwitch) &&
                isSecondaryTimeSeries &&
                !isMobile &&
                isSecondaryTime &&
                selectedSecondaryGraphGrain &&
                this.renderGranularityOption(
                  dataSet,
                  isSecondaryTime,
                  secondaryTimePeriod,
                  selectedSecondaryGraphGrain
                )}
            </div>
          </div>
          {/* {!isMobile && <Divider className="light-divider field-margin" />} */}
          {!isSecondaryTime &&
            !hideViewerTimePeriod &&
            !isMobile &&
            !isDashboard &&
            this.isCompareByShown() &&
            this.renderCompareByOptions(dataSet)}
        </div>
      </>
    )
  }

  renderGranularityOption(dataSet, isSecondaryTime, timePeriod, selectedGraphGranularity) {
    const { isDashboard, onTimePeriodChange } = this.props
    const graphGrain = isDashboard ? this.granularity : dataSet.valid_granularity_options
    const granularityValue =
      selectedGraphGranularity === 'none'
        ? 'All'
        : `${selectedGraphGranularity?.charAt(0)?.toUpperCase()}${selectedGraphGranularity?.slice(1)}`
    return (
      <TextField
        select
        label="Graph Granularity"
        value={granularityValue}
        onChange={e => {
          timePeriod.granularity = e.target.value

          onTimePeriodChange(timePeriod, false, isSecondaryTime)
        }}
        margin="normal"
        id="graphByGrain"
        className="width-150 topo-padding granularity-picker"
        disabled={this.isCardRenderRequested() || this.isDisabled()}
        variant="standard"
      >
        {graphGrain.map(option => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </TextField>
    )
  }

  isCompareByShown() {
    const { cacheCardInfo } = this.state
    let showCompareBy = false
    let isTimeIntervalArray = false

    if (cacheCardInfo && cacheCardInfo.data) {
      /* eslint-disable camelcase */
      const cardInfo = cacheCardInfo?.data?.card_config
      const contextInfo = cacheCardInfo?.data?.context
      const contextTimePeriod = contextInfo?.rendered_payload?.time_period
      const cardQueryAttribute = cardInfo?.card_query_attribute
      const cardAttribute = cardInfo?.card_attribute
      isTimeIntervalArray =
        Array.isArray(contextTimePeriod?.intervals) && contextTimePeriod?.type === CUSTOM_STR.toLowerCase()
      /* eslint-enable camelcase */
      if (cardAttribute && cardQueryAttribute?.columns) {
        const vizType = cardAttribute.viz_type.toLowerCase()
        const isColumnHaveTimeSeries = cardQueryAttribute.columns.some(column => column.type === TIMESERIES_STR)

        showCompareBy =
          vizType === 'table' || vizType === 'table_beta'
            ? isColumnHaveTimeSeries &&
              cardQueryAttribute.columns.some(column => NUMBER_COLUMN_TYPE_OPTIONS.includes(column.type))
            : !COMPARE_BY_NOT_SUPPORTED_OPTIONS.includes(vizType) &&
              cardAttribute.y_axis?.length === 1 &&
              cardAttribute.x_axis?.type === TIMESERIES_STR &&
              cardAttribute.y_axis?.some(column => NUMBER_COLUMN_TYPE_OPTIONS.includes(column.type)) &&
              isEmpty(cardAttribute.break_by_axis)
      }
    }

    return showCompareBy && !isTimeIntervalArray
  }

  renderCompareByOptions(dataSet) {
    const { isCompareBySeries, compareByType } = this.state

    return (
      <div className="component-row">
        <div className="align-selection">
          <Typography variant="body1">Compare By</Typography>
          <FormControlLabel
            className="switch-base"
            control={
              <Switch
                disabled={this.isCardRenderRequested()}
                id="compare-by-btn"
                aria-label="Compare by"
                checked={isCompareBySeries}
                onClick={() => this.handleCompareByChange()}
                inputProps={{ 'aria-label': 'Compare by' }}
              />
            }
            label={isCompareBySeries ? 'Yes' : 'No'}
          />
        </div>
        {isCompareBySeries && (
          <>
            <FormControl
              margin="normal"
              className="full-width"
              disabled={this.isCardRenderRequested()}
              variant="standard"
            >
              <InputLabel htmlFor="time-interval-type">Time Interval Type</InputLabel>
              <Select
                variant="standard"
                value={upperFirst(compareByType)}
                onChange={event => this.handleTimeIntervalTypeChange(event)}
                aria-label="Time Interval Type"
                inputProps={{
                  name: 'time-interval-type',
                  id: 'time-interval-type',
                  'aria-label': 'Time Interval Type',
                }}
              >
                {TIME_INTERVAL_TYPE_OPTIONS.map(option => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Divider className="light-divider field-margin" />

            {compareByType.toLowerCase() === 'interval'
              ? this.renderIntervalCompareBy(dataSet)
              : this.renderRelativeCompareBy()}
          </>
        )}
        {this.confirmDialogOnChangeType()}
      </div>
    )
  }

  renderIntervalCompareBy(dataSet) {
    const { isCompareBySeries, compareValues } = this.state
    const { deviceType, timePeriod } = this.props

    return (
      <div>
        {compareValues.map((compareByValue, index) => (
          <div key={index} className="display-flex">
            <TimePeriod
              compareValues={compareValues}
              isCompareBy={isCompareBySeries}
              changeIndex={index}
              deviceType={deviceType}
              isViewer
              viewerJson={timePeriod}
              compareByValue={compareByValue}
              callRenderCardWithCompareBy={this.callRenderCardWithCompareBy}
              selectedDataset={dataSet}
              {...this.props}
            />
            <div>
              <IconButton
                disabled={this.isCardRenderRequested()}
                className="remove-add-icon"
                onClick={() => this.deleteSelectedCompareBy(index, 'interval')}
                aria-label={`Delete ${compareByValue}`}
              >
                <Cancel />
              </IconButton>
              {compareValues.length === index + 1 && (
                <IconButton
                  disabled={this.isCardRenderRequested()}
                  className="remove-add-icon"
                  onClick={() => this.handleChangeIntervalTypeAddChange()}
                  aria-label="Add more compare by"
                >
                  <AddCircle />
                </IconButton>
              )}
            </div>
          </div>
        ))}
      </div>
    )
  }

  renderRelativeCompareBy() {
    const { compareValues, hasLabelChanged, duplicateLabelError } = this.state

    return (
      <>
        {compareValues.map((compareByValue, index) => {
          const splitCompareBy = compareByValue?.value?.split(' ') || []
          // eslint-disable-next-line camelcase
          const compDisplayName = compareByValue?.display_name
          const shiftPeriodOption =
            splitCompareBy && splitCompareBy[1] > 1 ? DEFAULT_PERIOD_PLURAL_OPTIONS : DEFAULT_PERIOD_SINGULAR_OPTIONS

          return (
            <div key={`compare${index}`} className="compare-row">
              <div>
                <TextField
                  required
                  disabled={this.isCardRenderRequested()}
                  id={`relative-label${index}`}
                  value={compDisplayName}
                  error={!compDisplayName}
                  type="text"
                  onChange={event => this.handleRelativeLabelChange(event, index)}
                  label="Label"
                  aria-label="Label"
                  onBlur={() => {
                    if (hasLabelChanged && !duplicateLabelError[index] && compDisplayName) {
                      this.setState({ hasLabelChanged: false })
                      this.callRenderCardWithCompareBy(null, true, null)
                    }
                  }}
                  variant="standard"
                />
                {(duplicateLabelError[index] || !compDisplayName) && (
                  <FormHelperText className="compare-error" id="top-n-error-text">
                    {compDisplayName ? 'This label exists' : 'This field is required'}
                  </FormHelperText>
                )}
              </div>
              <div>
                <TextField
                  select
                  disabled={this.isCardRenderRequested()}
                  className="relative-item-shift"
                  label="Shift"
                  name={splitCompareBy[0] === '<<' ? 'Prior' : 'Next'}
                  value={splitCompareBy[0]}
                  onChange={event => this.handleRelativeOptionChange(event, index, 'shift')}
                  margin="normal"
                  id={`time-shift-type${index}`}
                  aria-label="Shift"
                  variant="standard"
                >
                  {RELATIVE_OPTIONS.map((option, shiftIndex) => (
                    <MenuItem key={option.name} value={option.value} id={`shift-type${shiftIndex}`}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>

                <FormControl className="relative-item-shift number-period" variant="standard">
                  <InputLabel htmlFor="Number">Number</InputLabel>
                  <Input
                    disabled={this.isCardRenderRequested()}
                    id={`time-shift-number${index}`}
                    value={splitCompareBy[1]}
                    type="number"
                    onChange={event => this.handleRelativeOptionChange(event, index, 'shift-number')}
                    label="Number"
                    inputProps={{ min: '1', step: '1' }}
                    aria-label="Number"
                  />
                </FormControl>

                <TextField
                  select
                  disabled={this.isCardRenderRequested()}
                  className="relative-item"
                  label="Shift Period"
                  value={splitCompareBy[2]}
                  onChange={event => this.handleRelativeOptionChange(event, index, 'shift-period')}
                  margin="normal"
                  id={`time-shift-period${index}`}
                  aria-label="Shift Period"
                  variant="standard"
                >
                  {shiftPeriodOption.map((option, optionIdx) => (
                    <MenuItem key={option} value={option} id={`shift-period${optionIdx}`}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
                <div className="relative-operation">
                  <IconButton
                    disabled={this.isCardRenderRequested()}
                    className="relative-add-icon remove-add-icon"
                    onClick={() => this.deleteSelectedCompareBy(index, 'relative')}
                    aria-label={`Delete ${compDisplayName}`}
                    id={`delete-shift-period${index}`}
                  >
                    <Cancel />
                  </IconButton>
                  {compareValues.length === index + 1 && (
                    <IconButton
                      disabled={this.isCardRenderRequested() || this.isAddRelativeCompareByEnable()}
                      className="relative-add-icon remove-add-icon"
                      onClick={() => this.handleChangeIntervalTypeAddChange()}
                      aria-label="Add more compare by"
                      id={`add-shift-period${index}`}
                    >
                      <AddCircle />
                    </IconButton>
                  )}
                </div>
              </div>
              <Divider className="light-divider field-margin" />
            </div>
          )
        })}
      </>
    )
  }

  handleCompareByChange = () => {
    const { isCompareBySeries } = this.state
    const defaultPeriodValue = { display_name: '<< 1 Day', value: '<< 1 Day' }

    this.setState(
      {
        isCompareBySeries: !isCompareBySeries,
        compareValues: [defaultPeriodValue],
        compareByType: 'Relative',
        tempCompareByType: 'Relative',
        duplicateLabelError: {},
      },
      () => {
        this.callRenderCardWithCompareBy(null, !isCompareBySeries, null)
      }
    )
  }

  deleteSelectedCompareBy = (index, timeType) => {
    const { compareValues, isCompareBySeries } = this.state
    const { cardInfo } = this.props
    const card = cardInfo?.data?.card_config || {}
    const deleteValue = compareValues[index]
    const isCallRenderCompareBy = deleteValue !== card?.card_query_attribute?.time_period?.interval
    let deleteSelectedCompareBy = {}

    compareValues.splice(index, 1)

    if (timeType === 'relative') {
      deleteSelectedCompareBy = this.getDuplicateErrObj(compareValues)
    }

    this.setState(
      {
        compareValues,
        isCompareBySeries: compareValues.length ? isCompareBySeries : false,
        duplicateLabelError: compareValues.length && timeType === 'relative' ? deleteSelectedCompareBy : {},
      },
      () => {
        if (isCallRenderCompareBy) {
          this.callRenderCardWithCompareBy(null, Boolean(compareValues.length), null)
        }
      }
    )
  }

  handleTimeIntervalTypeChange = event => {
    const { tempCompareByType } = this.state

    if (upperFirst(tempCompareByType) !== event.target.value) {
      this.setState({ isIntervalTypeChange: true, tempCompareByType: event.target.value })
    }
  }

  handleRelativeLabelChange = (event, index) => {
    const _compareValues = cloneDeep(this.state.compareValues)
    const changeValue = event.target.value

    _compareValues[index].display_name = changeValue

    const _duplicateLabelError = this.getDuplicateErrObj(_compareValues)

    this.setState({ compareValues: _compareValues, hasLabelChanged: true, duplicateLabelError: _duplicateLabelError })
  }

  isAddRelativeCompareByEnable = () => {
    const { compareValues, duplicateLabelError } = this.state
    const isDisplayNameEmpty = compareValues.findIndex(obj => !obj.display_name) > -1

    return isDisplayNameEmpty || Boolean(Object.keys(duplicateLabelError).length)
  }

  getDuplicateErrObj = compareChangeArray => {
    const duplicateLabelError = {}

    compareChangeArray.filter((item, index) => {
      const isDupIndex = compareChangeArray.findIndex(compObj => compObj.display_name === item.display_name) !== index

      if (isDupIndex) {
        duplicateLabelError[index] = true
      }
      return isDupIndex
    })

    return duplicateLabelError
  }

  handleRelativeOptionChange = (event, index, optionField) => {
    const compareValues = cloneDeep(this.state.compareValues)
    const _prevCompareValues = cloneDeep(compareValues)
    const splitCompareValue = compareValues[index]?.value?.split(' ') || []

    if (splitCompareValue?.length === 3) {
      if (optionField === 'shift') {
        if (compareValues[index].value === compareValues[index].display_name) {
          compareValues[index].display_name = `${event.target.value} ${splitCompareValue[1]} ${splitCompareValue[2]}`
        }

        compareValues[index].value = `${event.target.value} ${splitCompareValue[1]} ${splitCompareValue[2]}`
      } else if (optionField === 'shift-number') {
        let changeNumber = event.target.value ? event.target.value : 1
        let shiftPeriod = splitCompareValue[2]
        const numberValue = parseInt(event.target.value, 10)

        // this is to change period based on number, example if 1 then period is Day else greater than 1 then Days
        if (numberValue > 1 && parseInt(splitCompareValue[1], 10) === 1) {
          shiftPeriod = `${splitCompareValue[2]}s`
        } else if (parseInt(splitCompareValue[1], 10) > 1 && (numberValue <= 1 || isNaN(numberValue))) {
          const indexValue = DEFAULT_PERIOD_PLURAL_OPTIONS.indexOf(shiftPeriod)

          if (indexValue > -1) {
            shiftPeriod = DEFAULT_PERIOD_SINGULAR_OPTIONS[indexValue]
          }

          if (!numberValue) {
            changeNumber = 1
          }
        }

        if (compareValues[index].value === compareValues[index].display_name) {
          compareValues[index].display_name = `${splitCompareValue[0]} ${changeNumber} ${shiftPeriod}`
        }
        compareValues[index].value = `${splitCompareValue[0]} ${changeNumber} ${shiftPeriod}`
      } else if (optionField === 'shift-period') {
        if (compareValues[index].value === compareValues[index].display_name) {
          compareValues[index].display_name = `${splitCompareValue[0]} ${splitCompareValue[1]} ${event.target.value}`
        }
        compareValues[index].value = `${splitCompareValue[0]} ${splitCompareValue[1]} ${event.target.value}`
      }

      const _duplicateLabelError = this.getDuplicateErrObj(compareValues)

      this.setState({ compareValues, duplicateLabelError: _duplicateLabelError }, () => {
        if (_prevCompareValues.findIndex(compObj => compObj.value === compareValues[index].value) === -1) {
          this.callRenderCardWithCompareBy(null, true, null)
        }
      })
    }
  }

  handleCancelIntervalTypeChange = () => {
    const { tempCompareByType } = this.state

    this.setState({
      isIntervalTypeChange: false,
      tempCompareByType: tempCompareByType === 'Relative' ? 'Interval' : 'Relative',
    })
  }

  handleChangeIntervalTypeAddChange = () => {
    const { tempCompareByType, compareValues, compareByType, isIntervalTypeChange } = this.state
    let duplicateLabelError = {}
    let _compareValues = cloneDeep(compareValues)

    if (isIntervalTypeChange) {
      _compareValues = []
    }

    const compValueLength = _compareValues && _compareValues.length
    const compareIndex = compValueLength > 0 ? compValueLength + 1 : 1
    const isRelativeType = Boolean(
      upperFirst(tempCompareByType) === 'Relative' ||
        (!isIntervalTypeChange && upperFirst(compareByType) === 'Relative')
    )
    let defaultPeriodValue = isRelativeType
      ? { display_name: `<< ${compareIndex} Day`, value: `<< ${compareIndex} Day` }
      : `Last ${compareIndex} Days`

    if (compValueLength > 0 && _compareValues[compValueLength - 1]) {
      const splitCompValue = isRelativeType
        ? _compareValues[compValueLength - 1]?.value?.split(' ')
        : _compareValues[compValueLength - 1].split(' ')
      const indexValue = DEFAULT_PERIOD_PLURAL_OPTIONS.indexOf(splitCompValue[2])

      if (isRelativeType) {
        const relValue = `${splitCompValue[0]} ${parseInt(splitCompValue[1], 10) + 1} ${
          indexValue > -1 ? splitCompValue[2] : splitCompValue[2] + 's'
        }`

        defaultPeriodValue = { display_name: relValue, value: relValue }
      } else {
        if (splitCompValue.length === 3) {
          defaultPeriodValue =
            indexValue > -1 && INTERVAL_OPTIONS.includes(splitCompValue[0])
              ? `${splitCompValue[0]} ${parseInt(splitCompValue[1], 10) + 1} ${splitCompValue[2]}`
              : `Last ${compareIndex} Days`
        } else if (splitCompValue.length === 2) {
          defaultPeriodValue = `Last ${compareIndex} ${splitCompValue[1] + 's'}`
        }
      }
    }

    _compareValues.push(defaultPeriodValue)

    if (isRelativeType) {
      duplicateLabelError = this.getDuplicateErrObj(_compareValues)
    }

    this.setState(
      {
        isIntervalTypeChange: false,
        compareValues: _compareValues,
        compareByType: isIntervalTypeChange ? tempCompareByType : compareByType,
        tempCompareByType: isIntervalTypeChange ? tempCompareByType : compareByType,
        duplicateLabelError,
      },
      () => {
        if (!compareValues.includes(defaultPeriodValue)) {
          this.callRenderCardWithCompareBy(null, true, null)
        }
      }
    )
  }

  callRenderCardWithCompareBy = (periodValue, isCompareBy, changeIndex) => {
    const { compareValues, compareByType } = this.state
    const { timePeriod, onTimePeriodChange, selectedTimePeriod } = this.props
    const _compareValues = cloneDeep(compareValues)
    const _timePeriod = selectedTimePeriod?.timePeriod
      ? cloneDeep(selectedTimePeriod?.timePeriod)
      : cloneDeep(timePeriod)
    const _compareByType = cloneDeep(compareByType)

    if (isCompareBy) {
      if (changeIndex >= 0) {
        _compareValues[changeIndex] = periodValue
      }

      _timePeriod.compare_by = {
        type: _compareByType.toLowerCase(),
        values: _compareValues,
      }
    } else {
      _timePeriod.compare_by = null
    }
    this.setState({ compareValues: _compareValues })

    if (!compareValues.includes(periodValue)) {
      if (!_timePeriod.compare_by) {
        delete _timePeriod.compare_by
      }
      onTimePeriodChange(_timePeriod)
    }
  }

  confirmDialogOnChangeType() {
    const { isIntervalTypeChange, tempCompareByType } = this.state
    return (
      <ConfirmDialog
        open={isIntervalTypeChange}
        dialogTitle={`Are you sure you want to Change Time Interval Type to "${tempCompareByType}" ?`}
        contentText="Changing time interval type may reset all values."
        okText="Apply"
        cancelText="Cancel"
        onCloseDialog={this.handleCancelIntervalTypeChange}
        onClickOk={this.handleChangeIntervalTypeAddChange}
        onClickCancel={this.handleCancelIntervalTypeChange}
      />
    )
  }

  isCardRenderRequested() {
    const { cardInfo } = this.props

    return cardInfo?.status === 'requested'
  }

  renderCardDataset() {
    const { cardsDatasetStatus, displayServiceErrorMessage, isMobile } = this.props
    const { datasetId } = this.state
    const selectedDataset = cardsDatasetStatus && cardsDatasetStatus[datasetId]?.data
    let isSecondaryTime = false

    if (!datasetId) {
      return null
    }
    if (!cardsDatasetStatus || !Object.keys(cardsDatasetStatus).length) {
      return !isMobile ? <Spinner size="large" layout="selfCentering" className="datasets-status-progress" /> : null
    }
    switch (cardsDatasetStatus[datasetId]?.status) {
      case 'requested':
        return !isMobile ? <Spinner size="large" layout="selfCentering" className="datasets-status-progress" /> : null
      case 'failed':
        displayServiceErrorMessage('The request to retrieve card viewer dataset data failed.')
        break
      default:
        isSecondaryTime = checkMultipleTimeSeries(selectedDataset)
        return cardsDatasetStatus[datasetId]?.data ? (
          <div>
            {this.renderTimePeriod(cardsDatasetStatus[datasetId].data)}
            {isSecondaryTime && this.renderTimePeriod(cardsDatasetStatus[datasetId].data, isSecondaryTime)}
          </div>
        ) : null
    }
  }

  render() {
    const { isDashboard } = this.props
    return <>{isDashboard ? this.renderTimePeriod({}) : this.renderCardDataset()}</>
  }
}

ViewerTimePeriod.defaultProps = {
  cardInfo: {},
  deviceType: [],
  showFiscalSwitch: false,
  timePeriod: {},
}

ViewerTimePeriod.propTypes = {
  dashboardInfo: PropTypes.object,
  onTimePeriodChange: PropTypes.func,
  timePeriod: PropTypes.object,
  isDashboard: PropTypes.bool,
  cardInfo: PropTypes.object,
  cardRender: PropTypes.func,
  displayServiceErrorMessage: PropTypes.func,
}

export default ViewerTimePeriod
