import React, { useState, useEffect } from 'react'
import './dashboardListing.scss'
import { getSiteURLInfo } from '../../../shared/util'
import AssetTable, { AssetType } from '../../../shared/AssetTableSS/AssetTable'

const DashboardContainer = props => {
  const { setHeaderTitle, siteObj } = props
  const [siteUrlInfo, setSiteUrlInfo] = useState([])
  const [siteTags, setSiteTags] = useState('')

  useEffect(() => {
    const siteUrlInfo = getSiteURLInfo()
    let tagStr = ''
    if (siteObj?.data && siteUrlInfo?.length) {
      const { content } = siteObj.data // eslint-disable-line camelcase
      const cardTag = content?.filter(tag => tag.object_type === 'dashboard')[0]
      tagStr = cardTag?.object_tags?.toLocaleString()
      setHeaderTitle && setHeaderTitle(`Dashboards in ${siteObj.data?.name}`)
    } else {
      setHeaderTitle('Dashboards')
    }
    setSiteTags(tagStr)
    setSiteUrlInfo(siteUrlInfo)
  }, [])

  return <AssetTable {...props} assetType={AssetType.DASHBOARDS} siteUrlInfo={siteUrlInfo} siteTags={siteTags} />
}

export default DashboardContainer
