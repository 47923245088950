import React, { useEffect, useState } from 'react'
import {
  Button,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Tab,
  Tabs,
  TextField,
  Typography,
  Divider,
} from '@mui/material'
import classNames from 'classnames'
import { Spinner } from 'greenfield-utilities'
import moment from 'moment'
import debounce from 'lodash/debounce'
import PropTypes from 'prop-types'

import {
  CUSTOM_DATES_OPTIONS,
  DEFAULT_PERIOD_PLURAL_OPTIONS,
  NEXT_TIME_PERIOD,
  PREVIOUS_TIME_PERIOD,
  RELATIVE_TYPES_OPTIONS,
  TODAY_TIME_PERIOD,
} from '../../constants/ArrayConstants'
import { getFormatDate } from '../util'
import DatePickerComponent from '../Calendar/DatePickerComponent'

const RelativeTimePeriod = props => {
  const {
    handleDateChange,
    handleCancel,
    fiscalYear,
    dateFilterStatus,
    anchorDateFilterStatus,
    getAnchorDateFilter,
    cardInfo,
    isDashboard,
    dashboardInfo,
    isViewer,
    viewerJson,
    compareByValue,
    isCompareBy,
    isMobile,
    isSecondaryTime,
    secondaryTimePeriodColumn,
    selectedTimePeriod,
    selectedDashboardTimePeriod,
    secondaryTime,
    isVirtualColumnFilter = false,
  } = props
  const currentDate = getFormatDate()
  const selectedSecondaryTimePeriods = selectedTimePeriod?.secondaryTimePeriods || secondaryTime?.secondaryTimePeriods
  const secondaryTimePeriods = isViewer
    ? selectedSecondaryTimePeriods ||
      cardInfo?.data?.context?.rendered_payload?.secondary_time_periods ||
      cardInfo?.data?.card_config?.card_query_attribute?.secondary_time_periods
    : cardInfo?.data?.card_config?.card_query_attribute?.secondary_time_periods
  const secondaryTimePeriod = secondaryTimePeriods && secondaryTimePeriods[secondaryTimePeriodColumn?.field_name]

  /* eslint-disable camelcase */
  const timePeriod = isViewer
    ? isDashboard
      ? selectedDashboardTimePeriod || viewerJson
        ? viewerJson
        : dashboardInfo?.time_period
      : isSecondaryTime
      ? secondaryTimePeriod
      : selectedTimePeriod?.timePeriod || viewerJson
    : cardInfo.status >= 200 && cardInfo.status < 300
    ? isViewer
      ? isSecondaryTime
        ? secondaryTimePeriod
        : selectedTimePeriod?.timePeriod || cardInfo?.data?.context?.rendered_payload?.time_period
      : isSecondaryTime
      ? secondaryTimePeriod
      : cardInfo?.data?.card_config?.card_query_attribute?.time_period
    : getFormatDate()
  const defaultDateType = timePeriod?.anchor_date && !isCompareBy ? 'anchorDate' : 'currentDate'
  const anchorDate = timePeriod?.anchor_date && !isCompareBy ? timePeriod?.anchor_date : currentDate
  /* eslint-enable camelcase */

  let interval = ''
  let defaultTabIndex = 0
  let customNumber = '1'
  let customRange = 'Days'
  let { Fiscal, Gregorian } = dateFilterStatus?.data

  if (timePeriod?.interval) {
    interval = isCompareBy ? compareByValue : timePeriod.interval
    const customArray = interval?.split(' ')
    if (customArray?.length === 3 && CUSTOM_DATES_OPTIONS.includes(customArray[0])) {
      interval = customArray[0]
      customNumber = customArray[1]
      customRange = customArray[2]
    }
  }
  if (TODAY_TIME_PERIOD.includes(interval)) {
    defaultTabIndex = 0
  } else if (PREVIOUS_TIME_PERIOD.includes(interval) || interval === 'Last') {
    defaultTabIndex = 1
  } else if (NEXT_TIME_PERIOD.includes(interval) || interval === 'Next') {
    defaultTabIndex = 2
  }

  const getRelativeTypeSelection = value => {
    let relativeTypePeriod = []
    switch (value) {
      case 0:
        relativeTypePeriod = TODAY_TIME_PERIOD
        break
      case 1:
        relativeTypePeriod = PREVIOUS_TIME_PERIOD
        break
      case 2:
        relativeTypePeriod = NEXT_TIME_PERIOD
        break
      default:
        relativeTypePeriod = TODAY_TIME_PERIOD
        break
    }
    return relativeTypePeriod
  }

  const debounceGetAnchorDateFilter = debounce(anchorDate => {
    getAnchorDateFilter({ anchor_date: anchorDate })
  }, 500)

  const [dateType, setDateType] = useState(defaultDateType)
  const [tabIndex, setTabIndex] = useState(defaultTabIndex)
  const [relativeTimeValue, setRelativeTimeValue] = useState(interval)
  const [anchorRelativeDate, setAnchorRelativeDate] = useState(anchorDate)
  const [relativeTimeNumber, setRelativeTimeNumber] = useState(customNumber)
  const [relativeTimeRange, setRelativeTimeRange] = useState(customRange)
  const [calendarType, setCalendarType] = useState('')
  const [relativeTypePeriodArray, setRelativeTypePeriodArray] = useState(getRelativeTypeSelection(defaultTabIndex))

  useEffect(() => {
    if (anchorDateFilterStatus?.data) {
      Fiscal = anchorDateFilterStatus.data?.Fiscal
      Gregorian = anchorDateFilterStatus.data?.Gregorian
    }
    setCalendarType(fiscalYear ? Fiscal : Gregorian)
  }, [anchorDateFilterStatus])

  useEffect(() => {
    if (isVirtualColumnFilter) {
      applySelectedRelativeTimePeriod()
    }
  }, [relativeTimeValue, relativeTimeNumber, relativeTimeRange, anchorRelativeDate])

  useEffect(() => {
    if (dateType === 'anchorDate' && anchorRelativeDate) {
      Fiscal = anchorDateFilterStatus.data?.Fiscal
      Gregorian = anchorDateFilterStatus.data?.Gregorian
    } else {
      Fiscal = dateFilterStatus.data?.Fiscal
      Gregorian = dateFilterStatus.data?.Gregorian
    }
    setCalendarType(fiscalYear ? Fiscal : Gregorian)
  }, [dateType])

  const handleTabIndexChange = (event, value) => {
    setTabIndex(value)
    setRelativeTimeValue('')
    setRelativeTimeNumber('1')
    setRelativeTimeRange('Days')
    setRelativeTypePeriodArray(getRelativeTypeSelection(value))
  }

  const handleDateTypeChange = event => {
    setDateType(event.target.value)
  }

  const checkExistenceValue = (calendarType, value) => {
    return !(calendarType && (!calendarType[value] || !calendarType[value]['Begin Date']))
  }

  const getRelativePeriodValue = timeValue => {
    const dateRange =
      calendarType &&
      checkExistenceValue(calendarType, timeValue) &&
      `${getFormatDate(calendarType[timeValue]['Begin Date'])} to
                        ${getFormatDate(calendarType[timeValue]['End Date'])}`
    return (
      <>
        <span>{timeValue}</span>
        <span>{dateRange || ''}</span>
      </>
    )
  }

  const renderRelativeLastNextConfigure = tabIndex => {
    const customValue = tabIndex === 1 ? 'Last' : 'Next'

    return (
      <div className="relative-last-next-section">
        <div className="last-next-label">{customValue}</div>

        <div className="last-next-container">
          <TextField
            id="enter-number"
            label="Enter Value"
            type="search"
            margin="none"
            className="last-next-text"
            value={relativeTimeNumber}
            onChange={event => {
              const _value = event.target.value
              setRelativeTimeNumber(_value)
            }}
            onFocus={() => {
              setRelativeTimeValue(customValue)
            }}
            variant="standard"
          />
        </div>
        <TextField
          id="select-gran-value"
          label="Select"
          select
          value={relativeTimeRange}
          className=""
          onChange={event => {
            const _value = event.target.value
            setRelativeTimeRange(_value)
          }}
          onFocus={() => {
            setRelativeTimeValue(customValue)
          }}
          variant="standard"
        >
          {DEFAULT_PERIOD_PLURAL_OPTIONS.map((option, index) => (
            <MenuItem key={option} value={option} id={`default-period-${index}`}>
              {option}
            </MenuItem>
          ))}
        </TextField>
      </div>
    )
  }

  const applySelectedRelativeTimePeriod = () => {
    let relativeDateValue = relativeTimeValue
    if (CUSTOM_DATES_OPTIONS.includes(relativeTimeValue)) {
      relativeDateValue = `${relativeTimeValue} ${relativeTimeNumber} ${relativeTimeRange}`
    }
    if (dateType === 'anchorDate') {
      handleDateChange(relativeDateValue, anchorRelativeDate)
    } else {
      handleDateChange(relativeDateValue)
    }
  }

  return (
    <>
      <div className={classNames('relative-section', !isMobile ? 'calendar-fit' : '')}>
        {dateType === 'anchorDate' && anchorDateFilterStatus?.status === 'requested' && (
          <Spinner className="date-spinner" layout="selfCentering" />
        )}

        {!isCompareBy && !isVirtualColumnFilter && (
          <RadioGroup aria-label="Date Type" name="Date Type" value={dateType} onChange={handleDateTypeChange} row>
            <FormControlLabel
              value="currentDate"
              control={<Radio id="current-date-btn" aria-label="Current Date" />}
              label={<span className={dateType === 'currentDate' ? 'selected-relative-type' : ''}>Current Date</span>}
              labelPlacement="end"
            />
            <FormControlLabel
              value="anchorDate"
              control={<Radio id="anchor-date-btn" aria-label="Anchor Date" />}
              label={<span className={dateType === 'anchorDate' ? 'selected-relative-type' : ''}>Anchor Date</span>}
              labelPlacement="end"
            />
          </RadioGroup>
        )}

        <div className="anchor-picker-section">
          {dateType === 'currentDate' && (
            <Typography variant="body1" className="current-date">
              Current Date: <span>{currentDate}</span>
            </Typography>
          )}
          {dateType === 'anchorDate' && !isCompareBy && (
            <>
              <Typography variant="body1" className="anchor-picker-label">
                Anchor Relative Date
              </Typography>
              <DatePickerComponent
                id="anchor-relative-date"
                ariaLabel="Anchor Relative Date"
                value={anchorRelativeDate}
                minDate={getFormatDate('1900-01-01')}
                maxDate={getFormatDate('2100-12-31')}
                handleOnChange={dateInput => {
                  if (dateInput) {
                    const dateValue = dateInput.format()
                    const anchorDate = dateValue ? dateValue.substr(0, 10) : getFormatDate()
                    setAnchorRelativeDate(anchorDate)

                    if (moment(dateInput).isValid()) {
                      debounceGetAnchorDateFilter(anchorDate)
                    }
                  }
                }}
              />
            </>
          )}
        </div>

        <div
          className={
            anchorDateFilterStatus?.status === 'requested' && dateType === 'anchorDate' ? 'disable-date-section' : ''
          }
        >
          <Tabs value={tabIndex} onChange={handleTabIndexChange} variant="fullWidth">
            {RELATIVE_TYPES_OPTIONS.map((name, index) => (
              <Tab id={`relative-tab-${index}`} key={name} label={name} aria-label={name} />
            ))}
          </Tabs>

          <RadioGroup
            aria-label="relative dates options"
            name="relative dates options"
            value={relativeTimeValue}
            onChange={event => {
              const _value = event.target.value
              setRelativeTimeValue(_value)
            }}
          >
            {relativeTypePeriodArray.map((timeValue, index) => {
              const radioBtnNAme = getRelativePeriodValue(timeValue)
              return (
                <FormControlLabel
                  key={timeValue}
                  value={timeValue}
                  control={<Radio id={`radio-btn-${index}`} aria-label={radioBtnNAme} />}
                  label={
                    <div
                      className={classNames(
                        'relative-dates',
                        timeValue === relativeTimeValue ? 'selected-dates' : 'not-selected-dates'
                      )}
                    >
                      {radioBtnNAme}
                    </div>
                  }
                />
              )
            })}
            {tabIndex !== 0 && (
              <FormControlLabel
                value={tabIndex === 1 ? 'Last' : 'Next'}
                control={<Radio id="radio-btn-last-next" aria-label={tabIndex === 1 ? 'Last' : 'Next'} />}
                label={<div>{renderRelativeLastNextConfigure(tabIndex)}</div>}
              />
            )}
          </RadioGroup>
        </div>
      </div>
      {!isVirtualColumnFilter && (
        <>
          <Divider />
          <div className="relative-button">
            <Button
              id="cancel-relative-dates"
              aria-label="Cancel relative dates"
              variant="text"
              color="secondary"
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              id="apply-relative-dates"
              aria-label="Apply relative dates"
              variant="text"
              color="primary"
              onClick={() => applySelectedRelativeTimePeriod()}
              disabled={!relativeTimeValue || (CUSTOM_DATES_OPTIONS.includes(relativeTimeValue) && !relativeTimeNumber)}
            >
              {`${isViewer && !isMobile ? 'Select' : 'Apply'}`}
            </Button>
          </div>
        </>
      )}
    </>
  )
}
RelativeTimePeriod.defaultProps = {
  fiscalYear: false,
  dateFilterStatus: {},
  anchorDateFilterStatus: {},
  cardInfo: {},
  isDashboard: false,
  dashboardInfo: {},
  isViewer: false,
  viewerJson: {},
  compareByValue: '',
  isCompareBy: false,
  isMobile: false,
}

RelativeTimePeriod.propTypes = {
  handleDateChange: PropTypes.func,
  handleCancel: PropTypes.func,
  fiscalYear: PropTypes.bool,
  dateFilterStatus: PropTypes.object,
  anchorDateFilterStatus: PropTypes.object,
  getAnchorDateFilter: PropTypes.func,
  cardInfo: PropTypes.object,
  isDashboard: PropTypes.bool,
  dashboardInfo: PropTypes.object,
  isViewer: PropTypes.bool,
  viewerJson: PropTypes.object,
  compareByValue: PropTypes.string,
  isCompareBy: PropTypes.bool,
  isMobile: PropTypes.bool,
}

export default RelativeTimePeriod
