import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { updateCardsDataset } from '../../../ducks/builder'
import ViewerTimePeriod from './ViewerTimePeriod'

export class ViewerTimePeriodContainer extends React.Component {
  componentDidMount() {
    const { cardsDatasetStatus, cardInfo, isDashboard, updateCardsDataset } = this.props

    if (!isDashboard && cardInfo.status >= 200 && cardInfo.status < 300) {
      const datasetId = cardInfo.data.card_config.card_attribute.dataset_id

      if (!cardsDatasetStatus || !Object.keys(cardsDatasetStatus).length || !cardsDatasetStatus[datasetId]) {
        updateCardsDataset(datasetId)
      }
    }
  }

  render() {
    return <ViewerTimePeriod {...this.props} />
  }
}

const mapStateToProps = state => ({
  cardsDatasetStatus: state.builder.cardsDatasetStatus,
})

export const mapDispatchToProps = dispatch => ({
  updateCardsDataset(data) {
    dispatch(updateCardsDataset(data))
  },
})

ViewerTimePeriodContainer.defaultProps = {
  cardsDatasetStatus: {},
  cardInfo: {},
  updateCardsDataset: () => {},
  hideViewerTimePeriod: false,
}

ViewerTimePeriodContainer.propTypes = {
  hideViewerTimePeriod: PropTypes.bool,
  updateCardsDataset: PropTypes.func,
  cardsDatasetStatus: PropTypes.object,
  dashboardInfo: PropTypes.object,
  onTimePeriodChange: PropTypes.func,
  timePeriod: PropTypes.object,
  isDashboard: PropTypes.bool,
  cardInfo: PropTypes.object,
  cardRender: PropTypes.func,
  routeProps: PropTypes.object.isRequired,
  selectedTimePeriod: PropTypes.object,
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewerTimePeriodContainer)
