import React from 'react'
import PropTypes from 'prop-types'
import { Button, CircularProgress, Dialog, DialogTitle, TextField, Typography, Slide, Grid, Radio } from '@mui/material'
import { Spinner } from 'greenfield-utilities'
import './admin.scss'
import debounce from 'lodash/debounce'
import cloneDeep from 'lodash/cloneDeep'

import { GreenfieldAutoComplete } from '../../shared/shared'
import { API_GATEWAY_URL } from '../../../ducks/utils'

const Transition = React.forwardRef((props, ref) => <Slide direction="up" {...props} ref={ref} />)

class AdminAddGroup extends React.Component {
  state = {
    createNewGroupDialogShown: false,
    createNewGroupError: false,
    groupAddValue: '',
    addedOwners: [],
    suggestions: [],
    isDefaultPresent: true,
    isNameUpdated: false,
    isOwnersUpdated: false,
    selectedGroupType: 'internal',
    isGroupTypeChanged: false,
  }

  componentDidMount() {
    this.props.fetchDefaultOwner()
    if (this.groupNameRef) {
      // if statment is for unit tests.  - 49754518/Art
      this.groupNameRef.focus()
    }
  }

  /* eslint-disable camelcase */
  UNSAFE_componentWillReceiveProps(nextProps) {
    /* eslint-enable camelcase */
    const { defaultOwner, autoSuggest, createNewGroupStatus, editGroupStatus, adminDetail, isEditGroup } = nextProps
    const { selectedGroupType, isGroupTypeChanged } = this.state
    const sugnArr = []
    let updateGroupId = null

    if (
      (createNewGroupStatus &&
        createNewGroupStatus.status !== this.props.createNewGroupStatus.status &&
        createNewGroupStatus.status >= 200 &&
        createNewGroupStatus.status < 300) ||
      (editGroupStatus &&
        editGroupStatus.status !== this.props.editGroupStatus.status &&
        editGroupStatus.status >= 200 &&
        editGroupStatus.status < 300)
    ) {
      this.setState(
        {
          createNewGroupDialogShown: true,
        },
        () => {
          nextProps.onDisableAddingNewGroup()

          if (nextProps.routeProps.history.length) {
            updateGroupId =
              createNewGroupStatus && createNewGroupStatus.data
                ? createNewGroupStatus.data[0]._id
                : editGroupStatus.data._id
            nextProps.routeProps.history.replace(`/admin/groups/${updateGroupId}/members`)
          }
          nextProps.updateSidebarInfo(`${API_GATEWAY_URL}/bi_reporting_assets/v2/groups?page=1&per_page=100`)

          setTimeout(() => {
            this.setState({
              createNewGroupDialogShown: false,
            })
          }, 1000)
        }
      )
    } else if (
      createNewGroupStatus &&
      createNewGroupStatus.status !== this.props.createNewGroupStatus.status &&
      createNewGroupStatus.status === 'failed'
    ) {
      this.setState({
        createNewGroupError: true,
      })
    }

    if (autoSuggest.data && autoSuggest.data.length > 0) {
      autoSuggest.data.forEach(item => {
        item.label = `${item.first_name} ${item.last_name} - ${item._id}`
        sugnArr.push(item)
      })
      this.setState({ suggestions: sugnArr })
    }

    switch (defaultOwner.status) {
      case 'requested':
        return <CircularProgress size={100} className="page-circular-progress" />
      case 'failed':
        this.props.displayServiceErrorMessage("The request to retrieve a user's admin details failed.")
        break
      default:
        if (Object.keys(defaultOwner).length > 0) {
          this.setState({
            defaultOwnerShown: true,
            selectedGroupType: isEditGroup && !isGroupTypeChanged ? adminDetail.data.user_type : selectedGroupType,
          })

          if (defaultOwner.data.first_name.length > 0) {
            defaultOwner.data.label = `${defaultOwner.data.first_name} ${defaultOwner.data.last_name}`

            let existingOwners = [defaultOwner.data]

            if (adminDetail && adminDetail.data && adminDetail.data.owners_access && this.props.isEditGroup) {
              existingOwners = adminDetail.data.owners_access.map(owner => {
                const ownerName = owner.user_group_name.split(' ')
                return {
                  _id: owner._id,
                  first_name: ownerName[0],
                  last_name: ownerName[1],
                }
              })
            }
            // Add group is shared component for Add and edit group feature, hence checking for both conditions
            const owners = this.props.isEditGroup
              ? this.state.addedOwners.length
                ? this.state.addedOwners
                : existingOwners
              : this.state.addedOwners.length
              ? this.state.addedOwners
              : [defaultOwner.data]
            this.setState({ addedOwners: owners })
          }
          return this.render()
        }
    }
  }

  closeErrorDialog() {
    this.setState({
      createNewGroupError: false,
    })
  }

  selectedSuggestions = selections => {
    const { addedOwners } = this.state
    let index = -1
    if (selections._id) {
      isNaN(selections) ? addedOwners.push(selections) : addedOwners.splice(selections, 1)
      this.setState({
        addedOwners,
        isOwnersUpdated: true,
      })
    } else if (Number.isInteger(selections)) {
      index = parseInt(selections, 10)
      if (index === 0) {
        this.setState({
          isDefaultPresent: false,
        })
      }
      addedOwners.splice(index, 1)
      this.setState({
        addedOwners,
        isOwnersUpdated: true,
      })
    }
  }

  handleSaveSubmit = () => {
    if (this.props.isEditGroup) {
      this.handleEditGroupSubmitClick()
    } else {
      this.handleAddGroupSubmitClick()
    }
  }

  handleAddGroupSubmitClick = () => {
    const ownerIds = this.state.addedOwners.map(addedOwner => addedOwner._id)

    const groupDetails = {
      groupAddValue: this.state.groupAddValue,
      groupAddOwners: ownerIds,
      user_type: this.state.selectedGroupType,
    }
    this.props.sendFireflyEvent(false, {
      eventName: 'adminAddGroup',
    })
    this.props.createNewGroup(groupDetails)
    this.setState({
      groupAddValue: '',
      groupAddOwners: [],
    })
  }

  handleEditGroupSubmitClick = () => {
    const ownerIds = this.state.addedOwners.map(addedOwner => addedOwner._id)

    const groupDetails = {
      groupAddValue: this.state.groupAddValue.length
        ? this.state.groupAddValue
        : this.props.adminDetail.data.group_name,
      groupAddOwners: ownerIds,
      groupId: this.props.adminDetail.data._id,
      user_type: this.state.selectedGroupType,
    }
    this.props.sendFireflyEvent(false, {
      eventName: 'adminEditGroup',
    })
    this.props.editGroup(groupDetails)
    this.setState({
      groupAddValue: '',
      groupAddOwners: [],
    })
  }

  handleGroupInputChange = event => {
    this.setState({
      groupAddValue: event.target.value,
      isNameUpdated: true,
    })
  }

  handleGroupTypeChange = event => {
    this.setState({
      selectedGroupType: event.target.value,
      isGroupTypeChanged: true,
    })
  }

  searchApi = searchText => {
    const url = `${API_GATEWAY_URL}/bi_reporting_assets/v2/users?page=1&per_page=5&search_string=${searchText}`

    this.props.fetchAutoSuggest(url)
  }

  render() {
    const {
      createNewGroupStatus,
      defaultOwner,
      onDisableAddingNewGroup,
      isEditGroup,
      adminDetail,
      isMobile,
      addedMembers,
    } = this.props
    const autoSuggest = cloneDeep(this.props.autoSuggest)
    let {
      groupAddValue,
      addedOwners,
      isDefaultPresent,
      createNewGroupDialogShown,
      createNewGroupError,
      suggestions,
      isNameUpdated,
      isOwnersUpdated,
      selectedGroupType,
    } = this.state
    let updatedDefaults = []

    if (isEditGroup) {
      if (!isNameUpdated) {
        groupAddValue = adminDetail.data && adminDetail.data.group_name
      }
      if (!isOwnersUpdated) {
        addedOwners = adminDetail.data.owners_access.map(owner => {
          const ownerName = owner.user_group_name.split(' ')
          return {
            _id: owner._id,
            first_name: ownerName[0],
            last_name: ownerName[1],
          }
        })
      }
    }
    if (addedOwners?.length && isEditGroup) {
      updatedDefaults = addedOwners.map(owner => {
        return owner.first_name ? `${owner.first_name} ${owner.last_name}` : `${owner}`
      })
    }
    if (!updatedDefaults.length && isDefaultPresent && defaultOwner.data) {
      updatedDefaults = [`${defaultOwner.data.first_name} ${defaultOwner.data.last_name}`]
    }
    const headText = !isEditGroup ? 'Create New Group' : 'Edit Group'

    const saveBtn = () => (
      <Button
        id="save-btn"
        variant="text"
        color="primary"
        className="save-btn"
        aria-label="Save group"
        onClick={this.handleSaveSubmit}
        disabled={Boolean(!groupAddValue) || Boolean(addedOwners.length === 0)}
      >
        {!isMobile && !updatedDefaults?.length ? <CircularProgress size={18} color="inherit" /> : 'Save'}
      </Button>
    )

    const cancelBtn = () => (
      <Button
        id="cancel-btn"
        variant="text"
        color="secondary"
        className="cancel-btn"
        aria-label="Cancel create new group"
        onClick={onDisableAddingNewGroup}
      >
        Cancel
      </Button>
    )

    return (
      <>
        <Dialog open={createNewGroupDialogShown} transition={Transition}>
          <DialogTitle>New group successfully created</DialogTitle>
        </Dialog>
        <Dialog open={createNewGroupError} transition={Transition} onBackdropClick={this.closeErrorDialog.bind(this)}>
          <DialogTitle>Group already exists, please use a different name</DialogTitle>
        </Dialog>
        <section className="isaddinggroup-container">
          <Typography variant="body1">
            <b>{headText}</b>
          </Typography>
          <div>
            <span>
              <Radio
                label="Internal"
                id="Internal-group"
                checked={selectedGroupType === 'internal'}
                onChange={this.handleGroupTypeChange}
                disabled={
                  isEditGroup ? !(addedMembers && addedMembers.data && addedMembers.data.members.length === 0) : false
                }
                value="internal"
                name="group_type"
                aria-label="Internal"
              />
              Internal
            </span>
            <span>
              <Radio
                label="External"
                id="External-group"
                disabled={
                  isEditGroup ? !(addedMembers && addedMembers.data && addedMembers.data.members.length === 0) : false
                }
                checked={selectedGroupType === 'external'}
                onChange={this.handleGroupTypeChange}
                value="external"
                name="group_type"
                aria-label="External"
              />
              External
            </span>
          </div>
          <div>
            <TextField
              id="enter-group-name"
              required
              variant="outlined"
              style={{ minWidth: 300 }}
              label="Group Name"
              margin="normal"
              onChange={this.handleGroupInputChange}
              value={groupAddValue}
              inputRef={input => (this.groupNameRef = input)}
            />
            {this.props.defaultOwner.data ? (
              <>
                <div className="isadding-header-textfield">
                  <div className="field-margin">Owner(s)</div>
                  <GreenfieldAutoComplete
                    label="Enter the Owner(s) *"
                    isEditable
                    default={updatedDefaults}
                    searchApi={debounce(this.searchApi, 1000)}
                    searchStatus={autoSuggest}
                    suggestions={suggestions}
                    selectedSuggestions={this.selectedSuggestions.bind(this)}
                    labelText="Owners"
                  />
                </div>
              </>
            ) : (
              <div />
            )}
            {createNewGroupStatus && createNewGroupStatus.status === 'requested' && (
              <Spinner size="large" layout="selfCentering" />
            )}
            {!isMobile ? (
              <div className="addground-button-container">
                {saveBtn()}
                {cancelBtn()}
              </div>
            ) : (
              <div>
                <Grid container spacing={3}>
                  <Grid item xs={6} className="mobileFilterBtnLeft">
                    {cancelBtn()}
                  </Grid>
                  <Grid item xs={6} className="mobileFilterBtnRight">
                    {saveBtn()}
                  </Grid>
                </Grid>
              </div>
            )}
          </div>
        </section>
      </>
    )
  }
}

AdminAddGroup.propTypes = {
  isEditGroup: PropTypes.bool,
  adminDetail: PropTypes.object,
  defaultOwner: PropTypes.object,
  autoSuggest: PropTypes.object,
  createNewGroupStatus: PropTypes.object,
  editGroupStatus: PropTypes.object,
  routeProps: PropTypes.object,
  fetchDefaultOwner: PropTypes.func,
  fetchAutoSuggest: PropTypes.func,
  createNewGroup: PropTypes.func,
  editGroup: PropTypes.func,
  onDisableAddingNewGroup: PropTypes.func,
  updateSidebarInfo: PropTypes.func,
}

AdminAddGroup.defaultProps = {
  isEditGroup: false,
  adminDetail: {},
  defaultOwner: {},
  autoSuggest: {},
  createNewGroupStatus: {},
  editGroupStatus: {},
  routeProps: {},
  fetchDefaultOwner: () => {},
  fetchAutoSuggest: () => {},
  createNewGroup: () => {},
  onDisableAddingNewGroup: () => {},
  updateSidebarInfo: () => {},
}

export default AdminAddGroup
