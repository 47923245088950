import React, { useEffect, useState, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { Spinner } from 'greenfield-utilities'
import PropTypes from 'prop-types'
import { Dialog, DialogTitle, DialogContent, TextField, Tooltip, InputAdornment } from '@mui/material'
import { FileCopy } from '@mui/icons-material'
import axios from 'axios'
import styled from 'styled-components'
import { withAnalytics } from '@praxis/component-analytics'

import analyticsConfig from '../analytics'
import { displayServiceErrorMessage } from '../../ducks/layout'
import { API_GATEWAY_URL } from '../../ducks/utils'

const SFileCopy = styled(FileCopy)`
  cursor: pointer;
`
const SDialog = styled(Dialog)`
  .MuiPaper-root.MuiDialog-paper {
    width: 98%;
    max-width: 500px;
  }
`
const SDialogTitle = styled(DialogTitle)`
  text-align: center;
`

const Generateshortlink = ({ routeProps, onDismiss, type, id, trackEvent }) => {
  const [isLoading, updateIsLoading] = useState(true)
  const [shortLinkInfo, updateShortLinkInfo] = useState('')
  const dispatch = useDispatch()

  const { origin } = window.location

  const textInput = useRef(null)

  useEffect(() => {
    axios
      .post(`${API_GATEWAY_URL}/bi_reporting_assets/v2/shortlinks`, {
        long_url: `${routeProps.location.pathname}${routeProps.location.search}`,
        object_type: type,
        object_id: parseInt(id, 10),
      })
      .then(response => {
        updateIsLoading(false)
        updateShortLinkInfo(`${origin}/l/${type}/${id}/${response.data}`)
        textInput.current.select()
      })
      .catch(() => {
        updateIsLoading(false)
        updateShortLinkInfo('')
        dispatch(displayServiceErrorMessage('Something went wrong with your request to generate a shortlink'))
      })

    trackEvent({
      event: {
        type: 'generateShortURL',
      },
    })
  }, [])

  const copyLink = () => {
    window.navigator.clipboard.writeText(shortLinkInfo)
  }

  return (
    <SDialog open onClose={onDismiss} id="generate-shortlink-modal">
      <SDialogTitle>Short link to this page:</SDialogTitle>
      {isLoading ? (
        <Spinner size="large" layout="selfCentering" />
      ) : (
        <DialogContent>
          <TextField
            variant="outlined"
            value={shortLinkInfo}
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
            inputRef={textInput}
            margin="normal"
            fullWidth
            onFocus={event => {
              event.target.select()
            }}
            name="generate-shortlink-value"
            id="generate-shortlink-value"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" onClick={copyLink}>
                  <Tooltip title="Copy to clipboard" placement="top">
                    <SFileCopy />
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />
        </DialogContent>
      )}
    </SDialog>
  )
}

Generateshortlink.propTypes = {
  routeProps: PropTypes.object.isRequired,
  onDismiss: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  trackEvent: PropTypes.func.isRequired,
}

export default withAnalytics(analyticsConfig)(Generateshortlink)
