import React, { useState, useEffect } from 'react'
import { Typography, Paper, ListItem, List, IconButton, Tooltip } from '@mui/material'
import { Cancel, AddBox } from '@mui/icons-material'
import debounce from 'lodash/debounce'
import cloneDeep from 'lodash/cloneDeep'
import AdvanceFilter from '../../shared/AdvanceFilter/AdvanceFilter'
import { GreenfieldAutoComplete } from '../../shared/shared'
import { advanceFilter } from '../../shared/util'

function RenderNewColumnSecurity(props) {
  const [searchValue, setSearchValue] = useState('')
  const [viewerSuggestions, setViewerSuggestions] = useState([])
  const displayFields = advanceFilter(searchValue, props.metricDimCols, ['column_display_name', 'field_name'])

  const searchViewerNames = debounce(searchText => {
    const payload = {
      type: 'none',
      data: searchText,
    }
    props.getDetailsOwners(payload)
  }, 1000)

  useEffect(() => {
    const { detailsOwnersStatus } = props
    if (detailsOwnersStatus && detailsOwnersStatus.status >= 200 && detailsOwnersStatus.status < 300) {
      const viewerSuggestionsTemp = detailsOwnersStatus.data.map(item => ({
        ...item,
        label: item.user_group_name,
      }))
      setViewerSuggestions(viewerSuggestionsTemp)
    }
  }, [props])

  return (
    <>
      <div className="width-40">
        <Typography variant="body1">
          Select Column<sup>*</sup>
        </Typography>
        <>
          <AdvanceFilter
            required
            placeholder="Search Dataset Field"
            onSearchChange={event => setSearchValue(event.target.value)}
            searchValue={(props.currentIndex === props.index ? searchValue : props.columnValue.fieldName) || ''}
            className="search-field-input"
            id={`addMetrics${props.index}`}
            label=""
            onFocus={() => props.setCurrentIndex(props.index)}
          />
        </>
        {props.currentIndex === props.index && (
          <Paper data-cy="column-suggestion" className="column-suggestion">
            <List>
              {displayFields.map(columnV => (
                <Tooltip title={columnV.column_display_name} key={columnV.field_name}>
                  <ListItem
                    onClick={() => props.columnSelected(columnV, props.index)}
                    key={columnV.field_name}
                    data-cy={columnV.field_name}
                  >
                    {columnV.column_display_name}
                  </ListItem>
                </Tooltip>
              ))}
            </List>
          </Paper>
        )}
      </div>
      <div className="width-40">
        <Typography variant="body1">
          Select Viewers<sup>*</sup>
        </Typography>
        <GreenfieldAutoComplete
          variant="outlined"
          isEditable
          label="Add Column Viewers"
          searchApi={value => {
            searchViewerNames(value)
          }}
          searchStatus={props.detailsOwnersStatus}
          suggestions={viewerSuggestions}
          selectedSuggestions={selections => props.selectedViewerAccess(selections, props.index)}
          default={props.columnValue.label}
          labelText="Viewers"
          id={`addViewer${props.index}`}
        />
      </div>
    </>
  )
}

function ColumnSecurityAdd(props) {
  const { columnSecurity, setColumnSecurity } = props
  const [currentIndex, setCurrentIndex] = useState(-1)

  const removeSecurity = index => {
    const columnSecurityClone = cloneDeep(columnSecurity)
    columnSecurityClone.splice(index, 1)
    setColumnSecurity(columnSecurityClone)
  }

  const clickAddColumnSecurity = () => {
    const columnSecurityClone = cloneDeep(columnSecurity)
    columnSecurityClone.push({})
    setColumnSecurity(columnSecurityClone)
  }

  const selectedViewerAccess = (selections, indexV) => {
    const columnSecurityClone = cloneDeep(columnSecurity || [])
    if (columnSecurityClone.length > indexV) {
      const viewers = columnSecurityClone[indexV].viewersAccess || []
      let index = -1

      if (selections._id) {
        isNaN(selections) ? viewers.push(selections) : viewers.splice(selections, 1)
        columnSecurityClone[indexV].viewersAccess = viewers
      } else if (Number.isInteger(selections)) {
        index = parseInt(selections, 10)
        viewers.splice(index, 1)
        columnSecurityClone[indexV].viewersAccess = viewers
      }
      columnSecurityClone[indexV].user = filterUserType(columnSecurityClone[indexV].viewersAccess, 'user')
      columnSecurityClone[indexV].label = columnSecurityClone[indexV].viewersAccess.map(
        viewer => viewer.user_group_name || viewer.label
      )
      columnSecurityClone[indexV].group = filterUserType(columnSecurityClone[indexV].viewersAccess, 'group')
      setColumnSecurity(columnSecurityClone)
    }
  }

  const filterUserType = (usersArr, type) => {
    return (
      usersArr &&
      usersArr.reduce((accumulator, eachOwner) => {
        if (eachOwner.type === type) {
          accumulator.push(eachOwner._id)
        }
        return accumulator
      }, [])
    )
  }

  const errorReduce = () => {
    return (
      columnSecurity &&
      columnSecurity.reduce((accumulator, eachColumn) => {
        if (eachColumn.fieldName) {
          if (eachColumn.fieldName in accumulator) {
            accumulator[eachColumn.fieldName] = true
          } else {
            accumulator[eachColumn.fieldName] = false
          }
        }
        return accumulator
      }, {})
    )
  }

  const columnSelected = (column, indexV) => {
    const columnSecurityClone = cloneDeep(columnSecurity || [])
    const index = props.columnSecurityMetrics.findIndex(col => col.fieldName === column.field_name)
    if (columnSecurityClone.length > indexV) {
      if (index >= 0) {
        columnSecurityClone[indexV].viewersAccess = props.columnSecurityMetrics[index].viewersAccess
        columnSecurityClone[indexV].user = props.columnSecurityMetrics[index].user
        columnSecurityClone[indexV].label = props.columnSecurityMetrics[index].label
        columnSecurityClone[indexV].group = props.columnSecurityMetrics[index].group
        columnSecurityClone[indexV].index = index
        columnSecurityClone[indexV].column = props.columnSecurityMetrics[index].column
      }
      columnSecurityClone[indexV].fieldName = column.field_name
      columnSecurityClone[indexV].column = column
      setColumnSecurity(columnSecurityClone)
      setCurrentIndex(-1)
    }
  }

  function renderNewColumnSecurity() {
    const leng = columnSecurity.length - 1
    const errorJson = errorReduce()
    return (
      <div className="margin-50">
        {columnSecurity.map((columnValue, index) => (
          <div key={index}>
            <div className="add-column-level-security" data-cy="add-column-level-security">
              <RenderNewColumnSecurity
                index={index}
                currentIndex={currentIndex}
                columnValue={columnValue}
                setCurrentIndex={setCurrentIndex}
                columnSelected={columnSelected}
                selectedViewerAccess={selectedViewerAccess}
                errorJson={errorJson}
                {...props}
              />
              <div>
                {Boolean(leng) && (
                  <IconButton onClick={() => removeSecurity(index)} id={`remove-column${index}`}>
                    <Cancel />
                  </IconButton>
                )}
              </div>
            </div>
            {(errorJson[columnValue.fieldName] ||
              !columnValue.fieldName ||
              !(columnValue.viewersAccess && columnValue.viewersAccess.length)) && (
              <Typography className="error-message-column" data-cy="error-message-details" id="error-message-details">
                {errorJson[columnValue.fieldName] ? 'Duplicate Column' : 'Required fields are empty'}
              </Typography>
            )}
          </div>
        ))}
      </div>
    )
  }

  return (
    <>
      {renderNewColumnSecurity()}
      {!props.isEditMode && (
        <Tooltip title="Add Column">
          <span>
            <IconButton onClick={clickAddColumnSecurity} disabled={props.disabled} id="add-column-security">
              <AddBox />
            </IconButton>
          </span>
        </Tooltip>
      )}
    </>
  )
}

export default ColumnSecurityAdd
