import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { getFavoriteDashboards } from '../../../ducks/favorites'
import { displayServiceErrorMessage } from '../../../ducks/layout'
import { updateFavorite } from '../../../ducks/user'

import { getNotificationCount } from '../../../ducks/notification'

import FavoritesDashboards from './FavoritesDashboards'

export class FavoritesDashboardsContainer extends React.Component {
  componentDidMount() {
    const { setHeaderTitle, getFavoriteDashboards } = this.props

    setHeaderTitle('Favorite Dashboards')
    getFavoriteDashboards()
  }

  render() {
    const { favoriteDashboardsStatus, displayServiceErrorMessage, updateFavorite, isMobile } = this.props

    return (
      <FavoritesDashboards
        updateFavorite={updateFavorite}
        favoriteDashboardsStatus={favoriteDashboardsStatus}
        displayServiceErrorMessage={displayServiceErrorMessage}
        isMobile={isMobile}
        getDashboardNotifications={this.getDashboardNotifications}
        getNotificationCountStatus={this.props.getNotificationCountStatus}
      />
    )
  }
}

const mapStateToProps = state => ({
  favoriteDashboardsStatus: state.favorites.favoriteDashboardsStatus,
  getNotificationCountStatus: state.notification.getNotificationCountStatus,
})

export const mapDispatchToProps = dispatch => ({
  updateFavorite(data) {
    dispatch(updateFavorite(data))
  },
  getFavoriteDashboards(data) {
    dispatch(getFavoriteDashboards(data))
  },
  displayServiceErrorMessage(data) {
    dispatch(displayServiceErrorMessage(data))
  },
  getNotificationCount(data) {
    dispatch(getNotificationCount(data))
  },
})

FavoritesDashboardsContainer.defaultProps = {
  favoriteDashboardsStatus: {},
}

FavoritesDashboardsContainer.propTypes = {
  getFavoriteCards: PropTypes.func,
  favoriteDashboardsStatus: PropTypes.object,
  displayServiceErrorMessage: PropTypes.func,
  updateFavorite: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps)(FavoritesDashboardsContainer)
