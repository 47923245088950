import React, { useState } from 'react'
import axios from 'axios'
import { Tooltip, IconButton } from '@mui/material'
import { DeleteOutline, EditOutlined } from '@mui/icons-material'
import { useDispatch } from 'react-redux'
import { createErrMsg } from 'greenfield-utilities'
import DeleteDialog from '../../shared/Dialog/DeleteDialog'
import { getSiteList } from '../../../ducks/site'
import { displayServiceErrorMessage } from '../../../ducks/layout'
import { API_GATEWAY_URL } from '../../../ducks/utils'
import { AddEditSiteDialog } from './AddEditSiteDialog'

export const SiteListActionButtons = ({ data = {} }) => {
  const [isEditSiteOpen, setIsEditSiteOpen] = useState(false)
  const [isDeleteSiteOpen, setIsDeleteSiteOpen] = useState(false)
  const dispatch = useDispatch()

  const handleDeleteSite = () => {
    axios
      .delete(`${API_GATEWAY_URL}/bi_reporting_assets/v2/sites/${data._id}`)
      .then(() => {
        setIsDeleteSiteOpen(false)
        dispatch(
          getSiteList({
            pageIndex: 1,
            pageSize: 1000,
            sortColumn: 'favorite:asc',
          })
        )
      })
      .catch(err => {
        setIsDeleteSiteOpen(false)
        dispatch(displayServiceErrorMessage(createErrMsg(err, 'Your attempt to delete this site has failed.')))
      })
  }

  return (
    <>
      <Tooltip title="Edit Site" placement="top">
        <span>
          <IconButton
            data-cy={`site-list-edit-${data._id}`}
            aria-label={`Edit Site ${data.name}`}
            disabled={data.edit_enabled === 'no'}
            onClick={() => setIsEditSiteOpen(!isEditSiteOpen)}
          >
            <EditOutlined color={data.edit_enabled === 'yes' ? 'success' : 'inherit'} />
          </IconButton>
        </span>
      </Tooltip>
      <Tooltip title="Delete Site" placement="top">
        <span>
          <IconButton
            data-cy={`site-list-edit-${data._id}`}
            aria-label={`Edit Site ${data.name}`}
            disabled={data.edit_enabled === 'no'}
            onClick={() => setIsDeleteSiteOpen(!isDeleteSiteOpen)}
          >
            <DeleteOutline color={data.edit_enabled === 'yes' ? 'error' : 'inherit'} />
          </IconButton>
        </span>
      </Tooltip>
      {isEditSiteOpen && (
        <AddEditSiteDialog isOpen={isEditSiteOpen} editSiteData={data} setIsOpen={setIsEditSiteOpen} />
      )}
      {isDeleteSiteOpen && (
        <DeleteDialog
          open={isDeleteSiteOpen}
          handleClose={() => setIsDeleteSiteOpen(false)}
          handleDelete={handleDeleteSite}
          asset="Site"
          name={data.name}
        />
      )}
    </>
  )
}
