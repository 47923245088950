import React from 'react'
import PropTypes from 'prop-types'
import { Typography, Breadcrumbs, List, ListItemText, ListItem } from '@mui/material'
import { NavigateNext } from '@mui/icons-material'
import classnames from 'classnames'

const DrillthroughBreadcrumbs = ({
  setDrill,
  topLevelId,
  drillthrough,
  activeDrillthroughIndex,
  renderedCardTitles,
  isMobile,
  setDrillRowClicked,
}) => {
  const handleCrumbClick = index => {
    if (index === activeDrillthroughIndex) {
      return
    }

    setDrill({
      activeDrillthroughIndex: index,
      id: index >= 0 ? drillthrough[index].card_id : topLevelId,
      drillthrough,
      isBreadCrumb: true,
    })
  }

  return !isMobile ? (
    <div className="drillthrough-container">
      <span>Drillthrough: </span>
      <Breadcrumbs separator={<NavigateNext />} aria-label="Breadcrumb">
        <Typography
          data-cy="breadcrumb-top"
          variant="h3"
          onClick={() => {
            handleCrumbClick(-1)
          }}
          className="drillthrough-crumb"
        >
          {renderedCardTitles.find(el => el.id === topLevelId).title}
        </Typography>
        {drillthrough.slice(0, activeDrillthroughIndex + 1).map((el, index) => (
          <Typography
            variant="h3"
            key={index}
            className={classnames('drillthrough-crumb', {
              'last-crumb': index === activeDrillthroughIndex,
            })}
            onClick={() => {
              handleCrumbClick(index)
            }}
          >
            {renderedCardTitles.find(title => title.id === el.card_id.toString())?.title}
          </Typography>
        ))}
      </Breadcrumbs>
    </div>
  ) : (
    <List classes={{ root: 'mbl-list' }}>
      <ListItem
        button
        onClick={() => {
          handleCrumbClick(-1)
          setDrillRowClicked('drillClicked')
        }}
      >
        <ListItemText primary={renderedCardTitles.find(el => el.id === topLevelId).title} />
      </ListItem>
      {drillthrough.slice(0, activeDrillthroughIndex + 1).map((el, index) => (
        <ListItem
          button
          key={index}
          selected={index === activeDrillthroughIndex}
          onClick={() => {
            handleCrumbClick(index)
            setDrillRowClicked('drillClicked')
          }}
        >
          <ListItemText primary={renderedCardTitles.find(title => title.id === el.card_id.toString())?.title} />
        </ListItem>
      ))}
    </List>
  )
}

DrillthroughBreadcrumbs.propTypes = {
  setDrill: PropTypes.func,
  activeDrillthroughIndex: PropTypes.number,
}

export default DrillthroughBreadcrumbs
