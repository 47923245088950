import { call, put } from 'redux-saga/effects'
import axios from 'axios'
import { API_GATEWAY_URL } from './utils'

export const SIDE_NAV_JSON_DATA = 'SIDE_NAV_JSON_DATA'
export const SERVICE_ERROR_MESSAGE = 'SERVICE_ERROR_MESSAGE'
export const RENDERCARD_FETCH_REQUESTED = 'RENDERCARD_FETCH_REQUESTED'

export const CLEAR_SERVICE_ERROR_MESSAGE = 'CLEAR_SERVICE_ERROR_MESSAGE'

export const BUILD_GREENFIELD_SERVICES_REQUESTED = 'BUILD_GREENFIELD_SERVICES_REQUESTED'
export const BUILD_GREENFIELD_SERVICES_SUCCEEDED = 'BUILD_GREENFIELD_SERVICES_SUCCEEDED'
export const BUILD_GREENFIELD_SERVICES_FAILED = 'BUILD_GREENFIELD_SERVICES_FAILED'

export default function layoutReducer(state = {}, action = {}) {
  switch (action.type) {
    case SERVICE_ERROR_MESSAGE: {
      const newState = {
        ...state,
        serviceErrorMessage: action.payload,
      }
      return newState
    }
    case CLEAR_SERVICE_ERROR_MESSAGE: {
      const newState = {
        ...state,
        serviceErrorMessage: '',
      }
      return newState
    }
    case BUILD_GREENFIELD_SERVICES_REQUESTED: {
      const newState = {
        ...state,
        buildGreenfieldServicesStatus: {
          status: 'requested',
        },
      }
      return newState
    }
    case BUILD_GREENFIELD_SERVICES_SUCCEEDED: {
      const newStateSuccessful = {
        ...state,
        buildGreenfieldServices: action.payload,
      }
      return newStateSuccessful
    }
    case BUILD_GREENFIELD_SERVICES_FAILED: {
      const newState = {
        ...state,
        buildGreenfieldServicesStatus: {
          status: 'failed',
        },
      }
      return newState
    }

    default:
      return state
  }
}

export function displayServiceErrorMessage(data) {
  return {
    type: SERVICE_ERROR_MESSAGE,
    payload: data,
  }
}

export function clearServiceErrorMessage(data) {
  return {
    type: CLEAR_SERVICE_ERROR_MESSAGE,
    payload: data,
  }
}

export function getBuildGreenfieldServices() {
  return {
    type: BUILD_GREENFIELD_SERVICES_REQUESTED,
  }
}

// Sagas (service requests)

export function* fetchBuildGreenfieldServices() {
  try {
    const buildGreenfieldServices = yield call(axios, {
      method: 'get',
      url: `${API_GATEWAY_URL}/bi_reporting_assets/v2/build_details`,
    })

    if (buildGreenfieldServices.error) {
      const e = buildGreenfieldServices.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }
    yield put({ type: BUILD_GREENFIELD_SERVICES_SUCCEEDED, payload: buildGreenfieldServices })
  } catch (error) {
    yield put({ type: BUILD_GREENFIELD_SERVICES_FAILED, payload: error })
  }
}
