import React, { createRef } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import {
  Badge,
  Tooltip,
  AppBar,
  Icon,
  IconButton,
  List,
  ListItem,
  Popover,
  Toolbar,
  Typography,
  Divider,
  Stack,
  InputAdornment,
  TextField,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  DialogTitle,
} from '@mui/material'
import {
  Notifications as NotificationIcon,
  Search as SearchIcon,
  Menu,
  KeyboardArrowLeft,
  KeyboardArrowDown,
  AdminPanelSettings,
  HelpOutline,
  AddCircle,
  AddCircleOutline,
  NotificationsNone,
} from '@mui/icons-material'
import { Link } from 'react-router-dom'
import classnames from 'classnames'
import isEqual from 'lodash/isEqual'
import styled from 'styled-components'
import { withAnalytics } from '@praxis/component-analytics'

import { updateNotificationAction } from '../../ducks/notification'
import { fetchActAsAdmin, fetchUserType, getUserAccess } from '../../ducks/user'
import { getSite } from '../../ducks/site'
import { DrillThroughClicked } from '../../ducks/Contexts'
import greenfieldLogo from '../../images/greenfield-logo.svg'
import analyticsConfig from '../analytics'
import DatasetBuilder from '../DatasetBuilder/DatasetBuilder'
import Notification from '../routes/Notifications/Notification.container'

import './header.scss'
import { getTimer } from '../shared/util'
import Help from '../routes/Help/Help'

const HeaderPopoverList = styled(ListItem)`
  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
  a {
    text-decoration: none;
    color: #000;
  }
  > span {
    cursor: pointer;
  }
`

const AddPopover = styled(Popover)`
  top: '20px';
`

export class Header extends React.Component {
  intervalID = 0
  ref = createRef()
  state = {
    notificationIsShown: false,
    addPopoverVisibility: false,
    anchorEl: null,
    siteHomePage: '',
    siteName: '',
    datasetBuilderVisible: false,
    searchText: new URLSearchParams(this.props.routeProps.location.search).get('keyword') || '',
    timer: null,
    isOpen: false,
    isDialogShown: false,
  }

  componentDidMount() {
    this.props.fetchUserType()
    this.props.updateNotificationAction()
    this.props.getUserAccess()
    const notificationRefreshInMilliSecs = 600000
    this.intervalID = setInterval(this.props.updateNotificationAction, notificationRefreshInMilliSecs)
    // To do to move to util and check for it always
    this.setSiteHomePageDetails()
  }

  setSiteHomePageDetails = () => {
    const isSite = /^\/site\//i.test(window.location.pathname)
    let siteHomePage = ''
    let siteName = ''
    if (isSite) {
      const splitSiteURl = window.location.pathname?.split('/')
      if (splitSiteURl.length >= 3) {
        if (Object.keys(this.props.siteObj).length === 0) {
          this.props.getSite(splitSiteURl[2])
        }
        siteHomePage = `/${splitSiteURl[1]}/${splitSiteURl[2]}/home`
        siteName = splitSiteURl[2]
      }
    }

    this.setState({
      siteHomePage,
      siteName,
    })
  }

  componentDidUpdate(prevProps) {
    const { notificationStatus } = this.props
    const prevNotificationStatus = prevProps.notificationStatus
    const unreadCount = notificationStatus.data ? notificationStatus.data.filter(row => !row.read).length : 0
    const prevUnreadCount = prevNotificationStatus?.data
      ? prevNotificationStatus.data.filter(row => !row.read).length
      : 0

    if (!isEqual(this.props.title, prevProps.title)) {
      this.setSiteHomePageDetails()
    }

    if (!isEqual(this.props.currentUser, prevProps.currentUser) && this.props.currentUser?.status === 200) {
      // admin timer setup
      const adminEndTime = new Date(this.props.currentUser.data?.system_administrator?.expiration_date)?.getTime()
      if (adminEndTime) {
        const interval = setInterval(() => {
          const delta = parseInt(adminEndTime, 10) - new Date()
          if (delta < 0) {
            this.setState({ timer: null, isOpen: false })
            clearInterval(interval)
          } else {
            if (delta <= 300000 && delta >= 0) {
              // remind before 5min timeout
              if (!this.state.isDialogShown) {
                this.setState({ isOpen: true, isDialogShown: true })
              }
            }
            this.setState({ timer: getTimer(adminEndTime) })
          }
        }, 1000)
      }
    }
    if (
      notificationStatus.data?.length > prevNotificationStatus?.data?.length ||
      (prevNotificationStatus?.status === 'requested' &&
        notificationStatus.data?.length &&
        !notificationStatus.data.some(note => note.read))
    ) {
      document.getElementById('small-favicon').setAttribute('href', 'favicon-16x16-notification.png')
      document.getElementById('large-favicon').setAttribute('href', 'favicon-32x32-notification.png')
    }

    if (!unreadCount && prevUnreadCount) {
      document.getElementById('small-favicon').setAttribute('href', 'favicon-16x16.png')
      document.getElementById('large-favicon').setAttribute('href', 'favicon-32x32.png')
    }
  }

  componentWillUnmount() {
    clearInterval(this.intervalID)
  }

  handleAddClick = event => {
    const { currentTarget } = event
    this.setState(() => ({ anchorEl: currentTarget }))
  }

  handleClose = () => {
    this.setState(() => ({ anchorEl: null }))
  }

  handleHelpDrawerToggle = () => {
    if (!this.state.openDrawer) {
      this.props.sendFireflyEvent(false, {
        eventName: 'helpDrawerOpened',
        key: 'notification',
      })
    }
    this.setState({ openDrawer: !this.state.openDrawer })
  }

  toggleVisibility() {
    this.setState(state => ({ notificationIsShown: !state.notificationIsShown }))
  }

  render() {
    const {
      trackEvent,
      isMobile,
      notificationStatus,
      title,
      isCard,
      isDashboard,
      currentUser,
      sendFireflyEvent,
      routeProps,
    } = this.props
    const { datasetBuilderVisible, searchText, timer, isOpen } = this.state
    const isInternalUser = currentUser?.data?.user_type === 'internal'
    const getUnreadCount =
      notificationStatus && notificationStatus.data ? notificationStatus.data.filter(row => !row.read).length : 0

    const handleClose = () => {
      this.toggleVisibility()
    }

    const locPathName = window.location.pathname
    const isBuilder = /builder/i.test(locPathName)

    // To do to move to util and check for it always, remove below line once genric is ready
    const isSite = /site/i.test(locPathName)
    const { anchorEl, siteHomePage, siteName, openDrawer } = this.state
    const open = Boolean(anchorEl)
    const id = open ? 'simple-popover' : ''
    const showDatasetBuilder = key => {
      if (!key || key === 'Enter') {
        this.setState({
          datasetBuilderVisible: true,
          anchorEl: null,
        })
      }
    }

    const closeDatasetBuilder = () => {
      this.setState({
        datasetBuilderVisible: false,
      })
    }

    const handleSearchChange = event => {
      this.setState({ searchText: event.target.value })
    }

    const handleSearchClick = () => {
      sendFireflyEvent(true, {
        eventName: 'globalSearchClicked',
        key: this.state.searchText,
        data: {
          searchText: this.state.searchText,
        },
      })
      routeProps?.history.push(`/search?keyword=${searchText}`)
    }

    const handleOnSearchKeyDown = e => {
      if (e.key === 'Enter') {
        sendFireflyEvent(true, {
          eventName: 'globalSearchClicked',
          key: this.state.searchText,
          data: {
            searchText: this.state.searchText,
          },
        })
        routeProps?.history.push(`/search?keyword=${searchText}`)
      }
    }

    const handleDialogClose = () => {
      this.setState({ isOpen: false })
    }

    const handleAdminOptIn = () => {
      this.props.actAsAdmin({ ...currentUser, adminMode: true })
    }

    return (
      <AppBar
        position="static"
        classes={{
          root: classnames('gf-header', 'greenFieldNoPrint', { 'builder-header': !siteHomePage && isBuilder }),
        }}
      >
        {datasetBuilderVisible && <DatasetBuilder trackEvent={trackEvent} closeDatasetBuilder={closeDatasetBuilder} />}
        <Toolbar>
          {isMobile && this.props.isCard ? (
            <DrillThroughClicked.Consumer>
              {({ isDrillThroughButton, setDrillRowClicked }) => {
                return isDrillThroughButton ? (
                  <IconButton
                    onClick={() => {
                      setDrillRowClicked('backButtonClicked')
                      sendFireflyEvent(false, {
                        eventName: 'mobileCardBackBtn',
                      })
                    }}
                  >
                    <KeyboardArrowLeft />
                  </IconButton>
                ) : (
                  <Link
                    onClick={() => sendFireflyEvent(false, { eventName: 'mobileCardBackBtn' })}
                    to="/cards"
                    className="headerBackBtn"
                  >
                    <KeyboardArrowLeft />
                  </Link>
                )
              }}
            </DrillThroughClicked.Consumer>
          ) : (
            <>
              <Tooltip title="Greenfield Home Page">
                <Link role="button" to="/favorites/home">
                  <img
                    src={greenfieldLogo}
                    alt="Greenfield Home Page"
                    className="greenfield-logo"
                    width="35"
                    data-cy="greenfield-logo"
                  />
                </Link>
              </Tooltip>
              <Divider className="divider-logo" variant="middle" orientation="vertical" flexItem />
            </>
          )}

          <Typography
            variant="h1"
            className="flex-1 greenfieldPageTitleHeader"
            tabIndex="0"
            style={{
              fontSize: isMobile ? '16px' : 'inherit',
            }}
          >
            {isSite && siteName ? (
              <div data-cy="site-header-title" className={isMobile ? 'cardMobileTitle' : 'cardDesktopTitle'}>
                <Link role="button" to={siteHomePage} className="site-link">
                  {!isMobile ? (isCard ? 'Card View' : isDashboard ? 'Dashboard View' : title) : title}
                </Link>
              </div>
            ) : (
              <div
                data-cy="greenfield-header-title"
                aria-label={!isMobile ? (isCard ? 'Card View' : isDashboard ? 'Dashboard View' : title) : title}
                className={isMobile ? 'cardMobileTitle' : 'cardDesktopTitle'}
              >
                {!isMobile ? (isCard ? 'Card View' : isDashboard ? 'Dashboard View' : title) : title}
              </div>
            )}
          </Typography>
          {currentUser?.data?.is_admin === 'yes' &&
            timer &&
            (isMobile ? (
              <AdminPanelSettings sx={{ color: '#cc0000' }} />
            ) : (
              <Stack
                direction="row"
                alignItems="center"
                gap={1}
                justifyContent="flex-start"
                style={{ background: '#cc0000', borderRadius: '5px', padding: '2px', color: 'white' }}
              >
                <AdminPanelSettings sx={{ ml: 1 }} />
                <Typography tabIndex="0" variant="button" sx={{ mr: 1 }}>
                  {timer}
                </Typography>
              </Stack>
            ))}
          {isMobile && isCard && (
            <DrillThroughClicked.Consumer>
              {({ isDrillThroughButton, setDrillRowClicked }) => (
                <>
                  {isDrillThroughButton && (
                    <IconButton onClick={() => setDrillRowClicked('drillClicked')}>
                      <KeyboardArrowDown />
                    </IconButton>
                  )}
                </>
              )}
            </DrillThroughClicked.Consumer>
          )}

          {!isMobile && (
            <>
              {this.props.userAccess?.data?.GLOBAL_SEARCH_MODE && (
                <TextField
                  placeholder="Search..."
                  onChange={handleSearchChange}
                  onKeyDown={handleOnSearchKeyDown}
                  value={searchText}
                  type="text"
                  size="small"
                  style={{ margin: '5px', width: 400 }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment data-cy="search-icon" position="end">
                        <Tooltip placement="top" title="Search">
                          <IconButton onClick={handleSearchClick}>
                            <SearchIcon />
                          </IconButton>
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
              <Tooltip title="Build a card, dashboard, or upload a dataset">
                {isMobile ? (
                  <IconButton onClick={this.handleAddClick} id="toggleBuilderAddVisibilityBtn">
                    <AddCircle />
                  </IconButton>
                ) : (
                  <Button
                    variant="standard"
                    startIcon={<AddCircleOutline />}
                    onClick={this.handleAddClick}
                    id="toggleBuilderAddVisibilityBtn"
                  >
                    Create
                  </Button>
                )}
              </Tooltip>
            </>
          )}

          <AddPopover
            open={open}
            onClose={this.handleClose}
            id={id}
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
          >
            <List data-cy="header-add-list">
              <HeaderPopoverList data-cy="header-add-list-card">
                <a
                  id="linkToBuilderCard"
                  href={isSite && siteName ? `/site/${siteName}/builder/card` : '/builder/card'}
                >
                  Create Card
                </a>
              </HeaderPopoverList>
              <HeaderPopoverList data-cy="header-add-list-textcard">
                <a
                  id="linkToBuilderTextCard"
                  href={isSite && siteName ? `/site/${siteName}/builder/textcard` : '/builder/textcard'}
                >
                  Create Text Card
                </a>
              </HeaderPopoverList>
              <HeaderPopoverList data-cy="header-add-list-dashboard">
                <a
                  id="linkToBuilderDashboard"
                  href={isSite && siteName ? `/site/${siteName}/builder/dashboard` : '/builder/dashboard'}
                >
                  Create Dashboard
                </a>
              </HeaderPopoverList>
              {isInternalUser && (
                <HeaderPopoverList data-cy="header-add-list-dataset">
                  <span
                    tabIndex={-1}
                    onKeyDown={e => {
                      showDatasetBuilder(e.key)
                    }}
                    role="button"
                    onClick={() => {
                      trackEvent({
                        event: {
                          type: 'header-datasetbuilder-button-clicked',
                        },
                      })
                      showDatasetBuilder()
                    }}
                  >
                    Create Dataset
                  </span>
                </HeaderPopoverList>
              )}
            </List>
          </AddPopover>
          <Tooltip
            title="Greenfield Helpcenter"
            onClick={() => {
              sendFireflyEvent(false, {
                eventName: 'helpCenterClick',
              })
            }}
          >
            {isMobile ? (
              <IconButton onClick={this.handleHelpDrawerToggle}>
                <Icon>live_help</Icon>
              </IconButton>
            ) : (
              <Button
                data-cy="help-button"
                variant="standard"
                startIcon={<HelpOutline />}
                onClick={this.handleHelpDrawerToggle}
              >
                Help
              </Button>
            )}
          </Tooltip>
          <Tooltip title="Notifications">
            {isMobile ? (
              <IconButton
                onClick={() => {
                  this.toggleVisibility()
                  sendFireflyEvent(true, {
                    eventName: 'notificationBellClick',
                    key: 'notification',
                  })
                }}
              >
                {getUnreadCount ? (
                  <Badge
                    badgeContent={getUnreadCount}
                    max={99}
                    color="error"
                    aria-label={`notification count is ${getUnreadCount}`}
                  >
                    <NotificationIcon />
                  </Badge>
                ) : (
                  <NotificationIcon />
                )}
              </IconButton>
            ) : (
              <Button
                variant="standard"
                onClick={() => {
                  this.toggleVisibility()
                  sendFireflyEvent(true, {
                    eventName: 'notificationBellClick',
                    key: 'notification',
                  })
                }}
                startIcon={
                  getUnreadCount ? (
                    <Badge
                      badgeContent={getUnreadCount}
                      max={99}
                      color="error"
                      aria-label={`notification count is ${getUnreadCount}`}
                    >
                      <NotificationsNone />
                    </Badge>
                  ) : (
                    <NotificationsNone />
                  )
                }
              >
                Notifications
              </Button>
            )}
          </Tooltip>
          <Help {...this.props} onChangeHelpDrawerToggle={this.handleHelpDrawerToggle} openHelpDrawer={openDrawer} />
          <Notification
            isMobile={isMobile}
            notificationIsShown={this.state.notificationIsShown}
            toggleVisibility={handleClose}
            data={this.props.notificationStatus}
            currentUser={this.props.currentUser}
            sendFireflyEvent={this.props.sendFireflyEvent}
          />
          <Dialog open={isOpen} onClose={handleDialogClose}>
            <DialogTitle id="alert-dialog-title">Your Admin mode will expire in under 5 minutes</DialogTitle>
            <DialogContent>
              <Typography>Please save your changes</Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleAdminOptIn} color="secondary">
                Extend Admin Mode
              </Button>
              <Button onClick={handleDialogClose}>Stay on this page</Button>
            </DialogActions>
          </Dialog>
          <IconButton onClick={this.props.onMenuAction} aria-label="Menu" id="toggleMainMenuBtn">
            <Menu />
          </IconButton>
        </Toolbar>
      </AppBar>
    )
  }
}

Header.defaultProps = {
  isCard: false,
  siteObj: {},
}

Header.propTypes = {
  title: PropTypes.string.isRequired,
  onMenuAction: PropTypes.func,
  trackEvent: PropTypes.func,
}

export const mapDispatchToProps = dispatch => ({
  updateNotificationAction(data) {
    dispatch(updateNotificationAction(data))
  },
  fetchUserType(data) {
    dispatch(fetchUserType(data))
  },
  getSite(data) {
    dispatch(getSite(data))
  },
  getUserAccess() {
    dispatch(getUserAccess())
  },
  actAsAdmin(data) {
    dispatch(fetchActAsAdmin(data))
  },
})

const mapStateToProps = state => ({
  notificationStatus: state.notification.notificationStatus,
  currentUser: state.user.userType,
  siteObj: state.site.siteStatus,
  userAccess: state.user.userAccessStatus,
})

export default withAnalytics(analyticsConfig)(connect(mapStateToProps, mapDispatchToProps)(Header))
