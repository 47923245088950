import React from 'react'
import PropTypes from 'prop-types'
import 'react-dates/initialize'
import { Button, IconButton, TextField, Grid, FormControlLabel, Checkbox } from '@mui/material'
import { AccessTime } from '@mui/icons-material'

import './calendar.scss'
import { getFormatDate } from '../util'
import DatePickerComponent from './DatePickerComponent'

class Calendar extends React.Component {
  state = {
    focused: true,
    date: getFormatDate(),
    visibleCalendar: false,
    visibleTime: false,
    hour: '12',
    minutes: '00',
    time: 'AM',
    value: '',
    currentDateEnable: this.props.isEnableToday || false,
  }

  setCalendarButton = null
  setCalendar = null
  setTimeButton = null
  setTime = null

  convertDate(date) {
    let value = date.substr(0, 10)
    let dateValue = parseInt(date.substr(11, 2))
    dateValue = date.substr(17, 2) === 'PM' ? dateValue + 12 : dateValue
    switch (dateValue) {
      case 24:
        value = `${value}T12:${date.substr(14, 2)}`
        break
      case 12:
        break
      default:
        value = `${value}T${dateValue.toString().padStart(2, '0')}:${date.substr(14, 2)}`
        break
    }
    return value
  }

  componentDidMount() {
    const date = this.props.defaultValue
    if (date !== null) {
      const value = date.substr(0, 10)
      let remaining = ','
      let hour, time, minutes
      if (date.length > 10) {
        const val = parseInt(date.substr(11, 2))
        hour = val > 12 ? val - 12 : val
        time = val < 12 ? 'AM' : 'PM'
        hour = hour.toString().padStart(2, '0')
        hour = hour === '00' ? '12' : hour
        minutes = date.substr(14, 2)
        remaining = `${remaining}${hour}:${minutes} ${time}`
      } else {
        remaining = `${remaining}12:00 AM`
        hour = '12'
        minutes = '00'
        time = 'AM'
      }
      this.setState({
        date: value,
        value: `${value}${remaining}`,
        time,
        hour,
        minutes,
      })
    }
  }

  handleMouseDownPassword = event => {
    event.preventDefault()
  }

  dateSubstr(dates) {
    if (dates) {
      return dates.substr(0, 10)
    }
    return null
  }

  dateChange = dateInput => {
    if (dateInput) {
      const value = `${this.dateSubstr(dateInput.format())},${this.state.hour}:${this.state.minutes} ${this.state.time}`

      this.setState(
        {
          date: this.dateSubstr(dateInput.format()),
          value,
          currentDateEnable: false,
        },
        () => {
          this.props.onChange(this.convertDate(value))
        }
      )
    }
  }

  handleClickShowTime = () => {
    const visibleTime = !this.state.visibleTime
    this.setState(
      {
        visibleTime,
        visibleCalendar: false,
      },
      () => {
        visibleTime ? this.setTime?.focus() : this.setTimeButton?.focus()
      }
    )
  }

  changeTime = (event, stateName) => {
    const value = event.target.value
    const timeValue = parseInt(value, 10)
    const stringValue = timeValue.toString().padStart(2, '0')
    let stateValue = stateName === 'hour' ? `${stringValue}:${this.state.minutes}` : `${this.state.hour}:${stringValue}`
    stateValue = `${this.state.date},${stateValue} ${this.state.time}`
    this.setState(
      {
        [stateName]: stringValue,
        value: stateValue,
      },
      () => {
        this.props.onChange(this.convertDate(stateValue))
      }
    )
  }

  textBox = (event, stateName) => {
    const val =
      stateName === 'minutes' || (event.target.value <= 12 && event.target.value >= 1)
        ? event.target.value
        : this.state.hour
    let value = stateName === 'hour' ? `${val}:${this.state.minutes}` : `${this.state.hour}:${val}`
    value = `${this.state.date},${value} ${this.state.time}`
    this.setState(
      {
        [stateName]: val,
        value,
      },
      () => {
        this.props.onChange(this.convertDate(value))
      }
    )
  }

  handleChangeAmPm = () => {
    const val = this.state.time === 'AM' ? 'PM' : 'AM'
    const value = `${this.state.date},${this.state.hour}:${this.state.minutes} ${val}`
    this.setState(
      {
        time: val,
        value,
      },
      () => {
        this.props.onChange(this.convertDate(value))
      }
    )
  }

  setValue = (varName, ip) => {
    this[varName] = ip
  }

  checkDeviceTypeBool = () => {
    const { isMobile, deviceType, calendarOpen } = this.props
    const isDesktop = deviceType.includes('isDesktop')
    return isDesktop || (isMobile && !calendarOpen)
  }

  render() {
    const {
      toggleCalendarState,
      label,
      minDate,
      maxDate,
      startDate,
      endDate,
      isDashboard,
      id,
      setToday,
      isEnableToday,
    } = this.props
    const { visibleCalendar, date, visibleTime, hour, minutes, time, currentDateEnable, value } = this.state

    return (
      <div className="root-calendar">
        <Grid container alignItems="flex-end">
          <Grid item style={{ display: 'flex' }}>
            {this.checkDeviceTypeBool() && !isEnableToday && (
              <>
                <DatePickerComponent
                  id={id}
                  label={label}
                  ariaLabel={label}
                  value={date}
                  minDate={label === 'Start Date' ? (!isDashboard ? minDate : getFormatDate('1900-01-01')) : startDate}
                  maxDate={
                    label === 'Start Date'
                      ? isDashboard
                        ? getFormatDate('2100-12-31')
                        : endDate
                      : !isDashboard
                      ? maxDate
                      : getFormatDate('2100-12-31')
                  }
                  handleOnChange={date => {
                    this.dateChange(date)
                    toggleCalendarState(false)
                  }}
                />
                <IconButton
                  className="button-top"
                  aria-label="Open Time Select"
                  onClick={() => {
                    toggleCalendarState(!visibleCalendar)
                    this.handleClickShowTime()
                  }}
                  onMouseDown={this.handleMouseDownPassword}
                  id="Time"
                >
                  <AccessTime />
                </IconButton>
              </>
            )}
          </Grid>
        </Grid>

        {visibleTime && !isEnableToday && (
          <div className="dayPickerWrapper">
            <span tabIndex="-1" ref={ip => this.setValue('setTime', ip)} />
            <div className="timeWrapper">
              <div className="flex-column">
                <TextField
                  value={hour}
                  type="number"
                  aria-label="Hour"
                  inputProps={{
                    max: 12,
                    min: 1,
                    maxLength: 2,
                  }}
                  id="hour"
                  label="Hour"
                  margin="normal"
                  className="textField-css test-textbox-hour"
                  onChange={event => this.changeTime(event, 'hour')}
                  variant="standard"
                />
              </div>
              <div className="flex-column">
                <TextField
                  value={minutes}
                  type="number"
                  aria-label="Minute"
                  inputProps={{
                    max: 59,
                    min: 0,
                    maxLength: 2,
                  }}
                  id="minute"
                  label="Minute"
                  margin="normal"
                  className="textField-css test-textbox-hour"
                  onChange={event => this.changeTime(event, 'minutes')}
                  variant="standard"
                />
              </div>
              <Button id="am-pm" onClick={this.handleChangeAmPm}>
                {time}
              </Button>
            </div>
            <Button
              variant="text"
              color="secondary"
              onClick={() => {
                toggleCalendarState(false)
                setTimeout(() => {
                  // Timeout is to get the toggleCalendarState method to resolve on the event loop first -Art/49754518
                  // Otherwise, the element to focus in the handleClickShowTime will not be visible
                  this.handleClickShowTime()
                }, 0)
              }}
            >
              Close
            </Button>
          </div>
        )}
        {this.checkDeviceTypeBool() && setToday && (
          <div className="align-selection">
            <FormControlLabel
              control={
                <Checkbox
                  id={`${id}-set-today`}
                  value={setToday}
                  aria-label={setToday}
                  checked={currentDateEnable}
                  onChange={() => {
                    const enableCurrentDate = !currentDateEnable
                    this.setState(
                      {
                        currentDateEnable: enableCurrentDate,
                      },
                      () => {
                        this.props.onChange(this.convertDate(value), enableCurrentDate)
                      }
                    )
                  }}
                />
              }
              label={setToday}
            />
          </div>
        )}
      </div>
    )
  }
}

Calendar.defaultProps = {
  defaultValue: null,
  endDate: null,
  label: 'Date',
  onChange: () => {},
  startDate: null,
  minDate: null,
  maxDate: null,
  deviceType: [],
  setToday: '',
  isEnableToday: false,
}

Calendar.propTypes = {
  defaultValue: PropTypes.string,
  endDate: PropTypes.any,
  label: PropTypes.string,
  onChange: PropTypes.func,
  startDate: PropTypes.any,
  minDate: PropTypes.string,
  maxDate: PropTypes.string,
  setToday: PropTypes.string,
  isEnableToday: PropTypes.bool,
}

export default Calendar
