import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Button, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { Done, Close, Pending } from '@mui/icons-material'
import { Spinner } from 'greenfield-utilities'

const NON_PROD_ENDPOINTS = [
  'https://stage-api.target.com/bi_reporting_assets/v3/cards?per_page=1',
  'https://stage-api.target.com/bi_reporting_assets/v2/datasets?page_1&per_page=1&field_groups=name_only',
  'https://stage-api.target.com/greenfield_subscriptions/v1/ping',
  'https://stage-api.target.com/greenfield_notifications/v1/ping',
]

const PROD_ENDPOINTS = [
  'https://api.target.com/bi_reporting_assets/v3/cards?per_page=1',
  'https://api.target.com/bi_reporting_assets/v2/datasets?page_1&per_page=1&field_groups=name_only',
  'https://api.target.com/greenfield_subscriptions/v1/ping',
  'https://api.target.com/greenfield_notifications/v1/ping',
]

const servicesApiUrl = () => {
  if (
    process.env.NODE_ENV === 'production' &&
    (window.location.host === 'greenfield.target.com' || window.location.host === 'greenfield.partnersonline.com')
  ) {
    return 'https://api.target.com'
  }

  return 'https://stage-api.target.com'
}

const IconStatus = ({ buttonHasBeenClicked, element, incrementCompletedRequests, url }) => {
  const [networkStatus, updateNetworkStatus] = useState('pending')

  useEffect(() => {
    if (buttonHasBeenClicked) {
      updateNetworkStatus('requested')
      axios
        .get(element)
        .then(() => {
          updateNetworkStatus('success')
        })
        .catch(() => {
          updateNetworkStatus('failed')
        })
        .finally(() => {
          incrementCompletedRequests(url)
        })
    }
  }, [buttonHasBeenClicked])

  const renderStatusIcon = () => {
    if (networkStatus === 'pending') {
      return <Pending sx={{ color: 'orange' }} fontSize="large" />
    }
    if (networkStatus === 'requested') {
      return <Spinner size="small" style={{ marginLeft: '0px' }} />
    }
    if (networkStatus === 'success') {
      return <Done sx={{ color: 'green' }} fontSize="large" />
    }
    return <Close sx={{ color: 'red' }} fontSize="large" />
  }

  return (
    <TableRow>
      <TableCell component="th" scope="row">
        {element}
      </TableCell>
      <TableCell>{renderStatusIcon()}</TableCell>
    </TableRow>
  )
}

const Apihealthcheck = () => {
  const [countOfCompletedRequests, updateCountOfCompletedRequests] = useState(0)
  const [buttonHasBeenClicked, updateButtonHasBeenClickedStatus] = useState(false)

  const checkAllEndpoints = () => {
    updateButtonHasBeenClickedStatus(true)
  }

  const incrementCompletedRequests = url => {
    if (url === 'https://stage-api.target.com') {
      updateCountOfCompletedRequests(prevValue => {
        if (prevValue === NON_PROD_ENDPOINTS.length - 1) {
          updateButtonHasBeenClickedStatus(false)
          return 0
        }
        return prevValue + 1
      })
    }
    updateCountOfCompletedRequests(prevValue => {
      if (prevValue === PROD_ENDPOINTS.length - 1) {
        updateButtonHasBeenClickedStatus(false)
        return 0
      }
      return prevValue + 1
    })
  }

  return (
    <Grid container spacing={0}>
      <Grid textAlign="center" paddingTop="20px" item xs={12}>
        <Button size="large" onClick={checkAllEndpoints} disabled={countOfCompletedRequests !== 0}>
          Check Api Endpoints
        </Button>
      </Grid>
      <Grid item style={{ width: '100%', padding: '20px' }}>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>URL</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {servicesApiUrl() === 'https://stage-api.target.com'
                ? NON_PROD_ENDPOINTS.map((element, index) => (
                    <IconStatus
                      buttonHasBeenClicked={buttonHasBeenClicked}
                      incrementCompletedRequests={incrementCompletedRequests}
                      element={element}
                      url={servicesApiUrl()}
                      key={index}
                    />
                  ))
                : PROD_ENDPOINTS.map((element, index) => (
                    <IconStatus
                      buttonHasBeenClicked={buttonHasBeenClicked}
                      incrementCompletedRequests={incrementCompletedRequests}
                      element={element}
                      url={servicesApiUrl()}
                      key={index}
                    />
                  ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  )
}

export default Apihealthcheck
