import { call, put } from 'redux-saga/effects'
import axios from 'axios'
import { API_GATEWAY_URL } from './utils'

export const TRAINING_CONTENT_REQUESTED = 'TRAINING_CONTENT_REQUESTED'
export const TRAINING_CONTENT_SUCCEEDED = 'TRAINING_CONTENT_SUCCEEDED'
export const TRAINING_CONTENT_FAILED = 'TRAINING_CONTENT_FAILED'

export default function trainingContentReducer(state = {}, action = {}) {
  switch (action.type) {
    case TRAINING_CONTENT_REQUESTED: {
      const newState = {
        ...state,
        trainingData: {
          status: 'requested',
        },
      }
      return newState
    }
    case TRAINING_CONTENT_SUCCEEDED: {
      const newState = {
        ...state,
        trainingData: action.payload,
      }
      return newState
    }
    case TRAINING_CONTENT_FAILED: {
      const newState = {
        ...state,
        trainingData: {
          status: 'failed',
          message: action.payload.response,
        },
      }
      return newState
    }
    default:
      return state
  }
}

export function getTrainingContent(data) {
  return {
    type: TRAINING_CONTENT_REQUESTED,
    payload: data,
  }
}

export function* fetchTrainingContent(action) {
  try {
    const trainingData = yield call(axios, {
      method: 'get',
      url: `${API_GATEWAY_URL}/bi_reporting_assets/v2/static_assets/greenfield_training_content/${action.payload}`,
    })

    if (trainingData.error) {
      const e = trainingData.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }

    yield put({ type: 'TRAINING_CONTENT_SUCCEEDED', payload: trainingData })
  } catch (error) {
    yield put({ type: 'TRAINING_CONTENT_FAILED', payload: error })
  }
}
