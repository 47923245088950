import React from 'react'
import PropTypes from 'prop-types'
import { Button, Popover, Tabs, Tab, List, ListItem, Tooltip } from '@mui/material'
import { DateRange, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import isEqual from 'lodash/isEqual'
import isEmpty from 'lodash/isEmpty'
import cloneDeep from 'lodash/cloneDeep'
import moment from 'moment'
import { Spinner, CUSTOM_STR, createErrMsg } from 'greenfield-utilities'

import { AnchorIcon } from '../Svgicon/Svgicon'
import Calendar from '../Calendar/Calendar'
import './TimePeriod.scss'
import { getFormatDate, getTimePeriodButtonLabel } from '../util'
import {
  OTHERS_TIME_PERIOD,
  NEXT_TIME_PERIOD,
  PERIOD_TIME_PERIOD,
  PREVIOUS_TIME_PERIOD,
  TIME_PERIOD_TAB_OPTIONS,
  TODAY_TIME_PERIOD,
  CUSTOM_DATES_OPTIONS,
  DEFAULT_PERIOD_PLURAL_OPTIONS,
  TIME_PERIOD_TAB_OPTIONS_WITH_COMPARE_BY,
} from '../../constants/ArrayConstants'
import { LWR_CASE_TODAY, MULTI_SELECT_CUSTOM_STR } from '../../constants/StringConstants'
import RelativeTimePeriod from './RelativeTimePeriod'

import OtherTimePeriodOptions from './OtherTimePeriodOptions'

import CustomTimePeriod from './CustomTimePeriod'
import { getSecondaryTimePeriodColumn, isDisableBetweenApply } from './TimePeriodUtils'

class SecondaryTimePeriod extends React.Component {
  getButtonText = () => {
    const { isDashboard, cardInfo, isCompareBy, compareByValue } = this.props
    const secondaryTimePeriods = cardInfo?.data?.card_config?.card_query_attribute?.secondary_time_periods
    const secondaryTimePeriod =
      (secondaryTimePeriods && secondaryTimePeriods[this.props?.secondaryTimePeriodColumn?.field_name]) || {} // eslint-disable-line
    const isTimeIntervalArray =
      Array.isArray(secondaryTimePeriod.intervals) && secondaryTimePeriod.type === CUSTOM_STR.toLowerCase()
    return !isDashboard && cardInfo?.data?.card_config && secondaryTimePeriod.type !== 'absolute'
      ? isCompareBy
        ? compareByValue
        : isTimeIntervalArray
        ? secondaryTimePeriod.intervals
        : secondaryTimePeriod.interval
      : ''
  }

  state = {
    startDate: null,
    endDate: null,
    value: 0,
    anchorEl: null,
    buttonText: this.getButtonText(),
    selectedStartDate: this.props.isDashboard ? getFormatDate() : null,
    selectedEndDate: this.props.isDashboard ? getFormatDate() : null,
    mobileTimePeriodContainerOpen: false,
    calendarOpen: false,
    hasSetDrillthroughTimePeriod: false,
    setBeginDateToday: false,
    setEndDateToday: false,
    secondaryTimePeriodColumn: {},
  }

  onHandleNone = () => {
    const { modifyDashboardInfo, isViewer, onTimeJsonChange } = this.props
    if (isViewer) {
      onTimeJsonChange({}, true)
    } else {
      modifyDashboardInfo('time_period', {})
    }

    this.setState({
      startDate: null,
      endDate: null,
      anchorEl: null,
      buttonText: '',
    })
  }

  componentDidMount() {
    let startState, endState, buttonText, setBeginDateToday, setEndDateToday
    let { selectedStartDate, selectedEndDate } = this.state
    const {
      isDashboard,
      dateFilterStatus,
      cardInfo,
      fiscalYear,
      dashboardInfo,
      viewerJson,
      isViewer,
      isCompareBy,
      compareByValue,
      anchorDateFilterStatus,
      selectedTimePeriod,
      secondaryTime,
    } = this.props

    if (!isDashboard && cardInfo?.data?.card_config) {
      const selectedSecondaryTimePeriods =
        selectedTimePeriod?.secondaryTimePeriods || secondaryTime?.secondaryTimePeriods
      /* eslint-disable camelcase */
      const secondaryTimePeriods = isViewer
        ? selectedSecondaryTimePeriods ||
          cardInfo?.data?.context?.rendered_payload?.secondary_time_periods ||
          cardInfo?.data?.card_config?.card_query_attribute?.secondary_time_periods
        : cardInfo?.data?.card_config?.card_query_attribute?.secondary_time_periods
      const secondaryTimePeriodColumn = getSecondaryTimePeriodColumn(this.props?.selectedDataset)
      this.setState({
        secondaryTimePeriodColumn,
      })
      const secondaryTimePeriod = secondaryTimePeriods && secondaryTimePeriods[secondaryTimePeriodColumn?.field_name]
      const dateFilterObj = secondaryTimePeriod?.anchor_date && !isCompareBy ? anchorDateFilterStatus : dateFilterStatus
      /* eslint-enable camelcase */

      const isTimeIntervalArray =
        Array.isArray(secondaryTimePeriod?.intervals) && secondaryTimePeriod?.type === CUSTOM_STR.toLowerCase()
      if (dateFilterObj && dateFilterObj.status !== 'failed' && Object.keys(dateFilterObj).length > 1) {
        const { Fiscal, Gregorian } =
          secondaryTimePeriod?.anchor_date && !isCompareBy
            ? this.dateFilterChange(anchorDateFilterStatus)
            : this.dateFilterChange(dateFilterStatus)
        const calendarType = fiscalYear ? Fiscal : Gregorian
        const periodInterval = isCompareBy
          ? compareByValue
          : isTimeIntervalArray
          ? secondaryTimePeriod?.intervals
          : secondaryTimePeriod?.interval

        if (
          (secondaryTimePeriod?.set_begin_date_today || secondaryTimePeriod?.set_end_date_today) &&
          periodInterval?.includes(',')
        ) {
          const splitInterval = periodInterval?.split(',')
          const start = splitInterval[0]?.trim()
          const end = splitInterval[1]?.trim()

          const startDateStr = secondaryTimePeriod.set_begin_date_today
            ? start?.slice(1)
            : start?.slice(2, start?.length - 1)
          const endDateStr = secondaryTimePeriod.set_end_date_today
            ? end?.slice(0, end?.length - 1)
            : end?.slice(1, end?.length - 2)
          startState = secondaryTimePeriod.set_begin_date_today ? 'Today' : startDateStr
          endState = secondaryTimePeriod.set_end_date_today ? 'Today' : endDateStr
          buttonText = 'Between'
          setBeginDateToday = secondaryTimePeriod.set_begin_date_today || false
          setEndDateToday = secondaryTimePeriod.set_end_date_today || false
        } else if (periodInterval && periodInterval.includes('/')) {
          startState = periodInterval.split('/')[0]
          endState = periodInterval.split('/')[1]
          selectedStartDate = periodInterval.split('/')[0]
          selectedEndDate = periodInterval.split('/')[1]
          buttonText = 'Between'
        } else {
          if (calendarType[periodInterval]) {
            buttonText = periodInterval
            startState = calendarType[buttonText]['Begin Date']
            endState = calendarType[buttonText]['End Date']
          } else if (periodInterval !== 'last segment') {
            startState = null
            endState = null
            buttonText = periodInterval
          } else {
            if (cardInfo.data && cardInfo.data.rendered_interval) {
              startState = cardInfo.data.rendered_interval.begin
              endState = cardInfo.data.rendered_interval.end
            }
            buttonText = periodInterval
          }
        }
        this.setState({
          startDate: startState,
          endDate: endState,
          selectedStartDate,
          selectedEndDate,
          buttonText,
          setBeginDateToday,
          setEndDateToday,
        })
      }
    } else if (isDashboard && (dashboardInfo.time_period || viewerJson)) {
      /* eslint-disable camelcase */
      const timePeriod = isViewer ? viewerJson : dashboardInfo.time_period || {}
      const isTimeIntervalArray = Array.isArray(timePeriod.intervals) && timePeriod.type === CUSTOM_STR.toLowerCase()
      const dateFilterObj = timePeriod?.anchor_date && !isCompareBy ? anchorDateFilterStatus : dateFilterStatus
      if (Object.keys(dateFilterObj).length > 1) {
        /* eslint-disable camelcase */
        const { Fiscal, Gregorian } = timePeriod?.anchor_date
          ? anchorDateFilterStatus?.data
          : this.dateFilterChange(dateFilterStatus)
        /* eslint-enable camelcase */
        const calendarType = fiscalYear ? Fiscal : Gregorian
        const periodInterval = isTimeIntervalArray ? timePeriod?.intervals : timePeriod?.interval
        if (periodInterval) {
          if ((timePeriod.set_begin_date_today || timePeriod.set_end_date_today) && periodInterval?.includes(',')) {
            const splitInterval = periodInterval?.split(',')
            const start = splitInterval[0]?.trim()
            const end = splitInterval[1]?.trim()

            const startDateStr = timePeriod.set_begin_date_today ? start?.slice(1) : start?.slice(2, start?.length - 1)
            const endDateStr = timePeriod.set_end_date_today
              ? end?.slice(0, end?.length - 1)
              : end?.slice(1, end?.length - 2)

            startState = timePeriod.set_begin_date_today ? 'Today' : startDateStr
            endState = timePeriod.set_end_date_today ? 'Today' : endDateStr
            buttonText = 'Between'
            setBeginDateToday = timePeriod.set_begin_date_today || false
            setEndDateToday = timePeriod.set_end_date_today || false
          } else if (calendarType[periodInterval]) {
            startState = calendarType[timePeriod.interval]['Begin Date']
            endState = calendarType[timePeriod.interval]['End Date']
            buttonText = timePeriod.interval
          } else if (periodInterval !== 'last segment' && !periodInterval?.includes('/')) {
            startState = null
            endState = null
            buttonText = periodInterval
          } else if (periodInterval?.includes('/')) {
            startState = periodInterval?.split('/')[0]
            endState = periodInterval?.split('/')[1]
            buttonText = 'Between'
          }
          this.setState({
            startDate: startState,
            endDate: endState,
            buttonText,
            setBeginDateToday,
            setEndDateToday,
          })
        }
      }
    }
  }

  checkExistenceValue(calendarType, value) {
    return !(
      typeof calendarType[value] === 'undefined' ||
      calendarType[value] === null ||
      calendarType[value]['Begin Date'] === null ||
      calendarType[value]['Begin Date'] === ''
    )
  }

  componentDidUpdate(prevProps) {
    let startState, endState, buttonTextObj
    const { buttonText, hasSetDrillthroughTimePeriod, anchorEl, secondaryTimePeriodColumn } = this.state
    const {
      fiscalYear,
      dateFilterStatus,
      isTimePeriodDrillThrough,
      cardInfo,
      isCompareBy,
      compareByValue,
      viewerJson,
      anchorDateFilterStatus,
      selectedTimePeriod,
    } = this.props
    const prevViewer = Object.assign({}, prevProps.viewerJson)
    const nextViewer = Object.assign({}, viewerJson)
    const selectedSecondaryTimePeriods = selectedTimePeriod?.secondaryTimePeriods

    if (fiscalYear !== prevProps.fiscalYear) {
      const { isDashboard, pendingCardUid } = prevProps
      const type = fiscalYear ? 'Fiscal' : 'Gregorian'
      const prevSecondaryTimePeriod = prevProps.isDashboard
        ? prevProps.dashboardInfo.time_period
        : isEmpty(secondaryTimePeriodColumn)
        ? prevProps.cardInfo.data?.card_config?.card_query_attribute?.secondary_time_periods[
            getSecondaryTimePeriodColumn(this.props.selectedDataset).field_name
          ]
        : prevProps.cardInfo.data?.card_config?.card_query_attribute?.secondary_time_periods[
            secondaryTimePeriodColumn.field_name
          ]

      const isTimeIntervalArray =
        Array.isArray(prevSecondaryTimePeriod?.intervals) && prevSecondaryTimePeriod?.type === CUSTOM_STR.toLowerCase()
      if (
        (prevSecondaryTimePeriod &&
          Object.keys(prevSecondaryTimePeriod).length &&
          prevSecondaryTimePeriod.calendar_type &&
          (prevSecondaryTimePeriod.calendar_type === 'Fiscal' ||
            prevSecondaryTimePeriod.calendar_type === 'Gregorian')) ||
        buttonText !== ''
      ) {
        if (!isDashboard && !prevProps.isViewer) {
          if (cardInfo?.data?.card_config) {
            const card = cloneDeep(cardInfo.data.card_config)

            if (!Object.prototype.hasOwnProperty.call(card, '_id')) {
              card.pendingCardUid = pendingCardUid
            }
            if (
              !prevProps.isCompareBy &&
              !isEmpty(cardInfo.data.card_config.card_query_attribute.secondary_time_periods)
            ) {
              if (isEmpty(secondaryTimePeriodColumn)) {
                const secondaryTimePeriodColumn = getSecondaryTimePeriodColumn(this.props.selectedDataset)
                cardInfo.data.card_config.card_query_attribute.secondary_time_periods[
                  secondaryTimePeriodColumn?.field_name
                ].type = prevSecondaryTimePeriod.type
                card.card_query_attribute.secondary_time_periods[secondaryTimePeriodColumn?.field_name].calendar_type =
                  type
                prevProps.markSelectedCardAndRender(card)
              } else {
                card.card_query_attribute.secondary_time_periods[secondaryTimePeriodColumn.field_name].type =
                  prevSecondaryTimePeriod.type
                card.card_query_attribute.secondary_time_periods[secondaryTimePeriodColumn?.field_name].calendar_type =
                  type
                prevProps.markSelectedCardAndRender(card)
              }
            }
          }
        } else if (!prevProps.isViewer) {
          const timePeriodInterval = {
            calendar_type: '',
          }
          if (isTimeIntervalArray) {
            timePeriodInterval.intervals = prevSecondaryTimePeriod.intervals
            timePeriodInterval.type = prevSecondaryTimePeriod.type
          } else {
            timePeriodInterval.interval = prevSecondaryTimePeriod.interval
          }
          timePeriodInterval.calendar_type = type
          prevProps.modifyDashboardInfo('time_period', timePeriodInterval)
        } else {
          const timePeriodValue = {
            calendar_type: type,
          }
          prevProps.onTimeJsonChange(timePeriodValue, true)
        }
      }
    }

    /* eslint-disable camelcase */
    const secondaryTimePeriods = prevProps.isViewer
      ? selectedSecondaryTimePeriods ||
        cardInfo?.data?.context?.rendered_payload?.secondary_time_periods ||
        cardInfo?.data?.card_config?.card_query_attribute?.secondary_time_periods
      : cardInfo?.data?.card_config?.card_query_attribute?.secondary_time_periods
    const secondaryTimePeriod = prevProps.isDashboard
      ? prevProps.dashboardInfo.time_period
      : secondaryTimePeriods && secondaryTimePeriods[secondaryTimePeriodColumn?.field_name]
    const dateFilterObj = secondaryTimePeriod?.anchor_date && !isCompareBy ? anchorDateFilterStatus : dateFilterStatus
    const prevDateFilterObj =
      secondaryTimePeriod?.anchor_date && !isCompareBy ? prevProps.anchorDateFilterStatus : prevProps.dateFilterStatus
    /* eslint-enable camelcase */

    if (
      (fiscalYear !== prevProps.fiscalYear ||
        dateFilterObj !== prevDateFilterObj ||
        Object.keys(nextViewer).length > Object.keys(prevViewer).length ||
        !isEqual(nextViewer, prevViewer) ||
        (cardInfo?.data?.card_config && isCompareBy && prevProps.compareByValue !== compareByValue)) &&
      ((!prevProps.isViewer &&
        prevProps.isDashboard &&
        prevProps.dashboardInfo.time_period &&
        Object.keys(prevProps.dashboardInfo.time_period).length) ||
        (this.props.isViewer && Object.keys(this.props.viewerJson).length) ||
        (!prevProps.isDashboard && cardInfo?.data?.card_config)) &&
      Object.keys(dateFilterObj).length > 1 &&
      !isTimePeriodDrillThrough &&
      !anchorEl
    ) {
      const { Gregorian } =
        secondaryTimePeriod?.anchor_date && !isCompareBy
          ? anchorDateFilterStatus?.data
          : this.dateFilterChange(dateFilterStatus)
      const calendarType = Gregorian
      const isTimeIntervalArray =
        Array.isArray(secondaryTimePeriod?.intervals) && secondaryTimePeriod?.type === CUSTOM_STR.toLowerCase()
      const periodInterval =
        secondaryTimePeriod && (secondaryTimePeriod?.interval || secondaryTimePeriod?.intervals)
          ? isCompareBy
            ? compareByValue
            : isTimeIntervalArray
            ? secondaryTimePeriod?.intervals
            : secondaryTimePeriod?.interval
          : ''

      if (
        secondaryTimePeriod &&
        (periodInterval || secondaryTimePeriod.type || (cardInfo && cardInfo.data && cardInfo.data.rendered_interval))
      ) {
        if (
          (secondaryTimePeriod.set_begin_date_today || secondaryTimePeriod.set_end_date_today) &&
          periodInterval?.includes(',')
        ) {
          const splitInterval = periodInterval?.split(',')
          const start = splitInterval[0]?.trim()
          const end = splitInterval[1]?.trim()

          const startDateStr = secondaryTimePeriod.set_begin_date_today
            ? start?.slice(1)
            : start?.slice(2, start?.length - 1)
          const endDateStr = secondaryTimePeriod.set_end_date_today
            ? end?.slice(0, end?.length - 1)
            : end?.slice(1, end?.length - 2)

          startState = secondaryTimePeriod.set_begin_date_today ? 'Today' : startDateStr
          endState = secondaryTimePeriod.set_end_date_today ? 'Today' : endDateStr
          buttonTextObj = 'Between'
        } else if (periodInterval?.includes('/')) {
          startState = periodInterval.split('/')[0]
          endState = periodInterval.split('/')[1]
          buttonTextObj = 'Between'
        } else {
          if (calendarType[periodInterval]) {
            buttonTextObj = periodInterval
            startState = calendarType[buttonTextObj]['Begin Date']
            endState = calendarType[buttonTextObj]['End Date']
          } else if (periodInterval !== 'last segment') {
            startState = null
            endState = null
            buttonTextObj = periodInterval
          } else {
            startState =
              cardInfo.data && cardInfo.data.rendered_interval && cardInfo.data.rendered_interval.begin
                ? cardInfo.data.rendered_interval.begin
                : null
            endState =
              cardInfo.data && cardInfo.data.rendered_interval && cardInfo.data.rendered_interval.end
                ? cardInfo.data.rendered_interval.end
                : null
            buttonTextObj = periodInterval
          }
        }
        this.setState({
          startDate: startState,
          endDate: endState,
          buttonText: buttonTextObj,
        })
      }
    }
    this.showErrorOnDateFilterStatusFailed(prevProps)

    if (
      isTimePeriodDrillThrough &&
      Object.keys(cardInfo).length &&
      cardInfo.data &&
      cardInfo.data.rendered_interval &&
      !hasSetDrillthroughTimePeriod
    ) {
      const { cardInfo } = this.props

      this.setState({
        startDate: cardInfo.data.rendered_interval.begin,
        endDate: cardInfo.data.rendered_interval.end,
        buttonText: 'Between',
        hasSetDrillthroughTimePeriod: true,
      })
    }
  }

  showErrorOnDateFilterStatusFailed(prevProps) {
    const { dateFilterStatus, displayServiceErrorMessage } = this.props

    if (
      prevProps.dateFilterStatus &&
      prevProps.dateFilterStatus.status === 'requested' &&
      dateFilterStatus &&
      dateFilterStatus.status === 'failed'
    ) {
      displayServiceErrorMessage(createErrMsg(dateFilterStatus, 'Something went wrong while retrieving time period'))
    }
  }

  dateFilterChange(dateFilterStatus = this.props.dateFilterStatus) {
    const { selectedDataset, cardInfo } = this.props
    const dateFilter = cloneDeep(dateFilterStatus?.data)
    if (cardInfo?.data?.card_config && Object.keys(dateFilterStatus).length > 0 && dateFilter) {
      dateFilter.Fiscal['All Time']['Begin Date'] = selectedDataset.min_date_range
      dateFilter.Fiscal['All Time']['End Date'] = selectedDataset.max_date_range
      dateFilter.Gregorian['All Time']['Begin Date'] = selectedDataset.min_date_range
      dateFilter.Gregorian['All Time']['End Date'] = selectedDataset.max_date_range
    }
    return dateFilter
  }

  callAnchorDate = value => {
    const { getAnchorDateFilter, anchorDateFilterStatus } = this.props
    const timePeriod = this.getTimePeriod()
    /* eslint-disable camelcase */

    if (timePeriod?.anchor_date && timePeriod?.anchor_date !== anchorDateFilterStatus?.anchor_date) {
      getAnchorDateFilter({ anchor_date: timePeriod?.anchor_date })
    } else if (value === 0 && !timePeriod?.anchor_date) {
      getAnchorDateFilter({ anchor_date: getFormatDate() })
    }
    /* eslint-enable camelcase */
  }

  handleTabChange = (event, value) => {
    this.callAnchorDate(value)
    this.setState({ value })
  }

  onHandleTabClose = () => {
    const { getAnchorDateFilter, anchorDateFilterStatus, closeMobilePopover, isMobile } = this.props
    const timePeriod = this.getTimePeriod()
    if (timePeriod?.anchor_date && timePeriod?.anchor_date !== anchorDateFilterStatus?.anchor_date) {
      getAnchorDateFilter({ anchor_date: timePeriod?.anchor_date })
    }

    this.setState({
      anchorEl: null,
    })

    if (isMobile) {
      closeMobilePopover()
    }
  }

  updateState = (stateName, value) => {
    this.setState({
      [stateName]: value,
    })
  }

  getTimePeriod() {
    const { cardInfo, isDashboard, viewerJson, isViewer, dashboardInfo, secondaryTimePeriodColumn } = this.props
    const secondaryTimePeriods = cardInfo?.data?.context?.rendered_payload?.secondary_time_periods

    return isViewer
      ? viewerJson
      : isDashboard
      ? dashboardInfo.time_period
      : cardInfo?.data?.card_config
      ? secondaryTimePeriods && secondaryTimePeriods[secondaryTimePeriodColumn?.field_name]
      : {}
  }

  handleTabOpen = event => {
    const { buttonText } = this.state
    const { isCompareBy } = this.props
    let value = 0
    const isButtonTextArray = Array.isArray(buttonText)
    const buttonLabel = getTimePeriodButtonLabel(buttonText)
    const customArray = !isButtonTextArray ? buttonText?.split(' ') : []
    const isLastNextLabel = Boolean(
      customArray?.length === 3 &&
        CUSTOM_DATES_OPTIONS.includes(customArray[0]) &&
        DEFAULT_PERIOD_PLURAL_OPTIONS.includes(customArray[2])
    )

    if (!isButtonTextArray) {
      if (
        TODAY_TIME_PERIOD.includes(buttonLabel) ||
        PREVIOUS_TIME_PERIOD.includes(buttonLabel) ||
        NEXT_TIME_PERIOD.includes(buttonLabel) ||
        isLastNextLabel
      ) {
        value = 0
        this.callAnchorDate(value)
      } else if (PERIOD_TIME_PERIOD.includes(buttonLabel)) {
        value = 1
      } else if (buttonLabel?.toLowerCase() === 'between') {
        value = 2
      } else if (
        OTHERS_TIME_PERIOD.includes(buttonLabel) ||
        buttonLabel === '' ||
        buttonLabel?.toLowerCase() === 'last segment'
      ) {
        value = isCompareBy ? 3 : 4
      }
    } else if (!isCompareBy) {
      value = 3
    }

    this.setState({
      anchorEl: event.currentTarget,
      value,
    })
  }

  handleCalendar = () => {
    const {
      cardInfo,
      isDashboard,
      pendingCardUid,
      markSelectedCardAndRender,
      modifyDashboardInfo,
      isViewer,
      onTimeJsonChange,
      isBuilder,
      isCompareBy,
      changeIndex,
      secondaryTimePeriodColumn,
      fiscalYear,
    } = this.props
    const { selectedStartDate, selectedEndDate, startDate, endDate, setEndDateToday, setBeginDateToday } = this.state
    const calendarStartDate = setBeginDateToday ? 'Today' : selectedStartDate || getFormatDate(startDate)
    const calendarEndDate = setEndDateToday ? 'Today' : selectedEndDate || getFormatDate(endDate)
    const type = fiscalYear ? 'Fiscal' : 'Gregorian'
    if (calendarStartDate && calendarEndDate) {
      const startDateStr = setBeginDateToday ? 'this.day.begin' : `'${calendarStartDate}'`
      const endDateStr = setEndDateToday ? 'this.day.end' : `'${calendarEndDate}'`
      const intervalString = `(${startDateStr}, ${endDateStr})`
      const periodIntervalStr =
        setBeginDateToday || setEndDateToday ? intervalString : `${calendarStartDate}/${calendarEndDate}`

      if (cardInfo?.data?.card_config && !isDashboard && !isViewer) {
        const card = cloneDeep(cardInfo.data.card_config)
        if (!Object.prototype.hasOwnProperty.call(card, '_id')) {
          card.pendingCardUid = pendingCardUid
        }

        this.setState(
          {
            startDate: calendarStartDate,
            endDate: calendarEndDate,
            buttonText: 'Between',
            anchorEl: null,
          },
          () => {
            if (isBuilder && isCompareBy) {
              this.props.callRenderCardWithCompareBy(card, periodIntervalStr, isCompareBy, changeIndex)
            } else {
              card.card_query_attribute.secondary_time_periods[secondaryTimePeriodColumn?.field_name].interval =
                periodIntervalStr
              card.card_query_attribute.secondary_time_periods[secondaryTimePeriodColumn?.field_name].type = 'absolute'
              card.card_query_attribute.secondary_time_periods[secondaryTimePeriodColumn?.field_name].calendar_type =
                type

              card.card_query_attribute.secondary_time_periods[
                secondaryTimePeriodColumn?.field_name
              ].set_end_date_today = setEndDateToday || false
              card.card_query_attribute.secondary_time_periods[
                secondaryTimePeriodColumn?.field_name
              ].set_begin_date_today = setBeginDateToday || false

              delete card.card_query_attribute.secondary_time_periods[secondaryTimePeriodColumn?.field_name].anchor_date
              delete card.card_query_attribute.secondary_time_periods[secondaryTimePeriodColumn?.field_name].intervals
              markSelectedCardAndRender(card)
            }
          }
        )
      } else if (isDashboard && !isViewer) {
        const timePeriodInterval = {
          interval: '',
          calendar_type: '',
        }

        timePeriodInterval.calendar_type = type
        timePeriodInterval.interval = periodIntervalStr
        timePeriodInterval.set_end_date_today = setEndDateToday || false
        timePeriodInterval.set_begin_date_today = setBeginDateToday || false
        modifyDashboardInfo('time_period', timePeriodInterval)
        this.setState({
          startDate: calendarStartDate,
          endDate: calendarEndDate,
          buttonText: 'Between',
          anchorEl: null,
        })
      } else if (isViewer) {
        const timePeriodValue = {
          interval: periodIntervalStr,
          type: 'absolute',
          calendar_type: type,
          set_end_date_today: setEndDateToday || false,
          set_begin_date_today: setBeginDateToday || false,
        }
        this.setState(
          {
            startDate: calendarStartDate,
            endDate: calendarEndDate,
            buttonText: 'Between',
            anchorEl: null,
          },
          () => {
            onTimeJsonChange(timePeriodValue, true, true)
          }
        )
      }
    }
  }

  onDatesChange = (selectedTimeValue, anchorRelativeDate, customType) => {
    const {
      cardInfo,
      isDashboard,
      pendingCardUid,
      modifyDashboardInfo,
      isViewer,
      onTimeJsonChange,
      isBuilder,
      isCompareBy,
      changeIndex,
      updateChangedCards,
      markSelectedCardAndRender,
      anchorDateFilterStatus,
      dateFilterStatus,
      fiscalYear,
      selectedTimePeriod,
    } = this.props

    const { secondaryTimePeriodColumn } = this.state
    const selectedSecondaryTimePeriods = selectedTimePeriod?.secondaryTimePeriods
    const anchorDate = anchorRelativeDate ? getFormatDate(anchorRelativeDate) : null
    const card = cloneDeep(cardInfo?.data?.card_config)
    // Only for cards
    if (updateChangedCards && !isDashboard) {
      updateChangedCards(cardInfo.data?.card_config?._id)
    }

    const isSelectedTimeArray = Array.isArray(selectedTimeValue) && customType === CUSTOM_STR.toLowerCase()
    const type =
      selectedSecondaryTimePeriods && selectedSecondaryTimePeriods[secondaryTimePeriodColumn?.field_name]
        ? selectedSecondaryTimePeriods[secondaryTimePeriodColumn?.field_name]?.calendar_type
        : fiscalYear
        ? 'Fiscal'
        : 'Gregorian'
    const { Fiscal, Gregorian } = anchorDate ? anchorDateFilterStatus?.data : this.dateFilterChange(dateFilterStatus)
    const calendarType = fiscalYear ? Fiscal : Gregorian

    if (!isDashboard && cardInfo?.data?.card_config && !isViewer) {
      let secondaryTimePeriod = card?.card_query_attribute?.secondary_time_periods
      const secondaryTimePeriodColumn = getSecondaryTimePeriodColumn(this.props.selectedDataset)
      if (isEmpty(secondaryTimePeriod)) {
        secondaryTimePeriod[secondaryTimePeriodColumn?.field_name] = {}
      }
      secondaryTimePeriod = secondaryTimePeriod[secondaryTimePeriodColumn?.field_name]

      if (!Object.prototype.hasOwnProperty.call(card, '_id')) {
        card.pendingCardUid = pendingCardUid
      }
      this.setState(
        {
          buttonText: selectedTimeValue,
          anchorEl: null,
          startDate:
            !isSelectedTimeArray && calendarType[selectedTimeValue]
              ? calendarType[selectedTimeValue]['Begin Date']
              : '',
          endDate:
            !isSelectedTimeArray && calendarType[selectedTimeValue] ? calendarType[selectedTimeValue]['End Date'] : '',
        },
        () => {
          if (isBuilder && isCompareBy) {
            this.props.callRenderCardWithCompareBy(card, selectedTimeValue, isCompareBy, changeIndex)
          } else {
            if (customType && isSelectedTimeArray) {
              secondaryTimePeriod.intervals = selectedTimeValue
              secondaryTimePeriod.type = customType
              delete secondaryTimePeriod.interval
            } else {
              delete secondaryTimePeriod?.intervals
              secondaryTimePeriod.interval = selectedTimeValue
              secondaryTimePeriod.type = 'relative'
            }
            if (anchorDate) {
              secondaryTimePeriod.anchor_date = anchorDate
            } else {
              delete secondaryTimePeriod.anchor_date
            }

            delete secondaryTimePeriod.set_begin_date_today
            delete secondaryTimePeriod.set_end_date_today
            markSelectedCardAndRender(card)
          }
        }
      )
    } else if (isDashboard && !isViewer) {
      const timePeriodInterval = {
        calendar_type: '',
      }
      if (isSelectedTimeArray && customType) {
        timePeriodInterval.intervals = selectedTimeValue
        timePeriodInterval.type = customType
      } else {
        timePeriodInterval.interval = selectedTimeValue
      }
      if (anchorDate) {
        timePeriodInterval.anchor_date = anchorDate
      }
      modifyDashboardInfo('time_period', timePeriodInterval)
      this.setState({
        buttonText: selectedTimeValue,
        anchorEl: null,
        startDate: calendarType[selectedTimeValue] ? calendarType[selectedTimeValue]['Begin Date'] : '',
        endDate: calendarType[selectedTimeValue] ? calendarType[selectedTimeValue]['End Date'] : '',
      })
    } else if (isViewer) {
      const timePeriodValue = {
        calendar_type: type,
        granularity: selectedSecondaryTimePeriods
          ? selectedSecondaryTimePeriods[secondaryTimePeriodColumn?.field_name]?.granularity || 'All'
          : cardInfo?.data?.context?.rendered_payload?.secondary_time_periods[secondaryTimePeriodColumn?.field_name]
              ?.granularity || 'All',
      }

      if (isSelectedTimeArray && customType) {
        timePeriodValue.intervals = selectedTimeValue
        timePeriodValue.type = customType
      } else {
        timePeriodValue.interval = selectedTimeValue
        timePeriodValue.type = 'relative'
      }

      if (anchorDate) {
        timePeriodValue.anchor_date = anchorDate
      }

      this.setState(
        {
          buttonText: selectedTimeValue,
          anchorEl: null,
          startDate: calendarType[selectedTimeValue] ? calendarType[selectedTimeValue]['Begin Date'] : '',
          endDate: calendarType[selectedTimeValue] ? calendarType[selectedTimeValue]['End Date'] : '',
        },
        () => {
          onTimeJsonChange(timePeriodValue, !anchorDate, true)
        }
      )
    }
  }

  renderTabValue() {
    const { Fiscal, Gregorian } = this.dateFilterChange()
    const { value, selectedStartDate, selectedEndDate, setBeginDateToday, setEndDateToday } = this.state
    const {
      fiscalYear,
      isDashboard,
      dashboardInfo,
      cardInfo,
      selectedDataset,
      isViewer,
      viewerJson,
      isMobile,
      deviceType,
      anchorDateFilterStatus,
      dateFilterStatus,
      getAnchorDateFilter,
      compareByValue,
      isCompareBy,
      customDateFilterStatus,
      getCustomDateFilter,
      secondaryTimePeriodColumn,
      isSecondaryTime,
      selectedTimePeriod,
      secondaryTime,
    } = this.props

    let timeArray
    const calendarType = fiscalYear ? Fiscal : Gregorian
    const selectedSecondaryTimePeriods = selectedTimePeriod?.secondaryTimePeriods
    const secondaryTimePeriods = isViewer
      ? selectedSecondaryTimePeriods || cardInfo?.data?.context?.rendered_payload?.secondary_time_periods
      : cardInfo?.data?.card_config?.card_query_attribute?.secondary_time_periods
    const secondaryTimePeriod = isDashboard
      ? dashboardInfo.time_period
      : cardInfo?.data?.card_config
      ? secondaryTimePeriods && secondaryTimePeriods[secondaryTimePeriodColumn?.field_name]
      : null
    switch (value) {
      case 1:
        timeArray = PERIOD_TIME_PERIOD
        break
      case 3:
        timeArray = OTHERS_TIME_PERIOD
        if (!isDashboard) {
          timeArray = OTHERS_TIME_PERIOD.slice(0, 1)
        }
        break
    }

    const _selectedDateset = cloneDeep(selectedDataset)
    let datasetMaxDateRange = null
    let datasetMinDateRange = null
    if (_selectedDateset) {
      if (_selectedDateset.max_date_range && moment(_selectedDateset.max_date_range).isValid()) {
        const maxRangeArray = _selectedDateset.max_date_range.slice(0, 19)
        datasetMaxDateRange = maxRangeArray || null
      }

      if (_selectedDateset.min_date_range && moment(_selectedDateset.min_date_range).isValid()) {
        const minRangeArray = _selectedDateset.min_date_range.slice(0, 19)
        datasetMinDateRange = minRangeArray || null
      }
    }

    const defaultStartDateValue = () => {
      let startDateStr = null
      if (secondaryTimePeriod && secondaryTimePeriod.interval) {
        const periodInterval = secondaryTimePeriod.interval
        if (
          (secondaryTimePeriod.set_begin_date_today || secondaryTimePeriod.set_end_date_today) &&
          periodInterval.includes(',')
        ) {
          const splitInterval = periodInterval?.split(',')
          const start = splitInterval[0]?.trim()
          startDateStr = secondaryTimePeriod.set_begin_date_today
            ? getFormatDate(datasetMinDateRange)
            : start?.slice(2, start?.length - 1)
        } else if (
          (!secondaryTimePeriod.type && secondaryTimePeriod.interval.includes('/')) ||
          (secondaryTimePeriod.type && secondaryTimePeriod.type === 'absolute')
        ) {
          startDateStr = secondaryTimePeriod.interval.split('/')[0]
        } else if (!isDashboard && datasetMinDateRange) {
          startDateStr = getFormatDate(datasetMinDateRange)
        }
      } else if (!isDashboard && datasetMinDateRange) {
        startDateStr = getFormatDate(datasetMinDateRange)
      }

      return startDateStr
    }

    const defaultEndDateValue = () => {
      let endDateStr = null
      if (secondaryTimePeriod && secondaryTimePeriod.interval) {
        const periodInterval = secondaryTimePeriod.interval
        if (
          (secondaryTimePeriod.set_begin_date_today || secondaryTimePeriod.set_end_date_today) &&
          periodInterval.includes(',')
        ) {
          const splitInterval = periodInterval?.split(',')
          const end = splitInterval[1]?.trim()
          endDateStr = secondaryTimePeriod.set_end_date_today
            ? getFormatDate(datasetMaxDateRange)
            : end?.slice(1, end?.length - 2)
        } else if (
          (!secondaryTimePeriod.type && secondaryTimePeriod.interval.includes('/')) ||
          (secondaryTimePeriod.type && secondaryTimePeriod.type === 'absolute')
        ) {
          endDateStr = secondaryTimePeriod.interval.split('/')[1]
        } else if (!isDashboard && datasetMaxDateRange) {
          endDateStr = getFormatDate(datasetMaxDateRange)
        }
      } else if (!isDashboard && datasetMaxDateRange) {
        endDateStr = getFormatDate(datasetMaxDateRange)
      }

      return endDateStr
    }

    return (
      <div>
        {value === 2 && (
          <div className="calendar-fit">
            <div className="flex-row">
              <Calendar
                id="start-date"
                setToday="Set Start Date Today"
                label="Start Date"
                isMobile={isMobile}
                calendarOpen={this.state.calendarOpen}
                toggleCalendarState={this.toggleCalendarState}
                deviceType={deviceType}
                endDate={selectedEndDate || defaultEndDateValue() || getFormatDate()}
                defaultValue={defaultStartDateValue()}
                onChange={(value, setBeginDateToday = false) => {
                  this.setState({
                    selectedStartDate: value,
                    setBeginDateToday,
                  })
                  if (selectedEndDate === null) {
                    this.updateState('selectedEndDate', defaultEndDateValue())
                  }
                }}
                minDate={datasetMinDateRange || null}
                maxDate={datasetMaxDateRange || null}
                isDashboard={isDashboard}
                isEnableToday={setBeginDateToday}
              />
              <Calendar
                id="end-date"
                setToday="Set End Date Today"
                label="End Date"
                isMobile={isMobile}
                calendarOpen={this.state.calendarOpen}
                toggleCalendarState={this.toggleCalendarState}
                deviceType={deviceType}
                startDate={selectedStartDate || defaultStartDateValue() || getFormatDate()}
                defaultValue={defaultEndDateValue()}
                onChange={(value, setEndDateToday = false) => {
                  this.setState({
                    selectedEndDate: value,
                    setEndDateToday,
                  })

                  if (selectedStartDate === null) {
                    this.updateState('selectedStartDate', defaultStartDateValue())
                  }
                }}
                minDate={datasetMinDateRange || null}
                maxDate={datasetMaxDateRange || null}
                isDashboard={isDashboard}
                isEnableToday={setEndDateToday}
              />
            </div>
            <Button
              id="apply-calendar-dates"
              variant="text"
              color="primary"
              disabled={isDisableBetweenApply(selectedStartDate, selectedEndDate, setBeginDateToday, setEndDateToday)}
              className="calendar-button"
              onClick={this.handleCalendar}
            >
              {`${isViewer ? 'Select' : 'Apply'}`}
            </Button>
          </div>
        )}
        {value === 1 && (
          <div>
            <List>
              {timeArray.map((timeValue, index) => (
                <ListItem
                  id={`selected-tab-value-${index}`}
                  button
                  onClick={() => this.onDatesChange(timeValue)}
                  key={`timeValue-${index}`}
                  className="listItem-test list-item-padding"
                >
                  <div className="date-scss">
                    <div className="text-scss">{timeValue}</div>
                    <div>
                      {this.checkExistenceValue(calendarType, timeValue) &&
                        `${getFormatDate(calendarType[timeValue]['Begin Date'])} to
                        ${getFormatDate(calendarType[timeValue]['End Date'])}`}
                    </div>
                  </div>
                </ListItem>
              ))}
            </List>
          </div>
        )}
        {value === 0 && (
          <RelativeTimePeriod
            handleCancel={this.onHandleTabClose}
            handleDateChange={this.onDatesChange}
            anchorDateFilterStatus={anchorDateFilterStatus}
            fiscalYear={fiscalYear}
            getAnchorDateFilter={getAnchorDateFilter}
            cardInfo={cardInfo}
            isDashboard={isDashboard}
            dashboardInfo={dashboardInfo}
            isViewer={isViewer}
            viewerJson={viewerJson}
            dateFilterStatus={dateFilterStatus}
            isSecondaryTime={isSecondaryTime}
            secondaryTimePeriodColumn={secondaryTimePeriodColumn}
            isCompareBy={isCompareBy}
            compareByValue={compareByValue}
            isMobile={isMobile}
            selectedTimePeriod={selectedTimePeriod}
            secondaryTime={secondaryTime}
          />
        )}
        {value === 3 && !isCompareBy && (
          <CustomTimePeriod
            handleCancel={this.onHandleTabClose}
            handleDateChange={this.onDatesChange}
            fiscalYear={fiscalYear}
            cardInfo={cardInfo}
            isDashboard={isDashboard}
            dashboardInfo={dashboardInfo}
            isViewer={isViewer}
            viewerJson={viewerJson}
            isMobile={isMobile}
            isSecondaryTime={isSecondaryTime}
            secondaryTimePeriodColumn={secondaryTimePeriodColumn}
            getCustomDateFilter={getCustomDateFilter}
            customDateFilterStatus={customDateFilterStatus}
            minDate={datasetMinDateRange || null}
            maxDate={datasetMaxDateRange || null}
            selectedTimePeriod={selectedTimePeriod}
            secondaryTime={secondaryTime}
          />
        )}
        {(isCompareBy ? value === 3 : value === 4) && (
          <OtherTimePeriodOptions
            handleCancel={this.onHandleTabClose}
            handleTimePeriod={this.onDatesChange}
            cardInfo={cardInfo}
            isDashboard={isDashboard}
            dashboardInfo={dashboardInfo}
            isViewer={isViewer}
            viewerJson={viewerJson}
            isMobile={isMobile}
            handleNone={this.onHandleNone}
            isSecondaryTime={isSecondaryTime}
            secondaryTimePeriodColumn={secondaryTimePeriodColumn}
            selectedTimePeriod={selectedTimePeriod}
            secondaryTime={secondaryTime}
          />
        )}
      </div>
    )
  }

  toggleCalendarState = calendarOpen => {
    this.setState({
      calendarOpen,
    })
  }

  renderDateFilter() {
    const { dateFilterStatus } = this.props
    switch (dateFilterStatus.status) {
      case 'requested':
        return <Spinner size="large" layout="selfCentering" className="datasets-status-progress" />
      case 'failed':
        // todo put in snackbar thing
        return (
          <p className="datasets-status-failed">
            {createErrMsg(dateFilterStatus, 'Something went wrong with the cards time data request')}
          </p>
        )
      default:
        return dateFilterStatus.data ? this.renderTabValue() : null
    }
  }

  toggleMobileTimePeriodContainer = () => {
    this.setState({
      mobileTimePeriodContainerOpen: !this.state.mobileTimePeriodContainerOpen,
    })
  }

  render() {
    const {
      cardInfo,
      isMobile,
      closeMobilePopover,
      isCompareBy,
      isViewer,
      isDashboard,
      dashboardInfo,
      secondaryTimePeriodColumn,
      selectedTimePeriod,
    } = this.props
    const { buttonText, anchorEl, startDate, endDate, value } = this.state
    const selectedSecondaryTimePeriods = selectedTimePeriod?.secondaryTimePeriods
    const anchor = this.props.anchorEl || anchorEl
    const closePopOver = isMobile ? closeMobilePopover : this.onHandleTabClose
    const secondaryTimePeriods = isViewer
      ? selectedSecondaryTimePeriods || cardInfo?.data?.context?.rendered_payload?.secondary_time_periods
      : cardInfo?.data?.card_config?.card_query_attribute?.secondary_time_periods
    /* eslint-disable camelcase */
    const secondaryTimePeriod = isDashboard
      ? dashboardInfo.time_period
      : (secondaryTimePeriods && secondaryTimePeriods[secondaryTimePeriodColumn?.field_name]) || {
          secondaryTimePeriodColumn: {
            intervals: ['All Time'],
            type: 'relative',
            granularity: 'Week',
            calendar_type: 'Gregorian',
          },
        }
    /* eslint-enable camelcase */
    const isButtonTextArray = Array.isArray(buttonText)
    const buttonLabel = isCompareBy ? buttonText : getTimePeriodButtonLabel(buttonText, secondaryTimePeriod)
    const showCustom = Boolean(buttonLabel === CUSTOM_STR && buttonText?.length < 30)

    /* eslint-disable camelcase */
    const startDateLabel = startDate
    const endDateLabel = endDate
    /* eslint-enable camelcase */

    const fromDate = startDate?.toLowerCase() === LWR_CASE_TODAY ? startDate : getFormatDate(startDateLabel)
    const toDate = endDate?.toLowerCase() === LWR_CASE_TODAY ? endDate : getFormatDate(endDateLabel)
    const tabOptions = isCompareBy ? TIME_PERIOD_TAB_OPTIONS_WITH_COMPARE_BY : TIME_PERIOD_TAB_OPTIONS
    return (
      <div className="timePeriod">
        {!isMobile && (
          <Tooltip
            title={!showCustom && buttonLabel === CUSTOM_STR ? buttonText : isButtonTextArray ? buttonLabel : ''}
            placement="bottom"
          >
            <Button
              id="secondary-time-period-btn"
              disabled={cardInfo.status === 'requested'}
              variant="contained"
              color="primary"
              onClick={this.handleTabOpen}
              className={`icon-label-btn-props time-period-btn ${isMobile ? 'mobileTimePeriodTriggerBtn' : null} ${
                anchorEl && isCompareBy ? 'time-period-selected' : null
              }`}
            >
              {!isMobile && (
                <div className="calender-icon">
                  <DateRange />
                </div>
              )}
              {secondaryTimePeriod && secondaryTimePeriod.anchor_date && <AnchorIcon className="anchor-icon" />}
              {startDateLabel
                ? `${buttonLabel} ${fromDate} to ${toDate}`
                : showCustom
                ? buttonText
                : isButtonTextArray
                ? MULTI_SELECT_CUSTOM_STR
                : buttonLabel}
              {!isMobile &&
                (anchorEl && isCompareBy ? (
                  <KeyboardArrowUp className="icon-left icon-right" />
                ) : (
                  <KeyboardArrowDown className="icon-left icon-right" />
                ))}
            </Button>
          </Tooltip>
        )}

        <Popover
          open={Boolean(anchor)}
          anchorEl={anchor}
          onClose={closePopOver}
          className="timePeriod"
          anchorReference={isCompareBy ? 'anchorPosition' : 'anchorEl'}
          anchorPosition={isCompareBy && isViewer ? { top: 390, left: 930 } : { top: 405, left: 35 }}
          anchorOrigin={{
            vertical: isCompareBy ? 'top' : 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: isCompareBy ? 'center' : 'top',
            horizontal: 'left',
          }}
          style={{ maxWidth: '450px' }}
        >
          <div className={isMobile ? 'mobilePickerHeight' : ''}>
            <Tabs
              classes={{ indicator: 'indicator-color' }}
              value={value}
              onChange={this.handleTabChange}
              id="tabs-unit-test"
              variant="scrollable"
              scrollButtons="auto"
            >
              {tabOptions.map(
                (name, index) =>
                  ((!isCompareBy && name === 'Custom') || name !== 'Custom') && (
                    <Tab id={`tab-name-${index}`} key={`name-${index}`} label={name} />
                  )
              )}
            </Tabs>
            {this.renderDateFilter()}
          </div>
        </Popover>
      </div>
    )
  }
}

SecondaryTimePeriod.defaultProps = {
  cardInfo: {},
  dateFilterStatus: {},
  isDashboard: false,
  dashboardInfo: null,
  selectedDataset: {},
  onTimeJsonChange: () => {},
  deviceType: [],
  isCompareBy: false,
  changeIndex: -1,
  compareByValue: '',
  anchorDateFilterStatus: {},
  getAnchorDateFilter: () => {},
}

SecondaryTimePeriod.propTypes = {
  fiscalYear: PropTypes.bool,
  secondaryFiscalYear: PropTypes.bool,
  dateFilterStatus: PropTypes.object,
  cardInfo: PropTypes.object,
  pendingCardUid: PropTypes.string,
  selectedDataset: PropTypes.object,
  isDashboard: PropTypes.bool,
  dashboardInfo: PropTypes.object,
  modifyDashboardInfo: PropTypes.func,
  isViewer: PropTypes.bool,
  viewerTimeEvent: PropTypes.func,
  onTimeJsonChange: PropTypes.func,
  viewerJson: PropTypes.object,
  isCompareBy: PropTypes.bool,
  changeIndex: PropTypes.number,
  compareByValue: PropTypes.string,
  callRenderCardWithCompareBy: PropTypes.func,
  anchorDateFilterStatus: PropTypes.object,
  getAnchorDateFilter: PropTypes.func,
}

export default SecondaryTimePeriod
