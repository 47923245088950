import apiConfig, { API_GATEWAY_URL_STG } from '../config/apiConfig'
import { API_GATEWAY_URL } from '../ducks/utils'

// https://confluence.target.com/pages/viewpage.action?spaceKey=BF&title=Firefly+Legacy+to+consumers-v1+endpoint+Migration+Quick+Guide
const ANALYTICS_ROUTE = 'consumers/v1/ingest/internal/internal_app'

// fallback to API_GATEWAY_URL_STG if in shortstacks env where API_GATEWAY_URL is empty string
const ANALYTICS_URL = API_GATEWAY_URL || API_GATEWAY_URL_STG

const eventManagerConfiguration = {
  url: `${ANALYTICS_URL}/${ANALYTICS_ROUTE}`,
  apiKey: apiConfig.analyticsApiKey,
  eventManagerKey: 'greenfield',
  appName: 'greenfield',
}

export default eventManagerConfiguration
