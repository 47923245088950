import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Spinner } from 'greenfield-utilities'
import { Card, CardContent, Typography, Icon, IconButton, Tooltip } from '@mui/material'
import { OpenInNew, Edit, Warning } from '@mui/icons-material'
import classnames from 'classnames'

import urlon from 'urlon'
import styled from 'styled-components'

import CardViewer from '../../routes/Cards/Cardviewer/Cardviewer.container'
import { TEXT_VIZ_TYPES, TABLE_VIZ_TYPES } from '../../constants/ArrayConstants'
import AccessDeniedViewOwner from '../AccessDeniedViewOwner'

const SSpinner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`
const CardPreview = props => {
  const { cards, cardId, isHomePage } = props
  const vizType =
    cards &&
    cards[cardId] &&
    cards[cardId].status >= 200 &&
    cards[cardId].status < 300 &&
    cards[cardId].data &&
    cards[cardId].data.card_config
      ? cards[cardId].data.card_config.card_attribute.viz_type
      : ''

  const [isHover, setIsHover] = useState(false)

  let focusOnExpand = null
  let cardTopRef = null
  const isSite = /^\/site\//i.test(window.location.pathname)
  let siteURL = ''
  if (props.routeProps.match.params._siteName) {
    siteURL = `/site/${props.routeProps.match.params._siteName}`
  }

  const unFavoriteCard = (e, data) => {
    e.preventDefault()

    props.updateFavorite({
      object_type: 'cards',
      object_id: data.card_config._id,
      is_favorite: 'no',
    })
    if (props.onUnfavorite) {
      props.onUnfavorite(data.card_config._id)
    } else {
      window.location.reload(1)
    }
  }

  const getHeaderStyle = data => {
    const style = {}

    if (data?.card_config?.card_attribute?.style) {
      style.color = data.card_config.card_attribute.style.headerFontColor
      style.fontSize = `${data.card_config.card_attribute.style.headerFontSize}px`
      style.fontFamily = data.card_config.card_attribute.style.headerFont
    }
    return style
  }

  const renderIconTextCard = cardData => {
    const { data } = cardData
    const { _id } = data.card_config
    const { isMobile, cardType, showFavIcon, isHomePage } = props
    let renderLink = []
    /* eslint-disable camelcase */
    const hideTitleDisplay =
      TEXT_VIZ_TYPES.includes(data?.card_config?.card_attribute?.viz_type) &&
      Boolean(data?.card_config?.card_attribute?.style?.headerHideCardTitle && !isHomePage)
    const cardTitle = data?.card_config?.card_attribute?.card_title
    /* eslint-enable camelcase */

    const showTitleAndStar = (
      <>
        {!hideTitleDisplay && isHomePage && (
          <Typography noWrap className="cardHeader" variant="h3">
            {showFavIcon && renderFavIcon(cardData)}
            <span className={`cardHeaderText ${showFavIcon ? 'padding-top' : ''}`} style={getHeaderStyle(data)}>
              {cardTitle}
            </span>
          </Typography>
        )}
      </>
    )

    if ((data && isHover) || (data && isMobile)) {
      renderLink = (
        <div>
          <IconButton
            className="cardPreviewLink expand-icon"
            id={`card-preview-link-${_id}`}
            target={isMobile ? '_self' : '_blank'}
            aria-label="card opens in new tab"
            onBlur={() => setIsHover(false)}
            onKeyDown={setFocusOnCard}
            component={Link}
            to={
              isSite && siteURL
                ? `${siteURL}/${cardType?.toLowerCase() === 'text' ? 'textcard' : 'card'}/${_id}`
                : `/${cardType?.toLowerCase() === 'text' ? 'textcard' : 'card'}/${_id}`
            }
            tabIndex={-1}
          >
            <OpenInNew className="open-icon" />
          </IconButton>
          <span
            tabIndex={-1}
            ref={innerRef => {
              focusOnExpand = innerRef
            }}
          />
        </div>
      )
    }

    return (
      <>
        {renderLink}
        {showTitleAndStar}
      </>
    )
  }

  const renderFailedIconTextCard = cardData => {
    const { data } = cardData
    const { isMobile, cardType, cardId } = props

    if (data && isHover) {
      return (
        <>
          <Link
            className="cardPreviewLink expand-icon"
            onBlur={() => setIsHover(false)}
            onKeyDown={setFocusOnCard}
            aria-label="card opens in new tab"
            target={isMobile ? '_self' : '_blank'}
            to={
              isSite && siteURL
                ? `${siteURL}/${cardType?.toLowerCase() === 'text' ? 'textcard' : 'card'}/${cardId}`
                : `/${cardType?.toLowerCase() === 'text' ? 'textcard' : 'card'}/${cardId}`
            }
            id={`card-preview-link-${cardId}`}
          >
            <IconButton tabIndex={-1}>
              <OpenInNew className="open-icon" />
            </IconButton>
          </Link>
          <span
            ref={innerRef => {
              focusOnExpand = innerRef
            }}
          />
        </>
      )
    }
  }

  const setFocusOnExpand = event => {
    if (event.shiftKey && event.keyCode === 9 && focusOnExpand) {
      focusOnExpand.focus()
    }
  }

  const setFocusOnCard = event => {
    if (event.shiftKey && event.keyCode === 9) {
      focusOnExpand = null
      cardTopRef.focus()
    }
  }

  const renderCardHeader = cardData => {
    const { data } = cardData
    const { _id } = data.card_config
    const datasetId = data?.card_config?.card_attribute?.dataset_id
    const { cardId, routeProps, showFavIcon, isMobile, cardType, isHomePage, getNotificationCountStatus } = props

    let hideTitleDisplay = false
    // eslint-disable-next-line camelcase
    if (data?.card_config?.card_attribute?.style) {
      hideTitleDisplay = Boolean(data.card_config.card_attribute.style.headerHideCardTitle && !isHomePage)
    }

    const urlState = `${routeProps.location.search}${routeProps.location.hash}`
    let stringifiedUrlFilters = ''

    // If there's dashboard filters in the url, apply them to the url to be clicked on for the card popout -> new tab cardviewer
    if (urlState) {
      const processedUrlState = urlState.slice(1, urlState.length)

      try {
        const decodedUrlState = processedUrlState ? urlon.parse(processedUrlState) : null
        const urlFilters = decodedUrlState.filters
        const urlTimePeriod =
          decodedUrlState.timePeriod && Object.keys(decodedUrlState.timePeriod).length > 1
            ? decodedUrlState.timePeriod
            : ''
        let filtersToApplyOnClick

        if (urlFilters && urlFilters[0] && urlFilters[0].cardFilters) {
          filtersToApplyOnClick = urlFilters.reduce((acc, curr) => {
            const cardFilter = curr.cardFilters.find(filt => filt.cardId && filt.cardId.toString() === cardId)

            if (cardFilter) {
              acc.push({
                dimension: cardFilter.filter.d || cardFilter.filter.dimension,
                type: cardFilter.filter.t || cardFilter.filter.type,
                pattern: cardFilter.filter.p || cardFilter.filter.pattern,
              })
            }

            return acc
          }, [])
        }

        if (filtersToApplyOnClick || urlTimePeriod) {
          stringifiedUrlFilters = urlon.stringify({
            filters: filtersToApplyOnClick,
            ...(data?.card_config?.card_query_attribute?.time_period?.hide_time_period_viewer
              ? {}
              : { timePeriod: urlTimePeriod }),
          })
        }
      } catch (e) {
        // If its not structured properly catch the error and don't append a filter
        // eslint-disable-next-line no-console
        console.log(e, 'error in parsing URL for cardpreview link to click')
      }
    }

    const style = data?.card_config.card_attribute.style || {}
    const { headerFontTextWrap } = style
    const setHeaderBackgroundColor = () => {
      if (TABLE_VIZ_TYPES.includes(vizType)) {
        return style.headerBackgroundColor
      }
      return 'inherit'
    }

    if (data) {
      return (
        <div>
          <div className="cardHeaderTextContainer" style={{ background: setHeaderBackgroundColor() }}>
            {!hideTitleDisplay && (
              <Typography noWrap={!headerFontTextWrap} className="cardHeader" variant="h3">
                {showFavIcon ? renderFavIcon(cardData) : null}
                <span
                  data-cy={`card-preview-title-${_id}`}
                  className={`cardHeaderText ${showFavIcon ? 'padding-top' : ''}`}
                  style={getHeaderStyle(data)}
                >
                  {!isMobile &&
                    getNotificationCountStatus?.status >= 200 &&
                    getNotificationCountStatus?.status < 300 &&
                    (getNotificationCountStatus?.data[Number(cardId)] > 0 ||
                      getNotificationCountStatus?.data[Number(datasetId)] > 0) &&
                    renderNotifications(
                      getNotificationCountStatus?.data[Number(cardId)],
                      getNotificationCountStatus?.data[datasetId],
                      cardId
                    )}
                  {data.card_config.card_attribute.card_title}{' '}
                </span>
              </Typography>
            )}

            {hideTitleDisplay &&
              !isMobile &&
              getNotificationCountStatus?.status >= 200 &&
              getNotificationCountStatus?.status < 300 &&
              (getNotificationCountStatus?.data[Number(cardId)] > 0 ||
                getNotificationCountStatus?.data[Number(datasetId)] > 0) && (
                <div className="hiddenTitleNotificationIcon">
                  {renderNotifications(
                    getNotificationCountStatus?.data[Number(cardId)],
                    getNotificationCountStatus?.data[datasetId],
                    cardId
                  )}
                </div>
              )}

            {(isMobile || (isHover && !isMobile)) && (
              <>
                <Link
                  tabIndex={0}
                  className="cardPreviewLink expand-icon"
                  onBlur={() => setIsHover(false)}
                  onKeyDown={setFocusOnCard}
                  aria-label="card opens in new tab"
                  target={isMobile ? '_self' : '_blank'}
                  to={
                    isSite && siteURL
                      ? `${siteURL}/${
                          cardType?.toLowerCase() === 'text' ? 'textcard' : 'card'
                        }/${_id}?${stringifiedUrlFilters}`
                      : `/${cardType?.toLowerCase() === 'text' ? 'textcard' : 'card'}/${_id}?${stringifiedUrlFilters}`
                  }
                  id={`card-preview-link-${_id}`}
                >
                  <IconButton tabIndex={-1}>
                    <OpenInNew className="open-icon" />
                  </IconButton>
                </Link>
                {data?.card_config?.card_attribute?.edit_enabled === 'yes' && (
                  <Link
                    tabIndex={0}
                    className="cardPreviewLink edit-icon"
                    onBlur={() => setIsHover(false)}
                    onKeyDown={setFocusOnCard}
                    target={isMobile ? '_self' : '_blank'}
                    id={`card-edit-link-${_id}`}
                    to={
                      isSite && siteURL
                        ? `${siteURL}/builder/${cardType?.toLowerCase() === 'text' ? 'textcard' : 'card'}/${_id}`
                        : `/builder/${cardType?.toLowerCase() === 'text' ? 'textcard' : 'card'}/${_id}`
                    }
                  >
                    <IconButton id="card-thumbnail-edit">
                      <Edit className="open-icon" />
                    </IconButton>
                  </Link>
                )}
              </>
            )}
            <span
              tabIndex={-1}
              ref={innerRef => {
                focusOnExpand = innerRef
              }}
              className={hideTitleDisplay && vizType === 'dynamic_text' ? 'hover-button-margin' : ''}
            />
          </div>
        </div>
      )
    }
  }

  const renderFavIcon = cardData => {
    const { data } = cardData
    const isFav = data.card_config.card_attribute.favorite === 'no'

    return (
      <Icon style={{ cursor: 'pointer' }} className="cardHeaderStarIcon" onClick={e => unFavoriteCard(e, data)}>
        {!isFav ? 'star' : 'star_border'}
      </Icon>
    )
  }

  const cardProcessRouter = () => {
    const statusCode = [400, 403, 404, 500, 503]
    const { cards, cardId } = props

    if (cards && Object.prototype.hasOwnProperty.call(cards, cardId)) {
      const card = cards[cardId]
      if (card && card.status >= 200 && card.status < 300) {
        return renderCard(card)
      } else if (card && statusCode.includes(card.status)) {
        return renderFailedCard(card)
      }
    }

    return (
      <SSpinner>
        <Spinner />
      </SSpinner>
    )
  }

  const renderFailedCard = cardData => {
    const { data } = cardData

    if (cardData && (cardData.status < 200 || cardData.status >= 300)) {
      return (
        <CardContent className="cardFailMessage">
          <span
            ref={ref => {
              cardTopRef = ref
            }}
          />
          <div
            role="button"
            tabIndex={0}
            className="cardPreviewErrorCont"
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
            onFocus={() => setIsHover(true)}
            onKeyDown={setFocusOnExpand}
          >
            <Icon className="notFoundIcon">not_interested</Icon>
            {renderFailedIconTextCard(cardData)}
            <Typography className="error-description">{data.message}</Typography>
            <div className="error-description">
              <AccessDeniedViewOwner errorData={data} />
            </div>
          </div>
        </CardContent>
      )
    }
  }

  const renderMainContent = data => {
    const { _id } = data.data.card_config
    const { cards, cardId, cardFilterApplied } = props
    const cardConfig =
      cards &&
      cards[cardId] &&
      cards[cardId].status >= 200 &&
      cards[cardId].status < 300 &&
      cards[cardId].data &&
      cards[cardId].data.card_config
        ? cards[cardId].data.card_config
        : ''

    const urlState = {
      activeDrillthroughIndex: 0,
    }

    if (cardFilterApplied && cardFilterApplied.length) {
      urlState.filters = cardFilterApplied
    }

    const stringifiedUrlState = urlon.stringify(urlState)

    return cardConfig &&
      cardConfig.card_attribute.viz_type === 'big_number' &&
      cardConfig.card_query_attribute.drillthrough?.length ? (
      <a
        href={
          cardConfig.card_query_attribute.drillthrough[0].dashboard_id
            ? `/dashboard/${cardConfig.card_query_attribute.drillthrough[0].dashboard_id}`
            : `/card/${_id}/?${stringifiedUrlState}`
        }
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: 'none' }}
      >
        <CardViewer
          cardId={_id}
          nonRoute
          thumbnail
          pagination={false}
          data={data.data}
          print={props.print}
          cardSize={{ height: props.cardSizeHeight, width: props.cardSizeWidth }}
          isHomePage={props.isHomePage}
          isMobile={props.isMobile}
          routeProps={props.routeProps}
        />
      </a>
    ) : (
      <CardViewer
        cardId={_id}
        nonRoute
        thumbnail
        pagination={false}
        data={data.data}
        print={props.print}
        cardSize={{ height: props.cardSizeHeight, width: props.cardSizeWidth }}
        isHomePage={props.isHomePage}
        isMobile={props.isMobile}
        routeProps={props.routeProps}
        timePeriod={props.timePeriodValue}
      />
    )
  }

  const renderNotifications = (card, dataset, cardId) => {
    return (
      <Tooltip title={`${card + dataset} Notifications`} aria-label={`${card + dataset} Notifications`}>
        <Warning
          data-cy={`card-preview-notification-${cardId}`}
          color="error"
          className="notificationCardPreviewIcon"
        />
      </Tooltip>
    )
  }

  const renderCard = cardData => {
    const vizType = cardData.data.card_config.card_attribute.viz_type.toLowerCase()
    const style = cardData.data.card_config.card_attribute.style || {}
    const { _id } = cardData.data.card_config
    const setBackgroundColor = () => {
      if (TABLE_VIZ_TYPES.includes(vizType)) {
        return style.tableBackgroundColor
      }
      if (vizType === 'text') {
        return style.textCardBackgroundColor
      }
      return 'inherit'
    }
    return (
      <CardContent className="padding-0 cardPreviewMobileHook" style={{ height: '100%' }}>
        <span
          tabIndex={-1}
          ref={ref => {
            cardTopRef = ref
          }}
        />
        <div
          role="button"
          tabIndex={0}
          style={{ background: setBackgroundColor() }}
          className={classnames({
            cardPreviewPrintBreak: vizType !== 'sparkline',
            cardPreviewPrintBreakSparkline: vizType === 'sparkline',
          })}
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
          onFocus={() => setIsHover(true)}
          onKeyDown={setFocusOnExpand}
          data-cy={`card-preview-container-${_id}`}
        >
          {vizType?.toLowerCase() !== 'text' ? renderCardHeader(cardData) : renderIconTextCard(cardData)}
          {renderMainContent(cardData)}
        </div>
      </CardContent>
    )
  }

  return vizType === 'big_number' && !isHomePage ? (
    <div className="cardPreview">{cardProcessRouter()}</div>
  ) : (
    <Card className="cardPreview">{cardProcessRouter()}</Card>
  )
}

CardPreview.defaultProps = {
  print: false,
  isHomePage: false,
}

export default CardPreview
