import React from 'react'
import axios from 'axios'
import { Email, Notifications, TrendingUp as TrendingUpIcon } from '@mui/icons-material'
import { Divider, Grid } from '@mui/material'
import startCase from 'lodash/startCase'
import styled from 'styled-components'
import urlon from 'urlon'
import isEqual from 'lodash/isEqual'
import cloneDeep from 'lodash/cloneDeep'
import { displayServiceErrorMessage } from '../../../../../ducks/layout'
import { API_GATEWAY_URL } from '../../../../../ducks/utils'

export const DATA_ALERT_STEPS = ['Add Details', 'Configure', 'Message']
export const SUBSCRIPTION_STEPS = ['Add Details', 'Message']

export const DATA_ALERT_ICONS = {
  0: <Notifications />,
  1: <TrendingUpIcon />,
  2: <Email />,
}

export const SUBSCRIPTION_ICONS = {
  0: <Notifications />,
  1: <Email />,
}

export const getCurrentMetricColumn = (allMetricFilters, columnDisplayName) =>
  allMetricFilters.find(col => col.column_display_name === columnDisplayName)

export const getEmailCriteriaText = ({ allMetricFilters = [], appliedMetricFilters = [] }) => {
  const helloText = '<p>Hello,</p>'
  const thresholdFilter = appliedMetricFilters
    .map(metric => {
      if (!isNaN(metric.value) || !isNaN(metric.lowervalue) || !isNaN(metric.uppervalue)) {
        const columnDisplayName = metric.column_display_name || metric.display_name
        const currentMetricColumn = getCurrentMetricColumn(allMetricFilters, columnDisplayName)
        const metricType = startCase(metric.type)

        const colType =
          currentMetricColumn?.format_type === 'currency_USD'
            ? '$'
            : currentMetricColumn?.format_type === 'percentage' ||
              currentMetricColumn?.format_type === 'percent_to_total'
            ? '%'
            : ''

        let metricValue = ''
        if (metric.type === 'between') {
          metricValue =
            currentMetricColumn?.format_type === 'currency_USD'
              ? `${colType}${metric.lowervalue.toLocaleString()} and ${colType}${metric.uppervalue.toLocaleString()}`
              : currentMetricColumn?.format_type === 'percentage'
              ? `${(metric.lowervalue * 100).toFixed(2)}${colType} and ${(metric.uppervalue * 100).toFixed(
                  2
                )}${colType}`
              : `${metric.lowervalue.toLocaleString()}${colType} and ${metric.uppervalue.toLocaleString()}${colType}`
        } else {
          metricValue =
            currentMetricColumn?.format_type === 'currency_USD'
              ? `${colType}${metric.value?.toLocaleString()}`
              : currentMetricColumn?.format_type === 'percentage'
              ? `${(metric.value * 100).toFixed(2)}${colType}`
              : `${metric.value.toLocaleString()}${colType}`
        }

        const currentMetricPlaceHolder = `(Current_${snakeCase(columnDisplayName)})`

        return `<p>${columnDisplayName} value is now <span contenteditable="false"><strong>${currentMetricPlaceHolder}</strong></span> which is ${metricType} configured threshold of <span contenteditable="false"><strong>${metricValue}</strong></span>.</p>`
      }

      return undefined
    })
    .join('')
  return `${helloText}${thresholdFilter}`
}

export const getExistingOwners = (userOwnersList, groupOwnersList) => {
  const userOwners = userOwnersList.map(owner => {
    return {
      user_group_name: owner.first_name === 'NonUser' ? owner._id : `${owner.first_name} ${owner.last_name}`,
      _id: owner._id,
      type: 'user',
    }
  })
  const groupOwners = groupOwnersList.map(owner => {
    return {
      user_group_name: owner.group_name,
      _id: owner._id,
      type: 'group',
    }
  })
  return [...userOwners, ...groupOwners]
}

export const getUniqueCardFilters = ({ appliedFilters = [], appliedMetricFilters = [] }) => {
  const appliedCardFilters = cloneDeep(appliedFilters)
  // Remove filter from card filters if same exists in threhsold filters
  appliedCardFilters.forEach((cardFilter, index) =>
    appliedMetricFilters.forEach(metricFilter => {
      if (metricFilter.display_name === cardFilter.display_name && isEqual(metricFilter, cardFilter)) {
        delete appliedCardFilters[index]
      }
    })
  )
  return [...appliedCardFilters.filter(item => item), ...appliedMetricFilters]
}

export const getCardFiltersURL = ({ appliedFilters = [], cardId = '' }) => {
  return appliedFilters.length
    ? `${location.origin}/card/${cardId}?$filters${urlon.stringify(appliedFilters)}`
    : `${location.origin}/card/${cardId}`
}

export const ButtonLink = styled.button`
  min-width: 0;
  text-decoration: underline;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 0.875rem;
  margin: 5px 5px 5px 0;
  min-height: 36px;
  color: #2f5ebc;
`
export const DividerWithText = ({ children, ...props }) => (
  <Grid container alignItems="center" spacing={3} {...props}>
    <Grid item xs>
      <Divider />
    </Grid>
    <Grid item>{children}</Grid>
    <Grid item xs>
      <Divider />
    </Grid>
  </Grid>
)

export const snakeCase = string => {
  return string
    .split(' ')
    .map(word => word.toLowerCase())
    .join('_')
}

export const getOwnerDetail = (isUser, owner, setOwner, setIsLoading, dispatch) => {
  axios
    .get(
      `${API_GATEWAY_URL}/greenfield_subscriptions/v1/details/${
        isUser ? `users?user_ids=${owner}` : `groups?group_ids=${owner}`
      }`
    )
    .then(response => {
      setOwner(isUser ? response.data.user_details : response.data.group_details)
      setIsLoading(false)
    })
    .catch(err => {
      setIsLoading(false)
      setOwner([])
      dispatch(displayServiceErrorMessage(err.message || 'Owner details get failed, please try again later!'))
    })
}
