import React from 'react'
import { Typography } from '@mui/material'

const About = () => (
  <div>
    <Typography type="body1">Welcome to Greenfield, Target&apos;s in-house Tool.</Typography>
    <Typography type="body1">Watch this space for some cool and exciting updates!!</Typography>
  </div>
)

export default About
