import React, { forwardRef } from 'react'
import { Dialog, IconButton, Typography, Link, Slide } from '@mui/material'
import { KeyboardBackspace } from '@mui/icons-material'
import { getBusinessInfoChip, getOwnerInfoChip } from '../../../shared/AgGrid/AgGridHelper'

export const CardDetailComponentMobile = ({ rowData, state, setState, isMobile }) => {
  const { mobileSubComponentActive } = state

  const Transition = forwardRef((props, ref) => <Slide direction="left" {...props} ref={ref} />)

  const handleCloseMobileSubComponent = () => {
    handleToggleMobileSubComponent(null)
  }

  const handleToggleMobileSubComponent = index => {
    if (isMobile) {
      setState({
        ...state,
        mobileSubComponentActive: !mobileSubComponentActive,
        mobileTableIndex: index,
      })
    }
  }

  return (
    <Dialog
      fullScreen
      open={mobileSubComponentActive}
      onClose={handleToggleMobileSubComponent}
      TransitionComponent={Transition}
      className="mobileListingViewDetailSlide isMobile"
    >
      <div className="mobileCardViewDetails">
        <IconButton onClick={handleCloseMobileSubComponent}>
          <KeyboardBackspace />
        </IconButton>

        <div className="viewDetailContent">
          <div className="mobileViewDetailCardName mobileViewDetailSection">
            <Typography className="expandLabel" variant="h3">
              Card:
            </Typography>
            <Typography className="expandValue">{rowData?.card_attribute.card_title}</Typography>
          </div>

          <div className="mobileViewDetailCardVizType mobileViewDetailSection">
            <Typography className="expandLabel" variant="h3">
              Viz type:
            </Typography>
            <Typography className="expandValue">{rowData?.card_attribute.viz_type}</Typography>
          </div>

          <div className="mobileViewDetailCardBusinessArea mobileViewDetailSection">
            <Typography className="expandLabel" variant="h3">
              Business Area:
            </Typography>
            {getBusinessInfoChip(rowData.card_attribute.business_use)}
          </div>

          <div className="mobileViewDetailOwner mobileViewDetailSection">
            <Typography className="expandLabel" variant="h3">
              Owner(s):
            </Typography>

            {rowData.card_attribute.owners_access && getOwnerInfoChip(rowData.card_attribute.owners_access)}
          </div>

          <div className="mobileViewDetailCardDataset mobileViewDetailSection">
            <Typography className="expandLabel" variant="h3">
              Dataset:
            </Typography>
            <Typography className="expandValue">
              <Link
                className="edit-card-link"
                href={`/dataset/preview/${rowData.card_attribute.dataset_id}/columns`}
                color="inherit"
              >
                {rowData.card_attribute.dataset_name}
              </Link>
            </Typography>
          </div>

          <div className="mobileViewDetailCardDataClass mobileViewDetailSection">
            <Typography className="expandLabel" variant="h3">
              Data Classification:
            </Typography>
            <Typography className="expandValue">{rowData.card_attribute.data_classification}</Typography>
          </div>

          <div className="mobileViewDetailCardCreateDate mobileViewDetailSection">
            <Typography className="expandLabel" variant="h3">
              Created Date:
            </Typography>
            <Typography className="expandValue">{rowData.card_attribute.create_date}</Typography>
          </div>

          <div className="mobileViewDetailCardLastUpdate mobileViewDetailSection">
            <Typography className="expandLabel" variant="h3">
              Last Updated:
            </Typography>
            <Typography className="expandValue">{rowData.card_attribute.last_updated_date}</Typography>
          </div>

          <div className="mobileViewDetailCardDescrip mobileViewDetailSection">
            <Typography className="expandLabel" variant="h3">
              Description:
            </Typography>
            <Typography className="expandValue">{rowData.card_attribute.card_description}</Typography>
          </div>
        </div>
      </div>
    </Dialog>
  )
}
