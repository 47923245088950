import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { getSite } from '../../../ducks/site'
import Site from './Site'

const SiteContainer = ({ routeProps = {}, isMobile = false, deviceType, setHeaderTitle = () => {} }) => {
  const siteName = routeProps.match.params._siteName
  const dispatch = useDispatch()
  const [isMounted, setIsMounted] = useState(false)

  useEffect(() => {
    if (siteResponse && Object.keys(siteResponse).length === 0) {
      dispatch(getSite(siteName))
    }
    setIsMounted(true)
  }, [])
  const siteResponse = useSelector(state => {
    if (isMounted) {
      return state.site.siteStatus
    }
  }, [])

  return (
    <Site
      setHeaderTitle={setHeaderTitle}
      routeProps={routeProps}
      isMobile={isMobile}
      deviceType={deviceType}
      siteObj={siteResponse}
    />
  )
}

SiteContainer.propTypes = {
  routeProps: PropTypes.object,
  setHeaderTitle: PropTypes.func,
  isMobile: PropTypes.bool,
  deviceType: PropTypes.array,
}
export default SiteContainer
