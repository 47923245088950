import { call, put } from 'redux-saga/effects'
import cloneDeep from 'lodash/cloneDeep'
import axios from 'axios'
import { API_GATEWAY_URL } from './utils'

export const FETCH_BASEMAP_REQUESTED = 'FETCH_BASEMAP_REQUESTED'
export const FETCH_BASEMAP_SUCCEEDED = 'FETCH_BASEMAP_SUCCEEDED'
export const FETCH_BASEMAP_FAILED = 'FETCH_BASEMAP_FAILED'

export const FETCH_ALLBASEMAPS_REQUESTED = 'FETCH_ALLBASEMAPS_REQUESTED'
export const FETCH_ALLBASEMAPS_SUCCEEDED = 'FETCH_ALLBASEMAPS_SUCCEEDED'
export const FETCH_ALLBASEMAPS_FAILED = 'FETCH_ALLBASEMAPS_FAILED'

export const UPDATE_BASEMAP_CARDIDS = 'UPDATE_BASEMAP_CARDIDS'

// reducer
export default function mapsReducer(state = {}, action = {}) {
  switch (action.type) {
    case FETCH_BASEMAP_REQUESTED: {
      const newState = {
        ...state,
        basemapStatus: {
          ...state.basemapStatus,
          [action.payload.basemapId]: {
            status: 'requested',
            cardIds: [action.payload.cardId],
          },
        },
      }
      return newState
    }

    case FETCH_BASEMAP_SUCCEEDED: {
      const cardIds = state.basemapStatus[action.payload.basemapId].cardIds
      const newState = {
        ...state,
        basemapStatus: {
          ...state.basemapStatus,
          [action.payload.basemapId]: { basemap: action.payload.basemap, cardIds: [...cardIds] },
        },
      }
      return newState
    }

    case FETCH_BASEMAP_FAILED: {
      const newState = {
        ...state,
        basemapStatus: {
          status: 'failed',
          message: action.payload.response,
        },
      }
      return newState
    }

    case FETCH_ALLBASEMAPS_REQUESTED: {
      const newState = {
        ...state,
        basemapsStatus: {
          status: 'requested',
        },
      }
      return newState
    }

    case FETCH_ALLBASEMAPS_SUCCEEDED: {
      const newState = {
        ...state,
        basemapsStatus: action.payload,
      }
      return newState
    }

    case FETCH_ALLBASEMAPS_FAILED: {
      const newState = {
        ...state,
        basemapsStatus: {
          status: 'failed',
          message: action.payload.response,
        },
      }
      return newState
    }

    case UPDATE_BASEMAP_CARDIDS: {
      const newState = {
        ...state,
        basemapStatus: {
          ...state.basemapStatus,
          [action.payload.basemapId]: { ...action.payload.data },
        },
      }
      return newState
    }

    default:
      return state
  }
}

// actions
export function getBasemap(data) {
  return {
    type: FETCH_BASEMAP_REQUESTED,
    payload: data,
  }
}

// actions
export function getBasemaps(data) {
  return {
    type: FETCH_ALLBASEMAPS_REQUESTED,
    payload: data,
  }
}
export function updateBasemapCardIds(data) {
  const { basemapId, basemapStatus, cardId } = data
  const _basemapStatus = cloneDeep(basemapStatus[basemapId])
  _basemapStatus.cardIds.push(cardId)
  return {
    type: UPDATE_BASEMAP_CARDIDS,
    payload: { basemapId, data: _basemapStatus },
  }
}

// sagas
export function* fetchBasemap(action) {
  const basemapId = action.payload.basemapId || null
  const cardId = action.payload.cardId || null
  try {
    const basemap = yield call(axios, {
      method: 'get',
      url: `${API_GATEWAY_URL}/bi_reporting_assets/v2/basemaps/${basemapId}`,
    })

    if (basemap.error) {
      const e = basemap.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }

    yield put({ type: 'FETCH_BASEMAP_SUCCEEDED', payload: { basemap, basemapId, cardId } })
  } catch (e) {
    yield put({ type: 'FETCH_BASEMAP_FAILED', payload: e })
  }
}

// sagas
export function* fetchAllBasemaps() {
  try {
    const basemaps = yield call(axios, {
      method: 'get',
      url: `${API_GATEWAY_URL}/bi_reporting_assets/v2/basemaps`,
    })

    if (basemaps.error) {
      const e = basemaps.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }

    yield put({ type: 'FETCH_ALLBASEMAPS_SUCCEEDED', payload: basemaps })
  } catch (error) {
    yield put({ type: 'FETCH_ALLBASEMAPS_FAILED', payload: error })
  }
}
