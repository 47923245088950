import { Alert, Grid, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getAllSubscriptions, getAllSubscriptionsByCardId } from '../../../ducks/dataAlertAndSubscription'
import { displayServiceErrorMessage } from '../../../ducks/layout'
import AdvanceFilter from '../../shared/AdvanceFilter/AdvanceFilter'
import { ACTIVE } from '../../shared/AssetTableSS/StatusFilters'
import { getSubscriptionsPayload } from '../Cards/Cardviewer/DataAlertAndSubscription/DataAlertSubscriptionListing'
import ContentTable from '../Profile/ContentTable'

const DataAlertNotificationListing = ({ routeProps, isMobile, trackEvent }) => {
  const dispatch = useDispatch()
  const [notifications, setNotifications] = useState(null)
  const [cardId, setCardId] = useState(null)
  const [error, setError] = useState('')
  const [searchText, setSearchText] = useState('')
  const [statusType, setStatusType] = useState(ACTIVE)
  const cardNotificationsResponse = useSelector(state => state.dataAlertAndSubscription.subscriptionsListByCardStatus)
  const allNotificationsResponse = useSelector(state => state.dataAlertAndSubscription.subscriptionsListStatus)

  useEffect(() => {
    if (cardId) {
      dispatch(getAllSubscriptionsByCardId(getSubscriptionsPayload(statusType, cardId)))
    } else {
      dispatch(getAllSubscriptions(getSubscriptionsPayload(statusType)))
    }
  }, [cardId, statusType])

  useEffect(() => {
    if (cardNotificationsResponse?.data) {
      setNotifications(cardNotificationsResponse?.data)
    } else if (cardNotificationsResponse?.status === 'failed') {
      dispatch(
        displayServiceErrorMessage(
          cardNotificationsResponse?.error?.resp?.data?.statusText || 'Card Subscriptions failed to render'
        )
      )
    }
  }, [cardNotificationsResponse])

  useEffect(() => {
    if (allNotificationsResponse?.data) {
      setNotifications(allNotificationsResponse?.data)
    } else if (allNotificationsResponse?.status === 'failed') {
      dispatch(
        displayServiceErrorMessage(
          cardNotificationsResponse?.error?.resp?.data?.statusText || 'Card Subscriptions failed to render'
        )
      )
    }
  }, [allNotificationsResponse])

  const handleSearchChange = event => {
    setSearchText(event.target.value)
  }

  const handleSearchClick = () => {
    if (Number(searchText)) {
      setCardId(searchText)
      setError('')
    } else {
      setError('Enter valid card Id')
    }
  }

  const handleOnSearchKeyDown = e => {
    if (e.key === 'Enter') {
      if (Number(searchText)) {
        setCardId(searchText)
        setError('')
      } else {
        setError('Enter valid card Id')
      }
    }
  }

  return (
    <Grid container direction="column">
      <Grid item>
        <AdvanceFilter
          style={{ width: 400, marginTop: '1rem', marginBottom: '1rem' }}
          className="search-notifications"
          label="Search Notifications By Card ID"
          onSearchChange={handleSearchChange}
          onSearchClick={handleSearchClick}
          onKeyDown={handleOnSearchKeyDown}
          searchValue={searchText}
          id="search-notifications"
          isSmall
          data-cy="search-notifications"
        />
        {error && <Alert severity="error">{error}</Alert>}
      </Grid>
      <Grid item>{cardId && <Typography variant="subtitle1">Showing results for Card ID: {cardId}</Typography>}</Grid>
      <Grid item>
        <ContentTable
          chipInfo={notifications || []}
          setSubscriptions={setNotifications}
          chipTitle="Subscription"
          routeProps={routeProps}
          trackEvent={trackEvent}
          isMobile={isMobile}
          isShowCardNotifications={Boolean(cardId)}
          statusType={statusType}
          setStatusType={setStatusType}
        />
      </Grid>
    </Grid>
  )
}

export default DataAlertNotificationListing
