import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import {
  Button,
  Popover,
  Typography,
  IconButton,
  Select,
  MenuItem,
  OutlinedInput,
  FormControl,
  List,
  ListItem,
  ListItemText,
  Icon,
  Tabs,
  Tab,
} from '@mui/material'
import { Close, Edit } from '@mui/icons-material'
import classNames from 'classnames'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import SwipeableViews from 'react-swipeable-views'
import orderBy from 'lodash/orderBy'
import cloneDeep from 'lodash/cloneDeep'

import { Spinner } from 'greenfield-utilities'

import { GreenfieldAutoComplete } from '../../shared/shared'
import SecCtrlColumnsMapper from './SecCtrlColumnsMapper'
import ColumnSecurityAdd from './ColumnSecurityAdd'

function SecurityControl(props) {
  const [securityControl, setSecurityControl] = useState('None')
  const [currentSecurityControl, setCurrentSecurityControl] = props.currentSecurityControl
    ? useState(cloneDeep(props.currentSecurityControl))
    : useState({})
  const [userTypeExemption, setUserTypeExemption] = useState(['None'])
  const [groupExemptions, setGroupExemptions] = useState([])
  const [securityControlList, setSecurityControlList] = useState(props.securityControlList)
  const [requiredColumns, setRequiredColumns] = useState([])
  const [dimensionCols] = useState(props.dimCols)
  const [showSecurityControlPopover, setShowSecurityControlPopover] = useState(true)
  const [mappedColumns, setMappedColumns] = useState({})
  const [groupSuggestions, setGroupSuggestions] = useState([])
  const [showSpinner, setShowSpinner] = useState(false)
  const [existingUpdated, setExistingUpdated] = useState(false)
  const [activeContent, setActiveContent] = useState('sec_control')
  const [activeTab, setActiveTab] = useState(0)
  const [columnSecurityMetrics, setColumnSecurityMetrics] = useState([])
  const [columnSecurity, setColumnSecurity] = useState([{}])
  const [isEditMode, setIsEditMode] = useState(false)
  const [isUpdated, setIsUpdated] = useState(false)
  const [edittingControlIndex, updateEdittingControlIndex] = useState(-1)

  useEffect(() => {
    const { metricDimCols } = props
    const columnSecurityMetricsClone = []
    metricDimCols.forEach(col => {
      if (col.data_classification === 'Confidential') {
        const obj = {
          fieldName: col.field_name,
          user: filterUserType(col.viewers_access, 'user'),
          group: filterUserType(col.viewers_access, 'group'),
          dataClassification: col.data_classification,
          label: col.viewers_access ? col.viewers_access.map(viewer => viewer.user_group_name || viewer.label) : [],
          viewersAccess: cloneDeep(col.viewers_access),
          column: col,
        }
        columnSecurityMetricsClone.push(obj)
      }
    })
    setColumnSecurityMetrics(columnSecurityMetricsClone)
  }, [])

  useEffect(() => {
    const {
      securityControlList,
      securityControlDetail,
      updateDatasetStatus,
      groupSearchDetail,
      showPopover,
      securityControlObj,
      singleGroupDetail,
      currentSecurityControl,
    } = props
    const groupExemptionIdx =
      currentSecurityControl &&
      currentSecurityControl.exemptions &&
      currentSecurityControl.exemptions.findIndex(exempt => exempt.type === 'group_membership')
    const groupExemptionDetails =
      currentSecurityControl &&
      currentSecurityControl.exemptions &&
      currentSecurityControl.exemptions[groupExemptionIdx] &&
      currentSecurityControl.exemptions[groupExemptionIdx]

    setShowSecurityControlPopover(showPopover)
    if (securityControlList && securityControlList.status >= 200 && securityControlList.status <= 300) {
      setSecurityControlList(securityControlList)
    }

    if (securityControlDetail && securityControlDetail.status === 'requested') {
      setShowSpinner(true)
    }

    if (!showPopover && updateDatasetStatus && updateDatasetStatus.status >= 200 && updateDatasetStatus.status <= 300) {
      setShowSecurityControlPopover(false)
      props.setEditable()
    }

    if (groupSearchDetail && groupSearchDetail.data && groupSearchDetail.data.length) {
      setGroupSuggestions(
        groupSearchDetail.data.map(group => ({
          ...group,
          label: group.group_name,
        }))
      )
    } else if (!groupSearchDetail) {
      setGroupSuggestions([])
    }

    if (
      securityControlObj &&
      securityControlObj.security_control_id &&
      securityControlObj.security_control_id === securityControl &&
      existingUpdated &&
      singleGroupDetail &&
      singleGroupDetail.data &&
      groupExemptions &&
      (groupExemptions.length === 0 ||
        groupExemptions.findIndex(grpExempt => grpExempt._id === singleGroupDetail.data._id) === -1)
    ) {
      const grpExempts = cloneDeep(groupExemptions)
      grpExempts.push(singleGroupDetail.data)
      setGroupExemptions(grpExempts)
    }

    if (groupExemptionDetails) {
      setGroupExemptions(groupExemptionDetails)
    }

    if (
      securityControlObj &&
      (securityControlObj.security_control_id === securityControl ||
        (securityControlObj.security_control_id && securityControl === 'None'))
    ) {
      setSecurityControl(securityControlObj.security_control_id)

      if (securityControlObj.column_map) {
        setMappedColumns(securityControlObj.column_map)

        const reqCols = Object.keys(securityControlObj.column_map)
        if (reqCols && reqCols.length > 1) {
          setRequiredColumns(reqCols)
        }
      }
    } else {
      setMappedColumns({})
    }

    if (currentSecurityControl && currentSecurityControl.exemptions) {
      const userTypeExemptionIdx = currentSecurityControl.exemptions.findIndex(exempt => exempt.type === 'user_type')
      if (userTypeExemptionIdx > -1) {
        setUserTypeExemption(currentSecurityControl.exemptions[userTypeExemptionIdx].exemption_details)
      }
      setExistingUpdated(true)
    }

    if (
      securityControl !== 'None' &&
      securityControlDetail &&
      securityControlDetail.data &&
      securityControlDetail.data._id === securityControl &&
      securityControlDetail.status >= 200 &&
      securityControlDetail.status <= 300
    ) {
      setRequiredColumns(securityControlDetail.data.dataset_columns || [])
      setShowSpinner(false)
    }
  }, [props])

  const portalElement = document.createElement('div')
  portalElement.classList.add('dnd-portal-container')
  document.body.appendChild(portalElement)

  const PortalAwareDraggableItem = ({ provided, snapshot, control, index }) => {
    const { isDragging } = snapshot

    const children = (
      <div
        ref={provided.innerRef}
        style={{
          ...provided.draggableProps.style,
        }}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        className="portal-column"
      >
        <>
          <ListItem button className="chip-style">
            <ListItemText className="sec-cntrl-text" primary={control.security_control_id} />
            <IconButton
              id={`edit-security-ctrl${index}`}
              onClick={() => {
                handleEditSecCtrl(control, index)
              }}
              aria-label="Edit Security Control"
            >
              <Icon>edit</Icon>
            </IconButton>
            <IconButton
              id={`delete-security-ctrl${index}`}
              onClick={() => {
                handleDeleteSecCtrl(control)
              }}
              aria-label="Delete Security Control"
            >
              <Icon>delete</Icon>
            </IconButton>
            <IconButton
              id={`move-down-security-ctrl${index}`}
              onClick={() => {
                moveSecControl(control, 'down')
              }}
              aria-label="Move Column Down"
            >
              <Icon>arrow_downward</Icon>
            </IconButton>
            <IconButton
              id={`move-up-security-ctrl${index}`}
              onClick={() => {
                moveSecControl(control, 'up')
              }}
              aria-label="Move Column Up"
            >
              <Icon>arrow_upward</Icon>
            </IconButton>
          </ListItem>
        </>
        <div />
      </div>
    )

    return isDragging ? ReactDOM.createPortal(children, portalElement) : children
  }

  const selectedGroups = selections => {
    const currentSecurityControlObj = cloneDeep(currentSecurityControl)
    const exemptions = (currentSecurityControlObj && currentSecurityControlObj.exemptions) || []
    const groupTypeExemptionIdx = exemptions.findIndex(exempt => exempt.type === 'group_membership')
    const groupExemptionsObj = cloneDeep(groupExemptions)
    const addedGroups = groupExemptionsObj.groups || []
    let index = -1

    if (selections._id) {
      delete selections.label
      isNaN(selections) ? addedGroups.push(selections) : addedGroups.splice(selections, 1)
      setGroupExemptions(addedGroups)
      if (exemptions[groupTypeExemptionIdx]) {
        exemptions[groupTypeExemptionIdx].groups = addedGroups
        exemptions[groupTypeExemptionIdx].exemption_details = addedGroups.length
          ? addedGroups.map(group => group._id)
          : []
      }
      currentSecurityControlObj.exemptions = exemptions
      setCurrentSecurityControl(currentSecurityControlObj)
    } else if (Number.isInteger(selections)) {
      index = parseInt(selections, 10)
      addedGroups.splice(index, 1)
      setGroupExemptions(addedGroups)
      if (exemptions[groupTypeExemptionIdx]) {
        exemptions[groupTypeExemptionIdx].groups = addedGroups
        exemptions[groupTypeExemptionIdx].exemption_details = addedGroups.length
          ? addedGroups.map(group => group._id)
          : []
      }
      currentSecurityControlObj.exemptions = exemptions
      setCurrentSecurityControl(currentSecurityControlObj)
    }
  }

  const filterUserType = (usersArr, type) => {
    return (
      usersArr &&
      usersArr.reduce((accumulator, eachOwner) => {
        if (eachOwner.type === type) {
          accumulator.push(eachOwner._id)
        }
        return accumulator
      }, [])
    )
  }

  const renderAddNewSecControl = () => {
    return (
      <>
        <div className="flex-cols align-controls">
          <Typography variant="body1" className="sec-cntrl-label">
            Select security control
          </Typography>
          {securityControlList && securityControlList.data && (
            <FormControl variant="outlined">
              <Select
                variant="standard"
                value={securityControl}
                onChange={e => {
                  if (e.target.value !== 'None') {
                    props.getSecurityControlDetail(e.target.value)
                  } else {
                    setRequiredColumns([])
                  }
                  setSecurityControl(e.target.value)
                  setGroupExemptions([])
                  setUserTypeExemption(['None'])
                  setGroupSuggestions([])
                  setExistingUpdated(false)
                }}
                input={<OutlinedInput name="securityControlId" id="outlined-security-control-simple" />}
              >
                <MenuItem value="None" key="None">
                  None
                </MenuItem>
                {securityControlList.data.map((securityCtl, index) => (
                  <MenuItem
                    id={`security-values${index}`}
                    value={securityCtl.security_control_display_name}
                    key={securityCtl.security_control_display_name}
                  >
                    {securityCtl.security_control_display_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </div>
        {showSpinner && securityControl !== 'None' ? (
          <Spinner size="medium" layout="selfCentering" />
        ) : (
          Boolean(requiredColumns && requiredColumns.length) && (
            <SecCtrlColumnsMapper
              className="flex-rows"
              requiredColumns={requiredColumns}
              dimensionCols={dimensionCols}
              mappedColumns={mappedColumns}
              updateMappedColumns={setMappedColumns}
            />
          )
        )}
      </>
    )
  }

  const renderAdvancedOptions = () => {
    return (
      <div className="collapse-section flex-cols">
        <Typography variant="body1">
          Select Groups and/or User Types you want to exclude from Row Level Security
        </Typography>
        <div className="flex-rows adv-options-content">
          <Typography variant="body1">Groups</Typography>
          <div>
            <GreenfieldAutoComplete
              isEditable
              label="Add Groups"
              searchApi={props.getGroupSearchDetail}
              searchStatus={props.groupSearchDetail}
              suggestions={groupSuggestions}
              selectedSuggestions={selectedGroups}
              default={
                groupExemptions && groupExemptions.groups && groupExemptions.groups.length
                  ? groupExemptions.groups.map(group => group.user_group_name)
                  : []
              }
            />
          </div>
        </div>
        <div className="flex-rows adv-options-content">
          <Typography variant="body1">User Types</Typography>
          <FormControl variant="outlined" className="usertypes-exemptions-select">
            <Select
              variant="standard"
              value={userTypeExemption}
              onChange={e => setUserTypeExemption([e.target.value])}
              input={<OutlinedInput name="userTypes" id="outlined-user-types" labelWidth={0} />}
            >
              <MenuItem value="None">None</MenuItem>
              <MenuItem value="Internal">Internal</MenuItem>
              <MenuItem value="External">External</MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>
    )
  }

  const renderSavedControls = () => {
    return (
      <DragDropContext className="col-fn-height" onDragEnd={handleCtrlDragEnd}>
        <SwipeableViews>{renderDragAndDropControls()}</SwipeableViews>
      </DragDropContext>
    )
  }

  const renderDragAndDropControls = () => {
    let controls = currentSecurityControl && currentSecurityControl.controls

    controls = orderBy(controls, 'sort_index', 'asc')
    return (
      <List className="charts-selected-chips-container columns-section">
        <Droppable droppableId="table-column-droppable">
          {dropProvided => (
            <div {...dropProvided.droppableProps} className="draggable-column-container" ref={dropProvided.innerRef}>
              {controls.map((control, index) => (
                <React.Fragment key={control.security_control_id}>
                  <Draggable key={control.security_control_id} index={index} draggableId={control.security_control_id}>
                    {(provided, snapshot) => (
                      <PortalAwareDraggableItem
                        className={classNames('draggable-item', {
                          isDragging: snapshot.isDragging,
                        })}
                        control={control}
                        provided={provided}
                        snapshot={snapshot}
                        index={index}
                      />
                    )}
                  </Draggable>
                </React.Fragment>
              ))}

              {dropProvided.placeholder}
            </div>
          )}
        </Droppable>
      </List>
    )
  }

  const handleCtrlDragEnd = event => {
    const currentSecurityControlObj = cloneDeep(currentSecurityControl)
    const controls = currentSecurityControlObj && currentSecurityControlObj.controls
    if (!event.destination || event.destination.index === event.source.index) {
      return
    }

    if (controls && controls.length) {
      const control = controls.splice(event.source.index, 1)[0]
      controls.splice(event.destination.index, 0, control)
    }

    currentSecurityControlObj.controls = controls

    setCurrentSecurityControl(currentSecurityControlObj)
  }

  const handleEditSecCtrl = (control, index) => {
    updateEdittingControlIndex(index)
    const reqCols = Object.keys(control.column_map)
    setSecurityControl(control.security_control_id)

    setMappedColumns(control.column_map)

    if (reqCols && reqCols.length) {
      setRequiredColumns(reqCols)
    }
    setActiveContent('add_new_control')
  }

  const handleSaveClick = () => {
    switch (activeContent) {
      case 'add_new_control':
        return activeTab ? updateColumnSecurity() : updateSecurityControl()
      case 'adv_options':
        return updateExemptions()
      case 'sec_control':
        return activeTab ? saveColumnSecurity() : saveSecurityControl()
    }
  }

  const handleCancelClick = () => {
    setIsEditMode(false)
    switch (activeContent) {
      case 'add_new_control':
        setActiveContent('sec_control')
        break
      case 'adv_options':
        setActiveContent('sec_control')
        break
      case 'sec_control':
        setShowSecurityControlPopover(false)
        resetSecControl()
        props.togglePopover()
        props.setEditable()
        break
    }
  }

  const updateColumnSecurity = () => {
    const columnSecurityMetricsClone = cloneDeep(columnSecurityMetrics)
    columnSecurity.forEach(col => {
      const obj = {
        fieldName: col.fieldName,
        label: col.label,
        user: col.user,
        viewersAccess: col.viewersAccess,
        group: col.group,
        column: col.column,
        dataClassification: 'Confidential',
      }
      if ('index' in col) {
        columnSecurityMetricsClone[col.index] = obj
      } else {
        columnSecurityMetricsClone.push(obj)
      }
    })
    setActiveContent('sec_control')
    setIsUpdated(true)
    setColumnSecurityMetrics(columnSecurityMetricsClone)
  }

  const saveColumnSecurity = () => {
    const columns = columnSecurityMetrics.map(col => {
      const column = cloneDeep(col.column)
      if (col.dataClassification === 'Confidential') {
        column.user_view_access = col.user
        column.group_view_access = col.group
        column.data_classification = 'Confidential'
      } else {
        column.data_classification = 'Internal'
        if ('user_view_access' in column) {
          delete column.user_view_access
        }
        if ('group_view_access' in column) {
          delete column.group_view_access
        }
      }
      if ('viewers_access' in column) {
        delete column.viewers_access
      }
      column.column_name = column.field_name
      delete column.field_name
      return column
    })
    props.patchDataset({ _id: props.datasetId, columns })
    props.togglePopover()
  }

  const getIsSaveDisabled = () => {
    let isSaveDisabled = false
    const savedSecurityControl = props.currentSecurityControl
    switch (activeContent) {
      case 'add_new_control':
        isSaveDisabled =
          requiredColumns &&
          (Boolean(!requiredColumns.length) ||
            (mappedColumns && Object.keys(mappedColumns).length !== requiredColumns.length))
        break
      case 'sec_control':
        if (
          (currentSecurityControl && currentSecurityControl.controls) !==
          (savedSecurityControl && savedSecurityControl.controls)
        ) {
          isSaveDisabled = false
        } else {
          isSaveDisabled = true
        }
        break
    }
    return isSaveDisabled
  }

  const saveSecurityControl = () => {
    // Update Dataset with Security Control
    props.updateDatasetOnEdit({ _id: props.datasetId, security_control: currentSecurityControl })
    props.togglePopover()
  }

  const updateSecurityControl = () => {
    const currentSecurityControlObj = cloneDeep(currentSecurityControl)
    // Create the Update Security Control Object on Dataset
    const securityControls = (currentSecurityControlObj && currentSecurityControlObj.controls) || []
    const existingCntrlInd = securityControls.findIndex((control, index) => index === edittingControlIndex)
    // If existing control, update else add new to securityControls
    if (existingCntrlInd >= 0) {
      const existingSecCntrl = securityControls[existingCntrlInd]
      existingSecCntrl.column_map = mappedColumns
      existingSecCntrl.security_control_id = securityControl
    } else {
      securityControls.push({
        security_control_id: securityControl,
        column_map: mappedColumns,
      })
    }
    currentSecurityControlObj._id = props.datasetId
    currentSecurityControlObj.controls = securityControls

    setCurrentSecurityControl(currentSecurityControlObj)

    props.setEditable()

    setActiveContent('sec_control')
    resetSecControl()
    updateEdittingControlIndex(-1)
  }

  const updateExemptions = () => {
    const currentSecurityControlObj = cloneDeep(currentSecurityControl)
    let exemptions = (currentSecurityControlObj && currentSecurityControlObj.exemptions) || []
    const userTypeExemptionIdx = exemptions.findIndex(exempt => exempt.type === 'user_type')
    const groupTypeExemptionIdx = exemptions.findIndex(exempt => exempt.type === 'group_membership')

    if (userTypeExemption && userTypeExemption.length) {
      if (currentSecurityControlObj) {
        if (userTypeExemptionIdx < 0) {
          exemptions.push({
            type: 'user_type',
            exemption_details: userTypeExemption,
          })
        } else {
          exemptions[userTypeExemptionIdx].exemption_details = userTypeExemption
        }
      }
    }

    if (groupExemptions && groupExemptions.length > 0) {
      if (currentSecurityControlObj) {
        if (groupTypeExemptionIdx < 0) {
          exemptions.push({
            type: 'group_membership',
            exemption_details: groupExemptions.map(groupExempt => groupExempt._id),
          })
        } else {
          exemptions[groupTypeExemptionIdx].exemption_details = groupExemptions.map(groupExempt => groupExempt._id)
        }
      }
    }
    if (
      userTypeExemption &&
      userTypeExemption.length &&
      userTypeExemption[0] === 'None' &&
      groupExemptions &&
      !groupExemptions.length
    ) {
      exemptions = []
    }
    currentSecurityControlObj.exemptions = exemptions

    setCurrentSecurityControl(currentSecurityControlObj)
    props.setEditable()
    setActiveContent('sec_control')
  }

  const handleDeleteSecCtrl = securityControl => {
    let currentSecurityControlObj = cloneDeep(currentSecurityControl)
    const securityControls = (currentSecurityControlObj && currentSecurityControlObj.controls) || []
    let existingCntrlInd
    if (securityControls && securityControls.length === 1) {
      currentSecurityControlObj = null
    } else {
      existingCntrlInd = securityControls.findIndex(
        control => control.security_control_id === securityControl.security_control_id
      )
      securityControls.splice(existingCntrlInd, 1)
    }

    setCurrentSecurityControl(currentSecurityControlObj)
    resetSecControl()
  }

  const moveSecControl = (row, move) => {
    const currentSecurityControlObj = cloneDeep(currentSecurityControl)
    const securityControls = (currentSecurityControlObj && currentSecurityControlObj.controls) || []

    let oldIndex = securityControls.findIndex(control => control.security_control_id === row.security_control_id)
    let newIndex = move === 'up' ? oldIndex - 1 : oldIndex + 1

    while (oldIndex < 0) {
      oldIndex += securityControls.length
    }
    while (newIndex < 0) {
      newIndex += securityControls.length
    }
    if (newIndex >= securityControls.length) {
      let k = newIndex - securityControls.length
      while (k-- + 1) {
        securityControls.push(undefined)
      }
    }
    securityControls.splice(newIndex, 0, securityControls.splice(oldIndex, 1)[0])
    currentSecurityControlObj.controls = securityControls
    setCurrentSecurityControl(currentSecurityControlObj)
  }

  const checkColumnStatus = () => {
    if (activeContent === 'add_new_control') {
      let errorAvail = false
      const obj = {}
      columnSecurity.forEach(col => {
        if (col.fieldName) {
          if (col.fieldName in obj) {
            errorAvail = true
          } else {
            obj[col.fieldName] = true
          }
        }
        if (!col.label?.length || !col.fieldName) {
          errorAvail = true
        }
      })
      return errorAvail
    } else {
      return !isUpdated
    }
  }

  const changeTab = (event, value) => {
    setActiveTab(value)
    setActiveContent('sec_control')
    setIsEditMode(false)
  }

  const resetSecControl = () => {
    setSecurityControl('None')
    setMappedColumns([])
    setRequiredColumns([])
  }

  const editColumnSecurity = index => {
    const columnSecurityClone = []
    const obj = {
      fieldName: columnSecurityMetrics[index].fieldName,
      user: columnSecurityMetrics[index].user,
      label: columnSecurityMetrics[index].label,
      group: columnSecurityMetrics[index].group,
      viewersAccess: columnSecurityMetrics[index].viewersAccess,
      column: columnSecurityMetrics[index].column,
      dataClassification: columnSecurityMetrics[index].dataClassification,
      index,
    }
    columnSecurityClone.push(obj)
    setColumnSecurity(columnSecurityClone)
    setIsEditMode(true)
    updateEdittingControlIndex(index)
    setActiveContent('add_new_control')
  }

  const deleteColumnSecurity = index => {
    const columnSecurityMetricsClone = cloneDeep(columnSecurityMetrics)
    columnSecurityMetricsClone[index].user = []
    columnSecurityMetricsClone[index].label = []
    columnSecurityMetricsClone[index].group = []
    columnSecurityMetricsClone[index].viewersAccess = []
    columnSecurityMetricsClone[index].dataClassification = 'Internal'
    setColumnSecurityMetrics(columnSecurityMetricsClone)
    setIsUpdated(true)
  }

  function renderColumnLevelSecurity() {
    return (
      <>
        {activeContent === 'sec_control' && (
          <>
            <List className="chip-style">
              {columnSecurityMetrics.map((col, index) => {
                return col.dataClassification === 'Confidential' ? (
                  <ListItem key={col.fieldName}>
                    <ListItemText className="sec-cntrl-text">{col.fieldName}</ListItemText>
                    <IconButton onClick={() => editColumnSecurity(index)} id={`edit-security-${index}`}>
                      <Edit />
                    </IconButton>
                    <IconButton onClick={() => deleteColumnSecurity(index)} id={`delete-security-${index}`}>
                      <Icon>delete</Icon>
                    </IconButton>
                  </ListItem>
                ) : null
              })}
            </List>
            <div className="add-new-container">
              <Button
                id="add-new-ctrl"
                variant="text"
                aria-label="add new column level security control"
                className="add-new-ctrl-btn"
                color="primary"
                onClick={() => {
                  setColumnSecurity([{}])
                  setActiveContent('add_new_control')
                }}
              >
                Add New Control
              </Button>
            </div>
          </>
        )}
        {activeContent === 'add_new_control' && (
          <ColumnSecurityAdd
            columnSecurityMetrics={columnSecurityMetrics}
            columnSecurity={columnSecurity}
            setColumnSecurity={setColumnSecurity}
            disabled={checkColumnStatus()}
            isEditMode={isEditMode}
            {...props}
          />
        )}
      </>
    )
  }

  return (
    <Popover
      className="security-ctl-popover"
      open={showSecurityControlPopover}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'center',
      }}
    >
      <div className="ctl-container">
        <header className="header-container">
          <Tabs value={activeTab} onChange={changeTab} indicatorColor="primary" textColor="primary">
            <Tab
              key="ROW LEVEL SECURITY"
              label="ROW LEVEL SECURITY"
              className="button-padding"
              id="row-level-security"
            />
            <Tab
              key="COLUMN LEVEL SECURITY"
              label="COLUMN LEVEL SECURITY"
              className="button-padding"
              id="column-level-security"
            />
          </Tabs>
          <IconButton
            id="close-sec-btn"
            onClick={() => {
              setShowSecurityControlPopover(false)
              resetSecControl()
              props.togglePopover()
            }}
            aria-label="Close Security Control"
          >
            <Close />
          </IconButton>
        </header>
        {!activeTab && (
          <>
            {activeContent === 'sec_control' && (
              <div className="add-new-container">
                <Typography variant="body1">
                  Add new controls and map them to respective column, rearrange them according to your priorities.
                </Typography>
                {renderSavedControls()}
                <Button
                  id="add-new-ctrl"
                  variant="text"
                  aria-label="add new security control"
                  className="add-new-ctrl-btn"
                  color="primary"
                  onClick={() => {
                    resetSecControl()
                    setActiveContent('add_new_control')
                  }}
                >
                  Add New Control
                </Button>
              </div>
            )}
            {activeContent === 'add_new_control' && renderAddNewSecControl()}
            {activeContent === 'adv_options' && renderAdvancedOptions()}
          </>
        )}
        {Boolean(activeTab) && renderColumnLevelSecurity()}
        <div className="flex-cols">
          <div className="bottom-options-container">
            {activeContent === 'sec_control' && activeContent !== 'adv_options' && !activeTab && (
              <Button
                id="advance-options-btn"
                variant="text"
                className="adv-options"
                onClick={() => {
                  setActiveContent('adv_options')
                }}
              >
                Advanced Options
              </Button>
            )}
            <div className="save-can-buttons">
              <Button
                id="apply-save-btn"
                variant="text"
                aria-label="save security controls"
                color="primary"
                onClick={handleSaveClick}
                disabled={!activeTab ? getIsSaveDisabled() : checkColumnStatus()}
              >
                {`${activeContent === 'adv_options' ? 'Apply' : 'Save'}`}
              </Button>
              <Button
                id="cancel-back-btn"
                variant="text"
                aria-label="cancel security controls"
                color="secondary"
                onClick={() => {
                  updateEdittingControlIndex(-1)
                  handleCancelClick()
                }}
              >
                {`${activeContent !== 'sec_control' ? 'Back' : 'Cancel'}`}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Popover>
  )
}

export default SecurityControl
