import React from 'react'
import PropTypes from 'prop-types'
import { Button, Popover, Tabs, Tab, List, ListItem, Tooltip } from '@mui/material'
import { DateRange, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import isEqual from 'lodash/isEqual'
import cloneDeep from 'lodash/cloneDeep'
import moment from 'moment'
import { Spinner, CUSTOM_STR, createErrMsg } from 'greenfield-utilities'

import Calendar from '../Calendar/Calendar'
import './TimePeriod.scss'
import { getFormatDate, getTimePeriodButtonLabel } from '../util'
import {
  OTHERS_TIME_PERIOD,
  NEXT_TIME_PERIOD,
  PERIOD_TIME_PERIOD,
  PREVIOUS_TIME_PERIOD,
  TIME_PERIOD_TAB_OPTIONS,
  TODAY_TIME_PERIOD,
  CUSTOM_DATES_OPTIONS,
  DEFAULT_PERIOD_PLURAL_OPTIONS,
  TIME_PERIOD_TAB_OPTIONS_WITH_COMPARE_BY,
  MULTI_DATE_TAB_OPTIONS,
} from '../../constants/ArrayConstants'
import { AnchorIcon } from '../Svgicon/Svgicon'
import { LWR_CASE_TODAY, MULTI_SELECT_CUSTOM_STR } from '../../constants/StringConstants'
import RelativeTimePeriod from './RelativeTimePeriod'
import OtherTimePeriodOptions from './OtherTimePeriodOptions'
import CustomTimePeriod from './CustomTimePeriod'

class TimePeriod extends React.Component {
  getButtonText = () => {
    const { isDashboard, cardInfo, isCompareBy, compareByValue } = this.props
    const timePeriod = cardInfo?.data?.card_config?.card_query_attribute?.time_period || {} // eslint-disable-line
    const isTimeIntervalArray = Array.isArray(timePeriod.intervals) && timePeriod.type === CUSTOM_STR.toLowerCase()
    return !isDashboard && cardInfo?.data?.card_config && timePeriod.type !== 'absolute'
      ? isCompareBy
        ? compareByValue
        : isTimeIntervalArray
        ? timePeriod.intervals
        : timePeriod.interval
      : ''
  }

  state = {
    startDate: null,
    endDate: null,
    value: 0,
    anchorEl: null,
    buttonText: this.getButtonText(),
    selectedStartDate: this.props.isDashboard ? getFormatDate() : null,
    selectedEndDate: this.props.isDashboard ? getFormatDate() : null,
    mobileTimePeriodContainerOpen: false,
    calendarOpen: false,
    hasSetDrillthroughTimePeriod: false,
    setBeginDateToday: false,
    setEndDateToday: false,
  }

  onHandleNone = () => {
    const { modifyDashboardInfo, isViewer, onTimeJsonChange } = this.props
    if (isViewer) {
      onTimeJsonChange({}, true)
    } else {
      modifyDashboardInfo('time_period', {})
    }

    this.setState({
      startDate: null,
      endDate: null,
      anchorEl: null,
      buttonText: '',
    })
  }

  componentDidMount() {
    let startState, endState, buttonText, setBeginDateToday, setEndDateToday
    let { selectedStartDate, selectedEndDate, value } = this.state
    const {
      isDashboard,
      dateFilterStatus,
      cardInfo,
      fiscalYear,
      dashboardInfo,
      viewerJson,
      isViewer,
      isCompareBy,
      compareByValue,
      anchorDateFilterStatus,
      selectedTimePeriod,
      isVirtualColumnFilter,
    } = this.props

    if (!isDashboard && cardInfo?.data?.card_config) {
      /* eslint-disable camelcase */
      const timePeriod = isViewer
        ? selectedTimePeriod?.timePeriod || viewerJson || {}
        : cardInfo?.data?.card_config?.card_query_attribute?.time_period || {}
      const dateFilterObj = timePeriod?.anchor_date && !isCompareBy ? anchorDateFilterStatus : dateFilterStatus
      /* eslint-enable camelcase */

      const isTimeIntervalArray = Array.isArray(timePeriod.intervals) && timePeriod.type === CUSTOM_STR.toLowerCase()
      if (dateFilterObj && dateFilterObj.status !== 'failed' && Object.keys(dateFilterObj).length > 1) {
        const { Fiscal, Gregorian } =
          timePeriod.anchor_date && !isCompareBy
            ? this.dateFilterChange(anchorDateFilterStatus)
            : this.dateFilterChange(dateFilterStatus)
        const calendarType = fiscalYear ? Fiscal : Gregorian
        const periodInterval = isCompareBy
          ? compareByValue
          : isTimeIntervalArray
          ? timePeriod.intervals
          : timePeriod.interval

        if ((timePeriod.set_begin_date_today || timePeriod.set_end_date_today) && periodInterval?.includes(',')) {
          const splitInterval = periodInterval?.split(',')
          const start = splitInterval[0]?.trim()
          const end = splitInterval[1]?.trim()

          const startDateStr = timePeriod.set_begin_date_today ? start?.slice(1) : start?.slice(2, start?.length - 1)
          const endDateStr = timePeriod.set_end_date_today
            ? end?.slice(0, end?.length - 1)
            : end?.slice(1, end?.length - 2)

          startState = timePeriod.set_begin_date_today ? 'Today' : startDateStr
          endState = timePeriod.set_end_date_today ? 'Today' : endDateStr
          buttonText = 'Between'
          setBeginDateToday = timePeriod.set_begin_date_today || false
          setEndDateToday = timePeriod.set_end_date_today || false
        } else if (periodInterval && periodInterval.includes('/')) {
          startState = periodInterval.split('/')[0]
          endState = periodInterval.split('/')[1]
          selectedStartDate = periodInterval.split('/')[0]
          selectedEndDate = periodInterval.split('/')[1]
          buttonText = 'Between'
        } else {
          if (calendarType && calendarType[periodInterval]) {
            buttonText = periodInterval
            startState = calendarType[buttonText]['Begin Date']
            endState = calendarType[buttonText]['End Date']
          } else if (periodInterval !== 'last segment') {
            startState = null
            endState = null
            buttonText = periodInterval
          } else {
            if (cardInfo.data && cardInfo.data.rendered_interval) {
              startState = cardInfo.data.rendered_interval.begin
              endState = cardInfo.data.rendered_interval.end
            }
            buttonText = periodInterval
          }
        }
        this.setState({
          startDate: startState,
          endDate: endState,
          selectedStartDate,
          selectedEndDate,
          buttonText,
          setBeginDateToday,
          setEndDateToday,
        })
      }
    } else if (isDashboard && (dashboardInfo.time_period || viewerJson)) {
      const _timePeriod = isViewer ? viewerJson || {} : dashboardInfo.time_period || {}
      const isTimeIntervalArray = Array.isArray(_timePeriod.intervals) && _timePeriod.type === CUSTOM_STR.toLowerCase()
      const dateFilterObj = _timePeriod?.anchor_date && !isCompareBy ? anchorDateFilterStatus : dateFilterStatus
      if (Object.keys(dateFilterObj).length > 1) {
        /* eslint-disable camelcase */
        const { Fiscal, Gregorian } = _timePeriod?.anchor_date
          ? anchorDateFilterStatus?.data
          : this.dateFilterChange(dateFilterStatus)
        /* eslint-enable camelcase */
        const calendarType = fiscalYear ? Fiscal : Gregorian
        const periodInterval = isTimeIntervalArray ? _timePeriod?.intervals : _timePeriod?.interval
        if (periodInterval) {
          if ((_timePeriod.set_begin_date_today || _timePeriod.set_end_date_today) && periodInterval?.includes(',')) {
            const splitInterval = periodInterval?.split(',')
            const start = splitInterval[0]?.trim()
            const end = splitInterval[1]?.trim()

            const startDateStr = _timePeriod.set_begin_date_today ? start?.slice(1) : start?.slice(2, start?.length - 1)
            const endDateStr = _timePeriod.set_end_date_today
              ? end?.slice(0, end?.length - 1)
              : end?.slice(1, end?.length - 2)

            startState = _timePeriod.set_begin_date_today ? 'Today' : startDateStr
            endState = _timePeriod.set_end_date_today ? 'Today' : endDateStr
            buttonText = 'Between'
            setBeginDateToday = _timePeriod.set_begin_date_today || false
            setEndDateToday = _timePeriod.set_end_date_today || false
          } else if (calendarType[periodInterval]) {
            startState = calendarType[_timePeriod.interval]['Begin Date']
            endState = calendarType[_timePeriod.interval]['End Date']
            buttonText = _timePeriod.interval
          } else if (periodInterval !== 'last segment' && !periodInterval?.includes('/')) {
            startState = null
            endState = null
            buttonText = periodInterval
          } else if (periodInterval?.includes('/')) {
            startState = periodInterval?.split('/')[0]
            endState = periodInterval?.split('/')[1]
            buttonText = 'Between'
          }
          this.setState({
            startDate: startState,
            endDate: endState,
            selectedStartDate: startState,
            selectedEndDate: endState,
            buttonText,
            setBeginDateToday,
            setEndDateToday,
          })
        }
      }
    }

    if (buttonText && isViewer && isVirtualColumnFilter && value === 0) {
      this.setState({
        value: this.getTabSelectValue(buttonText),
      })
    }
  }

  checkExistenceValue(calendarType, value) {
    return !(
      typeof calendarType[value] === 'undefined' ||
      calendarType[value] === null ||
      calendarType[value]['Begin Date'] === null ||
      calendarType[value]['Begin Date'] === ''
    )
  }

  componentDidUpdate(prevProps) {
    let startState, endState, buttonTextObj
    const { buttonText, hasSetDrillthroughTimePeriod, anchorEl } = this.state
    const {
      fiscalYear,
      dateFilterStatus,
      isTimePeriodDrillThrough,
      cardInfo,
      isCompareBy,
      compareByValue,
      viewerJson,
      anchorDateFilterStatus,
    } = this.props
    const prevViewer = Object.assign({}, prevProps.viewerJson)
    const nextViewer = Object.assign({}, viewerJson)

    if (fiscalYear !== prevProps.fiscalYear) {
      const type = fiscalYear ? 'Fiscal' : 'Gregorian'
      const { cardInfo, isDashboard, pendingCardUid } = prevProps
      const timePeriod = prevProps.isDashboard
        ? prevProps.dashboardInfo.time_period
        : prevProps.cardInfo?.data?.card_config?.card_query_attribute?.time_period // eslint-disable-line

      const isTimeIntervalArray = Array.isArray(timePeriod?.intervals) && timePeriod?.type === CUSTOM_STR.toLowerCase()

      if (
        (timePeriod &&
          Object.keys(timePeriod).length &&
          timePeriod.calendar_type &&
          (timePeriod.calendar_type === 'Fiscal' || timePeriod.calendar_type === 'Gregorian')) ||
        buttonText !== ''
      ) {
        if (!isDashboard && !prevProps.isViewer) {
          const card = cloneDeep(cardInfo.data.card_config)

          if (!Object.prototype.hasOwnProperty.call(card, '_id')) {
            card.pendingCardUid = pendingCardUid
          }
          if (!prevProps.isCompareBy) {
            card.card_query_attribute.time_period.type = timePeriod.type
            card.card_query_attribute.time_period.calendar_type = type
            prevProps.markSelectedCardAndRender(card)
          }
        } else if (!prevProps.isViewer) {
          const timePeriodInterval = {
            calendar_type: '',
          }
          if (isTimeIntervalArray) {
            timePeriodInterval.intervals = timePeriod.intervals
            timePeriodInterval.type = timePeriod.type
          } else {
            timePeriodInterval.interval = timePeriod.interval
          }
          timePeriodInterval.calendar_type = type
          prevProps.modifyDashboardInfo('time_period', timePeriodInterval)
        } else {
          const timePeriodValue = {
            calendar_type: type,
          }
          if (!isDashboard) {
            prevProps.onTimeJsonChange(timePeriodValue, true)
          }
        }
      }
    }

    /* eslint-disable camelcase */
    const timePeriod = prevProps.isViewer
      ? viewerJson || {}
      : prevProps.isDashboard
      ? prevProps.dashboardInfo.time_period
      : cardInfo?.data?.card_config?.card_query_attribute?.time_period
    const dateFilterObj = timePeriod?.anchor_date && !isCompareBy ? anchorDateFilterStatus : dateFilterStatus
    const prevDateFilterObj =
      timePeriod?.anchor_date && !isCompareBy ? prevProps.anchorDateFilterStatus : prevProps.dateFilterStatus
    /* eslint-enable camelcase */

    if (
      (fiscalYear !== prevProps.fiscalYear ||
        dateFilterObj !== prevDateFilterObj ||
        Object.keys(nextViewer).length > Object.keys(prevViewer).length ||
        !isEqual(nextViewer, prevViewer) ||
        (cardInfo?.data?.card_config && isCompareBy && prevProps.compareByValue !== compareByValue)) &&
      ((!prevProps.isViewer &&
        prevProps.isDashboard &&
        prevProps.dashboardInfo.time_period &&
        Object.keys(prevProps.dashboardInfo.time_period).length) ||
        (this.props.isViewer && Object.keys(this.props.viewerJson).length) ||
        (!prevProps.isDashboard && cardInfo?.data?.card_config)) &&
      Object.keys(dateFilterObj).length > 1 &&
      !isTimePeriodDrillThrough &&
      !anchorEl
    ) {
      const { Fiscal, Gregorian } =
        timePeriod?.anchor_date && !isCompareBy ? anchorDateFilterStatus?.data : this.dateFilterChange(dateFilterStatus)
      const calendarType = fiscalYear ? Fiscal : Gregorian
      const isTimeIntervalArray = Array.isArray(timePeriod?.intervals) && timePeriod?.type === CUSTOM_STR.toLowerCase()
      const periodInterval =
        timePeriod && (timePeriod?.interval || timePeriod?.intervals)
          ? isCompareBy
            ? compareByValue
            : isTimeIntervalArray
            ? timePeriod?.intervals
            : timePeriod?.interval
          : ''

      if (
        timePeriod &&
        (periodInterval || timePeriod.type || (cardInfo && cardInfo.data && cardInfo.data.rendered_interval))
      ) {
        if ((timePeriod.set_begin_date_today || timePeriod.set_end_date_today) && periodInterval?.includes(',')) {
          const splitInterval = periodInterval?.split(',')
          const start = splitInterval[0]?.trim()
          const end = splitInterval[1]?.trim()

          const startDateStr = timePeriod.set_begin_date_today ? start?.slice(1) : start?.slice(2, start?.length - 1)
          const endDateStr = timePeriod.set_end_date_today
            ? end?.slice(0, end?.length - 1)
            : end?.slice(1, end?.length - 2)

          startState = timePeriod.set_begin_date_today ? 'Today' : startDateStr
          endState = timePeriod.set_end_date_today ? 'Today' : endDateStr
          buttonTextObj = 'Between'
        } else if (typeof periodInterval === 'string' && periodInterval?.includes('/')) {
          startState = periodInterval.split('/')[0]
          endState = periodInterval.split('/')[1]
          buttonTextObj = 'Between'
        } else {
          if (calendarType[periodInterval]) {
            buttonTextObj = periodInterval
            startState = calendarType[buttonTextObj]['Begin Date']
            endState = calendarType[buttonTextObj]['End Date']
          } else if (periodInterval !== 'last segment') {
            startState = null
            endState = null
            buttonTextObj = periodInterval
          } else {
            startState =
              cardInfo.data && cardInfo.data.rendered_interval && cardInfo.data.rendered_interval.begin
                ? cardInfo.data.rendered_interval.begin
                : null
            endState =
              cardInfo.data && cardInfo.data.rendered_interval && cardInfo.data.rendered_interval.end
                ? cardInfo.data.rendered_interval.end
                : null
            buttonTextObj = periodInterval
          }
        }
        this.setState({
          startDate: startState,
          endDate: endState,
          buttonText: buttonTextObj,
        })
      }
    }
    this.showErrorOnDateFilterStatusFailed(prevProps)

    if (
      isTimePeriodDrillThrough &&
      Object.keys(cardInfo).length &&
      cardInfo.data &&
      cardInfo.data.rendered_interval &&
      !hasSetDrillthroughTimePeriod
    ) {
      const { cardInfo } = this.props

      this.setState({
        startDate: cardInfo.data.rendered_interval.begin,
        endDate: cardInfo.data.rendered_interval.end,
        buttonText: 'Between',
        hasSetDrillthroughTimePeriod: true,
      })
    }
  }

  showErrorOnDateFilterStatusFailed(prevProps) {
    const { dateFilterStatus, displayServiceErrorMessage } = this.props

    if (
      prevProps.dateFilterStatus &&
      prevProps.dateFilterStatus.status === 'requested' &&
      dateFilterStatus &&
      dateFilterStatus.status === 'failed'
    ) {
      displayServiceErrorMessage(createErrMsg(dateFilterStatus, 'Something went wrong while retrieving time period'))
    }
  }

  dateFilterChange(dateFilterStatus = this.props.dateFilterStatus) {
    const { selectedDataset, cardInfo } = this.props
    const dateFilter = cloneDeep(dateFilterStatus?.data)
    if (cardInfo?.data?.card_config && Object.keys(dateFilterStatus).length > 0 && dateFilter) {
      dateFilter.Fiscal['All Time']['Begin Date'] = selectedDataset.min_date_range
      dateFilter.Fiscal['All Time']['End Date'] = selectedDataset.max_date_range
      dateFilter.Gregorian['All Time']['Begin Date'] = selectedDataset.min_date_range
      dateFilter.Gregorian['All Time']['End Date'] = selectedDataset.max_date_range
    }
    return dateFilter
  }

  callAnchorDate = value => {
    const { getAnchorDateFilter, anchorDateFilterStatus } = this.props
    const timePeriod = this.getTimePeriod()
    /* eslint-disable camelcase */

    if (timePeriod?.anchor_date && timePeriod?.anchor_date !== anchorDateFilterStatus?.anchor_date) {
      getAnchorDateFilter({ anchor_date: timePeriod?.anchor_date })
    } else if (value === 0 && !timePeriod?.anchor_date && !anchorDateFilterStatus?.anchor_date) {
      getAnchorDateFilter({ anchor_date: getFormatDate() })
    }
    /* eslint-enable camelcase */
  }

  handleTabChange = (event, value) => {
    this.callAnchorDate(value)
    this.setState({ value })
  }

  onHandleTabClose = () => {
    const { getAnchorDateFilter, anchorDateFilterStatus, closeMobilePopover, isMobile } = this.props
    const timePeriod = this.getTimePeriod()
    if (timePeriod?.anchor_date && timePeriod?.anchor_date !== anchorDateFilterStatus?.anchor_date) {
      getAnchorDateFilter({ anchor_date: timePeriod?.anchor_date })
    }

    this.setState({
      anchorEl: null,
    })

    if (isMobile) {
      closeMobilePopover()
    }
  }

  updateState = (stateName, value) => {
    this.setState({
      [stateName]: value,
    })
  }

  getTimePeriod() {
    const { cardInfo, isDashboard, viewerJson, isViewer, dashboardInfo } = this.props

    return isViewer
      ? viewerJson
      : isDashboard
      ? dashboardInfo.time_period
      : cardInfo?.data?.card_config
      ? cardInfo?.data?.context?.rendered_payload?.time_period
      : {}
  }

  getTabSelectValue = selectTime => {
    const { isCompareBy, isVirtualColumnFilter } = this.props
    const buttonText = selectTime && isVirtualColumnFilter ? selectTime : this.state.buttonText
    let value = 0
    const isButtonTextArray = Array.isArray(buttonText)
    const buttonLabel = getTimePeriodButtonLabel(buttonText)
    const customArray = !isButtonTextArray ? buttonText?.split(' ') : []
    const isLastNextLabel = Boolean(
      customArray?.length === 3 &&
        CUSTOM_DATES_OPTIONS.includes(customArray[0]) &&
        DEFAULT_PERIOD_PLURAL_OPTIONS.includes(customArray[2])
    )

    if (!isButtonTextArray) {
      if (
        TODAY_TIME_PERIOD.includes(buttonLabel) ||
        PREVIOUS_TIME_PERIOD.includes(buttonLabel) ||
        NEXT_TIME_PERIOD.includes(buttonLabel) ||
        isLastNextLabel
      ) {
        value = 0
        this.callAnchorDate(value)
      } else if (PERIOD_TIME_PERIOD.includes(buttonLabel)) {
        value = 1
      } else if (buttonLabel?.toLowerCase() === 'between') {
        value = 2
      } else if (
        OTHERS_TIME_PERIOD.includes(buttonLabel) ||
        buttonLabel === '' ||
        buttonLabel?.toLowerCase() === 'last segment'
      ) {
        if (isVirtualColumnFilter) {
          value = 0
        } else {
          value = isCompareBy ? 3 : 4
        }
      }
    } else if (!isCompareBy) {
      value = 3
    }
    return value
  }

  handleTabOpen = event => {
    this.setState({
      anchorEl: event.currentTarget,
      value: this.getTabSelectValue(),
    })
  }

  isDisableBetweenApply = () => {
    const { selectedStartDate, selectedEndDate, setBeginDateToday, setEndDateToday } = this.state
    let isDisable = false

    if (setBeginDateToday || setEndDateToday) {
      isDisable = false
    } else if (
      selectedStartDate === null ||
      selectedEndDate === null ||
      !moment(selectedStartDate).isValid() ||
      !moment(selectedEndDate).isValid() ||
      new Date(selectedStartDate).getTime() > new Date(selectedEndDate).getTime()
    ) {
      isDisable = true
    }
    return isDisable
  }

  handleCalendar = () => {
    const {
      cardInfo,
      isDashboard,
      pendingCardUid,
      fiscalYear,
      markSelectedCardAndRender,
      modifyDashboardInfo,
      isViewer,
      onTimeJsonChange,
      isBuilder,
      isCompareBy,
      changeIndex,
      selectedTimePeriod,
      viewerJson,
    } = this.props
    const { selectedStartDate, selectedEndDate, startDate, endDate, setEndDateToday, setBeginDateToday } = this.state
    const type = fiscalYear ? 'Fiscal' : 'Gregorian'
    const calendarStartDate = setBeginDateToday ? 'Today' : selectedStartDate || getFormatDate(startDate)
    const calendarEndDate = setEndDateToday ? 'Today' : selectedEndDate || getFormatDate(endDate)
    const selectedGranularity = selectedTimePeriod?.timePeriod && selectedTimePeriod?.timePeriod.granularity
    if (calendarStartDate && calendarEndDate) {
      const startDateStr = setBeginDateToday ? 'this.day.begin' : `'${calendarStartDate}'`
      const endDateStr = setEndDateToday ? 'this.day.end' : `'${calendarEndDate}'`
      const intervalString = `(${startDateStr}, ${endDateStr})`
      const periodIntervalStr =
        setBeginDateToday || setEndDateToday ? intervalString : `${calendarStartDate}/${calendarEndDate}`

      if (cardInfo?.data?.card_config && !isDashboard && !isViewer) {
        const card = cloneDeep(cardInfo.data.card_config)
        if (!Object.prototype.hasOwnProperty.call(card, '_id')) {
          card.pendingCardUid = pendingCardUid
        }

        this.setState(
          {
            startDate: calendarStartDate,
            endDate: calendarEndDate,
            buttonText: 'Between',
            anchorEl: null,
          },
          () => {
            if (isBuilder && isCompareBy) {
              this.props.callRenderCardWithCompareBy(card, periodIntervalStr, isCompareBy, changeIndex)
            } else {
              card.card_query_attribute.time_period.interval = periodIntervalStr
              card.card_query_attribute.time_period.type = 'absolute'
              card.card_query_attribute.time_period.calendar_type = type

              card.card_query_attribute.time_period.set_end_date_today = setEndDateToday || false
              card.card_query_attribute.time_period.set_begin_date_today = setBeginDateToday || false

              delete card.card_query_attribute.time_period.anchor_date
              delete card.card_query_attribute.time_period.intervals
              markSelectedCardAndRender(card)
            }
          }
        )
      } else if (isDashboard && !isViewer) {
        const timePeriodInterval = {
          interval: '',
          calendar_type: '',
        }

        timePeriodInterval.calendar_type = type
        timePeriodInterval.interval = periodIntervalStr
        timePeriodInterval.type = 'absolute'
        timePeriodInterval.set_end_date_today = setEndDateToday || false
        timePeriodInterval.set_begin_date_today = setBeginDateToday || false
        modifyDashboardInfo('time_period', timePeriodInterval)
        this.setState({
          startDate: calendarStartDate,
          endDate: calendarEndDate,
          buttonText: 'Between',
          anchorEl: null,
        })
      } else if (isViewer) {
        const timePeriodValue = {
          interval: periodIntervalStr,
          type: 'absolute',
          calendar_type: type,
          set_end_date_today: setEndDateToday || false,
          set_begin_date_today: setBeginDateToday || false,
          granularity: selectedGranularity || viewerJson?.granularity,
        }
        this.setState(
          {
            startDate: calendarStartDate,
            endDate: calendarEndDate,
            buttonText: 'Between',
            anchorEl: null,
          },
          () => {
            if (isCompareBy) {
              this.props.callRenderCardWithCompareBy(periodIntervalStr, isCompareBy, changeIndex)
            } else {
              onTimeJsonChange(timePeriodValue, true)
            }
          }
        )
      }
    }
  }

  onDatesChange = (selectedTimeValue, anchorRelativeDate, customType) => {
    const {
      cardInfo,
      isDashboard,
      pendingCardUid,
      fiscalYear,
      modifyDashboardInfo,
      isViewer,
      onTimeJsonChange,
      isBuilder,
      isCompareBy,
      changeIndex,
      updateChangedCards,
      markSelectedCardAndRender,
      anchorDateFilterStatus,
      dateFilterStatus,
      isMobile,
      closeMobilePopover,
      selectedTimePeriod,
      isVirtualColumnFilter,
    } = this.props
    const selectedGranularity = cardInfo?.data?.context?.rendered_payload?.time_period?.granularity || 'All'

    if (isMobile) {
      closeMobilePopover()
    }

    const anchorDate = anchorRelativeDate && !isCompareBy ? getFormatDate(anchorRelativeDate) : null
    // cards For only
    if (updateChangedCards && !isDashboard) {
      updateChangedCards(cardInfo.data.card_config._id)
    }

    const isSelectedTimeArray = Array.isArray(selectedTimeValue) && customType === CUSTOM_STR.toLowerCase()
    const type = fiscalYear ? 'Fiscal' : 'Gregorian'
    const { Fiscal = {}, Gregorian = {} } =
      anchorDate && !isCompareBy ? anchorDateFilterStatus?.data || {} : this.dateFilterChange(dateFilterStatus)
    const calendarType = fiscalYear ? Fiscal : Gregorian
    if (!isDashboard && cardInfo?.data?.card_config && !isViewer) {
      const card = cloneDeep(cardInfo.data.card_config)

      if (!Object.prototype.hasOwnProperty.call(card, '_id')) {
        card.pendingCardUid = pendingCardUid
      }
      this.setState(
        {
          buttonText: selectedTimeValue,
          anchorEl: null,
          startDate:
            !isSelectedTimeArray && calendarType[selectedTimeValue]
              ? calendarType[selectedTimeValue]['Begin Date']
              : '',
          endDate:
            !isSelectedTimeArray && calendarType[selectedTimeValue] ? calendarType[selectedTimeValue]['End Date'] : '',
        },
        () => {
          if (isBuilder && isCompareBy) {
            this.props.callRenderCardWithCompareBy(card, selectedTimeValue, isCompareBy, changeIndex)
          } else {
            if (customType && isSelectedTimeArray) {
              card.card_query_attribute.time_period.intervals = selectedTimeValue
              card.card_query_attribute.time_period.type = customType
              delete card.card_query_attribute.time_period.interval
              delete card.card_query_attribute.time_period.compare_by
            } else {
              delete card.card_query_attribute.time_period.intervals
              card.card_query_attribute.time_period.interval = selectedTimeValue
              card.card_query_attribute.time_period.type = 'relative'
            }
            card.card_query_attribute.time_period.calendar_type = type
            if (anchorDate) {
              card.card_query_attribute.time_period.anchor_date = anchorDate
            } else {
              delete card.card_query_attribute.time_period.anchor_date
            }

            delete card.card_query_attribute.time_period.set_begin_date_today
            delete card.card_query_attribute.time_period.set_end_date_today
            markSelectedCardAndRender(card)
          }
        }
      )
    } else if (isDashboard && !isViewer) {
      const timePeriodInterval = {
        calendar_type: '',
      }
      timePeriodInterval.calendar_type = type
      if (isSelectedTimeArray && customType) {
        timePeriodInterval.intervals = selectedTimeValue
        timePeriodInterval.type = customType
      } else {
        timePeriodInterval.interval = selectedTimeValue
      }
      if (anchorDate) {
        timePeriodInterval.anchor_date = anchorDate
      }
      modifyDashboardInfo('time_period', timePeriodInterval)
      this.setState({
        buttonText: selectedTimeValue,
        anchorEl: null,
        startDate: calendarType[selectedTimeValue] ? calendarType[selectedTimeValue]['Begin Date'] : '',
        endDate: calendarType[selectedTimeValue] ? calendarType[selectedTimeValue]['End Date'] : '',
      })
    } else if (isViewer) {
      const timePeriodValue = selectedTimePeriod?.timePeriod
        ? selectedTimePeriod.timePeriod
        : {
            calendar_type: type,
            granularity: selectedGranularity,
          }
      if (isSelectedTimeArray && customType) {
        timePeriodValue.intervals = selectedTimeValue
        timePeriodValue.type = customType
      } else {
        timePeriodValue.interval = selectedTimeValue
        timePeriodValue.type =
          isVirtualColumnFilter && selectedTimePeriod?.timePeriod?.type
            ? selectedTimePeriod?.timePeriod?.type
            : 'relative'
      }

      if (anchorDate) {
        timePeriodValue.anchor_date = anchorDate
      }

      this.setState(
        {
          buttonText: selectedTimeValue,
          anchorEl: null,
          startDate: calendarType[selectedTimeValue] ? calendarType[selectedTimeValue]['Begin Date'] : '',
          endDate: calendarType[selectedTimeValue] ? calendarType[selectedTimeValue]['End Date'] : '',
        },
        () => {
          if (isCompareBy) {
            this.props.callRenderCardWithCompareBy(selectedTimeValue, isCompareBy, changeIndex)
          } else {
            onTimeJsonChange(timePeriodValue, !anchorDate)
          }
        }
      )
    }
  }

  renderTabValue() {
    const { Fiscal, Gregorian } = this.dateFilterChange()
    const { value, selectedStartDate, selectedEndDate, setBeginDateToday, setEndDateToday } = this.state
    const {
      fiscalYear,
      isDashboard,
      dashboardInfo,
      cardInfo,
      selectedDataset,
      isViewer,
      viewerJson,
      isMobile,
      deviceType,
      anchorDateFilterStatus,
      dateFilterStatus,
      getAnchorDateFilter,
      compareByValue,
      isCompareBy,
      customDateFilterStatus,
      getCustomDateFilter,
      selectedTimePeriod,
      selectedDashboardTimePeriod,
      isVirtualColumnFilter,
    } = this.props

    let timeArray
    const calendarType = fiscalYear ? Fiscal : Gregorian
    const timePeriod = isViewer
      ? selectedTimePeriod?.timePeriod || viewerJson
      : isDashboard
      ? dashboardInfo.time_period
      : cardInfo?.data?.card_config
      ? cardInfo.data.card_config.card_query_attribute.time_period
      : null
    switch (value) {
      case 1:
        timeArray = PERIOD_TIME_PERIOD
        break
      case 3:
        timeArray = OTHERS_TIME_PERIOD
        if (!isDashboard) {
          timeArray = OTHERS_TIME_PERIOD.slice(0, 1)
        }
        break
    }

    const _selectedDateset = cloneDeep(selectedDataset)
    let datasetMaxDateRange = null
    let datasetMinDateRange = null
    if (_selectedDateset) {
      if (_selectedDateset.max_date_range && moment(_selectedDateset.max_date_range).isValid()) {
        const maxRangeArray = _selectedDateset.max_date_range.slice(0, 19)
        datasetMaxDateRange = maxRangeArray || null
      }

      if (_selectedDateset.min_date_range && moment(_selectedDateset.min_date_range).isValid()) {
        const minRangeArray = _selectedDateset.min_date_range.slice(0, 19)
        datasetMinDateRange = minRangeArray || null
      }
    }

    if (dateFilterStatus?.data && isVirtualColumnFilter && isViewer) {
      const { Fiscal, Gregorian } = dateFilterStatus?.data
      const calendarType = viewerJson.calendar_type?.toLowerCase() === 'fiscal' ? Fiscal : Gregorian
      if (calendarType['All Time'] && calendarType['All Time']['Begin Date'] && calendarType['All Time']['End Date']) {
        datasetMinDateRange = calendarType['All Time']['Begin Date']
        datasetMaxDateRange = calendarType['All Time']['End Date']
      }
    }

    const defaultStartDateValue = () => {
      let startDateStr = null
      if (timePeriod && timePeriod.interval) {
        const periodInterval = timePeriod.interval
        if ((timePeriod.set_begin_date_today || timePeriod.set_end_date_today) && periodInterval.includes(',')) {
          const splitInterval = periodInterval?.split(',')
          const start = splitInterval[0]?.trim()
          startDateStr = timePeriod.set_begin_date_today
            ? getFormatDate(datasetMinDateRange)
            : start?.slice(2, start?.length - 1)
        } else if (
          (!timePeriod.type && timePeriod.interval.includes('/')) ||
          (timePeriod.type && timePeriod.type === 'absolute')
        ) {
          startDateStr = timePeriod.interval.split('/')[0]
        } else if (!isDashboard && datasetMinDateRange) {
          startDateStr = getFormatDate(datasetMinDateRange)
        }
      } else if (!isDashboard && datasetMinDateRange) {
        startDateStr = getFormatDate(datasetMinDateRange)
      }

      return startDateStr
    }

    const defaultEndDateValue = () => {
      let endDateStr = null
      if (timePeriod && timePeriod.interval) {
        const periodInterval = timePeriod.interval
        if ((timePeriod.set_begin_date_today || timePeriod.set_end_date_today) && periodInterval.includes(',')) {
          const splitInterval = periodInterval?.split(',')
          const end = splitInterval[1]?.trim()
          endDateStr = timePeriod.set_end_date_today
            ? getFormatDate(datasetMaxDateRange)
            : end?.slice(1, end?.length - 2)
        } else if (
          (!timePeriod.type && timePeriod.interval.includes('/')) ||
          (timePeriod.type && timePeriod.type === 'absolute')
        ) {
          endDateStr = timePeriod.interval.split('/')[1]
        } else if (!isDashboard && datasetMaxDateRange) {
          endDateStr = getFormatDate(datasetMaxDateRange)
        }
      } else if (!isDashboard && datasetMaxDateRange) {
        endDateStr = getFormatDate(datasetMaxDateRange)
      }

      return endDateStr
    }

    return (
      <div>
        {value === 2 && (
          <div className="calendar-fit">
            <div className="flex-row">
              <Calendar
                id="start-date"
                setToday="Set Start Date Today"
                label="Start Date"
                isMobile={isMobile}
                calendarOpen={this.state.calendarOpen}
                toggleCalendarState={this.toggleCalendarState}
                deviceType={deviceType}
                endDate={selectedEndDate || defaultEndDateValue() || getFormatDate()}
                defaultValue={defaultStartDateValue()}
                onChange={(value, setBeginDateToday = false) => {
                  this.setState(
                    {
                      selectedStartDate: value,
                      setBeginDateToday,
                    },
                    () => {
                      if (isVirtualColumnFilter) {
                        this.handleCalendar()
                      }
                    }
                  )
                  if (selectedEndDate === null) {
                    this.updateState('selectedEndDate', defaultEndDateValue())
                  }
                }}
                minDate={datasetMinDateRange || null}
                maxDate={datasetMaxDateRange || null}
                isDashboard={isDashboard}
                isEnableToday={setBeginDateToday}
              />
              <Calendar
                id="end-date"
                setToday="Set End Date Today"
                label="End Date"
                isMobile={isMobile}
                calendarOpen={this.state.calendarOpen}
                toggleCalendarState={this.toggleCalendarState}
                deviceType={deviceType}
                startDate={selectedStartDate || defaultStartDateValue() || getFormatDate()}
                defaultValue={defaultEndDateValue()}
                onChange={(value, setEndDateToday = false) => {
                  this.setState(
                    {
                      selectedEndDate: value,
                      setEndDateToday,
                    },
                    () => {
                      if (isVirtualColumnFilter) {
                        this.handleCalendar()
                      }
                    }
                  )
                  if (selectedStartDate === null) {
                    this.updateState('selectedStartDate', defaultStartDateValue())
                  }
                }}
                minDate={datasetMinDateRange || null}
                maxDate={datasetMaxDateRange || null}
                isDashboard={isDashboard}
                isEnableToday={setEndDateToday}
              />
            </div>
            {!isVirtualColumnFilter && (
              <Button
                id="apply-calendar-dates"
                variant="text"
                color="primary"
                disabled={this.isDisableBetweenApply()}
                className="calendar-button"
                onClick={() => {
                  if (this.props.isMobile) {
                    this.props.closeMobilePopover()
                  }
                  this.handleCalendar()
                }}
              >
                {`${isViewer ? 'Select' : 'Apply'}`}
              </Button>
            )}
          </div>
        )}
        {value === 1 && (
          <div>
            <List>
              {timeArray.map((timeValue, index) => (
                <ListItem
                  id={`selected-tab-value-${index}`}
                  button
                  onClick={() => this.onDatesChange(timeValue)}
                  key={`timeValue-${index}`}
                  className="listItem-test list-item-padding"
                >
                  <div className="date-scss">
                    <div className="text-scss">{timeValue}</div>
                    <div>
                      {this.checkExistenceValue(calendarType, timeValue) &&
                        `${getFormatDate(calendarType[timeValue]['Begin Date'])} to
                        ${getFormatDate(calendarType[timeValue]['End Date'])}`}
                    </div>
                  </div>
                </ListItem>
              ))}
            </List>
          </div>
        )}
        {value === 0 && (
          <RelativeTimePeriod
            handleCancel={this.onHandleTabClose}
            handleDateChange={this.onDatesChange}
            anchorDateFilterStatus={anchorDateFilterStatus}
            fiscalYear={fiscalYear}
            getAnchorDateFilter={getAnchorDateFilter}
            cardInfo={cardInfo}
            isDashboard={isDashboard}
            dashboardInfo={dashboardInfo}
            isViewer={isViewer}
            viewerJson={viewerJson}
            dateFilterStatus={dateFilterStatus}
            isCompareBy={isCompareBy}
            compareByValue={compareByValue}
            isMobile={isMobile}
            selectedTimePeriod={selectedTimePeriod}
            selectedDashboardTimePeriod={selectedDashboardTimePeriod}
            isVirtualColumnFilter={isVirtualColumnFilter}
            selectedDataset={selectedDataset}
          />
        )}
        {value === 3 && !isCompareBy && (
          <CustomTimePeriod
            handleCancel={this.onHandleTabClose}
            handleDateChange={this.onDatesChange}
            fiscalYear={fiscalYear}
            cardInfo={cardInfo}
            isDashboard={isDashboard}
            dashboardInfo={dashboardInfo}
            isViewer={isViewer}
            viewerJson={viewerJson}
            isMobile={isMobile}
            getCustomDateFilter={getCustomDateFilter}
            customDateFilterStatus={customDateFilterStatus}
            minDate={datasetMinDateRange || null}
            maxDate={datasetMaxDateRange || null}
            selectedTimePeriod={selectedTimePeriod}
            selectedDashboardTimePeriod={selectedDashboardTimePeriod}
          />
        )}
        {(isCompareBy ? value === 3 : value === 4) && (
          <OtherTimePeriodOptions
            handleCancel={this.onHandleTabClose}
            handleTimePeriod={this.onDatesChange}
            cardInfo={cardInfo}
            selectedDataset={this.props.selectedDataset}
            isDashboard={isDashboard}
            dashboardInfo={dashboardInfo}
            isViewer={isViewer}
            viewerJson={viewerJson}
            isMobile={isMobile}
            handleNone={this.onHandleNone}
            selectedTimePeriod={selectedTimePeriod}
            selectedDashboardTimePeriod={selectedDashboardTimePeriod}
          />
        )}
      </div>
    )
  }

  toggleCalendarState = calendarOpen => {
    this.setState({
      calendarOpen,
    })
  }

  renderDateFilter() {
    const { dateFilterStatus } = this.props
    switch (dateFilterStatus.status) {
      case 'requested':
        return <Spinner size="large" layout="selfCentering" className="datasets-status-progress" />
      case 'failed':
        // todo put in snackbar thing
        return (
          <p className="datasets-status-failed">
            {createErrMsg(dateFilterStatus, 'Something went wrong with the cards time data request')}
          </p>
        )
      default:
        return dateFilterStatus.data ? this.renderTabValue() : null
    }
  }

  toggleMobileTimePeriodContainer = () => {
    this.setState({
      mobileTimePeriodContainerOpen: !this.state.mobileTimePeriodContainerOpen,
    })
  }

  renderTimePeriodOptions = (tabOptions, value, isCompareBy) => (
    <>
      <Tabs
        value={value}
        onChange={this.handleTabChange}
        id="tabs-unit-test"
        variant="scrollable"
        scrollButtons={!this.props.isVirtualColumnFilter}
        allowScrollButtonsMobile={!this.props.isVirtualColumnFilter}
      >
        {tabOptions.map(
          (name, index) =>
            ((!isCompareBy && name === 'Custom') || name !== 'Custom') && (
              <Tab id={`tab-name-${index}`} key={`name-${index}`} label={name} />
            )
        )}
      </Tabs>

      {this.renderDateFilter()}
    </>
  )

  render() {
    const {
      cardInfo,
      isMobile,
      closeMobilePopover,
      isCompareBy,
      isViewer,
      viewerJson,
      isDashboard,
      dashboardInfo,
      isVirtualColumnFilter,
    } = this.props
    const { buttonText, anchorEl, startDate, endDate, value } = this.state
    const anchor = this.props.anchorEl || anchorEl
    const closePopOver = isMobile ? closeMobilePopover : this.onHandleTabClose
    /* eslint-disable camelcase */
    const timePeriod = isViewer
      ? viewerJson
      : isDashboard
      ? dashboardInfo.time_period
      : cardInfo?.data?.card_config?.card_query_attribute?.time_period
    /* eslint-enable camelcase */
    const isButtonTextArray = Array.isArray(buttonText)
    const buttonLabel = isCompareBy ? buttonText : getTimePeriodButtonLabel(buttonText, timePeriod)
    const showCustom = Boolean(buttonLabel === CUSTOM_STR && buttonText?.length < 30)

    /* eslint-disable camelcase */
    const startDateLabel = buttonText === 'last segment' ? cardInfo?.data?.rendered_interval?.begin : startDate
    const endDateLabel = buttonText === 'last segment' ? cardInfo?.data?.rendered_interval?.end : endDate
    /* eslint-enable camelcase */

    const fromDate = startDate?.toLowerCase() === LWR_CASE_TODAY ? startDate : getFormatDate(startDateLabel)
    const toDate = endDate?.toLowerCase() === LWR_CASE_TODAY ? endDate : getFormatDate(endDateLabel)
    const tabOptions = isCompareBy
      ? TIME_PERIOD_TAB_OPTIONS_WITH_COMPARE_BY
      : isVirtualColumnFilter
      ? MULTI_DATE_TAB_OPTIONS
      : TIME_PERIOD_TAB_OPTIONS

    return !isVirtualColumnFilter ? (
      <div className="timePeriod">
        {!isMobile && (
          <Tooltip
            title={!showCustom && buttonLabel === CUSTOM_STR ? buttonText : isButtonTextArray ? buttonLabel : ''}
            placement="bottom"
          >
            <Button
              id="time-period-btn"
              disabled={cardInfo.status === 'requested'}
              variant="contained"
              color="primary"
              onClick={this.handleTabOpen}
              className={`icon-label-btn-props time-period-btn ${isMobile ? 'mobileTimePeriodTriggerBtn' : null} ${
                anchorEl && isCompareBy ? 'time-period-selected' : null
              }`}
            >
              {!isMobile && (
                <div className="calender-icon">
                  <DateRange />
                </div>
              )}
              {timePeriod && timePeriod.anchor_date && <AnchorIcon className="anchor-icon" />}
              {startDateLabel
                ? `${buttonLabel} ${fromDate} to ${toDate}`
                : showCustom
                ? buttonText
                : isButtonTextArray
                ? MULTI_SELECT_CUSTOM_STR
                : buttonLabel}
              {!isMobile &&
                (anchorEl && isCompareBy ? (
                  <KeyboardArrowUp className="icon-left icon-right" />
                ) : (
                  <KeyboardArrowDown className="icon-left icon-right" />
                ))}
            </Button>
          </Tooltip>
        )}
        <Popover
          open={Boolean(anchor)}
          anchorEl={anchor}
          onClose={closePopOver}
          className="timePeriod"
          anchorReference={isCompareBy ? 'anchorPosition' : 'anchorEl'}
          anchorPosition={isCompareBy && isViewer ? { top: 390, left: 930 } : { top: 405, left: 35 }}
          anchorOrigin={{
            vertical: isCompareBy ? 'top' : 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: isCompareBy ? 'center' : 'top',
            horizontal: 'left',
          }}
          style={{ maxWidth: '450px' }}
        >
          <div className={isMobile ? 'mobilePickerHeight' : ''}>
            {this.renderTimePeriodOptions(tabOptions, value, isCompareBy)}
          </div>
        </Popover>
      </div>
    ) : (
      <div className="timePeriod">{this.renderTimePeriodOptions(tabOptions, value, isCompareBy)}</div>
    )
  }
}

TimePeriod.defaultProps = {
  cardInfo: {},
  dateFilterStatus: {},
  isDashboard: false,
  dashboardInfo: null,
  selectedDataset: {},
  onTimeJsonChange: () => {},
  deviceType: [],
  isCompareBy: false,
  changeIndex: -1,
  compareByValue: '',
  anchorDateFilterStatus: {},
  getAnchorDateFilter: () => {},
  isVirtualColumnFilter: false,
}

TimePeriod.propTypes = {
  fiscalYear: PropTypes.bool,
  dateFilterStatus: PropTypes.object,
  cardInfo: PropTypes.object,
  pendingCardUid: PropTypes.string,
  selectedDataset: PropTypes.object,
  isDashboard: PropTypes.bool,
  dashboardInfo: PropTypes.object,
  modifyDashboardInfo: PropTypes.func,
  isViewer: PropTypes.bool,
  viewerTimeEvent: PropTypes.func,
  onTimeJsonChange: PropTypes.func,
  viewerJson: PropTypes.object,
  isCompareBy: PropTypes.bool,
  changeIndex: PropTypes.number,
  compareByValue: PropTypes.string,
  callRenderCardWithCompareBy: PropTypes.func,
  anchorDateFilterStatus: PropTypes.object,
  getAnchorDateFilter: PropTypes.func,
  isVirtualColumnFilter: PropTypes.bool,
}

export default TimePeriod
