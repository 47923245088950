import React from 'react'
import { Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import Viewer from 'react-froala-wysiwyg/FroalaEditorView'

const AccessDeniedViewOwner = ({ errorData = {} }) => {
  return (
    <>
      {errorData?.type === 'AccessDeniedView' && (
        <Typography>
          <b>Please contact: </b>
          {errorData?.context?.owners_access?.map((item, key) =>
            item.type === 'group' ? (
              <Link className="link-text" to={`/admin/groups/${item._id}/members`} key={key}>
                {`${item.user_group_name}, `}
              </Link>
            ) : (
              <Typography key={key} variant="caption" color="inherit">
                {`${item.user_group_name}, `}
              </Typography>
            )
          )}
        </Typography>
      )}
      {errorData?.type === 'ConfidentialField' && errorData?.support_details?.text && (
        <Viewer model={errorData.support_details.text} />
      )}
    </>
  )
}

export default AccessDeniedViewOwner
