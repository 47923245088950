// List Options for column type and function type with list values
export const columnOptions = [
  {
    name: 'all',
    value: 'All',
  },
  {
    name: 'dimension',
    value: 'Dimensions',
  },
  {
    name: 'dynamic_dimension',
    value: 'Dynamic dimensions',
  },
  {
    name: 'metric',
    value: 'Metrics',
  },
  {
    name: 'calculated',
    value: 'Calculated Field',
  },
  {
    name: 'timeseries',
    value: 'Date',
  },
  {
    name: 'thetaSketch',
    value: 'thetaSketch',
  },
]

export const functionOptions = [
  {
    name: 'all',
    value: 'All',
  },
  {
    name: 'function',
    value: 'Function',
  },
  {
    name: 'condition',
    value: 'Condition',
  },
  {
    name: 'aggregate',
    value: 'Aggregate',
  },
]

export const calculatedTypes = ['aggregation', 'postagg']

export const profileInfoObj = {
  column_name: '',
  column_display_name: '',
  description: '',
  exprQL: '',
  data_classification: 'Internal',
  owners: [],
  owner_groups: [],
  user_view_access: [],
  group_view_access: [],
  owners_access: [],
  viewers_access: [],
}

export const cardExpressionInfoObj = {
  column_display_name: '',
  exprQL: '',
  ref_id: '',
}
