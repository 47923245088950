import React from 'react'
import {
  Typography,
  Grid,
  List,
  ListItem,
  ListItemText,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
} from '@mui/material'
import { Link } from 'react-router-dom'

export const LogsDetailComponent = ({ rowData, isRecipient }) => {
  const getDependency = () => {
    const dependency = rowData.depends_on
    return (
      <Table style={{ maxWidth: 450, marginLeft: '1rem' }}>
        <TableHead>
          <TableRow>
            <TableCell>Datasets</TableCell>
            <TableCell style={{ minWidth: 100 }}>Satisfied</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {dependency?.map(item => {
            return (
              <TableRow key={item.id}>
                <TableCell>
                  <Link rel="noopener noreferrer" target="_blank" to={`/dataset/preview/${item.id}`}>
                    {item.name}
                  </Link>
                </TableCell>
                <TableCell>{item.dependency_resolved ? 'Yes' : 'No'}</TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    )
  }

  return (
    <Grid style={{ minHeight: 150 }} container direction="row" justifyContent="space-between" alignItems="flex-start">
      <Grid item xs={6}>
        <GridItem primary="Dependencies" secondary={getDependency()} />
        {rowData?.execution_message && <GridItem primary="Status Message" secondary={rowData?.execution_message} />}
        <GridItem primary="Evaluation Window Id" secondary={rowData.evaluation_window_id} />
      </Grid>
      {isRecipient && (
        <Grid item xs={6}>
          {rowData.subscription_type === 'DATA_ALERT' && (
            <GridItem
              primary="Criteria Met"
              secondary={rowData.notification_eligibility?.eligible_for_notification ? 'Yes' : 'No'}
            />
          )}
          <GridItem
            primary="Notification Sent"
            secondary={rowData?.notification_eligibility?.notification_sent ? 'Yes' : 'No'}
          />
        </Grid>
      )}
    </Grid>
  )
}

const GridItem = ({ primary, secondary }) => {
  return (
    <List>
      <ListItem>
        <ListItemText
          className="site-list-item"
          primary={
            <Typography variant="subtitle1" style={{ fontSize: 14, fontWeight: 'bold' }}>
              {primary}
            </Typography>
          }
          secondary={
            <Typography
              component="span"
              variant="body1"
              style={{ fontSize: 14, wordBreak: 'break-word', whiteSpace: 'pre-wrap' }}
              data-cy={`site-list-item-${primary}`}
            >
              {secondary}
            </Typography>
          }
        />
      </ListItem>
    </List>
  )
}
