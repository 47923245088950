import React from 'react'
import { Chip } from '@mui/material'
import moment from 'moment'
import { DatastoreActionRenderer } from './DatastoreActionRenderer'

export const ColumnDefs = (isMobile, error) => {
  return [
    {
      field: 'datastore_name',
      headerName: 'Connection Name',
      minWidth: 150,
      cellRendererFramework: ({ data }) => {
        return (
          <span data-cy={`datastore-name-${data.datastore_name}`} aria-label={`datastore name ${data.datastore_name}`}>
            {data.datastore_name}
          </span>
        )
      },
    },
    {
      field: 'dbname',
      headerName: 'Database Name',
      minWidth: 150,
    },
    {
      field: 'host',
      headerName: 'Host Name',
      minWidth: 150,
    },
    {
      field: 'audit_properties.updated_date',
      headerName: 'Last Updated',
      minWidth: 150,
      valueGetter: ({ data }) =>
        data.audit_properties?.updated_date &&
        moment(data.audit_properties.updated_date, "YYYY-MM-DD'T'HH:mm:ssZ").fromNow(),
    },
    {
      field: 'owners_access',
      hide: isMobile,
      minWidth: 150,
      keyCreator: params => params.value?.map(owner => owner.user_group_name),
      headerName: 'Owner(s)',
      cellRendererFramework: ({ data, node }) => {
        const showMore = data.owners_access?.length > 1
        const value = data.owners_access[0]?.user_group_name || data.owners[0]
        return (
          <>
            {showMore
              ? data.owners_access.map(owner => (
                  <Chip
                    size="small"
                    key={owner.user_group_name}
                    label={owner.user_group_name}
                    className="chip-owners"
                    data-index={node.rowIndex}
                  />
                ))
              : value && <Chip size="small" label={value} key={node.rowIndex} className="chip-owners" />}
          </>
        )
      },
    },
    {
      field: 'name',
      headerName: 'Actions',
      pinned: 'right',
      filter: false,
      hide: isMobile,
      maxWidth: 175,
      sortable: false,
      cellRendererFramework: DatastoreActionRenderer,
      cellRendererParams: {
        error,
      },
    },
  ]
}
