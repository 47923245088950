import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Tooltip,
  Icon,
  IconButton,
  DialogActions,
  DialogContent,
  Button,
  FormControlLabel,
  Checkbox,
} from '@mui/material'
import { DropzoneArea } from 'mui-file-dropzone'
import SwipeableViews from 'react-swipeable-views'
import axios from 'axios'
import { Spinner } from 'greenfield-utilities'
import { useDispatch } from 'react-redux'
import { Close } from '@mui/icons-material'

import { displayServiceErrorMessage } from '../../ducks/layout'
import apiConfig from '../../config/apiConfig'
import { API_GATEWAY_URL } from '../../ducks/utils'
import DatasetBuilderEditUpload from './DatasetBuilderEditUpload'
import DatasetBuilderCompletion from './DatasetBuilderCompletion'
import { SClose, SDialogTitle, SDialog, LabelContainer } from './DatasetBuilder-styled'

const DatasetBuilder = ({ trackEvent = () => {}, closeDatasetBuilder, previouslyUploadedDatasetName }) => {
  const [swipeableIndex, updateSwipeableIndex] = useState(0)
  const [droppedFile, updateDroppedFile] = useState(null)
  const [isSubmittingUpload, updateIsSubmittingUpload] = useState(false)
  const [headerRowVisibility, updateHeaderRowVisibility] = useState(false)
  const [headerRowChecked, handleChangeHeaderRowChecked] = useState(true)
  const [datasetInfo, updateDatasetInfo] = useState({})
  const [uploadStatus, updateUploadStatus] = useState({
    activeStep: 0,
  })
  const [pendingDatasetId, updatePendingDatasetId] = useState(null)
  const [pollIntervalId, updatePollIntervalId] = useState(null)
  const [errorState, updateErrorState] = useState(false)

  const dispatch = useDispatch()

  const handleUploadSubmit = () => {
    updateIsSubmittingUpload(true)

    const form = new FormData()
    form.append('file', droppedFile)
    form.append('has_header', headerRowChecked)

    axios
      .post(`${API_GATEWAY_URL}/greenfield_ingests/v1/ingests/uploads`, form)
      .then(d => {
        updateIsSubmittingUpload(false)
        const { data } = d

        if (!headerRowChecked) {
          data.columns = data.columns.map((col, index) => ({
            ...col,
            name: `Column${index + 1}`,
          }))
        }
        updateDatasetInfo(data)
        updateSwipeableIndex(1)
      })
      .catch(err => {
        const { response } = err
        const errorMessage = (() => {
          if (!response) {
            return 'Your dataset upload has timed out, please try again.  If you continue to receive this message, your dataset likely exceeds our size limits.'
          }

          if (typeof response.data === 'string' && response.data.length) {
            if (response.status === 413) {
              return 'Your request body is too large, please reduce the size of the file and try again.'
            }
            return response.data
          }

          return (
            response.data.message ||
            'An error has occurred while uploading your dataset, please try again or contact support if this issue persists.'
          )
        })()
        updateIsSubmittingUpload(false)
        dispatch(displayServiceErrorMessage(errorMessage))
      })
  }

  const clearPollOnDialogClose = () => {
    if (pollIntervalId) {
      clearInterval(pollIntervalId)
    }
  }

  const uploadContentStep1 = () => (
    <>
      <SDialogTitle>
        <div>
          <span>Dataset builder</span> - Step 1: upload your dataset
        </div>
        <div>
          <Tooltip title="Helpcenter information for dataset builder">
            <a
              href={`${apiConfig.help.url}/03_datasets/what-is-a-dataset/#dataset-builder-using-file-upload`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <IconButton
                onClick={() => {
                  trackEvent({
                    event: {
                      type: 'datasetbuilder-helpcenter-clicked',
                    },
                  })
                }}
              >
                <Icon>live_help</Icon>
              </IconButton>
            </a>
          </Tooltip>
          <SClose
            onClick={() => {
              clearPollOnDialogClose()
              closeDatasetBuilder()
            }}
          >
            <Close />
          </SClose>
        </div>
      </SDialogTitle>
      <DialogContent>
        <DropzoneArea
          acceptedFiles={[
            'text/csv, application/vnd.ms-excel, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values',
          ]}
          dropzoneText="Add or drop CSV file here"
          filesLimit={1}
          maxFileSize={500000000}
          onDrop={files => {
            updateHeaderRowVisibility(true)
            updateDroppedFile(files[0])
          }}
          onDelete={() => {
            updateHeaderRowVisibility(false)
            updateDroppedFile(null)
          }}
        />
        {headerRowVisibility && (
          <LabelContainer>
            <FormControlLabel
              disabled={isSubmittingUpload}
              control={
                <Checkbox
                  checked={headerRowChecked}
                  onChange={() => {
                    handleChangeHeaderRowChecked(!headerRowChecked)
                  }}
                />
              }
              label="This CSV file has a header row"
            />
          </LabelContainer>
        )}
      </DialogContent>
      <DialogActions>
        {!isSubmittingUpload && (
          <Button
            onClick={() => {
              clearPollOnDialogClose()
              closeDatasetBuilder()
            }}
          >
            Cancel
          </Button>
        )}

        <Button
          disabled={!droppedFile || isSubmittingUpload}
          onClick={() => {
            trackEvent({
              event: {
                type: 'datasetbuilder-upload-clicked',
              },
            })
            handleUploadSubmit()
          }}
          startIcon={isSubmittingUpload && <Spinner size="small" />}
          data-cy="dataset-builder-upload-preview"
          variant="contained"
        >
          Upload
        </Button>
      </DialogActions>
    </>
  )

  return (
    <SDialog open fullWidth data-cy="dataset-builder" className={`dataset-builder-modal-index-${swipeableIndex}`}>
      <SwipeableViews index={swipeableIndex}>
        {swipeableIndex === 0 ? uploadContentStep1() : <></>}
        {swipeableIndex === 1 ? (
          <DatasetBuilderEditUpload
            closeDatasetBuilder={() => {
              clearPollOnDialogClose()
              return closeDatasetBuilder()
            }}
            updateSwipeableIndex={updateSwipeableIndex}
            datasetInfo={datasetInfo}
            updateDatasetInfo={updateDatasetInfo}
            updateUploadStatus={updateUploadStatus}
            hasHeader={headerRowChecked}
            previouslyUploadedDatasetName={previouslyUploadedDatasetName}
            updatePendingDatasetId={updatePendingDatasetId}
            updatePollIntervalId={updatePollIntervalId}
            updateErrorState={updateErrorState}
            trackEvent={trackEvent}
          />
        ) : (
          <></>
        )}
        <DatasetBuilderCompletion
          closeDatasetBuilder={() => {
            clearPollOnDialogClose()
            return closeDatasetBuilder()
          }}
          uploadStatus={uploadStatus}
          pendingDatasetId={pendingDatasetId}
          errorState={errorState}
        />
      </SwipeableViews>
    </SDialog>
  )
}

DatasetBuilder.propTypes = {
  closeDatasetBuilder: PropTypes.func.isRequired,
  previouslyUploadedDatasetName: PropTypes.string,
  trackEvent: PropTypes.func,
}

export default DatasetBuilder
