import { call, put } from 'redux-saga/effects'
import axios from 'axios'
import { API_GATEWAY_URL } from './utils'

export const FETCH_METADATA_REQUESTED = 'FETCH_METADATA_REQUESTED'
export const FETCH_METADATA_SUCCEEDED = 'FETCH_METADATA_SUCCEEDED'
export const FETCH_METADATA_FAILED = 'FETCH_METADATA_FAILED'

//  This is created to store filter operation in state and
//  so that it does not get updated on when different metadata is called

export const FETCH_FILTER_METADATA_REQUESTED = 'FETCH_FILTER_METADATA_REQUESTED'
export const FETCH_FILTER_METADATA_SUCCEEDED = 'FETCH_FILTER_METADATA_SUCCEEDED'
export const FETCH_FILTER_METADATA_FAILED = 'FETCH_FILTER_METADATA_FAILED'

// reducer
export default function metadataReducer(state = {}, action = {}) {
  switch (action.type) {
    case FETCH_METADATA_REQUESTED: {
      const newState = {
        ...state,
        metadataStatus: {
          status: 'requested',
        },
      }
      return newState
    }
    case FETCH_METADATA_SUCCEEDED: {
      const newState = {
        ...state,
        metadataStatus: action.payload,
      }
      return newState
    }

    case FETCH_METADATA_FAILED: {
      const newState = {
        ...state,
        metadataStatus: {
          status: 'failed',
          message: action.payload.response,
        },
      }
      return newState
    }
    case FETCH_FILTER_METADATA_REQUESTED: {
      const newState = {
        ...state,
        filterMetadataStatus: {
          status: 'requested',
        },
      }
      return newState
    }
    case FETCH_FILTER_METADATA_SUCCEEDED: {
      const newState = {
        ...state,
        filterMetadataStatus: action.payload,
      }
      return newState
    }

    case FETCH_FILTER_METADATA_FAILED: {
      const newState = {
        ...state,
        filterMetadataStatus: {
          status: 'failed',
          message: action.payload.response,
        },
      }
      return newState
    }
    default:
      return state
  }
}

// actions
export function getMetadata(data) {
  return {
    type: FETCH_METADATA_REQUESTED,
    payload: data,
  }
}

export function getFilterMetadata(data) {
  return {
    type: FETCH_FILTER_METADATA_REQUESTED,
    payload: data,
  }
}

// sagas
export function* fetchMetadata(action) {
  try {
    const metadata = yield call(axios, {
      method: 'get',
      url: `${API_GATEWAY_URL}/bi_reporting_assets/v2/metadata?type=${action.payload}`,
    })

    if (metadata.error) {
      const e = metadata.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }

    yield put({ type: 'FETCH_METADATA_SUCCEEDED', payload: metadata })
  } catch (error) {
    yield put({ type: 'FETCH_METADATA_FAILED', payload: error })
  }
}

export function* fetchFilterMetadata() {
  try {
    const metadata = yield call(axios, {
      method: 'get',
      url: `${API_GATEWAY_URL}/bi_reporting_assets/v2/metadata?type=filter_operations_aggregations`,
    })

    if (metadata.error) {
      const e = metadata.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }

    const data = metadata.data.filter_operations_aggregations.find(obj => obj.name === 'filter_operations')
    const dataIndex = metadata.data.filter_operations_aggregations.findIndex(obj => obj.name === 'filter_operations')
    const obj = {}
    Object.keys(data).forEach(key => {
      if (key !== 'name' && key !== '_id') {
        data[key].forEach(operation => {
          obj[operation.value] = operation.name
        })
      }
    })
    metadata.data.filter_operations_aggregations[dataIndex].allOperations = obj

    yield put({ type: 'FETCH_FILTER_METADATA_SUCCEEDED', payload: metadata })
  } catch (error) {
    yield put({ type: 'FETCH_FILTER_METADATA_FAILED', payload: error })
  }
}
