import './sites.scss'

import React, { useEffect } from 'react'
import { Typography } from '@mui/material'

import Cardlist from '../Cards/Cardlist/Cardlist.container'

const SiteCards = ({ isMobile = false, deviceType, setHeaderTitle, siteObj = {}, routeProps = {} }) => {
  useEffect(() => {
    if (siteObj.status >= 200 && siteObj.status < 300) {
      setHeaderTitle(siteObj?.data?.name)
    }
  }, [siteObj])

  return (
    <div className="site-tab">
      <Typography variant="h4" className="site-info-label">
        This page is a listing of promoted cards for the site.
      </Typography>
      {Object.keys(siteObj).length > 1 && (
        <Cardlist isMobile={isMobile} deviceClassNameList={deviceType} siteObj={siteObj} routeProps={routeProps} />
      )}
    </div>
  )
}

export default SiteCards
