import React from 'react'
import { Spinner } from 'greenfield-utilities'
import { Collapse, Icon, List, ListItem, ListItemText, IconButton } from '@mui/material'
import { NavigateBefore, NavigateNext } from '@mui/icons-material'

import QuicklinksBuilder from './QuicklinksBuilder'
import './quicklinks.scss'

class Quicklinks extends React.Component {
  state = {
    businessTags: [],
    isOpenQuickLinkBuilder: false,
    selectedTypeUser: [],
    selectedTypeGroup: [],
    isSave: false,
    isQuickLinksMenuOpen: true,
  }

  componentDidUpdate() {
    const { isSave } = this.state
    if (isSave) {
      this.props.getUserBusinessTags()
      this.props.clearSaveUserBusinessTagsInfo()
      this.setState({
        isSave: false,
      })
    }
  }

  handleNavSubMenuToggle = tagIndex => {
    this.props.toggleUserBusinessTags(tagIndex)
  }

  handleManageQuickLinksClick = event => {
    event.preventDefault()
    this.props.clearSaveUserBusinessTagsInfo()
    this.props.getBusinessTags({ type: 'dashboard', searchString: '' })
    this.setState({
      isOpenQuickLinkBuilder: true,
    })
  }

  handleQuickLinksClick = event => {
    event.preventDefault()
    const { isQuickLinksMenuOpen } = this.state
    this.setState({
      isQuickLinksMenuOpen: !isQuickLinksMenuOpen,
    })
  }

  handleOnCloseQuicklinkBuilder = () => {
    this.setState({ isOpenQuickLinkBuilder: false })
  }

  static getDerivedStateFromProps(nextProps) {
    const { userDashboardBusinessTagInfoStatus, saveUserQuickLinksInfoStatus } = nextProps

    if (
      saveUserQuickLinksInfoStatus &&
      saveUserQuickLinksInfoStatus.status &&
      saveUserQuickLinksInfoStatus.status >= 200 &&
      saveUserQuickLinksInfoStatus.status <= 300
    ) {
      return {
        isOpenQuickLinkBuilder: false,
        isSave: true,
      }
    } else if (
      userDashboardBusinessTagInfoStatus &&
      userDashboardBusinessTagInfoStatus.status &&
      userDashboardBusinessTagInfoStatus.status >= 200 &&
      userDashboardBusinessTagInfoStatus.status <= 300
    ) {
      const userSelectedTags = userDashboardBusinessTagInfoStatus.data.all_tags
        .filter(tags => tags.type === 'user')
        .map(tagDetails => tagDetails.tag)
      const groupSelectedTags = userDashboardBusinessTagInfoStatus.data.all_tags
        .filter(tags => tags.type === 'group')
        .map(tagDetails => tagDetails.tag)
      return {
        selectedTypeUser: userSelectedTags,
        selectedTypeGroup: groupSelectedTags,
        businessTags: userDashboardBusinessTagInfoStatus.data.all_tags,
      }
    } else {
      return null
    }
  }

  renderQuickLinkListItem() {
    const { userDashboardBusinessTagInfoStatus, closeQuicklinksMenu, isMobile } = this.props
    const itemTextClass = !isMobile ? 'sideNavListItemText' : 'sideNavListItemText sideNavHead'

    return (
      <>
        <div>
          <ListItem
            className="manage-quicklinks"
            onClick={!isMobile ? this.handleManageQuickLinksClick : null}
            button
            classes={{
              root: 'sideNavListItem',
            }}
          >
            {!isMobile && <Icon>link</Icon>}
            {isMobile && (
              <IconButton id="quicklinks-previous-btn" onClick={closeQuicklinksMenu}>
                <NavigateBefore className={`align-icon ${isMobile ? 'mobileQuickLinkNextBtn' : ''}`} />
              </IconButton>
            )}
            <ListItemText
              primary="Manage Quicklinks"
              classes={{
                root: itemTextClass,
              }}
              className={`${isMobile ? 'mobileQuickLinkNextBtn' : ''}`}
              id="sideNavListItemText-Manage_Quicklinks"
            />
            {isMobile && (
              <IconButton id="quicklinks-next-btn" onClick={this.handleManageQuickLinksClick}>
                <NavigateNext className={`align-icon ${isMobile ? 'mobileQuickLinkNextBtn' : ''}`} />
              </IconButton>
            )}
          </ListItem>
        </div>
        <div>
          {userDashboardBusinessTagInfoStatus &&
            userDashboardBusinessTagInfoStatus.status &&
            userDashboardBusinessTagInfoStatus.status === 200 &&
            this.renderBusinessTagsLinks()}
          {userDashboardBusinessTagInfoStatus && userDashboardBusinessTagInfoStatus.status === 'requested' && (
            <Spinner size="medium" layout="selfCentering" />
          )}
        </div>
      </>
    )
  }

  renderBusinessTagsLinks() {
    const { businessTags } = this.state
    const { isMobile } = this.props
    return (
      <>
        <div>
          {businessTags.map((tagDetails, tagIndex) => (
            <div key={tagIndex} id={`SidemenuItem-${tagDetails.tag}`}>
              <ListItem
                button
                classes={{
                  root: 'sideNavListItem',
                }}
                id={`sideNavListItem-${tagDetails.tag}`}
                onClick={() => {
                  this.handleNavSubMenuToggle(tagIndex)
                }}
              >
                <Icon>link</Icon>
                <ListItemText
                  primary={tagDetails.tag}
                  classes={{
                    root: 'sideNavListItemText',
                  }}
                  id={`sideNavListItemText-${tagDetails.tag}`}
                />
                {tagDetails.subMenuOpen ? <Icon>keyboard_arrow_up</Icon> : <Icon>keyboard_arrow_down</Icon>}
              </ListItem>
              <Collapse component="li" in={tagDetails.subMenuOpen} timeout="auto" unmountOnExit>
                <List disablePadding className="quicklink-list">
                  {tagDetails.dashboard_list.map(dashboardDetails => (
                    <a
                      href={`/dashboard/${dashboardDetails._id}`}
                      key={dashboardDetails.name}
                      target="_blank"
                      rel="noopener noreferrer"
                      id={`${dashboardDetails.name}-sidemenuLink`}
                    >
                      <ListItem
                        classes={{
                          root: 'sideNavListItem',
                        }}
                        className={`${isMobile ? 'sideNavMobileSubItem' : ''}`}
                        id={`sideNavListItem-${dashboardDetails.name}`}
                      >
                        <ListItemText
                          inset
                          primary={dashboardDetails.name}
                          classes={{
                            root: 'sideNavListItemText',
                          }}
                        />
                      </ListItem>
                    </a>
                  ))}
                </List>
              </Collapse>
            </div>
          ))}
        </div>
      </>
    )
  }

  renderQuickLinkBuilder() {
    return (
      <div id="quicklinks-builder">
        <QuicklinksBuilder
          {...this.props}
          open={this.state.isOpenQuickLinkBuilder}
          onClose={this.handleOnCloseQuicklinkBuilder}
          dashboardBusinessTagInfoStatus={this.props.dashboardBusinessTagInfoStatus}
          preSelectedTypeUser={this.state.selectedTypeUser}
          preSelectedTypeGroup={this.state.selectedTypeGroup}
          onCloseSideNav={this.props.onCloseSideNav}
        />
      </div>
    )
  }

  render() {
    return (
      <>
        <div id="quicklinks">
          {this.renderQuickLinkListItem()}
          {this.state.isOpenQuickLinkBuilder && this.renderQuickLinkBuilder()}
        </div>
      </>
    )
  }
}

export default Quicklinks
