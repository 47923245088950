import React from 'react'
import {
  Stack,
  IconButton,
  DialogContent,
  Dialog,
  DialogActions,
  Button,
  DialogTitle,
  Typography,
  Grid,
} from '@mui/material'
import { Close, Warning } from '@mui/icons-material'

const DeleteDialog = ({ handleDelete, asset, name, open, handleClose }) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item xs={8}>
            <Stack direction="row" alignItems="center" spacing={2}>
              <Warning color="error" />
              <Typography variant="h6" component="div" color="error">
                Delete {asset}
              </Typography>
            </Stack>
          </Grid>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>{`Are you sure you want to delete ${asset} "${name}"? There is no undo`}</DialogContent>
      <DialogActions>
        <Button size="large" color="error" onClick={handleDelete} data-cy={`delete-button-${name}`}>
          DELETE
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DeleteDialog
