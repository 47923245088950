import React from 'react'
import { AppBar, Toolbar, Typography, Link as AnchorLink, Grid } from '@mui/material'
import { ContactSupportOutlined, Contacts, TipsAndUpdatesOutlined } from '@mui/icons-material'
import styled from 'styled-components'
import apiConfig from '../../../config/apiConfig'
import { SlackIcon } from '../../shared/Svgicon/Svgicon'

const SToolbar = styled(Toolbar)`
  text-align: center;
  height: 100px;
`

const Footer = ({ isInternalUser }) => {
  const GridItem = ({ icon, title, url }) => (
    <Grid item xs>
      <AnchorLink data-cy={title} target="_blank" rel="noopener noreferrer" href={url}>
        {icon}
        <Typography>{title}</Typography>
      </AnchorLink>
    </Grid>
  )

  return (
    <AppBar position="static" sx={{ top: 'auto', bottom: 0 }}>
      <SToolbar>
        <Grid container justifyContent="space-between">
          <GridItem url={apiConfig.help.url} title="Help Documentation" icon={<ContactSupportOutlined />} />
          <Grid item xs>
            {isInternalUser ? (
              <GridItem
                url="https://target.slack.com/archives/greenfield-support"
                title="Ask a question"
                icon={<SlackIcon />}
              />
            ) : (
              <GridItem url="https://www.partnersonline.com/page/help" title="Contact Us" icon={<Contacts />} />
            )}
          </Grid>
          {isInternalUser && (
            <GridItem
              url={`${apiConfig.help.url}/09_get_help_internal/#admin-help`}
              title="Submit Feature Request"
              icon={<TipsAndUpdatesOutlined />}
            />
          )}
        </Grid>
      </SToolbar>
    </AppBar>
  )
}

export default Footer
