import React, { useEffect, useState } from 'react'
import { Grid, Checkbox, Typography, FormControlLabel, Tooltip, IconButton } from '@mui/material'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useAnalytics } from '@praxis/component-analytics'
import { useDispatch, useSelector } from 'react-redux'

import { getDatastoreDatasetList, getDatastoresList, refreshDataset } from '../../../ducks/dataset'
import AddRefreshDatasetDialog from '../../routes/Datasets/AddRefreshDatasetDialog'
import analyticsConfig from '../../analytics'
import AdvanceFilter from '../AdvanceFilter/AdvanceFilter'
import { getSiteURLInfo } from '../util'
import StatusFilter from './StatusFilters'
import { AssetType } from './AssetTable'

export const PAGE_SIZE_OPTIONS = [5, 10, 20, 25, 50, 100]

const SFormControlLabel = styled(FormControlLabel)`
  padding-left: 10px;
`

const TableFilters = ({
  data,
  handleMyContentToggle,
  handleSearchClick,
  handleOnSearchKeyDown,
  handleStatusChange,
  handleCertifiedToggle,
  setSearchText,
}) => {
  const {
    assetType,
    statusType,
    showUserOnlyData,
    siteURL,
    ownerOnly,
    userOrGroupId,
    routeProps,
    dpQueryStringLink,
    showCertified,
    searchKeyword,
  } = data
  const siteURLArray = getSiteURLInfo()
  const [searchInput, setSearchInput] = useState('')
  const [refreshDatasetOpen, setRefreshDatasetOpen] = useState(false)

  const dispatch = useDispatch()
  const { datastoresListStatus, datastoreDatasetsListStatus, refreshDatasetStatus } = useSelector(
    state => state.dataset
  )

  useEffect(() => {
    if (assetType === AssetType.DATASETS) {
      dispatch(getDatastoresList())
      trackClick(`${AssetType.DATASETS}-listing-page-navigation`)
    }
  }, [assetType])

  const handleSearchChange = event => {
    setSearchInput(event.target.value)
    setSearchText(event.target.value)
  }

  const { trackClick } = useAnalytics(analyticsConfig)

  const handleRefreshPostgresDatabase = () => {
    setRefreshDatasetOpen(true)
  }

  const handleRefreshPostgresDatabaseClose = () => {
    setRefreshDatasetOpen(false)
  }

  const isSite = siteURL && siteURLArray && !Object.values(AssetType).includes(siteURLArray[3])

  return (
    <>
      <Grid container className="asset-filter" justifyContent="space-between">
        <Grid item>
          <Grid container justifyContent="center" alignItems="center">
            {!searchKeyword && (
              <AdvanceFilter
                className="search-asset"
                label="Search"
                onSearchChange={handleSearchChange}
                onSearchClick={() => handleSearchClick(searchInput)}
                onKeyDown={e => handleOnSearchKeyDown(e, searchInput)}
                searchValue={searchInput}
                isSmall
                id="asset-search"
                data-cy="asset-search"
                style={{ width: 400 }}
              />
            )}
            {!isSite && !ownerOnly && !userOrGroupId && (
              <>
                <SFormControlLabel
                  control={
                    <Checkbox
                      name="My Content"
                      id="showMyContent"
                      data-cy="showMyContent"
                      aria-label="My Content"
                      checked={showUserOnlyData}
                      onChange={handleMyContentToggle}
                    />
                  }
                  data-cy="show-all-dataset"
                  label={<Typography variant="subtitle1">My Content</Typography>}
                />
                {assetType === AssetType.DATASETS && (
                  <SFormControlLabel
                    control={
                      <Checkbox
                        name="Certified"
                        id="showCertified"
                        data-cy="showCertified"
                        aria-label="Certified"
                        checked={showCertified}
                        onChange={handleCertifiedToggle}
                      />
                    }
                    label={<Typography variant="subtitle1">Certified</Typography>}
                  />
                )}
              </>
            )}
            {!isSite && assetType === AssetType.DATASETS && !ownerOnly && !userOrGroupId && !searchKeyword && (
              <Grid item>
                <Tooltip title="Add / Refresh Postgres Dataset" placement="bottom">
                  <div>
                    <IconButton
                      className="add-refresh-icon"
                      id="add-postgres-dataset"
                      aria-label="Add / Refresh Postgres Dataset"
                      onClick={handleRefreshPostgresDatabase}
                    />
                  </div>
                </Tooltip>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item>
          <Grid container alignItems="center" justifyContent="flex-end" alignContent="flex-end">
            {!isSite && !ownerOnly && !userOrGroupId && assetType === AssetType.DATASETS && (
              <Typography style={{ marginRight: '5px' }}>
                Can&apos;t find what you&apos;re looking for?&nbsp;
                <a
                  href={`https://dataportal.prod.target.com/${dpQueryStringLink}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => {
                    trackClick('dataset-listing-dataportal-link-clicked')
                  }}
                >
                  Search in dataPortal.
                </a>
              </Typography>
            )}
            <StatusFilter statusType={statusType} handleStatusChange={handleStatusChange} />
          </Grid>
        </Grid>
      </Grid>
      <AddRefreshDatasetDialog
        isPreview={false}
        open={refreshDatasetOpen}
        onRefreshPostgresDatabase={handleRefreshPostgresDatabase}
        onRefreshPostgresDatabaseClose={handleRefreshPostgresDatabaseClose}
        datastoresListStatus={datastoresListStatus}
        getDatastoreDatasetList={data => dispatch(getDatastoreDatasetList(data))}
        datastoreDatasetsListStatus={datastoreDatasetsListStatus}
        refreshDataset={data => dispatch(refreshDataset(data))}
        refreshDatasetStatus={refreshDatasetStatus}
        routeProps={routeProps}
      />
    </>
  )
}

TableFilters.propTypes = {
  data: PropTypes.object,
  handleMyContentToggle: PropTypes.func,
  handleSearchClick: PropTypes.func,
  handleOnSearchKeyDown: PropTypes.func,
  handlePageSizeChange: PropTypes.func,
  handleStatusChange: PropTypes.func,
}

export default TableFilters
