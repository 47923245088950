import React from 'react'
import { Card, CardContent, Fab, Grid, Tooltip, Typography } from '@mui/material'
import { ArrowBack } from '@mui/icons-material'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Box } from '@mui/system'
import { SChip } from '../../shared/MUIAutoComplete/Autocomplete'

export const SFloatingIconButton = styled(Fab)`
  position: absolute;
  top: 15px;
  background: #f7f7f7;
  border-radius: 5px;
`
const DatasetDetailsComponent = props => {
  const {
    datasetPreview,
    addedOwners,
    selectedBusinessAreas,
    currentUser,
    showDatasetDescription,
    setShowDatasetDescription,
  } = props
  const previewData = datasetPreview.data

  return (
    <Box m={2} mt={0} sx={{ position: 'relative' }}>
      <Card>
        <CardContent>
          <Grid container justifyContent="space-between" direction="column">
            <Grid item>
              <Grid container justifyContent="space-between">
                <Typography tabIndex="0" variant="subtitle1" className="label-text">
                  Dataset Details
                </Typography>
                <Tooltip title="Hide Details">
                  <SFloatingIconButton
                    size="small"
                    data-cy="hide-description"
                    onClick={() => setShowDatasetDescription(!showDatasetDescription)}
                    aria-label="show details"
                    sx={{ right: '-15px' }}
                  >
                    <ArrowBack />
                  </SFloatingIconButton>
                </Tooltip>
              </Grid>
            </Grid>
            <Typography tabIndex="0" variant="button" className="label-text" aria-label="Dataset Description">
              Description
            </Typography>
            <Typography tabIndex="0" className="value-text">
              {previewData.dataset_description}
            </Typography>
            <Typography tabIndex="0" variant="button" className="label-text">
              Owner(s)
            </Typography>
            <Typography tabIndex="0">
              {addedOwners?.map((item, index) =>
                item.type === 'group' ? (
                  currentUser?.data?.user_type === 'internal' ? (
                    <Link
                      key={item._id}
                      to={`/admin/groups/${item._id}/members`}
                      style={{ textDecoration: 'none', cursor: 'pointer' }}
                    >
                      <SChip
                        key={item._id}
                        style={{ cursor: 'pointer' }}
                        component="span"
                        color="primary"
                        label={<Typography component="span">{item.user_group_name || item.label}</Typography>}
                      />
                    </Link>
                  ) : (
                    <SChip
                      key={item._id}
                      component="span"
                      color="primary"
                      label={<Typography component="span">{item.user_group_name || item.label}</Typography>}
                    />
                  )
                ) : (
                  <SChip
                    component="span"
                    color="primary"
                    label={<Typography component="span">{item.user_group_name || item.label}</Typography>}
                    key={index}
                  />
                )
              )}
            </Typography>
            <Typography tabIndex="0" variant="button" className="label-text">
              Business Area(s)
            </Typography>
            <Typography tabIndex="0">
              {selectedBusinessAreas?.map((item, index) => (
                <SChip
                  component="span"
                  className="chip-business"
                  label={<Typography component="span">{item}</Typography>}
                  key={index}
                />
              ))}
            </Typography>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  )
}

export default DatasetDetailsComponent
