import React from 'react'
import PropTypes from 'prop-types'
import { List as VirtualList } from 'react-virtualized'
import { Spinner } from 'greenfield-utilities'
import { Button, Typography, Checkbox, Chip, TextField, Slide, Dialog, Grid } from '@mui/material'
import { Cancel } from '@mui/icons-material'
import isEqual from 'lodash/isEqual'
import debounce from 'lodash/debounce'

const Transition = React.forwardRef((props, ref) => <Slide direction="left" {...props} ref={ref} />)

class QuicklinksBuilder extends React.Component {
  state = {
    selected: [],
    data: [],
    searchString: '',
    addedUserQLs: [],
  }

  searchBusinessTags = debounce(this.props.getBusinessTags, 1000)

  componentDidMount() {
    if (this.props.preSelectedTypeUser) {
      this.setState({ addedUserQLs: this.props.preSelectedTypeUser })
    }
  }

  handleSearchChange = e => {
    const searchString = e.target.value
    this.searchBusinessTags({ type: 'dashboard', searchString })
    this.setState({
      searchString,
    })
  }

  static getDerivedStateFromProps(nextProps) {
    const { dashboardBusinessTagInfoStatus } = nextProps
    if (dashboardBusinessTagInfoStatus && dashboardBusinessTagInfoStatus.status === 200) {
      return {
        data: dashboardBusinessTagInfoStatus.data,
      }
    } else {
      return null
    }
  }

  handleSaveUserQuickLinks = event => {
    event.preventDefault()
    const { selected, addedUserQLs } = this.state
    const allSelectedTags = selected.concat(addedUserQLs)
    this.props.saveUserBusinessTags(allSelectedTags)
    this.props.onCloseSideNav()
  }

  handleBusinessTagItemToggle = item => {
    const { selected } = this.state
    const { addedUserQLs } = this.state
    const newChecked = [...selected]

    if (selected.includes(item)) {
      const index = selected.findIndex(sel => sel === item)
      newChecked.splice(index, 1)
    } else if (addedUserQLs.includes(item)) {
      const index = addedUserQLs.findIndex(sel => sel === item)
      addedUserQLs.splice(index, 1)
    } else {
      newChecked.push(item)
    }
    this.setState({
      selected: newChecked,
      addedUserQLs,
    })
  }

  renderRow = ({ key, index, style }) => {
    const { selected, addedUserQLs } = this.state
    const { preSelectedTypeGroup } = this.props
    const data = preSelectedTypeGroup.concat(this.state.data)

    const allSelectedItems = selected.concat(addedUserQLs)
    const isSelected = allSelectedItems.includes(data[index])

    return (
      <div key={key} style={style} className="quicklink-list-item">
        <Checkbox
          checked={isSelected}
          classes={{ root: 'ql-checkbox-btn' }}
          disableRipple
          label={data[index]}
          onChange={() => {
            this.handleBusinessTagItemToggle(data[index])
          }}
          disabled={
            (allSelectedItems.length >= 10 && !isSelected) ||
            preSelectedTypeGroup.findIndex(sel => sel === data[index]) > -1
          }
          id={`quicklink-option-${data[index]}`}
        />
        {data[index]}
      </div>
    )
  }

  renderVirtualList() {
    const { data, selected, addedUserQLs } = this.state
    const { isMobile, preSelectedTypeGroup } = this.props
    const allSelectedItems = selected.concat(addedUserQLs)
    const width = isMobile ? 350 : 550
    const height = isMobile ? 350 : 300

    return (
      <VirtualList
        tabIndex={-1}
        {...allSelectedItems}
        width={width}
        height={height}
        rowCount={preSelectedTypeGroup.length + data.length}
        rowHeight={30}
        rowRenderer={this.renderRow}
      />
    )
  }

  keyPressDelete = (event, area) => {
    if (event.which === 13 || event.which === 32) {
      this.handleBusinessTagItemToggle(area)
    }
  }

  renderChips() {
    const { selected, addedUserQLs } = this.state
    const { preSelectedTypeGroup } = this.props
    const allUserSelected = selected.concat(addedUserQLs)

    return (
      <>
        {preSelectedTypeGroup.map((item, index) => (
          <Chip variant="outlined" label={item} key={`${item}-${index}`} color="primary" />
        ))}
        {allUserSelected.map((item, index) => (
          <Chip
            data-cy={`quicklinks-chip-${item}`}
            variant="outlined"
            color="primary"
            key={`${item}-${index}`}
            label={item}
            tabIndex={-1}
            onDelete={() => {
              this.handleBusinessTagItemToggle(item)
            }}
            deleteIcon={
              <span
                tabIndex={0}
                role="button"
                aria-label={`Remove Business Area ${item}`}
                id={`quicklinks-remove-chip-${item}`}
                onKeyDown={event => this.keyPressDelete(event, item)}
              >
                <Cancel />
              </span>
            }
          />
        ))}
      </>
    )
  }

  renderQuickLinksBody() {
    const { dashboardBusinessTagInfoStatus, saveUserQuickLinksInfoStatus, isMobile } = this.props
    const { searchString } = this.state
    return (
      <>
        <div className={saveUserQuickLinksInfoStatus.status === 'requested' ? 'disable-on-request' : ''}>
          <div id="quicklinks-chip-container" className={isMobile ? 'mobileQuicklinksChipContainer' : null}>
            {this.renderChips()}
          </div>
          <div>
            {saveUserQuickLinksInfoStatus && saveUserQuickLinksInfoStatus.status === 'requested' && (
              <Spinner size="medium" layout="selfCentering" />
            )}
            <div>
              <TextField
                id="quicklinks-textarea-search"
                placeholder="Search tags"
                onChange={this.handleSearchChange}
                margin="normal"
                value={searchString}
                fullWidth
                variant="standard"
              />
            </div>
            <div className="quicklink-items">
              {dashboardBusinessTagInfoStatus.status === 'requested' ? (
                <div id="quicklinks-spinner-container">
                  <Spinner size="medium" layout="selfCentering" />
                </div>
              ) : dashboardBusinessTagInfoStatus.status === 200 &&
                dashboardBusinessTagInfoStatus.data &&
                dashboardBusinessTagInfoStatus.data.length > 0 ? (
                this.renderVirtualList()
              ) : (
                <Typography variant="body1" className="no-records">
                  No records found
                </Typography>
              )}
            </div>
          </div>
        </div>
      </>
    )
  }

  getSaveBtnStatus = () => {
    const { selected, addedUserQLs } = this.state
    const allAddedQLs = this.props.userDashboardBusinessTagInfoStatus
    const userQLs =
      allAddedQLs && allAddedQLs.data && allAddedQLs.data.all_tags
        ? allAddedQLs.data.all_tags.filter(ql => ql.type === 'user').map(userQL => userQL.tag)
        : []
    let isSelQLsChanged = false
    if (selected.length > 0) {
      isSelQLsChanged = true
      if (isEqual(selected.sort(), userQLs.sort())) {
        isSelQLsChanged = false
      }
    } else if (addedUserQLs.length < userQLs.length) {
      isSelQLsChanged = true
    } else if (userQLs.length === addedUserQLs.length) {
      userQLs.forEach(x => {
        if (!addedUserQLs.includes(x)) {
          isSelQLsChanged = true
        }
      })
    }
    return !isSelQLsChanged
  }

  renderContents = () => {
    const { onClose, isMobile } = this.props
    const isSaveEnable = this.getSaveBtnStatus()
    return (
      <div className={!isMobile ? 'quicklinks-modal-container' : undefined}>
        <header className="header-container">
          <Typography variant="h2" className="title">
            Manage Quicklinks
          </Typography>
        </header>
        <div data-cy="quicklinks-section" className={!isMobile ? 'quicklinks-details' : 'quicklinks-details ql-mobile'}>
          {this.renderQuickLinksBody()}
        </div>
        {!isMobile && (
          <footer className="footer">
            <Button variant="text" onClick={onClose} id="quicklinks-builder-cancel-btn">
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={this.handleSaveUserQuickLinks}
              disabled={isSaveEnable}
              id="quicklinks-builder-save-btn"
            >
              Save
            </Button>
          </footer>
        )}
      </div>
    )
  }

  renderDesktop = () => {
    const { open, onClose } = this.props
    return (
      <Dialog
        id="manage-quicklinks-dialog"
        className="quicklinks-popover-form"
        classes={{ paper: 'dialog-width' }}
        open={open}
        onClose={onClose}
        role="dialog"
        aria-labelledby="manage quicklinks dialog"
      >
        {this.renderContents()}
      </Dialog>
    )
  }

  renderMobile = () => {
    const { open, onClose } = this.props
    const isSaveEnable = this.getSaveBtnStatus()

    return (
      <Dialog fullScreen open={open} TransitionComponent={Transition} className="mobileQuickLinkSlide">
        {this.renderContents()}
        <div>
          <Grid spacing={3} container>
            <Grid item xs={6} className="mobileFilterBtnLeft">
              <Button variant="text" color="secondary" onClick={onClose} id="quicklinks-mobile-close-btn">
                Cancel
              </Button>
            </Grid>
            <Grid item xs={6} className="mobileFilterBtnRight">
              <Button
                variant="text"
                onClick={this.handleSaveUserQuickLinks}
                color="primary"
                disabled={isSaveEnable}
                id="quicklinks-mobile-save-btn"
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </div>
      </Dialog>
    )
  }

  render() {
    const { isMobile } = this.props

    return <>{isMobile ? this.renderMobile() : this.renderDesktop()}</>
  }
}

QuicklinksBuilder.defaultProps = {
  open: false,
  onClose: () => {},
  dashboardBusinessTagInfoStatus: {},
  onCloseSideNav: () => {},
}

QuicklinksBuilder.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  dashboardBusinessTagInfoStatus: PropTypes.object,
  isMobile: PropTypes.bool,
  deviceType: PropTypes.array,
}

export default QuicklinksBuilder
