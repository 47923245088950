import React from 'react'
import { Box, Step, StepButton, StepConnector, StepLabel, Stepper, Typography } from '@mui/material'
import classNames from 'classnames'
import PropTypes from 'prop-types'

const GreenfieldStepper = ({ completed, steps, icons, activeStep, isStepFailed = null, handleStep }) => {
  return (
    <Box m={2}>
      <Stepper
        alternativeLabel
        connector={
          <StepConnector
            classes={{
              active: 'active',
              alternativeLabel: 'alternative-label',
            }}
            className={classNames('step-connector', 'line', {
              completed: completed.size === steps.length,
            })}
          />
        }
        nonLinear
        activeStep={activeStep}
      >
        {steps.map((label, index) => {
          const labelProps = {}

          if (isStepFailed(index)) {
            labelProps.error = true
          }

          return (
            <Step key={label}>
              <StepButton
                icon={
                  <div
                    className={classNames('stepper-button-icon', {
                      active: activeStep === index || completed.has(index),
                    })}
                  >
                    {icons[index]}
                  </div>
                }
                completed={completed.has(index) ? 1 : 0}
                onClick={handleStep(index)}
              >
                <StepLabel sx={{ flexDirection: 'column' }} {...labelProps}>
                  <Typography m={1}>{label}</Typography>
                </StepLabel>
              </StepButton>
            </Step>
          )
        })}
      </Stepper>
    </Box>
  )
}

GreenfieldStepper.propTypes = {
  completed: PropTypes.object,
  activeStep: PropTypes.number,
  isStepFailed: PropTypes.func,
  handleStep: PropTypes.func,
  steps: PropTypes.array,
  icons: PropTypes.object,
}

GreenfieldStepper.defaultProps = {
  completed: 0,
  activeStep: 0,
  isStepFailed: () => {},
  handleStep: () => {},
  steps: [],
  icons: {},
}

export default GreenfieldStepper
