import React, { useEffect, useState } from 'react'
import '../cards.scss'
import PropTypes from 'prop-types'

import { getSiteURLInfo } from '../../../shared/util'
import AssetTable, { AssetType } from '../../../shared/AssetTableSS/AssetTable'

const CardlistContainer = props => {
  const { setHeaderTitle, siteObj } = props
  const [siteUrlInfo, setSiteUrlInfo] = useState([])
  const [siteTags, setSiteTags] = useState('')

  useEffect(() => {
    const siteUrlInfo = getSiteURLInfo()
    let tagStr = ''
    if (siteObj?.data && siteUrlInfo?.length) {
      const { content } = siteObj.data
      const cardTag = content?.filter(tag => tag.object_type === 'card')[0]
      tagStr = cardTag?.object_tags?.toLocaleString()
      setHeaderTitle(`Cards in ${siteObj.data?.name}`)
    } else {
      setHeaderTitle('Cards')
    }
    setSiteTags(tagStr)
    setSiteUrlInfo(setSiteUrlInfo)
  }, [])

  return <AssetTable {...props} assetType={AssetType.CARDS} siteUrlInfo={siteUrlInfo} siteTags={siteTags} />
}

CardlistContainer.defaultProps = {
  isMobile: false,
  setHeaderTitle: () => {},
  siteObj: {},
  routeProps: {},
}

CardlistContainer.propTypes = {
  isMobile: PropTypes.bool,
  routeProps: PropTypes.object,
  setHeaderTitle: PropTypes.func,
  siteObj: PropTypes.object,
}

export default CardlistContainer
