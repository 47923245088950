import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import {
  getDatasetPreview,
  getDatasetCalcFields,
  delCalculatedField,
  delDataset,
  updateDatasetOnEdit,
  refreshDataset,
  getDatasetCalcFieldsCount,
  refreshDatasetMultipleDates,
  disableMultipleDates,
} from '../../../ducks/dataset'
import { editAllCalculatedFields } from '../../../ducks/calculatedField'
import { displayServiceErrorMessage } from '../../../ducks/layout'
import DatasetPreviewComp from './DatasetPreview'

export class DatasetPreviewContainer extends React.Component {
  componentDidMount() {
    const { nonRoute, routeProps, datasetId, setHeaderTitle, getDatasetPreview, getDatasetCalcFieldsCount } = this.props
    const datasetIdx = nonRoute ? datasetId : routeProps.match.params._id
    setHeaderTitle('Preview Dataset')
    getDatasetPreview({ datasetId: datasetIdx })
    getDatasetCalcFieldsCount({ datasetId: datasetIdx })
  }

  render() {
    let datasetObject = {}
    const datasetId = this.props.nonRoute ? this.props.datasetId : this.props.routeProps.match.params._id
    datasetObject = {
      [datasetId]: this.props.datasetPreviewStatus,
    }

    return (
      <DatasetPreviewComp
        {...this.props}
        datasetPreview={this.props.datasetPreviewStatus}
        datasetDelete={this.props.datasetDelStatus}
        datasetObject={datasetObject}
        siteObj={this.props.siteObj}
      />
    )
  }
}

const mapStateToProps = state => ({
  datasetPreviewStatus: state.dataset.datasetPreviewStatus,
  datasetDelStatus: state.dataset.datasetDelStatus,
  updateDatasetStatus: state.dataset.updateDatasetStatus,
  calcFieldDelStatus: state.dataset.calcFieldDelStatus,
  editAllCalculatedFieldsStatus: state.calculatedField.editAllCalculatedFieldsStatus,
  siteObj: state.site.siteStatus,
  currentUser: state.user.userType,
  refreshDatasetStatus: state.dataset.refreshDatasetStatus,
  datasetCalcFieldCountStatus: state.dataset.datasetCalcFieldCountStatus,
  datasetRefreshMultipleDatesStatus: state.dataset.datasetRefreshMultipleDatesStatus,
  featureFlags: state.user.userAccessStatus,
})

export const mapDispatchToProps = dispatch => ({
  displayServiceErrorMessage(data) {
    dispatch(displayServiceErrorMessage(data))
  },
  getDatasetPreview(data) {
    dispatch(getDatasetPreview(data))
  },
  getDatasetCalcFields(data) {
    dispatch(getDatasetCalcFields(data))
  },
  getDatasetCalcFieldsCount(data) {
    dispatch(getDatasetCalcFieldsCount(data))
  },
  deleteDataset(data) {
    dispatch(delDataset(data))
  },
  delCalculatedField(data) {
    dispatch(delCalculatedField(data))
  },
  editAllCalculatedFields(data) {
    dispatch(editAllCalculatedFields(data))
  },
  updateDatasetOnEdit(data) {
    dispatch(updateDatasetOnEdit(data))
  },
  refreshDataset(data) {
    dispatch(refreshDataset(data))
  },
  refreshDatasetMultipleDates(data) {
    dispatch(refreshDatasetMultipleDates(data))
  },
  disableMultipleDates(data) {
    dispatch(disableMultipleDates(data))
  },
})

DatasetPreviewContainer.defaultProps = {
  displayServiceErrorMessage: () => {},
  routeProps: {},
  setHeaderTitle: () => {},
  datasetPreviewStatus: {},
  datasetDelStatus: {},
  getDatasetPreview: () => {},
  editCalculatedFieldStatus: {},
  getDatasetCalcFields: () => {},
  getDatasetCalcFieldsCount: () => {},
  calcFieldDelStatus: {},
  delCalculatedField: () => {},
  deleteDataset: () => {},
  editAllCalculatedFields: () => {},
  datasetRefreshMultipleDatesStatus: {},
  refreshDatasetMultipleDates: () => {},
  disableMultipleDates: () => {},
}

DatasetPreviewContainer.propTypes = {
  displayServiceErrorMessage: PropTypes.func,
  routeProps: PropTypes.object,
  setHeaderTitle: PropTypes.func,
  datasetPreviewStatus: PropTypes.object,
  datasetDelStatus: PropTypes.object,
  getDatasetPreview: PropTypes.func,
  getDatasetCalcFields: PropTypes.func,
  getDatasetCalcFieldsCount: PropTypes.func,
  calcFieldDelStatus: PropTypes.object,
  delCalculatedField: PropTypes.func,
  deleteDataset: PropTypes.func,
  editCalculatedFieldStatus: PropTypes.object,
  editAllCalculatedFields: PropTypes.func,
  updateDatasetOnEdit: PropTypes.func,
  updateDatasetStatus: PropTypes.object,
  refreshDatasetMultipleDates: PropTypes.func,
  disableMultipleDates: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps)(DatasetPreviewContainer)
