import { FormControl, FormControlLabel, Switch } from '@mui/material'
import React from 'react'

const TimePeriodCalendarType = ({ timePeriod = {}, onCalendarTypeChange }) => {
  const isFiscal = timePeriod.calendar_type === 'Fiscal'
  return (
    <FormControl component="fieldset" variant="standard">
      <FormControlLabel
        control={
          <Switch
            data-cy="fiscal-calendar-toggle"
            checked={isFiscal}
            onChange={() => {
              timePeriod.calendar_type = isFiscal ? 'Gregorian' : 'Fiscal'
              onCalendarTypeChange(timePeriod)
            }}
            name="fiscal calendar"
            inputprops={{ 'aria-label': 'Fiscal Calendar' }}
          />
        }
        label="Fiscal Calendar"
      />
    </FormControl>
  )
}

export default TimePeriodCalendarType
