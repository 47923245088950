/* global Blob */
import jsonCsv from 'json-2-csv'
import cloneDeep from 'lodash/cloneDeep'
import moment from 'moment'
import { TIME_COLUMN_STR, getColIdx, getFormatString } from 'greenfield-utilities'

export function prepCsv(props, data, displayedColsHeader, filters) {
  // todo: remove dupe data - Art/49754518
  const { renderCard, cardId } = props
  const renderedCardConfig = renderCard[cardId]?.data?.card_config || {}
  const cardQueryData = renderedCardConfig?.card_query_attribute
  const columns = cardQueryData?.columns || []
  const queryResults = cloneDeep(data.query_results || data)
  const cardType = renderedCardConfig?.card_attribute?.viz_type?.toLowerCase() || ''
  const isCompareBy = cardQueryData?.time_period ? Boolean(cardQueryData.time_period.compare_by) : false
  const tableTotal = renderedCardConfig?.card_attribute?.style?.tableTotalsOptions?.position

  // function is running multiple times & in some cases data.total_results doesn't exist but renderCard[cardId].data.total_results does
  let totalResults = null

  if (cardType !== 'pivot' && !isCompareBy) {
    if (data && data.total_results && data.total_results.length) {
      totalResults = cloneDeep(data.total_results[0])
    } else if (
      renderCard &&
      renderCard[cardId].data &&
      renderCard[cardId].data.total_results &&
      renderCard[cardId].data.total_results.length
    ) {
      totalResults = cloneDeep(renderCard[cardId].data.total_results[0])
    }
  }

  // Added to prevent cells getting split if they contain commas

  if (cardType !== 'pivot' && Array.isArray(queryResults) && !isCompareBy) {
    queryResults.map(item => {
      deleteUnusedReactTableAttributes(item)
      columns.forEach(value => {
        // For timeseries API is handling formatting - example formatted date: "2019-04-17T00:00:00||04-17-2019"
        if (value.type && value.type === 'timeseries') {
          if (item[value.ref_id] || Number.isInteger(item[value.ref_id])) {
            const timeArr = item[value.ref_id].split('||')
            item[value.ref_id] = timeArr && timeArr.length >= 2 ? timeArr[1] : timeArr[0]
          }
        } else if (value?.format_type === 'text') {
          item[value.ref_id] =
            item[value.ref_id] && typeof item[value.ref_id] === 'number'
              ? item[value.ref_id]
              : item[value.ref_id] &&
                typeof item[value.ref_id] === 'string' &&
                item[value.ref_id].split('\\n').join('\n')
        } else {
          const formatter = getFormatString(value)
          item[value.ref_id] = formatter ? formatter(item[value.ref_id]) : item[value.ref_id]
        }
      })

      return item
    })
  } else if (Array.isArray(queryResults) && (isCompareBy || cardType === 'pivot')) {
    const timeseriesColumns = columns.filter(column => column.type === 'timeseries')
    if (timeseriesColumns && timeseriesColumns.length) {
      queryResults.map(item => {
        timeseriesColumns.forEach(timeseriesColumn => {
          if (item[timeseriesColumn.ref_id] || Number.isInteger(item[timeseriesColumn.ref_id])) {
            const timeArr = item[timeseriesColumn.ref_id].split('||')
            // pivot timeseries dates have 2 items: 2019-03-25T00:00:00||03-25-2019 - get 2nd date which is formatted date
            // compare by timeseries dates have 4 items: 2019-03-25T00:00:00||03-25-2019||2019-04-08T00:00:00||04-08-2019 - get 4th date which is formatted actual date
            item[timeseriesColumn.ref_id] =
              timeArr && timeArr.length === 2 ? timeArr[1] : timeArr && timeArr.length === 4 ? timeArr[3] : timeArr[0]
          }
        })
        return item
      })
    }
  }

  const options = { delimiter: { wrap: '"' }, prependHeader: false }
  if (cardType === 'tree_table' || cardType === 'pivot') {
    // for tree table expanded nodes can result in many undefined vals
    // this sets those undefined vals to empty strings
    options.emptyFieldValue = ''
  }

  if (totalResults) {
    _returnFormattedTotals(totalResults, columns, queryResults)
    if (cardType !== 'table' || !(cardType === 'table' && tableTotal?.toLowerCase() === 'top')) {
      queryResults.push(totalResults)
    }
  }

  if (
    cardQueryData &&
    !isCompareBy &&
    Array.isArray(queryResults) &&
    cardType !== 'pivot' &&
    cardType !== 'tree_table'
  ) {
    let columnObj = {}
    for (const column of cardQueryData.columns) {
      columnObj[column.ref_id] = column.column_display_name.toUpperCase()
    }

    // Update columnObj when lock or compare
    if (displayedColsHeader && displayedColsHeader.length > 0) {
      const tempColumnObj = {}
      for (const columnKey in columnObj) {
        if (
          getColIdx(displayedColsHeader, 'name', columnKey) > -1 ||
          getColIdx(displayedColsHeader, 'ref_id', columnKey) > -1 ||
          getColIdx(displayedColsHeader, 'display_name', columnObj[columnKey]) > -1 ||
          (columnKey === TIME_COLUMN_STR && getColIdx(displayedColsHeader, '  ', columnObj[columnKey]) > -1)
        ) {
          tempColumnObj[columnKey] = columnObj[columnKey]
        }
      }
      columnObj = tempColumnObj
    }

    queryResults.unshift(columnObj)
  }
  // insert header row for tree table
  if (displayedColsHeader && cardType === 'tree_table') {
    queryResults.unshift(displayedColsHeader)
  }
  const csvExportDetail = getCsvFooter(props, filters)

  return new Promise((resolve, reject) => {
    jsonCsv
      .json2csvAsync(queryResults, options)
      .then(value => {
        resolve({
          csvData: `${value}\n\n${csvExportDetail}`,
        })
      })
      .catch(err => {
        reject(err)
      })
  })
}

export const getCsvFooter = (props, filters) => {
  const { renderCard, cardId, timePeriod } = props
  const renderCardData = renderCard[props.cardId]?.data || {}
  const renderCardConfig = renderCardData?.card_config || {}
  const { card_title } = renderCardConfig.card_attribute // eslint-disable-line
  // Add exported timestamp to export
  const exportedAt = `\nExported at: ${moment().format('MM/DD/YYYY h:mm:ss a')}`
  const filterList = _returnFilterListAsString(filters)
  const dataTimeWindow = renderCardData?.rendered_interval
    ? _returnDateTimeWindowAsString(renderCardData?.rendered_interval)
    : ''
  const cardIdExport = `Card ID: ${cardId}`
  const cardNameExport = `Card Name: ${card_title}` // eslint-disable-line

  const interval = Object.keys(timePeriod).length
    ? timePeriod.interval
    : renderCardConfig.card_query_attribute.time_period.interval // Coming from a dashboard, if the user doesnt set a time period and its all time, the timePeriod object will be empty - 49754518/Art
  const cardTimeIntervalExport = `Time Period: ${interval}`

  const partialRows = renderCardData?.partial_rows // eslint-disable-line camelcase
  const allRowsDisplayed = `\nAre all rows displayed: ${partialRows ? 'no' : 'yes'}` // eslint-disable-line camelcase
  const rowsReturned = `Number of rows: ${renderCardData?.rows_returned}` // eslint-disable-line camelcase

  return `${exportedAt}\n${cardIdExport}\n${cardNameExport}\n${allRowsDisplayed}\n${rowsReturned}\n${cardTimeIntervalExport}\n${dataTimeWindow}\n${filterList}`
}

const _returnFormattedTotals = (totalResults, columns, queryResults) => {
  Object.keys(totalResults).forEach(column => {
    const value = columns.find(item => item.ref_id === column)
    // Sometimes this gets run through twice. The second time will throw an error if its a string; percentage and currency_USD get cast to strings  - Art/49754518
    if (value) {
      const formatter = getFormatString(value)
      totalResults[value.ref_id] = formatter ? formatter(totalResults[value.ref_id]) : totalResults[value.ref_id]
    }
  })

  // Adding 'Total' text when table-column has total values. Using renderCard columns to handle drilldown scenario as well
  const renderedColumns = columns

  for (const column of renderedColumns) {
    if (
      column.type &&
      (column.type === 'dynamic_dimension' || column.type === 'dimension' || column.type === 'timeseries') &&
      queryResults &&
      queryResults.length &&
      queryResults[0][column.ref_id]
    ) {
      for (const columnKey in totalResults) {
        if (columnKey === column.ref_id) {
          totalResults[columnKey] = 'Total'
          break
        }
      }
      break
    }
  }
}

const _returnDateTimeWindowAsString = renderedInterval => {
  if (renderedInterval) {
    const { begin, end } = renderedInterval
    return `Data Time Window:\nBegin: ${begin}\nEnd: ${end}\n`
  } else {
    return ''
  }
}

const _returnFilterListAsString = filters => {
  if (filters && filters.length) {
    return filters.reduce((prev, item) => {
      prev += `Name: ${item.passedViaRouting ? item.dimension : item.display_name || item.dimension}\nType: ${
        item.type
      }\nPattern: ${item.pattern}\n\n`

      return prev
    }, '\nFilters: \n\n')
  } else {
    return ''
  }
}
/**
 * Removes React Table properties from data obj before exporting
 * @param {object} resultObj reference to query_results object that needs cleaning
 */
const deleteUnusedReactTableAttributes = resultObj => {
  delete resultObj._index
  delete resultObj._nestingLevel
  delete resultObj._original
  delete resultObj._subRows
}
export const isSafari = () => /^((?!chrome|android).)*safari/i.test(navigator.userAgent)

export const buildURI = data => {
  const csv = data
  const type = isSafari() ? 'application/csv' : 'text/csv'
  const blob = new Blob(['\uFEFF', csv], { type })
  const dataURI = `data:${type};charset=utf-8,${'\uFEFF'}${csv}`

  const URL = window.URL || window.webkitURL

  return typeof URL.createObjectURL === 'undefined' ? dataURI : URL.createObjectURL(blob)
}
