import isEqual from 'lodash/isEqual'

export function getCardFilterObject(
  filterDetails,
  pattern,
  type,
  saveAsDefault,
  value,
  aggregationValue,
  lowerValue,
  upperValue
) {
  const filterValue = {
    type,
    saveAsDefault,
    dimension: filterDetails.dimension,
    display_name: filterDetails.display_name, // need to handle display name here????
    ref_id: filterDetails.ref_id,
    obj_type: filterDetails.obj_type,
  }
  if (!value && !(lowerValue || lowerValue === 0)) {
    if ((pattern?.interval || pattern?.granularity) && type === 'interval') {
      filterValue.time_period = pattern
    } else {
      filterValue.pattern = type === 'regex' || type === 'contains' ? pattern.toString() : pattern
    }
  } else if (!value) {
    filterValue.aggregation_type = aggregationValue
    filterValue.lowervalue = Number.parseFloat(lowerValue)
    filterValue.uppervalue = Number.parseFloat(upperValue)
  } else {
    if (aggregationValue) {
      filterValue.aggregation_type = aggregationValue
    }
    filterValue.value = Number.parseFloat(value)
  }
  return filterValue
}

export function getDashboardFilterObject(filterDetails, pattern, type, saveAsDefault) {
  const cardFilters = _getCardFilterForDashboard(filterDetails.filter, pattern, type, saveAsDefault)
  return {
    filter_name: filterDetails.filter_name,
    filter_id: filterDetails.filter_id,
    cardFilters,
  }
}

export const prepareFilterPayload = filters =>
  Array.isArray(filters)
    ? filters.map(filter => {
        const filterClone = Object.assign({}, filter)

        delete filterClone.saveAsDefault
        delete filterClone.passedViaRouting

        return filterClone
      }) || []
    : {}

export function getDashboardAppliedFilterArrForSavedFilters(savedFilters = [], viewerFilters) {
  const appliedFilters = []

  savedFilters.forEach(savedFilter => {
    if (viewerFilters.findIndex(filter => filter.filter_id === savedFilter.filter_builder_id) > -1) {
      const cardFilters = viewerFilters
        .find(filter => filter.filter_id === savedFilter.filter_builder_id)
        .filter.map(filter => ({
          cardId: filter.card_id,
          filter: {
            pattern: savedFilter.pattern,
            saveAsDefault: true,
            dimension: filter.filter_column,
            type: savedFilter.type,
          },
        }))
      appliedFilters.push({
        filter_name: savedFilter.filter_name,
        filter_id: savedFilter.filter_builder_id,
        cardFilters,
      })
    }
  })

  return appliedFilters
}

export function getDashboardDefaultFilterPayload(savedFilters = [], viewerFilters, cardId) {
  const filterPayloadArr = []

  savedFilters.forEach(savedFilter => {
    if (viewerFilters.findIndex(filter => filter.filter_id === savedFilter.filter_builder_id) > -1) {
      const filterDetails = viewerFilters
        .find(filter => filter.filter_id === savedFilter.filter_builder_id)
        .filter.find(filter => Number(filter.card_id) === Number(cardId))
      if (filterDetails) {
        const filterPayload = {
          dimension: filterDetails.filter_column,
          pattern: savedFilter.pattern,
          type: savedFilter.type,
        }
        filterPayloadArr.push(filterPayload)
      }
    }
  })

  return filterPayloadArr
}

export function getFilterCtrlValues(activeFilters, selectedFilter, type = 'card') {
  const filterDetails = activeFilters.find(filter => filter.filter_id === selectedFilter.filter_id)

  switch (type) {
    case 'dashboard':
      return filterDetails && filterDetails.cardFilters ? filterDetails.cardFilters[0].filter : null
    case 'card':
      return activeFilters.find(
        filter =>
          (filter.dimension && filter.dimension === selectedFilter.dimension) ||
          (filter.ref_id && filter.ref_id === selectedFilter.ref_id)
      )
  }
}

export function updateDefaultFilters(filters) {
  return filters.map(filter => Object.assign(filter, { saveAsDefault: true }))
}

export function getDashboardFiltersForCard(dashboardFilters = [], cardId) {
  const filterPayload = []

  dashboardFilters.forEach(dashboardFilter => {
    const filter = dashboardFilter.cardFilters.find(cardFilter => cardFilter.cardId === Number(cardId))
    if (filter && filter.filter) {
      filterPayload.push(filter.filter)
    }
  })

  return prepareFilterPayload(filterPayload)
}

function _getCardFilterForDashboard(cardFilterDetails, pattern, type, saveAsDefault) {
  const cardFilters = []
  let cardFilter = {}

  for (const filterDetail of cardFilterDetails) {
    cardFilter = {
      pattern: type === 'regex' || type === 'contains' ? pattern.toString() : pattern,
      type,
      saveAsDefault,
      dimension: filterDetail.filter_column,
    }
    if (pattern?.interval && type === 'interval') {
      delete cardFilter.pattern
      cardFilter.time_period = pattern
    }
    cardFilters.push({ cardId: filterDetail.card_id, filter: cardFilter })
  }
  return cardFilters
}

/**
 * @param {array} filters a provided list of filter objects
 * @param {array} newFilter a list of 1 or more filter objects, optional
 * @return {array} returns an array with only unique values - filters are deep checked by _.isEqual
 */
export const getUniqueFilters = (filters, newFilter = []) =>
  filters.concat(newFilter).reduce((acc, curr) => {
    let isUnique = true

    acc.forEach(f => {
      if (isEqual(f, curr)) {
        isUnique = false
      }
    })

    if (isUnique) {
      acc.push(curr)
    }

    return acc
  }, [])

export function getFilteredColumn(selectedFilter, cardsDatasetStatus, datasetId, columnArray) {
  if (
    cardsDatasetStatus[datasetId] &&
    cardsDatasetStatus[datasetId].data &&
    selectedFilter &&
    Object.keys(selectedFilter).length
  ) {
    const datasetColumns = cardsDatasetStatus[datasetId].data.fields_array || []
    const matchedColumn = datasetColumns.find(column => column.field_name === selectedFilter.dimension) || {}

    if (selectedFilter.obj_type && selectedFilter.obj_type === 'column' && selectedFilter.ref_id) {
      const selColumn = columnArray.find(column => column.ref_id === selectedFilter.ref_id)
      return selColumn && Object.keys(selColumn)?.length > 0 ? selColumn : matchedColumn
    } else {
      return matchedColumn
    }
  }
  return {}
}
/**
 * @param {array} filters dashboard filters
 * @return {array} new filters that may or may not have been untruncated
 */
export const untruncateFiltersForDashboards = filters => {
  filters.forEach(state => {
    state.cardFilters.forEach(cardfilter => {
      cardfilter.filter = {
        dimension: cardfilter.filter.d || cardfilter.filter.dimension,
        pattern: cardfilter.filter.p || cardfilter.filter.pattern,
        type: cardfilter.filter.t || cardfilter.filter.type,
      }
    })
  })

  return filters
}
