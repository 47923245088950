import React from 'react'
import PropTypes from 'prop-types'
import DownshiftMultiple from './DownshiftMultiple.component'

class GreenfieldAutoComplete extends React.Component {
  render() {
    return (
      <DownshiftMultiple
        variant={this.props.variant}
        isEditable={this.props.isEditable}
        label={this.props.label}
        default={this.props.default}
        suggestions={this.props.suggestions}
        searchApi={this.props.searchApi}
        searchStatus={this.props.searchStatus}
        selectedSuggestions={this.props.selectedSuggestions}
        labelText={this.props.labelText}
        type={this.props.type}
        onOwnerFocus={this.props.onOwnerFocus}
        showLabel={this.props.showLabel}
        showEmptyFieldError={this.props.showEmptyFieldError}
        isSingleSelection={this.props.isSingleSelection}
        getObjectStatus={this.props.getObjectStatus}
      />
    )
  }
}

GreenfieldAutoComplete.defaultProps = {
  isEditable: 'true',
  label: '',
  default: [],
  suggestions: [],
  searchApi: () => {},
  selectedSuggestions: () => {},
  searchStatus: {},
  labelText: '',
  showLabel: false,
  showEmptyFieldError: false,
  isSingleSelection: false,
  getObjectStatus: {},
}

GreenfieldAutoComplete.propTypes = {
  isEditable: PropTypes.bool,
  label: PropTypes.string,
  default: PropTypes.array,
  suggestions: PropTypes.array,
  searchApi: PropTypes.func,
  searchStatus: PropTypes.object,
  selectedSuggestions: PropTypes.func,
  labelText: PropTypes.string,
  showLabel: PropTypes.bool,
  showEmptyFieldError: PropTypes.bool,
  isSingleSelection: PropTypes.bool,
  getObjectStatus: PropTypes.object,
}

export default GreenfieldAutoComplete
