import React, { useEffect, useMemo, useRef, useState } from 'react'
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Grid,
  IconButton,
  Radio,
  TextField,
  Typography,
  Autocomplete,
  MenuItem,
  CircularProgress,
} from '@mui/material'
import debounce from 'lodash/debounce'
import cloneDeep from 'lodash/cloneDeep'
import isEmpty from 'lodash/isEmpty'
import { Close } from '@mui/icons-material'
import styled from 'styled-components'
import { createErrMsg, Spinner } from 'greenfield-utilities'
import { useDispatch, useSelector } from 'react-redux'

import { GreenfieldAutoComplete } from '../../shared/shared'
import { clearDetailBusinessAreas, clearDetailOwnersInfo, getDetailsBusinessAreas } from '../../../ducks/builder'
import { clearSiteData, updateSelectedSiteReq } from '../../../ducks/site'
import { displayServiceErrorMessage } from '../../../ducks/layout'
import { clearViewDashboardInfo, getViewDashboardInfo, searchDashboardList } from '../../../ducks/dashboard'
import GFAutocomplete from '../../shared/MUIAutoComplete/Autocomplete'

const HeaderDiv = styled.div`
  display: flex;
  justify-content: space-between;

  h2 {
    margin-top: 10px;
  }
`
const FieldDiv = styled.div`
  margin-top: 15px;
`

export const AddEditSiteDialog = ({ isOpen = false, editSiteData = {}, setIsOpen }) => {
  const dispatch = useDispatch()
  const defaultObj = {
    labels: [],
  }
  const isAddSite = isEmpty(editSiteData)
  const [focusedUser, setFocusedUser] = useState('')
  const [dashboardSearchValue, setDashboardSearchValue] = useState('')
  const [dashboardSuggestions, setDashboardSuggestions] = useState([])
  const [selectedData, setSelectedData] = useState(editSiteData || {})
  const [ownersList, setOwnersList] = useState(editSiteData?.owners_access || [])
  const [viewersList, setViewersList] = useState(editSiteData?.viewers_access || [])
  const [addedDashboard, setAddedDashboard] = useState([])
  // tags
  const [tag, setTag] = useState('')
  const [tagSearchText, setTagSearchText] = useState('')
  const [loading, setLoading] = useState(false)
  const [options, setOptions] = useState([])

  const { dashBoardInfoStatus, viewDashBoardInfoStatus } = useSelector(state => state.dashboard)
  const { detailsBusinessAreasStatus } = useSelector(state => state.builder)
  const updateSiteResponse = useSelector(state => state.site.updateSiteStatus || {})

  const updatedDefaultDashboard = cloneDeep(defaultObj)

  if (addedDashboard.length) {
    updatedDefaultDashboard.labels = addedDashboard.map(dashObj => dashObj.dashboard_title || dashObj.label)
  }

  const searchDashboardInfo = useRef(
    debounce(searchText => {
      dispatch(
        searchDashboardList({
          pageIndex: 1,
          pageSize: 10,
          searchString: searchText,
          searchInUserData: 'no',
          sortColumn: '"favorite:asc"',
        })
      )
    }, 1000)
  )

  const searchBusinessAreas = useMemo(() => {
    return debounce(searchText => {
      dispatch(getDetailsBusinessAreas(searchText))
    }, 1000)
  }, [])

  const filterUserType = (usersArr, type) => {
    return usersArr.reduce((accumulator, eachOwner) => {
      if (eachOwner.type === type) {
        accumulator.push(eachOwner._id)
      }
      return accumulator
    }, [])
  }

  useEffect(() => {
    if (detailsBusinessAreasStatus?.data) {
      setLoading(false)
      setOptions(detailsBusinessAreasStatus.data)
    }
  }, [detailsBusinessAreasStatus])

  useEffect(() => {
    if (tagSearchText.length >= 3) {
      setLoading(true)
      searchBusinessAreas(tagSearchText)
    }
  }, [tagSearchText])

  useEffect(() => {
    if (dashboardSearchValue) {
      searchDashboardInfo.current(dashboardSearchValue)
    }
  }, [dashboardSearchValue])

  useEffect(() => {
    if (dashBoardInfoStatus?.data) {
      const dashboardSuggestions = dashBoardInfoStatus.data.map(item => ({
        _id: item._id,
        label: item.dashboard_title,
      }))
      setDashboardSuggestions(dashboardSuggestions)
    }
  }, [dashBoardInfoStatus])

  useEffect(() => {
    if (updateSiteResponse?.data) {
      handleDialogClose()
    } else if (updateSiteResponse?.status === 'failed') {
      dispatch(
        displayServiceErrorMessage(createErrMsg(updateSiteResponse, 'Your attempt to edit this site has failed.'))
      )
    }
  }, [updateSiteResponse])

  useEffect(() => {
    if (viewDashBoardInfoStatus?.data) {
      setAddedDashboard([viewDashBoardInfoStatus?.data])
    } else if (viewDashBoardInfoStatus?.status === 'failed') {
      dispatch(displayServiceErrorMessage(createErrMsg(updateSiteResponse, 'Fetch dashboard info is failed.')))
    }
  }, [viewDashBoardInfoStatus])

  useEffect(() => {
    if (editSiteData?.pages && editSiteData?.pages?.length > 0 && editSiteData.pages[0]?.object_id) {
      dispatch(getViewDashboardInfo(editSiteData.pages[0]?.object_id))
    }

    return () => {
      dispatch(clearSiteData())
    }
  }, [])

  const selectedDashboardName = selections => {
    const filterSelection = { _id: selections._id, label: selections.label }
    setAddedDashboard(filterSelection._id ? [filterSelection] : [])
    updateChange('home', filterSelection)
  }

  const updateChange = (type, value) => {
    const _selectedData = cloneDeep(selectedData)
    if (type === 'home') {
      const dashboardId = value?._id
      if (_selectedData?.pages && _selectedData?.pages?.length > 0) {
        _selectedData.pages[0].object_id = parseInt(dashboardId, 10)
      } else {
        _selectedData.pages = [
          {
            object_id: dashboardId,
            object_type: 'dashboard',
          },
        ]
      }
    } else {
      _selectedData[type] = value
    }
    setSelectedData(_selectedData)
  }

  const addOrUpdateSelectedSite = () => {
    const data = {
      ...selectedData,
      ...(isAddSite
        ? {
            tag,
            content: ['card', 'dataset', 'dashboard'].map(obj => {
              return { object_type: obj, object_tags: [tag] }
            }),
            route: selectedData.route,
          }
        : {}),
      owners: ownersList && filterUserType(ownersList, 'user'),
      owner_groups: ownersList && filterUserType(ownersList, 'group'),
      user_view_access: viewersList && filterUserType(viewersList, 'user'),
      group_view_access: viewersList && filterUserType(viewersList, 'group'),
      isAddSite,
    }
    dispatch(updateSelectedSiteReq(data))
  }

  const handleDialogClose = () => {
    setIsOpen(false)
    dispatch(clearViewDashboardInfo())
    dispatch(clearDetailBusinessAreas())
  }

  const isDisabled = isAddSite ? false : updateSiteResponse?.status === 'requested'
  const selectedDashboard = selectedData?.pages?.length > 0 ? selectedData.pages[0].object_id : ''
  return (
    <Dialog maxWidth="md" fullWidth open={isOpen} aria-labelledby="Edit site dialog">
      <DialogTitle data-cy="edit-site-dialog-header">
        <HeaderDiv>
          <Typography variant="h2">{isAddSite ? 'Add Site' : 'Edit Site'}</Typography>
          <IconButton
            disabled={isDisabled}
            data-cy="close-btn"
            aria-label="Close Site Dialog"
            onClick={handleDialogClose}
          >
            <Close />
          </IconButton>
        </HeaderDiv>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container justifyContent="space-between">
          <Grid item xs={12}>
            <FieldDiv>
              <Typography variant="subtitle1">Site Name*</Typography>
              <TextField
                required
                fullWidth
                disabled={isDisabled}
                data-cy="site-name"
                aria-label="site name"
                onChange={event => updateChange('name', event.target.value)}
                value={selectedData.name}
              />
            </FieldDiv>
            <FieldDiv>
              <Typography variant="subtitle1">Site Route*</Typography>
              <TextField
                required
                fullWidth
                disabled={isDisabled}
                data-cy="route"
                aria-label="route"
                onChange={event => updateChange('route', event.target.value)}
                value={selectedData.route}
              />
            </FieldDiv>
            <FieldDiv>
              <Typography variant="subtitle1">Site Description*</Typography>
              <TextField
                multiline
                required
                fullWidth
                data-cy="site-description"
                disabled={isDisabled}
                aria-label="site description"
                onChange={event => updateChange('description', event.target.value)}
                value={selectedData.description}
              />
            </FieldDiv>
            {isAddSite && (
              <FieldDiv>
                <Typography variant="subtitle1">Site Tag*</Typography>
                <Autocomplete
                  value={tag}
                  onChange={(event, newValue) => {
                    setTag(newValue)
                  }}
                  isOptionEqualToValue={(option, value) => option === value || value === ''}
                  id="business-area"
                  data-cy="autocomplete-label"
                  options={options}
                  renderOption={(props, option) => <MenuItem {...props}>{option}</MenuItem>}
                  renderInput={params => (
                    <TextField
                      fullWidth
                      onChange={event => setTagSearchText(event.target.value)}
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        style: tag?.length > 2 ? { flexWrap: 'wrap', paddingTop: '5px' } : { paddingTop: '5px' },
                        endAdornment: (
                          <>
                            {loading && tagSearchText.length > 2 ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                />
              </FieldDiv>
            )}
            <FieldDiv>
              <Typography variant="subtitle1">Owner(s)*</Typography>
              <GFAutocomplete label="" value={ownersList} setValue={setOwnersList} id="add-owners" />
            </FieldDiv>
          </Grid>
          <Grid item xs={12}>
            <FieldDiv>
              <Typography variant="subtitle1">Home Dashboard*</Typography>
              <GreenfieldAutoComplete
                variant="outlined"
                isEditable={!isDisabled}
                label=""
                searchApi={value => {
                  if (focusedUser !== 'homeDashboard') {
                    dispatch(clearDetailOwnersInfo())
                    setFocusedUser('homeDashboard')
                  }
                  setDashboardSearchValue(value)
                }}
                searchStatus={dashBoardInfoStatus}
                suggestions={dashboardSuggestions}
                selectedSuggestions={selectedDashboardName}
                default={viewDashBoardInfoStatus?.status === 'requested' ? [] : updatedDefaultDashboard.labels}
                labelText="SiteHome"
                getObjectStatus={viewDashBoardInfoStatus}
                isSingleSelection
              />
            </FieldDiv>
            <FieldDiv>
              <Typography variant="subtitle1">Data Classification</Typography>
              <div>
                <span>
                  <Radio
                    label="Confidential"
                    data-cy="site-privacy-confidential"
                    checked={selectedData.data_classification === 'Confidential'}
                    onChange={event => updateChange('data_classification', event.target.value)}
                    value="Confidential"
                    name="data_classification"
                    aria-label="Confidential"
                    disabled={isDisabled}
                  />
                  Confidential
                </span>
                <span>
                  <Radio
                    data-cy="site-privacy-internal"
                    checked={selectedData.data_classification === 'Internal'}
                    onChange={event => updateChange('data_classification', event.target.value)}
                    value="Internal"
                    name="data_classification"
                    aria-label="Internal"
                    disabled={isDisabled}
                  />
                  Internal
                </span>
              </div>
            </FieldDiv>
            <FieldDiv>
              {selectedData.data_classification === 'Confidential' && (
                <FieldDiv>
                  <Typography variant="subtitle1">Viewer(s)</Typography>
                  <GFAutocomplete value={viewersList} setValue={setViewersList} id="add-owners" />
                </FieldDiv>
              )}
            </FieldDiv>
          </Grid>
        </Grid>
        {updateSiteResponse?.status === 'requested' && (
          <Spinner style={{ top: '0px' }} size="large" layout="selfCentering" />
        )}
      </DialogContent>
      <DialogActions>
        <Button
          disabled={isDisabled}
          data-cy="cancel-edit-site"
          aria-label="cancel"
          variant="text"
          color="secondary"
          onClick={handleDialogClose}
        >
          Cancel
        </Button>
        <Button
          disabled={isDisabled || !selectedData.name || !selectedData.description || !selectedDashboard || !ownersList}
          data-cy="save-site"
          variant="text"
          color="primary"
          aria-label="save"
          onClick={addOrUpdateSelectedSite}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}
