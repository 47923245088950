import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Gfv from 'greenfield-visualizations'
import { createErrMsg } from 'greenfield-utilities'

import apiConfig from '../../../config/apiConfig'
import { updateCardWithoutRerender, updateTreeTableCsvData, clearTreeTableCsvData } from '../../../ducks/cards'
import { displayServiceErrorMessage } from '../../../ducks/layout'

class TreetableContainer extends React.Component {
  render() {
    return <Gfv apiKey={apiConfig.gnfapi.apikey} {...this.props} />
  }
}

export const mapDispatchToProps = dispatch => ({
  updateCardWithoutRerender(data) {
    dispatch(updateCardWithoutRerender(data))
  },
  updateTreeTableCsvData(data) {
    dispatch(updateTreeTableCsvData(data))
  },
  clearTreeTableCsvData(data) {
    dispatch(clearTreeTableCsvData(data))
  },
  displayServiceErrorMessage(data) {
    dispatch(displayServiceErrorMessage(createErrMsg(data)))
  },
})

TreetableContainer.defaultProps = {
  renderCard: {},
  isSubTable: false,
  row: {},
  timePeriod: {},
  dimensionColumn: {},
  drill: [],
  cardRenderFilters: [],
  cardSize: {},
  isHomePage: false,
}

TreetableContainer.propTypes = {
  renderCard: PropTypes.object,
  isSubTable: PropTypes.bool,
  row: PropTypes.object,
  timePeriod: PropTypes.object,
  dimensionColumn: PropTypes.object,
  drill: PropTypes.array,
  cardRenderFilters: PropTypes.array,
  cardSize: PropTypes.object,
  isHomePage: PropTypes.bool,
  updateCardWithoutRerender: PropTypes.func,
  columnWidth: PropTypes.string,
}

export default connect(null, mapDispatchToProps)(TreetableContainer)
