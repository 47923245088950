import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { stringify } from 'qs'
import startCase from 'lodash/startCase'
import styled from 'styled-components'
import { CardContent, Grid, IconButton, Skeleton, Stack, SvgIcon, Tooltip, Typography } from '@mui/material'
import { Spinner } from 'greenfield-utilities'
import { useDispatch } from 'react-redux'
import { Box } from '@mui/system'
import { Link } from 'react-router-dom'
import {
  DashboardOutlined,
  EditOutlined,
  InsertChartOutlined,
  Map,
  TextSnippetOutlined,
  TrendingUp,
  ViewQuilt,
} from '@mui/icons-material'
import moment from 'moment'
import { GreenfieldFavoriteButton } from '../../shared/shared'
import {
  AreaChartIcon,
  BigNumberIcon,
  BoxPlotIcon,
  BubbleChartIcon,
  CertifiedIcon,
  DatabaseIcon,
  DonutChartIcon,
  HeatMapIcon,
  PieChartIcon,
  ScatterPlotChartIcon,
  SimpleTableIcon,
} from '../../shared/Svgicon/Svgicon'
import { displayServiceErrorMessage } from '../../../ducks/layout'
import {
  MAP_VIZ_TYPES,
  SIMPLE_BAR_VIZ_TYPES_LIST,
  STACK_BAR_VIZ_TYPES,
  TABLE_VIZ_TYPES,
  TEXT_VIZ_TYPES,
} from '../../constants/ArrayConstants'
import { SecurityRenderer } from '../Datasets/DatasetCellRenderer/DatasetCellRenderers'
import { AssetType } from '../../shared/AssetTableSS/AssetTable'
import { API_GATEWAY_URL } from '../../../ducks/utils'

export const Hover = styled.div({
  opacity: 0,
  transition: 'opacity 350ms ease',
  position: 'absolute',
  right: '0',
  bottom: '0',
  background: 'transparent',
  padding: '5px',
  boxSizing: 'border-box',
})

const SCard = styled.div({
  position: 'relative',
  width: '500px',
  height: '150px',
  cursor: 'pointer',
  background: 'white',
  transition: '0.3s',
  [`:hover ${Hover}`]: {
    opacity: 1,
  },
})

const STypography = styled(Typography)`
  font-weight: 500;
  font-size: 16px;
  margin-left: 1rem;
`

const { CARDS, DASHBOARDS, DATASETS } = AssetType

const AllAssetsListing = ({
  searchText,
  cardCount,
  datasetCount,
  dashboardCount,
  setTabValue,
  setCardCount,
  setDatasetCount,
  setDashboardCount,
  setAllAssetsCount,
}) => {
  const [cards, setCards] = useState([])
  const [datasets, setDatasets] = useState([])
  const [dashboards, setDashboards] = useState([])
  const [cardsLoading, setCardsLoading] = useState(false)
  const [dashboardsLoading, setDashboardsLoading] = useState(false)
  const [datasetsLoading, setDatasetsLoading] = useState(false)
  const [isLoading, setIsLoading] = useState({
    cardCountLoading: false,
    dashboardCountLoading: false,
    datasetCountLoading: false,
  })

  const dispatch = useDispatch()
  const { cardCountLoading, dashboardCountLoading, datasetCountLoading } = isLoading

  useEffect(() => {
    if (!cardCountLoading && !dashboardCountLoading && !datasetCountLoading) {
      setAllAssetsCount(datasetCount + dashboardCount + cardCount)
    }
  }, [isLoading])

  useEffect(() => {
    if (searchText) {
      resetData()
      setCardsLoading(true)
      setDashboardsLoading(true)
      setDatasetsLoading(true)
      setIsLoading({
        dashboardCountLoading: true,
        cardCountLoading: true,
        datasetCountLoading: true,
      })

      const queryParams = {
        page: 1,
        per_page: 10,
        field_groups: 'quick_metadata',
        search_string: searchText,
        search_in_user_data: 'no',
      }
      const stringifiedParams = stringify(queryParams)

      axios
        .get(`${API_GATEWAY_URL}/bi_reporting_assets/v3/cards/count?status=active&search_string=${searchText}`)
        .then(resp => {
          setCardCount(resp.data.count)
          setIsLoading(prev => ({ ...prev, cardCountLoading: false }))
        })
        .catch(err => {
          setIsLoading(prev => ({ ...prev, cardCountLoading: false }))
          dispatch(displayServiceErrorMessage(err.message, 'Something went wrong with the request, please try again'))
          // eslint-disable-next-line no-console
          console.warn(err)
        })

      axios
        .get(`${API_GATEWAY_URL}/bi_reporting_assets/v2/datasets/count?status=active&search_string=${searchText}`)
        .then(resp => {
          setDatasetCount(resp.data.count)
          setIsLoading(prev => ({ ...prev, datasetCountLoading: false }))
        })
        .catch(err => {
          setIsLoading(prev => ({ ...prev, datasetCountLoading: false }))
          dispatch(displayServiceErrorMessage(err.message, 'Something went wrong with the request, please try again'))
          // eslint-disable-next-line no-console
          console.warn(err)
        })

      axios
        .get(`${API_GATEWAY_URL}/bi_reporting_assets/v2/dashboards/count?status=active&search_string=${searchText}`)
        .then(resp => {
          setIsLoading(prev => ({ ...prev, dashboardCountLoading: false }))
          setDashboardCount(resp.data.count)
        })
        .catch(err => {
          setIsLoading(prev => ({ ...prev, dashboardCountLoading: false }))

          dispatch(displayServiceErrorMessage(err.message, 'Something went wrong with the request, please try again'))
          // eslint-disable-next-line no-console
          console.warn(err)
        })
      axios.get(`${API_GATEWAY_URL}/bi_reporting_assets/v3/cards?${stringifiedParams}`).then(resp => {
        setCards(resp.data)
        setCardsLoading(false)
      })
      axios.get(`${API_GATEWAY_URL}/bi_reporting_assets/v2/dashboards?${stringifiedParams}`).then(resp => {
        setDashboards(resp.data)
        setDashboardsLoading(false)
      })
      axios.get(`${API_GATEWAY_URL}/bi_reporting_assets/v2/datasets?${stringifiedParams}`).then(resp => {
        setDatasets(resp.data)
        setDatasetsLoading(false)
      })
    }
  }, [searchText])

  const resetData = () => {
    setCardCount(0)
    setDashboardCount(0)
    setDatasetCount(0)
    setAllAssetsCount(0)
    setCards([])
    setDashboards([])
    setDatasets([])
  }

  const AssetComponent = ({ label, count, asset }) => (
    <Box m={1}>
      <Grid container direction="column">
        {asset.map((item, index) => {
          const businessUse = item.card_attribute?.business_use || item?.business_use
          const businessName = businessUse?.length > 0 ? businessUse.join(', ') : ''
          const toUrl = `${
            label === CARDS
              ? item.viz_type === 'text'
                ? '/textcard/'
                : '/card/'
              : label === DASHBOARDS
              ? '/dashboard/'
              : '/dataset/preview/'
          }${item._id}`
          const itemData = item.card_count
            ? ` ${item.card_count} Cards`
            : label === DATASETS
            ? item.database_name
            : label === CARDS && startCase(item.card_attribute?.viz_type)
          return (
            <Box key={item._id} m={1}>
              <SCard key={item._id}>
                <CardContent>
                  <Grid container justifyContent="space-between">
                    <Grid item xs={2}>
                      {getCardImage(item.card_attribute?.viz_type || label)}
                    </Grid>
                    <Grid item xs={10}>
                      <Tooltip
                        position="bottom"
                        title={item.card_attribute?.card_title || item?.dataset_business_name || item?.dashboard_title}
                      >
                        <div
                          style={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            maxWidth: '380px',
                          }}
                        >
                          <Typography
                            component={Link}
                            to={toUrl}
                            sx={{ textDecoration: 'none', color: '#3e72db' }}
                            noWrap
                            display="inline"
                            variant="h6"
                          >
                            {item.card_attribute?.card_title || item?.dataset_business_name || item?.dashboard_title}
                          </Typography>
                        </div>
                      </Tooltip>
                      <Stack direction="row" spacing={1}>
                        <Box
                          sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            maxWidth: '280px',
                            padding: 0,
                          }}
                        >
                          <Typography noWrap display="inline">
                            {item.card_attribute?.owners_access?.[0]?.user_group_name ||
                              item.owners_access?.[0]?.user_group_name || <>MISSING OWNER</>}
                          </Typography>
                        </Box>
                        {itemData && (
                          <>
                            <Typography>|</Typography>
                            <Typography display="inline">{itemData}</Typography>
                          </>
                        )}
                      </Stack>
                      <Typography>
                        {label === DATASETS ? 'Last Loaded: ' : 'Last Updated: '}
                        {moment(
                          item.card_attribute?.last_updated_date || item?.last_loaded || item?.last_modified_time,
                          "YYYY-MM-DD'T'HH:mm:ssZ"
                        ).fromNow()}
                      </Typography>
                      {businessName && (
                        <Box
                          sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            maxWidth: '400px',
                            padding: 0,
                          }}
                        >
                          <Typography noWrap display="inline">{`Tags: ${businessName}`}</Typography>
                        </Box>
                      )}
                      {label === DATASETS && item.certified === 'yes' && <CertifiedIcon />}
                      {label === DATASETS && <SecurityRenderer data={item} />}
                    </Grid>
                  </Grid>
                </CardContent>
                <Hover>
                  <Grid container justifyContent="flex-end">
                    {(item.card_attribute?.edit_enabled === 'yes' || item?.edit_enabled === 'yes') && (
                      <IconButton
                        component={Link}
                        to={`${
                          label === CARDS
                            ? '/builder/card/'
                            : label === DASHBOARDS
                            ? '/builder/dashboard/'
                            : '/dataset/edit/'
                        }${item._id}`}
                      >
                        <EditOutlined fontSize="small" />
                      </IconButton>
                    )}
                    <GreenfieldFavoriteButton
                      type={label}
                      id={item._id}
                      elementId={item._id + index}
                      isFavorite={item.card_attribute?.favorite || item.favorite}
                    />
                  </Grid>
                </Hover>
              </SCard>
            </Box>
          )
        })}
        {asset.length > 0 && count > 3 && (
          <Box m={1}>
            <SCard
              style={{ height: '50px' }}
              onClick={() => {
                label === CARDS
                  ? setTabValue(1)
                  : label === DASHBOARDS
                  ? setTabValue(2)
                  : label === DATASETS
                  ? setTabValue(3)
                  : setTabValue(0)
              }}
            >
              <CardContent style={{ display: 'flex', justifyContent: 'center' }}>Show More</CardContent>
            </SCard>
          </Box>
        )}
      </Grid>
    </Box>
  )

  const isCountLoading = cardCountLoading || dashboardCountLoading || datasetCountLoading

  return isCountLoading ? (
    <Spinner color="inherit" />
  ) : (
    <>
      <Grid container>
        <Grid item>
          {cardCount ? (
            <>
              <STypography variant="button">{`Cards (${cardCount})`}</STypography>
              {cardsLoading && !cards.length ? (
                <LoadingProgress />
              ) : (
                cardCount > 0 && cards.length > 0 && <AssetComponent label={CARDS} count={cardCount} asset={cards} />
              )}
            </>
          ) : (
            <></>
          )}
        </Grid>
        <Grid item>
          {dashboardCount ? (
            <>
              <STypography variant="button">{`Dashboards (${dashboardCount})`}</STypography>
              {dashboardsLoading && !dashboards.length ? (
                <LoadingProgress />
              ) : (
                <AssetComponent label={DASHBOARDS} count={dashboardCount} asset={dashboards} />
              )}
            </>
          ) : (
            <></>
          )}
        </Grid>
        <Grid item>
          {datasetCount ? (
            <>
              <STypography variant="button">{`Datasets (${datasetCount})`}</STypography>
              {datasetsLoading && !datasets.length ? (
                <LoadingProgress />
              ) : (
                <AssetComponent label={DATASETS} count={datasetCount} asset={datasets} />
              )}
            </>
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
      {!cardCount && !dashboardCount && !datasetCount && <Typography>No Results Found</Typography>}
    </>
  )
}

export default AllAssetsListing

const LoadingProgress = () => (
  <Grid container direction="column">
    {Array(10)
      .fill()
      .map(i => (
        <Box key={i} sx={{ pt: 2, pr: 4 }}>
          <Skeleton animation="wave" variant="rounded" width={500} height={150} />
        </Box>
      ))}
  </Grid>
)

const getCardImage = type => {
  if (SIMPLE_BAR_VIZ_TYPES_LIST.findIndex(l => l.name === type) > -1 || STACK_BAR_VIZ_TYPES.includes(type)) {
    return <InsertChartOutlined sx={{ fontSize: 50 }} />
  }
  if (TABLE_VIZ_TYPES.includes(type)) {
    return <SimpleTableIcon strokeColor="#000" width="50" height="50" />
  }
  if (TEXT_VIZ_TYPES.includes(type)) {
    return <TextSnippetOutlined sx={{ fontSize: 50, stroke: '#ffffff', strokeWidth: 1 }} />
  }
  if (MAP_VIZ_TYPES.includes(type)) {
    return <Map sx={{ fontSize: 50, stroke: '#ffffff', strokeWidth: 1 }} />
  }
  if (['line', 'sparkline'].includes(type)) {
    return <TrendingUp sx={{ fontSize: 50, stroke: '#ffffff', strokeWidth: 1 }} />
  }
  if (type === 'big_number') {
    return <BigNumberIcon width="50" height="50" />
  }
  if (type === 'donut_chart') {
    return <DonutChartIcon width="50" height="50" />
  }
  if (type === 'pie_chart') {
    return <PieChartIcon width="50" height="50" />
  }
  if (type === 'box_plot') {
    return <BoxPlotIcon width="50" height="50" />
  }
  if (type === 'heatmap') {
    return <HeatMapIcon width="50" height="50" />
  }
  if (type === 'scatter_plot') {
    return <ScatterPlotChartIcon width="50" height="50" />
  }
  if (type === 'bubble_chart') {
    return <BubbleChartIcon width="50" height="50" />
  }
  if (type === 'area_chart') {
    return <AreaChartIcon width="50" height="50" />
  }
  if (type === 'dashboards') {
    return <DashboardOutlined sx={{ fontSize: 50, stroke: '#ffffff', strokeWidth: 1 }} />
  }
  if (type === 'datasets') {
    return <SvgIcon component={DatabaseIcon} sx={{ fontSize: 45, stroke: '#ffffff', strokeWidth: 1 }} />
  }
  return <ViewQuilt sx={{ fontSize: 50, stroke: '#ffffff', strokeWidth: 1 }} />
}
