import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useDispatch } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { Spinner } from 'greenfield-utilities'
import { API_GATEWAY_URL } from '../ducks/utils'
import { displayServiceErrorMessage } from '../ducks/layout'

const NO_SHORT_URL_FOUND = 'no-saved-shortlink-found'

const ShortUrlRedirect = routeProps => {
  const { type, id, shortUrl } = routeProps?.match?.params || {}

  const [responseData, updateResponseData] = useState(null)
  const dispatch = useDispatch()

  useEffect(() => {
    axios
      .get(`${API_GATEWAY_URL}/bi_reporting_assets/v2/shortlinks/${shortUrl}`)
      .then(response => {
        updateResponseData(response.data)
      })
      .catch(() => {
        updateResponseData(NO_SHORT_URL_FOUND)
        dispatch(displayServiceErrorMessage('Something went wrong fetching the full URL for the short link'))
      })
  }, [])

  if (!responseData) {
    return <Spinner layout="selfCentering" />
  }

  const redirectUrl = responseData === NO_SHORT_URL_FOUND ? `/${type}/${id}?$invalidShortUrl=true` : responseData

  return <Redirect to={redirectUrl} />
}

export default ShortUrlRedirect
