import Cookies from 'js-cookie'
import React from 'react'
import apiConfig from '../../config/apiConfig'
import Interceptor from './Interceptor'

function getDomainName(hostName) {
  return hostName.substring(hostName.lastIndexOf('.', hostName.lastIndexOf('.') - 1) + 1)
}

export function checkUserAuthStatus(auth) {
  const userInfo =
    auth && auth.session && auth.session.userInfo && auth.session.identity
      ? Object.assign({}, auth.session.identity, auth.session.userInfo)
      : {}
  const lanId = userInfo.samaccountname
  const domain = getDomainName(window.location.hostname)

  if (domain === 'partnersonline.com') {
    // We're on a POL site of some kind
    const SSOSESSIONINFOCookie = Cookies.get('SSOSESSIONINFO')

    if (SSOSESSIONINFOCookie) {
      const SSOSESSIONINFOcoded = window.atob(SSOSESSIONINFOCookie)
      const SSOSESSIONINFOJson = JSON.parse(SSOSESSIONINFOcoded)

      if (!SSOSESSIONINFOJson.user || !SSOSESSIONINFOJson.login_status) {
        // Its a POL site and the user has a cookie that is logged out
        auth.logout()
      } else {
        // Its a POL site, but the user has a cookie that is logged in

        // userInfo  and lanId are Greenfield level info. This is checking the info that the
        // Greenfield app has against what the cookie is saying it has.
        if (userInfo.accessToken && String(lanId) === String(SSOSESSIONINFOJson.user)) {
          auth.login()
        } else if (!(lanId && SSOSESSIONINFOJson.user) && String(lanId) !== String(SSOSESSIONINFOJson.user)) {
          /*
          // This else is if lanId != SSOSESSIONINFOJson.user
          // This bit of code gets run every route change
          // If a user logs into a different account via POL
          // we want to switch users, this code should handle that
          */
          auth.logout()
        }
      }
    } else {
      auth.logout()
    }
  }
}

export class Auth extends React.Component {
  componentDidMount() {
    const { auth } = this.props
    if (auth && auth.session && auth.session.userInfo) {
      const uploadAccessToken = auth.session.userInfo.accessToken
      Cookies.set('uploadAccessToken', uploadAccessToken)

      const uploadAccessTokenNoBearer = uploadAccessToken?.replace('Bearer ', '')
      Cookies.set('uploadAccessTokenNoBearer', uploadAccessTokenNoBearer)

      Cookies.set('apiKey', apiConfig.gnfapi.apikey)

      // this cookie exists to help filter UI proxy server logs
      Cookies.set('GFUI', 'true')
    }

    checkUserAuthStatus(auth)
  }

  render() {
    return (
      <>
        <Interceptor auth={this.props.auth} routeProps={this.props.routeProps} />
      </>
    )
  }
}

export default Auth
