import escapeRegExp from 'lodash/escapeRegExp'
import isEqual from 'lodash/isEqual'
import isObject from 'lodash/isObject'
import transform from 'lodash/transform'
import moment from 'moment'
import { CUSTOM_STR } from 'greenfield-utilities'

import {
  COMBINED_TIME_PERIOD_OPTIONS,
  CUSTOM_DATES_OPTIONS,
  DEFAULT_PERIOD_PLURAL_OPTIONS,
} from '../constants/ArrayConstants'
import { DEFAULT_TIME_PERIOD_STR } from '../constants/StringConstants'
import { API_GATEWAY_URL } from '../../ducks/utils'

/**
 *
 * @param {string} searchValue details which has to be searched
 * @param {array} rows details table data/rows
 * @param {array} keys details columns to be searched in
 */
export function advanceFilter(searchValue, rows, keys) {
  let tempList = rows.slice(0)

  if (searchValue && searchValue.trim() !== '') {
    searchValue = searchValue.toLocaleLowerCase()
    const searchList = searchValue.split(' ')

    searchList.forEach(function (searchElem) {
      tempList =
        searchElem && searchElem !== ''
          ? tempList.filter(rowData =>
              new RegExp(escapeRegExp(searchElem.replace(/\\/g, '\\\\')), 'i').test(
                keys
                  .map(value => {
                    if (rowData.card_attribute) {
                      return rowData.card_attribute[value]
                    } else {
                      return rowData[value]
                    }
                  })
                  .join('')
              )
            )
          : tempList
    })
  }
  return tempList
}

export function isCertifiedDataset(item) {
  return item && item.certified && item.certified.toLowerCase() === 'yes'
}

/**
 *
 * @param dateValue
 * @returns {*}
 */
export function formatDate(dateValue) {
  if (dateValue) {
    return dateValue.substr(0, 10)
  }
  return null
}
/**
 * Formatting date in time period label
 * @param data
 * @param timePeriodValue
 * @returns {*}
 */
export function getTimePeriodFormat(data, timePeriodValue) {
  let timeInterval =
    timePeriodValue && timePeriodValue.interval
      ? timePeriodValue.interval
      : data.card_config.card_query_attribute.time_period.interval
  if (timeInterval) {
    if (timeInterval.indexOf('/') > -1) {
      const dateRange = timeInterval.split('/')
      timeInterval = `${formatDate(dateRange[0])} to ${formatDate(dateRange[1])}`
    } else if (timeInterval.toLowerCase() === 'last segment' && data.rendered_interval) {
      timeInterval = `${formatDate(data.rendered_interval.begin)} to ${formatDate(data.rendered_interval.end)}`
    }
  }
  return timeInterval
}

export function getFormatDate(dates) {
  if (dates) {
    return moment(dates).format('YYYY-MM-DD')
  } else {
    return moment().format('YYYY-MM-DD')
  }
}

export function getTimePeriodButtonLabel(buttonValue, timePeriod) {
  const isButtonValueArray = Array.isArray(buttonValue)
  let buttonLabel = !isButtonValueArray ? buttonValue : ''

  if (buttonValue && !isButtonValueArray) {
    let customArray = buttonValue?.split(' ')
    if (buttonValue.includes('/')) {
      customArray = buttonValue?.split('/')
    }

    if (
      (buttonValue.includes('/') && customArray?.length === 2) ||
      (timePeriod &&
        buttonValue.toLowerCase() === 'between' &&
        (timePeriod.set_begin_date_today || timePeriod.set_end_date_today))
    ) {
      buttonLabel = 'Between'
    } else if (buttonValue === 'last segment') {
      buttonLabel = DEFAULT_TIME_PERIOD_STR
    } else if (
      COMBINED_TIME_PERIOD_OPTIONS.indexOf(buttonValue) === -1 &&
      buttonValue !== 'last segment' &&
      buttonValue.toLowerCase() !== 'between' &&
      !(
        customArray?.length === 3 &&
        CUSTOM_DATES_OPTIONS.includes(customArray[0]) &&
        DEFAULT_PERIOD_PLURAL_OPTIONS.includes(customArray[2])
      )
    ) {
      buttonLabel = CUSTOM_STR
    }
  } else if (isButtonValueArray && buttonValue?.length) {
    return buttonValue.reduce((accumulator, obj) => {
      accumulator = accumulator + `${obj.display_name}, `
      return accumulator
    }, '')
  }

  return buttonLabel
}

export function compareSelectedDateWithNow(maxDate) {
  const _maxDate = getFormatDate(maxDate)
  const nowDate = getFormatDate()

  return moment(_maxDate).isAfter(nowDate) || moment(_maxDate).isSame(nowDate)
}

export function escapeOutput(toOutput) {
  return toOutput
    .replace(/&/g, '')
    .replace(/</g, '')
    .replace(/>/g, '')
    .replace(/"/g, '')
    .replace(/'/g, '')
    .replace(/\//g, '')
}

export function getSiteURLInfo() {
  const isSite = /^\/site\//i.test(window.location.pathname)
  let splitSiteURl = []
  if (isSite) {
    splitSiteURl = window.location.pathname?.split('/')
  }
  return splitSiteURl
}

export function getSiteURL(routeProps) {
  let siteURL = ''
  if (routeProps?.match?.params?._siteName) {
    siteURL = `/site/${routeProps.match.params._siteName}`
  }
  return siteURL
}

/**
 * Deep diff between two object, using lodash
 * @param  {Object} object Object compared
 * @param  {Object} base   Object to compare with
 * @return {Object}        Return a new object who represent the diff
 */
export function difference(object, base) {
  function changes(object, base) {
    return transform(object, function (result, value, key) {
      if (!isEqual(value, base[key])) {
        result[key] = isObject(value) && isObject(base[key]) ? changes(value, base[key]) : value
      }
    })
  }
  return changes(object, base)
}

/**
 * Deep compare two objects of objects,
 * @param  {Object} obj1 Object of objects compared
 * @param  {Object} obj2   Object of objects to compare with
 * @return {Boolean} Return boolean if objects equal or not
 */
export function isNotEqual(obj1, obj2) {
  if (typeof obj1 === 'object') {
    return Object.keys(obj1).some(key => {
      const childObj1 = obj1[key]
      const childObj2 = obj2[key]
      return Object.keys(childObj1).some(_key => String(childObj1[_key]) !== String(childObj2[_key]))
    })
  }
  return true
}

export const getTimer = time => {
  const { hours, minutes, seconds } = getRemainingTime(time)
  return `${hours > 9 ? hours : `0${hours}`}:${minutes > 9 ? minutes : `0${minutes}`}:${
    seconds > 9 ? seconds : `0${seconds}`
  }`
}

export const getRemainingTime = time => {
  const delta = parseInt(time, 10) - new Date()
  const seconds = Math.floor((delta / 1000) % 60)
  const minutes = Math.floor((delta / 1000 / 60) % 60)
  const hours = Math.floor((delta / 1000 / 60 / 60) % 24)
  return {
    delta,
    hours,
    minutes,
    seconds,
  }
}

export const toolbarButtons = {
  moreText: {
    buttons: [
      'bold',
      'italic',
      'underline',
      'strikeThrough',
      'subscript',
      'superscript',
      'fontFamily',
      'fontSize',
      'textColor',
      'backgroundColor',
      'inlineClass',
      'inlineStyle',
      'clearFormatting',
    ],
  },
  moreParagraph: {
    buttons: [
      'alignLeft',
      'alignCenter',
      'formatOLSimple',
      'alignRight',
      'alignJustify',
      'formatOL',
      'formatUL',
      'paragraphFormat',
      'paragraphStyle',
      'lineHeight',
      'outdent',
      'indent',
      'quote',
    ],
  },
  moreRich: {
    buttons: [
      'insertLink',
      'insertImage',
      'insertTable',
      'emoticons',
      'fontAwesome',
      'specialCharacters',
      'embedly',
      'insertFile',
      'insertHR',
    ],
    buttonsVisible: 3,
  },
  moreMisc: {
    buttons: ['undo', 'redo', 'backgroundColorSet', 'fullscreen', 'print', 'spellChecker', 'selectAll', 'html', 'help'],
    align: 'right',
    buttonsVisible: 3,
  },
}

export const buildDownloadUrl = urlPath => {
  try {
    // if urlPath is actually a valid URL already, just return it
    const url = new URL(urlPath)
    return url
  } catch (_) {
    // as expected, urlPath is not a fully URL
  }

  return `${API_GATEWAY_URL}${urlPath}`
}
