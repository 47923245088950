import { call, put } from 'redux-saga/effects'
import axios from 'axios'
import cloneDeep from 'lodash/cloneDeep'
import { API_GATEWAY_URL } from './utils'

// Action types
export const NOTIFICATION_FETCH_REQUESTED = 'NOTIFICATION_FETCH_REQUESTED'
export const NOTIFICATION_FETCH_SUCCEEDED = 'NOTIFICATION_FETCH_SUCCEEDED'
export const NOTIFICATION_FETCH_FAILED = 'NOTIFICATION_FETCH_FAILED'

export const NOTIFICATION_READ_DELETE_REQUESTED = 'NOTIFICATION_READ_DELETE_REQUESTED'
export const NOTIFICATION_READ_DELETE_SUCCEEDED = 'NOTIFICATION_READ_DELETE_SUCCEEDED'
export const NOTIFICATION_READ_DELETE_FAILED = 'NOTIFICATION_READ_DELETE_FAILED'

export const NOTIFICATION_READ_UPDATE_REQUESTED = 'NOTIFICATION_READ_UPDATE_REQUESTED'
export const NOTIFICATION_READ_UPDATE_SUCCEEDED = 'NOTIFICATION_READ_UPDATE_SUCCEEDED'
export const NOTIFICATION_READ_UPDATE_FAILED = 'NOTIFICATION_READ_UPDATE_FAILED'

export const NOTIFICATION_CREATE_REQUESTED = 'NOTIFICATION_CREATE_REQUESTED'
export const NOTIFICATION_CREATE_SUCCEEDED = 'NOTIFICATION_CREATE_SUCCEEDED'
export const NOTIFICATION_CREATE_FAILED = 'NOTIFICATION_CREATE_FAILED'

export const NOTIFICATION_EDIT_REQUESTED = 'NOTIFICATION_EDIT_REQUESTED'
export const NOTIFICATION_EDIT_SUCCEEDED = 'NOTIFICATION_EDIT_SUCCEEDED'
export const NOTIFICATION_EDIT_FAILED = 'NOTIFICATION_EDIT_FAILED'

export const NOTIFICATION_PUBLISH_REQUESTED = 'NOTIFICATION_PUBLISH_REQUESTED'
export const NOTIFICATION_PUBLISH_SUCCEEDED = 'NOTIFICATION_PUBLISH_SUCCEEDED'
export const NOTIFICATION_PUBLISH_FAILED = 'NOTIFICATION_PUBLISH_FAILED'

export const NOTIFICATION_FETCH_ALL_REQUESTED = 'NOTIFICATION_FETCH_ALL_REQUESTED'
export const NOTIFICATION_FETCH_ALL_SUCCEEDED = 'NOTIFICATION_FETCH_ALL_SUCCEEDED'
export const NOTIFICATION_FETCH_ALL_FAILED = 'NOTIFICATION_FETCH_ALL_FAILED'

export const NOTIFICATION_DELETE_REQUESTED = 'NOTIFICATION_DELETE_REQUESTED'
export const NOTIFICATION_DELETE_SUCCEEDED = 'NOTIFICATION_DELETE_SUCCEEDED'
export const NOTIFICATION_DELETE_FAILED = 'NOTIFICATION_DELETE_FAILED'

export const GET_OBJECT_NOTIFICATION_REQUESTED = 'GET_OBJECT_NOTIFICATION_REQUESTED'
export const GET_OBJECT_NOTIFICATION_SUCCEEDED = 'GET_OBJECT_NOTIFICATION_SUCCEEDED'
export const GET_OBJECT_NOTIFICATION_FAILED = 'GET_OBJECT_NOTIFICATION_FAILED'

export const DELETE_OBJECT_NOTIFICATION_REQUESTED = 'DELETE_OBJECT_NOTIFICATION_REQUESTED'
export const DELETE__OBJECT_NOTIFICATION_SUCCEEDED = 'DELETE__OBJECT_NOTIFICATION_SUCCEEDED'
export const DELETE__OBJECT_NOTIFICATION_FAILED = 'DELETE__OBJECT_NOTIFICATION_FAILED'
export const CLEAR_DELETE_OBJECT_NOTIFICATION_STATUS = 'CLEAR_DELETE_OBJECT_NOTIFICATION_STATUS'

export const GET_NOTIFICATION_COUNT_REQUESTED = 'GET_NOTIFICATION_COUNT_REQUESTED'
export const GET_NOTIFICATION_COUNT_SUCCEEDED = 'GET_NOTIFICATION_COUNT_SUCCEEDED'
export const GET_NOTIFICATION_COUNT_FAILED = 'GET_NOTIFICATION_COUNT_FAILED'

export const CREATE_OBJECT_NOTIFICATION_REQUESTED = 'CREATE_OBJECT_NOTIFICATION_REQUESTED'
export const CREATE_OBJECT_NOTIFICATION_SUCCEEDED = 'CREATE_OBJECT_NOTIFICATION_SUCCEEDED'
export const CREATE_OBJECT_NOTIFICATION_FAILED = 'CREATE_OBJECT_NOTIFICATION_FAILED'
export const CLEAR_OBJECT_NOTIFICATION_STATUS = 'CLEAR_OBJECT_NOTIFICATION_STATUS'

export const GET_NOTIFICATION_LISTING_REQUESTED = 'GET_NOTIFICATION_LISTING_REQUESTED'
export const GET_NOTIFICATION_LISTING_SUCCEEDED = 'GET_NOTIFICATION_LISTING_SUCCEEDED'
export const GET_NOTIFICATION_LISTING_FAILED = 'GET_NOTIFICATION_LISTING_FAILED'

export const UPDATE_NOTIFICATION_REQUESTED = 'UPDATE_NOTIFICATION_REQUESTED'
export const UPDATE_NOTIFICATION_SUCCEEDED = 'UPDATE_NOTIFICATION_SUCCEEDED'
export const UPDATE_NOTIFICATION_FAILED = 'UPDATE_NOTIFICATION_FAILED'

export const OBJECT_ID_NOTIFICATION_SUCCEEDED = 'OBJECT_ID_NOTIFICATION_SUCCEEDED'
export const OBJECT_ID_NOTIFICATION_REQUESTED = 'OBJECT_ID_NOTIFICATION_REQUESTED'
export const OBJECT_ID_NOTIFICATION_FAILED = 'OBJECT_ID_NOTIFICATION_FAILED'

export const CLEAR_NOTIFICATION_CACHE = 'CLEAR_NOTIFICATION_CACHE'

// Reducer
export default function notificationReducer(state = {}, action = {}) {
  switch (action.type) {
    case UPDATE_NOTIFICATION_REQUESTED: {
      const newState = {
        ...state,
        updateNotificationStatus: {
          status: 'requested',
        },
      }
      return newState
    }
    case UPDATE_NOTIFICATION_SUCCEEDED: {
      const { status, data } = action.payload
      const newState = {
        ...state,
        updateNotificationStatus: {
          status,
          data,
        },
      }
      return newState
    }
    case UPDATE_NOTIFICATION_FAILED: {
      const newState = {
        ...state,
        updateNotificationStatus: {
          status: 'failed',
          message: action.payload,
        },
      }
      return newState
    }

    case GET_NOTIFICATION_LISTING_REQUESTED: {
      const newState = {
        ...state,
        getObjectListings: {
          status: 'requested',
        },
      }
      return newState
    }
    case GET_NOTIFICATION_LISTING_SUCCEEDED: {
      const newState = {
        ...state,
        getObjectListings: action.payload,
      }
      return newState
    }
    case GET_NOTIFICATION_LISTING_FAILED: {
      const newState = {
        ...state,
        getObjectListings: {
          status: 'failed',
          message: action.payload,
        },
      }
      return newState
    }

    case CREATE_OBJECT_NOTIFICATION_REQUESTED: {
      const newState = {
        ...state,
        createObjectNotificationStatus: {
          status: 'requested',
        },
      }
      return newState
    }
    case CREATE_OBJECT_NOTIFICATION_SUCCEEDED: {
      const newState = {
        ...state,
        createObjectNotificationStatus: action.payload,
      }
      return newState
    }
    case CREATE_OBJECT_NOTIFICATION_FAILED: {
      const newState = {
        ...state,
        createObjectNotificationStatus: {
          status: 'failed',
          message: action.payload,
        },
      }
      return newState
    }

    case CLEAR_OBJECT_NOTIFICATION_STATUS: {
      const newState = {
        ...state,
        createObjectNotificationStatus: {},
        objectIdNotificationStatus: {},
        updateNotificationStatus: {},
      }
      return newState
    }

    case GET_NOTIFICATION_COUNT_REQUESTED: {
      const newState = {
        ...state,
        getNotificationCountStatus: {
          ...state.getNotificationCountStatus,
          status: 'requested',
        },
      }
      return newState
    }
    case GET_NOTIFICATION_COUNT_SUCCEEDED: {
      const { dataset, card } = action.payload.data.metric
      const newData = {
        ...state.getNotificationCountStatus.data,
        ...dataset,
        ...card,
      }
      const newState = {
        ...state,
        getNotificationCountStatus: {
          status: 200,
          data: newData,
        },
      }
      return newState
    }

    case GET_NOTIFICATION_COUNT_FAILED: {
      const newState = {
        ...state,
        getNotificationCountStatus: {
          status: 'failed',
        },
      }
      return newState
    }

    case DELETE_OBJECT_NOTIFICATION_REQUESTED: {
      const newState = {
        ...state,
        deleteObjectNotificationStatus: {
          [action.payload.id]: {
            status: 'requested',
          },
        },
      }
      return newState
    }
    case DELETE__OBJECT_NOTIFICATION_SUCCEEDED: {
      const { status } = action.payload
      const newState = {
        ...state,
        deleteObjectNotificationStatus: {
          [action.id]: {
            status,
          },
          status,
        },
      }
      return newState
    }

    case DELETE__OBJECT_NOTIFICATION_FAILED: {
      const newState = {
        ...state,
        deleteObjectNotificationStatus: {
          status: 'failed',
          message: action.payload,
        },
      }
      return newState
    }

    case CLEAR_DELETE_OBJECT_NOTIFICATION_STATUS: {
      const newState = {
        ...state,
        deleteObjectNotificationStatus: {},
      }
      return newState
    }

    case CLEAR_NOTIFICATION_CACHE: {
      const newState = {
        ...state,
        getObjectNotificationStatus: {},
        getNotificationCountStatus: {},
        objectIdNotificationStatus: {},
        updateNotificationStatus: {},
      }

      return newState
    }

    case GET_OBJECT_NOTIFICATION_REQUESTED: {
      const newState = {
        ...state,
        getObjectNotificationStatus: {
          ...state.getObjectNotificationStatus,
          status: 'requested',
        },
      }
      return newState
    }
    case GET_OBJECT_NOTIFICATION_SUCCEEDED: {
      const { objectId } = action.payload

      /*
        The general idea here is we're combining multiple http calls
        data into a single state object. Becasue we can only get either
        one of dataset, card, or dashboard at a time.
        -Art/49754518
      */
      const data = action.payload.data.data

      const newState = {
        ...state,
        getObjectNotificationStatus: {
          ...state.getObjectNotificationStatus,
          status: 200,
          data: {
            ...state.getObjectNotificationStatus.data,
            [objectId]: data.flat(),
          },
        },
      }
      return newState
    }

    case GET_OBJECT_NOTIFICATION_FAILED: {
      const newState = {
        ...state,
        getObjectNotificationStatus: {
          ...state.getObjectNotificationStatus,
          status: 'failed',
        },
      }
      return newState
    }

    case NOTIFICATION_FETCH_REQUESTED: {
      const newState = {
        ...state,
        notificationStatus: {
          status: 'requested',
        },
      }
      return newState
    }
    case NOTIFICATION_FETCH_SUCCEEDED: {
      const newState = {
        ...state,
        notificationStatus: action.payload,
      }
      return newState
    }
    case NOTIFICATION_FETCH_FAILED: {
      const newState = {
        ...state,
        notificationStatus: {
          status: 'failed',
        },
      }
      return newState
    }
    case NOTIFICATION_READ_DELETE_REQUESTED: {
      const newState = {
        ...state,
        readDeleteNotificationStatus: {
          status: 'requested',
        },
      }
      return newState
    }
    case NOTIFICATION_READ_DELETE_SUCCEEDED: {
      // Updating notification list in store, when any notification is deleted
      const notificationStatus = cloneDeep(state.notificationStatus)
      notificationStatus.data = notificationStatus.data.filter(row => row._id !== action.payload.config.data)

      const newState = {
        ...state,
        notificationStatus,
        readDeleteNotificationStatus: action.payload,
      }
      return newState
    }
    case NOTIFICATION_READ_DELETE_FAILED: {
      const newState = {
        ...state,
        readDeleteNotificationStatus: {
          status: 'failed',
          response: action.payload.response,
        },
      }
      return newState
    }
    case NOTIFICATION_READ_UPDATE_REQUESTED: {
      const newState = {
        ...state,
        notificationReadUpdateStatus: {
          status: 'requested',
        },
      }
      return newState
    }
    case NOTIFICATION_READ_UPDATE_SUCCEEDED: {
      // Updating notification list in store, when any notification is read

      const notificationStatus = cloneDeep(state.notificationStatus)
      // fetch the object's index in the notification list
      const index = notificationStatus.data.indexOf(
        notificationStatus.data.filter(row => row._id === action.payload.data._id)[0]
      )
      // replace the object in list with latest object from API
      notificationStatus.data.splice(index, 1, action.payload.data)

      const newState = {
        ...state,
        notificationStatus,
        notificationReadUpdateStatus: action.payload,
      }
      return newState
    }
    case NOTIFICATION_READ_UPDATE_FAILED: {
      const newState = {
        ...state,
        notificationReadUpdateStatus: {
          status: 'failed',
        },
      }
      return newState
    }
    case NOTIFICATION_CREATE_REQUESTED: {
      const newState = {
        ...state,
        notificationCreateStatus: {
          status: 'requested',
        },
      }
      return newState
    }
    case NOTIFICATION_CREATE_SUCCEEDED: {
      const newState = {
        ...state,
        notificationCreateStatus: action.payload,
      }
      return newState
    }
    case NOTIFICATION_CREATE_FAILED: {
      const newState = {
        ...state,
        notificationCreateStatus: {
          status: 'failed',
        },
      }
      return newState
    }
    case NOTIFICATION_EDIT_REQUESTED: {
      const newState = {
        ...state,
        notificationEditStatus: {
          status: 'requested',
        },
      }
      return newState
    }
    case NOTIFICATION_EDIT_SUCCEEDED: {
      const newState = {
        ...state,
        notificationEditStatus: action.payload,
      }
      return newState
    }
    case NOTIFICATION_EDIT_FAILED: {
      const newState = {
        ...state,
        notificationEditStatus: {
          status: 'failed',
        },
      }
      return newState
    }
    case NOTIFICATION_FETCH_ALL_REQUESTED: {
      const newState = {
        ...state,
        notificationFetchAllStatus: {
          status: 'requested',
        },
      }
      return newState
    }
    case NOTIFICATION_FETCH_ALL_SUCCEEDED: {
      const newState = {
        ...state,
        notificationFetchAllStatus: action.payload,
      }
      return newState
    }
    case NOTIFICATION_FETCH_ALL_FAILED: {
      const newState = {
        ...state,
        notificationFetchAllStatus: {
          status: 'failed',
        },
      }
      return newState
    }
    case NOTIFICATION_DELETE_REQUESTED: {
      const newState = {
        ...state,
        deleteNotificationStatus: {
          status: 'requested',
        },
      }
      return newState
    }
    case NOTIFICATION_DELETE_SUCCEEDED: {
      const notificationFetchAllStatus = cloneDeep(state.notificationFetchAllStatus)
      notificationFetchAllStatus.data = notificationFetchAllStatus.data.filter(
        row => row._id !== action.payload.config.data
      )
      const newState = {
        ...state,
        notificationFetchAllStatus,
        deleteNotificationStatus: action.payload,
      }
      return newState
    }
    case NOTIFICATION_DELETE_FAILED: {
      const newState = {
        ...state,
        deleteNotificationStatus: {
          status: 'failed',
          response: action.payload.response,
        },
      }
      return newState
    }
    case NOTIFICATION_PUBLISH_REQUESTED: {
      const newState = {
        ...state,
        publishNotificationStatus: {
          status: 'requested',
        },
      }
      return newState
    }
    case NOTIFICATION_PUBLISH_SUCCEEDED: {
      const notificationFetchAllStatus = cloneDeep(state.notificationFetchAllStatus)
      const index = notificationFetchAllStatus.data.indexOf(
        notificationFetchAllStatus.data.filter(row => row._id === action.payload.data._id)[0]
      )
      notificationFetchAllStatus.data.splice(index, 1, action.payload.data)
      const newState = {
        ...state,
        notificationFetchAllStatus,
        publishNotificationStatus: action.payload,
      }
      return newState
    }
    case NOTIFICATION_PUBLISH_FAILED: {
      const newState = {
        ...state,
        publishNotificationStatus: {
          status: 'failed',
        },
      }
      return newState
    }

    case OBJECT_ID_NOTIFICATION_REQUESTED: {
      const newState = {
        ...state,
        objectIdNotificationStatus: {
          status: 'requested',
        },
      }
      return newState
    }
    case OBJECT_ID_NOTIFICATION_SUCCEEDED: {
      const newState = {
        ...state,
        objectIdNotificationStatus: action.payload,
      }
      return newState
    }
    case OBJECT_ID_NOTIFICATION_FAILED: {
      const newState = {
        ...state,
        objectIdNotificationStatus: {
          status: 'failed',
        },
      }
      return newState
    }
    default:
      return state
  }
}

// Actions
export function updateNotificationAction(data) {
  return {
    type: NOTIFICATION_FETCH_REQUESTED,
    payload: data,
  }
}
export function readDeleteNotificationAction(data) {
  return {
    type: NOTIFICATION_READ_DELETE_REQUESTED,
    payload: data,
  }
}
export function notificationReadUpdateAction(data) {
  return {
    type: NOTIFICATION_READ_UPDATE_REQUESTED,
    payload: data,
  }
}
export function notificationCreateAction(data) {
  return {
    type: NOTIFICATION_CREATE_REQUESTED,
    payload: data,
  }
}
export function notificationEditAction(data) {
  return {
    type: NOTIFICATION_EDIT_REQUESTED,
    payload: data,
  }
}
export function fetchAllNotificationAction(data) {
  return {
    type: NOTIFICATION_FETCH_ALL_REQUESTED,
    payload: data,
  }
}
export function deleteNotificationAction(data) {
  return {
    type: NOTIFICATION_DELETE_REQUESTED,
    payload: data,
  }
}
export function getObjectNotificationBasedOnId(data) {
  return {
    type: OBJECT_ID_NOTIFICATION_REQUESTED,
    payload: data,
  }
}
export function publishNotificationAction(data) {
  return {
    type: NOTIFICATION_PUBLISH_REQUESTED,
    payload: data,
  }
}

export function getObjectNot(data) {
  return {
    type: GET_OBJECT_NOTIFICATION_REQUESTED,
    payload: data,
  }
}

export function getNotificationCount(data) {
  return {
    type: GET_NOTIFICATION_COUNT_REQUESTED,
    payload: data,
  }
}

export function clearNotificationCache(data) {
  return {
    type: CLEAR_NOTIFICATION_CACHE,
    payload: data,
  }
}

export function createNotification(data) {
  return {
    type: CREATE_OBJECT_NOTIFICATION_REQUESTED,
    payload: data,
  }
}

export function fetchObjectNotificationListing(data) {
  return {
    type: GET_NOTIFICATION_LISTING_REQUESTED,
    payload: data,
  }
}

export function clearObjectNotificationStatus(data) {
  return {
    type: CLEAR_OBJECT_NOTIFICATION_STATUS,
    payload: data,
  }
}

export function updateNotification(data) {
  return {
    type: UPDATE_NOTIFICATION_REQUESTED,
    payload: data,
  }
}

export function deleteObjectNotification(data) {
  return {
    type: DELETE_OBJECT_NOTIFICATION_REQUESTED,
    payload: data,
  }
}

export function clearDeleteObjectNotificationStatus(data) {
  return {
    type: CLEAR_DELETE_OBJECT_NOTIFICATION_STATUS,
    payload: data,
  }
}

// Sagas (service requests)
export function* fetchNotificationInfo() {
  try {
    const notificationList = yield call(axios, {
      method: 'get',
      url: `${API_GATEWAY_URL}/bi_reporting_assets/v2/notifications/publish`,
    })

    if (notificationList.error) {
      const e = notificationList.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }

    yield put({ type: NOTIFICATION_FETCH_SUCCEEDED, payload: notificationList })
  } catch (e) {
    yield put({ type: NOTIFICATION_FETCH_FAILED })
  }
}

export function* readDeleteNotificationInfo(action) {
  try {
    const delNotification = yield call(axios, {
      method: 'delete',
      url: `${API_GATEWAY_URL}/bi_reporting_assets/v2/notifications/read?notification_id=${action.payload}`,
    })
    if (delNotification.error) {
      const e = delNotification.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }

    yield put({ type: NOTIFICATION_READ_DELETE_SUCCEEDED, payload: delNotification })
  } catch (e) {
    yield put({ type: NOTIFICATION_READ_DELETE_FAILED, payload: e })
  }
}

export function* readUpdateNotificationInfo(action) {
  try {
    const readNotification = yield call(axios, {
      method: 'post',
      url: `${API_GATEWAY_URL}/bi_reporting_assets/v2/notifications/read?notification_id=${action.payload._id}`,
      data: action.payload,
    })
    if (readNotification.error) {
      const e = readNotification.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }

    yield put({ type: NOTIFICATION_READ_UPDATE_SUCCEEDED, payload: readNotification })
  } catch (e) {
    yield put({ type: NOTIFICATION_READ_UPDATE_FAILED, payload: e })
  }
}

export function* createNotificationInfo(action) {
  try {
    const createNotification = yield call(axios, {
      method: 'post',
      url: `${API_GATEWAY_URL}/bi_reporting_assets/v2/notifications`,
      data: action.payload,
    })
    if (createNotification.error) {
      const e = createNotification.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }

    yield put({ type: NOTIFICATION_CREATE_SUCCEEDED, payload: createNotification })
  } catch (e) {
    yield put({ type: NOTIFICATION_CREATE_FAILED, payload: e })
  }
}

export function* editNotificationInfo(action) {
  try {
    const editNotification = yield call(axios, {
      method: 'put',
      url: `${API_GATEWAY_URL}/bi_reporting_assets/v2/notifications/${action.payload._id}`,
      data: action.payload,
    })
    if (editNotification.error) {
      const e = editNotification.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }

    yield put({ type: NOTIFICATION_EDIT_SUCCEEDED, payload: editNotification })
  } catch (e) {
    yield put({ type: NOTIFICATION_EDIT_FAILED, payload: e })
  }
}

export function* fetchAllNotificationInfo() {
  try {
    const notificationList = yield call(axios, {
      method: 'get',
      url: `${API_GATEWAY_URL}/bi_reporting_assets/v2/notifications?notification_type=global-notification`,
    })

    if (notificationList.error) {
      const e = notificationList.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }

    yield put({ type: NOTIFICATION_FETCH_ALL_SUCCEEDED, payload: notificationList })
  } catch (e) {
    yield put({ type: NOTIFICATION_FETCH_ALL_FAILED })
  }
}

export function* deleteNotificationInfo(action) {
  try {
    const delNotification = yield call(axios, {
      method: 'delete',
      url: `${API_GATEWAY_URL}/bi_reporting_assets/v2/notifications/${action.payload}`,
      data: action.payload,
    })
    if (delNotification.error) {
      const e = delNotification.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }

    yield put({ type: NOTIFICATION_DELETE_SUCCEEDED, payload: delNotification })
  } catch (e) {
    yield put({ type: NOTIFICATION_DELETE_FAILED, payload: e })
  }
}

export function* publishNotificationInfo(action) {
  try {
    const publishNotification = yield call(axios, {
      method: 'post',
      url: `${API_GATEWAY_URL}/bi_reporting_assets/v2/notifications/publish?notification_id=${action.payload}`,
      data: action.payload,
    })
    if (publishNotification.error) {
      const e = publishNotification.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }

    yield put({ type: NOTIFICATION_PUBLISH_SUCCEEDED, payload: publishNotification })
  } catch (e) {
    yield put({ type: NOTIFICATION_PUBLISH_FAILED, payload: e })
  }
}

export function* fetchObjectNotification(action) {
  try {
    const fetchNotificationDataObject = yield call(axios, {
      method: 'get',
      url: `${API_GATEWAY_URL}/greenfield_notifications/v1/objects?object_type=${action.payload.type}&object_id=${action.payload.objectId}&message_type=FOR_ME&page=1&per_page=100`,
    })

    if (fetchNotificationDataObject.error) {
      const e = fetchNotificationDataObject.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }

    yield put({
      type: 'GET_OBJECT_NOTIFICATION_SUCCEEDED',
      payload: { data: fetchNotificationDataObject, objectId: action.payload.objectId },
    })
  } catch (e) {
    yield put({ type: 'GET_OBJECT_NOTIFICATION_FAILED', payload: { data: action.payload, resp: e.response } })
  }
}

export function* deleteNotificationObject(action) {
  try {
    const deleteNotificationObject = yield call(axios, {
      method: 'delete',
      url: `${API_GATEWAY_URL}/greenfield_notifications/v1/objects/${action.payload.id}`,
    })

    if (deleteNotificationObject.error) {
      const e = deleteNotificationObject.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }

    yield put({
      type: 'DELETE__OBJECT_NOTIFICATION_SUCCEEDED',
      payload: deleteNotificationObject,
      id: action.payload.id,
    })
  } catch (e) {
    yield put({ type: 'DELETE__OBJECT_NOTIFICATION_FAILED', payload: { data: action.payload, resp: e.response } })
  }
}

export function* fetchNotificationCount(action) {
  const { cardId, datasetId = '' } = action.payload
  if (!cardId) {
    return
  }
  try {
    const filteredDatasetId = datasetId
      .toString()
      .split(',')
      .filter(el => el)
      .join(',')
    const notificationCountObject = yield call(axios, {
      method: 'get',
      url: `${API_GATEWAY_URL}/greenfield_notifications/v1/objects/counts?${cardId ? `card_ids=${cardId}` : ''}${
        datasetId ? `&dataset_ids=${filteredDatasetId}` : ''
      }`,
    })
    if (notificationCountObject.error) {
      const e = notificationCountObject.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }

    yield put({ type: 'GET_NOTIFICATION_COUNT_SUCCEEDED', payload: notificationCountObject })
  } catch (e) {
    yield put({ type: 'GET_NOTIFICATION_COUNT_FAILED', payload: { data: action.payload, resp: e.response } })
  }
}

export function* createObjectNotification(action) {
  try {
    const notificationCountObject = yield call(axios, {
      method: 'post',
      url: `${API_GATEWAY_URL}/greenfield_notifications/v1/objects`,
      data: action.payload,
    })

    if (notificationCountObject.error) {
      const e = notificationCountObject.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }

    yield put({ type: 'CREATE_OBJECT_NOTIFICATION_SUCCEEDED', payload: notificationCountObject })
  } catch (e) {
    yield put({ type: 'CREATE_OBJECT_NOTIFICATION_FAILED', payload: { data: action.payload, resp: e.response } })
  }
}

export function* fetchObjectListingNotifications(action) {
  try {
    const fetchNotificationDataObject = yield call(axios, {
      method: 'get',
      url: action.payload.objectType
        ? `${API_GATEWAY_URL}/greenfield_notifications/v1/objects?object_type=${action.payload.objectType}&object_id=${action.payload.objectId}&per_page=100`
        : `${API_GATEWAY_URL}/greenfield_notifications/v1/objects?message_type=CREATED_BY_ME&page=${action.payload.pageIndex}&per_page=${action.payload.pageSize}`,
    })

    if (fetchNotificationDataObject.error) {
      const e = fetchNotificationDataObject.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }

    yield put({
      type: 'GET_NOTIFICATION_LISTING_SUCCEEDED',
      payload: fetchNotificationDataObject,
    })
  } catch (e) {
    yield put({ type: 'GET_NOTIFICATION_LISTING_FAILED', payload: { data: action.payload, resp: e.response } })
  }
}

export function* updateObjectNotification(action) {
  try {
    const updateNotificationObject = yield call(axios, {
      method: 'patch',
      url: `${API_GATEWAY_URL}/greenfield_notifications/v1/objects/${action.payload.id}`,
      data: action.payload.data,
    })

    if (updateNotificationObject.error) {
      const e = updateNotificationObject.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }

    yield put({ type: 'UPDATE_NOTIFICATION_SUCCEEDED', payload: updateNotificationObject })
  } catch (e) {
    yield put({ type: 'UPDATE_NOTIFICATION_FAILED', payload: { data: action.payload, resp: e.response } })
  }
}

export function* getObjectIdNotification(action) {
  try {
    const notificationObjectId = yield call(axios, {
      method: 'GET',
      url: `${API_GATEWAY_URL}/greenfield_notifications/v1/objects/${action.payload.id}`,
    })

    if (notificationObjectId.error) {
      const e = notificationObjectId.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }

    yield put({ type: 'OBJECT_ID_NOTIFICATION_SUCCEEDED', payload: notificationObjectId })
  } catch (e) {
    yield put({ type: 'OBJECT_ID_NOTIFICATION_FAILED', payload: { data: action.payload, resp: e.response } })
  }
}
