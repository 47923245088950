import React from 'react'
import { Chip, Tooltip, Avatar } from '@mui/material'
import { Edit, Cancel } from '@mui/icons-material'
import PropTypes from 'prop-types'
import './filterviewer.scss'
import cloneDeep from 'lodash/cloneDeep'
import { DATE_INTERVAL_TYPE_OPTIONS } from '../../constants/ArrayConstants'

export class FilterChipToolbar extends React.Component {
  renderDashboardFilterChips() {
    const { appliedFilters, onDeleteFilter, chipClicked, filterMetadataStatus } = this.props
    const filterCheck = filterMetadataStatus?.status >= 200 && filterMetadataStatus.status < 300
    const filterOperName =
      (filterCheck &&
        filterMetadataStatus.data?.filter_operations_aggregations.find(obj => obj.name === 'filter_operations')
          .allOperations) ||
      {}

    return (
      <>
        {filterCheck &&
          appliedFilters.map((filter, index) => {
            const pattern = filter.cardFilters[0].filter.pattern || ''
            const isEllipsis = pattern.length > 1 ? ',...' : ''
            let type = filterOperName[filter.cardFilters[0].filter.type] || filter.cardFilters[0].filter.type

            if (DATE_INTERVAL_TYPE_OPTIONS.includes(type)) {
              type = ''
            }
            return (
              <Tooltip
                key={`${filter.cardFilters[0].filter.dimension}-${index}`}
                id={filter.cardFilters[0].filter.dimension}
                title={`${filter.filter_name || filter.cardFilters[0].filter.dimension} ${type} ${pattern}`}
              >
                <Chip
                  id={`dashboard-viewer-filter-chip-${index}`}
                  variant="outlined"
                  className="editable-filter-chip greenFieldNoPrint"
                  label={`${filter.filter_name} ${type} ${pattern.toString().split(',')[0]}${isEllipsis}`}
                  aria-labelledby={`${filter.filter_name} ${type} ${pattern.toString().split(',')[0]}${isEllipsis}`}
                  key={filter.filter_name}
                  onClick={() => {
                    chipClicked(filter.filter_id)
                  }}
                  onDelete={() => {
                    onDeleteFilter(filter)
                  }}
                  deleteIcon={
                    <div id={`delete-dashboard-viewer-filter-chip-${index}`}>
                      <Cancel />
                    </div>
                  }
                  avatar={
                    <Avatar id={`edit-dashboard-viewer-filter-chip-${index}`}>
                      <Edit />
                    </Avatar>
                  }
                />
              </Tooltip>
            )
          })}
      </>
    )
  }

  renderCardViewerFilterChips() {
    const { appliedFilters, onDeleteFilter, chipClicked, filterMetadataStatus, cardInfo } = this.props
    const filterCheck = filterMetadataStatus && filterMetadataStatus.status >= 200 && filterMetadataStatus.status < 300

    const filterOperName =
      filterCheck &&
      filterMetadataStatus.data.filter_operations_aggregations.find(obj => obj.name === 'filter_operations')
        .allOperations
    const cardConfig = cardInfo && cardInfo.status >= 200 && cardInfo.status < 300 ? cardInfo.data.card_config : ''
    const cardQueryAttribute = cardConfig && cardConfig.card_query_attribute
    const isCardViewerFilters =
      cardQueryAttribute && cardQueryAttribute.viewer_filters && Boolean(cardQueryAttribute.viewer_filters.length)

    return (
      <>
        {filterCheck &&
          appliedFilters.map((filter, index) => {
            let pattern = null
            if (filter.pattern) {
              pattern = cloneDeep(filter.pattern)
              if (typeof pattern !== 'string') {
                if (pattern === null) {
                  pattern = 'null'
                } else {
                  const indexValue = pattern.indexOf(null)
                  if (indexValue > -1) {
                    pattern[indexValue] = 'null'
                  }
                }
              }
            }
            const valueLabel = pattern
              ? `${pattern.toString().split(',')[0]},...`
              : filter.value || filter.value === 0
              ? filter.value.toString()
              : filter.time_period?.interval
              ? filter.time_period.interval
              : filter.time_period?.granularity
              ? `Granularity-${filter.time_period.granularity}`
              : `${filter.lowervalue}-${filter.uppervalue}`
            const titleLabel = pattern
              ? pattern.toString()
              : filter.value || filter.value === 0
              ? filter.value.toString()
              : filter.time_period?.interval
              ? filter.time_period.interval
              : `${filter.lowervalue} to ${filter.uppervalue}`

            const chipLabel =
              filter.type === 'interval'
                ? `${filter.display_name || filter.dimension}`
                : `${filter.display_name || filter.dimension} ${filterOperName[filter.type]}`

            return (
              <Tooltip
                key={`${filter.display_name}-${index}`}
                id={filter.dimension}
                title={`${chipLabel} ${titleLabel}`}
              >
                <Chip
                  id={`card-viewer-filter-chip-${index}`}
                  variant="outlined"
                  className={filter.passedViaRouting ? 'uneditable-filter-chip' : 'editable-filter-chip'}
                  label={`${chipLabel} ${valueLabel}`}
                  aria-labelledby={`${chipLabel} ${valueLabel}`}
                  key={filter.display_name}
                  onClick={
                    filter.passedViaRouting
                      ? null
                      : () => {
                          chipClicked(filter.dimension)
                        }
                  }
                  deleteIcon={
                    <div id={`delete-card-viewer-filter-chip-${index}`}>
                      <Cancel />
                    </div>
                  }
                  onDelete={() => {
                    onDeleteFilter(filter)
                  }}
                  avatar={
                    !filter.passedViaRouting && isCardViewerFilters ? (
                      <Avatar id={`edit-card-viewer-filter-chip-${index}`}>
                        <Edit />
                      </Avatar>
                    ) : null
                  }
                />
              </Tooltip>
            )
          })}
      </>
    )
  }

  render() {
    const { type } = this.props

    return (
      <div className="filter-chip-container">
        {type === 'dashboard' ? this.renderDashboardFilterChips() : this.renderCardViewerFilterChips()}
      </div>
    )
  }
}

FilterChipToolbar.defaultProps = {
  appliedFilters: [],
  type: 'card',
  onDeleteFilter: () => {},
  chipClicked: () => {},
}

FilterChipToolbar.propTypes = {
  appliedFilters: PropTypes.array,
  type: PropTypes.string,
  onDeleteFilter: PropTypes.func,
  chipClicked: PropTypes.func,
}

export default FilterChipToolbar
