import React from 'react'
import { connect } from 'react-redux'

import {
  notificationCreateAction,
  notificationEditAction,
  fetchAllNotificationAction,
  deleteNotificationAction,
  publishNotificationAction,
  createNotification,
  fetchObjectNotificationListing,
  updateNotification,
  deleteObjectNotification,
  clearObjectNotificationStatus,
  clearDeleteObjectNotificationStatus,
  getObjectNotificationBasedOnId,
} from '../../../ducks/notification'
import { getCardMeta } from '../../../ducks/cards'
import { getViewDashboardInfo } from '../../../ducks/dashboard'
import { getDatasetDetail } from '../../../ducks/dataset'
import { displayServiceErrorMessage } from '../../../ducks/layout'
import { getDetailsOwners } from '../../../ducks/builder'
import NotificationManager from './NotificationManager'

const NotificationManagerContainer = props => <NotificationManager {...props} />

const mapStateToProps = state => ({
  notificationCreateStatus: state.notification.notificationCreateStatus,
  notificationEditStatus: state.notification.notificationEditStatus,
  notificationFetchAllStatus: state.notification.notificationFetchAllStatus,
  deleteNotificationStatus: state.notification.deleteNotificationStatus,
  publishNotificationStatus: state.notification.publishNotificationStatus,
  detailsOwnersStatus: state.builder.detailsOwnersStatus,
  currentUser: state.user.userType,
  createObjectNotificationStatus: state.notification.createObjectNotificationStatus,
  getObjectListings: state.notification.getObjectListings,
  updateNotificationStatus: state.notification.updateNotificationStatus,
  deleteObjectNotificationStatus: state.notification.deleteObjectNotificationStatus,
  cardInfoStatus: state.cards.cardInfoStatus,
  viewDashBoardInfoStatus: state.dashboard.viewDashBoardInfoStatus,
  datasetDetailStatus: state.dataset.datasetDetailStatus,
  objectIdNotificationStatus: state.notification.objectIdNotificationStatus,
})

export const mapDispatchToProps = dispatch => ({
  createNotification(data) {
    dispatch(createNotification(data))
  },
  fetchObjectNotificationListing(data) {
    dispatch(fetchObjectNotificationListing(data))
  },
  clearObjectNotificationStatus(data) {
    dispatch(clearObjectNotificationStatus(data))
  },
  clearDeleteObjectNotificationStatus(data) {
    dispatch(clearDeleteObjectNotificationStatus(data))
  },
  notificationCreateAction(data) {
    dispatch(notificationCreateAction(data))
  },
  notificationEditAction(data) {
    dispatch(notificationEditAction(data))
  },
  fetchAllNotificationAction(data) {
    dispatch(fetchAllNotificationAction(data))
  },
  deleteNotificationAction(data) {
    dispatch(deleteNotificationAction(data))
  },
  publishNotificationAction(data) {
    dispatch(publishNotificationAction(data))
  },
  getDetailsOwnersAction(data) {
    dispatch(getDetailsOwners(data))
  },
  displayServiceErrorMessage(data) {
    dispatch(displayServiceErrorMessage(data))
  },
  updateNotification(data) {
    dispatch(updateNotification(data))
  },
  deleteObjectNotification(data) {
    dispatch(deleteObjectNotification(data))
  },
  getCardMeta(data) {
    dispatch(getCardMeta(data))
  },
  getViewDashboardInfo(data) {
    dispatch(getViewDashboardInfo(data))
  },
  getDatasetDetail(data) {
    dispatch(getDatasetDetail(data))
  },
  getObjectNotificationBasedOnId(data) {
    dispatch(getObjectNotificationBasedOnId(data))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(NotificationManagerContainer)
