import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { createLargeExportJob } from '../../../../ducks/LargeExport'
import LargeExportInit from './LargeExportInit'

const LargeExportContainer = props => {
  return <LargeExportInit {...props} />
}

export const mapDispatchToProps = dispatch => ({
  createLargeExportJob(data) {
    dispatch(createLargeExportJob(data))
  },
})

export const mapStateToProps = state => {
  return {
    newLargeExportJobInfo: state.largeExport.newLargeExportJobInfo,
    anchorDateFilterStatus: state.timeperiod.anchorDateFilterStatus,
    userInfo: state.user,
  }
}

LargeExportContainer.propTypes = {
  routeProps: PropTypes.object,
  setHeaderTitle: PropTypes.func,
  user: PropTypes.object,
  isMobile: PropTypes.bool,
  deviceType: PropTypes.array,
}

export default connect(mapStateToProps, mapDispatchToProps)(LargeExportContainer)
