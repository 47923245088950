function DashboardService() {
  return {
    getDashboardMetadata: data => {
      return {
        alert_count: data.alert_count,
        business_area: data.business_area,
        card_count: data.card_count,
        created_by: data.created_by,
        dashboard_data_classification: data.dashboard_data_classification,
        dashboard_title: data.dashboard_title,
        description: data.description,
        edit_enabled: data.edit_enabled,
        favorite: data.favorite,
        last_modified_time: data.last_modified_time,
        view_count: data.view_count,
        id: data._id,
        filters: data.filters,
        saved_filters: data.saved_filters,
        enable_save_as: data.enable_save_as,
      }
    },

    getCardId: (cardData, item) => {
      return cardData.reduce((prev, value) => {
        if (item.id === value.cardContainerId) prev = value.cardId
        return prev
      }, undefined)
    },

    getCardSize: (cardId, tabs) => {
      let cardDetails
      const cardSize = { height: 4, width: 4 }
      for (const tab of tabs) {
        cardDetails = tab.detailContents.find(card => {
          if (card.id) {
            return card.id.toString() === cardId.toString()
          }

          return undefined
        })
        if (cardDetails) {
          cardSize.height = cardDetails.ySize
          cardSize.width = cardDetails.xSize
        }
      }
      return cardSize
    },

    getCardType: (cardId, tabs) => {
      let cardDetails, cardType
      for (const tab of tabs) {
        cardDetails = tab.detailContents.find(card => {
          if (card.id) {
            return card.id.toString() === cardId.toString()
          }

          return undefined
        })
        if (cardDetails) {
          cardType = cardDetails.type
        }
      }
      return cardType
    },
  }
}

export default DashboardService()
