/**
 * Filter/Search component - to search from table-data
 */
import React from 'react'
import { TextField, InputAdornment, Icon, IconButton, Tooltip } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import PropTypes from 'prop-types'

import '../../../styles/global.scss'

const AdvanceFilter = ({
  isSmall = false,
  onSearchClick,
  className,
  label,
  onSearchChange,
  onKeyDown,
  searchValue,
  id,
  ...rest
}) => (
  <TextField
    {...rest}
    className={className}
    type="text"
    InputProps={{
      endAdornment: (
        <InputAdornment data-cy="search-icon" position="end">
          {onSearchClick ? (
            <Tooltip placement="top" title="Search">
              <IconButton onClick={onSearchClick}>
                <SearchIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <Icon>search</Icon>
          )}
        </InputAdornment>
      ),
    }}
    size={isSmall ? 'small' : 'medium'}
    variant="outlined"
    label={label}
    onChange={onSearchChange}
    onKeyDown={onKeyDown}
    value={searchValue}
    id={id || null}
    name="filter-text-box"
  />
)

AdvanceFilter.propTypes = {
  className: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  searchValue: PropTypes.string.isRequired,
  onSearchChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}

export default AdvanceFilter
