import React, { useEffect, useState } from 'react'
import { Close } from '@mui/icons-material'
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  TextField,
} from '@mui/material'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { Spinner, createErrMsg } from 'greenfield-utilities'
import { displayServiceErrorMessage } from '../../../../ducks/layout'
import GFAutocomplete, { filterUserType } from '../../../shared/MUIAutoComplete/Autocomplete'
import {
  clearFFCreate,
  clearFFUpdate,
  createFeatureFlag,
  getFeatureFlags,
  updateFeatureFlag,
} from '../../../../ducks/featureFlag'
import { getOwnerDetail } from '../../Cards/Cardviewer/DataAlertAndSubscription/Helper'
import { getUserAccess } from '../../../../ducks/user'
import { ACCESS_CONTROL } from './BetaFeatures'

const STextField = styled(TextField)`
  margin-bottom: 12px;
`

const FeatureFlagAddEditDialog = props => {
  const { isDialogOpen, handleDialogClose, featureFlag } = props
  const [allUserDetails, setAllUserDetails] = useState([])
  const [allGroupDetails, setAllGroupDetails] = useState([])
  const [denyList, setDenyList] = useState([])
  const [grantList, setGrantList] = useState([])
  const [participantList, setParticipantList] = useState([])
  const [id, setId] = useState(featureFlag?._id || '')
  const [label, setLabel] = useState(featureFlag?.label || '')
  const [error, setError] = useState(false)
  const [description, setDescription] = useState(featureFlag?.description || '')
  const [accessType, setAccessType] = useState(featureFlag?.access?.access_control || ACCESS_CONTROL.RESTRICTED)
  const [canOptIn, setCanOptIn] = useState(featureFlag?.participation?.can_opt_in || false)
  const [isLoading, setIsLoading] = useState(false)

  const dispatch = useDispatch()
  const { featureFlagCreateStatus, featureFlagUpdateStatus } = useSelector(state => state.featureFlag)

  const handleAccessChange = event => {
    setAccessType(event.target.value)
  }

  const handleIdChange = event => {
    setId(event.target.value?.toUpperCase())
    setError(/\s/g.test(event.target.value))
  }

  const handleSave = () => {
    const payload = {
      label,
      access: {
        access_control: accessType,
        grant: {
          users: grantList && filterUserType(grantList, 'user'),
          groups: grantList && filterUserType(grantList, 'group'),
        },
        deny: {
          users: denyList && filterUserType(denyList, 'user'),
          groups: denyList && filterUserType(denyList, 'group'),
        },
      },
      participation: {
        can_opt_in: canOptIn,
        ...(participantList && {
          participants: {
            users: participantList && filterUserType(participantList, 'user'),
            groups: participantList && filterUserType(participantList, 'group'),
          },
        }),
      },
      description,
    }
    if (featureFlag?._id) {
      dispatch(updateFeatureFlag({ id: featureFlag?._id, data: payload }))
    } else {
      const data = {
        _id: id,
        ...payload,
      }
      dispatch(createFeatureFlag(data))
    }
  }

  useEffect(() => {
    if (featureFlag?.access && accessType !== ACCESS_CONTROL.NO_USERS) {
      const { grant, deny } = featureFlag.access
      const { users, groups } = featureFlag.participation?.participants

      const userIds = Array.from(
        new Set([
          ...(grant.users?.length ? [...grant.users] : []),
          ...(deny.users?.length ? [...deny.users] : []),
          ...(users.length ? [...users] : []),
        ])
      )
      const groupIds = Array.from(
        new Set([
          ...(grant.groups?.length ? [...grant.groups] : []),
          ...(deny.groups?.length ? [...deny.groups] : []),
          ...(groups.length ? [...groups] : []),
        ])
      )

      if (userIds.length) {
        setIsLoading(true)
        getOwnerDetail(true, userIds, setAllUserDetails, setIsLoading, dispatch)
      }

      if (groupIds.length) {
        setIsLoading(true)
        getOwnerDetail(false, groupIds, setAllGroupDetails, setIsLoading, dispatch)
      }
    }
  }, [featureFlag, accessType])

  useEffect(() => {
    if (featureFlag.access) {
      const { grant, deny } = featureFlag.access
      const { participants } = featureFlag.participation
      if (allUserDetails.length || allGroupDetails.length) {
        setIsLoading(true)
        if (grant.users.length || grant.groups.length) {
          setGrantList(getUserAndGroupDetail(allUserDetails, allGroupDetails, grant))
        }
        if (deny.users.length || deny.groups.length) {
          setDenyList(getUserAndGroupDetail(allUserDetails, allGroupDetails, deny))
        }
        if (participants.users.length || participants.groups.length) {
          setParticipantList(getUserAndGroupDetail(allUserDetails, allGroupDetails, participants))
        }
        setIsLoading(false)
      }
    }
  }, [allUserDetails, allGroupDetails, featureFlag])

  useEffect(() => {
    if (featureFlagCreateStatus?.status === 201) {
      dispatch(getFeatureFlags())
      dispatch(getUserAccess())
      dispatch(clearFFCreate())
      handleDialogClose()
    } else if (featureFlagCreateStatus?.status === 'failed') {
      handleDialogClose()
      dispatch(clearFFCreate())
      dispatch(displayServiceErrorMessage(createErrMsg(featureFlagCreateStatus, 'Feature flag post request failed!')))
    }
  }, [featureFlagCreateStatus])

  useEffect(() => {
    if (featureFlagUpdateStatus?.status === 200) {
      dispatch(getFeatureFlags())
      dispatch(getUserAccess())
      dispatch(clearFFUpdate())
      handleDialogClose()
    } else if (featureFlagUpdateStatus?.status === 'failed') {
      dispatch(displayServiceErrorMessage(createErrMsg(featureFlagUpdateStatus, 'Feature flag update request failed!')))
      dispatch(clearFFUpdate())
      handleDialogClose()
    }
  }, [featureFlagUpdateStatus])

  const getUserAndGroupDetail = (allUserDetails, allGroupDetails, { users, groups }) => {
    const userDetails = users.map(user => {
      const userData = allUserDetails.find(userDetail => userDetail._id === user)
      if (userData) {
        return {
          user_group_name:
            userData.first_name === 'NonUser' ? userData._id : `${userData.first_name} ${userData.last_name}`,
          _id: userData._id,
          type: 'user',
        }
      }
      return {}
    })
    const groupDetails = groups.map(group => {
      const groupData = allGroupDetails.find(groupDetail => groupDetail._id === group)
      if (groupData) {
        return {
          user_group_name: groupData.group_name,
          _id: groupData._id,
          type: 'group',
        }
      }
      return {}
    })
    return [...userDetails, ...groupDetails]
  }

  return (
    <Dialog
      maxWidth="md"
      fullWidth
      open={isDialogOpen}
      aria-labelledby="add-edit-ff-dialog"
      onClose={handleDialogClose}
    >
      <DialogTitle>
        <Grid container justifyContent="space-between" alignItems="center">
          {featureFlag?.label ? 'Edit Feature Flag' : 'Add Feature Flag'}
          <IconButton onClick={handleDialogClose}>
            <Close />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>
        {isLoading ? (
          <Box sx={{ height: 270 }}>
            <Spinner />
          </Box>
        ) : (
          <Grid container justifyContent="space-between">
            <Grid item xs={6}>
              <STextField
                sx={{ paddingRight: 1 }}
                value={id}
                onChange={handleIdChange}
                error={error}
                required
                id="name"
                label="Unique ID"
                fullWidth
                data-cy="feature-flag-id"
                variant="outlined"
                disabled={!!featureFlag?._id}
                helperText={error && 'Spaces are not allowed for Id'}
              />
            </Grid>
            <Grid item xs={6}>
              <STextField
                id="access-type"
                label="Access Type"
                select
                fullWidth
                variant="outlined"
                value={accessType}
                data-cy="feature-flag-access-type"
                onChange={handleAccessChange}
              >
                <MenuItem key={ACCESS_CONTROL.ALL_USERS} value={ACCESS_CONTROL.ALL_USERS}>
                  All Users
                </MenuItem>
                <MenuItem key={ACCESS_CONTROL.RESTRICTED} value={ACCESS_CONTROL.RESTRICTED}>
                  Restricted
                </MenuItem>
                <MenuItem key={ACCESS_CONTROL.NO_USERS} value={ACCESS_CONTROL.NO_USERS}>
                  No Users
                </MenuItem>
              </STextField>
            </Grid>
            <Grid item xs={12}>
              <STextField
                id="label"
                label="Label"
                data-cy="feature-flag-label"
                value={label}
                onChange={event => setLabel(event.target.value)}
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <STextField
                id="description"
                label="Description"
                value={description}
                data-cy="feature-flag-description"
                onChange={event => setDescription(event.target.value)}
                fullWidth
                multiline
                variant="outlined"
              />
            </Grid>
            {accessType !== ACCESS_CONTROL.NO_USERS && (
              <>
                <Grid item xs={12}>
                  <Box mb="12px">
                    <GFAutocomplete
                      id="feature-flag-grant-list"
                      label="Grant Acces(s)"
                      value={grantList}
                      setValue={setGrantList}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box mb="12px">
                    <GFAutocomplete
                      id="feature-flag-deny-list"
                      label="Deny Access"
                      value={denyList}
                      setValue={setDenyList}
                      required={false}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box mb="12px">
                    <GFAutocomplete
                      id="feature-flag-participant-list"
                      label="Participants"
                      value={participantList}
                      setValue={setParticipantList}
                      required={false}
                    />
                  </Box>
                </Grid>
              </>
            )}
            {accessType !== ACCESS_CONTROL.NO_USERS && (
              <Grid item xs={12}>
                <FormControlLabel
                  label="Allow Users to Opt In"
                  control={
                    <Checkbox
                      data-cy="feature-flag-allow-opt-in"
                      checked={canOptIn}
                      onChange={event => setCanOptIn(event.target.checked)}
                    />
                  }
                />
              </Grid>
            )}
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          data-cy="feature-flag-save"
          onClick={handleSave}
          disabled={
            accessType === ACCESS_CONTROL.RESTRICTED
              ? !grantList.length || !id
              : ((accessType === ACCESS_CONTROL.ALL_USERS || accessType === ACCESS_CONTROL.NO_USERS) && !id) ||
                !id ||
                error
          }
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default FeatureFlagAddEditDialog
