import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Tooltip,
  IconButton,
  Button,
  Dialog,
  Typography,
  DialogContent,
  DialogActions,
  DialogTitle,
  Icon,
  Grid,
  Chip,
  CircularProgress,
} from '@mui/material'
import { Close, DeleteOutline, EditOutlined, Info, SwapHorizontalCircleOutlined } from '@mui/icons-material'
import moment from 'moment'
import axios from 'axios'

import ConfirmDialog from '../../shared/Dialog/ConfirmDialog'
import {
  clearDatastore,
  clearDatastoreDetail,
  clearTestDatastore,
  deleteDatastore,
  getDatastore,
  getDatastoreList,
  testConnection,
} from '../../../ducks/datastore'
import { GridItem } from '../Datasets/DatasetDetailComponent'
import { AgGridTable } from '../../shared/AgGrid/AgGridTableClientSide'
import { API_GATEWAY_URL } from '../../../ducks/utils'
import DatastoreDetails from './DatastoreDetails'

const getDatasetListCols = () => [
  {
    field: 'name',
    headerName: 'Dataset Name',
    cellRendererFramework: ({ data = {} }) => {
      const businessName = data.name
      return (
        <a
          data-cy={`dataset-name-${businessName}`}
          aria-label={businessName}
          className="edit-dataset-link"
          href={`/dataset/preview/${data._id}`}
        >
          {businessName}
        </a>
      )
    },
  },
  {
    field: 'owners',
    headerName: 'Owner(s)',
    cellRendererFramework: ({ data = {}, node = {} }) => {
      return (
        <>
          {data.owners_access?.map(owners => (
            <Chip
              size="small"
              label={owners.user_group_name}
              key={`${owners.user_group_name}${node.rowIndex}`}
              className="chip-owners"
            />
          ))}
        </>
      )
    },
  },
  {
    field: 'last_updated_date',
    headerName: 'Last Edited',
    cellRendererFramework: ({ data }) =>
      data?.audit_properties?.updated_date &&
      moment(data.audit_properties.updated_date, "YYYY-MM-DD'T'HH:mm:ssZ").fromNow(),
  },
]

export const DatastoreActionRenderer = props => {
  const { rowIndex, data = {}, node, error } = props
  const dispatch = useDispatch()
  const { datastoreTestStatus, datastoreStatus } = useSelector(state => state.datastore)

  const defaultParams = {
    isOpen: false,
    selectedDatastoreId: '',
    selectedDatastoreName: '',
  }

  const [openEditDialog, setOpenEditDialog] = useState(false)
  const [connectionDetails, setConnectionDetails] = useState(null)
  const [selectedDatastore, setSelectedDatastore] = useState(defaultParams)
  const [openTestDialog, setOpenTestDialog] = useState(false)
  const [openListDatasetDialog, setOpenListDatasetDialog] = useState(false)
  const [datasetList, setDatasetList] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const { isOpen, selectedDatastoreId, selectedDatastoreName } = selectedDatastore

  useEffect(() => {
    if (datastoreStatus?.data) {
      setConnectionDetails(datastoreStatus?.data)
    }
  }, [datastoreStatus])

  const handleCloseDeleteDatasetDialog = () => {
    setOpenListDatasetDialog(false)
    setDatasetList([])
  }

  // Delete Datastore
  const handleDeleteDatastore = () => {
    setIsLoading(true)
    axios
      .delete(`${API_GATEWAY_URL}/bi_reporting_assets/v2/datastores/${selectedDatastoreId}`)
      .then(() => {
        setIsLoading(false)
        dispatch(getDatastoreList())
      })
      .catch(datastoreDeleteError => {
        const listDataset = datastoreDeleteError?.response?.data?.context?.dataset || []
        setDatasetList(listDataset)
        setIsLoading(false)
        setOpenListDatasetDialog(true)
      })

    setSelectedDatastore(defaultParams)
  }

  const handleOnClickEditDatastore = id => {
    dispatch(getDatastore({ _id: id }))
    setOpenEditDialog(true)
  }

  const handleEditDialogClose = () => {
    dispatch(clearDatastoreDetail())
    dispatch(clearTestDatastore())
    dispatch(clearDatastore())
    setOpenEditDialog(false)
  }

  const handleOnClickDeleteDatastore = (selectedDatastoreId, selectedDatastoreName) => {
    setSelectedDatastore({ isOpen: true, selectedDatastoreId, selectedDatastoreName })
  }

  const handleCloseDialog = () => {
    setSelectedDatastore(defaultParams)
  }

  const handleTestDialogOpen = datastoreId => {
    setOpenTestDialog(true)
    dispatch(testConnection({ _id: datastoreId }))
  }

  const handleTestDialogClose = () => {
    setOpenTestDialog(false)
    dispatch(clearTestDatastore())
  }

  const renderDetailsOfDatasets = () => {
    return (
      <Dialog maxWidth={false} open={openListDatasetDialog} aria-labelledby="delete-datastore-confirmation">
        <DialogTitle variant="subtitle1" data-cy="delete-datastore-header">
          <div className="datastore-info">
            <Icon classes={{ root: 'warning-icon' }}>warning</Icon>
            <Typography variant="subtitle1" data-cy="delete-datastore-header">
              Datastore <strong>{data.datastore_name}</strong> cannot be deleted because there are dependent datasets on
              it. Delete datasets listed below to delete the datastore.
            </Typography>
          </div>
        </DialogTitle>
        <DialogContent dividers>
          <Typography variant="h6">List of Dataset using the Datastore</Typography>
          <Grid container>
            <GridItem primary="Datastore Status" secondary="Active" />
            <GridItem primary="Number of Datasets" secondary={datasetList?.length} />
          </Grid>

          <AgGridTable data={datasetList} pageSize={5} columns={getDatasetListCols()} />
        </DialogContent>
        <DialogActions>
          <Button variant="text" color="primary" onClick={handleCloseDeleteDatasetDialog}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  return (
    <>
      <Tooltip title="Edit Datastore" placement="top">
        <IconButton
          component="div"
          data-cy={`datastorelist-edit-${rowIndex}`}
          id={`edit-datastore-${data.datastore_name}`}
          aria-label={`Edit Datastore ${data.datastore_name}`}
          onClick={() => handleOnClickEditDatastore(data._id)}
          disabled={data.edit_enabled !== 'yes'}
        >
          <EditOutlined color={data.edit_enabled === 'yes' ? 'success' : 'inherit'} />
        </IconButton>
      </Tooltip>
      <Tooltip title="Delete Datastore" placement="top">
        <IconButton
          component="div"
          id={`delete-datastore-${data.datastore_name}`}
          data-cy={`delete-datastore-${data.datastore_name}`}
          aria-label={`Delete Datastore ${data.datastore_name}`}
          onClick={() => handleOnClickDeleteDatastore(data._id, data.datastore_name)}
          disabled={data.edit_enabled !== 'yes'}
        >
          {isLoading ? (
            <CircularProgress size={20} color="inherit" />
          ) : (
            <DeleteOutline color={data.edit_enabled === 'yes' ? 'error' : 'inherit'} />
          )}
        </IconButton>
      </Tooltip>
      <Tooltip title="Test Database Connection" placement="top">
        <IconButton
          component="div"
          data-cy={`datastore-notification-${node.rowIndex}`}
          id={`test-datastore-${data.datastore_name}`}
          aria-label={`Test Database Connection ${data.datastore_name}`}
          onClick={() => handleTestDialogOpen(data._id)}
          disabled={data.edit_enabled !== 'yes'}
        >
          <SwapHorizontalCircleOutlined
            style={data.edit_enabled === 'yes' ? { color: '#2278cf' } : { color: 'inherit' }}
          />
        </IconButton>
      </Tooltip>
      <Dialog
        open={(datastoreTestStatus?.status === 'failed' || datastoreTestStatus?.status === 200) && openTestDialog}
        onClose={handleTestDialogClose}
        id="test-result"
      >
        <div className="dialog-container display-flex">
          <Typography variant="h2">Test Result</Typography>
          <IconButton onClick={handleTestDialogClose} id="test-datastore-close">
            <Close />
          </IconButton>
        </div>
        <DialogContent className="dialog-container">
          <div id="test-message">
            <div className="display-flex">
              <Info className="svg-align" color={datastoreTestStatus?.status === 'failed' ? 'error' : 'inherit'} />
              Test Connection
              {datastoreTestStatus?.status === 'failed'
                ? ' failed'
                : datastoreTestStatus?.status === 200
                ? ' success'
                : ''}
            </div>
            <div className="color-margin">
              {datastoreTestStatus?.status === 'failed' &&
                (datastoreTestStatus?.message?.message || datastoreTestStatus?.message)}
            </div>
          </div>
        </DialogContent>
        <DialogActions className="button-color">
          <Button onClick={handleTestDialogClose} variant="outlined" color="secondary" id="test-datastore-confirm">
            OK
          </Button>
        </DialogActions>
      </Dialog>
      {openListDatasetDialog && renderDetailsOfDatasets()}
      <ConfirmDialog
        open={isOpen}
        contentText={`Are you sure you want to delete datastore - ${selectedDatastoreName}?`}
        errorText={deleteDatastore?.message}
        okText="DELETE"
        onCloseDialog={handleCloseDialog}
        onClickOk={handleDeleteDatastore}
        onClickCancel={handleCloseDialog}
      />
      <DatastoreDetails
        open={openEditDialog}
        error={error}
        handleDialogClose={handleEditDialogClose}
        details={connectionDetails}
        title="Edit Postgres Connection"
      />
    </>
  )
}
