import React, { createRef } from 'react'
import { Tooltip, TextField, Typography, Button, IconButton, Paper } from '@mui/material'
import PropTypes from 'prop-types'
import { Delete, AddCircle, Close, Info } from '@mui/icons-material'
import isEqual from 'lodash/isEqual'
import { Link } from 'react-router-dom'
import { createErrMsg, Spinner } from 'greenfield-utilities'
import ConfirmDialog from '../../shared/Dialog/ConfirmDialog'
import { AgGridTable } from '../../shared/AgGrid/AgGridTableClientSide'
import { TableFilter } from '../../shared/AgGrid/TableFilter'
import GFAutocomplete from '../../shared/MUIAutoComplete/Autocomplete'

import './admin.scss'

class AdminMembers extends React.Component {
  gridRef = createRef()
  state = {
    isMemberAddSubmit: false,
    isMemberDelete: false,
    selectedMembers: [],
    members: [],
    newMembers: [],
    deleteModalShown: false,
    isAddUserGroup: false,
    adUserGroupName: '',
    isDisableButtons: false,
    pageSize: 10,
    columnsDefs: [
      {
        field: 'name',
        checkboxSelection: p => p.context,
        headerCheckboxSelection: () => this.gridOptions.context,
        cellRendererFramework: ({ data, value }) => {
          return (
            <Link className="admin-member-link" to={`/admin/people/${data._id}/groups`} data-cy="admin-member-link">
              {value}
            </Link>
          )
        },
      },
      {
        headerName: 'Member Id',
        field: '_id',
      },
      {
        headerName: 'Email',
        field: 'email_address',
      },
    ],
  }

  gridOptions = {
    context: this.state.isMemberDelete,
    suppressCellSelection: true,
  }

  componentDidMount() {
    this.setState({ members: this.props.adminDetail.data.members })
    if (this.props.adminDetail?.data?.provision_process?.rule) {
      this.setState({ isDisableButtons: true })
    }
    this.props.getUserAccess()
  }

  componentDidUpdate(prevProps) {
    const { addAdGroupStatus, displayServiceErrorMessage } = this.props
    if (!isEqual(prevProps.addAdGroupStatus, addAdGroupStatus)) {
      if (addAdGroupStatus?.data?.provision_process?.rule) {
        window.location.reload()
      } else if (addAdGroupStatus?.status === 'failed') {
        // error handling
        displayServiceErrorMessage(createErrMsg(null, addAdGroupStatus?.message))
      }
    }
  }

  updateState = (stateName, value) => {
    this.setState({ [stateName]: value })
  }

  handleShowDeleteConfirm = () => {
    this.setState({
      deleteModalShown: true,
    })
  }

  handleAddUserGroupClick = () => {
    this.setState({
      isAddUserGroup: true,
      isMemberAddSubmit: false,
    })
  }

  handleAddSubmitClick = () => {
    this.setState({
      isMemberAddSubmit: true,
      isAddUserGroup: false,
      newMembers: [],
    })
  }

  handleCancelClick = () => {
    this.setState(() => {
      this.gridOptions.context = false
      this.gridApi.redrawRows()
      this.gridApi.refreshHeader()
      this.gridOptions.api.deselectAll()
      return { isMemberDelete: false, isMemberAddSubmit: false }
    })
  }

  handleDeleteClick = () => {
    this.setState(() => {
      this.gridOptions.context = true
      this.gridApi.redrawRows()
      this.gridApi.refreshHeader()
      return { isMemberDelete: true }
    })
  }

  handleSaveClick = () => {
    this.setState({ isMemberAddSubmit: false })

    this.setState(
      this.props.addMember({
        groupId: this.props.adminDetail.data._id,
        data: this.state.newMembers,
      })
    )
  }

  handleDeleteSubmitClick = () => {
    this.setState(() => {
      this.gridOptions.context = false
      this.gridApi.redrawRows()
      this.gridApi.refreshHeader()
      return { isMemberDelete: false, deleteModalShown: false, selectedMembers: [] }
    })

    this.props.deleteMember({
      groupId: this.props.adminDetail.data._id,
      data: this.state.selectedMembers,
    })
  }

  static getDerivedStateFromProps(nextProps) {
    const { addedMembers, adminDetail } = nextProps
    let members = []

    if (
      adminDetail.data &&
      adminDetail.data._id &&
      addedMembers.data &&
      addedMembers.data._id &&
      addedMembers.data._id === adminDetail.data._id
    ) {
      members = addedMembers.data.members
    } else if (adminDetail.data) {
      members = adminDetail.data.members
    }

    return { members }
  }

  renderTablePreHeader() {
    const {
      isMemberAddSubmit,
      isMemberDelete,
      isAddUserGroup,
      newMembers,
      deleteModalShown,
      selectedMembers,
      members,
      isDisableButtons,
    } = this.state

    if (!members) {
      return <Spinner />
    }

    const { adminDetail, isMobile, userAccessStatus, currentUser } = this.props

    const canEdit = adminDetail.data.edit_enabled === 'yes'

    const disabled = members.length === 0

    const getAddDelBtns = () => {
      return !isMobile ? (
        <div className="button-end">
          {disabled &&
            userAccessStatus?.data?.ACTIVE_DIRECTORY_SYNC_UI_ENABLED &&
            adminDetail?.data?.user_type?.toLowerCase() === 'internal' &&
            currentUser?.data?.user_type?.toLowerCase() === 'internal' && (
              <Button
                id="add-ad-group-btn"
                className="add-btn icon-label-btn-props"
                onClick={this.handleAddUserGroupClick}
                color="secondary"
                disabled={this.state.isDisableButtons}
              >
                <AddCircle className="icon-left" />
                Add AD Group
              </Button>
            )}
          <Button
            id="add-member-btn"
            className="add-btn icon-label-btn-props"
            onClick={this.handleAddSubmitClick}
            disabled={this.state.isDisableButtons}
            color="secondary"
            startIcon={<AddCircle />}
          >
            Add Member(s)
          </Button>
          {!disabled && (
            <Button
              id="delete-member-btn"
              className="delete-btn icon-label-btn-props"
              disabled={disabled || this.state.isDisableButtons}
              onClick={this.handleDeleteClick}
              color="secondary"
              startIcon={<Delete />}
            >
              Remove Member(s)
            </Button>
          )}
        </div>
      ) : (
        <div className="button-end">
          <IconButton id="add-member-btn-mobile" onClick={this.handleAddSubmitClick}>
            <AddCircle className="align-icon" />
          </IconButton>
          <IconButton id="delete-member-btn-mobile" disabled={disabled} onClick={this.handleDeleteClick}>
            <Delete className="align-icon" />
          </IconButton>
        </div>
      )
    }

    const handleSaveADGroupClick = () => {
      const { adminDetail } = this.props
      const { adUserGroupName } = this.state
      const adminDetailData = adminDetail?.data
      const ownerIds = adminDetailData?.owners_access?.map(eachOwner => eachOwner._id)
      this.props.addAdGroup({
        adGroupPayload: {
          group_name: adminDetailData?.group_name,
          owners: ownerIds,
          user_type: adminDetailData?.user_type,
          provision_process: {
            type: 'automated',
            source: 'LDAP',
            rule: adUserGroupName,
          },
        },
        groupId: adminDetailData?._id,
      })
    }

    const getAddUserGroup = () => (
      <div className="field-margin">
        <span className="align-title">
          <Typography variant="body1">Add AD Group</Typography>
          <Tooltip title="Please use exact name of the Active Directory(AD) Group">
            <Info />
          </Tooltip>
        </span>
        <TextField
          variant="standard"
          type="text"
          value={this.state.adUserGroupName}
          onChange={e => this.setState({ adUserGroupName: e.target.value })}
          placeholder="Enter the Active Directory(AD) Group"
        />

        <div className="field-margin">
          <Button
            id="save-member-btn"
            className="save-btn"
            disabled={!this.state.adUserGroupName}
            onClick={handleSaveADGroupClick}
            variant="text"
            color="primary"
          >
            Save
          </Button>
          <Button
            id="cancel-member-btn"
            variant="text"
            className="cancel-btn"
            onClick={() => this.setState({ isAddUserGroup: false })}
            color="secondary"
          >
            Cancel
          </Button>
        </div>
      </div>
    )

    const getAddMemField = () => (
      <div className="field-margin">
        <Typography variant="body1">Add Member(s)</Typography>
        <GFAutocomplete
          value={newMembers}
          setValue={newMembers => this.setState({ newMembers })}
          variant="standard"
          membersOnly
          vendors={adminDetail?.data.user_type === 'external'}
        />
        <div className="field-margin">
          <Button
            id="save-member-btn"
            className="save-btn"
            disabled={Boolean(newMembers.length === 0)}
            onClick={this.handleSaveClick}
            variant="text"
            color="primary"
          >
            Save
          </Button>
          <Button
            id="cancel-member-btn"
            variant="text"
            className="cancel-btn"
            onClick={this.handleCancelClick}
            color="secondary"
          >
            Cancel
          </Button>
        </div>
      </div>
    )

    const getDelConfirmBtns = () => {
      return !isMobile ? (
        <div className="button-end">
          <Button
            variant="text"
            className="delete-submit-btn"
            onClick={this.handleShowDeleteConfirm}
            color="primary"
            disabled={selectedMembers.length === 0}
          >
            Remove Member(s)
          </Button>
          <Button variant="text" className="cancel-btn" onClick={this.handleCancelClick} color="secondary">
            Cancel
          </Button>
        </div>
      ) : (
        <div className="button-end">
          <IconButton
            id="delete-member-btn-mobile"
            disabled={selectedMembers.length === 0}
            onClick={this.handleShowDeleteConfirm}
          >
            <Delete className="align-icon" />
          </IconButton>
          <IconButton id="cancel-del-group-btn-mobile" onClick={this.handleCancelClick}>
            <Close className="align-icon" />
          </IconButton>
        </div>
      )
    }

    const onRowSelected = event => {
      if (event.node.isSelected()) {
        const groupIndex = this.state.selectedMembers.findIndex(item => item._id === event.node.data._id)
        const selectedMembers =
          groupIndex > -1 ? this.state.selectedMembers : [...this.state.selectedMembers, event.node.data]
        this.setState({ selectedMembers })
      } else {
        const selectedMembers = this.state.selectedMembers.filter(item => item._id !== event.node.data._id)
        this.setState({ selectedMembers })
      }
    }

    const onGridReady = params => {
      this.gridApi = params.api
    }

    return (
      <Paper>
        <div className="dashboards-container">
          <div className="dashboards-header-container">
            <div className="detail-btn-section">
              {!isAddUserGroup && !isDisableButtons && (
                <Typography variant="subtitle1" className="member-label-message">
                  {members.length
                    ? `Displaying ${members.length} Records`
                    : !isMemberAddSubmit
                    ? `Group ${adminDetail.data.group_name} has no members. You can add members to the group.`
                    : ''}
                </Typography>
              )}
              {isAddUserGroup && !isDisableButtons && (
                <Typography variant="body1">
                  Specify an Active Directory group to add all members to Greenfield group. Users can request access in
                  MyAccess to get added to the specified user group.
                </Typography>
              )}
              {canEdit &&
                !isMemberAddSubmit &&
                !isMemberDelete &&
                !isAddUserGroup &&
                !isDisableButtons &&
                getAddDelBtns()}
              {isMemberDelete && !isMemberAddSubmit && getDelConfirmBtns()}
              <ConfirmDialog
                open={deleteModalShown && selectedMembers.length > 0}
                contentText="Are you sure you want to remove the selected member(s)?"
                okText="Yes, Remove Member(s)"
                onCloseDialog={() => {
                  this.updateState('deleteModalShown', false)
                }}
                onClickOk={this.handleDeleteSubmitClick}
                onClickCancel={() => {
                  this.updateState('deleteModalShown', false)
                }}
                cancelText="No"
              />
            </div>
            {isMemberAddSubmit && !isMemberDelete && !isAddUserGroup && getAddMemField()}
            {isAddUserGroup && !isMemberAddSubmit && !isMemberDelete && getAddUserGroup()}
            <>
              <TableFilter
                gridRef={this.gridRef}
                pageSize={this.state.pageSize}
                setPageSize={pageSize => this.setState({ pageSize })}
              />
              <AgGridTable
                gridRef={this.gridRef}
                columns={this.state.columnsDefs}
                rowSelection="multiple"
                onRowSelected={onRowSelected}
                data={members}
                onGridReady={onGridReady}
                gridOptions={this.gridOptions}
                pageSize={this.state.pageSize}
              />
            </>
          </div>
        </div>
      </Paper>
    )
  }

  render() {
    return this.renderTablePreHeader()
  }
}

AdminMembers.defaultProps = {
  routeProps: {},
  adminDetail: {},
  addedMembers: {},
  addMember: () => {},
}

AdminMembers.propTypes = {
  routeProps: PropTypes.object,
  adminDetail: PropTypes.object,
  addedMembers: PropTypes.object,
  addMember: PropTypes.func,
}

export default AdminMembers
