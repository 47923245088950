import React from 'react'
import {
  SimpleBarChartSubheaderIcon,
  StackedBarChartSubheaderIcon,
  StackedGroupedChartIcon,
  ParetoChartIcon,
  AreaChartIcon,
  StackedBarLineChartIcon,
  WordCloudIcon,
  BigNumberIcon,
  BarWithLineChartIcon,
  ScatterPlotChartIcon,
  HeatMapIcon,
  PieChartIcon,
  BubbleChartIcon,
  StackBar100PercentIcon,
  BulletChartSubheaderIcon,
  TreeMapIcon,
  BoxPlotIcon,
  DynamicTextIcon,
  GaugeChartIcon,
  AutumnThemeSVG,
  FrozenThemeSVG,
  MoonriseThemeSVG,
  MutedThemeSVG,
  SpiritedThemeSVG,
  RoseThemeSVG,
  MaterialThemeSVG,
  DonutChartIcon,
} from '../shared/Svgicon/Svgicon'

export const TIME_INTERVAL_TYPE_OPTIONS = ['Relative', 'Interval']
export const DATE_INTERVAL_TYPE_OPTIONS = ['relative', 'absolute']
export const RELATIVE_OPTIONS = [
  { name: 'Prior', value: '<<' },
  { name: 'Next', value: '>>' },
]
export const DEFAULT_PERIOD_SINGULAR_OPTIONS = ['Hour', 'Day', 'Week', 'Month', 'Quarter', 'Year']
export const DEFAULT_PERIOD_PLURAL_OPTIONS = ['Hours', 'Days', 'Weeks', 'Months', 'Quarters', 'Years']
export const COMPARE_BY_NOT_SUPPORTED_OPTIONS = ['Pareto', 'pivot', 'heatmap', 'map_pinned', 'map_filled']
export const METRIC_CALC_FIELD_COLUMN_TYPE_OPTIONS = ['postagg', 'aggregation']
export const METRIC_TYPES = ['postagg', 'aggregation', 'metric']
export const INTERVAL_OPTIONS = ['Last', 'Next']
export const TEXT_VIZ_TYPES = ['big_number', 'dynamic_text', 'gauge_chart', 'text']
export const PRIVACIES_TYPES = ['Internal', 'Confidential']
export const XY_VIZ_TYPES = [
  'bar',
  'bar and line',
  'line',
  'stack bar',
  'stack bar and line',
  '100_percent_stack',
  'grouped_stack_bar',
  'pareto',
  'area_chart',
  'heatmap',
  'scatter_plot',
  'pie_chart',
  'donut_chart',
  'word_cloud',
  'treemap',
  'bubble_chart',
]

export const MAP_SYMBOLOGY_TYPE = [
  { label: 'Size', value: 'size' },
  { label: 'Color', value: 'color' },
]

export const metricConditions = [
  { label: 'Greater than', value: 'greater_than' },
  { label: 'Greater than or equal to', value: 'greater_than_or_equal_to' },
  { label: 'Less than', value: 'less_than' },
  { label: 'Less than or equal to', value: 'less_than_or_equal_to' },
  { label: 'Equal to', value: 'equal_to' },
  { label: 'Is not equal to', value: 'is_not_equal_to' },
  { label: 'Is between', value: 'is_between' },
]

export const dimConditions = [
  { label: 'Equal to', value: 'dimension_value_matches' },
  { label: 'Is not equal to', value: 'dimension_value_not_equal_to' },
]

export const COLUMN_FORMAT_OPTIONS = [
  { name: 'Number', value: 'number' },
  { name: 'Currency', value: 'currency_USD' },
  { name: 'Percentage', value: 'percentage' },
  { name: 'Text', value: 'text' },
]

// TEMP ARRAY will be removed until percent_to_total is enabled for all viz types
export const COLUMN_FORMAT_OPTIONS_PTT = [
  { name: 'Number', value: 'number' },
  { name: 'Currency', value: 'currency_USD' },
  { name: 'Percentage', value: 'percentage' },
  { name: 'Percent to Total', value: 'percent_to_total' },
  { name: 'Text', value: 'text' },
]

export const MAP_VIZ_TYPES = ['map_filled', 'map_pinned']
export const STACK_BAR_VIZ_TYPES = ['stack bar', '100_percent_stack', 'grouped_stack_bar', 'stack bar and line']
export const TABLE_VIZ_TYPES = ['table', 'table_beta', 'pivot', 'tree_table']
export const TODAY_TIME_PERIOD = ['Today', 'This Week', 'This Month', 'This Quarter', 'This Year']
export const PREVIOUS_TIME_PERIOD = ['Prior Day', 'Prior Week', 'Prior Month', 'Prior Quarter', 'Prior Year']
export const NEXT_TIME_PERIOD = ['Next Day', 'Next Week', 'Next Month', 'Next Quarter', 'Next Year']
export const PERIOD_TIME_PERIOD = [
  'Week to Date',
  'Week to Day',
  'Month to Date',
  'Month to Day',
  'Month to Week',
  'Quarter to Date',
  'Quarter to Day',
  'Quarter to Week',
  'Quarter to Month',
  'Year to Date',
  'Year to Day',
  'Year to Week',
  'Year to Month',
  'Year to Quarter',
]

export const DIMENSON_TYPES = ['dimension', 'dynamic_dimension']
export const OTHERS_TIME_PERIOD = ['All Time', 'Last Segment', 'None', 'Custom']
export const TIME_PERIOD_TAB_OPTIONS = ['Relative', 'Period to Date', 'Between', 'Multi-Select', 'Others']
export const TIME_PERIOD_TAB_OPTIONS_WITH_COMPARE_BY = ['Relative', 'Period to Date', 'Between', 'Others']
export const GRANULARITY_OPTIONS = [
  'None',
  'Second',
  'Minute',
  'Hour',
  'Day',
  'Week',
  'Month',
  'Quarter',
  'Year',
  'All',
]
export const MULTI_DATE_TAB_OPTIONS = ['Relative', 'Period to Date', 'Between']
export const CUSTOM_DATES_OPTIONS = ['Last', 'Next']
export const RELATIVE_TYPES_OPTIONS = ['Current', 'Previous', 'Next']
export const COMBINED_TIME_PERIOD_OPTIONS = [
  ...TODAY_TIME_PERIOD,
  ...PREVIOUS_TIME_PERIOD,
  ...NEXT_TIME_PERIOD,
  ...PERIOD_TIME_PERIOD,
  ...OTHERS_TIME_PERIOD,
]
export const OPERATORS_OPTIONS = [
  { label: 'And', value: 'and' },
  { label: 'Then', value: 'then' },
]

export const OPERATORS_OPTIONS_NON_MULTI = [{ label: 'Then', value: 'then' }]

export const SIMPLE_BAR_VIZ_TYPES_LIST = [
  { name: 'bar', displayName: 'Simple', id: 'bar-subheader-icon', icon: <SimpleBarChartSubheaderIcon /> },
  {
    name: 'bar and line',
    displayName: 'Bar with line',
    id: 'bar-and-line-subheader-icon',
    icon: <BarWithLineChartIcon />,
  },
  {
    name: 'pareto',
    displayName: 'Pareto',
    id: 'pareto-subheader-icon',
    icon: <ParetoChartIcon />,
  },
]

export const STACKED_BAR_VIZ_TYPES_LIST = [
  {
    name: 'stack bar',
    displayName: 'Stacked',
    id: 'stack-bar-subheader-icon',
    icon: <StackedBarChartSubheaderIcon />,
  },
  {
    name: '100_percent_stack',
    displayName: '100% Stacked Bar ',
    id: '100_percent_stack-subheader-icon',
    icon: <StackBar100PercentIcon />,
  },
  {
    name: 'grouped_stack_bar',
    displayName: 'Stacked Grouped Bar',
    id: 'stack-grouped-bar-subheader-icon',
    icon: <StackedGroupedChartIcon />,
  },
  {
    name: 'stack bar and line',
    displayName: 'Stacked Bar with line',
    id: 'stack-bar-line-subheader-icon',
    icon: <StackedBarLineChartIcon />,
  },
]

// Add Objects in order to show in UI
export const OTHER_VIZ_TYPES_LIST = [
  {
    name: 'big_number',
    displayName: 'Big Number',
    id: 'big-number-subheader-icon',
    icon: <BigNumberIcon />,
  },
  {
    name: 'pie_chart',
    displayName: 'Pie Chart',
    id: 'pie-chart-subheader-icon',
    icon: <PieChartIcon />,
  },
  {
    name: 'donut_chart',
    displayName: 'Donut Chart',
    id: 'donut-chart-subheader-icon',
    icon: <DonutChartIcon />,
  },
  {
    name: 'box_plot',
    displayName: 'Box Plot',
    id: 'box-plot-subheader-icon',
    icon: <BoxPlotIcon />,
  },
  {
    name: 'heatmap',
    displayName: 'Heat Map',
    id: 'heatmap-subheader-icon',
    icon: <HeatMapIcon />,
  },
  {
    name: 'bullet',
    displayName: 'Bullet Chart',
    id: 'bullet-subheader-icon',
    icon: <BulletChartSubheaderIcon />,
  },
  {
    name: 'scatter_plot',
    displayName: 'Scatter Plot',
    id: 'scatter-plot-subheader-icon',
    icon: <ScatterPlotChartIcon />,
  },
  {
    name: 'bubble_chart',
    displayName: 'Bubble Chart',
    id: 'bubble-chart-subheader-icon',
    icon: <BubbleChartIcon />,
  },
  {
    name: 'word_cloud',
    displayName: 'Word Cloud',
    id: 'word-cloud-subheader-icon',
    icon: <WordCloudIcon />,
  },
  {
    name: 'area_chart',
    displayName: 'Area Chart',
    id: 'area-chart-subheader-icon',
    icon: <AreaChartIcon />,
  },
  {
    name: 'treemap',
    displayName: 'TreeMap',
    id: 'treemap-subheader-icon',
    icon: <TreeMapIcon />,
  },
  {
    name: 'dynamic_text',
    displayName: 'Dynamic Text',
    id: 'dynamic-text-subheader-icon',
    icon: <DynamicTextIcon />,
  },
  {
    name: 'gauge_chart',
    displayName: 'Gauge Chart',
    id: 'gauge-chart-subheader-icon',
    icon: <GaugeChartIcon />,
  },
]

export const CUSTOM_TAB_DATES = ['Day', 'Week', 'Month', 'Quarter', 'Year']

export const COLUMN_DESC_OPTIONS = [
  'bar',
  'bar and line',
  'line',
  'stack bar',
  'area_chart',
  'grouped_stack_bar',
  'stack bar and line',
  '100_percent_stack',
  'Table',
  'table',
  'tree_table',
  'pivot',
  'bullet',
  'table_beta',
]

export const dimConditionalFormatting = [
  { label: 'Equal to', value: 'dimension_value_matches' },
  { label: 'Is not equal to', value: 'dimension_value_not_equal_to' },
  { label: 'In', value: 'dimension_value_in' },
  { label: 'Not In', value: 'dimension_value_not_in' },
]

export const CHART_THEMES_COLORFUL = [
  {
    label: 'Autumn',
    description: 'Selection of fall-like pastel colors',
    value: 'am4themes_kelly',
    icon: <AutumnThemeSVG />,
  },
  {
    label: 'Frozen',
    description: 'Selection of purple and pink colors',
    value: 'am4themes_frozen',
    icon: <FrozenThemeSVG />,
  },
  {
    label: 'Material',
    description: "Selection of colors from the Google's Material Design",
    value: 'am4themes_material',
    icon: <MaterialThemeSVG />,
  },
  {
    label: 'Moonrise Kingdom',
    description: 'Selection of earth-tone colors(maroon, green, brown, etc)',
    value: 'am4themes_moonrisekingdom',
    icon: <MoonriseThemeSVG />,
  },

  {
    label: 'Muted',
    description: 'Selection of various muted colors(default theme)',
    value: '',
    icon: <MutedThemeSVG />,
  },
  {
    label: 'Spirited Away',
    description: 'Selection of dull purple, maroon, orange, blue',
    value: 'am4themes_spiritedaway',
    icon: <SpiritedThemeSVG />,
  },
]

export const CHART_THEMES_MONO = [
  {
    label: 'Rose',
    description: 'Selection of various bright red colors',
    value: 'am4themes_dataviz',
    icon: <RoseThemeSVG />,
  },
]

export const SIMPLE_COLOR_CODE = [
  {
    codes: [
      { color: '#F2F2F2' },
      { color: '#D9D9D9' },
      { color: '#BFBFBF' },
      { color: '#A6A6A6' },
      { color: '#7F7F7F' },
    ],
  },
  {
    codes: [
      { color: '#7F7F7F' },
      { color: '#595959' },
      { color: '#404040' },
      { color: '#262626' },
      { color: '#0D0D0D' },
    ],
  },
  {
    codes: [
      { color: '#D0CECE' },
      { color: '#AFABAB' },
      { color: '#767171' },
      { color: '#3B3838' },
      { color: '#181717' },
    ],
  },
  {
    codes: [
      { color: '#D6DCE5' },
      { color: '#ADB9CA' },
      { color: '#8497B0' },
      { color: '#333F50' },
      { color: '#222A35' },
    ],
  },
  {
    codes: [
      { color: '#DAE3F3' },
      { color: '#B4C7E7' },
      { color: '#8FAADC' },
      { color: '#2F5597' },
      { color: '#203864' },
    ],
  },
  {
    codes: [
      { color: '#FBE5D6' },
      { color: '#F8CBAD' },
      { color: '#F4B183' },
      { color: '#C55A11' },
      { color: '#843C0C' },
    ],
  },
  {
    codes: [
      { color: '#EDEDED' },
      { color: '#DBDBDB' },
      { color: '#C9C9C9' },
      { color: '#7C7C7C' },
      { color: '#525252' },
    ],
  },
  {
    codes: [
      { color: '#FFF2CC' },
      { color: '#FFE699' },
      { color: '#FFD966' },
      { color: '#BF9000' },
      { color: '#7F6000' },
    ],
  },
  {
    codes: [
      { color: '#DEEBF7' },
      { color: '#BDD7EE' },
      { color: '#9DC3E6' },
      { color: '#2E75B6' },
      { color: '#1F4E79' },
    ],
  },
  {
    codes: [
      { color: '#E2F0D9' },
      { color: '#C5E0B4' },
      { color: '#A9D18E' },
      { color: '#548235' },
      { color: '#385723' },
    ],
  },
]

export const STANDARD_COLOR_CODE = [
  { color: '#C00000' },
  { color: '#FF0000' },
  { color: '#FFC000' },
  { color: '#FFFF00' },
  { color: '#92D050' },
  { color: '#00B050' },
  { color: '#00B0F0' },
  { color: '#0070C0' },
  { color: '#002060' },
  { color: '#7030A0' },
]
