import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import cloneDeep from 'lodash/cloneDeep'
import {
  updateSidebarInfo,
  updateAdminDetail,
  updateGrouplist,
  addGroups,
  removeGroups,
  createNewGroup,
  editGroup,
  updateDefaultOwner,
  updateAutoSuggest,
  addMember,
  deleteMember,
  addGroupProp,
  removeGroupProp,
  deleteGroup,
  addAdGroup,
} from '../../../ducks/admin'
import { displayServiceErrorMessage } from '../../../ducks/layout'
import { getDetailsBusinessAreas } from '../../../ducks/builder'
import { getUserAccess } from '../../../ducks/user'
import {
  saveUserBusinessTags,
  clearSaveUserBusinessTagsInfo,
  saveGroupBusinessTags,
  clearSaveGroupBusinessTagsInfo,
  getUserBusinessTags,
} from '../../../ducks/quicklinks'
import { API_GATEWAY_URL } from '../../../ducks/utils'
import Admin from './Admin'

export class AdminContainer extends React.Component {
  componentDidMount() {
    const { mainTab } = this.props.routeProps.match.params
    this.props.updateSidebarInfo(
      `${API_GATEWAY_URL}/bi_reporting_assets/v2/${this.routeMap(
        this.props.routeProps.match.params.mainTab
      )}?page=1&per_page=100${
        mainTab === 'people' && this.props.userDetails?.lanId
          ? `&_id=${this.props.userDetails.lanId.toLowerCase()}`
          : ''
      }`
    )

    this.props.setHeaderTitle('Administrator')
  }

  routeMap(route) {
    switch (route) {
      case 'people':
        return 'users'
      case 'groups':
        return 'groups'
    }
  }

  /* eslint-disable camelcase */
  UNSAFE_componentWillReceiveProps(nextProps) {
    /* eslint-enable camelcase */
    const { defaultOwnerStatus, displayServiceErrorMessage } = this.props
    const { mainTab } = this.props.routeProps.match.params
    const newMainTab = nextProps.routeProps.match.params.mainTab
    if (mainTab !== newMainTab) {
      this.props.updateSidebarInfo(
        `${API_GATEWAY_URL}/bi_reporting_assets/v2/${this.routeMap(newMainTab)}?field_groups=name_only${
          newMainTab === 'people' && this.props.userDetails?.lanId
            ? `&_id=${this.props.userDetails.lanId.toLowerCase()}`
            : ''
        }`
      )
    }

    if (defaultOwnerStatus.status === 'requested' && nextProps.defaultOwnerStatus.status === 'failed') {
      displayServiceErrorMessage(
        'Something went wrong while requesting your status, this page may not work as expected.'
      )
    }
  }

  render() {
    return (
      <Admin
        routeProps={this.props.routeProps}
        userDetails={this.props.userDetails}
        fetchAdminDetail={this.props.updateAdminDetail}
        fetchDefaultOwner={this.props.updateDefaultOwner}
        fetchAutoSuggest={this.props.updateAutoSuggest}
        fetchGrouplist={this.props.updateGrouplist}
        updateSidebarInfo={this.props.updateSidebarInfo}
        addGroups={this.props.addGroups}
        addMember={this.props.addMember}
        deleteMember={this.props.deleteMember}
        removeGroups={this.props.removeGroups}
        deleteGroup={this.props.deleteGroup}
        deleteGroupStatus={this.props.adminDeleteGroupStatus}
        addGroupProp={this.props.addGroupProp}
        removeGroupProp={this.props.removeGroupProp}
        groupList={this.props.groupList}
        sideBarListInfo={this.props.adminSidebarStatus}
        addGroupPropInfo={this.props.addGroupPropStatus}
        removeGroupPropInfo={this.props.removeGroupPropStatus}
        createNewGroup={this.props.createNewGroup}
        editGroup={this.props.editGroup}
        adminDetail={this.props.adminDetailStatus}
        addedGroups={this.props.addedGroupsStatus}
        addedMembers={this.props.addMemberGroupStatus}
        defaultOwner={this.props.defaultOwnerStatus}
        autoSuggest={cloneDeep(this.props.autoSuggestStatus)}
        createNewGroupStatus={this.props.adminCreateNewGroupStatus}
        editGroupStatus={this.props.adminEditGroupStatus}
        editgroupStatus
        displayServiceErrorMessage={this.props.displayServiceErrorMessage}
        isMobile={this.props.isMobile}
        getDetailsBusinessAreas={this.props.getDetailsBusinessAreas}
        detailsBusinessAreasStatus={this.props.detailsBusinessAreasStatus}
        saveUserBusinessTags={this.props.saveUserBusinessTags}
        saveUserQuickLinksInfoStatus={this.props.saveUserQuickLinksInfoStatus}
        clearSaveUserBusinessTagsInfo={this.props.clearSaveUserBusinessTagsInfo}
        saveGroupBusinessTags={this.props.saveGroupBusinessTags}
        saveGroupQuickLinksInfoStatus={this.props.saveGroupQuickLinksInfoStatus}
        clearSaveGroupBusinessTagsInfo={this.props.clearSaveGroupBusinessTagsInfo}
        userDashboardBusinessTagInfoStatus={this.props.userDashboardBusinessTagInfoStatus}
        getUserBusinessTags={this.props.getUserBusinessTags}
        sendFireflyEvent={this.props.sendFireflyEvent}
        addAdGroup={this.props.addAdGroup}
        addAdGroupStatus={this.props.addAdGroupStatus}
        userAccessStatus={this.props.userAccessStatus}
        getUserAccess={this.props.getUserAccess}
        currentUser={this.props.currentUser}
      />
    )
  }
}

const mapStateToProps = state => ({
  adminSidebarStatus: state.admin.adminSidebarStatus,
  adminDetailStatus: state.admin.adminDetailStatus,
  addedGroupsStatus: state.admin.addedGroupsStatus,
  addMemberGroupStatus: state.admin.addMemberGroupStatus,
  defaultOwnerStatus: state.admin.defaultOwnerStatus,
  autoSuggestStatus: state.admin.autoSuggestStatus,
  adminCreateNewGroupStatus: state.admin.adminCreateNewGroupStatus,
  adminEditGroupStatus: state.admin.adminEditGroupStatus,
  adminDeleteGroupStatus: state.admin.adminDeleteGroupStatus,
  groupList: state.admin.groupList,
  addGroupPropStatus: state.admin.addGroupPropStatus,
  removeGroupPropStatus: state.admin.removeGroupPropStatus,
  detailsBusinessAreasStatus: state.builder.detailsBusinessAreasStatus,
  saveUserQuickLinksInfoStatus: state.quicklinks.saveUserQuickLinksInfoStatus,
  saveGroupQuickLinksInfoStatus: state.quicklinks.saveGroupQuickLinksInfoStatus,
  userDashboardBusinessTagInfoStatus: state.quicklinks.userDashboardBusinessTagInfoStatus,
  addAdGroupStatus: state.admin.addAdGroupStatus,
  userAccessStatus: state.user.userAccessStatus,
  currentUser: state.user.userType,
})

export const mapDispatchToProps = dispatch => ({
  displayServiceErrorMessage(data) {
    dispatch(displayServiceErrorMessage(data))
  },
  updateSidebarInfo(data) {
    dispatch(updateSidebarInfo(data))
  },
  updateAdminDetail(data) {
    dispatch(updateAdminDetail(data))
  },
  updateGrouplist(data) {
    dispatch(updateGrouplist(data))
  },
  addGroups(data) {
    dispatch(addGroups(data))
  },
  removeGroups(data) {
    dispatch(removeGroups(data))
  },
  deleteGroup(data) {
    dispatch(deleteGroup(data))
  },
  createNewGroup(data) {
    dispatch(createNewGroup(data))
  },
  editGroup(data) {
    dispatch(editGroup(data))
  },
  updateDefaultOwner(data) {
    dispatch(updateDefaultOwner(data))
  },
  updateAutoSuggest(data) {
    dispatch(updateAutoSuggest(data))
  },
  addMember(data) {
    dispatch(addMember(data))
  },
  deleteMember(data) {
    dispatch(deleteMember(data))
  },
  addGroupProp(data) {
    dispatch(addGroupProp(data))
  },
  removeGroupProp(data) {
    dispatch(removeGroupProp(data))
  },
  getDetailsBusinessAreas(data) {
    dispatch(getDetailsBusinessAreas(data))
  },
  saveUserBusinessTags(data) {
    dispatch(saveUserBusinessTags(data))
  },
  clearSaveUserBusinessTagsInfo(data) {
    dispatch(clearSaveUserBusinessTagsInfo(data))
  },
  saveGroupBusinessTags(data) {
    dispatch(saveGroupBusinessTags(data))
  },
  clearSaveGroupBusinessTagsInfo(data) {
    dispatch(clearSaveGroupBusinessTagsInfo(data))
  },
  getUserBusinessTags() {
    dispatch(getUserBusinessTags())
  },
  addAdGroup(data) {
    dispatch(addAdGroup(data))
  },
  getUserAccess(data) {
    dispatch(getUserAccess(data))
  },
})

AdminContainer.defaultProps = {
  displayServiceErrorMessage: () => {},
  adminCreateNewGroupStatus: {},
  adminEditGroupStatus: {},
  adminDeleteGroupStatus: {},
  routeProps: {},
  adminDetailStatus: {},
  addedGroupsStatus: {},
  addMemberGroupStatus: {},
  autoSuggestStatus: {},
  updateSidebarInfo: () => {},
  addGroups: () => {},
  removeGroups: () => {},
  deleteGroup: () => {},
  createNewGroup: () => {},
  editGroup: () => {},
  setHeaderTitle: () => {},
  addMember: () => {},
  deleteMember: () => {},
  addGroupProp: () => {},
  removeGroupProp: () => {},
  groupList: {},
  isMobile: false,
  getDetailsBusinessAreas: () => {},
  saveUserBusinessTags: () => {},
  defaultOwnerStatus: {},
  saveGroupBusinessTags: () => {},
  clearSaveUserBusinessTagsInfo: () => {},
  clearSaveGroupBusinessTagsInfo: () => {},
  userDashboardBusinessTagInfoStatus: {},
  getUserBusinessTags: () => {},
}

AdminContainer.propTypes = {
  displayServiceErrorMessage: PropTypes.func,
  adminCreateNewGroupStatus: PropTypes.object,
  adminEditGroupStatus: PropTypes.object,
  adminDeleteGroupStatus: PropTypes.object,
  routeProps: PropTypes.object,
  adminDetailStatus: PropTypes.object,
  addedGroupsStatus: PropTypes.object,
  addMemberGroupStatus: PropTypes.object,
  defaultOwnerStatus: PropTypes.object,
  autoSuggestStatus: PropTypes.object,
  updateSidebarInfo: PropTypes.func,
  addGroups: PropTypes.func,
  removeGroups: PropTypes.func,
  deleteGroup: PropTypes.func,
  createNewGroup: PropTypes.func,
  editGroup: PropTypes.func,
  setHeaderTitle: PropTypes.func,
  addMember: PropTypes.func,
  deleteMember: PropTypes.func,
  addGroupProp: PropTypes.func,
  removeGroupProp: PropTypes.func,
  groupList: PropTypes.object,
  isMobile: PropTypes.bool,
  getDetailsBusinessAreas: PropTypes.func,
  saveUserBusinessTags: PropTypes.func,
  saveGroupBusinessTags: PropTypes.func,
  clearSaveUserBusinessTagsInfo: PropTypes.func,
  clearSaveGroupBusinessTagsInfo: PropTypes.func,
  userDashboardBusinessTagInfoStatus: PropTypes.object,
  getUserBusinessTags: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminContainer)
