import React from 'react'
import { Spinner } from 'greenfield-utilities'
import { Button, Typography, Grid, List, ListItem, ListItemText, Avatar } from '@mui/material'
import ReactPaginate from 'react-paginate'
import PropTypes from 'prop-types'
import { AddCircle } from '@mui/icons-material'
import './admin.scss'

class AdminSideBarList extends React.Component {
  state = {
    searchValue: '',
    pageCount: 0,
    currentPageData: [],
    perPage: 1000,
  }

  sideBarData = []

  componentDidUpdate(prevProps) {
    const { sideBarListInfo, displayServiceErrorMessage } = this.props

    if (prevProps.sideBarListInfo.status === 'requested' && sideBarListInfo.status === 'failed') {
      displayServiceErrorMessage('The request to retrieve the list of people or groups failed.')
    }

    if (this.props.addGroupInFocus && this.addGroupBtn) {
      this.addGroupBtn.focus()
    }
  }

  handleSelectedUser = selectedId => {
    const { routeProps, topic } = this.props

    routeProps.history.replace(
      topic === 'people' ? `/admin/people/${selectedId}/groups` : `/admin/${topic}/${selectedId}/members`
    )
    this.props.updateDetail()
    this.props.updateDeleteGroupSuccess('isDeleteGroupSuccess', false)
  }

  // 2890 - Total
  // 1, 2, 3
  // 2 - 1001 to 2000
  handlePageClick = data => {
    const selected = data.selected
    const offset = Math.ceil(selected * this.state.perPage)

    // Update new data
    this.setState({ currentPageData: this.sideBarData.slice(offset, offset + this.state.perPage - 1) })
  }

  renderAddNewGroup = isMobile => {
    return (
      <Button
        id="add-group-btn"
        size={!isMobile ? 'small' : 'medium'}
        color="secondary"
        aria-label="Create New Group"
        className="icon-label-btn-props accessibleOutline"
        onClick={this.props.onAddGroupClick}
      >
        <AddCircle className="icon-left" />
        Create New Group
      </Button>
    )
  }

  // "topic" logic will need to be redone if "permissions" top level nav goes in
  renderPageContent() {
    let pageData = []
    const { routeProps, topic, sideBarListInfo, isMobile, isShowExternalOnly } = this.props

    if (
      (topic === 'groups' && sideBarListInfo.data.page_list) ||
      (topic === 'people' && !sideBarListInfo.data.page_list)
    ) {
      if (sideBarListInfo.data[0] && !sideBarListInfo.data[0]._id) {
        return null
      }
    }

    if (this.state.currentPageData.length === 0 && this.sideBarData && !this.sideBarData.page_list) {
      pageData = this.sideBarData.data
        ? this.sideBarData.data.slice(0, this.state.perPage - 1)
        : this.sideBarData.slice(0, this.state.perPage - 1)
    } else {
      pageData = this.state.currentPageData
    }

    if (isShowExternalOnly) {
      pageData = pageData.filter(eachGroup => eachGroup.user_type && eachGroup.user_type.toLowerCase() === 'external')
    }

    const selectedPerson = routeProps.match.params._id

    return (
      <div>
        <div className="sidebartext-container">
          <Typography className="showing-people" variant="body1">
            Showing{' '}
            <b>
              {pageData.length} {topic}
            </b>
          </Typography>
          {topic === 'groups' && !isMobile && this.renderAddNewGroup(isMobile)}
        </div>

        <List className="admin-list-results" data-cy="admin-list-results">
          {pageData.map((item, index) => (
            <ListItem
              id={`name-list-${index}`}
              component="li"
              key={item._id}
              button
              onClick={() => {
                this.handleSelectedUser(item._id)
              }}
              className={item._id === selectedPerson ? 'selected-user' : ''}
              data-cy={`groupName-${item.group_name}`}
            >
              <Avatar className="admin-avatar-list">
                {topic === 'people'
                  ? item.first_name && item.last_name
                    ? `${item.first_name.charAt(0)}${item.last_name.charAt(0)}`
                    : ''
                  : item.group_name
                  ? item.group_name.charAt(0)
                  : ''}
              </Avatar>
              <ListItemText
                secondary={
                  topic === 'people'
                    ? item._id
                    : item?.provision_process?.source === 'LDAP'
                    ? 'AD Group'
                    : item.user_type && item.user_type.toLowerCase() === 'internal'
                    ? 'Internal'
                    : 'External'
                }
                primary={
                  topic === 'people'
                    ? item.first_name && item.last_name
                      ? `${item.first_name} ${item.last_name}`
                      : ''
                    : item.group_name
                }
                className="shoo"
              />
            </ListItem>
          ))}
        </List>
        <ReactPaginate
          previousLabel="<"
          nextLabel=">"
          breakLabel={<a href="#more">...</a>}
          pageCount={Math.ceil(this.sideBarData.length / this.state.perPage)}
          marginPagesDisplayed={2}
          pageRangeDisplayed={4}
          onPageChange={this.handlePageClick}
          containerClassName="pagination"
          subContainerClassName="pages pagination"
          activeClassName="active"
        />
        {isMobile && topic === 'groups' && (
          <Grid container spacing={3}>
            <Grid item xs={12} className="mobileBtnCenter">
              {this.renderAddNewGroup(isMobile)}
            </Grid>
          </Grid>
        )}
      </div>
    )
  }

  render() {
    const { sideBarListInfo, topic } = this.props

    switch (sideBarListInfo.status) {
      case 'requested':
        return (
          <Grid item>
            <Spinner size="large" layout="selfCentering" className="page-circular-progress" />
          </Grid>
        )
      case 'failed':
        return null

      default:
        if (Object.keys(sideBarListInfo).length) {
          this.sideBarData =
            topic === 'people' ? this.props.sideBarListInfo.data.page_list : this.props.sideBarListInfo.data

          if (!this.sideBarData) {
            this.sideBarData = this.props.sideBarListInfo.data
          }

          return this.renderPageContent()
        }
    }
    return null
  }
}

AdminSideBarList.defaultProps = {
  topic: '',
  sideBarListInfo: {},
  routeProps: {},
  onAddGroupClick: () => {},
  displayServiceErrorMessage: () => {},
  updateSidebarInfo: () => {},
  updateDetail: () => {},
}

AdminSideBarList.propTypes = {
  displayServiceErrorMessage: PropTypes.func,
  topic: PropTypes.string,
  sideBarListInfo: PropTypes.object,
  updateSidebarInfo: PropTypes.func,
  routeProps: PropTypes.object,
  onAddGroupClick: PropTypes.func,
  updateDetail: PropTypes.func,
}

export default AdminSideBarList
