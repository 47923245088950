import { call, put } from 'redux-saga/effects'
import axios from 'axios'

import { API_GATEWAY_URL } from './utils'

export const VIEWDASHBOARDINFO_FETCH_REQUESTED = 'VIEWDASHBOARDINFO_FETCH_REQUESTED'
export const VIEWDASHBOARDINFO_FETCH_SUCCEEDED = 'VIEWDASHBOARDINFO_FETCH_SUCCEEDED'
export const VIEWDASHBOARDINFO_FETCH_FAILED = 'VIEWDASHBOARDINFO_FETCH_FAILED'

export const UPDATE_VIEW_DASHBOARDINFO = 'UPDATE_VIEW_DASHBOARDINFO'
export const VIEWDASHBOARDINFO_CLEAR_STATE = 'VIEWDASHBOARDINFO_CLEAR_STATE'

export const DASHBOARDINFO_FETCH_REQUESTED = 'DASHBOARDINFO_FETCH_REQUESTED'
export const DASHBOARDINFO_FETCH_SUCCEEDED = 'DASHBOARDINFO_FETCH_SUCCEEDED'
export const DASHBOARDINFO_FETCH_FAILED = 'DASHBOARDINFO_FETCH_FAILED'

export const DASHBOARD_RESTORE_REQUESTED = 'DASHBOARD_RESTORE_REQUESTED'
export const DASHBOARD_RESTORE_SUCCEEDED = 'DASHBOARD_RESTORE_SUCCEEDED'
export const DASHBOARD_RESTORE_FAILED = 'DASHBOARD_RESTORE_FAILED'

export const DASHBOARD_DELETE_REQUESTED = 'DASHBOARD_DELETE_REQUESTED'
export const DASHBOARD_DELETE_SUCCEEDED = 'DASHBOARD_DELETE_SUCCEEDED'
export const DASHBOARD_DELETE_FAILED = 'DASHBOARD_DELETE_FAILED'

export const SEARCH_DASHBOARDLIST_FETCH_REQUESTED = 'SEARCH_DASHBOARDLIST_FETCH_REQUESTED'
export const SEARCH_DASHBOARDLIST_FETCH_SUCCEEDED = 'SEARCH_DASHBOARDLIST_FETCH_SUCCEEDED'
export const SEARCH_DASHBOARDLIST_FETCH_FAILED = 'SEARCH_DASHBOARDLIST_FETCH_FAILED'

export const FAVORITE_DASHBOARDLIST_FETCH_REQUESTED = 'FAVORITE_DASHBOARDLIST_FETCH_REQUESTED'
export const FAVORITE_DASHBOARDLIST_FETCH_SUCCEEDED = 'FAVORITE_DASHBOARDLIST_FETCH_SUCCEEDED'
export const FAVORITE_DASHBOARDLIST_FETCH_FAILED = 'FAVORITE_DASHBOARDLIST_FETCH_FAILED'

// Reducer
export default function homePageReducer(state = {}, action = {}) {
  switch (action.type) {
    case VIEWDASHBOARDINFO_FETCH_REQUESTED: {
      const newState = {
        ...state,
        viewDashBoardInfoStatus: {
          status: 'requested',
        },
      }
      return newState
    }
    case VIEWDASHBOARDINFO_FETCH_SUCCEEDED: {
      const newState = {
        ...state,
        viewDashBoardInfoStatus: action.payload,
      }
      return newState
    }
    case VIEWDASHBOARDINFO_FETCH_FAILED: {
      const newState = {
        ...state,
        viewDashBoardInfoStatus: {
          status: 'failed',
          message: action.payload.response,
        },
      }
      return newState
    }
    case VIEWDASHBOARDINFO_CLEAR_STATE: {
      const newViewDashBoardInfoStatus = {
        ...state,
        viewDashBoardInfoStatus: {},
      }
      return newViewDashBoardInfoStatus
    }
    case UPDATE_VIEW_DASHBOARDINFO: {
      const updatedViewDashBoardInfoStatus = {
        ...state,
        viewDashBoardInfoStatus: action.payload,
      }
      return updatedViewDashBoardInfoStatus
    }

    case DASHBOARD_RESTORE_REQUESTED: {
      const newState = {
        ...state,
        viewDashBoardInfoStatus: {
          status: 'requested',
        },
      }
      return newState
    }
    case DASHBOARD_RESTORE_SUCCEEDED: {
      const newState = {
        ...state,
        viewDashBoardInfoStatus: action.payload,
      }
      return newState
    }
    case DASHBOARD_RESTORE_FAILED: {
      const newState = {
        ...state,
        viewDashBoardInfoStatus: {
          status: 'failed',
          message: action.payload.response,
        },
      }
      return newState
    }

    case DASHBOARDINFO_FETCH_REQUESTED: {
      const newState = {
        ...state,
        dashBoardInfoStatus: {
          status: 'requested',
        },
      }
      return newState
    }
    case DASHBOARDINFO_FETCH_SUCCEEDED: {
      const newState = {
        ...state,
        dashBoardInfoStatus: action.payload,
      }
      return newState
    }
    case DASHBOARDINFO_FETCH_FAILED: {
      const newState = {
        ...state,
        dashBoardInfoStatus: {
          status: 'failed',
          message: action.payload.response,
        },
      }
      return newState
    }

    case FAVORITE_DASHBOARDLIST_FETCH_REQUESTED: {
      const newState = {
        ...state,
        favoriteDashBoardInfoStatus: {
          status: 'requested',
        },
      }
      return newState
    }
    case FAVORITE_DASHBOARDLIST_FETCH_SUCCEEDED: {
      const newState = {
        ...state,
        favoriteDashBoardInfoStatus: action.payload,
      }
      return newState
    }
    case FAVORITE_DASHBOARDLIST_FETCH_FAILED: {
      const newState = {
        ...state,
        favoriteDashBoardInfoStatus: {
          status: 'failed',
          message: action.payload.response,
        },
      }
      return newState
    }

    case SEARCH_DASHBOARDLIST_FETCH_REQUESTED: {
      const newState = {
        ...state,
        dashBoardInfoStatus: {
          status: 'requested',
        },
      }
      return newState
    }
    case SEARCH_DASHBOARDLIST_FETCH_SUCCEEDED: {
      const newState = {
        ...state,
        dashBoardInfoStatus: action.payload,
      }
      return newState
    }
    case SEARCH_DASHBOARDLIST_FETCH_FAILED: {
      const newState = {
        ...state,
        dashBoardInfoStatus: {
          status: 'failed',
          message: action.payload.response,
        },
      }
      return newState
    }

    case DASHBOARD_DELETE_REQUESTED: {
      const newState = {
        ...state,
        dashboardDelStatus: {
          status: 'requested',
        },
      }
      return newState
    }
    case DASHBOARD_DELETE_SUCCEEDED: {
      const newState = {
        ...state,
        dashboardDelStatus: action.payload,
      }
      return newState
    }
    case DASHBOARD_DELETE_FAILED: {
      const newState = {
        ...state,
        dashboardDelStatus: {
          status: 'failed',
          message: action.payload.response,
        },
      }
      return newState
    }

    default:
      return state
  }
}

// Actions
export function getViewDashboardInfo(data) {
  return {
    type: VIEWDASHBOARDINFO_FETCH_REQUESTED,
    payload: data,
  }
}

export function clearViewDashboardInfo() {
  return {
    type: VIEWDASHBOARDINFO_CLEAR_STATE,
  }
}

export function updateViewDashboardInfo(data) {
  return {
    type: UPDATE_VIEW_DASHBOARDINFO,
    payload: data,
  }
}

export function getDashboardList(data) {
  return {
    type: DASHBOARDINFO_FETCH_REQUESTED,
    payload: data,
  }
}

export function getFavoriteDashboardList(data) {
  return {
    type: FAVORITE_DASHBOARDLIST_FETCH_REQUESTED,
    payload: data,
  }
}

export function delDashboard(data) {
  return {
    type: DASHBOARD_DELETE_REQUESTED,
    payload: data,
  }
}

export function restoreDashboard(data) {
  return {
    type: DASHBOARD_RESTORE_REQUESTED,
    payload: data,
  }
}

export function searchDashboardList(data) {
  return {
    type: SEARCH_DASHBOARDLIST_FETCH_REQUESTED,
    payload: data,
  }
}

// Sagas
export function* fetchViewDashboardInfo(action) {
  try {
    const viewDashboardInfo = yield call(axios, {
      method: 'get',
      url: `${API_GATEWAY_URL}/bi_reporting_assets/v2/dashboards/${action.payload}`,
    })

    if (viewDashboardInfo.error) {
      const e = viewDashboardInfo.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }

    yield put({ type: 'VIEWDASHBOARDINFO_FETCH_SUCCEEDED', payload: viewDashboardInfo })
  } catch (error) {
    yield put({ type: 'VIEWDASHBOARDINFO_FETCH_FAILED', payload: error })
  }
}

// get all dashboards
export function* fetchDashboardsList(action) {
  try {
    const { pageIndex, pageSize, searchString, searchInUserData, sortColumn, tags } = action.payload

    const shouldIncludeTags = tags ? `&tags=${encodeURIComponent(tags)}` : ''

    const dashboardInfo = yield call(axios, {
      method: 'get',
      url: `${API_GATEWAY_URL}/bi_reporting_assets/v2/dashboards?page=${pageIndex}&per_page=${pageSize}&search_string=${encodeURIComponent(
        searchString
      )}&search_in_user_data=${searchInUserData}&sort=${encodeURIComponent(
        sortColumn
      )}&field_groups=quick_metadata${shouldIncludeTags}
      &owner_id=${action.payload.ownerId}`,
    })

    if (dashboardInfo.error) {
      const e = dashboardInfo.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }

    yield put({ type: 'DASHBOARDINFO_FETCH_SUCCEEDED', payload: dashboardInfo })
  } catch (error) {
    yield put({ type: 'DASHBOARDINFO_FETCH_FAILED', payload: error })
  }
}

export function* fetchFavoriteDashboardsList(action) {
  try {
    const dashboardInfo = yield call(axios, {
      method: 'get',
      url: `${API_GATEWAY_URL}/bi_reporting_assets/v2/dashboards?search_in_user_data=${action.payload.searchInUserData}&favorite_only=yes`,
    })

    if (dashboardInfo.error) {
      const e = dashboardInfo.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }

    yield put({ type: 'FAVORITE_DASHBOARDLIST_FETCH_SUCCEEDED', payload: dashboardInfo })
  } catch (error) {
    yield put({ type: 'FAVORITE_DASHBOARDLIST_FETCH_FAILED', payload: error })
  }
}

export function* fetchSearchDashboardList(action) {
  try {
    const { pageIndex, pageSize, searchString, searchInUserData, sortColumn, tags } = action.payload

    const shouldIncludeTags = tags ? `&tags=${encodeURIComponent(tags)}` : ''

    const dashboardInfo = yield call(axios, {
      method: 'get',
      url: `${API_GATEWAY_URL}/bi_reporting_assets/v2/dashboards?page=${pageIndex}&search_string=${encodeURIComponent(
        searchString
      )}&per_page=${pageSize}&search_in_user_data=${
        searchInUserData === 'yes' ? 'yes' : 'no'
      }&sort=${encodeURIComponent(sortColumn)}${shouldIncludeTags}`,
    })

    if (dashboardInfo.error) {
      const e = dashboardInfo.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }
    yield put({ type: SEARCH_DASHBOARDLIST_FETCH_SUCCEEDED, payload: dashboardInfo })
  } catch (error) {
    yield put({ type: SEARCH_DASHBOARDLIST_FETCH_FAILED, payload: error })
  }
}

export function* deleteDashboard(action) {
  try {
    const delDashboard = yield call(axios, {
      method: 'patch',
      url: `${API_GATEWAY_URL}/bi_reporting_assets/v2/dashboards/${action.payload.delDashboard}`,
      data: {
        status: {
          state: 'disabled',
        },
      },
    })

    if (delDashboard.error) {
      const e = delDashboard.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }

    yield put({ type: 'DASHBOARD_DELETE_SUCCEEDED', payload: delDashboard })
  } catch (error) {
    yield put({ type: 'DASHBOARD_DELETE_FAILED', payload: error })
  }
}

export function* restoreDashboardMeta(action) {
  try {
    const restoreDashboardVal = yield call(axios, {
      method: 'patch',
      url: `${API_GATEWAY_URL}/bi_reporting_assets/v2/dashboards/${action.payload.dashboardId}`,
      data: {
        status: {
          state: 'active',
        },
      },
    })

    if (restoreDashboardVal.error) {
      const e = restoreDashboardVal.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }

    yield put({ type: 'DASHBOARD_RESTORE_SUCCEEDED', payload: restoreDashboardVal })
  } catch (error) {
    yield put({ type: 'DASHBOARD_RESTORE_FAILED', payload: error })
  }
}
