// Ducks related common code

import apiConfig from '../config/apiConfig'

/**
 *
 * @param fields
 * @returns {any[]}
 */
export const formatDatasetFields = fields => {
  if (fields) {
    const keys = Object.keys(fields)
    const values = Object.values(fields)

    return keys.map((key, index) => {
      const obj = values[index]
      obj.field_name = key
      obj.column_display_name = obj.label || obj.column_name || key
      delete obj.label

      return obj
    })
  } else {
    return []
  }
}

export const API_GATEWAY_URL = (() => apiConfig.gatewayUrl)()
