import React from 'react'
import { Route } from 'react-router-dom'
import { Box } from '@mui/system'
import { Spinner } from 'greenfield-utilities'
import { Typography, Grid, Tabs, Tab, Avatar, Chip, Button, IconButton, Paper } from '@mui/material'
import { Edit, Delete, NavigateBefore } from '@mui/icons-material'
import SwipeableViews from 'react-swipeable-views'
import moment from 'moment'
import PropTypes from 'prop-types'
import './admin.scss'
import cloneDeep from 'lodash/cloneDeep'

import AssetTable, { AssetType } from '../../shared/AssetTableSS/AssetTable'
import AdminGroups from './Admin.groups'
import AdminMembers from './Admin.members'
import AdminQuicklinks from './Admin.quicklinks'
import AdminAdminActions from './Admin.adminActions'

class AdminDetail extends React.Component {
  state = {
    defaultOwner: '',
  }

  componentDidMount() {
    if (this.props.routeProps.match.params._id) {
      this.fetchAdmin(this.props.routeProps.match.params._id)
    }
    this.props.fetchDefaultOwner()
  }

  componentDidUpdate(prevProps) {
    const { _id } = this.props.routeProps.match.params
    const prevId = prevProps.routeProps.match.params._id
    const nextMainTab = this.props.routeProps.match.params.mainTab

    if (_id !== prevId) {
      this.fetchAdmin(_id, nextMainTab)
    }
  }

  static getDerivedStateFromProps(nextProps) {
    const { defaultOwner } = nextProps

    return {
      defaultOwner: defaultOwner.data && defaultOwner.data._id ? `${defaultOwner.data._id}` : '',
    }
  }

  fetchAdmin(_id, topic = this.props.topic) {
    if (_id) {
      this.props.fetchAdminDetail({ _id, topic })
    }
  }

  handleTabChange = (event, mainTabValue) => {
    const { routeProps, topic } = this.props
    const { _id, mainTab } = routeProps.match.params
    const subGroupMap = (() => {
      switch (mainTabValue) {
        case 0:
          return topic === 'people' ? 'groups' : 'members'
        case 1:
          return 'dashboards'
        case 2:
          return 'cards'
        case 3:
          return 'datasets'
        case 4:
          return 'calculated-fields'
        case 5:
          return 'quicklinks'
        case 6:
          return 'admin-actions'
      }
    })()

    routeProps.history.push(`/admin/${mainTab}/${_id}/${subGroupMap}`)
  }

  handleCloseDetail = () => {
    // Set to people and clear selected admin detail obbject
    const { topic } = this.props
    this.props.clearAdminDetail(topic)
  }

  renderMain() {
    const selectedItem = this.props.adminDetail.data
    const { routeProps, topic, addedMembers, isMobile, currentUser } = this.props

    const { subGroup } = routeProps.match.params
    const formatDate = (date, format) => moment(date).format(format)
    const isAdmin = currentUser?.data?.is_admin === 'yes'

    const ownersAccess = selectedItem.owners_access ? cloneDeep(selectedItem.owners_access) : []
    const owners = ownersAccess.map(owner => {
      const ownerName = owner.user_group_name.split(' ')
      return {
        _id: owner._id,
        first_name: ownerName[0],
        last_name: ownerName[1],
      }
    })

    const activeTab = (() => {
      switch (subGroup) {
        case 'groups':
          return 0
        case 'members':
          return 0
        case 'dashboards':
          return 1
        case 'cards':
          return 2
        case 'datasets':
          return 3
        case 'calculated-fields':
          return 4
        case 'quicklinks':
          return 5
        case 'admin-actions':
          return 6
      }
    })()

    const mainDetail = () => {
      return (
        <div className="admin-detail-container">
          <SwipeableViews className="admin-detail-swipe" index={activeTab}>
            {topic === 'people' ? (
              <Route
                path="/admin/:mainTab/:_id/groups"
                render={() => (
                  <AdminGroups
                    adminDetail={this.props.adminDetail}
                    autoSuggest={this.props.autoSuggest}
                    fetchAutoSuggest={this.props.fetchAutoSuggest}
                    addGroups={this.props.addGroups}
                    addedGroups={this.props.addedGroups}
                    removeGroups={this.props.removeGroups}
                    deleteGroup={this.props.deleteGroup}
                    isMobile={isMobile}
                    sendFireflyEvent={this.props.sendFireflyEvent}
                    addAdGroup={this.props.addAdGroup}
                    addAdGroupStatus={this.props.addAdGroupStatus}
                  />
                )}
              />
            ) : (
              <Route
                path="/admin/:mainTab/:_id/members"
                render={routeProps => (
                  <AdminMembers
                    routeProps={routeProps}
                    adminDetail={this.props.adminDetail}
                    addedMembers={this.props.addedMembers}
                    autoSuggest={this.props.autoSuggest}
                    fetchAutoSuggest={this.props.fetchAutoSuggest}
                    addMember={this.props.addMember}
                    deleteMember={this.props.deleteMember}
                    isMobile={isMobile}
                    selectedGroup={selectedItem}
                    sendFireflyEvent={this.props.sendFireflyEvent}
                    addAdGroup={this.props.addAdGroup}
                    addAdGroupStatus={this.props.addAdGroupStatus}
                    displayServiceErrorMessage={this.props.displayServiceErrorMessage}
                    userAccessStatus={this.props.userAccessStatus}
                    getUserAccess={this.props.getUserAccess}
                    currentUser={this.props.currentUser}
                  />
                )}
              />
            )}
            <Route
              path="/admin/:mainTab/:_id/dashboards"
              render={() => (
                <Paper>
                  <Box p={1}>
                    <AssetTable
                      assetType={AssetType.DASHBOARDS}
                      userOrGroupId={selectedItem._id}
                      isMobile={isMobile}
                      isGroupOwner={topic === 'groups'}
                    />
                  </Box>
                </Paper>
              )}
            />
            <Route
              path="/admin/:mainTab/:_id/cards"
              render={() => (
                <Paper>
                  <Box p={1}>
                    <AssetTable
                      assetType={AssetType.CARDS}
                      userOrGroupId={selectedItem._id}
                      isMobile={isMobile}
                      isGroupOwner={topic === 'groups'}
                    />
                  </Box>
                </Paper>
              )}
            />
            <Route
              path="/admin/:mainTab/:_id/datasets"
              render={() => (
                <Paper>
                  <Box p={1}>
                    <AssetTable
                      assetType={AssetType.DATASETS}
                      userOrGroupId={selectedItem._id}
                      isMobile={isMobile}
                      isGroupOwner={topic === 'groups'}
                    />
                  </Box>
                </Paper>
              )}
            />
            <Route
              path="/admin/:mainTab/:_id/calculated-fields"
              render={() => (
                <Paper>
                  <Box p={1}>
                    <AssetTable
                      assetType={AssetType.CALC_FIELDS}
                      userOrGroupId={selectedItem._id}
                      isMobile={isMobile}
                      isGroupOwner={topic === 'groups'}
                    />
                  </Box>
                </Paper>
              )}
            />
            <Route
              path="/admin/:mainTab/:_id/quicklinks"
              render={() => {
                return <AdminQuicklinks topic={topic} defaultOwner={this.state.defaultOwner} {...this.props} />
              }}
            />
            <Route
              path="/admin/:mainTab/:_id/admin-actions"
              render={() => (
                <AdminAdminActions
                  isAdmin={isAdmin}
                  adminDetail={this.props.adminDetail}
                  sendFireflyEvent={this.props.sendFireflyEvent}
                />
              )}
            />
          </SwipeableViews>
        </div>
      )
    }

    const getOwners = () => (
      <div className={isMobile ? 'grp-owner-mbl' : 'grp-owner'}>
        {topic === 'groups' ? (
          <>
            {/* <div> Owner(s) </div> */}
            <span className="label-text"> Owner(s): </span>
            <div>
              {owners &&
                owners.map((owner, index) => (
                  <Chip
                    variant="outlined"
                    color="secondary"
                    key={index}
                    label={owner.first_name === 'NonUser' ? owner._id : `${owner.first_name} ${owner.last_name}`}
                  />
                ))}
            </div>
          </>
        ) : (
          <div />
        )}
      </div>
    )

    const getDates = () =>
      (selectedItem.first_active_date || selectedItem.created_date) && (
        <div className="admin-person-dates">
          {topic === 'people' ? (
            <span>
              <Typography variant="body1">
                <b>Active Since:</b>{' '}
                {`${formatDate(selectedItem.first_active_date, 'hh:mm A')} | ${formatDate(
                  selectedItem.first_active_date,
                  'DD MMM YYYY'
                )}`}
              </Typography>
              <Typography variant="body1">
                <b>Last Active:</b>{' '}
                {`${formatDate(selectedItem.last_active_date, 'hh:mm A')} | ${formatDate(
                  selectedItem.last_active_date,
                  'DD MMM YYYY'
                )}`}
              </Typography>
            </span>
          ) : (
            <>
              <Typography body="body1">
                <b>Created Date:</b>{' '}
                {`${formatDate(selectedItem.created_date, 'hh:mm A')} | ${formatDate(
                  selectedItem.created_date,
                  'DD MMM YYYY'
                )}`}
              </Typography>
              {!isMobile && selectedItem.edit_enabled === 'yes' && (
                <>
                  <Button
                    id="edit-group-btn"
                    color="secondary"
                    className="icon-label-btn-props"
                    onClick={this.props.onEditGroupClick}
                  >
                    <Edit className="icon-left" /> Edit
                  </Button>
                  <Button
                    id="delete-group-btn"
                    color="secondary"
                    className="icon-label-btn-props"
                    onClick={this.props.onDeleteGroupClick}
                  >
                    <Delete className="icon-left" /> Delete
                  </Button>
                </>
              )}
            </>
          )}
        </div>
      )

    const getSelAdminName = () => (
      <div className="admin-person-name" data-cy="admin-person-name">
        <Typography variant="subtitle1" gutterBottom>
          {topic === 'people'
            ? selectedItem.first_name && selectedItem.last_name
              ? `${selectedItem.first_name} ${selectedItem.last_name}`
              : ''
            : selectedItem.group_name}
        </Typography>
        <Typography variant="body1">
          {topic === 'people'
            ? selectedItem._id
            : addedMembers.data && addedMembers.data.members
            ? `${addedMembers.data.members.length} members`
            : `${selectedItem.members ? selectedItem.members.length : ''} members`}
        </Typography>
        {topic !== 'people' && (
          <Typography variant="body1">
            {selectedItem?.provision_process?.source === 'LDAP'
              ? 'AD Group'
              : selectedItem.user_type && selectedItem.user_type.toLowerCase() === 'internal'
              ? 'Internal Group'
              : 'External Group'}
          </Typography>
        )}
        {selectedItem?.provision_process?.source === 'LDAP' && (
          <Typography variant="body1">{selectedItem?.provision_process?.rule}</Typography>
        )}
        <Typography variant="body1">{selectedItem.email_address}</Typography>
      </div>
    )

    return (
      <Grid item className="admin-main-pane">
        {!this.props.isAddingGroup ? (
          <section className={!isMobile ? 'name-details-container' : 'name-details-container mobile'}>
            <div className={isMobile ? 'display-flex-space' : ''}>
              <div className={!isMobile ? 'name-container' : 'name-container padding'}>
                {isMobile && (
                  <IconButton id="previous-btn" onClick={this.handleCloseDetail}>
                    <NavigateBefore className="align-icon" />
                  </IconButton>
                )}
                <Avatar className="admin-avatar-body">
                  {topic === 'people'
                    ? selectedItem.first_name && selectedItem.last_name
                      ? `${selectedItem.first_name.charAt(0)}${selectedItem.last_name.charAt(0)}`
                      : ''
                    : selectedItem.group_name
                    ? selectedItem.group_name.charAt(0)
                    : ''}
                </Avatar>
                <div className="admin-selected">{getSelAdminName()}</div>
              </div>
              {isMobile && topic === 'groups' && (
                <div className="mbl-edit-grp-btns">
                  <IconButton id="add-member-btn-mobile" onClick={this.props.onEditGroupClick}>
                    <Edit className="align-icon" />
                  </IconButton>
                  <IconButton id="delete-member-btn-mobile" onClick={this.props.onDeleteGroupClick}>
                    <Delete className="align-icon" />
                  </IconButton>
                </div>
              )}
              {/* <div /> */}
            </div>
            {getOwners()}
            {getDates()}
          </section>
        ) : (
          <div />
        )}
        <Tabs
          value={activeTab}
          onChange={this.handleTabChange}
          className="accent-color"
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
        >
          {[
            topic === 'people' ? 'Groups' : 'Members',
            'Dashboards',
            'Cards',
            'Datasets',
            'Calculated Fields',
            'Quicklinks',
            'Admin Actions',
          ].map((name, index) =>
            // only render the Admin Actions tab for admins
            !isAdmin && name === 'Admin Actions' ? null : <Tab key={name} label={name} data-cy={`admin-tab-${index}`} />
          )}
        </Tabs>
        {mainDetail()}
      </Grid>
    )
  }

  render() {
    const { adminDetail } = this.props

    switch (adminDetail.status) {
      case 'requested':
        return <Spinner size="large" layout="selfCentering" className="page-circular-progress" />
      case 'failed':
        this.props.displayServiceErrorMessage("The request to retrieve a user's admin details failed.")
        break
      default:
        if (Object.keys(adminDetail).length) {
          return this.renderMain()
        }
    }
    return null
  }
}

AdminDetail.defaultProps = {
  adminDetail: {},
  addedGroups: {},
  addedMembers: {},
  fetchAdminDetail: () => {},
  autoSuggest: {},
  fetchAutoSuggest: () => {},
  groupList: {},
  fetchGrouplist: () => {},
  routeProps: {},
  addGroups: () => {},
  removeGroups: () => {},
  deleteGroup: () => {},
  addMember: () => {},
  deleteMember: () => {},
  addGroupProp: () => {},
  removeGroupProp: () => {},
  addGroupPropInfo: {},
  removeGroupPropInfo: {},
  createNewGroup: () => {},
  isAddingGroup: false,
  topic: '',
  createNewGroupStatus: {},
  updateSidebarInfo: () => {},
  displayServiceErrorMessage: () => {},
  defaultOwner: {},
  fetchDefaultOwner: () => {},
  onEditGroupClick: () => {},
  onDeleteGroupClick: () => {},
  getDetailsBusinessAreas: () => {},
  detailsBusinessAreasStatus: {},
}

AdminDetail.propTypes = {
  displayServiceErrorMessage: PropTypes.func,
  adminDetail: PropTypes.object,
  addedGroups: PropTypes.object,
  addedMembers: PropTypes.object,
  fetchAdminDetail: PropTypes.func,
  deletedDashboards: PropTypes.object,
  autoSuggest: PropTypes.object,
  fetchAutoSuggest: PropTypes.func,
  groupList: PropTypes.object,
  fetchGrouplist: PropTypes.func,
  routeProps: PropTypes.object,
  addGroups: PropTypes.func,
  removeGroups: PropTypes.func,
  deleteGroup: PropTypes.func,
  addMember: PropTypes.func,
  deleteMember: PropTypes.func,
  addGroupProp: PropTypes.func,
  removeGroupProp: PropTypes.func,
  addGroupPropInfo: PropTypes.object,
  removeGroupPropInfo: PropTypes.object,
  createNewGroup: PropTypes.func,
  isAddingGroup: PropTypes.bool,
  topic: PropTypes.string,
  createNewGroupStatus: PropTypes.object,
  updateSidebarInfo: PropTypes.func,
  defaultOwner: PropTypes.object,
  fetchDefaultOwner: PropTypes.func,
  onEditGroupClick: PropTypes.func,
  onDeleteGroupClick: PropTypes.func,
  getDetailsBusinessAreas: PropTypes.func,
  detailsBusinessAreasStatus: PropTypes.object,
}

export default AdminDetail
