import React, { useState, useEffect } from 'react'
import { Typography } from '@mui/material'
import PropTypes from 'prop-types'
import { withAnalytics } from '@praxis/component-analytics'

import AssetTable, { AssetType } from '../../shared/AssetTableSS/AssetTable'
import analyticsConfig from '../../analytics'
import { getSiteURLInfo } from '../../shared/util'
import './datasets.scss'

const DatasetListContainer = props => {
  const { setHeaderTitle, siteObj } = props

  const [siteUrlInfo, setSiteUrlInfo] = useState([])
  const [siteTags, setSiteTags] = useState('')

  useEffect(() => {
    const siteUrlInfo = getSiteURLInfo()
    let tagStr = ''
    if (siteObj?.data && siteUrlInfo?.length) {
      const { content } = siteObj.data // eslint-disable-line camelcase
      const cardTag = content?.filter(tag => tag.object_type === 'dataset')[0]
      tagStr = cardTag?.object_tags?.toLocaleString()
      setHeaderTitle(`Datasets in ${siteObj.data?.name}`)
    } else {
      setHeaderTitle('Datasets')
    }
    setSiteTags(tagStr)
    setSiteUrlInfo(siteUrlInfo)
  }, [])

  return !props.isMobile ? (
    <AssetTable {...props} assetType={AssetType.DATASETS} siteUrlInfo={siteUrlInfo} siteTags={siteTags} />
  ) : (
    <Typography className="mobileMessage">Dataset features are not available on mobile</Typography>
  )
}

DatasetListContainer.defaultProps = {
  isMobile: false,
  setHeaderTitle: () => {},
  siteObj: {},
  routeProps: {},
}

DatasetListContainer.propTypes = {
  routeProps: PropTypes.object,
  setHeaderTitle: PropTypes.func,
  siteObj: PropTypes.object,
  isMobile: PropTypes.bool,
}

export default withAnalytics(analyticsConfig)(DatasetListContainer)
