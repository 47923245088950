import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { Spinner } from 'greenfield-utilities'
import { Button, FormControl, FormHelperText, Divider, TextareaAutosize } from '@mui/material'
import usePutGroupSql from '../../shared/usePutGroupSql'
import { API_GATEWAY_URL } from '../../../ducks/utils'
import { API_GATEWAY_URL_PROD } from '../../../config/apiConfig'

const AdminAdminActions = ({ isAdmin, adminDetail, sendFireflyEvent }) => {
  const { _id, mainTab } = useParams()
  const { putGroupSql, isSubmitted, isLoading, error } = usePutGroupSql(_id, sendFireflyEvent)
  const [newSql, setNewSql] = useState('')
  const [hasCopiedOriginalSql, setHasCopiedOriginalSql] = useState(false)

  // NOTE: if more actions are added, move all of the following into a subcomponent

  const existingSql = adminDetail?.data?.provision_process?.rule

  if (adminDetail?.data?.provision_process?.source !== 'bigred3') {
    return 'This Group is NOT managed by BigRed3 with SQL'
  }

  // NOTE: this functionality MUST only be shown to GF admins, display nothing if not an admin
  if (!isAdmin || mainTab === 'people') {
    return 'No actions available.'
  }

  const isDevEnv = API_GATEWAY_URL !== API_GATEWAY_URL_PROD

  const tapUrl = isDevEnv
    ? 'https://tap.prod.platform.target.com/a/gfjobs/containers/jobs/dev/gfjobs-group-populator-on-demand/overview'
    : 'https://tap.prod.platform.target.com/a/gfjobs/containers/jobs/prod/gfjobs-group-populator-on-demand/overview'

  return (
    <div>
      <div>{`Editing Group ${_id} (this is a bigred3 managed Group)`}</div>
      <h3>Update Group SQL for Dynamically Provisioned Groups Via BigRed3 (Updates Triggered by TAP Job)</h3>
      <p>
        <span>More information about this feature: </span>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://confluence.target.com/pages/viewpage.action?spaceKey=Greenfield&title=Dynamically+provisioned+groups%28automated+groups%29+-+Internal"
        >
          Group Update Confluence
        </a>
      </p>

      <Divider />

      <h4>Existing SQL:</h4>
      <p>{existingSql}</p>
      <Button
        onClick={() => {
          navigator.clipboard.writeText(existingSql)
          setHasCopiedOriginalSql(true)
        }}
      >
        Copy Existing SQL to Clipboard
      </Button>
      <h4 style={{ color: 'red' }}>
        WARNING: be sure to copy existing SQL somewhere before submitting new SQL (enables submit button)
      </h4>

      <Divider />

      <FormControl fullWidth error={error} variant="standard">
        <h3>New SQL For Group ({isDevEnv ? 'Release Env' : 'Production Env'})</h3>
        <p style={{ color: 'red' }}>
          You must manually check this SQL for security vulnerabilities such as reading from unrelated tables or doing
          other actions besides SELECT
        </p>
        <TextareaAutosize
          id="newSql"
          type="text"
          style={{ width: 1000, minWidth: 100, maxWidth: 2000 }}
          aria-label="sql query"
          minRows={10}
          maxRows={30}
          value={newSql}
          onChange={event => setNewSql(event.target.value)}
          placeholder="Add new SQL statement"
        />

        {error && <FormHelperText id="top-n-error-text">{error}</FormHelperText>}
      </FormControl>
      <Button disabled={!hasCopiedOriginalSql} onClick={() => putGroupSql(newSql)}>
        Submit New SQL for {isDevEnv ? 'Release Env' : 'Production Env'}
      </Button>
      {isLoading && <Spinner />}
      {!hasCopiedOriginalSql && (
        <span style={{ color: 'red' }}>NOTE: must copy the original SQL before submitting</span>
      )}
      {isSubmitted && <div>Submitted</div>}

      <Divider />

      <h3>
        Once submitted successfully, go to{' '}
        <a target="_blank" rel="noopener noreferrer" href={tapUrl}>
          the TAP job
        </a>
      </h3>
      <div>then click the "Configs" tab,</div>
      <div>then edit the "env" file to have</div>
      <div>the groups_to_load value equal {_id} (from this page's URL),</div>
      <div>click save (this does NOT need to be reverted later),</div>
      <div>go to the "Overview" tab,</div>
      <div>click the "Select tag" drop down,</div>
      <div>click the one that looks like the latest,</div>
      <div>then click "Run Job",</div>
      <div>then wait for the job to complete (probably about 1 minute).</div>
    </div>
  )
}

export default AdminAdminActions
