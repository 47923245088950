import React from 'react'
import {
  FormControl,
  FormLabel,
  FormHelperText,
  InputLabel,
  Input,
  TextField,
  Button,
  Radio,
  Typography,
  Select,
  MenuItem,
} from '@mui/material'
import { CancelRounded, CheckCircle } from '@mui/icons-material'
import PropTypes from 'prop-types'
import { Spinner } from 'greenfield-utilities'
import escapeRegExp from 'lodash/escapeRegExp'
import cloneDeep from 'lodash/cloneDeep'
import replace from 'lodash/replace'
import ReactTextareaAutocomplete from '@webscopeio/react-textarea-autocomplete'
import '@webscopeio/react-textarea-autocomplete/style.css'
import '../calculated.scss'

import { GreenfieldAutoComplete } from '../../../shared/shared'

export const Item = ({ entity: { name, char } }) => {
  return (
    <div className="columns-section">
      <div className="column-filter-list">
        <div id={name + char} className={name}>
          {char}
        </div>
      </div>
    </div>
  )
}
const Loading = () => <div>Loading</div>

class ProfileInfo extends React.Component {
  state = {
    profileInfo: cloneDeep(this.props.profileInfo),
    props: cloneDeep(this.props),
    expressionQL: cloneDeep(this.props.expressionQL),
    ownersAccess: [],
    viewersAccess: [],
    duplicateCalField: false,
    searchValue: '',
    column_display_name: '',
    isValidateClicked: false,
    focusedOwnerField: '',
    cardExpressionInfo: cloneDeep(this.props.cardExpressionInfo),
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { profileInfo, cardExpressionInfo } = nextProps.isValidateCall ? prevState : nextProps
    let {
      detailsOwnersStatus,
      expressionQL,
      executeCalculatedStatus,
      isCardColumnExpression,
      saveCardColumnExpression,
      saveCalculatedField,
    } = nextProps
    let { isFormulaValidateClicked, isValidateClicked } = prevState
    let suggestions = []
    const ownersAccess = nextProps.isValidateCall
      ? prevState.profileInfo.owners_access
      : nextProps.profileInfo.owners_access
    const viewersAccess = nextProps.isValidateCall
      ? prevState.profileInfo.viewers_access
      : nextProps.profileInfo.viewers_access

    if (
      !isCardColumnExpression &&
      profileInfo &&
      nextProps.detailsOwnersStatus !== prevState.props.detailsOwnersStatus &&
      detailsOwnersStatus &&
      detailsOwnersStatus.data &&
      detailsOwnersStatus.data.length
    ) {
      suggestions = detailsOwnersStatus.data.map(item => ({
        ...item,
        label: item.user_group_name,
      }))
    } else if (nextProps.expressionQL !== prevState.expressionQL) {
      expressionQL = nextProps.expressionQL
    }

    if (
      isFormulaValidateClicked &&
      executeCalculatedStatus &&
      executeCalculatedStatus.status >= 200 &&
      executeCalculatedStatus.status <= 300
    ) {
      isFormulaValidateClicked = false
      if (isCardColumnExpression) {
        cardExpressionInfo.exprQL = expressionQL
      } else {
        profileInfo.exprQL = expressionQL
      }
      if (!isValidateClicked) {
        if (isCardColumnExpression) {
          saveCardColumnExpression(prevState.cardExpressionInfo, prevState.expressionQL)
        } else {
          saveCalculatedField(prevState.profileInfo, prevState.expressionQL)
        }
      } else {
        isValidateClicked = false
      }
    }

    return {
      suggestions,
      expressionQL,
      profileInfo,
      ownersAccess,
      viewersAccess,
      isFormulaValidateClicked,
      isValidateClicked,
    }
  }

  handleChange = event => {
    const { profileInfo, cardExpressionInfo } = this.state
    const { metadataStatus } = this.props
    const { isCardColumnExpression } = this.props
    if (isCardColumnExpression) {
      if (metadataStatus?.data?.findIndex(metadataObj => metadataObj.format === event.target.value) > -1) {
        cardExpressionInfo[event.target.name] = 'date'
        cardExpressionInfo.format_string = event.target.value
      } else {
        delete cardExpressionInfo.format_string
        cardExpressionInfo[event.target.name] = event.target.value
      }
      this.setState({ cardExpressionInfo })
    } else {
      if (metadataStatus?.data?.findIndex(metadataObj => metadataObj.format === event.target.value) > -1) {
        profileInfo[event.target.name] = 'date'
        profileInfo.format_string = event.target.value
      } else {
        delete profileInfo.format_string
        profileInfo[event.target.name] = event.target.value
      }
      this.setState({ profileInfo })
    }
  }

  componentDidUpdate(prevProps) {
    const { isFormulaValidateClicked } = this.state
    const { executeCalculatedStatus } = this.props
    if (
      isFormulaValidateClicked &&
      executeCalculatedStatus &&
      executeCalculatedStatus.status !== 'requested' &&
      executeCalculatedStatus.status !== prevProps.executeCalculatedStatus.status &&
      this.textarea &&
      !(executeCalculatedStatus.status >= 200 && executeCalculatedStatus.status <= 300)
    ) {
      this.textarea.focus()
    }
  }

  handleFormulaChange = event => {
    this.setState({
      expressionQL: event.target.value,
      isFormulaValidateClicked: false,
    })
    this.updateFormula(event.target.value)
  }

  validateFormula = () => {
    this.props.callValidateFormula(this.state.expressionQL)
    this.setState({
      isFormulaValidateClicked: true,
    })
  }

  saveCalculatedField = event => {
    const { expressionQL, profileInfo } = this.state
    if (expressionQL !== profileInfo.exprQL) {
      this.validateFormula(event)
    } else {
      this.props.saveCalculatedField(profileInfo, expressionQL)
    }
  }

  saveCardColumnExpression = event => {
    const { expressionQL, cardExpressionInfo } = this.state
    if (expressionQL !== cardExpressionInfo.exprQL) {
      this.validateFormula(event)
    } else {
      this.props.saveCardColumnExpression(cardExpressionInfo, expressionQL)
    }
  }

  handleClearFormula = () => {
    this.updateFormula('')
  }

  handleCancel = () => {
    this.props.cancelCalculatedField()
  }

  handleSetFormulaCursorPoint = event => {
    this.props.updateCursorIndex(event.target.selectionStart)
  }

  isSaveCalculatedFieldEnabled() {
    if (this.props.isCardColumnExpression && this.state.expressionQL) {
      return false
    } else if (!this.state.profileInfo.column_display_name || !this.state.profileInfo.column_name) {
      return true
    } else if (!this.state.expressionQL) {
      return true
    } else if (this.state.duplicateCalField) {
      return true
    } else {
      return false
    }
  }

  handleSetValidateClicked = event => {
    this.setState(
      {
        isValidateClicked: true,
      },
      () => {
        this.validateFormula(event)
      }
    )
  }

  updateFormula(value) {
    this.props.handleFormulaChange('textArea', value)
  }

  getFormulaValidMessage() {
    const { expressionQL } = this.state
    const { executeCalculatedStatus } = this.props

    if (
      !expressionQL ||
      !executeCalculatedStatus ||
      !executeCalculatedStatus.status ||
      executeCalculatedStatus.status === 'requested'
    ) {
      return null
    }

    let classValue = 'error-label'
    let message = 'An unexpected error occured with the validation request'

    if (executeCalculatedStatus.status >= 200 && executeCalculatedStatus.status < 300) {
      classValue = 'success-label'
      message = 'This Calculation is Valid'
    } else if (executeCalculatedStatus.data && executeCalculatedStatus.data.message) {
      classValue = 'error-label'
      message = executeCalculatedStatus.data.message
    }

    return (
      <FormHelperText className={classValue} id="enter-or-paste-formula-message" role="alert">
        {message}
      </FormHelperText>
    )
  }

  getCalculatedFieldErrorMessage() {
    let message = ''
    const { searchCalculatedFieldStatus } = this.props
    if (
      this.state.profileInfo.column_display_name &&
      searchCalculatedFieldStatus &&
      searchCalculatedFieldStatus.data &&
      !this.state.profileInfo._id
    ) {
      // Check calculated field is already exists
      searchCalculatedFieldStatus.data.forEach(calField => {
        if (
          calField.column_display_name === this.state.profileInfo.column_display_name ||
          calField.column_name === this.state.profileInfo.column_display_name
        ) {
          message = 'Calculated field already exists'
          if (!this.state.duplicateCalField) {
            this.setState({
              duplicateCalField: true,
            })
          }
        }
      })
    }

    if (message === '' && this.state.duplicateCalField) {
      this.setState({
        duplicateCalField: false,
      })
    }

    return (
      message && (
        <FormHelperText className="error-label" id="enter-or-paste-formula-message">
          {message}
        </FormHelperText>
      )
    )
  }

  selectedOwnersAccess = selections => {
    const { ownersAccess } = this.state

    if (isNaN(selections)) {
      selections.label = selections.user_group_name
      ownersAccess.push(selections)
    } else {
      ownersAccess.splice(selections, 1)
    }

    this.setState({
      ownersAccess,
    })
  }

  selectedViewerAccess = selections => {
    const { viewersAccess } = this.state

    if (isNaN(selections)) {
      selections.label = selections.user_group_name
      viewersAccess.push(selections)
    } else {
      viewersAccess.splice(selections, 1)
    }

    this.setState({
      viewersAccess,
    })
  }

  searchOwnersNames = searchText => {
    this.props.fetchAutoSuggest(searchText)
  }

  handleKeyDown = event => {
    if (this.props.buttonRef && event.which === 9 && !event.shiftKey) {
      this.props.buttonRef(event)
    }
    if (event.which === 9 && event.shiftKey && this.props.isExistingCalculatedField) {
      this.cancelRef.focus()
    }
  }

  handleKeyDownTextField = event => {
    if (event.which === 9 && event.shiftKey) {
      this.cancelRef.focus()
    }
  }

  handleKeyDownButton = event => {
    if (this.props.buttonRef && event.which === 9 && event.shiftKey) {
      this.props.buttonRef(event)
    }
  }

  handleChangeCalculatedName = () => {
    const { profileInfo } = this.state
    if (!profileInfo.column_display_name || !profileInfo.column_display_name.length) {
      profileInfo.column_display_name = profileInfo.column_name
    }
    this.setState({ profileInfo })
  }

  errorOnSaveCalculatedField() {
    if (
      this.state.expressionQL &&
      this.state.profileInfo.column_display_name &&
      this.props.createCalFieldStatus &&
      this.props.createCalFieldStatus.status !== 200 &&
      this.props.createCalFieldStatus.data &&
      this.props.createCalFieldStatus.data.message
    ) {
      return (
        <FormHelperText id="save-calculated-field-failed" className="error-label">
          {this.props.createCalFieldStatus.data.message}
        </FormHelperText>
      )
    }
  }

  filterByGroupName = owner => owner.user_group_name || owner

  filterOnSearchValue = columnObj => {
    const searchValue = replace(this.state.searchValue, /\\/g, '\\\\')
    const hiddenColumn = !columnObj.hide_column

    return (
      hiddenColumn &&
      (columnObj.field_name.toLowerCase().search(escapeRegExp(searchValue.toLowerCase())) !== -1 ||
        columnObj.column_display_name.toLowerCase().search(escapeRegExp(searchValue.toLowerCase())) !== -1)
    )
  }

  filterFunctionOnSearchValue = functionObj => {
    const searchValue = replace(this.state.searchValue, /\\/g, '\\\\')

    return functionObj.column_display_name.toLowerCase().search(escapeRegExp(searchValue.toLowerCase())) !== -1
  }

  handleOnOwnerFocus = value => {
    this.setState({
      focusedOwnerField: value,
    })
  }

  render() {
    let { viewersAccess, ownersAccess, profileInfo, expressionQL, suggestions, cardExpressionInfo, focusedOwnerField } =
      this.state
    const {
      columnList,
      createCalFieldStatus,
      detailsOwnersStatus,
      executeCalculatedStatus,
      functionList,
      isEditable,
      isExistingCalculatedField,
      isCardColumnExpression,
      metadataStatus,
      textRef,
    } = this.props
    const _detailsOwnersStatus = cloneDeep(detailsOwnersStatus)
    const decimalRange = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12']
    const dateFormats = metadataStatus?.data
    const formatValueRef =
      (dateFormats?.findIndex(dateFormat => dateFormat.format === profileInfo.format_string) > -1
        ? 'format_string'
        : 'format_type') || 'format_type'
    if (viewersAccess) {
      viewersAccess = viewersAccess.map(viewAccess => viewAccess.user_group_name)
    }

    if (ownersAccess) {
      ownersAccess = ownersAccess.map(ownAccess => ownAccess.user_group_name)
    }

    return (
      <div className="box-layout">
        <span
          tabIndex={-1}
          ref={ref => {
            this.formulaeRef = ref
          }}
        />
        {!isCardColumnExpression && (
          <FormControl
            required
            aria-describedby="enter-calculated-field-name"
            fullWidth
            className="calculated-field-name-css"
            variant="standard"
          >
            <InputLabel htmlFor="calculated-field-name">Enter Calculated Field Name</InputLabel>
            <Input
              disabled={!isEditable || isExistingCalculatedField}
              id="calculated-field-name"
              value={profileInfo.column_name}
              onChange={this.handleChange}
              name="column_name"
              onBlur={this.handleChangeCalculatedName}
              onKeyDown={this.handleKeyDownTextField}
            />
            {this.getCalculatedFieldErrorMessage()}
          </FormControl>
        )}
        <FormControl fullWidth required aria-describedby="enter-or-paste-formula-text" variant="standard">
          <FormLabel className="text-area-label" htmlFor="textarea">
            Enter or paste formula
          </FormLabel>
          <ReactTextareaAutocomplete
            id="calculated-field-formula"
            placeholder="Prefix ':' to show list of columns and '@' for functions"
            disabled={!isEditable}
            onBlur={this.handleSetFormulaCursorPoint}
            onChange={this.handleFormulaChange}
            value={expressionQL}
            loadingComponent={Loading}
            onKeyDown={this.handleKeyDown}
            style={{
              fontSize: '16px',
              lineHeight: '20px',
              padding: 5,
              resize: 'vertical',
            }}
            ref={rta => {
              this.rta = rta
            }}
            innerRef={textarea => {
              this.textarea = textarea
            }}
            containerStyle={{
              marginTop: 20,
              width: 400,
            }}
            minChar={0}
            trigger={{
              ':': {
                dataProvider: token => {
                  this.setState({ searchValue: token })
                  return columnList.filter(this.filterOnSearchValue).map(column => ({
                    ...column,
                    name: column.type,
                    char: `${column.column_display_name} (${column.field_name})`,
                    column_name: `"${column.field_name}"`,
                    column: true,
                  }))
                },
                component: Item,
                output: item => {
                  return {
                    text: item.column_name,
                    caretPosition: 'end',
                  }
                },
              },
              '@': {
                dataProvider: token => {
                  this.setState({ searchValue: token })
                  return functionList.filter(this.filterFunctionOnSearchValue).map(column => ({
                    ...column,
                    name: column.value,
                    char: column.column_display_name,
                    column: false,
                  }))
                },
                component: Item,
                output: item => item.name,
              },
            }}
          />
        </FormControl>
        {this.getFormulaValidMessage()}

        <div className="margin-top-16">
          {executeCalculatedStatus?.status === 'requested' && <Spinner layout="inlineBlock" />}
          <span tabIndex={-1} ref={ref => textRef(ref)} />
          <Button
            className="icon-label-btn-props"
            color="secondary"
            disabled={!isEditable || !expressionQL}
            id="calculated-field-clear-formula"
            onClick={this.handleClearFormula}
            onKeyDown={this.handleKeyDownButton}
            startIcon={<CancelRounded />}
          >
            Clear Formula
          </Button>
          <Button
            className="icon-label-btn-props"
            id="calculated-field-validate-formula"
            disabled={!expressionQL || executeCalculatedStatus?.status === 'requested' || !isEditable}
            color="secondary"
            onClick={this.handleSetValidateClicked}
            required
            startIcon={<CheckCircle />}
          >
            Validate Formula
          </Button>
        </div>
        <FormControl
          required
          aria-describedby="enter-calculated-field-display-name"
          className="width-margin margin-top-right"
          variant="standard"
        >
          <InputLabel htmlFor="calculated-field-display-name">
            {isCardColumnExpression ? 'Enter Card Column Display Name' : 'Enter Calculated Field Display Name'}
          </InputLabel>
          <Input
            disabled={!isEditable}
            id="calculated-field-display-name"
            value={isCardColumnExpression ? cardExpressionInfo.column_display_name : profileInfo.column_display_name}
            onChange={this.handleChange}
            name="column_display_name"
          />
          {this.getCalculatedFieldErrorMessage()}
        </FormControl>
        {!isCardColumnExpression && (
          <>
            <TextField
              disabled={!isEditable}
              className="width-margin margin-top"
              multiline
              rows={2}
              id="calculated-field-description"
              label="Enter Description"
              margin="normal"
              onChange={this.handleChange}
              value={profileInfo.description}
              name="description"
              variant="standard"
            />

            <div className="margin-top-16">
              <Typography variant="body1">Format Type </Typography>
              <div>
                <Select
                  variant="standard"
                  disabled={!isEditable}
                  className="comp-min-margin-right"
                  value={profileInfo[formatValueRef] || profileInfo.format_type || 'Select Format'}
                  onChange={this.handleChange}
                  name="format_type"
                >
                  <MenuItem value="" disabled>
                    Select Format
                  </MenuItem>
                  <MenuItem value="number">Number</MenuItem>
                  <MenuItem value="currency_USD">Currency</MenuItem>
                  <MenuItem value="percentage">Percentage</MenuItem>
                  {dateFormats &&
                    dateFormats.map(dateformat => (
                      <MenuItem key={dateformat.format} value={dateformat.format}>
                        {dateformat.format}
                      </MenuItem>
                    ))}
                </Select>
                <Select
                  variant="standard"
                  disabled={!isEditable}
                  className="select-width"
                  value={profileInfo.format_precision || 'Decimal Range'}
                  onChange={this.handleChange}
                  name="format_precision"
                >
                  <MenuItem key="Decimals" value="" disabled>
                    Decimals
                  </MenuItem>
                  {decimalRange.map(decimal => (
                    <MenuItem key={decimal} value={decimal}>
                      {decimal}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </div>
            <div>
              <h3>PERMISSIONS</h3>
              <GreenfieldAutoComplete
                isEditable={isEditable}
                label="Add Owners"
                searchApi={this.searchOwnersNames}
                searchStatus={focusedOwnerField === 'owners' ? _detailsOwnersStatus : {}}
                suggestions={suggestions}
                selectedSuggestions={this.selectedOwnersAccess}
                default={ownersAccess}
                labelText="Owners"
                type="owners"
                onOwnerFocus={this.handleOnOwnerFocus}
              />
            </div>
            <div className="margin-top-16">
              <FormLabel>Select Privacy levels</FormLabel>
              <div>
                <span>
                  <Radio
                    label="Confidential"
                    disabled={!isEditable}
                    id="calculated-field-confidential"
                    checked={profileInfo.data_classification === 'Confidential'}
                    onChange={this.handleChange}
                    value="Confidential"
                    name="data_classification"
                    aria-label="Confidential"
                  />
                  Confidential
                </span>
                <span>
                  <Radio
                    disabled={!isEditable}
                    id="calculated-field-internal"
                    checked={profileInfo.data_classification === 'Internal'}
                    onChange={this.handleChange}
                    value="Internal"
                    name="data_classification"
                    aria-label="Internal"
                  />
                  Internal
                </span>
              </div>
            </div>
            {profileInfo.data_classification === 'Confidential' && (
              <GreenfieldAutoComplete
                isEditable={isEditable}
                label="Provide Viewer Access"
                searchApi={this.searchOwnersNames}
                suggestions={suggestions}
                searchStatus={focusedOwnerField === 'viewers' ? _detailsOwnersStatus : {}}
                selectedSuggestions={this.selectedViewerAccess}
                default={viewersAccess}
                labelText="Viewers"
                type="viewers"
                onOwnerFocus={this.handleOnOwnerFocus}
              />
            )}
          </>
        )}
        <div className="margin-top-16">
          {createCalFieldStatus?.status === 'requested' && <Spinner layout="inlineBlock" />}
          <Button
            variant="text"
            color="primary"
            id="calculated-field-save"
            disabled={
              executeCalculatedStatus?.status === 'requested' ||
              createCalFieldStatus?.status === 'requested' ||
              this.isSaveCalculatedFieldEnabled() ||
              !isEditable
            }
            onClick={isCardColumnExpression ? this.saveCardColumnExpression : this.saveCalculatedField}
          >
            {isCardColumnExpression ? 'Save Card Column Calculated Field' : 'Save Calculated Field'}
          </Button>
          <Button
            id="calculated-field-cancel"
            variant="text"
            color="secondary"
            onClick={this.handleCancel}
            onKeyDown={event => {
              if (event.which === 9 && !event.shiftKey) {
                this.formulaeRef.focus()
              }
            }}
          >
            Cancel
          </Button>
          <span
            tabIndex={-1}
            ref={ref => {
              this.cancelRef = ref
            }}
          />
        </div>
        {this.errorOnSaveCalculatedField()}
      </div>
    )
  }
}

ProfileInfo.propTypes = {
  expressionQL: PropTypes.string,
  isEditable: PropTypes.bool,
  isValidateCall: PropTypes.bool,
  executeCalculatedStatus: PropTypes.object,
  profileInfo: PropTypes.object.isRequired,
  handleFormulaChange: PropTypes.func,
  callValidateFormula: PropTypes.func,
  saveCalculatedField: PropTypes.func,
  createCalFieldStatus: PropTypes.object,
  functionList: PropTypes.array,
  isCardColumnExpression: PropTypes.bool,
  cardExpressionInfo: PropTypes.object.isRequired,
}

export default ProfileInfo
