import { call, put } from 'redux-saga/effects'
import axios from 'axios'
import { API_GATEWAY_URL } from './utils'

export const FAVORITECARDS_FETCH_REQUESTED = 'FAVORITECARDS_FETCH_REQUESTED'
export const FAVORITECARDS_FETCH_SUCCEEDED = 'FAVORITECARDS_FETCH_SUCCEEDED'
export const FAVORITECARDS_FETCH_FAILED = 'FAVORITECARDS_FETCH_FAILED'
export const FAVORITEDASHBOARDS_FETCH_REQUESTED = 'FAVORITEDASHBOARDS_FETCH_REQUESTED'
export const FAVORITEDASHBOARDS_FETCH_SUCCEEDED = 'FAVORITEDASHBOARDS_FETCH_SUCCEEDED'
export const FAVORITEDASHBOARDS_FETCH_FAILED = 'FAVORITEDASHBOARDS_FETCH_FAILED'
export const FAVORITEDATASETS_FETCH_REQUESTED = 'FAVORITEDATASETS_FETCH_REQUESTED'
export const FAVORITEDATASETS_FETCH_SUCCEEDED = 'FAVORITEDATASETS_FETCH_SUCCEEDED'
export const FAVORITEDATASETS_FETCH_FAILED = 'FAVORITEDATASETS_FETCH_FAILED'

// Reducers
export default function favoritesReducer(state = {}, action = {}) {
  switch (action.type) {
    case FAVORITECARDS_FETCH_REQUESTED: {
      const newState = {
        ...state,
        favoriteCardsStatus: {
          status: 'requested',
        },
      }
      return newState
    }

    case FAVORITECARDS_FETCH_SUCCEEDED: {
      const newState = {
        ...state,
        favoriteCardsStatus: action.payload,
      }
      return newState
    }

    case FAVORITECARDS_FETCH_FAILED: {
      const newState = {
        ...state,
        favoriteCardsStatus: {
          status: 'failed',
        },
      }
      return newState
    }

    case FAVORITEDASHBOARDS_FETCH_REQUESTED: {
      const newState = {
        ...state,
        favoriteDashboardsStatus: {
          status: 'requested',
        },
      }
      return newState
    }

    case FAVORITEDASHBOARDS_FETCH_SUCCEEDED: {
      const newState = {
        ...state,
        favoriteDashboardsStatus: action.payload,
      }
      return newState
    }

    case FAVORITEDASHBOARDS_FETCH_FAILED: {
      const newState = {
        ...state,
        favoriteDashboardsStatus: {
          status: 'failed',
        },
      }
      return newState
    }

    case FAVORITEDATASETS_FETCH_REQUESTED: {
      const newState = {
        ...state,
        favoriteDatasetsInfo: {
          status: 'requested',
        },
      }
      return newState
    }

    case FAVORITEDATASETS_FETCH_SUCCEEDED: {
      const newState = {
        ...state,
        favoriteDatasetsInfo: action.payload,
      }
      return newState
    }

    case FAVORITEDATASETS_FETCH_FAILED: {
      const newState = {
        ...state,
        favoriteDatasetsInfo: {
          status: 'failed',
        },
      }
      return newState
    }

    default:
      return state
  }
}

// Actions
export function getFavoriteCards(data) {
  return {
    type: FAVORITECARDS_FETCH_REQUESTED,
    payload: data,
  }
}

export function getFavoriteDashboards(data) {
  return {
    type: FAVORITEDASHBOARDS_FETCH_REQUESTED,
    payload: data,
  }
}

export function getFavoriteDatasets(data) {
  return {
    type: FAVORITEDATASETS_FETCH_REQUESTED,
    payload: data,
  }
}

// Sagas
export function* fetchFavoriteCards() {
  try {
    const favCardsInfo = yield call(axios, {
      method: 'get',
      url: `${API_GATEWAY_URL}/bi_reporting_assets/v3/cards?favorites_only=yes`,
    })

    if (favCardsInfo.error) {
      const e = favCardsInfo.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }
    yield put({ type: 'FAVORITECARDS_FETCH_SUCCEEDED', payload: favCardsInfo })
  } catch (e) {
    yield put({ type: 'FAVORITECARDS_FETCH_FAILED' })
  }
}

export function* fetchFavoriteDashboards() {
  try {
    const favDashboardInfo = yield call(axios, {
      method: 'get',
      url: `${API_GATEWAY_URL}/bi_reporting_assets/v2/dashboards?favorites_only=yes`,
    })

    if (favDashboardInfo.error) {
      const e = favDashboardInfo.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }
    yield put({ type: 'FAVORITEDASHBOARDS_FETCH_SUCCEEDED', payload: favDashboardInfo })
  } catch (e) {
    yield put({ type: 'FAVORITEDASHBOARDS_FETCH_FAILED' })
  }
}

export function* fetchFavoriteDatasets() {
  try {
    const favDatasetInfo = yield call(axios, {
      method: 'get',
      url: `${API_GATEWAY_URL}/bi_reporting_assets/v2/datasets?favorites_only=yes&field_groups=quick_metadata`,
    })

    if (favDatasetInfo.error) {
      const e = favDatasetInfo.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }
    yield put({ type: 'FAVORITEDATASETS_FETCH_SUCCEEDED', payload: favDatasetInfo })
  } catch (e) {
    yield put({ type: 'FAVORITEDATASETS_FETCH_FAILED' })
  }
}
