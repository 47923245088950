import Cookies from 'js-cookie'
import jwt from 'jsonwebtoken'
import moment from 'moment'

function getDomainName(hostName) {
  return hostName.substring(hostName.lastIndexOf('.', hostName.lastIndexOf('.') - 1) + 1)
}

export function clearLocalStorage(pathname) {
  window.localStorage.removeItem('access_token')
  window.localStorage.removeItem('environment')
  window.localStorage.removeItem('id_token')
  window.location = decodeURIComponent(pathname)
}

function checkSessionToken() {
  const userToken = window.localStorage.getItem('access_token')

  if (userToken === null) {
    return false
  } else {
    const decodedUserToken = jwt.decode(userToken.split(' ')[1])
    const tokenExpUtc = decodedUserToken.exp

    let tokenExpDateTime = new Date(0)
    const currentTime = new Date()
    tokenExpDateTime = tokenExpDateTime.setUTCSeconds(tokenExpUtc)
    return tokenExpDateTime >= currentTime
  }
}

export function checkAuthTtl() {
  const userToken = window.localStorage.getItem('access_token')

  if (userToken !== null) {
    const decodedUserToken = jwt.decode(userToken.split(' ')[1])
    const tokenExpUtc = decodedUserToken.exp

    let tokenExpDateTime = new Date(0)
    const currentTime = moment(new Date())
    tokenExpDateTime = moment(tokenExpDateTime.setUTCSeconds(tokenExpUtc))
    const diff = tokenExpDateTime.diff(currentTime, 'minutes')

    return diff <= 5
  }
}

export default function authUtil(auth) {
  const userInfo =
    auth && auth.session && auth.session.userInfo && auth.session.identity
      ? Object.assign({}, auth.session.identity, auth.session.userInfo)
      : {}

  const lanId = userInfo.samaccountname
  const domain = getDomainName(window.location.hostname)
  const isActiveSession = checkSessionToken()

  if (!isActiveSession) {
    return isActiveSession
  }

  if (domain === 'partnersonline.com') {
    //  // We're on a POL site of some kind
    const SSOSESSIONINFOCookie = Cookies.get('SSOSESSIONINFO')
    if (SSOSESSIONINFOCookie) {
      const SSOSESSIONINFOcoded = window.atob(SSOSESSIONINFOCookie)
      const SSOSESSIONINFOJson = JSON.parse(SSOSESSIONINFOcoded)

      if (!SSOSESSIONINFOJson.user || !SSOSESSIONINFOJson.login_status) {
        // Its a POL site and the user has a cookie that is logged out
        Cookies.remove('SSOSESSIONINFO')
        return false
      } else {
        // Its a POL site, but the user has a cookie that is logged in
        if (String(lanId) !== String(SSOSESSIONINFOJson.user)) {
          /*
              // This else is if lanId != SSOSESSIONINFOJson.user
              // This bit of code gets run every route change
              // If a user logs into a different account via POL
              // we want to switch users, this code should handle that
          */
          Cookies.remove('SSOSESSIONINFO')
          return false
        } else {
          return true
        }
      }
    } else {
      return true
    }
  } else {
    return true
  }
}
