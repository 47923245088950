import React, { useEffect } from 'react'
import './sites.scss'
import SiteListTable from './SiteListTable'

const SiteListContainer = ({ setHeaderTitle = () => {}, isMobile = false }) => {
  useEffect(() => {
    setHeaderTitle('Sites')
  }, [])

  return <SiteListTable isMobile={isMobile} />
}

export default SiteListContainer
