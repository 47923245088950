import { call, put } from 'redux-saga/effects'
import axios from 'axios'
import { API_GATEWAY_URL } from './utils'

export const SITE_REQUESTED = 'SITE_REQUESTED'
export const SITE_SUCCEEDED = 'SITE_SUCCEEDED'
export const SITE_FAILED = 'SITE_FAILED'
export const ALL_SITES_REQUESTED = 'ALL_SITES_REQUESTED'
export const ALL_SITES_SUCCEEDED = 'ALL_SITES_SUCCEEDED'
export const ALL_SITES_FAILED = 'ALL_SITES_FAILED'
export const ADD_UPDATE_SITES_REQUESTED = 'ADD_UPDATE_SITES_REQUESTED'
export const ADD_UPDATE_SITES_SUCCEEDED = 'ADD_UPDATE_SITES_SUCCEEDED'
export const ADD_UPDATE_SITES_FAILED = 'ADD_UPDATE_SITES_FAILED'
export const CLEAR_SITE_DATA = 'CLEAR_SITE_DATA'

export default function siteReducer(state = {}, action = {}) {
  switch (action.type) {
    case SITE_REQUESTED: {
      const newState = {
        ...state,
        siteStatus: {
          status: 'requested',
        },
      }
      return newState
    }
    case SITE_SUCCEEDED: {
      const newState = {
        ...state,
        siteStatus: action.payload,
      }
      return newState
    }
    case SITE_FAILED: {
      const newState = {
        ...state,
        siteStatus: {
          status: 'failed',
          message: action.payload.response,
        },
      }
      return newState
    }
    case ALL_SITES_REQUESTED: {
      const newState = {
        ...state,
        allSitesStatus: {
          status: 'requested',
        },
      }
      return newState
    }
    case ALL_SITES_SUCCEEDED: {
      const newState = {
        ...state,
        allSitesStatus: action.payload,
      }
      return newState
    }
    case ALL_SITES_FAILED: {
      const newState = {
        ...state,
        allSitesStatus: {
          status: 'failed',
          message: action.payload.response,
        },
      }
      return newState
    }
    case ADD_UPDATE_SITES_REQUESTED: {
      const newState = {
        ...state,
        updateSiteStatus: {
          status: 'requested',
        },
      }
      return newState
    }
    case ADD_UPDATE_SITES_SUCCEEDED: {
      const newState = {
        ...state,
        updateSiteStatus: action.payload,
      }
      return newState
    }
    case ADD_UPDATE_SITES_FAILED: {
      const newState = {
        ...state,
        updateSiteStatus: {
          status: 'failed',
          message: action.payload.response,
        },
      }
      return newState
    }
    case CLEAR_SITE_DATA: {
      const newState = {
        ...state,
        updateSiteStatus: {},
      }
      return newState
    }
    default:
      return state
  }
}

export function clearSiteData(data) {
  return {
    type: CLEAR_SITE_DATA,
    payload: data,
  }
}

export function getSite(data) {
  return {
    type: SITE_REQUESTED,
    payload: data,
  }
}

export function getSiteList(data) {
  return {
    type: ALL_SITES_REQUESTED,
    payload: data,
  }
}

export function updateSelectedSiteReq(data) {
  return {
    type: ADD_UPDATE_SITES_REQUESTED,
    payload: data,
  }
}

export function* getUserSite(action) {
  try {
    const siteType = yield call(axios, {
      method: 'get',
      url: `${API_GATEWAY_URL}/bi_reporting_assets/v2/sites/router/${action.payload}`,
    })

    if (siteType.error) {
      const e = siteType.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }
    yield put({ type: 'SITE_SUCCEEDED', payload: siteType })
  } catch (err) {
    yield put({ type: 'SITE_FAILED', payload: err })
  }
}

export function* getAllSites(action) {
  try {
    const { pageIndex, pageSize, searchString = '' } = action.payload
    const siteType = yield call(axios, {
      method: 'get',
      url: `${API_GATEWAY_URL}/bi_reporting_assets/v2/sites?page=${pageIndex}&per_page=${pageSize}&search_string=${encodeURIComponent(
        searchString
      )}`,
    })

    if (siteType.error) {
      const e = siteType.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }
    yield put({ type: 'ALL_SITES_SUCCEEDED', payload: siteType })
  } catch (err) {
    yield put({ type: 'ALL_SITES_FAILED', payload: err })
  }
}

export function* updateSiteRequest(action) {
  let method = 'put'
  let url = `${API_GATEWAY_URL}/bi_reporting_assets/v2/sites/${action.payload._id}`

  if (action.payload.isAddSite) {
    method = 'post'
    url = `${API_GATEWAY_URL}/bi_reporting_assets/v2/sites`
  }

  delete action.payload.isAddSite
  try {
    const addUpdateSite = yield call(axios, {
      method,
      url,
      data: action.payload,
    })

    if (addUpdateSite.error) {
      const error = addUpdateSite.error
      throw new Error(`status: ${error.code}, ${error.type} - ${error.message}`)
    }

    yield put({ type: ADD_UPDATE_SITES_SUCCEEDED, payload: addUpdateSite })
  } catch (error) {
    yield put({ type: ADD_UPDATE_SITES_FAILED, payload: error })
  }
}
