import React, { useState } from 'react'
import axios from 'axios'
import {
  Tooltip,
  IconButton,
  CircularProgress,
  createFilterOptions,
  DialogActions,
  TextField,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Box,
  Autocomplete,
  Button,
} from '@mui/material'
import { Link, Link as RouterLink } from 'react-router-dom'
import {
  CloudUploadOutlined,
  DeleteOutline,
  EditOutlined,
  NotificationsOutlined,
  RestoreFromTrashOutlined,
  LabelOutlined,
} from '@mui/icons-material'
import isEmpty from 'lodash/isEmpty'
import cloneDeep from 'lodash/cloneDeep'
import { useDispatch, useSelector } from 'react-redux'
import { createErrMsg } from 'greenfield-utilities'
import { displayServiceErrorMessage } from '../../../ducks/layout'
import DatasetDeleteDialog from '../../routes/Datasets/DatasetCellRenderer/DatasetDeleteDialog'
import { API_GATEWAY_URL, formatDatasetFields } from '../../../ducks/utils'
import { fetchUserType } from '../../../ducks/user'
import ConfirmDialog from '../../shared/Dialog/ConfirmDialog'
import DatasetBuilder from '../../DatasetBuilder/DatasetBuilder'
import CalcFieldAddEditDialog from '../../routes/CalculatedFIeld/CalculatedFieldAddEditDialog'
import { INACTIVE } from './StatusFilters'
import { AssetType, getAssetRequestPath } from './AssetTable'

const filter = createFilterOptions()

export const ActionRenderer = ({
  rowIndex,
  data,
  node,
  statusType,
  isSite,
  siteURL,
  assetType,
  favoriteOnly,
  datasetPreviewStatus,
}) => {
  const defaultParams = {
    isOpen: false,
    selectedAssetID: '',
    selectedAssetName: '',
  }
  const dispatch = useDispatch()
  const { userType } = useSelector(state => state.user)
  const [errorMessage, setErrorMessage] = useState('')
  const [deleteAsset, setDeleteAsset] = useState(defaultParams)
  // dataset states
  const [datasetBuilderVisibility, toggleDatasetBuilderVisibility] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [datasetDetail, setDatasetDetail] = useState([])
  const [hasDependentCards, setHasDependentCards] = useState(false)
  const [selectedDataset, setSelectedDataset] = useState({
    datasetId: '',
    datasetName: '',
  })
  // calc field states
  const [calculatedDialogOpen, setCalculatedDialogOpen] = useState(false)
  const [editSelectedCF, setEditSelectedCF] = useState({})
  const [datasetObject, setDatasetObject] = useState(null)

  // label
  const [selectedId, setSelectedId] = useState(null)
  const [labelDialogOpen, setLabelDiaogOpen] = useState(false)
  const [label, setLabel] = useState(null)

  const { isOpen, selectedAssetID, selectedAssetName } = deleteAsset
  const { datasetName } = selectedDataset

  const rowData = assetType === AssetType.CARDS ? data?.card_attribute : data
  const isUpload = data.sources?.includes('upload')

  const uploadClicked = () => {
    toggleDatasetBuilderVisibility(true)
  }
  const closeDatasetBuilder = () => {
    toggleDatasetBuilderVisibility(false)
  }

  // Dataset delete
  const handleOnClickDeleteDataset = (datasetId, datasetName) => {
    setSelectedDataset({ datasetId, datasetName })
    setIsLoading(true)
    axios
      .get(`${API_GATEWAY_URL}/bi_reporting_assets/v2/datasets/${datasetId}`)
      .then(response => {
        setDatasetDetail(response.data)
        const cards = response.data.cards
        cards.length > 0 ? setHasDependentCards(true) : handleOnClickDeleteAsset(datasetId, datasetName)
        setIsLoading(false)
      })
      .catch(e => {
        dispatch(displayServiceErrorMessage(createErrMsg(e.status, e.message)))
        setIsLoading(false)
      })
  }

  // Delete Asset
  const handleDeleteAsset = () => {
    if (selectedAssetID) {
      axios({
        method: [AssetType.DATASETS, AssetType.CALC_FIELDS].includes(assetType) ? 'delete' : 'patch',
        url: `${getAssetRequestPath(assetType)}/${selectedAssetID}`,
        ...(assetType !== AssetType.DATASETS && {
          data: {
            status: {
              state: 'disabled',
            },
          },
        }),
      })
        .then(() => {
          node.gridApi.refreshServerSideStore({ purge: true })
        })
        .catch(e => {
          setErrorMessage(e.response?.data?.message || e.message)
        })
    }
  }

  const handleOnClickDeleteAsset = (selectedAssetID, selectedAssetName) => {
    setDeleteAsset({ isOpen: true, selectedAssetID, selectedAssetName })
  }

  const handleCloseDialog = () => {
    setDeleteAsset(defaultParams)
  }

  const handleCloseDeleteDatasetDialog = () => {
    setHasDependentCards(false)
  }

  const handleCancelCalculatedField = () => {
    setCalculatedDialogOpen(false)
  }
  const handleLabelClick = assetId => {
    setLabelDiaogOpen(true)
    setSelectedId(assetId)
  }

  const handleLabelDialogClose = () => {
    setLabelDiaogOpen(false)
  }

  const handleLabelSave = () => {
    const userLabels = userType?.data?.labels
    if (selectedId && label) {
      const assetIds =
        userLabels && Object.keys(userLabels).includes(label)
          ? [...(userLabels[label]?.[assetType] || []), Number(selectedId)]
          : [Number(selectedId)]

      axios({
        method: 'patch',
        url: `${API_GATEWAY_URL}/bi_reporting_assets/v2/users/current_user`,
        data: {
          labels: {
            ...userLabels,
            [label]: {
              ...(userLabels && userLabels[label]),
              [assetType]: assetIds,
            },
          },
        },
      })
        .then(() => {
          dispatch(fetchUserType())
          handleLabelDialogClose()
          // node.gridApi.refreshServerSideStore({ purge: true })
        })
        .catch(e => setErrorMessage(e.message))
    }
  }

  // Edit CF
  const handleOnClickEditCF = selectedCF => {
    setEditSelectedCF(selectedCF)
    if (datasetPreviewStatus) {
      setDatasetObject({
        [data.dataset_id]: datasetPreviewStatus,
      })
      setCalculatedDialogOpen(true)
    } else {
      axios({
        method: 'get',
        url: `${API_GATEWAY_URL}/bi_reporting_assets/v2/preview_datasets/${rowData.dataset_id}`,
      })
        .then(resp => {
          const clonedDatasetData = cloneDeep(resp)
          clonedDatasetData.data.fields_array = formatDatasetFields(clonedDatasetData.data.fields)
          delete clonedDatasetData.data.columns
          setDatasetObject({
            [data.dataset_id]: clonedDatasetData,
          })
          setCalculatedDialogOpen(true)
        })
        .catch(e => {
          dispatch(displayServiceErrorMessage(createErrMsg(e.status, e.message)))
        })
    }
  }

  const saveCalculatedFieldSuccess = () => {
    setCalculatedDialogOpen(false)
    node.gridApi.refreshServerSideStore({ purge: true })
  }

  const editEnabled =
    rowData.edit_enabled === 'yes' || (typeof rowData.edit_enabled === 'boolean' && rowData.edit_enabled)
  if (!isEmpty(rowData)) {
    const assetId = data._id || rowData._id
    const assetStatus = rowData?.status || rowData?.audit_properties?.status || data?.audit_properties?.status
    const title =
      rowData.card_title || rowData.dashboard_title || rowData.dataset_business_name || rowData.column_display_name
    const assetName = assetType.slice(0, -1)
    return statusType === INACTIVE || (assetStatus === INACTIVE && rowData.edit_enabled === 'yes') ? (
      <Tooltip title="Restore" placement="top">
        <IconButton
          component={RouterLink}
          data-cy={`${assetName}-restore-${assetId}`}
          id={`asset-restore-${assetId}`}
          aria-label={`Restore ${title}`}
          to={
            assetType === AssetType.DATASETS
              ? { pathname: `/dataset/preview/${assetId}/columns`, restore: true }
              : `/${assetName}/${assetId}`
          }
          style={
            rowData.edit_enabled === 'yes'
              ? { display: 'inline-flex', border: 'none', color: '#616161' }
              : { display: 'none' }
          }
        >
          <RestoreFromTrashOutlined color={rowData.edit_enabled === 'yes' ? 'warning' : 'inherit'} />
        </IconButton>
      </Tooltip>
    ) : (
      <>
        {assetType === AssetType.CALC_FIELDS ? (
          <Tooltip title="Edit Calc Field" placement="top">
            <IconButton
              disabled={!editEnabled}
              component="span"
              aria-label="Edit Calc Field"
              data-cy="edit-cf-btn"
              id={`edit-cf-btn-${rowData.column_display_name}`}
              onClick={() => handleOnClickEditCF(rowData)}
            >
              <EditOutlined color={editEnabled ? 'success' : 'inherit'} />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title={`Edit ${assetName}`} placement="top">
            <IconButton
              component={Link}
              data-cy={`${assetName}-edit-${assetId}`}
              id={`edit-${assetName}-link-${assetId}`}
              aria-label={`Edit ${title}`}
              disabled={rowData.edit_enabled !== 'yes'}
              to={
                assetType === AssetType.DATASETS
                  ? isSite && siteURL
                    ? `/${siteURL}/dataset/edit/${assetId}`
                    : `/dataset/edit/${assetId}`
                  : isSite && siteURL
                  ? `${siteURL}/builder/${assetName}/${assetId}`
                  : `/builder/${assetName}/${assetId}`
              }
            >
              <EditOutlined color={rowData.edit_enabled === 'yes' ? 'success' : 'inherit'} />
            </IconButton>
          </Tooltip>
        )}
        {assetType === AssetType.DATASETS ? (
          <Tooltip title="Delete Dataset" placement="top">
            <IconButton
              component="span"
              id={`datasetlist-delete-${rowIndex}`}
              aria-label={`Delete Dataset ${data.dataset_name}`}
              disabled={data.edit_enabled !== 'yes'}
              onClick={() => handleOnClickDeleteDataset(data._id, data.dataset_name)}
            >
              {isLoading ? (
                <CircularProgress size={20} color="inherit" />
              ) : (
                <DeleteOutline color={data.edit_enabled === 'yes' ? 'error' : 'inherit'} />
              )}
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="Delete" placement="top">
            <IconButton
              component="span"
              id={`asset-delete-${rowIndex}`}
              aria-label={`Delete ${title}`}
              disabled={!editEnabled}
              onClick={() => handleOnClickDeleteAsset(assetId, title)}
            >
              <DeleteOutline color={editEnabled ? 'error' : 'inherit'} />
            </IconButton>
          </Tooltip>
        )}
        {assetType !== AssetType.CALC_FIELDS && (
          <Tooltip title={`Create ${assetName} Notification`} placement="top">
            <IconButton
              tabIndex={-1}
              component={RouterLink}
              to={`/notification-manager/edit/${assetName}/${assetId}`}
              data-cy={`${assetName}-notification-${node.rowIndex}`}
              aria-label={`Create Notification for ${title}`}
              disabled={rowData.edit_enabled === 'no'}
            >
              <NotificationsOutlined
                style={rowData.edit_enabled === 'yes' ? { color: '#2278cf' } : { color: 'inherit' }}
                className="asset-notification-icon"
              />
            </IconButton>
          </Tooltip>
        )}
        {isUpload && (
          <Tooltip title="Reupload this dataset" placement="top">
            <IconButton
              aria-label={`Reupload Dataset ${rowData.dataset_name}`}
              onClick={uploadClicked}
              data-cy="dataset-builder-reupload"
              disabled={data.edit_enabled !== 'yes'}
              component="span"
            >
              <CloudUploadOutlined />
            </IconButton>
          </Tooltip>
        )}
        {assetType !== AssetType.CALC_FIELDS && favoriteOnly && (
          <Tooltip title={`Label ${assetName}`} placement="top">
            <IconButton
              component="span"
              id={`${assetName}-label-${rowIndex}`}
              data-cy={`${assetName}-label-${rowIndex}`}
              aria-label={`Label ${assetName}`}
              onClick={() => handleLabelClick(assetId)}
            >
              <LabelOutlined />
            </IconButton>
          </Tooltip>
        )}
        <ConfirmDialog
          open={isOpen}
          contentText={`Are you sure you want to delete ${assetName}- "${selectedAssetName}"?`}
          errorText={errorMessage}
          okText="DELETE"
          onCloseDialog={handleCloseDialog}
          onClickOk={handleDeleteAsset}
          onClickCancel={handleCloseDialog}
        />
        {datasetBuilderVisibility && (
          <DatasetBuilder closeDatasetBuilder={closeDatasetBuilder} previouslyUploadedDatasetName={data.dataset_name} />
        )}
        <DatasetDeleteDialog
          datasetName={datasetName}
          datasetDetail={datasetDetail}
          hasDependentCards={hasDependentCards}
          handleCloseDeleteDatasetDialog={handleCloseDeleteDatasetDialog}
        />
        <Dialog open={labelDialogOpen} onClose={handleLabelDialogClose}>
          <DialogTitle>Add Label</DialogTitle>
          <DialogContent>
            <DialogContentText>To create a new label, please add the label name and save</DialogContentText>
            <Box mt={2}>
              <Autocomplete
                value={label}
                onChange={(event, newValue) => {
                  if (typeof newValue === 'string') {
                    setLabel(newValue)
                  } else if (newValue && newValue.inputValue) {
                    // Create a new value from the user input
                    setLabel(newValue.inputValue)
                  } else {
                    setLabel(newValue)
                  }
                }}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params)
                  const { inputValue } = params
                  // Suggest the creation of a new value
                  const isExisting = options.some(option => inputValue === option)
                  if (inputValue !== '' && !isExisting) {
                    const newLabel = `Add Label - "${inputValue}"`
                    filtered.push({
                      inputValue,
                      newLabel,
                    })
                  }

                  return filtered
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                id="free-solo-with-text-demo"
                data-cy="autocomplete-label"
                options={userType?.data?.labels ? Object.keys(userType?.data?.labels) : []}
                getOptionLabel={option => {
                  // Value selected with enter, right from the input
                  if (typeof option === 'string') {
                    return option
                  }
                  // Add "xxx" option created dynamically
                  if (option.inputValue) {
                    return option.inputValue
                  }
                  // Regular option
                  return option
                }}
                renderOption={(props, option) => <MenuItem {...props}>{option?.newLabel || option}</MenuItem>}
                sx={{ width: 300 }}
                freeSolo
                renderInput={params => <TextField {...params} label="Add Label" />}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button color="secondary" onClick={handleLabelDialogClose}>
              Cancel
            </Button>
            <Button color="primary" data-cy="save" onClick={handleLabelSave} disabled={!label || !selectedId}>
              Save
            </Button>
          </DialogActions>
        </Dialog>
        {calculatedDialogOpen && datasetObject && (
          <CalcFieldAddEditDialog
            calculatedDialogOpen={calculatedDialogOpen}
            selectedDatasetId={rowData.dataset_id}
            calculatedFieldDetail={editSelectedCF}
            datasetObject={datasetObject}
            handleCalculatedFieldSuccess={saveCalculatedFieldSuccess}
            onHandleCancelCalculatedField={handleCancelCalculatedField}
          />
        )}
      </>
    )
  }
  return <></>
}
