import React, { forwardRef } from 'react'
import moment from 'moment'
import { Dialog, IconButton, Typography, Slide, Chip } from '@mui/material'
import { KeyboardBackspace } from '@mui/icons-material'
import { getOwnerInfoChip } from '../../shared/AgGrid/AgGridHelper'

export const SiteDetailComponentMobile = ({ rowData, state, setState, isMobile }) => {
  const { mobileSubComponentActive } = state

  const Transition = forwardRef((props, ref) => <Slide direction="left" {...props} ref={ref} />)

  const handleCloseMobileSubComponent = () => {
    handleToggleMobileSubComponent(null)
  }

  const handleToggleMobileSubComponent = index => {
    if (isMobile) {
      setState({
        ...state,
        mobileSubComponentActive: !mobileSubComponentActive,
        mobileTableIndex: index,
      })
    }
  }

  return (
    <Dialog
      fullScreen
      open={mobileSubComponentActive}
      onClose={handleToggleMobileSubComponent}
      TransitionComponent={Transition}
      className="mobileListingViewDetailSlide isMobile"
    >
      <div className="mobileCardViewDetails">
        <IconButton onClick={handleCloseMobileSubComponent}>
          <KeyboardBackspace />
        </IconButton>

        <div className="viewDetailContent">
          <div className="mobileViewDetailCardName mobileViewDetailSection">
            <Typography className="expandLabel" variant="h3">
              Site Name:
            </Typography>
            <Typography className="expandValue">{rowData.name}</Typography>
          </div>

          <div className="mobileViewDetailCardName mobileViewDetailSection">
            <Typography className="expandLabel" variant="h3">
              Description:
            </Typography>
            <Typography className="expandValue">{rowData.description}</Typography>
          </div>

          <div className="mobileViewDetailCardName mobileViewDetailSection">
            <Typography className="expandLabel" variant="h3">
              Site Tag:
            </Typography>
            <Typography className="expandValue">
              <Chip
                label={rowData.tag}
                key={rowData._id}
                className="chip-business"
                component="span"
                size="small"
                role="note"
              />
            </Typography>
          </div>

          <div className="mobileViewDetailOwner mobileViewDetailSection">
            <Typography className="expandLabel" variant="h3">
              Owner(s):
            </Typography>

            {rowData.owners_access && getOwnerInfoChip(rowData.owners_access)}
          </div>

          <div className="mobileViewDetailCardCreateDate mobileViewDetailSection">
            <Typography className="expandLabel" variant="h3">
              Created Date:
            </Typography>
            <Typography className="expandValue">
              {moment(rowData?.audit_properties?.created_date).format('MMMM Do YYYY, h:mm:ss a')}
            </Typography>
          </div>

          <div className="mobileViewDetailCardLastUpdate mobileViewDetailSection">
            <Typography className="expandLabel" variant="h3">
              Last Updated:
            </Typography>
            <Typography className="expandValue">
              {' '}
              {moment(rowData?.audit_properties?.updated_date).format('MMMM Do YYYY, h:mm:ss a')}
            </Typography>
          </div>
        </div>
      </div>
    </Dialog>
  )
}
