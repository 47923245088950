export const COMPARE_INTERVAL_STR = '__compare_interval'
export const METRIC_STR = 'metric'
export const POSTAGG_STR = 'postagg'
export const AGGREGATION_STR = 'aggregation'
export const DEFAULT_TIME_PERIOD_STR = 'Last Segment'
export const MULTI_SELECT_CUSTOM_STR = 'Multi-Select'
export const LWR_CASE_TODAY = 'today'
export const THETASKETCH = 'thetaSketch'
export const AMERICA_CHICAGO_TIME_ZONE = 'America/Chicago'
export const DATA_ALERT_TRIGGER_MESSAGE = 'Data Alert Triggered for Card'
export const SUBSCRIPTION_MESSAGE = 'Subscription Report for Card'

export const SMART_EXPORT_TOKEN_EXPIRE_COPY = 'Please be patient.\n\nGetting your data will take longer than expected.'
export const SMART_EXPORT_SERVICE_ERROR = 'There was an error getting your data on the backend.'
export const SMART_EXPORT_INIT_DIALOG_CALL_TO_ACTION =
  'We see that not all of the data is on the page.\n\nWould you like to get more data?'

export const SMART_EXPORT_COULD_NOT_GET_ALL_ROW_MESSAGE = numberOfRows => {
  return `Unfortunately we could not get all rows in this dataset.\n\nWe recommend adding filters or narrowing the time frame in order to get all your results. \n\n We were able to get ${numberOfRows} rows.`
}
/**
 * @param {string} cardId card Id to filter on
 * @return {string} query string to filter on for view usage dashboard for cards
 */
export const VIEW_USAGE_CARD = cardId =>
  `/dashboard/4638/0?$filters@$filter_name=Card%20Filter&filter_id:2&cardFilters@$cardId:95594&filter$d=object_id&p@=${cardId};&t=in;;&$cardId:99629&filter$d=object_id&p@=${cardId};&t=in;;&$cardId:9124&filter$d=object_id&p@=${cardId};&t=in;;&$cardId:100055&filter$d=object_id&p@=${cardId};&t=in;;&$cardId:100095&filter$d=object_id&p@=${cardId};&t=in;;&$cardId:103147&filter$d=object_id&p@=${cardId};&t=in;;&$cardId:110208&filter$d=object_id&p@=${cardId};&t=in;;&$cardId:126977&filter$d=object_id&p@=${cardId};&t=in;;&$cardId:126980&filter$d=object_id&p@=${cardId};&t=in;;;;;&timePeriod$calendar_type=Gregorian&interval=Last%202%20Weeks&hide_time_period_viewer:true`

/**
 * @param {string} dashboardId dashboard Id to filter on
 * @return {string} query string to filter on for view usage dashboard for dashboards
 */
export const VIEW_USAGE_DASHBOARD = dashboardId =>
  `/dashboard/4875/0?$filters@$filter_name=Dashboard%20ID&filter_id:2&cardFilters@$cardId:95594&filter$d=object_id&p@=${dashboardId};&t=in;;&$cardId:99629&filter$d=object_id&p@=${dashboardId};&t=in;;&$cardId:9124&filter$d=object_id&p@=${dashboardId};&t=in;;&$cardId:100055&filter$d=object_id&p@=${dashboardId};&t=in;;&$cardId:100095&filter$d=object_id&p@=${dashboardId};&t=in;;&$cardId:99721&filter$d=object_id&p@=${dashboardId};&t=in;;&$cardId:99762&filter$d=object_id&p@=${dashboardId};&t=in;;&$cardId:99849&filter$d=object_id&p@=${dashboardId};&t=in;;&$cardId:102984&filter$d=object_id&p@=${dashboardId};&t=in;;&$cardId:110215&filter$d=object_id&p@=${dashboardId};&t=in;;&$cardId:110039&filter$d=object_id&p@=${dashboardId};&t=in;;&$cardId:110208&filter$d=object_id&p@=${dashboardId};&t=in;;&$cardId:126871&filter$d=object_id&p@=${dashboardId};&t=in;;&$cardId:126876&filter$d=object_id&p@=${dashboardId};&t=in;;;;;&timePeriod$interval=All%20Time&calendar_type=Gregorian&hide_time_period_viewer:true`

export const BI_REPORTING_ASSETS_ROUTE = '/bi_reporting_assets/v2/static_assets/greenfield-images'
