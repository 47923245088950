import React from 'react'
import PropTypes from 'prop-types'
import { Button, Icon, Dialog, DialogContent, Typography } from '@mui/material'
import Alert from '@mui/lab/Alert'
import moment from 'moment'

class ConfirmDialogComp extends React.Component {
  state = {
    open: false,
    okText: 'OK',
    cancelText: 'CANCEL',
  }

  render() {
    const { fullScreen, adminDetailObj, addedMembers, dialogTitle, isAlert } = this.props
    const open = this.props.open ? this.props.open : this.state.open
    const okText = this.props.okText ? this.props.okText : this.state.okText
    const cancelText = this.props.cancelText ? this.props.cancelText : this.state.cancelText
    const iconElem = this.props.iconElem ? (
      this.props.iconElem
    ) : (
      <Icon classes={{ root: 'warning-dialog-icon' }}>warning</Icon>
    )
    const formatDate = (date, format) => moment(date).format(format)
    return (
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={this.props.onCloseDialog}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogContent>
          <div className="warning-dialog-content">
            {!isAlert && iconElem}
            <Typography variant="h2" data-cy="warning-dialog-title">
              {dialogTitle} {dialogTitle && <br />}
              {this.props.contentText}
            </Typography>
          </div>
          {adminDetailObj && adminDetailObj.data && addedMembers && (
            <div className="admin-group-details">
              <Typography variant="body2">Group Status: Active</Typography>
              <Typography variant="body2">
                Created Date: {formatDate(adminDetailObj.data.created_date, 'DD MMM YYYY')}
              </Typography>
              <Typography variant="body2">
                No. of Members associated with the group :{' '}
                {addedMembers.data ? addedMembers.data.members.length : adminDetailObj.data.members.length}
              </Typography>
              <Typography variant="body2">
                No. of Dashboards associated with the group: {adminDetailObj.data.dashboards.length}
              </Typography>
              <Typography variant="body2">
                No. of Cards associated with the group: {adminDetailObj.data.cards.length}
              </Typography>
            </div>
          )}
          <div className="button-end">
            {!isAlert && (
              <Button onClick={this.props.onClickCancel} id="cancel-btn" variant="text" color="secondary">
                {cancelText}
              </Button>
            )}
            <Button onClick={this.props.onClickOk} id="ok-btn" variant="text" color="primary">
              {okText}
            </Button>
          </div>
        </DialogContent>
        {this.props.errorText && <Alert severity="error">{this.props.errorText}</Alert>}
      </Dialog>
    )
  }
}

ConfirmDialogComp.propTypes = {
  open: PropTypes.bool,
  contentText: PropTypes.string.isRequired,
  errorText: PropTypes.string,
  onCloseDialog: PropTypes.func.isRequired,
  onClickOk: PropTypes.func.isRequired,
}

export default ConfirmDialogComp
