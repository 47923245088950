import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { Button, Popover, Typography, Link } from '@mui/material'
import moment from 'moment'
import styled from 'styled-components'
import axios from 'axios'
import { Spinner } from 'greenfield-utilities'
import { AgGridReact } from 'ag-grid-react'
import { API_GATEWAY_URL } from '../../../ducks/utils'
import HistoryDetail from './HistoryDetail'

import './HistoryPopover.scss'

const SSpinner = styled(Spinner)`
  margin-top: 200px;
`

const HistoryPopover = ({
  isOpen,
  onHandleClose,
  historyType,
  historyId,
  historyData,
  setHistoryDataCallback,
  editEnabled,
}) => {
  const [data, setData] = useState(null)
  const [detail, setDetail] = useState(null)
  const [viewDetail, setViewDetail] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState({
    isError: false,
    message: '',
  })
  useEffect(() => {
    if (historyData) {
      setData(historyData)
      setIsLoading(false)
    } else {
      axios
        .get(
          `${API_GATEWAY_URL}/bi_reporting_assets/v2/object_history/object_type/${historyType}/object_id/${historyId}`
        )
        .then(response => {
          if (response.status >= 200 && response.status < 300) {
            if (response.data && response.data?.statusText === 'SUCCESS') {
              const responseData = response.data.results.map((row, idx) => ({
                id: idx,
                changeDate: moment(row.version_date).format('L h:mm:ss a'),
                changeBy: row.business_name,
                versionDate: row.version_date,
                patches: null,
              }))
              setData(responseData)
              setHistoryDataCallback(responseData)
            } else if (response.data && response.data?.statusText === 'ACCESS_DENIED') {
              setError({
                isError: true,
                message: `ACCESS DENIED: Unable to view change history for this ${historyType}`,
              })
            }
            setIsLoading(false)
          }
        })
        .catch(error => {
          setError({ isError: true, message: error.response.data.message })
          setIsLoading(false)
        })
    }
  }, [])

  const handleViewDetail = row => {
    const { data } = row
    setDetail(data)
    setViewDetail(true)
  }

  const onHandleBack = () => {
    setViewDetail(false)
  }

  const setPatchesCallback = patches => {
    data.find(d => d.id === detail.id).patches = patches
  }

  const defaultColumnDefinitionTemplate = {
    resizable: true,
    sortable: true,
    flex: 1,
    filter: 'agTextColumnFilter',
    filterParams: { caseSensitive: false, defaultOption: 'contains' },
    floatingFilter: true,
  }

  const LinkCellRenderer = row => (
    <Link
      onClick={() => handleViewDetail(row)}
      style={{ display: 'inherit', color: 'blue', cursor: 'pointer' }}
      data-cy={`detail-link-${row.rowIndex}`}
    >
      Details
    </Link>
  )

  const frameworkComponents = {
    linkCellRenderer: LinkCellRenderer,
  }

  const columnDefs = [
    {
      field: 'changeDate',
      headerName: 'Change Date',
    },
    {
      field: 'changeBy',
      headerName: 'Change By',
    },
    editEnabled && {
      headerName: 'Change',
      cellRenderer: 'linkCellRenderer',
      cellRendererParams: {
        row: params => params,
      },
      filter: false,
      sortable: false,
    },
  ].filter(Boolean)

  const getDetailTable = () => {
    const { changeBy, changeDate, versionDate, patches } = detail
    return (
      <HistoryDetail
        changeBy={changeBy}
        changeDate={changeDate}
        historyType={historyType}
        historyId={historyId}
        versionDate={versionDate}
        patches={patches}
        setPatchesCallback={setPatchesCallback}
      />
    )
  }

  const getDataTable = () => (
    <div style={{ marginLeft: 20, marginRight: 20 }}>
      <Typography variant="h2" className="history-title" data-cy="history-title">
        Viewing {historyType} History
      </Typography>
      <div className="ag-theme-alpine" style={{ height: 700, width: '100%' }}>
        <AgGridReact
          rowData={data}
          enableCellTextSelection
          frameworkComponents={frameworkComponents}
          defaultColDef={defaultColumnDefinitionTemplate}
          columnDefs={columnDefs}
          pagination
          paginationAutoPageSize
        />
      </div>
    </div>
  )

  const getError = () => (
    <div className="error">
      <Typography variant="h2">An Error Has Occurred</Typography>
      <Typography variant="h6">{error.message}</Typography>
    </div>
  )

  const renderLoading = () => (
    <div>
      <Typography variant="h2" className="history-title" style={{ marginLeft: 20 }}>
        Loading {historyType} History...
      </Typography>
      <SSpinner layout="selfCentering" size="large" />
    </div>
  )

  const renderContent = () => (viewDetail ? getDetailTable() : error.isError ? getError() : getDataTable())

  return (
    <Popover
      onClose={onHandleClose}
      open={isOpen}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      className="history-popover"
    >
      <div>
        {isLoading ? (
          renderLoading()
        ) : (
          <>
            {renderContent()}
            <footer className="footer">
              {viewDetail && (
                <Button variant="text" color="secondary" onClick={onHandleBack} data-cy="btn-back">
                  Back
                </Button>
              )}
              <Button
                variant="text"
                color="secondary"
                onClick={onHandleClose}
                style={{ marginRight: 20 }}
                data-cy="btn-ok"
              >
                OK
              </Button>
            </footer>
          </>
        )}
      </div>
    </Popover>
  )
}

HistoryPopover.propTypes = {
  isOpen: PropTypes.bool,
  onHandleClose: PropTypes.func,
  historyType: PropTypes.string,
  historyId: PropTypes.number,
  historyData: PropTypes.array,
  setHistoryDataCallback: PropTypes.func,
  editEnabled: PropTypes.bool,
}

export default HistoryPopover
