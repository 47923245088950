import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { AppBar, CircularProgress, Collapse, Divider, IconButton, Typography } from '@mui/material'
import { Delete, Edit, ExpandMore, NavigateNext } from '@mui/icons-material'
import Viewer from 'react-froala-wysiwyg/FroalaEditorView'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { deleteObjectNotification } from '../../../ducks/notification'

const NotificationWrapper = styled.div`
  .notificationBar {
    background-color: #eb0000;
    flex-direction: row;
  }

  .colorWhite {
    color: #fff;
  }

  .notificationSubjectLine {
    padding: 5px 10px;
    color: #fff;
    flex: 0 0 95%;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .dismissBar {
    color: #fff;
    padding: 5px 10px;
  }

  .notificationBannerBody {
    padding: 7px;
    white-space: pre-line;
    display: flex;
  }

  .notificationActionBtn {
    display: flex;
    margin: 5px;
    align-items: flex-start;
  }

  .notificationList {
    width: 90%;
    padding: 5px;
    margin-left: 25px;
  }

  .notificationDetails {
    display: flex;
  }

  .objectDivider {
    width: 100%;
  }

  .notificationTitle {
    font-weight: 500;
  }

  .notificationRowIndex {
    font-weight: 500;
    margin-right: 4px;
  }

  .viewercontainer: {
    margin: 5px 17px;
    p {
      margin: 0;
    }
    div {
      text-align: left !important;
    }
  }

  .notificationBody {
    background-color: #fff;
    position: relative;
    z-index: 1;
  }

  .spinnerWait {
    color: #e2e2e2;
  }
`

const Notifications = props => {
  const dispatch = useDispatch()
  const deleteObjectNotificationStatus = useSelector(state => state.notification.deleteObjectNotificationStatus)
  const { notificationData, isDatasetEdit, datasetId, viewerId, isViewerEdit, sendFireflyEvent, isMobile } = props
  const [showSlideInfo, setShowSlideInfo] = useState(false)
  const [showNotificationBar] = useState(true)

  const handleToggleSlideInfo = () => {
    setShowSlideInfo(!showSlideInfo)
  }

  const dataRouter = () => {
    if (Array.isArray(notificationData) && notificationData.length > 0) {
      const sortData = notificationData.sort(
        (a, b) => new Date(b?.audit_properties?.updated_date) - new Date(a?.audit_properties?.updated_date)
      )
      return renderBannerNotification(sortData)
    }

    return null
  }

  const renderBannerNotification = data => {
    return (
      <NotificationWrapper>
        <Collapse direction="up" in={showNotificationBar} mountOnEnter unmountOnExit>
          <AppBar
            onClick={handleToggleSlideInfo}
            position="static"
            className="notificationBar"
            data-cy="notification-bar-btn"
          >
            <Typography className="notificationSubjectLine" variant="h3" data-cy="notification-bar-label">
              {showSlideInfo ? <ExpandMore /> : <NavigateNext />}
              This {props.level} has {notificationData?.length || 0} notification(s).
            </Typography>
          </AppBar>
          <Collapse direction="down" in={showSlideInfo} mountOnEnter unmountOnExit className="notificationBody">
            {data.map((item, index) => renderNotificationRow(item, index))}
          </Collapse>
        </Collapse>
      </NotificationWrapper>
    )
  }

  const isNotificationEnabled = item => {
    let isEnabled = false
    if (item?.message?.object_detail) {
      const objectType = item.message.object_detail.object_type
      const objectId = item.message.object_detail.object_id
      if (Number(datasetId) === Number(objectId) && objectType?.toLowerCase() === 'dataset') {
        isEnabled = isDatasetEdit
      } else if (Number(viewerId) === Number(objectId) && ['dashboard', 'card'].includes(objectType?.toLowerCase())) {
        isEnabled = isViewerEdit
      }
    }

    return isEnabled
  }

  const renderNotificationRow = (item, index) => {
    const objType = item?.message?.object_detail?.object_type
    const objId = item?.message?.object_detail?.object_id

    return (
      <div key={item.id}>
        <div className="notificationBannerBody">
          <div data-cy={`object-list-${item.message.subject}`} className="notificationList">
            <span className="notificationDetails">
              <Typography variant="h3" className="notificationRowIndex">{`${index + 1}.`}</Typography>
              <Typography variant="h3" className="notificationTitle">
                {item.message.subject}
              </Typography>
            </span>
            <div className="viewerContainer">
              <Viewer model={item.message.body} />
            </div>
          </div>
          {isNotificationEnabled(item) && objType && objId && (
            <div className="notificationActionBtn">
              {!isMobile && (
                <Link
                  data-cy={`edit-notification${item.message.subject}`}
                  to={`/notification-manager/edit/${objType?.toLowerCase()}/${objId}/${item.id}`}
                >
                  <IconButton className="icon-button">
                    <Edit className="align-icon link-icon" />
                  </IconButton>
                </Link>
              )}
              {deleteObjectNotificationStatus && deleteObjectNotificationStatus[item.id]?.status === 'requested' ? (
                <CircularProgress className="spinnerWait" />
              ) : (
                <IconButton
                  className="icon-button"
                  data-cy={`delete-notification${item.message.subject}`}
                  aria-label={`delete notification ${item.message.subject}`}
                  onClick={() => {
                    dispatch(deleteObjectNotification({ id: item.id }))
                    sendFireflyEvent(true, {
                      eventName: `delete${objType}ViewerObjectNotificationClicked`,
                      key: 'notification',
                    })
                  }}
                >
                  <Delete className="align-icon link-icon" />
                </IconButton>
              )}
            </div>
          )}
        </div>
        <Divider className="objectDivider" />
      </div>
    )
  }

  return <>{dataRouter()}</>
}

Notifications.propTypes = {
  cardInfo: PropTypes.object,
  cardStatus: PropTypes.number,
  expanded: PropTypes.bool,
}

export default Notifications
