import React from 'react'
import moment from 'moment'
import { Typography, Grid, List, ListItem, ListItemText, Box } from '@mui/material'
import { getBusinessInfoChip, getOwnerInfoChip } from '../../../shared/AgGrid/AgGridHelper'
import { SCard, SCountTypography, STypography } from '../../Cards/Cardlist/CardDetailComponent'

export const DashboardDetailComponent = ({ rowData }) => {
  return (
    <Grid
      className="dashBoardRowExpand"
      container
      direction="row"
      justifyContent="space-evenly"
      alignItems="flex-start"
    >
      <Grid item xs={5}>
        <GridItem primary="Dashboard ID" secondary={rowData._id} />
        {rowData.business_area.length > 0 && (
          <GridItem
            primary="Business Area(s)"
            secondary={<Box style={{ fontWeight: 'bold' }}>{getBusinessInfoChip(rowData.business_area)}</Box>}
          />
        )}
        <GridItem
          primary="Owner(s)"
          secondary={<Box style={{ fontWeight: 'bold' }}>{getOwnerInfoChip(rowData.owners_access)}</Box>}
        />
      </Grid>
      <Grid item xs={5}>
        {rowData.description && <GridItem primary="Dashboard Description" secondary={rowData.description} />}
        <GridItem primary="Data Classification" secondary={rowData.dashboard_data_classification} />
        <GridItem
          primary="Last Updated Date"
          secondary={moment(rowData.last_modified_time).format('MMMM Do YYYY, h:mm:ss a')}
        />
      </Grid>
      <Grid item xs={2}>
        <SCard>
          <SCountTypography>{rowData.card_count || 0}</SCountTypography>
          <STypography>Cards</STypography>
        </SCard>
      </Grid>
    </Grid>
  )
}

export const GridItem = ({ primary, secondary }) => {
  return (
    <List>
      <ListItem>
        <ListItemText
          className="card-list-item"
          primary={<Typography variant="subtitle1">{primary}</Typography>}
          secondary={
            <Typography
              variant="body1"
              component="span"
              style={{ wordBreak: 'break-word', whiteSpace: 'pre-wrap' }}
              data-cy={`card-list-item-${primary}`}
            >
              {secondary}
            </Typography>
          }
        />
      </ListItem>
    </List>
  )
}
