import React, { useEffect, useState } from 'react'
import { Button, Tab, Tabs, Typography, Divider, Chip, FormGroup, FormControlLabel, Checkbox } from '@mui/material'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import cloneDeep from 'lodash/cloneDeep'
import moment from 'moment'
import { Spinner } from 'greenfield-utilities'
import { ArrowDropDown, Cancel } from '@mui/icons-material'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'

import { clearCustomCalendarDates } from '../../../ducks/timeperiod'
import StaticDatePickerComponent from '../Calendar/StaticDatePickerComponent'
import { getFormatDate } from '../util'
import { CUSTOM_TAB_DATES } from '../../constants/ArrayConstants'

const DateTab = styled(Tab)`
  min-width: inherit;
`
const CustomTimePeriod = ({
  fiscalYear = false,
  cardInfo = {},
  isDashboard = false,
  dashboardInfo = {},
  isViewer = false,
  viewerJson = {},
  isMobile = false,
  customDateFilterStatus = {},
  minDate = '1900-01-01',
  maxDate = '2100-12-31',
  handleCancel,
  handleDateChange,
  getCustomDateFilter,
  isSecondaryTime,
  secondaryTimePeriodColumn,
  selectedTimePeriod,
  selectedDashboardTimePeriod,
  secondaryTime,
}) => {
  const dispatch = useDispatch()
  const startYearRange = minDate && moment(minDate).year()
  const endYearRange = maxDate && moment(maxDate).year()
  const selectedSecondaryTimePeriods = selectedTimePeriod?.secondaryTimePeriods || secondaryTime?.secondaryTimePeriods
  const secondaryTimePeriods = isViewer
    ? selectedSecondaryTimePeriods ||
      cardInfo?.data?.context?.rendered_payload?.secondary_time_periods ||
      cardInfo?.data?.card_config?.card_query_attribute?.secondary_time_periods
    : cardInfo?.data?.card_config?.card_query_attribute?.secondary_time_periods
  const secondaryTimePeriod = secondaryTimePeriods && secondaryTimePeriods[secondaryTimePeriodColumn?.field_name]
  /* eslint-disable camelcase */
  const selectedIntervals = isViewer
    ? isDashboard
      ? selectedDashboardTimePeriod?.intervals || viewerJson?.intervals
        ? viewerJson?.intervals
        : dashboardInfo?.time_period?.intervals
      : isSecondaryTime
      ? secondaryTimePeriod?.intervals
      : selectedTimePeriod?.timePeriod?.intervals || viewerJson?.intervals
    : cardInfo.status >= 200 && cardInfo.status < 300
    ? isViewer
      ? isSecondaryTime
        ? secondaryTimePeriod?.intervals
        : selectedTimePeriod?.timePeriod?.intervals || cardInfo?.data?.context?.rendered_payload?.time_period?.intervals
      : isSecondaryTime
      ? secondaryTimePeriod?.intervals
      : cardInfo?.data?.card_config?.card_query_attribute?.time_period?.intervals
    : []
  /* eslint-enable camelcase */

  const [tabIndex, setTabIndex] = useState(0)
  const [expandSelectedValue, setExpandSelectedValue] = useState(false)
  const [selectedDates, setSelectedDates] = useState(selectedIntervals || [])
  const [selectedValueToShow, setSelectedValueToShow] = useState(5)
  const [dateTypeObject, setDateTypeObject] = useState([])
  const [showMonthYear, setShowMonthYear] = useState(false)
  const [monthYearSel, setMonthYearSel] = useState(maxDate || getFormatDate())
  const [calendarDates, setCalendarDates] = useState({})

  useEffect(() => {
    if (!customDateFilterStatus?.data) {
      getCustomDateFilter({ year: moment(monthYearSel).year() })
    }
    return () => {
      dispatch(clearCustomCalendarDates())
    }
  }, [])

  useEffect(() => {
    if (customDateFilterStatus?.data) {
      const { Fiscal, Gregorian } = customDateFilterStatus?.data
      const calendarObj = fiscalYear ? Fiscal : Gregorian
      setCalendarDates(calendarObj)
      if (tabIndex > 0) {
        handleTabIndexChange(tabIndex, calendarObj)
      }
    }
  }, [customDateFilterStatus])

  const handleTabIndexChange = (value, calendarObj) => {
    setTabIndex(value)
    let dateArray = []
    if (value === 1) {
      const weekArray = calendarObj?.Weeks || []
      const monthValue = moment(monthYearSel).format('MMM')
      dateArray = weekArray.filter(obj => obj.month === monthValue)
    } else if (value === 2) {
      dateArray = calendarObj?.Months || []
    } else if (value === 3) {
      dateArray = calendarObj?.Quarters || []
    } else if (value === 4) {
      dateArray = isDashboard
        ? calendarObj?.Years
        : calendarObj?.Years?.filter(obj => obj.year >= startYearRange && obj.year <= endYearRange)
    }

    setShowMonthYear(false)
    setDateTypeObject(dateArray || [])
  }

  const showMoreSelectedValues = () => {
    if (selectedValueToShow === 5) {
      setSelectedValueToShow(selectedDates?.length)
      setExpandSelectedValue(true)
    } else {
      setSelectedValueToShow(5)
      setExpandSelectedValue(false)
    }
  }

  const showExpandButtons = () => {
    return (
      <>
        {Boolean(selectedDates?.length) && (
          <>
            <Button
              size="small"
              variant="text"
              id="clear-all-btn"
              color="secondary"
              onClick={() => setSelectedDates([])}
            >
              Clear All
            </Button>
            {selectedDates?.length > 5 && (
              <Button size="small" variant="text" id="less-more-btn" color="primary" onClick={showMoreSelectedValues}>
                {expandSelectedValue ? (
                  'Show less'
                ) : (
                  <span className="label-lowercase">and {selectedDates?.length - selectedValueToShow} more</span>
                )}
              </Button>
            )}
          </>
        )}
      </>
    )
  }

  const keyPressDelete = (value, event) => {
    if (event.which === 13 || event.which === 32) {
      handleSelectionChange(value)
    }
  }

  const showSelectedDates = () => {
    const showSelectedValuesLength = expandSelectedValue ? selectedDates?.length : selectedValueToShow
    const selectedList = selectedDates?.slice(0, showSelectedValuesLength)
    return (
      <>
        <div id="selected-custom-chips" className={expandSelectedValue ? 'selected-chip-section' : ''}>
          {selectedList?.map((value, index) => (
            <Chip
              variant="outlined"
              key={value.display_name + index}
              label={value.display_name}
              onDelete={() => handleSelectionChange(value)}
              className="selected-chips"
              id={`selected-chip${index}`}
              deleteIcon={
                <span
                  tabIndex={0}
                  role="button"
                  aria-label={`Remove ${value.display_name}`}
                  onKeyDown={event => keyPressDelete(event, value)}
                  className="delete-selection-chip"
                >
                  <Cancel />
                </span>
              }
            />
          ))}
          {!expandSelectedValue && showExpandButtons()}
        </div>

        {expandSelectedValue && showExpandButtons()}
      </>
    )
  }

  const isCheckedValue = displayName => {
    const checkedIndex = selectedDates?.findIndex(
      date => date?.display_name?.toLowerCase() === displayName?.toLowerCase() // eslint-disable-line
    )
    return checkedIndex > -1
  }

  const handleSelectionChange = (dateObj, event) => {
    const _selectDates = cloneDeep(selectedDates)
    let resultArray = []

    if (dateObj) {
      const displayName = dateObj?.display_name || dateObj // eslint-disable-line
      if (event?.target?.checked) {
        _selectDates.push({
          interval: dateObj?.interval || dateObj,
          type: 'absolute',
          display_name: displayName,
        })
        resultArray = _selectDates
      } else {
        const compareValue = displayName
        resultArray = selectedDates?.filter(date => date?.display_name?.toLowerCase() !== compareValue?.toLowerCase()) // eslint-disable-line
      }
    }

    setSelectedDates(resultArray)
  }
  const isSelectedValueAvaliable = (value, selectedArray = []) => {
    return selectedArray.findIndex(obj => obj.display_name === value) > -1
  }

  return (
    <>
      <div className={classNames('relative-section', !isMobile ? 'calendar-fit' : '')}>
        {Boolean(selectedDates?.length) && <Typography variant="body1">Selected Time</Typography>}
        <div className="field-margin">{showSelectedDates()}</div>
        <div>
          <Tabs
            value={tabIndex}
            onChange={(event, value) => handleTabIndexChange(value, calendarDates)}
            variant="fullWidth"
          >
            {CUSTOM_TAB_DATES.map((name, index) => (
              <DateTab id={`custom-tab-${index}`} key={name} label={name} aria-label={name} />
            ))}
          </Tabs>
          {tabIndex === 0 && (
            <StaticDatePickerComponent
              id="static-date-picker"
              ariaLabel="Select Date"
              value={monthYearSel}
              minDate={minDate || getFormatDate('1900-01-01')}
              maxDate={maxDate || getFormatDate('2100-12-31')}
              handleOnChange={date => {
                const dateValue = getFormatDate(date)
                const _selectDates = cloneDeep(selectedDates)
                if (!isSelectedValueAvaliable(dateValue, _selectDates)) {
                  _selectDates.push({
                    interval: `${dateValue}/${dateValue}`,
                    type: 'absolute',
                    display_name: dateValue,
                  })
                  setSelectedDates(_selectDates)
                }
              }}
              views={['year', 'month', 'day']}
            />
          )}
          {tabIndex !== 0 && (
            <div className={customDateFilterStatus?.status === 'requested' ? 'disable-date-section' : ''}>
              {customDateFilterStatus?.status === 'requested' && (
                <Spinner className="date-spinner" layout="selfCentering" />
              )}
              {!showMonthYear && tabIndex !== 4 && (
                <Button
                  id="cal-month-year-btn"
                  variant="standard"
                  onClick={() => {
                    setShowMonthYear(true)
                  }}
                >
                  {tabIndex === 1 ? moment(monthYearSel).format('MMM YYYY') : moment(monthYearSel).format('YYYY')}
                  <ArrowDropDown />
                </Button>
              )}
              {showMonthYear && tabIndex !== 4 && (
                <StaticDatePickerComponent
                  id="month-year-select"
                  ariaLabel="Select Date"
                  value={monthYearSel}
                  minDate={minDate || getFormatDate('1900-01-01')}
                  maxDate={maxDate || getFormatDate('2100-12-31')}
                  handleOnChange={date => {
                    const dateValue = getFormatDate(date)
                    setMonthYearSel(dateValue)
                    setShowMonthYear(false)
                    getCustomDateFilter({ year: moment(date).year() })
                  }}
                  views={tabIndex === 1 ? ['year', 'month'] : ['year']}
                />
              )}
              {!showMonthYear && tabIndex > 0 && (
                <FormGroup className="period-group">
                  {dateTypeObject?.map((dateObj, index) => {
                    const isChecked = isCheckedValue(dateObj.display_name)
                    return (
                      <FormControlLabel
                        key={dateObj.display_name}
                        control={
                          <Checkbox
                            id={`check-box-btn-${index}`}
                            aria-label={dateObj.display_name}
                            value={dateObj.display_name}
                            checked={isChecked}
                            onChange={event => {
                              handleSelectionChange(dateObj, event)
                            }}
                          />
                        }
                        label={
                          <div
                            className={classNames(
                              'relative-dates',
                              isChecked ? 'selected-dates' : 'not-selected-dates'
                            )}
                          >
                            <span>{dateObj.display_name}</span>
                            <span>{` ${getFormatDate(dateObj.begin_date)} To ${getFormatDate(dateObj.end_date)}`}</span>
                          </div>
                        }
                      />
                    )
                  })}
                </FormGroup>
              )}
            </div>
          )}
        </div>
      </div>
      <Divider />
      <div className="relative-button">
        <Button
          id="cancel-custom-dates"
          aria-label="Cancel Custom dates"
          variant="text"
          color="secondary"
          onClick={handleCancel}
        >
          Cancel
        </Button>
        <Button
          id="apply-custom-dates"
          aria-label="Apply Custom dates"
          variant="text"
          color="primary"
          onClick={() => {
            if (selectedDates?.length) {
              handleDateChange(selectedDates, null, 'custom')
            }
          }}
          disabled={!selectedDates?.length}
        >
          {`${isViewer && !isMobile ? 'Select' : 'Apply'}`}
        </Button>
      </div>
    </>
  )
}

CustomTimePeriod.propTypes = {
  handleDateChange: PropTypes.func,
  handleCancel: PropTypes.func,
  fiscalYear: PropTypes.bool,
  cardInfo: PropTypes.object,
  isDashboard: PropTypes.bool,
  dashboardInfo: PropTypes.object,
  isViewer: PropTypes.bool,
  viewerJson: PropTypes.object,
  isMobile: PropTypes.bool,
  getCustomDateFilter: PropTypes.func,
  customDateFilterStatus: PropTypes.object,
  minDate: PropTypes.string,
  maxDate: PropTypes.string,
}

export default CustomTimePeriod
