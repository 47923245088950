import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import urlon from 'urlon'

import { updateAppliedFilters, clearFilter } from '../../../ducks/filter'
import { updateCurrentUser } from '../../../ducks/user'
import FilterChipToolbar from './FilterChipToolbar'

export class GreenfieldFilterChipToolbar extends React.Component {
  handleOnFilterDelete = filterToDelete => {
    const { type } = this.props
    if (type === 'dashboard') {
      this.deleteDashboardFilter(filterToDelete)
    } else {
      this.deleteCardFilter(filterToDelete)
    }
  }

  deleteCardFilter(filterToDelete) {
    const { id, updateCurrentUser, routeProps, cardInfo } = this.props
    const savedFilters = cardInfo.data.card_config.card_query_attribute.saved_filters
    const isDefault = savedFilters.some(filter => {
      const filterToDeletePattern =
        filterToDelete.pattern &&
        (Array.isArray(filterToDelete.pattern) ? filterToDelete.pattern : filterToDelete.pattern.split(','))
      const filterPattern =
        filter.pattern && (Array.isArray(filter.pattern) ? filter.pattern : filter.pattern.split(','))
      const isVirtualColumn = filterToDelete?.time_period && filterToDelete?.type === 'interval'
      return (
        filter.dimension === filterToDelete.dimension &&
        (((filter.value || filter.value === 0) && filter.value === filterToDelete.value) ||
          ((filter.uppervalue || filter.uppervalue === 0) &&
            filter.uppervalue === filterToDelete.uppervalue &&
            filter.lowervalue === filterToDelete.lowervalue) ||
          (((filterPattern && filterToDeletePattern) || filterPattern === '') &&
            filterPattern.length === filterToDeletePattern.length &&
            filterPattern.every((value, index) => value === filterToDeletePattern[index])) ||
          isVirtualColumn)
      )
    })

    if (isDefault) {
      const deleteSavedFilterPayload = { delete_card_filter: { card_id: id, dimension: filterToDelete.dimension } }

      updateCurrentUser(deleteSavedFilterPayload)
    }

    const urlState = `${routeProps.location.search}${routeProps.location.hash}`
    const processedUrlState = urlState.slice(1, urlState.length)

    try {
      const parsedUrlState = urlon.parse(processedUrlState)
      const newFilters = parsedUrlState.filters.filter(filt => filt.dimension !== filterToDelete.dimension)
      const newUrlState = {
        ...parsedUrlState,
      }

      if (newFilters.length) {
        newUrlState.filters = newFilters
      } else {
        delete newUrlState.filters
      }

      routeProps.history.push(`${routeProps.match.url}?${urlon.stringify(newUrlState)}`)
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e, 'caught error in parsing URL for filter chip toolbar delete')
    }
  }

  deleteDashboardFilter(filterToDelete) {
    const { id, routeProps } = this.props
    const userSavedFilter = this.props.currentUser?.status?.data?.saved_dashboards
    const dashboardSavedFilter = this.props.viewDashboardInfo?.data?.saved_filters

    if (userSavedFilter) {
      const savedFilter = userSavedFilter.find(filter => filter.dashboard_id === id)
      const savedFilterId = savedFilter?.filters_list?.find(
        filter => filter.filter_builder_id === filterToDelete.filter_id
      )
      const isFilterMatches =
        JSON.stringify(savedFilterId?.pattern) === JSON.stringify(filterToDelete.cardFilters[0].filter.pattern) &&
        savedFilterId.type === filterToDelete.cardFilters[0].filter.type
      savedFilterId && isFilterMatches && this.deleteFilter(filterToDelete)
    } else if (dashboardSavedFilter) {
      const savedFilterId = dashboardSavedFilter.find(filter => filter.filter_builder_id === filterToDelete.filter_id)
      const isFilterMatches =
        JSON.stringify(savedFilterId?.pattern) === JSON.stringify(filterToDelete.cardFilters[0].filter.pattern) &&
        savedFilterId.type === filterToDelete.cardFilters[0].filter.type
      savedFilterId && isFilterMatches && this.deleteFilter(filterToDelete)
    }
    const urlState = `${routeProps.location.search}${routeProps.location.hash}`

    try {
      const processedUrlState = urlState.slice(1, urlState.length)
      const newUrlState = processedUrlState ? urlon.parse(processedUrlState) : { filters: [] }

      newUrlState.filters.splice(
        newUrlState.filters.findIndex(filt => filt.filter_name === filterToDelete.filter_name),
        1
      )

      routeProps.history.push(`${routeProps.match.url}?${urlon.stringify(newUrlState)}`)
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e, 'caught error in deleting a filter in Dashboardviewer updating URL')
    }
  }

  deleteFilter(filterToDelete) {
    const { id, updateCurrentUser } = this.props
    const deleteSavedFilterPayload = {
      delete_dashboard_filter: {
        dashboard_id: id,
        filter_name: filterToDelete.filter_name,
        filter_builder_id: filterToDelete.filter_id,
      },
    }

    updateCurrentUser(deleteSavedFilterPayload)
  }

  render() {
    const { appliedFilters, chipClicked, id, type, filterMetadataStatus, cardInfo } = this.props

    return (
      <FilterChipToolbar
        appliedFilters={appliedFilters}
        onDeleteFilter={this.handleOnFilterDelete}
        chipClicked={chipClicked}
        id={id}
        type={type}
        filterMetadataStatus={filterMetadataStatus}
        cardInfo={cardInfo}
      />
    )
  }
}

const mapStateToProps = state => ({
  appliedFilters: state.filter.appliedFilters,
  currentUser: state.user.putUpdateDefaultFiltersStatus,
  viewDashboardInfo: state.dashboard.viewDashBoardInfoStatus,
  filterMetadataStatus: state.metadata.filterMetadataStatus,
})

export const mapDispatchToProps = dispatch => ({
  updateAppliedFilters(data) {
    dispatch(updateAppliedFilters(data))
  },
  clearFilter(data) {
    dispatch(clearFilter(data))
  },
  updateCurrentUser(data) {
    dispatch(updateCurrentUser(data))
  },
})

GreenfieldFilterChipToolbar.propTypes = {
  id: PropTypes.number,
  appliedFilters: PropTypes.array,
  type: PropTypes.string,
  updateAppliedFilters: PropTypes.func,
  updateCurrentUser: PropTypes.func,
  clearFilter: PropTypes.func,
  chipClicked: PropTypes.func,
  cardIds: PropTypes.array,
  routeProps: PropTypes.object.isRequired,
  cardInfo: PropTypes.object,
}

export default connect(mapStateToProps, mapDispatchToProps)(GreenfieldFilterChipToolbar)
