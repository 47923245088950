import { useState, useEffect } from 'react'
import axios from 'axios'
import { useDispatch } from 'react-redux'
import { displayServiceErrorMessage } from '../../ducks/layout'

const useFetchFileContents = ({ localFileName, isActive } = { isActive: true }) => {
  const [responseData, updateResponseData] = useState(null)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!localFileName || !isActive) {
      return
    }

    axios
      .get(`/${localFileName}`)
      .then(response => {
        updateResponseData(response.data)
      })
      .catch(() => {
        dispatch(displayServiceErrorMessage(`Something went wrong fetching '${localFileName}'`))
      })
  }, [localFileName, isActive])

  return responseData
}

export default useFetchFileContents
