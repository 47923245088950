import React from 'react'
import PropTypes from 'prop-types'
import { AgGridReact } from '@ag-grid-community/react'
import { ModuleRegistry } from '@ag-grid-community/core'
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model'
import { MasterDetailModule } from '@ag-grid-enterprise/master-detail'
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel'
import { MenuModule } from '@ag-grid-enterprise/menu'
import { SetFilterModule } from '@ag-grid-enterprise/set-filter'
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel'

// Register the required feature modules with the Grid
ModuleRegistry.registerModules([
  ClientSideRowModelModule,
  MasterDetailModule,
  MenuModule,
  SetFilterModule,
  ColumnsToolPanelModule,
  FiltersToolPanelModule,
])

export const AgGridTable = ({
  onGridReady,
  columns,
  data,
  pageSize,
  masterDetail,
  isMobile,
  detailCellRendererFramework,
  gridRef,
  pagination = true,
  filter = true,
  rowSelection,
  onRowSelected,
  gridOptions,
  minWidth = 75,
}) => {
  const DefaultColDef = {
    flex: 1,
    minWidth,
    resizable: true,
    sortable: true,
    filter: true,
    unSortIcon: true,
    floatingFilter: filter || true,
    filterParams: { closeOnApply: true },
    menuTabs: !isMobile && ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab'],
  }

  return (
    <div className="ag-theme-alpine" data-cy="ag-grid-table">
      <AgGridReact
        domLayout="autoHeight"
        columnDefs={columns}
        rowData={data}
        ref={gridRef}
        defaultColDef={DefaultColDef}
        detailRowAutoHeight
        onGridReady={onGridReady}
        gridOptions={gridOptions}
        suppressRowClickSelection
        enableCellTextSelection
        masterDetail={masterDetail}
        pagination={pagination}
        paginationPageSize={pageSize}
        rowSelection={rowSelection}
        detailCellRendererFramework={detailCellRendererFramework}
        overlayNoRowsTemplate={'<span data-cy="ag-grid-table-no-rows">No Rows to Show</span>'}
        onRowSelected={onRowSelected}
      />
    </div>
  )
}

AgGridTable.defaultProps = {
  columns: [],
  data: [],
  isMobile: false,
  masterDetail: false,
  pageSize: 10,
  detailCellRendererFramework: null,
}

AgGridTable.propTypes = {
  detailCellRendererFramework: PropTypes.func,
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  pageSize: PropTypes.number,
  isMobile: PropTypes.bool,
  masterDetail: PropTypes.bool,
}
