import React from 'react'
import { Close } from '@mui/icons-material'
import { Dialog, DialogContent, DialogTitle, Grid, Icon, IconButton, Tooltip } from '@mui/material'
import apiConfig from '../../../config/apiConfig'
import CalculatedField from './CalculatedField.container'

const CalcFieldAddEditDialog = props => {
  const {
    calculatedDialogOpen,
    selectedDatasetId,
    calculatedFieldDetail,
    datasetObject,
    onHandleCancelCalculatedField,
    handleCalculatedFieldSuccess,
  } = props
  let isEditExists = false
  if (calculatedFieldDetail && Object.keys(calculatedFieldDetail).length) {
    isEditExists = true
  }

  return (
    <Dialog
      maxWidth={false}
      open={calculatedDialogOpen}
      aria-labelledby="calculated-dialog"
      onClose={onHandleCancelCalculatedField}
    >
      <DialogTitle id="calculated-dialog-title">
        <>
          {calculatedFieldDetail && Object.keys(calculatedFieldDetail).length ? (
            <>
              <Grid container>
                <Grid container item alignItems="center" xs={6}>
                  <span>Edit Calculated Field: </span>
                  <span>{`${calculatedFieldDetail.column_name}`}</span>
                  <Tooltip title="Open Window for Training Help" placement="bottom">
                    <a
                      href={`${apiConfig.help.url}/04_calculated_fields/calculated_fields`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <IconButton id="calculated-fields-info-btn" aria-label="Calculated Fields Training Help">
                        <Icon>live_help</Icon>
                      </IconButton>
                    </a>
                  </Tooltip>
                </Grid>
                <Grid container item justifyContent="flex-end" alignItems="center" xs={6}>
                  <span className="closeBtn">
                    <IconButton onClick={onHandleCancelCalculatedField}>
                      <Close />
                    </IconButton>
                  </span>
                </Grid>
              </Grid>
            </>
          ) : (
            <Grid container>
              <Grid container item justifyContent="flex-start" alignItems="center" xs={6}>
                <span>Calculated Fields</span>
                <Tooltip title="Open Window for Training Help" placement="bottom">
                  <a
                    href={`${apiConfig.help.url}/04_calculated_fields/calculated_fields`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <IconButton id="calculated-fields-info-btn" aria-label="Calculated Fields Training Help">
                      <Icon>live_help</Icon>
                    </IconButton>
                  </a>
                </Tooltip>
              </Grid>
              <Grid container item justifyContent="flex-end" alignItems="center" xs={6}>
                <span className="closeBtn">
                  <IconButton onClick={onHandleCancelCalculatedField}>
                    <Close />
                  </IconButton>
                </span>
              </Grid>
            </Grid>
          )}
        </>
      </DialogTitle>
      <DialogContent>
        <div style={{ width: '75vw', height: '80vh' }}>
          <CalculatedField
            resetCalculatedField={!isEditExists}
            selectedDatasetId={Number(selectedDatasetId)}
            saveCalculatedFieldSuccess={handleCalculatedFieldSuccess}
            onCancelCalculatedField={onHandleCancelCalculatedField}
            datasetObject={datasetObject}
            isBuilder={false}
            calculatedFieldDetail={calculatedFieldDetail}
          />
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default CalcFieldAddEditDialog
