import { call, put } from 'redux-saga/effects'
import axios from 'axios'
import { API_GATEWAY_URL } from './utils'

export const DATEFILTERS_FETCH_REQUESTED = 'DATEFILTERS_FETCH_REQUESTED'
export const DATEFILTERS_FETCH_SUCCEEDED = 'DATEFILTERS_FETCH_SUCCEEDED'
export const DATEFILTERS_FETCH_FAILED = 'DATEFILTERS_FETCH_FAILED'
export const ANCHOR_DATE_FILTERS_FETCH_REQUESTED = 'ANCHOR_DATE_FILTERS_FETCH_REQUESTED'
export const ANCHOR_DATE_FILTERS_FETCH_SUCCEEDED = 'ANCHOR_DATE_FILTERS_FETCH_SUCCEEDED'
export const ANCHOR_DATE_FILTERS_FETCH_FAILED = 'ANCHOR_DATE_FILTERS_FETCH_FAILED'
export const CUSTOM_DATE_FILTERS_FETCH_REQUESTED = 'CUSTOM_DATE_FILTERS_FETCH_REQUESTED'
export const CUSTOM_DATE_FILTERS_FETCH_SUCCEEDED = 'CUSTOM_DATE_FILTERS_FETCH_SUCCEEDED'
export const CUSTOM_DATE_FILTERS_FETCH_FAILED = 'CUSTOM_DATE_FILTERS_FETCH_FAILED'
export const CLEAR_CUSTOM_DATE_FILTERS_STATE = 'CLEAR_CUSTOM_DATE_FILTERS_STATE'

export default function timePeriodReducer(state = {}, action = {}) {
  switch (action.type) {
    case DATEFILTERS_FETCH_REQUESTED: {
      const newState = {
        ...state,
        dateFilterStatus: {
          status: 'requested',
        },
      }
      return newState
    }
    case DATEFILTERS_FETCH_SUCCEEDED: {
      const newState = {
        ...state,
        dateFilterStatus: action.payload,
      }
      return newState
    }
    case DATEFILTERS_FETCH_FAILED: {
      const newState = {
        ...state,
        dateFilterStatus: {
          status: 'failed',
          message: action.payload.response,
        },
      }
      return newState
    }
    case ANCHOR_DATE_FILTERS_FETCH_REQUESTED: {
      const newState = {
        ...state,
        anchorDateFilterStatus: {
          status: 'requested',
        },
      }
      return newState
    }
    case ANCHOR_DATE_FILTERS_FETCH_SUCCEEDED: {
      const newState = {
        ...state,
        anchorDateFilterStatus: {
          data: action.payload.data,
          anchor_date: action.anchor_date,
        },
      }
      return newState
    }
    case ANCHOR_DATE_FILTERS_FETCH_FAILED: {
      const newState = {
        ...state,
        anchorDateFilterStatus: {
          status: 'failed',
          message: action.payload.response,
        },
      }
      return newState
    }

    case CUSTOM_DATE_FILTERS_FETCH_REQUESTED: {
      const newState = {
        ...state,
        customDateFilterStatus: {
          status: 'requested',
        },
      }
      return newState
    }
    case CUSTOM_DATE_FILTERS_FETCH_SUCCEEDED: {
      const newState = {
        ...state,
        customDateFilterStatus: {
          data: action.payload.data,
          year: action.year,
        },
      }
      return newState
    }
    case CUSTOM_DATE_FILTERS_FETCH_FAILED: {
      const newState = {
        ...state,
        customDateFilterStatus: {
          status: 'failed',
          message: action.payload.response,
        },
      }
      return newState
    }
    case CLEAR_CUSTOM_DATE_FILTERS_STATE: {
      const newCustomState = {
        ...state,
        customDateFilterStatus: {},
      }
      return newCustomState
    }
    default:
      return state
  }
}

export function clearCustomCalendarDates() {
  return {
    type: CLEAR_CUSTOM_DATE_FILTERS_STATE,
  }
}

export function dateFilter(data) {
  return {
    type: DATEFILTERS_FETCH_REQUESTED,
    payload: data,
  }
}

export function getAnchorDateFilter(data) {
  return {
    type: ANCHOR_DATE_FILTERS_FETCH_REQUESTED,
    payload: data,
  }
}

export function getCustomDateFilter(data) {
  return {
    type: CUSTOM_DATE_FILTERS_FETCH_REQUESTED,
    payload: data,
  }
}

export function* fetchDateFilters(action) {
  try {
    const dateFilter = yield call(axios, {
      method: 'get',
      url: action?.payload?.field_name
        ? `${API_GATEWAY_URL}/bi_reporting_assets/v2/date_filters?dataset_id=${action.payload.dataset_id}&field_name=${action.payload.field_name}`
        : `${API_GATEWAY_URL}/bi_reporting_assets/v2/date_filters`,
    })

    if (dateFilter.error) {
      const e = dateFilter.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }

    yield put({ type: 'DATEFILTERS_FETCH_SUCCEEDED', payload: dateFilter })
  } catch (error) {
    yield put({ type: 'DATEFILTERS_FETCH_FAILED', payload: error })
  }
}

export function* fetchDateFiltersWithAnchorDate(action) {
  try {
    const dateFilter = yield call(axios, {
      method: 'get',
      url: `${API_GATEWAY_URL}/bi_reporting_assets/v2/date_filters?anchor_date=${action.payload.anchor_date}`,
    })

    if (dateFilter.error) {
      const e = dateFilter.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }

    yield put({
      type: 'ANCHOR_DATE_FILTERS_FETCH_SUCCEEDED',
      payload: dateFilter,
      anchor_date: action.payload.anchor_date,
    })
  } catch (error) {
    yield put({ type: 'ANCHOR_DATE_FILTERS_FETCH_FAILED', payload: error })
  }
}
export function* fetchCustomDateFilters(action) {
  try {
    const dateFilter = yield call(axios, {
      method: 'get',
      url: `${API_GATEWAY_URL}/bi_reporting_assets/v2/calender_filters?year=${action.payload.year}`,
    })

    if (dateFilter.error) {
      const e = dateFilter.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }

    yield put({
      type: 'CUSTOM_DATE_FILTERS_FETCH_SUCCEEDED',
      payload: dateFilter,
      year: action.payload.year,
    })
  } catch (error) {
    yield put({ type: 'CUSTOM_DATE_FILTERS_FETCH_FAILED', payload: error })
  }
}
