import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  Dialog,
  List,
  IconButton,
  ListItem,
  ListItemText,
  Accordion,
  AccordionSummary,
  Tabs,
  Tab,
  Typography,
  Divider,
  Snackbar,
  Link,
  Tooltip,
  Button,
} from '@mui/material'
import { Close, Delete, ExpandMore, GetApp, NavigateNext, Settings } from '@mui/icons-material'
import cloneDeep from 'lodash/cloneDeep'
import SwipeableViews from 'react-swipeable-views'
import { Spinner } from 'greenfield-utilities'
import moment from 'moment'
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView'
import { Link as RouterLink } from 'react-router-dom'
import Badge from '@mui/material/Badge'

import './notification.scss'
import { buildDownloadUrl } from '../../shared/util'

const notificationTitleProps = {
  variant: 'subtitle2',
  noWrap: false,
}
const notificationBodyProps = (expanded, notificationId) => ({
  component: 'div',
  noWrap: !(expanded === notificationId),
})
const timeAgoProps = {
  variant: 'caption',
}
let deletedItem = null
let popupItem = null

const Notification = ({
  notificationIsShown,
  toggleVisibility,
  data,
  updateNotificationAction,
  readDeleteNotificationAction,
  readDeleteNotificationStatus,
  notificationReadUpdateAction,
  notificationStatus,
  notificationReadUpdateStatus,
  displayServiceErrorMessage,
  fetchDownloadNotifyData,
  downloadedNotificationData,
  clearLargeExportCache,
  isMobile,
  sendFireflyEvent,
}) => {
  const [value, setValue] = useState(0)
  const [expandedPanel, setExpandedPanel] = useState(false)
  const _displayData = data?.data?.length ? cloneDeep(data.data) : []
  const [expanded, setExpanded] = useState(false)
  const [popupShown, setPopupShown] = useState(false)
  const [downloadErrorSnackBar, setDownloadErrorSnackBar] = useState(false)
  const [downloadJobName, setDownloadJobName] = useState(null)
  const [isDownloading, setIsDownloading] = useState(false)
  useEffect(() => {
    if (notificationStatus && notificationStatus.status === 'failed') {
      displayServiceErrorMessage('Failed to fetch Notifications.')
    }
    popupLogic()
  }, [notificationStatus])
  useEffect(() => {
    if (readDeleteNotificationStatus && readDeleteNotificationStatus.status === 'failed') {
      displayServiceErrorMessage('Notification delete has failed.')
    } else if (readDeleteNotificationStatus?.status === 204) {
      updateNotificationAction()
    }
  }, [readDeleteNotificationStatus])
  useEffect(() => {
    if (notificationReadUpdateStatus && notificationReadUpdateStatus.status === 'failed') {
      displayServiceErrorMessage('Notification read update has failed.')
    }
  }, [notificationReadUpdateStatus])
  // To show error on download failed
  useEffect(() => {
    if (
      downloadedNotificationData &&
      downloadedNotificationData.status >= 200 &&
      downloadedNotificationData.status < 300
    ) {
      const anchor = document.createElement('a')
      document.body.appendChild(anchor)

      const objectUrl = window.URL.createObjectURL(downloadedNotificationData?.data?.data)

      anchor.href = objectUrl
      anchor.download = `${downloadJobName}.zip`
      anchor.click()

      window.URL.revokeObjectURL(objectUrl)

      // get rid of anchor
      document.body.removeChild(anchor)
      setIsDownloading(false)
      setDownloadJobName(null)
      clearLargeExportCache()
    } else if (
      downloadedNotificationData &&
      downloadedNotificationData.status >= 400 &&
      downloadedNotificationData.status <= 600
    ) {
      setIsDownloading(false)
      displayServiceErrorMessage('There was an error downloading the data')
    }
  }, [downloadedNotificationData])

  const popupLogic = () => {
    popupItem = data?.data?.length && data.data.find(item => item.delivery.popup)
    setExpanded(false)
    popupItem && !popupShown && setPopupShown(!popupShown)
    popupItem && setExpanded(popupItem._id)
  }
  const handleDownloadLink = (downloadPath, downloadTitle) => {
    setDownloadJobName(downloadTitle)
    fetchDownloadNotifyData(downloadPath)
  }
  const handleClose = () => {
    toggleVisibility()
    setValue(0)
    setExpandedPanel(false)
    setExpanded(false)
  }
  const closePopup = () => {
    setPopupShown(!popupShown)
    setExpandedPanel(false)
    setExpanded(false)
    popupItem && updateReadOnClick(popupItem)
    popupItem = null
  }
  const handleShowMoreClick = expandedItem => {
    setExpanded(expanded === expandedItem ? false : expandedItem)
  }
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }
  const handleChangeIndex = index => {
    setValue(index)
  }
  const handleDelete = index => {
    readDeleteNotificationAction(_displayData[index]._id)
  }
  const updateReadOnClick = notificationItem => {
    if (deletedItem !== notificationItem._id) {
      notificationReadUpdateAction(notificationItem)
    }
  }

  const secondaryJSX = notification => {
    const noOfCharactersInBody = 40
    const displayMore = notification.message.body.length > noOfCharactersInBody
    const tmp = document.createElement('DIV')
    tmp.innerHTML = notification.message.body
    const formattedMessageBody = tmp.textContent || tmp.innerText
    return (
      <span>
        {expanded === notification._id ? (
          <FroalaEditorView model={notification.message.body} />
        ) : (
          formattedMessageBody.slice(0, noOfCharactersInBody)
        )}
        {displayMore ? (expanded === notification._id ? '   ' : '...  ') : null}
        <br />
        {displayMore ? (
          <Link
            className="linkStyle"
            align="left"
            component="button"
            variant="body2"
            onClick={() => {
              handleShowMoreClick(notification._id)
            }}
          >
            {expanded === notification._id ? '<< Show Less' : 'Show More >>'}
          </Link>
        ) : null}
      </span>
    )
  }

  const NotificationList = props => {
    const notifications = props.notifications
    return (
      <div className="divStyle">
        {notifications.map((notificationItem, index) => (
          <ListItem
            className={`notificationRead${notificationItem.read}`}
            key={index}
            value={notificationItem.type}
            onClick={notificationItem.read || popupItem ? null : () => updateReadOnClick(notificationItem)}
          >
            <ListItemText
              primary={
                <ListItemText
                  primary={notificationItem.message.title}
                  primaryTypographyProps={notificationTitleProps}
                  secondary={secondaryJSX(notificationItem)}
                  secondaryTypographyProps={notificationBodyProps(expanded, notificationItem._id)}
                />
              }
              secondary={moment(notificationItem.publish.published_date, "YYYY-MM-DD'T'HH:mm:ssZ").fromNow()}
              secondaryTypographyProps={timeAgoProps}
            />

            {deletedItem === notificationItem._id &&
            readDeleteNotificationStatus &&
            readDeleteNotificationStatus.status === 'requested' ? (
              <Spinner size="small" />
            ) : (
              !popupItem && (
                <>
                  {notificationItem.type === 'object-notification' && notificationItem.message.link && (
                    <Tooltip title="Download File" position="bottom">
                      <IconButton
                        aria-label="Download File"
                        id={`notification-download-icon${index}`}
                        disabled={isDownloading}
                        onClick={() => {
                          setIsDownloading(true)
                          handleDownloadLink(
                            buildDownloadUrl(notificationItem.message.link),
                            notificationItem.message.title
                          )
                        }}
                      >
                        <GetApp />
                      </IconButton>
                    </Tooltip>
                  )}
                  <IconButton
                    edge="end"
                    aria-label="Delete"
                    onClick={() => {
                      deletedItem = notificationItem._id
                      handleDelete(_displayData.indexOf(notificationItem))
                    }}
                  >
                    <Delete />
                  </IconButton>
                </>
              )
            )}
          </ListItem>
        ))}
      </div>
    )
  }

  const NotificationBox = props => {
    const noOfItemsInRecentList = 5
    const recentData = props.notificationData.slice(0, noOfItemsInRecentList)
    const olderData = props.notificationData.slice(noOfItemsInRecentList)

    return (
      <div>
        {recentData.length > 0 ? (
          <div>
            <AccordionSummary>
              <Typography className="recentEarlierNotificationText">Recent</Typography>
            </AccordionSummary>
            <List>
              <NotificationList notifications={recentData} />
            </List>
          </div>
        ) : (
          <div>
            <AccordionSummary disabled>
              <Typography className="recentEarlierNotificationText">You have no new notifications.</Typography>
            </AccordionSummary>
          </div>
        )}
        {olderData.length > 0 ? (
          <Accordion
            expanded={expandedPanel}
            onChange={(event, expanded) => {
              setExpandedPanel(expanded)
            }}
          >
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Typography className="recentEarlierNotificationText">Older</Typography>
            </AccordionSummary>
            <List>
              <NotificationList notifications={olderData} />
            </List>
          </Accordion>
        ) : null}
      </div>
    )
  }

  const globalCount = _displayData.filter(item => item.type === 'global-notification' && !item.read).length
  const objectCount = _displayData.filter(item => item.type === 'object-notification' && !item.read).length

  return (
    <>
      {popupItem ? (
        <Dialog
          data-cy="notification-popup"
          className="notificationDialog"
          open={popupShown}
          onClose={closePopup}
          maxWidth="sm"
          fullWidth
        >
          <span className="popUpModal">
            <IconButton data-cy="notification-popup-close-btn" aria-label="Close" onClick={closePopup}>
              <Close />
            </IconButton>
          </span>
          <NotificationList notifications={[popupItem]} />
        </Dialog>
      ) : null}
      <Dialog className="notificationDialog" open={notificationIsShown} onClose={handleClose} maxWidth="sm" fullWidth>
        <header className="title-notification">
          <Typography className="title">Notifications</Typography>
          <span>
            {isMobile ? (
              <IconButton onClick={handleClose}>
                <Settings />
              </IconButton>
            ) : (
              <Button
                data-cy="notification-manager-btn"
                className="icon-label-btn-props"
                color="secondary"
                to="/notification-manager"
                component={RouterLink}
                endIcon={<NavigateNext />}
                onClick={() => {
                  sendFireflyEvent(true, {
                    eventName: 'notificationManagerBtnClicked',
                    key: 'notification',
                  })
                  handleClose()
                }}
              >
                Notification Manager
              </Button>
            )}
            {toggleVisibility && (
              <IconButton aria-label="Close" onClick={handleClose}>
                <Close />
              </IconButton>
            )}
          </span>
        </header>
        <div className="tabStyle">
          <Tabs value={value} onChange={handleChange} indicatorColor="primary">
            <Tab
              label={
                globalCount ? (
                  <Badge badgeContent={globalCount} color="error">
                    <Typography className="tabHeaderText">Global Notifications</Typography>
                  </Badge>
                ) : (
                  <Typography className="tabHeaderText">Global Notifications</Typography>
                )
              }
            />

            <Tab
              label={
                objectCount ? (
                  <Badge badgeContent={objectCount} color="error">
                    <Typography className="tabHeaderText">Object Notifications</Typography>
                  </Badge>
                ) : (
                  <Typography className="tabHeaderText">Object Notifications</Typography>
                )
              }
            />
          </Tabs>
        </div>
        <Divider />
        <SwipeableViews index={value} onChangeIndex={handleChangeIndex}>
          <NotificationBox notificationData={_displayData.filter(item => item.type === 'global-notification')} />
          <NotificationBox notificationData={_displayData.filter(item => item.type === 'object-notification')} />
        </SwipeableViews>
      </Dialog>
      {downloadErrorSnackBar && (
        <Snackbar
          className="error-snackbar-message"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          onClose={() => setDownloadErrorSnackBar(false)}
          message={<span className="failed-text">There was an error downloading the data</span>}
          open={downloadErrorSnackBar}
          action={[<Close key="error-message-close-icon" onClick={() => setDownloadErrorSnackBar(false)} />]}
        />
      )}
    </>
  )
}

Notification.propTypes = {
  notificationIsShown: PropTypes.bool.isRequired,
}

export default Notification
