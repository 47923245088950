import './sidenav.scss'

import React from 'react'
import { Link } from 'react-router-dom'
import {
  Dialog,
  FormControlLabel,
  Typography,
  Icon,
  Toolbar,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Button,
  Grid,
  IconButton,
  Slide,
  Switch,
  Fade,
  Tooltip,
  Collapse,
  ListItemButton,
} from '@mui/material'
import isEqual from 'lodash/isEqual'
import { ExpandLess, ExpandMore, Settings, ExitToApp, NavigateNext, Info, Help, Person } from '@mui/icons-material'
import styled from 'styled-components'

import greenfieldLogo from '../../images/greenfield-logo.svg'
import Quicklinks from '../routes/QuickLinks/Quicklinks.container'
import apiConfig from '../../config/apiConfig'
import BuildDetailsModal from './BuildDetailsModal'

const Transition = React.forwardRef((props, ref) => <Slide direction="left" ref={ref} {...props} />)

const DesktopTransition = React.forwardRef((props, ref) => <Fade ref={ref} {...props} />)

const UserBar = styled.div`
  padding-left: 14px;
  padding-right: 4px;
  color: #fff;
  background-color: #666;

  .display-name {
    font-size: 12px;
    flex-shrink: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 15px;
    line-height: 1.1;
  }
`
const SettingsContainer = styled(ListItem)`
  padding-left: 35px;
  color: #fff;
`
class SideNav extends React.Component {
  state = {
    open: false,
    isShowAbout: false,
    updatedNavList: [],
    showQuicklinksMenu: false,
    isMounted: false,
    siteHomePage: '',
    sitePath: '',
    isSettingsCollapseOpen: false,
    isProfileCollapseOpen: false,
  }

  componentDidMount() {
    const isSite = /^\/site\//i.test(window.location.pathname)
    let siteHomePage = ''
    let sitePath = ''
    if (isSite) {
      const splitSiteURl = window.location.pathname?.split('/')
      if (splitSiteURl.length >= 3) {
        siteHomePage = `/${splitSiteURl[1]}/${splitSiteURl[2]}/home`
        sitePath = `/${splitSiteURl[1]}/${splitSiteURl[2]}`
      }
    }

    this.setState({ updatedNavList: this.setNavList(), siteHomePage, sitePath })
  }

  componentDidUpdate(prevProps) {
    const { userType, sideNavIsOpen, fetchUserType } = this.props
    if (!isEqual(prevProps.userType, userType)) {
      this.setState({ updatedNavList: this.setNavList() })
    }
    if (prevProps.sideNavIsOpen !== sideNavIsOpen && sideNavIsOpen && !userType) {
      fetchUserType()
    }
    if (!prevProps.sideNavIsOpen && sideNavIsOpen) {
      setTimeout(() => {
        this.setState({
          isMounted: true,
        })
      }, 100)
    }
  }

  setNavList = () => {
    const { navList, userType } = this.props
    const internalUserSideNav = ['Admin', 'Datastores']
    return navList.filter(
      eachItem =>
        (internalUserSideNav.includes(eachItem.displayName) &&
          userType &&
          userType.status &&
          userType.status >= 200 &&
          userType.status <= 300 &&
          userType.data &&
          userType.data.user_type === 'internal') ||
        (!internalUserSideNav.includes(eachItem.displayName) &&
          userType &&
          userType.status &&
          userType.status >= 200 &&
          userType.status <= 300)
    )
  }

  handleToggleEasterEggDialog = () => {
    this.setState(prevState => ({
      open: !prevState.open,
    }))
  }

  toggleQuicklinksMenu = () => {
    const { showQuicklinksMenu } = this.state
    this.setState({ showQuicklinksMenu: !showQuicklinksMenu })
  }

  renderListItem = (item, isMobile) => {
    const listItem = () => (
      <ListItem
        onClick={item && item.subMenu ? this.toggleQuicklinksMenu : this.props.onToggleNav}
        button
        classes={{
          root: 'sideNavListItem',
        }}
        id={`sideNavListItem-${item.displayName}`}
      >
        <Icon>{item.icon}</Icon>
        <ListItemText
          primary={item.displayName}
          classes={{
            root: 'sideNavListItemText',
          }}
          id={`sideNavListItemText-${item.displayName}`}
        />
        {isMobile && item && item.subMenu && (
          <IconButton id="quicklinks-next-btn">
            <NavigateNext className="align-icon" />
          </IconButton>
        )}
      </ListItem>
    )
    if (item && item.link) {
      return (
        <Link to={item.link} id={`${item.displayName}-sidemenuLink`} tabIndex={-1}>
          {listItem()}
        </Link>
      )
    } else {
      return listItem()
    }
  }

  handleSetFocusOnEndList = event => {
    if (!this.props.isMobile && event.shiftKey && event.keyCode === 9) {
      this.endListRef.focus()
    }
  }

  handleSetFocusOnLogo = event => {
    if (!this.props.isMobile && !event.shiftKey && event.keyCode === 9) {
      this.logoRef.focus()
    }
  }

  handleAdminOptInClick = adminMode => {
    const { actAsAdmin, userType } = this.props

    actAsAdmin({ ...userType, adminMode })
  }

  renderAdminOptIn = () => {
    const { userType } = this.props
    const adminEndTime = new Date(userType?.data?.system_administrator?.expiration_date)?.getTime()
    const delta = adminEndTime ? parseInt(adminEndTime, 10) - new Date() : -1
    const isAdmin = userType?.data?.is_admin === 'yes' && delta >= 0
    const isAdminEligible = userType?.data?.is_admin_eligible

    // Rules to determine whether the below toggle component is visible, enabled and checked.
    // 1. The toggle switch is visible if the user is either an administrator or is eligible to become and administrator.
    // 2. The toggle switch is disabled if the user is not eligible to be an administrator.  For always-admins, the switch should be disabled is isAdminEligible should be false
    // 3. The toggle switch is "checked" if the user isAdmin.
    if (isAdmin || isAdminEligible) {
      return (
        <FormControlLabel
          classes={{
            root: 'sideNavListItemText',
          }}
          id="actAsAdminToggle"
          control={
            <Switch
              checked={isAdmin}
              onChange={() => this.handleAdminOptInClick(!isAdmin)}
              disabled={!isAdminEligible}
            />
          }
          label="Act As Administrator"
        />
      )
    }
  }

  renderSideNav = () => {
    const { isMobile, deviceType, updateUser } = this.props
    const { isSettingsCollapseOpen, isProfileCollapseOpen, updatedNavList, showQuicklinksMenu } = this.state

    const renderProfile = () => {
      return (
        <>
          <ListItemButton
            onClick={() => {
              this.setState({
                isProfileCollapseOpen: !isProfileCollapseOpen,
              })
            }}
            classes={{
              root: 'sideNavListItem',
            }}
            id="sideNavListItem-profile"
          >
            <Icon>
              <Person />
            </Icon>
            <ListItemText
              primary="Profile"
              classes={{
                root: 'sideNavListItemText',
              }}
              id="sideNavListItemText-profile"
            />
            {isProfileCollapseOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={isProfileCollapseOpen}>
            <List>
              <ListItemButton
                component={Link}
                to="/profile"
                onClick={() => {
                  this.props.onToggleNav()
                  this.setState({ isProfileCollapseOpen: false })
                }}
                style={{
                  textDecoration: 'none',
                  outline: 'none',
                }}
              >
                <ListItemText inset primary="My Profile" />
              </ListItemButton>
              <ListItemButton
                component={Link}
                to="/beta-features"
                onClick={() => {
                  this.props.onToggleNav()
                  this.setState({ isProfileCollapseOpen: false })
                }}
                style={{
                  textDecoration: 'none',
                  outline: 'none',
                }}
              >
                <ListItemText inset primary="βeta Features" />
              </ListItemButton>
            </List>
          </Collapse>
        </>
      )
    }
    const renderSettings = () => {
      const animationsDisabled = userType?.data?.application_data.ui.chart_animations_disabled

      return (
        <>
          <ListItem
            onClick={() => {
              this.setState({
                isSettingsCollapseOpen: !isSettingsCollapseOpen,
              })
            }}
            button
            classes={{
              root: 'sideNavListItem',
            }}
            id="sideNavListItem-settings"
          >
            <Icon>
              <Settings />
            </Icon>
            <ListItemText
              primary="Settings"
              classes={{
                root: 'sideNavListItemText',
              }}
              id="sideNavListItemText-settings"
            />
            {isSettingsCollapseOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={isSettingsCollapseOpen}>
            <SettingsContainer>
              <Tooltip style={{ cursor: 'help' }} title="Disabling animations may increase browser performance.">
                <FormControlLabel
                  control={
                    <Switch
                      onClick={() => {
                        const payload = {
                          application_data: {
                            ui: {
                              ...userType.data.application_data.ui,
                              chart_animations_disabled: !animationsDisabled,
                            },
                          },
                        }
                        updateUser(payload)
                      }}
                      checked={!animationsDisabled}
                    />
                  }
                  label="Chart animations"
                />
              </Tooltip>
            </SettingsContainer>
          </Collapse>
        </>
      )
    }
    const allMenuItems = () => {
      return updatedNavList.map(item => (
        <div key={item.displayName} id={`SidemenuItem-${item.displayName}`}>
          {((isMobile && item.showOnMobile) || (!isMobile && !item.subMenu)) && this.renderListItem(item, isMobile)}
        </div>
      ))
    }

    const quicklinksMenu = () => (
      <Quicklinks
        isMobile={isMobile}
        deviceType={deviceType}
        onCloseSideNav={this.handleCloseNav}
        closeQuicklinksMenu={this.toggleQuicklinksMenu}
      />
    )

    const displayMenu = () => {
      return !isMobile ? (
        <>
          {renderProfile()}
          {allMenuItems()}
          {quicklinksMenu()}
          {renderSettings()}
          {aboutListItem()}
          {faqListItem()}
        </>
      ) : (
        <>
          {!showQuicklinksMenu && allMenuItems()}
          {showQuicklinksMenu && quicklinksMenu()}
          {!showQuicklinksMenu && aboutListItem()}
          {!showQuicklinksMenu && faqListItem()}
        </>
      )
    }

    const aboutListItem = () => (
      <ListItem
        onClick={this.handleToggleAbout}
        button
        classes={{
          root: 'sideNavListItem',
        }}
        id="sideNavListItem-about"
      >
        <Icon>
          <Info />
        </Icon>
        <ListItemText
          primary="About"
          classes={{
            root: 'sideNavListItemText',
          }}
          id="sideNavListItemText-about"
        />
      </ListItem>
    )

    const faqListItem = () => (
      <a id="faq-sidemenuLink" href={`${apiConfig.help.url}/08_faq`} target="_blank" rel="noopener noreferrer">
        <ListItem
          onClick={this.props.onToggleNav}
          onKeyDown={this.handleSetFocusOnLogo}
          button
          classes={{
            root: 'sideNavListItem',
          }}
          id="sideNavListItem-faq"
        >
          <Icon>
            <Help />
          </Icon>
          <ListItemText
            primary="FAQ"
            classes={{
              root: 'sideNavListItemText',
            }}
            id="sideNavListItemText-faq"
          />
        </ListItem>
        <span
          role="button"
          tabIndex={-1}
          ref={refFocus => {
            this.endListRef = refFocus
          }}
          id="sideNavListItemText-about"
        />
      </a>
    )

    const authObj = this.props.auth
    const userType = this.props.userType
    const userObj = authObj && authObj.session && authObj.session.userInfo ? authObj.session.userInfo : {}
    const userCompany = authObj?.session?.identity?.company
    const displayName = `${userObj.email} (${userCompany})`
    const handleLogoutClick = authObj.logout

    return (
      <div className="drawer-container">
        <Toolbar
          classes={{
            root: 'listHeader',
          }}
          id="listHeader"
        >
          <span
            role="img"
            tabIndex={-1}
            ref={refFocus => {
              this.logoRef = refFocus
            }}
          />
          <Link role="button" to="/favorites/home" onKeyDown={this.handleSetFocusOnEndList}>
            <img className={this.state.isMounted ? 'mounted' : ''} src={greenfieldLogo} alt="Greenfield Home Page" />
          </Link>
        </Toolbar>
        <UserBar>
          <Grid container alignContent="center" alignItems="center" justifyContent="space-between" wrap="nowrap">
            <Grid item>
              <Typography className="display-name">{displayName}</Typography>
            </Grid>
            <Grid item>
              <Tooltip title="Sign Out">
                <IconButton onClick={handleLogoutClick}>
                  <ExitToApp />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </UserBar>
        {this.renderAdminOptIn(userType)}
        <List className="sideNavList" id="sideNavList">
          {displayMenu()}
        </List>
        {isMobile && this.renderCloseNavBtn()}
      </div>
    )
  }

  renderItemWithLink = item => (
    <a
      href={item.link}
      key={item.displayName}
      rel="noopener noreferrer"
      target="_blank"
      id={`${item.displayName}-sidemenuLink`}
    >
      <ListItem
        button
        classes={{
          root: 'sideNavListItem',
        }}
        onClick={this.props.onToggleNav}
        id={`sideNavListItem-${item.displayName}`}
      >
        <ListItemText inset primary={item.displayName} />
      </ListItem>
    </a>
  )

  renderItemWithMethod = item => {
    if (item.displayName === 'Logout') {
      return this.checkIfUserIsSignedIn() ? (
        <ListItem
          button
          classes={{
            root: 'sideNavListItem',
          }}
          id={`sideNavListItem-${item.displayName}`}
          onClick={() => this.handleClick(item)}
          key={item.displayName}
        >
          <ListItemText inset primary={item.displayName} />
        </ListItem>
      ) : null
    } else {
      return (
        <ListItem
          button
          classes={{
            root: 'sideNavListItem',
          }}
          id={`sideNavListItem-${item.displayName}`}
          onClick={() => this.handleClick(item)}
          key={item.displayName}
        >
          <ListItemText inset primary={item.displayName} />
        </ListItem>
      )
    }
  }

  handleClick = item => {
    if (item.onClick) {
      if (item.onClick === 'signOut') {
        this.handleToggleEasterEggDialog()

        setTimeout(() => {
          this.handleToggleEasterEggDialog()
        }, 3000)
      }
      this.props[item.onClick]()
      this.props.onToggleNav()
    }
  }

  checkIfUserIsSignedIn = () => window.localStorage.getItem('id_token')

  renderLogOutEasterEgg = () => (
    <Dialog open={this.state.open} onClose={this.handleToggleEasterEggDialog}>
      <div className="greenfieldLogoutEasterEggMessage">Mischief managed!</div>
    </Dialog>
  )

  renderAbout = () => {
    const { isShowAbout } = this.state
    const { isMobile } = this.props

    return (
      <BuildDetailsModal
        onHandleToggleAbout={this.handleToggleAbout}
        isShowAbout={isShowAbout}
        isMobile={isMobile}
        Transition={Transition}
        DesktopTransition={DesktopTransition}
      />
    )
  }

  handleToggleAbout = () => {
    const { isShowAbout } = this.state
    this.setState({
      isShowAbout: !isShowAbout,
    })
  }

  handleCloseNav = () => {
    const { onToggleNav } = this.props
    this.setState(
      {
        showQuicklinksMenu: false,
      },
      () => {
        onToggleNav()
      }
    )
  }

  renderCloseNavBtn = () => {
    return (
      <Grid container spacing={3} className="sideNavMblBtnCenter">
        <Grid item xs={12} className="sideNavMblBtnCenter">
          <Button
            id="close-sideNav-btn"
            size="medium"
            variant="text"
            aria-label="Close menu"
            onClick={this.handleCloseNav}
            className="sideNav-btn"
          >
            Close
          </Button>
        </Grid>
      </Grid>
    )
  }

  render() {
    const { isMobile, sideNavIsOpen } = this.props
    const sideNavClass = !isMobile ? 'sideNav' : 'sideNav sideNav-mobile'
    return (
      <div id="sideNav">
        {this.renderLogOutEasterEgg()}
        <Drawer anchor="right" open={sideNavIsOpen} onClose={this.handleCloseNav} classes={{ paper: sideNavClass }}>
          {this.renderSideNav()}
        </Drawer>
        {this.renderAbout()}
      </div>
    )
  }
}

SideNav.defaultProps = {
  user: {
    email: '',
    isAuthorized: false,
  },
}

export default SideNav
