import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import isEqual from 'lodash/isEqual'
import cloneDeep from 'lodash/cloneDeep'
import urlon from 'urlon'
import { createErrMsg } from 'greenfield-utilities'

import { displayServiceErrorMessage } from '../../../ducks/layout'
import { renderCardFilter, renderDashboardFilter, updateAppliedFilters } from '../../../ducks/filter'
import { updateCurrentUser } from '../../../ducks/user'
import { getFilterMetadata } from '../../../ducks/metadata'
import { cardColumnsFilterList, isVirtualColumnCheck } from '../../routes/Builder/Sidebar/utils'
import { calculatedFieldType, dimensionType } from '../../routes/Builder/BuilderConstants'
import { dateFilter } from '../../../ducks/timeperiod'
import { prepareFilterPayload, getFilteredColumn } from './filter-utils'
import FilterViewer from './FilterViewer'

export class GreenfieldFilterViewer extends React.Component {
  state = {
    open: false,
    clickedFilter: '',
    checkedFilterItems: [],
    selectedFilter: {},
    activeFilters: [...this.props.appliedFilters],
  }

  key = this.props.type === 'dashboard' ? 'filter_id' : 'dimension'
  calulatedFieldType = ['postagg', 'aggregation']

  componentDidMount() {
    const {
      isMobile,
      appliedFilters,
      open,
      selectedFilterId,
      filterMetadataStatus,
      getFilterMetadata,
      cardsDatasetStatus,
      datasetId,
      columnArray,
      renderType,
      largeExportFilters,
      type,
      cardInfo,
    } = this.props
    let filterToShow = {}

    if (isMobile || renderType === 'largeExport') {
      this.setState({
        open,
        activeFilters: renderType === 'largeExport' ? largeExportFilters : appliedFilters,
      })

      if (open) {
        if (selectedFilterId) {
          filterToShow = this.props.viewerFilters.find(filterObj => filterObj[this.key] === selectedFilterId)
        } else {
          const columnList = type === 'card' ? cardColumnsFilterList(cardInfo) : null
          filterToShow =
            !this.props.viewerFilters.length && columnList
              ? {
                  ...columnList[0],
                  dimension: columnList[0].field_name,
                  display_name: columnList[0].column_display_name,
                  type: dimensionType.includes(columnList[0].type) ? 'in' : 'greaterThan',
                }
              : this.props.viewerFilters[0]

          if (filterToShow && !filterToShow.dimension && type !== 'dashboard') {
            this.key = 'ref_id'
          }
        }
        const isEnable = cardsDatasetStatus && cardsDatasetStatus[datasetId] && cardsDatasetStatus[datasetId].data
        const column = isEnable ? getFilteredColumn(filterToShow, cardsDatasetStatus, datasetId, columnArray) : {}
        this.setState(
          {
            selectedFilter: Object.assign({}, filterToShow),
          },
          filterToShow &&
            this.loadFilterList({
              filterId: filterToShow[column?.type === 'dynamic_dimension' && column?.exprQL ? 'ref_id' : this.key],
              column,
            })
        )
      }
    }

    if (!filterMetadataStatus || !Object.keys(filterMetadataStatus).length) {
      getFilterMetadata()
    }
  }

  /* eslint-disable camelcase */
  UNSAFE_componentWillReceiveProps(nextProps) {
    /* eslint-enable camelcase */
    const { open } = this.state
    const { cardsDatasetStatus, datasetId, columnArray, cardInfo, renderFilterStatus } = nextProps
    let filterToShow = {}
    this.setState({
      open: nextProps.open,
    })
    if (!isEqual(nextProps.appliedFilters, this.props.appliedFilters)) {
      this.setState({
        activeFilters: nextProps.appliedFilters,
      })
    }

    if (nextProps.open && !open) {
      if (nextProps.selectedFilterId) {
        filterToShow = this.props.viewerFilters.find(filterObj => filterObj[this.key] === nextProps.selectedFilterId)
      } else {
        const isCard = /^\/card\//i.test(window.location.pathname)
        const columnList = isCard ? cardColumnsFilterList(cardInfo) : null
        filterToShow =
          !this.props.viewerFilters.length && columnList
            ? {
                ...columnList[0],
                dimension: columnList[0].field_name,
                display_name: columnList[0].column_display_name,
                type: dimensionType.includes(columnList[0].type) ? 'in' : 'greaterThan',
              }
            : this.props.viewerFilters[0]
      }
      const isEnable = cardsDatasetStatus && cardsDatasetStatus[datasetId] && cardsDatasetStatus[datasetId].data
      const column = isEnable ? getFilteredColumn(filterToShow, cardsDatasetStatus, datasetId, columnArray) : {}
      this.setState(
        {
          selectedFilter: Object.assign({}, filterToShow),
        },
        filterToShow &&
          this.loadFilterList({
            filterId: filterToShow[column?.type === 'dynamic_dimension' && column?.exprQL ? 'ref_id' : this.key],
            column,
          })
      )
    }

    if (
      !isEqual(renderFilterStatus?.status, this.props.renderFilterStatus?.status) &&
      renderFilterStatus?.status === 'failed'
    ) {
      this.props.displayServiceErrorMessage(createErrMsg(renderFilterStatus))
    }
  }

  // passes selected pattern & type to child components for selected dimension via props
  setAppliedFilter(filterId) {
    let filterArrToSearch = []
    filterArrToSearch = this.mergeFilters()
    this.setState({
      activeFilters: filterArrToSearch,
    })
    this.setState({
      appliedFilter: filterArrToSearch.find(filter => filter[this.key] === filterId) || {},
    })
  }

  mergeFilters = () => {
    const { activeFilters } = this.state
    const { type } = this.props
    const filters = []

    if (type === 'dashboard') {
      activeFilters.forEach(activeFilter => {
        const activeFilterClone = { ...activeFilter }
        if (
          activeFilterClone &&
          activeFilterClone.cardFilters &&
          activeFilterClone.cardFilters[0] &&
          activeFilterClone.cardFilters[0].filter &&
          activeFilterClone.cardFilters[0].filter.type &&
          activeFilterClone.cardFilters[0].filter.pattern?.length > 0
        ) {
          filters.push(activeFilterClone)
        }
        // Dashboard handle VC Todo
      })
    } else {
      activeFilters.forEach(activeFilter => {
        const activeFilterClone = { ...activeFilter }
        const isValidPattern = Array.isArray(activeFilterClone.pattern)
          ? activeFilterClone.pattern.length
          : activeFilterClone.pattern
        if (
          activeFilterClone &&
          activeFilterClone.type &&
          (isValidPattern ||
            activeFilterClone.value ||
            activeFilterClone.value === 0 ||
            activeFilterClone.uppervalue ||
            activeFilterClone.uppervalue === 0 ||
            activeFilterClone.time_period)
        ) {
          filters.push(activeFilterClone)
        }
      })
    }
    return filters
  }

  loadFilterList = ({ filterId, column, searchString = '', filterType }) => {
    const { type, id, existingTimePeriod, drill, datasetId, renderCardFilter, dateFilter } = this.props
    const { activeFilters } = this.state
    const _activeFilters = cloneDeep(activeFilters)
    _activeFilters.forEach(filt => {
      delete filt.saveAsDefault
    })
    const payload = {
      type,
      id,
      [column?.type === 'dynamic_dimension' && column?.exprQL ? 'ref_id' : this.key]: filterId,
      searchString,
      activeViewerFilters: _activeFilters.filter(filt => filt?.pattern?.length) || [],
      existingTimePeriod,
      drill,
    }
    this.setAppliedFilter(filterId)
    if (type === 'dashboard') {
      this.props.renderDashboardFilter(payload)
    } else if (
      !(column && Object.keys(column).length) ||
      (column &&
        column.type !== 'metric' &&
        !this.calulatedFieldType.includes(column.type) &&
        !isVirtualColumnCheck(column))
    ) {
      filterType !== 'text' && renderCardFilter(payload)
    } else if (column && isVirtualColumnCheck(column)) {
      dateFilter({ field_name: column.field_name, dataset_id: datasetId })
    }
  }

  handleOnFilterClick = (filterId, isCardFilters = false) => {
    if (isCardFilters && this.props.type !== 'dashboard') {
      this.key = 'dimension'
    }
    const { viewerFilters, cardsDatasetStatus, datasetId, columnArray, cardInfo } = this.props
    const isEnable = cardsDatasetStatus && cardsDatasetStatus[datasetId] && cardsDatasetStatus[datasetId].data
    const filterList = isCardFilters ? cardColumnsFilterList(cardInfo) : viewerFilters
    const newSelectedFilter = filterList.find(
      filter =>
        (filter[this.key] && filter[this.key] === filterId) ||
        (filter.ref_id && filter.ref_id === filterId) ||
        filter?.field_name === filterId
    )
    if (isCardFilters && newSelectedFilter) {
      if (newSelectedFilter.type === 'metric') {
        this.updateState(newSelectedFilter, 'Sum', 'greaterThan', '', [])
      } else if (calculatedFieldType.includes(newSelectedFilter.type)) {
        this.updateState(newSelectedFilter, '', 'greaterThan', '', [])
      } else if (isVirtualColumnCheck(newSelectedFilter)) {
        this.updateState(newSelectedFilter, '', 'interval', '', [], '', false)
      } else {
        this.updateState(newSelectedFilter, '', 'in', '', [])
      }
    } else {
      this.setState({
        selectedFilter: newSelectedFilter,
      })
    }

    const column = isCardFilters
      ? newSelectedFilter
      : isEnable
      ? getFilteredColumn(newSelectedFilter, cardsDatasetStatus, datasetId, columnArray)
      : {}
    filterId && this.loadFilterList({ filterId, column, filterType: newSelectedFilter?.display_filter_type })
  }

  updateState = (column, aggregationType, type, value, pattern) => {
    this.setState({
      selectedFilter: {
        aggregation_type: aggregationType,
        dimension: column.field_name,
        display_name: column.column_display_name,
        display_filter_type: 'checkbox',
        obj_type: column.obj_type,
        ref_id: column.ref_id,
        pattern,
        type,
        value,
      },
    })
  }

  handleOnApplyClick = () => {
    const { type, onApplyDashboardFilters, routeProps } = this.props
    const filters = this.mergeFilters()

    if (type === 'dashboard') {
      this.saveDashboardDefaultFilters()
      if (filters && filters.length) {
        onApplyDashboardFilters(filters)
      }
    } else {
      this.saveCardDefaultFilters()
    }

    this.setState(
      {
        activeFilters: filters,
      },
      () => {
        const urlState = `${routeProps.location.search}${routeProps.location.hash}`
        const processedUrlState = urlState.slice(1, urlState.length)
        const newUrlState = filters.length
          ? type === 'dashboard'
            ? {
                filters: filters.map(filt => ({
                  ...filt,
                  cardFilters: filt.cardFilters.map(fil => ({
                    ...fil,
                    filter: (() => {
                      delete fil.filter.saveAsDefault

                      return fil.filter
                    })(),
                  })),
                })),
              }
            : {
                filters: prepareFilterPayload(filters),
              }
          : {}

        if (this.props.renderType === 'largeExport') {
          this.props.handleApplyClick(false)
          this.props.updateSelectedFilters(filters)
        } else {
          try {
            const parsedUrlState = processedUrlState ? urlon.parse(processedUrlState) : {}
            newUrlState.timePeriod = parsedUrlState.timePeriod

            if (parsedUrlState && Number.isInteger(parsedUrlState.activeDrillthroughIndex) && type === 'card') {
              newUrlState.activeDrillthroughIndex = parsedUrlState.activeDrillthroughIndex
            }

            if (type === 'dashboard') {
              filters.forEach((filt, findex) => {
                filt.cardFilters.forEach(cfilt => {
                  if (cfilt.cardId) {
                    const filterToModify = newUrlState.filters[findex].cardFilters.find(
                      cf => cf.cardId === cfilt.cardId
                    )

                    filterToModify.filter = {
                      d: filterToModify.filter.dimension,
                      p: filterToModify.filter.pattern,
                      t: filterToModify.filter.type,
                    }
                  }
                })
              })
            }

            routeProps.history.push(`${routeProps.match.url}${newUrlState ? `?${urlon.stringify(newUrlState)}` : ''}`)
          } catch (e) {
            // eslint-disable-next-line no-console
            console.log(e, 'caught error in parsing url for filterviewer container')
          }
          this.handleDismissPopover()
        }
      }
    )
  }

  handleOnClearClick = () => {
    const { routeProps, renderType } = this.props

    if (renderType !== 'largeExport') {
      try {
        const urlState = `${routeProps.location.search}${routeProps.location.hash}`
        const processedUrlState = urlState.slice(1, urlState.length)
        const parsedUrlState = processedUrlState ? urlon.parse(processedUrlState) : {}
        delete parsedUrlState.filters
        routeProps.history.push(
          `${routeProps.match.url}${Object.keys(parsedUrlState).length ? `?${urlon.stringify(parsedUrlState)}` : ''}`
        )
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error, 'caught error in parsing URL when deleting cardviewer filters')
      }

      this.handleDismissPopover()
    } else if (renderType === 'largeExport') {
      this.setState(
        {
          activeFilters: [],
        },
        () => {
          this.handleDismissPopover()
          this.props.clearAllFilters()
        }
      )
    }
  }

  handleAddingActiveFilter = newFilter => {
    const { activeFilters } = this.state
    const newFilters = [...activeFilters]
    const idx = activeFilters.findIndex(
      filter =>
        (filter[this.key] && filter[this.key] === newFilter[this.key]) ||
        (filter.ref_id && filter.ref_id === newFilter.ref_id)
    )

    if (idx > -1) {
      newFilters[idx] = newFilter
    } else {
      newFilters.push(newFilter)
    }
    this.setState({
      activeFilters: newFilters,
    })
  }

  handleDismissPopover = () => {
    const { appliedFilters, onDismissPopover } = this.props

    this.setState({
      activeFilters: cloneDeep(appliedFilters),
      selectedFilter: {},
    })

    onDismissPopover()
  }

  handleHighCardinalitySearch = (filterId, searchString) => {
    this.loadFilterList({ filterId, searchString })
  }

  saveDashboardDefaultFilters() {
    const { activeFilters } = this.state
    const { id, updateCurrentUser } = this.props
    const defaultFilterList = activeFilters.filter(filter => filter.cardFilters[0].filter.saveAsDefault)
    const defaultFilters = defaultFilterList.map(filter => ({
      filter_name: filter.filter_name,
      filter_builder_id: filter.filter_id,
      pattern: filter.cardFilters[0].filter.pattern,
      type: filter.cardFilters[0].filter.type,
    }))

    if (defaultFilters.length > 0) {
      const payload = { save_dashboard_filter: { dashboard_id: id, filters_list: defaultFilters } }
      updateCurrentUser(payload)
    }
  }

  saveCardDefaultFilters() {
    const { activeFilters } = this.state
    const { id, updateCurrentUser } = this.props
    const defaultFilterList = activeFilters.filter(filter => filter.saveAsDefault)
    const defaultFilters = prepareFilterPayload(defaultFilterList)

    if (defaultFilters.length > 0) {
      const payload = { save_card_filter: { card_id: id, filters_list: defaultFilters } }
      updateCurrentUser(payload)
    }
  }

  deleteAppliedFilter = filterToDelete => {
    const { routeProps } = this.props
    const urlState = `${routeProps.location.search}${routeProps.location.hash}`
    const processedUrlState = urlState.slice(1, urlState.length)

    try {
      const parsedUrlState = urlon.parse(processedUrlState)
      const newFilters = parsedUrlState.filters.filter(filt => !isEqual(filterToDelete, filt))
      routeProps.history.push(
        `${routeProps.match.url}${
          (newFilters && newFilters.length) ||
          (Number.isInteger(parsedUrlState.activeDrillthroughIndex) && parsedUrlState.activeDrillthroughIndex > -1)
            ? `?${urlon.stringify({
                ...parsedUrlState,
                filters: newFilters && newFilters.length ? newFilters : undefined,
              })}`
            : ''
        }`
      )
    } catch (e) {
      routeProps.history.push(`${routeProps.match.url}`)
    }
  }

  render() {
    const { open, selectedFilter, appliedFilter, activeFilters } = this.state
    const isCard = /^\/card\//i.test(window.location.pathname)
    const {
      isMobile,
      viewerFilters,
      renderFilterStatus,
      type,
      filterMetadataStatus,
      isAppliedFilters,
      renderedFilters,
      renderType,
      contextFilters,
      cardsDatasetStatus,
      datasetId,
      columnArray,
      cardInfo,
      deviceType,
      dashboardInfo,
      isDashboard,
      dateFilterStatus,
      closeMobilePopover,
      activeFilterMobileTab,
    } = this.props

    return (
      <FilterViewer
        open={open}
        isCard={isCard}
        viewerFilters={viewerFilters}
        selectedFilter={selectedFilter}
        renderFilterStatus={renderFilterStatus}
        appliedFilter={appliedFilter}
        activeFilters={activeFilters}
        onDismissPopover={this.handleDismissPopover}
        onApplyFilters={this.handleOnApplyClick}
        onClearFilters={this.handleOnClearClick}
        onFilterClick={this.handleOnFilterClick}
        onAddActiveFilter={this.handleAddingActiveFilter}
        onHighCardinalitySearch={this.handleHighCardinalitySearch}
        type={type}
        renderType={renderType}
        isMobile={isMobile}
        activeFilterMobileTab={activeFilterMobileTab}
        filterMetadataStatus={filterMetadataStatus}
        isAppliedFilters={isAppliedFilters}
        renderedFilters={renderedFilters}
        contextFilters={contextFilters}
        deleteAppliedFilter={this.deleteAppliedFilter}
        cardsDatasetStatus={cardsDatasetStatus}
        datasetId={datasetId}
        columnArray={columnArray}
        cardInfo={cardInfo}
        deviceType={deviceType}
        dashboardInfo={dashboardInfo}
        isDashboard={isDashboard}
        dateFilterStatus={dateFilterStatus}
        closeMobilePopover={closeMobilePopover}
      />
    )
  }
}

const mapStateToProps = state => ({
  renderFilterStatus: state.filter.renderFilterStatus,
  appliedFilters: state.filter.appliedFilters,
  filterMetadataStatus: state.metadata.filterMetadataStatus,
  cardsDatasetStatus: state.builder.cardsDatasetStatus,
  dateFilterStatus: state.timeperiod.dateFilterStatus,
})

export const mapDispatchToProps = dispatch => ({
  renderCardFilter(data) {
    dispatch(renderCardFilter(data))
  },
  renderDashboardFilter(data) {
    dispatch(renderDashboardFilter(data))
  },
  updateAppliedFilters(data) {
    dispatch(updateAppliedFilters(data))
  },
  updateCurrentUser(data) {
    dispatch(updateCurrentUser(data))
  },
  getFilterMetadata(data) {
    dispatch(getFilterMetadata(data))
  },
  displayServiceErrorMessage(data) {
    dispatch(displayServiceErrorMessage(data))
  },
  dateFilter(data) {
    dispatch(dateFilter(data))
  },
})

GreenfieldFilterViewer.defaultProps = {
  id: 0,
  open: false,
  type: 'card',
  appliedFilters: [],
  onDismissPopover: () => {},
  viewerFilters: [],
  renderFilterStatus: {},
  updateAppliedFilters: () => {},
  selectedFilterId: '',
  filterMetadataStatus: {},
  isAppliedFilters: false,
  activeFilterMobileTab: 0,
  existingTimePeriod: {},
  renderedFilters: [],
  cardsDatasetStatus: {},
}

GreenfieldFilterViewer.propTypes = {
  id: PropTypes.number,
  open: PropTypes.any,
  type: PropTypes.string,
  appliedFilters: PropTypes.array,
  onDismissPopover: PropTypes.func,
  viewerFilters: PropTypes.array,
  renderFilterStatus: PropTypes.object,
  updateAppliedFilters: PropTypes.func,
  updateCurrentUser: PropTypes.func,
  selectedFilterId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  filterMetadataStatus: PropTypes.object,
  existingTimePeriod: PropTypes.object,
  cardsDatasetStatus: PropTypes.object,
}

export default connect(mapStateToProps, mapDispatchToProps)(GreenfieldFilterViewer)
