import React, { createRef } from 'react'
import { Typography, Button, IconButton, Grid, Paper } from '@mui/material'
import { Delete, AddCircle, Close } from '@mui/icons-material'
import PropTypes from 'prop-types'
import isEqual from 'lodash/isEqual'
import { Link } from 'react-router-dom'
import { Spinner } from 'greenfield-utilities'

import ConfirmDialog from '../../shared/Dialog/ConfirmDialog'
import './admin.scss'
import { AgGridTable } from '../../shared/AgGrid/AgGridTableClientSide'
import { TableFilter } from '../../shared/AgGrid/TableFilter'
import GFAutocomplete from '../../shared/MUIAutoComplete/Autocomplete'

class AdminGroups extends React.Component {
  gridRef = createRef()
  state = {
    newGroups: [],
    groups: [],
    isGroupAddSubmit: false,
    isGroupDelete: false,
    deleteModalShown: false,
    selectedGroups: [],
    pageSize: 10,
    columns: [
      {
        headerName: 'Group Name',
        field: 'group_name',
        checkboxSelection: p => p.context,
        headerCheckboxSelection: () => this.gridOptions.context,
        cellRendererFramework: ({ data, value }) => (
          <Link className="admin-member-link" to={`/admin/groups/${data._id}/members`} data-cy="admin-member-link">
            {value}
          </Link>
        ),
      },
      {
        headerName: 'Owner(s)',
        field: 'owner',
      },
    ],
  }

  gridOptions = {
    context: this.state.isGroupDelete,
    suppressCellSelection: true,
  }

  updateState = (stateName, value) => {
    this.setState({ [stateName]: value })
  }

  componentDidMount() {
    if (this.props.adminDetail.data.groups) {
      this.setState({ groups: this.props.adminDetail.data.groups })
    }
  }

  componentDidUpdate(prevProps) {
    const { adminDetail } = this.props

    if (!isEqual(prevProps.adminDetail.data.groups, adminDetail.data.groups)) {
      this.setState({ groups: adminDetail.data.groups })
    }
  }

  static getDerivedStateFromProps(nextProps) {
    const { addedGroups, adminDetail } = nextProps
    let groups = []

    if (addedGroups.data) {
      groups = addedGroups.data.groups
    } else if (adminDetail.data) {
      groups = adminDetail.data.groups
    }

    return { groups }
  }

  renderPreHeader = () => {
    const { groups, isGroupAddSubmit, isGroupDelete, deleteModalShown, selectedGroups } = this.state
    const disabled = groups && groups.length === 0
    const { isMobile } = this.props

    const getAddDelBtns = () => {
      return !isMobile ? (
        <div className="button-end">
          <Button
            id="add-groups-btn"
            className="icon-label-btn-props"
            onClick={this.handleAddSubmitClick}
            color="secondary"
            startIcon={<AddCircle />}
          >
            Add Group(s)
          </Button>
          <Button
            id="delete-groups-btn"
            className="delete-btn icon-label-btn-props"
            disabled={groups.length === 0}
            onClick={this.handleDeleteClick}
            color="secondary"
            startIcon={<Delete />}
          >
            Remove Group(s)
          </Button>
        </div>
      ) : (
        <div className="button-end">
          <IconButton id="add-groups-btn-mobile" onClick={this.handleAddSubmitClick}>
            <AddCircle className="align-icon" />
          </IconButton>
          <IconButton id="delete-groups-btn-mobile" disabled={disabled} onClick={this.handleDeleteClick}>
            <Delete className="align-icon" />
          </IconButton>
        </div>
      )
    }

    const getDelConfirmBtns = () => {
      return !isMobile ? (
        <div className="button-end">
          <Button
            variant="text"
            className="delete-submit-btn"
            onClick={this.handleShowDeleteConfirm}
            color="primary"
            disabled={selectedGroups.length === 0}
          >
            Remove Groups(s)
          </Button>
          <Button variant="text" className="cancel-btn" onClick={this.handleCancelClick} color="secondary">
            Cancel
          </Button>
        </div>
      ) : (
        <div className="button-end">
          <IconButton
            id="delete-group-btn-mobile"
            disabled={selectedGroups.length === 0}
            onClick={this.handleShowDeleteConfirm}
          >
            <Delete className="align-icon" />
          </IconButton>
          <IconButton id="cancel-del-group-btn-mobile" onClick={this.handleCancelClick}>
            <Close className="align-icon" />
          </IconButton>
        </div>
      )
    }

    return (
      <>
        {!isGroupAddSubmit && !isGroupDelete && getAddDelBtns()}
        {isGroupDelete && !isGroupAddSubmit && getDelConfirmBtns()}
        <ConfirmDialog
          open={deleteModalShown && selectedGroups.length > 0}
          contentText="Are you sure you want to delete the selected group(s)?"
          okText="Yes, Delete Group(s)"
          onCloseDialog={() => {
            this.updateState('deleteModalShown', false)
          }}
          onClickOk={this.handleDeleteSubmitClick}
          onClickCancel={() => {
            this.updateState('deleteModalShown', false)
          }}
          cancelText="No"
        />
      </>
    )
  }

  renderAddGrpField = () => {
    const { isGroupAddSubmit, isGroupDelete, newGroups } = this.state
    const { adminDetail } = this.props

    return (
      <>
        {isGroupAddSubmit && !isGroupDelete && (
          <div className="field-margin">
            <Typography variant="body1">Add Group(s)</Typography>
            <GFAutocomplete
              value={newGroups}
              setValue={newGroups => this.setState({ newGroups })}
              variant="standard"
              groupsOnly
              vendors={adminDetail?.data.user_type === 'external'}
            />
            <div className="field-margin">
              <Button
                variant="text"
                className="save-btn"
                disabled={Boolean(newGroups.length === 0)}
                onClick={this.handleSaveClick}
                color="primary"
              >
                Save
              </Button>
              <Button variant="text" className="cancel-btn" onClick={this.handleCancelClick} color="secondary">
                Cancel
              </Button>
            </div>
          </div>
        )}
      </>
    )
  }

  handleSaveClick = () => {
    const groupIds = this.state.newGroups.map(addedGroup => addedGroup._id)

    this.setState({ isGroupAddSubmit: false, selectedGroups: [] })
    this.props.sendFireflyEvent(false, {
      eventName: 'adminAddUserGroups',
    })
    this.props.addGroups({
      groupIds,
      userId: this.props.adminDetail.data._id,
    })
  }

  handleShowDeleteConfirm = () => {
    this.setState({
      deleteModalShown: true,
    })
  }

  handleDeleteSubmitClick = () => {
    const groupIds = this.state.selectedGroups.map(item => item._id)
    this.setState(() => {
      this.gridOptions.context = false
      this.gridApi.redrawRows()
      this.gridApi.refreshHeader()
      this.gridOptions.api.deselectAll()
      return { isGroupDelete: false, deleteModalShown: false, selectedGroups: [] }
    })

    this.props.removeGroups({
      groupIds,
      userId: this.props.adminDetail.data._id,
    })
    this.props.sendFireflyEvent(false, {
      eventName: 'adminDeleteUserGroups',
    })
  }

  handleAddSubmitClick = () => {
    this.setState({
      isGroupAddSubmit: true,
      isGroupDelete: false,
      newGroups: [],
    })
  }

  handleCancelClick = () => {
    this.setState(() => {
      this.gridOptions.context = false
      this.gridApi.redrawRows()
      this.gridApi.refreshHeader()
      this.gridOptions.api.deselectAll()
      return { isGroupDelete: false, isGroupAddSubmit: false }
    })
  }

  handleDeleteClick = () => {
    this.setState(() => {
      this.gridOptions.context = true
      this.gridApi.redrawRows()
      this.gridApi.refreshHeader()
      return { isGroupDelete: true }
    })
  }

  renderBtnsForMbl = () => {
    return (
      <div className="admin-mbl-btn-div">
        <Grid container spacing={3}>
          <Grid item xs={6} className="admin-mbl-btn-left" onClick={this.handleAddSubmitClick}>
            <AddCircle /> Add Group(s)
          </Grid>
          <Grid item xs={6} className="admin-mbl-btn-right" onClick={this.handleDeleteClick}>
            <Delete /> Delete Group(s)
          </Grid>
        </Grid>
      </div>
    )
  }

  render() {
    const { adminDetail } = this.props
    const { groups, pageSize, columns } = this.state
    const key =
      adminDetail.data.first_name && adminDetail.data.first_name.length > 0
        ? `${adminDetail.data.first_name} ${adminDetail.data.last_name}`
        : `${adminDetail.data._id}`
    const message = `${key} has no Groups`
    const isEditEnable = adminDetail.data.edit_enabled === 'yes' || false

    const onRowSelected = event => {
      if (event.node.isSelected()) {
        const groupIndex = this.state.selectedGroups.findIndex(item => item._id === event.node.data._id)
        const selectedGroups =
          groupIndex > -1 ? this.state.selectedGroups : [...this.state.selectedGroups, event.node.data]
        this.setState({ selectedGroups })
      } else {
        const selectedGroups = this.state.selectedGroups.filter(item => item._id !== event.node.data._id)
        this.setState({ selectedGroups })
      }
    }

    const onGridReady = params => {
      this.gridApi = params.api
    }

    if (!groups) {
      return <Spinner />
    }

    return (
      <Paper>
        <div className="dashboards-container">
          <div className="dashboards-header-container">
            <div className="detail-btn-section">
              <Typography variant="subtitle1">
                {groups?.length ? `Displaying ${groups.length} Records` : message}
              </Typography>
              {Boolean(isEditEnable) && this.renderPreHeader()}
            </div>
            {Boolean(isEditEnable) && this.renderAddGrpField()}
            {groups && (
              <>
                <TableFilter
                  gridRef={this.gridRef}
                  pageSize={pageSize}
                  setPageSize={pageSize => {
                    this.setState({ pageSize })
                    this.gridApi.paginationSetPageSize(Number(pageSize))
                  }}
                />
                <AgGridTable
                  columns={columns}
                  rowSelection="multiple"
                  onRowSelected={onRowSelected}
                  data={groups}
                  pageSize={pageSize}
                  gridRef={this.gridRef}
                  gridApi={this.gridApi}
                  onGridReady={onGridReady}
                  gridOptions={this.gridOptions}
                />
              </>
            )}
          </div>
        </div>
      </Paper>
    )
  }
}

AdminGroups.defaultProps = {
  adminDetail: {},
  groupList: {},
  autoSuggest: {},
  addedGroups: {},
  addGroups: () => {},
  removeGroups: () => {},
  deleteGroup: () => {},
  fetchGrouplist: () => {},
  fetchAutoSuggest: () => {},
}

AdminGroups.propTypes = {
  adminDetail: PropTypes.object,
  groupList: PropTypes.object,
  autoSuggest: PropTypes.object,
  addedGroups: PropTypes.object,
  addGroups: PropTypes.func,
  removeGroups: PropTypes.func,
  deleteGroup: PropTypes.func,
  fetchGrouplist: PropTypes.func,
  fetchAutoSuggest: PropTypes.func,
}

export default AdminGroups
