import React from 'react'
import { Box, FormControl, Grid, TextField, Typography } from '@mui/material'
import styled from '@emotion/styled'
import CryptoJS from 'crypto-js'
import FroalaEditor from 'froala-editor'
import FroalaEditorReact from 'react-froala-wysiwyg'

import GFAutocomplete from '../../../../shared/MUIAutoComplete/Autocomplete'
import apiConfig from '../../../../../config/apiConfig'
import { toolbarButtons } from '../../../../shared/util'
import { DATA_ALERT } from './DataAlertSubscriptionDialog'
import { getEmailCriteriaText } from './Helper'

const DataAlertEmailDetail = ({
  recipientsList,
  setRecipientsList,
  emailSubject,
  setEmailSubject,
  emailMessage,
  setEmailMessage,
  label,
  allMetricFilters,
  appliedMetricFilters,
}) => {
  FroalaEditor.DefineIcon('reset', { NAME: 'Reset', template: 'text' })
  FroalaEditor.RegisterCommand('reset', {
    title: 'Reset Message to default values',
    focus: false,
    undo: true,
    refreshAfterCallback: true,
    callback: function () {
      setEmailMessage(label === DATA_ALERT ? getEmailCriteriaText({ allMetricFilters, appliedMetricFilters }) : '')
      this.events.focus()
    },
  })

  return (
    <Grid container spacing={1} columns={1}>
      <Grid item xs={12}>
        <Item>
          <FormControl fullWidth variant="outlined">
            <GFAutocomplete label="To" value={recipientsList} setValue={setRecipientsList} />
          </FormControl>
        </Item>
      </Grid>
      <Grid item xs={12}>
        <Item>
          <TextField
            variant="outlined"
            fullWidth
            label="Subject"
            value={emailSubject}
            placeholder="Subject"
            error={emailSubject.length === 0}
            onChange={event => setEmailSubject(event.target.value)}
          />
        </Item>
      </Grid>
      <Grid item>
        <Item>
          <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
            Message
          </Typography>
          <FroalaEditorReact
            tag="textarea"
            label="Message"
            aria-label="text editor"
            model={emailMessage}
            onModelChange={event => setEmailMessage(event)}
            config={{
              width: '90%',
              placeholderText: 'Add Email Message Body',
              events: {
                'froalaEditor.image.uploaded': (e, editor, response) => {
                  const { link } = JSON.parse(response)
                  // NOTE: GREEN-12572 this relative route should be able to remain once we figure out go-proxy and fastly configs
                  editor.image.insert(
                    `/images/${link.split('/')[link.split('/').length - 1]}`,
                    false,
                    null,
                    editor.image.get(),
                    response
                  )
                  return false
                },
              },
              key: CryptoJS.AES.decrypt(process.env.FROALA_KEY.toString(), apiConfig.froalaDecryptKey).toString(
                CryptoJS.enc.Utf8
              ),
              quickInsertTags: [],
              attribution: false,
              charCounterMax: 5000,
              fileUpload: false,
              imageUpload: false,
              videoUpload: false,
              htmlExecuteScripts: true,
              fontFamily: {
                'Arial,Helvetica,sans-serif': 'Arial',
                'Georgia,serif': 'Georgia',
                'Impact,Charcoal,sans-serif': 'Impact',
                'Roboto,Arial,sans-serif': 'Roboto',
                'Tahoma,Geneva,sans-serif': 'Tahoma',
                "'Times New Roman',Times,serif": 'Times New Roman',
                'Verdana,Geneva,sans-serif': 'Verdana',
              },
              fontFamilyDefaultSelection: 'Roboto',
              fontSize: ['8', '9', '10', '11', '12', '14', '16', '18', '24', '30', '36', '48', '60', '72', '96'],
              toolbarButtons: {
                ...toolbarButtons,
                moreRich: {
                  buttons: ['insertTable', 'specialCharacters', 'insertHR'],
                  buttonsVisible: 2,
                },
                moreMisc: {
                  buttons: [
                    'undo',
                    'redo',
                    'reset',
                    'fullscreen',
                    'print',
                    'spellChecker',
                    'selectAll',
                    'html',
                    'help',
                  ],
                  align: 'right',
                  buttonsVisible: 3,
                },
              },
              zIndex: 999,
            }}
          />
        </Item>
      </Grid>
      {label === DATA_ALERT && (
        <Grid item>
          <Item>
            <Typography variant="caption">
              ** Please do not change the values inside ( ) and in bold which will impact the data received in the email
            </Typography>
          </Item>
        </Grid>
      )}
    </Grid>
  )
}

export default DataAlertEmailDetail

export const Item = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
}))
