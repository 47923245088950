import React from 'react'
// import { IconButton } from '@mui/material'
import PropTypes from 'prop-types'
// import { CropFree, AspectRatio } from '@mui/icons-material'
import { Promise } from 'q'

export class FullScreen extends React.Component {
  state = {
    fullScreenEle: '',
  }

  componentDidMount() {
    document.addEventListener('webkitfullscreenchange', this.fullScreenChange, false)
    document.addEventListener('mozfullscreenchange', this.fullScreenChange, false)
    document.addEventListener('fullscreenchange', this.fullScreenChange, false)
    document.addEventListener('MSFullscreenChange', this.fullScreenChange, false)
  }

  componentWillUnmount() {
    document.removeEventListener('webkitfullscreenchange', this.fullScreenChange, false)
    document.removeEventListener('mozfullscreenchange', this.fullScreenChange, false)
    document.removeEventListener('fullscreenchange', this.fullScreenChange, false)
    document.removeEventListener('MSFullscreenChange', this.fullScreenChange, false)
  }

  fullScreenChange = () => {
    const fullScreen = this.checkIfInFullScreen()
    const isFullScreen = fullScreen && this.state.fullScreenEle !== null

    if (!isFullScreen) {
      this.setState({
        fullScreenEle: null,
      })
    }
  }

  checkIfInFullScreen = () => {
    return (
      document.webkitIsFullScreen ||
      document.mozFullScreen ||
      document.msFullscreenElement ||
      document.fullScreenElement
    )
  }

  toggleFullScreen = (id, screenState) => {
    const { deviceType } = this.props
    if (deviceType.includes('isPhone')) {
      const elem = document.getElementById(id)

      this.triggerToggle(elem, screenState).then(() => {
        this.setState({
          fullScreenEle: id,
        })
      })
    }
  }

  triggerToggle = (elem, screenState) => {
    // Promises resolve a race condition around checking if the page is in full screen and react updating state - Art/49754518
    return new Promise(resolve => {
      if (!screenState) {
        if (elem.requestFullscreen) {
          elem.requestFullscreen().then(() => {
            resolve()
          })
        } else if (elem.mozRequestFullScreen) {
          elem.mozRequestFullScreen().then(() => {
            resolve()
          })
        } else if (elem.webkitRequestFullscreen) {
          elem.webkitRequestFullscreen().then(() => {
            resolve()
          })
        } else if (elem.msRequestFullscreen) {
          elem.msRequestFullscreen()
        }
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen().then(() => {
            resolve()
          })
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen().then(() => {
            resolve()
          })
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen().then(() => {
            resolve()
          })
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen()
        }
      }
    })
  }

  render() {
    //  const isFullScreen = this.checkIfInFullScreen()

    return (
      <>
        {/*
          Commenting out till the DOM API becomes a bit better
        {this.props.isMobile &&

          this.props.deviceType.includes('isPhone') && (
            <IconButton
              className="fullScreenTriggerBtn"
              onClick={e => this.toggleFullScreen(this.props.id, isFullScreen)}
            >
              {isFullScreen ? <AspectRatio /> : <CropFree />}
            </IconButton>
          )}
        */}
      </>
    )
  }
}

FullScreen.defaultProps = {
  isMobile: false,
  deviceType: [],
  id: '',
}

FullScreen.propTypes = {
  isMobile: PropTypes.bool,
  deviceType: PropTypes.array,
  id: PropTypes.string,
}

export default FullScreen
