import { useState } from 'react'
import axios from 'axios'
import { useDispatch } from 'react-redux'
import { Parser } from 'node-sql-parser'
import { displayServiceErrorMessage } from '../../ducks/layout'
import { API_GATEWAY_URL } from '../../ducks/utils'

const usePutGroupSql = (groupId, sendFireflyEvent) => {
  const [isSubmitted, setIsSubmitted] = useState(null)
  const [isLoading, setIsLoading] = useState(null)
  const [error, setError] = useState(null)
  const dispatch = useDispatch()
  const parser = new Parser()

  const putGroupSql = newSql => {
    setIsSubmitted(false)

    if (!groupId) {
      dispatch(displayServiceErrorMessage('Can not update unknown Group'))
      return
    }

    if (!newSql) {
      dispatch(displayServiceErrorMessage('Must enter a SQL statement'))
      return
    }

    setIsLoading(true)
    setError(null)

    let finalSql

    try {
      const sqlWithoutSpaces = newSql.replace(/\s+/g, ' ')
      parser.parse(sqlWithoutSpaces) // will throw error if invalid

      if (!sqlWithoutSpaces.toUpperCase().startsWith('SELECT')) {
        setError('SQL statement must start with SELECT')
        return
      }

      finalSql = sqlWithoutSpaces
    } catch (error) {
      setError(`${error.name}: ${error.message}`)
      return
    } finally {
      setIsLoading(false)
    }

    const requestBody = {
      provision_process: {
        type: 'automated',
        source: 'bigred3',
        rule: finalSql,
      },
    }

    sendFireflyEvent(false, {
      eventName: 'adminEditGroupSql',
    })

    axios
      .put(`${API_GATEWAY_URL}/bi_reporting_assets/v2/groups/${groupId}`, requestBody)
      .then(response => {
        // eslint-disable-next-line no-console
        console.log(`Group ${groupId} SQL update response:`, response)
        setIsSubmitted(true)
      })
      .catch(error => {
        setError(`${error.name}: ${error.message}`)
        dispatch(displayServiceErrorMessage(`Something went wrong updating Group '${groupId}'`))
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return { putGroupSql, isSubmitted, isLoading, error }
}

export default usePutGroupSql
