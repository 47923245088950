import React from 'react'
import PropTypes from 'prop-types'
import {
  Icon,
  IconButton,
  Tooltip,
  Typography,
  Button,
  Step,
  StepLabel,
  DialogContent,
  DialogActions,
} from '@mui/material'
import { Spinner } from 'greenfield-utilities'
import { Close } from '@mui/icons-material'

import apiConfig from '../../config/apiConfig'
import { TitleContainer, SDialogTitle, SClose, SStepper } from './DatasetBuilder-styled'

const DatasetBuilderCompletion = ({ errorState, closeDatasetBuilder, uploadStatus, pendingDatasetId }) => {
  // const cancelUpload = () => {
  // todo make this button/function do something when clicked as there's no service for it now.
  // }

  return (
    <>
      <SDialogTitle>
        <div />
        <TitleContainer>
          <Typography>Ingestion in Progress (this may take several minutes)</Typography>
          <Typography>Your dataset will be available to use once this process has completed successfully</Typography>
          <Typography>
            Ingestion status and upload task history for this dataset is viewable on the dataset page{' '}
            <a rel="noopener noreferrer" target="_blank" href={`/dataset/preview/${pendingDatasetId}/upload-history`}>
              Click to open in a new tab
            </a>
          </Typography>
        </TitleContainer>
        <div>
          <Tooltip title="Helpcenter information for dataset builder">
            <a
              href={`${apiConfig.help.url}/03_datasets/what-is-a-dataset/#dataset-builder-using-file-upload`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <IconButton>
                <Icon>live_help</Icon>
              </IconButton>
            </a>
          </Tooltip>
          <SClose onClick={closeDatasetBuilder}>
            <Close />
          </SClose>
        </div>
      </SDialogTitle>
      <DialogContent>
        <SStepper activeStep={uploadStatus.activeStep}>
          <Step key="1">
            <StepLabel>Indexing</StepLabel>
          </Step>
          <Step key="2">
            <StepLabel>Propagating</StepLabel>
          </Step>
          <Step key="3">
            <StepLabel>Updating Metadata</StepLabel>
          </Step>
          <Step key="4">
            <StepLabel>Complete</StepLabel>
          </Step>
        </SStepper>
        {uploadStatus.activeStep !== 3 && !errorState && (
          <Spinner style={{ opacity: '0.5', marginTop: '20px' }} size="large" layout="selfCentering" />
        )}
      </DialogContent>
      <DialogActions>
        {uploadStatus.activeStep === 3 ? (
          <>
            <Button onClick={closeDatasetBuilder}>Close</Button>
            <a href={`/builder/card/dataset/${uploadStatus.data.data.dataset_id}`}>
              <Button variant="contained" color="primary" onClick={closeDatasetBuilder}>
                Create a card
              </Button>
            </a>
          </>
        ) : (
          <>
            {/* <Button variant="contained" color="secondary" onClick={cancelUpload}>
              Cancel upload
            </Button> */}
            <Button onClick={closeDatasetBuilder} variant="contained" color="primary">
              Close and view later
            </Button>
          </>
        )}
      </DialogActions>
    </>
  )
}

DatasetBuilderCompletion.propTypes = {
  closeDatasetBuilder: PropTypes.func.isRequired,
  uploadStatus: PropTypes.object,
}

export default DatasetBuilderCompletion
