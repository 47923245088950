import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getBuildGreenfieldServices } from '../../ducks/layout'

export const useBuildGreenfieldServices = ({ isActive } = { isActive: true }) => {
  const buildGreenfieldServices = useSelector(state => state.layout.buildGreenfieldServices)
  const buildGreenfieldServicesStatus = useSelector(state => state.layout.buildGreenfieldServicesStatus)

  const dispatch = useDispatch()

  useEffect(() => {
    if (isActive && !buildGreenfieldServicesStatus?.status) {
      dispatch(getBuildGreenfieldServices())
    }
  }, [isActive, buildGreenfieldServicesStatus, getBuildGreenfieldServices])

  return buildGreenfieldServices?.data
}

export default useBuildGreenfieldServices
