import React from 'react'
import moment from 'moment'
import { Typography, Grid, List, ListItem, ListItemText } from '@mui/material'
import { getOwnerInfoChip } from '../../shared/AgGrid/AgGridHelper'

export const SiteDetailComponent = ({ rowData }) => {
  return (
    <Grid className="siteRowExpand" container direction="row" justifyContent="space-evenly" alignItems="flex-start">
      <Grid item xs={6}>
        <GridItem primary="Name" secondary={rowData.name} />
        {rowData.description && <GridItem primary="Site Description" secondary={rowData.description} />}
        <GridItem primary="Owner(s)*" secondary={getOwnerInfoChip(rowData.owners_access)} />
      </Grid>
      <Grid item xs={6}>
        <GridItem primary="Created Date" secondary={moment(rowData.create_date).format('MMMM Do YYYY, h:mm:ss a')} />
        <GridItem
          primary="Last Updated"
          secondary={moment(rowData.audit_properties?.updated_date).format('MMMM Do YYYY, h:mm:ss a')}
        />
      </Grid>
    </Grid>
  )
}

const GridItem = ({ primary, secondary }) => {
  return (
    <List>
      <ListItem>
        <ListItemText
          className="site-list-item"
          primary={<Typography variant="subtitle1">{primary}</Typography>}
          secondary={
            <Typography variant="body1" data-cy={`site-list-item-${primary}`}>
              {secondary}
            </Typography>
          }
        />
      </ListItem>
    </List>
  )
}
