import { call, put } from 'redux-saga/effects'
import axios from 'axios'
import { API_GATEWAY_URL } from './utils'

// Action types
export const DATASTORE_LIST_FETCH_REQUESTED = 'DATASTORE_LIST_FETCH_REQUESTED'
export const DATASTORE_LIST_FETCH_SUCCEEDED = 'DATASTORE_LIST_FETCH_SUCCEEDED'
export const DATASTORE_LIST_FETCH_FAILED = 'DATASTORE_LIST_FETCH_FAILED'

export const DATASTORE_POST_REQUESTED = 'DATASTORE_POST_REQUESTED'
export const DATASTORE_POST_SUCCEEDED = 'DATASTORE_POST_SUCCEEDED'
export const DATASTORE_POST_FAILED = 'DATASTORE_POST_FAILED'

export const DATASTORE_DETAIL_REQUESTED = 'DATASTORE_DETAIL_REQUESTED'
export const DATASTORE_DETAIL_SUCCEEDED = 'DATASTORE_DETAIL_SUCCEEDED'
export const DATASTORE_DETAIL_FAILED = 'DATASTORE_DETAIL_FAILED'
export const DATASTORE_DETAIL_CLEAR = 'DATASTORE_DETAIL_CLEAR'

export const DATASTORE_PUT_REQUESTED = 'DATASTORE_PUT_REQUESTED'
export const DATASTORE_PUT_SUCCEEDED = 'DATASTORE_PUT_SUCCEEDED'
export const DATASTORE_PUT_FAILED = 'DATASTORE_PUT_FAILED'

export const DATASTORE_TEST_REQUESTED = 'DATASTORE_TEST_REQUESTED'
export const DATASTORE_TEST_SUCCEEDED = 'DATASTORE_TEST_SUCCEEDED'
export const DATASTORE_TEST_FAILED = 'DATASTORE_TEST_FAILED'
export const DATASTORE_TEST_CLEAR = 'DATASTORE_TEST_CLEAR'

export const DATASTORE_DELETE_SUCCEEDED = 'DATASTORE_DELETE_SUCCEEDED'
export const DATASTORE_DELETE_REQUESTED = 'DATASTORE_DELETE_REQUESTED'
export const DATASTORE_DELETE_FAILED = 'DATASTORE_DELETE_FAILED'

export const CLEAR_DATASET_UPDATE = 'CLEAR_DATASET_UPDATE'

// Reducer
export default function datastoreReducer(state = {}, action = {}) {
  switch (action.type) {
    case DATASTORE_LIST_FETCH_REQUESTED: {
      const newState = {
        ...state,
        datastoreListStatus: {
          status: 'requested',
        },
      }
      return newState
    }
    case DATASTORE_LIST_FETCH_SUCCEEDED: {
      const newState = {
        ...state,
        datastoreListStatus: action.payload,
      }
      return newState
    }
    case DATASTORE_LIST_FETCH_FAILED: {
      const newState = {
        ...state,
        datastoreListStatus: {
          status: 'failed',
          message: action.payload,
        },
      }
      return newState
    }
    case DATASTORE_TEST_REQUESTED: {
      const newState = {
        ...state,
        datastoreTestStatus: {
          status: 'requested',
        },
      }
      return newState
    }
    case DATASTORE_TEST_SUCCEEDED: {
      const newState = {
        ...state,
        datastoreTestStatus: action.payload,
      }
      return newState
    }
    case DATASTORE_TEST_FAILED: {
      const newState = {
        ...state,
        datastoreTestStatus: {
          status: 'failed',
          message: action.payload,
        },
      }
      return newState
    }
    case DATASTORE_TEST_CLEAR: {
      const newState = {
        ...state,
        datastoreTestStatus: null,
      }
      return newState
    }
    case DATASTORE_PUT_REQUESTED: {
      const newState = {
        ...state,
        datastoreUpdateStatus: {
          status: 'requested',
        },
      }
      return newState
    }
    case DATASTORE_PUT_SUCCEEDED: {
      const newState = {
        ...state,
        datastoreUpdateStatus: action.payload,
      }
      return newState
    }
    case DATASTORE_PUT_FAILED: {
      const newState = {
        ...state,
        datastoreUpdateStatus: {
          status: 'failed',
        },
      }
      return newState
    }
    case DATASTORE_POST_REQUESTED: {
      const newState = {
        ...state,
        datastoreUpdateStatus: {
          status: 'requested',
        },
      }
      return newState
    }
    case DATASTORE_POST_SUCCEEDED: {
      const newState = {
        ...state,
        datastoreUpdateStatus: action.payload,
      }
      return newState
    }
    case DATASTORE_POST_FAILED: {
      const newState = {
        ...state,
        datastoreUpdateStatus: {
          status: 'failed',
          message: action.payload,
        },
      }
      return newState
    }
    case CLEAR_DATASET_UPDATE: {
      const newState = {
        ...state,
        datastoreUpdateStatus: null,
      }
      return newState
    }
    case DATASTORE_DETAIL_REQUESTED: {
      const newState = {
        ...state,
        datastoreStatus: {
          status: 'requested',
        },
      }
      return newState
    }
    case DATASTORE_DETAIL_SUCCEEDED: {
      const newState = {
        ...state,
        datastoreStatus: action.payload,
      }
      return newState
    }
    case DATASTORE_DETAIL_FAILED: {
      const newState = {
        ...state,
        datastoreStatus: {
          status: 'failed',
        },
      }
      return newState
    }
    case DATASTORE_DETAIL_CLEAR: {
      const newState = {
        ...state,
        datastoreStatus: null,
      }
      return newState
    }
    case DATASTORE_DELETE_REQUESTED: {
      const newState = {
        ...state,
        datastoreDeleteStatus: {
          status: 'requested',
        },
      }
      return newState
    }
    case DATASTORE_DELETE_SUCCEEDED: {
      const newState = {
        ...state,
        datastoreDeleteStatus: action.payload,
      }
      return newState
    }
    case DATASTORE_DELETE_FAILED: {
      const newState = {
        ...state,
        datastoreDeleteStatus: {
          status: 'failed',
        },
      }
      return newState
    }
    default:
      return state
  }
}

// Actions

export function getDatastoreList(data) {
  return {
    type: DATASTORE_LIST_FETCH_REQUESTED,
    payload: data,
  }
}

export function createConnection(data) {
  return {
    type: DATASTORE_POST_REQUESTED,
    payload: data,
  }
}

export function editConnection(data) {
  return {
    type: DATASTORE_PUT_REQUESTED,
    payload: data,
  }
}

export function testConnection(data) {
  return {
    type: DATASTORE_TEST_REQUESTED,
    payload: data,
  }
}

export function getDatastore(data) {
  return {
    type: DATASTORE_DETAIL_REQUESTED,
    payload: data,
  }
}

export function clearTestDatastore() {
  return {
    type: DATASTORE_TEST_CLEAR,
  }
}

export function clearDatastoreDetail() {
  return {
    type: DATASTORE_DETAIL_CLEAR,
  }
}

export function clearDatastore() {
  return {
    type: CLEAR_DATASET_UPDATE,
  }
}

export function deleteDatastore(data) {
  return {
    type: DATASTORE_DELETE_REQUESTED,
    payload: data,
  }
}
// Sagas (services requests)
export function* fetchDatastoreList() {
  try {
    const dataStoreList = yield call(axios, {
      method: 'get',
      url: `${API_GATEWAY_URL}/bi_reporting_assets/v2/datastores?datastore_type=postgres`,
    })

    if (dataStoreList.error) {
      const e = dataStoreList.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }

    yield put({ type: DATASTORE_LIST_FETCH_SUCCEEDED, payload: dataStoreList })
  } catch (e) {
    yield put({ type: DATASTORE_LIST_FETCH_FAILED, payload: e.response.data })
  }
}

export function* testDatastoreConnection(action) {
  try {
    const testDatastore = yield call(axios, {
      method: 'post',
      url: `${API_GATEWAY_URL}/bi_reporting_assets/v2/datastores/testconnection`,
      data: action.payload,
    })

    if (testDatastore.error) {
      const e = testDatastore.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }

    yield put({ type: DATASTORE_TEST_SUCCEEDED, payload: testDatastore })
  } catch (e) {
    yield put({ type: DATASTORE_TEST_FAILED, payload: e.response.data })
  }
}

export function* putDatastoreConnection(action) {
  try {
    const putDatastore = yield call(axios, {
      method: 'put',
      url: `${API_GATEWAY_URL}/bi_reporting_assets/v2/datastores/${action.payload._id}`,
      data: action.payload,
    })

    if (putDatastore.error) {
      const e = putDatastore.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }

    yield put({ type: DATASTORE_PUT_SUCCEEDED, payload: putDatastore })
  } catch (e) {
    yield put({ type: DATASTORE_PUT_FAILED, payload: e.response.data.message })
  }
}

export function* postDatastoreConnection(action) {
  try {
    const postDatastore = yield call(axios, {
      method: 'post',
      url: `${API_GATEWAY_URL}/bi_reporting_assets/v2/datastores`,
      data: action.payload,
    })

    if (postDatastore.error) {
      const e = postDatastore.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }

    yield put({ type: DATASTORE_POST_SUCCEEDED, payload: postDatastore })
  } catch (error) {
    yield put({ type: DATASTORE_POST_FAILED, payload: error.response.data.message })
  }
}

export function* getDatastoreDetail(action) {
  try {
    const dataStoreDetail = yield call(axios, {
      method: 'get',
      url: `${API_GATEWAY_URL}/bi_reporting_assets/v2/datastores/${action.payload._id}`,
    })

    if (dataStoreDetail.error) {
      const e = dataStoreDetail.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }

    yield put({ type: DATASTORE_DETAIL_SUCCEEDED, payload: dataStoreDetail })
  } catch (e) {
    yield put({ type: DATASTORE_DETAIL_FAILED })
  }
}

export function* deleteDataStoreDetail(action) {
  try {
    const dataStoreDetail = yield call(axios, {
      method: 'delete',
      url: `${API_GATEWAY_URL}/bi_reporting_assets/v2/datastores/${action.payload._id}`,
    })

    if (dataStoreDetail.error) {
      const e = dataStoreDetail.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }

    yield put({ type: DATASTORE_DELETE_SUCCEEDED, payload: dataStoreDetail })
  } catch (e) {
    yield put({ type: DATASTORE_DELETE_FAILED })
  }
}
