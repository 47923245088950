import React from 'react'
import { Route } from 'react-router-dom'
import { Grid, Tabs, Tab, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import isEqual from 'lodash/isEqual'
import { createErrMsg } from 'greenfield-utilities'

import ConfirmDialog from '../../shared/Dialog/ConfirmDialog'
import { API_GATEWAY_URL } from '../../../ducks/utils'
import AdminSideBar from './Admin.sideBar'
import AdminDetail from './Admin.detail'
import AdminAddGroup from './Admin.addGroup'

import './admin.scss'

class Admin extends React.Component {
  state = {
    isAddingGroup: false,
    isEditingGroup: false,
    isDeletingGroup: false,
    isDeleteGroupSuccess: false,
    createdGroupId: null,
    adminDetailObj: {},
    lastDeletedGroupName: null,
    addGroupInFocus: false,
    isShowExternalOnly: false,
  }

  // this may go in in the future
  // this.topTabNames = ['People', 'Groups', 'Permissions']
  topTabNames = ['People', 'Groups']

  updateDetail = () => {
    this.setState({
      isAddingGroup: false,
    })
  }

  updateDeleteGroupSuccess = (stateName, value) => {
    this.setState({
      [stateName]: value,
    })
  }

  componentDidUpdate(prevProps) {
    const { adminDetail, deleteGroupStatus, routeProps } = this.props
    if (!isEqual(adminDetail, prevProps.adminDetail)) {
      this.setState({ adminDetailObj: adminDetail })
    }
    if (!isEqual(deleteGroupStatus, prevProps.deleteGroupStatus) && deleteGroupStatus.status === 204) {
      routeProps.history.push('/admin/groups')
      this.setState({
        isDeletingGroup: false,
        adminDetailObj: {},
        isDeleteGroupSuccess: true,
      })
      this.props.updateSidebarInfo(`${API_GATEWAY_URL}/bi_reporting_assets/v2/groups?page=1&per_page=100`)
    } else if (
      deleteGroupStatus &&
      deleteGroupStatus.status &&
      deleteGroupStatus.status !== 204 &&
      deleteGroupStatus.status !== 'requested'
    ) {
      this.props.displayServiceErrorMessage(
        createErrMsg(deleteGroupStatus, 'Something went wrong while getting your card(s).')
      )
    }
  }

  handleTabChange = (event, topTabValue) => {
    const { routeProps } = this.props
    this.props.sendFireflyEvent(false, {
      eventName: `admin${this.topTabNames[topTabValue]}TabClicked`,
    })
    routeProps.history.push(`/admin/${this.topTabNames[topTabValue].toLowerCase()}`)

    // Reset the adminDetailObj on tab change, Because same object is used to update people and group which should be different
    this.setState({
      adminDetailObj: {},
    })
  }

  handleAddGroupClick = () => {
    this.setState({
      isAddingGroup: true,
      isDeleteGroupSuccess: false,
    })
  }

  handleEditGroupClick = () => {
    this.setState({
      isEditingGroup: true,
    })
  }

  handleDeleteGroupClick = () => {
    this.props.sendFireflyEvent(false, {
      eventName: 'adminDeleteGroup',
    })
    this.setState({
      isDeletingGroup: true,
    })
  }

  handleDeleteGroup = () => {
    const { adminDetailObj } = this.state
    this.props.deleteGroup({ groupId: adminDetailObj.data._id })
    this.setState({ lastDeletedGroupName: adminDetailObj.data.group_name })
  }

  handleCloseDialog = () => {
    this.setState({ isDeletingGroup: false })
  }

  handleDisableAddingNewGroup = () => {
    this.setState(
      {
        isAddingGroup: false,
        isEditingGroup: false,
        addGroupInFocus: true,
      },
      () => {
        // This is being used as a psudo event. We just want to tell the app that the cancel button has been triggered and to put the focus on the "add group" button - 49754518/Art
        this.handleUpdateAddGroupFocus()
      }
    )
  }

  handleUpdateAddGroupFocus = () => {
    // used for accessibility, keeps track of variable that is used to keep track of focused element
    this.setState({
      addGroupInFocus: false,
    })
  }

  updateShowExternalOnly = () => {
    const { isShowExternalOnly } = this.state
    this.setState({ isShowExternalOnly: !isShowExternalOnly })
  }

  renderTopTabs(isAdminDetail, isAddingGroup) {
    const { routeProps, isMobile } = this.props

    return (
      <Tabs
        value={routeProps.match.params.mainTab === 'people' ? 0 : 1}
        onChange={this.handleTabChange}
        className={
          isMobile && (isAdminDetail || isAddingGroup) ? 'accent-color admin-mobile-view' : 'accent-color admin-tabs'
        }
        indicatorColor="primary"
        textColor="primary"
      >
        {this.topTabNames.map(tabName => (
          <Tab id={`admin-tab-${tabName}`} className={`admin-tab-${tabName}`} label={tabName} key={tabName} />
        ))}
      </Tabs>
    )
  }

  clearAdminDetail = topic => {
    const { routeProps } = this.props
    const defaultUrl = topic === 'people' ? '/admin/people' : '/admin/groups'
    routeProps.history.push(defaultUrl)

    this.setState({ adminDetailObj: {} })
  }

  handleDelOK = () => {
    this.setState({ isDeleteGroupSuccess: false })
  }

  render() {
    const topic = this.props.routeProps.match.url.split('/')[2]
    const { adminDetailObj, lastDeletedGroupName, isAddingGroup, isEditingGroup, isShowExternalOnly } = this.state
    const { isMobile } = this.props
    const isAdminDetail =
      (isMobile &&
        adminDetailObj &&
        adminDetailObj.status &&
        adminDetailObj.status >= 200 &&
        adminDetailObj.status < 300) ||
      false

    const getAdminDetail = () => (
      <AdminDetail
        topic={topic}
        adminDetail={this.state.adminDetailObj}
        addedGroups={this.props.addedGroups}
        addedMembers={this.props.addedMembers}
        routeProps={this.props.routeProps}
        fetchAdminDetail={this.props.fetchAdminDetail}
        fetchDefaultOwner={this.props.fetchDefaultOwner}
        defaultOwner={this.props.defaultOwner}
        sendDeleteDashboardFromGroup={this.props.sendDeleteDashboardFromGroup}
        fetchAutoSuggest={this.props.fetchAutoSuggest}
        autoSuggest={this.props.autoSuggest}
        groupList={this.props.groupList}
        fetchGrouplist={this.props.fetchGrouplist}
        isAddingGroup={this.state.isAddingGroup}
        addGroups={this.props.addGroups}
        removeGroups={this.props.removeGroups}
        deleteGroup={this.props.deleteGroup}
        addMember={this.props.addMember}
        deleteMember={this.props.deleteMember}
        addGroupProp={this.props.addGroupProp}
        removeGroupProp={this.props.removeGroupProp}
        addGroupPropInfo={this.props.addGroupPropInfo}
        removeGroupPropInfo={this.props.removeGroupPropInfo}
        updateSidebarInfo={this.props.updateSidebarInfo}
        displayServiceErrorMessage={this.props.displayServiceErrorMessage}
        createdGroupId={this.state.createdGroupId}
        onEditGroupClick={this.handleEditGroupClick}
        onDeleteGroupClick={this.handleDeleteGroupClick}
        getDetailsBusinessAreas={this.props.getDetailsBusinessAreas}
        detailsBusinessAreasStatus={this.props.detailsBusinessAreasStatus}
        saveUserBusinessTags={this.props.saveUserBusinessTags}
        saveUserQuickLinksInfoStatus={this.props.saveUserQuickLinksInfoStatus}
        saveGroupBusinessTags={this.props.saveGroupBusinessTags}
        saveGroupQuickLinksInfoStatus={this.props.saveGroupQuickLinksInfoStatus}
        clearSaveUserBusinessTagsInfo={this.props.clearSaveUserBusinessTagsInfo}
        clearSaveGroupBusinessTagsInfo={this.props.clearSaveGroupBusinessTagsInfo}
        userDashboardBusinessTagInfoStatus={this.props.userDashboardBusinessTagInfoStatus}
        getUserBusinessTags={this.props.getUserBusinessTags}
        isMobile={isMobile}
        clearAdminDetail={this.clearAdminDetail}
        sendFireflyEvent={this.props.sendFireflyEvent}
        addAdGroup={this.props.addAdGroup}
        addAdGroupStatus={this.props.addAdGroupStatus}
        userAccessStatus={this.props.userAccessStatus}
        getUserAccess={this.props.getUserAccess}
        currentUser={this.props.currentUser}
      />
    )

    return (
      <div className="page-admin">
        {this.renderTopTabs(isAdminDetail, isAddingGroup)}
        <div>
          <Grid container spacing={0}>
            {this.topTabNames.map(topTabName => (
              <Route
                key={topTabName}
                path={`/admin/${topTabName.toLowerCase()}/:_id?/:subGroup?`}
                render={routeProps => (
                  <AdminSideBar
                    topic={topic}
                    userDetails={this.props.userDetails}
                    sideBarListInfo={this.props.sideBarListInfo}
                    updateSidebarInfo={this.props.updateSidebarInfo}
                    routeProps={routeProps}
                    onAddGroupClick={this.handleAddGroupClick}
                    displayServiceErrorMessage={this.props.displayServiceErrorMessage}
                    updateDetail={this.updateDetail}
                    updateDeleteGroupSuccess={this.updateDeleteGroupSuccess}
                    addGroupInFocus={this.state.addGroupInFocus}
                    className={isAdminDetail || isAddingGroup ? 'admin-mobile-view' : null}
                    isShowExternalOnly={isShowExternalOnly}
                    updateShowExternalOnly={this.updateShowExternalOnly}
                    isMobile={isMobile}
                    sendFireflyEvent={this.props.sendFireflyEvent}
                  />
                )}
              />
            ))}
            {isAddingGroup || isEditingGroup ? (
              <AdminAddGroup
                isEditGroup={this.state.isEditingGroup}
                adminDetail={this.props.adminDetail}
                addedMembers={this.props.addedMembers}
                routeProps={this.props.routeProps}
                fetchDefaultOwner={this.props.fetchDefaultOwner}
                defaultOwner={this.props.defaultOwner}
                autoSuggest={this.props.autoSuggest}
                fetchAutoSuggest={this.props.fetchAutoSuggest}
                createNewGroup={this.props.createNewGroup}
                createNewGroupStatus={this.props.createNewGroupStatus}
                editGroupStatus={this.props.editGroupStatus}
                editGroup={this.props.editGroup}
                onDisableAddingNewGroup={this.handleDisableAddingNewGroup}
                updateSidebarInfo={this.props.updateSidebarInfo}
                updateDeleteGroupSuccess={this.updateDeleteGroupSuccess}
                isMobile={isMobile}
                sendFireflyEvent={this.props.sendFireflyEvent}
              />
            ) : this.state.isDeleteGroupSuccess ? (
              !isMobile ? (
                <Typography variant="body2">{lastDeletedGroupName} Group deleted Successfully</Typography>
              ) : (
                <ConfirmDialog
                  isAlert
                  open={this.state.isDeleteGroupSuccess}
                  contentText={`${lastDeletedGroupName} Group deleted Successfully.`}
                  okText="OK"
                  onCloseDialog={this.handleDelOK}
                  onClickOk={this.handleDelOK}
                />
              )
            ) : (
              getAdminDetail()
            )}
          </Grid>
          {this.state.isDeletingGroup && (
            <ConfirmDialog
              open={this.state.isDeletingGroup}
              contentText={`Do you wish to continue deleting the Group '${adminDetailObj.data.group_name}' ?`}
              okText="Yes, Delete Group"
              onCloseDialog={this.handleCloseDialog}
              onClickOk={this.handleDeleteGroup}
              onClickCancel={this.handleCloseDialog}
              cancelText="No, Keep Going"
              adminDetailObj={adminDetailObj}
              addedMembers={this.props.addedMembers}
            />
          )}
        </div>
      </div>
    )
  }
}

Admin.defaultProps = {
  updateSidebarInfo: () => {},
  sideBarListInfo: {},
  adminDetail: {},
  addedGroups: {},
  addedMembers: {},
  fetchAdminDetail: () => {},
  defaultOwner: {},
  deletedDashboards: {},
  fetchDefaultOwner: () => {},
  autoSuggest: {},
  fetchAutoSuggest: () => {},
  groupList: {},
  createNewGroupStatus: {},
  editGroupStatus: {},
  fetchGrouplist: () => {},
  addGroups: () => {},
  removeGroups: () => {},
  deleteGroup: () => {},
  addMember: () => {},
  deleteMember: () => {},
  addGroupProp: () => {},
  removeGroupProp: () => {},
  addGroupPropInfo: {},
  removeGroupPropInfo: {},
  createNewGroup: () => {},
  editGroup: () => {},
  routeProps: {},
  isMobile: false,
  displayServiceErrorMessage: () => {},
  getDetailsBusinessAreas: () => {},
  detailsBusinessAreasStatus: {},
}

Admin.propTypes = {
  displayServiceErrorMessage: PropTypes.func,
  updateSidebarInfo: PropTypes.func,
  sideBarListInfo: PropTypes.object,
  adminDetail: PropTypes.object,
  addedGroups: PropTypes.object,
  addedMembers: PropTypes.object,
  fetchAdminDetail: PropTypes.func,
  defaultOwner: PropTypes.object,
  deletedDashboards: PropTypes.object,
  fetchDefaultOwner: PropTypes.func,
  autoSuggest: PropTypes.object,
  fetchAutoSuggest: PropTypes.func,
  groupList: PropTypes.object,
  createNewGroupStatus: PropTypes.object,
  editGroupStatus: PropTypes.object,
  fetchGrouplist: PropTypes.func,
  addGroups: PropTypes.func,
  removeGroups: PropTypes.func,
  deleteGroup: PropTypes.func,
  addMember: PropTypes.func,
  deleteMember: PropTypes.func,
  addGroupProp: PropTypes.func,
  removeGroupProp: PropTypes.func,
  addGroupPropInfo: PropTypes.object,
  removeGroupPropInfo: PropTypes.object,
  createNewGroup: PropTypes.func,
  editGroup: PropTypes.func,
  routeProps: PropTypes.object,
  isMobile: PropTypes.bool,
  getDetailsBusinessAreas: PropTypes.func,
  detailsBusinessAreasStatus: PropTypes.object,
}

export default Admin
