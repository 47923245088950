import React from 'react'
import { Link } from 'react-router-dom'
import { Box, Typography, Card, CardContent, Grid } from '@mui/material'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView'
import { getOwnerInfoChip } from '../../shared/AgGrid/AgGridHelper'

const SCard = styled(Card)`
  max-height: 220px;
  overflow: auto;
`

const CardDashboardHelpContent = ({ isCard, isSite }) => {
  const contentTitle = isCard ? 'Card' : 'Dashboard'
  const assetInfo = isCard
    ? useSelector(state => state.cards.cardInfoStatus)
    : useSelector(state => state.dashboard.viewDashBoardInfoStatus)

  const data = assetInfo?.data

  const { cardsDatasetStatus } = useSelector(state => state.builder)

  if (!data) {
    return null
  }

  return (
    <Grid container direction="column">
      <Grid item>
        <Box m={1}>
          <Typography data-cy="help-content-title" variant="subtitle1" color="primary">
            {`${isSite ? 'Site' : contentTitle} Support Info`}
          </Typography>
          <SCard variant="outlined">
            <CardContent>
              <FroalaEditorView model={data.card_attribute?.support_details?.text || data.support_details?.text} />
            </CardContent>
          </SCard>
        </Box>
      </Grid>
      <Grid item>
        <Box m={1}>
          <Typography variant="subtitle1" color="primary">
            {isCard
              ? 'Have questions about the results you see or want to request changes to the card? Please reach out to the card owners'
              : 'Have questions about the results you see? Please reach out to the dashboard owners'}
          </Typography>
          <Grid container>
            <GridItem
              owners={data.card_attribute?.owners_access || data?.owners_access}
              title={`${contentTitle} Owner(s)`}
            />
            {isCard && (
              <GridItem
                owners={cardsDatasetStatus[data.card_attribute?.dataset_id]?.data?.owners_access}
                title="Dataset Owner(s)"
              />
            )}
            {isCard && <GridItem isLink title="Dataset" data={data} cardsDatasetStatus={cardsDatasetStatus} />}
          </Grid>
        </Box>
      </Grid>
    </Grid>
  )
}

export default CardDashboardHelpContent

export const GridItem = ({ title, owners, isLink, data, cardsDatasetStatus }) => (
  <Grid item xs={12}>
    <Card variant="outlined">
      <CardContent>
        <Typography variant="subtitle2" style={{ wordBreak: 'break-all' }}>
          {title}
        </Typography>
        {isLink ? (
          <DatasetLink data={data} cardsDatasetStatus={cardsDatasetStatus} />
        ) : (
          <Typography data-cy={title}>{getOwnerInfoChip(owners)}</Typography>
        )}
      </CardContent>
    </Card>
  </Grid>
)

export const DatasetLink = ({ data, cardsDatasetStatus }) => {
  const datasetId = data.card_attribute?.dataset_id
  if (datasetId) {
    return (
      cardsDatasetStatus && (
        <Link data-cy="dataset-link" to={`/dataset/preview/${data.card_attribute?.dataset_id}`} target="_blank">
          {cardsDatasetStatus && cardsDatasetStatus[datasetId]?.data?.dataset_business_name}
        </Link>
      )
    )
  }
  return null
}
