import React from 'react'
import PropTypes from 'prop-types'
import { TextField, Checkbox } from '@mui/material'
import { API_GATEWAY_URL } from '../../../ducks/utils'

class AdminSideBarSearch extends React.Component {
  state = {
    searchValue: '',
  }

  handleSearchChange = (searchValue, isShowExternalOnly) => {
    let { topic } = this.props
    const isGroups = topic === 'groups'
    const userType = isShowExternalOnly ? 'external' : ''

    if (searchValue.length > 2) {
      topic = isGroups ? topic : 'users'
      this.props.updateSidebarInfo(
        `${API_GATEWAY_URL}/bi_reporting_assets/v2/${topic}?search_string=${searchValue}&user_type=${userType}`
      )
    } else if (searchValue.length === 0) {
      topic = isGroups ? 'groups' : 'users'
      const userId = topic === 'users' ? `&_id=${this.props.userDetails.lanId.toLowerCase()}` : ''
      this.props.updateSidebarInfo(
        `${API_GATEWAY_URL}/bi_reporting_assets/v2/${topic}?field_groups=name_only&page=1&per_page=100&user_type=${userType}${userId}`
      )
    }

    this.setState({
      searchValue,
    })
  }

  componentDidUpdate(prevProps) {
    const { isShowExternalOnly } = this.props
    const { searchValue } = this.state
    if (prevProps.isShowExternalOnly !== isShowExternalOnly) {
      this.handleSearchChange(searchValue, isShowExternalOnly)
    }
  }

  render() {
    const { topic, isShowExternalOnly, updateShowExternalOnly } = this.props
    return (
      <>
        <TextField
          id="admin-search-people"
          className="search-people"
          label={`Search ${topic}`}
          autoComplete="off"
          margin="normal"
          variant="standard"
          onChange={e => {
            this.handleSearchChange(e.target.value, isShowExternalOnly)
          }}
          value={this.state.searchValue}
        />

        <div className="external-group-checkbox">
          <label htmlFor="adminShowExternalAccess">
            <Checkbox
              aria-label="Show external groups"
              checked={isShowExternalOnly}
              onChange={() => {
                updateShowExternalOnly()
              }}
              value="Show External groups only"
              id="showExternalGrpsOnly"
            />
            Show External only
          </label>
        </div>
      </>
    )
  }
}

AdminSideBarSearch.defaultProps = {
  topic: '',
  updateSidebarInfo: () => {},
}

AdminSideBarSearch.propTypes = {
  topic: PropTypes.string,
  updateSidebarInfo: PropTypes.func,
}

export default AdminSideBarSearch
