import React, { useEffect } from 'react'
import DatastoreListTable from './DatastoreListTable'

const DatastoreListContainer = ({ setHeaderTitle = () => {}, isMobile = false, auth = {} }) => {
  useEffect(() => {
    setHeaderTitle('Datastore')
  }, [])
  return <DatastoreListTable isMobile={isMobile} defaultUser={auth?.session?.userInfo} />
}

export default DatastoreListContainer
