import React from 'react'
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import styled from 'styled-components'
import { Info } from '@mui/icons-material'
import moment from 'moment'
import DatePickerComponent from '../../../../shared/Calendar/DatePickerComponent'
import { getFormatDate } from '../../../../shared/util'
import GFAutocomplete from '../../../../shared/MUIAutoComplete/Autocomplete'
import { Item } from './EmailStep'
import { DATA_ALERT, CARD_SUBSCRIPTION } from './DataAlertSubscriptionDialog'

const STypography = styled(Typography)`
  font-weight: 500;
  margin-bottom: 1rem;
`

const DataAlertSubscriptionDetails = ({
  title,
  setTitle,
  description,
  setDescription,
  frequency,
  setFrequency,
  ownersList,
  setOwnersList,
  label,
  checked,
  setChecked,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
}) => {
  const handleAlertFrequencyChange = event => {
    setFrequency(event.target.value)
  }

  const customRenderInput = ({ inputRef, inputProps, InputProps }, label) => (
    <TextField
      sx={{
        width: 350,
        '& .gf-MuiInputBase-input.Mui-disabled': {
          WebkitTextFillColor: 'black',
        },
      }}
      label={label}
      ref={inputRef}
      {...inputProps}
      disabled
      InputProps={{
        endAdornment: InputProps?.endAdornment,
      }}
    />
  )

  return (
    <Box sx={{ m: 1 }}>
      <Grid container direction="column" spacing={2} columns={1}>
        <Grid item>
          <Item>
            <STypography variant="subtitle1">
              {label === DATA_ALERT ? 'Data Alert Details' : 'Subscription Details'}
            </STypography>
            <TextField
              fullWidth
              variant="outlined"
              label="Title"
              data-cy="data-alert-subscription-title"
              value={title}
              error={title.length === 0}
              onChange={event => setTitle(event.target.value)}
            />
          </Item>
        </Grid>
        <Grid item xs={12}>
          <Item>
            <TextField
              fullWidth
              multiline
              variant="outlined"
              label="Description"
              value={description}
              onChange={event => setDescription(event.target.value)}
            />
          </Item>
        </Grid>
        <Grid item>
          <Item>
            <Stack direction="row" alignItems="center" gap={1} justifyContent="flex-start" sx={{ mb: 1 }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                {label === CARD_SUBSCRIPTION ? 'Subscription Timeframe' : 'Data Alert Timeframe'}
              </Typography>
              <Tooltip title="Start and End Period of alert/subscription">
                <IconButton>
                  <Info />
                </IconButton>
              </Tooltip>
            </Stack>
            <Grid container justifyContent="space-between">
              <DatePickerComponent
                id="start-date"
                label="Begin Period Date"
                ariaLabel="Begin Period Date"
                minDate={getFormatDate(moment())}
                maxDate={getFormatDate(moment(endDate).subtract(1, 'days'))}
                handleOnChange={newStartDate => setStartDate(moment(newStartDate).format('YYYY-MM-DD'))}
                customRenderInput={props => customRenderInput(props, 'Begin Period Date')}
                value={startDate}
              />
              <DatePickerComponent
                id="end-date"
                label="End Period Date"
                ariaLabel="End Period Date"
                minDate={moment(startDate).add(1, 'd')}
                maxDate={moment().add(180, 'd')}
                handleOnChange={newEndDate => setEndDate(moment(newEndDate).format('YYYY-MM-DD'))}
                customRenderInput={props => customRenderInput(props, 'End Period Date')}
                value={endDate}
              />
            </Grid>
          </Item>
        </Grid>

        <Grid item>
          <Item>
            <STypography variant="subtitle1">Frequency</STypography>
            <TextField
              select
              variant="outlined"
              fullWidth
              label="Frequency"
              id="frequency"
              value={frequency}
              onChange={handleAlertFrequencyChange}
              helperText={label === DATA_ALERT && 'When data changes, send alerts at most daily or weekly'}
            >
              <MenuItem value="DAILY">Daily</MenuItem>
              <MenuItem value="WEEKLY">Weekly</MenuItem>
            </TextField>
          </Item>
        </Grid>
        {label === CARD_SUBSCRIPTION && (
          <>
            <Grid item>
              <Item>
                <FormGroup>
                  <FormControlLabel
                    disabled
                    control={<Checkbox checked={checked} onChange={event => setChecked(event.target.checked)} />}
                    label={<Typography variant="subtitle1">Run Subscription when Data Changes</Typography>}
                  />
                </FormGroup>
              </Item>
            </Grid>
            <Grid item>
              <Item>
                <STypography variant="subtitle1">Notification Method</STypography>
                <TextField select value="email" disabled fullWidth>
                  <MenuItem value="email">Email</MenuItem>
                </TextField>
              </Item>
            </Grid>
          </>
        )}

        <Grid item>
          <Item>
            <STypography variant="subtitle1">Add Owner(s)</STypography>
            <GFAutocomplete label="Owner(s)" value={ownersList} setValue={setOwnersList} id="add-owners" />
          </Item>
        </Grid>
      </Grid>
    </Box>
  )
}

export default DataAlertSubscriptionDetails
