import React, { useState, useEffect } from 'react'
import { Button, Typography, Grid, Toolbar, MenuItem, TextField } from '@mui/material'
import { Add } from '@mui/icons-material'
import { useDispatch, useSelector } from 'react-redux'
import { PAGE_SIZE_OPTIONS } from '../../shared/AssetTableSS/TableFilter'
import { AgGridTable } from '../../shared/AgGrid/AgGridTableClientSide'
import { clearDatastore, clearDatastoreDetail, clearTestDatastore, getDatastoreList } from '../../../ducks/datastore'
import AdvanceFilter from '../../shared/AdvanceFilter/AdvanceFilter'
import { advanceFilter } from '../../shared/util'
import DatastoreDetails from './DatastoreDetails'
import './DataStore.scss'
import { ColumnDefs } from './ColumnDefs'

const DatastoreListTable = ({ isMobile, defaultUser }) => {
  const dispatch = useDispatch()
  const [columns, setColumns] = useState([])
  const [error, setError] = useState('')
  const [pageSize, setPageSize] = useState(10)
  const [openDialog, setOpenDialog] = useState(false)
  const [connectionDetails, setConnectionDetails] = useState(null)
  const { datastoreListStatus, datastoreUpdateStatus } = useSelector(state => state.datastore)
  const [searchText, setSearchText] = useState('')
  const [datastoreList, setDatastoreList] = useState([])

  const defaultOwnersAccess = [
    {
      user_group_name: `${defaultUser?.firstName} ${defaultUser?.lastName}`,
      _id: defaultUser?.lanId?.toLowerCase(),
      type: 'user',
    },
  ]

  useEffect(() => {
    if (datastoreListStatus?.data) {
      setDatastoreList(datastoreListStatus?.data)
    }
  }, [datastoreListStatus])

  useEffect(() => {
    setColumns(ColumnDefs(isMobile, error))
  }, [isMobile, pageSize])

  useEffect(() => {
    if (datastoreUpdateStatus?.status >= 200 && datastoreUpdateStatus?.status < 300) {
      dispatch(getDatastoreList())
      handleDialogClose()
    } else if (datastoreUpdateStatus?.status === 'failed') {
      setError(datastoreUpdateStatus?.message || 'Something went wrong, please try again!')
    }
  }, [datastoreUpdateStatus])

  const onGridReady = () => {
    dispatch(getDatastoreList())
  }

  const handleDialogOpen = () => {
    setConnectionDetails({ owners_access: defaultOwnersAccess, port: 5432 })
    setOpenDialog(true)
  }

  const handleDialogClose = () => {
    dispatch(clearTestDatastore())
    dispatch(clearDatastore())
    dispatch(clearDatastoreDetail())
    setOpenDialog(false)
    setError('')
  }

  const handlePageSizeChange = event => {
    setPageSize(event.target.value)
  }

  const handleDatastoreSearchChange = event => {
    setSearchText(event.target.value)
  }

  const searchDatastore = () => {
    const dList = datastoreListStatus?.data || []
    const filteredData = advanceFilter(searchText, dList, ['datastore_name', 'dbname', 'owners', 'host'])

    setDatastoreList(filteredData)
  }

  const handleOnSearchKeyDown = e => {
    if (e.key === 'Enter') {
      searchDatastore()
    }
  }

  return (
    <>
      <Toolbar classes={{ root: 'toolbar' }}>
        <Typography classes={{ root: 'toolbar-title' }}>POSTGRES DATASTORES</Typography>
      </Toolbar>
      <div id="sites">
        <Grid container justifyContent="space-between">
          <Grid item>
            <AdvanceFilter
              isSmall
              className="search-datastore"
              label="Search Datastore"
              onSearchChange={handleDatastoreSearchChange}
              onSearchClick={searchDatastore}
              onKeyDown={handleOnSearchKeyDown}
              searchValue={searchText}
              data-cy="datastore-list-search"
            />
          </Grid>
          <Grid item>
            <Grid container alignItems="center" justifyContent="flex-end">
              {!isMobile && (
                <Button onClick={handleDialogOpen} startIcon={<Add />} color="secondary" id="new-datastore">
                  Add New Connection
                </Button>
              )}
              <TextField
                select
                size="small"
                variant="outlined"
                id="select-rows"
                value={pageSize}
                className="select-rows"
                onChange={handlePageSizeChange}
              >
                {PAGE_SIZE_OPTIONS.map(option => (
                  <MenuItem key={option} value={option} className="dropdown-menu-item">
                    {option} Rows
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </Grid>
        <Typography
          variant="subtitle1"
          id="total-pages"
          aria-label="Displaying records"
          classes={{ root: 'total-records' }}
          data-cy="display-record-count"
        />
        <AgGridTable
          onGridReady={onGridReady}
          columns={columns}
          pageSize={pageSize}
          data={datastoreList}
          isMobile={isMobile}
        />
      </div>
      <DatastoreDetails
        open={openDialog}
        error={error}
        handleDialogClose={handleDialogClose}
        details={connectionDetails}
        title="New Postgres Connection"
      />
    </>
  )
}

export default DatastoreListTable
