import React, { useState } from 'react'
import axios from 'axios'
import { Typography, Grid, ToggleButtonGroup, ToggleButton, Stack, Paper, Chip, Skeleton } from '@mui/material'
import { Dashboard, Functions, PermDataSetting, WebAssetOutlined } from '@mui/icons-material'
import { useDispatch, useSelector } from 'react-redux'
import { fetchUserType } from '../../../ducks/user'
import { API_GATEWAY_URL } from '../../../ducks/utils'
import ContentTable from './ContentTable'

export const GridFiltersWithData = ({
  dashboards = [],
  cards = [],
  datasets = [],
  calculatedFields = [],
  title,
  isFavorite = false,
  dashboardsCount,
  cardsCount,
  datasetsCount,
  calcFieldsCount,
  isLoading = false,
}) => {
  const dispatch = useDispatch()
  const { userType } = useSelector(state => state.user)
  const [selectedType, setSelectedType] = useState('Cards')
  const [selectedLabelIds, setSelectedLabelIds] = useState(null)
  const currentUser = useSelector(state => state.user?.userType)
  const [isActive, setIsActive] = useState('')

  const defaultTypes = [
    { name: 'Datasets', icon: <PermDataSetting />, count: datasetsCount },
    { name: 'Cards', icon: <WebAssetOutlined size="large" />, count: cardsCount },
    { name: 'Dashboards', icon: <Dashboard />, count: dashboardsCount },
  ]
  let types = []
  if (title === 'My Favorites') {
    types = defaultTypes
  } else if (title === 'Owned Items') {
    types = [...defaultTypes, { name: 'Calculated Fields', icon: <Functions />, count: calcFieldsCount }]
  }
  const labels = currentUser?.data?.labels

  const handleLabelClick = (label, assetType) => {
    assetType = assetType.toLowerCase() // This line is needed for search to work at the moment.
    if (label === isActive) {
      setSelectedLabelIds(null)
      setIsActive('')
    } else {
      const assetIds = labels && labels[label][assetType]
      setSelectedLabelIds(assetIds)
      setIsActive(label)
    }
  }

  const handleLabelDelete = label => {
    const userLabels = userType?.data?.labels
    if (userLabels) {
      if (label === isActive) {
        setSelectedLabelIds(null)
        setIsActive('')
      }
      const deleteLabel = label
      // eslint-disable-next-line no-unused-vars
      const { [deleteLabel]: remove, ...rest } = userLabels
      axios({
        method: 'patch',
        url: `${API_GATEWAY_URL}/bi_reporting_assets/v2/users/current_user`,
        data: {
          labels: {
            ...rest,
          },
        },
      }).then(() => {
        dispatch(fetchUserType())
      })
    }
  }

  return (
    <Paper elevation={0} style={{ paddingTop: 10 }}>
      {labels && (
        <Stack m={1} direction="row" alignItems="center" spacing={1}>
          <Typography display="inline" variant="subtitle1" aria-label="Labels">
            Labels:{' '}
          </Typography>
          {Object.keys(labels).map(label => (
            <Chip
              key={label}
              label={label}
              variant="outlined"
              id={`label-${label}`}
              className={isActive === label ? 'chip-business' : ''}
              onClick={() => handleLabelClick(label, selectedType)}
              onDelete={() => handleLabelDelete(label)}
              data-cy={`label-${label}`}
            />
          ))}
        </Stack>
      )}
      <Grid container direction="column">
        <Grid item xs>
          <Stack direction="row" spacing={4} justifyContent="center" alignItems="center">
            <ToggleButtonGroup
              value={selectedType}
              exclusive
              onChange={(event, newType) => {
                if (newType !== null) {
                  setSelectedType(prevType => (prevType === newType ? '' : newType))
                }
              }}
              aria-label="toggle button group"
            >
              {types.map((type, index) => (
                <ToggleButton
                  key={index + type.name}
                  value={type.name}
                  style={
                    selectedType === type.name
                      ? {
                          height: 120,
                          width: 250,
                          borderRadius: 0,
                          border: '2px solid #51a255',
                        }
                      : {
                          height: 120,
                          width: 250,
                          borderRadius: 0,
                        }
                  }
                >
                  <Typography
                    style={{
                      textAlign: 'center',
                    }}
                    component="span"
                    data-cy={`${isFavorite ? 'favorite-' : ''}${type.name.replace(/\s+/g, '-').toLowerCase()}-filter`}
                  >
                    {type.icon}
                    <Typography
                      variant="h6"
                      style={{
                        color: '#366CD9',
                      }}
                    >
                      {type.name}
                    </Typography>
                    {isLoading && type.name === 'Cards' ? (
                      <Skeleton />
                    ) : (
                      <Typography variant="subtitle1">({type.count})</Typography>
                    )}
                  </Typography>
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          </Stack>
        </Grid>
        <Grid item xs>
          {selectedType === 'Cards' && (
            <ContentTable
              ownerOnly
              chipInfo={cards}
              chipTitle="Card"
              isFavorite={isFavorite}
              assetIds={selectedLabelIds}
            />
          )}
          {selectedType === 'Dashboards' && (
            <ContentTable
              ownerOnly
              chipInfo={dashboards}
              chipTitle="Dashboard"
              isFavorite={isFavorite}
              assetIds={selectedLabelIds}
            />
          )}
          {selectedType === 'Datasets' && (
            <ContentTable
              ownerOnly
              chipInfo={datasets}
              chipTitle="Dataset"
              isFavorite={isFavorite}
              assetIds={selectedLabelIds}
            />
          )}
          {selectedType === 'Calculated Fields' && (
            <ContentTable ownerOnly chipInfo={calculatedFields} chipTitle="Calculated Field" />
          )}
        </Grid>
      </Grid>
    </Paper>
  )
}
