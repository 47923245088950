import React, { useState } from 'react'
import moment from 'moment'
import startCase from 'lodash/startCase'
import { Link } from 'react-router-dom'
import axios from 'axios'

import { SecurityRenderer } from '../../routes/Datasets/DatasetCellRenderer/DatasetCellRenderers'
import { isCertifiedDataset } from '../util'
import { CertifiedIcon } from '../Svgicon/Svgicon'
import { OwnerChipRenderer, TagChipRenderer } from '../AgGrid/AgGridHelper'
import { GreenfieldFavoriteButton } from '../shared'
import { API_GATEWAY_URL } from '../../../ducks/utils'
import { AssetType, isSite } from './AssetTable'
import { ActionRenderer } from './ActionRenderer'
import { DetailButtonRenderer } from './DetailButtonRenderer'

const getColumnDefinitions = props => {
  const {
    isMobile,
    siteURL,
    siteURLInfo,
    statusType,
    getColumnFilters,
    assetType,
    ownerOnly,
    datasetPreviewStatus,
    setHomePageId,
    userOrGroupId,
    favoriteOnly,
  } = props
  const isCardURL = !siteURL || (siteURLInfo && siteURLInfo[3] === 'cards')
  const handleToggleDetail = (api, rowNode) => {
    rowNode.expanded ? rowNode.setExpanded(false) : rowNode.setExpanded(true)
    api.refreshCells()
  }

  const getFilterParams = ({ api, setFilterData, columnName }) => {
    return {
      ...defaultFilterParams,
      values: params => {
        return setFilterData(filterData => {
          getColumnFilters({ api, columnName, filterData, params })
          return filterData
        })
      },
      ...(columnName === 'owners' && { valueFormatter: params => params?.value?.split(',')[2] }),
    }
  }

  return [
    ...(assetType !== AssetType.CALC_FIELDS
      ? [
          {
            field: '_id',
            headerName: '',
            maxWidth: 50,
            cellRenderer: 'agGroupCellRenderer',
            suppressColumnsToolPanel: true,
            valueFormatter: () => '',
            suppressMenu: true,
            hide: isMobile,
            sortable: false,
          },
        ]
      : []),
    ...(assetType === AssetType.DASHBOARDS
      ? [
          {
            field: '',
            headerName: 'Home Page',
            maxWidth: 120,
            suppressMenu: true,
            cellRendererFramework: ({ data }) => {
              const [disabled, setDisabled] = useState(false)
              const handleHomePageClicked = selectedId => {
                setDisabled(true)
                // if home dashboard is unchecked, it sets the default home dashboard to 2767
                setHomePageId(prevHomePageId => {
                  if (prevHomePageId === selectedId) {
                    document.getElementById(data._id).checked = false
                  }
                  const params = {
                    personalization: {
                      home:
                        prevHomePageId !== selectedId
                          ? {
                              type: 'dashboard',
                              id: selectedId,
                            }
                          : {},
                    },
                  }
                  axios
                    .put(`${API_GATEWAY_URL}/bi_reporting_assets/v2/users/current_user`, params)
                    .then(() => axios.get(`${API_GATEWAY_URL}/bi_reporting_assets/v2/users/current_user`))
                    .then(resp => {
                      setHomePageId(resp.data.personalization.home.id)
                      setDisabled(false)
                    })
                    // eslint-disable-next-line no-console
                    .catch(err => console.warn(err))
                  return prevHomePageId
                })
              }

              return (
                <input
                  style={{ height: 17, width: 17 }}
                  id={data._id}
                  name="selected"
                  type="radio"
                  disabled={disabled}
                  defaultChecked={data.is_home_page}
                  onClick={() => handleHomePageClicked(data._id, data.is_home_page)}
                />
              )
            },
          },
        ]
      : []),
    ...(assetType !== AssetType.CALC_FIELDS
      ? [
          {
            field: assetType === AssetType.CARDS ? 'card_attribute.favorite' : 'favorite',
            headerName: 'Favorite',
            maxWidth: 120,
            cellRendererFramework: ({ data, node, value }) => (
              <GreenfieldFavoriteButton type={assetType} id={data?._id} elementId={node.rowIndex} isFavorite={value} />
            ),
            suppressMenu: true,
          },
        ]
      : []),
    ...(assetType !== AssetType.CALC_FIELDS
      ? [
          {
            field:
              assetType === AssetType.CARDS
                ? 'card_attribute.card_title'
                : assetType === AssetType.DASHBOARDS
                ? 'dashboard_title'
                : assetType === AssetType.DATASETS
                ? 'dataset_business_name'
                : '',
            headerName:
              assetType === AssetType.CARDS
                ? 'Card Name'
                : assetType === AssetType.DASHBOARDS
                ? 'Dashboard Name'
                : assetType === AssetType.DATASETS
                ? 'Dataset Name'
                : '',
            minWidth: isMobile ? 200 : 400,
            wrapText: false,
            cellRendererFramework: ({ data, value }) => (
              <Link
                id={`open-asset-link-${data?._id}`}
                style={{ textDecoration: 'none', color: '#2278cf' }}
                data-cy={`open-asset-${value}`}
                aria-label={`${value} ${isCertifiedDataset(data) ? 'is certified dataset' : ''}`}
                to={
                  assetType === AssetType.CARDS
                    ? isSite && siteURL
                      ? `${siteURL}/${data?.card_attribute?.viz_type === 'text' ? 'textcard' : 'card'}/${data?._id}`
                      : `/${data?.card_attribute?.viz_type === 'text' ? 'textcard' : 'card'}/${data?._id}`
                    : assetType === AssetType.DASHBOARDS
                    ? isSite && siteURL
                      ? `${siteURL}/dashboard/${data._id}`
                      : `/dashboard/${data._id}`
                    : assetType === AssetType.DATASETS
                    ? isSite && siteURL
                      ? `${siteURL}/dataset/preview/${data._id}`
                      : `/dataset/preview/${data._id}`
                    : ''
                }
              >
                {value?.trim()}
              </Link>
            ),
          },
        ]
      : []),
    ...(assetType === AssetType.CALC_FIELDS
      ? [
          {
            field: 'column_display_name',
            headerName: 'Calculated Field Name',
            minWidth: 400,
            valueGetter: ({ data }) =>
              data.column_display_name === data.column_name
                ? data.column_display_name
                : `${data.column_display_name} (${data.column_name})`,
          },
          {
            field: 'exprQL',
            headerName: 'Expression',
          },
          {
            field: 'dataset_id',
            headerName: 'Dataset',
            cellRendererFramework: ({ value }) => <Link to={`/dataset/preview/${value}`}>{value}</Link>,
            hide: !ownerOnly,
          },
        ]
      : []),
    ...(userOrGroupId
      ? [
          {
            field: '_id',
            headerName: 'Access',
            hide: isMobile || userOrGroupId,
            sortable: false,
            cellRendererFramework: ({ data }) => {
              const ownerAccess = data?.card_attribute?.owners_access || data?.owners_access
              const ownerExistsIndex = ownerAccess.findIndex(owner => owner._id === userOrGroupId)
              return ownerExistsIndex > -1 ? 'Owner' : 'Viewer'
            },
          },
        ]
      : []),
    ...(assetType === AssetType.CARDS
      ? [
          {
            field: 'card_attribute.viz_type',
            headerName: 'Viz Type',
            hide: isMobile,
            filter: 'agSetColumnFilter',
            filterParams: ({ api }) => getFilterParams({ ...props, columnName: 'viz_type', api }),
            valueFormatter: ({ data }) => startCase(data?.card_attribute?.viz_type),
          },
        ]
      : []),
    ...(assetType === AssetType.DATASETS
      ? [
          {
            field: 'certified',
            headerName: 'Certified',
            maxWidth: 100,
            cellRendererFramework: ({ data }) => (data.certified === 'yes' ? <CertifiedIcon /> : null),
            suppressMenu: true,
          },
          {
            field: 'rls_enabled',
            headerName: 'Security',
            maxWidth: 125,
            cellRendererFramework: SecurityRenderer,
          },
        ]
      : []),
    ...(assetType !== AssetType.CALC_FIELDS
      ? [
          {
            field:
              assetType === AssetType.CARDS
                ? 'card_attribute.business_use'
                : assetType === AssetType.DASHBOARDS
                ? 'business_area'
                : assetType === AssetType.DATASETS
                ? 'business_use'
                : '',
            headerName: 'Business Area',
            hide: isMobile,
            filter: assetType === AssetType.CARDS ? isCardURL : 'agSetColumnFilter',
            filterParams: ({ api }) => getFilterParams({ ...props, columnName: 'tags', api }),
            cellRendererFramework: TagChipRenderer,
            cellRendererParams: {
              handleToggleDetail,
              className: 'chip-business',
            },
          },
        ]
      : []),
    {
      field: assetType === AssetType.CARDS ? 'card_attribute.owners' : 'owners',
      headerName: 'Owners',
      hide: isMobile,
      filter: true,
      filterParams: ({ api }) => getFilterParams({ ...props, columnName: 'owners', api }),
      cellRendererFramework: OwnerChipRenderer,
      cellRendererParams: {
        handleToggleDetail,
        className: 'chip-owners',
        assetType,
      },
    },
    {
      field:
        assetType === AssetType.CARDS
          ? 'card_attribute.last_updated_date'
          : assetType === AssetType.DASHBOARDS
          ? 'last_modified_time'
          : assetType === AssetType.DATASETS
          ? 'last_load_date'
          : 'last_updated_date',
      headerName: assetType === AssetType.DATASETS ? 'Last Loaded' : 'Last Updated',
      hide: isMobile,
      maxWidth: 180,
      valueGetter: ({ data }) => {
        const value =
          assetType === AssetType.CARDS
            ? data?.card_attribute?.last_updated_date
            : assetType === AssetType.DASHBOARDS
            ? data?.last_modified_time
            : assetType === AssetType.DATASETS
            ? data?.last_load_date
            : data?.last_updated_date

        return value && moment(value, "YYYY-MM-DD'T'HH:mm:ssZ").fromNow()
      },
    },
    ...(assetType === AssetType.DATASETS
      ? [
          {
            field: 'record_count',
            headerName: 'Rows',
            maxWidth: 200,
            valueGetter: ({ data }) =>
              data.record_count && data.record_count.toLocaleString(navigator.language, { minimumFractionDigits: 0 }),
          },
        ]
      : []),
    ...(assetType === AssetType.DASHBOARDS
      ? [
          {
            field: 'card_count',
            headerName: 'Cards',
            maxWidth: 120,
            hide: isMobile,
          },
        ]
      : []),
    {
      field: '_id',
      headerName: 'Actions',
      pinned: 'right',
      hide: isMobile,
      filter: false,
      sortable: false,
      width: assetType === AssetType.DATASETS ? 250 : 215,
      cellRendererFramework: ActionRenderer,
      cellRendererParams: {
        statusType,
        isSite,
        siteURL,
        assetType,
        favoriteOnly,
        datasetPreviewStatus,
      },
    },
    ...([AssetType.CARDS, AssetType.DASHBOARDS].includes(assetType)
      ? [
          {
            field: '_id',
            headerName: '',
            cellRendererFramework: DetailButtonRenderer,
            cellRendererParams: {
              handleToggleDetail,
              isMobile,
              assetType,
            },
            hide: !isMobile,
            menuTabs: false,
            sortable: false,
            suppressColumnsToolPanel: true,
          },
        ]
      : []),
  ]
}

const defaultFilterParams = {
  closeOnApply: true,
  refreshValuesOnOpen: true,
}

export default getColumnDefinitions
