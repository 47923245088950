import { call, put } from 'redux-saga/effects'
import axios from 'axios'
import { API_GATEWAY_URL } from './utils'

export const RENDERCARDFILTER_FETCH_REQUESTED = 'RENDERCARDFILTER_FETCH_REQUESTED'
export const RENDERCARDFILTER_FETCH_SUCCEEDED = 'RENDERCARDFILTER_FETCH_SUCCEEDED'
export const RENDERCARDFILTER_FETCH_FAILED = 'RENDERCARDFILTER_FETCH_FAILED'

export const RENDERDASHBOARDFILTER_FETCH_REQUESTED = 'RENDERDASHBOARDFILTER_FETCH_REQUESTED'
export const RENDERDASHBOARDFILTER_FETCH_SUCCEEDED = 'RENDERDASHBOARDFILTER_FETCH_SUCCEEDED'
export const RENDERDASHBOARDFILTER_FETCH_FAILED = 'RENDERDASHBOARDFILTER_FETCH_FAILED'

export const UPDATE_APPLIED_FILTERS = 'UPDATE_APPLIED_FILTERS'
export const CLEAR_ALL_FILTERS = 'CLEAR_ALL_FILTERS'
export const CLEAR_FILTER = 'CLEAR_FILTER'
export const TOGGLE_FILTER_DIALOG = 'TOGGLE_FILTER_DIALOG'

export default function filterReducer(state = {}, action = {}) {
  switch (action.type) {
    case RENDERCARDFILTER_FETCH_REQUESTED: {
      const newState = {
        ...state,
        renderFilterStatus: {
          status: 'requested',
        },
      }
      return newState
    }
    case RENDERCARDFILTER_FETCH_SUCCEEDED: {
      const newState = {
        ...state,
        renderFilterStatus: action.payload,
      }
      return newState
    }
    case RENDERCARDFILTER_FETCH_FAILED: {
      const newState = {
        ...state,
        renderFilterStatus: {
          status: 'failed',
          message: action.payload.response,
        },
      }
      return newState
    }

    case RENDERDASHBOARDFILTER_FETCH_REQUESTED: {
      const newState = {
        ...state,
        renderFilterStatus: {
          status: 'requested',
        },
      }
      return newState
    }
    case RENDERDASHBOARDFILTER_FETCH_SUCCEEDED: {
      const newState = {
        ...state,
        renderFilterStatus: action.payload,
      }
      return newState
    }
    case RENDERDASHBOARDFILTER_FETCH_FAILED: {
      const newState = {
        ...state,
        renderFilterStatus: {
          status: 'failed',
          message: action.payload.response,
        },
      }
      return newState
    }

    case UPDATE_APPLIED_FILTERS: {
      const newState = {
        ...state,
        appliedFilters: action.payload,
      }
      return newState
    }

    case CLEAR_ALL_FILTERS: {
      const newState = {
        ...state,
        appliedFilters: [],
      }
      return newState
    }

    case CLEAR_FILTER: {
      const newState = {
        ...state,
        appliedFilters: action.payload,
      }
      return newState
    }

    default:
      return state
  }
}

export function renderCardFilter(data) {
  return {
    type: RENDERCARDFILTER_FETCH_REQUESTED,
    payload: data,
  }
}

export function renderDashboardFilter(data) {
  return {
    type: RENDERDASHBOARDFILTER_FETCH_REQUESTED,
    payload: data,
  }
}

export function updateAppliedFilters(filters) {
  return {
    type: UPDATE_APPLIED_FILTERS,
    payload: filters,
  }
}

export function clearFilter(filterPayload = {}) {
  const existingFilters = filterPayload.filters || []
  const filterToClear = filterPayload.filterToDelete
  const filterIdKey = filterPayload.type === 'dashboard' ? 'filter_id' : 'dimension'
  const newFilters = existingFilters.filter(filter => filter[filterIdKey] !== filterToClear)
  return {
    type: CLEAR_FILTER,
    payload: newFilters,
  }
}

export function clearAllFilters(filters) {
  return {
    type: CLEAR_ALL_FILTERS,
    payload: filters,
  }
}

export function* fetchCardRenderFilter(action) {
  try {
    const json = {
      drill: action.payload.drill,
      filters: action.payload.activeViewerFilters || [],
      time_period: action.payload.existingTimePeriod,
    }
    const filterList = yield call(axios, {
      method: 'post',
      url: `${API_GATEWAY_URL}/bi_reporting_assets/v2/card_filters?${
        action.payload.dimension ? 'dimension_name' : 'ref_id'
      }=${encodeURIComponent(action.payload.dimension ? action.payload.dimension : action.payload.ref_id)}&card_id=${
        action.payload.id
      }&search_string=${encodeURIComponent(action.payload.searchString)}`,
      data: json,
    })
    if (filterList.error) {
      const e = filterList.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }

    yield put({ type: 'RENDERCARDFILTER_FETCH_SUCCEEDED', payload: filterList })
  } catch (e) {
    yield put({ type: 'RENDERCARDFILTER_FETCH_FAILED', payload: e })
  }
}

export function* fetchDashboardRenderFilter(action) {
  try {
    const filterList = yield call(axios, {
      method: 'get',
      url: `${API_GATEWAY_URL}/bi_reporting_assets/v2/dashboard_filters?dashboard_id=${action.payload.id}&filter_id=${
        action.payload.filter_id
      }&search_string=${encodeURIComponent(action.payload.searchString)}`,
    })
    if (filterList.error) {
      const e = filterList.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }

    yield put({ type: 'RENDERDASHBOARDFILTER_FETCH_SUCCEEDED', payload: filterList })
  } catch (e) {
    yield put({ type: 'RENDERDASHBOARDFILTER_FETCH_FAILED', payload: e })
  }
}
