import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react'
import axios from 'axios'
import { Toolbar, Typography } from '@mui/material'
import { Box } from '@mui/system'
import PropTypes from 'prop-types'
import { stringify } from 'qs'
import { AgGridReact } from '@ag-grid-community/react'
import { ModuleRegistry } from '@ag-grid-community/core'
import { MasterDetailModule } from '@ag-grid-enterprise/master-detail'
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model'
import { SetFilterModule } from '@ag-grid-enterprise/set-filter'
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel'
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel'
import { MenuModule } from '@ag-grid-enterprise/menu'
import { useAnalytics } from '@praxis/component-analytics'
import { useSelector } from 'react-redux'

import { API_GATEWAY_URL } from '../../../ducks/utils'
import { CardDetailComponent } from '../../routes/Cards/Cardlist/CardDetailComponent'
import { DashboardDetailComponent } from '../../routes/Dashboard/DashboardListing/DashboardDetailComponent'
import { DatasetDetailComponent } from '../../routes/Datasets/DatasetDetailComponent'
import { getSiteURL } from '../util'
import analyticsConfig from '../../analytics'
import TableFilters from './TableFilter'
import { ACTIVE } from './StatusFilters'
import getColumnDefinitions from './ColumnDefinitions'

export const isSite = /^\/site\//i.test(window.location.pathname)

// Register the required feature modules with the Grid
ModuleRegistry.registerModules([
  ServerSideRowModelModule,
  MasterDetailModule,
  MenuModule,
  SetFilterModule,
  ColumnsToolPanelModule,
  FiltersToolPanelModule,
])

export const AssetType = {
  CARDS: 'cards',
  DASHBOARDS: 'dashboards',
  DATASETS: 'datasets',
  CALC_FIELDS: 'calculated_metrics',
}

const AssetTable = props => {
  const gridRef = useRef()
  const {
    isMobile,
    routeProps,
    siteTags,
    siteURLInfo,
    datasetId,
    ownerOnly,
    isGroupOwner,
    userOrGroupId,
    assetType,
    datasetPreviewStatus = null,
    searchKeyword,
    favoriteOnly,
    assetIds,
  } = props

  const { trackClick } = useAnalytics(analyticsConfig)
  const getUserOnlyFlag = () =>
    !routeProps?.match?.params?._siteName || (siteURLInfo?.length === 3 && siteURLInfo[3] === 'cards')

  const siteURL = getSiteURL(routeProps)

  const getColumnFilters = ({ api, filterData = {}, columnName, params }) => {
    const { tagStr, searchText, showUserOnlyData, statusType } = filterData
    const filterModel = api.getFilterModel()

    const ownerFilterName = assetType === AssetType.CARDS ? 'card_attribute.owners' : 'owners'
    const ownersFilterModel = filterModel[ownerFilterName]?.values
    const ownersFilter = ownersFilterModel && getOwnerFilters(ownersFilterModel, isGroupOwner, userOrGroupId)

    const queryParams = {
      search_string: searchText,
      object_type: 'card',
      field_groups: columnName,
      status: statusType,
      search_in_user_data: showUserOnlyData ? 'yes' : 'no',
      viz_type: filterModel['card_attribute.viz_type']?.values?.join() || '',
      favorites_only: favoriteOnly ? 'yes' : 'no',
      tags: (() => {
        if (filterModel) {
          if (assetType === AssetType.CARDS) {
            return tagStr || filterModel['card_attribute.business_use']?.values?.join()
          } else if (assetType === AssetType.DATASETS) {
            if (tagStr) {
              return [tagStr, ...(filterModel.business_use?.values ? filterModel.business_use.values : '')].join()
            }
            return filterModel?.business_use?.values.join()
          } else if (AssetType.DASHBOARDS) {
            if (tagStr) {
              return [tagStr, ...(filterModel.business_area?.values ? filterModel.business_area.values : '')].join()
            }
            return filterModel?.business_area?.values?.join()
          }
        }
      })(),
      user_permitted: !isGroupOwner && userOrGroupId ? userOrGroupId : ownersFilter?.ownerIds || '',
      group_permitted: isGroupOwner ? userOrGroupId : ownersFilter?.groupIds || '',
      dataset_id: datasetId,
    }

    const payload = getStringifiedParams(queryParams)
    axios.get(`${getAssetRequestPath(assetType)}/terms?${payload}`).then(resp => {
      const values =
        columnName === 'owners'
          ? resp.data[columnName]?.map(owner =>
              owner.user_group_name === 'NonUser Account'
                ? `${owner._id},${owner.type},${owner._id}`
                : `${owner._id},${owner.type},${owner.user_group_name}`
            )
          : resp.data[columnName]
      params.success(values)
    })
  }
  const currentUser = useSelector(state => state.user.userType)

  const [gridApi, setGridApi] = useState(null)
  const [totalListSize, setTotalListSize] = useState('')
  const [tagStr, setTagStr] = useState(siteTags) // For Sites
  const [showUserOnlyData, setShowUserOnlyData] = useState(
    datasetId || userOrGroupId || searchKeyword || assetIds ? false : getUserOnlyFlag()
  )
  const [statusType, setStatusType] = useState(ACTIVE)
  const [searchText, setSearchText] = useState(searchKeyword || '')
  const [homePageId, setHomePageId] = useState(currentUser?.data?.personalization.home.id || '')
  const [showCertified, setShowCertified] = useState(false)
  const [dpQueryStringLink, setDpQueryStringLink] = useState('')
  const [filterData, setFilterData] = useState({
    searchText: '',
    tagStr: siteTags,
    showUserOnlyData,
    statusType,
  })
  const [columnDefs, setColumnDefs] = useState(
    getColumnDefinitions({
      assetType,
      isMobile,
      siteURL,
      siteURLInfo,
      statusType,
      filterData,
      ownerOnly,
      setFilterData,
      getColumnFilters,
      datasetPreviewStatus,
      homePageId,
      setHomePageId,
      userOrGroupId,
      favoriteOnly,
    })
  )

  const onGridReady = useCallback(params => {
    setGridApi(params.api)
  }, [])

  useEffect(() => {
    if (searchText || showCertified) {
      setDpQueryStringLink(
        `search?${searchText ? 'query=' : ''}${searchText ? `${searchText}&` : ''}page=1&size=10&sort=Suggested${
          showCertified ? '&certified=true' : ''
        }`
      )
    } else {
      setDpQueryStringLink('')
    }
  }, [searchText || showCertified])

  useEffect(() => {
    setTagStr(siteTags)
    setFilterData(prev => ({ ...prev, tagStr: siteTags }))
  }, [siteTags])

  useEffect(() => {
    setColumnDefs(
      getColumnDefinitions({
        assetType,
        isMobile,
        siteURL,
        siteURLInfo,
        statusType,
        tagStr,
        ownerOnly,
        setFilterData,
        getColumnFilters,
        datasetPreviewStatus,
        homePageId,
        setHomePageId,
        userOrGroupId,
        favoriteOnly,
      })
    )
  }, [isMobile, statusType, tagStr])

  useEffect(() => {
    if (gridApi) {
      gridApi.setServerSideDatasource(datasource)
      setTotalListSize('')
    }
  }, [
    gridApi,
    searchText,
    tagStr,
    favoriteOnly,
    assetIds,
    showUserOnlyData,
    siteTags,
    statusType,
    showCertified,
    assetIds,
  ])

  const gridOptions = useMemo(() => {
    return {
      defaultColDef: {
        flex: 1,
        minWidth: 150,
        resizable: true,
        floatingFilter: true,
        sortable: true,
        menuTabs: !isMobile && ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab'],
        unSortIcon: true,
      },
      rowStyle: {
        background: 'white',
      },
      rowModelType: 'serverSide',
      serverSideStoreType: 'partial',
      animateRows: true,
      detailRowAutoHeight: true,
      enableCellTextSelection: true,
      masterDetail: !isMobile,
      blockLoadDebounceMillis: 500,
      suppressRowHoverHighlight: true,
    }
  }, [])

  const datasource = {
    getRows: params => {
      const payload = getAssetPayload(params.request)
      axios
        .get(`${getAssetRequestPath(assetType)}?${payload}`)
        .then(resp => {
          params.success({ rowData: resp.data, rowCount: parseInt(resp.headers.total_list_size) })
          setTotalListSize(resp.headers.total_list_size)
        })
        .catch(() => params.fail())
    },
  }

  const getAssetPayload = params => {
    const { startRow, endRow, filterModel, sortModel } = params
    const page = endRow / 50
    const perPage = endRow - startRow

    const ownerFilterName = assetType === AssetType.CARDS ? 'card_attribute.owners' : 'owners'
    const ownersFilterModel = filterModel[ownerFilterName]?.values
    const ownersFilter = ownersFilterModel && getOwnerFilters(ownersFilterModel)

    const sortBy = sortModel[0]
      ? assetType === AssetType.CARDS
        ? `${sortModel[0]?.colId.split('.')[1]}:${sortModel[0]?.sort}`
        : `${sortModel[0]?.colId}:${sortModel[0]?.sort}`
      : ''

    const queryParams = {
      page,
      per_page: perPage,
      search_in_user_data: showUserOnlyData ? 'yes' : 'no',
      favorites_only: favoriteOnly ? 'yes' : 'no',
      search_string: searchText,
      viz_type: filterModel['card_attribute.viz_type']?.values?.join(), // only for cards
      _id: assetIds?.toString(),
      tags: (() => {
        if (filterModel) {
          if (assetType === AssetType.CARDS) {
            return tagStr || filterModel['card_attribute.business_use']?.values?.join()
          } else if (assetType === AssetType.DATASETS) {
            if (tagStr) {
              return [tagStr, ...(filterModel.business_use?.values ? filterModel.business_use.values : '')].join()
            }
            return filterModel?.business_use?.values.join()
          } else if (AssetType.DASHBOARDS) {
            if (tagStr) {
              return [tagStr, ...(filterModel.business_area?.values ? filterModel.business_area.values : '')].join()
            }
            return filterModel?.business_area?.values?.join()
          }
        }
      })(),
      owner_id: ownersFilter?.ownerIds,
      group_id: ownersFilter?.groupIds,
      ...([AssetType.DATASETS, AssetType.CARDS, AssetType.DASHBOARDS].includes(assetType)
        ? { field_groups: 'quick_metadata' }
        : { field_group: 'quick_metadata' }), // sorting doesn't work for last updated on calc fields, hence use field_group until the api is fixed
      sort: sortBy,
      certified_only: showCertified,
      dataset_id: datasetId,
      status: statusType,
      ...(ownerOnly && !favoriteOnly && !ownersFilter && { owner_only: 'yes' }),
      ...(userOrGroupId && !ownersFilter && { [isGroupOwner ? 'group_permitted' : 'user_permitted']: userOrGroupId }),
      ...(userOrGroupId && !isGroupOwner
        ? {
            user_permitted: ownersFilter?.groupIds
              ? userOrGroupId
                ? `${ownersFilter.groupIds},${userOrGroupId}`
                : ownersFilter.groupIds
              : userOrGroupId,
          }
        : {
            group_permitted: ownersFilter?.groupIds // for admin search terms to always have current owner selected
              ? userOrGroupId
                ? `${ownersFilter.groupIds},${userOrGroupId}`
                : ownersFilter.groupIds
              : userOrGroupId,
          }),
    }

    return getStringifiedParams(queryParams)
  }

  const getStringifiedParams = params => {
    Object.keys(params).forEach(key => {
      if (!params[key]) {
        delete params[key]
      }
    })
    return stringify(params)
  }

  const handleCertifiedToggle = () => {
    if (showCertified) {
      trackClick('dataset-listing-page-search-certified')
    } else {
      trackClick('dataset-listing-page-search-not-certified')
    }
    setShowCertified(!showCertified)
  }

  const handleMyContentToggle = () => {
    setShowUserOnlyData(!showUserOnlyData)
    setFilterData(prev => ({ ...prev, showUserOnlyData: !showUserOnlyData }))
  }

  const handleStatusChange = statusType => {
    setStatusType(statusType)
    setFilterData(prev => ({ ...prev, statusType }))
  }

  const handleSearchClick = searchText => {
    setSearchText(searchText)
    setFilterData(filterData => ({ ...filterData, searchText }))
  }

  const handleOnSearchKeyDown = (e, searchText) => {
    if (e.key === 'Enter') {
      setSearchText(searchText)
      setFilterData(filterData => ({ ...filterData, searchText }))
    }
  }

  return (
    <>
      {!siteURL && !datasetId && !ownerOnly && !userOrGroupId && !searchKeyword && (
        <Toolbar classes={{ root: 'toolbar' }}>
          <Typography classes={{ root: 'toolbar-title' }} tabIndex="0" aria-label="card listing">
            {assetType.toUpperCase()} LISTING
          </Typography>
        </Toolbar>
      )}
      <Box m={2} mb={5}>
        <TableFilters
          data={{
            showUserOnlyData,
            statusType,
            dpQueryStringLink,
            showCertified,
            siteURL,
            gridRef,
            ...props,
          }}
          handleMyContentToggle={handleMyContentToggle}
          handleOnSearchKeyDown={handleOnSearchKeyDown}
          handleSearchClick={handleSearchClick}
          handleStatusChange={handleStatusChange}
          handleCertifiedToggle={handleCertifiedToggle}
          setSearchText={setSearchText}
        />
        <Typography
          variant="subtitle1"
          id="total-pages"
          aria-label="Total records"
          classes={{ root: 'total-records' }}
          data-cy="display-record-count"
        >
          {`Total ${
            statusType === 'active' ? 'Active' : statusType === 'disabled' ? 'Inactive' : 'All'
          }: ${totalListSize}`}
        </Typography>
        <div className="ag-theme-alpine ag-style" style={{ height: '80vh', background: '#eee', border: 'none' }}>
          <AgGridReact
            ref={gridRef}
            columnDefs={columnDefs}
            gridOptions={gridOptions}
            onGridReady={onGridReady}
            maxBlocksInCache={4}
            cacheBlockSize={50}
            detailCellRendererFramework={({ data }) => {
              return !isMobile && assetType === AssetType.CARDS ? (
                <CardDetailComponent rowData={data} />
              ) : assetType === AssetType.DASHBOARDS ? (
                <DashboardDetailComponent rowData={data} />
              ) : assetType === AssetType.DATASETS ? (
                <DatasetDetailComponent rowData={data} />
              ) : (
                ''
              )
            }}
          />
        </div>
      </Box>
    </>
  )
}

AssetTable.propTypes = {
  assetType: PropTypes.string.isRequired,
  isMobile: PropTypes.bool,
  siteTags: PropTypes.string,
  siteUrlInfo: PropTypes.array,
  isGroupOwner: PropTypes.bool,
  userOrGroupId: PropTypes.string,
}

export default AssetTable

const getOwnerFilters = (data, isGroupOwner, userOrGroupId) => {
  const ownerIds = []
  const groupIds = []
  data.forEach(filter => {
    const filterData = filter.split(',')
    filterData[1] === 'group' ? groupIds.push(filterData[0]) : ownerIds.push(filterData[0])
  })
  if (userOrGroupId) {
    isGroupOwner ? groupIds.push(userOrGroupId) : ownerIds.push(userOrGroupId)
  }

  return { ownerIds: ownerIds.join(), groupIds: groupIds.join() }
}

export const getAssetRequestPath = assetType => {
  switch (assetType) {
    case AssetType.CARDS:
      return `${API_GATEWAY_URL}/bi_reporting_assets/v3/${assetType}`
    case AssetType.DATASETS:
    case AssetType.DASHBOARDS:
    case AssetType.CALC_FIELDS:
      return `${API_GATEWAY_URL}/bi_reporting_assets/v2/${assetType}`
    default:
      return ''
  }
}
