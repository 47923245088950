import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import {
  getBusinessTags,
  getUserBusinessTags,
  saveUserBusinessTags,
  clearSaveUserBusinessTagsInfo,
  toggleUserBusinessTags,
} from '../../../ducks/quicklinks'

import Quicklinks from './Quicklinks'

export class QuicklinksContainer extends React.Component {
  state = {
    subMenuOpen: false,
  }

  /* eslint-disable camelcase */
  UNSAFE_componentWillMount() {
    /* eslint-enable camelcase */
    this.props.getUserBusinessTags()
  }

  render() {
    return (
      <>
        <Quicklinks {...this.props} subMenuOpen={this.state.subMenuOpen} />
      </>
    )
  }
}

export const mapStateToProps = state => ({
  dashboardBusinessTagInfoStatus: state.quicklinks.dashboardBusinessTagInfoStatus,
  userDashboardBusinessTagInfoStatus: state.quicklinks.userDashboardBusinessTagInfoStatus,
  saveUserQuickLinksInfoStatus: state.quicklinks.saveUserQuickLinksInfoStatus,
})

export const mapDispatchToProps = dispatch => ({
  getBusinessTags(data) {
    dispatch(getBusinessTags(data))
  },
  getUserBusinessTags() {
    dispatch(getUserBusinessTags())
  },
  saveUserBusinessTags(data) {
    dispatch(saveUserBusinessTags(data))
  },
  clearSaveUserBusinessTagsInfo() {
    dispatch(clearSaveUserBusinessTagsInfo())
  },
  toggleUserBusinessTags(index) {
    dispatch(toggleUserBusinessTags(index))
  },
})

QuicklinksContainer.defaultProps = {
  dashboardBusinessTagInfoStatus: {},
  userDashboardBusinessTagInfoStatus: {},
  saveUserQuickLinksInfoStatus: {},
  saveUserBusinessTags: () => {},
  getUserBusinessTags: () => {},
  getBusinessTags: () => {},
  clearSaveUserBusinessTagsInfo: () => {},
  toggleUserBusinessTags: () => {},
  onCloseSideNav: () => {},
}

QuicklinksContainer.propTypes = {
  dashboardBusinessTagInfoStatus: PropTypes.object,
  userDashboardBusinessTagInfoStatus: PropTypes.object,
  saveUserQuickLinksInfoStatus: PropTypes.object,
  getUserBusinessTags: PropTypes.func,
  getBusinessTags: PropTypes.func,
  saveUserBusinessTags: PropTypes.func,
  clearSaveUserBusinessTagsInfo: PropTypes.func,
  toggleUserBusinessTags: PropTypes.func,
  isMobile: PropTypes.bool,
  deviceType: PropTypes.array,
}

export default connect(mapStateToProps, mapDispatchToProps)(QuicklinksContainer)
