import React from 'react'
import { Spinner } from 'greenfield-utilities'
import PropTypes from 'prop-types'
import { Grid, Typography, Icon } from '@mui/material'
import cloneDeep from 'lodash/cloneDeep'
import styled from 'styled-components'

import { GreenfieldCardPreview, FullScreen } from '../../shared/shared'

const SSpinner = styled(Spinner)`
  margin-top: 50px;
`
class FavoritesCards extends React.Component {
  state = {
    favoriteCards: [],
  }

  componentDidUpdate(prevProps) {
    const { favoriteCardInfo } = this.props

    if (
      prevProps.favoriteCardInfo.status === 'requested' &&
      favoriteCardInfo.status >= 200 &&
      favoriteCardInfo.status < 300
    ) {
      this.setState({
        favoriteCards: favoriteCardInfo.data,
      })
    }
  }

  handleUnfavorite = id => {
    const { favoriteCards } = this.state
    const index = favoriteCards.findIndex(card => card._id === id)

    if (index > -1) {
      this.setState(prevState => {
        const cards = cloneDeep(prevState.favoriteCards)
        cards.splice(index, 1)

        return {
          favoriteCards: cards,
        }
      })
    }
  }

  renderCards() {
    const { favoriteCards } = this.state
    const { routeProps } = this.props

    return (
      <Grid className={`fav-cards ${favoriteCards.length ? '' : 'fullHeight'}`} container spacing={1}>
        {Boolean(favoriteCards.length) &&
          favoriteCards.map((item, key) => (
            <Grid
              item
              xs={6}
              key={key}
              className="homePagePreviewWrapper"
              id={`favCardNum-${key}`}
              data-cy={`cardId-${item?._id}`}
            >
              {this.props.isMobile && this.props.deviceType.includes('isPhone') && (
                <Grid item className="mobileFullScreenBtnContainer">
                  <FullScreen
                    isMobile={this.props.isMobile}
                    deviceType={this.props.deviceType}
                    id={`favCardNum-${key}`}
                  />
                </Grid>
              )}
              <GreenfieldCardPreview
                onUnfavorite={this.handleUnfavorite}
                data={item}
                key={item._id}
                cardId={item._id}
                isHomePage
                showFavIcon
                isFavoritesCardViewer
                isMobile={this.props.isMobile}
                routeProps={routeProps}
              />
            </Grid>
          ))}
        {favoriteCards && !favoriteCards.length && (
          <Grid container className="noFavoriteCardsMessage" data-cy="fav-card-message">
            <Grid item className="noMessageMobileHook">
              <Typography className="noMessageFirst">
                No favorite cards. Click the <Icon className="noMessageIcon">star_outline</Icon> on any card, dashboard
                or dataset to have them appear in your favorites.{' '}
              </Typography>
            </Grid>
          </Grid>
        )}
      </Grid>
    )
  }

  render() {
    const { favoriteCardInfo, displayServiceErrorMessage } = this.props

    if (favoriteCardInfo.status === 'requested') {
      return <SSpinner layout="selfCentering" size="large" />
    } else if (favoriteCardInfo.status >= 200 && favoriteCardInfo.status < 300) {
      return this.renderCards()
    } else if (favoriteCardInfo.status === 'failed') {
      displayServiceErrorMessage('Your request to retrieve your favorite cards failed.')
    }

    return null
  }
}

FavoritesCards.propTypes = {
  favoriteCardInfo: PropTypes.object,
}

export default FavoritesCards
