import React, { createRef } from 'react'
import PropTypes from 'prop-types'
import { Route, Link, Switch } from 'react-router-dom'
import SwipeableViews from 'react-swipeable-views'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import ReactDOM from 'react-dom'
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Tabs,
  Tab,
  List,
  ListItem,
  ListItemText,
  Divider,
  Icon,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Popover,
  Avatar,
  TextField,
  MenuItem,
  Tooltip,
  DialogActions,
  Box,
  Stack,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Menu,
  ListItemIcon,
  ListSubheader,
  Chip,
  Snackbar,
  Alert,
} from '@mui/material'
import {
  CloudDownload,
  OpenInNew,
  Visibility,
  VisibilityOff,
  Help,
  Add,
  Lock,
  Public,
  Security,
  LibraryAdd,
  ArrowBackIos,
  Error,
  Refresh,
  ArrowForward,
  Done,
  MoreVert,
  EditOutlined,
  DeleteOutline,
  DateRangeOutlined,
  Close,
  NotificationAddOutlined,
  NotificationsNone,
} from '@mui/icons-material'
import moment from 'moment'
import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'
import isEmpty from 'lodash/isEmpty'
import orderBy from 'lodash/orderBy'
import classNames from 'classnames'
import { Spinner, formatCell, createErrMsg } from 'greenfield-utilities'
import axios from 'axios'
import styled from 'styled-components'

import DataPortalLogo from '../../../images/dataportal-logo.png'
import AutomationPortalLogo from '../../../images/automationportal.svg'
import ConfirmDialog from '../../shared/Dialog/ConfirmDialog'
import AdvanceFilter from '../../shared/AdvanceFilter/AdvanceFilter'
import { AgGridTable } from '../../shared/AgGrid/AgGridTableClientSide'
import { getOwnerInfoChip } from '../../shared/AgGrid/AgGridHelper'
import { advanceFilter, isCertifiedDataset } from '../../shared/util'
import { getColumnColorCodeBasedOnType, processColumnsIcon } from '../Builder/Sidebar/utils'
import { CertifiedIcon } from '../../shared/Svgicon/Svgicon'
import AccessDeniedViewOwner from '../../shared/AccessDeniedViewOwner'
import NotFoundPage from '../NotFoundPage/NotFoundPage'
import AssetTable, { AssetType } from '../../shared/AssetTableSS/AssetTable'
import './datasets.scss'
import CalcFieldAddEditDialog from '../CalculatedFIeld/CalculatedFieldAddEditDialog'
import { API_GATEWAY_URL } from '../../../ducks/utils'
import DatasetSupportDialog from './DatasetSupportDialog'
import DatasetDetailsComponent, { SFloatingIconButton } from './DatasetPreviewDetailsComponent'
import DatasetSecurityComponent from './DatasetPreviewSecurityComponent'
import DatasetDeleteDialog from './DatasetCellRenderer/DatasetDeleteDialog'

const portalElement = document.createElement('div')

portalElement.classList.add('dnd-portal-container')
document.body.appendChild(portalElement)

const formatLabelName = col =>
  `${col.column_display_name === col.field_name ? col.field_name : `${col.column_display_name} (${col.field_name})`}`

class InnerList extends React.Component {
  state = {
    searchFieldValue: '',
  }

  shouldComponentUpdate(nextProps, nextState) {
    // update columns only when needed
    let shouldUpdate = true
    if (this.state.searchFieldValue !== nextState.searchFieldValue) {
      shouldUpdate = true
    } else if (this.props.columns === nextProps.columns) {
      shouldUpdate = false
    }
    return shouldUpdate
  }

  handleSearchField = event => {
    this.setState({
      searchFieldValue: event.target.value,
    })
  }

  render() {
    const { searchFieldValue } = this.state
    const { columns, expanded, expandAll, editDatasetColumns, expandColMore, expandColLess } = this.props
    const _columns = cloneDeep(columns)

    const displayFields = advanceFilter(searchFieldValue, _columns, ['column_display_name', 'field_name'])
    return (
      <>
        <AdvanceFilter
          label="Search Dataset Fields"
          onSearchChange={this.handleSearchField}
          searchValue={searchFieldValue}
          className="search-input"
          id="search-dataset-field"
          style={{ width: '400px' }}
        />
        {displayFields.map((col, index) => (
          <React.Fragment key={col.field_name}>
            <Draggable key={col.field_name} index={index} draggableId={col.field_name}>
              {(provided, snapshot) => (
                <PortalAwareDraggableItem
                  col={col}
                  provided={provided}
                  snapshot={snapshot}
                  index={index}
                  expandAll={expandAll}
                  editDatasetColumns={editDatasetColumns}
                  expanded={expanded && expanded.length && expanded.includes(index)}
                  expandColMore={expandColMore}
                  expandColLess={expandColLess}
                  searchFieldValue={searchFieldValue}
                />
              )}
            </Draggable>
          </React.Fragment>
        ))}
      </>
    )
  }
}

const SAvatar = styled(Avatar)`
  width: 40px;
  height: 40px;
  background: inherit;
`
class PortalAwareDraggableItem extends React.Component {
  getDecimalMenuItems = () => {
    const decimalRange = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12']

    return decimalRange.map(decimal => {
      return (
        <MenuItem key={decimal} value={decimal}>
          {decimal}
        </MenuItem>
      )
    })
  }

  render() {
    const listItemStyle = {
      backgroundColor: '#f2f2f2',
      borderBottom: '1px solid #b2b2b2',
    }
    const {
      provided,
      snapshot,
      col,
      expandAll,
      editDatasetColumns,
      expanded,
      expandColLess,
      expandColMore,
      searchFieldValue,
    } = this.props
    const { isDragging } = snapshot

    const children = (
      <div
        ref={provided.innerRef}
        style={{
          ...provided.draggableProps.style,
        }}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        className="portal-column"
      >
        <>
          <ListItem button style={listItemStyle}>
            <SAvatar
              alt={col.type}
              src={processColumnsIcon(col)}
              className={classNames('icon-avatar', getColumnColorCodeBasedOnType(col))}
            />
            <ListItemText inset primary={formatLabelName(col)} />
            {col.hide_column ? <Icon>visibility_off</Icon> : <div />}
            {!searchFieldValue && (
              <>
                <IconButton
                  onClick={() => {
                    editDatasetColumns(col, 'down')
                  }}
                  aria-label="Move Column Down"
                >
                  <Icon>arrow_downward</Icon>
                </IconButton>
                <IconButton
                  onClick={() => {
                    editDatasetColumns(col, 'up')
                  }}
                  aria-label="Move Column Up"
                >
                  <Icon>arrow_upward</Icon>
                </IconButton>
              </>
            )}
            {expandAll || expanded ? (
              <IconButton
                onClick={() => {
                  expandColLess(col, searchFieldValue)
                }}
              >
                <Icon>keyboard_arrow_up</Icon>
              </IconButton>
            ) : (
              <IconButton
                onClick={() => {
                  expandColMore(col, searchFieldValue)
                }}
              >
                <Icon>keyboard_arrow_down</Icon>
              </IconButton>
            )}
          </ListItem>
        </>
        {expandAll || expanded ? this.expandColumnOps(col) : <div />}
      </div>
    )
    return !isDragging ? children : ReactDOM.createPortal(children, portalElement)
  }

  expandColumnOps = column => {
    const { editDatasetColumns } = this.props

    return (
      <div className="dir-col">
        <div className="dir-row">
          <Grid container>
            <Grid item xs={2}>
              <Typography variant="body1" className="col-format-margin">
                Label
              </Typography>
            </Grid>
            <TextField
              size="small"
              variant="outlined"
              defaultValue={column.column_display_name}
              onChange={e => {
                column.column_display_name = e.target.value
                editDatasetColumns(column)
              }}
              className="text-field"
            />
          </Grid>
        </div>
        {/* Add description for non calculated fields */}
        {column.type === 'dimension' && (
          <div className="dir-row">
            <Grid container>
              <Grid item xs={2}>
                <Typography variant="body1" className="col-format-margin">
                  Description
                </Typography>
              </Grid>
              <TextField
                multiline
                size="small"
                variant="outlined"
                placeholder="Enter Description"
                value={column.description}
                onChange={e => {
                  column.description = e.target.value
                  editDatasetColumns(column)
                }}
                className="text-field"
              />
            </Grid>
          </div>
        )}
        {(column.type === 'metric' || column.type === 'postagg' || column.type === 'aggregation') && (
          <div className="dir-col">
            <div className="dir-row margin-above">
              <Grid container>
                <Grid item xs={2}>
                  <Typography variant="body1" className="col-format-margin">
                    Format type
                  </Typography>
                </Grid>
                <TextField
                  variant="outlined"
                  className="component-min-margin"
                  value={column.format_type ? column.format_type : ''}
                  onChange={e => {
                    column.format_type = e.target.value
                    editDatasetColumns(column)
                  }}
                  name="formatType"
                  select
                  size="small"
                >
                  <MenuItem value="number">Number</MenuItem>
                  <MenuItem value="currency_USD">Currency</MenuItem>
                  <MenuItem value="percentage">Percentage</MenuItem>
                </TextField>
                <TextField
                  variant="outlined"
                  value={column.format_precision ? column.format_precision : ''}
                  onChange={e => {
                    column.format_precision = e.target.value
                    editDatasetColumns(column)
                  }}
                  name="decimalRange"
                  select
                  size="small"
                >
                  {this.getDecimalMenuItems()}
                </TextField>
              </Grid>
            </div>
          </div>
        )}
        {column.type !== 'postagg' && column.type !== 'aggregation' && (
          <>
            <Divider />
            <div className="col-format-margin">
              {column.hide_column ? (
                <Button
                  color="secondary"
                  className="icon-label-btn-props"
                  onClick={() => {
                    column.hide_column = false
                    editDatasetColumns(column)
                  }}
                >
                  <Visibility className="icon-left" />
                  Show Column
                </Button>
              ) : (
                <Button
                  color="secondary"
                  className="icon-label-btn-props"
                  onClick={() => {
                    column.hide_column = true
                    editDatasetColumns(column)
                  }}
                >
                  <VisibilityOff className="icon-left" />
                  Hide Column
                </Button>
              )}
            </div>
          </>
        )}
      </div>
    )
  }
}

function renderMobileMessage() {
  return <Typography className="mobileMessage">Dataset features are not available on mobile</Typography>
}

class DatasetPreviewComp extends React.Component {
  gridRef = createRef()
  state = {
    pageSize: 10,
    openAddIcon: false,
    selectedDatasetId: '',
    colPageIndex: 0,
    cfPageIndex: 0,
    searchCFValue: '',
    selectedCalcField: {},
    open: false,
    openDelWin: false,
    cardPageIndex: 0,
    calculatedDialogOpen: false,
    isEditable: false,
    addedOwners: [],
    isDefaultOwnerPresent: true,
    addedBusinessAreas: [],
    isDefaultBusinessAreaPresent: true,
    addedViewers: [],
    isDefaultViewerPresent: true,
    isFormatCols: false,
    collapseAll: false,
    expandAll: false,
    expanded: [],
    selectedBusinessAreas: [],
    formatColsStatus: [],
    securityControlObj: {},
    enableVendors: false,
    addedViewersSave: [],
    focusedVendor: false,
    focusedUser: '',
    ownersSuggestions: [],
    updatedDisplayNameValue: null,
    updateDatasetDescription: null,
    calculatedFieldDetail: null,
    isShowHelp: false,
    supportText: '',
    helpAnchorEl: null,
    isFormatColumnSearchEnabled: false,
    disableNonOwnerCfCreation: false,
    cardArray: [],
    dashboardArray: [],
    columnQueryResult: [],
    uploadStatus: null,
    uploadHistoryList: [],
    ingestLogDialogVisiblityIndex: -1,
    isDeleteDatasetDialogOpen: false,
    popoverAnchorEl: null,
    datasetCardsDialogOpen: false,
    isOpenDatasetRestoreDialog: this.props.routeProps.location?.restore || false,
    showDatasetDescription: true,
    isRefreshDatasetDialogOpen: false,
    isInvalidateCacheEnabled: false,
    isGenerateRefreshEventEnabled: false,
    openActionsMenu: false,
    warningMessage: false,
  }

  componentDidUpdate(prevProps, prevState) {
    const { displayServiceErrorMessage, datasetDelete, datasetPreview, datasetRefreshMultipleDatesStatus } = this.props

    // Delete Dataset Status
    if (prevProps.datasetDelete.status === 'requested' && datasetDelete.status === 'failed') {
      this.setState({ isDeleteDatasetDialogOpen: false })
      const errorMessage = datasetDelete?.response?.data?.message
      displayServiceErrorMessage(
        errorMessage || 'Something went wrong with the request to delete dataset, please try again later!'
      )
    } else if (prevProps.datasetDelete.status === 'requested' && datasetDelete.status === 204) {
      this.setState({ isDeleteDatasetDialogOpen: false })
      this.props.routeProps.history.push('/datasets')
    }

    if (
      prevProps.datasetPreview.status === 'requested' &&
      datasetPreview.status === 200 &&
      datasetPreview.data?.sources?.includes('upload')
    ) {
      this.setState(
        {
          uploadStatus: 'requested',
        },
        () => {
          axios
            .get(`${API_GATEWAY_URL}/greenfield_ingests/v1/ingests?dataset=${datasetPreview.data.dataset_name}`)
            .then(response => {
              this.setState({
                uploadHistoryList: response.data,
              })

              if (response.data[0]) {
                axios.get(`${API_GATEWAY_URL}/greenfield_ingests/v1/ingests/${response.data[0].id}/logs`).then(res => {
                  this.setState({
                    ingestLogs: res.data,
                  })
                })
              }
            })
        }
      )
    }

    this.updateOnUpdateDatasetStatus(prevProps, prevState)
    this.updateOnCalcFieldUpdateStatus(prevProps, prevState)
    this.updateOnCalcFieldDelStatus(prevProps, prevState)
    this.updateOnDatasetPreview(prevProps, prevState)
    this.updateOnRefreshDatasetButton(prevProps)
    this.updateOnMultidateDatasetButton(prevProps)

    if (prevProps.datasetPreview.status === 'requested' && datasetPreview.status === 'failed') {
      displayServiceErrorMessage("Something went wrong with the request to get your dataset's data.")
    }

    // Convert dataset to enable multiple dates error handling
    if (
      prevProps.datasetRefreshMultipleDatesStatus.status === 'requested' &&
      datasetRefreshMultipleDatesStatus.status === 'failed'
    ) {
      displayServiceErrorMessage(
        datasetRefreshMultipleDatesStatus.message?.statusText ||
          'Something went wrong with multiple dataset refresh, please try again later'
      )
    }
  }

  handleDeleteDataset = () => {
    const delDataset = this.props.datasetPreview?.data?._id
    if (delDataset) {
      this.props.deleteDataset({ delDataset })
    }
  }

  updateOnRefreshDatasetButton = prevProps => {
    const { displayServiceErrorMessage, refreshDatasetStatus, getDatasetPreview, datasetPreview } = this.props

    if (prevProps.refreshDatasetStatus.status === 'requested' && refreshDatasetStatus.status === 'failed') {
      displayServiceErrorMessage(
        createErrMsg(refreshDatasetStatus),
        'Something went wrong with refresh dataset request'
      )
      this.setState({
        openActionsMenu: null,
      })
    } else if (prevProps.refreshDatasetStatus.status === 'requested' && refreshDatasetStatus.status === 201) {
      getDatasetPreview({ datasetId: datasetPreview?.data?._id })
      this.setState({
        openActionsMenu: null,
      })
    }
  }

  updateOnMultidateDatasetButton = prevProps => {
    const { displayServiceErrorMessage, datasetRefreshMultipleDatesStatus, getDatasetPreview, datasetPreview } =
      this.props

    if (
      prevProps.datasetRefreshMultipleDatesStatus.status === 'requested' &&
      datasetRefreshMultipleDatesStatus.status === 'failed'
    ) {
      displayServiceErrorMessage(
        createErrMsg(datasetRefreshMultipleDatesStatus),
        'Something went wrong with multiple dates enable request'
      )
      this.setState({
        openActionsMenu: null,
      })
    } else if (
      prevProps.datasetRefreshMultipleDatesStatus.status === 'requested' &&
      datasetRefreshMultipleDatesStatus.status === 201
    ) {
      getDatasetPreview({ datasetId: datasetPreview?.data?._id })
      this.setState({
        openActionsMenu: null,
      })
    } else if (
      prevProps.datasetRefreshMultipleDatesStatus.status === 'requested' &&
      datasetRefreshMultipleDatesStatus.status === 200
    ) {
      this.setState({
        openActionsMenu: null,
        warningMessage: true,
      })
    }
  }

  handleShowSupportText = e => {
    const { isShowHelp } = this.state
    this.setState({
      helpAnchorEl: isShowHelp ? null : e.currentTarget,
      isShowHelp: !isShowHelp,
    })
  }

  updateState = (stateName, value) => {
    this.setState({
      [stateName]: value,
    })
  }

  getColumns = columns => {
    columns = orderBy(columns, 'sort_index', 'asc')

    const colsArray = columns.map(col => {
      const labelName = formatLabelName(col)

      return {
        field: col.field_name,
        headerName: labelName,
        headerComponentParams: { isColumnHidden: col.hide_column },
        cellRendererFramework: params => formatCell(params, col),
        minWidth: 200,
      }
    })
    return colsArray
  }

  handleFormatColSave = () => {
    const { formatColsStatus } = this.state
    const _formatColsStatus = cloneDeep(formatColsStatus)

    const columns = _formatColsStatus.fields_array.filter(col => {
      if (col.type !== 'postagg' && col.type !== 'aggregation' && col.type !== 'dynamic_dimension') {
        // Temp till service code is not change to field_name
        col.column_name = col.field_name
        delete col.field_name
        return col
      }

      return false
    })

    const calcFields = _formatColsStatus.fields_array.filter(col => {
      if (col.type === 'postagg' || col.type === 'aggregation' || col.type === 'dynamic_dimension') {
        col.datasetId = _formatColsStatus._id
        // Temp till service code is not change to field_name
        col.column_name = col.field_name
        col.edit_enabled = col.edit_enabled === 'yes'
        delete col.field_name
        return col
      }

      return false
    })

    this.props.editAllCalculatedFields({
      datasetId: _formatColsStatus._id,
      payload: calcFields,
    })

    this.props.updateDatasetOnEdit({
      _id: _formatColsStatus._id,
      columns,
    })

    this.setState({
      expanded: [],
    })
  }

  handleFormatCancel = () => {
    this.setState({
      isFormatCols: false,
      formatColsStatus: {},
      expanded: [],
    })
  }

  handleFormatColsClick = () => {
    this.setState({
      isFormatCols: true,
    })
  }

  handleExpandAll = () => {
    const { data } = cloneDeep(this.props.datasetPreviewStatus)
    const { formatColsStatus } = this.state
    const expanded = []
    let columns = formatColsStatus && formatColsStatus.fields_array ? formatColsStatus.fields_array : data.fields_array

    if (columns) {
      columns = orderBy(columns, 'sort_index', 'asc')
      for (let colIdx = 0; colIdx < columns.length; colIdx++) {
        expanded.push(colIdx)
      }
    }

    this.setState({
      expandAll: true,
      collapseAll: false,
      expanded,
    })
  }

  handleCollapseAll = () => {
    this.setState({
      collapseAll: true,
      expandAll: false,
      expanded: [],
    })
  }

  editDatasetColumns = (row, move, expanded) => {
    const { formatColsStatus, isFormatCols } = this.state
    const { data } = cloneDeep(this.props.datasetPreviewStatus)
    let columns = []

    if (!expanded) {
      expanded = this.state.expanded
    }

    if (isFormatCols) {
      columns = formatColsStatus && formatColsStatus.fields_array ? formatColsStatus.fields_array : data.fields_array
    }

    if (columns) {
      if (columns[0] && !columns[0].sort_index) {
        columns = this.updateSortedColumns(columns)
      }
      columns = orderBy(columns, 'sort_index', 'asc')
      const index = columns.findIndex(col => col.field_name === row.field_name)

      if (index > -1) {
        if (move === 'down' && index !== columns.length - 1) {
          if (columns[index + 1]) {
            const tempIdx = columns[index].sort_index
            columns[index].sort_index = columns[index + 1].sort_index
            columns[index].hasChanged = true
            columns[index + 1].sort_index = tempIdx
            columns[index + 1].hasChanged = true
          }
        } else if (move === 'up' && index !== 0) {
          if (columns[index - 1]) {
            const tempIdx = columns[index].sort_index
            columns[index].sort_index = columns[index - 1].sort_index
            columns[index].hasChanged = true
            columns[index - 1].sort_index = tempIdx
            columns[index - 1].hasChanged = true
          }
        } else {
          row.hasChanged = true
          columns.splice(index, 1, row)
        }
      }
    }

    if (isFormatCols) {
      data.fields_array = columns
      this.setState({
        formatColsStatus: data,
      })
    }
  }

  handleColumnDragEnd = event => {
    if (
      !event.destination ||
      event.destination.index === event.source.index ||
      this.state.isFormatColumnSearchEnabled
    ) {
      return
    }

    const { formatColsStatus, expanded } = this.state
    let beginIndex = event.source.index
    const endIndex = event.destination.index
    const { data } = cloneDeep(this.props.datasetPreviewStatus)
    let columns = formatColsStatus && formatColsStatus.fields_array ? formatColsStatus.fields_array : data.fields_array
    columns = orderBy(columns, 'sort_index', 'asc')

    if (endIndex > beginIndex) {
      for (beginIndex; beginIndex < endIndex; beginIndex++) {
        const tempIndex = columns[beginIndex + 1].sort_index
        columns[beginIndex + 1].sort_index = columns[beginIndex].sort_index
        columns[beginIndex + 1].hasChanged = true
        columns[beginIndex].sort_index = tempIndex
        columns[beginIndex].hasChanged = true
        columns = orderBy(columns, 'sort_index', 'asc')
      }
    } else if (beginIndex > endIndex) {
      for (beginIndex; beginIndex > endIndex; beginIndex--) {
        const tempIndex = columns[beginIndex].sort_index
        columns[beginIndex].sort_index = columns[beginIndex - 1].sort_index
        columns[beginIndex].hasChanged = true
        columns[beginIndex - 1].sort_index = tempIndex
        columns[beginIndex - 1].hasChanged = true
        columns = orderBy(columns, 'sort_index', 'asc')
      }
    }

    if (expanded && expanded.includes(event.source.index)) {
      const matchIndex = expanded.indexOf(event.source.index)

      if (matchIndex > -1) {
        data.hasChanged = true
        expanded.splice(matchIndex, 1, event.destination.index)
      }
    }

    data.fields_array = columns
    this.setState({
      formatColsStatus: data,
      expanded,
    })
  }

  expandColLess = (column, searchFieldValue) => {
    const { data } = cloneDeep(this.props.datasetPreviewStatus)
    const { expanded, formatColsStatus } = this.state
    const columns =
      formatColsStatus && formatColsStatus.fields_array ? formatColsStatus.fields_array : data.fields_array
    let columnArray = columns
    if (searchFieldValue) {
      columnArray = advanceFilter(searchFieldValue, columns, ['column_display_name', 'field_name'])
    }

    const colIndex = orderBy(columnArray, 'sort_index', 'asc').findIndex(col => col.field_name === column.field_name)

    if (colIndex > -1) {
      expanded.splice(expanded.indexOf(colIndex), 1)

      this.setState({
        expandAll: false,
        expanded,
      })
    }
  }

  expandColMore = (column, searchFieldValue) => {
    const { data } = cloneDeep(this.props.datasetPreviewStatus)
    const { expanded, formatColsStatus } = this.state
    const columns =
      formatColsStatus && formatColsStatus.fields_array ? formatColsStatus.fields_array : data.fields_array
    let columnArray = columns
    if (searchFieldValue) {
      columnArray = advanceFilter(searchFieldValue, columns, ['column_display_name', 'field_name'])
    }
    const colIndex = orderBy(columnArray, 'sort_index', 'asc').findIndex(col => col.field_name === column.field_name)

    if (colIndex > -1) {
      expanded.push(colIndex)

      this.setState({
        expanded,
      })
    }
  }

  setFormatColumnSearchValue = searchValue => {
    const isFormatColumnSearchEnabled = Boolean(searchValue)
    this.setState({ isFormatColumnSearchEnabled })
  }

  updateSortedColumns = columns => {
    const noSortIdxCols = columns.filter(col => !col.sort_index && col.sort_index !== 0)
    if (noSortIdxCols && noSortIdxCols.length) {
      const currBeginNoSortIdx = columns.findIndex(col => col.field_name === noSortIdxCols[0].field_name)
      if (currBeginNoSortIdx > -1) {
        for (let index = currBeginNoSortIdx; index < columns.length; index++) {
          if (index !== 0) {
            columns[index].sort_index = columns[index - 1].sort_index + 1
          } else {
            columns[index].sort_index = 1
          }
        }
      }
    }
    return columns
  }

  renderDragAndDropFormatCols = () => {
    const { data } = cloneDeep(this.props.datasetPreviewStatus)
    const { formatColsStatus, expandAll, expanded } = this.state
    let columns = formatColsStatus && formatColsStatus.fields_array ? formatColsStatus.fields_array : data.fields_array

    columns = orderBy(columns, 'sort_index', 'asc')
    columns = this.updateSortedColumns(columns)

    return (
      <List>
        <Droppable droppableId="table-column-droppable">
          {dropProvided => (
            <div {...dropProvided.droppableProps} ref={dropProvided.innerRef}>
              <InnerList
                columns={columns}
                expanded={expanded}
                expandAll={expandAll}
                editDatasetColumns={this.editDatasetColumns}
                expandColLess={this.expandColLess}
                expandColMore={this.expandColMore}
                setFormatColumnSearchValue={this.setFormatColumnSearchValue}
              />
              {dropProvided.placeholder}
            </div>
          )}
        </Droppable>
      </List>
    )
  }

  renderColTable = previewData => {
    const { columnQueryResult } = this.state
    const previewColData = columnQueryResult
    const excludeCalcFieldCols = previewData.fields_array
      ? previewData.fields_array.filter(item => {
          if (
            item.type === 'metric' ||
            item.type === 'dimension' ||
            item.type === 'timeseries' ||
            item.type === 'dynamic_dimension'
          ) {
            return item
          }

          return false
        })
      : []

    const columns = this.getColumns(excludeCalcFieldCols)

    const CustomHeader = props => (
      <div
        style={{
          display: 'flex',
          verticalAlign: 'middle',
        }}
      >
        {props.isColumnHidden ? (
          <>
            <VisibilityOff />
            {props.displayName}
          </>
        ) : (
          props.displayName
        )}
      </div>
    )
    const columnsCount =
      previewColData?.length >= 100
        ? `Displaying first ${previewColData?.length} only`
        : `Total Columns: ${previewColData?.length}`
    return (
      <>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Typography variant="subtitle1">{columnsCount}</Typography>
          </Grid>
          <Grid item>
            {previewData.edit_enabled === 'yes' ? (
              <Button
                style={{ marginLeft: 'auto', display: 'flex' }}
                aria-label="Format Columns"
                color="secondary"
                onClick={this.handleFormatColsClick}
              >
                Format Columns
              </Button>
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
        <AgGridTable
          frameworkComponents={{ agColumnHeader: CustomHeader }}
          columns={columns}
          pageSize={10}
          data={previewColData}
        />
      </>
    )
  }

  getOwners = ownersList => {
    let temp = ''
    let ownerNameList = []
    if (ownersList && ownersList.length > 1) {
      ownerNameList = ownersList.map(owner => owner.user_group_name)
      temp = ownerNameList.slice(0, 1)
      temp = (
        <p className="listItem-secondary-text">
          {temp}
          {` and ${ownersList.length - 1} more`}
        </p>
      )
    } else {
      ownerNameList = ownersList.map(owner => owner.user_group_name)
      temp = <p className="listItem-secondary-text">{ownerNameList.slice(0, 1)}</p>
    }
    return temp
  }

  renderCards = () => {
    const datasetId = this.props.nonRoute ? this.props.datasetId : this.props.routeProps.match.params._id
    return <AssetTable assetType={AssetType.CARDS} datasetId={datasetId} />
  }

  renderDashboards = () => {
    const { dashboardArray } = this.state
    const columns = [
      {
        field: 'name',
        headerName: 'Name',
        cellRendererFramework: ({ data }) => <Link to={`/dashboard/${data._id}`}>{data.name}</Link>,
      },
      {
        field: 'description',
        headerName: 'Description',
      },
      {
        field: 'owners_access',
        headerName: 'Owner(s)',
        keyCreator: ({ value }) => value.map(owner => owner.user_group_name),
        cellRendererFramework: ({ data }) => getOwnerInfoChip(data.owners_access),
      },
    ]
    const dashboardCount =
      dashboardArray?.length >= 100
        ? `Displaying first ${dashboardArray?.length} only`
        : `Total Dashboards: ${dashboardArray?.length}`
    return (
      <Grid container justifyContent="space-between" alignItems="flex-start">
        <Grid item xs>
          <Typography variant="subtitle1">{dashboardCount}</Typography>
          <AgGridTable columns={columns} pageSize={10} data={dashboardArray} />
        </Grid>
      </Grid>
    )
  }

  handleCloseDeleteCFWindow = () => {
    this.setState({
      openDelWin: false,
      cfPageIndex: 0,
    })
  }

  handleCardPageChange = cardPageIndex => {
    this.setState({
      cardPageIndex,
    })
  }

  renderCalcFieldCards = (id, name) => {
    const columns = [
      {
        field: '_id',
        headerName: 'Card ID',
      },
      {
        field: 'card_title',
        headerName: 'Card Name',
        cellRendererFramework: ({ data, value }) => {
          return (
            <Link
              id={`card-link-${data._id}`}
              data-cy={`card-name-${value}`}
              className="edit-card-link"
              rel="noopener noreferrer"
              target="_blank"
              to={`/${data.viz_type === 'text' ? 'textcard' : 'card'}/${data._id}`}
            >
              {value.trim()}
            </Link>
          )
        },
      },
      {
        field: 'owners',
        headerName: 'Owners',
        cellRendererFramework: ({ data }) => (data.owners ? data.owners.join(', ') : ''),
      },
    ]
    let calcField = []
    if (this.props.datasetCalcFields.data) {
      calcField = this.props.datasetCalcFields.data.filter(item => {
        if (item._id === id || item.column_display_name === name) {
          return item.cards
        }

        return false
      })
    }
    return <AgGridTable columns={columns} pageSize={this.state.pageSize} data={calcField[0].cards} />
  }

  openDelCalcFieldWin = () => {
    const { selectedCalcField, openDelWin } = this.state
    const calcField = {
      id: selectedCalcField.id,
      name: selectedCalcField.name,
    }
    return (
      <Popover
        open={openDelWin}
        onClose={() => this.setState({ popoverAnchorEl: null })}
        className="dataset-delete-popover-form calc-field-del"
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        anchorEl={this.state.popoverAnchorEl}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
      >
        <header className="header-container">
          <Typography variant="h2" className="title">
            CARDS
          </Typography>
        </header>
        <div className="content-container">
          <Card className="table-list-card">
            <CardContent classes={{ root: 'table-list-cardContent' }}>
              <div className="dataset-info">
                <Icon classes={{ root: 'warning-icon' }}>warning</Icon>
                <Typography className="warning-content">
                  The calculated field &apos;
                  {calcField.name}
                  &apos; is used by the below listed cards. Delete the calculated fields from the cards to proceed
                  deleting the Calculated Field.
                </Typography>
              </div>
              {this.renderCalcFieldCards(calcField.id, calcField.name)}
            </CardContent>
          </Card>
        </div>
        <footer className="footer">
          <Button aria-label="Ok" color="primary" onClick={this.handleCloseDeleteCFWindow}>
            OK
          </Button>
        </footer>
      </Popover>
    )
  }

  handleOnClickDelCalcField = (event, cfId, cfName) => {
    // Display dependent cards, on delete dataset
    const selectedCalcField = {
      id: cfId,
      name: cfName,
    }
    this.setState({
      selectedCalcField,
      popoverAnchorEl: event.currentTarget,
    })
    const calcField = this.props.datasetCalcFields.data.filter(item => {
      if (item.column_display_name === cfName || item._id === cfId) {
        return item.cards
      }

      return false
    })
    if (calcField[0] && calcField[0].cards.length > 0) {
      this.setState({
        openDelWin: true,
      })
    } else {
      this.setState({
        open: true,
      })
    }
  }

  saveCalculatedFieldSuccess = () => {
    const datasetId = this.state.selectedDatasetId
    this.setState(
      {
        calculatedDialogOpen: false,
      },
      () => {
        this.props.getDatasetCalcFields({ datasetId })
      }
    )
  }

  handleCancelCalculatedField = () => {
    this.setState({
      calculatedDialogOpen: false,
    })
  }

  // Open calculated field propmt
  handleOnClickAddCalcField = () => {
    const selectedDatasetId = this.props.routeProps.match.params._id
    this.setState({
      calculatedDialogOpen: true,
      selectedDatasetId,
    })
  }

  handleSearchCFChange = event => {
    const searchCFValue = event.target.value
    this.setState({
      searchCFValue,
      cfPageIndex: 0,
    })
  }

  renderCalcField = () => {
    const { calculatedDialogOpen, disableNonOwnerCfCreation } = this.state
    const { datasetPreviewStatus } = this.props

    return (
      <>
        <Grid container alignItems="center" justifyContent="space-between" style={{ marginBottom: '0.2rem' }}>
          <Grid item xs>
            <Grid container alignItems="center" justifyContent="flex-end" alignContent="flex-end">
              {(datasetPreviewStatus.data.edit_enabled === 'yes' || !disableNonOwnerCfCreation) && (
                <Grid item xs className="button-align">
                  <Button
                    color="secondary"
                    className="icon-label-btn-props add-calculated-field"
                    aria-label="Add Calculated Field"
                    data-cy="preview-add-cf-btn"
                    id="preview-add-cf-btn"
                    startIcon={<Add />}
                    onClick={this.handleOnClickAddCalcField}
                  >
                    Add Calculated field
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        <AssetTable
          assetType={AssetType.CALC_FIELDS}
          datasetId={this.props.nonRoute ? this.props.datasetId : this.props.routeProps.match.params._id}
          datasetPreviewStatus={this.props.datasetPreviewStatus}
        />
        {/* Add Calc Field */}
        {calculatedDialogOpen && (
          <CalcFieldAddEditDialog
            calculatedDialogOpen={this.state.calculatedDialogOpen}
            selectedDatasetId={this.state.selectedDatasetId}
            calculatedFieldDetail={this.state.calculatedFieldDetail}
            datasetObject={this.props.datasetObject}
            datasetCalcFields={this.props.datasetCalcFields}
            handleCalculatedFieldSuccess={this.saveCalculatedFieldSuccess}
            onHandleCancelCalculatedField={this.handleCancelCalculatedField}
          />
        )}
      </>
    )
  }

  renderUploadHistory = () => {
    const { uploadHistoryList, ingestLogDialogVisiblityIndex, ingestLogs } = this.state
    const fileDownloadClick = () => {
      const link = document.createElement('a')
      link.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(ingestLogs))
      link.setAttribute('download', 'ingest-download')

      document.body.appendChild(link)
      link.click()
      link.remove()
    }
    const openInNewClick = index => {
      this.setState({
        ingestLogDialogVisiblityIndex: index,
      })
    }

    const data = uploadHistoryList.map(row => ({
      status: row.status,
      startTime: `${moment(row.created_date.toLocaleString()).format('MMMM D YYYY[\n]h:mm A')} ${new Date()
        .toLocaleDateString(undefined, { timeZoneName: 'short' })
        .split(',')[1]
        ?.trim()}`,
      stopTime: row.completed_date
        ? `${moment(row.completed_date.toLocaleString()).format('MMMM D YYYY[\n]h:mm A')}  ${new Date()
            .toLocaleDateString(undefined, { timeZoneName: 'short' })
            .split(',')[1]
            ?.trim()}`
        : null,
      runTime: (() => {
        const start = new Date(moment(row.created_date).format())
        const end = new Date(moment(row.completed_date).format())
        const run = moment(end - start).format('m:ss')

        return `${run} minutes`
      })(),
    }))
    const columns = [
      {
        field: 'status',
        headerName: 'Status',
      },
      {
        field: 'startTime',
        headerName: 'Start Time',
      },
      {
        field: 'stopTime',
        headerName: 'Stop Time',
      },
      {
        field: 'runTime',
        headerName: 'Run Time',
      },
      {
        field: 'id',
        headerName: 'Ingest Logs',
        sortable: false,
        filter: false,
        cellRendererFramework: props => (
          <div className="upload-actions">
            <Tooltip title="Download logs" placement="top">
              <span>
                <IconButton onClick={() => fileDownloadClick()}>
                  <CloudDownload />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title="Open Logs" placement="top">
              <span>
                <IconButton onClick={() => openInNewClick(props.rowIndex)}>
                  <OpenInNew />
                </IconButton>
              </span>
            </Tooltip>
          </div>
        ),
      },
    ]

    return (
      <Box className="dataset-preview-box">
        <Dialog
          fullWidth
          maxWidth="md"
          open={Boolean(ingestLogDialogVisiblityIndex > -1)}
          onClose={() => this.setState({ ingestLogDialogVisiblityIndex: -1 })}
        >
          <DialogTitle>Ingest Logs</DialogTitle>
          <DialogContent>{ingestLogs}</DialogContent>
        </Dialog>
        <AgGridTable data={data} columns={columns} pageSize={5} />
      </Box>
    )
  }

  renderCard(previewData) {
    const {
      routeProps,
      datasetPreview,
      datasetCalcFieldCountStatus,
      trackEvent,
      refreshDatasetStatus,
      refreshDataset,
      refreshDatasetMultipleDates,
      featureFlags,
      datasetRefreshMultipleDatesStatus,
      disableMultipleDates,
    } = this.props

    const {
      openDelWin,
      isFormatCols,
      formatColsStatus,
      uploadStatus,
      isShowHelp,
      supportText,
      showDatasetDescription,
      openActionsMenu,
    } = this.state

    const { _siteName } = routeProps.match.params

    const tabList = [
      { id: 'columns', name: 'COLUMNS', path: '/columns' },
      { id: 'cards', name: 'CARDS', path: '/cards' },
      { id: 'dashboards', name: 'DASHBOARDS', path: '/dashboards' },
      { id: 'cf', name: 'CALCULATED FIELDS', path: '/calc-fields' },
    ]

    if (uploadStatus) {
      tabList.push({ id: 'upload', name: 'UPLOAD HISTORY', path: '/upload' })
    }

    const a11yProps = index => {
      return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
      }
    }
    const { _id } = routeProps.match.params
    const retention = datasetPreview.data?.data_retention
    const isMultidateEnabled =
      datasetPreview.data?.date_auto_detect_enabled || datasetRefreshMultipleDatesStatus.data?.length
    const isDisableMultipleDateButton =
      datasetPreview.data.database_name.indexOf(',') > -1 ||
      datasetPreview.data.database_type !== 'Druid' ||
      (datasetPreview.data.sources.length && datasetPreview.data.sources.includes('realtime'))

    const isOwnerOrAdmin = datasetPreview.data.edit_enabled === 'yes'
    const isRefreshEnabledFeatureFlag = featureFlags.data?.REFRESH_MULTIPLE_DATES

    const handleMoreButtonClick = event => {
      this.setState({
        openActionsMenu: event.currentTarget,
      })
    }

    const handleMoreButtonClose = () => {
      this.setState({
        openActionsMenu: null,
      })
    }

    return (
      <>
        <Box m={2}>
          <Link to="/datasets">
            <Stack direction="row" alignItems="center" gap={1} justifyContent="flex-start">
              <ArrowBackIos sx={{ fontSize: 15 }} />
              <Typography variant="button">Back to Dataset Listing</Typography>
            </Stack>
          </Link>
          <Card>
            <CardContent>
              <Grid container alignContent="center" justifyContent="space-between">
                <Grid item>
                  <Stack direction="row" alignItems="center" gap={1} justifyContent="center">
                    <Typography tabIndex="0" variant="h5" data-cy="dataset-display-name">
                      {previewData.dataset_business_name}
                      {isCertifiedDataset(previewData) ? <CertifiedIcon className="dataset-certified-icon" /> : ''}
                    </Typography>
                    <Tooltip title="Help" placement="top">
                      <IconButton id="card-view-help" onClick={this.handleShowSupportText} aria-label="help">
                        <Help className="align-icon" />
                      </IconButton>
                    </Tooltip>
                    {isMultidateEnabled ? (
                      <Chip
                        size="small"
                        label="Multiple dates enabled"
                        sx={{ background: '#fdddbe' }}
                        data-cy="dataset-multiple-dates-enabled-chip"
                      />
                    ) : (
                      ''
                    )}
                  </Stack>
                  {isShowHelp && (
                    <DatasetSupportDialog
                      savedSupportText={supportText}
                      onCloseSupportText={() => this.setState({ isShowHelp: false })}
                    />
                  )}
                </Grid>
                <Grid item>
                  <Grid container justifyContent="flex-end" alignItems="center">
                    <Button
                      style={{ display: 'none' }}
                      data-cy="dataset-disable-multiple-dates"
                      onClick={() => {
                        disableMultipleDates({
                          _id: datasetPreview.data._id,
                        })
                      }}
                    >
                      Disable Multiple Dates
                    </Button>
                    <Button
                      startIcon={<LibraryAdd />}
                      color="secondary"
                      component={Link}
                      to={`/builder/card/dataset/${datasetPreview?.data?._id}`}
                    >
                      Create Card
                    </Button>
                    {previewData.edit_enabled === 'yes' && (
                      <IconButton
                        sx={{ border: '1px solid', borderRadius: '5px', padding: '5px', height: 38 }}
                        onClick={handleMoreButtonClick}
                        data-cy="dataset-more-options-button"
                      >
                        <MoreVert fontSize="inherit" />
                      </IconButton>
                    )}
                    <Menu
                      open={Boolean(openActionsMenu)}
                      anchorEl={openActionsMenu}
                      onClose={handleMoreButtonClose}
                      PaperProps={{
                        sx: {
                          overflow: 'visible',
                          color: 'black',
                        },
                      }}
                    >
                      <ListSubheader component="div" id="list-subheader">
                        MANAGE
                      </ListSubheader>
                      <MenuItem
                        data-cy="edit-dataset"
                        id="edit-dataset"
                        aria-label="Edit Dataset"
                        component={Link}
                        to={
                          _siteName
                            ? `/site/${_siteName}/dataset/edit/${previewData._id}`
                            : `/dataset/edit/${previewData._id}`
                        }
                      >
                        <ListItemIcon>
                          <EditOutlined />
                        </ListItemIcon>
                        <ListItemText primary="Edit Dataset" />
                      </MenuItem>
                      <MenuItem
                        aria-label="Delete Dataset"
                        onClick={() => {
                          datasetPreview.data?.cards?.length > 0
                            ? this.setState({ datasetCardsDialogOpen: true })
                            : this.setState({ isDeleteDatasetDialogOpen: true })
                        }}
                      >
                        <ListItemIcon>
                          <DeleteOutline />
                        </ListItemIcon>
                        <ListItemText primary="Delete Dataset" />
                      </MenuItem>
                      <MenuItem
                        disabled={refreshDatasetStatus?.status === 'requested'}
                        aria-label="Refresh Dataset"
                        onClick={() => {
                          this.setState({ isRefreshDatasetDialogOpen: true })
                        }}
                      >
                        <ListItemIcon>
                          {refreshDatasetStatus?.status === 'requested' ? (
                            <Spinner size="small" style={{ width: '20px', height: '20px' }} />
                          ) : (
                            <Refresh />
                          )}
                        </ListItemIcon>
                        <ListItemText primary="Refresh Dataset" />
                      </MenuItem>
                      <MenuItem
                        aria-label="create-dataset-notification"
                        component={Link}
                        data-cy="create-dataset-preview-notification"
                        to={`/notification-manager/edit/dataset/${datasetPreview?.data?._id}`}
                      >
                        <ListItemIcon>
                          <NotificationAddOutlined />
                        </ListItemIcon>
                        <ListItemText primary="Create Notification" />
                      </MenuItem>
                      <MenuItem
                        aria-label="view-dataset-notification"
                        component={Link}
                        data-cy="view-dataset-preview-notification"
                        to={`/notification-manager/dataset/${datasetPreview?.data?._id}`}
                      >
                        <ListItemIcon>
                          <NotificationsNone />
                        </ListItemIcon>
                        <ListItemText primary="View Notification(s)" />
                      </MenuItem>
                      {isMultidateEnabled ? (
                        <MenuItem data-cy="dataset-multidate-enabled" disabled>
                          <ListItemIcon>
                            <Done />
                          </ListItemIcon>
                          <ListItemText primary="Multiple dates enabled" />
                        </MenuItem>
                      ) : isOwnerOrAdmin && isRefreshEnabledFeatureFlag && !isDisableMultipleDateButton ? (
                        <MenuItem
                          data-cy="dataset-enable-multiple-dates"
                          onClick={() => {
                            trackEvent({
                              event: {
                                type: 'dataset-enable-multiple-dates-clicked',
                              },
                            })
                            refreshDatasetMultipleDates({
                              _id: datasetPreview.data._id,
                            })
                          }}
                          disabled={datasetRefreshMultipleDatesStatus.status === 'requested'}
                        >
                          <ListItemIcon>
                            {datasetRefreshMultipleDatesStatus.status === 'requested' ? (
                              <Spinner size="small" style={{ width: '20px', height: '20px' }} />
                            ) : (
                              <DateRangeOutlined />
                            )}
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              datasetRefreshMultipleDatesStatus.status === 'requested'
                                ? 'Processing...'
                                : 'Enable Multiple dates'
                            }
                          />
                        </MenuItem>
                      ) : null}
                    </Menu>
                  </Grid>
                </Grid>
              </Grid>
              <Grid sx={{ marginTop: '2rem' }} container alignContent="flex-start" justifyContent="space-between">
                <Grid item>
                  <Typography tabIndex="0" variant="subtitle1" style={{ fontWeight: 'bold' }}>
                    Datasource
                  </Typography>
                  <Typography tabIndex="0">{`${previewData.database_name} (${previewData.database_type})`}</Typography>
                </Grid>
                <Divider orientation="vertical" variant="middle" flexItem />
                <Grid item>
                  <Typography tabIndex="0" variant="subtitle1" style={{ fontWeight: 'bold' }}>
                    Security
                  </Typography>
                  {!previewData.rls_enabled &&
                  previewData.data_classification === 'Internal' &&
                  !previewData.enable_vendor_access ? (
                    <Typography tabIndex="0" style={{ textAlign: 'center' }}>
                      None
                    </Typography>
                  ) : (
                    previewData.rls_enabled && (
                      <Tooltip title="RLS Enabled" placement="top">
                        <Icon aria-label="RLS Enabled">
                          <Lock />
                        </Icon>
                      </Tooltip>
                    )
                  )}
                  {previewData.data_classification === 'Confidential' && (
                    <Tooltip title="Confidential" placement="top">
                      <Icon aria-label="Confidential">
                        <Security />
                      </Icon>
                    </Tooltip>
                  )}
                  {previewData.enable_vendor_access && (
                    <Tooltip title="Vendor Access Enabled" placement="top">
                      <Icon aria-label="Vendor Access Enabled">
                        <Public />
                      </Icon>
                    </Tooltip>
                  )}
                </Grid>
                <Divider orientation="vertical" variant="middle" flexItem />
                <Grid item>
                  <Typography tabIndex="0" variant="subtitle1" style={{ fontWeight: 'bold' }}>
                    Rows
                  </Typography>
                  <Typography tabIndex="0" style={{ textAlign: 'center' }}>
                    {previewData.record_count &&
                      previewData.record_count.toLocaleString(navigator.language, { minimumFractionDigits: 0 })}
                  </Typography>
                </Grid>
                <Divider orientation="vertical" variant="middle" flexItem />
                <Grid item>
                  <Typography tabIndex="0" variant="subtitle1" style={{ fontWeight: 'bold' }}>
                    Columns
                  </Typography>
                  <Typography tabIndex="0" style={{ textAlign: 'center' }}>
                    {previewData.column_count &&
                      previewData.column_count.toLocaleString(navigator.language, { minimumFractionDigits: 0 })}
                  </Typography>
                </Grid>
                <Divider orientation="vertical" variant="middle" flexItem />
                <Grid item>
                  <Typography tabIndex="0" variant="subtitle1" style={{ fontWeight: 'bold' }}>
                    Calculated Fields
                  </Typography>
                  <Typography tabIndex="0" style={{ textAlign: 'center' }}>
                    {datasetCalcFieldCountStatus?.data?.count}
                  </Typography>
                </Grid>
                <Divider orientation="vertical" variant="middle" flexItem />

                {retention ? (
                  <>
                    <Grid item>
                      <Typography tabIndex="0" variant="subtitle1" style={{ fontWeight: 'bold' }}>
                        Retention
                      </Typography>
                      <Typography tabIndex="0" style={{ textAlign: 'center' }}>
                        {`${retention.n} ${
                          (retention.units !== 'Week' &&
                            retention.units !== 'Day' &&
                            retention.calendar_type?.toLowerCase()) ||
                          ''
                        } ${retention.units.toLowerCase()}s completed, plus current ${retention.units.toLowerCase()}`}
                      </Typography>
                    </Grid>
                    <Divider orientation="vertical" variant="middle" flexItem />
                  </>
                ) : null}
                <Grid item>
                  <Typography tabIndex="0" variant="subtitle1" style={{ fontWeight: 'bold' }}>
                    Last Loaded
                  </Typography>
                  {previewData.last_load_date && (
                    <Typography tabIndex="0" style={{ textAlign: 'center' }}>
                      {moment(previewData.last_load_date).format('MMMM Do YYYY, h:mm:ss a')}
                    </Typography>
                  )}
                </Grid>
                <Divider orientation="vertical" variant="middle" flexItem />
                <Grid item>
                  <Typography tabIndex="0" variant="subtitle1" style={{ fontWeight: 'bold' }}>
                    Last Updated
                  </Typography>
                  {previewData.last_load_date && (
                    <Typography tabIndex="0" style={{ textAlign: 'center' }}>
                      {moment(previewData.last_updated).format('MMMM Do YYYY, h:mm:ss a')}
                    </Typography>
                  )}
                </Grid>
              </Grid>
              <Grid sx={{ marginTop: '2rem' }} container justifyContent="flex-end">
                {this.props.currentUser?.data?.user_type === 'internal' && previewData.datastream_action_url && (
                  <Link to={{ pathname: previewData.datastream_action_url }} target="_blank">
                    <Button
                      color="secondary"
                      startIcon={<img src={AutomationPortalLogo} alt="dp-logo" style={{ height: 20 }} />}
                      onClick={() => {
                        trackEvent({
                          event: {
                            type: 'dataset-automation-portal-link-click',
                          },
                        })
                      }}
                    >
                      View in Automation Portal
                    </Button>
                  </Link>
                )}
                {this.props.currentUser?.data?.user_type === 'internal' && previewData.data_portal?.data_portal_url && (
                  <Link to={{ pathname: previewData.data_portal.data_portal_url }} target="_blank">
                    <Button
                      color="secondary"
                      startIcon={<img src={DataPortalLogo} alt="dp-logo" style={{ height: 20 }} />}
                      onClick={() => {
                        trackEvent({
                          event: {
                            type: 'dataportal-link-click',
                          },
                        })
                      }}
                    >
                      View in DataPortal
                    </Button>
                  </Link>
                )}
              </Grid>
            </CardContent>
            {datasetPreview?.status === 202 && previewData?.message && (
              <Typography tabIndex="0" style={{ background: '#efefef', padding: '10px' }} align="justify">
                <Error style={{ verticalAlign: 'middle', marginRight: '5px', color: '#2278cf' }} />
                {previewData.message}
              </Typography>
            )}
          </Card>
        </Box>
        <Grid container justifyContent="space-between">
          <Grid item xs={3} sm={12} md={12} lg={3} sx={{ display: showDatasetDescription ? 'flex' : 'none' }}>
            <Grid container direction="column">
              <Grid item>
                <DatasetDetailsComponent
                  {...this.state}
                  {...this.props}
                  setShowDatasetDescription={value => this.setState({ showDatasetDescription: value })}
                />
              </Grid>
              <Grid item>
                <DatasetSecurityComponent {...this.state} {...this.props} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item sx={{ flexGrow: 1 }}>
            <Box m={2} mt={0} sx={{ position: 'relative' }}>
              {openDelWin && this.openDelCalcFieldWin()}
              <Card>
                <CardContent>
                  {!showDatasetDescription && (
                    <Tooltip title="Show Details">
                      <SFloatingIconButton
                        size="small"
                        onClick={() => this.setState({ showDatasetDescription: !showDatasetDescription })}
                        aria-label="show details"
                        sx={{ left: '-1px' }}
                      >
                        <ArrowForward />
                      </SFloatingIconButton>
                    </Tooltip>
                  )}
                  <Route
                    path={`/dataset/preview/${_id}`}
                    render={() => (
                      <>
                        <Tabs value={location.pathname} variant="fullWidth">
                          {tabList.map((tab, key) => {
                            return (
                              <Tab
                                key={key}
                                {...a11yProps(key)}
                                component={Link}
                                label={<Typography variant="button">{tab.name}</Typography>}
                                value={`/dataset/preview/${_id}${tab.path}`}
                                to={`/dataset/preview/${_id}${tab.path}`}
                                data-cy={`${tab.name}`}
                                onClick={() => {
                                  if (key === 4) {
                                    trackEvent({
                                      event: {
                                        type: 'datasetpreview-datasetbuilder-uploadhistory-clicked',
                                      },
                                    })
                                  } else if (key === 2) {
                                    trackEvent({
                                      event: {
                                        type: 'datasetpreview-dashboard-tab-clicked',
                                      },
                                    })
                                  }
                                }}
                              />
                            )
                          })}
                        </Tabs>
                        <Switch>
                          <Box p={2}>
                            <Route
                              path={`/dataset/preview/${_id}${tabList[0].path}`}
                              render={() => this.renderColTable(previewData)}
                            />
                            <Route
                              path={`/dataset/preview/${_id}${tabList[1].path}`}
                              render={() => this.renderCards()}
                            />
                            <Route
                              path={`/dataset/preview/${_id}${tabList[2].path}`}
                              render={() => this.renderDashboards()}
                            />
                            <Route
                              path={`/dataset/preview/${_id}${tabList[3].path}`}
                              render={() => this.renderCalcField()}
                            />
                            {tabList[4] && (
                              <Route
                                path={`/dataset/preview/${_id}${tabList[4].path}`}
                                render={() => this.renderUploadHistory()}
                              />
                            )}
                          </Box>
                        </Switch>
                      </>
                    )}
                  />
                  {isFormatCols && (
                    <Dialog fullWidth maxWidth="md" className="format-column-popover-form" open={isFormatCols}>
                      <DialogTitle className="header-container">
                        <Grid container justifyContent="flex-start" alignItems="center">
                          <Grid item xs>
                            <Typography variant="h6" className="title">
                              FORMAT COLUMNS
                            </Typography>
                          </Grid>
                          <Grid item xs>
                            <Grid container alignItems="center" justifyContent="flex-end" alignContent="flex-end">
                              <Button color="secondary" onClick={this.handleExpandAll}>
                                Expand all
                              </Button>
                              <Button color="secondary" onClick={this.handleCollapseAll}>
                                Collapse all
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      </DialogTitle>
                      <DialogContent dividers={scroll === 'paper'}>
                        <DragDropContext className="col-fn-height" onDragEnd={this.handleColumnDragEnd}>
                          <SwipeableViews>{this.renderDragAndDropFormatCols()}</SwipeableViews>
                        </DragDropContext>
                      </DialogContent>
                      <DialogActions className="format-col-buttons">
                        <Button variant="text" color="secondary" onClick={this.handleFormatCancel}>
                          Cancel
                        </Button>
                        <Button
                          disabled={formatColsStatus && !formatColsStatus.fields_array}
                          variant="text"
                          className="fmt-save-btn"
                          color="primary"
                          onClick={this.handleFormatColSave}
                        >
                          Save And close
                        </Button>
                      </DialogActions>
                    </Dialog>
                  )}
                </CardContent>
              </Card>
            </Box>
          </Grid>
        </Grid>
        {this.state.datasetCardsDialogOpen && (
          <DatasetDeleteDialog
            hasDependentCards={this.state.datasetCardsDialogOpen}
            datasetDetail={datasetPreview?.data}
            handleCloseDeleteDatasetDialog={() => this.setState({ datasetCardsDialogOpen: false })}
          />
        )}
        <ConfirmDialog
          open={this.state.isDeleteDatasetDialogOpen}
          contentText={`Are you sure you want to delete dataset - ${previewData.dataset_business_name}? (There is no undo)`}
          okText="DELETE"
          onCloseDialog={() => this.setState({ isDeleteDatasetDialogOpen: false })}
          onClickOk={this.handleDeleteDataset}
          onClickCancel={() => this.setState({ isDeleteDatasetDialogOpen: false })}
        />
        {this.renderRefreshDialog(refreshDataset)}
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          data-cy="enable-multiple-dates-no-columns"
          open={this.state.warningMessage}
          autoHideDuration={6000}
          onClose={this.handleSnackBarClose}
          action={[<Close key="error-message-close-icon" onClick={this.handleSnackBarClose} />]}
        >
          <Alert severity="warning">
            We were unable to detect Columns that meet the criteria for the Multiple Date Process
          </Alert>
        </Snackbar>
      </>
    )
  }

  handleSnackBarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    this.setState({ warningMessage: false })
  }

  handleCloseDialog = () => {
    this.setState({ open: false })
  }

  handleDeleteCalcField = () => {
    const delCalcField = this.state.selectedCalcField.id
    this.props.delCalculatedField({ delCalcField })
  }

  renderRefreshDialog = refreshDataset => {
    const data = this.props.datasetPreview?.data || {}
    const { isRefreshDatasetDialogOpen, isInvalidateCacheEnabled, isGenerateRefreshEventEnabled } = this.state

    return (
      <Dialog maxWidth="md" open={isRefreshDatasetDialogOpen}>
        <DialogTitle className="header-container">
          <Typography variant="h6" className="title">
            Refresh Dataset
          </Typography>
        </DialogTitle>
        <DialogContent>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  aria-label="Invalidate all query caches for this dataset"
                  checked={isInvalidateCacheEnabled}
                  onChange={() => this.setState({ isInvalidateCacheEnabled: !isInvalidateCacheEnabled })}
                  id="ref-dataset-invalidate-cache"
                />
              }
              label="Invalidate all query caches for this dataset"
            />
            <FormControlLabel
              control={
                <Checkbox
                  aria-label="Generate REFRESH_DATASET event to trigger subscriptions"
                  checked={isGenerateRefreshEventEnabled}
                  onChange={this.handleGenerateRefreshEvent}
                  id="ref-dataset-event"
                />
              }
              label="Generate REFRESH_DATASET event to trigger subscriptions"
            />
          </FormGroup>
        </DialogContent>
        <DialogActions className="format-col-buttons">
          <Button
            aria-label="Cancel"
            variant="text"
            color="secondary"
            onClick={() => {
              this.setState({
                isRefreshDatasetDialogOpen: false,
                isInvalidateCacheEnabled: false,
                isGenerateRefreshEventEnabled: false,
              })
            }}
          >
            Cancel
          </Button>
          <Button
            aria-label="Submit"
            variant="text"
            className="fmt-save-btn"
            color="primary"
            onClick={() => {
              refreshDataset({
                greenfield_platform: data.database_name,
                dataset_name: data.dataset_name,
                invalidate_cache: isInvalidateCacheEnabled,
                generate_event: isGenerateRefreshEventEnabled,
              })

              this.setState({
                isRefreshDatasetDialogOpen: false,
                isInvalidateCacheEnabled: false,
                isGenerateRefreshEventEnabled: false,
              })
            }}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  handleGenerateRefreshEvent = () => {
    this.setState({ isGenerateRefreshEventEnabled: !this.state.isGenerateRefreshEventEnabled })
  }

  renderMain(data) {
    const { open } = this.state
    return (
      <>
        <ConfirmDialog
          open={open}
          contentText="Are you sure you want to delete the selected calculated field? There is no undo."
          okText="DELETE"
          onCloseDialog={this.handleCloseDialog}
          onClickOk={this.handleDeleteCalcField}
          onClickCancel={this.handleCloseDialog}
        />
        {this.renderCard(data)}
      </>
    )
  }

  updateOnUpdateDatasetStatus(prevProps, prevState) {
    const { updateDatasetStatus, displayServiceErrorMessage } = this.props
    const { securityControlUpdated } = this.state

    if (!isEmpty(updateDatasetStatus) && !isEqual(updateDatasetStatus, prevProps.updateDatasetStatus)) {
      switch (updateDatasetStatus.status) {
        case 'failed':
          displayServiceErrorMessage('Something went wrong while requesting the list of datasets.')
          this.setState({ isEditable: false })
          break
        default:
          if (!isEmpty(updateDatasetStatus) && updateDatasetStatus.status >= 200 && updateDatasetStatus.status < 300) {
            securityControlUpdated && updateDatasetStatus.data && updateDatasetStatus.data.security_control
              ? this.setState({
                  securityControlUpdated: false,
                  securityControlObj: updateDatasetStatus.data.security_control,
                })
              : this.setState({
                  isEditable: false,
                })

            this.setState({
              isFormatCols: false,
              formatColsStatus: [],
            })

            if (!prevState.isEditable) {
              const datasetId = updateDatasetStatus.data._id
              this.props.getDatasetPreview({ datasetId })
            }
          }
          break
      }
    }
  }

  previewDatasetRouter() {
    const { datasetPreview } = this.props

    if (!isEmpty(datasetPreview)) {
      switch (datasetPreview.status) {
        case 'requested':
          return <Spinner className="spinner-center" size="large" layout="selfCentering" />
        case 'failed':
          return (
            <CardContent className="failMessage">
              <Icon className="notFoundIcon">not_interested</Icon>
              <Typography>
                {createErrMsg(datasetPreview, 'Something went wrong with the request to render this card.')}
              </Typography>
              <AccessDeniedViewOwner errorData={datasetPreview?.message?.data} />
            </CardContent>
          )
        default:
          return this.props.datasetPreview.data ? this.renderMain(datasetPreview.data) : null
      }
    }
  }

  updateOnCalcFieldDelStatus(prevProps) {
    const { calcFieldDelStatus, displayServiceErrorMessage, datasetPreview } = this.props

    if (!isEmpty(calcFieldDelStatus) && !isEqual(calcFieldDelStatus, prevProps.calcFieldDelStatus)) {
      const errorMessage = calcFieldDelStatus?.response?.data?.message
      switch (calcFieldDelStatus.status) {
        case 'failed':
          displayServiceErrorMessage(errorMessage || 'Something went wrong while deleting selected calculated-field.')
          this.setState({ open: false })
          return null
        default:
          if (calcFieldDelStatus.status === 204) {
            this.props.getDatasetCalcFields({ datasetId: datasetPreview.data._id })
            this.setState({ open: false })
          }
          break
      }
    }
  }

  updateOnCalcFieldUpdateStatus(prevProps) {
    const { editCalculatedFieldStatus } = this.props
    const { formatColsStatus } = this.state

    if (
      !isEmpty(editCalculatedFieldStatus) &&
      !isEqual(editCalculatedFieldStatus, prevProps.editCalculatedFieldStatus)
    ) {
      switch (editCalculatedFieldStatus.status) {
        case 'failed':
          return null
        default:
          if (
            editCalculatedFieldStatus &&
            editCalculatedFieldStatus.status >= 200 &&
            editCalculatedFieldStatus.status < 300
          ) {
            if (formatColsStatus && formatColsStatus.fields_array) {
              const existingIndex = formatColsStatus.fields_array.findIndex(
                col => col._id === editCalculatedFieldStatus.data._id
              )
              if (existingIndex > -1) {
                formatColsStatus.fields_array.splice(existingIndex, 1, editCalculatedFieldStatus.data)
              }

              this.setState({
                formatColsStatus,
              })
            }
          }
          break
      }
    }
  }

  updateOnDatasetPreview(prevProps, prevState) {
    const { datasetPreview, routeProps, siteObj, setHeaderTitle } = this.props
    const { cardArray, dashboardArray, columnQueryResult } = this.state
    if (
      !isEmpty(datasetPreview) &&
      !isEqual(datasetPreview, prevProps.datasetPreview) &&
      (prevState.isDefaultOwnerPresent || prevState.isDefaultBusinessAreaPresent)
    ) {
      const { _siteName } = routeProps.match.params
      if (_siteName && siteObj && siteObj.status >= 200 && siteObj.status < 300) {
        setHeaderTitle(`Preview Dataset in ${siteObj?.data?.name}`)
      }

      if (
        !prevState.isEditable &&
        (!prevState.addedOwners || !prevState.addedBusinessAreas || !prevState.privacyInfo)
      ) {
        const ownerAccess = datasetPreview.data ? cloneDeep(datasetPreview.data.owners_access) : []
        const addedOwners = ownerAccess.map(owner => {
          const label = owner.user_group_name || owner.label
          return {
            label,
            _id: owner._id,
            type: owner.type,
          }
        })

        const businessUses = datasetPreview.data ? cloneDeep(datasetPreview.data.business_use) : []
        const addedBusinessAreas = businessUses.map(owner => {
          return { label: owner }
        })

        const selectedBusinessAreas = businessUses.map(owner => {
          return owner
        })

        const privacy = datasetPreview.data ? cloneDeep(datasetPreview.data.data_classification) : ''

        const enableVendors =
          datasetPreview.data && datasetPreview.data.enable_vendor_access
            ? cloneDeep(datasetPreview.data.enable_vendor_access)
            : false

        const disableNonOwnerCfCreation = Boolean(
          datasetPreview.data && datasetPreview.data.disable_non_owner_cf_creation
        )

        const userViewAccess = datasetPreview.data ? cloneDeep(datasetPreview.data.viewers_access) : []
        const addedViewers = userViewAccess.map(viewer => {
          const label = viewer.user_group_name ? viewer.user_group_name : viewer.label ? viewer.label : ''
          return {
            label,
            _id: viewer._id,
            type: viewer.type,
            user_type: viewer.user_type,
          }
        })

        const supportText = datasetPreview.data ? cloneDeep(datasetPreview.data.support_details.text) : ''

        const securityControlObj = datasetPreview.data ? cloneDeep(datasetPreview.data.security_control) : {}
        const cards = datasetPreview?.data?.cards || cardArray
        const dashboards = datasetPreview?.data?.dashboards || dashboardArray

        // eslint-disable-next-line camelcase
        const queryResult = datasetPreview?.data?.query_results?.result || columnQueryResult

        this.setState({
          addedOwners,
          addedBusinessAreas,
          selectedBusinessAreas,
          privacy,
          addedViewers,
          addedViewersSave: addedViewers,
          securityControlObj,
          enableVendors,
          supportText,
          disableNonOwnerCfCreation,
          cardArray: cards,
          dashboardArray: dashboards,
          columnQueryResult: queryResult,
        })
      }
      if (!isEqual(datasetPreview, prevProps.datasetPreview)) {
        const securityControlObj = datasetPreview.data ? cloneDeep(datasetPreview.data.security_control) : {}

        this.setState({
          securityControlObj,
        })
      }
    }
  }

  render() {
    const { isOpenDatasetRestoreDialog } = this.state
    const handleRestoreDataset = () => {
      if (this.props.routeProps.match.params?._id) {
        axios
          .delete(
            `${API_GATEWAY_URL}/bi_reporting_assets/v2/datasets/${this.props.routeProps.match.params._id}?type=undelete`
          )
          .then(() => {
            this.setState({ isOpenDatasetRestoreDialog: false })
            window.location.reload()
          })
          .catch(e => this.setState({ errorMessage: e.message || 'Something went wrong, plese try again!' }))
      }
    }
    return this.props.isMobile ? (
      renderMobileMessage()
    ) : this.props.datasetPreview?.data?.dataset_business_name === '<dataset not found>' ? (
      <>
        <Route component={NotFoundPage} />
        <ConfirmDialog
          open={isOpenDatasetRestoreDialog}
          contentText="Are you sure you want to restore this dataset ?"
          okText="RESTORE"
          onCloseDialog={() => this.setState({ isOpenDatasetRestoreDialog: false })}
          onClickOk={handleRestoreDataset}
          onClickCancel={() => this.setState({ isOpenDatasetRestoreDialog: false })}
          errorText={this.state.errorMessage}
        />
      </>
    ) : (
      <div className="dataset-preview">{this.previewDatasetRouter()}</div>
    )
  }
}

DatasetPreviewComp.defaultProps = {
  datasetPreview: {},
  trackEvent: () => {},
  refreshDatasetStatus: {},
  refreshDataset: () => {},
  currentUser: {},
  featureFlags: {},
}

DatasetPreviewComp.propTypes = {
  datasetPreview: PropTypes.object,
  trackEvent: PropTypes.func,
  refreshDatasetStatus: PropTypes.object,
  refreshDataset: PropTypes.func,
  featureFlags: PropTypes.object,
}

export default DatasetPreviewComp
