import { call, put } from 'redux-saga/effects'
import axios from 'axios'
import cloneDeep from 'lodash/cloneDeep'
import { API_GATEWAY_URL } from './utils'

// Action types
export const CARDS_FETCH_REQUESTED = 'CARDS_FETCH_REQUESTED'
export const CARDS_FETCH_SUCCEEDED = 'CARDS_FETCH_SUCCEEDED'
export const CARDS_FETCH_FAILED = 'CARDS_FETCH_FAILED'

export const CARD_RESTORE_REQUESTED = 'CARD_RESTORE_REQUESTED'
export const CARD_RESTORE_SUCCEEDED = 'CARD_RESTORE_SUCCEEDED'
export const CARD_RESTORE_FAILED = 'CARD_RESTORE_FAILED'

export const DRILLCARD_RESTORE_REQUESTED = 'DRILLCARD_RESTORE_REQUESTED'
export const DRILLCARD_RESTORE_SUCCEEDED = 'DRILLCARD_RESTORE_SUCCEEDED'
export const DRILLCARD_RESTORE_FAILED = 'DRILLCARD_RESTORE_FAILED'

export const RENDERCARD_FETCH_REQUESTED = 'RENDERCARD_FETCH_REQUESTED'
export const RENDERCARD_FETCH_SUCCEEDED = 'RENDERCARD_FETCH_SUCCEEDED'
export const RENDERCARD_FETCH_FAILED = 'RENDERCARD_FETCH_FAILED'

export const CARDMETA_FETCH_REQUESTED = 'CARDMETA_FETCH_REQUESTED'
export const CARDMETA_FETCH_SUCCEEDED = 'CARDMETA_FETCH_SUCCEEDED'
export const CARDMETA_FETCH_FAILED = 'CARDMETA_FETCH_FAILED'

export const CARDMETA_ONLY_FETCH_REQUESTED = 'CARDMETA_ONLY_FETCH_REQUESTED'
export const CARDMETA_ONLY_FETCH_SUCCEEDED = 'CARDMETA_ONLY_FETCH_SUCCEEDED'
export const CARDMETA_ONLY_FETCH_FAILED = 'CARDMETA_ONLY_FETCH_FAILED'

export const DRILLCARD_CARDMETA_FETCH_REQUESTED = 'DRILLCARD_CARDMETA_FETCH_REQUESTED'
export const DRILLCARD_CARDMETA_FETCH_SUCCEEDED = 'DRILLCARD_CARDMETA_FETCH_SUCCEEDED'
export const DRILLCARD_CARDMETA_FETCH_FAILED = 'DRILLCARD_CARD_META_FETCH_FAILED'

export const DATEFILTER_FETCH_REQUESTED = 'DATEFILTER_FETCH_REQUESTED'
export const DATEFILTER_FETCH_SUCCEEDED = 'DATEFILTER_FETCH_SUCCEEDED'
export const DATEFILTER_FETCH_FAILED = 'DATEFILTER_FETCH_FAILED'

export const CARDLIST_FETCH_REQUESTED = 'CARDLIST_FETCH_REQUESTED'
export const CARDLIST_FETCH_SUCCEEDED = 'CARDLISTFETCH_SUCCEEDED'
export const CARDLIST_FETCH_FAILED = 'CARDLIST_FETCH_FAILED'

export const FAVORITE_CARDLIST_FETCH_REQUESTED = 'FAVORITE_CARDLIST_FETCH_REQUESTED'
export const FAVORITE_CARDLIST_FETCH_SUCCEEDED = 'FAVORITE_CARDLIST_FETCH_SUCCEEDED'
export const FAVORITE_CARDLIST_FETCH_FAILED = 'FAVORITE_CARDLIST_FETCH_FAILED'

export const SEARCH_CARDLIST_FETCH_REQUESTED = 'SEARCH_CARDLIST_FETCH_REQUESTED'
export const SEARCH_CARDLIST_FETCH_SUCCEEDED = 'SEARCH_CARDLIST_FETCH_SUCCEEDED'
export const SEARCH_CARDLIST_FETCH_FAILED = 'SEARCH_CARDLIST_FETCH_FAILED'

export const CARDDETAIL_FETCH_REQUESTED = 'CARDDETAIL_FETCH_REQUESTED'
export const CARDDETAIL_FETCH_SUCCEEDED = 'CARDDETAIL_FETCH_SUCCEEDED'
export const CARDDETAIL_FETCH_FAILED = 'CARDDETAIL_FETCH_FAILED'

export const CARD_DELETE_REQUESTED = 'CARD_DELETE_REQUESTED'
export const CARD_DELETE_SUCCEEDED = 'CARD_DELETE_SUCCEEDED'
export const CARD_DELETE_FAILED = 'CARD_DELETE_FAILED'

export const SAVE_CARD_REQUESTED = 'SAVE_CARD_REQUESTED'
export const SAVE_CARD_SUCCEEDED = 'SAVE_CARD_SUCCEEDED'
export const SAVE_CARD_FAILED = 'SAVE_CARD_FAILED'

export const CARDINFO_UPDATE_WITHOUT_RERENDER = 'CARDINFO_UPDATE_WITHOUT_RERENDER'
export const CARDINFO_CLEAR_WITHOUT_RERENDER = 'CARDINFO_CLEAR_WITHOUT_RERENDER'
export const CARDINFOSTATUS_UPDATE_WITHOUT_RENDER = 'CARDINFOSTATUS_UPDATE_WITHOUT_RENDER'

export const CLEAR_CARD_INFO = 'CLEAR_CARD_INFO'
export const CLEAR_CARD_DETAIL_STATUS = 'CLEAR_CARD_DETAIL_STATUS'

export const UPDATE_TREETABLE_CSV = 'UPDATE_TREETABLE_CSV'
export const CLEAR_TREETABLE_CSV = 'CLEAR_TREETABLE_CSV'

export const SMART_EXPORT_REQUESTED = 'SMART_EXPORT_REQUESTED'
export const SMART_EXPORT_SUCCEEDED = 'SMART_EXPORT_SUCCEEDED'
export const SMART_EXPORT_FAILED = 'SMART_EXPORT_FAILED'

export const CLEAR_RENDER_COUNT = 'CLEAR_RENDER_COUNT'
export const CLEAR_RENDER_COUNT_GEN = 'CLEAR_RENDER_COUNT_GEN'

// Reducer
export default function cardsReducer(state = {}, action = {}) {
  switch (action.type) {
    case CLEAR_RENDER_COUNT_GEN: {
      const newSatee = {
        ...state,
        numberOfRenders: 0,
      }

      return newSatee
    }

    case SMART_EXPORT_REQUESTED: {
      const newState = {
        ...state,
        smartExportData: {
          status: 'requested',
        },
      }
      return newState
    }
    case SMART_EXPORT_SUCCEEDED: {
      const newState = {
        ...state,
        smartExportData: action.payload,
      }

      return newState
    }
    case SMART_EXPORT_FAILED: {
      let httpStatus = 500
      let errorMessage = 'error on the server'

      try {
        errorMessage = action.payload.response.data.message
        const regex = /time contract expired/g
        httpStatus = errorMessage.match(regex) ? 403 : 500
      } catch (e) {
        action.payload.response = {
          message: errorMessage,
        }
      }

      const newState = {
        ...state,
        smartExportData: {
          status: httpStatus,
          message: action.payload.response,
        },
      }
      return newState
    }

    case CARDS_FETCH_REQUESTED: {
      const newState = {
        ...state,
        cardsStatus: {
          status: 'requested',
        },
      }
      return newState
    }
    case CARDS_FETCH_SUCCEEDED: {
      const newState = {
        ...state,
        cardsStatus: action.payload,
      }
      return newState
    }
    case CARDS_FETCH_FAILED: {
      const newState = {
        ...state,
        cardsStatus: {
          status: 'failed',
          message: action.payload.response,
        },
      }
      return newState
    }

    case RENDERCARD_FETCH_REQUESTED: {
      const id = action.payload.pendingCardUid
        ? action.payload.pendingCardUid
        : action.payload.card
        ? action.payload.card
        : action.payload.existingId
        ? action.payload.existingId
        : action.payload._id

      const previousPayloadOfCurrentCard = state.previousPayloadOfCurrentCard
        ? cloneDeep(state.previousPayloadOfCurrentCard)
        : {}

      previousPayloadOfCurrentCard[action.payload.pendingCardUid || action.payload._id] = cloneDeep(action.payload)

      const newState = {
        ...state,
        previousPayloadOfCurrentCard,
        renderCardStatus: {
          ...state.renderCardStatus,
          [id]: {
            status: 'requested',
            ...(state.renderCardStatus &&
              state.renderCardStatus[id]?.data &&
              action.isBuilder && {
                data: {
                  ...state.renderCardStatus[id]?.data,
                  card_attribute: action.payload.card_attribute,
                  card_query_attribute: action.payload.card_query_attribute,
                  card_config: {
                    ...state.renderCardStatus[id]?.data.card_config,
                    card_attribute: action.payload.card_attribute,
                    card_query_attribute: action.payload.card_query_attribute,
                  },
                },
              }),
          },
        },
      }

      return newState
    }
    case RENDERCARD_FETCH_SUCCEEDED: {
      action.payload.data.card_config.card_query_attribute.columns.map(column => {
        if (!column.ref_id) {
          column.ref_id = `r${Math.random().toString(36).slice(3)}`
        }

        return column
      })
      const newState = {
        ...state,
        numberOfRenders: state.numberOfRenders + 1 || 1,
        renderCardStatus: {
          ...state.renderCardStatus,
          [action.payload.pendingCardUid || action.payload.data.card_config._id]: action.payload,
        },
      }
      return newState
    }
    case RENDERCARD_FETCH_FAILED: {
      const newState = {
        ...state,
        renderCardStatus: {
          ...state.renderCardStatus,
          [action.payload.id]: {
            cardId: action.payload.id,
            status: 'failed',
            message: action.payload.response,
            type: action.payload.type,
            ...(state.renderCardStatus &&
              state.renderCardStatus[action.payload.id]?.data && {
                data: state.renderCardStatus[action.payload.id].data,
              }),
          },
        },
      }
      return newState
    }

    case CARDMETA_FETCH_REQUESTED: {
      const newState = {
        ...state,
        renderCardStatus: {
          ...state.renderCardStatus,
          [action.payload.id]: {
            status: 'requested',
          },
        },
        cardInfoStatusForFilter: {
          ...state.cardInfoStatusForFilter,
          [action.payload.id]: {
            status: 'requested',
            getCardCallFromFilter: action.payload.getCardCallFromFilter,
          },
        },
        cardInfoStatus: {
          status: 'requested',
        },
      }
      return newState
    }
    case CARDMETA_FETCH_SUCCEEDED: {
      action.payload.data.card_query_attribute &&
        action.payload.data.card_query_attribute.columns.map(column => {
          if (!column.ref_id) {
            column.ref_id = `r${Math.random().toString(36).slice(3)}`
          }
          return column
        })

      const newState = {
        ...state,
        renderCardStatus: {
          ...state.renderCardStatus,
          [action.payload.data._id]: {
            data: { card_config: action.payload.data },
            status: action.payload.status,
          },
        },
        cardInfoStatusForFilter: {
          ...state.cardInfoStatusForFilter,
          [action.payload.data._id]: {
            data: { card_config: action.payload.data },
            status: action.payload.status,
          },
        },
        cardInfoStatus: action.payload,
      }
      return newState
    }
    case CARDMETA_FETCH_FAILED: {
      const newState = {
        ...state,
        renderCardStatus: {
          ...state.renderCardStatus,
          [action.payload.id]: {
            status: 'failed',
            cardId: action.payload.id,
            message: action.payload.response,
          },
        },
        cardInfoStatusForFilter: {
          ...state.cardInfoStatusForFilter,
          [action.payload.id]: {
            status: 'failed',
            cardId: action.payload.id,
            message: action.payload.response,
          },
        },
        cardInfoStatus: {
          status: 'failed',
          message: action.payload.response,
        },
      }
      return newState
    }

    case CARDMETA_ONLY_FETCH_REQUESTED: {
      const newState = {
        ...state,
        cardMetaStatus: {
          status: 'requested',
        },
      }
      return newState
    }
    case CARDMETA_ONLY_FETCH_SUCCEEDED: {
      const newState = {
        ...state,
        cardMetaStatus: action.payload,
      }
      return newState
    }
    case CARDMETA_ONLY_FETCH_FAILED: {
      const newState = {
        ...state,
        cardMetaStatus: {
          status: 'failed',
          message: action.payload.response,
        },
      }
      return newState
    }

    case DRILLCARD_CARDMETA_FETCH_REQUESTED: {
      const newState = {
        ...state,
        drillCardMetaStatus: {
          ...state.drillCardMetaStatus,
          [action.payload.id]: {
            status: 'requested',
          },
        },
      }
      return newState
    }
    case DRILLCARD_CARDMETA_FETCH_SUCCEEDED: {
      const newState = {
        ...state,
        drillCardMetaStatus: {
          ...state.drillCardMetaStatus,
          [action.payload.data._id]: {
            data: { card_config: action.payload.data },
            status: action.payload.status,
          },
        },
      }
      return newState
    }
    case DRILLCARD_CARDMETA_FETCH_FAILED: {
      const newState = {
        ...state,
        drillCardMetaStatus: {
          ...state.drillCardMetaStatus,
          [action.payload.id]: {
            status: 'failed',
            message: action.payload.response,
          },
        },
      }
      return newState
    }

    case CARDLIST_FETCH_REQUESTED: {
      const newState = {
        ...state,
        cardsInfoStatus: {
          status: 'requested',
        },
      }
      return newState
    }
    case CARDLIST_FETCH_SUCCEEDED: {
      const newState = {
        ...state,
        cardsInfoStatus: action.payload,
      }
      return newState
    }
    case CARDLIST_FETCH_FAILED: {
      const newState = {
        ...state,
        cardsInfoStatus: {
          status: 'failed',
          message: action.payload.response,
        },
      }
      return newState
    }

    case SEARCH_CARDLIST_FETCH_REQUESTED: {
      const newState = {
        ...state,
        cardsInfoStatus: {
          status: 'requested',
        },
      }
      return newState
    }
    case SEARCH_CARDLIST_FETCH_SUCCEEDED: {
      const newState = {
        ...state,
        cardsInfoStatus: action.payload,
      }
      return newState
    }
    case SEARCH_CARDLIST_FETCH_FAILED: {
      const newState = {
        ...state,
        cardsInfoStatus: {
          status: 'failed',
          message: action.payload.response,
        },
      }
      return newState
    }

    case FAVORITE_CARDLIST_FETCH_REQUESTED: {
      const newState = {
        ...state,
        favoriteCardsInfoStatus: {
          status: 'requested',
        },
      }
      return newState
    }
    case FAVORITE_CARDLIST_FETCH_SUCCEEDED: {
      const newState = {
        ...state,
        favoriteCardsInfoStatus: action.payload,
      }
      return newState
    }
    case FAVORITE_CARDLIST_FETCH_FAILED: {
      const newState = {
        ...state,
        favoriteCardsInfoStatus: {
          status: 'failed',
          message: action.payload.response,
        },
      }
      return newState
    }

    case CARD_DELETE_REQUESTED: {
      const newState = {
        ...state,
        cardDelStatus: {
          status: 'requested',
        },
      }
      return newState
    }
    case CARD_DELETE_SUCCEEDED: {
      const newState = {
        ...state,
        cardDelStatus: action.payload,
      }
      return newState
    }
    case CARD_DELETE_FAILED: {
      const newState = {
        ...state,
        cardDelStatus: {
          status: 'failed',
          message: action.payload.response,
        },
      }
      return newState
    }

    case CARD_RESTORE_REQUESTED: {
      const newState = {
        ...state,
        renderCardStatus: {
          ...state.renderCardStatus,
          [action.payload.cardId]: {
            status: 'requested',
          },
        },
        cardInfoStatus: {
          status: 'requested',
        },
      }
      return newState
    }

    case CARD_RESTORE_SUCCEEDED: {
      action.payload.data.card_query_attribute &&
        action.payload.data.card_query_attribute.columns.map(column => {
          if (!column.ref_id) {
            column.ref_id = `r${Math.random().toString(36).slice(3)}`
          }
          return column
        })

      const newState = {
        ...state,
        renderCardStatus: {
          ...state.renderCardStatus,
          [action.payload.data._id]: {
            data: { card_config: action.payload.data },
            status: action.payload.status,
          },
        },
        cardInfoStatus: action.payload,
      }
      return newState
    }
    case CARD_RESTORE_FAILED: {
      const newState = {
        ...state,
        renderCardStatus: {
          ...state.renderCardStatus,
          [action.payload.id]: {
            status: 'failed',
            message: action.payload.response,
          },
        },
        cardInfoStatus: {
          status: 'failed',
          message: action.payload.response,
        },
      }
      return newState
    }

    case DRILLCARD_RESTORE_REQUESTED: {
      const newState = {
        ...state,
        drillCardMetaStatus: {
          ...state.drillCardMetaStatus,
          [action.payload.cardId]: {
            status: 'requested',
          },
        },
      }
      return newState
    }
    case DRILLCARD_RESTORE_SUCCEEDED: {
      const newState = {
        ...state,
        drillCardMetaStatus: {
          ...state.drillCardMetaStatus,
          [action.payload.data._id]: {
            data: { card_config: action.payload.data },
            status: action.payload.status,
          },
        },
      }
      return newState
    }
    case DRILLCARD_RESTORE_FAILED: {
      const newState = {
        ...state,
        drillCardMetaStatus: {
          ...state.drillCardMetaStatus,
          [action.payload.id]: {
            status: 'failed',
            message: action.payload.response,
          },
        },
      }
      return newState
    }

    case CARDDETAIL_FETCH_REQUESTED: {
      const newState = {
        ...state,
        cardDetailStatus: {
          status: 'requested',
        },
      }
      return newState
    }
    case CARDDETAIL_FETCH_SUCCEEDED: {
      const newState = {
        ...state,
        cardDetailStatus: action.payload,
      }
      return newState
    }
    case CARDDETAIL_FETCH_FAILED: {
      const newState = {
        ...state,
        cardDetailStatus: {
          status: 'failed',
          message: action.payload.response,
        },
      }
      return newState
    }

    case CARDINFO_UPDATE_WITHOUT_RERENDER: {
      const vizType = action.payload?.cardInfo?.card_config?.card_attribute?.viz_type

      const previousPayloadOfCurrentCard = state.previousPayloadOfCurrentCard
        ? cloneDeep(state.previousPayloadOfCurrentCard)
        : {}

      previousPayloadOfCurrentCard[action.payload.id] = cloneDeep(action.payload.cardInfo?.card_config)

      const newState = {
        ...state,
        previousPayloadOfCurrentCard,
        renderCardStatus: {
          ...state.renderCardStatus,
          [action.payload.id]: {
            status:
              action.payload.isCloningCard || vizType?.toLowerCase() === 'text'
                ? 200
                : state.renderCardStatus[action.payload.id].status,
            data: action.payload.cardInfo,
          },
        },
      }
      return newState
    }
    case CARDINFOSTATUS_UPDATE_WITHOUT_RENDER: {
      const newState = {
        ...state,
        cardInfoStatus: {
          ...state.cardInfoStatus,
          data: action.payload.cardInfo,
        },
      }
      return newState
    }
    case SAVE_CARD_REQUESTED: {
      const newState = {
        ...state,
        saveCardFromViewerStatus: {
          status: 'requested',
        },
      }
      return newState
    }
    case SAVE_CARD_SUCCEEDED: {
      const newState = {
        ...state,
        saveCardFromViewerStatus: action.payload,
      }
      return newState
    }
    case SAVE_CARD_FAILED: {
      const newState = {
        ...state,
        saveCardFromViewerStatus: {
          status: 'failed',
          message: action.payload.response,
        },
      }
      return newState
    }

    case CARDINFO_CLEAR_WITHOUT_RERENDER: {
      const renderCard = cloneDeep(state.renderCardStatus)
      delete renderCard[action.payload.id]
      const newState = {
        ...state,
        renderCardStatus: {
          ...renderCard,
        },
      }
      return newState
    }

    case CLEAR_CARD_DETAIL_STATUS: {
      const newState = {
        ...state,
        cardDetailStatus: {},
      }
      return newState
    }

    case CLEAR_CARD_INFO: {
      const newState = {
        ...state,
        renderCardStatus: {},
      }
      return newState
    }

    case CLEAR_TREETABLE_CSV: {
      const newState = {
        ...state,
        treeTableCsvData: {},
      }
      return newState
    }

    case UPDATE_TREETABLE_CSV: {
      const { hashmap, newDrill, patternToDelete } = action.payload
      const _treeTableCsvData = newDrill ? cloneDeep(state.treeTableCsvData || hashmap) : cloneDeep(hashmap)
      let nodeRef = _treeTableCsvData

      if (newDrill) {
        const drillPathLength = action.payload.newDrill.length
        newDrill.forEach((drillObj, idx) => {
          const ref = nodeRef[`z${drillObj.pattern}`]

          if (idx === drillPathLength - 1) {
            nodeRef = ref
          } else if (ref) {
            nodeRef = ref.children
          }
        })

        if (nodeRef) {
          if (!patternToDelete) {
            nodeRef.children = hashmap
          } else {
            if (newDrill.length) {
              nodeRef = nodeRef.children
            }

            if (nodeRef[`z${patternToDelete}`]) {
              nodeRef[`z${patternToDelete}`].children = {}
            }
          }
        }
      }
      const newState = {
        ...state,
        treeTableCsvData: _treeTableCsvData,
      }
      return newState
    }

    default:
      return state
  }
}
// Actions
export function clearTreeTableCsvData(data) {
  return {
    type: CLEAR_TREETABLE_CSV,
    payload: data,
  }
}

/**
 * preps data for appending to or removing from in-memory storage of tree table data for csv export
 * @param {array} newData represents query_results for expanded table in tree
 * @param {*} dimension the dimension column object from expanded table in tree
 * @param {*} newDrill drill path used to get to expanded table
 * @param {*} patternToDelete dimension value to find in csv data and remove if user collapses table(s)
 */
export function updateTreeTableCsvData({ newData = [], dimension = {}, newDrill, patternToDelete = null }) {
  const hashmap = {}
  if (!patternToDelete) {
    newData.forEach(dataObj => {
      const _dataObj = { ...dataObj }
      _dataObj.children = {}
      _dataObj.refId = dimension.ref_id
      _dataObj.columnDisplayName = dimension.column_display_name
      // append 'z' to force object properties to be ordered based on insertion
      hashmap[`z${dataObj[dimension.ref_id]}`] = _dataObj
    })
  }
  return {
    type: UPDATE_TREETABLE_CSV,
    payload: { hashmap, newDrill, patternToDelete },
  }
}

export function updateCardWithoutRerender(data) {
  return {
    type: CARDINFO_UPDATE_WITHOUT_RERENDER,
    payload: data,
  }
}

export function updateCardInfoStatusWithoutRender(data) {
  return {
    type: CARDINFOSTATUS_UPDATE_WITHOUT_RENDER,
    payload: data,
  }
}

// Actions
export function clearCardRenderCount() {
  return {
    type: CLEAR_RENDER_COUNT,
  }
}

export function clearCardWithoutRerender(data) {
  return {
    type: CARDINFO_CLEAR_WITHOUT_RERENDER,
    payload: data,
  }
}

export function clearCardDetailStatus(data) {
  return {
    type: CLEAR_CARD_DETAIL_STATUS,
    payload: data,
  }
}

export function clearCardInfo(data) {
  return {
    type: CLEAR_CARD_INFO,
    payload: data,
  }
}

export function updateCards(data) {
  return {
    type: CARDS_FETCH_REQUESTED,
    payload: data,
  }
}

export function cardRender(data, isBuilder) {
  return {
    type: RENDERCARD_FETCH_REQUESTED,
    payload: data,
    isBuilder,
  }
}

export function getCardMeta(data) {
  return {
    type: CARDMETA_FETCH_REQUESTED,
    payload: data,
  }
}

export function getCardMetaOnly(data) {
  return {
    type: CARDMETA_ONLY_FETCH_REQUESTED,
    payload: data,
    cardMetaOnly: true,
  }
}

export function getDrillCardMeta(data) {
  return {
    type: DRILLCARD_CARDMETA_FETCH_REQUESTED,
    payload: data,
  }
}

export function getCardList(data) {
  return {
    type: CARDLIST_FETCH_REQUESTED,
    payload: data,
  }
}

export function searchCardList(data) {
  return {
    type: SEARCH_CARDLIST_FETCH_REQUESTED,
    payload: data,
  }
}

export function searchFavoriteCardList(data) {
  return {
    type: FAVORITE_CARDLIST_FETCH_REQUESTED,
    payload: data,
  }
}

export function getCardDetail(data) {
  return {
    type: CARDDETAIL_FETCH_REQUESTED,
    payload: data,
  }
}

export function delCard(data) {
  return {
    type: CARD_DELETE_REQUESTED,
    payload: data,
  }
}

export function saveCardFromViewer(data) {
  return {
    type: SAVE_CARD_REQUESTED,
    payload: data,
  }
}

export function getSmartExportData(data) {
  return {
    type: SMART_EXPORT_REQUESTED,
    payload: data,
  }
}

export function restoreCard(data) {
  return {
    type: CARD_RESTORE_REQUESTED,
    payload: data,
  }
}

export function restoreDrillCard(data) {
  return {
    type: DRILLCARD_RESTORE_REQUESTED,
    payload: data,
  }
}

// Sagas (service requests)

export function* resetCardRenderCount() {
  yield put({ type: CLEAR_RENDER_COUNT_GEN })
}

export function* smartExportExecuteCard(action) {
  const { cardData, cardId } = action.payload
  try {
    const cards = yield call(axios, {
      method: 'post',
      url: `${API_GATEWAY_URL}/bi_reporting_assets/v3/execute_cards?card_id=${cardId}`,
      data: cardData,
    })

    if (cards.error) {
      const e = cards.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }

    yield put({ type: SMART_EXPORT_SUCCEEDED, payload: cards })
  } catch (error) {
    yield put({ type: SMART_EXPORT_FAILED, payload: error })
  }
}

export function* fetchCards(action) {
  try {
    const isSearch = action?.payload?.isSearch
    const searchInPayload = 'isSearch' in action.payload

    const cards = yield call(axios, {
      method: 'get',
      url: `${API_GATEWAY_URL}/bi_reporting_assets/v3/cards?search_string=${action.payload.searchString}${
        action.payload.tags && action.payload.tags !== 'All' ? `&tags=${action.payload.tags}` : ''
      }&field_groups=quick_metadata&per_page=100${searchInPayload && !isSearch ? '&search_in_user_data=yes' : ''}`,
    })

    if (cards.error) {
      const e = cards.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }
    yield put({ type: CARDS_FETCH_SUCCEEDED, payload: cards })
  } catch (error) {
    yield put({ type: CARDS_FETCH_FAILED, payload: error })
  }
}

export function* renderCard(action) {
  const { payload, isBuilder } = action
  const postData = payload.post ? payload.post : payload

  try {
    const data = payload.pendingCardUid
      ? {
          card_attribute: payload.card_attribute,
        }
      : cloneDeep(postData)

    if (payload.card_query_attribute) {
      data.card_query_attribute = payload.card_query_attribute
    }

    if (!data.pendingCardUid) {
      delete data.pendingCardUid
    }

    const isCompareBy =
      (data.card_query_attribute &&
        data.card_query_attribute.time_period &&
        data.card_query_attribute.time_period.compare_by) ||
      (data.time_period && data.time_period.compare_by)

    if (isBuilder) {
      data.viewer = /^\/builder\/dashboard/i.test(window.location.pathname) ? 'dashboard_builder' : 'card_builder'

      // For all viz types check if card has column of type timeseries
      // if it does, then append result_form to request dates in specific format
      // Table & Pivot dates should be returned as array to preserve sort
      // All amCharts viz types return formatted date only
      const vizType =
        data.card_attribute && data.card_attribute.viz_type
          ? data.card_attribute && data.card_attribute.viz_type.toLowerCase()
          : ''
      const columns =
        data.card_query_attribute && data.card_query_attribute.columns ? data.card_query_attribute.columns : []
      if (columns.some(col => col.type === 'timeseries' || col.format_type === 'date')) {
        if (
          [
            'table',
            'pivot',
            'line',
            'bar',
            'bar and line',
            'stack bar',
            '100_percent_stack',
            'heatmap',
            'pareto',
            'stack bar and line',
            'scatter_plot',
            'bubble_chart',
            'area_chart',
            'table_beta',
            'bullet',
            'sparkline',
            'dynamic_text',
            'big_number',
          ].includes(vizType)
        ) {
          data.result_form = {
            date: isCompareBy ? '${raw}||${formatted}||${alternate}||${alternate_formatted}' : '${raw}||${formatted}', // eslint-disable-line no-template-curly-in-string
            pivot: 'formatted',
          }
        }
      } else {
        data.result_form = {
          pivot: 'formatted',
        }
      }
    } else {
      if (!data.result_form) {
        data.result_form = {
          date: isCompareBy ? '${raw}||${formatted}||${alternate}||${alternate_formatted}' : '${raw}||${formatted}', // eslint-disable-line no-template-curly-in-string
          pivot: 'formatted',
        }
      }
    }

    if (isBuilder && payload.application_data && payload.application_data.ui) {
      data.application_data = payload.application_data
    }
    const renderCardResults = yield call(axios, {
      method: 'post',
      url: `${API_GATEWAY_URL}/bi_reporting_assets/v3/execute_cards${
        payload.existingId || payload.card ? `?card_id=${payload.existingId || payload.card}` : ''
      }`,
      ...(payload.executeAs && { headers: { execute_as: payload.executeAs } }),
      data: payload.existingId
        ? data.isDashboard
          ? {
              viewer: 'dashboard',
            }
          : { drill: [], filters: [], time_period: {}, viewer: 'dashboard' }
        : data,
    })

    if (renderCardResults.error) {
      const e = renderCardResults.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }

    if (payload.pendingCardUid) {
      renderCardResults.pendingCardUid = payload.pendingCardUid
    }

    if (payload._id) {
      renderCardResults.data.card_config._id = payload._id
    }
    yield put({
      type: RENDERCARD_FETCH_SUCCEEDED,
      payload: renderCardResults,
    })
  } catch (error) {
    const type = error.response?.data?.type

    yield put({
      type: RENDERCARD_FETCH_FAILED,
      payload: {
        id: payload._id || payload.pendingCardUid || payload.existingId || payload.card,
        response: error,
        type,
      },
    })
  }
}

export function* fetchCardMeta(action) {
  const { payload } = action

  try {
    const cardInfo = yield call(axios, {
      method: 'get',
      url: `${API_GATEWAY_URL}/bi_reporting_assets/v3/cards/${payload.id}${
        payload.versionDate ? `?version_date=${payload.versionDate}` : ''
      }`,
      ...(payload.executeAs && { headers: { execute_as: payload.executeAs } }),
    })

    if (cardInfo.error) {
      const e = cardInfo.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }
    if (action?.cardMetaOnly) {
      yield put({ type: 'CARDMETA_ONLY_FETCH_SUCCEEDED', payload: cardInfo })
    } else {
      yield put({ type: 'CARDMETA_FETCH_SUCCEEDED', payload: cardInfo, preDefinedStatus: payload.status })
    }
  } catch (error) {
    if (action?.cardMetaOnly) {
      yield put({ type: 'CARDMETA_ONLY_FETCH_FAILED', payload: { id: payload.id, response: error } })
    } else {
      yield put({ type: 'CARDMETA_FETCH_FAILED', payload: { id: payload.id, response: error } })
    }
  }
}

export function* fetchDrillCardMeta(action) {
  const { payload } = action

  try {
    const drillCardMetaInfo = yield call(axios, {
      method: 'get',
      url: `${API_GATEWAY_URL}/bi_reporting_assets/v3/cards/${payload.id}`,
    })

    if (drillCardMetaInfo.error) {
      const e = drillCardMetaInfo.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }
    yield put({ type: 'DRILLCARD_CARDMETA_FETCH_SUCCEEDED', payload: drillCardMetaInfo })
  } catch (error) {
    yield put({ type: 'DRILLCARD_CARDMETA_FETCH_FAILED', payload: { id: payload.id, response: error } })
  }
}

export function* fetchFavoriteCardList(action) {
  try {
    const cardList = yield call(axios, {
      method: 'get',
      url: `${API_GATEWAY_URL}/bi_reporting_assets/v3/cards?search_in_user_data=${action.payload.searchInUserData}&favorites_only=yes`,
    })

    if (cardList.error) {
      const e = cardList.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }
    yield put({ type: FAVORITE_CARDLIST_FETCH_SUCCEEDED, payload: cardList })
  } catch (error) {
    yield put({ type: FAVORITE_CARDLIST_FETCH_FAILED, payload: error })
  }
}

export function* fetchCardList(action) {
  try {
    const cardList = yield call(axios, {
      method: 'get',
      url: `${API_GATEWAY_URL}/bi_reporting_assets/v3/cards?page=${action.payload.pageIndex}&per_page=${
        action.payload.pageSize
      }&search_string=${encodeURIComponent(action.payload.searchString)}&sort=${encodeURIComponent(
        action.payload.sortColumn
      )}&field_groups=${
        action.payload.fieldGroups ? action.payload.fieldGroups : 'quick_metadata'
      }&search_in_user_data=${action.payload.searchInUserData}&tags=${action.payload.tags}&owner_id=${
        action.payload.ownerId
      }`,
    })

    if (cardList.error) {
      const e = cardList.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }
    yield put({ type: CARDLIST_FETCH_SUCCEEDED, payload: cardList })
  } catch (error) {
    yield put({ type: CARDLIST_FETCH_FAILED, payload: error })
  }
}

export function* fetchSearchCardList(action) {
  try {
    const cardList = yield call(axios, {
      method: 'get',
      url: `${API_GATEWAY_URL}/bi_reporting_assets/v2/cards?page=${
        action.payload.pageIndex
      }&search_string=${encodeURIComponent(action.payload.searchString)}&per_page=${
        action.payload.pageSize
      }&search_in_user_data=${action.payload.searchInUserData}&sort=${encodeURIComponent(
        action.payload.sortColumn
      )}&tags=${action.payload.tags}`,
    })

    if (cardList.error) {
      const e = cardList.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }
    yield put({ type: SEARCH_CARDLIST_FETCH_SUCCEEDED, payload: cardList })
  } catch (error) {
    yield put({ type: SEARCH_CARDLIST_FETCH_FAILED, payload: error })
  }
}

export function* fetchCardDetail(action) {
  try {
    const card = yield call(axios, {
      method: 'get',
      url: `${API_GATEWAY_URL}/bi_reporting_assets/v3/cards/${action.payload.cardId}`,
    })

    if (card.error) {
      const e = card.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }
    yield put({ type: CARDDETAIL_FETCH_SUCCEEDED, payload: card })
  } catch (error) {
    yield put({ type: CARDDETAIL_FETCH_FAILED, payload: error })
  }
}

export function* deleteCard(action) {
  try {
    const delCard = yield call(axios, {
      method: 'patch',
      url: `${API_GATEWAY_URL}/bi_reporting_assets/v3/cards/${action.payload.delCard}`,
      data: {
        status: {
          state: 'disabled',
        },
      },
    })

    if (delCard.error) {
      const e = delCard.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }

    yield put({ type: 'CARD_DELETE_SUCCEEDED', payload: delCard })
  } catch (error) {
    yield put({ type: 'CARD_DELETE_FAILED', payload: error })
  }
}
export function* restoreCardMeta(action) {
  try {
    const restoreCard = yield call(axios, {
      method: 'patch',
      url: `${API_GATEWAY_URL}/bi_reporting_assets/v3/cards/${action.payload.cardId}`,
      data: {
        status: {
          state: 'active',
        },
      },
    })
    if (restoreCard.error) {
      const e = restoreCard.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }
    yield put({ type: 'CARD_RESTORE_SUCCEEDED', payload: restoreCard })
  } catch (error) {
    yield put({ type: 'CARD_RESTORE_FAILED', payload: error })
  }
}

export function* restoreDrillCardMeta(action) {
  try {
    const restoreCard = yield call(axios, {
      method: 'patch',
      url: `${API_GATEWAY_URL}/bi_reporting_assets/v3/cards/${action.payload.cardId}`,
      data: {
        status: {
          state: 'active',
        },
      },
    })
    if (restoreCard.error) {
      const e = restoreCard.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }
    yield put({ type: 'DRILLCARD_RESTORE_SUCCEEDED', payload: restoreCard })
  } catch (error) {
    yield put({ type: 'DRILLCARD_RESTORE_FAILED', payload: error })
  }
}

export function* sendSaveCardFromViewer(action) {
  try {
    const card = yield call(axios, {
      method: 'post',
      url: `${API_GATEWAY_URL}/bi_reporting_assets/v3/cards`,
      data: action.payload,
    })

    if (card.error) {
      const e = card.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }

    yield put({ type: SAVE_CARD_SUCCEEDED, payload: card })
  } catch (error) {
    yield put({ type: SAVE_CARD_FAILED, payload: error })
  }
}
