import React, { useState } from 'react'
import { Typography, Select, MenuItem, FormControl, OutlinedInput } from '@mui/material'
import cloneDeep from 'lodash/cloneDeep'

function SecCtrlColumnsMapper(props) {
  const [requiredColumns] = useState(props.requiredColumns)
  const [dimensionCols] = useState(props.dimensionCols)
  const [mappedColumns, setMappedColumns] = useState(props.mappedColumns || {})

  const setRequiredColMapping = (reqCol, colName) => {
    const columns = cloneDeep(mappedColumns)

    columns[reqCol] = colName
    setMappedColumns(columns)
    props.updateMappedColumns(columns)
  }

  return (
    <div className="flex-cols align-controls">
      <Typography variant="subtitle2">Please map your columns*</Typography>
      {requiredColumns &&
        requiredColumns.map(reqCol => (
          <div className="map-col-select" key={reqCol}>
            <Typography variant="body1" className="map-column-label">
              {reqCol}
            </Typography>
            <FormControl required variant="outlined" className="col-name-form">
              <Select
                variant="standard"
                value={mappedColumns[reqCol] || 'None'}
                onChange={e => {
                  setRequiredColMapping(reqCol, e.target.value)
                }}
                input={<OutlinedInput name="columnNames" id="outlined-column-names" labelWidth={0} />}
              >
                <MenuItem value="None" disabled>
                  None
                </MenuItem>
                {dimensionCols.map((dimCol, index) => (
                  <MenuItem id={`map-column${index}`} key={dimCol.field_name} value={dimCol.field_name}>
                    {dimCol.field_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        ))}
    </div>
  )
}

export default SecCtrlColumnsMapper
