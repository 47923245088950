import React, { useRef, useState, useEffect } from 'react'
import { AgGridReact, AgGridColumn } from '@ag-grid-community/react'
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import {
  DialogContent,
  Typography,
  Button,
  FormControl,
  RadioGroup,
  Radio,
  FormControlLabel,
  TextField,
  List,
  ListItem,
  Chip,
  Popover,
  Icon,
  IconButton,
  Tooltip,
} from '@mui/material'
import { Spinner } from 'greenfield-utilities'
import { Close, Warning, Edit, Info } from '@mui/icons-material'
import axios from 'axios'
import debounce from 'lodash/debounce'

import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-balham.css'

import { getDetailsOwners } from '../../ducks/builder'
import { displayServiceErrorMessage } from '../../ducks/layout'
import { processColumnsIcon } from '../routes/Builder/Sidebar/utils'
import apiConfig from '../../config/apiConfig'
import { API_GATEWAY_URL } from '../../ducks/utils'
import {
  Step2ContentContainer,
  SArrowBack,
  SPopover,
  ColumnHeaderIcon,
  PopoverLabelContainer,
  SelectedOwnersContainer,
  SIconButton,
  CustomDateInfoPopoverText,
  NoTimeWarningMessage,
  SDialogTitle,
  SClose,
  FinalizeErrorsDetail,
  SDialogActions,
} from './DatasetBuilder-styled'

const DatasetBuilderEditUpload = ({
  datasetInfo,
  updateSwipeableIndex,
  closeDatasetBuilder,
  updateUploadStatus,
  hasHeader,
  previouslyUploadedDatasetName,
  updatePendingDatasetId,
  updatePollIntervalId,
  updateErrorState,
  trackEvent,
}) => {
  const [datasetName, updateDatasetName] = useState('')
  const [datasetDescription, updateDatasetDescription] = useState('')
  const [dataClassification, updateDataClassification] = useState('internal')
  const [isSubmittingFinalize, updateIsSubmittingFinalize] = useState(false)
  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null)
  const [ownersValue, updateOwnersValue] = useState('')
  const [ownersListVisiblity, updateOwnersListVisibility] = useState(false)
  const [selectedOwners, updateSelectedOwners] = useState([])
  const [colDefinitions, updateColDefinitions] = useState(
    datasetInfo?.columns?.map((col, index) => ({
      field: col.field || col.name || `Column${index + 1}`,
      category: col.category,
      customDateFormat: col.timestamp_format,
      flex: 1,
      index,
    }))
  )
  const [columnEditValues, updateColumnEditValues] = useState({})
  const [customDateInfoPopoverVisiblity, updateCustomDateInfoPopoverVisiblity] = useState(false)
  const [popoverAnchor, updatePopoverAnchor] = useState(null)
  const [finalizeErrors, updateFinalizeErrors] = useState([])
  const [ownersPopoverAnchor, setOwnersPopoverAnchor] = useState(null)
  const [datasetNameError, updateDatasetNameError] = useState('')

  const dispatch = useDispatch()
  const ownersStatus = useSelector(state => state.builder.detailsOwnersStatus || {})

  const debouncedOwnersSearch = useRef(
    debounce(value => {
      dispatch(getDetailsOwners(value))
    }, 1000)
  )

  useEffect(() => {
    if (ownersStatus.data && !ownersListVisiblity) {
      updateOwnersListVisibility(true)
    }
  }, [ownersStatus])

  useEffect(() => {
    if (ownersValue) {
      debouncedOwnersSearch.current(ownersValue)
    } else {
      updateOwnersListVisibility(false)
    }
  }, [ownersValue])

  const handleFinalizeSubmit = () => {
    updateIsSubmittingFinalize(true)
    updateFinalizeErrors([])
    axios
      .patch(`${API_GATEWAY_URL}/greenfield_ingests/v1/ingests/${datasetInfo.id}/tasks`, {
        id: datasetInfo.id,
        datasource: previouslyUploadedDatasetName || `FileUpload.${datasetName}`,
        dataset_description: datasetDescription,
        columns: colDefinitions.map(col => ({
          category: col.category,
          name: col.displayName || col.field,
          data_type: 'STRING',
          timestamp_format: col.category === 'PRIMARY_TIME_SERIES' ? col.customDateFormat : undefined,
        })),
        owners: selectedOwners.map(owner => owner.user_group_name),
        paths: ['string'],
      })
      .then(response => {
        updateSwipeableIndex(2)
        updatePendingDatasetId(response.data.dataset_id)

        const poll = setInterval(() => {
          axios
            .get(`${API_GATEWAY_URL}/greenfield_ingests/v1/ingests/${datasetInfo.id}`)
            .then(d => {
              const statuses = ['INDEXING', 'PROPAGATION', 'METADATA_REFRESH', 'SUCCESS']

              if (d.data.status === 'ERROR') {
                dispatch(
                  displayServiceErrorMessage(
                    'Your upload task failed:  Your file may contain data that could not be parsed. Please review the log for this task from the task history on the dataset page for more details'
                  )
                )
                updateErrorState(true)
              } else {
                updateUploadStatus({
                  data: d,
                  activeStep: statuses.indexOf(d.data.status),
                })
              }

              if (d.data.status === 'SUCCESS' || d.data.status === 'ERROR') {
                clearInterval(poll)
              }
            })
            .catch(() => {
              dispatch(
                displayServiceErrorMessage(
                  'Something went wrong with the request to poll for the status of your upload.'
                )
              )
              clearInterval(poll)
            })
        }, 5000)
        updatePollIntervalId(poll)
      })
      .catch(err => {
        const { data } = err.response

        if (data.validation_errors) {
          updateFinalizeErrors(data.validation_errors)
        } else {
          dispatch(displayServiceErrorMessage(data.message))
        }
        updateIsSubmittingFinalize(false)
      })
  }

  const headerEditClicked = (event, column) => {
    updateColumnEditValues({
      displayName: column.colDef.displayName || column.colId,
      category: column.colDef.category,
      index: column.colDef.index,
      customDateFormat: column.colDef.customDateFormat,
    })

    setPopoverAnchorEl(event.target)
  }

  const typeMap = type => {
    if (type === 'PRIMARY_TIME_SERIES') {
      return 'timeseries'
    }

    if (type === 'DIMENSION') {
      return 'dimension'
    }

    if (type === 'METRIC') {
      return 'metric'
    }
  }

  const renderPopover = () => {
    const columns = [...colDefinitions]
    const column = columns[columnEditValues.index]
    const isPreviewColumnAllNumbers = datasetInfo.preview_lines.reduce(
      (acc, curr, index) => index && !window.isNaN(curr[columnEditValues.index]),
      false
    )

    const handleRadioCategoryChange = e => {
      columnEditValues.category = e.target.value
      updateColumnEditValues({
        ...columnEditValues,
        category: e.target.value,
      })
    }

    const onDateFormatChange = e => {
      updateColumnEditValues({
        ...columnEditValues,
        customDateFormat: e.target.value,
      })
    }

    const updateEditLabelValue = e => {
      updateColumnEditValues({
        ...columnEditValues,
        displayName: e.target.value,
      })
    }

    const popoverCancelClick = () => {
      setPopoverAnchorEl(null)
    }

    const popoverApplyClick = () => {
      // if the editing column is changed to time, the previous time column needs to change to a dimension if it exists
      if (columnEditValues.category === 'PRIMARY_TIME_SERIES' && column.category !== 'PRIMARY_TIME_SERIES') {
        const prevTimeColumn = columns.find(col => col.category === 'PRIMARY_TIME_SERIES')

        if (prevTimeColumn) {
          prevTimeColumn.category = 'DIMENSION'
        }
      }

      columns.splice(columnEditValues.index, 1, { ...column, ...columnEditValues })

      updateColDefinitions(columns)
      setPopoverAnchorEl(null)
    }

    return popoverAnchorEl ? (
      <SPopover
        open={Boolean(popoverAnchorEl)}
        onClose={() => setPopoverAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        anchorEl={popoverAnchorEl}
        data-cy="dataset-builder-edit-column-popover"
      >
        <Typography variant="subtitle1">Edit column</Typography>
        <PopoverLabelContainer>
          <TextField
            label="Label"
            value={columnEditValues.displayName}
            onChange={updateEditLabelValue}
            data-cy="dataset-builder-edit-column-name"
            variant="standard"
          />
        </PopoverLabelContainer>
        <FormControl component="fieldset">
          <RadioGroup name="category" value={columnEditValues.category} onChange={handleRadioCategoryChange}>
            <div className="radio-container">
              <img alt="field icon" className="metric" src={processColumnsIcon('metric')} />
              <FormControlLabel
                value="METRIC"
                control={<Radio />}
                label="Metric: Useful for calculations and aggregations"
                disabled={!isPreviewColumnAllNumbers}
                data-cy="dataset-builder-edit-column-type-metric"
              />
            </div>
            <div className="radio-container">
              <img alt="field icon" className="dimension" src={processColumnsIcon('dimension')} />
              <FormControlLabel
                value="DIMENSION"
                control={<Radio />}
                label="Dimension: Attributes to view, group or filter on"
                data-cy="dataset-builder-edit-column-type-dimension"
              />
            </div>
            <div className="radio-container">
              <img alt="field icon" className="timeseries" src={processColumnsIcon('timeseries')} />
              <FormControlLabel
                value="PRIMARY_TIME_SERIES"
                control={<Radio />}
                label="timeSeries: Primary date/time column for your data (there can only be one, and one must exist)"
                data-cy="dataset-builder-edit-column-type-time"
              />
            </div>
          </RadioGroup>
        </FormControl>
        <div className="edit-column-button-container">
          {columnEditValues.category === 'PRIMARY_TIME_SERIES' ? (
            <div style={{ width: '40%' }}>
              <TextField
                value={columnEditValues.customDateFormat}
                placeholder="Custom date format (optional)"
                onChange={onDateFormatChange}
                style={{ width: '80%' }}
                variant="standard"
              />
              <Info
                style={{ cursor: 'pointer' }}
                onClick={e => {
                  updateCustomDateInfoPopoverVisiblity(!customDateInfoPopoverVisiblity)
                  updatePopoverAnchor(e.currentTarget)
                }}
              />
              <Popover
                anchorEl={popoverAnchor}
                open={customDateInfoPopoverVisiblity}
                onClose={() => updateCustomDateInfoPopoverVisiblity(false)}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <CustomDateInfoPopoverText>
                  Users can send a format to help interprete their column as a date. This uses the{' '}
                  <a href="https://www.joda.org/joda-time/key_format.html" target="_blank" rel="noopener noreferrer">
                    Joda-Time format.
                  </a>
                </CustomDateInfoPopoverText>
              </Popover>
            </div>
          ) : (
            <div />
          )}

          <div>
            <Button onClick={popoverCancelClick} data-cy="dataset-builder-edit-column-cancel">
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={popoverApplyClick}
              color="primary"
              data-cy="dataset-builder-edit-column-apply"
            >
              Apply
            </Button>
          </div>
        </div>
      </SPopover>
    ) : null
  }

  const customHeader = props => {
    const { category } = props.column.colDef

    return (
      <>
        <ColumnHeaderIcon alt="field icon" className={typeMap(category)} src={processColumnsIcon(typeMap(category))} />
        <SIconButton
          data-cy={`datasetbuilder-previewtable-customheader-edit-${props.displayName}`}
          onClick={event => headerEditClicked(event, props.column)}
        >
          <Edit />
        </SIconButton>
        {props.displayName}
      </>
    )
  }

  const changeOwnersValue = e => {
    const { value } = e.target

    updateOwnersValue(value)

    if (!ownersPopoverAnchor) {
      setOwnersPopoverAnchor(e.target)
    }
  }

  const onOwnerClick = owner => {
    const _selectedOwners = [...selectedOwners]

    _selectedOwners.push(owner)
    updateSelectedOwners(_selectedOwners)
    updateOwnersValue('')
    updateOwnersListVisibility(false)
  }

  const onDeleteOwner = index => {
    const _selectedOwners = [...selectedOwners]

    _selectedOwners.splice(index, 1)
    updateSelectedOwners(_selectedOwners)
  }

  const displayFinalizeErrors = () => (
    <FinalizeErrorsDetail>
      <summary>There were validation errors when finalizing your dataset</summary>
      <List>
        {finalizeErrors.map((item, index) => (
          <ListItem key={index}>
            <details>
              <summary>{item.error_message}</summary>
              <Typography style={{ paddingLeft: '30px' }}>
                Line: {item.line_number}, Column: {item.column_number}, Column Name: {item.column_name}
              </Typography>
              <Typography style={{ paddingLeft: '30px' }}>Record: {item.record.trim()}</Typography>
            </details>
          </ListItem>
        ))}
      </List>
    </FinalizeErrorsDetail>
  )

  let processedData = datasetInfo.preview_lines

  if (hasHeader) {
    processedData = processedData?.slice(1)
  }

  processedData = processedData?.map(row =>
    row.reduce((acc, rowValue, index) => {
      acc[colDefinitions[index].field] = rowValue

      return acc
    }, {})
  )

  const atLeastOneTimeSeriesColumnExists = colDefinitions?.some(el => el.category === 'PRIMARY_TIME_SERIES')

  return (
    <>
      <SDialogTitle data-cy="dataset-builder-step2-header-container">
        <SArrowBack onClick={() => updateSwipeableIndex(0)} />
        Step 2: review your data and adjust column data types
        <div>
          <Tooltip title="Helpcenter information for dataset builder">
            <a
              href={`${apiConfig.help.url}/03_datasets/what-is-a-dataset/#dataset-builder-using-file-upload`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <IconButton>
                <Icon>live_help</Icon>
              </IconButton>
            </a>
          </Tooltip>
          <SClose onClick={closeDatasetBuilder}>
            <Close />
          </SClose>
        </div>
      </SDialogTitle>
      <DialogContent>
        <Step2ContentContainer>
          <div className="dsb-form-container dsb-form-container-1">
            {!previouslyUploadedDatasetName && (
              <div>
                <Typography>FileUpload.</Typography>
              </div>
            )}
            <div>
              <TextField
                error={Boolean(datasetNameError)}
                required
                label={datasetNameError || 'Dataset Name'}
                value={previouslyUploadedDatasetName || datasetName}
                onChange={e => {
                  const { value } = e.target

                  if (/\s/i.test(value)) {
                    updateDatasetNameError('Dataset name cannot contain whitespace.')
                  } else if (/\./i.test(value)) {
                    updateDatasetNameError('Dataset name cannot contain a dot.')
                  } else if (/,/i.test(value)) {
                    updateDatasetNameError('Dataset name cannot contain a comma.')
                  } else if (/[^A-Za-z0-9_]+/g.test(value)) {
                    updateDatasetNameError('Dataset name can only contain numbers, letters, and underscores.')
                  } else if (datasetNameError) {
                    updateDatasetNameError('')
                  }

                  updateDatasetName(value)
                }}
                fullWidth
                disabled={Boolean(previouslyUploadedDatasetName)}
                data-cy="dataset-builder-name"
                variant="standard"
              />
              <TextField
                label="Description"
                fullWidth
                value={datasetDescription}
                onChange={e => updateDatasetDescription(e.target.value)}
                data-cy="dataset-builder-description"
                variant="standard"
              />
            </div>
          </div>
          <div className="dsb-form-container">
            <FormControl>
              <Typography>Data Classification</Typography>
              <RadioGroup
                value={dataClassification}
                onChange={e => {
                  updateDataClassification(e.target.value)
                }}
              >
                <FormControlLabel value="confidential" control={<Radio />} label="Confidential" />
                <FormControlLabel value="internal" control={<Radio />} label="Internal" />
              </RadioGroup>
            </FormControl>
          </div>
          <div>
            <TextField
              value={ownersValue}
              label="Ownership"
              placeholder="Add owners"
              onChange={changeOwnersValue}
              data-cy="dataset-builder-ownership"
              variant="standard"
              InputLabelProps={{ shrink: true }}
            />
            {ownersStatus.status === 'requested' && <Spinner layout="selfCentering" size="small" />}
            {ownersStatus.status >= 200 &&
              ownersStatus.status < 300 &&
              ownersListVisiblity &&
              Boolean(ownersStatus.data.length) && (
                <Popover
                  open={ownersListVisiblity}
                  onClose={() => updateOwnersListVisibility(false)}
                  anchorEl={ownersPopoverAnchor}
                  style={{ maxHeight: '350px' }}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                >
                  <List className="businessarea-list">
                    {ownersStatus.data
                      .filter(owner => !selectedOwners.map(o => o.user_group_name).includes(owner.user_group_name))
                      .map(owner => (
                        <ListItem
                          component="li"
                          button
                          key={owner._id}
                          onClick={() => {
                            onOwnerClick(owner)
                          }}
                        >
                          {owner.user_group_name}
                        </ListItem>
                      ))}
                  </List>
                </Popover>
              )}
            <SelectedOwnersContainer>
              {Boolean(selectedOwners.length) &&
                selectedOwners.map((owner, index) => (
                  <Chip
                    data-cy={`dataset-owner-chip-${index}`}
                    key={index}
                    label={owner.user_group_name}
                    onDelete={() => onDeleteOwner(index)}
                  />
                ))}
            </SelectedOwnersContainer>
          </div>
        </Step2ContentContainer>
        {renderPopover()}
        {Boolean(Object.keys(datasetInfo).length) && (
          <div className="ag-theme-balham" style={{ height: 200, width: '100%' }}>
            <AgGridReact
              frameworkComponents={{ agColumnHeader: customHeader }}
              rowData={processedData}
              defaultColDef={{
                minWidth: 100,
                resizable: false,
                sortable: false,
              }}
              modules={[ClientSideRowModelModule]}
              suppressFieldDotNotation
            >
              {colDefinitions.map(column => (
                <AgGridColumn {...column} key={column.field} />
              ))}
            </AgGridReact>
          </div>
        )}
        {!atLeastOneTimeSeriesColumnExists && (
          <NoTimeWarningMessage data-cy="dataset-builder-no-timeseries-warning-message">
            <Warning />
            No timeSeries column is selected, all data will default to a date of 2001-01-01
          </NoTimeWarningMessage>
        )}
        <SDialogActions>
          {Boolean(finalizeErrors.length) && displayFinalizeErrors()}
          {!isSubmittingFinalize && <Button onClick={closeDatasetBuilder}>Cancel</Button>}
          <Button
            disabled={(!previouslyUploadedDatasetName && !datasetName) || Boolean(datasetNameError)}
            onClick={() => {
              trackEvent({
                event: {
                  type: 'datasetbuilder-finalize-clicked',
                },
              })
              handleFinalizeSubmit()
            }}
            startIcon={isSubmittingFinalize && <Spinner size="small" />}
            variant="contained"
            color="primary"
            data-cy="dataset-builder-step2-finalize"
          >
            Finalize
          </Button>
        </SDialogActions>
      </DialogContent>
    </>
  )
}

DatasetBuilderEditUpload.propTypes = {
  datasetInfo: PropTypes.object.isRequired,
  updateSwipeableIndex: PropTypes.func.isRequired,
  closeDatasetBuilder: PropTypes.func.isRequired,
  updateDatasetInfo: PropTypes.func.isRequired,
  updateUploadStatus: PropTypes.func.isRequired,
  hasHeader: PropTypes.bool.isRequired,
  previouslyUploadedDatasetName: PropTypes.string,
  trackEvent: PropTypes.func,
}

export default DatasetBuilderEditUpload
