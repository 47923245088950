import React from 'react'
import { Spinner } from 'greenfield-utilities'
import PropTypes from 'prop-types'
import { Grid, Typography, IconButton, Icon, Card, CardContent, Divider } from '@mui/material'
import cloneDeep from 'lodash/cloneDeep'
import styled from 'styled-components'

import { showNotificationWarning } from '../../shared/Utilities/General.utility'

const SSpinner = styled(Spinner)`
  margin-top: 50px;
`

class FavoritesDashboards extends React.Component {
  state = {
    favoriteDashboards: [],
  }

  componentDidUpdate(prevProps) {
    const { favoriteDashboardsStatus } = this.props

    if (
      prevProps.favoriteDashboardsStatus.status === 'requested' &&
      favoriteDashboardsStatus.status >= 200 &&
      favoriteDashboardsStatus.status < 300
    ) {
      this.setState({
        favoriteDashboards: favoriteDashboardsStatus.data,
      })
    }
  }

  unFavoriteDashboard(e, _id) {
    const { updateFavorite } = this.props
    const { favoriteDashboards } = this.state
    const index = favoriteDashboards.findIndex(card => card._id === _id)

    e.preventDefault()

    updateFavorite({
      object_type: 'dashboards',
      object_id: _id,
      is_favorite: 'no',
    })

    if (index > -1) {
      this.setState(prevState => {
        const dashboards = cloneDeep(prevState.favoriteDashboards)
        dashboards.splice(index, 1)

        return {
          favoriteDashboards: dashboards,
        }
      })
    }
  }

  renderDashboards() {
    const { favoriteDashboards } = this.state
    const { isMobile } = this.props

    return (
      <Grid container spacing={1} className={`fav-dashboards ${favoriteDashboards.length ? '' : 'fullHeight'}`}>
        {Boolean(favoriteDashboards.length) &&
          favoriteDashboards.map((item, key) => (
            <Grid item xs={6} key={key} className="homePagePreviewWrapper" data-cy={`favDashId-${item?._id}`}>
              <Card className="dashPreview">
                <CardContent>
                  <Grid container className="fav-padding-comp">
                    <Grid item xs={1}>
                      <IconButton className="dashHeaderStarIcon" onClick={e => this.unFavoriteDashboard(e, item._id)}>
                        <Icon>star</Icon>
                      </IconButton>
                      {showNotificationWarning(item)}
                    </Grid>
                    <Grid item xs className="fav-card-description">
                      <div className="dashHeaderText">
                        <Typography variant="h3">{item.dashboard_title}</Typography>
                      </div>
                    </Grid>
                  </Grid>
                  <a href={`/dashboard/${item._id}`} target={isMobile ? '_self' : '_blank'} rel="noreferrer">
                    <div className="count-container">
                      <div>
                        <div>{item.card_count}</div>
                        <Typography variant="body2">Cards</Typography>
                      </div>
                    </div>
                    <Divider />
                    <Grid className="fav-padding-comp" container spacing={0}>
                      <Grid item sm={6}>
                        <Typography variant="body1">
                          {item.owners_access
                            .filter((el, index) => !index)
                            .map(el => (
                              <span key={el.user_group_name}>{el.user_group_name}</span>
                            ))}
                        </Typography>
                      </Grid>
                    </Grid>
                  </a>
                </CardContent>
              </Card>
            </Grid>
          ))}

        {favoriteDashboards && favoriteDashboards.length === 0 && (
          <Grid container className="noFavoriteDashMessage">
            <Grid item className="noMessageMobileHook">
              <Typography className="noMessageFirst">
                No favorite dashboards. Click the
                <Icon className="noMessageIcon">star_outline</Icon> on any card, dashboard or dataset to have them
                appear in your favorites.{' '}
              </Typography>
            </Grid>
          </Grid>
        )}
      </Grid>
    )
  }

  render() {
    const { favoriteDashboardsStatus, displayServiceErrorMessage } = this.props

    if (favoriteDashboardsStatus.status === 'requested') {
      return <SSpinner layout="selfCentering" size="large" />
    } else if (favoriteDashboardsStatus.status >= 200 && favoriteDashboardsStatus.status < 300) {
      return this.renderDashboards()
    } else if (favoriteDashboardsStatus.status === 'failed') {
      displayServiceErrorMessage('Your request to retrieve your favorite dashboards failed.')
    }

    return null
  }
}

FavoritesDashboards.propTypes = {
  favoriteDashboardsStatus: PropTypes.object,
  updateFavorite: PropTypes.func,
  displayServiceErrorMessage: PropTypes.func,
}

export default FavoritesDashboards
