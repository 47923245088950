import { call, put } from 'redux-saga/effects'
import axios from 'axios'
import { API_GATEWAY_URL } from './utils'

export const USERUPDATEFAVORITE_FETCH_REQUESTED = 'USERUPDATEFAVORITE_FETCH_REQUESTED'
export const USERUPDATEFAVORITE_FETCH_SUCCEEDED = 'USERUPDATEFAVORITE_FETCH_SUCCEEDED'
export const USERUPDATEFAVORITE_FETCH_FAILED = 'USERUPDATEFAVORITE_FETCH_FAILED'

export const UPDATECURRENTUSER_FETCH_REQUESTED = 'UPDATECURRENTUSER_FETCH_REQUESTED'
export const UPDATECURRENTUSER_FETCH_SUCCEEDED = 'UPDATECURRENTUSER_FETCH_SUCCEEDED'
export const UPDATECURRENTUSER_FETCH_FAILED = 'UPDATECURRENTUSER_FETCH_FAILED'

export const FETCHUSERTYPE_REQUESTED = 'FETCHUSERTYPE_REQUESTED'
export const FETCHUSERTYPE_SUCCEEDED = 'FETCHUSERTYPE_SUCCEEDED'
export const FETCHUSERTYPE_FAILED = 'FETCHUSERTYPE_FAILED'

export const UPDATE_ADMIN_OPT_IN_REQUESTED = 'UPDATE_ADMIN_OPT_IN_REQUESTED'
export const UPDATE_ADMIN_OPT_IN_SUCCEEDED = 'UPDATE_ADMIN_OPT_IN_SUCCEEDED'
export const UPDATE_ADMIN_OPT_IN_FAILED = 'UPDATE_ADMIN_OPT_IN_FAILED'

export const GET_USER_ACCESS_REQUESTED = 'GET_USER_ACCESS_REQUESTED'
export const GET_USER_ACCESS_SUCCEEDED = 'GET_USER_ACCESS_SUCCEEDED'
export const GET_USER_ACCESS_FAILED = 'GET_USER_ACCESS_FAILED'

export const FETCHUSERCARDSCOUNT_REQUESTED = 'FETCHUSERCARDSCOUNT_REQUESTED'
export const FETCHUSERCARDSCOUNT_SUCCEEDED = 'FETCHUSERCARDSCOUNT_SUCCEEDED'
export const FETCHUSERCARDSCOUNT_FAILED = 'FETCHUSERCARDSCOUNT_FAILED'

export const FETCH_USER_FAV_CARDS_COUNT_REQUESTED = 'FETCH_USER_FAV_CARDS_COUNT_REQUESTED'
export const FETCH_USER_FAV_CARDS_COUNT_SUCCEEDED = 'FETCH_USER_FAV_CARDS_COUNT_SUCCEEDED'
export const FETCH_USER_FAV_CARDS_COUNT_FAILED = 'FETCH_USER_FAV_CARDS_COUNT_FAILED'

export const FETCH_USER_FAV_DATASETS_COUNT_REQUESTED = 'FETCH_USER_FAV_DATASETS_COUNT_REQUESTED'
export const FETCH_USER_FAV_DATASETS_COUNT_SUCCEEDED = 'FETCH_USER_FAV_DATASETS_COUNT_SUCCEEDED'
export const FETCH_USER_FAV_DATASETS_COUNT_FAILED = 'FETCH_USER_FAV_DATASETS_COUNT_FAILED'

export const FETCH_USER_FAV_DASHBOARDS_COUNT_REQUESTED = 'FETCH_USER_FAV_DASHBOARDS_COUNT_REQUESTED'
export const FETCH_USER_FAV_DASHBOARDS_COUNT_SUCCEEDED = 'FETCH_USER_FAV_DASHBOARDS_COUNT_SUCCEEDED'
export const FETCH_USER_FAV_DASHBOARDS_COUNT_FAILED = 'FETCH_USER_FAV_DASHBOARDS_COUNT_FAILED'

export const FETCHUSERDASHBOARDSCOUNT_REQUESTED = 'FETCHUSERDASHBOARDSCOUNT_REQUESTED'
export const FETCHUSERDASHBOARDSCOUNT_SUCCEEDED = 'FETCHUSERDASHBOARDSCOUNT_SUCCEEDED'
export const FETCHUSERDASHBOARDSCOUNT_FAILED = 'FETCHUSERDASHBOARDSCOUNT_FAILED'

export const FETCHUSERDATASETSCOUNT_REQUESTED = 'FETCHUSERDATASETSCOUNT_REQUESTED'
export const FETCHUSERDATASETSCOUNT_SUCCEEDED = 'FETCHUSERDATASETSCOUNT_SUCCEEDED'
export const FETCHUSERDATASETSCOUNT_FAILED = 'FETCHUSERDATASETSCOUNT_FAILED'

export const FETCHUSERCALCFIELDSCOUNT_REQUESTED = 'FETCHUSERCALCFIELDSCOUNT_REQUESTED'
export const FETCHUSERCALCFIELDSCOUNT_SUCCEEDED = 'FETCHUSERCALCFIELDSCOUNT_SUCCEEDED'
export const FETCHUSERCALCFIELDSCOUNT_FAILED = 'FETCHUSERCALCFIELDSCOUNT_FAILED'

export default function userReducer(state = {}, action = {}) {
  switch (action.type) {
    case USERUPDATEFAVORITE_FETCH_REQUESTED: {
      const newState = {
        ...state,
        postFavoriteStatus: {
          status: 'requested',
        },
      }
      return newState
    }
    case USERUPDATEFAVORITE_FETCH_SUCCEEDED: {
      const newState = {
        ...state,
        getFaveCardsStatus: action.payload,
        postFavoriteStatus: {},
      }
      return newState
    }
    case USERUPDATEFAVORITE_FETCH_FAILED: {
      const newState = {
        ...state,
        getFaveCardsStatus: {
          status: 'failed',
        },
      }
      return newState
    }
    case UPDATECURRENTUSER_FETCH_REQUESTED: {
      const newState = {
        ...state,
        putUpdateDefaultFiltersStatus: {
          status: 'requested',
        },
      }
      return newState
    }
    case UPDATECURRENTUSER_FETCH_SUCCEEDED: {
      const newState = {
        ...state,
        putUpdateDefaultFiltersStatus: {
          status: action.payload,
        },
      }
      return newState
    }
    case UPDATECURRENTUSER_FETCH_FAILED: {
      const newState = {
        ...state,
        putUpdateDefaultFiltersStatus: {
          status: 'failed',
        },
      }
      return newState
    }
    case FETCHUSERTYPE_REQUESTED: {
      const newState = {
        ...state,
        userType: {
          status: 'requested',
        },
      }
      return newState
    }
    case FETCHUSERTYPE_SUCCEEDED: {
      const newState = {
        ...state,
        userType: action.payload,
      }
      return newState
    }
    case FETCHUSERTYPE_FAILED: {
      const newState = {
        ...state,
        userType: {
          status: 'failed',
        },
      }
      return newState
    }
    case GET_USER_ACCESS_REQUESTED: {
      const newState = {
        ...state,
        userAccessStatus: {
          status: 'requested',
        },
      }
      return newState
    }
    case UPDATE_ADMIN_OPT_IN_REQUESTED: {
      const newState = {
        ...state,
        userAdminOptIn: {
          status: 'requested',
        },
      }
      return newState
    }
    case UPDATE_ADMIN_OPT_IN_SUCCEEDED: {
      const newState = {
        ...state,
        userAdminOptIn: action.payload,
      }
      return newState
    }
    case UPDATE_ADMIN_OPT_IN_FAILED: {
      const newState = {
        ...state,
        userAdminOptIn: {
          status: 'failed',
        },
      }
      return newState
    }

    case GET_USER_ACCESS_SUCCEEDED: {
      const newState = {
        ...state,
        userAccessStatus: action.payload,
      }
      return newState
    }
    case GET_USER_ACCESS_FAILED: {
      const newState = {
        ...state,
        userAccessStatus: {
          status: 'failed',
        },
      }
      return newState
    }
    case FETCHUSERCARDSCOUNT_REQUESTED: {
      const newState = {
        ...state,
        userCardsCount: {
          status: 'requested',
        },
      }
      return newState
    }
    case FETCHUSERCARDSCOUNT_SUCCEEDED: {
      const newState = {
        ...state,
        userCardsCount: action.payload,
      }
      return newState
    }
    case FETCHUSERCARDSCOUNT_FAILED: {
      const newState = {
        ...state,
        userCardsCount: {
          status: 'failed',
        },
      }
      return newState
    }
    case FETCH_USER_FAV_CARDS_COUNT_REQUESTED: {
      const newState = {
        ...state,
        userFavCardsCount: {
          status: 'requested',
        },
      }
      return newState
    }
    case FETCH_USER_FAV_CARDS_COUNT_SUCCEEDED: {
      const newState = {
        ...state,
        userFavCardsCount: action.payload,
      }
      return newState
    }
    case FETCH_USER_FAV_CARDS_COUNT_FAILED: {
      const newState = {
        ...state,
        userFavCardsCount: {
          status: 'failed',
        },
      }
      return newState
    }
    case FETCH_USER_FAV_DASHBOARDS_COUNT_REQUESTED: {
      const newState = {
        ...state,
        userFavDashboardsCount: {
          status: 'requested',
        },
      }
      return newState
    }
    case FETCH_USER_FAV_DASHBOARDS_COUNT_SUCCEEDED: {
      const newState = {
        ...state,
        userFavDashboardsCount: action.payload,
      }
      return newState
    }
    case FETCH_USER_FAV_DASHBOARDS_COUNT_FAILED: {
      const newState = {
        ...state,
        userFavDashboardsCount: {
          status: 'failed',
        },
      }
      return newState
    }
    case FETCH_USER_FAV_DATASETS_COUNT_REQUESTED: {
      const newState = {
        ...state,
        userFavDatasetsCount: {
          status: 'requested',
        },
      }
      return newState
    }
    case FETCH_USER_FAV_DATASETS_COUNT_SUCCEEDED: {
      const newState = {
        ...state,
        userFavDatasetsCount: action.payload,
      }
      return newState
    }
    case FETCH_USER_FAV_DATASETS_COUNT_FAILED: {
      const newState = {
        ...state,
        userFavDatasetsCount: {
          status: 'failed',
        },
      }
      return newState
    }
    case FETCHUSERDASHBOARDSCOUNT_REQUESTED: {
      const newState = {
        ...state,
        userDashboardsCount: {
          status: 'requested',
        },
      }
      return newState
    }
    case FETCHUSERDASHBOARDSCOUNT_SUCCEEDED: {
      const newState = {
        ...state,
        userDashboardsCount: action.payload,
      }
      return newState
    }
    case FETCHUSERDASHBOARDSCOUNT_FAILED: {
      const newState = {
        ...state,
        userDashboardsCount: {
          status: 'failed',
        },
      }
      return newState
    }
    case FETCHUSERDATASETSCOUNT_REQUESTED: {
      const newState = {
        ...state,
        userDatasetsCount: {
          status: 'requested',
        },
      }
      return newState
    }
    case FETCHUSERDATASETSCOUNT_SUCCEEDED: {
      const newState = {
        ...state,
        userDatasetsCount: action.payload,
      }
      return newState
    }
    case FETCHUSERDATASETSCOUNT_FAILED: {
      const newState = {
        ...state,
        userDatasetsCount: {
          status: 'failed',
        },
      }
      return newState
    }
    case FETCHUSERCALCFIELDSCOUNT_REQUESTED: {
      const newState = {
        ...state,
        userCalcFieldsCount: {
          status: 'requested',
        },
      }
      return newState
    }
    case FETCHUSERCALCFIELDSCOUNT_SUCCEEDED: {
      const newState = {
        ...state,
        userCalcFieldsCount: action.payload,
      }
      return newState
    }
    case FETCHUSERCALCFIELDSCOUNT_FAILED: {
      const newState = {
        ...state,
        userCalcFieldsCount: {
          status: 'failed',
        },
      }
      return newState
    }
    default:
      return state
  }
}

export function updateFavorite(data) {
  return {
    type: USERUPDATEFAVORITE_FETCH_REQUESTED,
    payload: data,
  }
}

export function updateCurrentUser(filters) {
  return {
    type: UPDATECURRENTUSER_FETCH_REQUESTED,
    payload: filters,
  }
}

export function fetchUserType() {
  return {
    type: FETCHUSERTYPE_REQUESTED,
  }
}

export function fetchActAsAdmin(data) {
  return {
    type: UPDATE_ADMIN_OPT_IN_REQUESTED,
    payload: data,
  }
}

export function getUserAccess() {
  return {
    type: GET_USER_ACCESS_REQUESTED,
  }
}

export function fetchUserCardsCount() {
  return {
    type: FETCHUSERCARDSCOUNT_REQUESTED,
  }
}

export function fetchUserDashboardsCount() {
  return {
    type: FETCHUSERDASHBOARDSCOUNT_REQUESTED,
  }
}

export function fetchUserDatasetsCount() {
  return {
    type: FETCHUSERDATASETSCOUNT_REQUESTED,
  }
}

export function fetchUserCalcFieldsCount() {
  return {
    type: FETCHUSERCALCFIELDSCOUNT_REQUESTED,
  }
}

export function fetchUserFavCardsCount(data) {
  return {
    type: FETCH_USER_FAV_CARDS_COUNT_REQUESTED,
    payload: data,
  }
}

export function fetchUserFavDashboardsCount(data) {
  return {
    type: FETCH_USER_FAV_DASHBOARDS_COUNT_REQUESTED,
    payload: data,
  }
}

export function fetchUserFavDatasetsCount(data) {
  return {
    type: FETCH_USER_FAV_DATASETS_COUNT_REQUESTED,
    payload: data,
  }
}

export function* sendUserFavorite(action) {
  try {
    const userFavorites = yield call(axios, {
      method: 'put',
      url: `${API_GATEWAY_URL}/bi_reporting_assets/v2/user_favorites/current_user`,
      data: action.payload,
    })

    if (userFavorites.error) {
      const e = userFavorites.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }

    yield put({ type: 'USERUPDATEFAVORITE_FETCH_SUCCEEDED', payload: userFavorites })
  } catch (e) {
    yield put({ type: 'USERUPDATEFAVORITE_FETCH_FAILED' })
  }
}

export function* sendCurrentUser(action) {
  try {
    const user = yield call(axios, {
      method: 'put',
      url: `${API_GATEWAY_URL}/bi_reporting_assets/v2/users/current_user`,
      data: action.payload,
    })

    if (user.error) {
      const e = user.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }

    yield put({ type: 'FETCHUSERTYPE_SUCCEEDED', payload: user })
  } catch (e) {
    yield put({ type: 'UPDATECURRENTUSER_FETCH_FAILED' })
  }
}

export function* setActAsAdmin(action) {
  const methodType = action.payload.adminMode ? 'post' : 'delete'

  try {
    const userType = yield call(axios, {
      method: `${methodType}`,
      url: `${API_GATEWAY_URL}/bi_reporting_assets/v2/users/${action.payload.data._id}/system_admin`,
    })

    if (userType.error) {
      const e = userType.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }
    yield put({ type: 'UPDATE_ADMIN_OPT_IN_SUCCEEDED', payload: userType })
  } catch (e) {
    yield put({ type: 'UPDATE_ADMIN_OPT_IN_FAILED' })
  }
}

export function* getUserType() {
  try {
    const userType = yield call(axios, {
      method: 'get',
      url: `${API_GATEWAY_URL}/bi_reporting_assets/v2/users/current_user?field_groups=quick_metadata,groups`,
    })

    if (userType.error) {
      const e = userType.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }
    yield put({ type: 'FETCHUSERTYPE_SUCCEEDED', payload: userType })
  } catch (e) {
    yield put({ type: 'FETCHUSERTYPE_FAILED' })
  }
}

export function* fetchUserAccess() {
  try {
    const userAccess = yield call(axios, {
      method: 'get',
      url: `${API_GATEWAY_URL}/bi_reporting_assets/v2/feature_flags/user_access`,
    })
    if (userAccess.error) {
      const e = userAccess.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }
    yield put({ type: 'GET_USER_ACCESS_SUCCEEDED', payload: userAccess })
  } catch (e) {
    yield put({ type: 'GET_USER_ACCESS_FAILED' })
  }
}

export function* getUserCardsCount(action) {
  try {
    const userCardsCount = yield call(axios, {
      method: 'get',
      url: `${API_GATEWAY_URL}/bi_reporting_assets/v3/cards/count?status=all${
        action?.payload?.favoriteOnly ? '&favorites_only=yes' : '&owner_only=yes'
      }`,
    })

    if (userCardsCount.error) {
      const e = userCardsCount.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }
    if (action?.payload?.favoriteOnly) {
      yield put({ type: 'FETCH_USER_FAV_CARDS_COUNT_SUCCEEDED', payload: userCardsCount })
    } else {
      yield put({ type: 'FETCHUSERCARDSCOUNT_SUCCEEDED', payload: userCardsCount })
    }
  } catch (e) {
    yield put({ type: 'FETCHUSERCARDSCOUNT_FAILED' })
  }
}

export function* getUserDashboardsCount(action) {
  try {
    const userDashboardsCount = yield call(axios, {
      method: 'get',
      url: `${API_GATEWAY_URL}/bi_reporting_assets/v2/dashboards/count?status=all${
        action?.payload?.favoriteOnly ? '&favorites_only=yes' : '&owner_only=yes'
      }`,
    })

    if (userDashboardsCount.error) {
      const e = userDashboardsCount.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }
    if (action?.payload?.favoriteOnly) {
      yield put({ type: 'FETCH_USER_FAV_DASHBOARDS_COUNT_SUCCEEDED', payload: userDashboardsCount })
    } else {
      yield put({ type: 'FETCHUSERDASHBOARDSCOUNT_SUCCEEDED', payload: userDashboardsCount })
    }
  } catch (e) {
    yield put({ type: 'FETCHUSERDASHBOARDSCOUNT_FAILED' })
  }
}

export function* getUserDatasetsCount(action) {
  try {
    const userDatasetsCount = yield call(axios, {
      method: 'get',
      url: `${API_GATEWAY_URL}/bi_reporting_assets/v2/datasets/count?status=all${
        action?.payload?.favoriteOnly ? '&favorites_only=yes' : '&owner_only=yes'
      }`,
    })

    if (userDatasetsCount.error) {
      const e = userDatasetsCount.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }
    if (action?.payload?.favoriteOnly) {
      yield put({ type: 'FETCH_USER_FAV_DATASETS_COUNT_SUCCEEDED', payload: userDatasetsCount })
    } else {
      yield put({ type: 'FETCHUSERDATASETSCOUNT_SUCCEEDED', payload: userDatasetsCount })
    }
  } catch (e) {
    yield put({ type: 'FETCHUSERDATASETSCOUNT_FAILED' })
  }
}

export function* getUserCalcFieldsCount() {
  try {
    const userCalcFieldsCount = yield call(axios, {
      method: 'get',
      url: `${API_GATEWAY_URL}/bi_reporting_assets/v2/calculated_metrics/count?owner_only=yes&status=all`,
    })

    if (userCalcFieldsCount.error) {
      const e = userCalcFieldsCount.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }
    yield put({ type: 'FETCHUSERCALCFIELDSCOUNT_SUCCEEDED', payload: userCalcFieldsCount })
  } catch (e) {
    yield put({ type: 'FETCHUSERCALCFIELDSCOUNT_FAILED' })
  }
}
