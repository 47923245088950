import { call, put } from 'redux-saga/effects'
import axios from 'axios'
import { API_GATEWAY_URL } from './utils'

// Action types
export const SECURITYCONTROLLIST_FETCH_REQUESTED = 'SECURITYCONTROLLIST_FETCH_REQUESTED'
export const SECURITYCONTROLLIST_FETCH_SUCCEEDED = 'SECURITYCONTROLLIST_FETCH_SUCCEEDED'
export const SECURITYCONTROLLIST_FETCH_FAILED = 'SECURITYCONTROLLIST_FETCH_FAILED'

export const SECURITYCONTROLDETAIL_FETCH_REQUESTED = 'SECURITYCONTROLDETAIL_FETCH_REQUESTED'
export const SECURITYCONTROLDETAIL_FETCH_SUCCEEDED = 'SECURITYCONTROLDETAIL_FETCH_SUCCEEDED'
export const SECURITYCONTROLDETAIL_FETCH_FAILED = 'SECURITYCONTROLDETAIL_FETCH_FAILED'

export const GROUP_FETCH_REQUESTED = 'GROUP_FETCH_REQUESTED'
export const GROUP_FETCH_SUCCEEDED = 'GROUP_FETCH_SUCCEEDED'
export const GROUP_FETCH_FAILED = 'GROUP_FETCH_FAILED'

export const SINGLE_GROUP_REQUESTED = 'SINGLE_GROUP_REQUESTED'
export const SINGLE_GROUP_SUCCEEDED = 'SINGLE_GROUP_SUCCEEDED'
export const SINGLE_GROUP_FAILED = 'SINGLE_GROUP_FAILED'

// Reducer
export default function securityControlReducer(state = {}, action = {}) {
  switch (action.type) {
    case SECURITYCONTROLLIST_FETCH_REQUESTED: {
      const newState = {
        ...state,
        securityControlInfoStatus: {
          status: 'requested',
        },
      }
      return newState
    }
    case SECURITYCONTROLLIST_FETCH_SUCCEEDED: {
      const newState = {
        ...state,
        securityControlInfoStatus: action.payload,
      }
      return newState
    }
    case SECURITYCONTROLLIST_FETCH_FAILED: {
      const newState = {
        ...state,
        securityControlInfoStatus: {
          status: 'failed',
        },
      }
      return newState
    }
    case SECURITYCONTROLDETAIL_FETCH_REQUESTED: {
      const newState = {
        ...state,
        securityControlDetailStatus: {
          status: 'requested',
        },
      }
      return newState
    }
    case SECURITYCONTROLDETAIL_FETCH_SUCCEEDED: {
      const newState = {
        ...state,
        securityControlDetailStatus: action.payload,
      }
      return newState
    }
    case SECURITYCONTROLDETAIL_FETCH_FAILED: {
      const newState = {
        ...state,
        securityControlDetailStatus: {
          status: 'failed',
        },
      }
      return newState
    }
    case GROUP_FETCH_REQUESTED: {
      const newState = {
        ...state,
        groupSearchStatus: {
          status: 'requested',
        },
      }
      return newState
    }
    case GROUP_FETCH_SUCCEEDED: {
      const newState = {
        ...state,
        groupSearchStatus: action.payload,
      }
      return newState
    }
    case GROUP_FETCH_FAILED: {
      const newState = {
        ...state,
        groupSearchStatus: {
          status: 'failed',
        },
      }
      return newState
    }
    case SINGLE_GROUP_REQUESTED: {
      const newState = {
        ...state,
        singleGroupStatus: {
          status: 'requested',
        },
      }
      return newState
    }
    case SINGLE_GROUP_SUCCEEDED: {
      const newState = {
        ...state,
        singleGroupStatus: action.payload,
      }
      return newState
    }
    case SINGLE_GROUP_FAILED: {
      const newState = {
        ...state,
        singleGroupStatus: {
          status: 'failed',
        },
      }
      return newState
    }
    default:
      return state
  }
}

// Actions
export function getSecurityControlList(data) {
  return {
    type: SECURITYCONTROLLIST_FETCH_REQUESTED,
    payload: data,
  }
}

export function getSecurityControlDetail(data) {
  return {
    type: SECURITYCONTROLDETAIL_FETCH_REQUESTED,
    payload: data,
  }
}

export function getGroupSearchDetail(data) {
  return {
    type: GROUP_FETCH_REQUESTED,
    payload: data,
  }
}

export function getSingleGroupDetail(data) {
  return {
    type: SINGLE_GROUP_REQUESTED,
    payload: data,
  }
}

// Sagas (services requests)
export function* fetchSecurityControlList() {
  try {
    const securityControlList = yield call(axios, {
      method: 'get',
      url: `${API_GATEWAY_URL}/bi_reporting_assets/v2/security_controls`,
    })

    if (securityControlList.error) {
      const e = securityControlList.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }

    yield put({ type: SECURITYCONTROLLIST_FETCH_SUCCEEDED, payload: securityControlList })
  } catch (e) {
    yield put({ type: SECURITYCONTROLLIST_FETCH_FAILED })
  }
}

export function* fetchSecurityControlDetail(action) {
  try {
    const securityControl = yield call(axios, {
      method: 'get',
      url: `${API_GATEWAY_URL}/bi_reporting_assets/v2/security_controls/${action.payload}`,
    })

    if (securityControl.error) {
      const e = securityControl.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }

    yield put({ type: SECURITYCONTROLDETAIL_FETCH_SUCCEEDED, payload: securityControl })
  } catch (e) {
    yield put({ type: SECURITYCONTROLDETAIL_FETCH_FAILED })
  }
}

export function* fetchGroupSearchDetail(action) {
  try {
    const groupSearch = yield call(axios, {
      method: 'get',
      url: `${API_GATEWAY_URL}/bi_reporting_assets/v2/groups?search_string=${action.payload}`,
    })

    if (groupSearch.error) {
      const e = groupSearch.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }

    yield put({ type: GROUP_FETCH_SUCCEEDED, payload: groupSearch })
  } catch (e) {
    yield put({ type: GROUP_FETCH_FAILED })
  }
}

export function* fetchSingleGroupDetail(action) {
  try {
    const singleGroup = yield call(axios, {
      method: 'get',
      url: `${API_GATEWAY_URL}/bi_reporting_assets/v2/groups/${action.payload}`,
    })

    if (singleGroup.error) {
      const e = singleGroup.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }

    yield put({ type: SINGLE_GROUP_SUCCEEDED, payload: singleGroup })
  } catch (e) {
    yield put({ type: SINGLE_GROUP_FAILED })
  }
}
