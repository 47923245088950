import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Typography } from '@mui/material'
import { Spinner } from 'greenfield-utilities'
import { cardRender } from '../../../../ducks/cards'
import AssetTable, { AssetType } from '../../../shared/AssetTableSS/AssetTable'

const RecommendedCards = () => {
  const [cards, setCards] = useState([])

  const user = useSelector(state => state.user)
  const cardInfoStatusResponse = useSelector(state => state.cards.renderCardStatus)
  const dispatch = useDispatch()
  const jobTitleCard = '1108851'
  const jobTitleReccomendation = '1096424'

  useEffect(() => {
    if (user.userType?.data) {
      const data = {
        card: jobTitleCard,
        post: {
          drill: [],
          filters: [
            {
              ref_id: 'rb7fbd8waxo',
              obj_type: 'column',
              type: 'in',
              display_name: 'user_id',
              dimension: 'user_id',
              field_name: 'user_id',
              pattern: user.userType?.data._id,
              saved_filter_type: 'card_default',
            },
          ],
          secondary_time_periods: {},
          swap_drill_column: {},
          result_form: {
            date: '${raw}||${formatted}', // eslint-disable-line no-template-curly-in-string
            pivot: 'formatted',
          },
        },
      }
      dispatch(cardRender(data, false))
    }
  }, [user])

  useEffect(() => {
    if (cardInfoStatusResponse && cardInfoStatusResponse[jobTitleReccomendation]?.data) {
      const cardData = cardInfoStatusResponse[jobTitleReccomendation].data.query_results
      const cardIds = cardData.map(card => Number(card.rxogjioq14))
      setCards(cardIds)
    } else if (
      cardInfoStatusResponse &&
      cardInfoStatusResponse[1108851]?.data &&
      !Object.prototype.hasOwnProperty.call(cardInfoStatusResponse, Number(jobTitleReccomendation))
    ) {
      const data = {
        card: jobTitleReccomendation,
        post: {
          drill: [],
          filters: [
            {
              ref_id: 'rrb5tvmt1ii',
              field_name: 'Job Title',
              obj_type: 'column',
              type: 'in',
              pattern: cardInfoStatusResponse[1108851].data.query_results[0].re2qr0a0co,
              display_name: 'Job Title',
              dimension: 'Job Title',
            },
            {
              dimension: 'user_id',
              obj_type: 'field',
              type: 'not in',
              display_name: 'user_id',
              pattern: user.userType?.data._id,
            },
          ],
          time_period: {
            calendar_type: 'Fiscal',
            type: 'relative',
            interval: 'Last 5 Days',
          },
          secondary_time_periods: {},
          swap_drill_column: {},
          result_form: {
            date: '${raw}||${formatted}', // eslint-disable-line no-template-curly-in-string
            pivot: 'formatted',
          },
        },
      }
      dispatch(cardRender(data, false))
    }
  }, [cardInfoStatusResponse])

  return cards?.length === 0 ? (
    <>
      <Typography component="h1" variant="h6" sx={{ fontWeight: 'bold' }}>
        Recommended Cards
      </Typography>
      <Spinner />
    </>
  ) : (
    <>
      <Typography component="h1" variant="h6" sx={{ fontWeight: 'bold' }}>
        Recommended Cards
      </Typography>
      <AssetTable assetType={AssetType.CARDS} assetIds={cards} />
    </>
  )
}

export default RecommendedCards
