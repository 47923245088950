import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import Dashboardviewer from '../Dashboard/Dashboardviewer/Dashboardviewer.container'

const SiteHome = ({ routeProps = {}, isMobile = false, deviceType, setHeaderTitle = () => {}, siteObj = {} }) => {
  useEffect(() => {
    if (siteObj.status >= 200 && siteObj.status < 300) {
      setHeaderTitle(siteObj?.data?.name)
    }
  }, [siteObj])

  return siteObj.data?.pages[0]?.object_id ? (
    <Dashboardviewer
      activeTab={routeProps.match.params._index}
      isPrintLandscape={false}
      routeProps={routeProps}
      setHeaderTitle={() => {}}
      isMobile={isMobile}
      deviceType={deviceType}
      dashboardId={siteObj?.data?.pages[0]?.object_id}
      isHomePage
    />
  ) : (
    <div />
  )
}

SiteHome.propTypes = {
  routeProps: PropTypes.object,
  setHeaderTitle: PropTypes.func,
  siteObj: PropTypes.object,
  isMobile: PropTypes.bool,
  deviceType: PropTypes.array,
}
export default SiteHome
