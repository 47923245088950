import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Button, Grid, Typography } from '@mui/material'
import styled from 'styled-components'
import axios from 'axios'
import { Spinner, defaultStyles } from 'greenfield-utilities'
import { AgGridReact } from 'ag-grid-react'

import { difference } from '../util'
import { API_GATEWAY_URL } from '../../../ducks/utils'

const SSpinner = styled(Spinner)`
  margin-top: 130px;
  margin-bottom: 90px;
`

const HistoryDetail = ({ changeBy, changeDate, historyType, historyId, versionDate, patches, setPatchesCallback }) => {
  const [data, setData] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState({
    isError: false,
    message: '',
  })
  const pathRegex = /\/card_query_attribute|\/card_attribute/

  useEffect(() => {
    if (patches) {
      setData(patches)
      setIsLoading(false)
    } else {
      axios
        .get(
          `${API_GATEWAY_URL}/bi_reporting_assets/v2/object_history/object_type/${historyType}/object_id/${historyId}/version_date/${versionDate}`,
          {}
        )
        .then(response => {
          if (response.status >= 200 && response.status < 300) {
            if (response.data?.statusText === 'SUCCESS') {
              const res = response.data.result.evolve_patch.map(patch => ({
                changeType: patch.op,
                appliedPath: patch.path.replace(pathRegex, ''),
                value:
                  typeof patch.value === 'object'
                    ? JSON.stringify(
                        patch.path === '/card_attribute/style' ? difference(patch.value, defaultStyles) : patch.value,
                        null,
                        '\t'
                      )
                    : patch.value,
              }))
              setData(res)
              setPatchesCallback(res)
            } else if (response.data?.statusText === 'ACCESS_DENIED') {
              setError({
                isError: true,
                message: `ACCESS DENIED: Unable to view change history for this ${historyType}`,
              })
            }
            setIsLoading(false)
          }
        })
        .catch(error => {
          setError({ isError: true, message: error.response.data.message })
          setIsLoading(false)
        })
    }
  }, [])

  const onGridReady = params => {
    params.api.sizeColumnsToFit()
  }

  const defaultColumnDefinitionTemplate = {
    resizable: true,
    sortable: true,
  }

  const columnDefs = [
    {
      field: 'changeType',
      headerName: 'Change Type',
      width: 75,
      filter: 'agTextColumnFilter',
      filterParams: { caseSensitive: false, defaultOption: 'contains' },
      floatingFilter: true,
    },
    {
      field: 'appliedPath',
      headerName: 'Applied To',
      width: 150,
      filter: 'agTextColumnFilter',
      filterParams: { caseSensitive: false, defaultOption: 'contains' },
      floatingFilter: true,
    },
    {
      field: 'value',
      headerName: 'Value',
      wrapText: true,
      filter: 'agTextColumnFilter',
      filterParams: { caseSensitive: false, defaultOption: 'contains' },
      floatingFilter: true,
      cellStyle: params => {
        return params.value?.length > 50 ? { lineHeight: '25px' } : {}
      },
    },
  ]

  const getRowHeight = params => {
    const { data } = params
    return data.value?.length > 50 ? 20 * (Math.floor(data.value.length / 45) + 1) : 50
  }

  const getError = () => (
    <div className="error">
      <Typography variant="h2">An Error Has Occurred</Typography>
      <Typography variant="h6">{error.message}</Typography>
    </div>
  )

  const renderLoading = () => (
    <div>
      <Typography variant="h2" className="history-title">
        Loading Change Details...
      </Typography>
      <SSpinner layout="selfCentering" size="large" />
    </div>
  )

  return (
    <div style={{ marginLeft: 20, marginRight: 20 }}>
      <Grid container direction="row" justifyContent="space-between" alignItems="flex-end">
        <Grid item>
          <Typography variant="subtitle1">
            <b>Date / Time of Change:</b> {changeDate}
          </Typography>
          <Typography variant="subtitle1">
            <b>Change By:</b> {changeBy}
          </Typography>
          <Typography variant="subtitle1" style={{ marginTop: 40 }} data-cy="details-label">
            <b>Changes Applied</b>
          </Typography>
        </Grid>
        {historyType === 'card' && (
          <Grid item>
            <Link
              to={`/builder/card/${historyId}?version_date=${versionDate}`}
              style={{ textDecoration: 'none', color: 'rgba(0, 0, 0, 0.87)' }}
              target="_blank"
            >
              <Button color="primary" data-cy="open-builder-btn">
                Open in builder
              </Button>
            </Link>
          </Grid>
        )}
      </Grid>
      {isLoading ? (
        renderLoading()
      ) : error.isError ? (
        getError()
      ) : (
        <div className="ag-theme-alpine" style={{ height: 700, width: '100%' }}>
          <AgGridReact
            className="details-table"
            rowData={data}
            columnDefs={columnDefs}
            onGridReady={onGridReady}
            enableCellTextSelection
            defaultColDef={defaultColumnDefinitionTemplate}
            pagination
            paginationAutoPageSize
            getRowHeight={getRowHeight}
          />
        </div>
      )}
    </div>
  )
}

HistoryDetail.propTypes = {
  changeBy: PropTypes.string,
  changeDate: PropTypes.string,
  historyType: PropTypes.string,
  historyId: PropTypes.number,
  versionDate: PropTypes.string,
  patches: PropTypes.array,
  setPatchesCallback: PropTypes.func,
}

export default HistoryDetail
