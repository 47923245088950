import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { Close, Replay, DeleteOutline, CloudDownloadOutlined, InfoOutlined } from '@mui/icons-material'
import { withAnalytics } from '@praxis/component-analytics'
import {
  Button,
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Grid,
  Tooltip,
  TextField,
  Chip,
  Snackbar,
  Box,
  MenuItem,
} from '@mui/material'
import styled from 'styled-components'

import analyticsConfig from '../../analytics'
import { AgGridTable } from '../../shared/AgGrid/AgGridTableClientSide'
import { TableFilter } from '../../shared/AgGrid/TableFilter'
import { buildDownloadUrl } from '../../shared/util'

const SDialogTitle = styled(DialogTitle)`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .largeExportViewDetailDialogCloseBtn {
  }
`
const SBox = styled(Box)`
  padding: 1rem;
  display: flex;
  justify-content: center;

  .export-user-id-container {
    display: inline-block;
    padding-right: 30px;
  }

  .export-status-update-container {
    display: inline-flex;
    align-items: center;

    > div:first-child {
      padding-right: 20px;
    }
  }
`

const LargeExport = props => {
  const {
    largeExportJobList,
    deleteJobInfo,
    clearLargeExportCache,
    classes = {},
    dateFilterStatus,
    downloadedData,
    cancelJobInfo,
    userInfo,
    loginId,
  } = props

  const [largeExportJobs, setLargeExportJobs] = useState([])
  const [deletedJobInfo, setDeletedJobInfo] = useState(null)
  const [successfulDeleteJobDialogStatus, setSuccessfulDeleteJobDialogStatus] = useState(false)
  const [confirmDeleteDialogStatus, setConfirmDeleteDialogStatus] = useState(false)
  const [confirmDeleteJobInfo, setDonfirmDeleteJobInfo] = useState(null)
  const [viewMoreInfoDialogStatus, setViewMoreInfoDialogStatus] = useState(false)
  const [dateFilters, setDateFilters] = useState(null)
  const [viewMoreDetailRowInfo, setViewMoreDetailRowInfo] = useState(null)
  const [failedDeleteJobDialogStatus, setFailedDeleteJobDialogStatus] = useState(false)
  const [jobDownloadData, setJobDownloadData] = useState(null)
  const [downloadJobName, setDownloadJobName] = useState(null)
  const [cancelJobDialogStatus, setCancelJobDialogStatus] = useState(false)
  const [jobToCancelInfo, setJobToCancelInfo] = useState(null)
  const [canceledJobInfo, setCanceledJobInfo] = useState(null)
  const [successfulCancelDialogStatus, setSuccessfulCancelDialogStatus] = useState(false)
  const [failedCancelJobDialogStatus, setFailedCancelJobDialogStatus] = useState(false)
  const [downloadErrorBoxStatus, setDownloadErrorBoxStatus] = useState(false)
  const [adminUserIdSearch, setAdminUserIdSearch] = useState(loginId)
  const [adminSearchStatus, setAdminSearchStatus] = useState('All')
  const [adminSearchQueryStatus, setAdminSearchQueryStatus] = useState(null)

  const [deleteBtnStatus, setDeleteBtnStatus] = useState(false)
  const [cancelBtnStatus, setCancelBtnStatus] = useState(false)
  const [disableAllDownloadBtns, setDisableAllDownloadBtns] = useState(false)
  const [pageSize, setPageSize] = useState(10)
  const [gridApi, setGridApi] = useState(null)
  const gridRef = useRef()

  const isAdmin = userInfo?.is_admin === 'yes'
  const isProduction = process.env.NODE_ENV === 'production'

  const onGridReady = params => {
    setGridApi(params.api)
  }

  useEffect(() => {
    if (deleteJobInfo?.status >= 200 && deleteJobInfo?.status < 300) {
      handleConfirmDeleteDialogToggle()
      setDeletedJobInfo(deleteJobInfo)
      setSuccessfulDeleteJobDialogStatus(true)
      setDeleteBtnStatus(false)
      setLargeExportJobs([])
      props.fetchLargeExportJobs()
      clearLargeExportCache()
    } else if (deleteJobInfo?.status >= 400 && deleteJobInfo?.status < 600) {
      handleConfirmDeleteDialogToggle()
      setFailedDeleteJobDialogStatus(true)
      gridRef.current.api.hideOverlay()
      setDeleteBtnStatus(false)
      setDeletedJobInfo(deleteJobInfo?.data)
    }
  }, [deleteJobInfo])

  useEffect(() => {
    if (largeExportJobList && largeExportJobList.status >= 200 && largeExportJobs.length === 0) {
      gridRef.current.api.hideOverlay()
      setLargeExportJobs(largeExportJobList.jobs.data)
    }
  }, [largeExportJobList])

  useEffect(() => {
    if (dateFilterStatus && dateFilterStatus.status >= 200 && dateFilterStatus.status < 300) {
      setDateFilters(dateFilterStatus.data)
    }
  }, [dateFilterStatus])

  useEffect(() => {
    if (downloadedData && downloadedData.status >= 200 && downloadedData.status < 300) {
      setJobDownloadData(downloadedData.data.data)
      setDisableAllDownloadBtns(false)
      gridRef.current.api.hideOverlay()
    } else if (downloadedData && downloadedData.status >= 400 && downloadedData.status <= 600) {
      setDownloadErrorBoxStatus(true)
      gridRef.current.api.hideOverlay()
      setDisableAllDownloadBtns(false)
    }
  }, [downloadedData])

  useEffect(() => {
    if (cancelJobInfo && cancelJobInfo.status && cancelJobInfo.status >= 200 && cancelJobInfo.status < 300) {
      setSuccessfulCancelDialogStatus(true)
      handleCloseConfirmCancelDialog()
      setCancelBtnStatus(false)
      handleTableRefresh()
      setCanceledJobInfo({
        success: true,
        data: cancelJobInfo.data.data,
      })
      clearLargeExportCache()
    } else if (cancelJobInfo && cancelJobInfo.status && cancelJobInfo.status >= 400 && cancelJobInfo.status <= 600) {
      setFailedCancelJobDialogStatus(true)
      setCancelBtnStatus(false)
      handleCloseConfirmCancelDialog()

      setCanceledJobInfo({
        success: false,
        data: cancelJobInfo?.data?.data,
      })
    }
  }, [cancelJobInfo])

  const sendFireflyEvent = (isCustomEvent, eventObj) => {
    if (isProduction) {
      if (isCustomEvent) {
        props.trackCustomEvent({
          type: eventObj.eventName,
          key: eventObj.key,
          value: JSON.stringify(eventObj.data),
        })
      } else {
        props.trackEvent({
          event: {
            type: eventObj.eventName,
          },
        })
      }
    }
  }

  const formatDate = (date, useUtc = true) => {
    return useUtc
      ? moment(date).utc(date).local().format('MM/DD/YYYY h:mm:ss A')
      : moment(date).local().format('MM/DD/YYYY h:mm:ss A')
  }
  const getTimeWindow = (data, useUtc) => {
    if (dateFilters) {
      const { payload } = data
      const { time_period } = payload?.request_payload // eslint-disable-line camelcase
      const { calendar_type, interval } = time_period // eslint-disable-line camelcase
      const { Fiscal, Gregorian } = dateFilters
      let beginDate
      let endDate
      let timeString

      // eslint-disable-next-line camelcase
      if (time_period.type !== 'absolute') {
        const calendarType =
          time_period && calendar_type && calendar_type?.toLowerCase() === 'fiscal' // eslint-disable-line camelcase
            ? Fiscal
            : Gregorian

        const jobFilterData = interval?.toLowerCase() === 'last segment' ? 'Last Segment' : calendarType[interval]

        if (!jobFilterData) {
          timeString = time_period?.interval // eslint-disable-line camelcase
        } else if (interval === 'All Time') {
          timeString = 'All Time'
        } else {
          beginDate = jobFilterData['Begin Date']
          endDate = jobFilterData['End Date']
          timeString = (
            <>
              <Typography variant="subtitle1">{`${formatDate(beginDate, useUtc)} to`}</Typography>
              <Typography variant="subtitle1">{formatDate(endDate, useUtc)}</Typography>
            </>
          )
        }
      } else {
        const startEndDate = time_period?.interval.split('/') // eslint-disable-line camelcase
        beginDate = startEndDate[0]
        endDate = startEndDate[1]
        timeString = (
          <>
            <Typography variant="subtitle1">{`${formatDate(beginDate, useUtc)} to`}</Typography>
            <Typography variant="subtitle1">{formatDate(endDate, useUtc)}</Typography>
          </>
        )
      }

      return interval?.toLowerCase() === 'last segment' ? 'Last Segment' : timeString
    }

    return null
  }

  const getColumns = () => {
    const columns = [
      {
        field: 'payload.request_payload.request_label',
        headerName: 'Request Label',
        minWidth: 300,
        cellRendererFramework: ({ data, value }) => {
          const requestId = data.request_id
          const cardId = data?.payload?.metadata?.card_id
          const cardTitle = value || cardId
          return (
            <Box className="requestLabelColumn" id={`${requestId}-requestLabel`}>
              <Link to={`/card/${cardId}`} target="_blank" className="largeExportCardLink">
                {cardTitle}
              </Link>
            </Box>
          )
        },
      },
      {
        field: 'status',
        cellRendererFramework: ({ data, value }) => {
          const completionEst = data?.estimated_wait_time_msg
          return (
            <Box component="span" id={`${data.request_id}-status`}>
              <Typography display="inline">{value === 'DEQUEUED' ? 'RUNNING' : value}</Typography>
              <Typography display="inline">{completionEst ? ` - ${completionEst}` : ''}</Typography>
            </Box>
          )
        },
      },
      {
        field: 'end_of_life',
        minWidth: 200,
        headerName: 'Data End of Life',
        cellRendererFramework: ({ data, value }) => {
          const { status } = data
          return (
            <div id={`${data.request_id}-endOfLife`}>
              {status.toLowerCase() === 'success' || status.toLowerCase() === 'error' ? formatDate(value) : ''}
            </div>
          )
        },
      },
      {
        field: 'created_date',
        minWidth: 200,
        headerName: 'Date of Request',
        cellRendererFramework: ({ data, value }) => {
          return <div id={`${data.request_id}-dateOfRequest`}>{formatDate(value)}</div>
        },
      },
      {
        field: 'payload.request_payload.time_period',
        minWidth: 250,
        sortable: false,
        filter: false,
        headerName: 'Time Period',
        cellRendererFramework: ({ data }) => {
          return <div id={`${data.request_id}-timePeriod`}>{getTimeWindow(data, false)}</div>
        },
      },
      {
        field: 'status',
        width: 180,
        headerName: 'Actions',
        sortable: false,
        filter: false,
        pinned: 'right',
        cellRendererFramework: ({ data, value }) => {
          const downloadUrl = buildDownloadUrl(data.payload.metadata.url_path)
          const cardId = data.payload.metadata.card_id
          const cardTitle = data.payload.request_payload.request_label || cardId
          const fireflyEventName = 'LargeExportDownloadBtnClickFromRow'
          const requestId = data.request_id

          const showWarningIcon = data.status === 'SUCCESS' && data.payload?.metadata?.partial_rows
          const isDisabled = data.status === 'DEQUEUED'
          const requestLabel = data?.payload?.request_payload?.request_label
            ? data?.payload?.request_payload?.request_label?.replace(/ /g, '')
            : data?.payload?.metadata?.card_title

          return (
            <Grid container>
              <Grid item>
                <Tooltip title="Show Details">
                  <IconButton
                    className="largeExportShowDetailBtn"
                    color="info"
                    onClick={() => handleViewMoreJobInfoToggle(data)}
                    id={`${requestId}-showDetailsBtn`}
                  >
                    <InfoOutlined color="info" />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item>
                {showWarningIcon ? (
                  <Tooltip title="Not all rows retrieved">
                    <IconButton
                      component="span"
                      onClick={() => downloadData(downloadUrl, cardTitle, fireflyEventName)}
                      disabled={value.toLowerCase() !== 'success' || disableAllDownloadBtns}
                      id={`${requestId}-downloadBtn`}
                    >
                      <CloudDownloadOutlined color={showWarningIcon ? 'warning' : 'success'} />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <Tooltip title="Download">
                    <IconButton
                      component="span"
                      className={`${classes.downloadBtn} largeExportDownloadbtn`}
                      onClick={() => downloadData(downloadUrl, cardTitle, fireflyEventName)}
                      disabled={value.toLowerCase() !== 'success' || disableAllDownloadBtns}
                      id={`${requestId}-downloadBtn`}
                    >
                      <CloudDownloadOutlined
                        color={value.toLowerCase() !== 'success' || disableAllDownloadBtns ? 'inherit' : 'success'}
                      />
                    </IconButton>
                  </Tooltip>
                )}
              </Grid>
              <Grid item>
                <Tooltip title="Delete">
                  <IconButton
                    component="span"
                    disabled={isDisabled}
                    onClick={() => {
                      handleTriggerConfirmDeleteDialog(data)
                    }}
                    className={`largeExportDelete-${requestLabel}Btn`}
                    id={`${requestId}-deleteBtn`}
                  >
                    <DeleteOutline color={isDisabled ? 'inherit' : 'error'} />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          )
        },
      },
    ]

    const adminCol = {
      field: 'user_id',
      width: 100,
      headerName: 'User ID',
      cellRendererFramework: ({ data, value }) => {
        return (
          <Typography id={`${data.request_id}-userId`} variant="subtitle1">
            {value}
          </Typography>
        )
      },
    }

    if (isAdmin) {
      columns.splice(5, 0, adminCol)
    }

    return [...columns]
  }

  const adminSearch = () => {
    const searchString = constructAdminSearchString(adminUserIdSearch, adminSearchQueryStatus)

    gridRef.current.api.showLoadingOverlay()
    setLargeExportJobs([])
    props.fetchLargeExportJobs({
      queryString: searchString,
    })
  }

  const updateAdminSearch = e => {
    const userId = e.target.value || 'all'

    setAdminUserIdSearch(`user_id=${userId.toLowerCase()}`)
  }

  const updateAdminSearchStatus = e => {
    const status = e.target.value === 'All' ? '' : `status=${e.target.value}&`
    setAdminSearchQueryStatus(status)
    setAdminSearchStatus(e.target.value)
  }

  const constructAdminSearchString = (user, status) => {
    const userSearchString = adminUserIdSearch === loginId ? `user_id=${user.toLowerCase()}` : user

    return `?${userSearchString || ''}&${status || ''}type=EXPORT`
  }

  const handleDeleteJob = row => {
    const { request_id } = row // eslint-disable-line camelcase
    setDeleteBtnStatus(true)
    gridRef.current.api.showLoadingOverlay()

    props.deleteLargeExportJob({
      // eslint-disable-next-line camelcase
      request_id,
    })
  }

  const handleConfirmCancelDialogToggle = row => {
    setJobToCancelInfo(row)
    handleViewMoreJobInfoToggle(null)
    toggleCancelDialog()
  }

  const handleCloseConfirmCancelDialog = () => {
    toggleCancelDialog()
    setJobToCancelInfo(null)
  }

  const toggleCancelDialog = () => {
    setCancelJobDialogStatus(!cancelJobDialogStatus)
  }

  const handleViewMoreJobInfoToggle = rowInfo => {
    const jobStatus = rowInfo?.status
    const isErrorState = jobStatus?.toLowerCase() === 'error'
    const numberOfEvents = rowInfo?.events?.length
    const lastEvent = rowInfo?.events[numberOfEvents - 1]

    const eventObj = {
      eventName: isErrorState ? 'errorJobViewMoreBtnClick' : 'jobViewMoreBtnClick',
      key: rowInfo?.request_id, // eslint-disable-line camelcase
      data: {
        user: loginId,
        lastEvent,
      },
    }

    sendFireflyEvent(isErrorState, eventObj)

    setViewMoreDetailRowInfo(rowInfo)
    setViewMoreInfoDialogStatus(!viewMoreInfoDialogStatus)
  }

  const handleCloseSuccessfulCancelDialog = () => {
    setSuccessfulCancelDialogStatus(false)
    setCanceledJobInfo(null)
  }

  const handleCloseFailedCancelJobDialog = () => {
    setCanceledJobInfo(null)
    setFailedCancelJobDialogStatus(false)
  }

  const handleCloseDownloadErrorBox = () => {
    setDownloadErrorBoxStatus(false)
  }

  const renderDownloadAnchor = () => {
    /*
      This is a standard way of triggering a manual download.

      We need the ability to trigger this manually, rather than on mount of a component
    */
    if (jobDownloadData && downloadedData && downloadedData.status >= 200 && downloadedData.status < 300) {
      const anchor = document.createElement('a')
      document.body.appendChild(anchor)

      const objectUrl = window.URL.createObjectURL(jobDownloadData)

      anchor.href = objectUrl
      anchor.download = `${downloadJobName}.zip`
      anchor.click()

      window.URL.revokeObjectURL(objectUrl)

      // get rid of anchor
      document.body.removeChild(anchor)

      setJobDownloadData(null)
      setDownloadJobName(null)
      clearLargeExportCache()
    }

    return null
  }

  const downloadData = (path, cardTitle, fireflyEventname) => {
    props.trackEvent({
      event: {
        type: fireflyEventname,
      },
    })

    gridRef.current.api.showLoadingOverlay()
    setDisableAllDownloadBtns(true)
    setDownloadJobName(cardTitle)
    props.fetchDownloadData(path)
  }

  const handleTriggerConfirmDeleteDialog = row => {
    setDonfirmDeleteJobInfo(row)
    setConfirmDeleteDialogStatus(true)
  }

  const handleConfirmDeleteDialogToggle = () => {
    setConfirmDeleteDialogStatus(!confirmDeleteDialogStatus)
  }

  const renderJobTable = () => {
    return (
      <Box m={2} mb={5}>
        <TableFilter gridRef={gridRef} pageSize={pageSize} setPageSize={setPageSize} />
        <AgGridTable
          gridRef={gridRef}
          gridApi={gridApi}
          onGridReady={onGridReady}
          data={largeExportJobs}
          columns={getColumns()}
          pageSize={pageSize}
        />
      </Box>
    )
  }

  const renderDeleteJobSuccessfulDialog = () => {
    const requestLabel = confirmDeleteJobInfo?.payload?.request_payload?.request_label // eslint-disable-line
    return (
      <Dialog open={confirmDeleteDialogStatus} onClose={handleConfirmDeleteDialogToggle}>
        <DialogContent>
          <Typography variant="subtitle1">
            Are you sure you want to delete the large export job for - {`"${requestLabel}"`}?
          </Typography>
          <br />
          <Typography>Data for completed requests will be removed.</Typography>
        </DialogContent>

        <DialogActions className={classes.confirmDeleteActionItems}>
          <Button
            className="largeExportCancelDeleteBtn"
            data-cy="large-export-cancel-delete-btn"
            id="large-export-cancel-delete-btn"
            color="secondary"
            onClick={handleConfirmDeleteDialogToggle}
            disabled={deleteBtnStatus}
          >
            Cancel
          </Button>
          <Button
            className="largeExportDeleteConfirmBtn"
            data-cy="large-export-confirm-delete-btn"
            id="large-export-confirm-delete-btn"
            onClick={() => handleDeleteJob(confirmDeleteJobInfo)}
            disabled={deleteBtnStatus}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  const renderCancelJobConfirmDialog = () => {
    const cardId = jobToCancelInfo?.payload?.metadata?.card_id // eslint-disable-line camelcase
    const cardName = jobToCancelInfo?.payload?.request_payload?.request_label || cardId // eslint-disable-line camelcase

    return (
      <Dialog open={cancelJobDialogStatus} onClose={handleCloseConfirmCancelDialog}>
        <DialogContent>
          <Typography variant="subtitle1">
            {`Are you sure you want to cancel the large export job for "${cardName}"?`}
          </Typography>
        </DialogContent>

        <DialogActions>
          <Button color="secondary" onClick={handleCloseConfirmCancelDialog} disabled={cancelBtnStatus}>
            Close
          </Button>
          <Button onClick={() => handleViewMoreCancelJobClick(jobToCancelInfo)} disabled={cancelBtnStatus}>
            Cancel Job
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  const renderFilterChip = chipData => {
    const isEllipsis = chipData?.pattern?.includes(',') ? ',...' : ''

    return (
      <Tooltip
        id={chipData.dimension}
        title={`${chipData.filter_name || chipData.display_name} ${chipData.type} ${
          chipData.pattern
            ? chipData.pattern.toString()
            : chipData.value
            ? chipData.value
            : `${chipData.lowervalue}-${chipData.uppervalue}`
        }`}
      >
        <Chip
          variant="outlined"
          label={`${chipData.display_name} ${chipData.type} ${
            chipData.pattern
              ? chipData.pattern?.toString().split(',')[0]
              : chipData.value
              ? chipData.value
              : `${chipData.lowervalue}-${chipData.uppervalue}`
          }${isEllipsis} `}
        />
      </Tooltip>
    )
  }

  const handleViewMoreCancelJobClick = row => {
    setCancelBtnStatus(true)
    props.cancelJob(row.request_id)
  }

  const renderViewMoreDetailDialog = () => {
    if (dateFilters && viewMoreDetailRowInfo) {
      const { filters } = viewMoreDetailRowInfo?.payload?.request_payload // eslint-disable-line camelcase
      const cardTitle =
        viewMoreDetailRowInfo?.payload?.metadata?.card_title || viewMoreDetailRowInfo?.payload?.metadata?.card_id // eslint-disable-line camelcase
      const urlPath = buildDownloadUrl(viewMoreDetailRowInfo?.payload?.metadata?.url_path) // eslint-disable-line camelcase
      const { status, events } = viewMoreDetailRowInfo
      const requestId = viewMoreDetailRowInfo?.request_id // eslint-disable-line camelcase
      const endOfLife = viewMoreDetailRowInfo?.end_of_life // eslint-disable-line camelcase
      const timePeriodText = getTimeWindow(viewMoreDetailRowInfo, false)
      const numOfEvents = events.length
      const createDate = viewMoreDetailRowInfo?.created_date // eslint-disable-line camelcase
      const statusState = status.toLowerCase() === 'success'
      const partialData = viewMoreDetailRowInfo.payload?.metadata?.partial_rows

      // We need the last event because it would contain the error message - 49754518/Art
      const lastEvent = events[numOfEvents - 1]

      return (
        <Dialog
          open={viewMoreInfoDialogStatus}
          maxWidth="md"
          fullWidth
          onClose={() => handleViewMoreJobInfoToggle(null)}
        >
          <SDialogTitle>
            <Typography variant="h2" component="span">
              <b>{cardTitle}</b> - Large Export
            </Typography>

            <IconButton
              className="largeExportViewDetailDialogCloseBtn"
              data-cy="large-export-close-dialog-btn"
              id="large-export-close-dialog-btn"
              aria-label="close"
              onClick={() => handleViewMoreJobInfoToggle(null)}
            >
              <Close />
            </IconButton>
          </SDialogTitle>

          <DialogContent>
            <Grid container>
              <Grid container item xs={6} direction="column">
                <Grid item>
                  <Typography>
                    <b>Creation date:</b> {formatDate(createDate)}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography>
                    <b>Status:</b>{' '}
                    {viewMoreDetailRowInfo?.status === 'DEQUEUED' ? 'RUNNING' : viewMoreDetailRowInfo?.status}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography>
                    <b>Data End of Life:</b> {formatDate(endOfLife)}
                  </Typography>
                </Grid>
                {statusState && (
                  <Grid item>
                    <Typography>
                      <b>Were all rows exported:</b> {partialData ? 'No' : 'Yes'}
                    </Typography>
                  </Grid>
                )}
              </Grid>
              <Grid container item xs={6} direction="column">
                <Grid item>
                  <Typography>
                    <b>Time Period:</b> {timePeriodText}
                  </Typography>
                </Grid>

                <Grid item>
                  <Typography>
                    <b>Filters:</b>{' '}
                    {filters.length
                      ? filters.map((item, key) => <React.Fragment key={key}>{renderFilterChip(item)}</React.Fragment>)
                      : 'None'}
                  </Typography>
                </Grid>

                <Grid item>
                  <Typography>
                    <b>Request ID:</b> {requestId}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            {status.toLowerCase() === 'error' && (
              <TextField
                disabled
                label="Error message"
                fullWidth
                multiline
                rows={3}
                sx={{ marginTop: 2 }}
                value={lastEvent.message}
                variant="outlined"
              />
            )}
          </DialogContent>

          <DialogActions className={classes.viewMoreDetailDialogActionItems}>
            <Button
              disabled={!statusState}
              onClick={() => downloadData(urlPath, cardTitle, 'LargeExportDownloadBtnClickFromDialog')}
            >
              Download
            </Button>

            {status === 'QUEUED' && (
              <Button color="secondary" onClick={() => handleConfirmCancelDialogToggle(viewMoreDetailRowInfo)}>
                Cancel Job
              </Button>
            )}
          </DialogActions>
        </Dialog>
      )
    }
    return null
  }

  const handleCloseSuccessfulDeleteJobDialog = () => {
    setSuccessfulDeleteJobDialogStatus(false)
  }

  const handleTableRefresh = () => {
    gridRef.current.api.showLoadingOverlay()
    setLargeExportJobs([])
    props.fetchLargeExportJobs()
  }

  const handleCloseFailedDeleteJobDialog = () => {
    setFailedDeleteJobDialogStatus(false)
  }

  const renderSuccessfulDeleteDialog = () => {
    const requestLabel = deletedJobInfo?.data?.data?.payload?.request_payload?.request_label // eslint-disable-line camelcase

    return (
      <Dialog open={successfulDeleteJobDialogStatus} onClose={handleCloseSuccessfulDeleteJobDialog}>
        <DialogTitle>
          <Grid container justifyContent="space-between" alignItems="center">
            <Typography variant="subtitle1">Job successfully removed!</Typography>
            <IconButton aria-label="close" onClick={handleCloseSuccessfulDeleteJobDialog}>
              <Close />
            </IconButton>
          </Grid>
        </DialogTitle>

        <DialogContent>
          <Typography>
            Job <strong>{requestLabel}</strong> deleted
          </Typography>
        </DialogContent>

        <DialogActions>
          <Button
            className="largeExportSuccessfulDeleteMessageCloseBtn"
            data-cy="large-export-successful-delete-close-btn"
            id="large-export-successful-delete-close-btn"
            onClick={handleCloseSuccessfulDeleteJobDialog}
            color="secondary"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  const renderSuccessfulCancelDialog = () => {
    const requestLabel = canceledJobInfo?.data?.payload?.request_payload?.request_label // eslint-disable-line camelcase
    return (
      <Dialog open={successfulCancelDialogStatus} onClose={handleCloseSuccessfulCancelDialog}>
        <DialogTitle>
          <Typography>Job successfully canceled</Typography>

          <Box component="span">
            <IconButton aria-label="close" onClick={handleCloseSuccessfulCancelDialog}>
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>

        <DialogContent>
          Job <Typography>{requestLabel}</Typography> canceled
        </DialogContent>

        <DialogActions>
          <Button color="secondary" onClick={handleCloseSuccessfulCancelDialog}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  const renderFailedCancelDialog = () => {
    return (
      <Dialog open={failedCancelJobDialogStatus} onClose={handleCloseFailedCancelJobDialog}>
        <DialogTitle>
          <Typography>:( There was an error</Typography>

          <span>
            <IconButton aria-label="close" onClick={handleCloseFailedCancelJobDialog}>
              <Close />
            </IconButton>
          </span>
        </DialogTitle>

        <DialogContent>
          <Typography color="error">The job failed to cancel</Typography>
        </DialogContent>

        <DialogActions>
          <Button color="secondary" onClick={handleCloseFailedCancelJobDialog}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  const renderFailedDeleteDialog = () => {
    return (
      <Dialog open={failedDeleteJobDialogStatus} onClose={handleCloseFailedDeleteJobDialog}>
        <DialogTitle>
          <Grid container justifyContent="space-between" alignItems="center">
            <Typography variant="subtitle1">There was an error trying to delete the job</Typography>
            <IconButton aria-label="close" onClick={handleCloseFailedDeleteJobDialog}>
              <Close />
            </IconButton>
          </Grid>
        </DialogTitle>

        <DialogContent>
          <Typography color="error">{deletedJobInfo?.message}</Typography>
        </DialogContent>

        <DialogActions>
          <Button color="secondary" onClick={handleCloseFailedDeleteJobDialog}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  const renderDownloadError = () => {
    return (
      <Snackbar
        className="error-snackbar-message"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        onClose={handleCloseDownloadErrorBox}
        message={<span className="failed-text">There was an error downloading the data</span>}
        open={downloadErrorBoxStatus}
        action={[<Close key="error-message-close-icon" onClick={handleCloseDownloadErrorBox} />]}
      />
    )
  }

  return (
    <div>
      {renderViewMoreDetailDialog()}
      {renderDeleteJobSuccessfulDialog()}
      {renderFailedDeleteDialog()}
      {renderSuccessfulCancelDialog()}
      {renderCancelJobConfirmDialog()}
      {renderFailedCancelDialog()}
      {renderDownloadError()}
      {renderDownloadAnchor()}
      {renderSuccessfulDeleteDialog()}

      <SBox>
        {isAdmin && (
          <Box className="export-user-id-container">
            <TextField
              helperText="User Z ID"
              label="User ID"
              defaultValue={adminUserIdSearch}
              onChange={updateAdminSearch}
              variant="standard"
              id="largeExportUserIdSearchField"
            />
          </Box>
        )}

        <Box className="export-status-update-container">
          <TextField
            select
            label="Status"
            value={adminSearchStatus}
            onChange={updateAdminSearchStatus}
            helperText="Select a status"
            className={classes.statusSelect}
            id="largeExportSelectStatus"
            variant="standard"
          >
            <MenuItem value="All" id="largeExportSelectStatus-all">
              All
            </MenuItem>
            <MenuItem value="SUCCESS" id="largeExportSelectStatus-success">
              SUCCESS
            </MenuItem>
            <MenuItem value="ERROR" id="largeExportSelectStatus-error">
              ERROR
            </MenuItem>
            <MenuItem value="RETRIES_EXHAUSTED" id="largeExportSelectStatus-retries">
              RETRIES_EXHAUSTED
            </MenuItem>
            <MenuItem value="QUEUED" id="largeExportSelectStatus-queued">
              QUEUED
            </MenuItem>
            <MenuItem value="DEQUEUED" id="largeExportSelectStatus-dequeued">
              DEQUEUED
            </MenuItem>
          </TextField>

          <Box>
            <Button onClick={adminSearch} color="primary" startIcon={<Replay />} id="largeExportSearchUpdateBtn">
              Update
            </Button>
          </Box>
        </Box>
      </SBox>

      {renderJobTable()}
    </div>
  )
}

export default withAnalytics(analyticsConfig)(LargeExport)
