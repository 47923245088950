import React, { useState, useEffect } from 'react'
import { Button, TextField, Divider, Tooltip, Icon, FormControlLabel, Radio, RadioGroup } from '@mui/material'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { CUSTOM_STR } from 'greenfield-utilities'

import { OTHERS_TIME_PERIOD } from '../../constants/ArrayConstants'
import { getFormatDate, getTimePeriodButtonLabel } from '../util'
import { DEFAULT_TIME_PERIOD_STR } from '../../constants/StringConstants'

const OtherTimePeriodOptions = ({
  handleTimePeriod,
  handleCancel,
  selectedDataset,
  cardInfo,
  isDashboard,
  dashboardInfo,
  isViewer,
  viewerJson,
  isMobile,
  handleNone,
  isSecondaryTime,
  secondaryTimePeriodColumn,
  selectedTimePeriod,
  selectedDashboardTimePeriod,
  secondaryTime,
}) => {
  const selectedSecondaryTimePeriods = selectedTimePeriod?.secondaryTimePeriods || secondaryTime?.secondaryTimePeriods
  const secondaryTimePeriods = isViewer
    ? selectedSecondaryTimePeriods ||
      cardInfo?.data?.context?.rendered_payload?.secondary_time_periods ||
      cardInfo?.data?.card_config?.card_query_attribute?.secondary_time_periods
    : cardInfo?.data?.card_config?.card_query_attribute?.secondary_time_periods
  const secondaryTimePeriod = secondaryTimePeriods && secondaryTimePeriods[secondaryTimePeriodColumn?.field_name]
  /* eslint-disable camelcase */
  const timePeriod = isViewer
    ? isDashboard
      ? selectedDashboardTimePeriod || viewerJson
        ? viewerJson
        : dashboardInfo?.time_period
      : isSecondaryTime
      ? secondaryTimePeriod
      : selectedTimePeriod?.timePeriod || viewerJson
    : cardInfo?.data?.card_config
    ? isViewer
      ? isSecondaryTime
        ? secondaryTimePeriod
        : selectedTimePeriod?.timePeriod || cardInfo?.data?.context?.rendered_payload?.time_period
      : isSecondaryTime
      ? secondaryTimePeriod
      : cardInfo?.data?.card_config?.card_query_attribute?.time_period
    : getFormatDate()

  /* eslint-enable camelcase */
  let interval = isDashboard ? timePeriod?.interval || 'None' : timePeriod?.interval || ''
  if (interval === 'last segment') {
    interval = DEFAULT_TIME_PERIOD_STR
  }
  const timeSelected = getTimePeriodButtonLabel(interval, timePeriod)

  const [selectedOption, setSelectedOption] = useState(timeSelected)
  const [advanceTimePeriod, setAdvanceTimePeriod] = useState(timeSelected === CUSTOM_STR ? interval : '')
  const [othersTimeOptions, setOthersTimeOptions] = useState(OTHERS_TIME_PERIOD)
  const disableAllTimeInterval = selectedDataset?.disable_all_time_interval

  useEffect(() => {
    const options = isDashboard
      ? OTHERS_TIME_PERIOD.filter(value => value !== DEFAULT_TIME_PERIOD_STR)
      : OTHERS_TIME_PERIOD.filter(value => value !== 'None')
    setOthersTimeOptions(options)
  }, [])

  const renderAdvanceBoxConfigure = () => {
    return (
      <TextField
        id="advance-box"
        value={advanceTimePeriod}
        label="Enter Time Period Code"
        variant="outlined"
        multiline
        rows={5}
        aria-label="Enter Time Period Code"
        onChange={event => setAdvanceTimePeriod(event.target.value)}
        className="enter-time-period-other-time-period-options"
      />
    )
  }

  return (
    <>
      <div className={classNames('relative-section', !isMobile ? 'calendar-fit' : '')}>
        <RadioGroup
          className="form-group-other-time-period-options"
          aria-label="others time period dates options"
          name="others time period dates options"
          value={selectedOption}
          onChange={event => {
            setSelectedOption(event.target.value)
            setAdvanceTimePeriod('')
          }}
        >
          {othersTimeOptions.map((timeValue, index) => {
            return (
              <React.Fragment key={`timeValue-${index}`}>
                <FormControlLabel
                  value={timeValue}
                  className="form-control-other-time-period-options"
                  control={<Radio id={`radio-btn-${index}`} aria-label={timeValue} />}
                  disabled={timeValue === 'All Time' && disableAllTimeInterval}
                  label={
                    <div
                      className={classNames(
                        'relative-dates',
                        timeValue === selectedOption ? 'selected-dates' : 'not-selected-dates'
                      )}
                    >
                      {timeValue}
                    </div>
                  }
                />
                {timeValue === 'All Time' && disableAllTimeInterval && (
                  <Tooltip title="All Time is not available for this card - please pick a smaller time range.">
                    <Icon className="all-time-tooltip-other-time-period-options">warning</Icon>
                  </Tooltip>
                )}
              </React.Fragment>
            )
          })}
          {selectedOption === CUSTOM_STR && renderAdvanceBoxConfigure()}
        </RadioGroup>
      </div>
      <Divider />
      <div className="relative-button">
        <Button
          id="cancel-options-dates"
          aria-label="Cancel Others Time Period"
          variant="text"
          color="secondary"
          onClick={handleCancel}
        >
          Cancel
        </Button>
        <Button
          id="apply-options-dates"
          aria-label="Apply Others Time Periods"
          variant="text"
          color="primary"
          onClick={() => {
            if (selectedOption === 'None') {
              handleNone()
            } else if (selectedOption === DEFAULT_TIME_PERIOD_STR) {
              handleTimePeriod('last segment')
            } else {
              const selectedValue = selectedOption === CUSTOM_STR ? advanceTimePeriod : selectedOption
              handleTimePeriod(selectedValue)
            }
          }}
          disabled={!selectedOption || (selectedOption === CUSTOM_STR && !advanceTimePeriod)}
        >
          {`${isViewer && !isMobile ? 'Select' : 'Apply'}`}
        </Button>
      </div>
    </>
  )
}

OtherTimePeriodOptions.defaultProps = {
  cardInfo: {},
  isDashboard: false,
  dashboardInfo: {},
  isViewer: false,
  viewerJson: {},
  isMobile: false,
}

OtherTimePeriodOptions.propTypes = {
  handleRelativeChange: PropTypes.func,
  handleCancel: PropTypes.func,
  cardInfo: PropTypes.object,
  isDashboard: PropTypes.bool,
  dashboardInfo: PropTypes.object,
  isViewer: PropTypes.bool,
  viewerJson: PropTypes.object,
  isMobile: PropTypes.bool,
}

export default OtherTimePeriodOptions
