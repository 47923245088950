import { Chip } from '@mui/material'
import styled from 'styled-components'

export const SChip = styled(Chip)`
  && {
    && {
      padding: 0;
      color: #222;
      font-size: 14px;
      font-family: Roboto;
      border-radius: 1px;
      justify-content: left;
      width: 100%;
    }
  }
`

export const SDatasetColumnsTabs = styled.div`
  && {
    && {
      display: flex;
      justify-content: space-between;

      .hint-badge {
        > span {
          top: 12px;
          right: 10px;
          background-color: #696969;
          color: white;
        }
      }
    }
  }
`
