import React, { Suspense } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Route, Switch, Redirect } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { Fab, Grid, Snackbar } from '@mui/material'
import { withStyles } from '@mui/styles'
import { Close, ContactSupportOutlined } from '@mui/icons-material'
import { Spinner } from 'greenfield-utilities'
import styled from 'styled-components'
import classnames from 'classnames'
import Scroll from 'react-scroll'
import { withAnalytics } from '@praxis/component-analytics'
import SideNav from '../Sidenav/Sidenav.container'

import analyticsConfig from '../analytics'
import { displayServiceErrorMessage } from '../../ducks/layout'
import { DrillThroughClicked } from '../../ducks/Contexts'
import Header from '../Header/Header'
import IEMessage from '../IEMessage/IEMessage'
import NotFound from '../routes/NotFoundPage/NotFoundPage'
import About from '../routes/About/About'
import Feedback from '../routes/Feedback/Feedback'
import HomeContainer from '../routes/Home/Home.container'
import DatasetListing from '../routes/Datasets/DatasetListing.container'
import DatasetPreview from '../routes/Datasets/DatasetPreview.container'
import ProfileContainer from '../routes/Profile/Profile.container'
import Admin from '../routes/Admin/Admin.container'
import DashboardListing from '../routes/Dashboard/DashboardListing/DashboardListing.container'
import Dashboardviewer from '../routes/Dashboard/Dashboardviewer/Dashboardviewer.container'
import CardlistContainer from '../routes/Cards/Cardlist/Cardlist.container'
import Cardviewer from '../routes/Cards/Cardviewer/Cardviewer.container'
import LargeExport from '../routes/LargeExport/LargeExport.container'
import NotificationManager from '../routes/Notifications/NotificationManager.container'
import DataAlertSubscriptionListing from '../routes/Cards/Cardviewer/DataAlertAndSubscription/DataAlertSubscriptionListing'
import Datastore from '../routes/Datastore/DatastoreList.container'
import authUtil, { clearLocalStorage } from '../Auth/authUtil'
import SiteContainer from '../routes/Site/Site.container'
import SiteListContainer from '../routes/Site/SiteList.container'
import DatasetEdit from '../routes/Datasets/DatasetEdit'
import SearchComp from '../routes/Search/Search'
import BetaFeatures from '../routes/Profile/BetaFeatures/BetaFeatures'
import Apihealthcheck from '../routes/Apihealthcheck/Apihealthcheck'
import Help from '../routes/Help/Help'
import TopLevelErrorBoundary from './ErrorBoundary'

import '../../styles/global.scss'
import 'greenfield-visualizations/greenfieldvisualizations.css'

const isProduction = process.env.NODE_ENV === 'production'

const SFab = styled(Fab)`
  width: 150px;
  margin-left: auto;
  text-transform: none;
  position: absolute;
  right: 15px;
  bottom: 10px;
  color: white;
  opacity: 85%;
`

const scroll = Scroll.animateScroll

function checkMobileDevice() {
  /*
    Using an array to store the device types so that sub components can easily check if its a table, mobile, or what the orientation is easier
    - Art/49754518
  */

  if (window.innerWidth > 100 && window.innerWidth < 424) {
    // More than likely a phone type device
    return ['isMobile', 'isPhone', 'isPortrait']
  } else if (window.innerWidth > 400 && window.innerWidth < 800 && window.innerHeight < 450) {
    // A phone type device in landscape mode
    return ['isMobile', 'isPhone', 'isLandscape']
  } else if (window.innerWidth > 425 && window.innerWidth < 800) {
    // More than likly a tablet in portrait mode
    return ['isMobile', 'isTablet', 'isPortrait']
  } else if (window.innerWidth > 801 && window.innerWidth < 900 && navigator.userAgent.match(/iPhone/i)) {
    // iPhone x landscape
    return ['isMobile', 'isPhone', 'isLandscape']
  } else if (
    window.innerWidth > 801 &&
    window.innerWidth < 1025 &&
    window.innerHeight < 1000 &&
    navigator.userAgent.match(/iPad/i)
  ) {
    // A tablet type device in landscape mode
    // checking inner height because an iPad pro's width in portrait is the same size as a 'normal' iPad in landscape
    return ['isMobile', 'isTablet', 'isLandscape']
  } else if (navigator.userAgent.match(/iPad/i) && window.innerWidth > 1000 && window.innerWidth < 1100) {
    // iPad pro in portrait
    return ['isMobile', 'isTablet', 'isPortrait']
  } else if (navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/Android/i)) {
    // if it gets to this point, it means it had to fall through everythign else, but is still a tablet. Leading us to believe its a tablet in landscape
    return ['isMobile', 'isTablet', 'isLandscape']
  } else {
    return ['isDesktop']
  }
}

function checkMobile() {
  return (
    (window.innerWidth > 100 && window.innerWidth < 800) ||
    /iPad/i.test(navigator.userAgent) ||
    (window.innerWidth > 801 && window.innerWidth < 900 && navigator.userAgent.match(/iPhone/i))
  )
}

const Builder = React.lazy(() => import('../routes/Builder/Builder.container'))

export const styles = theme => ({
  header: {
    height: 56,
    [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
      height: 48,
    },
    [theme.breakpoints.up('sm')]: {
      height: 64,
    },
  },
  contentContainer: {
    padding: theme.spacing(),
  },
  sideNavFooterList: {
    color: theme.typography.body1.color,
    display: 'flex',
    flexWrap: 'wrap',
    fontSize: theme.typography.caption.fontSize,
    listStyle: 'none',
    margin: 0,
    padding: theme.spacing(1.5),
    '& li': {
      padding: theme.spacing(0.5),
    },
    '& a': {
      color: theme.typography.caption.color,
      textDecoration: 'none',
      transition: theme.transitions.create('color', { duration: theme.transitions.duration.shorter }),
      '&:hover': {
        color: theme.palette.primary[500],
      },
    },
  },
})

export class Layout extends React.Component {
  state = {
    headerTitle: '',
    sideNavIsOpen: false,
    isMobile: checkMobile(),
    isIEMessageOpen: true,
    ignoreHeaderTitlePrefix: false,
    deviceType: checkMobileDevice(),
    drillClicked: false,
    backButtonClicked: false,
    isDrillThroughButton: false,
    openDrawer: false,
  }

  handleScrollToTop = () => {
    scroll.scrollToTop()
  }

  componentDidMount() {
    window.onresize = () => {
      this.setState({
        isMobile: checkMobile(),
        deviceType: checkMobileDevice(),
      })
    }

    if (isProduction) {
      this.props.trackEvent({
        event: {
          type: 'pageload',
        },
      })
    }
  }

  setDrillRowClicked = value => {
    this.setState({
      [value]: !this.state[value],
    })
  }

  componentWillUnmount() {
    window.onresize = null
  }

  handleMenuButtonClick = () => {
    this.setState(prevState => ({
      sideNavIsOpen: !prevState.sideNavIsOpen,
    }))
  }

  handleErrorMessageClose = () => {
    this.props.displayServiceErrorMessage('')
  }

  checkIfChrome = () => {
    const isChromium = window.chrome
    const winNav = window.navigator
    const vendorName = winNav.vendor
    const isOpera = typeof window.opr !== 'undefined'
    const isIEedge = winNav.userAgent.indexOf('Edge') > -1

    if (
      isChromium !== null &&
      typeof isChromium !== 'undefined' &&
      vendorName === 'Google Inc.' &&
      isOpera === false &&
      isIEedge === false
    ) {
      // is Google Chrome
      return true
    } else {
      // not Google Chrome
      return false
    }
  }

  isIEBrowser() {
    return false || document.documentMode
  }

  dismissIEMessage = () => {
    this.setState({
      isIEMessageOpen: false,
    })
  }

  sendFireflyEvent = (isCustomEvent, eventObj) => {
    if (isCustomEvent) {
      this.props.trackCustomEvent(eventObj.eventName, eventObj.key, JSON.stringify(eventObj.data))
    } else {
      this.props.trackEvent({
        event: {
          type: eventObj.eventName,
        },
      })
    }
  }

  handleHelpDrawerToggle = () => {
    if (!this.state.openDrawer) {
      this.sendFireflyEvent(false, {
        eventName: 'helpDrawerOpened',
        key: 'notification',
      })
    }
    this.setState({ openDrawer: !this.state.openDrawer })
  }

  render() {
    const { isMobile, headerTitle, sideNavIsOpen, isIEMessageOpen, ignoreHeaderTitlePrefix, deviceType, openDrawer } =
      this.state
    const { trackEvent, trackCustomEvent, serviceErrorMessage, auth, routeProps, currentUser } = this.props
    const isSessionValid = authUtil(auth)

    const isPrint = /\/print\//i.test(window.location.pathname)
    const isEmbed = /\/embed/i.test(window.location.pathname)
    const isBuilder = /\/builder\//i.test(window.location.pathname)
    const isPrintLandscape = /\/landscape/i.test(window.location.pathname)
    const nonListingPage =
      /\/dashboards/i.test(window.location.pathname) ||
      /\/cards/i.test(window.location.pathname) ||
      /\/faq/i.test(window.location.pathname)
    const isCard = /^\/card\//i.test(window.location.pathname)
    const isDashboard = /^\/dashboard\//i.test(window.location.pathname)

    const setHeaderTitle = (headerTitle, ignoreHeaderTitlePrefix = false) => {
      this.setState({
        headerTitle,
        ignoreHeaderTitlePrefix,
      })
    }
    const company = auth?.session?.identity?.company
    const isAdmin = currentUser.data?.is_admin === 'yes'

    return auth && auth.session && isSessionValid ? (
      <DrillThroughClicked.Provider
        value={{
          drillClicked: this.state.drillClicked,
          backButtonClicked: this.state.backButtonClicked,
          setDrillRowClicked: this.setDrillRowClicked,
          isDrillThroughButton: this.state.isDrillThroughButton,
        }}
      >
        <TopLevelErrorBoundary
          trackEvent={trackCustomEvent}
          routeProps={routeProps}
          isCard={isCard}
          cardInfo={this.props.cardInfo}
          isDashboard={isDashboard}
        >
          <Helmet
            defaultTitle="Greenfield: Home"
            title={ignoreHeaderTitlePrefix ? headerTitle : `Greenfield: ${headerTitle}`}
          />
          <div
            id="gf-root"
            className={classnames({
              isMobileLayoutHook: isMobile,
              chrome: this.checkIfChrome(),
              notChrome: !this.checkIfChrome(),
              'printable-dashboard': isPrint,
              embed: isEmbed,
              landscape: isPrintLandscape,
              nonListingPage: !nonListingPage,
            })}
          >
            <Switch>
              {/* route for embed card */}
              <Route
                exact
                path="/card/:_id?/embed"
                render={routeProps => (
                  <Cardviewer
                    isEmbed
                    setHeaderTitle={setHeaderTitle}
                    routeProps={routeProps}
                    deviceClassNameList={deviceType}
                    isMobile={isMobile}
                    deviceType={deviceType}
                  />
                )}
              />
              {/* route for card with only chart, no headers */}
              <Route
                exact
                path="/card/:_id?/chart"
                render={routeProps => (
                  <Cardviewer
                    chart // prop to identify if it is chart only
                    setHeaderTitle={setHeaderTitle}
                    routeProps={routeProps}
                    deviceClassNameList={deviceType}
                    isMobile={isMobile}
                    deviceType={deviceType}
                  />
                )}
              />
              <Route
                render={() => (
                  <>
                    <SideNav
                      sideNavIsOpen={sideNavIsOpen}
                      onToggle={this.handleMenuButtonClick}
                      isMobile={isMobile}
                      deviceType={deviceType}
                      auth={auth}
                    />
                    <div className={`gf-body ${deviceType.join(' ')}`}>
                      {this.isIEBrowser() && isIEMessageOpen && <IEMessage dismissIEMessage={this.dismissIEMessage} />}
                      <Header
                        title={headerTitle}
                        onMenuAction={this.handleMenuButtonClick}
                        isMobile={isMobile}
                        isCard={isCard}
                        isDashboard={isDashboard}
                        trackEvent={trackEvent}
                        sendFireflyEvent={this.sendFireflyEvent}
                        routeProps={routeProps}
                        auth={auth}
                      />
                      <div
                        className={classnames({
                          layoutContainer: true,
                          builder: isBuilder,
                          isMobileCard: isCard && isMobile,
                        })}
                      >
                        <Grid container className={isMobile ? 'mobileDirectChild' : ''}>
                          <Grid item xs={12} className="layout-flex-container">
                            <Switch>
                              <Route
                                exact
                                path="/profile"
                                render={() => (
                                  <Redirect to="/profile/owned-items" isMobile={isMobile} deviceType={deviceType} />
                                )}
                              />
                              <Route
                                exact
                                path="/profile/:subGroup"
                                render={() => (
                                  <ProfileContainer
                                    isMobile={isMobile}
                                    routeProps={routeProps}
                                    setHeaderTitle={setHeaderTitle}
                                    company={company}
                                    trackEvent={trackEvent}
                                    sendFireflyEvent={this.sendFireflyEvent}
                                  />
                                )}
                              />
                              <Route
                                exact
                                path="/beta-features"
                                render={routeProps => (
                                  <BetaFeatures
                                    setHeaderTitle={setHeaderTitle}
                                    routeProps={routeProps}
                                    isMobile={isMobile}
                                    deviceType={deviceType}
                                    userDetails={auth.session.userInfo}
                                  />
                                )}
                              />
                              <Route
                                exact
                                path={['/', '/favorites/home']}
                                render={() => (
                                  <Redirect to="/favorites/home/0" isMobile={isMobile} deviceType={deviceType} />
                                )}
                              />
                              <Route
                                exact
                                path={['/site/:_siteName', '/site/:_siteName/home']}
                                render={routeProps => (
                                  <Redirect
                                    to={`/site/${routeProps.match.params._siteName}/home/0`}
                                    isMobile={isMobile}
                                    deviceType={deviceType}
                                  />
                                )}
                              />
                              <Route
                                exact
                                path="/favorites/:_id/:_tab?"
                                render={routeProps => (
                                  <HomeContainer
                                    setHeaderTitle={setHeaderTitle}
                                    routeProps={routeProps}
                                    isMobile={isMobile}
                                    deviceType={deviceType}
                                  />
                                )}
                                deviceClassNameList={deviceType}
                              />
                              <Route
                                exact
                                path={['/cards', '/site/:_siteName/cards']}
                                render={routeProps => (
                                  <CardlistContainer
                                    setHeaderTitle={setHeaderTitle}
                                    isMobile={isMobile}
                                    deviceClassNameList={deviceType}
                                    routeProps={routeProps}
                                  />
                                )}
                              />
                              <Route
                                path={['/card/:_id?', '/site/:_siteName/card/:_id?']}
                                render={routeProps => (
                                  <Cardviewer
                                    setHeaderTitle={setHeaderTitle}
                                    routeProps={routeProps}
                                    deviceClassNameList={deviceType}
                                    isMobile={isMobile}
                                    deviceType={deviceType}
                                    userDetails={auth.session.userInfo}
                                  />
                                )}
                              />
                              <Route
                                path={['/textcard/:_id?', '/site/:_siteName/textcard/:_id?']}
                                render={routeProps => (
                                  <Cardviewer setHeaderTitle={setHeaderTitle} routeProps={routeProps} />
                                )}
                              />
                              <Route
                                exact
                                path={['/dashboards', '/site/:_siteName/dashboards']}
                                render={routeProps => (
                                  <DashboardListing
                                    setHeaderTitle={setHeaderTitle}
                                    deviceType={deviceType}
                                    isMobile={isMobile}
                                    routeProps={routeProps}
                                  />
                                )}
                              />
                              <Route
                                path={['/dashboard/:_id', '/site/:_siteName/dashboard/:_id']}
                                exact
                                render={routeProps => {
                                  const { _id, _siteName } = routeProps.match.params
                                  const { search, hash } = routeProps.location
                                  const qs = search.concat(hash)

                                  return _siteName ? (
                                    <Redirect to={`/site/${_siteName}/dashboard/${_id}/0${qs}`} />
                                  ) : (
                                    <Redirect to={`/dashboard/${_id}/0${qs}`} />
                                  )
                                }}
                              />
                              <Route
                                path={['/dashboard/:_id/:_tab', '/site/:_siteName/dashboard/:_id/:_tab']}
                                render={routeProps => {
                                  const { _tab } = routeProps.match.params
                                  return (
                                    <Dashboardviewer
                                      activeTabIndex={_tab}
                                      isPrintLandscape={isPrintLandscape}
                                      routeProps={routeProps}
                                      setHeaderTitle={setHeaderTitle}
                                      isMobile={isMobile}
                                      deviceType={deviceType}
                                    />
                                  )
                                }}
                              />
                              <Route
                                exact
                                path="/datasets"
                                render={routeProps => (
                                  <DatasetListing
                                    isMobile={isMobile}
                                    setHeaderTitle={setHeaderTitle}
                                    routeProps={routeProps}
                                  />
                                )}
                                routeProps={routeProps}
                              />
                              <Route
                                path={[
                                  '/dataset/preview/:_id',
                                  '/site/:_siteName/dataset/preview/:_id',
                                  '/datasets/preview/:_id?',
                                  '/site/:_siteName/datasets/preview/:_id',
                                ]}
                                exact
                                render={routeProps => {
                                  const { _id, _siteName } = routeProps.match.params
                                  return _siteName ? (
                                    <Redirect to={`/site/${_siteName}/dataset/preview/${_id}/columns`} />
                                  ) : (
                                    <Redirect to={`/dataset/preview/${_id}/columns`} />
                                  )
                                }}
                              />
                              <Route
                                path={[
                                  '/dataset/preview/:_id?/:subGroup?',
                                  '/site/:_siteName/dataset/preview/:_id?/:subGroup?',
                                ]}
                                render={routeProps => (
                                  <DatasetPreview
                                    trackEvent={trackEvent}
                                    isMobile={isMobile}
                                    setHeaderTitle={setHeaderTitle}
                                    routeProps={routeProps}
                                  />
                                )}
                              />
                              <Route
                                path={[
                                  '/datasets/preview/:_id?/:subGroup?',
                                  '/site/:_siteName/datasets/preview/:_id?/:subGroup?',
                                ]}
                                exact
                                render={routeProps => {
                                  const { _id, _siteName } = routeProps.match.params
                                  return _siteName ? (
                                    <Redirect to={`/site/${_siteName}/dataset/preview/${_id}/columns`} />
                                  ) : (
                                    <Redirect to={`/dataset/preview/${_id}/columns`} />
                                  )
                                }}
                              />
                              <Route
                                path={['/dataset/edit/:_id', '/site/:_siteName/dataset/edit/:_id?']}
                                render={routeProps => (
                                  <DatasetEdit
                                    trackEvent={trackEvent}
                                    isMobile={isMobile}
                                    setHeaderTitle={setHeaderTitle}
                                    routeProps={routeProps}
                                  />
                                )}
                              />
                              <Route
                                exact
                                path={['/sites', '/site/:_siteName/sites']}
                                render={() => <SiteListContainer setHeaderTitle={setHeaderTitle} isMobile={isMobile} />}
                              />
                              <Route
                                path="/site/:_siteName/builder/:subGroup?/:_id?/:_datasetId?"
                                render={routeProps => {
                                  const { subGroup, _id, _datasetId } = routeProps.match.params

                                  return (
                                    <Suspense fallback={<Spinner size="large" layout="selfCentering" />}>
                                      <Builder
                                        existingCardId={
                                          subGroup &&
                                          _id &&
                                          (subGroup === 'card' || subGroup === 'textcard') &&
                                          !_datasetId
                                            ? _id
                                            : ''
                                        }
                                        existingDashboardId={
                                          subGroup && _id && subGroup === 'dashboard' && !_datasetId ? _id : ''
                                        }
                                        isNewCard={(subGroup === 'card' || subGroup === 'textcard') && !_id}
                                        isNewDashboard={subGroup === 'dashboard' && !_id}
                                        preselectedDatasetId={
                                          _datasetId &&
                                          (subGroup === 'card' || subGroup === 'textcard') &&
                                          _id === 'dataset'
                                            ? _datasetId
                                            : ''
                                        }
                                        routeProps={routeProps}
                                        isMobile={isMobile}
                                        setHeaderTitle={setHeaderTitle}
                                        deviceType={this.state.deviceType}
                                        userDetails={auth.session.userInfo}
                                      />
                                    </Suspense>
                                  )
                                }}
                              />
                              <Route
                                exact
                                path="/site/:_siteName/:_tabName?/:_index?"
                                render={routeProps => (
                                  <SiteContainer
                                    setHeaderTitle={setHeaderTitle}
                                    routeProps={routeProps}
                                    isMobile={isMobile}
                                    deviceType={deviceType}
                                  />
                                )}
                                deviceClassNameList={deviceType}
                              />
                              <Route
                                exact
                                path="/admin"
                                render={() => <Redirect to="/admin/groups" />}
                                deviceClassNameList={deviceType}
                              />
                              <Route
                                path="/admin/:mainTab/:_id?/:subGroup?"
                                render={routeProps => (
                                  <Admin
                                    isMobile={isMobile}
                                    setHeaderTitle={setHeaderTitle}
                                    routeProps={routeProps}
                                    sendFireflyEvent={this.sendFireflyEvent}
                                    userDetails={auth.session.userInfo}
                                  />
                                )}
                                deviceClassNameList={deviceType}
                              />
                              <Route
                                path="/builder/:subGroup?/:_id?/:_datasetId?"
                                render={routeProps => {
                                  const { subGroup, _id, _datasetId } = routeProps.match.params

                                  return (
                                    <Suspense fallback={<Spinner size="large" layout="selfCentering" />}>
                                      <Builder
                                        existingCardId={
                                          subGroup &&
                                          _id &&
                                          (subGroup === 'card' || subGroup === 'textcard') &&
                                          !_datasetId
                                            ? _id
                                            : ''
                                        }
                                        existingDashboardId={
                                          subGroup && _id && subGroup === 'dashboard' && !_datasetId ? _id : ''
                                        }
                                        isNewCard={(subGroup === 'card' || subGroup === 'textcard') && !_id}
                                        isNewDashboard={subGroup === 'dashboard' && !_id}
                                        preselectedDatasetId={
                                          _datasetId &&
                                          (subGroup === 'card' || subGroup === 'textcard') &&
                                          _id === 'dataset'
                                            ? _datasetId
                                            : ''
                                        }
                                        routeProps={routeProps}
                                        isMobile={isMobile}
                                        setHeaderTitle={setHeaderTitle}
                                        deviceType={this.state.deviceType}
                                        userDetails={auth.session.userInfo}
                                      />
                                    </Suspense>
                                  )
                                }}
                              />
                              <Route
                                exact
                                path="/notifications/:navigationType?/:_id?"
                                render={routeProps => (
                                  <DataAlertSubscriptionListing
                                    setHeaderTitle={setHeaderTitle}
                                    routeProps={routeProps}
                                    isMobile={isMobile}
                                    trackEvent={trackEvent}
                                    sendFireflyEvent={this.sendFireflyEvent}
                                  />
                                )}
                                deviceClassNameList={deviceType}
                              />
                              <Route
                                exact
                                path="/notification-manager/:navigationType?/:_id?"
                                render={routeProps => (
                                  <NotificationManager
                                    setHeaderTitle={setHeaderTitle}
                                    routeProps={routeProps}
                                    isMobile={isMobile}
                                    sendFireflyEvent={this.sendFireflyEvent}
                                  />
                                )}
                                deviceClassNameList={deviceType}
                              />
                              <Route
                                exact
                                path="/notification-manager/edit/:navigationType?/:_id?/:notificationId?"
                                render={routeProps => (
                                  <NotificationManager
                                    setHeaderTitle={setHeaderTitle}
                                    routeProps={routeProps}
                                    isMobile={isMobile}
                                    sendFireflyEvent={this.sendFireflyEvent}
                                    isEdit
                                  />
                                )}
                                deviceClassNameList={deviceType}
                              />
                              <Route
                                exact
                                path="/datastores"
                                render={() => (
                                  <Datastore setHeaderTitle={setHeaderTitle} isMobile={isMobile} auth={auth} />
                                )}
                                deviceClassNameList={deviceType}
                              />
                              <Route exact path="/about" component={About} />
                              <Route
                                exact
                                path="/search"
                                render={routeProps => (
                                  <SearchComp
                                    setHeaderTitle={setHeaderTitle}
                                    routeProps={routeProps}
                                    isMobile={isMobile}
                                    sendFireflyEvent={this.sendFireflyEvent}
                                  />
                                )}
                              />
                              <Route exact path="/feedback" component={Feedback} />

                              <Route
                                exact
                                path="/exportrequests"
                                render={() => <LargeExport setHeaderTitle={setHeaderTitle} />}
                              />

                              <Route
                                exact
                                path="/l/:type/:id/:shortlink"
                                render={() => {
                                  // This may look strange but this route can only be hit if a non-logged in user starts on a matching route here.  Reloading the page hits the proxy which redirects to the long url.  Without this, non logged in users will hit the 404 page instead of getting the redirect.
                                  window.location.reload()
                                }}
                              />
                              {isAdmin ? (
                                <Route
                                  exact
                                  path="/apihealthcheck"
                                  render={() => {
                                    return <Apihealthcheck />
                                  }}
                                />
                              ) : null}
                              <Route component={NotFound} />
                            </Switch>
                            {serviceErrorMessage && (
                              <Snackbar
                                className="error-snackbar-message"
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                                onClose={this.handleErrorMessageClose}
                                message={<span className="failed-text">{serviceErrorMessage}</span>}
                                open={Boolean(serviceErrorMessage)}
                                action={[
                                  <Close
                                    key="error-message-close-icon"
                                    data-cy="close-snackbar-error-message"
                                    onClick={this.handleErrorMessageClose}
                                  />,
                                ]}
                              />
                            )}
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                    {!isMobile && (
                      <SFab
                        variant="extended"
                        size="medium"
                        aria-label="help"
                        color="primary"
                        onClick={this.handleHelpDrawerToggle}
                        id="need_help_btn"
                      >
                        Need help
                        <ContactSupportOutlined sx={{ ml: 1 }} />
                      </SFab>
                    )}
                    <Help
                      {...this.props}
                      isCard={isCard}
                      isDashboard={isDashboard}
                      onChangeHelpDrawerToggle={this.handleHelpDrawerToggle}
                      openHelpDrawer={openDrawer}
                    />
                  </>
                )}
              />
            </Switch>
          </div>
        </TopLevelErrorBoundary>
      </DrillThroughClicked.Provider>
    ) : (
      clearLocalStorage(routeProps.history.location.pathname)
    )
  }
}

Layout.defaultProps = {
  classes: {},
  sideNavIsOpen: false,
  currentUser: {},
}

Layout.propTypes = {
  sideNavIsOpen: PropTypes.bool,
  classes: PropTypes.object,
}

export const mapDispatchToProps = dispatch => ({
  displayServiceErrorMessage(data) {
    dispatch(displayServiceErrorMessage(data))
  },
})

const mapStateToProps = state => ({
  sideNavIsOpen: state.layout.sideNavIsOpen,
  serviceErrorMessage: state.layout.serviceErrorMessage,
  cardInfo: state.cards.cardInfoStatus,
  currentUser: state.user.userType,
})

export default withAnalytics(analyticsConfig)(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Layout)))
