import { call, put } from 'redux-saga/effects'
import axios from 'axios'
import { API_GATEWAY_URL } from './utils'

export const LARGE_EXPORT_CREATION_REQUESTED = 'LARGE_EXPORT_CREATION_REQUESTED'
export const LARGE_EXPORT_CREATION_SUCCEEDED = 'LARGE_EXPORT_CREATION_SUCCEEDED'
export const LARGE_EXPORT_CREATION_FAILED = 'LARGE_EXPORT_CREATION_FAILED'

export const GET_LARGE_EXPORT_JOBS_REQUESTED = 'GET_LARGE_EXPORT_JOBS_REQUESTED'
export const GET_LARGE_EXPORT_JOBS_SUCCEEDED = 'GET_LARGE_EXPORT_JOBS_SUCCEEDED'
export const GET_LARGE_EXPORT_JOBS_FAILED = 'GET_LARGE_EXPORT_JOBS_FAILED'

export const GET_INDIVIDUAL_EXPORT_JOB_REQUESTED = 'GET_INDIVIDUAL_EXPORT_JOB_REQUESTED'
export const GET_INDIVIDUAL_EXPORT_JOB_SUCCEEDED = 'GET_INDIVIDUAL_EXPORT_JOB_SUCCEEDED'
export const GET_INDIVIDUAL_EXPORT_JOB_FAILED = 'GET_INDIVIDUAL_EXPORT_JOB_FAILED'

export const DELETE_EXPORT_JOB_REQUESTED = 'DELETE_EXPORT_JOB_REQUESTED'
export const DELETE_EXPORT_JOB_SUCCEEDED = 'DELETE_EXPORT_JOB_SUCCEEDED'
export const DELETE_EXPORT_JOB_FAILED = 'DELETE_EXPORT_JOB_FAILED'

export const GET_DOWNLOAD_DATA_REQUESTED = 'GET_DOWNLOAD_DATA_REQUESTED'
export const GET_DOWNLOAD_DATA_SUCCEEDED = 'GET_DOWNLOAD_DATA_SUCCEEDED'
export const GET_DOWNLOAD_DATA_FAILED = 'GET_DOWNLOAD_DATA_FAILED'

export const GET_DOWNLOAD_NOTIFY_DATA_REQUESTED = 'GET_DOWNLOAD_NOTIFY_DATA_REQUESTED'
export const GET_DOWNLOAD_NOTIFY_DATA_SUCCEEDED = 'GET_DOWNLOAD_NOTIFY_DATA_SUCCEEDED'
export const GET_DOWNLOAD_NOTIFY_DATA_FAILED = 'GET_DOWNLOAD_NOTIFY_DATA_FAILED'

export const CANCEL_JOB_REQUESTED = 'CANCEL_JOB_REQUESTED'
export const CANCEL_JOB_SUCCEEDED = 'CANCEL_JOB_SUCCEEDED'
export const CANCEL_JOB_FAILED = 'CANCEL_JOB_FAILED'

export const CLEAR_CACHE = 'CLEAR_CACHE'
export const CLEAR_CACHE_REQUESTED = 'CLEAR_CACHE_REQUESTED'

// reducer
export default function largeExportReducer(state = {}, action = {}) {
  switch (action.type) {
    case CLEAR_CACHE: {
      const newState = {
        ...state,
        deleteJobInfo: {},
        cancelJobInfo: {},
        downloadedData: {},
      }
      return newState
    }

    case CANCEL_JOB_SUCCEEDED: {
      const newState = {
        ...state,
        cancelJobInfo: {
          data: action.payload,
          status: 200,
        },
      }

      return newState
    }

    case CANCEL_JOB_FAILED: {
      const { resp } = action.payload
      const newState = {
        ...state,
        cancelJobInfo: {
          data: action.payload,
          status: resp.status,
        },
      }

      return newState
    }

    // download data
    case GET_DOWNLOAD_DATA_SUCCEEDED: {
      const newState = {
        ...state,
        downloadedData: {
          data: action.payload,
          status: 200,
        },
      }

      return newState
    }

    case GET_DOWNLOAD_DATA_FAILED: {
      const { resp } = action.payload
      const newState = {
        ...state,
        downloadedData: {
          data: resp.data,
          status: resp.status,
        },
      }

      return newState
    }

    case GET_DOWNLOAD_NOTIFY_DATA_SUCCEEDED: {
      const newState = {
        ...state,
        downloadedNotificationData: {
          data: action.payload,
          status: 200,
        },
      }

      return newState
    }

    case GET_DOWNLOAD_NOTIFY_DATA_FAILED: {
      const { resp } = action.payload
      const newState = {
        ...state,
        downloadedNotificationData: {
          data: resp.data,
          status: resp.status,
        },
      }

      return newState
    }

    // delete job

    case DELETE_EXPORT_JOB_SUCCEEDED: {
      const newState = {
        ...state,
        deleteJobInfo: {
          data: action.payload,
          status: 200,
        },
      }

      return newState
    }

    case DELETE_EXPORT_JOB_FAILED: {
      const { resp } = action.payload
      const newState = {
        ...state,
        deleteJobInfo: {
          data: resp.data,
          status: resp.status,
        },
      }

      return newState
    }

    // create large export job
    case LARGE_EXPORT_CREATION_REQUESTED: {
      const newState = {
        ...state,
        newLargeExportJobInfo: {
          status: 'requested',
        },
      }

      return newState
    }

    case LARGE_EXPORT_CREATION_SUCCEEDED: {
      const newState = {
        ...state,
        newLargeExportJobInfo: {
          data: action.payload,
          status: 200,
        },
      }

      return newState
    }

    case LARGE_EXPORT_CREATION_FAILED: {
      const newState = {
        ...state,
        newLargeExportJobInfo: action.payload.resp,
        status: action.payload.resp.status,
      }

      return newState
    }

    // get all jobs for user
    case GET_LARGE_EXPORT_JOBS_REQUESTED: {
      const newState = {
        ...state,
        largeExportJobs: {
          status: 'requested',
        },
      }

      return newState
    }

    case GET_LARGE_EXPORT_JOBS_SUCCEEDED: {
      const newState = {
        ...state,
        largeExportJobs: {
          status: 200,
          jobs: action.payload,
        },
      }

      return newState
    }

    // get individual job for user
    case GET_INDIVIDUAL_EXPORT_JOB_REQUESTED: {
      const newState = {
        ...state,
        largeExportIndividualJob: {
          status: 'requested',
        },
      }

      return newState
    }

    case GET_INDIVIDUAL_EXPORT_JOB_SUCCEEDED: {
      const newState = {
        ...state,
        largeExportIndividualJob: {
          status: 200,
          data: action.payload,
        },
      }

      return newState
    }

    default:
      return state
  }
}

// actions
export function createLargeExportJob(data) {
  return {
    type: LARGE_EXPORT_CREATION_REQUESTED,
    payload: data,
  }
}

export function fetchLargeExportJobs(data) {
  return {
    type: GET_LARGE_EXPORT_JOBS_REQUESTED,
    payload: data,
  }
}

export function fetchLargeExportIndividualJob(data) {
  return {
    type: GET_INDIVIDUAL_EXPORT_JOB_REQUESTED,
    payload: data,
  }
}

export function deleteLargeExportJob(data) {
  return {
    type: DELETE_EXPORT_JOB_REQUESTED,
    payload: data,
  }
}

export function fetchDownloadData(data) {
  return {
    type: GET_DOWNLOAD_DATA_REQUESTED,
    payload: data,
  }
}

export function fetchDownloadNotifyData(data) {
  return {
    type: GET_DOWNLOAD_NOTIFY_DATA_REQUESTED,
    payload: data,
  }
}

export function cancelJob(data) {
  return {
    type: CANCEL_JOB_REQUESTED,
    payload: data,
  }
}

export function clearLargeExportCacheData() {
  return {
    type: CLEAR_CACHE_REQUESTED,
    payload: {},
  }
}

// sagas
export function* removeLargeExportJob(action) {
  try {
    const previewCardInfo = yield call(axios, {
      method: 'patch',
      url: `${API_GATEWAY_URL}/bi_reporting_assets/v3/tasklifecycle/tasks/${action.payload.request_id}`,
      data: { status: 'DELETED' },
    })

    if (previewCardInfo.error) {
      const e = previewCardInfo.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }

    yield put({ type: 'DELETE_EXPORT_JOB_SUCCEEDED', payload: previewCardInfo })
  } catch (e) {
    yield put({ type: 'DELETE_EXPORT_JOB_FAILED', payload: { cardId: action.payload, resp: e.response } })
  }
}

export function* postLargeExportJob(action) {
  try {
    const previewCardInfo = yield call(axios, {
      method: 'post',
      url: `${API_GATEWAY_URL}/bi_reporting_assets/v3/submit_export_cards?card_id=${action.payload.cardId}`,
      data: action.payload.jobData,
    })

    if (previewCardInfo.error) {
      const e = previewCardInfo.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }

    yield put({ type: 'LARGE_EXPORT_CREATION_SUCCEEDED', payload: previewCardInfo })
  } catch (e) {
    yield put({ type: 'LARGE_EXPORT_CREATION_FAILED', payload: { cardId: action.payload, resp: e.response } })
  }
}

export function* getLargeExportJobs(action) {
  const queryString = action?.payload?.queryString || ''
  try {
    const largeExportJobList = yield call(axios, {
      method: 'get',
      url: `${API_GATEWAY_URL}/bi_reporting_assets/v3/tasklifecycle/tasks${queryString}`,
    })

    yield put({ type: 'GET_LARGE_EXPORT_JOBS_SUCCEEDED', payload: largeExportJobList })
  } catch (e) {
    yield put({ type: 'GET_LARGE_EXPORT_JOBS_FAILED', payload: { cardId: action.payload, resp: e.response } })
  }
}

export function* getDownloadData(action) {
  try {
    const exportJobInfo = yield call(axios, {
      method: 'get',
      url: action.payload,
      responseType: 'blob',
    })

    if (exportJobInfo.error) {
      const e = exportJobInfo.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }

    yield put({ type: 'GET_DOWNLOAD_DATA_SUCCEEDED', payload: exportJobInfo })
  } catch (e) {
    yield put({ type: 'GET_DOWNLOAD_DATA_FAILED', payload: { cardId: action.payload, resp: e.response } })
  }
}

export function* getDownloadNotifyData(action) {
  try {
    const exportJobInfo = yield call(axios, {
      method: 'get',
      url: action.payload,
      responseType: 'blob',
    })

    if (exportJobInfo.error) {
      const e = exportJobInfo.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }

    yield put({ type: 'GET_DOWNLOAD_NOTIFY_DATA_SUCCEEDED', payload: exportJobInfo })
  } catch (e) {
    yield put({ type: 'GET_DOWNLOAD_NOTIFY_DATA_FAILED', payload: { cardId: action.payload, resp: e.response } })
  }
}

export function* cancelLargeExportJob(action) {
  try {
    const cancelLargeExportJob = yield call(axios, {
      method: 'patch',
      url: `${API_GATEWAY_URL}/bi_reporting_assets/v3/tasklifecycle/tasks/${action.payload}`,
      data: { status: 'CANCELED' },
    })

    if (cancelLargeExportJob.error) {
      const e = cancelLargeExportJob.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }

    yield put({ type: 'CANCEL_JOB_SUCCEEDED', payload: cancelLargeExportJob })
  } catch (e) {
    yield put({ type: 'CANCEL_JOB_FAILED', payload: { cardId: action.payload, resp: e.response } })
  }
}

export function* clearLargeExportCache() {
  yield put({ type: 'CLEAR_CACHE', payload: {} })
}
