import React, { useState } from 'react'
import { Grid, MenuItem, TextField } from '@mui/material'
import AdvanceFilter from '../AdvanceFilter/AdvanceFilter'
import { PAGE_SIZE_OPTIONS } from '../AssetTableSS/TableFilter'

export const TableFilter = ({ pageSize, setPageSize, gridRef, subscriptionId = '' }) => {
  const [searchText, setSearchText] = useState(subscriptionId)

  // Search Sites
  const handleSearchChange = event => {
    setSearchText(event.target.value)
    gridRef.current.api.setQuickFilter(event.target.value)
  }

  const handlePageSizeChange = event => {
    setPageSize(event.target.value)
    gridRef.current.api.paginationSetPageSize(Number(event.target.value))
  }

  return (
    <Grid
      container
      justifyContent="flex-start"
      alignContent="flex-start"
      alignItems="center"
      className="site-list-search"
      style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }}
    >
      <Grid item>
        <AdvanceFilter
          className="search-site"
          label="Search"
          onSearchChange={handleSearchChange}
          searchValue={searchText}
          isSmall
          id="filter-text-box"
        />
      </Grid>
      <Grid item xs>
        <Grid container alignItems="center" justifyContent="flex-end" alignContent="flex-end">
          <TextField
            variant="outlined"
            id="simple-select"
            value={pageSize}
            size="small"
            select
            className="select-rows"
            onChange={handlePageSizeChange}
          >
            {PAGE_SIZE_OPTIONS.map(option => (
              <MenuItem key={option} value={option} className="dropdown-menu-item">
                {option} Rows
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
    </Grid>
  )
}
