import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { useAuth } from '@praxis/component-auth'

import Layout from './Layout/Layout'
import authUtil from './Auth/authUtil'
import ShortUrlRedirect from './ShortUrlRedirect'
import Auth from './Auth/Auth'

const ViewController = props => {
  const auth = useAuth()
  const { isAuthorized, login, isAuthenticated } = auth
  const redirectUrl = window.sessionStorage.getItem('redirectUrl')
  const isSessionValid = authUtil(auth)

  if (!auth.session && props.routeProps.history.location.pathname !== '/') {
    const { pathname, search } = props.routeProps.history.location
    window.sessionStorage.setItem('redirectUrl', pathname + search)
  }

  if (auth && auth.session && redirectUrl && redirectUrl !== '/') {
    props.routeProps.history.push(redirectUrl)
    window.sessionStorage.removeItem('redirectUrl')
  }

  if (!isAuthorized() && !isAuthenticated() && !isSessionValid) {
    login()
    return <span />
  }
  return (
    <>
      <Auth auth={auth} routeProps={props.routeProps} />
      <Switch>
        <Route path="/l/:type/:id/:shortUrl" render={routeProps => <ShortUrlRedirect {...routeProps} />} />
        <Route path={redirectUrl || '/'} render={routeProps => <Layout routeProps={routeProps} auth={auth} />} />
      </Switch>
    </>
  )
}
export default ViewController
