import { Typography } from '@mui/material'
import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const ButtonLink = styled.button`
  min-width: 0;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 0.875rem;
  margin: 5px 5px 5px 0;
  min-height: 36px;
  color: #2f5ebc;
`
export const INACTIVE = 'disabled'
export const ACTIVE = 'active'
export const ALL = 'all'

const StatusFilter = ({ statusType, handleStatusChange }) => {
  return (
    <>
      <Typography variant="subtitle2">View by Status: </Typography>
      <ButtonLink
        style={statusType === ACTIVE ? { fontWeight: 'bold', textDecoration: 'underline' } : {}}
        onClick={() => handleStatusChange(ACTIVE)}
      >
        Active
      </ButtonLink>
      |
      <ButtonLink
        style={statusType === INACTIVE ? { fontWeight: 'bold', textDecoration: 'underline' } : {}}
        variant="text"
        onClick={() => handleStatusChange(INACTIVE)}
      >
        Inactive
      </ButtonLink>
      |
      <ButtonLink
        style={statusType === ALL ? { fontWeight: 'bold', textDecoration: 'underline' } : {}}
        variant="text"
        onClick={() => handleStatusChange(ALL)}
      >
        All
      </ButtonLink>
    </>
  )
}

StatusFilter.propTypes = {
  statusType: PropTypes.string.isRequired,
  handleStatusChange: PropTypes.func.isRequired,
}

export default StatusFilter
