import React, { useEffect, useState } from 'react'
import moment from 'moment'
import axios from 'axios'
import { Typography, Grid, List, ListItem, ListItemText, Chip, Box } from '@mui/material'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { Spinner } from 'greenfield-utilities'
import { getOwnerDetail } from '../Cards/Cardviewer/DataAlertAndSubscription/Helper'
import { API_GATEWAY_URL } from '../../../ducks/utils'

export const SubscriptionDetailComponent = ({ rowData, isInternalUser }) => {
  const [userOwnerDetails, setUserOwnerDetails] = useState([])
  const [groupOwnerDetails, setGroupOwnerDetails] = useState([])
  const [userRecipientDetails, setUserRecipientDetails] = useState([])
  const [groupRecipientDetails, setGroupRecipientDetails] = useState([])
  const [cardInfo, setCardInfo] = useState([])
  const [isLoadingCard, setIsLoadingCard] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const userOwnerIds = rowData.permission.user_owners.join(',')
  const groupOwnerIds = rowData.permission.group_owners.join(',')
  const userRecipientIds = rowData.notification.user_recipients.join(',')
  const groupRecipientIds = rowData.notification.group_recipients.join(',')

  const dispatch = useDispatch()

  useEffect(() => {
    if (userRecipientIds) {
      setIsLoading(true)
      getOwnerDetail(true, userRecipientIds, setUserRecipientDetails, setIsLoading, dispatch)
    }
    if (groupRecipientIds) {
      setIsLoading(true)
      getOwnerDetail(false, groupRecipientIds, setGroupRecipientDetails, setIsLoading, dispatch)
    }
  }, [userRecipientIds, groupRecipientIds])

  useEffect(() => {
    if (userOwnerIds) {
      setIsLoading(true)
      getOwnerDetail(true, userOwnerIds, setUserOwnerDetails, setIsLoading, dispatch)
    }
    if (groupOwnerIds) {
      setIsLoading(true)
      getOwnerDetail(false, groupOwnerIds, setGroupOwnerDetails, setIsLoading, dispatch)
    }
  }, [userOwnerIds, groupOwnerIds])

  useEffect(() => {
    const getCardInfo = () => {
      axios
        .get(`${API_GATEWAY_URL}/bi_reporting_assets/v3/cards/${rowData.card_id}?field_groups=name_only`)
        .then(response => {
          setCardInfo(response.data)
          setIsLoadingCard(false)
        })
        .catch(err => {
          setIsLoadingCard(false)
          // eslint-disable-next-line no-console
          console.warn(
            err.message?.data?.message ||
              err.response?.data?.message ||
              'Card fetch request failed, please try again later!'
          )
        })
    }
    if (rowData.card_id) {
      setIsLoadingCard(true)
      getCardInfo()
    }
  }, [rowData.card_id])

  return isLoadingCard || isLoading ? (
    <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 300 }}>
      <Spinner />
    </Box>
  ) : (
    <Grid className="siteRowExpand" container justifyContent="space-evenly" alignItems="flex-start">
      <Grid item xs={4}>
        <GridItem primary="Name" secondary={rowData.name} />
        {rowData.description && <GridItem primary="Description" secondary={rowData.description} />}
        <GridItem
          primary="Owner(s)"
          secondary={getOwnerInfoChip(userOwnerDetails, groupOwnerDetails, isInternalUser)}
        />
        <GridItem
          primary="Recipient(s)"
          secondary={getOwnerInfoChip(userRecipientDetails, groupRecipientDetails, isInternalUser)}
        />
      </Grid>
      <Grid item xs={4}>
        <GridItem
          primary="Card Name"
          secondary={
            <Link target="_blank" rel="noopener" to={`/card/${rowData.card_id}`}>
              {cardInfo?.card_attribute?.card_title}
            </Link>
          }
        />
        <GridItem
          primary="Created Date"
          secondary={moment(rowData.audit_properties?.created_date).format('MMMM Do YYYY, h:mm:ss a')}
        />
        <GridItem
          primary="Last Updated"
          secondary={moment(rowData.audit_properties?.updated_date).format('MMMM Do YYYY, h:mm:ss a')}
        />
      </Grid>
      <Grid item xs={4}>
        <GridItem primary="Start Date" secondary={moment(rowData.start_time).format('MMMM Do YYYY')} />
        <GridItem primary="End Date" secondary={moment(rowData.end_time).format('MMMM Do YYYY')} />
        <GridItem primary="Subscription ID" secondary={rowData._id} />
      </Grid>
    </Grid>
  )
}

const GridItem = ({ primary, secondary }) => {
  return (
    <List>
      <ListItem sx={{ margin: '5px' }}>
        <ListItemText
          className="subscription-list-item"
          primary={<Typography variant="subtitle1">{primary}</Typography>}
          secondary={
            <Typography variant="body1" data-cy={`subscription-list-item-${primary}`}>
              {secondary}
            </Typography>
          }
        />
      </ListItem>
    </List>
  )
}

export const getOwnerInfoChip = (userOwnersList, groupOwnersList, isInternalUser) => {
  const userOwners = userOwnersList.map(item => (
    <Chip
      label={`${item.first_name} ${item.last_name}`}
      key={item._id}
      className="chip-owners"
      component="span"
      size="small"
      role="note"
      style={{ fontWeight: 600 }}
    />
  ))
  const groupOwners = groupOwnersList.map(item =>
    isInternalUser ? (
      <Link
        key={item._id}
        to={`/admin/groups/${item._id}/members`}
        style={{ textDecoration: 'none', cursor: 'pointer' }}
      >
        <Chip
          label={item.group_name}
          key={item._id}
          className="chip-owners"
          component="span"
          size="small"
          role="note"
          style={{ fontWeight: 600, cursor: 'pointer' }}
        />
      </Link>
    ) : (
      <Chip
        label={item.group_name}
        key={item._id}
        className="chip-owners"
        component="span"
        size="small"
        role="note"
        style={{ fontWeight: 600 }}
      />
    )
  )
  return [...userOwners, ...groupOwners]
}
