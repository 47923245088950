import React from 'react' // eslint-disable-line
import { connect } from 'react-redux'
import isEqual from 'lodash/isEqual'
import cloneDeep from 'lodash/cloneDeep'
import PropTypes from 'prop-types'
import Visibility from 'react-visibility-sensor'

import { getCardData } from '../../../ducks/cardPreview'
import { updateAppliedFilters } from '../../../ducks/filter'
import { updateFavorite } from '../../../ducks/user'
import { getObjectNot, getNotificationCount, clearNotificationCache } from '../../../ducks/notification'
import { getDashboardFiltersForCard, getDashboardDefaultFilterPayload } from '../FilterViewer/filter-utils'
import CardPreview from './CardPreview'
import './cardPreview.scss'

export class GreenfieldCardPreviewContainer extends React.Component {
  state = {
    drill: [],
    filters: [],
    viewer: 'dashboard',
    isGranular: null,
    activeTabIndex: -1,
    isVisible: false,
    filtersToApplyWhenVisible: [],
  }

  componentDidMount() {
    const { filters, cardId, savedFilters, cards, activeTab, appliedFilters, isFavoritesCardViewer } = this.props
    const { activeTabIndex, isVisible } = this.state

    const isPrint = /\/print\//i.test(window.location.pathname)
    const filterPayload = getDashboardDefaultFilterPayload(savedFilters, filters, cardId)

    if (!isEqual(activeTab, activeTabIndex)) {
      this.setState({ activeTabIndex: activeTab })
    }

    // Card condition status check is required because we dont want to reload the card again on click of tab
    if (
      !(cards && cards[cardId] && cards[cardId].status >= 200 && cards[cardId].status < 300) ||
      isFavoritesCardViewer
    ) {
      if (!filterPayload.length) {
        this.setState(
          {
            filters: [],
          },
          () => {
            const newFilters = getDashboardFiltersForCard(appliedFilters, cardId)

            if (!isVisible && !isPrint) {
              this.setState({
                filtersToApplyWhenVisible: newFilters,
              })
            } else {
              this.cardRender(newFilters)
            }
          }
        )
      } else {
        // this is when personalized default filters are applied
        this.setState({
          filters: filterPayload,
          filtersToApplyWhenVisible: filterPayload,
        })
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { timePeriod, refreshDashboard, savedFilters, filters, cardId, getNotificationCountStatus, isHomePage } =
      this.props
    const isPrint = /\/print\//i.test(window.location.pathname)

    this.cardRenderOnFilterApplied(prevProps, isPrint)

    if (
      prevProps.getNotificationCountStatus?.status >= 200 &&
      prevProps.getNotificationCountStatus?.status < 300 &&
      Object.keys(getNotificationCountStatus.data || {}).length !==
        Object.keys(prevProps.getNotificationCountStatus.data || {}).length &&
      Object.keys(getNotificationCountStatus.data || {}).length > 0 &&
      !isHomePage &&
      !isPrint
    ) {
      if (getNotificationCountStatus?.data[cardId] > 0) {
        this.props.getObjectNot({
          objectId: cardId,
          type: 'card',
        })
      }
    }

    if (!isEqual(timePeriod, prevProps.timePeriod) || !isEqual(refreshDashboard, prevProps.refreshDashboard)) {
      const hasLoaded = Boolean(Object.keys(this.props.cards).length)
      const filterPayload = hasLoaded ? null : getDashboardDefaultFilterPayload(savedFilters, filters, cardId)

      // The idea here being, only include default filters on load. If cards has a lenght, it means the dashboard has loaded, and using the default filters
      // could override other filters.
      this.cardRender(filterPayload)
    }
  }

  cardRenderOnFilterApplied(prevProps, isPrint) {
    const { filters } = this.state
    const { cards, cardId, appliedFilters, tabCardsPayload } = this.props

    // This need to compare with cardID because AppliedFilters state in duck updated on first execute card and AppliedFilters object is not based on the cardID
    if (appliedFilters && isEqual(prevProps.cardId, cardId)) {
      const newFilters = getDashboardFiltersForCard(appliedFilters, cardId)

      // Add the condtion check with tabCardsPayload which have payload sent for each card and compare if same payload is applied an card available then don't send the render card request
      if (
        !isEqual(filters, newFilters) ||
        (cards &&
          cardId &&
          cards[cardId] &&
          cards[cardId].status !== 'requested' &&
          !appliedFilters.length &&
          tabCardsPayload &&
          cardId &&
          tabCardsPayload[cardId] &&
          !isEqual(tabCardsPayload[cardId].filters, newFilters))
      ) {
        this.setState({ filters: [...newFilters], filtersToApplyWhenVisible: [...newFilters] }, () => {
          if (
            tabCardsPayload &&
            cardId &&
            tabCardsPayload[cardId] &&
            !isEqual(tabCardsPayload[cardId].filters, newFilters) &&
            !isPrint
          ) {
            this.cardRender()
          }
        })
      }
    }
  }

  cardRender(filterOverride) {
    const { isPrintCard, getCardData, cardId, data, timePeriod } = this.props
    const { drill, filters, viewer } = this.state

    const payload = {
      drill,
      filters: filterOverride || filters,
      time_period: timePeriod?.hide_time_period_viewer ? {} : timePeriod,
      viewer,
    }

    const vizType = data?.card_attribute?.viz_type || ''
    const isCompareBy = Boolean(data?.card_query_attribute?.time_period?.compare_by)

    if (
      ['table', 'pivot', 'line', 'bar', 'bar and line', 'stack bar', '100_percent_stack', 'heatmap', 'pareto'].includes(
        vizType.toLowerCase()
      )
    ) {
      if (!isCompareBy) {
        payload.result_form = { date: '${raw}||${formatted}', pivot: 'formatted' } // eslint-disable-line no-template-curly-in-string
      } else {
        payload.result_form = { date: '${raw}||${formatted}||${alternate}||${alternate_formatted}', pivot: 'formatted' } // eslint-disable-line no-template-curly-in-string
      }
    } else {
      // for dashboard viewer we have no access to viz type or compare_by info so request data in compare by form
      // code in viz_type components will appropriately parse out date
      payload.result_form = { date: '${raw}||${formatted}||${alternate}||${alternate_formatted}', pivot: 'formatted' } // eslint-disable-line no-template-curly-in-string
    }

    if (!isPrintCard) {
      getCardData({
        id: cardId,
        json: payload,
        vizType,
      })
    }
  }

  handleVisibilityChange = change => {
    if (!change) {
      return
    }

    this.setState(
      () => ({
        isVisible: change,
      }),
      () => {
        const { cards, cardId, timePeriod } = this.props
        const { isVisible, filtersToApplyWhenVisible } = this.state
        const card = cards[cardId] || {}
        if (isVisible && (!cards || !cards[cardId])) {
          this.cardRender(filtersToApplyWhenVisible)
        } else if (isVisible && card?.data?.context?.rendered_payload?.time_period && Object.keys(timePeriod).length) {
          // Cards on a dashboard tab that have already rendered would not rerender if the time period was changed on another tab, this is the only situation where this render should fire.
          const cardTimePeriod = cloneDeep(card.data.context?.rendered_payload?.time_period) || {}
          const _timePeriod = cloneDeep(timePeriod)
          delete cardTimePeriod.granularity
          delete _timePeriod.granularity
          if (
            !isEqual(_timePeriod, cardTimePeriod) &&
            !cardTimePeriod.hide_time_period_viewer &&
            !_timePeriod?.hide_time_period_viewer
          ) {
            this.cardRender(filtersToApplyWhenVisible)
          }
        }
      }
    )
  }

  render() {
    return (
      <Visibility intervalDelay={600} partialVisibility onChange={this.handleVisibilityChange} delayedCall>
        <CardPreview
          onUnfavorite={this.props.onUnfavorite}
          updateFavorite={this.props.updateFavorite}
          cardId={this.props.cardId}
          showFavIcon={this.props.showFavIcon}
          filters={this.props.filters}
          cards={this.props.cards}
          cardSizeHeight={this.props.cardSizeHeight}
          cardSizeWidth={this.props.cardSizeWidth}
          isHomePage={this.props.isHomePage}
          getCardData={this.props.getCardData}
          isMobile={this.props.isMobile}
          timePeriodValue={this.props.timePeriod}
          cardFilterApplied={this.state.filters}
          routeProps={this.props.routeProps}
          objectNotificationState={this.props.objectNotificationState}
          getNotificationCountStatus={this.props.getNotificationCountStatus}
        />
      </Visibility>
    )
  }
}

const mapStateToProps = state => ({
  fetchCardData: state.previewCard.fetchCardData,
  cards: state.previewCard.cards,
  tabCardsPayload: state.previewCard.tabCardsPayload,
  appliedFilters: state.filter.appliedFilters,
  objectNotificationState: state.notification.getObjectNotificationStatus,
  getNotificationCountStatus: state.notification.getNotificationCountStatus,
})

export const mapDispatchToProps = dispatch => ({
  getCardData(data) {
    dispatch(getCardData(data))
  },
  updateAppliedFilters(data) {
    dispatch(updateAppliedFilters(data))
  },
  updateFavorite(data) {
    dispatch(updateFavorite(data))
  },
  getObjectNot(data) {
    dispatch(getObjectNot(data))
  },
  getNotificationCount(data) {
    dispatch(getNotificationCount(data))
  },
  clearNotificationCache(data) {
    dispatch(clearNotificationCache(data))
  },
})

GreenfieldCardPreviewContainer.defaultProps = {
  getCardData: () => {},
  updateAppliedFilters: () => {},
  fetchCardData: {},
  appliedFilters: [],
  savedFilters: [],
  dashboardTimePeriod: {},
  cardSizeHeight: 0,
  cardSizeWidth: 0,
  isHomePage: false,
  isFavoritesCardViewer: false,
  timePeriod: {},
  cards: {},
}

GreenfieldCardPreviewContainer.propTypes = {
  data: PropTypes.object,
  fetchCardData: PropTypes.object,
  savedFilters: PropTypes.array,
  timePeriod: PropTypes.object,
  cardSizeHeight: PropTypes.number,
  cardSizeWidth: PropTypes.number,
  isHomePage: PropTypes.bool,
  isFavoritesCardViewer: PropTypes.bool,
}

export default connect(mapStateToProps, mapDispatchToProps)(GreenfieldCardPreviewContainer)
