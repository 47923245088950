import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Typography, Divider, IconButton, Button, Dialog, DialogContent, DialogActions, Tooltip } from '@mui/material'
import { Edit, Close } from '@mui/icons-material'
import Viewer from 'react-froala-wysiwyg/FroalaEditorView'
import cloneDeep from 'lodash/cloneDeep'
import BuilderSupportDetails from '../../routes/Builder/Sidebar/BuilderSupportDetails'

const DatasetSupportDialog = ({ savedSupportText = '', onSaveSupportText, onCloseSupportText, isDatasetEditable }) => {
  const [isHelpEditable, setIsHelpEditable] = useState(false)
  const [supportText, setSupportText] = useState('')

  useEffect(() => {
    setSupportText(cloneDeep(savedSupportText))
  }, [])

  const handleSupportTextUpdate = model => {
    setSupportText(model)
  }

  return (
    <Dialog open>
      <header className="header-container">
        <Typography variant="h2" className="title">
          Support Detail
        </Typography>
        <div>
          {!isHelpEditable && isDatasetEditable && (
            <Tooltip title="Help" placement="top">
              <IconButton
                id="card-view-help"
                onClick={() => setIsHelpEditable(!isHelpEditable)}
                aria-label="help button"
              >
                <Edit className="align-icon" />
              </IconButton>
            </Tooltip>
          )}
          <IconButton id="card-view-help" onClick={() => onCloseSupportText()} aria-label="help button">
            <Close className="align-icon" />
          </IconButton>
        </div>
      </header>
      <Divider />
      {isHelpEditable && (
        <>
          <DialogContent style={{ paddingTop: '10px' }}>
            <BuilderSupportDetails
              supportText={supportText}
              type="dataset"
              updateSupportText={handleSupportTextUpdate}
            />
          </DialogContent>
          <Divider />
          <DialogActions>
            <Button
              onClick={() => {
                setIsHelpEditable(false)
                onCloseSupportText()
              }}
              color="primary"
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                setIsHelpEditable(false)
                onSaveSupportText(supportText)
              }}
              color="primary"
            >
              Update
            </Button>
          </DialogActions>
        </>
      )}
      {!isHelpEditable && (
        <DialogContent style={{ paddingTop: '10px' }}>
          <Viewer model={supportText} />
        </DialogContent>
      )}
    </Dialog>
  )
}

DatasetSupportDialog.propTypes = {
  savedSupportText: PropTypes.string.isRequired,
  onSaveSupportText: PropTypes.func,
  onCloseSupportText: PropTypes.func,
}

export default DatasetSupportDialog
