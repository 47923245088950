import React from 'react'
import { InputAdornment, Button, Typography, Grid, Card, OutlinedInput } from '@mui/material'
import { Link } from '@mui/icons-material'

import greenfieldLogo from '../../images/greenfield-logo.svg'

export default class TopLevelErrorBoundary extends React.Component {
  state = {
    error: '',
    info: '',
  }

  componentDidCatch(error, info) {
    const { routeProps, cardInfo, isCard, isDashboard } = this.props

    this.setState(
      {
        error,
        info,
      },
      () => {
        if (process.env.NODE_ENV === 'production') {
          if (isCard && cardInfo?.data?.card_attribute) {
            const { viz_type } = cardInfo.data.card_attribute // eslint-disable-line camelcase
            this.props.trackEvent('ui-error-stacktrace', viz_type, info.componentStack)
            this.props.trackEvent('ui-error-text', viz_type, error)
            this.props.trackEvent('ui-error', 'card', error)
          } else if (isDashboard) {
            this.props.trackEvent('ui-error-dashPath', routeProps.location.pathname, error)
            this.props.trackEvent('ui-error', 'dashboard', error)
          } else {
            this.props.trackEvent('ui-error', routeProps.location.pathname, error)
          }
        }
      }
    )
  }

  render() {
    const { error, info } = this.state
    const pageURL = `${window.location.href}`

    /* eslint-disable */
    return error ? (
      <div className="ui-toplevel-error-boundary">
        <img
          src={greenfieldLogo}
          alt="Greenfield Home Page"
          className="greenfield-logo"
          width="150"
          data-cy="greenfield-logo"
        />
        <Typography style={{ color: 'red' }} mt={5} mb={3} variant="h1">
          Uh-oh! There was an error, please try to refresh the page
        </Typography>
        <Typography variant="h2" mb={[3, 5]}>
          If the error continues, reach out via the appropriate method for you
        </Typography>
        <Grid container px={[0, 0, 4]} maxWidth="1000px" mb={5} alignItems="stretch">
          <Grid item xs={12} sm={4} mb={[2, 0]} pr={[0, '10px', '20px']}>
            <Card
              sx={{
                height: '100%',
                padding: '25px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <Typography variant="h6" mb="10px">
                HQ Team Members
              </Typography>
              <a href="https://target.slack.com/archives/C8L2XD0SE">#greenfield-support</a>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4} mb={[2, 0]} pr={[0, '10px', '20px']}>
            <Card
              sx={{
                height: '100%',
                padding: '25px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <Typography variant="h6" mb="10px">
                Store Team Members
              </Typography>
              <span padding="20px">myDay Help</span>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4} mb={[2, 0]}>
            <Card
              sx={{
                height: '100%',
                padding: '25px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <Typography variant="h6" mb="10px">
                External Partners{' '}
              </Typography>
              <a href="https://www.partnersonline.com/page/help" padding="20px">
                PartnersOnline Help
              </a>
            </Card>
          </Grid>
        </Grid>
        <Typography variant="h2" mb={3}>
          with the following information and steps taken when error occurred:
        </Typography>
        <OutlinedInput
          label="URL"
          variant="outlined"
          disabled
          defaultValue={pageURL}
          sx={{
            width: '100%',
            maxWidth: '600px',
            marginBottom: 3,
          }}
          endAdornment={
            <InputAdornment position="end">
              <Button
                startIcon={<Link />}
                aria-label="Copy URL"
                onClick={() => navigator.clipboard.writeText(pageURL)}
                edge="end"
              >
                Copy URL
              </Button>
            </InputAdornment>
          }
        />
        <details>
          <summary>{error?.toString()}</summary>
          <p>{info.componentStack?.split(' ').join('')}</p>
        </details>
      </div>
    ) : (
      this.props.children
    )
  }
  /* eslint-enable */
}
