import React from 'react'
import { Typography, Tabs, Button, Tab, Tooltip, Grid } from '@mui/material'
import { PropTypes } from 'prop-types'
import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'
import debounce from 'lodash/debounce'
import pick from 'lodash/pick'
import { KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material'
import SelectOptionSection from '../../shared/SelectionBoxComponent/SelectOptionSection'
import ProfileInfo from './ProfileComponent/ProfileInfo'
import { cardExpressionInfoObj, columnOptions, functionOptions, profileInfoObj } from './CalculatedFieldConstants'
import './calculated.scss'

class CalculatedField extends React.Component {
  state = {
    profileInfo: profileInfoObj,
    expressionQL: '',
    cursorIndex: 0,
    props: this.props,
    selectedCalField: {},
    isEditable: true,
    isValidateCall: false,
    resetCalculatedField: true,
    calcFieldOptions: [],
    calcFieldFnHelp: [],
    calcFieldFnDoc: {},
    currentFnOption: '',
    drawerOpen: true,
    value: 0,
    open: false,
    executeCalFieldStatus: {},
    createCalFieldStatus: {},
    cardExpressionInfo: cardExpressionInfoObj,
  }

  drawerRef = null
  buttonRef = null
  iconRef = null

  componentDidMount() {
    const { defaultUser, getCalculatedFieldFnHelp, getCalculatedFieldFnHelpStatus, metadataStatus, getMetadata } =
      this.props
    if (Object.prototype.hasOwnProperty.call(defaultUser, 'lanId')) {
      const profileInfo = cloneDeep(this.state.profileInfo)
      if (!profileInfo.owners_access || !profileInfo.owners_access.length) {
        profileInfo.owners_access = [
          {
            user_group_name: `${defaultUser.firstName} ${defaultUser.lastName}`,
            _id: defaultUser.lanId.toLowerCase(),
            type: 'user',
          },
        ]
      }
      this.setState({ profileInfo })
    }
    if (!getCalculatedFieldFnHelpStatus.data) {
      getCalculatedFieldFnHelp()
    }
    if (!metadataStatus) {
      getMetadata('default_date_formats')
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let executeCalFieldStatus = {}
    let createCalFieldStatus = {}
    let calcFieldOptions = []
    let calcFieldFnHelp = []
    let calcFieldFnDoc = {}
    let { profileInfo, expressionQL, selectedCalField, isEditable, isValidateCall, cardExpressionInfo } = prevState
    const {
      resetCalculatedField,
      createCalculatedFieldStatus,
      editCalculatedFieldStatus,
      getCalculatedFieldFnHelpStatus,
      defaultUser,
      calculatedFieldDetail,
      isCardColumnExpression,
      editCardExpression,
    } = nextProps

    if (!isCardColumnExpression) {
      if (calculatedFieldDetail && !Object.keys(selectedCalField).length) {
        isValidateCall = false
        selectedCalField = nextProps.calculatedFieldDetail
        isEditable = nextProps.calculatedFieldDetail.edit_enabled
        profileInfo = nextProps.calculatedFieldDetail
        expressionQL = nextProps.calculatedFieldDetail.exprQL
      }

      if (nextProps.resetCalculatedField && nextProps.resetCalculatedField !== prevState.resetCalculatedField) {
        profileInfo = profileInfoObj
        if (Object.prototype.hasOwnProperty.call(defaultUser, 'lanId')) {
          profileInfo.owners_access = [
            {
              user_group_name: `${defaultUser.firstName} ${defaultUser.lastName}`,
              _id: defaultUser.lanId.toLowerCase(),
              type: 'user',
            },
          ]
        }

        expressionQL = ''
        selectedCalField = {}
        isEditable = true
        isValidateCall = false
        nextProps.setSelectedEditId()
      }

      if (
        (createCalculatedFieldStatus &&
          (createCalculatedFieldStatus.status === 201 || createCalculatedFieldStatus.status === 200)) ||
        (editCalculatedFieldStatus &&
          (editCalculatedFieldStatus.status === 201 || editCalculatedFieldStatus.status === 200))
      ) {
        if (
          !isEqual(createCalculatedFieldStatus, prevState.props.createCalculatedFieldStatus) ||
          !isEqual(editCalculatedFieldStatus, prevState.props.editCalculatedFieldStatus)
        ) {
          let calFieldInfo = null

          if (editCalculatedFieldStatus) {
            // removed is_metric
            calFieldInfo = pick(editCalculatedFieldStatus.data, [
              'column_name',
              'column_display_name',
              'exprQL',
              'type',
              'data_type',
            ])
            calFieldInfo.field_name = calFieldInfo.column_name
            delete calFieldInfo.column_name
          }
          nextProps.saveCalculatedFieldSuccess(calFieldInfo)
        }
      }

      if (
        createCalculatedFieldStatus !== prevState.props.createCalculatedFieldStatus &&
        createCalculatedFieldStatus.status !== 200 &&
        createCalculatedFieldStatus.status !== 201
      ) {
        createCalFieldStatus = createCalculatedFieldStatus
      }
    }

    if (
      !calcFieldOptions.length &&
      getCalculatedFieldFnHelpStatus.status >= 200 &&
      getCalculatedFieldFnHelpStatus.status < 300
    ) {
      if (getCalculatedFieldFnHelpStatus && getCalculatedFieldFnHelpStatus.data) {
        const fnHelp = getCalculatedFieldFnHelpStatus.data.calculated_field_function_help

        calcFieldOptions = fnHelp.map(fnHelpIdx => {
          fnHelpIdx.column_display_name = fnHelpIdx.name
          return fnHelpIdx
        })
        calcFieldFnHelp = fnHelp.map(fnHelpIdx => fnHelpIdx.description)
        calcFieldFnDoc = fnHelp.reduce((accumulator, eachFn) => {
          accumulator[eachFn.name] = eachFn.description
          return accumulator
        }, {})
      }
    }

    if (nextProps.executeCalculatedFieldStatus !== prevState.props.executeCalculatedFieldStatus) {
      executeCalFieldStatus = nextProps.executeCalculatedFieldStatus
    }

    if (isCardColumnExpression && editCardExpression && !Object.keys(selectedCalField).length) {
      isValidateCall = false
      isEditable = true
      selectedCalField = editCardExpression
      cardExpressionInfo = editCardExpression
      expressionQL = editCardExpression.exprQL
    }

    return {
      executeCalFieldStatus,
      createCalFieldStatus,
      resetCalculatedField,
      profileInfo,
      expressionQL,
      selectedCalField,
      isEditable,
      isValidateCall,
      calcFieldOptions,
      calcFieldFnHelp,
      calcFieldFnDoc,
      cardExpressionInfo,
    }
  }

  renderBasedOnRequest() {
    const datasetId = this.props.selectedDatasetId

    if (
      this.props.cardsDatasetStatus[datasetId] &&
      this.props.cardsDatasetStatus[datasetId].data &&
      this.props.cardsDatasetStatus[datasetId].data.fields_array
    ) {
      return this.renderAllFields(this.props)
    }
  }

  getTimeSeriesColumn() {
    const { selectedDatasetId } = this.props
    const columnObj = this.props.cardsDatasetStatus[selectedDatasetId].data.fields_array
    const columnIndex = columnObj.findIndex(obj => obj.type === 'timeseries')

    return columnObj[columnIndex].field_name
  }

  updateFormula = (inputType, column) => {
    let { expressionQL, cursorIndex, calcFieldFnHelp, calcFieldOptions } = { ...this.state }
    if (inputType === 'listSelect') {
      if (column.field_name) {
        expressionQL = `${expressionQL.substr(0, cursorIndex)}'${column.field_name}'${expressionQL.substr(cursorIndex)}`
      } else {
        if (column.column_display_name === 'interval') {
          const timeValue = this.getTimeSeriesColumn()
          const intervalValue = `WHERE ${timeValue} INTERVAL ''`

          column.value = intervalValue
        }

        const existingIdx = calcFieldOptions.findIndex(option => option.name === column.column_display_name)
        if (existingIdx > -1) {
          this.setState({
            currentFnOption: calcFieldFnHelp[existingIdx],
          })
        }
        expressionQL = `${expressionQL.substr(0, cursorIndex)}${column.value}${expressionQL.substr(cursorIndex)}`
      }
    } else if (inputType === 'textArea') {
      expressionQL = column
    }

    this.setState({
      expressionQL,
      executeCalFieldStatus: {},
      createCalFieldStatus: {},
      isValidateCall: true,
    })
  }

  updateCursorIndex = value => {
    this.setState({
      cursorIndex: value,
    })
  }

  callValidateFormula = expql => {
    const paramsData = {}
    paramsData.exprQL = expql
    paramsData.dataset_id = this.props.selectedDatasetId

    this.setState({
      isValidateCall: true,
    })

    this.props.executeCalculatedField(paramsData)
  }

  saveCalculatedField = (calculatedFieldDetails, expressionQL) => {
    const calFieldInfo = cloneDeep(calculatedFieldDetails)

    // TODO in case column conversion required.
    // const columnObj = this.props.cardsDatasetStatus[this.props.selectedDatasetId].data.fields_array
    // columnObj.forEach(column => {
    //   calFieldInfo.exprQL = calFieldInfo.exprQL.replace(column.column_display_name, column.column_name)
    // })

    calFieldInfo.dataset_id = this.props.selectedDatasetId
    calFieldInfo.exprQL = expressionQL
    calFieldInfo.owners = calFieldInfo.owners_access
      .filter(owner => owner.type === 'user')
      .map(owner => owner._id.toString())
    calFieldInfo.owner_groups = calFieldInfo.owners_access
      .filter(owner => owner.type === 'group')
      .map(owner => owner._id)
    calFieldInfo.user_view_access = calFieldInfo.viewers_access
      .filter(viewer => viewer.type === 'user')
      .map(viewer => viewer._id.toString())
    calFieldInfo.group_view_access = calFieldInfo.viewers_access
      .filter(viewer => viewer.type === 'group')
      .map(viewer => viewer._id)

    // removing unneeded attributes
    delete calFieldInfo.owners_access
    delete calFieldInfo.viewers_access
    delete calFieldInfo.label
    delete calFieldInfo.calFieldId
    delete calFieldInfo.value

    if (this.state.selectedCalField._id) {
      delete calFieldInfo.column_name
      this.props.editCalculatedField({ calFieldId: this.state.selectedCalField._id, payload: calFieldInfo })
    } else {
      this.props.createCalculatedField(calFieldInfo)
    }
  }

  saveCardColumnExpression = (cardColumnInfo, expressionQL) => {
    const _cardColumnInfo = cloneDeep(cardColumnInfo)
    _cardColumnInfo.exprQL = expressionQL

    this.props.saveCalculatedFieldSuccess(_cardColumnInfo)
  }

  cancelCalculatedField = () => {
    this.props.onCancelCalculatedField()
  }

  fetchAutoSuggest = searchText => {
    this.props.getDetailsOwners(searchText)

    this.setState({
      isValidateCall: true,
    })
  }

  handleTabChange = (event, value) => {
    this.setState({ value })
  }

  refFocus = event => {
    if (event.which === 9 && !event.shiftKey) {
      this.iconRef.focus()
    }
    if (event.which === 9 && event.shiftKey) {
      this.iconRefShift.focus()
    }
  }

  setRef = ref => {
    this.buttonRef = ref
  }

  handleKeyDown = event => {
    const { drawerOpen } = this.state
    if (!drawerOpen && event.which === 9) {
      this.buttonRef.focus()
    }
    if (drawerOpen && event.which === 9 && !event.shiftKey) {
      this.drawerRef.focus()
    }
  }

  keyDownTab = (event, index) => {
    if (event.which === 9 && event.shiftKey && index === 0) {
      this.iconRefShift.focus()
    }
  }

  renderTabs(props) {
    const { selectedDatasetId } = props
    const { value, calcFieldOptions, currentFnOption, calcFieldFnDoc } = this.state
    return (
      <div className="tab-padding-top">
        {value === 1 && (
          <SelectOptionSection
            arrList={this.props.cardsDatasetStatus[selectedDatasetId].data.fields_array}
            selectOptions={columnOptions}
            handleListSelectValue={this.updateFormula}
            isAvatar
            idSelectType="column-select-type"
            idSearchColumn="column-search"
            idListColumn="column-list"
            selectLabel="Select Dataset Field Type"
            searchLabel="Search Dataset Fields"
          />
        )}
        {value === 0 && (
          <>
            <SelectOptionSection
              arrList={calcFieldOptions}
              selectOptions={functionOptions}
              handleListSelectValue={this.updateFormula}
              isAvatar={false}
              idSelectType="function-select-type"
              idSearchColumn="function-search"
              idListColumn="function-list"
              selectLabel="Select Function Type"
              searchLabel="Search function items"
              calcFieldFnDoc={calcFieldFnDoc}
              isFunctionHelp
            />
            <div className="function-margin-top">
              <Typography variant="h2">Function Description</Typography>
              <div className="documentation">
                <Typography variant="body1">
                  {currentFnOption ||
                    'Click on any of the function names from the function list for a short description of the function and its formulas.'}
                </Typography>
              </div>
            </div>
          </>
        )}
      </div>
    )
  }

  renderAllFields(props) {
    const { selectedDatasetId, isCardColumnExpression, metadataStatus } = props
    const { drawerOpen, value, open } = this.state
    return (
      <div className="section-layout" id="calculated-field-section">
        <Grid container>
          <Grid item xs={drawerOpen ? 8 : 12}>
            <>
              <ProfileInfo
                columnList={this.props.cardsDatasetStatus[selectedDatasetId].data.fields_array}
                functionList={this.state.calcFieldOptions}
                isEditable={this.state.isEditable}
                isValidateCall={this.state.isValidateCall}
                expressionQL={this.state.expressionQL}
                profileInfo={this.state.profileInfo}
                handleFormulaChange={this.updateFormula}
                callValidateFormula={this.callValidateFormula}
                executeCalculatedStatus={this.state.executeCalFieldStatus}
                createCalFieldStatus={this.state.createCalFieldStatus}
                saveCalculatedField={this.saveCalculatedField}
                updateCursorIndex={this.updateCursorIndex}
                cancelCalculatedField={this.cancelCalculatedField}
                fetchAutoSuggest={debounce(this.fetchAutoSuggest, 500)}
                detailsOwnersStatus={this.props.detailsOwnersStatus}
                searchCalculatedFieldStatus={this.props.searchCalculatedFieldStatus}
                calculatedFieldDetail={this.props.calculatedFieldDetail}
                buttonRef={this.refFocus}
                textRef={this.setRef}
                isExistingCalculatedField={Boolean(
                  this.props.calculatedFieldDetail && Object.keys(this.props.calculatedFieldDetail).length
                )}
                isCardColumnExpression={isCardColumnExpression}
                saveCardColumnExpression={this.saveCardColumnExpression}
                cardExpressionInfo={this.state.cardExpressionInfo}
                metadataStatus={metadataStatus}
              />
              <span
                tabIndex={-1}
                ref={ref => {
                  this.iconRef = ref
                }}
              />
              <Tooltip
                title={drawerOpen ? 'Close Slider' : 'Open Slider'}
                onClose={() => this.setState({ open: false })}
                onOpen={() => this.setState({ open: true })}
                open={open}
              >
                <Button
                  onClick={() => {
                    this.setState({
                      drawerOpen: !drawerOpen,
                      open: false,
                    })
                  }}
                  label={drawerOpen ? 'Close Slider' : 'Open Slider'}
                  style={{
                    position: 'absolute',
                    right: drawerOpen ? '33%' : 5,
                    top: '50%',
                    transform: 'rotateZ(90deg)',
                  }}
                  onKeyDown={this.handleKeyDown}
                  variant="outlined"
                >
                  {drawerOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                </Button>
              </Tooltip>
              <span
                tabIndex={-1}
                ref={ref => {
                  this.iconRefShift = ref
                }}
              />
            </>
          </Grid>
          {drawerOpen && (
            <Grid item xs={4}>
              <div id="calculated-field-drawer" className="drawer-class">
                <span
                  tabIndex={-1}
                  ref={drawerRef => {
                    this.drawerRef = drawerRef
                  }}
                />
                <>
                  <Tabs onChange={this.handleTabChange} indicatorColor="primary" textColor="primary" value={value}>
                    {['Functions', 'Dataset Fields'].map((name, index) => (
                      <Tab
                        id={`tab-name-${index}`}
                        key={name}
                        label={name}
                        onKeyDown={event => this.keyDownTab(event, index)}
                      />
                    ))}
                  </Tabs>
                  {this.renderTabs(props)}
                </>
              </div>
            </Grid>
          )}
        </Grid>
      </div>
    )
  }

  render() {
    return <div id="calculated-fields">{this.renderBasedOnRequest()}</div>
  }
}

CalculatedField.defaultProps = {
  resetCalculatedField: false,
  executeCalculatedFieldStatus: {},
  createCalculatedFieldStatus: {},
  editCalculatedFieldStatus: {},
  searchCalculatedFieldStatus: {},
  getCalculatedFieldFnHelpStatus: {},
  cardsDatasetStatus: {},
  selectedDatasetId: -1,
  expressionQL: '',
  isEditable: true,
  profileInfo: profileInfoObj,
  cardExpressionInfo: cardExpressionInfoObj,
  defaultUser: {},
  getMetadata: () => {},
}

CalculatedField.propTypes = {
  resetCalculatedField: PropTypes.bool,
  executeCalculatedFieldStatus: PropTypes.object,
  createCalculatedFieldStatus: PropTypes.object,
  editCalculatedFieldStatus: PropTypes.object,
  searchCalculatedFieldStatus: PropTypes.object,
  getCalculatedFieldFnHelpStatus: PropTypes.object,
  cardsDatasetStatus: PropTypes.object.isRequired,
  selectedDatasetId: PropTypes.number.isRequired,
  saveCalculatedFieldSuccess: PropTypes.func,
  cancelCalculatedField: PropTypes.func,
  setSelectedEditId: PropTypes.func,
  defaultUser: PropTypes.object,
  getCalculatedFieldFnHelp: PropTypes.func,
  getMetadata: PropTypes.func,
}

export default CalculatedField
