export const defaultFilterDatasetObj = {
  column: '',
  aggregation_type: 'Sum',
  type: 'in',
  value: '',
  pattern: [],
  uppervalue: '',
  lowervalue: '',
}

export const calculatedFieldType = ['postagg', 'aggregation']

export const dimensionType = ['dimension', 'dynamic_dimension']

export const defaultFilterForViewer = {
  column: '',
  type: 'in',
  display_name: '',
  filterType: 'checkbox',
  isDefaultFilter: false,
  pattern: [],
  value: '',
  uppervalue: '',
  lowervalue: '',
  aggregation_type: 'Sum',
}

export const AXIS_OPTIONS = {
  CATEGORIES: 'Dimension',
  SERIES: 'Metrics',
  BREAK_BY: 'Break By',
}

export const DEFAULT_FONT_COLOR = '#000000'
