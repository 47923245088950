import { call, put } from 'redux-saga/effects'
import axios from 'axios'
import cloneDeep from 'lodash/cloneDeep'
import { API_GATEWAY_URL, formatDatasetFields } from './utils'

// Action types
export const DATASETLIST_FETCH_REQUESTED = 'DATASETLIST_FETCH_REQUESTED'
export const DATASETLIST_FETCH_SUCCEEDED = 'DATASETLIST_FETCH_SUCCEEDED'
export const DATASETLIST_FETCH_FAILED = 'DATASETLIST_FETCH_FAILED'

export const FAVORITE_DATASETLIST_FETCH_REQUESTED = 'FAVORITE_DATASETLIST_FETCH_REQUESTED'
export const FAVORITE_DATASETLIST_FETCH_SUCCEEDED = 'FAVORITE_DATASETLIST_FETCH_SUCCEEDED'
export const FAVORITE_DATASETLIST_FETCH_FAILED = 'FAVORITE_DATASETLIST_FETCH_FAILED'

export const SEARCH_DATASETLIST_FETCH_REQUESTED = 'SEARCH_DATASETLIST_FETCH_REQUESTED'
export const SEARCH_DATASETLIST_FETCH_SUCCEEDED = 'SEARCH_DATASETLIST_FETCH_SUCCEEDED'
export const SEARCH_DATASETLIST_FETCH_FAILED = 'SEARCH_DATASETLIST_FETCH_FAILED'

export const DATASETDETAIL_FETCH_REQUESTED = 'DATASETDETAIL_FETCH_REQUESTED'
export const DATASETDETAIL_FETCH_SUCCEEDED = 'DATASETDETAIL_FETCH_SUCCEEDED'
export const DATASETDETAIL_FETCH_FAILED = 'DATASETDETAIL_FETCH_FAILED'

export const DATASET_DELETE_REQUESTED = 'DATASET_DELETE_REQUESTED'
export const DATASET_DELETE_SUCCEEDED = 'DATASET_DELETE_SUCCEEDED'
export const DATASET_DELETE_FAILED = 'DATASET_DELETE_FAILED'

export const DATASET_PREVIEW_REQUESTED = 'DATASET_PREVIEW_REQUESTED'
export const DATASET_PREVIEW_SUCCEEDED = 'DATASET_PREVIEW_SUCCEEDED'
export const DATASET_PREVIEW_FAILED = 'DATASET_PREVIEW_FAILED'
export const CLEAR_DATASET_PREVIEW = 'CLEAR_DATASET_PREVIEW'
export const CLEAR_UPDATE_STATUS = 'CLEAR_UPDATE_STATUS'

export const DATASET_CALCFIELD_FETCH_REQUESTED = 'DATASET_CALCFIELD_FETCH_REQUESTED'
export const DATASET_CALCFIELD_FETCH_SUCCEEDED = 'DATASET_CALCFIELD_FETCH_SUCCEEDED'
export const DATASET_CALCFIELD_FETCH_FAILED = 'DATASET_CALCFIELD_FETCH_FAILED'

export const CALCFIELD_DELETE_REQUESTED = 'CALCFIELD_DELETE_REQUESTED'
export const CALCFIELD_DELETE_SUCCEEDED = 'CALCFIELD_DELETE_SUCCEEDED'
export const CALCFIELD_DELETE_FAILED = 'CALCFIELD_DELETE_FAILED'

export const DATASET_EDIT_REQUESTED = 'DATASET_EDIT_REQUESTED'
export const DATASET_EDIT_SUCCEEDED = 'DATASET_EDIT_SUCCEEDED'
export const DATASET_EDIT_FAILED = 'DATASET_EDIT_FAILED'

export const DATASET_PATCH_REQUESTED = 'DATASET_PATCH_REQUESTED'
export const DATASET_PATCH_SUCCEEDED = 'DATASET_PATCH_SUCCEEDED'
export const DATASET_PATCH_FAILED = 'DATASET_PATCH_FAILED'

export const DATASTORES_FETCH_REQUESTED = 'DATASTORES_FETCH_REQUESTED'
export const DATASTORES_FETCH_SUCCEEDED = 'DATASTORES_FETCH_SUCCEEDED'
export const DATASTORES_FETCH_FAILED = 'DATASTORES_FETCH_FAILED'

export const DATASTORE_DATASETS_FETCH_REQUESTED = 'DATASTORE_DATASETS_FETCH_REQUESTED'
export const DATASTORE_DATASETS_FETCH_SUCCEEDED = 'DATASTORE_DATASETS_FETCH_SUCCEEDED'
export const DATASTORE_DATASETS_FETCH_FAILED = 'DATASTORE_DATASETS_FETCH_FAILED'

export const REFRESH_DATASETS_FETCH_REQUESTED = 'REFRESH_DATASETS_FETCH_REQUESTED'
export const REFRESH_DATASETS_FETCH_SUCCEEDED = 'REFRESH_DATASETS_FETCH_SUCCEEDED'
export const REFRESH_DATASETS_FETCH_FAILED = 'REFRESH_DATASETS_FETCH_FAILED'

export const DATASET_CALC_FIELD_COUNT_REQUESTED = 'DATASET_CALC_FIELD_COUNT_REQUESTED'
export const DATASET_CALC_FIELD_COUNT_SUCCEEDED = 'DATASET_CALC_FIELD_COUNT_SUCCEEDED'
export const DATASET_CALC_FIELD_COUNT_FAILED = 'DATASET_CALC_FIELD_COUNT_FAILED'

export const DATASET_REFRESH_MULTIPLE_DATES_REQUESTED = 'DATASET_REFRESH_MULTIPLE_DATES_REQUESTED'
export const DATASET_REFRESH_MULTIPLE_DATES_SUCCEEDED = 'DATASET_REFRESH_MULTIPLE_DATES_SUCCEEDED'
export const DATASET_REFRESH_MULTIPLE_DATES_FAILED = 'DATASET_REFRESH_MULTIPLE_DATES_FAILED'

export const DATASET_DISABLE_MULTIPLE_DATES_REQUESTED = 'DATASET_DISABLE_MULTIPLE_DATES_REQUESTED'

// Reducer
export default function datasetReducer(state = {}, action = {}) {
  switch (action.type) {
    case DATASETLIST_FETCH_REQUESTED: {
      const newState = {
        ...state,
        datasetsInfoStatus: {
          status: 'requested',
        },
      }
      return newState
    }
    case DATASETLIST_FETCH_SUCCEEDED: {
      const newState = {
        ...state,
        datasetsInfoStatus: action.payload,
      }
      return newState
    }
    case DATASETLIST_FETCH_FAILED: {
      const newState = {
        ...state,
        datasetsInfoStatus: {
          status: 'failed',
        },
      }
      return newState
    }
    case FAVORITE_DATASETLIST_FETCH_REQUESTED: {
      const newState = {
        ...state,
        favoriteDatasetsInfoStatus: {
          status: 'requested',
        },
      }
      return newState
    }
    case FAVORITE_DATASETLIST_FETCH_SUCCEEDED: {
      const newState = {
        ...state,
        favoriteDatasetsInfoStatus: action.payload,
      }
      return newState
    }
    case FAVORITE_DATASETLIST_FETCH_FAILED: {
      const newState = {
        ...state,
        favoriteDatasetsInfoStatus: {
          status: 'failed',
        },
      }
      return newState
    }
    case SEARCH_DATASETLIST_FETCH_REQUESTED: {
      const newState = {
        ...state,
        datasetsInfoStatus: {
          status: 'requested',
        },
      }
      return newState
    }
    case SEARCH_DATASETLIST_FETCH_SUCCEEDED: {
      const newState = {
        ...state,
        datasetsInfoStatus: action.payload,
      }
      return newState
    }
    case SEARCH_DATASETLIST_FETCH_FAILED: {
      const newState = {
        ...state,
        datasetsInfoStatus: {
          status: 'failed',
        },
      }
      return newState
    }
    case DATASETDETAIL_FETCH_REQUESTED: {
      const newState = {
        ...state,
        datasetDetailStatus: {
          status: 'requested',
        },
      }
      return newState
    }
    case DATASETDETAIL_FETCH_SUCCEEDED: {
      action.payload.data.fields_array = formatDatasetFields(action.payload.data.fields)
      delete action.payload.data.columns

      const newState = {
        ...state,
        datasetDetailStatus: action.payload,
      }
      return newState
    }
    case DATASETDETAIL_FETCH_FAILED: {
      const newState = {
        ...state,
        datasetDetailStatus: {
          status: 'failed',
        },
      }
      return newState
    }
    case DATASET_DELETE_REQUESTED: {
      const newState = {
        ...state,
        datasetDelStatus: {
          status: 'requested',
        },
      }
      return newState
    }
    case DATASET_DELETE_SUCCEEDED: {
      const newState = {
        ...state,
        datasetDelStatus: action.payload,
      }
      return newState
    }
    case DATASET_DELETE_FAILED: {
      const newState = {
        ...state,
        datasetDelStatus: {
          status: 'failed',
          response: action.payload.response,
        },
      }
      return newState
    }
    case DATASET_PREVIEW_REQUESTED: {
      const newState = {
        ...state,
        datasetPreviewStatus: {
          status: 'requested',
        },
      }
      return newState
    }
    case DATASET_PREVIEW_SUCCEEDED: {
      action.payload.data.fields_array = formatDatasetFields(action.payload.data.fields)
      delete action.payload.data.columns

      const newState = {
        ...state,
        datasetPreviewStatus: action.payload,
      }
      return newState
    }
    case CLEAR_DATASET_PREVIEW: {
      const newState = {
        ...state,
        datasetPreviewStatus: {},
      }
      return newState
    }
    case CLEAR_UPDATE_STATUS: {
      const newState = {
        ...state,
        updateDatasetStatus: {},
      }
      return newState
    }
    case DATASET_PREVIEW_FAILED: {
      const newState = {
        ...state,
        datasetPreviewStatus: {
          status: 'failed',
          message: action.payload.response,
        },
      }
      return newState
    }
    case DATASET_CALCFIELD_FETCH_REQUESTED: {
      const newState = {
        ...state,
        calcFieldInfoStatus: {
          status: 'requested',
        },
      }
      return newState
    }
    case DATASET_CALCFIELD_FETCH_SUCCEEDED: {
      const newState = {
        ...state,
        calcFieldInfoStatus: action.payload,
      }
      return newState
    }
    case DATASET_CALCFIELD_FETCH_FAILED: {
      const newState = {
        ...state,
        calcFieldInfoStatus: {
          status: 'failed',
        },
      }
      return newState
    }
    case CALCFIELD_DELETE_REQUESTED: {
      const updateReqState = {
        ...state,
        calcFieldDelStatus: {
          status: 'requested',
        },
      }
      return updateReqState
    }
    case CALCFIELD_DELETE_SUCCEEDED: {
      // Updating calc field list in store, when any calc field is deleted
      const calcFieldInfoStatus = cloneDeep(state.calcFieldInfoStatus)
      calcFieldInfoStatus.data = calcFieldInfoStatus.data.filter(row => row._id !== action.payload.config.data)
      const updateSuccState = {
        ...state,
        calcFieldInfoStatus,
        calcFieldDelStatus: action.payload,
      }
      return updateSuccState
    }
    case CALCFIELD_DELETE_FAILED: {
      const updateFailState = {
        ...state,
        calcFieldDelStatus: {
          status: 'failed',
        },
      }
      return updateFailState
    }
    case DATASET_EDIT_REQUESTED: {
      const newState = {
        ...state,
        updateDatasetStatus: {
          status: 'requested',
        },
      }
      return newState
    }
    case DATASET_EDIT_SUCCEEDED: {
      action.payload.data.fields_array = action.payload.data && formatDatasetFields(action.payload.data.fields)
      const newState = {
        ...state,
        updateDatasetStatus: action.payload,
        datasetPreviewStatus: action.payload,
      }
      return newState
    }
    case DATASET_EDIT_FAILED: {
      const newState = {
        ...state,
        updateDatasetStatus: {
          status: 'failed',
        },
      }
      return newState
    }

    case DATASET_PATCH_REQUESTED: {
      const newState = {
        ...state,
        updateDatasetStatus: {
          status: 'requested',
        },
      }
      return newState
    }
    case DATASET_PATCH_SUCCEEDED: {
      action.payload.data.fields_array = action.payload.data && formatDatasetFields(action.payload.data.fields)
      const newState = {
        ...state,
        updateDatasetStatus: action.payload,
        datasetPreviewStatus: action.payload,
      }
      return newState
    }
    case DATASET_PATCH_FAILED: {
      const newState = {
        ...state,
        updateDatasetStatus: {
          status: 'failed',
        },
      }
      return newState
    }

    case DATASTORES_FETCH_REQUESTED: {
      const newState = {
        ...state,
        datastoresListStatus: {
          status: 'requested',
        },
      }
      return newState
    }
    case DATASTORES_FETCH_SUCCEEDED: {
      const newState = {
        ...state,
        datastoresListStatus: action.payload,
      }
      return newState
    }
    case DATASTORES_FETCH_FAILED: {
      const newState = {
        ...state,
        datastoresListStatus: {
          status: 'failed',
          message: action.payload.response,
        },
      }
      return newState
    }

    case DATASTORE_DATASETS_FETCH_REQUESTED: {
      const newState = {
        ...state,
        datastoreDatasetsListStatus: {
          status: 'requested',
        },
      }
      return newState
    }
    case DATASTORE_DATASETS_FETCH_SUCCEEDED: {
      const newState = {
        ...state,
        datastoreDatasetsListStatus: action.payload,
      }
      return newState
    }
    case DATASTORE_DATASETS_FETCH_FAILED: {
      const newState = {
        ...state,
        datastoreDatasetsListStatus: {
          status: 'failed',
          message: action.payload.response,
        },
      }
      return newState
    }

    case REFRESH_DATASETS_FETCH_REQUESTED: {
      const newState = {
        ...state,
        refreshDatasetStatus: {
          status: 'requested',
        },
      }
      return newState
    }
    case REFRESH_DATASETS_FETCH_SUCCEEDED: {
      const newState = {
        ...state,
        refreshDatasetStatus: action.payload,
      }
      return newState
    }
    case REFRESH_DATASETS_FETCH_FAILED: {
      const newState = {
        ...state,
        refreshDatasetStatus: {
          status: 'failed',
          message: action.payload.response,
        },
      }
      return newState
    }

    case DATASET_CALC_FIELD_COUNT_REQUESTED: {
      const newState = {
        ...state,
        datasetCalcFieldCountStatus: {
          status: 'requested',
        },
      }
      return newState
    }
    case DATASET_CALC_FIELD_COUNT_SUCCEEDED: {
      const newState = {
        ...state,
        datasetCalcFieldCountStatus: action.payload,
      }
      return newState
    }
    case DATASET_CALC_FIELD_COUNT_FAILED: {
      const newState = {
        ...state,
        datasetCalcFieldCountStatus: {
          status: 'failed',
          message: action.payload.response,
        },
      }
      return newState
    }

    case DATASET_REFRESH_MULTIPLE_DATES_REQUESTED: {
      const newState = {
        ...state,
        datasetRefreshMultipleDatesStatus: {
          status: 'requested',
        },
      }
      return newState
    }
    case DATASET_REFRESH_MULTIPLE_DATES_SUCCEEDED: {
      const newState = {
        ...state,
        datasetRefreshMultipleDatesStatus: action.payload,
      }
      return newState
    }
    case DATASET_REFRESH_MULTIPLE_DATES_FAILED: {
      const newState = {
        ...state,
        datasetRefreshMultipleDatesStatus: {
          status: 'failed',
          message: action.payload.response,
        },
      }
      return newState
    }

    default:
      return state
  }
}

// Actions
export function getDatasetCalcFieldsCount(data) {
  return {
    type: DATASET_CALC_FIELD_COUNT_REQUESTED,
    payload: data,
  }
}

export function getDatasetList(data) {
  return {
    type: DATASETLIST_FETCH_REQUESTED,
    payload: data,
  }
}

export function getFavoriteDatasetList(data) {
  return {
    type: FAVORITE_DATASETLIST_FETCH_REQUESTED,
    payload: data,
  }
}

// Actions
export function getSearchDatasetList(data) {
  return {
    type: SEARCH_DATASETLIST_FETCH_REQUESTED,
    payload: data,
  }
}

export function getDatasetDetail(data) {
  return {
    type: DATASETDETAIL_FETCH_REQUESTED,
    payload: data,
  }
}

export function delDataset(data) {
  return {
    type: DATASET_DELETE_REQUESTED,
    payload: data,
  }
}

export function getDatasetPreview(data) {
  return {
    type: DATASET_PREVIEW_REQUESTED,
    payload: data,
  }
}

export function getDatasetCalcFields(data) {
  return {
    type: DATASET_CALCFIELD_FETCH_REQUESTED,
    payload: data,
  }
}

export function delCalculatedField(data) {
  return {
    type: CALCFIELD_DELETE_REQUESTED,
    payload: data,
  }
}

export function updateDatasetOnEdit(data) {
  return {
    type: DATASET_EDIT_REQUESTED,
    payload: data,
  }
}

export function clearDatasetPreview(data) {
  return {
    type: CLEAR_DATASET_PREVIEW,
    payload: data,
  }
}

export function clearUpdateStatus(data) {
  return {
    type: CLEAR_UPDATE_STATUS,
    payload: data,
  }
}

export function patchDataset(data) {
  return {
    type: DATASET_PATCH_REQUESTED,
    payload: data,
  }
}

export function getDatastoresList(data) {
  return {
    type: DATASTORES_FETCH_REQUESTED,
    payload: data,
  }
}

export function getDatastoreDatasetList(data) {
  return {
    type: DATASTORE_DATASETS_FETCH_REQUESTED,
    payload: data,
  }
}

export function refreshDataset(data) {
  return {
    type: REFRESH_DATASETS_FETCH_REQUESTED,
    payload: data,
  }
}

export function refreshDatasetMultipleDates(data) {
  return {
    type: DATASET_REFRESH_MULTIPLE_DATES_REQUESTED,
    payload: data,
  }
}

export function disableMultipleDates(data) {
  return {
    type: DATASET_DISABLE_MULTIPLE_DATES_REQUESTED,
    payload: data,
  }
}

// Sagas (services requests)
export function* fetchDatasetList(action) {
  try {
    const datsetList = yield call(axios, {
      method: 'get',
      url: `${API_GATEWAY_URL}/bi_reporting_assets/v2/datasets?page=${action.payload.pageIndex}&per_page=${
        action.payload.pageSize
      }&search_string=${encodeURIComponent(action.payload.searchString)}&sort=${encodeURIComponent(
        action.payload.sortColumn
      )}&field_groups=quick_metadata&search_in_user_data=${action.payload.searchInUserData}&tags=${
        action.payload.tags
      }`,
    })

    if (datsetList.error) {
      const e = datsetList.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }

    yield put({ type: DATASETLIST_FETCH_SUCCEEDED, payload: datsetList })
  } catch (e) {
    yield put({ type: DATASETLIST_FETCH_FAILED })
  }
}

export function* fetchFavoriteDatasetList(action) {
  try {
    const datsetList = yield call(axios, {
      method: 'get',
      url: `${API_GATEWAY_URL}/bi_reporting_assets/v2/datasets?search_in_user_data=${action.payload.searchInUserData}&favorites_only=yes`,
    })

    if (datsetList.error) {
      const e = datsetList.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }

    yield put({ type: FAVORITE_DATASETLIST_FETCH_SUCCEEDED, payload: datsetList })
  } catch (e) {
    yield put({ type: FAVORITE_DATASETLIST_FETCH_FAILED })
  }
}

export function* searchDatasetList(action) {
  try {
    const datsetList = yield call(axios, {
      method: 'get',
      url: `${API_GATEWAY_URL}/bi_reporting_assets/v2/datasets?page=${
        action.payload.pageIndex
      }&search_string=${encodeURIComponent(action.payload.searchString)}&per_page=${
        action.payload.pageSize
      }&search_in_user_data=${action.payload.searchInUserData}&sort=${encodeURIComponent(
        action.payload.sortColumn
      )}&tags=${action.payload.tags}`,
    })

    if (datsetList.error) {
      const e = datsetList.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }

    yield put({ type: SEARCH_DATASETLIST_FETCH_SUCCEEDED, payload: datsetList })
  } catch (e) {
    yield put({ type: SEARCH_DATASETLIST_FETCH_FAILED })
  }
}

export function* fetchDatasetDetail(action) {
  try {
    const dataset = yield call(axios, {
      method: 'get',
      url: `${API_GATEWAY_URL}/bi_reporting_assets/v2/datasets/${action.payload.datasetId}`,
    })

    if (dataset.error) {
      const e = dataset.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }

    yield put({ type: DATASETDETAIL_FETCH_SUCCEEDED, payload: dataset })
  } catch (e) {
    yield put({ type: DATASETDETAIL_FETCH_FAILED })
  }
}

export function* deleteDataset(action) {
  try {
    const delDataset = yield call(axios, {
      method: 'delete',
      url: `${API_GATEWAY_URL}/bi_reporting_assets/v2/datasets/${action.payload.delDataset}`,
    })
    if (delDataset.error) {
      const e = delDataset.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }

    yield put({ type: 'DATASET_DELETE_SUCCEEDED', payload: delDataset })
  } catch (e) {
    yield put({ type: 'DATASET_DELETE_FAILED', payload: e })
  }
}

export function* fetchDatasetPreview(action) {
  try {
    const datsetPreview = yield call(axios, {
      method: 'get',
      url: `${API_GATEWAY_URL}/bi_reporting_assets/v2/preview_datasets/${action.payload.datasetId}`,
    })

    if (datsetPreview.error) {
      const e = datsetPreview.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }
    yield put({ type: DATASET_PREVIEW_SUCCEEDED, payload: datsetPreview })
  } catch (error) {
    yield put({ type: DATASET_PREVIEW_FAILED, payload: error })
  }
}

export function* fetchDatasetCalcFields(action) {
  try {
    const calcFields = yield call(axios, {
      method: 'get',
      url: `${API_GATEWAY_URL}/bi_reporting_assets/v2/calculated_metrics?${
        action.payload.datasetId ? `dataset_id=${action.payload.datasetId}` : ''
      }${action.payload.ownerOnly ? 'owner_only=yes' : ''}`,
    })

    if (calcFields.error) {
      const e = calcFields.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }
    yield put({ type: DATASET_CALCFIELD_FETCH_SUCCEEDED, payload: calcFields })
  } catch (e) {
    // todo: this doesn't seem to do anything on the UI.  Not really sure where this fits into this application.
    yield put({ type: DATASET_CALCFIELD_FETCH_FAILED })
  }
}

export function* deleteCalculatedField(action) {
  try {
    const delCalcField = yield call(axios, {
      method: 'delete',
      url: `${API_GATEWAY_URL}/bi_reporting_assets/v2/calculated_metrics/${action.payload.delCalcField}`,
    })
    if (delCalcField.error) {
      const e = delCalcField.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }

    yield put({ type: 'CALCFIELD_DELETE_SUCCEEDED', payload: delCalcField })
  } catch (e) {
    yield put({ type: 'CALCFIELD_DELETE_FAILED' })
  }
}

export function* updateDataset(action) {
  try {
    const editCal = yield call(axios, {
      method: 'put',
      url: `${API_GATEWAY_URL}/bi_reporting_assets/v2/datasets/${action.payload._id}`,
      data: action.payload,
    })

    if (editCal.error) {
      const error = editCal.error
      throw new Error(`status: ${error.code}, ${error.type} - ${error.message}`)
    }

    yield put({ type: DATASET_EDIT_SUCCEEDED, payload: editCal })
  } catch (error) {
    yield put({ type: DATASET_EDIT_FAILED, payload: error })
  }
}

export function* editDataset(action) {
  try {
    const editCal = yield call(axios, {
      method: 'patch',
      url: `${API_GATEWAY_URL}/bi_reporting_assets/v2/datasets/${action.payload._id}`,
      data: action.payload,
    })

    if (editCal.error) {
      const error = editCal.error
      throw new Error(`status: ${error.code}, ${error.type} - ${error.message}`)
    }

    yield put({ type: DATASET_PATCH_SUCCEEDED, payload: editCal })
  } catch (error) {
    yield put({ type: DATASET_PATCH_FAILED, payload: error })
  }
}

export function* fetchDatastoresList() {
  try {
    const datastores = yield call(axios, {
      method: 'get',
      url: `${API_GATEWAY_URL}/bi_reporting_assets/v2/datastores?datastore_type=postgres`,
    })

    if (datastores.error) {
      const e = datastores.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }
    yield put({ type: DATASTORES_FETCH_SUCCEEDED, payload: datastores })
  } catch (error) {
    yield put({ type: DATASTORES_FETCH_FAILED, payload: error })
  }
}

export function* fetchDatasetRelatedToDatastore(action) {
  try {
    const datastoreDatasets = yield call(axios, {
      method: 'get',
      url: `${API_GATEWAY_URL}/bi_reporting_assets/v2/datasets?field_groups=quick_metadata&datastore_id=${action.payload._id}&search_string=${action.payload.searchValue}`,
    })

    if (datastoreDatasets.error) {
      const e = datastoreDatasets.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }
    yield put({ type: DATASTORE_DATASETS_FETCH_SUCCEEDED, payload: datastoreDatasets })
  } catch (error) {
    yield put({ type: DATASTORE_DATASETS_FETCH_FAILED, payload: error })
  }
}

export function* sendRefreshDataset(action) {
  try {
    const refreshDataset = yield call(axios, {
      method: 'post',
      url: `${API_GATEWAY_URL}/bi_reporting_assets/v2/refresh_datasets?invalidate_cache=${
        action.payload.invalidate_cache || false
      }&generate_event=${action.payload.generate_event || false}`,
      data: { greenfield_platform: action.payload.greenfield_platform, dataset_name: action.payload.dataset_name },
    })

    if (refreshDataset.error) {
      const e = refreshDataset.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }

    yield put({ type: REFRESH_DATASETS_FETCH_SUCCEEDED, payload: refreshDataset })
  } catch (error) {
    yield put({ type: REFRESH_DATASETS_FETCH_FAILED, payload: error })
  }
}

export function* fetchDatasetCalcFieldsCount(action) {
  try {
    const datasetCalcFieldsCount = yield call(axios, {
      method: 'get',
      url: `${API_GATEWAY_URL}/bi_reporting_assets/v2/calculated_metrics/count?dataset_id=${action.payload.datasetId}&status=active`,
    })

    if (datasetCalcFieldsCount.error) {
      const e = datasetCalcFieldsCount.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }
    yield put({ type: DATASET_CALC_FIELD_COUNT_SUCCEEDED, payload: datasetCalcFieldsCount })
  } catch (e) {
    yield put({ type: DATASET_CALC_FIELD_COUNT_FAILED })
  }
}

export function* updateDatasetMultipleDates(action) {
  try {
    const mdResponse = yield call(axios, {
      method: 'post',
      url: `${API_GATEWAY_URL}/bi_reporting_assets/v2/datasets/${action.payload._id}/fields?auto_detect=true`,
      data: action.payload,
    })

    if (mdResponse.error) {
      const error = mdResponse.error
      throw new Error(`status: ${error.code}, ${error.type} - ${error.message}`)
    }

    yield put({ type: DATASET_REFRESH_MULTIPLE_DATES_SUCCEEDED, payload: mdResponse })
  } catch (error) {
    yield put({ type: DATASET_REFRESH_MULTIPLE_DATES_FAILED, payload: error })
  }
}

export function* updateDisableMultipleDates(action) {
  yield call(axios, {
    method: 'post',
    url: `${API_GATEWAY_URL}/bi_reporting_assets/v2/datasets/${action.payload._id}/fields?auto_detect=false`,
    data: action.payload,
  })
}
