import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { updateFavorite } from '../../../ducks/user'
import FavoriteButton from './FavoriteButton'

export class GreenfieldFavoriteButton extends React.Component {
  state = {
    icon: this.props.isFavorite === 'yes' ? 'star' : 'star_border',
  }

  /* eslint-disable camelcase */
  UNSAFE_componentWillReceiveProps(nextProps) {
    /* eslint-enable camelcase */
    this.setState({
      icon: nextProps.isFavorite === 'yes' ? 'star' : 'star_border',
    })
  }

  handleClick = oldIcon => {
    const icon = oldIcon === 'star_border' ? 'star' : 'star_border'
    const favorite = icon === 'star_border' ? 'no' : 'yes'
    this.setState({ icon })
    this.props.updateFavorite({
      object_type: this.props.type,
      object_id: this.props.id,
      is_favorite: favorite,
    })
  }

  render() {
    const { icon } = this.state
    return <FavoriteButton icon={icon} onClick={this.handleClick} elementId={this.props.elementId} />
  }
}

const mapStateToProps = () => ({})

export const mapDispatchToProps = dispatch => ({
  updateFavorite(data) {
    dispatch(updateFavorite(data))
  },
})

GreenfieldFavoriteButton.propTypes = {
  type: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  isFavorite: PropTypes.string.isRequired,
  elementId: PropTypes.any,
}

export default connect(mapStateToProps, mapDispatchToProps)(GreenfieldFavoriteButton)
