import React, { useEffect, useState } from 'react'
import axios from 'axios'
import moment from 'moment'
import {
  Typography,
  Grid,
  Card,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  Popover,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@mui/material'
import { Link } from 'react-router-dom'
import { Box } from '@mui/system'
import styled from 'styled-components'
import { getBusinessInfoChip, getOwnerInfoChip } from '../../../shared/AgGrid/AgGridHelper'
import { API_GATEWAY_URL } from '../../../../ducks/utils'

export const SCard = styled(Card)`
  margin: 10px;
  padding: 0px;
  width: 120px;
  height: 127px;
  text-align: center;
`

export const STypography = styled(Typography)`
  font-size: 15px;
  font-weight: 400;
  background: #4dc07d;
  height: 86px;
  padding-top: 20px;
  margin: 0px;
`

export const SCountTypography = styled(Typography)`
  font-size: 28px;
  font-weight: 400;
`

export const CardDetailComponent = ({ rowData }) => {
  const cardId = rowData._id
  const [cardDetail, setCardDetail] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const cardData = rowData.card_attribute

  useEffect(() => {
    setIsLoading(true)
    axios.get(`${API_GATEWAY_URL}/bi_reporting_assets/v3/cards/${cardId}`).then(response => {
      setCardDetail(response.data)
      setIsLoading(false)
    })
  }, [cardId])

  const handleShowDashboardCardsClick = event => {
    setAnchorEl(event.currentTarget)
  }

  return isLoading ? (
    <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 300 }}>
      <CircularProgress color="inherit" />
    </Box>
  ) : (
    <>
      <Grid className="cardRowExpand" container direction="row" justifyContent="space-evenly" alignItems="flex-start">
        <Grid item xs={6}>
          <GridItem primary="Card ID" secondary={rowData._id} />
          <GridItem primary="Dataset" secondary={getDatasetLink(cardData.dataset_id, cardData.dataset_name)} />
          {cardData.business_use.length > 0 && (
            <GridItem
              primary="Business Area(s)"
              secondary={<Box style={{ fontWeight: 'bold' }}>{getBusinessInfoChip(cardData.business_use)}</Box>}
            />
          )}
          <GridItem
            primary="Owner(s)*"
            secondary={<Box style={{ fontWeight: 'bold' }}>{getOwnerInfoChip(cardData.owners_access)}</Box>}
          />
        </Grid>
        <Grid item xs={4}>
          {cardData.card_description && <GridItem primary="Card Description" secondary={cardData.card_description} />}
          <GridItem primary="Data Classification" secondary={cardData.data_classification} />
          <GridItem primary="Created Date" secondary={moment(cardData.create_date).format('MMMM Do YYYY, h:mm:ss a')} />
          <GridItem
            primary="Last Updated"
            secondary={moment(cardData.last_updated_date).format('MMMM Do YYYY, h:mm:ss a')}
          />
        </Grid>
        <Grid item xs={2}>
          <Grid container direction="column">
            <SCard>
              <SCountTypography>{cardData.view_count}</SCountTypography>
              <STypography>Views</STypography>
            </SCard>
            <SCard>
              <SCountTypography>{cardDetail.dashboards_containing_card?.length || 0}</SCountTypography>
              <STypography
                onClick={cardDetail.dashboards_containing_card?.length ? handleShowDashboardCardsClick : () => {}}
                style={
                  cardDetail.dashboards_containing_card?.length
                    ? {
                        textDecoration: 'underline',
                        cursor: 'pointer',
                      }
                    : {}
                }
              >
                Dashboards
              </STypography>
            </SCard>
          </Grid>
        </Grid>
      </Grid>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Dashboard Name</TableCell>
              <TableCell>Dashboard Id</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {cardDetail.dashboards_containing_card?.map(dashboard => (
              <TableRow key={dashboard.dashboard_id}>
                <TableCell>
                  <Link
                    target="_blank"
                    rel="noopener noreferrer"
                    to={`/dashboard/${dashboard.dashboard_id}`}
                    variant="subtitle1"
                  >
                    {dashboard.dashboard_title}
                  </Link>
                </TableCell>
                <TableCell>{dashboard.dashboard_id}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Popover>
    </>
  )
}

const getDatasetLink = (id, name) => (
  <Link
    data-cy={`card-name-${name}`}
    className="edit-card-link"
    target="_blank"
    rel="noopener noreferrer"
    to={`/dataset/preview/${id}`}
  >
    {name}
  </Link>
)

const GridItem = ({ primary, secondary }) => {
  return (
    <List>
      <ListItem>
        <ListItemText
          className="card-list-item"
          primary={<Typography variant="subtitle1">{primary}</Typography>}
          secondary={
            <Typography
              variant="body1"
              component="span"
              data-cy={`card-list-item-${primary}`}
              style={{ wordBreak: 'break-word', whiteSpace: 'pre-wrap' }}
            >
              {secondary}
            </Typography>
          }
        />
      </ListItem>
    </List>
  )
}
