import React from 'react' // eslint-disable-line
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'
import urlon from 'urlon'

import {
  getViewDashboardInfo,
  delDashboard,
  clearViewDashboardInfo,
  restoreDashboard,
  updateViewDashboardInfo,
} from '../../../../ducks/dashboard'
import { saveDashboard } from '../../../../ducks/builder'
import { clearCardInfo } from '../../../../ducks/cards'
import { updateAppliedFilters, clearAllFilters } from '../../../../ducks/filter'
import { displayServiceErrorMessage } from '../../../../ducks/layout'
import { clearTabCardsPayload, clearSavedCards } from '../../../../ducks/cardPreview'
import {
  untruncateFiltersForDashboards,
  getDashboardAppliedFilterArrForSavedFilters,
} from '../../../shared/FilterViewer/filter-utils'
import {
  getObjectNot,
  getNotificationCount,
  clearNotificationCache,
  clearDeleteObjectNotificationStatus,
} from '../../../../ducks/notification'
import Dashboardviewer from './Dashboardviewer'

export class DashboardviewerContainer extends React.Component {
  state = {
    metadataMoreExpand: false,
    activeTab:
      Number(this.props.activeTabIndex) ||
      (this.props.routeProps.match.params._siteName && this.props.routeProps.match.params._tabName
        ? Number(this.props.routeProps.match.params._index)
        : Number(this.props.routeProps.match.params._tab)),
    timePeriod: {},
    selectedGranularity: '',
    isBadShortUrl: false,
    selectedDashboardTimePeriod: null,
  }

  componentDidMount() {
    const { getViewDashboardInfo, updateAppliedFilters, dashboardId, routeProps } = this.props

    this.props.getObjectNot({
      objectId: dashboardId || routeProps.match.params._id,
      type: 'dashboard',
    })
    getViewDashboardInfo(dashboardId || routeProps.match.params._id)

    const urlState = `${routeProps.location.search}${routeProps.location.hash}`

    try {
      const processedUrlState = urlState.slice(1, urlState.length)
      const decodedUrlState = processedUrlState ? urlon.parse(processedUrlState) : null

      if (decodedUrlState?.filters) {
        updateAppliedFilters(untruncateFiltersForDashboards(decodedUrlState.filters))
      }

      if (decodedUrlState?.timePeriod) {
        this.setState({
          timePeriod: decodedUrlState.timePeriod,
          selectedGranularity: decodedUrlState.timePeriod.granularity || '',
        })
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e, 'caught error in Dashboardviewer container on mount')
    }
  }

  componentDidUpdate(prevProps) {
    const {
      viewDashBoardInfoStatus,
      saveDashboardStatus,
      getViewDashboardInfo,
      displayServiceErrorMessage,
      routeProps,
      setHeaderTitle,
      updateAppliedFilters,
      isHomePage,
      siteObj,
      deleteObjectNotificationStatus,
      clearDeleteObjectNotificationStatus,
      dashboardId,
    } = this.props
    const { params } = routeProps.match

    const urlState = `${routeProps.location.search}${routeProps.location.hash}`
    const prevUrlState = `${prevProps.routeProps.location.search}${prevProps.routeProps.location.hash}`

    // updates to timeperiod or filters in the url
    if (urlState !== prevUrlState) {
      try {
        const processedUrlState = urlState.slice(1, urlState.length)
        const processedPrevUrlState = prevUrlState ? prevUrlState.slice(1, prevUrlState.length) : ''
        const decodedUrlState = urlon.parse(processedUrlState || '$')
        const decodedPrevUrlState = processedPrevUrlState ? urlon.parse(processedPrevUrlState) : ''

        if (!isEqual(decodedUrlState.filters, decodedPrevUrlState.filters)) {
          updateAppliedFilters(untruncateFiltersForDashboards(decodedUrlState.filters || []))
        }

        if (!isEqual(decodedUrlState.timePeriod, decodedPrevUrlState.timePeriod)) {
          this.setState({
            timePeriod: decodedUrlState.timePeriod || {},
            selectedGranularity: decodedUrlState.timePeriod?.granularity || '',
          })
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e, 'caught error in Dashboardviewer container updating URL')
      }
    }

    // This is for when saveas goes through
    if (saveDashboardStatus && !isEqual(saveDashboardStatus, prevProps.saveDashboardStatus)) {
      if (saveDashboardStatus.status >= 200 && saveDashboardStatus.status < 300) {
        getViewDashboardInfo(saveDashboardStatus.data._id)
      } else if (saveDashboardStatus.status === 'failed') {
        displayServiceErrorMessage('The request to save dashboard has failed.')
      }
    }

    if (
      params?._tab !== prevProps.routeProps.match.params?._tab ||
      params?._index !== prevProps.routeProps.match.params?._index
    ) {
      this.setState({
        activeTab: params?._siteName && params?._tabName ? Number(params._index) : Number(params._tab),
      })
    }

    if (
      prevProps.viewDashBoardInfoStatus.status === 'requested' &&
      viewDashBoardInfoStatus &&
      viewDashBoardInfoStatus.status >= 200 &&
      viewDashBoardInfoStatus.status < 300 &&
      viewDashBoardInfoStatus.status?.state !== 'disabled'
    ) {
      if (viewDashBoardInfoStatus.data?.cards) {
        const cardList = viewDashBoardInfoStatus.data.cards.map(item => item.card_id) || []
        const datasetList = [...new Set(viewDashBoardInfoStatus.data.cards.map(item => item.dataset_id))]

        this.props.getNotificationCount({
          cardId: cardList.join(','),
          datasetId: datasetList.join(','),
        })
      }

      const { _siteName } = routeProps.match.params
      if (_siteName && siteObj && siteObj.status >= 200 && siteObj.status < 300) {
        setHeaderTitle(siteObj?.data?.name)
      } else {
        setHeaderTitle(viewDashBoardInfoStatus.data.dashboard_title, true)
      }

      const savedFilters = viewDashBoardInfoStatus.data.saved_filters
      const dashboardFilters = viewDashBoardInfoStatus.data.filters

      const newUrlState = {}

      try {
        const processedUrlState = urlState.slice(1, urlState.length)
        const decodedUrlState = processedUrlState && urlon.parse(processedUrlState)
        const processedPrevUrlState = prevUrlState.slice(1, prevUrlState.length)
        const decodedPrevUrlState = processedPrevUrlState && urlon.parse(processedPrevUrlState)

        if (Object.keys(viewDashBoardInfoStatus.data.time_period).length && !decodedPrevUrlState?.timePeriod) {
          newUrlState.timePeriod = viewDashBoardInfoStatus.data.time_period
        } else {
          newUrlState.timePeriod = decodedPrevUrlState?.timePeriod
        }

        if (decodedUrlState.filters) {
          newUrlState.filters = decodedUrlState.filters
        } else if (savedFilters?.length) {
          newUrlState.filters = getDashboardAppliedFilterArrForSavedFilters(savedFilters, dashboardFilters)
        }

        const qs = Object.keys(newUrlState).length ? urlon.stringify(newUrlState) : ''

        if (Number(params?._tab) + 1 > viewDashBoardInfoStatus.data.tabs?.length) {
          // if a route tries to go to a tab that is out of range, go back to 1st tab
          routeProps.history.replace(`/dashboard/${params._id}/0?${qs}`)
        } else if (params._tab !== 'print') {
          // add default time period to links without a querystring or links without a timePeriod in its querystring
          if (routeProps.location.search === '?$invalidShortUrl=true') {
            this.setState(
              {
                isBadShortUrl: true,
              },
              () => {
                this.setState({
                  isBadShortUrl: false,
                })
              }
            )
          }

          if (params._siteName && params._tabName) {
            routeProps.history.replace(`/site/${params._siteName}/${params._tabName}/${params._index}?${qs}`)
          } else if (params._siteName) {
            routeProps.history.replace(`/site/${params._siteName}/dashboard/${params._id}/${params._tab}?${qs}`)
          } else {
            routeProps.history.replace(`/${isHomePage ? 'favorites' : 'dashboard'}/${params._id}/${params._tab}?${qs}`)
          }
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err, 'caught err in decoding url state on mount of viewdashboard container')
      }
    }

    if (
      deleteObjectNotificationStatus &&
      deleteObjectNotificationStatus.status >= 200 &&
      deleteObjectNotificationStatus.status < 300 &&
      !isEqual(deleteObjectNotificationStatus.status, prevProps.deleteObjectNotificationStatus.status)
    ) {
      clearDeleteObjectNotificationStatus()

      this.props.getObjectNot({
        objectId: dashboardId || routeProps.match.params._id,
        type: 'dashboard',
      })
    } else if (deleteObjectNotificationStatus?.status === 'failed') {
      displayServiceErrorMessage('Notification Delete Failed')
    }
  }

  componentWillUnmount() {
    const { appliedFilters, clearSavedCards, updateAppliedFilters } = this.props

    if (appliedFilters && appliedFilters.length) {
      updateAppliedFilters([])
    }

    clearSavedCards()
  }

  handleCalledCardData = () => {
    this.setState({
      calledCardData: !this.state.calledCardData,
    })
  }

  handleUpdateCardDataLoaded = () => {
    this.setState({
      cardDataLoaded: !this.state.cardDataLoaded,
    })
  }

  handleTimePeriodChange = timePeriodObj => {
    const { selectedGranularity } = this.state
    const { isMobile } = this.props
    const { granularity } = timePeriodObj
    const currentGranularity = granularity || selectedGranularity

    this.setState(
      {
        selectedDashboardTimePeriod: timePeriodObj,
      },
      () => {
        if (isMobile) {
          this.handleApplyDashboardTimePeriod()
        }
      }
    )
    if (currentGranularity) {
      this.setState({
        selectedGranularity: currentGranularity,
      })
    }
  }

  handleApplyDashboardTimePeriod = () => {
    const { selectedDashboardTimePeriod } = this.state
    const isBadFiscalObj =
      Object.keys(selectedDashboardTimePeriod).length === 1 && selectedDashboardTimePeriod.calendar_type
    const { routeProps } = this.props
    const urlState = `${routeProps.location.search}${routeProps.location.hash}`
    const { selectedGranularity } = this.state
    const { granularity } = selectedDashboardTimePeriod
    const currentGranularity = granularity || selectedGranularity
    let decodedUrlState

    if (isBadFiscalObj) {
      return
    }
    try {
      const slicedUrlState = urlState.slice(1, urlState.length)
      decodedUrlState = slicedUrlState ? urlon.parse(slicedUrlState) : {}
      decodedUrlState.timePeriod = selectedDashboardTimePeriod
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err, 'caught err in decodeding url state on change of timeperiod')
    }

    if (currentGranularity) {
      decodedUrlState.timePeriod.granularity = currentGranularity
    }
    this.setState(
      {
        selectedDashboardTimePeriod: null,
      },
      () => {
        const _dashboardInfo = cloneDeep(this.props.viewDashBoardInfoStatus)
        if (_dashboardInfo && _dashboardInfo.data && decodedUrlState) {
          _dashboardInfo.data.time_period = decodedUrlState.timePeriod
          this.props.updateViewDashboardInfo(_dashboardInfo)
        }
        routeProps.history.push(`${routeProps.match.url}${`?${urlon.stringify(decodedUrlState)}`}`)
      }
    )
  }

  updateSelectedDashTimePeriod = timePeriodObj => {
    this.setState({
      selectedDashboardTimePeriod: timePeriodObj,
    })
  }

  handleDashTimePeriodClose = () => {
    this.updateSelectedDashTimePeriod(null)
  }

  render() {
    return (
      <Dashboardviewer
        timePeriod={this.state.timePeriod}
        onTimePeriodChange={this.handleTimePeriodChange}
        displayServiceErrorMessage={this.props.displayServiceErrorMessage}
        pageData={this.props.viewDashBoardInfoStatus}
        activeTab={this.state.activeTab}
        routeProps={this.props.routeProps}
        delDashboard={this.props.delDashboard}
        dashboardDelStatus={this.props.dashboardDelStatus}
        isMobile={this.props.isMobile}
        saveDashboard={this.props.saveDashboard}
        clearViewDashboardInfo={this.props.clearViewDashboardInfo}
        clearCardInfo={this.props.clearCardInfo}
        deviceType={this.props.deviceType}
        clearTabCardsPayload={this.props.clearTabCardsPayload}
        updateAppliedFilters={this.props.updateAppliedFilters}
        clearAllFilters={this.props.clearAllFilters}
        dashboardId={this.props.dashboardId}
        isHomePage={this.props.isHomePage}
        restoreDashboard={this.props.restoreDashboard}
        isBadShortUrl={this.state.isBadShortUrl}
        selectedDashboardTimePeriod={this.state.selectedDashboardTimePeriod}
        updateSelectedDashTimePeriod={this.updateSelectedDashTimePeriod}
        onApplyDashboardTimePeriod={this.handleApplyDashboardTimePeriod}
        onHandleDashTimePeriodClose={this.handleDashTimePeriodClose}
        objectNotificationState={this.props.objectNotificationState}
        getNotificationCountStatus={this.props.getNotificationCountStatus}
        clearNotificationCache={this.props.clearNotificationCache}
      />
    )
  }
}

const mapStateToProps = state => ({
  viewDashBoardInfoStatus: state.dashboard.viewDashBoardInfoStatus,
  dashboardDelStatus: state.dashboard.dashboardDelStatus,
  saveDashboardStatus: state.builder.saveDashboardStatus,
  appliedFilters: state.filter.appliedFilters,
  siteObj: state.site.siteStatus,
  objectNotificationState: state.notification.getObjectNotificationStatus,
  getNotificationCountStatus: state.notification.getNotificationCountStatus,
  deleteObjectNotificationStatus: state.notification.deleteObjectNotificationStatus,
})

export const mapDispatchToProps = dispatch => ({
  displayServiceErrorMessage(data) {
    dispatch(displayServiceErrorMessage(data))
  },
  getViewDashboardInfo(data) {
    dispatch(getViewDashboardInfo(data))
  },
  delDashboard(data) {
    dispatch(delDashboard(data))
  },
  saveDashboard(data) {
    dispatch(saveDashboard(data))
  },
  updateAppliedFilters(data) {
    dispatch(updateAppliedFilters(data))
  },
  clearViewDashboardInfo(data) {
    dispatch(clearViewDashboardInfo(data))
  },
  clearCardInfo(data) {
    dispatch(clearCardInfo(data))
  },
  clearTabCardsPayload(data) {
    dispatch(clearTabCardsPayload(data))
  },
  clearAllFilters() {
    dispatch(clearAllFilters())
  },
  clearSavedCards() {
    dispatch(clearSavedCards())
  },
  restoreDashboard(data) {
    dispatch(restoreDashboard(data))
  },
  updateViewDashboardInfo(data) {
    dispatch(updateViewDashboardInfo(data))
  },
  getObjectNot(data) {
    dispatch(getObjectNot(data))
  },
  getNotificationCount(data) {
    dispatch(getNotificationCount(data))
  },
  clearNotificationCache(data) {
    dispatch(clearNotificationCache(data))
  },
  clearDeleteObjectNotificationStatus(data) {
    dispatch(clearDeleteObjectNotificationStatus(data))
  },
})

DashboardviewerContainer.defaultProps = {
  viewDashBoardInfoStatus: {},
  getViewDashboardInfo: () => {},
  delDashboard: () => {},
  displayServiceErrorMessage: () => {},
  timePeriodViewer: {},
  saveDashboardStatus: {},
}

DashboardviewerContainer.propTypes = {
  viewDashBoardInfoStatus: PropTypes.object,
  getViewDashboardInfo: PropTypes.func,
  routeProps: PropTypes.object,
  delDashboard: PropTypes.func,
  displayServiceErrorMessage: PropTypes.func,
  appliedFilters: PropTypes.array,
  updateAppliedFilters: PropTypes.func,
  timePeriodViewer: PropTypes.object,
  activeTabIndex: PropTypes.string,
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardviewerContainer)
