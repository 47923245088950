import { call, put } from 'redux-saga/effects'
import axios from 'axios'
import cloneDeep from 'lodash/cloneDeep'
import { API_GATEWAY_URL } from './utils'

export const DASHBOARD_BUSINESSAREA_TAGS_REQUESTED = 'DASHBOARD_BUSINESSAREA_TAGS_REQUESTED'
export const DASHBOARD_BUSINESSAREA_TAGS_SUCCEEDED = 'DASHBOARD_BUSINESSAREA_TAGS_SUCCEEDED'
export const DASHBOARD_BUSINESSAREA_TAGS_FAILED = 'DASHBOARD_BUSINESSAREA_TAGS_FAILED'
export const USER_DASHBOARD_BUSINESSAREA_TAGS_REQUESTED = 'USER_DASHBOARD_BUSINESSAREA_TAGS_REQUESTED'
export const USER_DASHBOARD_BUSINESSAREA_TAGS_SUCCEEDED = 'USER_DASHBOARD_BUSINESSAREA_TAGS_SUCCEEDED'
export const USER_DASHBOARD_BUSINESSAREA_TAGS_FAILED = 'USER_DASHBOARD_BUSINESSAREA_TAGS_FAILED'
export const USER_DASHBOARD_BUSINESSAREA_TAGS_TOGGLE = 'USER_DASHBOARD_BUSINESSAREA_TAGS_TOGGLE'
export const SAVE_USER_QUICKLINKS_REQUESTED = 'SAVE_USER_QUICKLINKS_REQUESTED'
export const SAVE_USER_QUICKLINKS_SUCCEEDED = 'SAVE_USER_QUICKLINKS_SUCCEEDED'
export const SAVE_USER_QUICKLINKS_FAILED = 'SAVE_USER_QUICKLINKS_FAILED'
export const SAVE_USER_QUICKLINKS_CLEAR_STATE = 'SAVE_USER_QUICKLINKS_CLEAR_STATE'
export const SAVE_GROUP_QUICKLINKS_REQUESTED = 'SAVE_GROUP_QUICKLINKS_REQUESTED'
export const SAVE_GROUP_QUICKLINKS_SUCCEEDED = 'SAVE_GROUP_QUICKLINKS_SUCCEEDED'
export const SAVE_GROUP_QUICKLINKS_FAILED = 'SAVE_GROUP_QUICKLINKS_FAILED'
export const SAVE_GROUP_QUICKLINKS_CLEAR_STATE = 'SAVE_GROUP_QUICKLINKS_CLEAR_STATE'

export default function quicklinksReducer(state = {}, action = {}) {
  switch (action.type) {
    case DASHBOARD_BUSINESSAREA_TAGS_REQUESTED: {
      const newState = {
        ...state,
        dashboardBusinessTagInfoStatus: {
          status: 'requested',
        },
      }
      return newState
    }
    case DASHBOARD_BUSINESSAREA_TAGS_SUCCEEDED: {
      const newStateSuccessful = {
        ...state,
        dashboardBusinessTagInfoStatus: action.payload,
      }
      return newStateSuccessful
    }
    case DASHBOARD_BUSINESSAREA_TAGS_FAILED: {
      const newState = {
        ...state,
        dashboardBusinessTagInfoStatus: {
          status: 'failed',
        },
      }
      return newState
    }
    case USER_DASHBOARD_BUSINESSAREA_TAGS_REQUESTED: {
      const newUserStateRequested = {
        ...state,
        userDashboardBusinessTagInfoStatus: {
          status: 'requested',
        },
      }
      return newUserStateRequested
    }
    case USER_DASHBOARD_BUSINESSAREA_TAGS_SUCCEEDED: {
      const newUserStateSuccessful = {
        ...state,
        userDashboardBusinessTagInfoStatus: action.payload,
      }
      return newUserStateSuccessful
    }
    case USER_DASHBOARD_BUSINESSAREA_TAGS_FAILED: {
      const newState = {
        ...state,
        userDashboardBusinessTagInfoStatus: {
          status: 'failed',
        },
      }
      return newState
    }
    case USER_DASHBOARD_BUSINESSAREA_TAGS_TOGGLE: {
      const newToggleBusinessTags = cloneDeep(state.userDashboardBusinessTagInfoStatus)
      newToggleBusinessTags.data.all_tags.map((item, index) => {
        if (index === action.payload) {
          item.subMenuOpen = !item.subMenuOpen
        }

        return item
      })
      const newToggledState = {
        ...state,
        userDashboardBusinessTagInfoStatus: newToggleBusinessTags,
      }

      return newToggledState
    }
    case SAVE_USER_QUICKLINKS_REQUESTED: {
      const newSaveUserQuicklinksRequestedState = {
        ...state,
        saveUserQuickLinksInfoStatus: {
          status: 'requested',
        },
      }
      return newSaveUserQuicklinksRequestedState
    }
    case SAVE_USER_QUICKLINKS_SUCCEEDED: {
      const newSaveUserQuicklinksSucceededState = {
        ...state,
        saveUserQuickLinksInfoStatus: action.payload,
      }
      return newSaveUserQuicklinksSucceededState
    }
    case SAVE_USER_QUICKLINKS_FAILED: {
      const newSaveUserQuicklinksFailedState = {
        ...state,
        saveUserQuickLinksInfoStatus: {
          status: 'failed',
        },
      }
      return newSaveUserQuicklinksFailedState
    }
    case SAVE_USER_QUICKLINKS_CLEAR_STATE: {
      const newSaveUserQuicklinksState = {
        ...state,
        saveUserQuickLinksInfoStatus: {},
      }
      return newSaveUserQuicklinksState
    }
    case SAVE_GROUP_QUICKLINKS_REQUESTED: {
      const newSaveGroupQuicklinksRequestedState = {
        ...state,
        saveGroupQuickLinksInfoStatus: {
          status: 'requested',
        },
      }
      return newSaveGroupQuicklinksRequestedState
    }
    case SAVE_GROUP_QUICKLINKS_SUCCEEDED: {
      const newSaveGroupQuicklinksSucceededState = {
        ...state,
        saveGroupQuickLinksInfoStatus: action.payload,
      }
      return newSaveGroupQuicklinksSucceededState
    }
    case SAVE_GROUP_QUICKLINKS_FAILED: {
      const newSaveGroupQuicklinksFailedState = {
        ...state,
        saveGroupQuickLinksInfoStatus: {
          status: 'failed',
        },
      }
      return newSaveGroupQuicklinksFailedState
    }
    case SAVE_GROUP_QUICKLINKS_CLEAR_STATE: {
      const newSaveGroupQuicklinksState = {
        ...state,
        saveGroupQuickLinksInfoStatus: {},
      }
      return newSaveGroupQuicklinksState
    }

    default:
      return state
  }
}

export function getBusinessTags(data) {
  return {
    type: DASHBOARD_BUSINESSAREA_TAGS_REQUESTED,
    payload: data,
  }
}

export function getUserBusinessTags() {
  return {
    type: USER_DASHBOARD_BUSINESSAREA_TAGS_REQUESTED,
  }
}

export function saveUserBusinessTags(data) {
  return {
    type: SAVE_USER_QUICKLINKS_REQUESTED,
    payload: data,
  }
}

export function clearSaveUserBusinessTagsInfo() {
  return {
    type: SAVE_USER_QUICKLINKS_CLEAR_STATE,
  }
}

export function toggleUserBusinessTags(index) {
  return {
    type: USER_DASHBOARD_BUSINESSAREA_TAGS_TOGGLE,
    payload: index,
  }
}

export function saveGroupBusinessTags(data) {
  return {
    type: SAVE_GROUP_QUICKLINKS_REQUESTED,
    payload: data,
  }
}

export function clearSaveGroupBusinessTagsInfo() {
  return {
    type: SAVE_GROUP_QUICKLINKS_CLEAR_STATE,
  }
}

export function* fetchBusinessTags(action) {
  try {
    const dashboardBusinessTagList = yield call(axios, {
      method: 'get',
      url: `${API_GATEWAY_URL}/bi_reporting_assets/v2/business_area_tags?field_groups=full_metadata&object_type=${action.payload.type}&search_string=${action.payload.searchString}`,
    })
    if (dashboardBusinessTagList.error) {
      const e = dashboardBusinessTagList.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }
    yield put({ type: 'DASHBOARD_BUSINESSAREA_TAGS_SUCCEEDED', payload: dashboardBusinessTagList })
  } catch (e) {
    yield put({ type: 'DASHBOARD_BUSINESSAREA_TAGS_FAILED' })
  }
}

export function* fetchUserBusinessTags() {
  try {
    const userBusinessTagList = yield call(axios, {
      method: 'get',
      url: `${API_GATEWAY_URL}/bi_reporting_assets/v2/user_quicklinks?field_groups=full_metadata`,
    })
    if (userBusinessTagList.error) {
      const e = userBusinessTagList.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }
    // Add subMenuOpen for handle
    userBusinessTagList.data.all_tags.map(tagDetails => {
      tagDetails.subMenuOpen = false
      return tagDetails
    })

    yield put({
      type: 'USER_DASHBOARD_BUSINESSAREA_TAGS_SUCCEEDED',
      payload: userBusinessTagList,
    })
  } catch (e) {
    yield put({ type: 'USER_DASHBOARD_BUSINESSAREA_TAGS_FAILED' })
  }
}

export function* updateUserBusinessTags(action) {
  try {
    const dashboardBusinessTagList = yield call(axios, {
      method: 'put',
      url: `${API_GATEWAY_URL}/bi_reporting_assets/v2/user_quicklinks`,
      data: { tags: action.payload },
    })
    if (dashboardBusinessTagList.error) {
      const e = dashboardBusinessTagList.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }
    yield put({
      type: 'SAVE_USER_QUICKLINKS_SUCCEEDED',
      payload: dashboardBusinessTagList,
    })
  } catch (e) {
    yield put({ type: 'SAVE_USER_QUICKLINKS_FAILED' })
  }
}

export function* updateGroupBusinessTags(action) {
  try {
    const dashboardBusinessTagList = yield call(axios, {
      method: 'post',
      url:
        `${API_GATEWAY_URL}/bi_reporting_assets/v2/group_properties?group_id=` +
        `${action.payload.id}` +
        '&object_type=quick_links',
      data: { tags: action.payload.businessTags },
    })
    if (dashboardBusinessTagList.error) {
      const e = dashboardBusinessTagList.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }
    yield put({
      type: 'SAVE_GROUP_QUICKLINKS_SUCCEEDED',
      payload: dashboardBusinessTagList,
    })
  } catch (e) {
    yield put({ type: 'SAVE_GROUP_QUICKLINKS_FAILED' })
  }
}
