import './Dashboardviewer.scss'

import React from 'react'
import { Redirect, Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import {
  Grid,
  CardContent,
  Typography,
  Icon,
  Tabs,
  Tab,
  Slide,
  Dialog,
  MenuList,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  MenuItem,
  ListItemIcon,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Tooltip,
} from '@mui/material'
import SwipeableViews from 'react-swipeable-views'
import AppBar from '@mui/material/AppBar'
import Rgl, { WidthProvider } from 'react-grid-layout'
import cloneDeep from 'lodash/cloneDeep'
import { FilterList, MoreVert, Warning } from '@mui/icons-material'
import Viewer from 'react-froala-wysiwyg/FroalaEditorView'
import classnames from 'classnames'
import styled from 'styled-components'
import { withAnalytics } from '@praxis/component-analytics'
import { Spinner, createErrMsg } from 'greenfield-utilities'

import { GreenfieldCardPreview, GreenfieldFilterViewer } from '../../../shared/shared'
import Generateshortlink from '../../../shared/Generateshortlink'
import DashboardService from '../Dashboard.service'
import ViewerTimePeriod from '../../../shared/ViewerTimePeriod/ViewerTimePeriod'
import ConfirmDialog from '../../../shared/Dialog/ConfirmDialog'
import GreenfieldFilterChipToolbar from '../../../shared/FilterViewer/FilterChipToolbar.container'
import analyticsConfig from '../../../analytics'
import Notifications from '../../../shared/Notifications/Notifications.js'
import AccessDeniedViewOwner from '../../../shared/AccessDeniedViewOwner'
import DashboardviewerHeaderMobile from './DashboardviewerHeader/DashboardviewerHeaderMobile'
import DashboardviewerHeader from './DashboardviewerHeader/DashboardviewerHeader.container'

const ReactGridLayout = WidthProvider(Rgl)

const Transition = React.forwardRef((props, ref) => <Slide direction="left" {...props} ref={ref} />)

const STab = styled(Tab)`
  && {
    border-bottom: none;
    min-height: 48px;
  }
  span {
    display: contents;

    svg {
    padding-right: 2px;
  }
`
const SSpinner = styled(Spinner)`
  margin-top: 50px;
`

class Dashboardviewer extends React.Component {
  state = {
    selectedFilterId: '',
    mobileFilterDialogState: false,
    mobileMoreMenuActive: false,
    mobileViewDetailActive: false,
    mobileAnchorEl: null,
    refreshDashboard: 0,
    openFilters: false,
    cardInFocus: false,
    unDeleteConfirm: true,
    cancelRestore: false,
    shortLinkVisible: false,
  }

  componentDidMount() {
    const { clearTabCardsPayload } = this.props

    clearTabCardsPayload()
  }

  componentWillUnmount() {
    const { clearViewDashboardInfo, clearNotificationCache, clearCardInfo } = this.props
    clearNotificationCache()
    clearViewDashboardInfo()
    clearCardInfo()
  }

  componentDidUpdate(prevProps) {
    const { isBadShortUrl, displayServiceErrorMessage } = this.props

    if (!prevProps.isBadShortUrl && isBadShortUrl) {
      displayServiceErrorMessage(
        createErrMsg(
          '',
          'The short link you have requested does not exist and you have been redirected to the default dashboard.'
        )
      )
    }
  }

  sendFireFlyEvent = (isCustomEvent, eventObj) => {
    if (isCustomEvent) {
      this.props.trackCustomEvent(eventObj.eventName, eventObj.key, JSON.stringify(eventObj.data))
    } else {
      this.props.trackEvent({
        event: {
          type: eventObj.eventName,
        },
      })
    }
  }

  updateState = (stateName, value) => {
    this.setState({ [stateName]: value })
  }

  toggleMobileViewDetail = (panel, expanded) => {
    this.setState({
      mobileViewDetailActive: expanded ? panel : false,
    })
  }

  handleToggleMobileTimePeriod = e => {
    if (!this.state.mobileAnchorEl) {
      this.setState({
        mobileAnchorEl: e.currentTarget,
      })
    }
  }

  handleTabChange = (e, value) => {
    const { pageData } = this.props
    const tabName = pageData.data.tabs[value]?.name

    const eventObject = {
      eventName: 'greenfieldDashboardTabChange',
      key: tabName,
      data: tabName,
    }
    this.sendFireFlyEvent(true, eventObject)
  }

  dashboardResize = data => {
    const { isMobile } = this.props

    return isMobile ? this.reOrderCards(data.tabs) : null
  }

  reOrderCards = arr =>
    arr.map(item => {
      item.detailContents
        .sort((a, b) => (a.yPosition > b.yPosition ? 1 : -1))
        .sort((a, b) => (a.xPosition > b.xPosition ? 1 : -1))
        .map(value => {
          value.xSize = 12
          return value
        })
      return item
    })

  dashboardHttpStatusRouter = () => {
    const { pageData } = this.props

    if (this.state.cancelRestore) {
      return <Redirect to="/dashboards" />
    }

    if (pageData.status === 'requested' || !Object.keys(pageData).length) {
      return <SSpinner layout="selfCentering" size="large" />
    } else if (pageData.status >= 200 && pageData.status < 300) {
      return this.handleOKStatus()
    } else {
      const errorMsg = createErrMsg(pageData, 'Something went wrong with the data request')
      if (!this.props.dashboardId) {
        // For homepage dashboard this is causing invariant violation
        this.props.displayServiceErrorMessage(errorMsg)
      }

      return (
        <CardContent className="dashboardFailMessage">
          <Icon className="notFoundIcon">not_interested</Icon>
          <Typography>{errorMsg}</Typography>
          <AccessDeniedViewOwner errorData={pageData?.message?.data} />
        </CardContent>
      )
    }
  }

  handleOKStatus() {
    const { pageData, isMobile } = this.props
    const isDeleted = pageData.data?.status?.state === 'disabled'

    if (pageData.data) {
      const { data } = pageData

      if (data.tabs) {
        const _data = cloneDeep(data)
        const typeOfData = isMobile ? this.dashboardResize(_data) : data.tabs
        const dashboardMetadata = DashboardService.getDashboardMetadata(pageData.data)
        const layoutData = this.createLayoutJson(typeOfData)
        const tabHeaders = this.getTabHeaders(typeOfData)
        return this.renderMainContainer(dashboardMetadata, layoutData, tabHeaders, pageData.data, this.sendFireFlyEvent)
      } else if (isDeleted) {
        const dashboardMetadata = DashboardService.getDashboardMetadata(pageData.data)

        return this.renderMainContainer(dashboardMetadata, null, null, pageData.data, this.sendFireFlyEvent)
      }
    }
    return null
  }

  renderMainContainer(metadata, layoutData, mockDataHeaders, dashboardData, sendFireflyEvent) {
    const { trackEvent, isMobile, routeProps, restoreDashboard, objectNotificationState } = this.props
    const { shortLinkVisible } = this.state
    const isDeleted = dashboardData?.status?.state === 'disabled'
    const onDismissShortlinkModal = () => {
      this.setState({
        shortLinkVisible: false,
      })
    }

    return (
      <div className="viewDashboardMainContainer" id="viewDashboardMainContainer">
        {!isMobile && this.renderDashboardHeader(metadata, dashboardData)}
        {objectNotificationState?.data && objectNotificationState.data[this.props?.pageData?.data?._id]?.length > 0 && (
          <Notifications
            notificationData={objectNotificationState.data[this.props?.pageData?.data?._id]}
            level="dashboard"
            isViewerEdit={dashboardData?.edit_enabled === 'yes'}
            viewerId={dashboardData?._id}
            sendFireflyEvent={sendFireflyEvent}
          />
        )}
        {shortLinkVisible && (
          <Generateshortlink
            trackEvent={trackEvent}
            id={dashboardData._id}
            type="dashboard"
            routeProps={routeProps}
            onDismiss={onDismissShortlinkModal}
          />
        )}

        {isDeleted &&
          dashboardData &&
          (dashboardData.edit_enabled === 'yes' ? (
            <ConfirmDialog
              open={this.state.unDeleteConfirm}
              contentText="Dashboard you are trying to view is deleted. Do you want to restore ?"
              okText="RESTORE"
              onClickOk={() => {
                restoreDashboard({ dashboardId: dashboardData._id })
                this.setState({ unDeleteConfirm: false })
              }}
              onClickCancel={() => {
                this.setState({
                  unDeleteConfirm: false,
                  cancelRestore: true,
                })
              }}
            />
          ) : (
            <Dialog open>
              <DialogContent>
                <DialogContentText className="dialog-text">
                  This dashboard is deleted, please contact{' '}
                  <b>
                    {(dashboardData.owners?.length && dashboardData.owners[0]) ||
                      (dashboardData.owners_access?.length && dashboardData.owners_access[0].user_group_name) ||
                      dashboardData.created_by}
                  </b>{' '}
                  to restore this dashboard
                </DialogContentText>
              </DialogContent>
              <DialogActions className="okButton">
                <Button
                  onClick={() => {
                    this.setState({
                      cancelRestore: true,
                    })
                  }}
                >
                  OK
                </Button>
              </DialogActions>
            </Dialog>
          ))}
        {!isDeleted && (
          <div className="inline-css">
            <>
              {isMobile && this.renderMobileMenuBtns()}
              {isMobile && this.renderMobileFilterMenu()}
              {isMobile && this.renderMobileMoreMenu()}
              {!isMobile && (
                <GreenfieldFilterChipToolbar
                  cardIds={dashboardData.cards ? dashboardData.cards.map(card => card?.card_id?.toString()) : []}
                  id={metadata.id}
                  chipClicked={this.filterChipClick}
                  type="dashboard"
                  routeProps={routeProps}
                />
              )}
              {this.renderFireFoxPrintMessage()}
            </>
            {this.renderTabs(layoutData, mockDataHeaders)}
          </div>
        )}
      </div>
    )
  }

  renderMobileHeader = metadata => (
    <DashboardviewerHeaderMobile
      metadata={metadata}
      updateAppliedFilters={this.props.updateAppliedFilters}
      clearAllFilters={this.props.clearAllFilters}
    />
  )

  renderFireFoxPrintMessage = () => (
    <div className="printMessage hide">
      <h1>
        <div>Users may see defects printing on Firefox</div>
        <div>We recommend using Google Chrome</div>
      </h1>
    </div>
  )

  dismissPopover = () => {
    this.setState({
      openFilters: false,
    })
  }

  filterChipClick = selectedFilterId => {
    this.setState({
      openFilters: true,
      selectedFilterId,
    })
  }

  filterClick = e => {
    const { target } = e

    this.setState({
      openFilters: target,
      selectedFilterId: '',
    })
  }

  renderDashboardHeader(metadata, dashboardData) {
    const { data } = this.props.pageData
    const { openFilters, selectedFilterId } = this.state

    return (
      <DashboardviewerHeader
        timePeriod={this.props.timePeriod}
        onTimePeriodChange={this.props.onTimePeriodChange}
        data={metadata}
        metadata={data}
        delDashboard={this.props.delDashboard}
        dashboardDelStatus={this.props.dashboardDelStatus}
        dashboardData={dashboardData || {}}
        saveDashboard={this.props.saveDashboard}
        routeProps={this.props.routeProps}
        activeTab={this.props.activeTab}
        isMobile={this.props.isMobile}
        clearTabCardsPayload={this.props.clearTabCardsPayload}
        refreshDashboardClicked={this.refreshDashboardClicked}
        deviceType={this.props.deviceType}
        dismissPopover={this.dismissPopover}
        filterClick={this.filterClick}
        openFilters={openFilters}
        selectedFilterId={selectedFilterId}
        dashboardId={this.props.dashboardId}
        selectedDashboardTimePeriod={this.props.selectedDashboardTimePeriod}
        onApplyDashboardTimePeriod={this.props.onApplyDashboardTimePeriod}
        updateSelectedDashTimePeriod={this.props.updateSelectedDashTimePeriod}
        onHandleDashTimePeriodClose={this.props.onHandleDashTimePeriodClose}
      />
    )
  }

  renderTabNotification = tabNum => {
    const { getNotificationCountStatus, pageData, isMobile } = this.props
    if (
      getNotificationCountStatus &&
      getNotificationCountStatus?.status &&
      getNotificationCountStatus?.status >= 200 &&
      getNotificationCountStatus?.status < 300 &&
      getNotificationCountStatus.data
    ) {
      if (pageData.data.tabs[tabNum].detailContents.length > 0) {
        const cardList = pageData.data.tabs[tabNum].detailContents.map(card => card.id)
        const datasetList = pageData.data.tabs[tabNum].detailContents.reduce((prev, item) => {
          if (!prev.includes(item.datasetId)) {
            prev.push(item.datasetId)
          }
          return prev
        }, [])

        const datasetTabList = datasetList.filter(id => Number(getNotificationCountStatus.data[id]) > 0)
        const tabCards = cardList.filter(id => Number(getNotificationCountStatus.data[id]) > 0)
        const notificationTabCount =
          tabCards.length > 0
            ? tabCards.reduce((prev, item) => {
                prev = prev + getNotificationCountStatus.data[item]
                return prev
              }, 0)
            : 0

        const notificationDatasetTabCount =
          datasetTabList.length > 0 &&
          datasetTabList.reduce((prev, item) => {
            prev = prev + getNotificationCountStatus.data[item]
            return prev
          }, 0)

        const totalCount = notificationTabCount + notificationDatasetTabCount
        if (!isMobile && totalCount) {
          return (
            <Tooltip title={`${totalCount} notifications`} aria-label="add">
              <Warning data-cy={`tab-notification-${tabNum}`} color="error" />
            </Tooltip>
          )
        }
      }
    }

    return null
  }

  renderTabs(layoutData, dataHeaders) {
    const { activeTab, routeProps, isMobile, isHomePage } = this.props
    const { cardInFocus } = this.state
    const printTabIndex = /\/print\//i.test(routeProps.location.pathname)
      ? window.parseInt(routeProps.location.pathname.split('/')[4], 10)
      : null
    const { _id, _siteName, _tabName } = routeProps.match.params
    const { search, hash } = routeProps.location
    const qs = search.concat(hash)
    const tabValue = Number.isInteger(printTabIndex) ? printTabIndex : activeTab

    return (
      <div className="viewDashboardTabs" id="viewDashboardTabs">
        <AppBar position="static" className="greenFieldNoPrint">
          <Tabs
            value={tabValue}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            onChange={(e, value) => this.handleTabChange(e, value)}
          >
            {dataHeaders.map((item, index) => (
              <STab
                data-cy={`dashboard-tab-${index}`}
                label={<span>{item}</span>}
                icon={this.renderTabNotification(index)}
                key={index}
                to={
                  isHomePage
                    ? _siteName
                      ? `/site/${_siteName}/${_tabName}/${index}${qs}`
                      : `/favorites/home/${index}${qs}`
                    : _siteName
                    ? `/site/${_siteName}/dashboard/${_id}/${index}${qs}`
                    : `/dashboard/${_id}/${index}${qs}`
                }
                component={Link}
              />
            ))}
          </Tabs>
        </AppBar>

        <SwipeableViews
          index={Number.isInteger(printTabIndex) ? printTabIndex : activeTab}
          className="swipeableViewsContent"
          disabled={isMobile && cardInFocus}
        >
          {layoutData.map((item, key) => (
            <div key={`renderGrid-${key}`}>
              {((printTabIndex === null && activeTab === key) || printTabIndex === key) && this.renderGridLayout(item)}
            </div>
          ))}
        </SwipeableViews>
      </div>
    )
  }

  createLayoutJson(data) {
    const _data = cloneDeep(data)

    return _data.map(item =>
      item.detailContents.map(value => ({
        i: String(value.id),
        text: value.text,
        x: value.xPosition,
        w: value.xSize,
        y: value.yPosition,
        h: value.ySize,
        type: value.type,
      }))
    )
  }

  mapGridProps = value => ({
    i: String(value.id),
    text: value.text,
    x: value.xPosition,
    w: value.xSize,
    y: value.yPosition,
    h: value.ySize,
  })

  getTabHeaders = data => data.map(item => item.name)

  // Delay on refresh to clear the tabCardsPayload state from ducks
  refreshDashboardClicked = () => {
    setTimeout(() => {
      this.setState({ refreshDashboard: this.state.refreshDashboard + 1 })
    }, 500)
  }

  renderCardContents(cardId) {
    const { timePeriod, activeTab, pageData, isMobile, routeProps } = this.props
    const { refreshDashboard } = this.state
    const cardSize = pageData.data && pageData.data.tabs ? DashboardService.getCardSize(cardId, pageData.data.tabs) : {}
    const cardType = pageData.data && pageData.data.tabs ? DashboardService.getCardType(cardId, pageData.data.tabs) : ''

    return (
      <GreenfieldCardPreview
        cardId={cardId}
        cardSizeHeight={cardSize.height}
        cardSizeWidth={cardSize.width}
        showFavIcon={false}
        filters={pageData.data.filters}
        savedFilters={pageData.data.saved_filters}
        timePeriod={timePeriod}
        refreshDashboard={refreshDashboard}
        activeTab={activeTab}
        isMobile={isMobile}
        cardType={cardType}
        routeProps={routeProps}
        notificationCountData={this.props.getNotificationCountStatus}
      />
    )
  }

  toggleCardinFocus = () => {
    this.setState(prevState => ({
      cardInFocus: !prevState.cardInFocus,
    }))
  }

  generateEachItem = (data, borders = '') => {
    return data.map(item => (
      <div
        key={item.i}
        role="grid"
        tabIndex="-1"
        className={classnames('dashboardPreviewCard', borders)}
        onFocus={() => this.toggleCardinFocus()}
        onBlur={() => this.toggleCardinFocus()}
      >
        {this.renderCardContents(item.i)}
      </div>
    ))
  }

  renderGridLayout = dataObj => {
    const { isMobile, isPrint, pageData } = this.props
    const settings = pageData?.data?.application_data?.ui?.settings || {}
    const spacing = settings?.spacing ? parseInt(settings.spacing, 10) : 10

    return isPrint ? (
      <Grid item xs className="printable-dashboard">
        <Rgl
          layout={dataObj}
          compactType={null}
          className="layout"
          isDraggable={false}
          isResizable={false}
          width={1050}
          rowHeight={72}
          onLayoutChange={() => {}}
          margin={[spacing, spacing]}
        >
          {this.generateEachItem(dataObj, settings.borders)}
        </Rgl>
      </Grid>
    ) : (
      <Grid item xs>
        <ReactGridLayout
          layout={dataObj}
          compactType={isMobile ? 'vertical' : null}
          className="layout"
          isDraggable={false}
          isResizable={false}
          rowHeight={72}
          onLayoutChange={() => {}}
          margin={[spacing, spacing]}
        >
          {this.generateEachItem(dataObj, settings.borders)}
        </ReactGridLayout>
      </Grid>
    )
  }

  renderMobileFilterMenu = () => {
    const { pageData, routeProps } = this.props
    const { selectedFilterId, mobileFilterDialogState } = this.state
    const { _id, filters } = pageData.data

    return (
      <Dialog
        fullScreen
        open={mobileFilterDialogState}
        onClose={() => {
          this.updateState('mobileFilterDialogState', !mobileFilterDialogState)
        }}
        TransitionComponent={Transition}
        className="mobileFilterSlide"
      >
        {filters?.length > 0 ? (
          <GreenfieldFilterViewer
            routeProps={routeProps}
            open={mobileFilterDialogState}
            onApplyDashboardFilters={() => {}}
            viewerFilters={filters}
            type="dashboard"
            id={_id}
            onDismissPopover={() => {
              this.updateState('mobileFilterDialogState', !mobileFilterDialogState)
            }}
            selectedFilterId={selectedFilterId}
            isMobile
            className="greenFieldNoPrint"
          />
        ) : (
          <>
            <Typography className="mobileNoFilterMessage">There are no filters available</Typography>

            <Grid container spacing={3}>
              <Grid
                item
                xs={12}
                className="mobileMoreCloseBtn"
                onClick={() => {
                  this.updateState('mobileFilterDialogState', !mobileFilterDialogState)
                }}
              >
                Close
              </Grid>
            </Grid>
          </>
        )}
      </Dialog>
    )
  }

  handleDeleteDashboard = id => {
    this.props.delDashboard({ delDashboard: id })
  }

  renderMobileMoreMenu = () => {
    const { mobileViewDetailActive, mobileMoreMenuActive, confirmDeleteDashboard, mobileAnchorEl } = this.state
    const {
      isMobile,
      deviceType,
      pageData,
      routeProps,
      timePeriod,
      dashboardDelStatus,
      onTimePeriodChange,
      selectedDashboardTimePeriod,
    } = this.props
    const { data } = pageData
    // eslint-disable-next-line camelcase
    const { edit_enabled } = data

    return (
      <>
        <Dialog
          fullScreen
          open={mobileMoreMenuActive}
          onClose={() => {
            this.updateState('mobileMoreMenuActive', !mobileMoreMenuActive)
          }}
          TransitionComponent={Transition}
          className="mobileMoreSlide"
        >
          <MenuList>
            <Accordion
              expanded={mobileViewDetailActive === 'panel1'}
              onClick={() => {
                const eventData = {
                  eventName: 'mobileDashboardMoreViewDetailClick',
                  key: this.props.pageData.data._id,
                  data: {
                    dashboardId: this.props.pageData.data._id,
                  },
                }
                this.sendFireFlyEvent(true, eventData)
              }}
              onChange={(event, expanded) => {
                this.toggleMobileViewDetail('panel1', expanded)
              }}
              className="mobileDashboardViewMoreExpand"
              id="mobile-dashboard-more-view-detail-btn"
            >
              <AccordionSummary
                classes={{
                  content: 'expansionContent',
                }}
              >
                <Typography>View Detail</Typography>
                <Icon className="align-icon">visibility</Icon>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={3} alignItems="flex-start" direction="row">
                  <Grid item xs={6}>
                    <div className="card-details">
                      <Typography variant="caption" color="inherit">
                        <b>Owner(s):</b>
                        {data.owners_access.map((item, key) => (
                          <Typography key={key} variant="caption" color="inherit">
                            {item.user_group_name}
                          </Typography>
                        ))}
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className="card-details">
                      <Typography variant="caption" color="inherit">
                        <b>Privacy:</b> {data.dashboard_data_classification}
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div className="card-details">
                      <Typography variant="caption" color="inherit">
                        <b>Description:</b> {data.description}
                      </Typography>
                    </div>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={mobileViewDetailActive === 'panel2'}
              onClick={() => {
                const eventData = {
                  eventName: 'mobileDashboardMoreHelpClick',
                  key: this.props.pageData.data._id,
                  data: {
                    dashboardId: this.props.pageData.data._id,
                  },
                }
                this.sendFireFlyEvent(true, eventData)
              }}
              onChange={(event, expanded) => {
                this.toggleMobileViewDetail('panel2', expanded)
              }}
              className="mobileDashboardViewMoreExpand"
              id="mobile-dashboard-more-help-btn"
            >
              <AccordionSummary
                classes={{
                  content: 'expansionContent',
                }}
              >
                <Typography>Help</Typography>
                <Icon className="align-icon">help</Icon>
              </AccordionSummary>
              <AccordionDetails>
                <Viewer model={data.support_details.text} />
              </AccordionDetails>
            </Accordion>

            <MenuItem
              className="mobileMoreListItem"
              id="mobile-dashboard-generate-shortlink-btn"
              onClick={() => {
                this.updateState('mobileMoreMenuActive', !mobileMoreMenuActive)
                this.updateState('shortLinkVisible', true)
              }}
            >
              <Typography>Get a short link</Typography>
              <ListItemIcon>
                <Icon className="align-icon">link</Icon>
              </ListItemIcon>
            </MenuItem>

            {edit_enabled === 'yes' && ( // eslint-disable-line camelcase
              <MenuItem
                className="mobileMoreListItem"
                onClick={() => {
                  const eventData = {
                    eventName: 'mobileDashboardMoreDeleteClick',
                    key: this.props.pageData.data._id,
                    data: {
                      dashboardId: this.props.pageData.data._id,
                    },
                  }
                  this.sendFireFlyEvent(true, eventData)
                  this.updateState('confirmDeleteDashboard', true)
                }}
                id="mobile-dashboard-more-delete-btn"
              >
                <Typography>Delete</Typography>
                <ListItemIcon>
                  <Icon className="align-icon">delete</Icon>
                </ListItemIcon>
              </MenuItem>
            )}

            <MenuItem
              className="mobileMoreListItem"
              onClick={e => {
                const eventData = {
                  eventName: 'mobileDashboardMoreTimePeriodClick',
                  key: this.props.pageData.data._id,
                  data: {
                    dashboardId: this.props.pageData.data._id,
                  },
                }
                this.sendFireFlyEvent(true, eventData)
                this.handleToggleMobileTimePeriod(e)
              }}
              id="mobile-dashboard-more-datePicker-btn"
            >
              <Typography>Time Period</Typography>
              <ListItemIcon>
                <Icon className="align-icon">date_range</Icon>
              </ListItemIcon>
            </MenuItem>
          </MenuList>

          <Grid container spacing={3}>
            <Grid
              item
              xs={12}
              className="mobileMoreCloseBtn"
              onClick={() => this.updateState('mobileMoreMenuActive', !mobileMoreMenuActive)}
              id="mobile-dashboard-close-more-menu"
            >
              Close
            </Grid>
          </Grid>

          <ConfirmDialog
            open={confirmDeleteDashboard}
            contentText="Are you sure you want to delete"
            okText="DELETE"
            onCloseDialog={() => this.updateState('confirmDeleteDashboard', false)}
            onClickOk={() => this.handleDeleteDashboard(data._id)}
            onClickCancel={() => this.updateState('confirmDeleteDashboard', false)}
          />
          {dashboardDelStatus?.status === 204 && <Redirect to="/dashboards" />}
        </Dialog>

        <ViewerTimePeriod
          routeProps={routeProps}
          dashboardInfo={pageData.data}
          isDashboard
          isMobile={isMobile}
          deviceType={deviceType}
          anchorEl={mobileAnchorEl}
          onTimePeriodChange={onTimePeriodChange}
          closeMobilePopover={() => {
            this.updateState('mobileAnchorEl', null)
            this.updateState('mobileMoreMenuActive', !mobileMoreMenuActive)
          }}
          showFiscalSwitch
          timePeriod={selectedDashboardTimePeriod || timePeriod}
          selectedDashboardTimePeriod={selectedDashboardTimePeriod}
        />
      </>
    )
  }

  renderMobileMenuBtns = () => {
    const { isHomePage, deviceType } = this.props
    const isPhone = deviceType.includes('isPhone')

    return (
      <div>
        <Grid container spacing={3}>
          <Grid
            item
            xs={6}
            className={classnames('mobileFilterBtnLeft', {
              homePagePhoneBtn: isHomePage && isPhone,
            })}
            onClick={() => {
              const eventData = {
                eventName: 'mobileDashboardFilterBtnClick',
                key: this.props.pageData.data._id,
                data: {
                  dashboardId: this.props.pageData.data._id,
                },
              }
              this.sendFireFlyEvent(true, eventData)
              this.updateState('mobileFilterDialogState', !this.state.mobileFilterDialogState)
            }}
            id="mobile-filter-btn-dashboard"
          >
            <FilterList /> Filter
          </Grid>
          <Grid
            item
            xs={6}
            className={classnames('mobileFilterBtnRight', {
              homePagePhoneBtn: isHomePage && isPhone,
            })}
            onClick={() => {
              const eventData = {
                eventName: 'mobileDashboardMoreBtnClick',
                key: this.props.pageData.data._id,
                data: {
                  dashboardId: this.props.pageData.data._id,
                },
              }
              this.sendFireFlyEvent(true, eventData)
              this.updateState('mobileMoreMenuActive', !this.state.mobileMoreMenuActive)
            }}
            id="mobile-more-btn-dashboard"
          >
            <MoreVert /> More
          </Grid>
        </Grid>
      </div>
    )
  }

  renderCard(hasCard, item, cardData) {
    // todo: create something better than "hasCard"
    if (hasCard.cardSlot) {
      const cardId = DashboardService.getCardId(cardData, item)
      return <div className="hasCard">{this.renderCardContents(cardId)}</div>
    } else {
      return <div className="noCard" />
    }
  }

  getCardIdList = list => {
    return list.reduce((prev, item) => {
      if (item) {
        item.forEach(value => {
          if (value.cardId && !prev.includes(value.cardId)) {
            prev.push(value.cardId)
          }
        })
      }

      return prev
    }, [])
  }

  /* end legacy dashboard render */

  render() {
    const { pageData, deviceType, isMobile, isHomePage, dashboardId } = this.props
    const isTabletClassName = deviceType.includes('isTablet') ? 'isTablet' : ''
    const backgroundColorValue = pageData?.data?.application_data?.ui?.settings?.backgroundColorValue?.hex

    return (
      <div
        className={`viewDashboard ${isTabletClassName} ${isHomePage && isMobile ? 'homePageDashboard' : ''}`}
        id="viewDashboard"
        data-cy={`dashId-${dashboardId}`}
        style={{ background: backgroundColorValue || 'inherit' }}
      >
        {this.dashboardHttpStatusRouter()}
      </div>
    )
  }
}

Dashboardviewer.defaultProps = {
  deviceType: [],
  pageData: {},
}

Dashboardviewer.propTypes = {
  pageData: PropTypes.object,
}

export default withAnalytics(analyticsConfig)(Dashboardviewer)
