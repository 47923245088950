import React from 'react'
import { IconButton, Icon } from '@mui/material'
import PropTypes from 'prop-types'
import '../../../styles/global.scss'

export const FavoriteButton = ({ icon, onClick, elementId }) => (
  <IconButton
    className="greenFieldNoPrint"
    id={elementId >= 0 ? `favBtnId${elementId}` : 'favBtnId'}
    aria-label="Favorite Item"
    onClick={() => onClick(icon)}
    data-cy="favorite-button"
  >
    <Icon className="favorite-icon" data-cy="favorite-icon">
      {icon}
    </Icon>
  </IconButton>
)

FavoriteButton.propTypes = {
  icon: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default FavoriteButton
