import React from 'react'
import { connect } from 'react-redux'

import {
  updateNotificationAction,
  readDeleteNotificationAction,
  notificationReadUpdateAction,
} from '../../../ducks/notification'
import { displayServiceErrorMessage } from '../../../ducks/layout'
import { fetchDownloadNotifyData, clearLargeExportCacheData } from '../../../ducks/LargeExport'
import Notification from './Notification'

export class NotificationContainer extends React.Component {
  render() {
    return (
      <div>
        <Notification
          {...this.props}
          fetchDownloadNotifyData={this.props.fetchDownloadNotifyData}
          downloadedNotificationData={this.props.downloadedNotificationData}
          clearLargeExportCache={this.props.clearLargeExportCacheData}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  notificationStatus: state.notification.notificationStatus,
  readDeleteNotificationStatus: state.notification.readDeleteNotificationStatus,
  notificationReadUpdateStatus: state.notification.notificationReadUpdateStatus,
  downloadedNotificationData: state.largeExport.downloadedNotificationData,
})

export const mapDispatchToProps = dispatch => ({
  updateNotificationAction(data) {
    dispatch(updateNotificationAction(data))
  },
  readDeleteNotificationAction(data) {
    dispatch(readDeleteNotificationAction(data))
  },
  notificationReadUpdateAction(data) {
    dispatch(notificationReadUpdateAction(data))
  },
  displayServiceErrorMessage(data) {
    dispatch(displayServiceErrorMessage(data))
  },
  fetchDownloadNotifyData(data) {
    dispatch(fetchDownloadNotifyData(data))
  },
  clearLargeExportCacheData(data) {
    dispatch(clearLargeExportCacheData(data))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(NotificationContainer)
