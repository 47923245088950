import { call, put } from 'redux-saga/effects'
import axios from 'axios'
import cloneDeep from 'lodash/cloneDeep'

import { API_GATEWAY_URL } from './utils'

// Action types
export const ADMINSIDEBAR_FETCH_REQUESTED = 'ADMINSIDEBAR_FETCH_REQUESTED'
export const ADMINSIDEBAR_FETCH_SUCCEEDED = 'ADMINSIDEBAR_FETCH_SUCCEEDED'
export const ADMINSIDEBAR_FETCH_FAILED = 'ADMINSIDEBAR_FETCH_FAILED'

export const ADMINDETAIL_FETCH_REQUESTED = 'ADMINDETAIL_FETCH_REQUESTED'
export const ADMINDETAIL_FETCH_SUCCEEDED = 'ADMINDETAIL_FETCH_SUCCEEDED'
export const ADMINDETAIL_FETCH_FAILED = 'ADMINDETAIL_FETCH_FAILED'

export const ADMINGROUPLIST_FETCH_REQUESTED = 'ADMINGROUPLIST_FETCH_REQUESTED'
export const ADMINGROUPLIST_FETCH_SUCCEEDED = 'ADMINGROUPLIST_FETCH_SUCCEEDED'
export const ADMINGROUPLIST_FETCH_FAILED = 'ADMINGROUPLIST_FETCH_FAILED'

export const ADMINADDGROUPS_FETCH_REQUESTED = 'ADMINADDGROUPS_FETCH_REQUESTED'
export const ADMINADDGROUPS_FETCH_SUCCEEDED = 'ADMINADDGROUPS_FETCH_SUCCEEDED'
export const ADMINADDGROUPS_FETCH_FAILED = 'ADMINADDGROUPS_FETCH_FAILED'

export const ADMINREMOVEGROUPS_FETCH_REQUESTED = 'ADMINREMOVEGROUPS_FETCH_REQUESTED'
export const ADMINREMOVEGROUPS_FETCH_SUCCEEDED = 'ADMINREMOVEGROUPS_FETCH_SUCCEEDED'
export const ADMINREMOVEGROUPS_FETCH_FAILED = 'ADMINREMOVEGROUPS_FETCH_FAILED'

export const ADMINCREATENEWGROUP_REQUESTED = 'ADMINCREATENEWGROUP_REQUESTED'
export const ADMINCREATENEWGROUP_SUCCEEDED = 'ADMINCREATENEWGROUP_SUCCEEDED'
export const ADMINCREATENEWGROUP_FAILED = 'ADMINCREATENEWGROUP_FAILED'

export const ADMIN_EDIT_GROUP_REQUESTED = 'ADMIN_EDIT_GROUP_REQUESTED'
export const ADMIN_EDIT_GROUP_SUCCEEDED = 'ADMIN_EDIT_GROUP_SUCCEEDED'
export const ADMIN_EDIT_GROUP_FAILED = 'ADMIN_EDIT_GROUP_FAILED'

export const ADMIN_DELETE_GROUP_REQUESTED = 'ADMIN_DELETE_GROUP_REQUESTED'
export const ADMIN_DELETE_GROUP_SUCCEEDED = 'ADMIN_DELETE_GROUP_SUCCEEDED'
export const ADMIN_DELETE_GROUP_FAILED = 'ADMIN_DELETE_GROUP_FAILED'

export const ADMIN_DEFAULTOWNER_FETCH_REQUESTED = 'ADMIN_DEFAULTOWNER_FETCH_REQUESTED'
export const ADMIN_DEFAULTOWNER_FETCH_SUCCEEDED = 'ADMIN_DEFAULTOWNER_FETCH_SUCCEEDED'
export const ADMIN_DEFAULTOWNER_FETCH_FAILED = 'ADMIN_DEFAULTOWNER_FETCH_FAILED'

export const ADMIN_AUTO_SUGGEST_FETCH_REQUESTED = 'ADMIN_AUTO_SUGGEST_FETCH_REQUESTED'
export const ADMIN_AUTO_SUGGEST_FETCH_SUCCEEDED = 'ADMIN_AUTO_SUGGEST_FETCH_SUCCEEDED'
export const ADMIN_AUTO_SUGGEST_FETCH_FAILED = 'ADMIN_AUTO_SUGGEST_FETCH_FAILED'
export const ADMIN_AUTO_SUGGEST_CLEAR = 'ADMIN_AUTO_SUGGEST_CLEAR'

export const ADMIN_ADD_GROUP_PROPERTIES_REQUESTED = 'ADMIN_ADD_GROUP_PROPERTIES_REQUESTED'
export const ADMIN_ADD_GROUP_PROPERTIES_SUCCEEDED = 'ADMIN_ADD_GROUP_PROPERTIES_SUCCEEDED'
export const ADMIN_ADD_GROUP_PROPERTIES_FAILED = 'ADMIN_ADD_GROUP_PROPERTIES_FAILED'

export const ADMIN_REMOVE_GROUP_PROPERTIES_REQUESTED = 'ADMIN_REMOVE_GROUP_PROPERTIES_REQUESTED'
export const ADMIN_REMOVE_GROUP_PROPERTIES_SUCCEEDED = 'ADMIN_REMOVE_GROUP_PROPERTIES_SUCCEEDED'
export const ADMIN_REMOVE_GROUP_PROPERTIES_FAILED = 'ADMIN_REMOVE_GROUP_PROPERTIES_FAILED'

export const ADMIN_ADD_MEMBERS_GROUP_REQUESTED = 'ADMIN_ADD_MEMBERS_GROUP_REQUESTED'
export const ADMIN_ADD_MEMBERS_GROUP_SUCCEEDED = 'ADMIN_ADD_MEMBERS_GROUP_SUCCEEDED'
export const ADMIN_ADD_MEMBERS_GROUP_FAILED = 'ADMIN_ADD_MEMBERS_GROUP_FAILED'

export const ADMIN_DELETE_MEMBERS_GROUP_REQUESTED = 'ADMIN_DELETE_MEMBERS_GROUP_REQUESTED'
export const ADMIN_DELETE_MEMBERS_GROUP_SUCCEEDED = 'ADMIN_DELETE_MEMBERS_GROUP_SUCCEEDED'
export const ADMIN_DELETE_MEMBERS_GROUP_FAILED = 'ADMIN_DELETE_MEMBERS_GROUP_FAILED'

export const ADMIN_ADD_AD_GROUP_REQUESTED = 'ADMIN_ADD_AD_GROUP_REQUESTED'
export const ADMIN_ADD_AD_GROUP_SUCCEEDED = 'ADMIN_ADD_AD_GROUP_SUCCEEDED'
export const ADMIN_ADD_AD_GROUP_FAILED = 'ADMIN_ADD_AD_GROUP_FAILED'

// Reducer
export default function adminReducer(state = {}, action = {}) {
  switch (action.type) {
    case ADMINSIDEBAR_FETCH_REQUESTED: {
      const newState = {
        ...state,
        adminSidebarStatus: {
          status: 'requested',
        },
      }
      return newState
    }
    case ADMINSIDEBAR_FETCH_SUCCEEDED: {
      const newState = {
        ...state,
        adminSidebarStatus: action.payload,
      }
      return newState
    }
    case ADMINSIDEBAR_FETCH_FAILED: {
      const newState = {
        ...state,
        adminSidebarStatus: {
          status: 'failed',
        },
      }
      return newState
    }
    case ADMINDETAIL_FETCH_REQUESTED: {
      const newState = {
        ...state,
        adminDetailStatus: {
          status: 'requested',
        },
      }
      return newState
    }
    case ADMINDETAIL_FETCH_SUCCEEDED: {
      const newState = {
        ...state,
        adminDetailStatus: action.payload,
      }
      return newState
    }
    case ADMINDETAIL_FETCH_FAILED: {
      const newState = {
        ...state,
        adminDetailStatus: {
          status: 'failed',
        },
      }
      return newState
    }
    case ADMINGROUPLIST_FETCH_REQUESTED: {
      const newState = {
        ...state,
        groupList: {
          status: 'requested',
        },
      }
      return newState
    }
    case ADMINGROUPLIST_FETCH_SUCCEEDED: {
      const newState = {
        ...state,
        groupList: action.payload,
      }
      return newState
    }
    case ADMINGROUPLIST_FETCH_FAILED: {
      const newState = {
        ...state,
        groupList: {
          status: 'failed',
        },
      }
      return newState
    }

    case ADMINADDGROUPS_FETCH_REQUESTED: {
      const newState = {
        ...state,
        addedGroupsStatus: {
          status: 'requested',
        },
      }
      return newState
    }
    case ADMINADDGROUPS_FETCH_SUCCEEDED: {
      const adminDetailStatus = cloneDeep(state.adminDetailStatus)
      if (adminDetailStatus) {
        adminDetailStatus.data = action.payload.data
      }
      const newState = {
        ...state,
        adminDetailStatus,
        addedGroupsStatus: action.payload,
      }
      return newState
    }
    case ADMINADDGROUPS_FETCH_FAILED: {
      const newState = {
        ...state,
        addedGroupsStatus: {
          status: 'failed',
        },
      }
      return newState
    }

    case ADMINREMOVEGROUPS_FETCH_REQUESTED: {
      const newState = {
        ...state,
        removeGroupStatus: {
          status: 'requested',
        },
      }
      return newState
    }
    case ADMINREMOVEGROUPS_FETCH_SUCCEEDED: {
      const adminDetailStatus = cloneDeep(state.adminDetailStatus)
      if (adminDetailStatus) {
        adminDetailStatus.data.groups = adminDetailStatus.data.groups.filter(
          group => !JSON.parse(action.payload.config.data).groups.includes(group._id)
        )
      }

      const addedGroupsStatus = cloneDeep(state.addedGroupsStatus)
      if (addedGroupsStatus) {
        addedGroupsStatus.data.groups = addedGroupsStatus.data.groups.filter(
          group => !JSON.parse(action.payload.config.data).groups.includes(group._id)
        )
      }

      const newState = {
        ...state,
        adminDetailStatus,
        addedGroupsStatus,
        removeGroupStatus: action.payload,
      }
      return newState
    }
    case ADMINREMOVEGROUPS_FETCH_FAILED: {
      const newState = {
        ...state,
        removeGroupStatus: {
          status: 'failed',
        },
      }
      return newState
    }

    case ADMINCREATENEWGROUP_REQUESTED: {
      const newState = {
        ...state,
        adminCreateNewGroupStatus: {
          status: 'requested',
        },
      }
      return newState
    }
    case ADMINCREATENEWGROUP_SUCCEEDED: {
      const newState = {
        ...state,
        adminCreateNewGroupStatus: action.payload,
      }
      return newState
    }
    case ADMINCREATENEWGROUP_FAILED: {
      const newState = {
        ...state,
        adminCreateNewGroupStatus: {
          status: 'failed',
        },
      }
      return newState
    }

    case ADMIN_EDIT_GROUP_REQUESTED: {
      const newState = {
        ...state,
        adminEditGroupStatus: {
          status: 'requested',
        },
      }
      return newState
    }
    case ADMIN_EDIT_GROUP_SUCCEEDED: {
      const newState = {
        ...state,
        adminEditGroupStatus: action.payload,
      }
      return newState
    }
    case ADMIN_EDIT_GROUP_FAILED: {
      const newState = {
        ...state,
        adminEditGroupStatus: {
          status: 'failed',
        },
      }
      return newState
    }

    case ADMIN_DELETE_GROUP_REQUESTED: {
      const newState = {
        ...state,
        adminDeleteGroupStatus: {
          status: 'requested',
        },
      }
      return newState
    }
    case ADMIN_DELETE_GROUP_SUCCEEDED: {
      const newState = {
        ...state,
        adminDeleteGroupStatus: action.payload,
      }
      return newState
    }
    case ADMIN_DELETE_GROUP_FAILED: {
      const newState = {
        ...state,
        adminDeleteGroupStatus: {
          status: 'failed',
        },
      }
      return newState
    }

    case ADMIN_DEFAULTOWNER_FETCH_REQUESTED: {
      const newState = {
        ...state,
        defaultOwnerStatus: {
          status: 'requested',
        },
      }
      return newState
    }
    case ADMIN_DEFAULTOWNER_FETCH_SUCCEEDED: {
      const newState = {
        ...state,
        defaultOwnerStatus: action.payload,
      }
      return newState
    }
    case ADMIN_DEFAULTOWNER_FETCH_FAILED: {
      const newState = {
        ...state,
        defaultOwnerStatus: {
          status: 'failed',
        },
      }
      return newState
    }

    case ADMIN_AUTO_SUGGEST_FETCH_REQUESTED: {
      const newState = {
        ...state,
        autoSuggestStatus: {
          status: 'requested',
        },
      }
      return newState
    }
    case ADMIN_AUTO_SUGGEST_CLEAR: {
      const newState = {
        ...state,
        autoSuggestStatus: {},
      }
      return newState
    }
    case ADMIN_AUTO_SUGGEST_FETCH_SUCCEEDED: {
      const newState = {
        ...state,
        autoSuggestStatus: action.payload,
      }
      return newState
    }
    case ADMIN_AUTO_SUGGEST_FETCH_FAILED: {
      const newState = {
        ...state,
        autoSuggestStatus: {
          status: 'failed',
        },
      }
      return newState
    }

    case ADMIN_ADD_MEMBERS_GROUP_REQUESTED: {
      const newState = {
        ...state,
        addMemberGroupStatus: {
          status: 'requested',
        },
      }
      return newState
    }
    case ADMIN_ADD_MEMBERS_GROUP_SUCCEEDED: {
      const newState = {
        ...state,
        addMemberGroupStatus: action.payload,
      }
      return newState
    }
    case ADMIN_ADD_MEMBERS_GROUP_FAILED: {
      const newState = {
        ...state,
        addMemberGroupStatus: {
          status: 'failed',
        },
      }
      return newState
    }

    case ADMIN_ADD_AD_GROUP_REQUESTED: {
      const newState = {
        ...state,
        addAdGroupStatus: {
          status: 'requested',
        },
      }
      return newState
    }
    case ADMIN_ADD_AD_GROUP_SUCCEEDED: {
      const newState = {
        ...state,
        addAdGroupStatus: action.payload,
      }
      return newState
    }
    case ADMIN_ADD_AD_GROUP_FAILED: {
      const newState = {
        ...state,
        addAdGroupStatus: {
          status: 'failed',
          message: action?.payload?.response?.data?.message,
        },
      }
      return newState
    }

    case ADMIN_DELETE_MEMBERS_GROUP_REQUESTED: {
      const newState = {
        ...state,
        deleteMemberGroupStatus: {
          status: 'requested',
        },
      }
      return newState
    }
    case ADMIN_DELETE_MEMBERS_GROUP_SUCCEEDED: {
      const adminDetailStatus = cloneDeep(state.adminDetailStatus)
      if (adminDetailStatus) {
        adminDetailStatus.data.members = adminDetailStatus.data.members.filter(
          member => !JSON.parse(action.payload.config.data).users.some(user => user._id === member._id)
        )
      }

      const addMemberGroupStatus = cloneDeep(state.addMemberGroupStatus)
      if (addMemberGroupStatus) {
        addMemberGroupStatus.data.members = addMemberGroupStatus.data.members.filter(
          member => !JSON.parse(action.payload.config.data).users.some(user => user._id === member._id)
        )
      }
      const newState = {
        ...state,
        adminDetailStatus,
        addMemberGroupStatus,
        deleteMemberGroupStatus: action.payload,
      }
      return newState
    }
    case ADMIN_DELETE_MEMBERS_GROUP_FAILED: {
      const newState = {
        ...state,
        deleteMemberGroupStatus: {
          status: 'failed',
        },
      }
      return newState
    }

    case ADMIN_ADD_GROUP_PROPERTIES_REQUESTED: {
      const newState = {
        ...state,
        addGroupPropStatus: {
          status: 'requested',
        },
      }
      return newState
    }
    case ADMIN_ADD_GROUP_PROPERTIES_SUCCEEDED: {
      const adminDetailStatus = cloneDeep(state.adminDetailStatus)
      if (adminDetailStatus) {
        if (action.payload.config.url.includes('card')) {
          adminDetailStatus.data.cards = action.payload.data.cards
        } else if (action.payload.config.url.includes('dashboard')) {
          adminDetailStatus.data.dashboards = action.payload.data.dashboards
        } else if (action.payload.config.url.includes('dataset')) {
          adminDetailStatus.data.datasets = action.payload.data.datasets
        } else if (action.payload.config.url.includes('calculated_fields')) {
          adminDetailStatus.data.calculated_fields = action.payload.data.calculated_fields
        }
      }
      const newState = {
        ...state,
        adminDetailStatus,
        addGroupPropStatus: action.payload,
      }
      return newState
    }
    case ADMIN_ADD_GROUP_PROPERTIES_FAILED: {
      const newState = {
        ...state,
        addGroupPropStatus: {
          status: 'failed',
        },
      }
      return newState
    }

    case ADMIN_REMOVE_GROUP_PROPERTIES_REQUESTED: {
      const newState = {
        ...state,
        removeGroupPropStatus: {
          status: 'requested',
        },
      }
      return newState
    }
    case ADMIN_REMOVE_GROUP_PROPERTIES_SUCCEEDED: {
      const adminDetailStatus = cloneDeep(state.adminDetailStatus)
      if (adminDetailStatus) {
        if (action.payload.config.url.includes('card')) {
          adminDetailStatus.data.cards = adminDetailStatus.data.cards.filter(
            card => !JSON.parse(action.payload.config.data).object_id_list.includes(card._id)
          )
        } else if (action.payload.config.url.includes('dashboard')) {
          adminDetailStatus.data.dashboards = adminDetailStatus.data.dashboards.filter(
            dashboard => !JSON.parse(action.payload.config.data).object_id_list.includes(dashboard._id)
          )
        } else if (action.payload.config.url.includes('dataset')) {
          adminDetailStatus.data.datasets = adminDetailStatus.data.datasets.filter(
            dataset => !JSON.parse(action.payload.config.data).object_id_list.includes(dataset._id)
          )
        } else if (action.payload.config.url.includes('calculated_fields')) {
          adminDetailStatus.data.calculated_fields = adminDetailStatus.data.calculated_fields.filter(
            calculatedField => !JSON.parse(action.payload.config.data).object_id_list.includes(calculatedField._id)
          )
        }
      }
      const newState = {
        ...state,
        adminDetailStatus,
        removeGroupPropStatus: action.payload,
      }
      return newState
    }
    case ADMIN_REMOVE_GROUP_PROPERTIES_FAILED: {
      const newState = {
        ...state,
        removeGroupPropStatus: {
          status: 'failed',
        },
      }
      return newState
    }

    default:
      return state
  }
}

// Actions
export function updateSidebarInfo(data) {
  return {
    type: ADMINSIDEBAR_FETCH_REQUESTED,
    payload: data,
  }
}

export function updateAdminDetail(data) {
  return {
    type: ADMINDETAIL_FETCH_REQUESTED,
    payload: data,
  }
}

export function updateGrouplist(data) {
  return {
    type: ADMINGROUPLIST_FETCH_REQUESTED,
    payload: data,
  }
}

export function addGroups(data) {
  return {
    type: ADMINADDGROUPS_FETCH_REQUESTED,
    payload: data,
  }
}

export function removeGroups(data) {
  return {
    type: ADMINREMOVEGROUPS_FETCH_REQUESTED,
    payload: data,
  }
}

export function createNewGroup(data) {
  return {
    type: ADMINCREATENEWGROUP_REQUESTED,
    payload: data,
  }
}

export function editGroup(data) {
  return {
    type: ADMIN_EDIT_GROUP_REQUESTED,
    payload: data,
  }
}

export function deleteGroup(data) {
  return {
    type: ADMIN_DELETE_GROUP_REQUESTED,
    payload: data,
  }
}

export function updateDefaultOwner(data) {
  return {
    type: ADMIN_DEFAULTOWNER_FETCH_REQUESTED,
    payload: data,
  }
}

export function updateAutoSuggest(data) {
  return {
    type: ADMIN_AUTO_SUGGEST_FETCH_REQUESTED,
    payload: data,
  }
}

export function addMember(data) {
  return {
    type: ADMIN_ADD_MEMBERS_GROUP_REQUESTED,
    payload: data,
  }
}

export function deleteMember(data) {
  return {
    type: ADMIN_DELETE_MEMBERS_GROUP_REQUESTED,
    payload: data,
  }
}

export function addAdGroup(data) {
  return {
    type: ADMIN_ADD_AD_GROUP_REQUESTED,
    payload: data,
  }
}

export function addGroupProp(data) {
  return {
    type: ADMIN_ADD_GROUP_PROPERTIES_REQUESTED,
    payload: data,
  }
}

export function removeGroupProp(data) {
  return {
    type: ADMIN_REMOVE_GROUP_PROPERTIES_REQUESTED,
    payload: data,
  }
}

export const clearAdminOwnersInfo = () => ({
  type: ADMIN_AUTO_SUGGEST_CLEAR,
})

// Sagas (service requests)
export function* fetchAdminSidebarInfo(action) {
  try {
    const sidebarInfo = yield call(axios, {
      method: 'get',
      url: action.payload,
    })

    if (sidebarInfo.error) {
      const e = sidebarInfo.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }

    yield put({ type: ADMINSIDEBAR_FETCH_SUCCEEDED, payload: sidebarInfo })
  } catch (e) {
    yield put({ type: ADMINSIDEBAR_FETCH_FAILED })
  }
}

export function* fetchAdminDetail(action) {
  try {
    const adminDetail = yield call(axios, {
      method: 'get',
      url: `${API_GATEWAY_URL}/bi_reporting_assets/v2/${action.payload.topic === 'people' ? 'users' : 'groups'}/${
        action.payload._id
      }?${
        action.payload.topic === 'people'
          ? 'field_groups=quick_metadata,groups'
          : 'field_groups=quick_metadata,members,provision_process'
      }`,
    })

    if (adminDetail.error) {
      const e = adminDetail.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }

    yield put({ type: ADMINDETAIL_FETCH_SUCCEEDED, payload: adminDetail })
  } catch (e) {
    yield put({ type: ADMINDETAIL_FETCH_FAILED })
  }
}

export function* fetchGrouplist(action) {
  try {
    const groupList = yield call(axios, {
      method: 'get',
      url: `${API_GATEWAY_URL}/bi_reporting_assets/v2/groups?page=1&per_page=5&search_string=${action.payload}`,
    })

    if (groupList.error) {
      const e = groupList.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }

    yield put({ type: ADMINGROUPLIST_FETCH_SUCCEEDED, payload: groupList })
  } catch (e) {
    yield put({ type: ADMINGROUPLIST_FETCH_FAILED })
  }
}

export function* sendAddGroups(action) {
  try {
    const addedGroups = yield call(axios, {
      method: 'post',
      url: `${API_GATEWAY_URL}/bi_reporting_assets/v2/user_groups`,
      data: {
        groups: action.payload.groupIds,
        user_id: action.payload.userId,
      },
    })

    if (addedGroups.error) {
      const e = addedGroups.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }

    yield put({ type: ADMINADDGROUPS_FETCH_SUCCEEDED, payload: addedGroups })
  } catch (e) {
    yield put({ type: ADMINADDGROUPS_FETCH_FAILED })
  }
}

export function* sendRemoveGroups(action) {
  try {
    const removedGroups = yield call(axios, {
      method: 'delete',
      url: `${API_GATEWAY_URL}/bi_reporting_assets/v2/user_groups`,
      data: {
        groups: action.payload.groupIds,
        user_id: action.payload.userId,
      },
    })

    if (removedGroups.error) {
      const e = removedGroups.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }

    yield put({ type: ADMINREMOVEGROUPS_FETCH_SUCCEEDED, payload: removedGroups })
  } catch (e) {
    yield put({ type: ADMINREMOVEGROUPS_FETCH_FAILED })
  }
}

export function* sendCreateNewGroup(action) {
  try {
    const group = yield call(axios, {
      method: 'post',
      url: `${API_GATEWAY_URL}/bi_reporting_assets/v2/groups`,
      data: {
        group_name: action.payload.groupAddValue,
        owners: action.payload.groupAddOwners,
        user_type: action.payload.user_type,
      },
    })

    if (group.error) {
      const e = group.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }

    yield put({ type: ADMINCREATENEWGROUP_SUCCEEDED, payload: group })
  } catch (e) {
    yield put({ type: ADMINCREATENEWGROUP_FAILED })
  }
}

export function* sendEditGroup(action) {
  try {
    const group = yield call(axios, {
      method: 'put',
      url: `${API_GATEWAY_URL}/bi_reporting_assets/v2/groups/${action.payload.groupId}`,
      data: {
        group_name: action.payload.groupAddValue,
        owners: action.payload.groupAddOwners,
        user_type: action.payload.user_type,
      },
    })

    if (group.error) {
      const e = group.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }

    yield put({ type: ADMIN_EDIT_GROUP_SUCCEEDED, payload: group })
  } catch (e) {
    yield put({ type: ADMIN_EDIT_GROUP_FAILED })
  }
}

export function* sendDeleteGroup(action) {
  try {
    const group = yield call(axios, {
      method: 'delete',
      url: `${API_GATEWAY_URL}/bi_reporting_assets/v2/groups/${action.payload.groupId}`,
    })

    if (group.error) {
      const e = group.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }

    yield put({ type: ADMIN_DELETE_GROUP_SUCCEEDED, payload: group })
  } catch (e) {
    yield put({ type: ADMIN_DELETE_GROUP_FAILED })
  }
}

export function* fetchDefaultOwner() {
  try {
    const defaultOwner = yield call(axios, {
      method: 'get',
      url: `${API_GATEWAY_URL}/bi_reporting_assets/v2/users/current_user?field_groups=quick_metadata`,
    })

    if (defaultOwner.error) {
      const e = defaultOwner.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }

    yield put({ type: ADMIN_DEFAULTOWNER_FETCH_SUCCEEDED, payload: defaultOwner })
  } catch (e) {
    yield put({ type: ADMIN_DEFAULTOWNER_FETCH_FAILED })
  }
}

export function* fetchAutoSuggest(action) {
  try {
    const autoSuggest = yield call(axios, {
      method: 'get',
      url: action.payload,
    })

    if (autoSuggest.error) {
      const e = autoSuggest.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }

    yield put({ type: ADMIN_AUTO_SUGGEST_FETCH_SUCCEEDED, payload: autoSuggest })
  } catch (e) {
    yield put({ type: ADMIN_AUTO_SUGGEST_FETCH_FAILED })
  }
}

export function* sendAddMemberToGroup(action) {
  try {
    const addedMembers = yield call(axios, {
      method: 'post',
      url: `${API_GATEWAY_URL}/bi_reporting_assets/v2/group_users`,
      data: {
        group_id: action.payload.groupId,
        users: action.payload.data,
      },
    })

    if (addedMembers.error) {
      const e = addedMembers.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }

    yield put({ type: ADMIN_ADD_MEMBERS_GROUP_SUCCEEDED, payload: addedMembers })
  } catch (e) {
    yield put({ type: ADMIN_ADD_MEMBERS_GROUP_FAILED })
  }
}

export function* sendAddAdGroup(action) {
  try {
    const addAdGroup = yield call(axios, {
      method: 'put',
      url: `${API_GATEWAY_URL}/bi_reporting_assets/v2/groups/${action?.payload?.groupId}`,
      data: action.payload?.adGroupPayload,
    })

    if (addAdGroup.error) {
      const e = addAdGroup.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }

    yield put({ type: ADMIN_ADD_AD_GROUP_SUCCEEDED, payload: addAdGroup })
  } catch (e) {
    yield put({ type: ADMIN_ADD_AD_GROUP_FAILED, payload: e })
  }
}

export function* sendDeleteMemberFromGroup(action) {
  try {
    const deletedMembers = yield call(axios, {
      method: 'delete',
      url: `${API_GATEWAY_URL}/bi_reporting_assets/v2/group_users`,
      data: {
        group_id: action.payload.groupId,
        users: action.payload.data,
      },
    })

    if (deletedMembers.error) {
      const e = deletedMembers.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }

    yield put({ type: ADMIN_DELETE_MEMBERS_GROUP_SUCCEEDED, payload: deletedMembers })
  } catch (e) {
    yield put({ type: ADMIN_DELETE_MEMBERS_GROUP_FAILED })
  }
}

export function* sendAddGroupProps(action) {
  try {
    const addedGroupProps = yield call(axios, {
      method: 'post',
      url: `${API_GATEWAY_URL}/bi_reporting_assets/v2/group_properties?group_id=${action.payload.groupId}&object_type=${action.payload.objectType}`,
      data: {
        object_id_list: action.payload.data,
      },
    })

    if (addedGroupProps.error) {
      const e = addedGroupProps.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }

    yield put({ type: ADMIN_ADD_GROUP_PROPERTIES_SUCCEEDED, payload: addedGroupProps })
  } catch (e) {
    yield put({ type: ADMIN_ADD_GROUP_PROPERTIES_FAILED })
  }
}

export function* sendRemoveGroupProps(action) {
  try {
    const removedGroupProps = yield call(axios, {
      method: 'delete',
      url: `${API_GATEWAY_URL}/bi_reporting_assets/v2/group_properties?group_id=${action.payload.groupId}&object_type=${action.payload.objectType}`,
      data: {
        object_id_list: action.payload.data,
      },
    })

    if (removedGroupProps.error) {
      const e = removedGroupProps.error
      throw new Error(`status: ${e.code}, ${e.type} - ${e.message.value}`)
    }

    yield put({ type: ADMIN_REMOVE_GROUP_PROPERTIES_SUCCEEDED, payload: removedGroupProps })
  } catch (e) {
    yield put({ type: ADMIN_REMOVE_GROUP_PROPERTIES_FAILED })
  }
}
