import React from 'react'
import PropTypes from 'prop-types'
import { Button, Dialog, DialogTitle, InputLabel, Toolbar, FormHelperText, Typography, Grid } from '@mui/material'
import { Lens } from '@mui/icons-material'
import debounce from 'lodash/debounce'
import isEqual from 'lodash/isEqual'
import isEmpty from 'lodash/isEmpty'
import CreatableSelect from 'react-select/creatable'
import { components } from 'react-select'
import { Spinner, createErrMsg } from 'greenfield-utilities'

import { GreenfieldAutoCompleteSelect } from '../../shared/shared'

import '../../../styles/commonElements/button/_button.scss'

class AddRefreshDatasetDialog extends React.Component {
  handleDelayedSearch = debounce(this.props.getDatastoreDatasetList, 1000)
  state = {
    selectedDataset: '',
    datastoreSuggestions: [],
    selectedDatastore: {},
    datasetListSuggestions: [],
    showFailedStatus: false,
  }

  handleSelectedDatasetClick = selectedDataset => {
    this.setState({
      selectedDataset,
    })
  }

  handleDeleteSelectedDataset = () => {
    this.setState({
      selectedDataset: '',
    })

    this.callGetDatasetList(this.state.selectedDatastore, '')
  }

  selectedDatastoreSuggestions = selectedDatastore => {
    if (selectedDatastore && !isEqual(this.state.selectedDatastore, selectedDatastore)) {
      this.setState({ selectedDatastore, selectedDataset: '', datasetListSuggestions: [] })
      this.callGetDatasetList(selectedDatastore, '')
    }
  }

  handleSelectedDataset = selectedDataset => {
    this.setState({
      selectedDataset,
    })
  }

  handleRefreshDataset = () => {
    const { selectedDatastore, selectedDataset } = this.state
    const datasetName = selectedDataset.dataset_name || selectedDataset.label

    this.props.refreshDataset({ greenfield_platform: selectedDatastore.datastore_name, dataset_name: datasetName })
  }

  handleCancel = () => {
    if (!this.isRefressOrAddDatasetRequested()) {
      this.props.onRefreshPostgresDatabaseClose()
      this.resetChanges()
    }
  }

  componentDidUpdate(prevProps) {
    this.onDatastoreListStatus(prevProps)
    this.onDatasetListStatus(prevProps)
    this.onRefreshOrAddDatasetStatus(prevProps)
  }

  onDatastoreListStatus(prevProps) {
    const { datastoresListStatus } = this.props

    if (datastoresListStatus && !isEqual(prevProps.datastoresListStatus, datastoresListStatus)) {
      if (datastoresListStatus.data && datastoresListStatus.status >= 200 && datastoresListStatus.status < 300) {
        const datastoreSuggestions = datastoresListStatus.data.map(datastore => ({
          ...datastore,
          label: datastore.datastore_name,
          value: datastore.datastore_name,
        }))

        this.setState({ datastoreSuggestions, showFailedStatus: false })
      } else if (datastoresListStatus.status === 'failed') {
        this.setState({ showFailedStatus: true })
      }
    }
  }

  onDatasetListStatus(prevProps) {
    const { datastoreDatasetsListStatus } = this.props

    if (datastoreDatasetsListStatus && !isEqual(prevProps.datastoreDatasetsListStatus, datastoreDatasetsListStatus)) {
      if (
        datastoreDatasetsListStatus.status >= 200 &&
        datastoreDatasetsListStatus.status < 300 &&
        datastoreDatasetsListStatus.data
      ) {
        const datasetListSuggestions = datastoreDatasetsListStatus.data.reduce((accumulator, dataset) => {
          if (dataset && dataset.edit_enabled && dataset.edit_enabled.toLowerCase() === 'yes') {
            dataset.label = dataset.dataset_business_name
            dataset.value = dataset.dataset_name

            accumulator.push(dataset)
          }

          return accumulator
        }, [])

        this.setState({ datasetListSuggestions, showFailedStatus: false })
      } else if (datastoreDatasetsListStatus.status === 'failed') {
        this.setState({ showFailedStatus: true })
      }
    }
  }

  onRefreshOrAddDatasetStatus(prevProps) {
    const { refreshDatasetStatus, routeProps, isPreview } = this.props
    if (refreshDatasetStatus && !isEqual(prevProps.refreshDatasetStatus, refreshDatasetStatus)) {
      if (refreshDatasetStatus.status >= 200 && refreshDatasetStatus.status < 300 && refreshDatasetStatus.data) {
        this.resetChanges()
        if (isPreview) {
          routeProps.history.push(`/dataset/preview/${refreshDatasetStatus.data._id}/columns`)
        } else {
          routeProps.history.replace(`/dataset/preview/${refreshDatasetStatus.data._id}/columns`)
        }
      } else if (refreshDatasetStatus.status === 'failed') {
        this.setState({ showFailedStatus: true })
      }
    }
  }

  callGetDatasetList(selectedDatastore, searchValue) {
    if (!isEmpty(selectedDatastore)) {
      this.props.getDatastoreDatasetList({ _id: selectedDatastore._id, searchValue })
    }
  }

  resetChanges() {
    this.setState({
      selectedDataset: '',
      datasetListSuggestions: [],
      selectedDatastore: {},
      showFailedStatus: false,
    })
  }

  isRefressOrAddDatasetRequested() {
    const { refreshDatasetStatus } = this.props

    return Boolean(refreshDatasetStatus && refreshDatasetStatus.status === 'requested')
  }

  isDatasetListRequested() {
    const { datastoreDatasetsListStatus } = this.props

    return Boolean(datastoreDatasetsListStatus && datastoreDatasetsListStatus.status === 'requested')
  }

  showErrorOnApiCallFailed(statusObj, defaultErrorMessage) {
    if (statusObj && statusObj.status === 'failed' && this.state.showFailedStatus) {
      const errorMessage = createErrMsg(statusObj, defaultErrorMessage)
      return (
        <FormHelperText id="show-error" className="error-label" aria-label={errorMessage}>
          {errorMessage}
        </FormHelperText>
      )
    }
  }

  handleDefaultFocusOnTab = event => {
    if (event.keyCode === 9) {
      if (!event.shiftKey) {
        this.refCancel.focus()
      }
    }
  }

  setDefaultFocusOnShiftTab = event => {
    if (event.shiftKey && event.keyCode === 9) {
      this.datastoreRef.focus()
    }
  }

  renderSelectDataset() {
    const { selectedDataset, datasetListSuggestions, selectedDatastore } = this.state
    const { Option } = components

    const IconOption = props => {
      return (
        <Option {...props} role="option">
          {props.isFocused && <Lens className="dot-format" />}
          {props.data.label}
        </Option>
      )
    }

    const colourStyles = {
      option: (styles, { isFocused, isSelected }) => {
        return {
          ...styles,
          backgroundColor: isSelected ? '#CCCCCC' : isFocused ? '#F0EFEF' : null,
        }
      },
    }

    return (
      <>
        <CreatableSelect
          isDisabled={
            this.isDatasetListRequested() || isEmpty(selectedDatastore) || this.isRefressOrAddDatasetRequested()
          }
          id="select-dataset-container"
          inputId="select-dataset-input-container"
          aria-label="Search / Create / Select Dataset Name: suggestions appear below on press of tab or arrow down key button"
          onChange={this.handleSelectedDataset}
          options={datasetListSuggestions}
          placeholder="Search / Create / Select Dataset Name"
          maxMenuHeight={140}
          value={selectedDataset}
          components={{ Option: IconOption }}
          styles={colourStyles}
        />
        {this.isDatasetListRequested() && (
          <Spinner
            className="dataset-loader"
            aria-label="loading dataset list for selected connection is in progress"
            layout="selfCentering"
            size="small"
            role="alert"
          />
        )}
      </>
    )
  }

  render() {
    const { open, datastoresListStatus, datastoreDatasetsListStatus, refreshDatasetStatus } = this.props
    const { datastoreSuggestions, selectedDataset, selectedDatastore } = this.state
    return (
      <Dialog
        id="refresh-dataset-dialog"
        className="refresh-dataset"
        open={open}
        onClose={this.handleCancel}
        role="dialog"
        aria-labelledby="add and refresh postgres dataset dialog"
      >
        <Toolbar>
          <DialogTitle
            id="add-refress-dialog-title"
            className="dialog-title"
            aria-label="ADD/ REFRESH POSTGRES DATASET"
          >
            <Typography variant="h2" component="p">
              ADD/REFRESH POSTGRES DATASET
            </Typography>
          </DialogTitle>
        </Toolbar>

        <div className="refresh-dataset-content">
          <Grid container justifyContent="space-between">
            <Grid item xs={3} className="field-label">
              <InputLabel>Select Connection</InputLabel>
            </Grid>
            <Grid item xs={9}>
              <span
                role="searchbox"
                tabIndex={-1}
                ref={refFocus => {
                  this.refCancel = refFocus
                }}
              />
              <GreenfieldAutoCompleteSelect
                tabIndex={0}
                isDisabled={this.isRefressOrAddDatasetRequested()}
                id="datastore-select-container"
                inputId="datastore-select-input-container"
                ariaLabel="Search / Select Connection: suggestions appear below on press of tab or arrow down key button"
                isDefaultHeight
                customWidth
                isReset={false}
                label="Search / Select Connection"
                suggestions={datastoreSuggestions}
                selectedSuggestions={this.selectedDatastoreSuggestions}
                shiftTab={this.setDefaultFocusOnShiftTab}
              />
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={3} className="field-label field-dataset-label">
              <InputLabel>Select Dataset</InputLabel>
            </Grid>
            <Grid item xs={9} className="field-label field-dataset-element">
              {this.renderSelectDataset()}
            </Grid>
          </Grid>
        </div>
        <div className="action-buttons">
          <Button
            color="primary"
            id="refresh-add-btn"
            aria-label={selectedDataset && selectedDataset.dataset_name ? 'REFRESH DATASET' : 'ADD DATASET'}
            className="button-spacing"
            onClick={this.handleRefreshDataset}
            disabled={isEmpty(selectedDataset) || isEmpty(selectedDatastore) || this.isRefressOrAddDatasetRequested()}
          >
            {selectedDataset && selectedDataset.dataset_name ? 'REFRESH DATASET' : 'ADD DATASET'}
          </Button>
          <Button
            color="secondary"
            id="cancel-btn"
            aria-label="cancel add and refresh dataset"
            onClick={this.handleCancel}
            onKeyDown={this.handleDefaultFocusOnTab}
            disabled={this.isRefressOrAddDatasetRequested()}
          >
            CANCEL
          </Button>
          <span
            tabIndex={-1}
            ref={refFocus => {
              this.datastoreRef = refFocus
            }}
          />
          {this.showErrorOnApiCallFailed(
            refreshDatasetStatus,
            'Something went wrong with add/refresh dataset query.'
          ) ||
            this.showErrorOnApiCallFailed(
              datastoreDatasetsListStatus,
              'Something went wrong with get dataset list query.'
            ) ||
            this.showErrorOnApiCallFailed(
              datastoresListStatus,
              'Something went wrong with get datastore connection query.'
            )}
        </div>
        {this.isRefressOrAddDatasetRequested() && (
          <Spinner
            className="spinner-layout"
            aria-label="refresh dataset request is in progress"
            layout="selfCentering"
            size="large"
            role="alert"
          />
        )}
      </Dialog>
    )
  }
}

AddRefreshDatasetDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onRefreshPostgresDatabase: PropTypes.func,
  onRefreshPostgresDatabaseClose: PropTypes.func,
  getDatastoreDatasetList: PropTypes.func,
  refreshDataset: PropTypes.func,
  datastoresListStatus: PropTypes.object,
  datastoreDatasetsListStatus: PropTypes.object,
  refreshDatasetStatus: PropTypes.object,
  routeProps: PropTypes.object,
}

export default AddRefreshDatasetDialog
