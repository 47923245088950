import { Add, Close, Delete, Help } from '@mui/icons-material'
import {
  Grid,
  CircularProgress,
  TextField,
  Typography,
  Radio,
  FormControlLabel,
  Checkbox,
  Button,
  Paper,
  Stack,
  Tooltip,
  IconButton,
  Snackbar,
  Alert,
  MenuItem,
} from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, Redirect, Route } from 'react-router-dom'
import { createErrMsg } from 'greenfield-utilities'
import { displayServiceErrorMessage } from '../../../ducks/layout'
import {
  getDatasetPreview,
  updateDatasetOnEdit,
  patchDataset,
  delDataset,
  clearDatasetPreview,
  clearUpdateStatus,
} from '../../../ducks/dataset'
import { getDetailsOwners } from '../../../ducks/builder'
import {
  getSecurityControlList,
  getSecurityControlDetail,
  getGroupSearchDetail,
  getSingleGroupDetail,
} from '../../../ducks/securityControl'
import NotFoundPage from '../NotFoundPage/NotFoundPage'
import AccessDeniedError from '../../shared/AccessDeniedError'
import GFAutocomplete, { filterUserType, SChip } from '../../shared/MUIAutoComplete/Autocomplete'
import BusinessAreaComponent from '../../shared/BusinessAreaComponent/BusinessAreaComponent'
import ConfirmDialogComp from '../../shared/Dialog/ConfirmDialog'
import SecurityControl from './SecurityControl.js'
import DatasetSupportDialog from './DatasetSupportDialog'
import DatasetDeleteDialog from './DatasetCellRenderer/DatasetDeleteDialog'

const DatasetEdit = props => {
  const { setHeaderTitle, routeProps } = props
  const [datasetData, setDatasetData] = useState(null)
  const [displayName, setDisplayName] = useState('')
  const [description, setDescription] = useState('')
  const [isDeleteOpen, setIsDeleteOpen] = useState(false)
  const [isSaveDatasetDialogOpen, setIsSaveDatasetDialogOpen] = useState(false)
  const [isEditable, setIsEditable] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [successMessage, setSuccessMessage] = useState(false)
  const [isShowPreview, setIsShowPreview] = useState(false)
  const [datasetCardsDialogOpen, setDatasetCardsDialogOpen] = useState(false)
  const [saveWarning, setSaveWarning] = useState('')
  const [applicationCI, setApplicationCI] = useState('')
  const [applicationCIError, updateApplicationCIError] = useState('')

  // Data Retention
  const [retentionCount, setRetentionCount] = useState('')
  const [retentionUnit, setRetentionUnit] = useState('')
  const [retentionCalendar, setRetentionCalendar] = useState('')
  const [retentionAlert, setRetentionAlert] = useState('')

  // Owners
  const [ownersList, setOwnersList] = useState([])

  // Business Area
  const [businessAreaList, setBusinessAreaList] = useState([])

  // Security
  const [privacy, setPrivacy] = useState('')
  const [isVendorAccessEnabled, setIsVendorAccessEnabled] = useState(false)
  const [viewersList, setViewersList] = useState([])
  const [vendorsList, setVendorsList] = useState([])
  const [isNonOwnerCFCreationAllowed, setIsNonOwnerCFCreationAllowed] = useState(false)

  // Add security controls
  const [isOpenSecurityControl, setIsOpenSecurityControl] = useState(false)
  const [dimensionCols, setDimensionCols] = useState([])
  const [metricDimensionCols, setMetricDimensionCols] = useState([])
  const [datasetColumns, setDatasetColumns] = useState([])
  const [securityControlData, setSecurityControlData] = useState([])
  const [securityControlUpdated, setSecurityControlUpdated] = useState(false)

  // Support Text
  const [isShowHelp, setIsShowHelp] = useState(false)
  const [supportText, setSupportText] = useState('')

  const dispatch = useDispatch()

  const { datasetPreviewStatus, updateDatasetStatus, datasetDelStatus } = useSelector(state => state.dataset)
  const { detailsOwnersStatus } = useSelector(state => state.builder)
  const { securityControlInfoStatus, securityControlDetailStatus, groupSearchStatus, singleGroupStatus } = useSelector(
    state => state.securityControl
  )

  const { _id, _siteName } = routeProps.match.params

  useEffect(() => {
    if (datasetDelStatus?.status === 204) {
      dispatch(getDatasetPreview({ datasetId: _id }))
    } else if (datasetDelStatus?.status === 'failed') {
      const errorMessage = datasetDelStatus?.response?.data?.message
      dispatch(
        displayServiceErrorMessage(
          errorMessage || 'Something went wrong with the request to delete dataset, please try again later!'
        )
      )
    }
  }, [datasetDelStatus])

  useEffect(() => {
    if (updateDatasetStatus?.data) {
      if (securityControlUpdated) {
        setSecurityControlUpdated(false)
        setSecurityControlData(updateDatasetStatus.data.security_control)
      } else {
        dispatch(getDatasetPreview({ datasetId: _id }))
      }
      setSuccessMessage(true)
      setIsLoading(false)
      dispatch(clearUpdateStatus())
    } else if (updateDatasetStatus?.status === 'failed') {
      dispatch(
        displayServiceErrorMessage(createErrMsg(updateDatasetStatus, 'Dataset update failed. Please try again later.'))
      )
    }
  }, [updateDatasetStatus])

  useEffect(() => {
    setSuccessMessage(false)
    setIsShowPreview(false)
    setHeaderTitle('Edit Dataset')
    if (_id) {
      dispatch(getDatasetPreview({ datasetId: _id }))
    }
    return () => {
      dispatch(clearDatasetPreview())
      dispatch(clearUpdateStatus())
    }
  }, [_id])

  useEffect(() => {
    if (datasetPreviewStatus?.status === 'failed') {
      setDatasetData(datasetPreviewStatus.message.data)
    } else if (datasetPreviewStatus?.data) {
      const datasetData = datasetPreviewStatus.data
      setDatasetData(datasetData)
      setDisplayName(datasetData.dataset_business_name)
      setDescription(datasetData.dataset_description)
      setPrivacy(datasetData.data_classification)
      setIsVendorAccessEnabled(datasetData.enable_vendor_access)
      setIsNonOwnerCFCreationAllowed(datasetData.disable_non_owner_cf_creation)
      setIsEditable(datasetData.edit_enabled === 'yes')
      setSupportText(datasetData.support_details.text)

      setOwnersList(datasetData.owners_access)
      setApplicationCI(datasetData.applicationCI)

      const existingBusinessAreas = datasetData.business_use?.map(businessUse => businessUse.label || businessUse)
      setBusinessAreaList(existingBusinessAreas)

      const existingViewersList = datasetData.viewers_access?.filter(users => users.user_type === 'internal')
      setViewersList(existingViewersList)

      const existingVendorsList = datasetData.viewers_access?.filter(users => users.user_type === 'external')
      setVendorsList(existingVendorsList)

      // data retention
      const dataRetentionN = datasetData.data_retention?.n
      setRetentionCount(dataRetentionN)
      const dataRetentionCalendar = datasetData.data_retention?.calendar_type.toLowerCase()
      setRetentionCalendar(dataRetentionCalendar)
      const dataretentionUnit = datasetData.data_retention?.units.toLowerCase().concat('s')
      setRetentionUnit(dataretentionUnit)

      // security
      const COLUMN_TYPES = ['metric', 'dimension']
      const dimensionCols =
        datasetData.fields_array?.filter(
          column => column.type === 'dimension' || column.type === 'dynamic_dimension'
        ) || []
      setDimensionCols(dimensionCols)
      const metricDimensionCols = datasetData.fields_array?.filter(column => COLUMN_TYPES.includes(column.type)) || []
      setMetricDimensionCols(metricDimensionCols)
      setDatasetColumns(datasetData.columns)
      setSecurityControlData(datasetData.security_control)
    }
  }, [datasetPreviewStatus])

  const handlePrivacyChange = event => {
    setPrivacy(event.target.value)
    const viewersList = getViewersList(event.target.value, isVendorAccessEnabled)
    setViewersList(viewersList)
  }

  const getViewersList = () => {
    if (privacy !== 'Internal') {
      return isVendorAccessEnabled ? viewersList : viewersList.filter(users => users.user_type === 'internal')
    }
    return isVendorAccessEnabled ? viewersList.filter(users => users.user_type === 'external') : []
  }

  const isColumnSecurity = () => {
    const colTypeArray = ['metric', 'dimension']
    return datasetData.fields_array?.some(
      col => colTypeArray.includes(col.type) && col.data_classification === 'Confidential'
    )
  }

  const handleSnackBarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setSuccessMessage(false)
  }
  const handleEnableExternalAccessChange = () => {
    setIsVendorAccessEnabled(isVendorAccessEnabled => !isVendorAccessEnabled)
    const viewersList = getViewersList()
    setViewersList(viewersList)
    if (!isVendorAccessEnabled) {
      setVendorsList([])
    }
  }

  const handleIsNonOwnerCFCreationAllowed = () => {
    setIsNonOwnerCFCreationAllowed(isNonOwnerCFCreationAllowed => !isNonOwnerCFCreationAllowed)
  }

  const handleDeleteDataset = () => {
    if (_id) {
      dispatch(delDataset({ delDataset: _id }))
      setIsDeleteOpen(false)
    }
  }

  const handleSaveWarning = () => {
    if (securityControlData && vendorsList.length > 0) {
      setSaveWarning('')
    } else if (!securityControlData && vendorsList.length > 0) {
      setSaveWarning(
        <Typography>
          - No RLS has been configured, Any vendors with access will see ALL DATA in the dataset.`
        </Typography>
      )
    } else if (vendorsList.length === 0 && securityControlData) {
      setSaveWarning(
        <Typography>- Vendors will NOT have access as no vendor groups/users have been assigned.</Typography>
      )
    } else {
      setSaveWarning(
        <Typography>
          - Vendors will NOT have access as no vendor groups/users have been assigned. - No RLS has been configured, Any
          vendors with access will see ALL DATA in the dataset.
        </Typography>
      )
    }
  }

  const handleSaveDataset = () => {
    setIsLoading(true)
    setIsSaveDatasetDialogOpen(false)
    const payload = {
      _id,
      data_classification: privacy,
      business_use: businessAreaList,
      dataset_description: description,
      dataset_business_name: displayName,
      owners: filterUserType(ownersList, 'user'),
      owner_groups: filterUserType(ownersList, 'group'),
      user_view_access: [...filterUserType(viewersList, 'user'), ...filterUserType(vendorsList, 'user')],
      group_view_access: [...filterUserType(viewersList, 'group'), ...filterUserType(vendorsList, 'group')],
      enable_vendor_access: isVendorAccessEnabled,
      disable_non_owner_cf_creation: isNonOwnerCFCreationAllowed,
      data_retention: { calendar_type: retentionCalendar, n: retentionCount, units: retentionUnit },
      applicationCI,
    }
    setSaveWarning('')
    dispatch(updateDatasetOnEdit(payload))
  }

  // Security Control
  const handleSecurityControlChange = () => {
    setSecurityControlUpdated(true)
  }

  // Support
  const handleSaveSupportText = newSupportText => {
    const datasetInfo = {
      _id,
      support_details: { text: newSupportText },
    }
    setSupportText(newSupportText)
    setIsShowHelp(false)
    dispatch(updateDatasetOnEdit(datasetInfo))
  }

  const handleRetentionAlert = () => {
    if (retentionUnit !== undefined || retentionCount !== undefined) {
      setRetentionAlert(
        'Retention Policy: Last ' + retentionCount + ' ' + retentionUnit + ' (' + retentionCalendar + ')'
      )
    } else {
      setRetentionAlert('No Retention Policy')
    }
  }

  return props.isMobile ? (
    <Box m={3}>
      <Typography className="mobileMessage">Dataset features are not available on mobile</Typography>
    </Box>
  ) : (
    <Box m={3}>
      <Paper elevation={0} sx={{ padding: '1rem' }}>
        {!datasetData && (
          <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 800 }}>
            <CircularProgress color="inherit" />
          </Box>
        )}
        {datasetData && !isEditable && displayName !== '<dataset not found>' && (
          <AccessDeniedError
            errorMessage={datasetPreviewStatus}
            fallBackErrorMessage="You do not have access to edit the dataset, please reachout to owner(s) of dataset."
            ownerAccess={datasetData?.owners_access}
          />
        )}
        {displayName === '<dataset not found>' && <Route component={NotFoundPage} />}
        {datasetData && isEditable && (
          <>
            <Grid container direction="column" justifyContent="center">
              <Box m={1}>
                <Grid container>
                  <Grid item xs={6}>
                    <Stack direction="row" alignItems="center" gap={1} justifyContent="flex-start">
                      <Typography variant="button" style={{ fontSize: 18 }} tabIndex="0">
                        Dataset Details
                      </Typography>
                      <Tooltip title="Help" placement="top">
                        <IconButton id="card-view-help" onClick={() => setIsShowHelp(!isShowHelp)} aria-label="help">
                          <Help className="align-icon" />
                        </IconButton>
                      </Tooltip>
                    </Stack>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid container justifyContent="flex-end">
                      <Button
                        color="secondary"
                        aria-label="Delete Dataset"
                        startIcon={<Delete />}
                        onClick={() => {
                          datasetData?.cards?.length > 0 ? setDatasetCardsDialogOpen(true) : setIsDeleteOpen(true)
                        }}
                      >
                        Delete Dataset
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
              <Grid item>
                <Box m={1}>
                  <Typography>Display Name</Typography>
                  <TextField
                    fullWidth
                    onChange={event => setDisplayName(event.target.value)}
                    value={displayName}
                    variant="outlined"
                  />
                </Box>
              </Grid>

              <Grid item>
                <Box m={1}>
                  <Typography>Description</Typography>
                  <TextField
                    fullWidth
                    onChange={event => setDescription(event.target.value)}
                    value={description}
                    variant="outlined"
                    multiline
                    rows="4"
                  />
                </Box>
              </Grid>
              <Grid item>
                <Box m={1}>
                  <GFAutocomplete label="Owner(s)" value={ownersList} setValue={setOwnersList} />
                </Box>
              </Grid>
              <Grid item>
                <Box m={1}>
                  <BusinessAreaComponent
                    variant="outlined"
                    businessAreaList={businessAreaList}
                    setBusinessAreaList={setBusinessAreaList}
                  />
                </Box>
              </Grid>
              <Grid item>
                <Box m={1}>
                  <Typography tabIndex="0" variant="button" fontSize={16}>
                    Data Retention
                  </Typography>
                  <Tooltip title="Length of time to retain data in this dataset" placement="right">
                    <IconButton id="card-view-help" aria-label="help" onClick={() => handleRetentionAlert()}>
                      <Help className="align-icon" />
                    </IconButton>
                  </Tooltip>
                  <Stack direction="row" alignItems="center" gap={1} justifyContent="flex-start">
                    <TextField
                      label="Number of"
                      sx={{ width: '15%' }}
                      id="retention-count"
                      onChange={event => setRetentionCount(event.target.value)}
                      value={retentionCount}
                      varient="outlined"
                    />
                    {retentionUnit !== 'days' && retentionUnit !== 'weeks' ? (
                      <TextField
                        label="Calendar Type"
                        sx={{ width: '15%' }}
                        id="retention-calendar"
                        onChange={event => setRetentionCalendar(event.target.value)}
                        value={retentionCalendar}
                        varient="outlined"
                        select
                      >
                        <MenuItem value="fiscal">Fiscal</MenuItem>
                        <MenuItem value="gregorian">Gregorian</MenuItem>
                      </TextField>
                    ) : null}
                    <TextField
                      sx={{ width: '15%' }}
                      id="retention-unit"
                      value={retentionUnit}
                      onChange={event => setRetentionUnit(event.target.value)}
                      varient="outlined"
                      label="Unit"
                      select
                    >
                      <MenuItem value="years">Years</MenuItem>
                      <MenuItem value="months">Months</MenuItem>
                      <MenuItem value="weeks">Weeks</MenuItem>
                      <MenuItem value="days">Days</MenuItem>
                    </TextField>
                    <Typography>
                      completed
                      {retentionUnit ? `, plus current ${retentionUnit.slice(0, retentionUnit.length - 1)}` : ''}
                    </Typography>
                  </Stack>
                  <Stack direction="row" alignItems="center" gap={1} justifyContent="flex-start">
                    <Typography tabIndex="0" variant="button" fontSize={14} sx={{ width: '40% ' }}>
                      {retentionAlert}
                    </Typography>
                  </Stack>
                  <Stack direction="row" alignItems="center" gap={1} justifyContent="flex-start">
                    <Typography>Application CI</Typography>
                    <Tooltip title="App CI not Greenfield App CI" placement="top">
                      <IconButton id="id-view-help" aria-label="help">
                        <Help className="align-icon" />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                  <TextField
                    fullWidth
                    id="application-ci"
                    error={Boolean(applicationCIError)}
                    label={applicationCIError || ''}
                    onChange={event => {
                      const { value } = event.target
                      if (!/^CI\d{8}$/i.test(value)) {
                        updateApplicationCIError('Invalid Application CI. Hint: CI########')
                      } else if (applicationCIError) {
                        updateApplicationCIError('')
                      }
                      setApplicationCI(value)
                    }}
                    value={applicationCI}
                    variant="outlined"
                    inputProps={{
                      maxLength: 10,
                    }}
                  />
                </Box>
              </Grid>
              <Grid item>
                <Box m={1} mt={4}>
                  <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item>
                      <Typography tabIndex="0" variant="button" fontSize={16}>
                        Security
                      </Typography>
                    </Grid>
                    <Button
                      color="secondary"
                      startIcon={<Add />}
                      aria-label="Add or Edit Security Controls"
                      id="add-edit-security-ctrl"
                      onClick={() => {
                        setIsOpenSecurityControl(true)
                        dispatch(getSecurityControlList())
                      }}
                    >
                      Add/Edit Security Controls
                    </Button>
                  </Grid>
                </Box>
              </Grid>
              <Grid item>
                <Box m={1}>
                  <Typography variant="subtitle1" tabIndex="0">
                    Data Classification
                  </Typography>
                  <Radio
                    id="dataset-field-confidential"
                    checked={privacy === 'Confidential'}
                    onChange={handlePrivacyChange}
                    value="Confidential"
                    name="data_classification"
                    aria-label="Confidential"
                  />
                  Confidential
                  <Radio
                    id="dataset-field-internal"
                    checked={privacy === 'Internal'}
                    onChange={handlePrivacyChange}
                    value="Internal"
                    name="data_classification"
                    aria-label="Internal"
                  />
                  Internal
                </Box>
                {privacy === 'Confidential' && (
                  <Box m={1}>
                    <Box m={1}>
                      <GFAutocomplete label="Viewers(s)" value={viewersList} setValue={setViewersList} />
                    </Box>
                  </Box>
                )}
                <Grid item>
                  <Box m={1}>
                    <FormControlLabel
                      htmlFor="dataset-enable-external-access"
                      control={
                        <Checkbox
                          aria-label="Enable External Access"
                          checked={isVendorAccessEnabled}
                          onChange={handleEnableExternalAccessChange}
                          id="dataset-enable-external-accessn"
                        />
                      }
                      label={<Typography variant="subtitle1">Enable External Access</Typography>}
                    />
                  </Box>
                </Grid>
                {isVendorAccessEnabled && (
                  <Box m={1}>
                    <GFAutocomplete
                      value={vendorsList}
                      setValue={setVendorsList}
                      label="Vendor(s)"
                      vendors
                      id="vendor-autocomplete"
                    />
                  </Box>
                )}
              </Grid>
              <Grid item>
                <Box m={1}>
                  <FormControlLabel
                    htmlFor="dataset-enable-cf-creation"
                    control={
                      <Checkbox
                        aria-label="Limit Dataset Calculated Field Creation to Owners"
                        checked={isNonOwnerCFCreationAllowed}
                        onChange={handleIsNonOwnerCFCreationAllowed}
                        id="dataset-limit-calc-fields-creation"
                      />
                    }
                    label={
                      <Typography variant="subtitle1">Limit Dataset Calculated Field Creation to Owners</Typography>
                    }
                  />
                </Box>
              </Grid>
              <Grid item>
                <Box m={1}>
                  <Typography variant="subtitle1" tabIndex="0">
                    Row Level Security
                  </Typography>
                  {securityControlData?.controls ? (
                    securityControlData.controls.map(eachControl => (
                      <SChip
                        component="span"
                        className="chip-business"
                        data-cy="chip-rls"
                        key={eachControl.security_control_id}
                        label={eachControl.security_control_id}
                        tabIndex={-1}
                      />
                    ))
                  ) : (
                    <Typography tabIndex="0">None</Typography>
                  )}
                </Box>
              </Grid>
              <Grid item>
                <Box m={1}>
                  <Typography variant="subtitle1" tabIndex="0">
                    Column Level Security
                  </Typography>
                  <Typography tabIndex="0">{isColumnSecurity() ? 'Applied' : 'None'}</Typography>
                </Box>
              </Grid>
            </Grid>
            <Box mt={10}>
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <Button
                    component={Link}
                    data-cy="return-to-dataset"
                    to={_siteName ? `/site/${_siteName}/dataset/preview/${_id}` : `/dataset/preview/${_id}`}
                    color="secondary"
                  >
                    Return to Dataset
                  </Button>
                  <Button
                    onClick={() => {
                      handleSaveWarning()
                      !isVendorAccessEnabled ? handleSaveDataset() : setIsSaveDatasetDialogOpen(true)
                    }}
                    disabled={!ownersList?.length || isLoading || Boolean(applicationCIError)}
                    aria-label="Save and Update Dataset"
                    data-cy="dataset-save"
                  >
                    {isLoading && <CircularProgress size={20} color="inherit" style={{ marginRight: '0.5rem' }} />}
                    Save Changes
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </>
        )}
        {datasetCardsDialogOpen && (
          <DatasetDeleteDialog
            datasetDetail={datasetData}
            handleCloseDeleteDatasetDialog={() => setDatasetCardsDialogOpen(false)}
          />
        )}
        <ConfirmDialogComp
          open={isDeleteOpen}
          contentText={`Are you sure you want to delete dataset - ${displayName}? (There is no undo)`}
          okText="DELETE"
          onCloseDialog={() => setIsDeleteOpen(false)}
          onClickOk={handleDeleteDataset}
          onClickCancel={() => setIsDeleteOpen(false)}
        />
        <ConfirmDialogComp
          open={isSaveDatasetDialogOpen}
          contentText={
            <Typography>
              Warning: This dataset has external vendor access enabled. Please ensure proper vendor access groups and
              RLS are applied to avoid unexpected data exposure to vendors. {saveWarning}
            </Typography>
          }
          okText="SAVE"
          onCloseDialog={() => setIsSaveDatasetDialogOpen(false)}
          onClickOk={handleSaveDataset}
          onClickCancel={() => setIsSaveDatasetDialogOpen(false)}
        />
        {isOpenSecurityControl && (
          <SecurityControl
            currentSecurityControl={securityControlData}
            dimCols={dimensionCols}
            securityControlList={securityControlInfoStatus}
            securityControlDetail={securityControlDetailStatus}
            getSecurityControlDetail={data => dispatch(getSecurityControlDetail(data))}
            updateDatasetStatus={updateDatasetStatus}
            updateDatasetOnEdit={data => {
              dispatch(updateDatasetOnEdit(data))
              dispatch(clearUpdateStatus(data))
            }}
            datasetId={_id}
            getGroupSearchDetail={data => dispatch(getGroupSearchDetail(data))}
            groupSearchDetail={groupSearchStatus}
            getSingleGroupDetail={data => dispatch(getSingleGroupDetail(data))}
            singleGroupDetail={singleGroupStatus}
            setEditable={handleSecurityControlChange}
            togglePopover={() => setIsOpenSecurityControl(false)}
            securityControlObj={securityControlData}
            getDetailsOwners={data => dispatch(getDetailsOwners(data))}
            detailsOwnersStatus={detailsOwnersStatus}
            metricDimCols={metricDimensionCols}
            patchDataset={data => {
              dispatch(patchDataset(data))
            }}
            columns={datasetColumns}
            showPopover
          />
        )}
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          open={successMessage}
          autoHideDuration={6000}
          onClose={handleSnackBarClose}
          action={[<Close key="error-message-close-icon" onClick={handleSnackBarClose} />]}
        >
          <Alert severity="success">Dataset updated successfully!</Alert>
        </Snackbar>
        {isShowPreview && <Redirect to={`/dataset/preview/${_id}`} />}
      </Paper>
      {isShowHelp && (
        <DatasetSupportDialog
          savedSupportText={supportText}
          onSaveSupportText={handleSaveSupportText}
          onCloseSupportText={() => setIsShowHelp(false)}
          isDatasetEditable={isEditable}
        />
      )}
    </Box>
  )
}

export default DatasetEdit
