import './sites.scss'

import React, { useEffect } from 'react'
import { Typography } from '@mui/material'

import DatasetListing from '../Datasets/DatasetListing.container'

const SiteDatasets = ({ isMobile = false, setHeaderTitle, siteObj = {}, routeProps = {} }) => {
  useEffect(() => {
    if (siteObj.status >= 200 && siteObj.status < 300) {
      setHeaderTitle(siteObj?.data?.name)
    }
  }, [siteObj])

  return (
    <div className="site-tab">
      <Typography variant="h4" className="site-info-label">
        This page is a listing of promoted datasets for the site.
      </Typography>
      {Object.keys(siteObj).length > 1 && (
        <DatasetListing isMobile={isMobile} routeProps={routeProps} siteObj={siteObj} />
      )}
    </div>
  )
}

export default SiteDatasets
