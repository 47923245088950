import { FormControl, MenuItem, Select, InputLabel } from '@mui/material'
import React from 'react'
import { GRANULARITY_OPTIONS } from '../../constants/ArrayConstants'
import { isVirtualColumnCheck } from '../../routes/Builder/Sidebar/utils'

const TimePeriodGranularity = ({
  selectedDataset = {},
  timePeriod = {},
  isDashboard = false,
  onGranularityChange,
  cardInfo = {},
  columnObj = {},
}) => {
  const cardConfig = cardInfo?.data?.card_config
  const graphByGrain = isDashboard
    ? GRANULARITY_OPTIONS
    : cardConfig
    ? selectedDataset.valid_granularity_options || []
    : []
  const selectedValue =
    !timePeriod.granularity || timePeriod.granularity?.toLowerCase() === 'none' ? 'All' : timePeriod.granularity

  const columns = cardConfig?.card_query_attribute?.columns || []
  const isTimeSeries =
    columns.some(
      col => col.type === 'timeseries' && isVirtualColumnCheck(col) && columnObj.field_name === col.field_name
    ) || isDashboard
  return (
    isTimeSeries && (
      <FormControl variant="standard" sx={{ m: 1, minWidth: 200 }}>
        <InputLabel data-cy="graph-by-grain-label">Graph Granularity</InputLabel>
        <Select
          labelId="graph-by-grain-select-label"
          data-cy="graph-by-grain-select"
          value={selectedValue}
          onChange={event => {
            timePeriod.granularity = event.target.value
            onGranularityChange(timePeriod)
          }}
          label="Graph Granularity"
        >
          {graphByGrain.map(option => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    )
  )
}

export default TimePeriodGranularity
