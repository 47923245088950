import './notificationManager.scss'
import '../../../styles/global.scss'

import React, { useState, useEffect } from 'react'
import { Typography, Toolbar, Snackbar, Tabs, Tab, AppBar, Paper, IconButton, Tooltip, Box, Grid } from '@mui/material'
import Button from '@mui/material/Button'
import { Add, ArrowBackIos, CallToAction, DeleteOutline, EditOutlined, Email, Public } from '@mui/icons-material'
import { Link } from 'react-router-dom'
import debounce from 'lodash/debounce'
import cloneDeep from 'lodash/cloneDeep'
import capitalize from 'lodash/capitalize'
import moment from 'moment'
import SwipeableViews from 'react-swipeable-views'
import { useDispatch, useSelector } from 'react-redux'
import { getUserAccess } from '../../../ducks/user'
import { AgGridTable } from '../../shared/AgGrid/AgGridTableClientSide'
import NotificationAuthoring from './NotificationAuthoring'
import DataAlertNotificationListing from './DataAlertsNotificationListing'

const NotificationManager = ({
  notificationCreateAction,
  notificationCreateStatus,
  notificationEditAction,
  notificationEditStatus,
  displayServiceErrorMessage,
  fetchAllNotificationAction,
  notificationFetchAllStatus,
  deleteNotificationAction,
  deleteNotificationStatus,
  publishNotificationAction,
  publishNotificationStatus,
  setHeaderTitle,
  getDetailsOwnersAction,
  detailsOwnersStatus,
  currentUser,
  createNotification,
  createObjectNotificationStatus,
  fetchObjectNotificationListing,
  clearObjectNotificationStatus,
  getObjectListings = {},
  updateNotification,
  deleteObjectNotification,
  updateNotificationStatus,
  deleteObjectNotificationStatus,
  clearDeleteObjectNotificationStatus,
  routeProps,
  isMobile,
  getCardMeta,
  cardInfoStatus,
  viewDashBoardInfoStatus,
  getViewDashboardInfo,
  getDatasetDetail,
  datasetDetailStatus,
  trackEvent,
  sendFireflyEvent,
  getObjectNotificationBasedOnId,
  objectIdNotificationStatus = {},
  isEdit,
}) => {
  const dispatch = useDispatch()
  const objectType = routeProps?.match?.params.navigationType || ''
  const objectId = routeProps?.match?.params._id || ''

  const userAccessStatus = useSelector(state => state.user.userAccessStatus)
  const [userHasSubscriptionAccess, setUserHasSubscriptionAccess] = useState(false)
  const [asset, setAsset] = useState(null)
  const [editMode, setEditMode] = useState({})
  const [isWaitingForDelete, setIsWaitingForDelete] = useState(false)
  const [deleteStatus, setDeleteStatus] = useState(false)
  const [routeObjectType, setRouteObjectType] = useState(routeProps?.match?.params?.navigationType || '')
  const [routeObjectId, setRouteObjectId] = useState(routeProps?.match?.params?._id || -1)
  const [routeObjectNotificationId] = useState(routeProps?.match?.params?.notificationId || '')
  const [notificationOptionType, setNotificationOptionType] = useState(
    currentUser?.data?.is_admin !== 'yes' || (routeObjectType && routeObjectId > 0) ? 'object-notification' : ''
  )
  const [createMode, setCreateMode] = useState(
    (routeObjectType && routeObjectId && !routeObjectNotificationId > 0) || false
  )
  const [notificationGlobalTableData, setNotificationGlobalTableData] = useState([])
  const [notificationObjectTableData, setNotificationObjectTableData] = useState([])
  const pageSize = 100
  const [value, setValue] = useState(0)

  const handleTabChange = (event, newValue) => {
    setValue(newValue)
  }

  const handleChangeIndex = index => {
    setValue(index)
  }

  useEffect(() => {
    fetchObjectNotificationListing({ pageIndex: 1, pageSize, objectType, objectId })
    setHeaderTitle('Notifications')
    sendFireflyEvent(true, {
      eventName: routeObjectType
        ? routeObjectNotificationId
          ? `edit${capitalize(routeObjectType)}ObjectNotificationClicked`
          : `create${capitalize(routeObjectType)}ObjectNotificationClicked`
        : 'notificationManagerList',
      key: 'notification',
      data: {},
    })
    dispatch(getUserAccess())
  }, [objectType, objectId])

  useEffect(() => {
    if (userAccessStatus?.data) {
      setUserHasSubscriptionAccess(userAccessStatus.data.SUBSCRIPTION_CREATE)
    }
  }, [userAccessStatus])

  useEffect(() => {
    setAsset(routeProps.match.params)
    if (currentUser && currentUser.status >= 200 && currentUser.status < 300 && currentUser.data?.is_admin === 'yes') {
      fetchAllNotificationAction()
    }
  }, [currentUser])

  useEffect(() => {
    if (deleteObjectNotificationStatus?.status >= 200 && deleteObjectNotificationStatus?.status < 300) {
      setIsWaitingForDelete(false)
      setDeleteStatus(true)
      fetchObjectNotificationListing({ pageIndex: 1, pageSize, objectType, objectId })
      clearDeleteObjectNotificationStatus()
      cancelCreateNotification()
    } else if (deleteObjectNotificationStatus?.status === 'failed') {
      setIsWaitingForDelete(false)
      displayServiceErrorMessage('Notification Delete Failed')
    }
  }, [deleteObjectNotificationStatus])

  useEffect(() => {
    if (notificationFetchAllStatus) {
      if (notificationFetchAllStatus.status === 'failed') {
        displayServiceErrorMessage('Failed to fetch all Notifications')
      } else if (notificationFetchAllStatus.status === 200) {
        setNotificationGlobalTableData(notificationFetchAllStatus.data)
      }
    }
  }, [notificationFetchAllStatus])

  useEffect(() => {
    if (getObjectListings?.status >= 200 && getObjectListings?.status < 300) {
      const _getObjectListings = getObjectListings?.data
        ? cloneDeep(getObjectListings.data).map(item => {
            item.type = 'object-notification'
            return item
          })
        : []

      setNotificationObjectTableData(_getObjectListings)
    } else if (getObjectListings?.status === 'failed') {
      displayServiceErrorMessage('Failed to fetch Notifications')
    }
  }, [getObjectListings])

  useEffect(() => {
    if (deleteNotificationStatus?.status === 'failed') {
      displayServiceErrorMessage('Notification Delete Failed')
    }
  }, [deleteNotificationStatus])
  useEffect(() => {
    if (publishNotificationStatus?.status === 'failed') {
      displayServiceErrorMessage('Notification Publish Failed')
    }
  }, [publishNotificationStatus])
  useEffect(() => {
    if (notificationEditStatus) {
      if (notificationEditStatus.status === 'failed') {
        displayServiceErrorMessage('Edit notification failed')
      } else if (notificationEditStatus.status === 200) {
        setEditMode({})
        fetchAllNotificationAction()
      }
    }
  }, [notificationEditStatus])

  useEffect(() => {
    if (notificationCreateStatus) {
      if (notificationCreateStatus.status === 'failed') {
        displayServiceErrorMessage('Create notification failed')
      } else if (notificationCreateStatus.status === 201) {
        setCreateMode(false)
        fetchAllNotificationAction()
      }
    }
  }, [notificationCreateStatus])

  useEffect(() => {
    if (createObjectNotificationStatus) {
      if (createObjectNotificationStatus.status === 'failed') {
        const errorData = createObjectNotificationStatus.message?.resp?.data
        const errorMessage = errorData?.description || errorData?.message || 'Create notification failed'
        displayServiceErrorMessage(errorMessage)
      } else if (createObjectNotificationStatus.status >= 200 && createObjectNotificationStatus.status < 300) {
        cancelCreateNotification()
        clearObjectNotificationStatus()
        fetchObjectNotificationListing({ pageIndex: 1, pageSize, objectType, objectId })
      }
    }
  }, [createObjectNotificationStatus])

  useEffect(() => {
    if (updateNotificationStatus) {
      if (updateNotificationStatus.status >= 200 && updateNotificationStatus.status < 300) {
        clearObjectNotificationStatus()
        cancelCreateNotification()
        fetchObjectNotificationListing({ pageIndex: 1, pageSize, objectType, objectId })
      } else if (updateNotificationStatus.status === 'failed') {
        const errorData = updateNotificationStatus.message?.resp?.data
        const errorMessage = errorData?.description || errorData?.message || 'Update notification failed'
        displayServiceErrorMessage(errorMessage)
      }
    }
  }, [updateNotificationStatus])

  useEffect(() => {
    if (routeObjectNotificationId) {
      const isObjectIdNotificationStatus = !['failed', 'success'].includes(objectIdNotificationStatus?.status)
      if (isObjectIdNotificationStatus && objectIdNotificationStatus?.data) {
        setEditMode(objectIdNotificationStatus?.data)
        clearObjectNotificationStatus()
      } else if (objectIdNotificationStatus?.status === 'failed') {
        displayServiceErrorMessage('Fetch notification is failed on edit.')
      }
    }
  }, [objectIdNotificationStatus])

  const handleClose = () => {
    setDeleteStatus(false)
  }

  const columns = [
    {
      field: 'message',
      headerName: 'Name',
      valueGetter: ({ data }) => data.message?.subject || data.message?.title,
      cellRendererFramework: ({ data }) => (
        <div data-cy={`notification-title-${data?.message?.subject}`}>
          {data.message?.subject || data.message?.title}
        </div>
      ),
    },
    {
      field: 'message',
      headerName: 'Created Date',
      minWidth: 250,
      valueGetter: ({ data }) => {
        const startDate = data.message?.time_to_live?.start_date || data.audit_properties?.created_date
        return moment(startDate).format('MMMM Do YYYY, h:mm A')
      },
    },
    {
      field: 'message.time_to_live.end_date',
      headerName: 'End Date',
      minWidth: 250,
      valueGetter: ({ data }) =>
        data?.message?.time_to_live?.end_date &&
        moment(data.message.time_to_live.end_date).format('MMMM Do YYYY, h:mm A'),
    },
    {
      field: 'message',
      headerName: 'Type',
      valueGetter: ({ data }) => data.message?.object_detail?.object_type || 'Global',
    },
    {
      field: 'publish',
      headerName: 'Publish Status',
      valueGetter: ({ data }) => {
        const publishStatus =
          data.publish?.published || data.message?.time_to_live?.start_date
            ? `Published ${
                data.message?.time_to_live?.start_date
                  ? moment(data.audit_properties?.updated_date, "YYYY-MM-DD'T'HH:mm:ssZ").fromNow()
                  : moment(data.publish.published_date, "YYYY-MM-DD'T'HH:mm:ssZ").fromNow()
              }`
            : 'Publish'
        return publishStatus === 'Publish' ? (
          <Button
            color="primary"
            onClick={
              data.publish?.published
                ? null
                : () => {
                    publishNotificationAction(data._id)
                  }
            }
          >
            {publishStatus}
          </Button>
        ) : (
          publishStatus
        )
      },
      cellRendererFramework: ({ data }) => {
        const publishStatus =
          data.publish?.published || data.message?.time_to_live?.start_date
            ? `Published ${
                data.message?.time_to_live?.start_date
                  ? moment(data.audit_properties?.updated_date, "YYYY-MM-DD'T'HH:mm:ssZ").fromNow()
                  : moment(data.publish.published_date, "YYYY-MM-DD'T'HH:mm:ssZ").fromNow()
              }`
            : 'Publish'
        return publishStatus === 'Publish' ? (
          <Button
            color="primary"
            onClick={
              data.publish?.published
                ? null
                : () => {
                    publishNotificationAction(data._id)
                  }
            }
          >
            {publishStatus}
          </Button>
        ) : (
          publishStatus
        )
      },
    },
    {
      field: 'details',
      headerName: 'Actions',
      pinned: 'right',
      maxWidth: 125,
      suppressMenu: true,
      filter: false,
      sortable: false,
      cellRendererFramework: ({ data }, index) => {
        const isExpiredNotification = new Date(data.message?.time_to_live?.end_date) < new Date()
        return (
          <div className="tableDetail" data-cy={`action-btn-${index}`}>
            <Tooltip title={isExpiredNotification ? 'Notification Expired' : 'Edit Notification'} placement="top">
              <span>
                <IconButton
                  color="primary"
                  className="editDeleteButtons"
                  data-cy={`edit-${data.message?.subject}`}
                  // disable edit if the notification is expired
                  disabled={isExpiredNotification || isWaitingForDelete}
                  onClick={() => {
                    setEditMode(data)
                    sendFireflyEvent(true, {
                      eventName: 'editObjectNotificationClicked',
                      key: 'notification',
                    })
                  }}
                >
                  <EditOutlined color={isExpiredNotification || isWaitingForDelete ? 'inherit' : 'success'} />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title="Delete Notification" placement="top">
              <span>
                <IconButton
                  color="secondary"
                  className="editDeleteButtons"
                  disabled={isWaitingForDelete}
                  data-cy={`delete-${data.message?.subject}`}
                  onClick={() => {
                    if (!data?.object && data?.type === 'object-notification') {
                      setIsWaitingForDelete(true)
                      deleteObjectNotification({ id: data.id })
                      sendFireflyEvent(true, {
                        eventName: 'deleteObjectNotificationClicked',
                        key: 'notification',
                      })
                    } else {
                      sendFireflyEvent(true, {
                        eventName: 'deleteGlobalNotificationClicked',
                        key: 'notification',
                      })
                      deleteNotificationAction(data._id)
                    }
                  }}
                >
                  <DeleteOutline color={!isWaitingForDelete ? 'error' : 'inherit'} />
                </IconButton>
              </span>
            </Tooltip>
          </div>
        )
      },
    },
  ]

  const NotificationListTable = (
    <>
      <Paper>
        <AppBar position="static" elevation={1}>
          <Tabs variant="fullWidth" value={value} onChange={handleTabChange} aria-label="profile tabs">
            <Tab data-cy="object" icon={<CallToAction />} label="Object Notifications" />
            {userHasSubscriptionAccess && (
              <Tab data-cy="alerts" icon={<Email />} label="Data Alert & Subscription Notifications" />
            )}
            {currentUser?.data?.is_admin === 'yes' && (
              <Tab data-cy="global" icon={<Public />} label="Global Notifications" />
            )}
          </Tabs>
        </AppBar>
      </Paper>
      <Box style={{ padding: '1rem', background: 'white' }}>
        <SwipeableViews
          index={value === 1 && !userHasSubscriptionAccess ? value + 1 : value}
          onChangeIndex={handleChangeIndex}
        >
          {/* Object Notifications */}
          <AgGridTable isMobile={isMobile} columns={columns} pageSize={10} data={notificationObjectTableData} />
          {/* Data Alert Notifications */}
          <DataAlertNotificationListing routeProps={routeProps} trackEvent={trackEvent} />
          {/* Global Notifications */}
          {currentUser?.data?.is_admin ? (
            <AgGridTable isMobile={isMobile} columns={columns} pageSize={10} data={notificationGlobalTableData} />
          ) : (
            <></>
          )}
        </SwipeableViews>
      </Box>
    </>
  )

  const renderCreationMessage = () => {
    return (
      <Snackbar
        className="error-success-message"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        onClose={handleClose}
        autoHideDuration={1000}
        message={<span className="failed-text">Deleted successfully</span>}
        open={deleteStatus}
      />
    )
  }

  const handleCreateNotificationChange = optionType => {
    setNotificationOptionType(optionType)
    setCreateMode(true)
    sendFireflyEvent(true, {
      eventName:
        optionType === 'global-notification' ? 'createGlobalNotificationClicked' : 'createObjectNotificationClicked',
      key: 'notification',
    })
  }

  const cancelCreateNotification = () => {
    setCreateMode(false)
    setEditMode({})
    setRouteObjectId(-1)
    setRouteObjectType('')
    if (routeProps.match.params.navigationType && routeProps.match.params._id) {
      routeProps.history.replace(
        `/notification-manager/${routeProps.match.params.navigationType}/${routeProps.match.params._id}`
      )
    } else {
      routeProps.history.replace('/notification-manager')
    }
  }

  return (
    <>
      {renderCreationMessage()}
      <Toolbar classes={{ root: 'toolbar' }}>
        {!createMode && editMode && !Object.keys(editMode).length ? (
          <Typography classes={{ root: 'toolbar-title' }}>NOTIFICATION MANAGER</Typography>
        ) : (
          <Link to="/notification-manager" onClick={cancelCreateNotification}>
            <Grid container justifyContent="flex-start" alignItems="center">
              <Typography variant="subtitle1">Notification Manager</Typography>
            </Grid>
          </Link>
        )}

        {!(createMode || (editMode && Object.keys(editMode).length) || (routeObjectType && routeObjectId > 0)) &&
          currentUser?.data &&
          !isMobile && (
            <>
              {currentUser.data.is_admin === 'yes' && (
                <Button
                  color="secondary"
                  sx={{ marginLeft: 'auto' }}
                  className="icon-label-btn-props"
                  aria-label="Create Global Notification"
                  data-cy="create-user-notification-btn"
                  startIcon={<Add />}
                  onClick={() => handleCreateNotificationChange('global-notification')}
                >
                  Create Global Notification
                </Button>
              )}
            </>
          )}
      </Toolbar>
      {asset?._id && (
        <Link
          to={
            asset.navigationType === 'dataset'
              ? `/dataset/preview/${asset._id}`
              : `/${asset.navigationType}/${asset._id}`
          }
        >
          <Grid container justifyContent="flex-start" alignItems="center" style={{ margin: '1rem' }}>
            <ArrowBackIos fontSize="small" />
            <Typography variant="subtitle1">
              Back to {asset.navigationType && asset.navigationType[0].toUpperCase() + asset.navigationType.slice(1)}
            </Typography>
          </Grid>
        </Link>
      )}
      {!isEdit && objectType && !Object.keys(editMode).length ? (
        <Box m={2}>
          <AgGridTable isMobile={isMobile} columns={columns} pageSize={10} data={notificationObjectTableData} />
        </Box>
      ) : createMode || (editMode && Object.keys(editMode).length) || (routeObjectType && routeObjectId > 0) ? (
        <NotificationAuthoring
          createMode={createMode}
          cancelCreateNotification={cancelCreateNotification}
          editMode={editMode}
          notificationCreateAction={notificationCreateAction}
          notificationEditAction={notificationEditAction}
          displayServiceErrorMessage={displayServiceErrorMessage}
          getDetailsAudienceAction={debounce(getDetailsOwnersAction, 500)}
          detailsAudienceStatus={detailsOwnersStatus}
          currentUser={currentUser}
          createNotification={createNotification}
          createObjectNotificationStatus={createObjectNotificationStatus}
          updateNotification={updateNotification}
          updateNotificationStatus={updateNotificationStatus}
          notificationOptionType={notificationOptionType}
          routeObjectType={routeObjectType}
          routeObjectId={routeObjectId}
          getCardMeta={getCardMeta}
          cardInfoStatus={cardInfoStatus}
          viewDashBoardInfoStatus={viewDashBoardInfoStatus}
          getViewDashboardInfo={getViewDashboardInfo}
          getDatasetDetail={getDatasetDetail}
          datasetDetailStatus={datasetDetailStatus}
          setRouteObjectId={setRouteObjectId}
          setRouteObjectType={setRouteObjectType}
          sendFireflyEvent={sendFireflyEvent}
          getObjectNotificationBasedOnId={getObjectNotificationBasedOnId}
          routeObjectNotificationId={routeObjectNotificationId}
          objectIdNotificationStatus={objectIdNotificationStatus}
          deleteObjectNotification={deleteObjectNotification}
          deleteObjectNotificationStatus={deleteObjectNotificationStatus}
        />
      ) : (
        NotificationListTable
      )}
    </>
  )
}

export default NotificationManager
