import React from 'react'

import calculatedField from '../../../../images/cficon.svg'
import dateColumn from '../../../../images/dateicon.svg'
import metricColumn from '../../../../images/numbericon.svg'
import dimensionColumn from '../../../../images/texticon.svg'
import basemapColumn from '../../../../images/basemapicon.svg'
import multipleDateColumn from '../../../../images/multiple_date_icon.svg'
import { calculatedFieldType, dimensionType } from '../BuilderConstants'

/**
 * @param {array} detailContents list of cards on a dashboard tab
 * @param {boolean} isBig whether or not the new card is big or small
 * @return {object}
 */
export const createNewPendingCard = (detailContents, isBig, isText) => {
  const obj = {
    xSize: isBig || isText ? 12 : 4,
    ySize: isBig ? 6 : 4,
    xPosition: 0,
    yPosition: 0,
    text: '+ Add Card',
    id: Math.random().toString(36).substring(2),
    datasetId: '',
    type: isText ? 'text' : '',
  }

  if (!detailContents) {
    return obj
  }

  function findFirstAvailableSpace(arr) {
    let x = 0
    let y = 0
    while (true) {
      let overlaps = false
      for (let i = 0; i < arr.length; i++) {
        const obj = arr[i]
        if (
          x >= obj.xPosition &&
          x < obj.xPosition + obj.xSize &&
          y >= obj.yPosition &&
          y < obj.yPosition + obj.ySize
        ) {
          overlaps = true
          break
        }
      }
      if (!overlaps) {
        if (x + 4 <= 12) {
          return [x, y]
        } else {
          x = 0
          y++
        }
      } else {
        x++
        if (x + 4 > 12) {
          x = 0
          y++
        }
      }
    }
  }

  const [xPosition, yPosition] = findFirstAvailableSpace(detailContents)

  obj.xPosition = xPosition
  obj.yPosition = yPosition

  return obj
}

export const processColumnsIcon = (column = {}, colType = '') => {
  const type = colType || column.type || ''
  const storageType = column.timeseries_type || ''
  // only for dimension need to show Text Icon and for dynamic_dimension f(x) i.e. calculatedField
  if (type === 'dimension') {
    return dimensionColumn
  } else if (type === 'metric') {
    return metricColumn
  } else if (type === 'timeseries') {
    if (storageType?.toLowerCase() === 'auto-detected') {
      return multipleDateColumn
    }
    return dateColumn
  } else if (type === 'geojson') {
    return basemapColumn
  } else if (type?.toLowerCase() === 'auto-detected') {
    return multipleDateColumn
  } else {
    // this will be for dynamic_dimension and all type of calculated field
    return calculatedField
  }
}
/**
 * getColumnColorCodeBasedOnType
 * @param column
 */
export const getColumnColorCodeBasedOnType = (column = {}) => {
  let columnType = column.type
  const storageType = column.timeseries_type?.toLowerCase()
  if (storageType === 'auto-detected') {
    columnType = storageType
  }

  return columnType
}

export const checkCalculatedField = type => {
  // only for dimension need to show Text Icon and for dynamic_dimension f(x) i.e. calculatedField
  const nonCalculatedTypes = ['dimension', 'metric', 'timeseries']
  return type && !nonCalculatedTypes.includes(type)
}

/**
 * For Legacy Cards
 * @param filter
 * @returns {*}
 */
export const getFormattedFilterArray = filter => {
  const formattedFilterObj = {}

  if (filter.column_type) {
    if (dimensionType.includes(filter.column_type)) {
      formattedFilterObj.column = filter.column
      formattedFilterObj.type = filter.type
      formattedFilterObj.pattern = filter.pattern
    } else if (calculatedFieldType.includes(filter.column_type)) {
      formattedFilterObj.column = filter.column
      formattedFilterObj.type = filter.type
      formattedFilterObj.value = Number.parseFloat(filter.value)
    } else if (filter.column_type === 'metric') {
      formattedFilterObj.column = filter.column
      formattedFilterObj.type = filter.type
      formattedFilterObj.value = Number.parseFloat(filter.value)
      formattedFilterObj.aggregation_type = filter.aggregation_type
    }
    return formattedFilterObj
  }
  return filter
}

export const getCurrentCardPayloadFormat = (previousPayloadOfCurrentCard, selectedElementId) => ({
  data: {
    card_config: previousPayloadOfCurrentCard[selectedElementId],
  },
  pendingCardUid: selectedElementId,
  status: 200,
})

export const conditionsCharacterSymbols = {
  greater_than: '>',
  greater_than_or_equal_to: '>=',
  less_than: '<',
  less_than_or_equal_to: '<=',
  equal_to: '=',
  is_not_equal_to: '!=',
  is_between: 'between',
  dimension_value_matches: '=',
  dimension_value_not_equal_to: '!=',
}

export const DrillThroughIcon = () => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 128 128"
    style={{ enableBackground: 'new 0 0 128 128' }}
    xmlSpace="preserve"
    className="drillthrough-icon"
  >
    <g>
      <rect x="58.4" y="42.1" width="14.3" height="14.3" />
      <rect x="58.4" y="67.1" width="14.3" height="14.3" />
      <rect x="83.4" y="67.1" width="14.3" height="14.3" />
      <rect x="33.4" y="67.1" width="14.3" height="14.3" />
      <line className="st13" x1="40.6" y1="60.1" x2="90.6" y2="60.1" />
      <line className="st14" x1="40.6" y1="67.1" x2="40.6" y2="60.1" />
      <line className="st14" x1="90.6" y1="67.1" x2="90.6" y2="60.1" />
      <line className="st14" x1="65.6" y1="56.4" x2="65.6" y2="67.1" />
    </g>
  </svg>
)

export const mapThemes = [
  {
    theme: 'YlOrBr',
    details: [
      { fill: '#ffffd4', x: '0' },
      { fill: '#fed98e', x: '37' },
      { fill: '#fe9929', x: '74' },
      { fill: '#d95f0e', x: '111' },
      { fill: '#993404', x: '148' },
    ],
  },
  {
    theme: 'BuGn',
    details: [
      { fill: '#edf8fb', x: '0' },
      { fill: '#b2e2e2', x: '37' },
      { fill: '#66c2a4', x: '74' },
      { fill: '#2ca25f', x: '111' },
      { fill: '#006d2c', x: '148' },
    ],
  },
  {
    theme: 'Blues',
    details: [
      { fill: '#eff3ff', x: '0' },
      { fill: '#bdd7e7', x: '37' },
      { fill: '#6baed6', x: '74' },
      { fill: '#3182bd', x: '111' },
      { fill: '#08519c', x: '148' },
    ],
  },
  {
    theme: 'RdPu',
    details: [
      { fill: '#feebe2', x: '0' },
      { fill: '#fbb4b9', x: '37' },
      { fill: '#f768a1', x: '74' },
      { fill: '#c51b8a', x: '111' },
      { fill: '#7a0177', x: '148' },
    ],
  },
  {
    theme: 'RdYlGn',
    details: [
      { fill: '#d7191c', x: '0' },
      { fill: '#fdae61', x: '37' },
      { fill: '#ffffbf', x: '74' },
      { fill: '#a6d96a', x: '111' },
      { fill: '#1a9641', x: '148' },
    ],
  },
  {
    theme: 'GnYlRd',
    details: [
      { fill: '#1a9641', x: '0' },
      { fill: '#a6d96a', x: '37' },
      { fill: '#ffffbf', x: '74' },
      { fill: '#fdae61', x: '111' },
      { fill: '#d7191c', x: '148' },
    ],
  },
  {
    theme: 'RdBu',
    details: [
      { fill: '#ca0020', x: '0' },
      { fill: '#f4a582', x: '37' },
      { fill: '#f7f7f7', x: '74' },
      { fill: '#92c5de', x: '111' },
      { fill: '#0571b0', x: '148' },
    ],
  },
]

export const getColumnExcellIndex = index => {
  if (index >= 0 && index < 26) {
    return String.fromCharCode(65 + index)
  } else {
    return String.fromCharCode(65 + ((index - (index % 26)) / 26 - 1)) + String.fromCharCode(65 + (index % 26))
  }
}

export const getColumnExcelIndexByRefId = (refId, cardColumns = []) => {
  const colIndex = cardColumns.findIndex(col => col.ref_id === refId)

  return colIndex > -1 ? `(Column: ${getColumnExcellIndex(colIndex)})` : ''
}

/**
 * Helper fuction for react drag and drop to reorder draggable list onDragEnd.
 * @param {array} list list of items to be reordered
 * @param {number} startIndex index from where to pick the object
 * @param {number} endIndex index at which to drop the object
 * @return {array} reordered list
 */
export const reorderDraggedList = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)
  return result
}

export const isFilterEqualsCondition = type => {
  return type === '!=' || type === '=='
}

export const isVirtualColumnCheck = (column = {}) => {
  return column.type === 'timeseries' && column.timeseries_type === 'auto-detected'
}

export const cardColumnsFilterList = cardInfo => {
  const columnArray = cardInfo.data?.card_config?.card_query_attribute?.columns
  if (columnArray) {
    return columnArray.filter(column => {
      const isHideColumn =
        column.ref_id &&
        cardInfo.data?.card_config?.card_attribute?.style?.columnLevelJustification?.find(
          col => col.name === column.ref_id
        )?.hideColumn
      return (!isHideColumn && !column.hide_column && column.type !== 'timeseries') || isVirtualColumnCheck(column)
    })
  }
}
