import React from 'react'
import { Chip } from '@mui/material'
import { SiteDetailButtonRenderer } from './SiteDetailButtonRenderer'
import { SiteListActionButtons } from './SiteListActionButtons'

export const ColumnDefs = isMobile => {
  const handleToggleSiteDetail = (api, rowNode) => {
    rowNode.expanded ? rowNode.setExpanded(false) : rowNode.setExpanded(true)
    api.refreshCells()
  }
  return [
    {
      field: 'name',
      headerName: '',
      maxWidth: 50,
      filter: false,
      sortable: false,
      hide: isMobile,
      suppressColumnsToolPanel: true,
      cellRenderer: 'agGroupCellRenderer',
      valueFormatter: () => '',
      suppressMenu: true,
    },
    {
      field: 'name',
      headerName: 'Site Name',
      cellRendererFramework: ({ value, data, node }) => (
        <a
          id={`edit-site-link${node.rowIndex}`}
          data-cy={`site-route-${data.route}`}
          className="edit-card-link"
          href={`/site/${data.route}/home/0`}
        >
          {value.trim()}
        </a>
      ),
    },
    {
      field: 'tag',
      headerName: 'Site Tags',
      hide: isMobile,
      cellRendererFramework: ({ node, value }) => (
        <Chip className="chip-business" size="small" label={value} key={value} id={`chip-tag-${node.rowIndex}`} />
      ),
    },
    {
      field: 'owners_access',
      hide: isMobile,
      keyCreator: params => params.value?.map(owner => owner.user_group_name),
      headerName: 'Owner(s)',
      cellRendererFramework: ({ data, node, api }) => {
        const showMore = data.owners_access?.length > 1
        const value = data.owners_access[0]?.user_group_name || data.owners[0]
        return (
          <>
            {value && <Chip size="small" label={value} key={node.rowIndex} className="chip-owners" />}
            {showMore && (
              <Chip
                size="small"
                label={`+ ${data.owners_access.length - 1} more`}
                className="chip-owners"
                onClick={() => handleToggleSiteDetail(api, node)}
                data-index={node.rowIndex}
              />
            )}
          </>
        )
      },
      cellRendererParams: {
        handleToggleDetail: handleToggleSiteDetail,
        className: 'chip-owners',
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      pinned: 'right',
      hide: isMobile,
      maxWidth: 150,
      filter: false,
      sortable: false,
      cellRendererFramework: SiteListActionButtons,
    },
    {
      field: 'name',
      headerName: '',
      filter: false,
      sortable: false,
      suppressColumnsToolPanel: true,
      cellRendererFramework: SiteDetailButtonRenderer,
      cellRendererParams: {
        handleToggleSiteDetail,
        isMobile,
      },
      hide: !isMobile,
      menuTabs: false,
    },
  ]
}
