import React from 'react'
import PropTypes from 'prop-types'
import {
  Card,
  CardContent,
  Tooltip,
  Button,
  Toolbar,
  AppBar,
  Icon,
  Grid,
  Chip,
  Typography,
  IconButton,
  Popover,
  Menu,
  MenuItem,
  Badge,
  Dialog,
} from '@mui/material'
import { Link, Redirect } from 'react-router-dom'
import {
  Link as IconLink,
  Edit,
  Delete,
  FilterList,
  Close,
  DateRange,
  FileCopy,
  Refresh,
  InfoOutlined,
  InfoRounded,
} from '@mui/icons-material'
import moment from 'moment'
import Viewer from 'react-froala-wysiwyg/FroalaEditorView'
import groupBy from 'lodash/groupBy'
import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'
import omit from 'lodash/omit'
import { withAnalytics } from '@praxis/component-analytics'
import { CUSTOM_STR } from 'greenfield-utilities'

import analyticsConfig from '../../../../analytics'
import ConfirmDialog from '../../../../shared/Dialog/ConfirmDialog'
import ViewerDescription from '../../../../shared/ViewerDescription'
import { GreenfieldFilterViewer, GreenfieldFavoriteButton, SaveAsContainer } from '../../../../shared/shared'
import Generateshortlink from '../../../../shared/Generateshortlink'
import ViewerTimePeriod from '../../../../shared/ViewerTimePeriod/ViewerTimePeriod'
import { AnchorIcon } from '../../../../shared/Svgicon/Svgicon'
import { VIEW_USAGE_DASHBOARD, MULTI_SELECT_CUSTOM_STR, LWR_CASE_TODAY } from '../../../../constants/StringConstants'
import { getFormatDate, getTimePeriodButtonLabel } from '../../../../shared/util'
import HistoryPopover from '../../../../shared/HistoryPopover/HistoryPopover'

class DashboardviewerHeader extends React.Component {
  state = {
    favStatus: this.props.data.favorite,
    id: this.props.data.id,
    open: false,
    expandLess: true,
    expandMore: false,
    confirmDeleteDashboard: false,
    openSaveAs: false,
    openMenuItem: false,
    isShowHelp: false,
    printMenuVisible: null,
    dashboardFilters: null,
    timePeriodLabelClicked: null,
    buttonText: '',
    beginDate: '',
    endDate: '',
    shortLinkVisible: false,
    expandHistory: false,
    historyData: null,
  }

  componentDidUpdate(prevProps) {
    const { saveDashboardStatus, appliedFilters, dateFilterStatus, timePeriod, anchorDateFilterStatus } = this.props
    const { dashboardFilters } = this.state

    const dateFilterObj = timePeriod?.anchor_date ? anchorDateFilterStatus : dateFilterStatus
    const prevDateFilterObj = timePeriod?.anchor_date ? prevProps.anchorDateFilterStatus : prevProps.dateFilterStatus

    if (
      saveDashboardStatus &&
      saveDashboardStatus.status >= 200 &&
      saveDashboardStatus.status < 300 &&
      saveDashboardStatus !== prevProps.saveDashboardStatus
    ) {
      const { routeProps } = this.props
      routeProps.history.replace(`/dashboard/${this.props.saveDashboardStatus.data._id}`)
      this.openSaveAsDialog(false)
    }

    if (!isEqual(appliedFilters, dashboardFilters)) {
      this.setState({
        dashboardFilters: appliedFilters,
      })
    }

    if (!isEqual(dateFilterObj, prevDateFilterObj) || !isEqual(timePeriod, prevProps.timePeriod)) {
      const isTimeIntervalArray = Array.isArray(timePeriod.intervals) && timePeriod.type === CUSTOM_STR.toLowerCase()
      const intervalTime = isTimeIntervalArray ? timePeriod.intervals : timePeriod.interval || ''
      let beginDate = null
      let endDate = null
      let buttonText = intervalTime

      if (dateFilterObj?.data) {
        const { Fiscal, Gregorian } = timePeriod.anchor_date ? anchorDateFilterStatus?.data : dateFilterStatus.data
        const calendarType =
          timePeriod.calendar_type && timePeriod.calendar_type.toLowerCase() === 'fiscal' ? Fiscal : Gregorian

        if ((timePeriod.set_begin_date_today || timePeriod.set_end_date_today) && intervalTime?.includes(',')) {
          const splitInterval = intervalTime?.split(',')
          const start = splitInterval[0]?.trim()
          const end = splitInterval[1]?.trim()

          const startDateStr = timePeriod.set_begin_date_today ? start?.slice(1) : start?.slice(2, start?.length - 1)
          const endDateStr = timePeriod.set_end_date_today
            ? end?.slice(0, end?.length - 1)
            : end?.slice(1, end?.length - 2)

          beginDate = timePeriod.set_begin_date_today ? 'Today' : startDateStr
          endDate = timePeriod.set_end_date_today ? 'Today' : endDateStr
          buttonText = 'Between'
        } else if (intervalTime && intervalTime.includes('/')) {
          beginDate = intervalTime.split('/')[0]
          endDate = intervalTime.split('/')[1]
          buttonText = 'Between'
        } else {
          if (intervalTime && Object.prototype.hasOwnProperty.call(calendarType, intervalTime)) {
            buttonText = intervalTime
            beginDate = calendarType[intervalTime]['Begin Date']
            endDate = calendarType[intervalTime]['End Date']
          } else if (intervalTime !== 'last segment') {
            buttonText = intervalTime
          }
        }
        this.setState({ buttonText, beginDate, endDate })
      }
    }
  }

  sendFireFlyEvent = (isCustomEvent, eventObj) => {
    if (isCustomEvent) {
      this.props.trackCustomEvent({
        type: eventObj.eventName,
        key: eventObj.key,
        value: JSON.stringify(eventObj.data),
      })
    } else {
      this.props.trackEvent({
        event: {
          type: eventObj.eventName,
        },
      })
    }
  }

  handleClickOpen = () => {
    this.setState({ open: true })
  }

  handleDialogClose = () => {
    this.setState({ open: false })
  }

  handlePrintDialogClose = () => {
    this.setState({ open: false })
  }

  dateFromNow = date => moment(date).fromNow()

  handleApplyDashboardFilters = dashboardFilters => {
    this.setState({ dashboardFilters })
  }

  refreshDashboard() {
    this.sendFireFlyEvent(false, { eventName: 'dashboardRefreshBtnClick' })
    this.props.clearTabCardsPayload()
    this.props.refreshDashboardClicked()
  }

  dashboardFilter(viewerFilters, dashboardId) {
    const {
      openFilters,
      selectedFilterId,
      filterClick,
      dismissPopover,
      routeProps,
      appliedFilters = [],
      deviceType,
      metadata,
    } = this.props
    return (
      <>
        {appliedFilters.length ? (
          <Badge className="hint-badge" badgeContent={appliedFilters.length}>
            <Button
              id="dashboard-view-filterby"
              color="secondary"
              onClick={filterClick}
              aria-label="Add Filter"
              className="icon-label-btn-props greenFieldNoPrint"
            >
              <FilterList className="icon-left" />
              Filter By
            </Button>
          </Badge>
        ) : (
          <Button
            id="dashboard-view-filterby"
            color="secondary"
            onClick={filterClick}
            aria-label="Add Filter"
            className="icon-label-btn-props greenFieldNoPrint"
          >
            <FilterList className="icon-left" />
            Filter By
          </Button>
        )}
        <span className="vertical-bar greenFieldNoPrint">|</span>

        <GreenfieldFilterViewer
          routeProps={routeProps}
          onApplyDashboardFilters={this.handleApplyDashboardFilters}
          open={openFilters}
          viewerFilters={viewerFilters}
          type="dashboard"
          id={dashboardId}
          onDismissPopover={dismissPopover}
          selectedFilterId={selectedFilterId}
          className="greenFieldNoPrint"
          deviceType={deviceType}
          dashboardInfo={metadata}
          isDashboard
        />
      </>
    )
  }

  handlePrintButtonClick = () => {
    window.print()
  }

  handleShowPrintSelect = e => {
    const eventObject = {
      eventName: 'dashboardviewer-print-clicked',
      key: this.state.id,
      data: {
        activeFilters: this.props.appliedFilters,
        time: this.props.data?.time_period,
      },
    }

    this.sendFireFlyEvent(true, eventObject)
    this.setState({ printMenuVisible: e.currentTarget })
  }

  handlePrintSelectClose = () => {
    this.setState({ printMenuVisible: null })
  }

  handlePrintViewerClick = orientation => {
    const { appliedFilters, data } = this.props
    const { id } = this.state

    const eventObject = {
      eventName: 'dashboardviewer-print-action-followthrough',
      key: id,
      data: {
        activeFilters: appliedFilters,
        time: data?.time_period,
        orientation,
      },
    }

    if (orientation) {
      this.sendFireFlyEvent(true, eventObject)
    }
  }

  renderOpMenu = () => {
    const { routeProps, activeTab, cardsInfo, dashboardId } = this.props
    const { printMenuVisible } = this.state
    const isPrint = /\/print\//i.test(routeProps.location.pathname)
    const isReady = Object.keys(cardsInfo).every(
      cardStatusId =>
        cardsInfo[cardStatusId] && cardsInfo[cardStatusId].status >= 200 && cardsInfo[cardStatusId].status < 300
    )
    const urlState = `${routeProps.location.search}${routeProps.location.hash}`

    return isPrint ? (
      <>
        <Button color="primary" onClick={this.handlePrintButtonClick} disabled={!isReady} variant="contained">
          {isReady ? 'Print now' : 'Rendering cards, please wait..'}
        </Button>
      </>
    ) : (
      <>
        <IconButton
          id="dashboard-view-print"
          aria-label="More"
          aria-haspopup="true"
          className="align-icon greenFieldNoPrint"
          onClick={this.handleShowPrintSelect}
        >
          <Icon>print</Icon>
        </IconButton>
        <Menu open={Boolean(printMenuVisible)} anchorEl={printMenuVisible} onClose={this.handlePrintSelectClose}>
          <Link
            to={`/dashboard/${dashboardId || routeProps.match.params._id}/print/${activeTab}${urlState}`}
            target="_blank"
            style={{ textDecoration: 'none' }}
          >
            <MenuItem className="greenFieldDashboardNoPrint" onClick={() => this.handlePrintViewerClick('portrait')}>
              Print Portrait
            </MenuItem>
          </Link>
          <Link
            to={`/dashboard/${dashboardId || routeProps.match.params._id}/print/${activeTab}/landscape${urlState}`}
            style={{ textDecoration: 'none' }}
            target="_blank"
          >
            <MenuItem className="greenFieldDashboardNoPrint" onClick={() => this.handlePrintViewerClick('landscape')}>
              Print Landscape
            </MenuItem>
          </Link>
        </Menu>
      </>
    )
  }

  handleDeleteDashboard = () => {
    const { id } = { ...this.state }
    this.props.delDashboard({ delDashboard: id })
  }

  handleOnClickDeleteDashboard = () => {
    this.setState({
      confirmDeleteDashboard: true,
    })
  }

  handleKeyPressed = event => {
    if (event.which === 9) {
      this.handleShowSupportText(event)
    }
  }

  handleSetOpenSaveAs = () => {
    this.openSaveAsDialog(true)
  }

  handleUsageClick = () => {
    this.setState({
      openMenuItem: false,
    })
  }

  saveAsSuccess = details => {
    const dashboardDetails = omit(cloneDeep(this.props.dashboardData), [
      '_id',
      'detailed_card_data',
      'alert_count',
      'owners_access',
      'viewers_access',
      'saved_filters',
      'name',
      'user_view_access',
      'group_view_access',
      'edit_enabled',
      'groups',
      'viewer_filters',
    ])

    if (!dashboardDetails.time_period) {
      dashboardDetails.time_period = {}
    }

    // Set business tags to empty if it is Save as
    dashboardDetails.business_area = []
    dashboardDetails.dashboard_title = details.title
    dashboardDetails.description = details.description
    dashboardDetails.support_details = details.support_details

    const ownersType = groupBy(details.selectedOwners, 'type')
    dashboardDetails.owners = ownersType.user ? ownersType.user.map(owner => owner._id) : []
    dashboardDetails.owner_groups = ownersType.group ? ownersType.group.map(owner => owner._id) : []

    // only for legacy dashboard
    if (dashboardDetails.sections) {
      const sections = dashboardDetails.sections.map(section => {
        if (!section.cards_list) {
          section.cards_list = []
        }
        return section
      })

      dashboardDetails.sections = sections

      // This is only in case of legacy dashboard where id is null
      if (dashboardDetails.tabs) {
        dashboardDetails.tabs.forEach((tab, index) => {
          dashboardDetails.tabs[index].detailContents = tab.detailContents.filter(content => content.id)
        })
      }
    }

    this.props.saveDashboard(dashboardDetails)
  }

  cancelSaveAs = () => {
    const { dashboardData } = this.props
    const id = dashboardData._id // eslint-disable-line
    const dashboardTitle = dashboardData.dashboard_title // eslint-disable-line
    const eventObject = {
      eventName: 'dashboardSaveAsCancel',
      key: id,
      data: {
        dashboardTitle,
      },
    }

    this.sendFireFlyEvent(true, eventObject)
    this.openSaveAsDialog(false)
  }

  openSaveAsDialog(isOpen) {
    this.setState({
      openSaveAs: isOpen,
    })
  }

  renderSaveAsCard() {
    const { openSaveAs, id } = this.state

    return (
      <>
        <SaveAsContainer
          openSaveAs={openSaveAs}
          saveAsSuccess={this.saveAsSuccess}
          cancelSaveAs={this.cancelSaveAs}
          titleLabel="Dashboard Name"
          ownerLabel="Owner(s)"
          descriptionLabel="Dashboard Description"
          saveAsType="dashboard"
          itemId={id}
          apiCallRequestStatus={this.props.saveDashboardStatus}
        />
      </>
    )
  }

  handleShowSupportText = e => {
    const target = e.currentTarget

    this.setState(
      prevState => ({
        isShowHelp: !prevState.isShowHelp ? target : null,
      }),
      () => {
        if (this.state.isShowHelp) {
          // Because this is a toggle....
          this.sendFireFlyEvent(false, { eventName: 'dashboardHelpTextClick' })
        }
      }
    )
  }

  renderTitle = title => {
    if (this.props.isMobile) {
      // using if statment to make this a bit more readable
      return title.length > 6 ? title.substr(0, 9) + '...' : title
    }
    return title
  }

  renderTimePeriod() {
    const {
      isMobile,
      deviceType,
      metadata,
      timePeriod,
      onTimePeriodChange,
      routeProps,
      selectedDashboardTimePeriod,
      updateSelectedDashTimePeriod,
    } = this.props

    return (
      Boolean(metadata) && (
        <div>
          <ViewerTimePeriod
            routeProps={routeProps}
            dashboardInfo={metadata}
            timePeriod={timePeriod}
            onTimePeriodChange={onTimePeriodChange}
            isDashboard
            deviceType={deviceType}
            isMobile={isMobile}
            hideViewerTimePeriod={
              metadata && metadata.time_period ? metadata.time_period.hide_time_period_viewer : false
            }
            selectedDashboardTimePeriod={selectedDashboardTimePeriod}
            updateSelectedDashTimePeriod={updateSelectedDashTimePeriod}
          />
        </div>
      )
    )
  }

  renderTimePeriodPopup() {
    const { timePeriodLabelClicked } = this.state
    const { onHandleDashTimePeriodClose, selectedDashboardTimePeriod, onApplyDashboardTimePeriod } = this.props

    return (
      timePeriodLabelClicked &&
      !this.props.isMobile && (
        <Popover
          id="time-period-dashboard-viewer"
          anchorEl={timePeriodLabelClicked}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          open={Boolean(timePeriodLabelClicked)}
          className="filters-popover-form popover-time-period"
          onClose={() => {
            this.setState({ timePeriodLabelClicked: null })
          }}
        >
          <div className="time-period-content">
            <Typography variant="h2">Time Period</Typography>
            <IconButton
              id="close-btn"
              onClick={() => {
                this.setState({ timePeriodLabelClicked: null })
                onHandleDashTimePeriodClose()
              }}
              aria-label="Close Time Period"
            >
              <Close />
            </IconButton>
          </div>
          <div className="viewer-time-period-container">{this.renderTimePeriod()}</div>
          <div className="apply-timeperiod-button">
            {selectedDashboardTimePeriod && (
              <Typography className="time-period-warning">
                <InfoOutlined className="info-icon" /> Press Apply to update
              </Typography>
            )}
            <Button
              id="cancel-timeperiod"
              data-cy="cancel-timeperiod"
              aria-label="Cancel relative dates"
              variant="text"
              color="secondary"
              onClick={() => {
                this.setState({ timePeriodLabelClicked: null })
                onHandleDashTimePeriodClose()
              }}
            >
              Cancel
            </Button>
            <Button
              id="apply-time-period-btn"
              data-cy="apply-time-period-btn"
              aria-label="Apply relative dates"
              variant="text"
              color="primary"
              onClick={() => {
                onApplyDashboardTimePeriod()
                this.setState({ timePeriodLabelClicked: null })
                onHandleDashTimePeriodClose()
              }}
              disabled={!selectedDashboardTimePeriod}
            >
              Apply
            </Button>
          </div>
        </Popover>
      )
    )
  }

  dateSubstr = dates => (dates ? dates.substr(0, 10) : null)

  hideViewerTimePeriod() {
    const { metadata } = this.props

    return metadata.time_period ? metadata.time_period.hide_time_period_viewer : false
  }

  renderSelectedTimePeriod() {
    const { buttonText, beginDate, endDate } = this.state
    const { timePeriod } = this.props
    const buttonLabel = getTimePeriodButtonLabel(buttonText, timePeriod)
    const isButtonTextArray = Array.isArray(buttonText) && timePeriod?.type === CUSTOM_STR.toLowerCase()
    const showCustom = buttonLabel === CUSTOM_STR && buttonText?.length < 30

    const fromDate = beginDate?.toLowerCase() === LWR_CASE_TODAY ? beginDate : getFormatDate(beginDate)
    const toDate = endDate?.toLowerCase() === LWR_CASE_TODAY ? endDate : getFormatDate(endDate)

    return (
      <Tooltip
        title={!showCustom && buttonLabel === CUSTOM_STR ? buttonText : isButtonTextArray ? buttonLabel : ''}
        placement="bottom"
      >
        <div
          id="selected-time-period"
          tabIndex={0}
          role="button"
          className="time-period-section"
          onClick={event => {
            this.setState({ timePeriodLabelClicked: event.currentTarget })
          }}
          onKeyPress={event => {
            this.setState({ timePeriodLabelClicked: event.currentTarget })
          }}
        >
          <span>
            <DateRange />
            {timePeriod?.anchor_date && <AnchorIcon />}
            <span className="time-period-label">
              {beginDate
                ? `${buttonLabel} ${fromDate} to ${toDate}`
                : showCustom
                ? buttonText
                : isButtonTextArray
                ? MULTI_SELECT_CUSTOM_STR
                : buttonLabel}
            </span>
          </span>
        </div>
      </Tooltip>
    )
  }

  handleMoreActionsClick = event => {
    this.setState({
      openActionsMenu: event.currentTarget,
    })
  }

  handleMoreActionsClose = () => {
    this.setState({
      openActionsMenu: null,
    })
  }

  handleCloseDialog = () => {
    this.setState({ confirmDeleteDashboard: false })
  }

  handleClose = () => {
    this.setState({
      expandMore: false,
      expandLess: true,
      openActionsMenu: null,
    })
  }

  handleMoreClick = () => {
    this.setState({
      expandLess: false,
      expandMore: true,
    })
  }

  handleViewHistory = () => {
    this.setState({
      expandLess: false,
      expandHistory: true,
      openActionsMenu: null,
    })
  }

  handleCloseHistory = () => {
    this.setState({
      expandHistory: false,
      openActionsMenu: null,
    })
  }

  setHistoryDataCallback = data => {
    this.setState({
      historyData: data,
    })
  }

  renderCountCard = (label, count) => {
    return (
      <Card className="dashboardExpandCard expandCardBackgroundColor">
        <CardContent className="dashboardExpandCardContents">
          <Typography className="expandCardData" data-cy="dashboard-card-count">
            {count}
          </Typography>
          <Typography className="expandCardLabel">{label}</Typography>
        </CardContent>
      </Card>
    )
  }

  render() {
    const { isMobile, deviceType, routeProps, dashboardId, clearSavedCards, data, dashboardData, metadata } = this.props
    const { shortLinkVisible, expandLess, expandMore, id, openActionsMenu, isShowHelp, expandHistory, historyData } =
      this.state
    // eslint-disable-next-line camelcase
    const businessArea = data.business_area
    const { _siteName } = routeProps.match.params
    const onDismissShortlinkModal = () => {
      this.setState({
        shortLinkVisible: false,
      })
    }

    return (
      <>
        <AppBar position="static">
          <Toolbar id="dashboardMetadataContainer" className="dashboard-metadata">
            <Grid container className="toolbar-align">
              <Grid item xs={4}>
                <Grid container alignItems="flex-start" direction="column">
                  <div className="dashboard-title-fav-view-group">
                    <Grid container alignItems="flex-start" direction="row">
                      <GreenfieldFavoriteButton
                        type="dashboards"
                        id={this.state.id}
                        isFavorite={this.state.favStatus}
                        className="greenFieldNoPrint"
                      />

                      <Typography
                        aria-label={data.dashboard_title}
                        id="dashboard-viewer-title"
                        data-cy="dashboard-viewer-title"
                        variant="h2"
                        className="card-title"
                      >
                        {this.renderTitle(data.dashboard_title)}
                      </Typography>
                    </Grid>
                  </div>
                  <ViewerDescription description={data.description} />
                  {expandMore && (
                    <Dialog
                      data-cy="dashboard-moredetails-popover"
                      onClose={this.handleClose}
                      className="moredetails-popover"
                      open={!expandLess}
                    >
                      <div>
                        <span className="more-details-title" data-cy="dialog-dashboard-title">
                          <Typography
                            aria-label={data.dashboard_title}
                            variant="h2"
                            className="card-title min-padding-title"
                            data-cy="more-details-dashboard-title"
                          >
                            {this.renderTitle(data.dashboard_title)}
                          </Typography>
                          <GreenfieldFavoriteButton
                            type="dashboards"
                            id={this.state.id}
                            isFavorite={this.state.favStatus}
                            className="greenFieldNoPrint"
                          />
                          <div className="card-details min-padding-title">
                            <Typography variant="caption">{data.dashboard_data_classification}</Typography>
                          </div>
                        </span>
                        <div className="card-details topItem">
                          <Typography variant="caption">
                            <b>Owner(s): </b>
                            {metadata.owners_access?.map((item, index) => {
                              const isLast = index === metadata.owners_access.length - 1

                              if (item.type === 'group' && item.user_type === 'internal') {
                                return (
                                  <Link
                                    key={item._id}
                                    to={`/admin/groups/${item._id}/members`}
                                    style={{ textDecoration: 'none', cursor: 'pointer' }}
                                  >
                                    {item.user_group_name || item.label}
                                    {!isLast ? ', ' : null}
                                  </Link>
                                )
                              }

                              return (
                                <span key={item._id}>
                                  {item.user_group_name || item.label}
                                  {!isLast ? ', ' : null}
                                </span>
                              )
                            })}
                          </Typography>
                        </div>
                        <div className="card-dates greenFieldNoPrint">
                          <Typography variant="caption">
                            <b>Dashboard Created on </b>
                          </Typography>
                        </div>
                        <div className="card-dates greenFieldNoPrint">
                          <Typography variant="caption">
                            <b>Dashboard Last Updated on </b>
                            {moment(data.last_modified_time).format('MMMM Do YYYY, h:mm:ss a')}
                          </Typography>
                        </div>
                        <Typography variant="caption" className="business-area min-margin-top-comp">
                          <b>Business Area(s) </b>
                          <span>
                            {businessArea.map((item, key) => (
                              <Chip
                                data-cy={`business-area-chip-${key}`}
                                key={key}
                                label={item}
                                variant="outlined"
                                color="primary"
                              />
                            ))}
                          </span>
                        </Typography>
                        <Grid item xs={6}>
                          <Grid container alignItems="flex-start" direction="row" justifyContent="center">
                            {this.renderCountCard('Cards', data.card_count)}
                          </Grid>
                        </Grid>
                      </div>
                      <footer className="footer">
                        <Button
                          data-cy="more-details-ok-btn"
                          variant="text"
                          color="secondary"
                          onClick={this.handleClose}
                        >
                          OK
                        </Button>
                      </footer>
                    </Dialog>
                  )}
                  {expandHistory && (
                    <HistoryPopover
                      isOpen={expandHistory}
                      onHandleClose={this.handleCloseHistory}
                      historyType="dashboard"
                      historyId={id}
                      historyData={historyData}
                      setHistoryDataCallback={this.setHistoryDataCallback}
                      editEnabled={dashboardData.edit_enabled === 'yes'}
                    />
                  )}
                </Grid>
              </Grid>
              <Grid item xs={8} className="greenFieldNoPrintHeader">
                <Grid container alignItems="flex-end" direction="column">
                  <div>
                    {(!isMobile || deviceType.includes('isTablet')) && (
                      <span className="margin-refresh-dashboard greenFieldNoPrint">
                        <Tooltip title="Refresh Dashboard" placement="bottom">
                          <IconButton
                            onClick={() => {
                              this.refreshDashboard()
                            }}
                          >
                            <Refresh />
                          </IconButton>
                        </Tooltip>
                      </span>
                    )}
                    {!this.hideViewerTimePeriod() && this.renderSelectedTimePeriod()}
                    {this.renderTimePeriodPopup()}
                    {data.filters && data.filters.length > 0 && this.dashboardFilter(data.filters, data.id)}
                    {dashboardData.support_details && dashboardData.support_details.text && (
                      <Tooltip title="Help" placement="top" className="greenFieldNoPrint">
                        <IconButton
                          id="dashboard-view-help"
                          onClick={this.handleShowSupportText}
                          aria-label="help button"
                        >
                          <InfoRounded className="align-icon" />
                        </IconButton>
                      </Tooltip>
                    )}
                    {this.state.isShowHelp && (
                      <Popover
                        anchorReference="anchorEl"
                        anchorEl={isShowHelp}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        className="support-text-popover"
                        open
                        onClose={this.handleShowSupportText}
                        onKeyDown={this.handleKeyPressed}
                      >
                        <div className="support-text-popover" data-cy="support-popover-details-text">
                          <Viewer model={dashboardData.support_details.text} />
                        </div>
                      </Popover>
                    )}
                    {data.edit_enabled === 'yes' && (
                      <>
                        <Tooltip title="Edit Dashboard" placement="top" tabIndex="-1">
                          <Link
                            to={_siteName ? `/site/${_siteName}/builder/dashboard/${id}` : `/builder/dashboard/${id}`}
                          >
                            <IconButton
                              id="dashboard-view-edit"
                              onClick={clearSavedCards}
                              className="icon-button greenFieldNoPrint"
                            >
                              <Edit className="align-icon link-icon" />
                            </IconButton>
                          </Link>
                        </Tooltip>
                      </>
                    )}
                    {(data.enable_save_as || data.edit_enabled === 'yes') && this.renderSaveAsCard()}
                    {this.renderOpMenu()}
                    <IconButton id="dashboard-view-more" aria-label="More" onClick={this.handleMoreActionsClick}>
                      <Icon>more_vert</Icon>
                    </IconButton>
                    <Menu
                      open={Boolean(openActionsMenu) && !expandMore}
                      anchorEl={openActionsMenu}
                      onClose={this.handleMoreActionsClose}
                    >
                      {(data.enable_save_as || data.edit_enabled === 'yes') && (
                        <MenuItem
                          onClick={() => {
                            this.handleSetOpenSaveAs()
                            this.handleMoreActionsClose()
                          }}
                          id="save-dashboard-as-btn"
                        >
                          <FileCopy className="menu-icon-list" />
                          Save Dashboard As
                        </MenuItem>
                      )}

                      <MenuItem
                        onClick={() => {
                          this.handleMoreActionsClose()
                          this.setState({
                            shortLinkVisible: true,
                          })
                        }}
                        id="dashboardviewer-generate-shortlink"
                        data-cy="dashboardviewer-generate-shortlink"
                      >
                        <IconLink className="menu-icon-list" />
                        Get a short link
                      </MenuItem>

                      {data.edit_enabled === 'yes' && (
                        <MenuItem
                          onClick={() => {
                            this.handleOnClickDeleteDashboard()
                            this.handleMoreActionsClose()
                          }}
                          id="delete-dashboard-btn"
                          data-cy="delete-dashboard-btn"
                        >
                          <Delete className="menu-icon-list" />
                          Delete
                        </MenuItem>
                      )}
                      <MenuItem
                        id="dashboard-view-more-btn"
                        data-cy="dashboard-view-more-btn"
                        onClick={this.handleMoreClick}
                      >
                        View More Details
                      </MenuItem>
                      <a
                        href={VIEW_USAGE_DASHBOARD(dashboardId || routeProps.match.params._id)}
                        style={{ textDecoration: 'none', color: 'rgba(0, 0, 0, 0.87)' }}
                        id="dashboard-view-usage-btn"
                        data-cy="dashboard-view-usage-btn"
                      >
                        <MenuItem onClick={this.handleUsageClick}>View Usage</MenuItem>
                      </a>
                      {data.edit_enabled === 'yes' && (
                        <Link
                          data-cy="create-card-notification-btn"
                          to={`/notification-manager/edit/dashboard/${data.id}`}
                          style={{ textDecoration: 'none', color: 'rgba(0, 0, 0, 0.87)' }}
                        >
                          <MenuItem>Create Notification</MenuItem>
                        </Link>
                      )}
                      <MenuItem id="view-history-btn" onClick={this.handleViewHistory}>
                        View History
                      </MenuItem>
                    </Menu>
                  </div>
                </Grid>
              </Grid>
              <ConfirmDialog
                open={this.state.confirmDeleteDashboard}
                contentText={`Are you sure you want to delete ${data.dashboard_title} dashboard ? This dashboard contains ${data.card_count} card(s)`}
                okText="DELETE"
                onCloseDialog={this.handleCloseDialog}
                onClickOk={this.handleDeleteDashboard}
                onClickCancel={this.handleCloseDialog}
              />
            </Grid>
          </Toolbar>
        </AppBar>
        {this.props.dashboardDelStatus && this.props.dashboardDelStatus.status === 204 && <Redirect to="/dashboards" />}
        {shortLinkVisible && (
          <Generateshortlink
            id={dashboardId || routeProps.match.params._id}
            type="dashboard"
            routeProps={routeProps}
            onDismiss={onDismissShortlinkModal}
          />
        )}
      </>
    )
  }
}

DashboardviewerHeader.defaultProps = {
  timePeriod: {},
}

DashboardviewerHeader.propTypes = {
  routeProps: PropTypes.object,
  activeTab: PropTypes.number,
  timePeriod: PropTypes.object,
}

export default withAnalytics(analyticsConfig)(DashboardviewerHeader)
