import styled from 'styled-components'
import { DialogActions, Typography, Stepper, Dialog, Popover, IconButton, DialogTitle } from '@mui/material'
import { ArrowBack } from '@mui/icons-material'

// step 1
export const SDialog = styled(Dialog)`
  div[role='dialog'] {
    max-width: initial;
  }
`
export const LabelContainer = styled.div`
  display: flex;
  justify-content: center;
`
export const SClose = styled(IconButton)`
  && {
    padding: 5px;
  }
`
// step 2
export const Step2ContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 20px;

  > .dsb-form-container {
    flex-grow: 1;
    padding: 0 20px;
  }

  > .dsb-form-container-1 {
    display: flex;
    align-items: baseline;
    position: relative;
    top: -20px;

    > div {
      display: flex;
      align-items: baseline;
      flex-direction: column;

      &:first-child {
        opacity: 0.4;
      }

      &:last-child {
        flex-grow: 1;
      }
    }
  }

  > .dsb-form-container-2 {
    display: flex;
  }
`
export const SDialogActions = styled(DialogActions)`
  && {
    align-items: end;
  }
`
export const FinalizeErrorsDetail = styled.details`
  padding-bottom: 10px;
  flex: 1;
  max-height: 400px;
  overflow-y: auto;
  color: #eb0000;

  li {
    color: #000;
  }
`
export const NoTimeWarningMessage = styled(Typography)`
  color: #eb0000;
  display: flex;
  align-items: center;
`
export const CustomDateInfoPopoverText = styled(Typography)`
  padding: 10px;
`
export const SIconButton = styled(IconButton)`
  && {
    padding: 10px 0;
  }
`
export const SArrowBack = styled(ArrowBack)`
  position: relative;
  top: 5px;
  right: 8px;
  cursor: pointer;

  &:hover {
    background: #81c784;
    border-radius: 50%;
  }
`
export const SDialogTitle = styled(DialogTitle)`
  display: flex;
  justify-content: space-between;

  > div > span {
    color: #51a255;
  }
`
export const SPopover = styled(Popover)`
  > .gf-MuiPopover-paper {
    padding: 15px;
  }

  .gf-MuiTypography-subtitle1 {
    text-align: center;
    font-size: 18px;
  }

  .edit-column-button-container {
    display: flex;
    justify-content: space-between;
  }

  .radio-container {
    display: flex;
    align-items: center;

    img {
      width: 30px;
      margin-right: 5px;

      &.timeseries {
        background: #fefedd;
      }

      &.metric {
        background: #fde2e2;
      }

      &.dimension {
        background: #cae8fb;
      }
    }
  }
`
export const ColumnHeaderIcon = styled.img`
  width: 30px;
  position: relative;
  top: 9px;
  margin-right: 5px;
  &.timeseries {
    background: #fefedd;
  }

  &.metric {
    background: #fde2e2;
  }

  &.dimension {
    background: #cae8fb;
  }
`
export const PopoverLabelContainer = styled.div`
  display: flex;
  align-items: center;
`
// step 3
export const TitleContainer = styled.div`
  > p {
    text-align: center;

    &:first-child {
      font-size: 20px;
    }
  }
`
export const SStepper = styled(Stepper)`
  && {
    .gf-MuiStepIcon-active {
      color: #51a255;
    }

    .gf-MuiStepIcon-completed {
      color: #51a255;
    }
  }
`
export const SelectedOwnersContainer = styled.div`
  max-height: 50px;
  overflow-y: scroll;
`

export const SFontStyleButton = styled(IconButton)`
  padding: 8px 3px;
  margin-top: 2px;
  &:hover {
    padding: 8px 3px;
  }
`
