import React from 'react'
import {
  Avatar,
  FormControl,
  Input,
  InputLabel,
  InputAdornment,
  List,
  ListItem,
  MenuItem,
  Select,
  Tooltip,
} from '@mui/material'
import PropTypes from 'prop-types'
import { Search } from '@mui/icons-material'
import classNames from 'classnames'
import escapeRegExp from 'lodash/escapeRegExp'
import replace from 'lodash/replace'

import './option-secton.scss'

import {
  getColumnColorCodeBasedOnType,
  getColumnExcelIndexByRefId,
  processColumnsIcon,
} from '../../routes/Builder/Sidebar/utils'
import { calculatedTypes } from '../../routes/CalculatedFIeld/CalculatedFieldConstants'
import { SChip } from '../../routes/Builder/Builder-styled-components'
import { THETASKETCH } from '../../constants/StringConstants'
import { isThetaSketch } from '../../shared/Utilities/General.utility'

class SelectOptionSection extends React.Component {
  state = {
    searchValue: '',
    selectType:
      this.props.selectOptions &&
      this.props.selectOptions.length &&
      (this.props.isFunctionHelp ? this.props.selectOptions[0].name : this.props.selectOptions[1].name),
  }

  filterOnSelectionType = column => {
    if (this.state.selectType === 'all') {
      return true
    } else if (isThetaSketch(this.state.selectType)) {
      return column.data_type === THETASKETCH
    } else {
      return (
        (column.type === this.state.selectType &&
          this.state.selectType !== 'calculated' &&
          !isThetaSketch(column.data_type)) ||
        (this.state.selectType === 'calculated' && calculatedTypes.includes(column.type)) ||
        column.name === this.state.selectType
      )
    }
  }

  filterOnSearchValue = column => {
    const searchValue = replace(this.state.searchValue, /\\/g, '\\\\')
    return (
      column.column_display_name.toLowerCase().search(escapeRegExp(searchValue.toLowerCase())) !== -1 ||
      (column.field_name && column.field_name.toLowerCase().search(escapeRegExp(searchValue.toLowerCase())) !== -1)
    )
  }

  handleSearchChange = event => {
    this.updateState('searchValue', event.target.value)
  }

  handleSelectChange = event => {
    event.preventDefault()
    this.updateState('selectType', event.target.value)
  }

  updateState = (stateName, value) => {
    this.setState({
      [stateName]: value,
    })
  }

  keyPressed = (event, listSelect, column) => {
    if (event.which === 13 || event.which === 32) {
      this.handleListSelectValue(listSelect, column)
    }
  }

  handleListSelectValue = (listSelect, column) => {
    this.props.handleListSelectValue(listSelect, column)
  }

  generateListItem = (col, index, labelName) => {
    const colType = getColumnColorCodeBasedOnType(col)
    return (
      <ListItem
        tabIndex="0"
        id="column-list-item"
        key={index + (col.ref_id || col.field_name || col.value)}
        className="focus-outlined"
        onClick={() => {
          this.handleListSelectValue('listSelect', col)
        }}
        onKeyDown={event => this.keyPressed(event, 'listSelect', col)}
      >
        <SChip
          id={this.props.idListColumn + replace(col.column_display_name, /\\/g, '\\\\')}
          className={`${colType} click-cursor`}
          role="button"
          avatar={
            this.props.isAvatar ? (
              <Avatar alt={colType} src={processColumnsIcon(col)} className={classNames('icon-avatar', colType)} />
            ) : null
          }
          label={labelName}
          aria-label={`${labelName} ${
            this.props.isFunctionHelp ? `Function Help ${this.props.calcFieldFnDoc[labelName]}` : ''
          }`}
        />
      </ListItem>
    )
  }

  render() {
    return (
      <div className="select-option">
        {this.props.selectOptions && this.props.selectOptions.length && (
          <Select
            fullWidth
            label={this.props.selectLabel}
            variant="standard"
            id={this.props.idSelectType}
            value={this.state.selectType}
            onChange={this.handleSelectChange}
          >
            {this.props.selectOptions.map(options => (
              <MenuItem key={options.name} value={options.name}>
                {options.value}
              </MenuItem>
            ))}
          </Select>
        )}
        <FormControl fullWidth variant="standard">
          <InputLabel htmlFor={this.props.idSearchColumn}>{this.props.searchLabel}</InputLabel>
          <Input
            id={this.props.idSearchColumn}
            className="margin-bottom-10"
            type="text"
            value={this.state.searchValue}
            onChange={this.handleSearchChange}
            endAdornment={
              <InputAdornment position="end">
                <Search />
              </InputAdornment>
            }
          />
        </FormControl>
        <List className="column-list columns-section">
          {this.props.arrList
            .filter(this.filterOnSelectionType)
            .filter(this.filterOnSearchValue)
            .map((col, index) => {
              const labelName = col.ref_id
                ? `${col.column_display_name} ${getColumnExcelIndexByRefId(col.ref_id, this.props.cardColumnList)}`
                : col.field_name
                ? `${
                    col.column_display_name !== col.field_name
                      ? `${col.column_display_name} (${col.field_name})`
                      : `${col.column_display_name}`
                  }`
                : col.column_display_name

              return col.description ? (
                <Tooltip key={index + (col.ref_id || col.field_name || col.value)} title={col.description}>
                  {this.generateListItem(col, index, labelName)}
                </Tooltip>
              ) : (
                this.generateListItem(col, index, labelName)
              )
            })}
        </List>
      </div>
    )
  }
}

SelectOptionSection.propTypes = {
  arrList: PropTypes.array.isRequired,
  selectOptions: PropTypes.array,
  handleListSelectValue: PropTypes.func.isRequired,
  isAvatar: PropTypes.bool,
  idSelectType: PropTypes.string,
  idSearchColumn: PropTypes.string.isRequired,
  idListColumn: PropTypes.string.isRequired,
  selectLabel: PropTypes.string,
  searchLabel: PropTypes.string.isRequired,
}

export default SelectOptionSection
